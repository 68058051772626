exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".linkText___3tf-j {\r\n  text-decoration: underline;\r\n  font-size: 12px;\r\n  cursor: pointer;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: end;\r\n      justify-content: flex-end;\r\n}\r\n\r\n.linkText___3tf-j > button {\r\n  margin: 0px 0px 0px 12px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/MonitoringConfigurationsTable/styles.css"],"names":[],"mappings":"AAAA;EAEE,2BAA2B;EAC3B,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;EACrB,cAAc;EACd,uBAAuB;MACnB,oBAAoB;EACxB,mBAAmB;MACf,0BAA0B;CAC/B;;AAED;EACE,yBAAyB;CAC1B","file":"styles.css","sourcesContent":[".linkText {\r\n  composes: avigilonBlueTextColor from '../../sharedStyles/brushes.css';\r\n  text-decoration: underline;\r\n  font-size: 12px;\r\n  cursor: pointer;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: end;\r\n      justify-content: flex-end;\r\n}\r\n\r\n.linkText > button {\r\n  margin: 0px 0px 0px 12px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"linkText": "linkText___3tf-j " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["avigilonBlueTextColor"] + ""
};