import PropTypes from 'prop-types';
import React from 'react';
import renderIf from 'render-if';

import { Icon } from 'lib';

import { containerOverride } from './styles.css';

const Toast = ({
  // Optional: Pass in function required to close the message
  close,
  // Optional: Boolean to set cotainer to fixed position
  fixedPosition,
  // Optional: Boolean to trigger "dismissable" Toast
  keepOpen,
  // Required: String, translate, etc to display as message
  message,
  // Required: Success | Error | Warning
  messageType,
}) => {
  let color;
  const msgType = () => {
    switch (messageType) {
      case 'success':
        color = 'white';
        return 'success';
      case 'warning':
        color = 'black';
        return 'warning';
      case 'error':
        color = 'white';
        return 'error';
      default:
        return '';
    }
  };
  const msgIcon = () => {
    switch (messageType) {
      case 'success':
        return 'ic_menu_available';
      case 'warning':
        return 'ic_information';
      case 'error':
        return 'ic_error';
      default:
        return '';
    }
  };
  return (
    <div
      className={
        fixedPosition
          ? 'msi-toast-container top-center'
          : `${containerOverride} msi-toast-container`
      }
    >
      <div className={`msi-toast msi-toast--${msgType()}`}>
        <div className="msi-toast-body">
          <div className="msi-toast-icon">
            <Icon color={color} id={msgIcon()} renderBare />
          </div>
          <div className="msi-toast-message">{message}</div>
          {renderIf(keepOpen)(
            <div
              className="msi-btn msi-btn-action msi-toast-close-button"
              onClick={close}
              onKeyPress={close}
              role="button"
              tabIndex="0"
            >
              <Icon id="ic_remove" renderBare />
            </div>,
          )}
        </div>
      </div>
    </div>
  );
};

Toast.defaultProps = {
  close: () => {},
  fixedPosition: true,
  keepOpen: false,
};

Toast.propTypes = {
  close: PropTypes.func,
  fixedPosition: PropTypes.bool,
  keepOpen: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  messageType: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
};

export default Toast;
