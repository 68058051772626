import * as types from 'constants/ActionTypes';

export function logSignalRMessage(message) {
  const now = new Date().getTime();
  return {
    type: types.LOG_SIGNAL_R_MESSAGE,
    payload: { ...message, timestamp: now },
  };
}

export function clearSignalRLog() {
  return {
    type: types.CLEAR_SIGNAL_R_LOG,
  };
}
