import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { formReadOnlyText, inputError, isInputError } from './styles.css';

const FieldSelect = props => {
  const {
    children,
    className,
    disabled,
    input,
    isNewVersion,
    meta: { error, touched },
    options,
    placeholder,
    translate,
  } = props;

  const getDropdownComponent = () => {
    return (
      <select
        className={touched && error ? isInputError : ''}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
      >
        {placeholder ? (
          <option key="placeholder" hidden selected value="">
            {placeholder}
          </option>
        ) : (
          ''
        )}
        {options
          ? options.map(option => (
              <option key={option.Enum} value={option.Enum}>
                {option.Display}
              </option>
            ))
          : children}
      </select>
    );
  };

  const getSelectedItemText = dropdownComponent => {
    let selectedItemText = null;
    if (
      dropdownComponent.props &&
      dropdownComponent.props.children &&
      dropdownComponent.props.children.length > 0
    ) {
      const dropdownComponentOptions = dropdownComponent.props.children.find(
        child => Array.isArray(child),
      );
      selectedItemText = dropdownComponentOptions
        .filter(child => child.type === 'option' || Array.isArray(child))
        .map(child => {
          if (Array.isArray(child)) {
            const matchedItem = child.find(
              o =>
                o.props &&
                o.props.value &&
                o.props.value.toString() === input.value.toString(),
            );
            if (matchedItem) {
              return matchedItem.props.children;
            }
          } else if (
            child.props &&
            child.props.value &&
            child.props.value.toString() === input.value.toString()
          ) {
            return child.props.children;
          }
          return null;
        });
    }
    return selectedItemText;
  };

  let field = null;
  if (disabled && isNewVersion) {
    const dropdownComponent = getDropdownComponent();
    field = (
      <div className={formReadOnlyText}>
        {getSelectedItemText(dropdownComponent)}
      </div>
    );
  } else {
    field = (
      <div className={className}>
        {getDropdownComponent()}
        {touched && error && (
          <span className={inputError}>
            {translate ? <Translate id={error} /> : error}
          </span>
        )}
      </div>
    );
  }
  return field;
};

FieldSelect.defaultProps = {
  options: null,
  placeholder: null,
};

FieldSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Display: PropTypes.string,
      Enum: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
};

export default FieldSelect;
