import { generateSort } from 'util/generateSort';

export const formatTimezone = (name, number) => {
  const formattedNumber = number.indexOf(':') >= 0 ? number : `${number}:00`;
  return `(UTC${formattedNumber}) ${name}`;
};

export const formatHourAndMinuteFromOffset = (offset, isHours = false) => {
  if (isHours) {
    const offsetHours = parseInt(offset, 10);
    const hoursAbs = Math.abs(offsetHours);
    const sign = offsetHours >= 0 ? '+' : '-';
    const prependZero = hoursAbs < 10;
    const hours = prependZero ? `${sign}0${hoursAbs}` : `${sign}${hoursAbs}`;

    const offsetMinutes = Math.abs((parseFloat(offset) % 1) * 60).toFixed(0);
    const minutes = offsetMinutes < 10 ? `0${offsetMinutes}` : offsetMinutes;

    return `${hours}:${minutes}`;
  }

  const hours = parseInt(offset / 3600, 10)
    .toString()
    .replace(/^(\d)/, '+$1')
    .replace(/([+-])(\d)$/, '$10$2');

  const minutes = Math.abs((parseFloat(offset / 3600) % 1) * 60)
    .toFixed(0)
    .replace(/^0$/, '00');

  return `${hours}:${minutes}`;
};

// We consider a time zone to be "normalized" when it is an object with
// a Key prop that is the name of the zone and a Value prop that is the offset
// in "+/-HH:MM" format (e.g. { Key: "America/New_York", Value: "-04:00" })
export const normalizeServerTimeZoneList = timeZoneList => {
  if (!timeZoneList) return [];
  return timeZoneList
    .sort(generateSort(zone => parseInt(zone.offset, 10)))
    .map(zone => ({
      Key: zone.name,
      Value: formatHourAndMinuteFromOffset(zone.offset),
    }));
};
