import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const highlightColor = '#FFFFFF';

const IconAlarmInReview = props => {
  const { className, height, highlight, title, translate, width } = props;

  return (
    <div
      height={height}
      style={{ display: 'flex' }}
      title={translate(title)}
      width={width}
    >
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 33 33"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g
          fill={highlight ? highlightColor : ''}
          fillRule="evenodd"
          id="01-notifications-list"
          stroke="none"
          strokeWidth="1"
        >
          <g id="Alarms-List" transform="translate(-127.000000, -829.000000)">
            <g
              id="new-icon-edit-01"
              transform="translate(127.000000, 829.000000)"
            >
              <path
                d="M16.4781528,4.10719817 C9.642875,4.10719817 4.101625,9.59761101 4.101625,16.3701798 C4.101625,23.1427486 9.642875,28.6331615 16.4781528,28.6331615 C23.3134306,28.6331615 28.8546806,23.1427486 28.8546806,16.3701798 C28.8546806,9.59761101 23.3134306,4.10719817 16.4781528,4.10719817 M32.9564583,16.3701798 C32.9564583,25.3873541 25.5788194,32.6973083 16.4781528,32.6973083 C7.37748611,32.6973083 -0.000152777778,25.3873541 -0.000152777778,16.3701798 C-0.000152777778,7.3530055 7.37748611,0.0433541284 16.4781528,0.0433541284 C25.5788194,0.0433541284 32.9564583,7.3530055 32.9564583,16.3701798"
                fill={highlight ? '#FFFFFF' : '#D0D2D3'}
                id="Fill-1"
              />
              <polygon
                fill={highlight ? highlightColor : '#70B1E0'}
                id="Fill-4"
                points="12.68795 22.6908606 9.77111667 19.8007872 18.41345 11.2377413 21.3302833 14.1278147"
              />
              <polygon
                fill={highlight ? highlightColor : '#70B1E0'}
                id="Fill-6"
                points="22.1121389 13.201756 19.1953056 10.3116826 22.1121389 7.42160917 25.0289722 10.3116826"
              />
              <polygon
                fill={highlight ? highlightColor : '#70B1E0'}
                id="Fill-8"
                points="12.0344583 23.6412303 8.51354167 24.2397716 9.117625 20.7511569"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconAlarmInReview.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.bool,
  title: PropTypes.string,
  translate: PropTypes.func,
  width: PropTypes.string,
};

IconAlarmInReview.defaultProps = {
  className: null,
  height: '25px',
  highlight: false,
  title: 'ALARMS.STATUS.IN_REVIEW_LABEL',
  translate: () => {},
  width: '25px',
};

export default withLocalize(IconAlarmInReview);
