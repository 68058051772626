import React from 'react';
import { Translate } from 'react-localize-redux';
import * as modalTypes from 'constants/ModalTypes';
import { bold, messageLine } from '../styles.css';
import { SUSPEND, UNSUSPEND } from '../constants';

export default function handleSuspension(
  type,
  currentOrg,
  selectedLocations,
  hasPermission,
  actions,
) {
  const isAllowed = hasPermission && selectedLocations.length > 0;
  if (!isAllowed) {
    return;
  }

  const onOkClick = () => {
    const currentOrgId = currentOrg.Id;
    actions.suspendUnsuspendLocations(
      currentOrgId,
      selectedLocations.map(l => l.Id),
      type,
    );
    actions.hideModal();
  };

  const message = [
    <span key={`${type}SiteConfirm`}>
      <Translate id={`ACCOUNTS.LOCATIONS.${type}_SITE_MESSAGE`} />
    </span>,
  ];

  const names = selectedLocations.map((l, ix) => (
    <span className={bold}>
      {ix < selectedLocations.length - 1 ? ` ${l.Name},` : ` ${l.Name}`}
    </span>
  ));
  message.push(names);

  const suspendNotice = (
    <div key="suspendInfo" className={messageLine}>
      <Translate id="ACCOUNTS.LOCATIONS.SUSPEND_SITE_INFO_HEAD" />
      <ul>
        <li>
          <Translate id="ACCOUNTS.LOCATIONS.SUSPEND_SITE_INFO_LINE_01" />
        </li>
        <li>
          <Translate id="ACCOUNTS.LOCATIONS.SUSPEND_SITE_INFO_LINE_02" />
        </li>
        <li>
          <Translate id="ACCOUNTS.LOCATIONS.SUSPEND_SITE_INFO_LINE_03" />
        </li>
      </ul>
      <div className={messageLine}>
        <Translate id="ACCOUNTS.LOCATIONS.SUSPEND_SITE_INFO_TAIL" />
      </div>
    </div>
  );

  if (type === SUSPEND) {
    message.push(suspendNotice);
  }

  const modalProps = {
    handleCancel: () => {
      actions.hideModal();
    },
    message,
    onOkClick,
    textCancel: 'BUTTONS.CANCEL',
    textConfirm: `BUTTONS.${type}`,
    title: <Translate id={`ACCOUNTS.LOCATIONS.${type}_SITE`} />,
  };

  actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
}
