/**
 * The purpose of this component is to manage data and switch view outlets.
 * The data is sorted and filtered in this component, and rendered in an available outlet.
 * Outlets include the OGTable, and the CardSet components, but anything that
 * alligns with the prop signature could work.
 */
// Libs
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import renderIf from 'render-if';
import { withLocalize } from 'react-localize-redux';

// Components
import { CardSet, EmptyPlaceholder, OGTable } from 'lib';

// Utils
import { generateSort } from 'util/generateSort';
import { searchText } from 'util/searchText';

// Icons
import { IconGridView, IconListView } from 'icons';

// Constants
import { idSearchInput } from '../../containers/AccountManagement/constants';

// Styles
import * as styles from './ListView.css';

const ViewToggle = ({ setDisplayType }) => {
  const getHandler = useCallback(
    type => {
      switch (type) {
        case 'cards':
        case 'table': {
          return () => setDisplayType(type);
        }
        default: {
          return avoLogError(
            `Unexpected type provided to ViewToggle.getHandler: ${type}`,
          );
        }
      }
    },
    [setDisplayType],
  );

  return (
    <div className={styles.viewSwitcher}>
      <div
        className={styles.viewSwitcherItem}
        onClick={getHandler('table')}
        onKeyPress={getHandler('table')}
        role="button"
        tabIndex={0}
      >
        <IconListView title="LIST_VIEWS.LIST" />
      </div>
      <div
        className={styles.viewSwitcherItem}
        onClick={getHandler('cards')}
        onKeyPress={getHandler('cards')}
        role="button"
        tabIndex={0}
      >
        <IconGridView title="LIST_VIEWS.GRID" />
      </div>
    </div>
  );
};
ViewToggle.propTypes = {
  setDisplayType: PropTypes.func.isRequired,
};
const ContentFilter = withLocalize(
  ({
    defaultFilterText,
    filteringText,
    persistFilterAs,
    persistentFilterCallback,
    setFilteringText,
    translate,
  }) => {
    function filterContentByText(e) {
      const { value } = e.target;
      setFilteringText(value);
      if (persistFilterAs) {
        persistentFilterCallback(persistFilterAs, value);
      }
    }
    return (
      <input
        className={styles.filterInput}
        id={idSearchInput}
        onChange={filterContentByText}
        placeholder={translate('LIST_VIEWS.FILTER')}
        type="search"
        value={filteringText || defaultFilterText}
      />
    );
  },
);
const SortSelector = withLocalize(
  ({ fieldOrder, headerLabels, onSortChange, sortField }) => {
    const options = fieldOrder.map(fieldName => {
      return {
        label: headerLabels[fieldName],
        value: fieldName,
      };
    });
    return (
      <div className={styles.sortSelector}>
        {'Sort By: '}
        <select defaultValue={sortField} onChange={onSortChange}>
          {options.map(pair => (
            <option key={pair.value} value={pair.value}>
              {pair.label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);

function ListView(props) {
  const {
    canChangeView,
    cardFieldOrder,
    clickableRows,
    data,
    defaultFilterText,
    defaultSortField,
    fieldOrder,
    fillContainerHeight,
    hideFilter,
    headerLabels,
    isFetching,
    leftContent,
    messageComponent,
    mobileTouchCards,
    persistFilterAs,
    persistentFilterCallback,
    rightContent,
    rowClickCallback,
    showFilters,
    sortType,
    switchListViewType,
    title,
    titleClass,
  } = props;
  const [displayType, setDisplayType] = useState(
    mobileTouchCards && 'ontouchstart' in document.documentElement
      ? 'cards'
      : 'table',
  );
  const [sortingField, setSortingField] = useState(defaultSortField);
  const [sortDirection, setSortDirection] = useState('ASC');
  const [filteringText, setFilteringText] = useState('');
  function changeSort(newSortingField) {
    if (newSortingField === sortingField) {
      // Just change direction
      if (sortDirection === 'ASC') {
        setSortDirection('DESC');
      } else {
        setSortDirection('ASC');
      }
    } else {
      setSortDirection('ASC');
      setSortingField(newSortingField);
    }
  }

  function onChangeSort(e) {
    const { value } = e.target;
    changeSort(value);
  }

  const filteredSortedData = useMemo(() => {
    let filteredData;
    let sortedData;
    let textToFilterOn = filteringText || defaultFilterText;
    if (textToFilterOn.length > 0) {
      filteredData = searchText(data, textToFilterOn, fieldOrder);
    } else {
      filteredData = data;
    }
    if (sortingField) {
      const comparator = generateSort(item => {
        return item[sortingField];
      });
      let newSorting = filteredData.sort(comparator);
      if (sortDirection === 'DESC') {
        newSorting = newSorting.reverse();
      }
      sortedData = newSorting;
    } else {
      sortedData = filteredData;
    }
    return sortedData;
  }, [
    sortingField,
    sortDirection,
    data,
    filteringText,
    fieldOrder,
    defaultFilterText,
  ]);

  return (
    <>
      {title && title.length > 0 ? (
        <div className={titleClass || styles.title}>{title}</div>
      ) : (
        ''
      )}
      {renderIf(
        (!hideFilter && showFilters) ||
          switchListViewType ||
          canChangeView ||
          leftContent ||
          rightContent,
      )(
        <div className={styles.filterBar}>
          <div className={styles.leftItems}>
            {renderIf(!hideFilter && showFilters)(
              <ContentFilter
                defaultFilterText={defaultFilterText}
                filteringText={filteringText}
                setFilteringText={setFilteringText}
                persistFilterAs={persistFilterAs}
                persistentFilterCallback={persistentFilterCallback}
              />,
            )}
            {leftContent}
          </div>
          <div className={styles.rightItems}>
            {rightContent}
            {displayType === 'cards' ? (
              <SortSelector
                fieldOrder={fieldOrder}
                headerLabels={headerLabels}
                onSortChange={onChangeSort}
                sortField={sortingField}
              />
            ) : (
              ''
            )}
            {switchListViewType || canChangeView ? (
              <ViewToggle
                displayType={displayType}
                setDisplayType={setDisplayType}
              />
            ) : (
              ''
            )}
          </div>
        </div>,
      )}
      {messageComponent}
      <div
        className={`${styles.listViewContainer} ${
          fillContainerHeight ? styles.fillContainerHeight : ''
        }`}
      >
        <EmptyPlaceholder
          isFetching={isFetching} // isFetching is false unless provided
          items={[1]} // when isFetching is false, hard-coded item list guarantees the table is displayed
          showDataWhileFetching={false} // when isFetching is true, 'loading' will be shown
          string="" // Empty string will never be shown
        >
          {displayType === 'table' ? (
            <OGTable
              {...props}
              data={filteredSortedData}
              defaultSortField={sortingField || defaultSortField}
              headerClickCallback={changeSort}
              sortable={sortType !== 'none'}
            />
          ) : (
            <CardSet
              {...props}
              cardClickCallback={rowClickCallback}
              clickable={clickableRows}
              data={filteredSortedData}
              fieldOrder={cardFieldOrder || fieldOrder}
              sortData={changeSort}
            />
          )}
        </EmptyPlaceholder>
      </div>
    </>
  );
}

ListView.defaultProps = {
  canChangeView: false,
  cardFieldOrder: null,
  clickableRows: false,
  defaultDisplay:
    'ontouchstart' in document.documentElement ? 'cards' : 'table',
  defaultFilterText: '',
  defaultSortField: '',
  fillContainerHeight: false,
  headerLabels: {},
  hideFilter: false,
  isFetching: false,
  leftContent: null,
  messageComponent: null,
  persistFilterAs: null,
  persistentFilterCallback: () => {
    console.error(
      'WARNING: define persistentFilterCallback to persist the filter value in a manner of your choosing.',
    );
  },
  rightContent: null,
  rowClickCallback: () => {},
  showTitle: true,
  sortType: 'none',
  theme: 'listView',
  title: '',
  titleClass: null,
};

ListView.propTypes = {
  canChangeView: PropTypes.bool,
  cardFieldOrder: PropTypes.arrayOf(PropTypes.string),
  clickableRows: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultDisplay: PropTypes.string,
  defaultFilterText: PropTypes.string,
  defaultSortField: PropTypes.string,
  fieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  fillContainerHeight: PropTypes.bool,
  headerLabels: PropTypes.shape({}),
  hideFilter: PropTypes.bool,
  isFetching: PropTypes.bool,
  leftContent: PropTypes.element,
  messageComponent: PropTypes.node,
  persistFilterAs: PropTypes.string,
  persistentFilterCallback: PropTypes.func,
  rightContent: PropTypes.element,
  rowClickCallback: PropTypes.func,
  showTitle: PropTypes.bool,
  sortType: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
};

export default ListView;
