/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/*

IMPORTANT: It is important that CardSet's prop signature and OGTable's prop signature stay aligned.
It is okay to not have exactly the same props. But the names should mean and do about the same things.

It is better to have the features as similar as possible.

- Andre

*/
// Libs
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

// Components
import { ObjectDetailTable, Spinner } from 'components';
import OGHeader from './OGHeader';
import OGRow from './OGRow';

import * as styles from './OGTable.css';

function OGTable(props) {
  const {
    bodyHeight,
    bulkActions,
    bulkActionsSingle,
    bulkActionsTable,
    cellWidths,
    className,
    clickableRowPrompt,
    clickableRows,
    customBodyRowClass,
    customCells,
    customHeader,
    customHeaderClass,
    customRowClass,
    customRowTrigger,
    data,
    debug,
    defaultSortField,
    fieldOrder,
    fillContainerHeight,
    headerClickCallback,
    headerLabels,
    hideBulkActionsWhen,
    hideNoResultMessage,
    highlightRowNo,
    infiniteScroll,
    infiniteScrollLoadingText,
    inlineDetailContent,
    inlineDetailLabel,
    inlineDetails,
    isFetchingMoreData,
    noResultsText,
    nonSortingFields,
    onInfiniteScroll,
    overflowFieldOrder,
    overflowFieldOrderField,
    overflowHeaderTranslations,
    resizable,
    resizableFrom,
    rowActions,
    rowClickCallback,
    rowDisabled,
    rowHeight,
    scrollToPos,
    sortable,
    tableBodyStyle: tableBodyStyleProp,
    truncatedFields,
    xray,
  } = props;
  const [calcRowHeight, setCalcRowHeight] = useState(34);
  const [expandedRow, setExpandedRow] = useState(null);
  const [tableIsScrollable, setTableIsScrollable] = useState(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [grabberActive, setGrabberActive] = useState(false);
  const [initialClientY, setInitialClientY] = useState(0);
  const [currentClientY, setCurrentClientY] = useState(0);
  const [finalOffset, setFinalOffset] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);
  const tableRef = useRef(null);

  useEffect(() => {
    if (props && tableRef) {
      if (tableRef !== null && tableRef.current) {
        if (tableRef.current.scrollHeight > tableRef.current.clientHeight) {
          setTableIsScrollable(true);
        } else {
          setTableIsScrollable(false);
        }
        const scrollbarOffset =
          tableRef.current.offsetWidth - tableRef.current.clientWidth;
        setScrollbarWidth(scrollbarOffset);
      }
    }
    if (props && rowHeight) {
      if (tableRef && tableRef.current && rowHeight.indexOf('%') > 0) {
        const ratio = parseInt(rowHeight, 10) / 100;
        const curBodyHeight = tableRef.current.getBoundingClientRect().height;
        setCalcRowHeight(`${ratio * curBodyHeight}px`);
      } else {
        setCalcRowHeight(rowHeight);
      }
    }
    if (props && scrollToPos && tableRef && tableRef.current) {
      tableRef.current.scrollTop =
        parseInt(scrollToPos, 10) * parseInt(calcRowHeight, 10);
    }
  }, [
    calcRowHeight,
    props,
    rowHeight,
    setTableIsScrollable,
    scrollToPos,
    tableIsScrollable,
    tableRef,
  ]);

  const alignClass = field => {
    const { cellAlignments } = props;
    switch (cellAlignments[field]) {
      case 'right': {
        return styles.alignRight;
      }
      case 'center': {
        return styles.alignCenter;
      }
      default: {
        return styles.alignLeft;
      }
    }
  };

  const calculatedBodyHeight = () => {
    if (fillContainerHeight) {
      return '100%';
    }
    if (resizable) {
      if (resizableFrom === 'top') {
        return `${bodyHeight -
          (grabberActive
            ? finalOffset + (currentClientY - initialClientY)
            : finalOffset)}px`;
      }
      return `${bodyHeight -
        (grabberActive
          ? finalOffset - (currentClientY - initialClientY)
          : finalOffset)}px`;
    }
    return typeof bodyHeight === 'number' ? `${bodyHeight}px` : bodyHeight;
  };

  const activateGrabber = e => {
    e.stopPropagation();
    setInitialClientY(e.clientY);
    setCurrentClientY(e.clientY);
    setGrabberActive(true);
  };

  const moveGrabber = e => {
    if (grabberActive) {
      setCurrentClientY(e.clientY);
    }
  };

  const releaseGrabber = e => {
    if (grabberActive) {
      e.stopPropagation();
      let newHeight;
      if (resizableFrom === 'top') {
        newHeight = grabberActive
          ? finalOffset + (currentClientY - initialClientY)
          : finalOffset;
      } else {
        newHeight = grabberActive
          ? finalOffset - (currentClientY - initialClientY)
          : finalOffset;
      }
      setFinalOffset(newHeight);
      setGrabberActive(false);
    }
  };

  const emitDebouncedScroll = debounce(e => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (scrollTop > lastScrollHeight) {
      setLastScrollHeight(scrollTop);
    }
    if (Math.round(scrollHeight - scrollTop) === clientHeight) {
      onInfiniteScroll();
    }
  }, 500);

  const monitorScroll = e => {
    const { scrollTop } = e.target;
    if (!isFetchingMoreData && scrollTop !== lastScrollHeight) {
      e.persist();
      emitDebouncedScroll(e);
    }
  };

  const visibleFieldOrder = fieldOrder;
  const fillContainerHeightClass = useMemo(() => {
    return fillContainerHeight ? styles.fillContainerHeight : '';
  }, [fillContainerHeight]);

  const totalColumns = bulkActions ? fieldOrder.length + 1 : fieldOrder.length;

  if (data.length === 0) {
    return (
      <div className={styles.noResults}>
        {!hideNoResultMessage ? noResultsText : ''}
      </div>
    );
  }

  const calculatedHeight = calculatedBodyHeight();
  return (
    <>
      <div
        className={`${styles.ogTableContainer} ${className} ${
          debug ? styles.debug : ''
        } ${fillContainerHeightClass}`}
      >
        {resizable && resizableFrom === 'top' && (
          <div
            className={`${styles.resizeGrabber} ${
              grabberActive ? styles.active : ''
            }`}
            onMouseDown={activateGrabber}
            role="button"
            tabIndex={-1}
          >
            <div className={styles.grabberContent}>===</div>
          </div>
        )}
        <table className={`${styles.ogTable} ${fillContainerHeightClass}`}>
          <OGHeader
            alignClass={alignClass}
            bulkActions={bulkActions}
            cellWidths={cellWidths}
            customHeader={customHeader}
            customHeaderClass={customHeaderClass}
            defaultSortField={defaultSortField}
            hasActions={rowActions.length > 0}
            hasOverflow={
              !!overflowFieldOrder.length || !!overflowFieldOrderField
            }
            headerClickCallback={headerClickCallback}
            headerLabels={headerLabels}
            nonSortingFields={nonSortingFields}
            scrollbarWidth={scrollbarWidth}
            sortable={sortable}
            tableIsScrollable={tableIsScrollable}
            visibleFieldOrder={fieldOrder}
          />
          <tbody
            ref={tableRef}
            className={`${styles.ogTableBody} ${tableBodyStyleProp}`}
            onScroll={monitorScroll}
            style={calculatedHeight ? { maxHeight: calculatedHeight } : {}}
          >
            {data &&
              data.length > 0 &&
              data.map((rowData, idx) => (
                <OGRow
                  key={`ogrow-${idx}`}
                  alignClass={alignClass}
                  bulkActions={bulkActions}
                  bulkActionsSingle={bulkActionsSingle}
                  bulkActionsTable={bulkActionsTable}
                  cellWidths={cellWidths}
                  clickableRowPrompt={clickableRowPrompt}
                  clickableRows={clickableRows}
                  customBodyRowClass={customBodyRowClass}
                  customCells={customCells}
                  customRowClass={customRowClass}
                  customRowTrigger={customRowTrigger}
                  data={data}
                  expanded={expandedRow === idx}
                  fieldOrder={fieldOrder}
                  headerLabels={headerLabels}
                  height={calcRowHeight}
                  headerLabels={headerLabels}
                  hideBulkActionsWhen={hideBulkActionsWhen}
                  highlightRow={highlightRowNo === idx}
                  idx={idx}
                  inlineDetailContent={inlineDetailContent}
                  inlineDetailLabel={inlineDetailLabel}
                  inlineDetails={inlineDetails}
                  overflowFieldOrder={overflowFieldOrder}
                  overflowFieldOrderField={overflowFieldOrderField}
                  overflowHeaderTranslations={overflowHeaderTranslations}
                  rowActions={rowActions}
                  rowClickCallback={rowClickCallback}
                  rowData={rowData}
                  rowDisabled={rowDisabled}
                  setExpandedRow={setExpandedRow}
                  totalColumns={totalColumns}
                  truncatedFields={truncatedFields}
                  visibleFieldOrder={fieldOrder}
                  xray={xray}
                />
              ))}
            {infiniteScroll && isFetchingMoreData ? (
              <tr className={styles.ogBodyRow}>
                <td className={styles.ogBodyCell} colSpan={totalColumns}>
                  <div className={styles.infiniteScrollTrigger}>
                    <Spinner size={20} />
                    <div className={styles.infiniteScrollLoadingText}>
                      {infiniteScrollLoadingText}
                    </div>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {resizable && resizableFrom === 'bottom' && (
          <div
            className={`${styles.resizeGrabber} ${
              grabberActive ? styles.active : ''
            } ${styles.resizeGrabberBottom}`}
            onMouseDown={activateGrabber}
            role="button"
            tabIndex={-1}
          >
            ===
          </div>
        )}
        {grabberActive && (
          <div
            className={styles.mouseMoveVisor}
            onMouseMove={moveGrabber}
            onMouseUp={releaseGrabber}
            role="button"
            tabIndex={-1}
          >
            <div>{`${initialClientY} -> ${currentClientY}`}</div>
          </div>
        )}
      </div>
    </>
  );
}

OGTable.defaultProps = {
  bodyHeight: null,
  bulkActions: false,
  bulkActionsSingle: false,
  bulkActionsTable: null,
  cellAlignments: {},
  cellWidths: {},
  className: '',
  clickableRowPrompt: null,
  clickableRows: false,
  customBodyRowClass: '',
  customCells: {},
  customHeader: {},
  customHeaderClass: {},
  customRowClass: '',
  customRowTrigger: {},
  debug: false,
  defaultSortField: null,
  fillContainerHeight: false,
  headerClickCallback: fieldHeaderClicked => {
    avoLog(
      'Clicked on OGTable header node with remote sort enabled. Add prop headerClickCallback to handle header clicks.',
      { fieldHeaderClicked },
      'warn',
    );
  },
  headerLabels: {},
  hideBulkActionsWhen: () => false,
  hideNoResultMessage: false,
  highlightRowNo: -1,
  infiniteScroll: false,
  infiniteScrollLoadingText: 'Loading More...',
  inlineDetailContent: (
    rowData,
    rowIdx,
    inlineDetails,
    xray,
    headerLabels,
    fieldOrder,
    customCells,
  ) => {
    if (inlineDetails) {
      return (
        <ObjectDetailTable
          customCells={customCells}
          fieldOrder={fieldOrder}
          headerLabels={headerLabels}
          item={rowData}
          rowIndex={rowIdx}
        />
      );
    }
    if (xray) {
      return <ObjectDetailTable item={rowData} rowIndex={rowIdx} />;
    }
    return '';
  },
  inlineDetailLabel: rowData => rowData.Name || rowData.name || 'Details',
  inlineDetails: false,
  isFetchingMoreData: false,
  noResultsText: 'No Results Found',
  nonSortingFields: ['Actions'],
  onInfiniteScroll: () => {
    avoLog('define onInfiniteScroll to enable infinite scroll changes');
  },
  overflowFieldOrder: [],
  overflowFieldOrderField: null,
  overflowHeaderTranslations: {},
  resizable: false,
  resizableFrom: 'bottom',
  rowActions: [],
  rowClickCallback: (rowData, rowIndex) => {
    avoLog('Clicked OGTable row', { rowData, rowIndex });
  },
  rowDisabled: () => false,
  rowHeight: null,
  scrollToPos: null,
  sortable: false,
  tableBodyStyle: '',
  truncatedFields: [],
};

OGTable.propTypes = {
  // If set, the body area of the table will become scrollable <'300px'>
  bodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // For tables with bulk actions, enable checkboxes, and broadcast row selection changes
  bulkActions: PropTypes.bool,
  // Limit bulk actions to only one item. (Rare)
  bulkActionsSingle: PropTypes.bool,
  // Label for which table's bulk actions are being used.
  bulkActionsTable: PropTypes.string,
  // Default is always left. Can override fieldName: <center,right>
  cellAlignments: PropTypes.objectOf(PropTypes.string),
  // A hash of width overrides: fieldName: <number> for width. Just use a number.
  // In general, you will want to use this to make things smaller, but use as sparingly as you can.
  // <140>
  cellWidths: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  // If a row is clickable, and you are on desktop, an optional string explaining what clicking will do.
  clickableRowPrompt: PropTypes.string,
  // If true, clicking a row will run the rowClickCallback
  clickableRows: PropTypes.bool,
  // custom body row class
  customBodyRowClass: PropTypes.string,
  // A hash of custom cells. fieldName: (rowData, rowIdx, allData) => (<div>{rowData.Name}</div>}
  customCells: PropTypes.objectOf(PropTypes.func),
  // Like custom cells, but for headers.
  customHeader: PropTypes.objectOf(PropTypes.node),
  // Provide a custom class for headers.
  customHeaderClass: PropTypes.objectOf(PropTypes.node),
  // CSS class name to apply for the row that matches customRowTrigger
  customRowClass: PropTypes.string,
  // Object indicating to use customRowClass when its field matches a row data field
  customRowTrigger: PropTypes.objectOf(PropTypes.any),
  // Array of objects. Generally, an API response.
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  // At the moment, a dev-only tool to make sure mouse movement is being handled correctly. Ignore.
  debug: PropTypes.bool,
  // Which field to charge the sort with.
  defaultSortField: PropTypes.string,
  // The order of the fields in the table, left to right (for now?)
  fieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  // This will also use the (fewer-fields) list to render the table, and use the normal fieldOrder to render the detail view.
  fillContainerHeight: PropTypes.bool,
  // Callback when you click a header. By default, runs a sort.
  headerClickCallback: PropTypes.func,
  // Object that maps raw strings. Mostly useful for dev-only areas like Oasis, but might be useful if used in other contexts.
  headerLabels: PropTypes.objectOf(PropTypes.string),
  // Function that returns true or false, on whether an item can be operated on by bulk actions. Defaults to a function returning false
  hideBulkActionsWhen: PropTypes.func,
  // Hides the "No results" message displayed when the OGTable is empty
  hideNoResultMessage: PropTypes.bool,
  // Defines which row should be highligted programatically
  highlightRowNo: PropTypes.number,
  // Whether to enable detection of reaching the bottom of the table and requesting more data.
  infiniteScroll: PropTypes.bool,
  // Text to display when infinite scroll is loading another page.
  infiniteScrollLoadingText: PropTypes.string,
  // What renders when inline detail is enabled and a row is clicked. The default is XRay, if enabled.
  // But you can put in any content you want, even a full-on form container. You just have to wire it up.
  inlineDetailContent: PropTypes.func,
  // The inline detail is rendered in an always-open AccordionItem. By default, it will pick a display name for the detail.
  // Specify a custom label here.
  inlineDetailLabel: PropTypes.func,
  // By default, this is off. If you only set it to true, you'll get a programmer-focused listing of fields. (XRay oasis feature)
  inlineDetails: PropTypes.bool,
  // Part of infinite scroll loading
  isFetchingMoreData: PropTypes.bool,
  // Text to display if no data is available for the table
  noResultsText: PropTypes.string,
  // Fields that do not sort, if sorting is enabled.
  nonSortingFields: PropTypes.arrayOf(PropTypes.string),
  // What to do if infinite scroll is enabled, and the bottom is reached
  onInfiniteScroll: PropTypes.func,
  // List of fields whose data should appear in an overflow menu rather than the main body.
  overflowFieldOrder: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // If the overflowFildOrder is an object, declares what field in rowData will select the correct set.
  overflowFieldOrderField: PropTypes.string,
  // List of translations for fields in the overflow menu
  overflowHeaderTranslations: PropTypes.objectOf(PropTypes.string),
  // Lets you resize the table. Handle appears when hovering the table // TODO: Mobile
  resizable: PropTypes.bool,
  // Which side of the table the resize handle appears on.
  resizableFrom: PropTypes.oneOf(['top', 'bottom']),
  // An array of callbacks that deliver mostly buttons/icons that are clickable and cause things to happen.
  rowActions: PropTypes.arrayOf(PropTypes.any),
  // What gets called when you click a row.
  rowClickCallback: PropTypes.func,
  // rowData => bool: Determines if a row should be disabled
  rowDisabled: PropTypes.func,
  rowHeight: PropTypes.string,
  // For Multiselect: Which row indexes are currently selected
  scrollToPos: PropTypes.number,
  // Whether to trigger a sort when clicking table headers
  sortable: PropTypes.bool,
  // If specified, the inlineDetails mechanism will switch on, and the detail view will be a fully rendered detail item.
  tableBodyStyle: PropTypes.string,
  // Array of fields that can be truncated and receive the styling for that, <[fieldOne, fieldTwo]>
  // In the table, these will be rendered as the last column, which generates automatically.
  // In the detail view, these will be rendered as left Items in its header.
  // the argument signature is (rowData, rowIndex, isDetails)
  truncatedFields: PropTypes.arrayOf(PropTypes.string),
};

export default OGTable;
