import _ from 'lodash';

function parseEstimate(seconds) {
  const s = Number(seconds);
  const d = Math.floor(s / (3600 * 24));
  const h = Math.floor((s % (3600 * 24)) / 3600);
  return { days: d, hours: h };
}

function getAmortizedDataRate(deviceId, recordingSettings) {
  const recordSettings =
    recordingSettings &&
    recordingSettings.find(setting => setting.blueId === deviceId);
  if (!recordSettings) {
    return 0;
  }
  const [
    highQualityStoragePercentage,
    lowQualityStoragePercentage,
  ] = recordSettings.streamRetentionAmount.val;
  const primaryDataRate =
    (_.get(recordSettings, 'bandwidth.primaryAverageBitrate_kbps.val', 0) *
      1024) /
    8;
  const secondaryDataRate =
    (_.get(recordSettings, 'bandwidth.secondaryAverageBitrate_kbps.val', 0) *
      1024) /
    8;
  return (
    primaryDataRate *
      (highQualityStoragePercentage + lowQualityStoragePercentage) +
    secondaryDataRate
  );
}

function calculateStorageTime(
  devices,
  recordingSettings,
  totalAvailableStorage,
) {
  const secondsPerDay = 60 * 60 * 24;
  const estimates = {};
  let availableStorage = totalAvailableStorage;
  let maxRetention = 0;
  // Sort devices by retention so we can calculate data rates easier.
  const sortedDevices = JSON.parse(JSON.stringify(devices)).sort(
    (prev, next) => next.retention - prev.retention,
  );
  let i = 0;
  for (i; i < sortedDevices.length && sortedDevices[i].retention > 0; ++i) {
    // Calculate average storage for all devices with retention greater or
    // equal to this device.
    const device = sortedDevices[i];
    const { retention } = device;
    const retentionSeconds = Math.ceil(retention * secondsPerDay);
    let subDataRate = 0;
    for (let j = i; j < sortedDevices.length; ++j) {
      const amortizedDataRate = getAmortizedDataRate(
        sortedDevices[j].Id,
        recordingSettings,
      );
      subDataRate += amortizedDataRate;
    }
    // If all remaining devices have no data rate, just set to retention times
    if (subDataRate === 0) {
      estimates[device.Id] = {
        storageTime: retentionSeconds,
      };
      maxRetention = retentionSeconds;
      continue;
    }

    const averageSeconds = availableStorage / subDataRate;
    if (averageSeconds > retentionSeconds) {
      // If there is more than enough space for this device's maximum retention,
      // reserve that space for the device and move on.
      const amortizedDataRate = getAmortizedDataRate(
        device.Id,
        recordingSettings,
      );
      const requiredStorage = retentionSeconds * amortizedDataRate;
      availableStorage -= requiredStorage;
      estimates[device.Id] = {
        storageTime: retentionSeconds,
      };
      maxRetention = retentionSeconds;
    }
    // We're now capped by the average space requirement.
    else {
      break;
    }
  }

  // Distribute the remaining storage space to all remaining devices.
  let totalDataRate = 0;
  for (let j = i; j < sortedDevices.length; ++j) {
    const amortizedDataRate = getAmortizedDataRate(
      sortedDevices[j].Id,
      recordingSettings,
    );
    totalDataRate += amortizedDataRate;
  }

  // Divide the time equally between all connected cameras.
  if (totalDataRate > 0) {
    const averageSeconds = Math.floor(availableStorage / totalDataRate);
    for (let j = i; j < sortedDevices.length; ++j) {
      const device = sortedDevices[j];
      const { retention } = device;
      const retentionSeconds = Math.ceil(retention * secondsPerDay);
      const calculatedStorageTime =
        retentionSeconds > 0 && averageSeconds > retentionSeconds
          ? retentionSeconds
          : averageSeconds;
      estimates[sortedDevices[j].Id] = {
        storageTime: calculatedStorageTime,
      };
    }
  }
  // If there are no connected cameras, use the maximum retention
  else {
    for (let j = i; j < sortedDevices.length; ++j) {
      estimates[sortedDevices[j].Id] = {
        storageTime: maxRetention,
      };
    }
  }
  const parsed = {};
  Object.keys(estimates).forEach(id => {
    parsed[id] = parseEstimate(estimates[id].storageTime);
  });
  return parsed;
}

export default calculateStorageTime;
