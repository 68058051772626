import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButton } from 'components';
import {
  lblFalse,
  lblTrue,
  toggleSelected,
  toggleSwitch,
  toggleUnselected,
} from './Buttons.styles.css';

// does not maintain any internal state about toggled value
class ToggleSwitch extends React.Component {
  activate = () => {
    const { isActive, onToggle } = this.props;

    if (!isActive) {
      onToggle(true);
    }
  };

  deactivate = () => {
    const { isActive, onToggle } = this.props;

    if (isActive) {
      onToggle(false);
    }
  };

  render() {
    const { id, isActive, labelFalse, labelTrue } = this.props;

    return (
      <div className={toggleSwitch}>
        <ToggleButton
          classNameSelected={toggleSelected}
          classNameUnselected={toggleUnselected}
          id={`${id}_false`}
          isActive={!isActive}
          onMouseDown={this.deactivate}
        >
          <span className={lblFalse}>{labelFalse}</span>
        </ToggleButton>
        <ToggleButton
          classNameSelected={toggleSelected}
          classNameUnselected={toggleUnselected}
          id={`${id}_true`}
          isActive={isActive}
          onMouseDown={this.activate}
        >
          <span className={lblTrue}>{labelTrue}</span>
        </ToggleButton>
      </div>
    );
  }
}

ToggleSwitch.defaultProps = {
  id: 'btnToggle',
  isActive: false,
  labelFalse: null,
  labelTrue: null,
  onToggle: () => {},
};

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  labelFalse: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  labelTrue: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onToggle: PropTypes.func,
};

export default ToggleSwitch;
