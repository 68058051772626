// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// Actions
import { getLocations } from 'actions/locations';

// Components
import { Tooltip } from 'lib';

// Utils
import { translatedConnect } from 'util/translatedConnect';

// Icons
import { IconInfoCircle } from 'icons';

// Styles
import { alertIcon, alertText } from './styles.css';
import {
  infoBox,
  leftContent,
  messageBody as messageBodyStyle,
} from '../PageMessage/styles.css';

class CameraMonitoredAlert extends React.Component {
  // Create a new container to check the camera site / subscription and display monitoring if necessary
  // Decreases the number of re-renders of child components if location is updated

  componentDidMount() {
    const { actions, isFetchingLocations, location } = this.props;
    if (!location && !isFetchingLocations) {
      // fetch all locations to avoid confusing other components
      actions.getLocations();
    }
  }

  renderTooltipIcon = message => {
    return (
      <Tooltip
        message={message}
        toggleMethod="click"
        width={250} // Width of the tab minus some margin
      >
        <div className={alertIcon}>
          <IconInfoCircle height={10} width={10} />
        </div>
      </Tooltip>
    );
  };

  renderMessageBody = (healthEnabled, securityEnabled) => {
    const { translate } = this.props;
    let translateString = 'CAMERA.MONITORING_ALERT';
    if (securityEnabled && !healthEnabled) {
      translateString = 'CAMERA.SECURITY_MONITORING_ALERT';
    } else if (healthEnabled && !securityEnabled) {
      translateString = 'CAMERA.HEALTH_MONITORING_ALERT';
    }
    let tooltipTranslateString = 'CAMERA.MONITORING_ALERT_TOOLTIP';
    if (healthEnabled && !securityEnabled) {
      tooltipTranslateString = 'CAMERA.HEALTH_MONITORING_ALERT_TOOLTIP';
    }
    return (
      <span className={alertText}>
        {translate(translateString)}
        {this.renderTooltipIcon(translate(tooltipTranslateString))}
      </span>
    );
  };

  render() {
    const { location } = this.props;
    if (
      location &&
      location.PackageSubscription &&
      (location.PackageSubscription.RemoteHealthMonitoringEnabled ||
        location.PackageSubscription.RemoteSecurityMonitoringEnabled)
    ) {
      const messageBody = this.renderMessageBody(
        location.PackageSubscription.RemoteHealthMonitoringEnabled,
        location.PackageSubscription.RemoteSecurityMonitoringEnabled,
      );
      return (
        <div className={infoBox}>
          <div className={`${leftContent} ${messageBodyStyle}`}>
            {messageBody}
          </div>
        </div>
      );
    }
    return null;
  }
}
CameraMonitoredAlert.defaultProps = {
  isFetchingLocations: null,
  location: null,
};
CameraMonitoredAlert.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isFetchingLocations: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let location = {};
  if (ownProps.camera && ownProps.camera.LocationId) {
    location = state.locations.locations.find(
      l => l.Id === ownProps.camera.LocationId,
    );
  }
  return {
    isFetchingLocations: state.isFetching.getLocations,
    location,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ getLocations }, dispatch),
  };
};

export default translatedConnect(mapStateToProps, mapDispatchToProps)(
  CameraMonitoredAlert,
);
