/* eslint-disable react/jsx-curly-newline */
// Libs
import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { uniqBy } from 'lodash';
import { ai } from 'util/telemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

// Components
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { EmptyPlaceholder, MainContentWrapper } from 'lib';
import { PageMessage } from 'containers';

// Actions
import * as AftClientActions from 'actions/aftClient';
import * as ClusterActions from 'actions/clusters';
import * as LocationActions from 'actions/locations';
import * as FilterActions from 'actions/filters';
import * as UserActions from 'actions/user';
import * as SubscriptionActions from 'actions/subscriptions';
import { hideModal, showModal } from 'actions/modal';
import * as MenuActions from 'actions/actionMenu';

// Utilities
import { renderLocationDeleteErrors } from 'util/deleteHelper';
import * as permissions from 'util/permissions';
import * as tableUtils from 'util/tableData';
import FilterableContainer from 'util/FilterableContainer';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import {
  BULK_ACTION_TABLES,
  FILTER_GROUPS,
  SERVICE_FAMILIES,
} from 'constants/app';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_SITES,
} from 'constants/urlPaths';
import handleSuspension from './SuspendLogic';
import * as consts from '../constants';
import { defaultSiteDetailsTab } from './constants';
import AccountNavMenu from '../AccountNavMenu';
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';
import SitesListNav from './SitesListNav';
import LocationsTable from './LocationsTable/LocationsTable';
import PageTitle from '../../../lib/PageTitle/PageTitle';

const getLocationsWithAccData = (locations, accServers, accCameras) => {
  return locations.map(location => {
    if (
      location.PackageSubscription.ServiceFamily ===
      SERVICE_FAMILIES.AccCloudServices
    ) {
      const cameraClusters = accCameras[location.Id];
      const serverClusters = accServers[location.Id];

      let cameraCount = 0;
      let serverCount = 0;

      if (cameraClusters) {
        const connectedCameras = Object.keys(cameraClusters).map(clusterId => {
          return cameraClusters[clusterId].filter(cam => cam.active);
        });

        cameraCount = tableUtils.extractItemCountFromMap(connectedCameras);
      }

      if (serverClusters) {
        serverCount = tableUtils.extractItemCountFromMap(serverClusters);
      }

      return {
        ...location,
        CameraCount: cameraCount,
        DeviceCount: serverCount,
      };
    }

    return location;
  });
};

class SitesContainer extends FilterableContainer {
  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      submittingForm: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      isFetchingClusters,
      isFetchingLocations,
      isFetchingServicePackages,
      isFetchingSubscriberServicePackages,
      orgId,
    } = this.props;

    if (isFetchingClusters === null) {
      actions.getClusters();
    }
    if (orgId && isFetchingLocations === null) {
      this.getLocationData(); // Everything you don't need additional information for
    }
    if (orgId && isFetchingServicePackages === null) {
      this.getServicePackages(this.props);
    }
    if (orgId && isFetchingSubscriberServicePackages === null) {
      actions.getSubscriberServicePackages(orgId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      clusters,
      isFetchingAccServers,
      isFetchingClusters,
      isFetchingLocations,
      isFetchingServicePackages,
      orgId,
    } = this.props;
    if (orgId !== prevProps.orgId || isFetchingLocations === null) {
      this.getLocationData();
    }
    if (isFetchingServicePackages === null) {
      this.getServicePackages(this.props);
    }

    if (
      (prevProps.clusters.length !== clusters.length && !isFetchingClusters) ||
      (prevProps.isFetchingClusters && !isFetchingClusters)
    ) {
      actions.refreshAllCameraLists(clusters);
      actions.getAllAccServerLists(clusters);
    } else if (
      clusters.length > 0 &&
      Object.keys(isFetchingAccServers).length === 0
    ) {
      actions.getAllAccServerLists(clusters);
    }
  }

  get enhancedLocations() {
    const {
      accCameras,
      accServers,
      dealerServicePackages,
      filteredItems,
      hasTableData,
      translate,
    } = this.props;
    let locations = [];
    if (translate !== undefined && hasTableData) {
      locations = getLocationsWithAccData(
        filteredItems,
        accServers,
        accCameras,
      );
      locations = tableUtils.locationTableData(
        locations,
        dealerServicePackages,
        translate,
      );
    }
    return locations;
  }

  getLocationData = () => {
    const { actions, currentOrg } = this.props;
    actions.getLocations(currentOrg.Id);
  };

  getServicePackages = props => {
    const { actions, isFetchingServicePackages } = props;
    const locations = props.items;
    const dealerId =
      (locations &&
        locations[0] &&
        locations[0].PackageSubscription &&
        locations[0].PackageSubscription.DealerId) ||
      (props.currentOrg && props.currentOrg.DealerId);
    if (!dealerId) {
      return null;
    }
    return (
      isFetchingServicePackages === null && actions.getServicePackages(dealerId)
    );
  };

  handleNavbarEditClick = () => {
    const { handleEditClick } = this.props;
    handleEditClick();
    ai.appInsights.trackEvent({ name: 'NavbarEditClick' });
  };

  onClickRow = locationData => {
    const { history } = this.props;

    history.push({
      pathname: `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}/${locationData.Id}/${defaultSiteDetailsTab}`,
      state: { siteId: locationData.Id },
    });
  };

  onAddSiteClick = () => {
    const { history } = this.props;
    history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}${PATH_SEGMENT_NEW}`);
  };

  handleDeleteClick = locationData => {
    const { actions } = this.props;
    actions.showModal(modalTypes.ADD_EDIT_LOCATION, {
      locationId: locationData.Id,
    });
  };

  handleSuspendClick = location => {
    const { actions, canEditSubscription, currentOrg } = this.props;
    handleSuspension(
      consts.SUSPEND,
      currentOrg,
      [location],
      canEditSubscription,
      actions,
    );
  };

  handleUnsuspendClick = location => {
    const { actions, canEditSubscription, currentOrg } = this.props;
    handleSuspension(
      consts.UNSUSPEND,
      currentOrg,
      [location],
      canEditSubscription,
      actions,
    );
  };

  handleDeleteClick = (idsToDelete, isACC) => {
    const { actions, canBulkDeleteLocations, items, profile } = this.props;
    if (!canBulkDeleteLocations && !isACC) {
      return;
    }
    const { clearBulkActionIdsFor } = this.context;
    const selectedLocations = idsToDelete.map(id =>
      items.find(location => location.Id === id),
    );
    const errorMessage = renderLocationDeleteErrors(selectedLocations, profile);

    if (errorMessage !== null) {
      const onOkClick = () => {
        actions.hideModal();
      };
      const modalProps = {
        handleCancel: () => {
          actions.hideModal();
        },
        message: errorMessage,
        onOkClick,
        title: <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_MODAL_HEADER" />,
      };
      actions.showModal(modalTypes.INFO_MODAL, modalProps);
    } else {
      const message = [];
      if (selectedLocations.length > 1) {
        message.push(
          <div key="deleteLocationConfirm">
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_LOCATIONS_CONFIRM_1" />
            <br />
            <br />
          </div>,
        );
        selectedLocations.forEach(site => {
          message.push(<li key={site.Id}>{site.Name}</li>);
        });
      } else {
        message.push(
          <div key="DeleteLocationConfirm">
            <Translate
              id="LOCATIONS.LOCATIONS_MODAL.DELETE_SINGLE_LOCATION_CONFIRM"
              data={{ locationName: selectedLocations[0].Name }}
            />
          </div>,
        );
      }
      message.push(
        <div key="deleteLocationConfirm2">
          <br />
          <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_LOCATIONS_CONFIRM_2" />
        </div>,
      );

      const onOkClick = () => {
        clearBulkActionIdsFor(BULK_ACTION_TABLES.LOCATIONS);
        actions.deleteLocations(idsToDelete);
        actions.hideModal();
      };

      const modalProps = {
        disabled: !!errorMessage,
        handleCancel: () => {
          actions.hideModal();
        },
        message,
        onOkClick,
        textConfirm: 'BUTTONS.DELETE',
        title: <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_MODAL_HEADER" />,
      };
      actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
    }
  };

  get locationRecords() {
    const {
      canBulkDeleteLocations,
      canDeleteLocation,
      canSuspendLocations,
      canViewBillingOrderId,
      currentOrg,
      orgId,
      preferredShortDateFormat,
      subscriberServicePackages,
    } = this.props;
    return (
      <LocationsTable
        access={canViewBillingOrderId}
        bulkActionsTable={BULK_ACTION_TABLES.LOCATIONS}
        canBulkDeleteLocations={canBulkDeleteLocations}
        canDeleteLocation={canDeleteLocation}
        canSuspendLocations={canSuspendLocations}
        currentOrg={currentOrg}
        handleDeleteClick={this.handleDeleteClick}
        handleSuspendClick={this.handleSuspendClick}
        handleUnsuspendClick={this.handleUnsuspendClick}
        locations={this.enhancedLocations}
        onRowClicked={this.onClickRow}
        orgId={orgId}
        preferredShortDateFormat={preferredShortDateFormat}
        servicePackages={subscriberServicePackages}
      />
    );
  }

  getModalGallery = () => {
    const { selectedId, selectedIds } = this.props;
    return selectedIds.length > 1 && selectedId !== null;
  };

  getUniqueValueOptionsList = options => uniqBy(options, 'value');

  render() {
    const {
      actions,
      canAddLocation,
      canChangeCustomer,
      currentOrg,
      fullPath,
      isFetchingAftData,
      isFetchingLocations,
      isFetchingServicePackages,
      items,
      profile,
    } = this.props;
    return (
      <>
        <SitesListNav
          actions={actions}
          allLocations={items}
          canChangeCustomer={canChangeCustomer}
          currentOrg={currentOrg}
          forceFilterToggle
          navigationTabs={<AccountNavMenu profile={profile} />}
          onAddClick={() => this.onAddSiteClick()}
          onDeleteClick={this.handleDeleteClick}
          pageTitle={
            <PageTitle
              title={<Translate id="SECONDARY_NAV.TABS.SITES.LABEL" />}
            />
          }
          path={fullPath}
          profile={profile}
        />
        <MainContentWrapper>
          <PageMessage
            messageType={[
              messageTypes.LOCATION_SUCCESS,
              messageTypes.LOCATION_ERROR,
            ]}
          />
          <EmptyPlaceholder
            isFetching={
              isFetchingLocations ||
              isFetchingServicePackages ||
              isFetchingAftData ||
              false
            }
            items={this.enhancedLocations}
            translateKey={
              canAddLocation && items.length === 0
                ? 'LOCATIONS.NO_SITES_ADD_SITE'
                : 'USERS.ADD_USER_MODAL.NO_SITE_ACCESS'
            }
          >
            {this.locationRecords}
          </EmptyPlaceholder>
        </MainContentWrapper>
      </>
    );
  }
}

SitesContainer.contextType = BulkActionContext;

SitesContainer.defaultProps = {
  locations: [],
  preferredShortDateFormat: '',
  selectedId: null,
  selectedIds: [],
  users: [],
};

function mapStateToProps(state) {
  const hasTableData = !!(
    state.locations.locations &&
    state.locations.locations.length > 0 &&
    state.locations.dealerServicePackages
  );

  const orgId = permissions.getOrgIdFromStore(state);

  // AFT-related data and state

  const accCameras = state.aft;
  const accServers = state.accServerLists;

  const isFetchingClusters = state.isFetching.getClusters;

  // const aftCameraFetch = state.isFetching.getAftCameraList;
  // const isFetchingAftCameras = Object.keys(aftCameraFetch).some(
  //   clusterId => aftCameraFetch[clusterId] === true,
  // );
  // Commenting out so we can watch the numbers go up while aft cameras load

  const aftServerFetch = state.isFetching.getAftServerList;
  const isFetchingAftServers = Object.keys(aftServerFetch).some(
    clusterId => aftServerFetch[clusterId] === true,
  );

  const isFetchingAftData = isFetchingClusters || isFetchingAftServers;

  return {
    accCameras,
    accServers,
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canBulkDeleteLocations: state.user.permissions.CAN_BULK_DELETE_SITES,
    canDeleteLocation: state.user.permissions.CAN_DELETE_SITE,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    canSuspendLocations: state.user.permissions.CAN_SUSPEND_SITE,
    canViewBillingOrderId: state.user.permissions.CAN_VIEW_BILLING_ORDER_ID,
    clusters: state.clusters.clusters,
    currentOrg: state.user.currentOrganization
      ? state.user.currentOrganization
      : state.user.selectedOrganization,
    dealerServicePackages: state.locations.dealerServicePackages,
    filterGroup: FILTER_GROUPS.SITES,
    filterStatus: state.filters.filterStatus,
    hasTableData,
    isFetchingAccServers: state.isFetching.getAftServerList,
    isFetchingAftData,
    isFetchingClusters,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    isFetchingSubscriberServicePackages:
      state.isFetching.getSubscriberServicePackages,
    items: state.locations.locations,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_EDIT_LOCATION,
    orgId,
    preferredShortDateFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
    profile: state.user.profile,
    selectedId: state.modal.modalProps.locationId,
    subscriberServicePackages: state.user.subscriberServicePackages,
    timezones: state.utilities.timezones,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...AftClientActions,
        ...ClusterActions,
        ...LocationActions,
        ...UserActions,
        ...MenuActions,
        ...FilterActions,
        ...SubscriptionActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  withLocalize(
    withAITracking(ai.reactPlugin, SitesContainer, 'SitesContainer'),
  ),
  mapStateToProps,
  mapDispatchToProps,
  modalTypes.ADD_EDIT_LOCATION, // modal name)
);
