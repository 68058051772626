import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// Com ponents
import { Translate } from 'react-localize-redux';

// Constants
import * as inputConsts from './constants';

// Styles
import { durationInputFormGroup, durationInputLabel } from './styles.css';

class MinuteDurationInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      duration: props.initialDuration,
      error: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ duration: nextProps.initialDuration });
  }

  /*
   * debounce() waits for events to bubble. After a timeout
   * if no new events is received, it performs the action.
   */
  handleChange = debounce(() => {
    const { duration } = this.state;
    const { maxDuration, minDuration } = this.props;
    if (maxDuration && maxDuration < duration) {
      this.setState(
        { duration: maxDuration, error: this.props.errorString },
        () => this.props.onChange(this.state.duration),
      );
    } else if (minDuration && minDuration > duration) {
      this.setState(
        { duration: minDuration, error: this.props.errorString },
        () => this.props.onChange(this.state.duration),
      );
    } else {
      this.props.onChange(this.state.duration);
    }
  }, 300);

  handleChangeMinutes = e => {
    const { isMilliseconds } = this.props;
    const newMinutes = parseInt(e.target.value) || 0;
    const duration = isMilliseconds ? newMinutes * 1000 * 60 : newMinutes;
    this.setState({ duration }, this.handleChange);
  };

  render() {
    const { maxDuration, minDuration, isMilliseconds, disabled } = this.props;
    const { duration } = this.state;
    const minuteMax = isMilliseconds ? maxDuration / 1000 / 60 : maxDuration;
    const minuteMin = Math.floor(
      isMilliseconds ? minDuration / 1000 / 60 : minDuration / 60,
    );
    let minuteVal = Math.floor(
      isMilliseconds ? duration / 1000 / 60 : duration,
    );

    if (minuteVal > 0) {
      minuteVal = minuteVal.toString().replace(/^0+/, '');
    }

    return (
      <div className={durationInputFormGroup}>
        <input
          id={`${inputConsts.idMinInput}_${this.props.id}`}
          type="number"
          value={minuteVal}
          onChange={this.handleChangeMinutes}
          onFocus={this.handleFocus}
          min={minuteMin}
          max={minuteMax}
          disabled={disabled}
        />
        <div className={durationInputLabel}>
          <Translate id="UNITS.MINUTES_ABBREV" />
        </div>
      </div>
    );
  }
}

MinuteDurationInput.defaultProps = {
  id: '',
  initialDuration: 0,
  maxDuration: undefined,
  minDuration: undefined,
  errorString: '',
  isMilliseconds: false,
  disabled: false,
};

MinuteDurationInput.propTypes = {
  id: PropTypes.string,
  initialDuration: PropTypes.number,
  maxDuration: PropTypes.number,
  minDuration: PropTypes.number,
  errorString: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMilliseconds: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MinuteDurationInput;
