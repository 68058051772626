import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';

// Components
import { FilterTranslated, InputFilter } from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { PageMessage } from 'containers';
import AccountNavMenu from '../AccountNavMenu';

// Actions
import * as ContactActions from 'actions/contacts';
import * as LocationActions from 'actions/locations';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';

// Utilities
import * as permissions from 'util/permissions';
import FilterableContainer from 'util/FilterableContainer';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import {
  ACTION_IMAGES,
  BULK_ACTION_TABLES,
  FILTER_GROUPS,
} from 'constants/app';
import ContactsTable from './ContactsTable/ContactsTable';

// Style
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';
import { filterWrapper } from '../styles.css';

import {
  filters,
  idContactLocationFilter,
  idContNavHeader,
  idSearchInput,
  SEARCH_PLACEHOLDER,
  contactsTextInputFields as textInputFields,
} from '../constants';

// Contact Containter responsible for contacts CRUD funcationality
class ContactsContainer extends FilterableContainer {
  // eslint-disable-next-line react/static-property-placement
  static contextType = BulkActionContext;

  constructor(props) {
    super(props);
    this.state = {
      queryOptions: {},
      submittingForm: false,
    };
  }

  // TODO: Improve= Check if data already available

  componentDidMount() {
    const { actions, isFetchingContacts, locations, orgId } = this.props;
    const { queryOptions } = this.state;
    if (isFetchingContacts === null && orgId) {
      actions.getContacts(queryOptions);
      if (!locations || locations.length === 0) {
        actions.getLocations();
      }
    }
  }

  componentDidUpdate() {
    const { actions, isFetchingContacts, isFetchingLocations } = this.props;
    const { queryOptions } = this.state;
    if (isFetchingLocations === null) {
      actions.getLocations();
    }
    if (isFetchingContacts === null) {
      actions.getContacts(queryOptions);
    }
  }

  get selectedContactIds() {
    return this.context.bulkActionIdsFor(BULK_ACTION_TABLES.CONTACTS) || [];
  }

  onClickRow = contactData => {
    this.props.history.push(`/accounts/contacts/${contactData.Id}`);
  };

  handleDeleteClick = selectedIds => {
    const { actions, items } = this.props;
    const { queryOptions } = this.state;
    const { clearBulkActionIdsFor } = this.context;
    const message = [
      <div key="deleteContactConfirm">
        <Translate id="CONTACTS.CONTACTS_MODAL.DELETE_CONTACTS_CONFIRM_1" />
        <br />
        <br />
      </div>,
    ];
    const names = selectedIds
      .map(id => items.find(location => location.Id === id).Name)
      .map(name => <li>{name}</li>);
    message.push(names);
    message.push(
      <div key="deleteContactConfirm2">
        <Translate id="CONTACTS.CONTACTS_MODAL.DELETE_CONTACTS_CONFIRM_2" />
      </div>,
    );
    const onOkClick = () => {
      actions.deleteContacts(selectedIds, queryOptions);
      clearBulkActionIdsFor(BULK_ACTION_TABLES.CONTACTS);
      actions.hideModal();
    };
    const modalProps = {
      handleCancel: () => {
        actions.hideModal();
      },
      message,
      onOkClick,
      title: <Translate id="CONTACTS_TABLE.MODAL.DELETE_CONTACT_TITLE" />,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  handleFilter = (field, values) => {
    const { handleFilter } = this.props;
    handleFilter(field, values);
  };

  // Override the behavior of NavigationWrapper to navigate to a route instead of opening a modal
  handleAddClick = () => {
    this.props.history.push(`/accounts/contacts/new`);
  };

  processContacts = (contacts, locations) => {
    if (contacts && locations) {
      return contacts.map(contact => {
        let sites = [];
        if (contact.Sites) {
          sites = contact.Sites.map(site => {
            return locations.find(loc => loc.Id === site);
          });
        }
        return {
          contact,
          sites,
        };
      });
    }
    return [];
  };

  render() {
    const {
      canChangeCustomer,
      filterValues,
      filteredItems,
      fullPath,
      handleEditClick,
      isFetchingContacts,
      locations,
      profile,
    } = this.props;

    const processedContacts = this.processContacts(filteredItems, locations);
    return (
      <>
        <ListNav
          actionImage={ACTION_IMAGES.CONTACT}
          bulkActionsTable={BULK_ACTION_TABLES.CONTACTS}
          canAdd
          canChangeCustomer={canChangeCustomer}
          canDelete
          deleteActive={this.selectedContactIds.length > 0}
          id={idContNavHeader}
          navigationTabs={<AccountNavMenu profile={profile} />}
          onAddClick={this.handleAddClick}
          onDeleteClick={this.handleDeleteClick}
          onViewClick={handleEditClick}
          pageTitle={<PageTitle titleId="CONTACTS_TABLE.TITLE" />}
          viewActive={this.selectedContactIds.length > 0}
        >
          <div className={filterWrapper}>
            <InputFilter
              field={textInputFields}
              id={idSearchInput}
              onChange={this.handleFilter}
              overrideValue
              placeholder={SEARCH_PLACEHOLDER}
              value={this.getFilterValue(filters.contacts.name.field)}
            />
          </div>
          <div className={filterWrapper}>
            <FilterTranslated
              field={filters.contacts.site.field}
              id={idContactLocationFilter}
              label={filters.contacts.site.label}
              onChange={this.handleFilter}
              options={filterValues.location}
              translateLabel
              value={this.getFilterValue(filters.contacts.site.field)}
            />
          </div>
        </ListNav>
        <MainContentWrapper>
          <PageMessage
            messageType={[
              messageTypes.CONTACT_ERROR,
              messageTypes.CONTACT_SUCCESS,
            ]}
          />
          <EmptyPlaceholder
            isFetching={isFetchingContacts}
            items={processedContacts}
            string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
          >
            <ContactsTable
              contacts={processedContacts}
              onDeleteClick={this.handleDeleteClick}
              onRowClicked={this.onClickRow}
              rowActions
              rowsSelected={this.context.bulkActionIds.contacts}
              string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
            />
          </EmptyPlaceholder>
        </MainContentWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    filterGroup: FILTER_GROUPS.CONTACTS,
    filterStatus: state.filters.filterStatus,
    filterValues: state.filters.users,
    isFetchingContacts: state.isFetching.getContacts,
    isFetchingLocations: state.isFetching.getLocations,
    items: state.contacts.contacts,
    locations: state.locations.locations,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_EDIT_CONTACT,
    orgId,
    selectedContactId: state.modal.modalProps.contactId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...ContactActions,
        ...LocationActions,
        ...FilterActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  ContactsContainer,
  mapStateToProps,
  mapDispatchToProps,
  modalTypes.ADD_EDIT_CONTACT,
  { textInputFields },
);
