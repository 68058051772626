import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dimensions from 'react-dimensions';
import { Redirect, Route, Switch } from 'react-router-dom';

// Components
import {
  AccountContainer,
  ChangeProviderFormContainer,
  ContactDetailContainer,
  ContactsContainer,
  DealerDetailsContainer,
  MonitoringConfigDetailContainer,
  MonitoringContainer,
  OrganizationCustomerContainer,
  SchedulesContainer,
  ServicePlansContainer,
  SitesContainer,
  SiteDetailContainer,
  SubscriberDetailsContainer,
  UserDetailContainer,
  UsersTableContainer,
} from 'containers';

// Utilities
import * as PATH from 'constants/urlPaths';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';
import getPathName, {
  getAccountSubRoute,
  getCurrentPath,
  getFullPath,
} from 'util/getPathName';

// Styles
import { contentContainer } from 'sharedStyles/global.css';

class OrganizationsContainer extends Component {
  componentDidMount() {
    this.pushCurrentPath();
  }

  componentDidUpdate() {
    this.pushCurrentPath();
  }

  get currentPath() {
    const { fullPath, path, profile } = this.props;
    return getCurrentPath(fullPath, path, profile, getAccountSubRoute);
  }

  pushCurrentPath() {
    const { fullPath, history } = this.props;
    if (!fullPath.includes(this.currentPath)) {
      history.push(this.currentPath);
    }
  }

  // by default, react will not re-render child details page that does not have props change, even if route changes
  // so bind child details page to route so that is refreshed
  renderChildren() {
    const renderOCC = props => (
      <OrganizationCustomerContainer {...this.props} {...props} />
    );

    return (
      <Switch>
        <Route
          exact
          path={PATH.PATH_ACCOUNTS}
          render={() => <Redirect to={this.currentPath} />}
        />
        <Route
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_GENERAL}
          render={props => <AccountContainer {...props} {...this.props} />}
        />
        <Route
          exact
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_USERS}
          render={props => <UsersTableContainer {...props} {...this.props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_USERS}/:userid`}
          render={props => <UserDetailContainer {...props} {...this.props} />}
        />
        <Route
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_SCHEDULES}
          render={props => <SchedulesContainer {...props} {...this.props} />}
        />
        <Route
          exact
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_SITES}
          render={props => <SitesContainer {...props} {...this.props} />}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SITES}/:siteId${PATH.PATH_CHANGE_PROVIDER}`}
          render={props => <ChangeProviderFormContainer {...props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SITES}/:siteId`}
          render={props => <SiteDetailContainer {...props} />}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}/:subscriberId${PATH.PATH_SEGMENT_SITES}/:siteId${PATH.PATH_CHANGE_PROVIDER}`}
          render={props => <ChangeProviderFormContainer {...props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}/:subscriberId${PATH.PATH_SEGMENT_SITES}/:siteId`}
          render={props => <SiteDetailContainer {...props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}/:subscriberId${PATH.PATH_SEGMENT_SITES}${PATH.PATH_SEGMENT_NEW}`}
          render={props => <SiteDetailContainer {...props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SITES}${PATH.PATH_SEGMENT_NEW}`}
          render={props => <SiteDetailContainer {...props} />}
        />

        <Route
          exact
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_CONTACTS}
          render={props => <ContactsContainer {...props} {...this.props} />}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_CONTACTS}/:contactid`}
          render={props => (
            <ContactDetailContainer {...props} {...this.props} />
          )}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_MONITORING}${PATH.PATH_SEGMENT_MONITORING_CENTERS}/:configid`}
          render={props => (
            <MonitoringConfigDetailContainer {...props} {...this.props} />
          )}
        />
        <Route
          path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_MONITORING}
          render={props => <MonitoringContainer {...props} {...this.props} />}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}`}
          render={renderOCC}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SERVICE_PLANS}`}
          render={props => (
            <ServicePlansContainer {...props} {...this.props} isAdding />
          )}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}${PATH.PATH_SEGMENT_NEW}`}
          render={props => (
            <SubscriberDetailsContainer {...props} {...this.props} isAdding />
          )}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_NEW}`}
          render={props => (
            <SubscriberDetailsContainer {...props} {...this.props} isAdding />
          )}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}/:subscriberId`}
          render={renderOCC}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}`}
          render={renderOCC}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}${PATH.PATH_SEGMENT_NEW}`}
          render={props => (
            <DealerDetailsContainer {...props} {...this.props} isAdding />
          )}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_SUBSCRIBERS}`}
          render={renderOCC}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_SERVICE_PLANS}`}
          render={props => (
            <DealerDetailsContainer {...props} {...this.props} />
          )}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_INFO}`}
          render={props => (
            <DealerDetailsContainer {...props} {...this.props} />
          )}
        />
        <Route
          exact
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_SUBSCRIBERS}`}
          render={renderOCC}
        />
        <Route
          path={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_DEALERS}/:dealerId${PATH.PATH_SEGMENT_SUBSCRIBERS}/:subscriberId`}
          render={props => (
            <DealerDetailsContainer {...props} {...this.props} />
          )}
        />
      </Switch>
    );
  }

  render() {
    return <>{this.renderChildren()}</>;
  }
}

OrganizationsContainer.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
};

OrganizationsContainer.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  fullPath: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state, ownProps) {
  // this component can be used within either the /accounts or /devices route
  // need to split b/c pathname can be /devices/foo/123
  const path = getPathName(ownProps.location.pathname);
  const fullPath = getFullPath(ownProps.location.pathname);
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    canViewCustomersTab: state.user.permissions.CAN_VIEW_CUSTOMERS,
    fullPath,
    path,
    profile: state.user.profile,
  };
}

export default connect(
  mapStateToProps,
  null,
)(
  Dimensions({ elementResize: true })(
    withAITracking(
      ai.reactPlugin,
      OrganizationsContainer,
      'OrganizationsContainer',
      contentContainer,
    ),
  ),
);
