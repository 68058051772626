/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { Button } from 'lib';

import {
  ACTION_IMAGES,
  getClickHandler,
  idAddContactBtn,
  idAddOrgBtn,
  idChangeProviderBtn,
  tenantTypeStrings,
} from '../constants';

// TODO: Img to SVG
function AddControl(props) {
  const { actionImage, canAdd, isDisabled, onAddClick, translate } = props;
  let resultComponent = null;
  const onClick = canAdd ? onAddClick : () => null;
  const tenantType =
    tenantTypeStrings[actionImage] || tenantTypeStrings.Subscriber;
  switch (actionImage) {
    case ACTION_IMAGES.LOCATION:
      resultComponent = (
        <Button
          buttonType="primary"
          id={idAddContactBtn}
          inputType="button"
          onClick={onClick}
          text={translate('BUTTONS.ADD_SITE')}
        />
      );
      break;
    case ACTION_IMAGES.SITE_SUBSCRIPTIONS:
      resultComponent = (
        <Button
          buttonType="primary"
          disabled={isDisabled}
          id={idChangeProviderBtn}
          inputType="button"
          onClick={onClick}
          text={translate('BUTTONS.CHANGE_PROVIDER')}
        />
      );
      break;
    case ACTION_IMAGES.SUBSCRIBER:
    case ACTION_IMAGES.DEALER:
    case ACTION_IMAGES.DEALER_SELECTED: {
      const tenantTypeMap = {
        Dealer: 'provider',
        Provider: 'provider',
        Subscriber: 'customer',
      };
      resultComponent = (
        <Button
          id={idAddOrgBtn}
          inputType="button"
          onClick={getClickHandler(canAdd, onAddClick)}
          text={translate('BUTTONS.ADD_ORGANIZATION', {
            tenantType: translate(
              `TENANTS.${tenantTypeMap[tenantType].toUpperCase()}`,
            ),
          })}
        />
      );
      break;
    }
    case ACTION_IMAGES.USER:
    case ACTION_IMAGES.CONTACT:
      resultComponent = (
        <Button
          id={idAddContactBtn}
          inputType="button"
          onClick={onClick}
          text={translate(`BUTTONS.ADD_${actionImage.toUpperCase()}`)}
        />
      );
      break;
    case ACTION_IMAGES.SCHEDULE:
    case ACTION_IMAGES.NOTIFICATION:
      resultComponent = '';
      break;
    default:
  }
  return resultComponent;
}

AddControl.defaultProps = {
  actionImage: '',
  canAdd: null,
  isDisabled: false,
  onAddClick() {},
  translate: () => {},
};

AddControl.propTypes = {
  actionImage: PropTypes.string,
  canAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  onAddClick: PropTypes.func,
  translate: PropTypes.func,
};

export default withLocalize(AddControl);
