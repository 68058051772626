import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconChevron = props => {
  return (
    <svg
      title={props.title}
      width={props.width}
      height={props.height}
      className={props.className}
      viewBox="0 0 11 12"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          id="a_chevron"
          d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
        />
        <path id="d_chevron" d="M0 0h24v24H0z" />
        <filter
          id="c_chevron"
          width="158.3%"
          height="158.3%"
          x="-29.2%"
          y="-20.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-8 -6)">
        <path d="M0 0h24v24H0z" />
        <mask id="b_chevron" fill="#fff">
          <use xlinkHref="#a_chevron" />
        </mask>
        <g mask="url(#b_chevron)">
          <use fill="#000" filter="url(#c_chevron)" xlinkHref="#d_chevron" />
          <use fill="#FFF" xlinkHref="#d_chevron" />
        </g>
      </g>
    </svg>
  );
};

IconChevron.defaultProps = {
  title: '',
  className: null,
  width: '16px',
  height: '16px',
  fillColor: '#FFFFFF',
};

export default IconChevron;
