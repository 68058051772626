import React from 'react';
import { Translate } from 'react-localize-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { FieldInput } from 'components/FormElements';
import { FieldSelect, FormGroup } from 'lib';

// Containers
import TimeZoneSelect from 'containers/TimeZoneSelect/TimeZoneSelectContainer';

// Utils
import * as validation from 'util/validation';

// Styles
import { formReadOnlyText } from 'sharedStyles/modalForms.css';
import { sectionHeader } from 'components/VerticalForm/styles.css';

// Constants
import { countries } from 'constants/dropdownContent';
import * as PlaceHolders from 'constants/PlaceHolders';
import * as LocationFormConsts from '../constants';

// Styles
import { formColumn, formField } from '../styles.css';

// Fields
import NonUsaAddressFields from './NonUsaAddressFields';
import UsaAddressFields from './UsaAddressFields';

const AddressFieldSet = props => {
  const { country, readOnly, timezone } = props;
  return (
    <fieldset className={formColumn} disabled={readOnly}>
      <div className={sectionHeader}>
        <Translate id="GENERAL_MESSAGES.ADDRESS_HEADER" />
      </div>
      <FormGroup
        id={LocationFormConsts.idCountry}
        label={<Translate id="ADDRESS.COUNTRY" />}
      >
        {readOnly ? (
          <div className={formReadOnlyText}>{country}</div>
        ) : (
          <Field
            className={formField}
            component={FieldSelect}
            name={LocationFormConsts.nameCountry}
          >
            {countries.map(c => (
              <Translate key={c.code}>
                {({ translate }) => (
                  <option value={c.dbValue}>{translate(c.name)}</option>
                )}
              </Translate>
            ))}
          </Field>
        )}
      </FormGroup>
      <FormGroup
        id={LocationFormConsts.idStreet}
        label={<Translate id="ADDRESS.STREET_ONE" />}
      >
        <Field
          className={formField}
          component={FieldInput}
          disabled={readOnly}
          isNewVersion
          name={LocationFormConsts.nameStreet}
          translate
          type="text"
          validate={validation.required}
        />
      </FormGroup>
      <FormGroup
        id={LocationFormConsts.idStreet2}
        label={<Translate id="ADDRESS.STREET_TWO" />}
      >
        <Field
          className={formField}
          component={FieldInput}
          disabled={readOnly}
          isNewVersion
          name={LocationFormConsts.nameStreet2}
          translate
          type="text"
        />
      </FormGroup>
      {/* TODO: MVAAS-8114 - Update this comparison when the options are translated and there is a new way for how the data is persisted on the data base */}
      {country === LocationFormConsts.USA ? (
        <UsaAddressFields disabled={readOnly} />
      ) : (
        <NonUsaAddressFields disabled={readOnly} />
      )}
      <FormGroup
        id={LocationFormConsts.idTimezone}
        label={<Translate id="ADDRESS.TIMEZONE" />}
      >
        {readOnly ? (
          <div className={formReadOnlyText}>{timezone}</div>
        ) : (
          <Field
            className={formField}
            component={TimeZoneSelect}
            disabled={readOnly}
            name={LocationFormConsts.nameTimeZone}
            translate
            validate={validation.required}
          />
        )}
      </FormGroup>
      <FormGroup
        id={LocationFormConsts.idPhone}
        label={<Translate id="ADDRESS.PHONE_NUMBER" />}
      >
        <Field
          className={formField}
          component={FieldInput}
          disabled={readOnly}
          isNewVersion
          name={LocationFormConsts.namePhone}
          placeholder={PlaceHolders.PhoneNumber}
          translate
          type="text"
          validate={validation.required}
        />
      </FormGroup>
    </fieldset>
  );
};

AddressFieldSet.defaultProps = {
  country: undefined,
  timezone: undefined,
};

AddressFieldSet.propTypes = {
  country: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  timezone: PropTypes.string,
};

export default AddressFieldSet;
