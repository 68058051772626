import React from 'react';
import { FieldInput } from 'components';

const FieldInputWithOnBlur = props => {
  const {
    input: { onBlur, ...input },
    normalizeOnBlur,
  } = props;
  const enterKeyListener = event => {
    if (event.keyCode === 13) {
      const value =
        event &&
        event.target &&
        Object.prototype.hasOwnProperty.call(event.target, 'value')
          ? event.target.value
          : event;
      const newValue = normalizeOnBlur ? normalizeOnBlur(value) : value;
      input.onChange(newValue);
    }
  };
  return (
    <FieldInput
      {...props}
      input={{
        ...input,
        onKeyDown: event => {
          enterKeyListener(event);
          if (input.onKeyDown) {
            input.onKeyDown(event);
          }
        },
        onBlur: event => {
          const value =
            event &&
            event.target &&
            Object.prototype.hasOwnProperty.call(event.target, 'value')
              ? event.target.value
              : event;
          const newValue = normalizeOnBlur ? normalizeOnBlur(value) : value;
          onBlur(newValue);
        },
      }}
    />
  );
};

export default FieldInputWithOnBlur;
