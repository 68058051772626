import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconToggleZoomMode = props => {
  return (
    <div
      className={props.className}
      title={props.translate('PTZ.BUTTONS.ZOOM_IN_OUT')}
    >
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path
            d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1zm3.5 4l4.99 5L19 20.49l-5-4.99v-.79l-.27-.28A6.471 6.471 0 0 1 9.5 16 6.5 6.5 0 1 1 16 9.5c0 1.61-.59 3.09-1.57 4.23l.28.27h.79zm-6 0c2.49 0 4.5-2.01 4.5-4.5S11.99 5 9.5 5 5 7.01 5 9.5 7.01 14 9.5 14z"
            id="ab"
          />
          <path id="db" d="M0 0h24v24H0z" />
          <filter
            x="-29.2%"
            y="-20.8%"
            width="158.3%"
            height="158.3%"
            filterUnits="objectBoundingBox"
            id="cb"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <mask id="bb" fill="#fff">
            <use xlinkHref="#ab" />
          </mask>
          <g mask="url(#bb)">
            <use fill="#000" filter="url(#cb)" xlinkHref="#db" />
            <use fill="#FFF" xlinkHref="#db" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconToggleZoomMode.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
};

export default withLocalize(IconToggleZoomMode);
