import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import getVersionInfo from 'util/getVersionInfo';
import { isHidden } from 'sharedStyles/isState.css';
import { APP_NAME } from 'constants/app';
import { connect } from 'react-redux';
import { getActiveLanguageCode } from 'util/languagesUtils';
import { feedbackLink, feedbackContainer } from './styles.css';

const idReportBug = 'reportFeedback';
const uservoiceId = 'uservoiceScript';
const { userAgent } = navigator;

class FeedbackLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uservoiceLoaded: false,
      actualLanguage: getActiveLanguageCode() || 'en',
    };
  }

  componentDidMount() {
    // Adds uservoice javascript if it does not already exist
    if (!document.getElementById(uservoiceId)) {
      const uv = document.createElement('script');
      uv.id = uservoiceId;
      uv.type = 'text/javascript';
      uv.async = true;
      uv.src = '//widget.uservoice.com/q4anxi0rk5ZVxnwoGaw.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(uv, s);
    }
  }

  get classes() {
    const showHide = this.props.fullScreen ? isHidden : '';
    return `${feedbackLink} ${showHide}`;
  }

  loadUservoice = () => {
    // Just-in-time configuration for uservoice
    if (
      window.UserVoice &&
      (!this.state.uservoiceLoaded ||
        this.state.actualLanguage !== getActiveLanguageCode())
    ) {
      window.UserVoice.push([
        'set',
        {
          smartvote_enabled: false,
          post_suggestion_enabled: false,
          contact_enabled: true,
          locale: getActiveLanguageCode() || 'en', // TODO-MVAAS-12879: Change it to the user's language on Blue v3.0. Note: If we send a not supported locale, the default fallback for it inside the userVoice is en
          ticket_custom_fields: {
            'App Name': APP_NAME,
            Version: getVersionInfo(),
            'Environment Data': userAgent,
          },
        },
      ]);
      window.UserVoice.push([
        'identify',
        {
          email: this.props.userEmail, // User’s email address
        },
      ]);
      window.UserVoice.push([
        'addTrigger',
        `#${idReportBug}`,
        { mode: 'contact' },
      ]);
      this.setState({
        uservoiceLoaded: true,
        actualLanguage: getActiveLanguageCode(),
      });
    }
  };

  render() {
    return (
      <div className={feedbackContainer}>
        <a
          id={idReportBug}
          className={this.classes}
          href="javascript:void(0)"
          onMouseEnter={this.loadUservoice}
        >
          <Translate id="FEEDBACK.BUTTON" />
        </a>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    fullScreen: state.views.isCurrentViewMaximized,
  };
}
export default connect(mapStateToProps)(FeedbackLink);
