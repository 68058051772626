import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import IconLogo from 'icons/IconAvigilonLogo';

// Style
import { logo, logoLink } from './logo.css';

// Icons

const Logo = ({ fontColor }) => {
  return (
    <Link className={logoLink} to="/">
      <div className={logo}>
        <IconLogo fontColor={fontColor} />
      </div>
    </Link>
  );
}

Logo.propTypes = {
  fontColor: PropTypes.string,
};

Logo.defaultProps = {
  fontColor: undefined,
};

export default Logo;
