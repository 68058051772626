import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Icon, Tag, Tooltip } from 'lib';

// Constants
import { SERVICE_FAMILIES } from 'constants/app';
import * as accordionTypes from 'constants/AccordionTypes';
import {
  IC_AGENCY_BUILDING,
  IC_CAMERA_MOBILE,
  IC_SERVER,
} from 'constants/iconNames';

// Components
import { PersistentAccordionItem, Spinner, SubscriptionStateNotice } from '..';

// Utils
import { extractItemCountFromMap } from 'util/tableData';

// Icons
import { IconInfoCircle } from 'icons';

// Styles
import {
  accLocationNotice,
  accLocationNoticeIcon,
  firmwareUpgradeIndicator,
  iconStyle,
  locationBarRight,
  locationColumn,
  upgradeButton,
} from './styles.css';

export default class DeviceLocationWrapper extends Component {
  handleUpgradeClick = event => {
    const { onUpgradeClick } = this.props;
    event.stopPropagation();
    onUpgradeClick();
  };

  isUpgradeable = siteIds => {
    const { location } = this.props;
    return siteIds.find(siteId => {
      return siteId === location.Id;
    });
  };

  showUpgradeButton = () => {
    const { siteIdsWithUpgradableDevices, subscriptionState } = this.props;
    return (
      this.isUpgradeable(
        siteIdsWithUpgradableDevices.map(item => item.SiteId),
      ) && subscriptionState.isValid
    );
  };

  getAccordionStats = (location, accCameras, accServers) => {
    let cameraCount = 0;
    let serverCount = 0;

    if (location.PackageSubscription.ServiceFamily === SERVICE_FAMILIES.AccCloudServices) {
      const cameraClusters = accCameras[location.Id];
      const serverClusters = accServers[location.Id];

      if (cameraClusters) {
        const connectedCameras = Object.keys(cameraClusters).map(clusterId => {
          return cameraClusters[clusterId].filter(cam => cam.active);
        });

        cameraCount = extractItemCountFromMap(connectedCameras);
      }

      if (serverClusters) {
        serverCount = extractItemCountFromMap(serverClusters);
      }
    } else {
      serverCount = location.DeviceCount;
      cameraCount = location.CameraCount;
    }

    return [
      {
        count: serverCount,
        icon: <Icon id={IC_SERVER} renderBare />,
        label: 'DEVICES.DEVICE_LOCATION_WRAPPER.DEVICE_SERVERS_LABEL',
      },
      {
        count: cameraCount,
        icon: <Icon id={IC_CAMERA_MOBILE} renderBare />,
        label: 'COMMON.CAMERAS',
      },
    ];
  };

  getUpgradeTagContent = upgradingFirmware => {
    let upgradeTagContent = null;

    if (upgradingFirmware) {
      upgradeTagContent = (
        <div className={firmwareUpgradeIndicator}>
          <Spinner size={20} />
        </div>
      );
    } else if (this.showUpgradeButton()) {
      upgradeTagContent = (
        <Tag
          className={upgradeButton}
          label="DEVICES.DEVICE_LOCATION_WRAPPER.DEVICE_UPGRADE_LABEL"
          tagType="emergency"
        />
      );
    }

    return upgradeTagContent;
  };

  render() {
    const { index, location } = this.props;
    const {
      accCameras,
      accServers,
      children,
      disableSites,
      retrieveSiteDevicesAndCameras,
      subscriptionState,
      translate,
      upgradingFirmware,
    } = this.props;

    const upgradeTag = (
      <div className={locationBarRight}>
        {this.getUpgradeTagContent(upgradingFirmware)}
      </div>
    );

    const [serverStats, cameraStats] = this.getAccordionStats(
      location,
      accCameras,
      accServers,
    );

    const tooltip = () => {
      if (
        location.PackageSubscription.ServiceFamily === SERVICE_FAMILIES.AccCloudServices &&
        (cameraStats.count > 0 || serverStats.count > 0)
      ) {
        return (
          <div className={accLocationNotice}>
            <Tooltip
              message={translate(
                'DEVICES.DEVICE_LOCATION_WRAPPER.ACC_LOCATION_NOTICE',
              )}
              toggleMethod="click"
              width={130}
            >
              <div className={accLocationNoticeIcon}>
                <IconInfoCircle height={10} width={10} />
              </div>
            </Tooltip>
          </div>
        );
      }

      return null;
    };

    const notice = (
      <div className={locationColumn}>
        {upgradeTag}
        <SubscriptionStateNotice subscriptionState={subscriptionState} />
        {tooltip()}
      </div>
    );

    return (
      <PersistentAccordionItem
        key={location.Id}
        ref={cd => (this.accordion = cd)}
        accordionType={accordionTypes.DEVICES}
        disabled={disableSites || location.DeviceCount < 1}
        item={location}
        leftIcon={
          <>
            <Icon
              iconClass={iconStyle}
              id={IC_AGENCY_BUILDING}
              title="COMMON.SITE"
            />
          </>
        }
        loadExpanded={
          index === 0 &&
          location.DeviceCount < 50 && // For now, larger sites must be manually opened
          location.DeviceCount > 0
        }
        nameField="Name"
        notice={notice}
        retrieveContainedData={retrieveSiteDevicesAndCameras}
        stats={[serverStats, cameraStats]}
      >
        {children}
      </PersistentAccordionItem>
    );
  }
}

DeviceLocationWrapper.defaultProps = {
  accCameras: [],
  accServers: [],
  children: null,
  disableSites: null,
  index: '',
  onUpgradeClick: () => {},
  retrieveSiteDevicesAndCameras: () => {},
  siteIdsWithUpgradableDevices: [],
  subscriptionState: {},
  upgradingFirmware: null,
};

const accCameraPtzInfoShape = PropTypes.shape({
  auxChannels: PropTypes.shape({
    max: PropTypes.number,
    min: PropTypes.number,
  }),
  capabilities: PropTypes.arrayOf(PropTypes.string),
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
});

const accCameraConnectionStatusShape = PropTypes.shape({
  errorFlags: PropTypes.arrayOf(PropTypes.string),
  isConnectable: PropTypes.bool,
  startTime: PropTypes.string,
  state: PropTypes.string,
});

const accCameraCapabilitiesShape = PropTypes.shape({
  acquisition: PropTypes.arrayOf(PropTypes.string),
  analytic: PropTypes.arrayOf(PropTypes.string),
  audio: PropTypes.arrayOf(PropTypes.string),
  compression: PropTypes.arrayOf(PropTypes.string),
  digitalIo: PropTypes.arrayOf(PropTypes.string),
  exposure: PropTypes.arrayOf(PropTypes.string),
  general: PropTypes.arrayOf(PropTypes.string),
  h264: PropTypes.arrayOf(PropTypes.string),
  mjpeg: PropTypes.arrayOf(PropTypes.string),
  motion: PropTypes.arrayOf(PropTypes.string),
  network: PropTypes.arrayOf(PropTypes.string),
  profileRecording: PropTypes.arrayOf(PropTypes.string),
  ptz: PropTypes.arrayOf(PropTypes.string),
  speaker: PropTypes.arrayOf(PropTypes.string),
  streamRecording: PropTypes.arrayOf(PropTypes.string),
});

const accCameraFirmwareUpgradeStateShape = PropTypes.shape({
  error: PropTypes.string,
  progress: PropTypes.number,
  state: PropTypes.string,
  timestamp: PropTypes.string,
});

const accCameraShape = PropTypes.shape({
  active: PropTypes.boolean,
  apiType: PropTypes.string,
  available: PropTypes.boolean,
  capabilities: accCameraCapabilitiesShape,
  clusterId: PropTypes.string,
  connected: PropTypes.boolean,
  connectionFailoverLevel: PropTypes.number,
  connectionState: PropTypes.string,
  connectionStatus: accCameraConnectionStatusShape,
  defaultHeight: PropTypes.number,
  defaultWidth: PropTypes.number,
  firmwareUpgradeStatus: accCameraFirmwareUpgradeStateShape,
  firmwareVersion: PropTypes.string,
  id: PropTypes.string,
  ipAddress: PropTypes.string,
  location: PropTypes.string,
  logicalId: PropTypes.number,
  manufacturer: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string,
  operatingPriority: PropTypes.number,
  physicalAddress: PropTypes.string,
  ptzInfo: accCameraPtzInfoShape,
  recordedData: PropTypes.boolean,
  serial: PropTypes.string,
  serverId: PropTypes.string,
  timezone: PropTypes.string,
});

const accCamerasPropType = PropTypes.objectOf(
  PropTypes.objectOf(PropTypes.arrayOf(accCameraShape)),
);

const accServerNetworkShape = PropTypes.shape({
  ip: PropTypes.string,
  linkSpeed: PropTypes.number,
  name: PropTypes.string,
  operStatus: PropTypes.number,
  rxBytesPerSec: PropTypes.number,
  txBytesPerSec: PropTypes.number,
});

const accServerLicenseShape = PropTypes.shape({
  serverAnalyticsTotal: PropTypes.number,
  serverAnalyticsUsed: PropTypes.number,
  serverAudioTotal: PropTypes.number,
  serverAudioUsed: PropTypes.number,
  serverCameraTotal: PropTypes.number,
  serverCameraUsed: PropTypes.number,
  serverFaceMatchTotal: PropTypes.number,
  serverFaceMatchUsed: PropTypes.number,
  serverFailoverTotal: PropTypes.number,
  serverFailoverUsed: PropTypes.number,
  serverLPR6Total: PropTypes.number,
  serverLPR6Used: PropTypes.number,
  serverLPRTotal: PropTypes.number,
  serverLPRUsed: PropTypes.number,
  serverPOSTotal: PropTypes.number,
  serverPOSUsed: PropTypes.number,
  serverSpeakerTotal: PropTypes.number,
  serverSpeakerUsed: PropTypes.number,
  siteAnalyticsTotal: PropTypes.number,
  siteAnalyticsUsed: PropTypes.number,
  siteAudioTotal: PropTypes.number,
  siteAudioUsed: PropTypes.number,
  siteCameraTotal: PropTypes.number,
  siteCameraUsed: PropTypes.number,
  siteFaceMatchTotal: PropTypes.number,
  siteFaceMatchUsed: PropTypes.number,
  siteFailoverTotal: PropTypes.number,
  siteFailoverUsed: PropTypes.number,
  siteLPR6Total: PropTypes.number,
  siteLPR6Used: PropTypes.number,
  siteLPRTotal: PropTypes.number,
  siteLPRUsed: PropTypes.number,
  sitePOSTotal: PropTypes.number,
  sitePOSUsed: PropTypes.number,
  siteSpeakerTotal: PropTypes.number,
  siteSpeakerUsed: PropTypes.number,
});

const accServerVolumeInfoShape = PropTypes.shape({
  activeUsageBytes: PropTypes.number,
  databaseUsageBytes: PropTypes.number,
  internalUsageBytes: PropTypes.number,
  targetUsageBytes: PropTypes.number,
});

const accServerShape = PropTypes.shape({
  available: PropTypes.bool,
  availablePhysicalMemoryBytes: PropTypes.number,
  id: PropTypes.string,
  license: accServerLicenseShape,
  machineName: PropTypes.string,
  name: PropTypes.string,
  networks: PropTypes.arrayOf(accServerNetworkShape),
  physicalMemoryUsageBytes: PropTypes.number,
  processCPU: PropTypes.number,
  siteId: PropTypes.string,
  startTime: PropTypes.string,
  systemCPU: PropTypes.number,
  useage: PropTypes.number,
  version: PropTypes.string,
  volumeInfo: accServerVolumeInfoShape,
});

const accServersPropType = PropTypes.objectOf(
  PropTypes.objectOf(PropTypes.arrayOf(accServerShape)),
);

DeviceLocationWrapper.propTypes = {
  accCameras: accCamerasPropType,
  accServers: accServersPropType,
  children: PropTypes.node,
  disableSites: PropTypes.bool,
  index: PropTypes.number,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  onUpgradeClick: PropTypes.func,
  retrieveSiteDevicesAndCameras: PropTypes.func,
  siteIdsWithUpgradableDevices: PropTypes.arrayOf(PropTypes.any),
  subscriptionState: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
  upgradingFirmware: PropTypes.bool,
};
