exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".snapshotUnavailableWrapper___1ILLH{\r\n  color: #8CCBFF;\r\n  background-color: #EFF8FE;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n\r\n.snapshotUnavailableTitle___1981O{\r\n  text-align: center;\r\n  line-height: 13px;\r\n  font-size: 12px;\r\n  margin-top: 5px;\r\n  font-weight: bold;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/SnapshotUnavailable/SnapshotUnavailable.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,0BAA0B;EAC1B,qBAAqB;EACrB,cAAc;EACd,aAAa;EACb,YAAY;EACZ,2BAA2B;MACvB,uBAAuB;EAC3B,sBAAsB;MAClB,wBAAwB;EAC5B,uBAAuB;MACnB,oBAAoB;CACzB;;AAED;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;CACnB","file":"SnapshotUnavailable.css","sourcesContent":[".snapshotUnavailableWrapper{\r\n  color: #8CCBFF;\r\n  background-color: #EFF8FE;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n\r\n.snapshotUnavailableTitle{\r\n  text-align: center;\r\n  line-height: 13px;\r\n  font-size: 12px;\r\n  margin-top: 5px;\r\n  font-weight: bold;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"snapshotUnavailableWrapper": "snapshotUnavailableWrapper___1ILLH",
	"snapshotUnavailableTitle": "snapshotUnavailableTitle___1981O"
};