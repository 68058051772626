/* Displays boundaries associated with analytics rules for a camera, including ROIs and LOIs */

import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setPrivacyZone } from 'actions/streamOverlays';
import { getGatewayGeneralSettings } from 'actions/devices';
import Canvas from 'components/Drawing/Canvas';

import { canvasTypes } from 'constants/app';

const PrivacyZoneOverlayContainer = ({
  actions,
  cameraId,
  canvasItems,
  deviceId,
  remoteId,
  screenSpaceHeight,
  screenSpaceWidth,
  streamDetails,
}) => {
  useEffect(() => {
    actions.getGatewayGeneralSettings(deviceId, remoteId, cameraId);
  }, [actions, deviceId, remoteId, cameraId]);

  const updateAllZones = useCallback(
    newCanvasItems => {
      newCanvasItems.forEach(item =>
        actions.setPrivacyZone(cameraId, item.id, item),
      );
    },
    [actions, cameraId],
  );

  const selectZone = useCallback(
    id => {
      const oldSelectedZone = canvasItems.find(
        item => item.isSelected === true,
      );
      if (oldSelectedZone && oldSelectedZone.id === id) return;
      if (oldSelectedZone) {
        const deselectedZone = { ...oldSelectedZone, isSelected: false };
        actions.setPrivacyZone(cameraId, deselectedZone.id, deselectedZone);
      }
      const newSelectedZone = canvasItems.find(item => item.id === id);
      const selectedZone = { ...newSelectedZone, isSelected: true };
      actions.setPrivacyZone(cameraId, id, selectedZone);
    },
    [actions, cameraId, canvasItems],
  );

  if (canvasItems) {
    return (
      <Canvas
        key="canvas"
        canEditROI
        canvasList={canvasItems}
        height={screenSpaceHeight}
        onObjectSelected={selectZone}
        streamDetails={streamDetails}
        type={canvasTypes.privacy}
        updateCanvasList={updateAllZones}
        width={screenSpaceWidth}
      />
    );
  }
  return null;
};

PrivacyZoneOverlayContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  cameraId: PropTypes.string.isRequired,
  canvasItems: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      privacyZoneDimensions: PropTypes.object,
    }),
  ),
  deviceId: PropTypes.string.isRequired,
  remoteId: PropTypes.string.isRequired,
  screenSpaceHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  screenSpaceWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  streamDetails: PropTypes.shape({
    cameraResHeight: PropTypes.number,
    cameraResWidth: PropTypes.number,
    xOffset: PropTypes.number,
    yOffset: PropTypes.number,
  }).isRequired,
};

PrivacyZoneOverlayContainer.defaultProps = {
  canvasItems: null,
};

const mapStateToProps = (state, ownProps) => {
  let mappedProps = {};
  const { cameraId } = ownProps;
  const currentCameraData = state.devices.cameras.find(c => c.Id === cameraId);
  const currentStreamData = state.streamOverlays[cameraId];
  if (currentStreamData) {
    const canvasItemsObject = {
      ...currentStreamData.privacyZones,
      ...currentStreamData.privacyZoneEdits,
    };
    const canvasItems = Object.entries(canvasItemsObject)
      .filter(item => item[1] !== null)
      .map(item => ({ ...item[1], id: item[0] }));
    mappedProps = { canvasItems };
  }
  if (currentCameraData) {
    mappedProps.deviceId = currentCameraData.ServerId;
    mappedProps.remoteId = currentCameraData.RemoteId;
  }
  return {
    cameraId,
    canvasItems: null,
    ...mappedProps,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { getGatewayGeneralSettings, setPrivacyZone },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyZoneOverlayContainer);
