import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconViewDropdown1x1 = props => {
  const { className, fill, height, translate, width } = props;
  return (
    <svg
      className={className}
      height={height}
      version="1.1"
      viewBox="0 0 17 15"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{translate('VIEWS.LAYOUT_TOOLTIP')}</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-1045.000000, -20.000000)">
          <g transform="translate(110.000000, 20.000000)">
            <rect height="15" width="17" x="935" y="0" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconViewDropdown1x1.defaultProps = {
  className: null,
  fill: '#000000',
  height: '15px',
  width: '17px',
};

IconViewDropdown1x1.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconViewDropdown1x1);
