import React from 'react';
import { Translate } from 'react-localize-redux';

import { Button, GroupLayout } from 'lib';

import { PropTypes } from 'prop-types';
import { buttonsWrapper } from './styles.css';

const DefaultFormButtons = ({
  invalid,
  passwordInvalid,
  pristine,
  resetForm,
}) => {
  return (
    <div className={buttonsWrapper}>
      <GroupLayout>
        <Button
          key="generalSettingsButtonRevert"
          buttonType="action"
          disabled={pristine}
          id="generalSettingsButtonRevert"
          inputType="reset"
          onClick={resetForm}
          text={<Translate id="BUTTONS.REVERT" />}
        />
        <Button
          key="generalSettingsButtonSave"
          buttonType="primary"
          disabled={pristine || invalid || passwordInvalid}
          id="generalSettingsButtonSave"
          inputType="submit"
          text={<Translate id="BUTTONS.SAVE" />}
        />
      </GroupLayout>
    </div>
  );
};

DefaultFormButtons.propTypes = {
  invalid: PropTypes.bool,
  passwordInvalid: PropTypes.bool,
  pristine: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
};

DefaultFormButtons.defaultProps = {
  invalid: false,
  passwordInvalid: false,
  pristine: true,
};

export default DefaultFormButtons;
