import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import * as stateStyles from 'sharedStyles/isState.css';
import * as genericStyles from './genericMessage.css';

class GenericMessage extends Component {
  get cssClasses() {
    const { messageType } = this.props;
    const messageKey = `genericMessageState${this.messageType}`;
    return [
      messageType,
      genericStyles.genericMessageMarginTop,
      genericStyles.genericMessageBox,
      genericStyles[messageKey],
    ].join(' ');
  }

  get messageType() {
    const { messageType } = this.props;
    let type = messageType;
    if (type) {
      type = type.toLowerCase();
      type = type.charAt(0).toUpperCase() + type.slice(1);
    }
    return type;
  }

  get header() {
    const { messageHeader } = this.props;
    if (messageHeader) {
      return (
        <span className={genericStyles.genericMessageHeader}>
          {messageHeader}
        </span>
      );
    }
    return null;
  }

  get isWaiting() {
    const { isWaiting } = this.props;
    if (isWaiting) {
      return stateStyles.isWaiting;
    }
    return null;
  }

  render() {
    const { children } = this.props;
    return (
      <div className={this.cssClasses}>
        <div className={genericStyles.genericMessageContentLeft}>
          {this.header}
          <div className={genericStyles.genericMessageBody}>
            <span className={this.isWaiting}>{children}</span>
          </div>
        </div>
      </div>
    );
  }
}
GenericMessage.defaultProps = {
  messageType: 'Info',
  isWaiting: false,
  children: null,
  messageHeader: null,
};

GenericMessage.propTypes = {
  messageType: PropTypes.string,
  isWaiting: PropTypes.bool,
  children: PropTypes.node,
  messageHeader: PropTypes.node,
};

export default GenericMessage;
