/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  PATH_SEGMENT_FORMATS,
  PATH_SEGMENT_PASSWORD,
  PATH_SEGMENT_PERSONAL_INFO,
  PATH_SEGMENT_SUMMARY,
} from 'constants/urlPaths';

export const idSummaryLink = 'idSummaryLink';
export const idPersonalInfoLink = 'idPersonalInfoLink';
export const idPasswordLink = 'idPasswordLink';
export const idFormatsLink = 'idFormatsLink';

export const tabs = {
  summary: { id: idSummaryLink, path: PATH_SEGMENT_SUMMARY },
  personalInfo: {
    id: idPersonalInfoLink,
    path: PATH_SEGMENT_PERSONAL_INFO,
  },
  password: { id: idPasswordLink, path: PATH_SEGMENT_PASSWORD },
  formats: { id: idFormatsLink, path: PATH_SEGMENT_FORMATS },
};
