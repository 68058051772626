export default class ComparableFirmwareVersion {
  constructor(versionString) {
    this.version = [0, 0, 0, 0];

    if (versionString) {
      const m = versionString.match(/(\d+)\.(\d+)\.(\d+)\.(\d+)/);

      if (m && m.length === 5) {
        this.version = m.splice(1, 4).map(s => parseInt(s, 10));
      }
    }
  }

  compare(otherVersion) {
    const compResults = this.version.map((n, i) => {
      if (n < otherVersion.version[i]) return -1;
      if (n > otherVersion.version[i]) return 1;
      return 0;
    });

    return compResults.reduce((accumulator, current) => {
      if (accumulator === 0) {
        return current;
      }
      return accumulator;
    }, 0);
  }
}
