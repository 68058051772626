import React from 'react';
import { convertToShortFormat } from 'util/convertTimeTo';

export const SERVICE_TABLE_LABEL = {
  AUTO_RENEWAL: {
    FIELD: 'IsAutoRenewable',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.AUTO_RENEWAL',
  },
  CHANGE_PENDING: { FIELD: 'IsChangePending' },
  NAME: {
    FIELD: 'Name',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.SUBSCRIPTION_LABEL',
  },
  PROVIDER_NAME: {
    FIELD: 'ProviderName',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.PROVIDED_BY_LABEL',
  },
  TERM_END_DATE: {
    FIELD: 'TermEndDate',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.END_DATE_LABEL',
  },
  TERM_LENGTH: {
    FIELD: 'Term',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.TERM_LABEL',
  },
  TERM_START_DATE: {
    FIELD: 'TermStartDate',
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.START_DATE_LABEL',
  },
};

export const SERVICE_TABLE_FIELDS = Object.keys(SERVICE_TABLE_LABEL).reduce(
  (acc, cur) => {
    return {
      ...acc,
      [cur]: SERVICE_TABLE_LABEL[cur].FIELD,
    };
  },
  {},
);

export const TRANSLATION_IDS = Object.keys(SERVICE_TABLE_LABEL).reduce(
  (acc, cur) => {
    const field = SERVICE_TABLE_LABEL[cur].FIELD;
    const translationId = SERVICE_TABLE_LABEL[cur].TRANSLATED_HEADER;

    if (translationId) {
      return {
        ...acc,
        [field]: translationId,
      };
    }

    return acc;
  },
  {},
);

export const FIELD_ORDER = [
  SERVICE_TABLE_FIELDS.NAME,
  SERVICE_TABLE_FIELDS.PROVIDER_NAME,
  SERVICE_TABLE_FIELDS.TERM_LENGTH,
  SERVICE_TABLE_FIELDS.TERM_START_DATE,
  SERVICE_TABLE_FIELDS.TERM_END_DATE,
  SERVICE_TABLE_FIELDS.AUTO_RENEWAL,
];

const formatDate = (translate, preferredShortDateFormat, rawDate) =>
  rawDate
    ? convertToShortFormat(rawDate, preferredShortDateFormat)
    : translate('GENERAL_TABLE.BODY.NOT_APPLICABLE_LABEL');

const formatAutoRenewal = (translate, rawData) =>
  rawData ? translate('COMMON.YES') : translate('COMMON.NO');

export const getCustomCells = (
  translate,
  preferredShortDateFormat,
  classes,
  ChangePendingIndicatorComponent,
) => {
  const {
    AUTO_RENEWAL,
    CHANGE_PENDING,
    NAME,
    PROVIDER_NAME,
    TERM_END_DATE,
    TERM_LENGTH,
    TERM_START_DATE,
  } = SERVICE_TABLE_FIELDS;

  return {
    [AUTO_RENEWAL]: rowData => (
      <span>{formatAutoRenewal(translate, rowData[AUTO_RENEWAL])}</span>
    ),
    [NAME]: rowData => (
      <span className={classes.flexContainer}>
        <ChangePendingIndicatorComponent
          isPending={rowData[CHANGE_PENDING]}
          titleId="ACCOUNTS.LOCATIONS.SUBSCRIPTION_PENDING"
          translate={translate}
        />
        {rowData[NAME]}
      </span>
    ),
    [PROVIDER_NAME]: rowData => <span>{rowData[PROVIDER_NAME]}</span>,
    [TERM_END_DATE]: rowData => (
      <span>
        {formatDate(
          translate,
          preferredShortDateFormat,
          rowData[TERM_END_DATE],
        )}
      </span>
    ),
    [TERM_LENGTH]: rowData => <span>{rowData[TERM_LENGTH]}</span>,
    [TERM_START_DATE]: rowData => (
      <span>
        {formatDate(
          translate,
          preferredShortDateFormat,
          rowData[TERM_START_DATE],
        )}
      </span>
    ),
  };
};
