import {
  PATH_SEGMENT_MONITORING_CENTERS,
  PATH_SEGMENT_SITE_CONFIGURATION,
} from 'constants/urlPaths';

export const idCentalStationsLink = 'centralStationsLink';
export const idSiteConfigurationsLink = 'siteConfigurationsLink';

export const tabs = {
  siteConfigurations: {
    id: idSiteConfigurationsLink,
    label: 'INTEGRATIONS.SUBTAB_LABELS.SITE_CONFIGURATIONS',
    path: PATH_SEGMENT_SITE_CONFIGURATION,
  },
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  centralStations: {
    id: idCentalStationsLink,
    label: 'INTEGRATIONS.SUBTAB_LABELS.CENTRAL_STATIONS',
    path: PATH_SEGMENT_MONITORING_CENTERS,
  },
};

export const pendingRequestListViewConfig = {
  CELL_ALIGNMENTS: {
    Created: 'left',
    ServicePackageName: 'left',
    SiteName: 'left',
    SubscriberName: 'left',
    requestDecision: 'left',
  },
  CELL_WIDTHS: {
    SubscriberName: '30%',
  },

  /* eslint-disable */
  // Eslint disabled here because field order and translationIds can be achieved in single block
  // This reduces the noise and helps achieve both things with single object.
  HEADER_TRANSLATION_IDS: {
    SubscriberName: 'INTEGRATIONS.PENDING_REQUEST_TABLE.SUBSCRIBER',
    SiteName: 'INTEGRATIONS.PENDING_REQUEST_TABLE.SITE',
    ServicePackageName: 'INTEGRATIONS.PENDING_REQUEST_TABLE.SERVICE_PLAN',
    Created: 'INTEGRATIONS.PENDING_REQUEST_TABLE.REQUEST_DATE',
    requestDecision: 'DEV_DO_NOT_TRANSLATE.ACTIONS',
  },
  /* eslint-enable */
};
