// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getSubscriptionState } from 'util/validation';

// Components
import { SiteBookmarksContainer } from 'containers';
import { Icon } from 'lib';
import { PersistentAccordionItem, SubscriptionStateNotice } from 'components';

// Constants
import { IC_AGENCY_BUILDING } from 'constants/iconNames';

// Styles
import * as accordionTypes from 'constants/AccordionTypes';
import { iconStyle, locationColumn, sitesAccordion } from './styles.css';

class BookmarksListAccordion extends Component {
  render() {
    const { getClustersForSite, siteHasBookmarks, sites } = this.props;
    return (
      <div className={sitesAccordion}>
        {sites.map((site, index) => {
          const notice = (
            <div className={locationColumn}>
              <SubscriptionStateNotice
                subscriptionState={getSubscriptionState(
                  site.PackageSubscription,
                )}
              />
            </div>
          );
          return (
            <PersistentAccordionItem
              key={site.Id}
              accordionType={accordionTypes.BOOKMARKS}
              disabled={!site.UserHasAccess}
              item={site}
              leftIcon={
                <Icon
                  iconClass={iconStyle}
                  id={IC_AGENCY_BUILDING}
                  title="COMMON.SITE"
                />
              }
              loadExpanded={index === 0 && siteHasBookmarks(site)}
              nameField="Name"
              notice={notice}
            >
              <SiteBookmarksContainer
                clustersForSite={getClustersForSite(site.Id)}
                showBookmarkDetails={this.showBookmarkDetails}
                siteId={site.Id}
                subscriptionState={getSubscriptionState(
                  site.PackageSubscription,
                )}
              />
            </PersistentAccordionItem>
          );
        })}
      </div>
    );
  }
}

BookmarksListAccordion.defaultProps = {
  disabledSiteIds: [],
  getClustersForSite: () => {},
  siteHasBookmarks: () => {},
  sites: [],
};

BookmarksListAccordion.propTypes = {
  disabledSiteIds: PropTypes.arrayOf(PropTypes.string),
  getClustersForSite: PropTypes.func,
  siteHasBookmarks: PropTypes.func,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string,
      PackageSubscription: PropTypes.object,
    }),
  ),
};

export default BookmarksListAccordion;
