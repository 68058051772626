

export const getSecurityTokens = location => {
  const query = location.search.substr(1); // remove '?' in the search URI
  // Manually grab the tokens from the URI and decode the value. This is a result of react-router upgrades from V3 to V4
  return JSON.parse(
    `{"${query.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
    (key, value) => {
      return key === '' ? value : decodeURIComponent(value);
    },
  );
};
