// Libs
import React, { Component } from 'react';
import renderIf from 'render-if';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import Dimensions from 'react-dimensions';

// utils
import checkProxyAlarmHistoryChange from 'util/checkProxyAlarmHistoryChange';
import { setAftTenantId } from 'util/aftHelper';

// Actions
import * as AlarmActions from 'actions/alarms';
import * as UserActions from 'actions/user';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';

// Constants
import * as notificationContainerConsts from 'containers/Notifications/constants';
import {
  convertCameraTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';
import BLUE_CONNECT_MODELS from 'constants/BlueConnectModels';

// Components
import { ModalContainer } from 'containers';
import { RadioGroup } from 'components';
import * as types from 'constants/ActionTypes';
import { ACTIVE_COLOR_DEFAULT, INACTIVE_COLOR_DEFAULT } from 'constants/app';
import getNotificationsEventsTypeKey from 'util/getNotificationEventTypeKey';
import { IconPhone } from 'icons';
import { EmptyPlaceholder } from 'lib';
import * as alarmConstants from './constants';
import ActionLog from './ActionLog';
import CommentComponent from './CommentComponent';
import NotificationStreamVideo from './NotificationStreamVideo';
import NotificationsContactsTable from './NotificationsContactsTable';

// Styles
import {
  ActivityBoxColumn,
  activityBoxRowActivityLog,
  activityBoxRowCommentBox,
  alarmButton,
  alarmData,
  alarmHealthformWrapper,
  blueDevice,
  blueDeviceDarkhorse,
  blueDeviceGeneric,
  buttonContainer,
  contactsDropdown,
  controlsContainer,
  headerButton,
  headerButtonDisabled,
  HealthvideoPlayerContainer,
  scrollButtonContainer,
  scrollButtonLeft,
  scrollButtonRight,
  separator,
  staleModalData,
  subheaderBold,
  subheaderLight,
  verifyAlarmContainer,
  VideoPlayerColumn,
} from './styles.css';

class EditHealthNotificationModal extends Component {
  constructor(props) {
    super(props);
    this.evoPlayer = null;
    this.state = {
      alarmReopened: false,
      isBusyFetchingRules: false,
      newAlarmRequested: false,
      showLiveRulesOverlay: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      alarm,
      alarmId,
      alarmTenantId,
      selectedTenantId,
    } = this.props;
    if (alarm && alarmId === alarm.Id) {
      actions.isFetching &&
        actions.isFetching(types.IS_FETCHING_SELECTED_ALARM);
      this.setState({ alarmReopened: true }, () => {
        actions.getAlarm(alarmId, alarmTenantId);
      }); // Flag so willReceiveProps sets alarm to in-review state
    } else {
      actions.getAlarm(alarmId, alarmTenantId);
      if (alarmTenantId) {
        // TODO: Store username in history to eliminate the need for this
        actions.getUsersPublicInfo(alarmTenantId);
      }
    }

    if (alarmTenantId && alarmTenantId !== selectedTenantId) {
      setAftTenantId(alarmTenantId);
    }
    document.addEventListener('keydown', this.handleArrowKeyPress);
  }

  componentWillUnmount() {
    const { alarmTenantId, selectedTenantId } = this.props;
    if (alarmTenantId && alarmTenantId !== selectedTenantId) {
      setAftTenantId(selectedTenantId);
    }
    document.removeEventListener('keydown', this.handleArrowKeyPress);
  }

  get blueDeviceStyle() {
    const { cameraDevice: server } = this.props;
    if (server) {
      switch (server.Model) {
        case BLUE_CONNECT_MODELS.DARKHORSE: {
          return `${blueDevice} ${blueDeviceDarkhorse}`;
        }
        default: {
          return `${blueDevice} ${blueDeviceGeneric}`;
        }
      }
    }
    return `${blueDevice} ${blueDeviceGeneric}`;
  }

  handleArrowKeyPress = e => {
    const { alarm, alarms } = this.props;
    const isFirst = alarms.length && alarms[0].Id === alarm.Id;
    const isLast = alarms.length && alarms[alarms.length - 1].Id === alarm.Id;
    if (e.keyCode === 37 && !isFirst) {
      this.onPrevious();
    } else if (e.keyCode === 39 && !isLast) {
      this.onNext();
    }
  };

  calculateVideoDimensions = () => {
    const { containerHeight, containerWidth } = this.props;
    // First, try to fill available height
    const playerHeight =
      containerHeight -
      alarmConstants.MODAL_HEADER_HEIGHT -
      alarmConstants.HEADER_HEIGHT -
      alarmConstants.VERTICAL_PADDING * 2;
    const playerWidth =
      (containerWidth -
        alarmConstants.HORIZONTAL_PADDING -
        alarmConstants.ACTIVITY_COLUMN_PADDING) *
      0.5;
    return { playerHeight, playerWidth };
  };

  updateAlarm = (storeData, serverData, alarmId, alarmTenantId) => {
    const { actions, updateAlarmStore } = this.props;
    // update store in the top-level container
    updateAlarmStore(storeData, alarmId);
    // send update to server
    actions.editAlarm(serverData, alarmId, alarmTenantId);
  };

  onResolvedClicked = () => {
    const { alarm, alarmTenantId, isLoading, isUpdating } = this.props;
    if (isUpdating || isLoading) return null;
    let newStoreData;
    let newServerData;
    if (alarm.Status !== alarmConstants.dismissed) {
      newServerData = {
        Action: 'Dismiss',
        Context: '',
      };
      newStoreData = this.getStoreData(alarmConstants.dismissed, newServerData);
    } else {
      newServerData = {
        Action: 'Reopen',
        Context: 'inreview',
      };
      newStoreData = this.getStoreData(alarmConstants.inReview, newServerData);
    }
    this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
  };

  onFalseAlarmClicked = () => {
    const { alarm, alarmTenantId, isLoading, isUpdating } = this.props;
    if (isUpdating || isLoading) return null;
    const isFalseAlarm = !alarm.FalseAlarm;
    const newServerData = {
      Action: 'MarkFalse',
      Context: isFalseAlarm,
    };
    const newStoreData = this.getStoreData(alarm.Status, newServerData);
    newStoreData.FalseAlarm = isFalseAlarm;
    this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
  };

  getStoreData = (status, newServerData, hasHistory = true) => {
    const { alarm, userId } = this.props;
    const history = alarm.History ? alarm.History : [];
    const storeData = { Status: status };
    if (hasHistory) {
      storeData.History = [
        ...history,
        {
          Timestamp: alarmConstants.justNow,
          UserId: userId,
          ...newServerData,
        },
      ];
    }
    return storeData;
  };

  onPrevious = () => {
    const {
      alarm,
      alarmTenantId,
      handlePrevious,
      isLoading,
      isUpdating,
    } = this.props;
    const { newAlarmRequested } = this.state;
    if (!isUpdating && !isLoading && !newAlarmRequested) {
      this.setState({
        isBusyFetchingRules: false,
        newAlarmRequested: true,
        showContacts: false,
      });
      const newServerData = {
        Action: 'Release',
        Context: '',
      };
      const newStoreData = this.getStoreData(
        alarmConstants.unreviewed,
        newServerData,
        false,
      );
      this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
      handlePrevious();
    }
  };

  onNext = () => {
    const {
      alarm,
      alarmTenantId,
      handleNext,
      isLoading,
      isUpdating,
    } = this.props;
    const { newAlarmRequested } = this.state;
    if (!isUpdating && !isLoading && !newAlarmRequested) {
      this.setState({
        isBusyFetchingRules: false,
        newAlarmRequested: true,
        showContacts: false,
      });
      const newServerData = {
        Action: 'Release',
        Context: '',
      };
      const newStoreData = this.getStoreData(
        alarmConstants.unreviewed,
        newServerData,
        false,
      );
      this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
      handleNext();
    }
  };

  onContactClick = () => {
    const { showContacts } = this.state;
    this.setState({ showContacts: !showContacts });
  };

  processContacts = contacts => {
    // Normalize for contacts table
    // Since only name and phone are displayed, sites are left intentionally empty
    return contacts.map(contact => {
      return {
        contact,
        sites: [],
      };
    });
  };

  submitForm = formData => {
    const { alarm, alarmTenantId } = this.props;
    const serverData = { Action: 'Comment', Context: formData.ReviewComment };
    const storeData = this.getStoreData(alarm.Status, serverData);
    this.updateAlarm(storeData, serverData, alarm.Id, alarmTenantId);
  };

  onExit = () => {
    const { alarm, alarmTenantId, handleCancel } = this.props;
    this.setState({ showContacts: false });
    if (!alarm || !alarm.Id) {
      handleCancel();
    }
    const newServerData = {
      Action: 'Release',
      Context: '',
    };
    const status =
      alarm.Status === alarmConstants.dismissed
        ? alarmConstants.dismissed
        : alarmConstants.unreviewed;
    const newStoreData = this.getStoreData(status, newServerData);
    this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
    handleCancel();
  };

  autofocus = () => {
    const { actions, camera } = this.props;
    actions.cameraAutofocusGateway(camera.DeviceId, camera.RemoteId);
  };

  setLiveRulesOverlayVisibility = show => {
    this.setState({
      showLiveRulesOverlay: show,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, alarm, alarmId, alarmTenantId, camera } = this.props;
    const {
      alarmReopened,
      isBusyFetchingRules,
      showLiveRulesOverlay,
    } = this.state;
    const isAlarmChanged = nextProps.alarmId && nextProps.alarmId !== alarmId;
    const isProxyAlarmHistoryChanged = checkProxyAlarmHistoryChange(
      alarm,
      nextProps.alarm,
    );
    if (isAlarmChanged || isProxyAlarmHistoryChanged) {
      actions.getAlarm(nextProps.alarmId, nextProps.alarmTenantId);
      if (isAlarmChanged) {
        if (alarmTenantId !== nextProps.alarmTenantId) {
          // TODO: Store username in history to eliminate the need for this
          actions.getUsersPublicInfo(nextProps.alarmTenantId);
        }
        this.setState({
          showLiveRulesOverlay: nextProps.shouldRefreshLiveVideo
            ? false
            : showLiveRulesOverlay,
        });
      }
    }

    if (nextProps.alarm) {
      if (
        nextProps.alarm.DeviceId &&
        nextProps.alarmTenantId &&
        !nextProps.cameraDevice &&
        !nextProps.isFetchingDevice
      ) {
        actions.getDevice(nextProps.alarm.DeviceId, nextProps.alarmTenantId);
      }
    }

    if (
      nextProps.alarm &&
      nextProps.alarm.Id &&
      nextProps.isUpdating === false &&
      (!alarm || nextProps.alarm.Id !== alarm.Id || alarmReopened)
    ) {
      const history = nextProps.alarm.History ? nextProps.alarm.History : [];
      const status =
        nextProps.alarm.Status === alarmConstants.dismissed
          ? alarmConstants.dismissed
          : alarmConstants.inReview;
      const newServerData = {
        Action: 'Accept',
        Context: '',
      };
      const newStoreData = {
        History: [
          ...history,
          {
            Timestamp: alarmConstants.justNow,
            UserId: nextProps.userId,
            ...newServerData,
          },
        ],
        Status: status,
      };
      this.setState({ alarmReopened: false, newAlarmRequested: false }, () => {
        this.updateAlarm(
          newStoreData,
          newServerData,
          nextProps.alarm.Id,
          nextProps.alarmTenantId,
        );
      });
    }

    if (
      nextProps.alarm &&
      nextProps.alarm.ActivityClass ===
        notificationContainerConsts.ActivityClass.CameraHealthActivity &&
      nextProps.camera &&
      camera &&
      nextProps.camera.RemoteId &&
      nextProps.camera.RemoteId !== camera.RemoteId
    ) {
      // Look for source cameras and location
      if (
        !nextProps.cameraDevice &&
        nextProps.alarmTenantId &&
        !nextProps.isFetchingDevice
      ) {
        actions.getCameras(
          { field: 'DeviceId', value: nextProps.alarm.DeviceId },
          nextProps.alarmTenantId,
        );
        actions.getUpdatedLocation(
          nextProps.alarm.LocationId,
          nextProps.alarmTenantId,
        );
      }
      if (
        !nextProps.settings &&
        !nextProps.alarmTenantId // Non-proxy alarms only
      ) {
        actions.getAcquisitionSettings(
          nextProps.camera.DeviceId,
          nextProps.camera.RemoteId,
          nextProps.camera.Id,
        );
      }

      if (
        !nextProps.rules &&
        nextProps.isFetchingRules !== true &&
        isBusyFetchingRules === false &&
        nextProps.cameraRule === null
      ) {
        this.setState({ isBusyFetchingRules: true }, () => {
          actions.getRules(
            nextProps.camera.DeviceId,
            nextProps.camera.RemoteId,
            nextProps.camera.Id,
            nextProps.alarmTenantId ? nextProps.alarmTenantId : null,
          );
        });
      }
    }
  }

  render() {
    const {
      alarm,
      alarms,
      camera,
      cameraDevice,
      cameraDeviceLocation,
      cameraDeviceName,
      cameraLocation,
      contacts,
      isLoading,
      isUpdating,
      locations,
      preferredLongDateFormat,
      preferredTimeFormat,
      profileTimeZone,
      rules,
      show,
      users,
    } = this.props;

    const {
      newAlarmRequested,
      showContacts,
      showLiveRulesOverlay,
    } = this.state;

    const isFirst = alarms.length && alarms[0].Id === alarm.Id;
    const isLast = alarms.length && alarms[alarms.length - 1].Id === alarm.Id;

    if (!show) {
      return <div />;
    }
    if (alarm) {
      const alarmDate = alarm.EventStartTime || alarm.Created;
      const formatForLocale = getFormatForActiveLocale();
      const dateTimeString = alarmDate ? (
        convertCameraTimeTo(
          alarmDate,
          TIME_TYPES.LOCAL,
          cameraDevice,
          cameraLocation,
          getPreferredLongDateTimeFormat(
            preferredLongDateFormat,
            preferredTimeFormat,
          ) || formatForLocale,
        )
      ) : (
        <Translate id="ALARMS.STATUS.UNAVAILABLE_LABEL" />
      );
      const isResolved = alarm.Status === alarmConstants.dismissed;
      const { CameraName } = alarm;
      let header;
      if (isUpdating) {
        header = <Translate id="ALARMS.EDIT_MODAL.LOADING_LABEL" />;
      } else {
        const eventName = getNotificationsEventsTypeKey(alarm.EventName);

        header = (
          <Translate
            data={{
              eventDateTime: dateTimeString,
              eventName: eventName.translate ? (
                <Translate id={eventName.keyName} />
              ) : (
                eventName.name
              ),
            }}
            id="ALARMS.EDIT_MODAL.REVIEW_LABEL"
          />
        );
      }
      const { playerHeight, playerWidth } = this.calculateVideoDimensions();

      return (
        <ModalContainer handleCancel={this.onExit} modalTitle={header}>
          {isLoading ? (
            <EmptyPlaceholder isFetching={isLoading} />
          ) : (
            <div
              className={`${alarmHealthformWrapper} ${
                newAlarmRequested ? staleModalData : ''
              }`}
            >
              <div className={controlsContainer}>
                <div className={alarmData}>
                  <span className={subheaderBold}>
                    <Translate id="ALARMS.EDIT_MODAL.SITE_LABEL" />
                  </span>
                  <span className={subheaderLight}>
                    {`${cameraDeviceLocation},`}
                  </span>
                  <span className={subheaderBold}>
                    <Translate id="ALARMS.EDIT_MODAL.SERVER_LABEL" />
                  </span>
                  <span className={subheaderLight}>
                    {`${cameraDeviceName}`}
                    {CameraName ? ',' : ''}
                  </span>

                  {renderIf(CameraName)(
                    <>
                      <span className={subheaderBold}>
                        <Translate id="ALARMS.EDIT_MODAL.CAMERA_LABEL" />
                      </span>
                      <span className={subheaderLight}>{`${CameraName}`}</span>
                    </>,
                  )}
                </div>
                {
                  <div className={buttonContainer}>
                    <div
                      onClick={
                        contacts.length !== 0 ? this.onContactClick : undefined
                      }
                    >
                      <div
                        className={`${headerButton} ${contacts.length === 0 &&
                          headerButtonDisabled}`}
                      >
                        <IconPhone
                          fill={
                            contacts.length === 0
                              ? INACTIVE_COLOR_DEFAULT
                              : ACTIVE_COLOR_DEFAULT
                          }
                          title={
                            <Translate id="ALARMS.EDIT_MODAL.CONTACTS_ICON_TITLE" />
                          }
                        />
                      </div>
                    </div>
                    {/* this.props.showTalkdown &&
                    <div onClick={this.onTalkdownClick}>
                      <div
                        className={
                          headerButton + ' ' + headerButtonDisabled
                        }
                      >
                        <IconMicrophone
                          fill={INACTIVE_COLOR_DEFAULT}
                          title={<Translate id={'ALARMS.EDIT_MODAL.TALKDOWN_ICON_TOOLTIP'} />}
                        />
                      </div>
                      </div> */}

                    {showContacts ? (
                      <div className={contactsDropdown}>
                        <NotificationsContactsTable
                          contacts={this.processContacts(contacts, locations)}
                          maxHeight={350}
                        />
                      </div>
                    ) : null}
                  </div>
                }

                <div className={verifyAlarmContainer}>
                  <RadioGroup
                    containerClassName={alarmButton}
                    labelTranslateId="ALARMS.EDIT_MODAL.REVIEWED_LABEL"
                    onChange={this.onResolvedClicked}
                    options={alarmConstants.REVIEWED_RADIO_OPTIONS}
                    value={isResolved}
                  />
                  <div className={separator} />
                  <Translate>
                    {({ translate }) => (
                      <div className={scrollButtonContainer}>
                        {isFirst ? (
                          <div />
                        ) : (
                          <div
                            alt={translate('ALARMS.EDIT_MODAL.PREVIOUS_LABEL')}
                            className={`${headerButton} ${scrollButtonLeft}`}
                            onClick={this.onPrevious}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex={0}
                          />
                        )}
                        {isLast ? (
                          <div />
                        ) : (
                          <div
                            alt={translate('ALARMS.EDIT_MODAL.NEXT_LABEL')}
                            className={`${headerButton} ${scrollButtonRight}`}
                            onClick={this.onNext}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex={0}
                          />
                        )}
                      </div>
                    )}
                  </Translate>
                </div>
              </div>
              <div className={HealthvideoPlayerContainer}>
                <div className={VideoPlayerColumn}>
                  {alarm.ActivityClass ===
                  notificationContainerConsts.ActivityClass
                    .CameraHealthActivity ? (
                    <NotificationStreamVideo
                      autofocus={this.autofocus}
                      camera={camera}
                      cameraName={CameraName}
                      cameraRemoteId={camera.RemoteId}
                      deviceId={camera.DeviceId}
                      deviceName={cameraDeviceName}
                      getClipVideo={null}
                      id={alarmConstants.liveLabel}
                      playerHeight={playerHeight}
                      playerWidth={playerWidth}
                      rules={rules}
                      setRulesOverlayVisibility={
                        this.setLiveRulesOverlayVisibility
                      }
                      showRulesOverlay={showLiveRulesOverlay}
                      siteName={cameraDeviceLocation}
                    />
                  ) : (
                    <div className={this.blueDeviceStyle} />
                  )}
                </div>
                <div
                  className={ActivityBoxColumn}
                  style={{ height: playerHeight, width: playerWidth }}
                >
                  <div className={activityBoxRowCommentBox}>
                    <CommentComponent
                      handleSubmit={this.submitForm}
                      height="calc(100% - 50px)"
                      width="100%"
                    />
                  </div>
                  <div className={activityBoxRowActivityLog}>
                    <ActionLog
                      height={350}
                      history={alarm.History || []}
                      logMaxHeight={playerHeight}
                      profileTimeZone={profileTimeZone}
                      users={users || []}
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalContainer>
      );
    }
    return <div />;
  }
}

EditHealthNotificationModal.propTypes = {};

function mapStateToProps(state, ownProps) {
  let cameraDeviceLocation = <Translate id="ALARMS.EDIT_MODAL.NOT_AVAILABLE" />;
  let cameraDeviceName = <Translate id="ALARMS.EDIT_MODAL.NOT_AVAILABLE" />;
  const alarm = state.alarms.selectedAlarm;
  let camId = '';
  let clipId = '';
  let clipUri = '';
  let contacts = [];
  let camera = null;
  let isLoading = ownProps.isBusy;
  let cameraDevice = null;
  let settings = null;
  let rules = null;
  let cameraRule = null;
  let cameraLocation = null;
  let { users } = ownProps;
  if (alarm) {
    camId = alarm.CameraId;
    clipId = alarm.ClipId;
    clipUri = state.alarms.clips[clipId];
    contacts = alarm.Contacts || [];
    camera = state.devices.cameras.find(c => c.Id === camId) || {
      // If this is a dealer, fall back to a pared-down version of the subscriber's camera object
      DeviceId: alarm.DeviceId,
      Id: alarm.CameraId,
      IsDisconnected: true,
      Name: alarm.CameraName,
      RemoteId: alarm.CameraRemoteId,
    };
    rules =
      state.devices.rules[alarm.CameraId] &&
      state.devices.rules[alarm.CameraId].rules;
    if (ownProps.alarmTenantId) {
      camera.TenantId = ownProps.alarmTenantId; // Ensure dealer has subscriber id available
      users = state.user.usersPublicInfo[ownProps.alarmTenantId] || [];
    }
    isLoading = !state.alarms.selectedAlarm.Id || ownProps.isBusy; // When first loading or switching pages
    cameraDeviceName = alarm.DeviceName;
    cameraDeviceLocation = alarm.LocationName;
    cameraDevice = state.devices.devices.find(
      deviceObj => deviceObj.Id === alarm.DeviceId,
    );
    if (cameraDevice && !alarm.DeviceName) {
      cameraDeviceName = cameraDevice.Name;
    }
    if (alarm.LocationId && !cameraDeviceLocation) {
      const location = state.locations.locations.find(
        loc => loc.Id === alarm.LocationId,
      );
      if (location) {
        cameraDeviceLocation = location.Name;
      }
    }
    if (camera) {
      settings = state.devices.settings[alarm.CameraId];
      cameraRule =
        state.devices.rules.length > 0
          ? state.devices.rules[alarm.CameraId]
          : null;

      // if (state.devices.mediaParams) {
      //   for (let key in state.devices.mediaParams) {
      //     if (state.devices.mediaParams[key].cameraId === camera.RemoteId) {
      //       cameraMediaRotation =
      //         state.devices.mediaParams[key].params &&
      //         state.devices.mediaParams[key].params.rotation;
      //     }
      //   }
      // }
      cameraLocation = state.locations.locations.find(
        x => x.Id === alarm.LocationId,
      );
    }
  }

  const isUpdating = !!state.alarms.isFetchingSelectedAlarm; // When cycling from one alarm to the next

  return {
    alarm,
    camId,
    camera,
    cameraDevice,
    cameraDeviceLocation,
    cameraDeviceName,
    cameraLocation,
    cameraRule,
    clipId,
    clipUri,
    contacts,
    isFetchingAcquisitionData: state.devices.isFetchingAcquisitionData,
    isFetchingDevice: state.devices.isFetchingDeviceData,
    isFetchingRules: state.isFetching.getRules,
    isLoading,
    isUpdating,
    preferredLongDateFormat:
      state.user.profile.LocalizationPreference.PreferredLongDateFormat,
    preferredTimeFormat:
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
    rules,
    selectedTenantId: state.user.selectedOrganization.Id,
    settings,
    users,
  };
}

EditHealthNotificationModal.defaultProps = {
  alarmTenantId: undefined,
  camera: undefined,
  cameraDevice: undefined,
  cameraDeviceLocation: undefined,
  cameraDeviceName: undefined,
  cameraLocation: undefined,
  isLoading: false,
  isUpdating: false,
  preferredLongDateFormat: '',
  preferredTimeFormat: '',
  rules: undefined,
};

EditHealthNotificationModal.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  alarm: PropTypes.objectOf(PropTypes.any).isRequired,
  alarmId: PropTypes.string.isRequired,
  alarmTenantId: PropTypes.string,
  alarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  camera: PropTypes.objectOf(PropTypes.any),
  cameraDevice: PropTypes.objectOf(PropTypes.any),
  cameraDeviceLocation: PropTypes.string,
  cameraDeviceName: PropTypes.string,
  cameraLocation: PropTypes.shape({}),
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  preferredLongDateFormat: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
  profileTimeZone: PropTypes.string.isRequired,
  rules: PropTypes.objectOf(PropTypes.any),
  selectedTenantId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  updateAlarmStore: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...AlarmActions, ...DeviceActions, ...UserActions, ...LocationActions },
      dispatch,
    ),
  };
}

export default Dimensions({
  elementResize: true,
})(connect(mapStateToProps, mapDispatchToProps)(EditHealthNotificationModal));
