import PropTypes from 'prop-types';
import React from 'react';
import { OGTable } from 'lib';

function ArrayDetailTable({
  data,
  sortType,
  bodyHeight,
  resizable,
  resizableFrom,
}) {
  return (
    <OGTable
      data={data}
      bodyHeight={bodyHeight}
      fieldOrder={Object.keys(data[0])}
      truncatedFields={['value']}
      resizable={resizable}
      resizableFrom={resizableFrom}
      sortType={sortType}
    />
  );
}

ArrayDetailTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  resizable: PropTypes.bool,
  resizableFrom: PropTypes.oneOf(['top', 'bottom']),
  headerTranslationIds: PropTypes.objectOf(PropTypes.string),
};

ArrayDetailTable.defaultProps = {
  resizable: false,
  resizableFrom: 'bottom',
};

export default ArrayDetailTable;
