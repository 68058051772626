import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as OrganizationActions from 'actions/organizations';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_MONITORING,
  PATH_SEGMENT_MONITORING_CENTERS,
} from 'constants/urlPaths';
import CentralStationForm from './CentralStationForm/CentralStationForm';

function CentralStationFormContainer(props) {
  const {
    actions,
    canChangeCustomer,
    configId,
    history,
    integrationConfigs,
    integrationTypes,
    isFetchingIntegrationConfigs,
    isFetchingIntegrationTypes,
  } = props;

  const [fieldValidations, setFieldValidations] = useState({
    fieldValidations: { ConnectionString: false },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isFetchingIntegrationTypes === null) {
      actions.getIntegrationTypes();
    }
  }, [actions, isFetchingIntegrationTypes]);

  useEffect(() => {
    if (isFetchingIntegrationConfigs === null) {
      actions.getIntegrationConfigs();
    }
  }, [actions, isFetchingIntegrationConfigs]);
  const selectedConfig = integrationConfigs
    ? integrationConfigs.find(ic => ic.IntegrationConfigurationId === configId)
    : {};
  const initValues = {
    ApiLoginPassword: '',
    ApiLoginUsername: '',
    AuthenticationToken: '',
    ConfirmPassword: '',
    ConnectionString: '',
    IntegrationType: '',
    Name: '',
    ...selectedConfig,
  };
  function setValidation(fieldName, validation) {
    setFieldValidations({ [fieldName]: validation });
  }

  function handleSubmit(formData) {
    setIsSubmitting(true);
    if (selectedConfig) {
      actions.editIntegrationConfiguration(
        selectedConfig.IntegrationConfigurationId,
        formData,
      );
    } else {
      const augmentedData = {
        ...formData,
        Enabled: false,
      };
      actions.createIntegrationConfiguration(augmentedData);
    }
    setIsSubmitting(false);
    history.push(
      `${PATH_ACCOUNTS}${PATH_SEGMENT_MONITORING}${PATH_SEGMENT_MONITORING_CENTERS}`,
    );
  }

  return (
    <CentralStationForm
      {...props}
      canChangeCustomer={canChangeCustomer}
      fieldValidations={fieldValidations}
      initialValues={initValues}
      integrationTypes={integrationTypes}
      onSubmit={handleSubmit}
      setValidation={setValidation}
      submittingForm={isSubmitting}
    />
  );
}

CentralStationFormContainer.propTypes = {};
const selector = formValueSelector('centralStationForm');

CentralStationFormContainer.defaultProps = {
  canChangeCustomer: false,
  history: {},
  integrationConfigs: [],
  integrationTypes: [],
  isFetchingIntegrationConfigs: null,
  isFetchingIntegrationTypes: null,
  locations: [],
  match: {},
};

CentralStationFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canChangeCustomer: PropTypes.bool,
  configId: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  integrationConfigs: PropTypes.arrayOf(PropTypes.object),
  integrationTypes: PropTypes.arrayOf(PropTypes.object),
  isFetchingIntegrationConfigs: PropTypes.bool,
  isFetchingIntegrationTypes: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = state => ({
  canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
  integrationConfigs: state.organizations.integrationConfigs,
  integrationType: selector(state, 'IntegrationType'),
  integrationTypes: state.organizations.integrationTypes,
  isFetchingIntegrationConfigs:
    state.organizations.isFetchingIntegrationConfigs,
  isFetchingIntegrationTypes: state.isFetching.getIntegrationTypes,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...OrganizationActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CentralStationFormContainer));
