// Libs
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Components
import {
  CameraSettingsContainer,
  DevicesContainer,
  ServerDetailContainer,
} from 'containers';

// Utilities
import { getFullPath } from 'util/getPathName';

// Constants
import * as PATH from 'constants/urlPaths';

const OrganizationDeviceContainer = localProps => {
  return (
    <>
      <Switch>
        <Route
          path={`${PATH.PATH_DEVICES}/cameras/:cid`}
          render={props => <CameraSettingsContainer {...props} canChangeCustomer={localProps.canChangeCustomer} fullPath={localProps.fullPath}/>}
        />
        <Route
          path={`${PATH.PATH_DEVICES}/servers/:sid`}
          render={props => <ServerDetailContainer {...props} canChangeCustomer={localProps.canChangeCustomer} fullPath={localProps.fullPath}/>}
        />
        <Route render={props => <DevicesContainer {...props} canChangeCustomer={localProps.canChangeCustomer} fullPath={localProps.fullPath}/>} />
      </Switch>
    </>
  );
};

OrganizationDeviceContainer.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    fullPath: getFullPath(ownProps.location.pathname),
  };
}

export default connect(mapStateToProps, null)(OrganizationDeviceContainer);
