import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomOutMax = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 11 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1057.000000, -446.000000)"
      >
        <path
          d="M1062.34167,453.757359 L1067.9664,459.382096 L1066.58271,460.765788 L1059.74854,453.931613 L1059.92279,453.757359 L1059.74854,453.583106 L1066.58271,446.748931 L1067.9664,448.132623 L1062.34167,453.757359 Z M1058.96538,446.991871 L1058.96538,460.522847 L1057.00854,460.522847 L1057.00854,446.991871 L1058.96538,446.991871 Z"
          id="icon-btn-zoomfocus-out-max-v2"
          fill={props.fill}
        />
      </g>
    </svg>
  );
};

IconBtnZoomOutMax.defaultProps = {
  className: null,
  width: '11px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomOutMax;
