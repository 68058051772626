import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconPtzMode = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      style={{ verticalAlign: 'middle' }}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g id="PTZ-icons_ptz_mode" fill="#FFFFFF" transform="translate(0 -1)">
        <polygon id="Path" points="6 5 10 0 14 5" />
        <polygon id="Path" points="14 15 10 20 6 15" />
        <polygon id="Path" points="5 14 0 10 5 6" />
        <polygon id="Path" points="15 6 20 10 15 14" />
      </g>
    </svg>
  );
};

IconPtzMode.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
  isPtzModeEnabled: false,
};

export default IconPtzMode;
