import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { Checkbox } from 'components';
import { ListView, Tooltip } from 'lib';
import {
  FirstLinkingLine,
  IconCamera,
  IconHelpQuestion,
  LastLinkingLine,
  LinkingLine,
} from 'icons';
import { SHOW_CONFIRM } from 'constants/ModalTypes';

import {
  alignCenter,
  cameraListHeader,
  daysDropdown,
  linkingColumn,
  tableBodyCameraList,
  transformLetter,
  truncateName,
} from './styles.css';

import { DAYS_DROPDOWN_TRANSLATION_IDS } from './constants';

class CameraList extends Component {
  constructor(props) {
    super(props);
    const { recordingSettings } = props;
    const initialMaxRecordTime = {};
    if (recordingSettings) {
      recordingSettings.forEach(recordingSetting => {
        initialMaxRecordTime[recordingSetting.blueId] =
          recordingSetting.maxRetention.val;
      });
    }
    this.state = {
      maxRecordTime: initialMaxRecordTime,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { cameras } = props;
    const newRecordTime = {};
    cameras.forEach(camera => {
      newRecordTime[camera.Id] = camera.retention;
    });
    const recordTimeIsNotEqual =
      JSON.stringify(newRecordTime) !== JSON.stringify(state.maxRecordTime);
    if (recordTimeIsNotEqual) {
      return {
        maxRecordTime: newRecordTime,
      };
    }
    return null;
  }

  onDaysDropdownChange = (event, cameraId) => {
    const { target } = event;
    const { estimatedRetention, modalActions } = this.props;
    const { maxRecordTime } = this.state;
    const currentMaxRecordTime =
      maxRecordTime[cameraId] && Number(maxRecordTime[cameraId]);
    const maximumRecordTime =
      estimatedRetention && estimatedRetention[cameraId].days;
    const newMaxRecordTime = target.value;
    const isMax = currentMaxRecordTime === 0;
    if (
      currentMaxRecordTime > newMaxRecordTime ||
      maximumRecordTime > newMaxRecordTime ||
      isMax
    ) {
      modalActions.show(SHOW_CONFIRM, {
        handleCancel: () => {
          modalActions.hide();
        },
        message: (
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.MAX_RETENTION_TIME_DECREASE_MODAL_MESSAGE" />
        ),
        onOkClick: () => {
          this.handleDropdownChange(cameraId, newMaxRecordTime);
          modalActions.hide();
        },
        textConfirm: 'BUTTONS.CONTINUE',
        title: (
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.MAX_RETENTION_TIME_DECREASE_MODAL_TITLE" />
        ),
      });
    } else {
      this.handleDropdownChange(cameraId, newMaxRecordTime);
    }
  };

  handleDropdownChange = (cameraId, newMaxRecordTime) => {
    const {
      cameras,
      handleLinkedRetentionChange,
      handleRetentionChange,
    } = this.props;
    const linkedCameras = [];
    cameras.forEach(camera => {
      if (camera.linked) {
        linkedCameras.push(camera.Id);
      }
    });
    if (linkedCameras.length > 0 && linkedCameras.indexOf(cameraId) >= 0) {
      linkedCameras.forEach(linkedCamera => {
        this.setState(prevState => ({
          maxRecordTime: {
            ...prevState.maxRecordTime,
            [linkedCamera]: newMaxRecordTime,
          },
        }));
        handleLinkedRetentionChange(linkedCameras, newMaxRecordTime);
      });
    } else {
      this.setState(
        prevState => ({
          maxRecordTime: {
            ...prevState.maxRecordTime,
            [cameraId]: newMaxRecordTime,
          },
        }),
        () => {
          handleRetentionChange(cameraId, newMaxRecordTime);
        },
      );
    }
  };

  renderCameraStatus = status => {
    const color = {
      CONNECTED: '#6ECA3D',
      DISCONNECTED: '#FF3B30',
    };
    return (
      <div className={alignCenter}>
        <IconCamera fill={color[status]} height="12px" width="24px" />
        <span className={transformLetter}>
          <Translate
            id={`DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.CAMERA_STATUS.${status}`}
          />
        </span>
      </div>
    );
  };

  renderDaysDropdown = cameraId => {
    const { cameras } = this.props;
    const retentionValue = cameras.find(camera => camera.Id === cameraId)
      .retention;
    const parsedRetention = retentionValue === 0 ? 'MAX' : retentionValue;
    return (
      <div className={daysDropdown}>
        <Translate>
          {({ translate }) => (
            <select
              onChange={event => this.onDaysDropdownChange(event, cameraId)}
              value={parsedRetention}
            >
              {Object.keys(DAYS_DROPDOWN_TRANSLATION_IDS)
                .sort((prev, next) => (prev !== 'MAX' ? prev - next : -1))
                .map(key => (
                  <option value={key}>
                    {translate(
                      `DEVICE_DETAILS.VIDEO_RETENTION_TAB.DAYS_DROPDOWN.${
                        DAYS_DROPDOWN_TRANSLATION_IDS[key]
                      }`,
                    )}
                  </option>
                ))}
            </select>
          )}
        </Translate>
      </div>
    );
  };

  renderLinkingCamera = (camera, index, camerasLength) => {
    const { cameras, onLinkedStatusChange } = this.props;
    const checked = cameras.find(cameraObj => camera.Id === cameraObj.Id)
      .linked;
    const checkbox = (
      <Checkbox
        checked={checked}
        onChange={() => onLinkedStatusChange(camera.Id, !checked)}
      />
    );
    if (index === 0) {
      return (
        <div key={camera.Id} className={linkingColumn}>
          <span>
            <FirstLinkingLine />
          </span>
          {checkbox}
        </div>
      );
    }
    if (index === camerasLength - 1) {
      return (
        <div key={camera.Id} className={linkingColumn}>
          <span>
            <LastLinkingLine />
          </span>
          {checkbox}
        </div>
      );
    }
    return (
      <div key={camera.Id} className={linkingColumn}>
        <span>
          <LinkingLine />
        </span>
        {checkbox}
      </div>
    );
  };

  renderEstimatedTotalRecordTime = cameraId => {
    const { estimatedRetention } = this.props;
    return (
      <div className={cameraListHeader}>
        <Translate
          data={{
            days:
              estimatedRetention && estimatedRetention[cameraId]
                ? estimatedRetention[cameraId].days
                : 0,
            hours:
              estimatedRetention && estimatedRetention[cameraId]
                ? estimatedRetention[cameraId].hours
                : 0,
          }}
          id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.ESTIMATED_TOTAL_RECORD_TIME_LABEL_PLURAL"
        />
      </div>
    );
  };

  render() {
    const { cameras } = this.props;
    const translationsId = {
      name:
        'DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.CAMERA_NAME_TABLE_HEADER',
    };
    const data = cameras.map((camera, index) => ({
      estTotalRecordTime: this.renderEstimatedTotalRecordTime(camera.Id),
      linking: this.renderLinkingCamera(camera, index, cameras.length),
      maxRecordTime: this.renderDaysDropdown(camera.Id),
      name: <p className={truncateName}>{camera.Name}</p>,
      status: this.renderCameraStatus(camera.ConnectionState),
    }));
    const customHeaders = {
      estTotalRecordTime: (
        <div className={cameraListHeader}>
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.ESTIMATED_RETENTION_TIME_HEADER" />
          <Tooltip
            message={
              <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.ESTIMATED_TOTAL_TIME_TOOLTIP_INFO" />
            }
            toggleMethod="click"
            width={200}
          >
            <IconHelpQuestion height="12px" width="12px" />
          </Tooltip>
        </div>
      ),
      linking: <span />,
      maxRecordTime: (
        <div className={cameraListHeader}>
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.MAXIMUM_RETENTION_TIME_HEADER" />
          <Tooltip
            message={
              <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.MAXIMUM_RECORDING_TIME_TOOLTIP_INFO" />
            }
            toggleMethod="click"
            width={200}
          >
            <IconHelpQuestion height="12px" width="12px" />
          </Tooltip>
        </div>
      ),
      status: (
        <div className={cameraListHeader}>
          <Translate id="COMMON.STATUS" />
          <Tooltip
            message={
              <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.STATUS_TOOLTIP_INFO" />
            }
            toggleMethod="click"
            width={200}
          >
            <IconHelpQuestion height="12px" width="12px" />
          </Tooltip>
        </div>
      ),
    };
    return (
      cameras && (
        <ListView
          cellWidths={{ linking: 30 }}
          customHeader={customHeaders}
          data={data}
          fieldOrder={[
            'linking',
            'name',
            'status',
            'maxRecordTime',
            'estTotalRecordTime',
          ]}
          headerTranslationIds={translationsId}
          hideFilter
          resizable={false}
          tableBodyStyle={tableBodyCameraList}
        />
      )
    );
  }
}

CameraList.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.object),
  estimatedRetention: PropTypes.objectOf(PropTypes.any),
  handleLinkedRetentionChange: PropTypes.func.isRequired,
  handleRetentionChange: PropTypes.func.isRequired,
  modalActions: PropTypes.shape({
    hide: PropTypes.func.isRequired,
    show: PropTypes.func.isRequired,
  }).isRequired,
  onLinkedStatusChange: PropTypes.func.isRequired,
  recordingSettings: PropTypes.arrayOf(PropTypes.object),
};

CameraList.defaultProps = {
  cameras: null,
  estimatedRetention: null,
  recordingSettings: null,
};

export default CameraList;
