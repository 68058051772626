/* Functions for determining whether a certain kind of item or list of items
/* is valid to pass to a DELETE api call.
*/
import React from 'react';
import renderIf from 'render-if';

import { Translate } from 'react-localize-redux';

import { SELF_PROVISIONED_TENANT_FLAG } from 'constants/app';

export const renderLocationDeleteErrors = (selectedLocations, profile) => {
  const locationsWithEntities = [];
  let haveNonDeletableBlueSites = false;
  let haveNonDeletableAccSites = false;

  selectedLocations.forEach(location => {
    if (location.DeviceCount > 0) {
      locationsWithEntities.push(location);
      haveNonDeletableBlueSites = true;
    } else if (location.ActiveClusterCount > 0) {
      haveNonDeletableAccSites = true;
      locationsWithEntities.push(location);
    }
  });

  if (
    // Cannot delete locations without proper user permissions

    (profile.CustomerTenantFlags & SELF_PROVISIONED_TENANT_FLAG) ===
    SELF_PROVISIONED_TENANT_FLAG
  ) {
    return (
      <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_SELF_PROVISIONED_TENANT_LOCATION_ERROR" />
    );
  }
  if (locationsWithEntities.length > 0) {
    // Cannot delete locations containing servers or cameras
    return (
      <>
        <div>
          { locationsWithEntities.length === 1 ?
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_SINGLE_NOT_EMPTY_LOCATION_ERROR_1" data={{locationName: locationsWithEntities[0].Name}} /> :
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_NOT_EMPTY_LOCATION_ERROR_1" />
          }
          <br />
          <br />
        </div>
        { locationsWithEntities.length > 1 ?
          locationsWithEntities.map(site => (
            <li key={site.Id}>{site.Name}</li>
          ))
          :
          null}
        <div>
          <br />
          {renderIf(!haveNonDeletableAccSites && haveNonDeletableBlueSites)(
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_NOT_EMPTY_BLUE_LOCATIONS_ERROR" />,
          )}
           {renderIf(haveNonDeletableAccSites && !haveNonDeletableBlueSites)(
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_NOT_EMPTY_ACC_LOCATIONS_ERROR" />,
          )}
           {renderIf(haveNonDeletableAccSites && haveNonDeletableBlueSites)(
            <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_NOT_EMPTY_ACC_AND_BLUE_LOCATIONS_ERROR" />,
          )}
        </div>
      </>
    );
  }

  return null;
};
