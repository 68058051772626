
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// COMPONENTS
import { PresetTourBox } from 'components';

// Actions
import * as DeviceActions from 'actions/devices';
import { PropTypes } from 'prop-types';

const PresetTourBoxContainer = ({
  actions,
  ptzPresets,
  ptzTours,
  close,
  camera,
  deviceId,
  frameHeight,
  webRtcHelper,
  activeList,
  switchList,
  handleEditPtzTour,
  videoId,
}) => {
  return (
    <PresetTourBox
      actions={actions}
      ptzPresets={ptzPresets}
      ptzTours={ptzTours}
      close={close}
      camera={camera}
      deviceId={deviceId}
      maxHeight={frameHeight}
      webRtcHelper={webRtcHelper}
      activeList={activeList}
      switchList={switchList}
      handleEditPtzTour={handleEditPtzTour}
      videoId={videoId}
    />
  );
};

PresetTourBoxContainer.defaultProps = {
  frameHeight: 400,
  ptzPresets: null,
  ptzTours: null,
  close: () => {},
  camera: null,
  deviceId: null,
  webRtcHelper: null,
  activeList: null,
  switchList: null,
  handleEditPtzTour: () => {},
  videoId: null,
};

PresetTourBoxContainer.propTypes = {
  frameHeight: PropTypes.number,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  ptzPresets: PropTypes.objectOf(PropTypes.any),
  ptzTours: PropTypes.objectOf(PropTypes.any),
  close: PropTypes.func,
  camera: PropTypes.objectOf(PropTypes.any),
  deviceId: PropTypes.string,
  webRtcHelper: PropTypes.objectOf(PropTypes.any),
  activeList: PropTypes.objectOf(PropTypes.any),
  switchList: PropTypes.objectOf(PropTypes.any),
  handleEditPtzTour: PropTypes.func,
  videoId: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  const deviceActions = DeviceActions;
  return {
    actions: bindActionCreators(deviceActions, dispatch),
  };
}

function mapStateToProps(state, ownProps) {
  let ptzPresets = { presets: [] };
  let ptzTours = null;

  if (
    ownProps.camera &&
    state.devices.ptz &&
    state.devices.ptz[ownProps.camera.Id]
  ) {
    ptzPresets = state.devices.ptz[ownProps.camera.Id].presets;
    ptzTours = state.devices.ptz[ownProps.camera.Id].tours;
  }

  return {
    ptzPresets,
    ptzTours,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresetTourBoxContainer);
