import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconArrowOut = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 10 23"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <rect
          id="path-1"
          x="0"
          y="0"
          width={props.width}
          height={props.height}
          rx="4"
        />
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Artboard" transform="translate(-227.000000, -105.000000)">
          <g id="i/o-compact" transform="translate(203.000000, 103.000000)">
            <g id="Group" transform="translate(24.000000, 2.000000)">
              <path
                d="M0,0 L8,0 C9.1045695,-2.02906125e-16 10,0.8954305 10,2 L10,21 C10,22.1045695 9.1045695,23 8,23 L0,23 L0,0 Z"
                id="Rectangle-15"
                fill="#006e99"
              />
              <polygon
                id="Triangle"
                fill="#FFFFFF"
                transform="translate(5.666667, 12.466667) rotate(-270.000000) translate(-5.666667, -12.466667) "
                points="5.66666667 10.3333333 9.66666667 14.6 1.66666667 14.6"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconArrowOut.defaultProps = {
  className: null,
  width: '10px',
  height: '23px',
};

export default IconArrowOut;
