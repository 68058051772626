import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as toggleActions from 'actions/toggle';
import { toggleIcon, toggleIconDisabled } from './Buttons.styles.css';

// button that has no visual appearance, just displays the child content
const ToggleIconButton = ({
  actions,
  disabled,
  iconActive,
  iconInactive,
  id,
  isActive,
  onClick,
  onMouseDown,
  revertOn,
  shouldRevert,
  styleClass,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(isActive || undefined);
  }, [setActive, isActive]);

  useEffect(() => {
    if (shouldRevert) {
      actions.clearToggle(revertOn);
      setActive(isActive);
    }
  }, [actions, isActive, shouldRevert, revertOn]);

  return (
    <button
      className={`${disabled ? toggleIconDisabled : toggleIcon} ${styleClass &&
        styleClass}`}
      id={id}
      onClick={ev => (!disabled ? onClick(ev) : null)}
      onMouseDown={() => (!isActive ? onMouseDown() : null)}
      type="button"
    >
      {active ? iconActive : iconInactive}
    </button>
  );
};

ToggleIconButton.defaultProps = {
  disabled: false,
  id: 'btnToggleIcon',
  isActive: false,
  onClick() {},
  onMouseDown() {},
  revertOn: null,
  shouldRevert: false,
  styleClass: '',
};

ToggleIconButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  iconActive: PropTypes.objectOf(PropTypes.object).isRequired,
  iconInactive: PropTypes.objectOf(PropTypes.object).isRequired,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  revertOn: PropTypes.string,
  shouldRevert: PropTypes.bool,
  styleClass: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const shouldRevert =
    ownProps.revertOn &&
    state.toggle.actions.find(
      action => action.toggleAction === ownProps.revertOn,
    );
  return {
    shouldRevert: !!shouldRevert,
  };
};

const mapDispatchtoProps = dispatch => {
  return {
    actions: bindActionCreators({ ...toggleActions }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(ToggleIconButton);
