import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconTogglePanTilt = props => {
  return (
    <div className={props.className} title="Pan-Tilt">
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="PTZ-icons"
            transform="translate(-38.000000, -22.000000)"
            fill="#FFFFFF"
          >
            <g id="ic_PTZ_icon" transform="translate(36.000000, 20.000000)">
              <g id="Drag-to-pan" transform="translate(2.000000, 2.000000)">
                <polygon id="Path" points="6 5 10 0 14 5" />
                <polygon id="Path" points="14 15 10 20 6 15" />
                <polygon id="Path" points="5 14 0 10 5 6" />
                <polygon id="Path" points="15 6 20 10 15 14" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconTogglePanTilt.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
};

export default IconTogglePanTilt;
