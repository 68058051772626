import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { GEAR_ICON_ID } from '../constants/ElementId';

const IconGear = ({ className, fill, height, title, translate, width }) => {
  return (
    <div
      id={GEAR_ICON_ID}
      style={{ height: `${height}px`, width: `${width}px` }}
      title={translate(title)}
    >
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 20 20"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <path
          d="M58.66,18 C56.451,18 54.66,16.209 54.66,14 C54.66,11.791 56.451,10 58.66,10 C60.869,10 62.66,11.791 62.66,14 C62.66,16.209 60.869,18 58.66,18 M66.66,14 C66.66,13.259 66.552,12.544 66.362,11.862 L68.32,10.732 L66.32,7.268 L64.365,8.396 C63.363,7.376 62.088,6.631 60.66,6.262 L60.66,4 L56.66,4 L56.66,6.262 C55.232,6.631 53.957,7.376 52.955,8.396 L51,7.268 L49,10.732 L50.958,11.862 C50.768,12.544 50.66,13.259 50.66,14 C50.66,14.741 50.768,15.456 50.958,16.138 L49,17.268 L51,20.732 L52.955,19.604 C53.957,20.624 55.232,21.369 56.66,21.738 L56.66,24 L60.66,24 L60.66,21.738 C62.088,21.369 63.363,20.624 64.365,19.604 L66.32,20.732 L68.32,17.268 L66.362,16.138 C66.552,15.456 66.66,14.741 66.66,14"
          fill={fill}
          fillRule="evenodd"
          transform="translate(-49 -4)"
        />
      </svg>
    </div>
  );
};

IconGear.defaultProps = {
  className: '',
  fill: '#494949',
  height: '20px',
  title: 'DEVICE_DETAILS.CAMERAS_TAB.SETTINGS',
  width: '20px',
};

IconGear.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconGear);
