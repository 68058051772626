import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from '../constants/app';

const IconNavHealthMonitor = ({ className, fill, height, title, width }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      title={title}
      version="1.1"
      viewBox="0 0 22 20"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <path d="M13,17 L14,19 L18,19 L18,20 L4,20 L4,19 L8,19 L9,17 L13,17 Z M12.87,3.58 L16.38,8.25 L19,8.25 L19,9.75 L15.63,9.75 L13.13,6.42 L10,12.68 L7.79,8.27 L6.31,9.75 L3,9.75 L3,8.25 L5.69,8.25 L8.21,5.73 L10,9.32 L12.87,3.58 Z M20,0 L2,0 C0.8954305,0 0,0.8954305 0,2 L0,14 C0,15 0,16 2,16 L20,16 C21.1045695,16 22,15.1045695 22,14 L22,2 C22,0.8954305 21.1045695,0 20,0 Z" />
    </svg>
  );
};

IconNavHealthMonitor.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

IconNavHealthMonitor.defaultProps = {
  className: null,
  fill: '#939597',
  height: '44px',
  title: 'Health Monitor',
  width: '60px',
};

export default IconNavHealthMonitor;
