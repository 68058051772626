import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function clustersReducer(state, action) {
  switch (action.type) {
    case types.RECEIVE_ALL_CLUSTERS: {
      if (action.clusters) {
        return { ...state, clusters: action.clusters };
      }
      return state;
    }
    case types.REMOVE_CLUSTER: {
      const clusterIndex = state.clusters.findIndex(
        cluster => cluster.Id === action.clusterId,
      );
      if (clusterIndex >= 0) {
        const clusters = state.clusters
          .slice(0, clusterIndex)
          .concat(state.clusters.slice(clusterIndex + 1));
        return Object.assign({}, state, {
          clusters,
        });
      }
      return state;
    }
    case types.RECEIVE_CLUSTER: {
      const newState = { ...state };
      const clusterIndex = state.clusters.findIndex(
        cluster => cluster.Id === action.cluster.Id,
      );

      if (clusterIndex >= 0) {
        newState.clusters[clusterIndex] = action.cluster;
      } else {
        newState.clusters = [...newState.clusters, action.cluster];
      }

      return newState;
    }
    // clear cache when user logs out or changes org/customer
    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().clusters;
    }
    default:
      return state || initialState().clusters;
  }
}
