// Libs
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';

// Styles
import { disabledIcon, iconWrapper } from './Icon.styles.css';

/* iconName should be as per the icon name of the repective icons  found in \Client\prod\ folder.
 iconClass should have css style to be used for the icon like height, width, color etc */

const Icon = ({
  color,
  disabled,
  iconClass,
  id,
  renderBare,
  size,
  title,
  translate,
}) => {
  if (renderBare) {
    return (
      <msi-icon-native
        class={`${iconClass} ${disabled ? disabledIcon : ''}`}
        color={color}
        id={id}
        name={id}
        title={title ? translate(title) : ''}
      />
    );
  }
  return (
    <div className={iconWrapper}>
      <msi-icon-native
        class={`${disabled ? disabledIcon : ''} ${iconClass}`}
        color={color}
        disabled={disabled}
        id={id}
        name={id}
        size={size}
        title={title ? translate(title) : ''}
      />
    </div>
  );
};

Icon.defaultProps = {
  color: '',
  disabled: false,
  iconClass: '',
  renderBare: false,
  size: '',
  title: '',
};

Icon.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  renderBare: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(Icon);
