import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const clustersSelector = state => state.clusters.clusters;
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const clusterLocationMapSelector = createDeepEqualSelector(
  clustersSelector,
  clusters => {
    const clusterLocationArray = [];
    clusters.forEach(cluster => {
      clusterLocationArray.push({ [cluster.Id]: cluster.LocationId });
    });
    const clusterLocationMap = Object.assign({}, ...clusterLocationArray);
    return clusterLocationMap;
  },
);
export default clusterLocationMapSelector;
