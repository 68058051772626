// libs
import React from 'react';

const GridLines = ({
  xScale,
  yScale,
  xTicks,
  yTicks,
  height,
  width,
  xStart,
  yStart,
  coloredBackground
}) => {
  xStart = xStart || 0;
  yStart = yStart || 0;
  const xValues = xTicks ? xScale.ticks(xTicks) : xScale.domain();
  const yValues = yTicks ? yScale.ticks(yTicks) : yScale.domain();
  // Start with the border
  const grid = [
    <line
      key={`x_${0}`}
      id={`x_${0}`}
      x1={0}
      x2={0}
      y1={0}
      y2={height}
      transform={`translate(${xStart}, ${yStart})`}
    />,
    <line
      key={"x_end"}
      id={"x_end"}
      x1={0}
      x2={0}
      y1={0}
      y2={height}
      transform={`translate(${width + xStart}, ${yStart})`}
    />,
    <line
      key={`y_${`translate(${xStart}, ${yStart})`}`}
      id={`y_${`translate(${xStart}, ${yStart})`}`}
      x1={0}
      x2={width}
      y1={0}
      y2={0}
      transform={`translate(${xStart}, ${yStart})`}
    />,
    <line
      key={"y_end"}
      id={"y_end"}
      x1={0}
      x2={width}
      y1={0}
      y2={0}
      transform={`translate(${xStart}, ${height + yStart})`}
    />,
  ];

  if (coloredBackground) {
    let lastY = 0;
    yValues.forEach((y, i) => {
      if (i % 2) {
        grid.push(
          <rect
            key={`y_${y}`}
            width={width}
            height={lastY - yScale(y)}
            stroke="none"
            transform={`translate(${xStart}, ${yScale(y)})`}
          />,
        );
      }
      lastY = yScale(y);
    });
  }
  else {
    yValues.forEach((y, i) => {
      grid.push(
        <line
          key={`y_${y}`}
          id={`y_${y}`}
          x1={0}
          x2={width}
          y1={0}
          y2={0}
          transform={`translate(${xStart}, ${yScale(y)})`}
          strokeDasharray={i === 0 ? '0' : '3'} // Solid border for x base
          stroke="#DDDDDD"
        />
      );
    });
  }

  xValues.forEach(x => {
    grid.push(
      <line
        key={`x_${x}`}
        id={`x_${x}`}
        x1={0}
        x2={0}
        y1={0}
        y2={height}
        transform={`translate(${xScale(x)}, ${yStart})`}
      />,
    );
  });
  return (
    <g
      style={{
        strokeWidth: '1px',
        fill: coloredBackground ? '#f4f4f4' : '#FFFFFF',
        stroke: coloredBackground ? '#dddddd' : 'none',
      }}
    >
      {grid}
    </g>
  );
};

export default GridLines;
