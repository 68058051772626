import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomInMax = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 11 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1240.000000, -447.000000)"
      >
        <path
          d="M1243.23665,453.848925 C1243.1775,453.540973 1243.26715,453.210091 1243.50559,452.971655 L1248.80857,447.668668 C1249.19067,447.286572 1249.81017,447.286572 1250.19226,447.668668 C1250.57436,448.050764 1250.57436,448.670264 1250.19226,449.05236 L1245.3957,453.848925 L1250.19226,458.64549 C1250.57436,459.027586 1250.57436,459.647086 1250.19226,460.029182 C1249.81017,460.411278 1249.19067,460.411278 1248.80857,460.029182 L1243.50559,454.726195 C1243.26715,454.487758 1243.1775,454.156876 1243.23665,453.848925 Z M1240.97842,447.229451 C1241.51878,447.229451 1241.95684,447.667503 1241.95684,448.207868 L1241.95684,459.489981 C1241.95684,460.030346 1241.51878,460.468399 1240.97842,460.468399 C1240.43805,460.468399 1240,460.030346 1240,459.489981 L1240,448.207868 C1240,447.667503 1240.43805,447.229451 1240.97842,447.229451 Z"
          id="icon-btn-zoom-in-max-v2"
          fill={props.fill}
          transform="translate(1245.500000, 453.848925) rotate(180.000000) translate(-1245.500000, -453.848925) "
        />
      </g>
    </svg>
  );
};

IconBtnZoomInMax.defaultProps = {
  className: null,
  width: '11px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomInMax;
