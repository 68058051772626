import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { useForm } from 'react-hook-form';
import { MainContentWrapper } from 'lib';

import { DefaultFormButtons, LabeledBox } from 'components';

import { PropTypes } from 'prop-types';
import {
  COMPUTER_LOCALE,
  DECIMAL_SEPARATOR,
  LONG_DATE,
  NO_PREFERENCE_FORMAT_DATA,
  SHORT_DATE,
  THOUSAND_SEPARATOR,
  TIME,
  WEEK_BEGINS,
} from './constants';

import {
  formatGroup,
  formatsWrap,
  halfPageColumn,
  radioOptionWrap,
} from './styles.css';

const ProfileFormatsForm = ({ handleFormSubmit, initialValues, translate }) => {
  const { formState, handleSubmit, register, reset, watch } = useForm({
    mode: 'onChange',
  });
  const watchComputerLocale = watch(COMPUTER_LOCALE.name);

  const onSubmit = data => {
    let formatData = { LocalizationPreference: data };
    if (watchComputerLocale === COMPUTER_LOCALE.options[0].value) {
      formatData = NO_PREFERENCE_FORMAT_DATA;
    }
    handleFormSubmit({
      ...initialValues,
      ...formatData,
    });
  };

  const renderRadioGroup = (group, initialValue) => {
    return group.options.map(option => (
      <div key={option.value} className={radioOptionWrap}>
        <input
          ref={register({ required: true })}
          defaultChecked={initialValue === option.value}
          disabled={
            watchComputerLocale === COMPUTER_LOCALE.options[0].value &&
            group.name !== COMPUTER_LOCALE.name
          }
          name={group.name}
          type="radio"
          value={option.value}
        />
        {translate(option.label)}
        <br />
      </div>
    ));
  };

  return (
    <form className={formatsWrap} onSubmit={handleSubmit(onSubmit)}>
      <div className={halfPageColumn}>
        <MainContentWrapper>
          <LabeledBox labelId="SECONDARY_NAV.TABS.FORMATS.LABEL" />
          <label>
            {translate('PROFILE.FORMAT_FIELDS.COMPUTER_LOCALE_LABEL')}
          </label>
          <div className={formatGroup}>
            {renderRadioGroup(
              COMPUTER_LOCALE,
              initialValues.LocalizationPreference.PreferredDateFormat,
            )}
          </div>

          <label>{translate('PROFILE.FORMAT_FIELDS.SHORT_DATE')}</label>
          <div className={formatGroup}>
            {renderRadioGroup(
              SHORT_DATE,
              initialValues.LocalizationPreference.PreferredShortDateFormat,
            )}
          </div>

          <label>{translate('PROFILE.FORMAT_FIELDS.LONG_DATE')}</label>
          <div className={formatGroup}>
            {renderRadioGroup(
              LONG_DATE,
              initialValues.LocalizationPreference.PreferredLongDateFormat,
            )}
          </div>

          <label>{translate('PROFILE.FORMAT_FIELDS.TIME')}</label>
          <div className={formatGroup}>
            {renderRadioGroup(
              TIME,
              initialValues.LocalizationPreference.PreferredTimeFormat,
            )}
          </div>

          <label>{translate('PROFILE.FORMAT_FIELDS.WEEK_BEGINS')}</label>
          <select
            ref={register({ required: true })}
            className={formatGroup}
            defaultValue={
              initialValues.LocalizationPreference.PreferredWeekStartDay
            }
            disabled={watchComputerLocale === COMPUTER_LOCALE.options[0].value}
            name={WEEK_BEGINS.name}
          >
            {WEEK_BEGINS.options.map(option => (
              <option key={option.value} value={option.value}>
                {translate(option.label)}
              </option>
            ))}
          </select>

          <label>{translate('PROFILE.FORMAT_FIELDS.DECIMAL_SEPARATOR')}</label>
          <div className={formatGroup}>
            {renderRadioGroup(
              DECIMAL_SEPARATOR,
              initialValues.LocalizationPreference.PreferredDecimalsSeparator,
            )}
          </div>

          <label>{translate('PROFILE.FORMAT_FIELDS.THOUSAND_SEPARATOR')}</label>
          <div className={formatGroup}>
            {renderRadioGroup(
              THOUSAND_SEPARATOR,
              initialValues.LocalizationPreference.PreferredThousandsSeparator,
            )}
          </div>

          <DefaultFormButtons
            invalid={!formState.isValid}
            pristine={!formState.dirty}
            resetForm={reset}
          />
        </MainContentWrapper>
      </div>
    </form>
  );
};

ProfileFormatsForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

ProfileFormatsForm.defaultProps = {
  handleFormSubmit: () => {},
  initialValues: { LocalizationPreference: {} },
};

export default withLocalize(React.memo(ProfileFormatsForm));
