import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ onReset, onTick }) => {
  const [time, setTime] = useState(0);

  const increment = useCallback(() => {
    const incr = time + 1;
    setTime(incr);
    if (onTick) onTick(incr);
  }, [onTick, time]);

  const reset = useCallback(() => {
    setTime(0);
    if (onReset) onReset();
  }, [onReset]);

  useEffect(() => {
    const timer = setInterval(increment, 1000);
    document.addEventListener('mousedown', reset);
    document.addEventListener('mousemove', reset);
    return () => {
      document.removeEventListener('mousedown', reset);
      document.removeEventListener('mousemove', reset);
      clearInterval(timer);
    };
  }, [increment, reset]);

  return <span />;
};

Timer.defaultProps = {
  onReset: () => {},
  onTick: () => {},
};

Timer.propTypes = {
  onReset: PropTypes.func,
  onTick: PropTypes.func,
};

export default Timer;
