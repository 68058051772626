export default function filterCameras(blueCameras, accCameras) {
  const filteredCameras = [];
  accCameras.forEach(camera => {
    const {
      id,
      storage: { maxRetention, camerasLinked, streamRetentionAmount },
      bandwidth,
    } = camera;
    const blueCameraFound = blueCameras.find(blueCamera => {
      return blueCamera.RemoteId === id;
    });
    if (blueCameraFound) {
      filteredCameras.push({
        blueId: blueCameraFound.Id,
        accId: id,
        maxRetention,
        camerasLinked,
        streamRetentionAmount,
        bandwidth,
      });
    }
  });
  return filteredCameras;
}
