import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconUpArrow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>{props.translate(props.title)}</title>
      <defs>
        <path
          id={`a_${props.iconId}`}
          d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8z"
        />
        <path id={`d_${props.iconId}`} d="M0 0h24v24H0z" />
        <filter
          x="-29.2%"
          y="-20.8%"
          width="158.3%"
          height="158.3%"
          filterUnits="objectBoundingBox"
          id={`c_${props.iconId}`}
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id={`b_${props.iconId}`} fill="#fff">
          <use xlinkHref={`#a_${props.iconId}`} />
        </mask>
        <g mask={`url(#b_${props.iconId})`}>
          <use
            fill="#000"
            filter={`url(#c_${props.iconId})`}
            xlinkHref={`#d_${props.iconId}`}
          />
          <use fill={props.fillColor} xlinkHref={`#d_${props.iconId}`} />
        </g>
      </g>
    </svg>
  );
};

IconUpArrow.defaultProps = {
  title: 'DEVICE_DETAILS.UP_ARROW',
  iconId: 'triangle_up_0',
  width: '21px',
  height: '24px',
  fillColor: ACTIVE_COLOR_DEFAULT,
};

IconUpArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string,
  title: PropTypes.string,
  iconId: PropTypes.string,
  translate: PropTypes.func,
};

export default withLocalize(IconUpArrow);
