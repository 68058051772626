import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { Translate } from 'react-localize-redux';
/* Styles */
import {
  hoverboxHeader,
  hoverboxInteractable,
  hoverboxStyle,
} from './styles.css';

class Tooltip extends Component {
  handleClickOutside = () => {
    const { onClickout, ownKey, selectedKey } = this.props;
    if (ownKey === selectedKey) {
      onClickout(ownKey);
    }
  };

  handleClickInside = () => {
    const { dataPoint, onClick, ownKey } = this.props;
    if (onClick) {
      onClick(ownKey, dataPoint);
    }
  };

  render() {
    const {
      chartWidth,
      className,
      dataPoint,
      filterName,
      filterType,
      onClick,
      ownKey,
      radius,
      selectedKey,
    } = this.props;
    const style = {
      display: ownKey === selectedKey ? 'inline-block' : 'none',
      position: 'absolute',
    };
    if (chartWidth && dataPoint.x >= chartWidth / 2) {
      style.top = `${dataPoint.y}px`;
      style.right = `${-dataPoint.x + radius}px`;
    } else {
      style.top = `${dataPoint.y}px`;
      style.left = `${dataPoint.x + radius}px`;
    }
    return (
      <div
        key={ownKey}
        className={className || hoverboxStyle}
        onClick={this.handleClickInside}
        onKeyPress={() => {}}
        role="button"
        style={style}
        tabIndex={0}
      >
        <p className={hoverboxHeader}>{dataPoint.xString}</p>
        <p>
          <Translate
            data={{ filterName }}
            id={`ALARMS.NOTIFICATIONS.CHART.FILTER_TYPE_${filterType.toUpperCase()}_TOOLTIP`}
          />
        </p>
        <p>
          <Translate
            data={{
              linkToNotifications: (
                <span className={hoverboxInteractable}>
                  {dataPoint.yString}
                </span>
              ),
            }}
            id="ALARMS.NOTIFICATIONS.CHART.NOTIFICATIONS_TOOLTIP"
            options={{ renderInnerHtml: true }}
          />
        </p>
      </div>
    );
  }
}

Tooltip.defaultProps = {
  className: '',
  filterName: '',
  filterType: '',
  onClick: () => {},
};

Tooltip.propTypes = {
  chartWidth: PropTypes.number.isRequired,
  className: PropTypes.string,
  dataPoint: PropTypes.objectOf(PropTypes.any).isRequired,
  filterName: PropTypes.string,
  filterType: PropTypes.string,
  onClick: PropTypes.func,
  onClickout: PropTypes.func.isRequired,
  ownKey: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  selectedKey: PropTypes.number.isRequired,
};

export default onClickOutside(Tooltip);
