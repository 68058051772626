// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Translate } from 'react-localize-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import renderIf from 'render-if';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Actions
import * as DeviceActions from 'actions/devices';
import * as MenuActions from 'actions/actionMenu';
import { getTimezones } from 'actions/utilities';
import { getLocations } from 'actions/locations';
import { hideModal, showModal } from 'actions/modal';

// Components
import CameraLoginModal from 'components/ServerDetail/CameraLoginModal';
import ConnectedCamerasTable from 'components/ServerDetail/ConnectedCamerasTable';
import DiscoveredCamerasTable from 'components/ServerDetail/DiscoveredCamerasTable';
import EmptyPlaceholder from 'lib/EmptyPlaceholder/EmptyPlaceholder';
import FDSCameraResetPasswordForm from 'components/ServerDetail/FDSCameraResetPasswordForm';
import MainContentWrapper from 'lib/ContentWrapper/MainContentWrapper';
import ServerDetailGeneral from 'components/ServerDetail/ServerDetailGeneral';
import FindCameraFormContainer from 'containers/FindCameraForm/FindCameraFormContainer';
import ModalContainer from 'containers/Modal/ModalContainer';
import PageMessage from 'containers/PageMessage/PageMessage';
import { ListNav, PageTitle } from 'lib';
import { generateSort } from 'util/generateSort';
import { getSubscriptionState } from 'util/validation';
import ComparableIpAddress from 'util/ComparableFirmwareVersion';
import * as permissions from 'util/permissions';
import ServerDetailsNavMenu from './ServerDetailsNavMenu';

// Constants
import * as CameraTypes from 'constants/cameraTypes';
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import {
  PATH_DEVICES,
  PATH_SEGMENT_CAMERAS,
  PATH_SEGMENT_DIGITAL_IO,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_POE,
  PATH_SEGMENT_RECORDING_SCHEDULE,
  PATH_SEGMENT_VIDEO_RETENTION,
  PATH_SERVERS,
} from 'constants/urlPaths';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';

// Styles
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';
import ServerDetailRetentionContainer from './ServerDetailRetentionContainer';
import ServerDetailRecordingScheduleContainer from './ServerDetailRecordingScheduleContainer';
import ServerDetailPOEContainer from './ServerDetailPOEContainer';
import ServerDetailDigitalOutContainer from './ServerDetailDigitalOutContainer';
import {
  connectedCamerasTable,
  discoveredCamerasContainer,
  firmwareUpgradingFeedbackText,
  firmwareUpgradingFeedbackTextWrapper,
  pageErrorWrapper,
} from './ServerDetailContainer.css';

const darkHorseModel = 'VMA-BLU-4C1';
const noCameraSelected = 'No camera selected';

// Class
class ServerDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableRestartServer: false,
      discCamerasSearchText: '',
      discoveredCamerasSource: null,
      findCameraFormData: null,
      restartAppliancePressed: false,
      selectedCameraId: null,
      upgradeButtonPressed: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      connectedCameras,
      history,
      isFetchingDevicePoe,
      orgId,
      server,
      serverId,
      serverLocation,
      timezones,
    } = this.props;
    // Matches the way connected cameras are discovered on the devices page
    if (
      serverLocation &&
      !getSubscriptionState(serverLocation.PackageSubscription).isValid
    ) {
      history.push(PATH_DEVICES);
      return;
    }
    if (orgId) {
      actions.getServerCapabilities(serverId);
      actions.getDiscoveredCameras(serverId);
      actions.getSupportedCameraList(serverId);
      if (server.Model !== undefined) {
        actions.getLatestFirmware(serverId, server.Model);
      }
      if (!connectedCameras || connectedCameras.length === 0) {
        actions.getAllCameras();
      }
      if (!server || !server.Name) {
        actions.getAllDevices();
      }
      if (!timezones || timezones.length === 0) {
        actions.getTimezones();
      }

      document.addEventListener('mouseup', this.pageMouseUp, true);
      actions.setSelectedServer(serverId);

      if (isFetchingDevicePoe !== true) {
        actions.getPoe(serverId);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      disableRestartServer,
      discCamerasSearchText,
      discoveredCamerasSource,
      selectedCameraId,
    } = this.state;

    const {
      actions,
      discoveredCameras,
      history,
      isFetchingDiscovered,
      isFetchingFirmwareImage,
      isFetchingServerLocation,
      messageType,
      orgId,
      selectedId,
      server,
      serverLocation,
    } = this.props;
    if (
      (discoveredCamerasSource === null ||
        discoveredCameras !== prevProps.discoveredCameras) &&
      isFetchingDiscovered === false
    ) {
      this.setState({
        discoveredCamerasSource: this.filterDiscoveredCameras(
          discCamerasSearchText,
          discoveredCameras,
        ),
      });
    }
    if (selectedId && selectedCameraId !== selectedId) {
      this.setState({
        selectedCameraId: selectedId,
      });
    }
    if (server.Model !== undefined && isFetchingFirmwareImage === null) {
      actions.getLatestFirmware(server.Id, server.Model);
    }
    if (
      (server.ConnectionState !== prevProps.server.ConnectionState ||
        messageType === messageTypes.DEVICE_ERROR) &&
      disableRestartServer === true
    ) {
      this.setState({ disableRestartServer: false });
    }
    if (
      !serverLocation &&
      !isFetchingServerLocation &&
      server &&
      server.LocationId
    ) {
      actions.getLocations(orgId);
    }
    if (
      !isFetchingServerLocation &&
      prevProps.isFetchingServerLocation &&
      serverLocation &&
      !getSubscriptionState(serverLocation.PackageSubscription).isValid
    ) {
      history.push(PATH_DEVICES);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.unSetServerBreadcrumb();
    actions.hideMenu();
    actions.clearCameras();
    document.removeEventListener('mouseup', this.pageMouseUp, true);
    actions.setSelectedServer(null);
  }

  get discoveredCamerasToShow() {
    const { discoveredCameras } = this.props;
    const { discoveredCamerasSource } = this.state;
    if (discoveredCameras && discoveredCameras.length > 0) {
      return discoveredCameras;
    }
    if (discoveredCamerasSource && discoveredCamerasSource.length > 0) {
      return discoveredCamerasSource;
    }
    return [];
  }

  getConnectedCamInitialValues = () => {
    const returnObject = {
      IpAddress: '',
      cameraPassword: '',
      cameraType: '',
      cameraUserName: ' ',
      streamURL: '',
    };
    const { connectedCameras } = this.props;
    const { selectedCameraId } = this.state;
    let selectedCamera = connectedCameras.find(c => c.Id === selectedCameraId);
    // If the camera is not authenticated, only discovered cameras will have the IP address.

    if (selectedCamera) {
      selectedCamera = this.mergeDiscoveredDataIntoConnected(selectedCamera);
      returnObject.cameraUserName = ' ';
      returnObject.cameraPassword = ' ';
      returnObject.cameraType =
        selectedCamera.Manufacturer || selectedCamera.mfr || '';
      returnObject.streamURL = selectedCamera.Uri || '';
      returnObject.IpAddress =
        selectedCamera.IpAddress || selectedCamera.ipAddress || '';
    }
    return returnObject;
  };

  getActiveCameras = cameras => {
    return cameras.filter(camera => {
      const { Active: active, ConnectionState: connectionState } = camera;

      const isActiveCam =
        connectionState === CameraTypes.CAMERA_CONNECTED || active;

      if (isActiveCam) {
        return camera;
      }
      return false;
    });
  };

  connectConnectedCamera = values => {
    let cameraId;
    let cameraName;
    let cameraUserName;
    let cameraPassword;
    const { actions, cameraLoginCamera, server, serverId } = this.props;

    let selectedCamera = cameraLoginCamera;
    if (selectedCamera) {
      selectedCamera = this.mergeDiscoveredDataIntoConnected(selectedCamera);
      cameraId = selectedCamera.RemoteId;
      cameraName = selectedCamera.name || selectedCamera.Name;
      cameraUserName = values.cameraUserName.trim();
      cameraPassword = values.cameraPassword.trim();

      actions.connectCamera(
        serverId,
        cameraId,
        cameraUserName,
        cameraPassword,
        selectedCamera.Model || selectedCamera.model,
        {
          cameraName,
          deviceName: server.Name,
          physicalAddress:
            selectedCamera.PhysicalAddress || selectedCamera.physicalAddress,
          uri: selectedCamera.uri || selectedCamera.Uri,
        },
      );
      this.toggleModal(null, false);
    } else {
      actions.showModal(modalTypes.SHOW_ERROR, {
        // Is this really going to happen?
        message: noCameraSelected,
      });
    }
    actions.hideMenu();
  };

  findCachedCredentials = selectedCamera => {
    const { discoveredCamerasCredentials: credentials } = this.props;
    const cameraIp = selectedCamera.ipAddress;
    const cameraUri = selectedCamera.uri || selectedCamera.Uri;

    // check if the camera's IP matches any stored credentials's IP
    let foundCredentials = credentials.find(c => c.address === cameraIp);

    // if not, check if the camera's IP falls into any stored credentials's IP range
    if (!foundCredentials) {
      const cam = new ComparableIpAddress(cameraIp);
      foundCredentials = credentials.find(c => {
        const low = new ComparableIpAddress(c.startIpAddress);
        const high = new ComparableIpAddress(c.endIpAddress);
        return cam.compare(low) >= 0 && cam.compare(high) <= 0;
      });
    }

    // if not, check if the camera's (RTSP) URI matches any stored credentials's URI
    if (!foundCredentials) {
      foundCredentials = credentials.find(c => c.uri === cameraUri);
    }

    if (!foundCredentials) {
      return { password: '', username: '' };
    }

    const { password, username } = foundCredentials;
    return { password, username };
  };

  connectDiscoveredCamera = cameraData => {
    const { actions, server, serverId } = this.props;
    const cameraId = cameraData.id || cameraData.Id;
    const cameraName = cameraData.name || cameraData.Name;

    const { password, username } = this.findCachedCredentials(cameraData);
    const cameraUserName = username;
    const cameraPassword = password;

    actions.connectCamera(
      serverId,
      cameraId,
      cameraUserName,
      cameraPassword,
      cameraData.model,
      {
        cameraName,
        deviceName: server.Name,
        ipAddress: cameraData.ipAddress,
        physicalAddress:
          cameraData.PhysicalAddress || cameraData.physicalAddress,
        uri: cameraData.uri || cameraData.Uri,
      },
    );
  };

  disconnectCamera = cameraData => {
    const { actions, server, serverId } = this.props;
    const cameraId = cameraData.id || cameraData.Id;
    const cameraName = cameraData.name || cameraData.Name;

    actions.disconnectCamera(serverId, cameraId, server.ConnectionState, {
      cameraName,
      deviceName: server.Name,
    });
  };

  mergeDiscoveredDataIntoConnected = selectedObject => {
    const { discoveredCameras } = this.props;
    let discoveredCamera = {};
    if (selectedObject) {
      discoveredCamera = discoveredCameras.find(
        cam => cam.physicalAddress === selectedObject.PhysicalAddress,
      );
    }
    return { ...selectedObject, ...discoveredCamera };
  };

  saveServerDetailsChanges = formValues => {
    const { actions, server } = this.props;
    let { Name: name, TimeZone: timeZone } = formValues;
    if (server.Name === name) {
      name = null;
    }
    if (server.TimeZone === timeZone) {
      timeZone = null;
    }
    actions.updateServerInfo(formValues.Id, name, timeZone);
  };

  upgradeConfirmed = () => {
    const { actions, server, serverId } = this.props;
    this.toggleModal(null, false);
    actions.firmwareUpgrade(serverId, {
      deviceName: server.Name,
    });
  };

  upgradeDeviceFirmware = () => {
    const { actions } = this.props;
    const modalProps = {
      handleCancel: () => {
        this.toggleModal(null, false);
      },
      hideModal: () => {
        this.toggleModal(null, false);
      },
      message: this.renderUpgradeModalMessage(),
      onOkClick: this.upgradeConfirmed,
      textConfirm: 'DEVICE_DETAILS.UPGRADE_MODAL.UPGRADE_NOW',
      title: <Translate id="DEVICE_DETAILS.UPGRADE_MODAL.MODAL_TITLE" />,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  renderUpgradeModalMessage = () => (
    <div>
      <Translate id="DEVICE_DETAILS.UPGRADE_MODAL.MODAL_HEADER" />
    </div>
  );

  onRestartApplianceClick = () => {
    const { actions, server, serverId } = this.props;
    this.setState({ disableRestartServer: true });
    actions.restartAppliance(serverId, {
      deviceName: server.Name,
    });
  };

  requestDeviceLogs = () => {
    const { actions, serverId } = this.props;
    actions.requestDeviceLogs(serverId);
  };

  onSecondaryButtonMouseDown = buttonId => {
    const { restartAppliancePressed, upgradeButtonPressed } = this.state;
    if (buttonId === 'btnUpgradeFirmware') {
      this.setState({
        upgradeButtonPressed: !upgradeButtonPressed,
      });
    } else if (buttonId === 'btnRestartAppliance') {
      this.setState({
        restartAppliancePressed: !restartAppliancePressed,
      });
    }
  };

  pageMouseUp = () => {
    const { restartAppliancePressed, upgradeButtonPressed } = this.state;
    if (upgradeButtonPressed || restartAppliancePressed) {
      this.setState({
        restartAppliancePressed: false,
        upgradeButtonPressed: false,
      });
    }
  };

  toggleModal = (modalType, show) => {
    const { actions } = this.props;
    if (show) {
      actions.showModal(modalType, {});
    } else {
      actions.hideModal();
    }
  };

  handleSearchTextChange = e => {
    const { discoveredCameras } = this.props;
    let searchResult = [];
    searchResult = this.filterDiscoveredCameras(
      e.target.value,
      discoveredCameras,
    );

    this.setState({
      discCamerasSearchText: e.target.value,
      discoveredCamerasSource: searchResult,
    });
  };

  filterDiscoveredCameras = (searchText, DisCameras) => {
    let resultCameras = [];

    if (
      searchText &&
      DisCameras &&
      searchText.length > 0 &&
      DisCameras.length > 0
    ) {
      const searchTerm = searchText.toLowerCase();
      DisCameras.forEach(disCamera => {
        if (
          disCamera.name.toLowerCase().includes(searchTerm) ||
          disCamera.ipAddress.toLowerCase().includes(searchTerm) ||
          disCamera.mfr.toLowerCase().includes(searchTerm) ||
          disCamera.model.toLowerCase().includes(searchTerm) ||
          disCamera.physicalAddress.toLowerCase().includes(searchTerm)
        ) {
          resultCameras.push(disCamera);
        }
      }, this);
    } else {
      resultCameras = DisCameras;
    }

    return resultCameras;
  };

  toggleFindCamera = () => {
    const { isFormVisible } = this.state;
    this.setState({
      isFormVisible: !isFormVisible,
    });
  };

  resetFDSPassword = menuProps => {
    const { actions } = this.props;
    const { rowIndex } = menuProps;
    this.setState(
      {
        selectedCameraId: menuProps.cameraData[rowIndex].Id,
      },
      () => {
        actions.showModal(modalTypes.FDS_CAMERA_RESET, {
          menuProps,
          rowIndex,
        });
      },
    );
  };

  showCameraLoginModal = (rowIndex, menuProps) => {
    const { actions } = this.props;
    this.setState(
      {
        selectedCameraId: menuProps.cameraData[rowIndex].Id,
      },
      () => {
        actions.showModal(modalTypes.CAMERA_LOGIN, {
          menuProps,
          rowIndex,
        });
      },
    );
  };

  hideCameraLoginModal = () => {
    this.toggleModal(modalTypes.CAMERA_LOGIN, false);
  };

  enableCamera = (rowIndex, menuProps) => {
    const { actions } = this.props;
    const camera = menuProps.cameraData[rowIndex];
    if (camera) {
      actions.enableCamera(camera.Id);
    }
  };

  disableCamera = (rowIndex, menuProps) => {
    const { actions } = this.props;
    const camera = menuProps.cameraData[rowIndex];
    if (camera) {
      actions.disableCamera(camera.Id);
    }
  };

  disCameraEmpty = () => {
    const { discCamerasSearchText, discoveredCamerasSource } = this.state;
    const { discoveredCameras } = this.props;
    return (
      discCamerasSearchText &&
      discoveredCamerasSource &&
      discoveredCameras.length > 0 && (
        <Translate id="DEVICE_DETAILS.CAMERAS_TAB.EMPTY_DISCOVERED_CAMERAS" />
      )
    );
  };

  updatePoE = (poeData = []) => {
    const { actions, server } = this.props;
    const deviceId = server.Id;
    actions.updatePoe(deviceId, null, poeData);
  };

  renderServerPoeContent = () => {
    const {
      actions,
      connectedCameras,
      discoveredCameras,
      isFetching,
      isFetchingGatewayDevicePoe,
      isFetchingServerCapabilities,
      poe,
      server,
    } = this.props;
    if (poe && poe.budgetmax !== 0 && poe.ports) {
      return (
        <ServerDetailPOEContainer
          cameras={connectedCameras}
          discoveredCameras={discoveredCameras}
          hideModal={actions.hideModal}
          isFetching={isFetching}
          poe={poe}
          server={server}
          showModal={actions.showModal}
          updatePoE={this.updatePoE}
        />
      );
    }
    if (isFetchingServerCapabilities || isFetchingGatewayDevicePoe) {
      return (
        <MainContentWrapper>
          <EmptyPlaceholder isFetching />
        </MainContentWrapper>
      );
    }
    return (
      <MainContentWrapper>
        <PageMessage
          header=" "
          keepOpen
          messageStyle="warning"
          translateBody="SERVER.POE.POE_NOT_SUPPORTED"
          visible
        />
      </MainContentWrapper>
    );
  };

  renderServerDigitalOutContent = () => {
    const {
      isFetchingServerCapabilities,
      server,
      serverCapabilities,
      serverId,
    } = this.props;
    if (
      serverCapabilities &&
      serverCapabilities.find(cap => cap === 'TIMELINE_MULTI_LAYER')
    ) {
      if (server.Model === darkHorseModel) {
        return (
          <MainContentWrapper>
            <PageMessage
              header=" "
              keepOpen
              messageStyle="warning"
              translateBody="SERVER.DIGITAL_OUT.DIGITAL_OUT_NOT_SUPPORTED"
              visible
            />
          </MainContentWrapper>
        );
      }
      return <ServerDetailDigitalOutContainer serverId={serverId} />;
    }
    if (isFetchingServerCapabilities) {
      return (
        <MainContentWrapper>
          <EmptyPlaceholder isFetching />
        </MainContentWrapper>
      );
    }

    return (
      <MainContentWrapper>
        <PageMessage
          header=" "
          keepOpen
          messageStyle="warning"
          translateBody="SERVER.DIGITAL_OUT.UPGRADE_FIRMWARE_WARNING"
          visible
        />
      </MainContentWrapper>
    );
  };

  renderVideoRetentionContent = () => {
    const { isFetchingServerCapabilities, serverCapabilities } = this.props;
    if (serverCapabilities.find(cap => cap === 'DATA_AGING_MANAGEMENT')) {
      return <ServerDetailRetentionContainer />;
    }
    if (isFetchingServerCapabilities) {
      return (
        <MainContentWrapper>
          <EmptyPlaceholder isFetching />
        </MainContentWrapper>
      );
    }
    return (
      <MainContentWrapper>
        <PageMessage
          header=" "
          keepOpen
          messageStyle="warning"
          translateBody="DEVICE_DETAILS.VIDEO_RETENTION_TAB.UPGRADE_FIRMWARE_WARNING"
          visible
        />
      </MainContentWrapper>
    );
  };

  renderRecordingScheduleContent = () => {
    const {
      connectedCameras,
      isFetchingServerCapabilities,
      server,
      serverCapabilities,
    } = this.props;
    if (
      serverCapabilities.find(cap => cap === 'RECORDING_SCHEDULE_MANAGEMENT')
    ) {
      return (
        <ServerDetailRecordingScheduleContainer
          // Prevent recording schedules from crashing
          cameras={connectedCameras.filter(cam => cam.Connected && cam.Active)}
          server={server}
        />
      );
    }
    if (isFetchingServerCapabilities) {
      return (
        <MainContentWrapper>
          <EmptyPlaceholder isFetching />
        </MainContentWrapper>
      );
    }
    return (
      <MainContentWrapper>
        <PageMessage
          header=" "
          keepOpen
          messageStyle="warning"
          translateBody="DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.UPGRADE_FIRMWARE_WARNING"
          visible
        />
      </MainContentWrapper>
    );
  };

  getCameraFDSModalContent = values => (
    <ModalContainer
      handleCancel={this.hideCameraLoginModal}
      modalTitle={
        <Translate id="CAMERA.SET_CAMERA_FDS_PASSWORD_MODAL.MODAL_TITLE" />
      }
    >
      <FDSCameraResetPasswordForm
        handleCancel={this.hideCameraLoginModal}
        initialValue={values}
        onSubmit={formValues => {
          const {
            actions,
            cameraLoginCamera: selectedCamera,
            server,
          } = this.props;
          const { cameraPassword, cameraUserName } = formValues;
          const { Id: serverId } = server;

          let factoryDefault;
          let cameraId;
          let cameraModel;
          let cameraName;

          // Connected Cameras have a TenantId field, discovered Cameras do not.
          // Use this fact to determine from which kind of Camera we are deriving information.
          if (selectedCamera.TenantId) {
            ({
              Model: cameraModel,
              Name: cameraName,
              RemoteId: cameraId,
            } = selectedCamera);
          } else {
            ({
              factoryDefault,
              id: cameraId,
              model: cameraModel,
              name: cameraName,
            } = selectedCamera);
          }

          const connectData = {
            cameraName,
            deviceName: server.Name,
            factoryDefault,
            ipAddress: selectedCamera.ipAddress || selectedCamera.IpAddress,
            physicalAddress:
              selectedCamera.PhysicalAddress || selectedCamera.physicalAddress,
            uri: selectedCamera.uri || selectedCamera.Uri,
          };

          actions.connectCamera(
            serverId,
            cameraId,
            cameraUserName,
            cameraPassword,
            cameraModel,
            connectData,
          );

          this.toggleModal(null, false);
        }}
      />
    </ModalContainer>
  );

  getCameraLoginModalContent = values => (
    <ModalContainer
      handleCancel={this.hideCameraLoginModal}
      modalTitle={<Translate id="CAMERA.LOGIN_MODAL.MODAL_TITLE" />}
    >
      <CameraLoginModal
        handleCancel={this.hideCameraLoginModal}
        initialValue={values}
        onSubmit={this.connectConnectedCamera}
      />
    </ModalContainer>
  );

  render() {
    const {
      activeLanguage,
      canChangeCustomer,
      canGetLogs,
      connectedCameras,
      firmwareImage,
      isCameraFDSModalOpen,
      isCameraLoginModalOpen,
      isFetchingAllCameras,
      isFetchingDiscovered,
      isFindCameraModalOpen,
      location,
      logs,
      messageType,
      preferredLongDateFormat,
      preferredTimeFormat,
      server,
      serverId,
      serverLocation,
      supportedCameras,
      timezones,
      upgrade,
    } = this.props;
    const {
      disableRestartServer,
      restartAppliancePressed,
      upgradeButtonPressed,
    } = this.state;
    const isFirmwareUpgrading = !!upgrade.status;
    const activeCameras = this.getActiveCameras(connectedCameras);
    const isServerDisconnected =
      server.ConnectionState === CameraTypes.CAMERA_DISCONNECTED;

    const path = `${PATH_DEVICES}${(location.state &&
      location.state.fromSegment) ||
      ''}`;
    return (
      <>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          navigationTabs={
            <ServerDetailsNavMenu
              isDisconnected={isServerDisconnected}
              serverId={serverId}
            />
          }
          pageTitle={
            <PageTitle backPath={path} showBackButton title={server.Name} />
          }
        />
        <Switch>
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_GENERAL}`}
            render={() => {
              return (
                <>
                  <div className={pageErrorWrapper}>
                    <PageMessage
                      messageType={[
                        messageTypes.DEVICE_ERROR,
                        messageTypes.DEVICE_SUCCESS,
                      ]}
                    />
                  </div>
                  <ServerDetailGeneral
                    activeLanguage={activeLanguage}
                    canGetLogs={canGetLogs}
                    connectionState={server.ConnectionState}
                    disableRestartServer={disableRestartServer}
                    firmwareImage={firmwareImage}
                    initialValues={server}
                    logs={logs}
                    onRequestDeviceLogs={this.requestDeviceLogs}
                    onRestartApplianceClick={this.onRestartApplianceClick}
                    onSubmit={this.saveServerDetailsChanges}
                    onUpgradeFirmwareClick={this.upgradeDeviceFirmware}
                    preferredLongDateFormat={preferredLongDateFormat}
                    preferredTimeFormat={preferredTimeFormat}
                    restartApplianceClick={restartAppliancePressed}
                    secondaryButtonMouseDown={this.onSecondaryButtonMouseDown}
                    serverLocation={serverLocation}
                    timezones={timezones}
                    upgrade={upgrade}
                    upgradeButtonPressed={upgradeButtonPressed}
                  />
                </>
              );
            }}
          />
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_DIGITAL_IO}`}
            render={() => {
              return (
                <>
                  <div className={pageErrorWrapper}>
                    <PageMessage
                      messageType={[
                        messageTypes.DEVICE_ERROR,
                        messageTypes.DEVICE_SUCCESS,
                      ]}
                    />
                  </div>
                  {this.renderServerDigitalOutContent()}
                </>
              );
            }}
          />
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_VIDEO_RETENTION}`}
            render={() => {
              return (
                <>
                  <div className={pageErrorWrapper}>
                    <PageMessage
                      messageType={[
                        messageTypes.DEVICE_ERROR,
                        messageTypes.DEVICE_SUCCESS,
                      ]}
                    />
                  </div>
                  {this.renderVideoRetentionContent()}
                </>
              );
            }}
          />
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_RECORDING_SCHEDULE}`}
            render={() => {
              return (
                <>
                  <div className={pageErrorWrapper}>
                    <PageMessage
                      messageType={[
                        messageTypes.DEVICE_ERROR,
                        messageTypes.DEVICE_SUCCESS,
                      ]}
                    />
                  </div>
                  {this.renderRecordingScheduleContent()}
                </>
              );
            }}
          />
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_POE}`}
            render={() => {
              return (
                <>
                  <div className={pageErrorWrapper}>
                    <PageMessage
                      messageType={[
                        messageTypes.DEVICE_ERROR,
                        messageTypes.DEVICE_SUCCESS,
                      ]}
                    />
                  </div>
                  {this.renderServerPoeContent()}
                </>
              );
            }}
          />
          <Route
            path={`${PATH_SERVERS}/${serverId}${PATH_SEGMENT_CAMERAS}`}
            render={() => {
              return (
                <>
                  {isFirmwareUpgrading ? (
                    <div className={firmwareUpgradingFeedbackTextWrapper}>
                      <div className={firmwareUpgradingFeedbackText}>
                        <Translate id="DEVICES.DISCOVERED_CAMERAS_PAGE.DEVICE_FIRMWAREUPGRADING_FEEDBACK_TEXT" />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <MainContentWrapper>
                    <div className={connectedCamerasTable}>
                      <PageMessage
                        messageType={[
                          messageTypes.CONNECT_DISCONNECT_ERROR,
                          messageTypes.CONNECT_DISCONNECT_SUCCESS,
                          messageTypes.CAMERA_ENABLE_ERROR,
                          messageTypes.CAMERA_ENABLE_SUCCESS,
                          messageTypes.CAMERA_DISABLE_ERROR,
                          messageTypes.CAMERA_DISABLE_SUCCESS,
                        ]}
                      />
                      <EmptyPlaceholder
                        isFetching={isFetchingAllCameras}
                        items={connectedCameras}
                        translateKey="DEVICE_DETAILS.CAMERAS_TAB.EMPTY_CONNECTED_CAMERAS"
                      >
                        <ConnectedCamerasTable
                          activeCameras={activeCameras}
                          connectedCameras={connectedCameras}
                          disableCamera={this.disableCamera}
                          disconnectCamera={this.disconnectCamera}
                          enableCamera={this.enableCamera}
                          location={location}
                          loginCamera={this.showCameraLoginModal}
                          resetFDSPassword={this.resetFDSPassword}
                          server={server}
                        />
                      </EmptyPlaceholder>
                    </div>
                    <div className={discoveredCamerasContainer}>
                      <FindCameraFormContainer
                        discoveredCameras={this.discoveredCamerasToShow}
                        id={serverId}
                        isFormVisible={isFindCameraModalOpen}
                        supportedCameras={supportedCameras}
                        toggleFindCameraModal={formData => {
                          this.toggleModal(modalTypes.FIND_CAMERA, false);
                          this.setState({ findCameraFormData: formData });
                        }}
                      />
                      <div>
                        <PageMessage
                          messageType={messageTypes.DISCOVERED_CAMERAS_ERROR}
                        />
                        {renderIf(server.ConnectionState === CAMERA_CONNECTED)(
                          <DiscoveredCamerasTable
                            connectDiscoveredCamera={
                              this.connectDiscoveredCamera
                            }
                            discoveredCameras={this.discoveredCamerasToShow}
                            findCameraFormData={this.state.findCameraFormData}
                            findUndiscoveredCamerasCallback={() => {
                              this.toggleModal(modalTypes.FIND_CAMERA, true);
                            }}
                            isFetching={isFetchingDiscovered}
                            isServerDisconnected={isServerDisconnected}
                            pageMessageType={messageType}
                            resetFDSPassword={this.resetFDSPassword}
                          />,
                        )}
                      </div>
                    </div>
                  </MainContentWrapper>
                </>
              );
            }}
          />
          <Route
            exact
            path={`${PATH_SERVERS}/${serverId}`}
            render={() => (
              <Redirect
                to={{
                  pathname: `${PATH_SERVERS}/${serverId}${PATH_SEGMENT_CAMERAS}`,
                  state: location.state,
                }}
              />
            )}
          />
        </Switch>
        <Modal
          className={modalContentContainer}
          contentLabel="CameraLoginModal"
          isOpen={isCameraLoginModalOpen || isCameraFDSModalOpen}
          onRequestClose={this.hideCameraLoginModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          {isCameraLoginModalOpen
            ? this.getCameraLoginModalContent()
            : this.getCameraFDSModalContent()}
        </Modal>
      </>
    );
  }
}

ServerDetailContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  activeLanguage: PropTypes.objectOf(PropTypes.any),
  cameraLoginCamera: PropTypes.shape({}),
  canGetLogs: PropTypes.bool,
  connectedCameras: PropTypes.arrayOf(PropTypes.object),
  discoveredCameras: PropTypes.arrayOf(PropTypes.object),
  discoveredCamerasCredentials: PropTypes.arrayOf(PropTypes.any),
  firmwareImage: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isCameraFDSModalOpen: PropTypes.bool,
  isCameraLoginModalOpen: PropTypes.bool,
  isFetching: PropTypes.objectOf(PropTypes.any),
  isFetchingAllCameras: PropTypes.bool,
  isFetchingDevicePoe: PropTypes.bool,
  isFetchingDiscovered: PropTypes.bool,
  isFetchingFirmwareImage: PropTypes.bool,
  isFetchingGatewayDevicePoe: PropTypes.bool,
  isFetchingServerCapabilities: PropTypes.bool,
  isFetchingServerLocation: PropTypes.bool,
  isFindCameraModalOpen: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
  logs: PropTypes.objectOf(PropTypes.any),
  messageType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  poe: PropTypes.objectOf(PropTypes.any),
  preferredLongDateFormat: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedId: PropTypes.string,
  server: PropTypes.objectOf(PropTypes.any),
  serverCapabilities: PropTypes.arrayOf(PropTypes.any),
  serverId: PropTypes.string.isRequired,
  serverLocation: PropTypes.objectOf(PropTypes.any),
  supportedCameras: PropTypes.arrayOf(PropTypes.string).isRequired,
  timezones: PropTypes.arrayOf(PropTypes.object),
  upgrade: PropTypes.objectOf(PropTypes.any),
};

ServerDetailContainer.defaultProps = {
  activeLanguage: {},
  cameraLoginCamera: null,
  canGetLogs: false,
  connectedCameras: [],
  discoveredCameras: [],
  discoveredCamerasCredentials: [],
  firmwareImage: {},
  isCameraFDSModalOpen: false,
  isCameraLoginModalOpen: false,
  isFetching: {},
  isFetchingAllCameras: null,
  isFetchingDevicePoe: null,
  isFetchingDiscovered: null,
  isFetchingFirmwareImage: null,
  isFetchingGatewayDevicePoe: null,
  isFetchingServerCapabilities: null,
  isFetchingServerLocation: null,
  isFindCameraModalOpen: false,
  location: {},
  logs: {},
  poe: {},
  preferredLongDateFormat: '',
  preferredTimeFormat: '',
  selectedId: null,
  server: {},
  serverCapabilities: {},
  serverLocation: {},
  timezones: [],
  upgrade: {},
};

function mapStateToProps(state, ownProps) {
  const discoveredCameras = [];
  let cameraLoginCamera = null;
  let connectedCameras = [];
  let serverCapabilities = [];
  let selectedId = null;
  let serverLocation = null;
  let logs = {};
  let upgrade = {};
  let poe = {};

  const serverId = ownProps.match.params.sid;

  const server = state.devices.devices.find(s => s.Id === serverId);

  const { firmwareImage } = state.devices;
  // Use the same logic as the devices page; the ConnectedCameras api does not
  // provide accurate information for status and MAC address

  if (serverId) {
    connectedCameras = state.devices.cameras.filter(camera => {
      return camera.DeviceId === serverId;
    });
    connectedCameras.sort(generateSort(item => item.Name.toLowerCase()));
    logs = state.devices.logs[serverId];
    upgrade = state.devices.firmwareUpgrade[serverId];

    if (state.devices.poe && state.devices.poe[serverId]) {
      poe = state.devices.poe[serverId];
    }
  }

  if (
    state.devices.serverCapabilities &&
    !state.devices.isFetchingServerCapabilities
  ) {
    const { serverCapabilities: serverCapabilitiesFromState } = state.devices;
    serverCapabilities = serverCapabilitiesFromState;
  }

  if (
    state.devices.discoveredCameras &&
    !state.devices.isFetchingDiscoveredCameras
  ) {
    state.devices.discoveredCameras.forEach(disCamera => {
      let isCamQualifiedForDiscoveredList = false;
      const connCameraFound = connectedCameras.find(
        conCamera => conCamera.IpAddress === disCamera.ipAddress,
      );
      if (connCameraFound === undefined) {
        isCamQualifiedForDiscoveredList = true;
      } else if (
        connCameraFound &&
        disCamera.mfr === 'Generic RTSP' &&
        disCamera.uri !== connCameraFound.Uri
      ) {
        isCamQualifiedForDiscoveredList = true;
      }

      if (isCamQualifiedForDiscoveredList === true) {
        discoveredCameras.push(disCamera);
      }
      discoveredCameras.sort(generateSort(item => item.name.toLowerCase()));
    });
  }

  if (
    state.actionMenu.menuProps &&
    state.actionMenu.menuProps.table &&
    state.actionMenu.menuProps.rowIndex
  ) {
    if (
      state.actionMenu.menuProps.table === 'DISCOVERED' &&
      discoveredCameras.length > 0
    ) {
      selectedId = discoveredCameras[state.actionMenu.menuProps.rowIndex].Id;
    } else if (
      state.actionMenu.menuProps.table === 'CONNECTED' &&
      connectedCameras.length > 0
    ) {
      selectedId = connectedCameras[state.actionMenu.menuProps.rowIndex].Id;
    }
  }

  if (
    state.locations.locations &&
    state.locations.locations.length > 0 &&
    server
  ) {
    serverLocation = state.locations.locations.find(
      location => location.Id === server.LocationId,
    );
  }

  if (state.modal.modalProps) {
    const { modalProps } = state.modal;
    const { menuProps, rowIndex } = modalProps;
    if (menuProps) {
      const { cameraData } = menuProps;
      cameraLoginCamera = cameraData[rowIndex];
    }
  }

  const { supportedCameras } = state.devices;
  return {
    activeLanguage: state.localize.languages.find(lang => lang.active),
    cameraLoginCamera,
    canGetLogs: state.user.permissions.CAN_GET_DEVICE_LOGS,
    connectedCameras,
    discoveredCameras,
    discoveredCamerasCredentials: state.devices.discoveredCamerasCredentials,
    dropdownMenuIsVisible: state.actionMenu.isVisible,
    firmwareImage,
    isCameraFDSModalOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.FDS_CAMERA_RESET,
    isCameraLoginModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.CAMERA_LOGIN,
    isFetchingAllCameras: state.isFetching.getAllCameras,
    isFetchingDevicePoe: state.isFetching.getDevicePoe,
    isFetchingDiscovered: state.devices.isFetchingDiscoveredCameras,
    isFetchingFirmwareImage: state.devices.isFetchingFirmwareImage,
    isFetchingGatewayDevicePoe: state.isFetching.gatewayDevicePoe,
    isFetchingServerCapabilities: state.devices.isFetchingServerCapabilities,
    isFetchingServerLocation: state.isFetching.getLocations,
    isFindCameraModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.FIND_CAMERA,
    logs,
    menuCell: state.actionMenu.menuProps
      ? state.actionMenu.menuProps.cell
      : null,
    menuPositionX: state.actionMenu.x,
    menuPositionY: state.actionMenu.y,
    menuRowIndex: state.actionMenu.menuProps
      ? state.actionMenu.menuProps.rowIndex
      : null,
    menuTable: state.actionMenu.menuProps
      ? state.actionMenu.menuProps.table
      : null,
    messageType: state.pageMessage.messageType,
    orgId: permissions.getOrgIdFromStore(state),
    poe,
    preferredLongDateFormat:
      state.user.profile.LocalizationPreference.PreferredLongDateFormat,
    preferredTimeFormat:
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
    profile: state.user.profile,
    selectedId,
    selectedRowIndex: state.actionMenu.rowIndex,
    server,
    serverBreadcrumb: state.devices.serverBreadcrumb,
    serverCapabilities,
    serverId,
    serverLocation,
    supportedCameras,
    timezones: state.utilities.timezones,
    upgrade,
  };
}

function mapDispatchToProps(dispatch) {
  const deviceActions = DeviceActions;
  return {
    actions: bindActionCreators(
      {
        ...deviceActions,
        ...MenuActions,
        getLocations,
        getTimezones,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withAITracking(
    ai.reactPlugin,
    ServerDetailContainer,
    'ServerDetailContainer',
  ),
);
