import React from 'react';
import PropTypes from 'prop-types';
import { SelectableGroup, createSelectable } from 'react-selectable';
import { itemOdd, itemEven, selected, recordingSchedule } from './styles.css';

const GridItem = props => {
  const {
    selectableKey,
    rowNumber,
    handleClick,
    children,
    columnNumber,
    auxData,
    cellClassName,
  } = props;
  const cellData =
    auxData && auxData.length ? auxData[rowNumber - 1][columnNumber] : null;
  const divStyle = {
    backgroundColor: cellData ? cellData.backgroundColor : null,
    textAlign: 'center',
    paddingTop: '0.7%',
  };
  return (
    <div
      style={cellData ? divStyle : null}
      role="presentation"
      onClick={() => handleClick([selectableKey])}
      className={`${rowNumber % 2 ? itemOdd : itemEven} ${
        props.selected ? selected : null
      } ${cellClassName}`}
    >
      {cellData ? cellData.name : children}
    </div>
  );
};

GridItem.propTypes = {
  handleClick: PropTypes.func,
  rowNumber: PropTypes.number.isRequired,
  columnNumber: PropTypes.number,
  auxData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  selectableKey: PropTypes.number,
  children: PropTypes.element,
  selected: PropTypes.bool.isRequired,
};

GridItem.defaultProps = {
  selectableKey: null,
  handleClick: () => {},
  auxData: null,
  columnNumber: null,
  children: null,
};

const SelectableComponent = createSelectable(GridItem);

const SelectableGrid = props => {
  /* Takes in an array of arrays and makes a row of each one
    Each row array must be the same length
    Note that numRows and numColumns override the original configuration of gridMatrix
    when they are provided */
  const {
    gridMatrix,
    setSelected,
    onGridUpdate,
    numRows,
    numColumns,
    outerClass,
    rowClass,
    auxData,
    cellClassName,
  } = props;
  const getGrid = () => {
    const grid = [];
    if (gridMatrix && gridMatrix.length > 0) {
      gridMatrix.forEach(row => row.forEach(item => grid.push(item)));
    } else {
      for (let i = 1; i <= (numColumns || 1) * (numRows || 1); i += 1) {
        grid.push(false);
      }
    }
    return grid;
  };

  const handleGridUpdate = selectedKeys => {
    const newGridArray = getGrid();
    // Either manually pass in instructions to select or deselect
    // or, if no such instructions are present, set all squares to the opposite of the first square clicked.
    const selectValue =
      setSelected !== null ? setSelected : !newGridArray[selectedKeys[0]];
    selectedKeys.forEach(key => {
      newGridArray[key] = selectValue;
    });
    const newGridMatrix = [];
    const newNumColumns =
      numColumns || (gridMatrix && gridMatrix[0].length) || 1;
    let row = [];
    newGridArray.forEach((key, i) => {
      if (i !== 0 && i % newNumColumns === 0) {
        newGridMatrix.push(row);
        row = [];
      }
      row.push(key);
    });
    newGridMatrix.push(row);
    onGridUpdate(newGridMatrix, selectedKeys);
  };

  const selectableContent = (numRowsParam, numColumnsParam) => {
    const grid = getGrid();
    const gridJsx = [];
    const newNumRows = numRowsParam || (gridMatrix && gridMatrix.length) || 1;
    const newNumColumns =
      numColumnsParam || (gridMatrix && gridMatrix[0].length) || 1;
    for (let i = 1; i <= newNumRows; i += 1) {
      const row = grid
        .slice(newNumColumns * (i - 1), newNumColumns * i)
        .map((item, j) => {
          return (
            <SelectableComponent
              rowNumber={i}
              key={newNumColumns * (i - 1) + j}
              selected={item}
              selectableKey={newNumColumns * (i - 1) + j}
              handleClick={handleGridUpdate}
              columnNumber={j}
              auxData={auxData}
              cellClassName={cellClassName}
            />
          );
        });
      gridJsx.push(
        <div key={i} className={rowClass}>
          {row}
        </div>,
      );
    }
    return gridJsx;
  };
  return (
    <div className={outerClass}>
      <SelectableGroup onSelection={handleGridUpdate} style={{ width: '100%' }}>
        {selectableContent(numRows, numColumns)}
      </SelectableGroup>
    </div>
  );
};

SelectableGrid.propTypes = {
  gridMatrix: PropTypes.array.isRequired,
  onGridUpdate: PropTypes.func,
  numRows: PropTypes.number,
  numColumns: PropTypes.number.isRequired,
  outerClass: PropTypes.string,
  rowClass: PropTypes.string,
  auxData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  setSelected: PropTypes.string,
};

SelectableGrid.defaultProps = {
  setSelected: null,
  onGridUpdate: () => {},
  outerClass: '',
  rowClass: '',
  auxData: null,
};

export default SelectableGrid;
