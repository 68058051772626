import urlBuilder from 'queryBuilder/url';
import { GET_SNAPSHOT_URL } from 'constants/ActionTypes';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import {
  decorateCamera,
  getSiteChildren,
  NODE_TYPE_MESSAGE_LOADING_CAMERAS,
} from 'util/aftHelper';
import { DeviceSource, findServerCategory } from 'util/deviceCategory';
import { getSubscriptionState } from 'util/validation';
import { sendGetRequestReturningJSON } from 'util/fetchHelpers';

const camerasSelector = state => state.devices.cameras;
const devicesSelector = state => state.devices.devices;
const clustersSelector = state => state.clusters.clusters;
const locationsSelector = state => state.locations.locations;
const aftSelector = state => state.aft;
const aftSiteViewSelector = state => state.aftSiteView;
const isFetchingAllCamerasSelector = state => state.isFetching.getAllCameras;
const isFetchingAftCameraListSelector = state => state.isFetching.getAftCameraList;
const isFetchingAftSiteviewTreeSelector = state => state.isFetching.getAftSiteviewTree;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

function getConnectionState(camera) {
  if (!camera.available) {
    return 'DISCONNECTED';
  }
  return camera.connectionStatus.state;
}

/**
 * find the clusterId of given locationId via the map
 * @param {Object} clusterToLocationMap a map whose key is clusterId and value is locationId
 * @param {string} locationId locationId to search for
 * @returns {string} a list of clusterIds
 */

const mapLocationIdToClusterId = (clusters, locationId) => {
  const clusterIds = [];
  clusters
    .filter(clusterObj => clusterObj.LocationId === locationId)
    .map(x => clusterIds.push(x.Id));
  return clusterIds;
};

const isLocationValid = location =>
  location &&
  location.PackageSubscription &&
  getSubscriptionState(location.PackageSubscription).isValid;

const getSiteView = (location, siteViews) => {
  if (!isLocationValid(location)) {
    return [];
  }

  const siteView = siteViews[location.Id];
  if (siteView) {
    const [siteViewTree] = Object.values(siteView);
    return siteViewTree;
  }

  return [];
};

const getMessage = location => {
  const messageArray = [];
  if (getSubscriptionState(location.PackageSubscription).isSuspended) {
    messageArray.push(
      'DEVICES.DEVICE_LOCATION_WRAPPER.SUBSCRIPTION_SUSPENSION_NOTICE',
    );
  }
  if (getSubscriptionState(location.PackageSubscription).isExpired) {
    messageArray.push(
      'DEVICES.DEVICE_LOCATION_WRAPPER.SUBSCRIPTION_EXPIRATION_NOTICE',
    );
  }
  return messageArray;
};

const isClusterFetchingAft = (clusterId, isFetchingAft) => {
  return Object.entries(isFetchingAft).some(
    ([clusterIdFetching, isLoading]) => {
      return isLoading && clusterIdFetching === clusterId;
    },
  );
};

const isClusterLoading = (clusterId, isFetchings) => {
  let loading = false;
  isFetchings.forEach(fetchObj => {
    loading = loading || isClusterFetchingAft(clusterId, fetchObj);
  });
  return loading;
};

const isSiteLoading = (siteId, clusters, scopedFetches) => {
  const locationClusters = mapLocationIdToClusterId(clusters, siteId);

  return locationClusters.some(clusterId =>
    isClusterLoading(clusterId, scopedFetches),
  );
};

const getChildren = (site, clusters, isFetchingAllCameras, scopedFetches) => {
  // generate children for Savitar SiteView Virtual Tree

  const loading =
    isFetchingAllCameras || // in the case of blue fetching all cameras at once
    clusters.some(clusterId => isClusterLoading(clusterId, scopedFetches));

  if (loading) {
    return [
      {
        id: `loading_${site.Id}`,
        messages: [],
        type: NODE_TYPE_MESSAGE_LOADING_CAMERAS,
      },
    ];
  }

  return [];
};

const getCameraFromBlue = (camera, device, fetchSnapshotUrl) => ({
  active: camera.Active,
  aftClient: camera.aftClient || device.aftClient,
  available: device.ConnectionState === 'CONNECTED',
  connectionState: camera.ConnectionState,
  deviceId: camera.DeviceId,
  fetchSnapshotUrl,
  id: camera.Id,
  manufacturer: camera.Manufacturer,
  model: camera.Model,
  name: camera.Name,
  originalDevice: camera,
  recordedData: camera.RecordedData && device.ConnectionState === 'CONNECTED',
  remoteId: camera.RemoteId,
  snapshotUrl: camera.SnapshotUri,
  type: 'CAMERA',
});

const cameraSiteSelectionSelector = createDeepEqualSelector(
  camerasSelector,
  devicesSelector,
  clustersSelector,
  locationsSelector,
  aftSelector,
  aftSiteViewSelector,
  isFetchingAllCamerasSelector,
  isFetchingAftCameraListSelector,
  isFetchingAftSiteviewTreeSelector,
  (
    cameras,
    devices,
    clusters,
    locations,
    aft,
    aftSiteViews,
    isFetchingAllCameras,
    isFetchingAftCamerasList,
    isFetchingAftSiteviewTree,
  ) => {
    const sitesArr = [];

    const scopedFetches = [isFetchingAftCamerasList, isFetchingAftSiteviewTree];

    /* Add all locations the user has access to that have no cameras */
    locations.forEach(location => {
      if (location.UserHasAccess) {
        const locationClusters = mapLocationIdToClusterId(
          clusters,
          location.Id,
        );
        const locationObj = {
          cameras: [],
          children: getChildren(
            location,
            locationClusters,
            isFetchingAllCameras,
            scopedFetches,
          ), // children is used by Savitar SiteView Virtual Tree
          clusters: locationClusters,
          locationId: location.Id,
          locationName: location.Name,
          locationTimeZone: location.TimeZone,
          messages: getMessage(location),
          siteView: getSiteView(location, aftSiteViews),
          type: 'SITE',
        };
        sitesArr.push(locationObj);
      }
    });

    /* accumulate cameras as we go through for ease of child generation */
    const cameraHash = {};

    /* Add all valid Blue cameras */
    cameras
      /**
       * Disabled Cameras:      `Connected` == `true` && `IsDisconnected` == `true`
       * Disconnected Cameras:  `Connected` == `false`
       * Live/Rec Cameras:      `Connected` == `true` && `IsDisconnected` == `false`
       *
       * filter the cameras that are *not* Disabled Cameras or *not* Disconnected Cameras with no recording
       *
       * statement: `!(camera.disabledCameras || camera.disconnectedWithNoRecording)`
       *    => `!camera.disabledCameras && !camera.disconnectedWithNoRecording`
       *    => `!(camera.Connected && camera.IsDisconnected) && !(camera.Connected === false && !camera.RecordedData)`
       *    => `!(camera.Connected && camera.IsDisconnected) && (camera.Connected || camera.RecordedData)`
       *
       * meaning: only getting the cameras that are connected *and* is connected or with recorded data
       */
      .filter(camera => {
        const currentLocation = locations.find(
          location => location.Id === camera.LocationId,
        );
        return (
          !(camera.Connected && camera.IsDisconnected) &&
          (camera.Connected || camera.RecordedData) &&
          isLocationValid(currentLocation)
        );
      })
      .forEach(camera => {
        const device = devices.find(x => x.Id === camera.DeviceId);
        if (findServerCategory(device) !== DeviceSource.BLUE) {
          return;
        }

        const url = urlBuilder(GET_SNAPSHOT_URL, camera.DeviceId, camera.Id);
        const fetchSnapshotUrl = () => sendGetRequestReturningJSON(url);
        // consider using devices.getSnapshotURL to limit duplicate data fetches?

        const siteViewCamera = getCameraFromBlue(
          camera,
          device,
          fetchSnapshotUrl,
        );
        const itemIndex = sitesArr.findIndex(
          item => item.locationId === camera.LocationId,
        );
        if (itemIndex >= 0) {
          sitesArr[itemIndex].cameras.push(siteViewCamera);
        }
        cameraHash[siteViewCamera.id] = siteViewCamera;
      });

    /* Add all AFT cameras */
    Object.entries(aft).forEach(([locationId, aftClusters]) => {
      const currentLocation = locations.find(
        location => location.Id === locationId,
      );
      const loading = isSiteLoading(locationId, clusters, scopedFetches);
      if (isLocationValid(currentLocation) && !loading) {
        Object.values(aftClusters).forEach(aftCameras => {
          aftCameras.map(decorateCamera).forEach(camera => {
            const siteViewCamera = {
              active: camera.active,
              aftClient: camera.aftClient,
              available: camera.available,
              connected: camera.connected,
              connectionState: getConnectionState(camera),
              deviceId: camera.serverId,
              getRevokableSnapshotURL: camera.getRevokableSnapshotURL,
              id: camera.id,
              manufacturer: camera.manufacturer,
              model: camera.model,
              name: camera.name,
              recordedData: camera.available && camera.recordedData,
              remoteId: camera.id,
              type: 'CAMERA',
            };
            const itemIndex = sitesArr.findIndex(
              item => item.locationId === currentLocation.Id,
            );
            if (itemIndex >= 0) {
              sitesArr[itemIndex].cameras.push(siteViewCamera);
            }
            cameraHash[siteViewCamera.id] = siteViewCamera;
          });
        });
      }
    });

    // Populate Site children array for Savitar Virtual Tree
    sitesArr.forEach(site => {
      const loading = isSiteLoading(site.locationId, clusters, scopedFetches);
      if (!loading) {
        site.children = [
          ...site.children,
          ...getSiteChildren(site, cameraHash),
        ];
      }
    });

    /* Sort the array by location name */
    sitesArr.sort((a, b) => {
      const aName = a.locationName ? a.locationName.toUpperCase() : '';
      const bName = b.locationName ? b.locationName.toUpperCase() : '';
      if (aName > bName) {
        return 1;
      }
      if (aName < bName) {
        return -1;
      }
      return 0;
    });
    return sitesArr;
  },
);

export default cameraSiteSelectionSelector;
