// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { debounce } from 'lodash';
import Select from 'react-select-plus';

// Actions
import * as DeviceActions from 'actions/devices';
import * as UserActions from 'actions/user';

// Utilities
import * as tenantUtils from 'util/tenantTypes';
import { ai } from 'util/telemetryService';
import { renderRedirectToHMSRoot } from 'util/hmsHelpers';

// Constants
import { SELECT_STYLE } from 'constants/app';

// Icons
import { IconSearch } from 'icons';
import * as SelectCustConsts from './constants';

// Styles
import 'react-select-plus/dist/react-select-plus.css';
import { customerSelect, searchIconContainer, selectBox } from './styles.css';

const defaultQueryOptionToFetchCustomers = {
  filters: [],
  skip: 0,
  sort: {
    col: 'TenantType, TenantName',
    direction: 'asc',
  },
};

class SelectCustomerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownItems: [], // dropdown is concat of orgs + customers
      hasFetchedCustomers: false,
      isSelectionFocused: false,
      lastSearchText: '',
    };
  }

  componentDidMount() {
    const {
      actions,
      canChangeCustomer,
      customers,
      parentOrganization,
      selectedOrganization,
      tenantType,
    } = this.props;
    const { hasFetchedCustomers } = this.state;
    if (customers.length === 0 && canChangeCustomer && !hasFetchedCustomers) {
      actions.getCustomers(defaultQueryOptionToFetchCustomers);
      this.setState({ hasFetchedCustomers: true });
    } else if (!tenantType) {
      actions.getUserProfile();
    }
    const dropdownItems = this.concatenateLists(
      selectedOrganization,
      parentOrganization,
      customers,
    );
    this.setState({
      dropdownItems,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, customers, selectedOrganization } = this.props;
    const { hasFetchedCustomers } = this.state;
    let dropdownItems = [];
    if (
      nextProps.customers.length === 0 &&
      nextProps.canChangeCustomer &&
      !hasFetchedCustomers
    ) {
      actions.getCustomers(defaultQueryOptionToFetchCustomers);
      this.setState({ hasFetchedCustomers: true });
    } else if (
      nextProps.customers.length === 0 &&
      nextProps.canChangeCustomer &&
      nextProps.selectedOrganization &&
      selectedOrganization &&
      nextProps.selectedOrganization.Id !== selectedOrganization.Id
    ) {
      actions.getCustomers(defaultQueryOptionToFetchCustomers);
      this.setState({ hasFetchedCustomers: true });
    }

    if (
      nextProps.selectedOrganization ||
      nextProps.customers.length > customers.length
    ) {
      dropdownItems = this.concatenateLists(
        nextProps.selectedOrganization,
        nextProps.parentOrganization,
        nextProps.customers,
      );
      this.setState({
        dropdownItems,
      });
    }
  }

  onSelectionChanged = option => {
    const {
      actions,
      selectedItem,
      selectedOrganization,
      userProfile,
    } = this.props;

    if (option) {
      const id = option.value;
      if (selectedItem) {
        selectedItem(id);
      }
      // Only run this if there is an actual new id, and it is different.
      if (id && id !== selectedOrganization.Id) {
        actions.setCustomerContext(id);
      }
      return;
    }

    const { EmailAddress: userEmail, Id: userId } = userProfile;
    const { dropdownItems } = this.state;

    const optionsListWithValidValuesRemoved = this.generateOptionsList(
      dropdownItems,
    ).map(group => {
      group.options = group.options.filter(opt => !opt || !opt.value);
      return group;
    });

    ai.appInsights.trackEvent({
      name: 'reactSelectPlusClickedUndefinedOption',
      properties: {
        clickedOption: option,
        optionsListWithValidValuesRemoved,
        userEmail,
        userId,
      },
    });
  };

  onSelectionFocus = () => {
    this.setState({ isSelectionFocused: true });
  };

  onSelectionBlur = () => {
    this.setState({ isSelectionFocused: false });
  };

  renderSelectedValue = option => {
    const { tenantType } = this.props;
    const { isSelectionFocused } = this.state;
    const dealerCloudOwnerLabel =
      tenantType === tenantUtils.tenantTypeStrings.Dealer ||
      tenantType === tenantUtils.tenantTypeStrings.CloudOwner
        ? ''
        : option.label;
    const label = isSelectionFocused ? dealerCloudOwnerLabel : option.label;
    return label;
  };

  getOrgDisplayName = org => {
    const displayName = org.BillingNumber
      ? `${org.Name} - ${org.BillingNumber}`
      : org.Name;
    return displayName;
  };

  generateOptionsList = dropdownItems => {
    // Note from andre.behrens:
    // The selected item comes from userReducer -> CHANGE_CUSTOMER_CONTEXT, not this container.
    const { translate } = this.props;
    const cloudowner = [];
    const subscribers = [];
    const dealers = [];
    const providers = [];
    const keys = [];
    const options = [];

    dropdownItems.forEach(org => {
      if (!keys.includes(org.Id)) {
        keys.push(org.Id);
        if (org.TenantType === tenantUtils.tenantTypeStrings.CloudOwner) {
          cloudowner.push({
            label: org.Name,
            value: org.Id,
          });
        }
        if (org.TenantType === tenantUtils.tenantTypeStrings.Subscriber) {
          subscribers.push({
            label: this.getOrgDisplayName(org),
            value: org.Id,
          });
        }
        if (org.TenantType === tenantUtils.tenantTypeStrings.Dealer) {
          dealers.push({
            label: this.getOrgDisplayName(org),
            value: org.Id,
          });
        }
        if (org.TenantType === tenantUtils.tenantTypeStrings.Provider) {
          providers.push({
            label: org.Name,
            value: org.Id,
          });
        }
      }
    });
    cloudowner.length >= 0 &&
      options.push({
        label:
          cloudowner.length === 1
            ? translate('TENANTS.AVIGILON')
            : translate('TENANTS.AVIGILON_PLURAL'),
        options: cloudowner,
      });
    providers.length >= 0 &&
      options.push({
        label:
          providers.length === 1
            ? translate('TENANTS.PROVIDER')
            : translate('TENANTS.PROVIDER_PLURAL'),
        options: providers,
      });
    dealers.length >= 0 &&
      options.push({
        label:
          dealers.length === 1
            ? translate('TENANTS.SUBTYPES.DEALER')
            : translate('TENANTS.SUBTYPES.DEALER_PLURAL'),
        options: dealers,
      });
    subscribers.length >= 0 &&
      options.push({
        label:
          subscribers.length === 1
            ? translate('TENANTS.SUBSCRIBER')
            : translate('TENANTS.SUBSCRIBER_PLURAL'),
        options: subscribers,
      });
    return options;
  };

  concatenateLists = (selection, parent, customers) => {
    // customer list is unstable.  sometimes it includes the parent/cloudowner
    // if it does, generateOptionsList will remove duplicates
    const arrParent = parent
      ? [{ TenantType: tenantUtils.tenantTypeStrings.CloudOwner, ...parent }]
      : [];
    const arrSelected = selection ? [{ ...selection }] : [];
    return arrSelected.concat(arrParent).concat(customers);
  };

  getNextPage = () => {
    const { actions, customersNextPageLink } = this.props;

    if (customersNextPageLink && customersNextPageLink.length > 0) {
      actions.getCustomersNextPage(customersNextPageLink);
    }
  };

  onSearch = searchText => {
    const { actions } = this.props;

    if (searchText.length > 0) {
      const searchFilter = {
        ...defaultQueryOptionToFetchCustomers,
      };

      searchFilter.filters = [];
      searchFilter.filters.push({
        field: 'TenantName',
        operator: 'contains',
        typeOfValue: 'string',
        values: [searchText],
      });

      actions.getCustomers(searchFilter);
      this.setState({ lastSearchText: searchText });
    } else if (
      searchText.length === 0 &&
      this.state.lastSearchText.length > 0
    ) {
      actions.getCustomers(defaultQueryOptionToFetchCustomers);
      this.setState({ lastSearchText: searchText });
    }
  };

  render() {
    const {
      isEnabled,
      isFetchingCustomerData,
      redirectToHMSRoot,
      selectedOrganization,
      translate,
    } = this.props;

    const { dropdownItems } = this.state;

    const defaultOrg = selectedOrganization || {};
    const isFetchingCustomers = isFetchingCustomerData === null ||!isFetchingCustomerData ? false : true;


    return (
      <div style={{ position: 'relative' }}>
        {renderRedirectToHMSRoot(redirectToHMSRoot)}
        <Select
          className={`${selectBox} ${customerSelect} msi-select`}
          clearable={false}
          disabled={!isEnabled}
          filterOptions={false}
          id={SelectCustConsts.idCustomerDropdown}
          inputProps={{ autoComplete: 'off' }}
          isLoading={isFetchingCustomers}
          noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
          onBlur={this.onSelectionBlur}
          onChange={this.onSelectionChanged}
          onFocus={this.onSelectionFocus}
          onInputChange={debounce(searchText => this.onSearch(searchText), 500)}
          onMenuScrollToBottom={() => this.getNextPage()}
          openOnFocus
          optionClassName="msi-select-option"
          options={this.generateOptionsList(dropdownItems)}
          searchable
          style={SELECT_STYLE}
          tabIndex="-1"
          value={defaultOrg.Id}
          valueRenderer={option => this.renderSelectedValue(option)}
        />
        <div className={searchIconContainer}>
          <IconSearch height={25} showBorder={false} />
        </div>
      </div>
    );
  }
}

SelectCustomerContainer.defaultProps = {
  canChangeCustomer: false,
  customers: [],
  isEnabled: false,
  parentOrganization: null,
  selectedItem: () => {},
  selectedOrganization: null,
  tenantType: null,
  userProfile: {},
};

SelectCustomerContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canChangeCustomer: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.any),
  customersNextPageLink: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  isFetchingCustomerData: PropTypes.bool.isRequired,
  parentOrganization: PropTypes.objectOf(PropTypes.any),
  redirectToHMSRoot: PropTypes.bool.isRequired,
  selectedItem: PropTypes.func,
  selectedOrganization: PropTypes.objectOf(PropTypes.any),
  tenantType: PropTypes.oneOf(tenantUtils.tenantTypes),
  translate: PropTypes.func.isRequired,
  userProfile: PropTypes.shape({
    EmailAddress: PropTypes.string,
    Id: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  const {
    parentOrganization,
    profile: userProfile,
    selectedOrganization,
  } = state.user;
  const customers = state.user.customers || [];
  const tenantType = userProfile ? userProfile.TenantType : null;
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    customers,
    customersNextPageLink: state.user.customersNextPageLink,
    isEnabled: !state.devices.isFetchingSnapshots,
    isFetchingCustomerData: state.user.isFetchingCustomerData,
    parentOrganization,
    redirectToHMSRoot: state.microFrontend.hms.redirectToHMSRoot,
    selectedOrganization,
    tenantType,
    userProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions, ...DeviceActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(SelectCustomerContainer));
