import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  formatHourAndMinuteFromOffset,
  normalizeServerTimeZoneList,
} from 'util/timezoneUtil';
import getIdFromPath, { ID_TYPE } from 'util/getIdFromPath';

// Actions
import * as UtilityActions from 'actions/utilities';
// Components
import TimeZoneSelect from './TimeZoneSelect';

import { types } from './constants';

class TimeZoneSelectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { actions, serverId, timeZoneLists, timezones, type } = this.props;

    switch (type) {
      case types.SERVER: {
        if (!timeZoneLists[serverId]) {
          actions.getTimeZoneList(serverId);
        }
        break;
      }

      default: {
        if (timezones.length === 0) {
          actions.getTimezones();
        }
        break;
      }
    }
  }

  render() {
    const { changeCallback, disabled, input, timezones, type } = this.props;
    return (
      <TimeZoneSelect
        changeCallback={changeCallback}
        disabled={disabled}
        input={input}
        timezones={timezones}
        type={type}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { devices, utilities } = state;
  const { isFetchingTimeZoneList } = devices;
  const { timeZoneLists } = utilities;

  let { selectedServer: serverId } = devices;
  let { timezones } = utilities;
  let { disabled } = ownProps;

  if (!serverId) {
    serverId = getIdFromPath(ID_TYPE.SERVER);
  }

  const server = devices.devices.find(i => i.Id === serverId);

  switch (ownProps.type) {
    case types.SERVER: {
      const list = timeZoneLists[serverId];
      if (!list && server) {
        timezones = [
          {
            Key: server.TimeZone,
            Value: formatHourAndMinuteFromOffset(server.TimeZoneOffset, true),
          },
        ];
        disabled = true;
      } else {
        timezones = normalizeServerTimeZoneList(timeZoneLists[serverId]);
      }
      break;
    }

    default: {
      break;
    }
  }

  return {
    disabled,
    isFetchingTimeZoneList,
    serverId,
    timeZoneLists,
    timezones,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UtilityActions }, dispatch),
  };
}

TimeZoneSelectContainer.defaultProps = {
  changeCallback: () => {},
  disabled: false,
  serverId: null,
  timeZoneLists: {},
  timezones: [],
  type: types.DEFAULT,
};

TimeZoneSelectContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  changeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any).isRequired,
  serverId: PropTypes.string,
  timeZoneLists: PropTypes.shape({}),
  timezones: PropTypes.arrayOf(
    PropTypes.shape({ Key: PropTypes.string, Value: PropTypes.string }),
  ),
  type: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimeZoneSelectContainer);
