import { canvasObjectTypes } from 'constants/app';

// CAMERA BASIC
export const optionsImageQuality = [
  { value: '1', label: '1 (High)' },
  { value: '2', label: '2 (Medium)' },
  { value: '3', label: '3 (Low)' },
];

export const MINIMUM_OBJECTS_TO_SHOW_THRESHOLD = 2;

export const optionsMaxBitrate = [{ value: '36000', label: '36000' }];

export const optionsResolution = [{ value: '4944_3280', label: '4944x3280' }];

export const enableCheckId = 'enableCheckId';

/* The order for the options has to be the same as ACC: 
   - Objects in area
   - Objects crossing beam
   - Object loiters
   - Object appears or enters area
   - Objectstops in area
   - Objects leave area
   - Objects enter area
   - Object not present in area
   - Direction violated
*/

export const activityOptions = [
  {
    value: 'OBJECT_PRESENT',
    limit: { def: 1, min: 1, max: 20 },
    detectionTime: { def: 2, min: 0, max: 59 },
    timeout: { def: 3600, min: 5, max: 3600 },
  },
  {
    value: 'OBJECTS_CROSSED_BEAM',
    limit: { def: 1, min: 1, max: 20 },
    detectionTime: { def: 1, min: 1, max: 15 * 60 },
    timeout: { def: 5, min: 5, max: 3600 },
    boundary: canvasObjectTypes.loi,
  },
  {
    value: 'OBJECT_LOITERING',
    limit: { def: 1, min: 1, max: 1 },
    detectionTime: { def: 30, min: 0, max: 1800 },
    timeout: { def: 3600, min: 5, max: 3600 },
  },
  {
    value: 'OBJECT_COUNTING',
    limit: { def: 1, min: 1, max: 1 },
    detectionTime: { def: 0, min: 0, max: 59 },
    timeout: { def: 3600, min: 5, max: 3600 },
  },
  {
    value: 'OBJECT_STOPPED',
    limit: { def: 1, min: 1, max: 1 },
    detectionTime: { def: 10, min: 0, max: 900 },
    timeout: { def: 3600, min: 5, max: 3600 },
  },
  {
    value: 'OBJECT_LEAVES',
    limit: { def: 1, min: 1, max: 20 },
    detectionTime: { def: 10, min: 1, max: 900 }, // if limit > 1
    timeout: { def: 5, min: 5, max: 3600 },
  },
  {
    value: 'OBJECT_ENTERS',
    limit: { def: 1, min: 1, max: 20 },
    detectionTime: { def: 10, min: 1, max: 900 }, // if limit > 1
    timeout: { def: 3600, min: 5, max: 3600 },
  },
  {
    value: 'OBJECT_REQUIRED',
    limit: { def: 1, min: 1, max: 1 },
    detectionTime: { def: 2, min: 0, max: 3600 },
    timeout: { def: 5, min: 5, max: 3600 },
  },
  {
    value: 'PROHIBITED_DIRECTION',
    limit: { def: 1, min: 1, max: 1 },
    detectionTime: { def: 2, min: 0, max: 15 },
    timeout: { def: 5, min: 5, max: 3600 },
  },
];

export const scheduleOptions = ['Never', 'Weekly', 'Weekends'];

export const calcMinSec = totalSeconds => {
  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60);
  return { minutes, seconds };
};

export const RULE_BOX = {
  idRuleName: 'RuleNameInput',
  idActivity: 'ActivityDropdown',
  idDirectionalFlag: 'checkboxDirectional',
  idThreshold: 'Threshold',
  idNumberOfObjects: 'NumberOfObjects',
  idTimedOut: 'Timeout',
  idBtnRuleClose: 'btnRuleClose',
  idSensitivitySlider: 'SensitivitySlider',
};
