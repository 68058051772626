const itemUpsert = (list, item, idField = 'Id') => {
  let listUpdated = [];
  if (typeof item === 'string') {
    if (!list.includes(item)) {
      listUpdated = [item].concat(list);
    } else {
      listUpdated = list;
    }
  } else if (typeof item === 'object') {
    const idNew = item[idField];
    const matchIndex = list.findIndex(location => location[idField] === idNew);
    if (matchIndex >= 0) {
      listUpdated = list
        .slice(0, matchIndex)
        .concat([item])
        .concat(list.slice(matchIndex + 1));
    } else {
      listUpdated = [item].concat(list);
    }
  } else {
    throw new Error(`Unknown itemUpsert type ${typeof item}`);
  }
  return listUpdated;
};

export default itemUpsert;
