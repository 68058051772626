import { formField } from './styles.css';

export const BOOKMARK_DETAILS_FORM_FIELDS = [
  {
    type: 'text',
    name: 'name',
    inputId: 'name',
    label: 'COMMON.NAME',
    disabled: true,
    className: formField,
  },
  {
    type: 'text',
    name: 'locationName',
    id: 'locationName',
    label: 'BOOKMARKS.BOOKMARK_CREATED.LOCATION',
    disabled: true,
    className: formField,
  },
  {
    type: 'time',
    name: 'startTime',
    id: 'startTime',
    label: 'BOOKMARKS.BOOKMARK_CREATED.START',
    disabled: true,
    className: formField,
  },
  {
    type: 'time',
    name: 'endTime',
    id: 'endTime',
    label: 'BOOKMARKS.BOOKMARK_CREATED.END',
    disabled: true,
    className: formField,
  },
  {
    type: 'text',
    name: 'duration',
    id: 'duration',
    label: 'BOOKMARKS.FORM.DURATION',
    disabled: true,
    className: formField,
  },
  {
    type: 'time',
    name: 'creationTime',
    id: 'creationTime',
    label: 'BOOKMARKS.BOOKMARK_CREATED.CREATED',
    disabled: true,
    className: formField,
  },
  {
    type: 'text',
    name: 'isProtected',
    id: 'isProtected',
    label: 'BOOKMARKS.BOOKMARK_CREATED.PROTECTED_BOOKMARK',
    disabled: true,
    className: formField,
  },
  {
    type: 'text',
    name: 'description',
    id: 'description',
    label: 'COMMON.DESCRIPTION',
    disabled: true,
    className: formField,
  },
];

export const FORM_NAME = 'bookmarkDetails';

export const FORM_FIELDS_LIST = [
  'name',
  'locationName',
  'startTime',
  'endTime',
  'duration',
  'creationTime',
  'isProtected',
  'description',
];
