import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RecoilRoot } from 'recoil';
import Modal from 'react-modal';
import { withLocalize } from 'react-localize-redux';
import Spinner from 'react-md-spinner';
import { RootModal } from 'components';
import { BulkActionContext } from 'lib';
import { LoggedInFrameContainer, PushService } from 'containers';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

// Actions
import * as LocationActions from 'actions/locations';
import * as UserActions from 'actions/user';
import * as UtilityActions from 'actions/utilities';
import './sharedStyles/reset.css';

// Utils
import { getSecurityTokens } from 'util/getSecurityTokens';
import deepEqual from 'deep-equal';

// Constants
import {
  PATH_ACCOUNTS,
  PATH_INVALID_TOKEN,
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_RESET,
  PATH_SEGMENT_SITES,
  PATH_SIGNUP,
  PATH_SIGNUP_SELF_PROVISIONED,
  unauthenticatedPaths,
} from 'constants/urlPaths';
import { NAVIGATION_TABS } from 'constants/app';

// Utils
import getPathName, { getFullPath } from 'util/getPathName';
import * as permissions from 'util/permissions';
import {
  initializeLanguages,
  initializeMomentSettings,
} from 'util/languagesUtils';
import { ai } from 'util/telemetryService';
import { getShowFilters } from 'util/persistentStorageHelper';

const logoutPaths = [
  PATH_SIGNUP,
  PATH_SIGNUP_SELF_PROVISIONED,
  PATH_RESET,
  PATH_REGISTER,
];

// There is not style.css for App.js
const loaderStyle = {
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
};
const loaderSize = 80;
const loaderColor = '#5DB6FF';

Modal.setAppElement('#modal');

class App extends Component {
  constructor(props) {
    super(props);
    const {
      addTranslationForLanguage,
      initialize,
      location,
      setActiveLanguage,
    } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');
    initializeLanguages(
      initialize,
      setActiveLanguage,
      addTranslationForLanguage,
      lang ? lang.replace(/"/g, '') : null,
    );
    this.state = {
      bulkActionIds: {},
    };
  }

  // for routes that require auth, getProfile as a test canary
  componentDidMount() {
    const { actions, authCode, isFetchingLocations } = this.props;

    actions.getAiKey();
    const route = getPathName(window.location.pathname);
    if (logoutPaths.indexOf(route) >= 0 || (route === PATH_LOGIN && authCode)) {
      actions.invalidateUserSession();
    } else {
      actions.getUserProfile();
    }
  }

  // redirect user to login page if server returns 401
  // user is authenticated by either login or successful fetch of profile

  componentDidUpdate(prevProps) {
    const route = getPathName(window.location.pathname);
    const path = getFullPath(window.location.pathname);
    const {
      actions,
      activeLanguage: wasActiveLanguage,
      history,
      isAuthenticated: wasIsAuthenticated,
      isFetchingAiKey: wasFetchingAiKey,
      isFetchingUserProfile: wasIsFetchingUserProfile,
      localizationPreference: wasLocalizationPreference,
    } = prevProps;
    const {
      activeLanguage,
      aiKey,
      canViewDevices,
      currentOrg,
      isAuthenticated,
      isFetchingAiKey,
      isFetchingCurrentOrg,
      isFetchingLocations,
      isFetchingRegionalDeployments,
      isFetchingUserProfile,
      isWorldwideCentral,
      localizationPreference,
      locations,
      newOrgId,
      profile,
      user,
    } = this.props;
    if (wasFetchingAiKey && !isFetchingAiKey) {
      const browserHistory = createBrowserHistory({ basename: '/' });
      ai.initialize({ history: browserHistory }, aiKey);
    }
    if (isAuthenticated === false) {
      if (path === PATH_INVALID_TOKEN) {
        // never redirect from invalid token page
        return;
      }
      if (
        isFetchingRegionalDeployments === null &&
        (route === '' || route === '/')
      ) {
        // If you are at the empty route and have no information
        // on regional deployments, do not redirect until you have that information
        actions.getRegionalDeployments();
      } else if (
        isFetchingRegionalDeployments === true ||
        (unauthenticatedPaths.includes(route) && !['', '/'].includes(route)) ||
        (['', '/'].includes(route) && isWorldwideCentral === true)
      ) {
        // If you meet all of the following conditions:
        // 1. Not authenticated
        // 2. At the empty route, i.e. hostname or hostname/
        // 3. In the worldwide central region OR fetching region information
        // Then you will not be redirected from the empty route
        return;
      } else {
        if (path.length > 0 && !user.isLoggingOut) {
          actions.redirectAfterLogin(path);
        }
        history.push(PATH_LOGIN);
      }
    } else if (wasIsAuthenticated && profile.Role) {
      if (
        path === '' &&
        locations.length === 0 &&
        isFetchingLocations === false
      ) {
        history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}`);
      }
      if (
        path === '' &&
        isFetchingLocations === false &&
        locations.length >= 1
      ) {
        const homepage = permissions.getUserHomepage(
          profile,
          locations,
          canViewDevices,
          currentOrg,
        );
        // user failed to get profile, authenticated, now re-get profile
        history.push(homepage);
      }
    }

    // Get the current org of the profile user, preferring proxied to credentialed org.
    if (
      !isFetchingUserProfile &&
      profile &&
      profile.TenantId &&
      wasIsFetchingUserProfile
    ) {
      if (
        newOrgId &&
        isFetchingCurrentOrg === null &&
        (!currentOrg || newOrgId !== currentOrg.Id)
      ) {
        actions.getCurrentOrganization(newOrgId);
      }
    }
    if (
      !deepEqual(localizationPreference, wasLocalizationPreference) ||
      activeLanguage !== wasActiveLanguage
    ) {
      initializeMomentSettings(localizationPreference);
    }
    if (currentOrg && currentOrg.Id) {
      if (isFetchingLocations === null) {
        actions.getLocations(currentOrg.Id);
      }
    }
  }

  setBulkActionIds = (tableName, ids) => {
    this.setState(oldState => {
      oldState.bulkActionIds[tableName] = ids;
      return { bulkActionIds: oldState.bulkActionIds };
    });
  };

  bulkActionIdsFor = tableName => {
    const { bulkActionIds } = this.state;
    return bulkActionIds[tableName] || [];
  };

  clearBulkActionIdsFor = tableName => {
    this.setState(oldState => {
      const { bulkActionIds } = oldState;
      const newBulkActionIds = {};
      Object.keys(bulkActionIds).forEach(table => {
        if (table !== tableName) {
          newBulkActionIds[table] = [...bulkActionIds[table]];
        }
      });
      return { bulkActionIds: newBulkActionIds };
    });
  };

  render() {
    let content = '';
    const route = getPathName(window.location.pathname);
    const {
      children,
      hasConnectionSubscription,
      isAuthenticated,
      isFetchingAiKey,
      isFetchingCurrentOrg,
      isFetchingLocations,
      isFetchingUserProfile,
      modal,
      newOrgId,
    } = this.props;

    if (isFetchingAiKey !== false) {
      return (
        <div style={loaderStyle}>
          <Spinner singleColor={loaderColor} size={loaderSize} />
        </div>
      );
    }

    if (
      !unauthenticatedPaths.includes(route) &&
      isAuthenticated &&
      newOrgId &&
      isFetchingLocations === false
    ) {
      // for a logged-in user, unknown paths are considered 'authenticated'
      // Do not load any authenticated paths until an org id is available
      content = (
        <LoggedInFrameContainer>
          <PushService />
          {hasConnectionSubscription &&
          !isFetchingUserProfile &&
          !isFetchingCurrentOrg ? (
            children
          ) : (
            <div style={loaderStyle}>
              <Spinner singleColor={loaderColor} size={loaderSize} />
            </div>
          )}
        </LoggedInFrameContainer>
      );
    } else if (!unauthenticatedPaths.includes(route)) {
      // Paths that are not specified as accessible to logged-out users
      // will not be loaded until the user is demonstrably logged in
      content = (
        <div style={loaderStyle}>
          <Spinner singleColor={loaderColor} size={loaderSize} />
        </div>
      );
    } else {
      content = <div> {children} </div>;
    }
    const { bulkActionIds, flags, settings } = this.state;
    return (
      <RecoilRoot>
        <BulkActionContext.Provider
          value={{
            bulkActionIds,
            bulkActionIdsFor: this.bulkActionIdsFor,
            clearBulkActionIdsFor: this.clearBulkActionIdsFor,
            setBulkActionIds: this.setBulkActionIds,
          }}
        >
          <div>
            {content}
            <RootModal {...modal} />
            <div id="SelectPortal" />
          </div>
        </BulkActionContext.Provider>
      </RecoilRoot>
    );
  }
}

App.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  activeLanguage: PropTypes.objectOf(PropTypes.any),
  addTranslationForLanguage: PropTypes.func.isRequired,
  aiKey: PropTypes.string,
  authCode: PropTypes.string,
  canViewDevices: PropTypes.bool,
  children: PropTypes.node,
  currentOrg: PropTypes.objectOf(PropTypes.any),
  hasConnectionSubscription: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  initialize: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isFetchingAiKey: PropTypes.bool,
  isFetchingCurrentOrg: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  isFetchingRegionalDeployments: PropTypes.bool,
  isFetchingUserProfile: PropTypes.bool,
  isWorldwideCentral: PropTypes.bool,
  localizationPreference: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  locations: PropTypes.arrayOf(PropTypes.object),
  modal: PropTypes.objectOf(PropTypes.any).isRequired,
  newOrgId: PropTypes.string,
  profile: PropTypes.objectOf(PropTypes.any),
  router: PropTypes.objectOf(PropTypes.any),
  setActiveLanguage: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

App.defaultProps = {
  activeLanguage: {},
  aiKey: '',
  authCode: null,
  canViewDevices: false,
  children: null,
  currentOrg: {},
  isAuthenticated: null,
  isFetchingAiKey: null,
  isFetchingCurrentOrg: null,
  isFetchingLocations: null,
  isFetchingRegionalDeployments: null,
  isFetchingUserProfile: null,
  isWorldwideCentral: null,
  localizationPreference: {},
  location: {},
  locations: null,
  newOrgId: '',
  profile: {},
  router: null,
  user: {},
};

function mapStateToProps(state, ownProps) {
  let authCode;

  if (ownProps.location.search) {
    const params = getSecurityTokens(ownProps.location);
    authCode = params.code;
  }
  const canView = permissions.canViewTabs(
    state.user.profile,
    state.user.currentOrganization,
  );
  const canViewDevices = canView.indexOf(NAVIGATION_TABS.DEVICES) >= 0;

  // Add user to props so that willRecieveProps procs on SET_USER
  return {
    activeLanguage: state.localize.languages.find(lang => lang.active),
    aiKey: state.utilities.aiKey,
    authCode,
    canViewDevices,
    currentOrg: state.user.currentOrganization,
    hasConnectionSubscription: state.notifications.hasConnectionSubscription,
    isAuthenticated: state.user.isAuthenticated,
    isFetchingAiKey: state.isFetching.getAiKey,
    isFetchingCurrentOrg: state.user.isFetchingCurrentOrganization,
    isFetchingLinks: state.isFetching.getLinks,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingRegionalDeployments: state.isFetching.getRegionalDeployments,
    isFetchingUserProfile: state.isFetching.getUserProfile,
    isWorldwideCentral: state.utilities.isWorldwideCentral,
    localizationPreference: state.user.profile.LocalizationPreference,
    locations: state.locations.locations,
    modal: state.modal,
    newOrgId: permissions.getOrgIdFromStore(state),
    profile: state.user.profile,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...UserActions,
        ...UtilityActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(withAITracking(ai.reactPlugin, App, 'App')));
