import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import * as DashboardActions from 'actions/dashboard';

// Components
import { Dashboard } from 'components';

// Utilities
import * as permissions from 'util/permissions';

class DashboardContainer extends Component {
  componentDidMount() {
    // Note to self: componentWillReceiveProps does not run unless these run. No idea.
    this.props.actions.getDealerSubscriberStats();
    this.props.actions.getDealerSubscriberUpgradeSites();
    this.props.actions.getDealerSubscribersNearingRenewal();
    this.props.actions.getDealerBillingCycles();
    this.props.actions.getDealerSubscriptionLevels();
    this.props.actions.getDealerSubscriptionRenewalPeriods();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentBillingCycleId !== nextProps.currentBillingCycleId) {
      if (!nextProps.isFetchingDealerSubscriberStats) {
        this.props.actions.getDealerSubscriberStats(
          nextProps.currentBillingCycleId,
        );
      }
      if (!nextProps.isFetchingDealerSubscriptionLevels) {
        this.props.actions.getDealerSubscriptionLevels(
          nextProps.currentBillingCycleId,
        );
      }
      if (!nextProps.isFetchingSubscriptionRenewalPeriods) {
        this.props.actions.getDealerSubscriptionRenewalPeriods(
          nextProps.currentBillingCycleId,
        );
      }
    } else {
      // When orgId is changed, the dashboard reducer is reset to its initial state
      if (nextProps.isFetchingDealerSubscriberStats === null) {
        this.props.actions.getDealerSubscriberStats();
      }
      if (nextProps.isFetchingDealerSubscriberUpgradeSites === null) {
        this.props.actions.getDealerSubscriberUpgradeSites();
      }
      if (nextProps.isFetchingDealerSubscriptionLevels === null) {
        this.props.actions.getDealerSubscriptionLevels();
      }
      if (nextProps.isFetchingSubscriptionRenewalPeriods === null) {
        this.props.actions.getDealerSubscriptionRenewalPeriods();
      }
      if (nextProps.isFetchingDealerSubscribersNearingRenewal === null) {
        this.props.actions.getDealerSubscribersNearingRenewal();
      }
    }
  }

  handleCurrentBillingCycleChange(cycleId) {
    this.props.actions.setCurrentBillingCycle(cycleId);
  }

  render() {
    return (
      <Dashboard
        {...this.props}
        handleCurrentBillingCycleChange={this.handleCurrentBillingCycleChange.bind(
          this,
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    orgId,
    ...state.dashboard,
    preferredLongDateFormat:
      state.user.profile.LocalizationPreference.PreferredLongDateFormat,
    preferredShortDateFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, DashboardActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
