import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { ai } from 'util/telemetryService';

// Components
import {
  BusyIndicator,
  LoggedOutContentWrapper,
  RegisterText,
  SignupAccForm,
} from 'components';

// Actions
import * as UserActions from 'actions/user';

// Constants
import { APP_INSIGHTS_EVENTS, DEFAULT_LANGUAGE } from 'constants/app';

// Icons
import { registerText } from './styles.css';

function SignupACCContainer(props) {
  const { activeLanguage, isBusy, translate } = props;
  const [activeLanguageState, setActiveLanguageState] = useState((activeLanguage && activeLanguage.fullCode) || DEFAULT_LANGUAGE);

  function register(registrationData) {
    const { actions } = props;
    ai.appInsights.trackEvent({
      name: APP_INSIGHTS_EVENTS.registrationSubmit,
    });
    delete registrationData.CommunicationLanguage;
    actions.registerSelfProvisioned(registrationData);
  }

  useEffect(() => {
    if (
      activeLanguage &&
      activeLanguageState &&
      activeLanguageState.fullCode !== activeLanguage.fullCode
    ) {
      setActiveLanguageState(activeLanguage.fullCode);
    }
  }, [activeLanguage, activeLanguageState, setActiveLanguageState])

  if (isBusy) {
    return <BusyIndicator />;
  }
  return (
    <LoggedOutContentWrapper header={translate('SIGNUP.HEADER_ACC')}>
        <>
          <div className={registerText}>
            <RegisterText isACC/>
          </div>
          <SignupAccForm
            onSubmit={register}
          />
        </>
    </LoggedOutContentWrapper>
  );
}

SignupACCContainer.defaultProps = {
  user: {
    activeLanguage: null,
    error: null,
    isAuthenticated: false,
  },
};

SignupACCContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  activeLanguage: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  isBusy: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {

  return {
    activeLanguage: state.activeLanguage,
    isBusy: state.user.isBusy,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = UserActions;
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(SignupACCContainer),
);
