import React from 'react';

// Icons
import IconSnapshotUnavailable from '@avo-svtr/savitar/dist/svgIcons/IconSnapshotUnavailable';

// Style
import { snapshotUnavailableWrapper } from './SnapshotUnavailable.css';

const style = {
  width: '36px',
  height: '36px',
  fill: "#8CCBFF",
};

const SnapshotUnavailable = () => (
  <div className={snapshotUnavailableWrapper}>
    <IconSnapshotUnavailable style={style} />
  </div>
);

export default SnapshotUnavailable;
