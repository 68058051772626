import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  editUserProfile,
  getSchedules,
  getUserProfile,
  resetPassword,
} from 'actions/user';
import { getLocations } from 'actions/locations';
import { PageMessage } from 'containers';
import Dimensions from 'react-dimensions';
import * as messageTypes from 'constants/MessageTypes';
import { showMessage } from 'actions/pageMessage';
import { secondaryNavContainerHidden } from 'sharedStyles/styles.css';
import {
  BusyIndicator,
  ProfileForm,
  ProfileFormatsForm,
  ProfilePasswordForm,
  ProfileSummary,
} from 'components';
import { localizeLocationSchedules } from 'util/localizeSeededSchedules';
import { ListNav, PageTitle } from 'lib';
// Constants
import { DEFAULT_LANGUAGE } from 'constants/app';
import getPathName, {
  getCurrentPath,
  getFullPath,
  getProfileSubRoute,
} from 'util/getPathName';
import * as PATH from 'constants/urlPaths';
import ProfileNavMenu from './ProfileNavMenu';
import * as messageConsts from '../PageMessage/constants';
import { errorParent, snippetContainer } from './styles.css';

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotLast3Passwords: {},
    };
  }

  componentDidMount() {
    const { actions, isFetchingLocations, isFetchingSchedules } = this.props;
    if (isFetchingSchedules === null) actions.getSchedules();
    if (isFetchingLocations === null) actions.getLocations();
    this.pushCurrentPath();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isNotLast3Passwords } = this.state;

    const isNot3OldPasswordsClone = JSON.parse(
      JSON.stringify(nextProps.isNotLast3Passwords),
    );

    if (isNot3OldPasswordsClone !== isNotLast3Passwords) {
      this.setState({
        isNotLast3Passwords: isNot3OldPasswordsClone,
      });
    }
  }

  componentDidUpdate() {
    const { actions, isFetchingLocations, isFetchingSchedules } = this.props;
    if (isFetchingLocations === null) {
      actions.getLocations();
    }
    if (isFetchingSchedules === null) {
      actions.getSchedules();
    }
    this.pushCurrentPath();
  }

  get currentPath() {
    const { fullPath, path, profile } = this.props;
    return getCurrentPath(fullPath, path, profile, getProfileSubRoute);
  }

  handleUpdateProfile = values => {
    const { actions } = this.props;
    if (
      (values.CurrentPassword ||
        values.NewPassword ||
        values.ConfirmPassword) &&
      (!values.CurrentPassword ||
        !values.NewPassword ||
        !values.ConfirmPassword)
    ) {
      actions.showMessage(messageTypes.PROFILE_ERROR, null, null, {
        messageStyle: messageConsts.messageStyleStrings.error,
        translateBody: 'PROFILE.FILL_REQUIRED_FIELDS_ERROR',
      });
    } else {
      actions.editUserProfile(values);
    }
  };

  handleResetPassword = () => {
    const { actions, profile } = this.props;
    actions.resetPassword(profile.Id);
  };

  getLocationSchedules = () => {
    const { profileLocationSchedule, translate } = this.props;
    // Localize seeded schedules
    const locationData = localizeLocationSchedules(
      profileLocationSchedule,
      translate,
    );
    if (locationData.length) {
      return locationData;
    }
    return null;
  };

  pushCurrentPath() {
    const { fullPath, history } = this.props;
    if (!fullPath.includes(this.currentPath)) {
      history.push(this.currentPath);
    }
  }

  renderChildren = () => {
    const {
      canManageEmailNotifications,
      canViewSites,
      containerWidth,
      profile,
    } = this.props;
    const { isNotLast3Passwords } = this.state;

    // specify all fields in initialValues for V4 PUT Profile, even those
    // fields whose value is not explicitly updated. any undefined
    // fields will be implicitly set to their default value
    // (null for string, false for bool, etc.).
    const initialValues = {
      ConfirmPassword: '',
      CurrentPassword: '',
      DesiredCulture: profile.DesiredCulture || DEFAULT_LANGUAGE,
      EmailAddress: profile.EmailAddress,
      EmailNotification: profile.EmailNotification,
      FirstName: profile.FirstName || '',
      LastName: profile.LastName || '',
      LocalizationPreference: profile.LocalizationPreference,
      NewPassword: '',
      NewSiteAccess: profile.NewSiteAccess,
      PhoneNumber: profile.PhoneNumber,
      Policies: profile.Policies,
      TimeZone: profile.TimeZone,
    };
    return (
      <Switch>
        <Route
          exact
          path={PATH.PATH_PROFILE}
          render={() => <Redirect to={this.currentPath} />}
        />
        <Route
          path={PATH.PATH_PROFILE + PATH.PATH_SEGMENT_SUMMARY}
          render={() => (
            <ProfileSummary
              canViewSites={canViewSites}
              emailAddress={profile.EmailAddress}
              LocationData={this.getLocationSchedules()}
              phoneNumber={profile.PhoneNumber}
              profileFullName={`${profile.FirstName || ''} ${profile.LastName ||
                ''}`}
              tenantType={profile.TenantType}
            />
          )}
        />
        <Route
          path={PATH.PATH_PROFILE + PATH.PATH_SEGMENT_PERSONAL_INFO}
          render={() => (
            <ProfileForm
              canManageEmailNotifications={canManageEmailNotifications}
              ContainerWidth={containerWidth}
              initialValues={initialValues}
              onSubmit={this.handleUpdateProfile}
              Profile={profile}
            />
          )}
        />
        <Route
          path={PATH.PATH_PROFILE + PATH.PATH_SEGMENT_PASSWORD}
          render={() => (
            <ProfilePasswordForm
              ContainerWidth={containerWidth}
              initialValues={initialValues}
              isNotLast3Passwords={isNotLast3Passwords}
              onResetPassword={this.handleResetPassword}
              onSubmit={this.handleUpdateProfile}
            />
          )}
        />
        <Route
          path={PATH.PATH_PROFILE + PATH.PATH_SEGMENT_FORMATS}
          render={() => (
            <ProfileFormatsForm
              handleFormSubmit={this.handleUpdateProfile}
              initialValues={initialValues}
            />
          )}
        />
      </Switch>
    );
  };

  render() {
    const { canChangeCustomer, fullPath, profile } = this.props;
    return (
      <div
        className={snippetContainer}
        style={{
          height: this.containerHeight,
        }}
      >
        <div className={secondaryNavContainerHidden} />
        <ListNav
          canChangeCustomer={canChangeCustomer}
          navigationTabs={<ProfileNavMenu profile={profile} />}
          pageTitle={<PageTitle titleId="PROFILE.HEADER" />}
        />
        <div className={errorParent}>
          <PageMessage
            messageType={[
              messageTypes.PROFILE_ERROR,
              messageTypes.PROFILE_SUCCESS,
            ]}
          />
        </div>
        {profile ? (
          this.renderChildren()
        ) : (
          <div>
            <BusyIndicator />
          </div>
        )}
      </div>
    );
  }
}

ProfileContainer.defaultProps = {
  containerWidth: null,
  isFetchingLocations: null,
  isFetchingSchedules: null,
  profile: null,
  profileLocationSchedule: null,
};

ProfileContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canManageEmailNotifications: PropTypes.bool.isRequired,
  canViewSites: PropTypes.bool.isRequired,
  containerWidth: PropTypes.string,
  isFetchingLocations: PropTypes.bool,
  isFetchingSchedules: PropTypes.bool,
  profile: PropTypes.objectOf(PropTypes.any),
  profileLocationSchedule: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { profile } = state.user;
  const { locations } = state.locations;
  const { schedules } = state.user;
  const locationSchedules = [];
  const fullPath = getFullPath(ownProps.location.pathname);
  const path = getPathName(ownProps.location.pathname);
  if (
    profile &&
    locations &&
    schedules &&
    locations.length > 0 &&
    schedules.length > 0
  ) {
    locations.forEach(location => {
      let securityScheduleForLocation;
      let healthScheduleForLocation;
      const policyForLocation = profile.Policies.find(policy => {
        return policy.GroupId === location.Id;
      });

      if (policyForLocation) {
        securityScheduleForLocation = schedules.find(
          schedule => schedule.Id === policyForLocation.SecurityScheduleId,
        );
        healthScheduleForLocation = schedules.find(
          schedule => schedule.Id === policyForLocation.HealthScheduleId,
        );
      }

      if (securityScheduleForLocation && healthScheduleForLocation) {
        locationSchedules.push({
          HealthSchedule: healthScheduleForLocation,
          Location: location,
          SecuritySchedule: securityScheduleForLocation,
        });
      }
    });
  }
  return {
    canManageEmailNotifications:
      state.user.permissions.CAN_EDIT_EMAIL_NOTIFICATION_PREFERENCES,
    canViewSites: state.user.permissions.CAN_VIEW_SITES,
    fullPath,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingSchedules: state.isFetching.getSchedules,
    isNotLast3Passwords: state.user.isNotLast3Passwords,
    locationSchedules,
    locations,
    path,
    profile,
    profileLocationSchedule: locationSchedules,
    schedules,
    users: state.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        editUserProfile,
        getLocations,
        getSchedules,
        getUserProfile,
        resetPassword,
        showMessage,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Dimensions({
    elementResize: true,
  })(withLocalize(ProfileContainer)),
);
