import { INTEGRATION_TYPES } from 'constants/app';

// Central Station form fields
export const FILTER_SOFTWARE_PROPS = ['AuthenticationToken'];

export const FORM_FIELDS_LIST = {
  API_LOGIN_PASSWORD: 'ApiLoginPassword',
  API_LOGIN_USERNAME: 'ApiLoginUsername',
  AUTHENTICATION_TOKEN: 'AuthenticationToken',
  CONFIRM_PASSWORD: 'ConfirmPassword',
  CONNECTION_STRING: 'ConnectionString',
  INTEGRATION_TYPE: 'IntegrationType',
  NAME: 'Name',
};

export const USER_PROPS = [
  {
    inputId: 'ApiLoginUsername',
    label: 'INTEGRATIONS.CONFIGS.API_LOGIN_USERNAME',
    name: FORM_FIELDS_LIST.API_LOGIN_USERNAME,
    type: 'text',
  },
  {
    inputId: 'password',
    label: 'INTEGRATIONS.CONFIGS.API_LOGIN_PASSWORD',
    name: FORM_FIELDS_LIST.API_LOGIN_PASSWORD,
    preventAutocomplete: true,
    type: 'password',
  },
  {
    inputId: 'confirmPassword',
    isConfirmation: true,
    label: 'INTEGRATIONS.CONFIGS.CONFIRM_PASSWORD_LABEL',
    name: FORM_FIELDS_LIST.CONFIRM_PASSWORD,
    preventAutocomplete: true,
    type: 'password',
    validationWindowVisible: false,
  },
];

// Redux Config

export const FORM_NAME = 'centralStationForm';

export const CONNECTION_STRING_LABELS = {
  [INTEGRATION_TYPES.WEBHOOKS]: 'INTEGRATIONS.CONFIGS.CONNECTION_STRING_URI',
  [INTEGRATION_TYPES.SUREVIEW]:
    'INTEGRATIONS.CONFIGS.CONNECTION_STRING_HOSTPORT',
};

export const TOOLTIPS = {
  [INTEGRATION_TYPES.WEBHOOKS]: undefined,
  [INTEGRATION_TYPES.SUREVIEW]:
    'INTEGRATIONS.TOOLTIP.CONNECTION_STRING_HOSTPORT',
  [FORM_FIELDS_LIST.NAME]: 'INTEGRATIONS.TOOLTIP.NAME',
};
