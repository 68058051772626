import {
  GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS,
  RECEIVE_RECORDING_SETTINGS,
} from 'constants/ActionTypes';
import filterCamerasById from 'util/retentionFilterCamerasById';

export default function videoRetention(state, action) {
  switch (action.type) {
    case GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS: {
      return Object.assign({}, state, {
        isFetchingAllCamerasCompressionSettings: action.value,
      });
    }

    case RECEIVE_RECORDING_SETTINGS: {
      return {
        ...state,
        [action.serverId]: {
          recordingSettings: filterCamerasById(
            action.blueCameras,
            action.recordingSettings,
          ),
          storageData: {
            volumeInfo: action.volumeInfo,
            usage: action.usage,
          },
        },
      };
    }

    default: {
      return state || {};
    }
  }
}
