import React, { Component } from 'react';
import { connect } from 'react-redux';

import { applyFilters, processFilters } from 'util/filterUtils';

function NavigationWrapper(
  ContentContainer,
  state,
  navActions,
  modalType,
  filterProps,
) {
  class ContentContainerWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedItemType: null,
        selectedId: null,
        selectedIds: [],
        isTable: true,
        filters: [],
      };
    }

    getFilteredItems = () => {
      const { items } = this.props;
      const applicableFilters = this.appliedFilters() || [];
      if (applicableFilters.length > 0) {
        return applyFilters(
          items,
          applicableFilters,
          filterProps ? filterProps.textInputFields : [],
        );
      }
      return items;
    };

    onSelectedRowsChanged = (
      items,
      selectedIndices,
      selectedIndex,
      showModal,
      callback,
    ) => {
      // NOTE: Items do not need to be passed in if wrapped component
      // calls this directly; instead they are injected in the
      // render method
      const selectedIds = selectedIndices.map(id =>
        this.getIdForIndex(id, items),
      );
      const selectedId = this.getIdForIndex(selectedIndex, items);
      this.setState({ selectedIds, selectedId }, () => {
        if (showModal) {
          this.toggleModal();
        }
        if (callback) {
          callback(selectedIds, selectedId);
        }
      });
    };

    selectRowsById = (selected, callback) => {
      const selectedIds = Array.isArray(selected) ? selected : [selected];
      const selectedId = selectedIds[0];
      this.setState({ selectedIds, selectedId }, () => {
        if (callback) {
          callback(selectedIds, selectedId);
        }
      });
    };

    handleFilter = (
      field,
      values,
      replaceFilters = false,
      operator = 'eq',
      appliedFilters = null, // This is a TEMPORARY workaround to avoid data-update race conditions
      // when updating multiple fields after a single filter change. See MVAAS-20204.
    ) => {
      // Setting replaceFilters to true will replace the existing filters
      // instead of upserting into them
      const { actions, filterGroup } = this.props;
      const fields = typeof field === 'string' ? [field] : field;
      let newFilters = appliedFilters || this.appliedFilters() || [];
      if (replaceFilters) {
        newFilters = [];
      }
      let filtersChanged;
      fields.forEach(f => {
        const processedFilters = processFilters(newFilters, {
          field: f,
          values,
          operator,
        });
        ({ newFilters } = processedFilters);
        filtersChanged = processedFilters.filtersChanged || filtersChanged;
      });
      if (filtersChanged) {
        this.setState({
          selectedIds: [],
        });
        actions.addFilter(filterGroup, newFilters);
        return newFilters;
      }
      return null;
    };

    handleClearFilters = () => {
      const { actions, filterGroup } = this.props;
      actions.clearFilter(filterGroup);
    };

    handleAddClick = () => {
      this.setState({ selectedId: null }, this.toggleModal());
    };

    handleEditClick = () => {
      const { selectedIds } = this.state;
      this.setState({ selectedId: selectedIds[0] }, this.toggleModal());
    };

    handleModalForward = () => {
      const { selectedId, selectedIds } = this.state;
      const indexInSelectedIds = selectedIds.indexOf(selectedId);
      if (indexInSelectedIds < selectedIds.length - 1) {
        this.setState({
          selectedId: selectedIds[indexInSelectedIds + 1],
        });
      }
    };

    handleModalBackward = () => {
      const { selectedId, selectedIds } = this.state;
      const indexInSelectedIds = selectedIds.indexOf(selectedId);
      if (indexInSelectedIds > 0) {
        this.setState({
          selectedId: selectedIds[indexInSelectedIds - 1],
        });
      }
    };

    getIndexForId = (id, items) =>
      items.findIndex(location => location.Id === id);

    getIdForIndex = (ix, items) =>
      typeof ix === 'number' && ix >= 0 ? items[ix].Id : null;

    changeIsTable = isTable => {
      this.setState({
        isTable,
      });
    };

    toggleModal = (options = {}) => {
      const { actions, modalIsOpen } = this.props;
      if (options.hideParent) {
        actions.hideModal();
        this.setState({ selectedIds: [] });
      } else if (modalIsOpen) {
        actions.hideModal();
        this.setState({ selectedIds: [] });
      } else {
        actions.showModal(modalType, options);
      }
    };

    appliedFilters = () => {
      const { filterGroup, filterStatus } = this.props;
      return filterStatus && filterStatus[filterGroup];
    };

    
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { items } = this.props;
      if (nextProps.items && nextProps.items.length !== items.length) {
        this.setState({
          selectedIds: [],
        });
      } else if (nextProps.items && nextProps.items.length === 0) {
        this.setState({
          selectedIds: [],
        });
      }
    }

    render() {
      const { selectedIds } = this.state;
      const items = this.getFilteredItems();
      // This is the only place items are filtered
      const selectedIndices = selectedIds.map(id =>
        this.getIndexForId(id, items),
      );
      return (
        <ContentContainer
          changeIsTable={this.changeIsTable}
          onSelectedRowsChanged={(...args) =>
            this.onSelectedRowsChanged(items, ...args)
          }
          selectRowsById={this.selectRowsById}
          handleFilter={this.handleFilter}
          handleClearFilters={this.handleClearFilters}
          handleAddClick={this.handleAddClick}
          handleEditClick={this.handleEditClick}
          handleModalBackward={this.handleModalBackward}
          handleModalForward={this.handleModalForward}
          toggleModal={this.toggleModal}
          selectedIndices={selectedIndices}
          filteredItems={items}
          filtersApplied={this.appliedFilters()}
          {...this.state}
          {...this.props}
        />
      );
    }
  }

  ContentContainerWrapper.displayName = 'NavigationWrapper';
  return connect(
    state,
    navActions,
  )(ContentContainerWrapper);
}

export default NavigationWrapper;
