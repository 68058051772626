// User
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const UNPROVISIONED_REGISTRATION_SUCCESS = 'UNPROVISIONED_REGISTRATION_SUCCESS';

// Monitoring
export const ADD_CENTRAL_STATION = 'ADD_CENTRAL_STATION';
export const DELETE_CENTRAL_STATION = 'DELETE_CENTRAL_STATION';

// User

// Location
export const ADD_EDIT_LOCATION = 'ADD_EDIT_LOCATION';
export const ADD_SITE_TO_ADDED_ORG = 'ADD_SITE_TO_ADDED_ORG';
export const INFO_MODAL = 'INFO_MODAL';
export const DELETE_SUBSCRIBER_MODAL = 'DELETE_SUBSCRIBER_MODAL';
export const NEW_SITE_ACTIVATION_CODE = 'NEW_SITE_ACTIVATION_CODE';
// Location

// Device
export const ADD_DEVICE = 'ADD_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
// Device

// Schedules
export const SUBSCRIBERS = 'SUBSCRIBERS';
// Schedules

// Contacts
export const ADD_EDIT_CONTACT = 'ADD_EDIT_CONTACT';
// Contacts

// Service Plans
export const ADD_EDIT_SERVICE_PLAN = 'ADD_EDIT_SERVICE_PLAN';
// Service Plans

// Views
export const SAVE_VIEW = 'SAVE_VIEW';
export const INACTIVITY_PROMPT = 'INACTIVITY_PROMPT';
// Views

// Modal
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const ADD_CAMERA_GROUP_SUCCESS = 'ADD_CAMERA_GROUP_SUCCESS';
export const DOWNLOAD_LOGS = 'DOWNLOAD_LOGS';
// Modal

// Camera
export const CAMERA_LOGIN = 'CAMERA_LOGIN';
export const FIND_CAMERA = 'FIND_CAMERA';
export const CAMERA_VIDEO_DOWNLOAD = 'CAMERA_VIDEO_DOWNLOAD';
export const FDS_CAMERA_RESET = 'FDS_CAMERA_RESET';
// Camera

// EULA
export const EULA = 'EULA';
// EULA

// PTZ TOUR
export const PTZ_TOUR = 'PTZ_TOUR';
// PTZ TOUR

// BOOKMARKS
export const EDIT_BOOKMARK = 'EDIT_BOOKMARK';
// BOOKMARKS
