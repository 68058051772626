// Libs
import * as d3Array from 'd3-array';
/* Note - d3-axis will manipulate the dom directly. Use with caution! */
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import Dimensions from 'react-dimensions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { select as d3Select } from 'd3-selection';

// Components
import GridLines from './GridLines';

// Styles
import {
  barChartContent,
  barChartKeyColor,
  barChartKeyColumn,
  barChartKeyColumnItem,
  barChartKeyColumnItemWrapper,
  barChartKeyLabel,
  barChartWrapper,
  chartContainer,
  chartContainerItem,
} from './styles.css';

const BarCluster = props => {
  return props.x.map((x, index) => (
    <rect
      key={x}
      x={x}
      y={props.y[index] || 0}
      width={props.width}
      height={props.chartHeight - props.y[index] || 0}
      fill={props.colors[index]}
    />
  ));
};

class BarChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartHeight: props.containerHeight - props.margin * 2 - props.legendHeight,
      chartWidth: props.containerWidth - props.keyColumnWidth,
    };
  }

  componentDidMount() {
    const xNode = d3Select(this.xAxis);
    this.drawXAxis(xNode);
    const yNode = d3Select(this.yAxis);
    this.drawYAxis(yNode);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { containerHeight, containerWidth } = this.props;

    if (
      nextProps.containerHeight !== containerHeight ||
      nextProps.containerWidth !== containerWidth
    ) {
      this.setState(
        {
          chartHeight:
            nextProps.containerHeight -
            nextProps.margin * 2 -
            nextProps.legendHeight,
          chartWidth: nextProps.containerWidth - nextProps.keyColumnWidth,
        },
        () => {
          const xNode = d3Select(this.xAxis);
          this.drawXAxis(xNode);
          const yNode = d3Select(this.yAxis);
          this.drawYAxis(yNode);
        },
      );
    }
  }

  drawXAxis = xNode => {
    const { xTicks, margin } = this.props;
    const { chartHeight } = this.state;

    const { xDomain } = this.getDomain();
    const xAxis = d3Axis
      .axisBottom()
      .scale(this.xScale(xDomain))
      .ticks(xTicks)
      .tickSize(0)
      .tickPadding([6]);
    xNode
      .attr(
        'transform',
        `translate(0,${chartHeight + margin})`,
      )
      .call(xAxis);
    xNode.select('.domain').remove();
  };

  drawYAxis = yNode => {
    const { margin, yTicks } = this.props;
    const { yDomain } = this.getDomain();

    const yAxis = d3Axis
      .axisLeft()
      .scale(
        this.yScale(
          yDomain,
          Math.ceil((yDomain[1] - yDomain[0]) / yTicks),
        ),
      )
      .ticks(yTicks)
      .tickSize(0)
      .tickPadding([6]);
    yNode.attr('transform', `translate(${margin}, 0)`).call(yAxis);
    yNode.select('.domain').remove();
  };

  getDomain = () => {
    const { data } = this.props;
    const yMin = 0;
    let yMax = 0;
    const xDomain = [];

    data.forEach(datum => {
      xDomain.push(datum.label);
      yMax = d3Array.max(datum.y.concat([yMax]));
    });
    return { yDomain: [yMin, yMax], xDomain, yMin, yMax };
  };

  scaleData = (dataArray, xDomain, yDomain) => {
    const { colorArray } = this.props;
    const xScale = this.xScale(xDomain);
    const yScale = this.yScale(yDomain);
    const clusterWidth = xScale.bandwidth();
    const colors = colorArray; // TODO-General: use d3 color scale?

    const scaledData = dataArray.map((dataItem, index) => {
      const clusterStart = xScale(dataItem.label);
      const y = dataItem.y.map(y => yScale(y));
      const xSubDomain = dataItem.y.map((y, index) => index);
      const xSubScale = this.xSubScale(xSubDomain, clusterWidth);
      const x = xSubDomain.map(x => xSubScale(x) + clusterStart);
      const width = xSubScale.bandwidth();
      return { label: dataItem.label, width, colors, x, y };
    });
    return scaledData;
  };

  xScale = domainArray => {
    const { margin } = this.props;
    const { chartWidth } = this.state;

    return d3Scale
      .scaleBand()
      .domain(domainArray)
      .rangeRound([
        margin,
        chartWidth,
      ])
      .padding(0.2);
  };

  xSubScale = (subDomainArray, width) => {
    return d3Scale
      .scaleBand()
      .domain(subDomainArray)
      .rangeRound([0, width])
      .paddingInner(0.1);
  };

  yScale = (extent, step = 1) => {
    const { margin } = this.props;
    const { chartHeight } = this.state;

    return d3Scale
      .scalePoint()
      .domain(d3Array.range(extent[0], extent[1] + 1, step))
      .range([chartHeight + margin, margin]);
  };

  render() {
    const {
      cardPadding,
      containerHeight,
      containerWidth,
      data,
      keyColumnWidth,
      legendHeight,
      margin,
      yTicks } = this.props;
    const { chartHeight } = this.state;
    const { yDomain, xDomain } = this.getDomain();
    const scaledData = this.scaleData(data, xDomain, yDomain);

    return (
      <div className={chartContainer}>
        <div className={chartContainerItem}>
          {this.props.chartKeys && (
            <div className={barChartKeyColumn}>
              <div className={barChartKeyColumnItemWrapper}>
                {this.props.chartKeys.map((k, i) => (
                  <div key={i} className={barChartKeyColumnItem}>
                    <div style={{
                      backgroundColor: k.color,
                    }} className={barChartKeyColor} />
                    <div className={barChartKeyLabel}>
                      <Translate id={k.labelTranslateKey} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            height: `${containerHeight}px`,
            width: `${containerWidth}px`,
          }}
          className={barChartWrapper}
        >
          <svg
            className={barChartContent}
            height={containerHeight - legendHeight}>
            width={containerWidth - keyColumnWidth}
            <GridLines
              xScale={this.xScale(xDomain)}
              yScale={this.yScale(
                yDomain,
                Math.ceil((yDomain[1] - yDomain[0]) / yTicks),
              )}
              width={containerWidth - keyColumnWidth - cardPadding}
              xStart={margin}
              yStart={margin}
              height={chartHeight + margin}
              xTicks={null}
              yTicks={null}
            />
            {scaledData.map((data, index) => (
              <BarCluster
                {...data}
                key={index}
                chartHeight={chartHeight + margin}
              />
            ))}
            <g
              className="xAxis"
              ref={node => (this.xAxis = node)}
              key="xAxis"
            />
            <g
              className="yAxis"
              ref={node => (this.yAxis = node)}
              key="yAxis"
            />
          </svg>
        </div>
      </div >
    );
  }
}

BarChartComponent.defaultProps = {
  chartRange: null,
  colorArray: ['#000'],
  chartWidthMargin: 17,
  cardPadding: 16,
  getId: null,
  header: '',
  headerClassName: '',
  keyColumnWidth: 100,
  legendHeight: 0,
  margin: 25,
  selectedLegendClassName: '',
  xTicks: 6,
  yTicks: 7,
};

BarChartComponent.propTypes = {
  colorArray: PropTypes.array, // Array of colors for bars
  cardPadding: PropTypes.number,
  data: PropTypes.array.isRequired, // an array of objects {label: string, y: [array of numbers]}
  header: PropTypes.string, // header of chart component
  headerClassName: PropTypes.string, // class of chart component
  keyColumnWidth: PropTypes.number,
  legendHeight: PropTypes.number, // height of the portion of the chart containing the key/legend
  margin: PropTypes.number, // width of the left and bottom margin in which axis are placed
  selectedLegendClassName: PropTypes.string, // class applied to legend key when line is selected
  xTicks: PropTypes.number, // Number of labelled tick marks on the x-axis
  yTicks: PropTypes.number, // Number of labelled tick marks on the y-axis
};

export default Dimensions({ elementResize: true })(BarChartComponent);
