import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translatedConnect } from 'util/translatedConnect';
import { bindActionCreators } from 'redux';
import Select from 'react-select-plus';
import * as LocationActions from 'actions/locations';
import 'react-select-plus/dist/react-select-plus.css';
import { Translate } from 'react-localize-redux';
import { getSubscriptionState } from 'util/validation';
import { SELECT_STYLE } from 'constants/app';
import { selectBox } from './styles.css';

class SelectLocationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: undefined,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.getLocations();
  }

  onSelectionChanged = option => {
    const { selectedLocation } = this.props;
    const id = option.value;
    this.setState({ selection: id });
    selectedLocation(id);
  };

  generateOptionsList = options => {
    const optionArr = [];
    options.forEach(loc => {
      optionArr.push({ label: loc.Name, value: loc.Id });
    });
    return optionArr;
  };

  getValidLocations = locations => {
    return locations.filter(
      loc => (getSubscriptionState(loc.PackageSubscription).isValid && loc.UserHasAccess),
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, selectedOrganization } = this.props;
    if (selectedOrganization !== nextProps.selectedOrganization) {
      actions.getLocations();
    }
  }

  render() {
    const { disabled, locations, translate } = this.props;
    const { selection } = this.state;
    const allLocations = locations || [];
    return (
      <div style={{ position: 'relative' }}>
        <Select
          className={`${selectBox} msi-select`}
          clearable={false}
          disabled={disabled}
          noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
          onChange={this.onSelectionChanged}
          openOnFocus
          optionClassName="msi-select-option"
          options={this.generateOptionsList(
            this.getValidLocations(allLocations),
          )}
          placeholder={
            <Translate id="DEVICES.DEVICE_CLAIM.SELECT_SITE_PLACEHOLDER_TEXT" />
          }
          searchable
          style={SELECT_STYLE}
          tabIndex="-1"
          value={selection}
        />
      </div>
    );
  }
}

SelectLocationContainer.defaultProps = {
  actions: null,
  disabled: false,
  locations: [],
  selectedOrganization: null,
};

SelectLocationContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.any),
  selectedLocation: PropTypes.func.isRequired,
  selectedOrganization: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    locations: state.locations.locations,
    selectedOrganization: state.user.selectedOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...LocationActions }, dispatch),
  };
}

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectLocationContainer);
