import * as types from 'constants/ActionTypes';
import { runGetActionReturningJSON } from '../util/fetchHelpers';
import urlBuilder from '../queryBuilder/url';
import { isAftCatalogSync } from '../util/aftHelper';

function receiveAllClusters(data) {
  return {
    type: types.RECEIVE_ALL_CLUSTERS,
    clusters: data,
  };
}

function receiveCluster(data) {
  return {
    type: types.RECEIVE_CLUSTER,
    cluster: data,
  };
}

export function getClusters() {
  return dispatch => {
    return runGetActionReturningJSON({
      dispatch,
      url: urlBuilder(types.GET_CLUSTERS),
      fetchType: types.GET_CLUSTERS,
      onSuccess: json => {
        const { Items: clusters } = json;
        dispatch(receiveAllClusters(clusters));
        return Promise.resolve(
          clusters.filter(({ GatewayCapabilities }) =>
            isAftCatalogSync(GatewayCapabilities),
          ),
        );
      },
      onError: err => {
        avoLogError('Error fetching clusters:', err);
      },
    });
  };
}
export function removeCluster(locationId, clusterId) {
  return dispatch => {
    dispatch({ type: types.REMOVE_CLUSTER, locationId, clusterId });
  };
}
export function getCluster(clusterId) {
  return dispatch => {
    return runGetActionReturningJSON({
      dispatch,
      url: urlBuilder(types.GET_CLUSTER, null, null, {
        filter: { field: 'Id', operator: 'eq', value: `${clusterId}` },
      }),
      fetchType: types.GET_CLUSTER,
      onSuccess: json => {
        const { Items: clusters } = json;
        if (clusters && clusters.length === 1) {
          dispatch(receiveCluster(clusters[0]));
        } else {
          avoLogError(
            `Received an unexpected number of clusters: [${clusters.length}]`,
          );
        }
      },
      onError: err => {
        avoLogError('Error fetching cluster', err);
      },
    });
  };
}
