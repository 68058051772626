import React from 'react';

// Components
import {
  IconViewDropdown1x1,
  IconViewDropdown2x1,
  IconViewDropdown2x2,
  IconViewDropdown3x3,
  IconViewDropdown3x1,
  IconViewDropdown3x2,
} from 'icons';

// Styles
import { iconStyle } from './styles.css';

const Icon = ({ params, fillColor }) => {
  switch (params.value) {
    case 0: {
      return <IconViewDropdown1x1 className={iconStyle} fill={fillColor} />;
    }
    case 1: {
      return <IconViewDropdown2x1 className={iconStyle} fill={fillColor} />;
    }
    case 2: {
      return <IconViewDropdown2x2 className={iconStyle} fill={fillColor} />;
    }
    case 3: {
      return <IconViewDropdown3x3 className={iconStyle} fill={fillColor} />;
    }
    case 4: {
      return <IconViewDropdown3x1 className={iconStyle} fill={fillColor} />;
    }
    case 5: {
      return <IconViewDropdown3x2 className={iconStyle} fill={fillColor} />;
    }
    default:
      return null;
  }
};

export default Icon;
