import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

// Utils
import { accountTabAccess as tabAccess } from 'util/permissions';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_ACCOUNTS } from 'constants/urlPaths';
import { tabs } from './constants';

const AccountNavMenu = props => {
  const {
    canViewContacts,
    canViewSchedules,
    fullPath,
    hasPendingSiteRequest,
    profile,
    translate,
  } = props;
  const tabAccessPass = tabAccess(profile);
  const tabKeys = Object.keys(tabs);
  if (canViewContacts !== undefined) {
    tabAccessPass.contacts = canViewContacts;
  }
  if (canViewSchedules !== undefined) {
    tabAccessPass.schedules = canViewSchedules;
  }

  tabs.monitoring.hasPendingClue = hasPendingSiteRequest;

  return (
    <NavTabs
      fullPath={fullPath}
      tabs={tabKeys
        .filter(key => tabAccessPass[key])
        .map(key => ({
          exact: true,
          hasPendingClue: tabs[key].hasPendingClue,
          id: tabs[key].id,
          key,
          label: translate(`SECONDARY_NAV.TABS.${key.toUpperCase()}.LABEL`),
          path: `${PATH_ACCOUNTS}${tabs[key].path}`,
        }))}
    />
  );
};

AccountNavMenu.defaultProps = {
  canViewContacts: false,
  canViewSchedules: false,
  fullPath: '',
};
AccountNavMenu.propTypes = {
  canViewContacts: PropTypes.bool,
  canViewSchedules: PropTypes.bool,
  fullPath: PropTypes.string,
  hasPendingSiteRequest: PropTypes.bool.isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    canViewContacts:
      state.user.permissions && state.user.permissions.CAN_VIEW_CONTACTS,
    canViewSchedules:
      state.user.permissions && state.user.permissions.CAN_VIEW_SCHEDULES,
    hasPendingSiteRequest:
      state.locations.pendingSiteRequests &&
      state.locations.pendingSiteRequests.length > 0,
  };
}

export default connect(mapStateToProps)(withLocalize(AccountNavMenu));
