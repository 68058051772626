export const rotationTypes = {
  None: 'None',
  cw90: 'cw90',
  cw180: 'cw180',
  cw270: 'cw270',
};

export const convertRotationToValue = imageRotation => {
  let result = 0;
  switch (imageRotation) {
    case 1:
    case rotationTypes.cw90:
      result = 90;
      break;
    case 2:
    case rotationTypes.cw180:
      result = 180;
      break;
    case 3:
    case rotationTypes.cw270:
      result = 270;
      break;
    default:
      result = 0;
      break;
  }
  return result;
};

export const AdjustToVideoRotation = (
  camera,
  rotationDegree,
  zoneUneditable,
) => {
  const { top, bottom, left, right } = zoneUneditable;
  const { cameraResWidth: cameraWidth, cameraResHeight: cameraHeight } = camera;
  const newZone = {
    top,
    left,
    right,
    bottom,
  };
  if (rotationDegree === 90) {
    newZone.top = (left * cameraHeight) / cameraWidth;
    newZone.bottom = (right * cameraHeight) / cameraWidth;
    newZone.left = ((cameraHeight - bottom) * cameraWidth) / cameraHeight;
    newZone.right = ((cameraHeight - top) * cameraWidth) / cameraHeight;
  } else if (rotationDegree === 180) {
    newZone.top = cameraHeight - bottom;
    newZone.bottom = newZone.top + (bottom - top);
    newZone.left = cameraWidth - right;
    newZone.right = newZone.left + (right - left);
  } else if (rotationDegree === 270) {
    newZone.left = (top * cameraWidth) / cameraHeight;
    newZone.right = (bottom * cameraWidth) / cameraHeight;
    newZone.top = ((cameraWidth - right) * cameraHeight) / cameraWidth;
    newZone.bottom = ((cameraWidth - left) * cameraHeight) / cameraWidth;
  }
  return newZone;
};

export const RevertFromVideoRotation = (
  camera,
  rotationDegree,
  zoneUneditable,
) => {
  const { top, bottom, left, right } = zoneUneditable;
  const { cameraResWidth: cameraWidth, cameraResHeight: cameraHeight } = camera;
  const newZone = {
    top,
    left,
    right,
    bottom,
  };
  const rotationDegreeValue = rotationDegree;
  if (rotationDegreeValue === 90) {
    newZone.left = (top * cameraWidth) / cameraHeight;
    newZone.right = (bottom * cameraWidth) / cameraHeight;
    newZone.top = ((cameraWidth - right) * cameraHeight) / cameraWidth;
    newZone.bottom = ((cameraWidth - left) * cameraHeight) / cameraWidth;
  } else if (rotationDegreeValue === 180) {
    newZone.bottom = cameraHeight - top;
    newZone.top = cameraHeight - bottom;
    newZone.right = cameraWidth - left;
    newZone.left = cameraWidth - right;
  } else if (rotationDegreeValue === 270) {
    newZone.top = (left * cameraHeight) / cameraWidth;
    newZone.bottom = (right * cameraHeight) / cameraWidth;
    newZone.right = ((cameraHeight - top) * cameraWidth) / cameraHeight;
    newZone.left = ((cameraHeight - bottom) * cameraWidth) / cameraHeight;
  }
  return newZone;
};

export const AdjustRulesToVideoRotation = (
  cameraDimensions,
  rotationDegree,
  ruleUneditable,
) => {
  const newRule = {
    x: '',
    y: '',
  };
  const {
    cameraResWidth,
    cameraResHeight,
    xOffset,
    yOffset,
  } = cameraDimensions;
  // Rotation is apparently based on the sensor dimensions, not just the height/width of the stream
  const defaultWidth = cameraResWidth + 2 * (xOffset || 0);
  const defaultHeight = cameraResHeight + 2 * (yOffset || 0);
  const rotationDegreeValue = rotationDegree;
  if (rotationDegreeValue === 0) {
    newRule.x = ruleUneditable.x;
    newRule.y = ruleUneditable.y;
  } else if (rotationDegreeValue === 90) {
    newRule.x =
      defaultWidth - (ruleUneditable.y * defaultWidth) / defaultHeight;
    newRule.y = (ruleUneditable.x * defaultHeight) / defaultWidth;
  } else if (rotationDegreeValue === 180) {
    newRule.x = defaultWidth - ruleUneditable.x;
    newRule.y = defaultHeight - ruleUneditable.y;
  } else if (rotationDegreeValue === 270) {
    newRule.x = (ruleUneditable.y * defaultWidth) / defaultHeight;
    newRule.y =
      defaultHeight - (ruleUneditable.x * defaultHeight) / defaultWidth;
  }
  return newRule;
};

export const RevertRulesFromVideoRotation = (
  cameraDimensions,
  rotationDegree,
  ruleUneditable,
) => {
  const newRule = {
    x: '',
    y: '',
  };
  // Rotation is apparently based on the sensor dimensions, not just the height/width of the stream
  const {
    cameraResWidth,
    cameraResHeight,
    xOffset,
    yOffset,
  } = cameraDimensions;
  const defaultWidth = cameraResWidth + 2 * (xOffset || 0);
  const defaultHeight = cameraResHeight + 2 * (yOffset || 0);
  const rotationDegreeValue = rotationDegree;
  if (rotationDegreeValue === 0) {
    newRule.x = ruleUneditable.x;
    newRule.y = ruleUneditable.y;
  } else if (rotationDegreeValue === 90) {
    newRule.x = (ruleUneditable.y * defaultWidth) / defaultHeight;
    newRule.y =
      defaultHeight - (ruleUneditable.x * defaultHeight) / defaultWidth;
  } else if (rotationDegreeValue === 180) {
    newRule.x = defaultWidth - ruleUneditable.x;
    newRule.y = defaultHeight - ruleUneditable.y;
  } else if (rotationDegreeValue === 270) {
    newRule.y = (ruleUneditable.x * defaultHeight) / defaultWidth;
    newRule.x =
      defaultWidth - (ruleUneditable.y * defaultWidth) / defaultHeight;
  }
  return newRule;
};
