import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconDigitalOutOn = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 13 20"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <rect
          id="path-1"
          x="0"
          y="0"
          width={props.width}
          height={props.height}
          rx="4"
        />
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard"
          transform="translate(-228.000000, -158.000000)"
          fillRule="nonzero"
          stroke={props.strokeColor}
        >
          <g id="i/o-open" transform="translate(202.000000, 154.000000)">
            <g id="Group-12" transform="translate(27.000000, 1.000000)">
              <g
                id="vertical-toggle-btn-on"
                transform="translate(0.000000, 4.000000)"
              >
                <path
                  d="M0,12 C0,15.0375661 2.46243388,17.5 5.5,17.5 C8.53756612,17.5 11,15.0375661 11,12 L11,5.5 C11,2.46243388 8.53756612,0 5.5,0 C2.46243388,0 0,2.46243388 0,5.5 L0,12 Z"
                  id="Oval"
                  fill={props.toggleFillColor}
                  transform="translate(5.500000, 8.750000) rotate(-180.000000) translate(-5.500000, -8.750000) "
                />
                <circle
                  id="Oval"
                  fill={props.circleFillColor}
                  cx="5.5"
                  cy="12.5"
                  r="5.5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconDigitalOutOn.defaultProps = {
  className: null,
  width: '13px',
  height: '20px',
  circleFillColor: '#D8D8D8',
  toggleFillColor: '#4B9B18',
  strokeColor: '#666666',
};

export default IconDigitalOutOn;
