import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from '../constants/app';

const LinkingLine = ({ title, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 6"
    version="1.1"
    xmlns={SVG_XMLNS}
  >
    <title>{title}</title>
    <g
      id="uiServerSettings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="uiServerSettings-VideoRetention"
        transform="translate(-114.000000, -348.000000)"
        stroke="#000000"
      >
        <g id="Row-of-Cameras" transform="translate(103.000000, 272.000000)">
          <g id="linked-ui" transform="translate(10.000000, 40.801938)">
            <path
              d="M1.54545455,35.1980616 L1.54545455,40.1980616 L1.54545455,46.1980616 L1.54545455,35.1980616 Z M1.54545455,40.6980616 L7,40.6980616 L1.54545455,40.6980616 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LinkingLine.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LinkingLine.defaultProps = {
  title: '',
  width: '6px',
  height: '13px',
};

export default LinkingLine;
