import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  IconAlarmInReview,
  IconAlarmReviewed,
  IconAlarmUnReviewed,
  IconDeviceHealth,
} from 'icons';

// Constants
import { ActivityClass } from 'containers/Notifications/constants';

const isAlarmNotification = activityClass => {
  return (
    activityClass === ActivityClass.VideoReviewActivity ||
    activityClass === ActivityClass.DeviceTamperingActivity
  );
};

const isHealthNotification = activityClass => {
  return (
    activityClass === ActivityClass.CameraHealthActivity ||
    activityClass === ActivityClass.DeviceHealthActivity
  );
};

// Constants
const StatusCell = ({ status, activityClass, isSelected }) => {
  if (status === 'Unreviewed') {
    if (isAlarmNotification(activityClass)) {
      return <IconAlarmUnReviewed highlight={isSelected} />;
    }
    if (isHealthNotification(activityClass)) {
      return <IconDeviceHealth highlight={isSelected} />;
    }
  } else if (status === 'Reviewed') {
    return <IconAlarmReviewed highlight={isSelected} />;
  } else {
    return <IconAlarmInReview highlight={isSelected} />;
  }
  return <div>&nbsp;</div>;
};

StatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  activityClass: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};

StatusCell.defaultProps = {
  isSelected: false,
};

export default StatusCell;
