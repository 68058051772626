import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import get from 'lodash.get';

// Actions
import * as UserActions from 'actions/user';
import * as LocationActions from 'actions/locations';
import * as DeviceActions from 'actions/devices';
import { hideModal } from 'actions/modal';

// Utilities
import * as messageTypes from 'constants/MessageTypes';
import { OrganizationForm } from 'containers';
import { tenantTypeStrings } from 'util/tenantTypes';

// Constants
import * as customerConsts from './constants';

// Components

const formDefaults = {
  AdminUserId: customerConsts.defaultAdminUser,
  Type: tenantTypeStrings.Subscriber,
};

class CustomerFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: [],
      submittingForm: false,
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.getPartners();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions } = this.props;
    const { submittingForm } = this.state;
    // Intercept the success or error message to know which buttons to display
    if (
      nextProps.messageType === messageTypes.CUSTOMER_SUCCESS &&
      submittingForm
    ) {
      this.setState({ submittingForm: false });

      actions.getPartners();
      // Need to refresh that customer list as well
    }
    if (
      nextProps.messageType === messageTypes.CUSTOMER_ERROR &&
      submittingForm
    ) {
      this.setState({ submittingForm: false });
    }
  }

  componentWillUnmount() {
    this.setState({ submittingForm: false });
  }

  resetState = () => {
    this.setState({
      submittingForm: false,
    });
  };

  getInitialValues = () => {
    const {
      isAdding,
      pageTenantType,
      partners,
      selectedCustomer,
      selectedDealerId,
      selectedOrganization,
    } = this.props;
    if (!isAdding && selectedCustomer) {
      return {
        ...selectedCustomer,
        AdminUserId: selectedCustomer.AdministratorId,
      };
    }

    const initialValues = { ...formDefaults, Type: pageTenantType };
    if (selectedDealerId || selectedOrganization.Id) {
      let dealer = [...partners, selectedOrganization].find(
        x => x.Id === selectedDealerId,
      );

      dealer =
        !dealer && selectedOrganization.Id
          ? [...partners, selectedOrganization].find(
              x => x.Id === selectedOrganization.Id,
            )
          : dealer;
      // This is probably for the multi-dealer work, but breaks adding dealers for now.
      // When that work proceeds, we can put this back.
      if (dealer /* && dealer.TenantType !== tenantTypeStrings.CloudOwner */) {
        initialValues.DealerName = dealer.Name;
        initialValues.Entitlements = dealer.Entitlements
          ? dealer.Entitlements
          : 'None';
        initialValues.PreferredDealer_Id = dealer.Id;
      }
    }
    return initialValues;
  };

  submitForm = formFields => {
    /* extract the Dealer object by looking up in the partners via DealerName
       and set the dealerId to null when creating a dealer */
    const {
      actions,
      canAdd,
      canEdit,
      isAdding,
      navigateBack,
      partners,
      queryOptions,
      selectedCustomer,
      tableFilterId,
    } = this.props;

    const dealer = partners.find(
      x => x.Id === (formFields.PreferredDealer_Id || formFields.DealerId),
    );
    const dealerId = dealer ? dealer.Id : null;
    this.setState({
      dealerId,
      submittingForm: true,
    });

    const filters = get(queryOptions, 'filters.filters', []);
    const hasFilters = filters.length > 0;

    const refinedQueryOptions = queryOptions;
    if (refinedQueryOptions) {
      refinedQueryOptions.top = 50;
      refinedQueryOptions.skip = 0;
    }

    if (!isAdding && canEdit) {
      if (!hasFilters && refinedQueryOptions) {
        refinedQueryOptions.filter = {
          field: 'Id',
          operator: 'eq',
          value: selectedCustomer.Id,
        };
      }

      if (canEdit) {
        // edit
        actions.editCustomer(
          { ...formFields, Name: formFields.Name.trim() },
          selectedCustomer.Id, // Edit as the customer being edited
        );
      }
    } else if (canAdd) {
      /* Notes on refinedQueryOptions.filter:
       * When organization is created we don't have selectedCustomer.Id
       * So to fetch the only organization which was created, We provide queryOptions inside
       * addCustomer action after creating organization successful and before fetching with
       * getCustomerOrganizations
       */
      actions.addCustomer(
        { ...formFields, Name: formFields.Name.trim() },
        dealerId, // Create as the parent of the customer being created
        refinedQueryOptions,
        tableFilterId,
      );
      navigateBack();
    }
    this.clearErrors();
  };

  clearErrors() {
    this.setState({
      formErrors: [],
    });
  }

  render() {
    const {
      canAdd,
      canEdit,
      isAdding,
      onDelete,
      partners,
      selectedCustomer,
      selectedDealerId,
      userTenantType,
    } = this.props;
    const { dealerId, formErrors, submittingForm } = this.state;
    return (
      <OrganizationForm
        dealerId={dealerId || selectedDealerId}
        delete={onDelete}
        errors={formErrors}
        initialValues={this.getInitialValues()}
        isEditing={!isAdding}
        onSubmit={this.submitForm}
        partners={partners}
        readOnly={!canAdd && !canEdit}
        resetState={this.resetState}
        // this is dirty
        submittingForm={submittingForm}
        tenantId={selectedCustomer && selectedCustomer.Id}
        userTenantType={userTenantType}
      />
    );
  }
}

CustomerFormContainer.defaultProps = {
  initialValues: {},
  isAdding: false,
  messageType: '',
  navigateBack: () => {},
  onDelete: () => {},
  pageTenantType: '',
  queryOptions: null,
  selectedCustomer: undefined,
  selectedOrganization: '',
  tableFilterId: '',
};

CustomerFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canAdd: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isAdding: PropTypes.bool,
  messageType: PropTypes.string,
  navigateBack: PropTypes.func,
  onDelete: PropTypes.func,
  pageTenantType: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  queryOptions: PropTypes.shape({}),
  selectedCustomer: PropTypes.shape({}),
  selectedDealerId: PropTypes.string.isRequired,
  selectedOrganization: PropTypes.string,
  tableFilterId: PropTypes.string,
  userTenantType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    messageType: state.pageMessage.messageType,
    partners: state.user.partners,
    selectedOrganization: state.user.selectedOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...LocationActions, ...UserActions, ...DeviceActions, hideModal },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerFormContainer);
