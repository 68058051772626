import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';

const IconSaveAs = props => {
  return (
    <div title="Save as">
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 24 23"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="view-live"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-789.000000, -145.000000)">
            <g transform="translate(110.000000, 136.000000)">
              <g transform="translate(364.000000, 5.000000)">
                <g transform="translate(313.000000, 1.000000)">
                  <path
                    d="M24.4285714,8.63468812 L24.4285714,24.7738869 L3.31628027,24.7738869 L3.31628027,4.63992754 L19.8112057,4.57142857 L24.4285714,8.63468812 Z"
                    stroke="#006e99"
                    strokeWidth="2"
                    fill="#F4F4F4"
                    fillRule="evenodd"
                  />
                  <polyline
                    stroke="#006e99"
                    strokeWidth="1"
                    points="6.76325335 23.7213542 6.76325335 16.8496088 20.3281517 16.8496088 20.3281517 23.5497194"
                  />
                  <polyline
                    stroke="#006e99"
                    strokeWidth="1"
                    transform="translate(12.523810, 8.007301) rotate(180.000000) translate(-12.523810, -8.007301) "
                    points="7.76190476 11.4431739 7.76190476 4.57142857 17.2857143 4.57142857 17.2857143 11.2715391"
                  />
                  <rect
                    fill="#006e99"
                    fillRule="evenodd"
                    x="7.76190476"
                    y="4.57142857"
                    width="3.56999993"
                    height="7"
                  />
                  <circle
                    fill="#EFEFEF"
                    fillRule="evenodd"
                    cx="21.5"
                    cy="21.5"
                    r="7.5"
                  />
                  <g
                    transform="translate(19.010408, 22.010408) rotate(-45.000000) translate(-19.010408, -22.010408) translate(12.010408, 20.510408)"
                    fillRule="evenodd"
                    fill="#006e99"
                  >
                    <rect x="8" y="0" width="6" height="4" />
                    <polygon
                      transform="translate(5.000000, 2.000000) rotate(270.000000) translate(-5.000000, -2.000000) "
                      points="5 0 7 4 3 4"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconSaveAs.defaultProps = {
  className: null,
  width: '24px',
  height: '23px',
  backgroundFill: '#F4F4F4',
  strokeFill: ACTIVE_COLOR_DEFAULT,
};

export default IconSaveAs;
