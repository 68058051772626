import { states } from 'constants/dropdownContent';
import { PATH_SEGMENT_INFO, PATH_SEGMENT_SITES } from 'constants/urlPaths';

export const defaultTermLength = '12';
export const USA_USA_USA = 'United States';
export const defaultRegion = states[0].abbreviation;

export const tabs = [
  {
    label: 'CUSTOMERS.CUSTOMERS_DETAIL.SITES_TAB_LABEL',
    pathSegment: PATH_SEGMENT_SITES,
  },
  {
    label: 'CUSTOMERS.CUSTOMERS_DETAIL.INFO_TAB_LABEL',
    pathSegment: PATH_SEGMENT_INFO,
  },
];

export const defaultSiteDetailsTab = 'generalInfo';

export const navTabs = {
  [defaultSiteDetailsTab]: {
    id: 'idSiteGeneralInfo',
    path: '/generalInfo',
  },
  subscriptions: {
    id: 'idSiteSubscriptions',
    path: '/subscriptions',
  },
};

export const BLUE_SELF_MONITORING = 'BlueSelfMonitoring';
export const SUBSCRIPTIONS = 'subscriptions';
