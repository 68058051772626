import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as app from 'constants/app';
import {
  PATH_ACCOUNTS,
  PATH_ACTIVATION_SUCCESS,
  PATH_ALARMS,
  PATH_BOOKMARKS,
  PATH_CLAIM,
  PATH_DASHBOARD,
  PATH_DEVICES,
  PATH_GUEST,
  PATH_HEALTH_MONITOR,
  PATH_INVALID_TOKEN,
  PATH_LOGIN,
  PATH_PROFILE,
  PATH_REGISTER,
  PATH_REPORTS,
  PATH_REQUEST_RESET,
  PATH_RESET,
  PATH_SIGNUP,
  PATH_SIGNUP_SELF_PROVISIONED,
  PATH_VIEWS,
} from 'constants/urlPaths';

import ga from 'react-ga';
import { InvalidToken, NotFound } from 'components';

import {
  ActivationSuccessContainer,
  BookmarksContainer,
  ClaimDeviceFormContainer,
  CustomersContainer,
  GuestClaimDeviceFormContainer,
  HealthMonitorContainer,
  LoginContainer,
  NotificationsContainer,
  OrganizationDeviceContainer,
  OrganizationsContainer,
  ProfileContainer,
  RequestResetContainer,
  ResetPasswordContainer,
  SelectRegionContainer,
  SignupACCContainer,
  SignupContainer,
  ViewsContainer,
  ReportsContainer,
} from 'containers';
import App from '../App';

const options = {
  gaOptions: { anonymizeIp: true, cookieDomain: 'none' },
};

ga.initialize(app.GA_APPLICATION_ID, options);

const Routes = () => {
  return (
    <BrowserRouter>
      <Route
        path="/"
        render={appProps => (
          <App {...appProps}>
            <Switch>
              <Route component={SelectRegionContainer} exact path="/" />
              <Route component={LoginContainer} path={PATH_LOGIN} />
              <Route component={LoginContainer} path={PATH_REGISTER} />
              <Route
                component={RequestResetContainer}
                path={PATH_REQUEST_RESET}
              />
              <Route
                path={PATH_DEVICES}
                render={props => <OrganizationDeviceContainer {...props} />}
              />
              <Route
                path={PATH_ACCOUNTS}
                render={props => <OrganizationsContainer {...props} />}
              />
              <Route
                path={PATH_ALARMS}
                render={props => <NotificationsContainer {...props} />}
              />
              <Route
                path={PATH_PROFILE}
                render={props => <ProfileContainer {...props} />}
              />
              <Route
                path={PATH_DASHBOARD}
                render={props => <CustomersContainer {...props} />}
              />
              <Route
                path={PATH_VIEWS}
                render={props => <ViewsContainer {...props} />}
              />
              <Route
                path={PATH_BOOKMARKS}
                render={props => <BookmarksContainer {...props} />}
              />
              <Route
                component={HealthMonitorContainer}
                path={PATH_HEALTH_MONITOR}
              />
              <Route component={ReportsContainer} path={PATH_REPORTS} />
              <Route
                component={ClaimDeviceFormContainer}
                path={`${PATH_CLAIM}/:id`}
              />
              <Route
                component={GuestClaimDeviceFormContainer}
                path={`${PATH_GUEST}/:id`}
              />
              <Route
                component={ActivationSuccessContainer}
                path={`${PATH_ACTIVATION_SUCCESS}/:id`}
              />
              <Route
                component={SignupACCContainer}
                path={PATH_SIGNUP_SELF_PROVISIONED}
              />
              <Route component={SignupContainer} path={PATH_SIGNUP} />
              <Route component={ResetPasswordContainer} path={PATH_RESET} />
              <Route component={InvalidToken} path={PATH_INVALID_TOKEN} />
              <Route component={NotFound} />
            </Switch>
          </App>
        )}
      />
    </BrowserRouter>
  );
};
export default Routes;
