export const idMinInput = 'minuteInput';
export const idSecInput = 'secondInput';

export const CONTROL_TYPE = {
  ELEMENT: 'ELEMENT',
  FORM: 'FORM',
};

/**
 * TODO: MVAAS-16596 Research how to manage colors at one place for the app
 */
export const WHITE_COLOR = '#FFFFFF';

export const getControlTypeTheme = (disabled, isSelectedOption) => {
  if (!disabled && isSelectedOption) {
    return '#28b1ec';
  }

  if (!disabled && !isSelectedOption) {
    return 'rgba(74, 74, 74, 0.4)';
  }

  if (disabled && isSelectedOption) {
    return 'rgba(0, 0, 0, 0.4)';
  }

  return '';
};
