import { FORM_DEFAULTS } from 'constants/app';

export const idTextInput = 'searchInput';

export const labelLocationModal = 'LocationModal';

export const defaultAdminUser = FORM_DEFAULTS.new;

// TODO: Add SAP ID and PO to searchable fields
export const textInputFields = ['Name', 'Address', 'City', 'Region'];
