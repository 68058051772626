/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './OGTable.css';

const OGCell = ({
  alignClass,
  cellContent,
  clickable,
  disabled,
  fieldName,
  height,
  onClick,
  title,
  truncated,
  width,
}) => {
  return (
    <td
      className={`${styles.ogBodyCell} ${alignClass(fieldName)} ${
        clickable ? styles.clickableRow : ''
      }`}
      onClick={onClick}
      onKeyDown={() => {}}
      style={height ? { height } : {}}
      width={width}
    >
      <div
        className={`${styles.ogBodyCellContent} ${alignClass(fieldName)} ${
          disabled ? styles.disabledCell : ''
        }`}
        title={title}
      >
        {truncated ? (
          <div className={`${truncated ? styles.truncated : ''}`}>
            {cellContent}
          </div>
        ) : (
          cellContent
        )}
      </div>
    </td>
  );
};

OGCell.defaultProps = {
  clickable: false,
  disabled: false,
  height: '',
  onClick: () => {},
  title: '',
  truncated: false,
  width: '',
};

OGCell.propTypes = {
  alignClass: PropTypes.func.isRequired,
  cellContent: PropTypes.node.isRequired,
  clickable: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  title: PropTypes.string,
  truncated: PropTypes.bool,
  width: PropTypes.string,
};

export default OGCell;
