// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { ai } from 'util/telemetryService';

// Container
import { Oasis, PageMessage } from 'containers';

// Components
import { Button } from 'lib';

// Constants
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  FORGOT_PASSWORD_LINK_ID,
  PASSWORD_INPUT_ID,
  SIGN_UP_ACC_LINK_ID,
  SUBMIT_BUTTON_ID,
  USERNAME_INPUT_ID,
} from 'constants/ElementId';
import * as messageTypes from 'constants/MessageTypes';
import { APP_INSIGHTS_EVENTS } from 'constants/app';
import {
  PATH_CLAIM,
  PATH_GUEST,
  PATH_REGISTER,
  PATH_REQUEST_RESET,
  PATH_SIGNUP_SELF_PROVISIONED,
} from 'constants/urlPaths';

// Style
import {
  formField,
  formGroup,
  formLabel,
} from 'components/VerticalForm/styles.css';
import { formWrapper, horizontalDivider } from 'sharedStyles/modalForms.css';
import { box, noAccountLabel, pageError, submitWrap } from './styles.css';

// Class
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleEmailChange = evt => {
    this.setState({ email: evt.target.value });
  };

  handlePasswordChange = evt => {
    this.setState({ password: evt.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { onChange } = this.props;
    const { email, password } = this.state;
    onChange({ email, password });
  };

  renderLinks = () => {
    const { path, redirectToOnSuccess } = this.props;
    const { email, password } = this.state;
    let unauthenticatedUser;

    if (path === PATH_REGISTER && redirectToOnSuccess) {
      const routeGuest = redirectToOnSuccess.replace(PATH_CLAIM, PATH_GUEST);
      unauthenticatedUser = (
        <div className={box}>
          <div className={`${noAccountLabel} ${submitWrap}`}>
            <Translate
              data={{
                link: (
                  <Link id={FORGOT_PASSWORD_LINK_ID} to={routeGuest}>
                    <Translate id="BUTTONS.CLICK_HERE" />
                  </Link>
                ),
              }}
              id="LOGIN.NO_ACCOUNT_TEXT"
            />
            <Button
              disabled={email.length === 0 || password.length === 0}
              id={SUBMIT_BUTTON_ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
        </div>
      );
    } else {
      unauthenticatedUser = (
        <div className={box}>
          <div className={`${noAccountLabel} ${submitWrap}`}>
            <Link id={FORGOT_PASSWORD_LINK_ID} to={PATH_REQUEST_RESET}>
              <Translate id="LOGIN.FORGOT_PASSWORD_TEXT" />
            </Link>
            <Button
              disabled={email.length === 0 || password.length === 0}
              id={SUBMIT_BUTTON_ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
          <div className={horizontalDivider} />
          <div className={noAccountLabel}>
            <Link
              id={SIGN_UP_ACC_LINK_ID}
              onClick={() => {
                ai.appInsights.trackEvent({
                  name: APP_INSIGHTS_EVENTS.registerLinkClick,
                });
              }}
              to={PATH_SIGNUP_SELF_PROVISIONED}
            >
              <Translate id="BUTTONS.REGISTER_NOW" />
            </Link>
          </div>
        </div>
      );
    }
    return unauthenticatedUser;
  };

  render() {
    // disable autocomplete if user is signing in after changing pw
    // const { data-disableautocomplete } = this.props;
    const { email, password } = this.state;
    const { translate } = this.props;

    const shouldDisableAutoComplete = this.props['data-disableautocomplete']
      ? 'new-password'
      : '';
    return (
      <div className={formWrapper}>
        <form onSubmit={this.handleSubmit}>
          <div className={pageError}>
            <PageMessage
              messageStyle={messageStyleStrings.error}
              messageType={messageTypes.LOGIN_ERROR}
            />
          </div>

          <div className={formGroup}>
            <div className={formLabel}>{translate('COMMON.EMAIL')}</div>
            <input
              className={formField}
              id={USERNAME_INPUT_ID}
              onChange={this.handleEmailChange}
              placeholder={translate('LOGIN.EMAIL_PLACEHOLDER')}
              type="email"
              value={email}
            />
          </div>
          <div className={formGroup}>
            <div className={formLabel}>{translate('LOGIN.PASSWORD_LABEL')}</div>
            <input
              autoComplete={shouldDisableAutoComplete}
              className={formField}
              id={PASSWORD_INPUT_ID}
              onChange={this.handlePasswordChange}
              placeholder={translate('LOGIN.PASSWORD_PLACEHOLDER')}
              type="password"
              value={password}
            />
          </div>
          {this.renderLinks()}
        </form>
        <Oasis />
      </div>
    );
  }
}

LoginForm.defaultProps = {
  'data-disableautocomplete': false,
  onChange: () => undefined,
  redirectToOnSuccess: null,
};

LoginForm.propTypes = {
  'data-disableautocomplete': PropTypes.bool,
  onChange: PropTypes.func,
  path: PropTypes.string.isRequired,
  redirectToOnSuccess: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(LoginForm);
