export const NEW_VIEW_ID = 'NEW';
export const NEW_VIEW_NAME = 'VIEWS.NEW_VIEW_DEFAULT_TITLE';
export const DEFAULT_VIEW_COUNT = 4;

export const newView = {
  Id: NEW_VIEW_ID,
  Name: NEW_VIEW_NAME,
  Default: false,
  Favorite: false,
  Layout: 2,
  Elements: Array.apply(null, Array(DEFAULT_VIEW_COUNT)),
  layoutIsDirty: true,
};
