export function getCurrent(observable) {
  if (!observable) {
    return observable;
  }

  let value;

  const onceValue = function onceValue(v) {
    value = v;
  };

  observable.onValue(onceValue);
  observable.offValue(onceValue);
  return value;
}
