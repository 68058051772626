export default {
  'health-monitor': {
    description: 'Enable Health Monitor',
    enabled: false,
    name: 'health-monitor',
  },
  'site-view': {
    name: 'site-view',
    enabled: false,
    description: 'Enable site-view list',
  },
  'site-view-tree': {
    name: 'site-view-tree',
    enabled: true,
    description: 'Enable site-view-tree',
  },
};
