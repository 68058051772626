import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
import { ICON_CLOSE, ICON_CLOSE_HOVER } from 'constants/app';
import { IconX } from 'icons';

// Styles
import { modalCancelDiv } from './styles.css';

// Constants
import * as ModalCancelConsts from './constants';

class ModalCancelIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
    };
  }

  handleMouseOver = () => {
    this.setState({
      mouseOver: true,
    });
  };

  handleMouseOut = () => {
    this.setState({
      mouseOver: false,
    });
  };

  render() {
    const {
      isVisible,
      handleCancel,
      size,
      lineHeight,
      colorHover,
      color,
    } = this.props;
    const { mouseOver } = this.state;
    return (
      <div
        className={modalCancelDiv}
        style={{
          display: isVisible
            ? ModalCancelConsts.BLOCK_STYLE
            : ModalCancelConsts.NONE_STYLE,
        }}
        onClick={handleCancel}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onBlur={this.handleMouseOut}
        onFocus={this.handleMouseOver}
        onKeyPress={handleCancel}
        role="button"
        tabIndex="0"
      >
        <IconX
          height={size}
          width={size}
          lineHeight={lineHeight}
          strokeFill={mouseOver ? colorHover : color}
        />
      </div>
    );
  }
}

ModalCancelIcon.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  size: PropTypes.number,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
};

ModalCancelIcon.defaultProps = {
  isVisible: true,
  size: ModalCancelConsts.DEFAULT_SIZE,
  lineHeight: ModalCancelConsts.DEFAULT_LINE_HEIGHT,
  color: ICON_CLOSE,
  colorHover: ICON_CLOSE_HOVER,
};
export default ModalCancelIcon;
