import { canvasObjectTypes } from 'constants/app';

export const CAMERA_SETTING = {
  ROTATION: 'ROTATION',
};

export const CAMERA_PTZ_OBJECT = [
  {
    id: 100001,
    type: canvasObjectTypes.ptzArrow,
    point1: {
      x: 10,
      y: 10,
    },
    point2: {
      x: 20,
      y: 20,
    },
    isSelected: true,
    directional: true,
  },
];

export const getCameraSettingValue = (settings, valueFor) => {
  if (settings && valueFor) {
    switch (valueFor) {
      case CAMERA_SETTING.ROTATION: {
        return settings &&
          settings.acquisition &&
          settings.acquisition.imageRotation &&
          settings.acquisition.imageRotation.val
          ? settings.acquisition.imageRotation.val
          : 'None';
      }
      default: {
        return null;
      }
    }
  } else {
    return null;
  }
};
