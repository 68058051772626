
// Libs
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';

// Components
import { Checkbox, GenericMessage } from 'components';

// Styles
import {
  formContent,
  formField,
  formGroup,
  formLabel,
  settingsPanelHeader,
  sliderInput,
} from './styles.css';

// Class
class CameraSettingsAudioSpeakers extends Component {
  constructor(props) {
    super(props);
    this.state = props.speakers;
  }

  get isSpeakerEnabled() {
    const { hasSpeaker } = this.state;
    return hasSpeaker;
  }

  get isDisabled() {
    const { hasSpeaker, isSaving } = this.state;
    const { disabled } = this.props;
    return !hasSpeaker || isSaving || disabled;
  }

  get noSpeakersMessage() {
    if (!this.isSpeakerEnabled) {
      return (
        <GenericMessage>
          <Translate id="CAMERA.AUDIO.OUTPUT.NONE" />
        </GenericMessage>
      );
    }
    return null;
  }

  setAudioEnabled = activated => {
    this.setStateValue({ activated });
  };

  setAudioVolume = volume => {
    this.setStateValue({ volume });
  };

  setStateValue = value => {
    const { onChange } = this.props;
    /* notify listeners about the resulting new state (not just the
     * specific value that changed
     */
    onChange(Object.assign({}, this.state, value));
    /* update our local state, don't rely on a parent component to update
     * our props
     */
    this.setState(value);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(nextProps.speakers);
  }

  render() {
    const { activated, volume } = this.state;
    return (
      <fieldset data-for="audio-speakers" disabled={this.isDisabled}>
        <legend className={settingsPanelHeader}>
          <Translate id="CAMERA.AUDIO.OUTPUT.SPEAKERS_HEADER" />
        </legend>
        {this.noSpeakersMessage}
        <div className={formContent}>
          <Translate>
            {({ translate }) => (
              <Checkbox
                id="audio-settings_checkbox-enabled"
                checked={!!activated}
                onChange={this.setAudioEnabled}
                label={translate('CAMERA.AUDIO.OUTPUT.FORM.LABEL.ENABLE_AUDIO')}
              />
            )}
          </Translate>
          <div className={formGroup}>
            <div className={formLabel}>
              <Translate id="CAMERA.AUDIO.OUTPUT.FORM.LABEL.VOLUME" />
            </div>
            <div className={formField}>
              <div className={sliderInput}>
                <InputRange
                  maxValue={100}
                  minValue={0}
                  step={1}
                  value={volume}
                  onChange={this.setAudioVolume}
                  disabled={this.isDisabled || !activated}
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

CameraSettingsAudioSpeakers.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  speakers: PropTypes.shape({
    activated: PropTypes.bool.isRequired,
    hasSpeaker: PropTypes.bool.isRequired,
    volume: PropTypes.number.isRequired,
  }).isRequired,
};

CameraSettingsAudioSpeakers.defaultProps = {
  disabled: false,
  onChange: function noop() {},
};

export default CameraSettingsAudioSpeakers;
