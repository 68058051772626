import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconEditCamera = props => {
  return (
    <div title="Edit camera">
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g transform="translate(-696.000000, -441.000000)">
          <g transform="translate(693.000000, 436.000000)" fill="none">
            <g>
              <rect
                fill={props.fill}
                fillRule="evenodd"
                x="3"
                y="5"
                width="24"
                height="24"
                rx="4"
              />
              <rect
                stroke={props.fill}
                strokeWidth="1"
                x="3.5"
                y="5.5"
                width="23"
                height="23"
                rx="4"
              />
            </g>
            <g
              id="Group"
              transform="translate(15.885548, 15.971226) rotate(-315.000000) translate(-15.885548, -15.971226) translate(5.385548, 4.471226)"
            >
              <polyline
                id="Path-4"
                stroke="#FFFFFF"
                points="7.10811363 6.64290767 0.74685835 12.8755302 10.8348545 22.9635264 20.9423985 12.8559825 14.0648108 5.97839476"
              />
              <path
                d="M9.411264,4.71730743 L12.0398354,4.71730743 L12.0398354,13.9173074 L9.411264,13.9173074 L9.411264,4.71730743 Z M9.411264,0.774450284 L12.0398354,0.774450284 L12.0398354,3.40302171 L9.411264,3.40302171 L9.411264,0.774450284 Z M9.08269257,14.9030217 L12.3684069,14.9030217 L10.7255497,16.5458789 L9.08269257,14.9030217 Z"
                id="Combined-Shape"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconEditCamera.defaultProps = {
  className: null,
  width: '24px',
  height: '24px',
  fill: ACTIVE_COLOR_DEFAULT,
};

export default IconEditCamera;
