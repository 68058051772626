import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import { Button } from 'lib';
import { ACTION_IMAGES, idEditCustomer, tenantTypeStrings } from '../constants';

const ACTION_IMAGE_TENANT_TYPE = {
  [ACTION_IMAGES.LOCATION]: tenantTypeStrings.Subscriber,
  [ACTION_IMAGES.DEALER]: tenantTypeStrings.Dealer,
  [ACTION_IMAGES.SUBSCRIBER]: tenantTypeStrings.Subscriber,
  [ACTION_IMAGES.DEALER_SELECTED]: tenantTypeStrings.Dealer,
};

const EditControl = ({ actionImage, canEdit, onEditClick, translate }) => {
  let resultComponent = null;
  const tenantType = ACTION_IMAGE_TENANT_TYPE[actionImage];
  if (canEdit) {
    switch (actionImage) {
      case ACTION_IMAGES.LOCATION:
      case ACTION_IMAGES.DEALER:
      case ACTION_IMAGES.SUBSCRIBER:
      case ACTION_IMAGES.DEALER_SELECTED:
        resultComponent = (
          <Button
            buttonType="primary"
            id={idEditCustomer}
            inputType="button"
            onClick={onEditClick}
            text={translate('BUTTONS.EDIT_ORGANIZATION', {
              tenantType: translate(`TENANTS.${tenantType.toUpperCase()}`),
            })}
          />
        );

        break;
      default:
    }
  }
  return resultComponent;
};

EditControl.defaultProps = {
  actionImage: '',
  canEdit: false,
  onEditClick: () => {},
};

EditControl.propTypes = {
  actionImage: PropTypes.string,
  canEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(EditControl);
