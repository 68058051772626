/* eslint-disable import/no-cycle */
// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import InputRange from 'react-input-range';
import deepEqual from 'deep-equal';

// Components
import { TextInput, Toggle } from 'components';
import { Button, GroupLayout } from 'lib';

// Utils
import get from 'lodash.get';
import { convertRotationToValue, rotationTypes } from 'util/AdjustPrivacyZone';
import { calculateFramePeriod } from 'util/calculateFramePeriod';
import { cloneDeep } from 'util/cameraSettingLinks';

// Icons
import {
  IconBtnFocusInfinity,
  IconBtnFocusToZero,
  IconBtnZoomFocusIn,
  IconBtnZoomFocusInMore,
  IconBtnZoomFocusOut,
  IconBtnZoomFocusOutMore,
  IconBtnZoomInMax,
  IconBtnZoomOutMax,
} from 'icons';

// Constants
import {
  cameraVideoSettings,
  idIconBtnZoomFocusIn,
  idIconBtnZoomFocusInMore,
  idIconBtnZoomFocusOut,
  idIconBtnZoomFocusOutMore,
  VIDEO_CONTENT_ID,
  VIDEO_FIELDS,
} from './constants';

// Styles
import 'react-input-range/lib/css/index.css';
import {
  cameraSettingsBasic,
  focusAutoButton,
  focusSegmentedButton,
  formContent,
  formField,
  formGroup,
  formLabel,
  rightSettings,
  settingsPanel,
  settingsPanelHeader,
  sliderField,
  sliderInput,
} from './styles.css';

const smallStep = 0.01;
const largeStep = 0.03;
const zoomFocusButtons = [
  {
    id: idIconBtnZoomFocusOutMore,
    step: largeStep * -1,
    title: {
      focus: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_FAR_MORE',
      zoom: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_OUT_MORE',
    },
    type: <IconBtnZoomFocusOutMore />,
  },
  {
    id: idIconBtnZoomFocusOut,
    step: smallStep * -1,
    title: {
      focus: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_FAR',
      zoom: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_OUT',
    },
    type: <IconBtnZoomFocusOut />,
  },
  {
    id: idIconBtnZoomFocusIn,
    step: smallStep,
    title: {
      focus: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_NEAR',
      zoom: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_IN',
    },
    type: <IconBtnZoomFocusIn />,
  },
  {
    id: idIconBtnZoomFocusInMore,
    step: largeStep,
    title: {
      focus: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_NEAR_MORE',
      zoom: 'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_IN_MORE',
    },
    type: <IconBtnZoomFocusInMore />,
  },
];

// Class
class CameraSettingsVideo extends Component {
  constructor(props) {
    super(props);
    this.state = props.settings ? this.getInitialState() : cameraVideoSettings;
    this.onFocusUpdate = this.onFocusUpdate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onZoomUpdate = this.onZoomUpdate.bind(this);
    this.updateCompressionField = this.updateCompressionField.bind(this);
    this.validateValue = this.validateValue.bind(this);
  }

  get focusUI() {
    const { cameraData } = this.props;
    if (cameraData.hasFocus || cameraData.hasAutoFocus) {
      return (
        <div className={formGroup}>
          {this.focusTitle}
          <div className={formField}>
            {this.manualFocusUI}
            {this.autoFocusUI}
          </div>
        </div>
      );
    }
    return undefined;
  }

  get focusTitle() {
    const { cameraData } = this.props;
    if (cameraData.hasFocus || cameraData.hasAutoFocus) {
      return (
        <label className={formLabel}>
          <Translate id="CAMERA.IMAGE_AND_DISPLAY.LABELS.FOCUS" />
        </label>
      );
    }
    return undefined;
  }

  get manualFocusUI() {
    const { cameraData } = this.props;
    if (cameraData.hasFocus) {
      return (
        <Translate>
          {({ translate }) => (
            <div>
              <button
                className={focusSegmentedButton}
                onClick={this.onFocusUpdate.bind(this, -2)}
                title={translate(
                  'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_ZERO',
                )}
                type="button"
              >
                <IconBtnFocusToZero
                  title={translate(
                    'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_ZERO',
                  )}
                />
              </button>
              {zoomFocusButtons.map(btn => (
                <button
                  key={btn.id}
                  className={focusSegmentedButton}
                  onClick={this.onFocusUpdate.bind(this, btn.step)}
                  title={translate(btn.title.focus)}
                  type="button"
                >
                  {btn.type}
                </button>
              ))}
              <button
                className={focusSegmentedButton}
                onClick={this.onFocusUpdate.bind(this, 2)}
                title={translate(
                  'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_INFINITY',
                )}
                type="button"
              >
                <IconBtnFocusInfinity
                  title={translate(
                    'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.FOCUS_INFINITY',
                  )}
                />
              </button>
            </div>
          )}
        </Translate>
      );
    }
    return undefined;
  }

  get autoFocusUI() {
    const { cameraData } = this.props;
    if (cameraData.hasAutoFocus) {
      return (
        <div>
          <Translate>
            {({ translate }) => (
              <button
                className={focusAutoButton}
                onClick={this.onAutofocus}
                title={translate('CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.AUTO')}
                type="button"
              >
                <Translate id="CAMERA.IMAGE_AND_DISPLAY.LABELS.AUTO" />
              </button>
            )}
          </Translate>
        </div>
      );
    }
    return undefined;
  }

  get zoomUI() {
    const { cameraData } = this.props;
    // it feels like this should be checking against a different capability but
    // there is nothing zoom related in the capabilities object.
    if (cameraData.hasFocus) {
      return (
        <div className={formGroup}>
          <label className={formLabel}>
            <Translate id="CAMERA.IMAGE_AND_DISPLAY.LABELS.ZOOM" />
          </label>
          <div className={formField}>
            <Translate>
              {({ translate }) => (
                <div>
                  <button
                    className={focusSegmentedButton}
                    onClick={() => {
                      this.onZoomUpdate(-2);
                    }}
                    title={translate(
                      'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_OUT_MAX',
                    )}
                    type="button"
                  >
                    <IconBtnZoomOutMax />
                  </button>
                  {zoomFocusButtons.map(btn => (
                    <button
                      key={btn.id}
                      className={focusSegmentedButton}
                      onClick={() => {
                        this.onZoomUpdate(btn.step);
                      }}
                      title={translate(btn.title.zoom)}
                      type="button"
                    >
                      {btn.type}
                    </button>
                  ))}
                  <button
                    className={focusSegmentedButton}
                    onClick={() => {
                      this.onZoomUpdate(2);
                    }}
                    title={translate(
                      'CAMERA.IMAGE_AND_DISPLAY.TOOL_TIPS.ZOOM_IN_MAX',
                    )}
                    type="button"
                  >
                    <IconBtnZoomInMax />
                  </button>
                </div>
              )}
            </Translate>
          </div>
        </div>
      );
    }
    return undefined;
  }

  get zoomFocusUI() {
    const { cameraData } = this.props;
    if (cameraData.hasFocus) {
      return (
        <div>
          {this.zoomUI}
          {this.focusUI}
        </div>
      );
    }
    return undefined;
  }

  get imageRotationUI() {
    const { acquisition, settings, updateAcquisitionField } = this.props;
    const { acquisition: settingsAcquisition } = settings;
    if (settingsAcquisition && settingsAcquisition.imageRotation) {
      return (
        <div className={formGroup}>
          <label className={formLabel}>
            <Translate id="CAMERA.IMAGE_AND_DISPLAY.LABELS.ROTATION" />
          </label>
          <div className={formField}>
            <select
              id="select-camera-image-rotation"
              onChange={event =>
                updateAcquisitionField(event.target.value, 'imageRotation')
              }
              value={get(acquisition, 'imageRotation.val') || 'none'}
            >
              {settingsAcquisition.imageRotation.options.map(opt => (
                <Translate key={opt}>
                  {({ translate }) => (
                    <option key={opt} value={opt}>
                      {opt === rotationTypes.None
                        ? translate(
                            'CAMERA.IMAGE_AND_DISPLAY.ROTATION.NONE_LABEL',
                          )
                        : translate(
                            'CAMERA.IMAGE_AND_DISPLAY.ROTATION.DEGREES_LABEL',
                            { number: convertRotationToValue(opt) },
                          )}
                    </option>
                  )}
                </Translate>
              ))}
            </select>
          </div>
        </div>
      );
    }
    return undefined;
  }

  get smartCodecUI() {
    const { compression } = this.state;
    if (!compression.enableISM) {
      return null;
    }
    return (
      <>
        <div className={formGroup}>
          <Translate id="CAMERA.SETTINGS.SMART_CODEC_LABEL" />
        </div>
        <div className={formGroup}>
          <label className={formLabel}>
            <Translate id="CAMERA.SETTINGS.COMPRESSION_FORM.LABELS.SMART_CODEC" />
          </label>
          <div className={formField}>
            <Toggle
              checked={compression.enableISM.val}
              id={VIDEO_FIELDS.FIELDS.ENABLE_ISM}
              onChange={() => {
                const enableISMVal = !compression.enableISM.val || false;
                this.updateCompressionField(
                  VIDEO_FIELDS.FIELDS.ENABLE_ISM,
                  null,
                  enableISMVal,
                );
              }}
            />
          </div>
        </div>
      </>
    );
  }

  get compressionChanged() {
    const { settings } = this.props;
    const { compression } = this.state;
    const fields = Object.keys(settings.compression);
    return !!fields.find(
      field => !deepEqual(settings.compression[field], compression[field]),
    );
  }

  get acquisitionChanged() {
    const { acquisition, settings } = this.props;
    const { acquisition: settingsAcquisition } = settings;
    return deepEqual(acquisition, settingsAcquisition);
  }

  prepareCompressionSetting = () => {
    const { settings } = this.props;
    const { compression } = this.state;
    const propsCompression = settings.compression;
    const updatedCompression = { ...compression };
    if (
      !propsCompression.keyFrameIntervalFrames &&
      propsCompression.keyFrameInterval &&
      updatedCompression.keyFrameIntervalFrames
    ) {
      updatedCompression.keyFrameInterval =
        updatedCompression.keyFrameIntervalFrames;
      delete updatedCompression.keyFrameIntervalFrames;
    }
    return updatedCompression;
  };

  onSave = () => {
    const { onSave } = this.props;
    const compression = this.prepareCompressionSetting();
    this.setState({ isSaving: true }, () => {
      onSave(compression);
    });
  };

  onZoomUpdate = step => {
    const { onZoomUpdate } = this.props;
    const constrainedStep = Math.max(-1, Math.min(1, step));
    onZoomUpdate(constrainedStep);
  };

  onFocusUpdate = step => {
    const { onFocusUpdate } = this.props;
    const constrainedStep = Math.max(-1, Math.min(1, step));
    onFocusUpdate(constrainedStep);
  };

  onAutofocus = () => {
    const { onAutofocus } = this.props;
    onAutofocus();
  };

  getInitialState = () => {
    const { settings } = this.props;
    const comp = cloneDeep(settings.compression);
    // Initialize compression vars
    let { compression } = cameraVideoSettings;
    compression = cloneDeep(compression);
    if (comp) {
      compression = { ...compression, ...comp };
      compression.keyFrameIntervalFrames =
        comp.keyFrameIntervalFrames || comp.keyFrameInterval || {};
      compression.keyFramePeriod = calculateFramePeriod(
        compression.keyFrameIntervalFrames || compression.keyFrameInterval,
        compression.requestedFrameRate,
      );
    }
    return {
      compression,
      isSaving: false,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settings } = this.props;
    if (settings !== nextProps.settings) {
      this.setState(this.getInitialState());
    }
  }

  updateCompressionField(field, e, v) {
    const { compression } = this.state;
    const currentComp = compression;
    const newComp = currentComp;
    // enable_ISM expects a boolean value and has no target
    if (field === VIDEO_FIELDS.FIELDS.ENABLE_ISM) {
      newComp[field].val = v;
    } else if (field !== VIDEO_FIELDS.FIELDS.RESOLUTION) {
      newComp[field].val = parseInt(e.target ? e.target.value : v, 10);
    } else {
      newComp[field].val = e.target ? e.target.value : v;
    }

    if (
      field === VIDEO_FIELDS.FIELDS.KEY_FRAME_INTERVAL_FRAMES ||
      field === VIDEO_FIELDS.FIELDS.REQUESTED_FRAME_RATE
    ) {
      const frameRate =
        newComp.keyFrameIntervalFrames || newComp.keyFrameInterval;
      const framePeriod = calculateFramePeriod(
        frameRate,
        newComp.requestedFrameRate,
      );
      newComp.keyFramePeriod = framePeriod;
    }

    this.setState({ compression: newComp, isSaving: false });
  }

  validateValue(field, e) {
    const { compression } = this.state;
    const currentC = compression;
    const newC = currentC;
    const targetMinValue = e.target.min;
    const targetMaxValue = e.target.max;
    if (e.target.value === '') {
      e.target.value = targetMinValue;
    }
    if (parseInt(e.target.value, 10) > parseInt(targetMaxValue, 10)) {
      e.target.value = targetMaxValue;
    }
    if (parseInt(e.target.value, 10) < parseInt(targetMinValue, 10)) {
      e.target.value = targetMinValue;
    }
    e.target.value = parseInt(e.target.value, 10).toFixed(0);

    newC[field].val = parseInt(e.target.value, 10);

    if (
      field === VIDEO_FIELDS.FIELDS.KEY_FRAME_INTERVAL_FRAMES ||
      field === VIDEO_FIELDS.FIELDS.REQUESTED_FRAME_RATE
    ) {
      const frameRate = newC.keyFrameIntervalFrames
        ? newC.keyFrameIntervalFrames.val
        : newC.keyFrameInterval.val;
      const framePeriod = calculateFramePeriod(
        frameRate,
        newC.requestedFrameRate.val,
      );
      newC.keyFramePeriod = framePeriod;
    }
    // this.setState({ newC });
  }

  render() {
    const { settings, updateAcquisitionField } = this.props;
    const { compression, isSaving } = this.state;
    const comp = compression || {};
    const className = {
      class: formGroup,
      input: formField,
      label: formLabel,
    };
    return (
      <div
        className={`${rightSettings} ${cameraSettingsBasic}`}
        id={VIDEO_CONTENT_ID}
      >
        {settings.acquisition && (
          <div className={settingsPanel}>
            <div className={settingsPanelHeader}>
              <Translate id="CAMERA.IMAGE_AND_DISPLAY.HEADER" />
            </div>
            <div className={formContent}>
              {this.zoomFocusUI}
              {this.imageRotationUI}
            </div>
          </div>
        )}
        {settings.compression && (
          <div className={settingsPanel}>
            <div className={settingsPanelHeader}>
              <Translate id="CAMERA.COMPRESSION.HEADER" />
            </div>
            <div className={formContent}>
              {this.smartCodecUI}
              <div className={formGroup}>
                <label className={formLabel}>
                  <Translate id="CAMERA.COMPRESSION.LABELS.IMAGE_RATE" />
                </label>
                <div className={`${formField} ${sliderField}`}>
                  <div className={sliderInput}>
                    <InputRange
                      maxValue={comp.requestedFrameRate.max}
                      minValue={comp.requestedFrameRate.min}
                      onChange={e => {
                        this.updateCompressionField(
                          VIDEO_FIELDS.FIELDS.REQUESTED_FRAME_RATE,
                          false,
                          e,
                        );
                      }}
                      step={1}
                      value={comp.requestedFrameRate.val}
                    />
                  </div>
                </div>
              </div>
              <TextInput
                className={className}
                id={VIDEO_FIELDS.TEXT_INPUT.IMAGE_QUALITY}
                label={
                  <Translate id="CAMERA.COMPRESSION.LABELS.IMAGE_QUALITY" />
                }
                max={comp.imageQuality.max}
                min={comp.imageQuality.min}
                onBlur={e => {
                  this.validateValue(VIDEO_FIELDS.FIELDS.IMAGE_QUALITY, e);
                }}
                onChange={e => {
                  this.updateCompressionField(
                    VIDEO_FIELDS.FIELDS.IMAGE_QUALITY,
                    e,
                  );
                }}
                type="number"
                value={comp.imageQuality.val}
              />
              <TextInput
                className={className}
                id={VIDEO_FIELDS.TEXT_INPUT.MAX_BIT_RATE}
                label={
                  <Translate id="CAMERA.COMPRESSION.LABELS.MAX_BIT_RATE" />
                }
                max={comp.maxBitrate.max}
                min={comp.maxBitrate.min}
                onBlur={e => {
                  this.validateValue(VIDEO_FIELDS.FIELDS.MAX_BITRATE, e);
                }}
                onChange={e => {
                  this.updateCompressionField(
                    VIDEO_FIELDS.FIELDS.MAX_BITRATE,
                    e,
                  );
                }}
                type="number"
                value={comp.maxBitrate.val}
              />
              <div className={formGroup}>
                <label className={formLabel}>
                  <Translate id="CAMERA.COMPRESSION.LABELS.RESOLUTION" />
                </label>
                <div className={formField}>
                  <select
                    disabled={!comp.resolution.options}
                    id={VIDEO_FIELDS.TEXT_INPUT.RESOLUTION}
                    onChange={e => {
                      this.updateCompressionField(
                        VIDEO_FIELDS.FIELDS.RESOLUTION,
                        e,
                      );
                    }}
                    value={comp.resolution.val}
                  >
                    {comp.resolution.options
                      ? comp.resolution.options.map(opt => (
                          <option key={opt} value={opt}>
                            {opt}
                          </option>
                        ))
                      : []}
                  </select>
                </div>
              </div>
              <TextInput
                className={className}
                disabled={
                  Object.entries(comp.keyFrameIntervalFrames).length === 0
                }
                id={VIDEO_FIELDS.TEXT_INPUT.KEYFRAME_INTERVAL}
                label={
                  <Translate id="CAMERA.COMPRESSION.LABELS.KEY_FRAME_INTERVAL" />
                }
                max={comp.keyFrameIntervalFrames.max}
                min={comp.keyFrameIntervalFrames.min}
                onBlur={e => {
                  this.validateValue(
                    VIDEO_FIELDS.FIELDS.KEY_FRAME_INTERVAL_FRAMES,
                    e,
                  );
                }}
                onChange={e => {
                  this.updateCompressionField(
                    VIDEO_FIELDS.FIELDS.KEY_FRAME_INTERVAL_FRAMES,
                    e,
                  );
                }}
                type="number"
                value={comp.keyFrameIntervalFrames.val}
              />
              <div className={formGroup}>
                <label className={formLabel}>
                  <Translate id="CAMERA.COMPRESSION.LABELS.KEY_FRAME_PERIOD" />
                </label>
                <div className={formField}>
                  <span id={VIDEO_FIELDS.TEXT_INPUT.KEYFRAME_PERIOD}>
                    <Translate
                      data={{ seconds: comp.keyFramePeriod }}
                      id="CAMERA.COMPRESSION.KEYFRAME_PERIOD_IN_SECONDS"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        <GroupLayout verticalSpacing="large">
          <Button
            key={VIDEO_FIELDS.BUTTONS.REVERT.KEY}
            buttonType="primary"
            disabled={this.acquisitionChanged && !this.compressionChanged}
            id={VIDEO_FIELDS.BUTTONS.REVERT.ID}
            inputType="button"
            onClick={() => {
              this.setState(this.getInitialState);
              updateAcquisitionField(
                settings.acquisition.imageRotation.val,
                'imageRotation',
              );
            }}
            text={<Translate id="BUTTONS.REVERT" />}
          />
          <Button
            key={VIDEO_FIELDS.BUTTONS.SAVE.KEY}
            buttonType="primary"
            disabled={isSaving}
            id={VIDEO_FIELDS.BUTTONS.SAVE.ID}
            inputType="button"
            onClick={this.onSave}
            text={<Translate id="BUTTONS.SAVE" />}
          />
        </GroupLayout>
      </div>
    );
  }
}

CameraSettingsVideo.propTypes = {
  acquisition: PropTypes.objectOf(PropTypes.any).isRequired,
  cameraData: PropTypes.objectOf(PropTypes.any).isRequired,
  onAutofocus: PropTypes.func.isRequired,
  onFocusUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onZoomUpdate: PropTypes.func.isRequired,
  settings: PropTypes.objectOf(PropTypes.any),
  updateAcquisitionField: PropTypes.func.isRequired,
};

CameraSettingsVideo.defaultProps = {
  settings: {},
};

export default CameraSettingsVideo;
