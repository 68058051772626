// Libs
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { getLanguages } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import renderIf from 'render-if';

// Components
import { MessageWrapper, SavitarConfiguration } from 'components';
import { Oasis } from 'containers';

// Atoms
import { flags as flagsAtom, showFilters as showFiltersAtom } from 'atoms';

// Actions
import * as UserActions from 'actions/user';

// Utils
import { hasNeverFetched } from 'actions/common';
import { getShowFilters } from 'util/persistentStorageHelper';

// Constants
import { NAVIGATION_TABS } from 'constants/app';
import {
  PATH_ACCOUNTS,
  PATH_ALARMS,
  PATH_BOOKMARKS,
  PATH_DASHBOARD,
  PATH_DEVICES,
  PATH_HEALTH_MONITOR,
  PATH_PROFILE,
  PATH_REPORTS,
  PATH_VIEWS,
} from 'constants/urlPaths';

// Styles
import {
  appBody,
  appContainer,
  appContent,
  appContentMaximized,
  appFooter,
  appMenu,
  contentBody,
  messageWrapper,
} from 'sharedStyles/global.css';
import * as permissions from 'util/permissions';
import { translatedConnect } from 'util/translatedConnect';
import LoggedInFrameFooter from './LoggedInFrameFooter';
import Masthead from './Masthead';
import NavMenu from './NavMenu';
import { set } from 'react-ga';

function LoggedInFrameContainer(props) {
  const {
    actions,
    bannerNotices,
    canAccessDeviceManager,
    canAccessNotificationCenter,
    canChangeOrganization,
    canViewAlarms,
    canViewBookmarks,
    canViewAccounts,
    canViewDashboard,
    canViewDevices,
    canViewHealthMonitoring,
    canViewProfile,
    canViewReports,
    canViewViews,
    currentOrganization,
    history,
    isFetchingBannerNotices,
    isFetchingCustomerData,
    isFetchingLocations,
    location,
    locations,
    profile,
    children,
    currentPermissionsFor,
    localizationPreference,
    organizations,
    role,
    showFrame,
    translate,
  } = props;

  const [flags, setFlags] = useRecoilState(flagsAtom);
  const [filterStatus, setFilterStatus] = useRecoilState(showFiltersAtom);

  useEffect(() => {
    if (isFetchingCustomerData === null) {
      actions.getOrganizations();
    }
  }, [isFetchingCustomerData]);

  useEffect(() => {
    if (window.localStorage) {
      let flags = {
        xray: false,
      };
      if (localStorage.flags !== undefined) {
        flags = JSON.parse(localStorage.flags);
      }
      setFlags(flags);
      const storedFilterStatus = getShowFilters();
      setFilterStatus(storedFilterStatus);
    }
  }, []);

  useEffect(() => {
    const defaultHomepage = permissions.getUserHomepage(
      profile,
      locations,
      canViewDevices,
      currentOrganization,
    );
    // redirect to default page away from unauthorized page
    if (location.pathname.includes(PATH_ALARMS) && canViewAlarms === false) {
      history.push(defaultHomepage);
    }
    if (
      location.pathname.includes(PATH_DEVICES) &&
      !location.pathname.includes(PATH_HEALTH_MONITOR) &&
      locations &&
      locations.length === 0
    ) {
      history.push(PATH_ACCOUNTS);
    }
    if (
      location.pathname.includes(PATH_DEVICES) &&
      !location.pathname.includes(PATH_HEALTH_MONITOR) &&
      canViewDevices === false
    ) {
      history.push(defaultHomepage);
    }
    if (
      location.pathname.includes(PATH_ACCOUNTS) &&
      canViewAccounts === false
    ) {
      history.push(defaultHomepage);
    }
    if (location.pathname.includes(PATH_VIEWS) && canViewViews === false) {
      history.push(defaultHomepage);
    }
    if (location.pathname.includes(PATH_PROFILE) && canViewProfile === false) {
      history.push(defaultHomepage);
    }
    if (
      location.pathname.includes(PATH_DASHBOARD) &&
      canViewDashboard === false
    ) {
      history.push(defaultHomepage);
    }

    if (
      location.pathname.includes(PATH_HEALTH_MONITOR) &&
      canViewHealthMonitoring === false
    ) {
      history.push(defaultHomepage);
    }

    if (
      props.location.pathname.includes(PATH_REPORTS) &&
      props.canViewReports === false
    ) {
      history.push(defaultHomepage);
    }

    if (
      location.pathname.includes(PATH_BOOKMARKS) &&
      canViewBookmarks === false
    ) {
      history.push(defaultHomepage);
    }

    if (
      currentOrganization &&
      profile &&
      currentPermissionsFor !== profile.Id + currentOrganization.Id
    ) {
      actions.setPermissions();
    }

    if (
      bannerNotices.length === 0 &&
      hasNeverFetched(isFetchingBannerNotices)
    ) {
      actions.getBannerNotices();
    }
  }, [
    profile,
    history,
    location,
    locations,
    isFetchingLocations,
    isFetchingBannerNotices,
  ]);

  useEffect(() => {
    // if user resets their org context, need to re-fetch org list
    actions.getOrganizations();
  }, [currentOrganization]);

  function changeOrganization(userId) {
    const { actions, connectionId } = props;
    actions.setOrganizationContext(userId, connectionId);
  }

  function logout() {
    const { actions } = props;
    actions.logout();
  }

  return (
    <div className={appContainer}>
      {/* hide masthead if view is maximized */}
      <SavitarConfiguration localizationPreference={localizationPreference}>
        <div className={messageWrapper}>
          <MessageWrapper data={bannerNotices} />
        </div>
        <Masthead
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          organizations={organizations}
          changeOrganization={changeOrganization}
          canChangeOrganization={canChangeOrganization}
          logout={logout}
        />
        <div className={appBody}>
          <div
            className={appMenu}
            style={{ display: showFrame ? 'flex' : 'none' }}
          >
            <NavMenu
              canAccessDeviceManager={canAccessDeviceManager}
              canAccessNotificationCenter={canAccessNotificationCenter}
              canViewAccounts={canViewAccounts}
              canViewAlarms={canViewAlarms}
              canViewBookmarks={canViewBookmarks}
              canViewDashboard={canViewDashboard}
              canViewDevices={canViewDevices}
              canViewHealthMonitoring={canViewHealthMonitoring}
              canViewReports={canViewReports}
              canViewViews={canViewViews}
              path={location.pathname}
              role={role}
              translate={translate}
            />
          </div>
          <div className={showFrame ? appContent : appContentMaximized}>
            <div className={contentBody}>{children}</div>
            <Oasis />
            {renderIf(showFrame)(
              <div className={appFooter}>
                <LoggedInFrameFooter />
              </div>,
            )}
          </div>
        </div>
      </SavitarConfiguration>
    </div>
  );
}

LoggedInFrameContainer.defaultProps = {
  canViewAccounts: null,
  canViewAlarms: null,
  canViewBookmarks: null,
  canViewDashboard: null,
  canViewDevices: null,
  canViewHealthMonitoring: null,
  canViewProfile: null,
  canViewReports: null,
  canViewViews: null,
  children: null,
  connectionId: null,
  currentOrganization: null,
  currentPermissionsFor: null,
  isAuthenticated: null,
  isFetchingBannerNotices: null,
  isFetchingLocations: null,
  isFetchingUserProfile: null,
  localizationPreference: null,
  role: null,
  userName: '',
};

LoggedInFrameContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bannerNotices: PropTypes.arrayOf(PropTypes.object).isRequired,
  canChangeOrganization: PropTypes.bool.isRequired,
  canViewAccounts: PropTypes.bool,
  canViewAlarms: PropTypes.bool,
  canViewBookmarks: PropTypes.bool,
  canViewDashboard: PropTypes.bool,
  canViewDevices: PropTypes.bool,
  canViewHealthMonitoring: PropTypes.bool,
  canViewProfile: PropTypes.bool,
  canViewReports: PropTypes.bool,
  canViewViews: PropTypes.bool,
  children: PropTypes.node,
  connectionId: PropTypes.string,
  currentOrganization: PropTypes.string,
  currentPermissionsFor: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.bool,
  isFetchingBannerNotices: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  isFetchingUserProfile: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  localizationPreference: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  profile: PropTypes.shape({}).isRequired,
  role: PropTypes.string,
  showFrame: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

LoggedInFrameContainer.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

function mapStateToProps(state) {
  let role;
  let currentOrganization;
  if (state.user.profile) {
    role = state.user.profile.Role;
    currentOrganization = state.user.profile.TenantName;
  }
  const canView = permissions.canViewTabs(
    state.user.profile,
    state.user.currentOrganization,
  );
  const canViewDashboard = canView.indexOf(NAVIGATION_TABS.DASHBOARD) >= 0;
  const canViewAlarms = canView.indexOf(NAVIGATION_TABS.ALARMS) >= 0;
  const canViewDevices = canView.indexOf(NAVIGATION_TABS.DEVICES) >= 0;
  const canViewAccounts = canView.indexOf(NAVIGATION_TABS.ACCOUNTS) >= 0;
  const canViewViews = canView.indexOf(NAVIGATION_TABS.VIEWS) >= 0;
  const canViewHealthMonitoring =
    canView.indexOf(NAVIGATION_TABS.HEALTH_MONITOR) >= 0;
  const canViewReports = canView.indexOf(NAVIGATION_TABS.REPORTS) >= 0;
  const canViewBookmarks = canView.indexOf(NAVIGATION_TABS.BOOKMARKS) >= 0;
  return {
    bannerNotices: state.user.bannerNotices,
    canChangeOrganization: !state.devices.isFetchingSnapshots,
    canViewAccounts,
    canViewAlarms,
    canViewBookmarks,
    canViewDashboard,
    canViewDevices,
    canViewHealthMonitoring,
    canViewReports,
    canViewViews,
    connectionId: state.notifications.connectionId,
    currentOrganization,
    currentPermissionsFor: state.user.currentPermissionsFor,
    isAuthenticated: state.user.isAuthenticated,
    isFetchingBannerNotices: state.isFetching.getBannerNotices,
    isFetchingCustomerData: state.user.isFetchingCustomerData,
    isFetchingUserProfile: state.isFetching.getUserProfile,
    languages: getLanguages(state.localize),
    localizationPreference: state.user.profile.LocalizationPreference,
    locations: state.locations.locations,
    organizations: state.user.organizations,
    profile: state.user.profile,
    role,
    showFrame: !state.views.isCurrentViewMaximized,
    userName: state.user.userName,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(UserActions, dispatch),
  };
}

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoggedInFrameContainer));
