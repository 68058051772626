import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const iconToBackgroundDimensionRatio = 1.6;

const IconTogglePtzMode = props => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: `${parseInt(
            parseInt(props.width) * iconToBackgroundDimensionRatio,
          )}px`,
          height: `${parseInt(
            parseInt(props.height) * iconToBackgroundDimensionRatio,
          )}px`,
          marginLeft: '-6px',
          marginTop: '-6px',
          backgroundColor: props.isPtzModeEnabled
            ? 'rgba(0,93,171,1.0)'
            : 'transparent',
          zIndex: 0,
        }}
      />
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        style={{ position: 'relative', verticalAlign: 'middle', zIndex: 1 }}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="PTZ-icons"
            transform="translate(-38.000000, -22.000000)"
            fill="#FFFFFF"
          >
            <g id="ic_PTZ_icon" transform="translate(36.000000, 20.000000)">
              <g id="Drag-to-pan" transform="translate(2.000000, 2.000000)">
                <polygon id="Path" points="6 5 10 0 14 5" />
                <polygon id="Path" points="14 15 10 20 6 15" />
                <polygon id="Path" points="5 14 0 10 5 6" />
                <polygon id="Path" points="15 6 20 10 15 14" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconTogglePtzMode.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
  isPtzModeEnabled: false,
};

export default IconTogglePtzMode;
