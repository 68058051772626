import PropTypes from 'prop-types';
import React from 'react';
import * as style from './dropdown.css';

const Dropdown = ({ children, containerClasses, id, toggleDropdown }) => {
  return (
    <>
      <div className={style.dropdownShade} onClick={toggleDropdown} />
      <div
        key={id}
        className={`${style.dropdownWrapper} ${containerClasses}`}
        id={id}
      >
        <div className={style.dropdownContainer}>
          <div className={style.dropdownContent}>{children}</div>
        </div>
      </div>
    </>
  );
};

Dropdown.defaultProps = {
  containerClasses: '',
  id: 'dropdown',
};

Dropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  containerClasses: PropTypes.string,
  id: PropTypes.string,
};

export default Dropdown;
