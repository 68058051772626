import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import itemUpsert from 'util/itemUpsert';

export default function bookmarksReducer(state, action = {}) {
  switch (action.type) {
    case types.RECEIVE_BOOKMARKS: {
      const bookmarks = Object.assign({}, state.bookmarks, {
        [action.clusterId]: action.accBookmarks,
      });
      const bookmarksQueryOptions = Object.assign(
        {},
        state.bookmarksQueryOptions,
        {
          [action.clusterId]: action.queryOptions,
        },
      );
      return Object.assign({}, state, {
        bookmarks,
        bookmarksQueryOptions,
      });
    }

    case types.UPDATE_BOOKMARK:
    case types.CREATE_BOOKMARK: {
      if (state.bookmarks[action.clusterId]) {
        const bookmarkCluster = [...state.bookmarks[action.clusterId]] || [];
        const existingBookmark = bookmarkCluster.find(
          bookmark => bookmark.id === action.bookmarkData.id,
        );
        const bookmarkWithUpdates = Object.assign(
          {},
          existingBookmark,
          action.bookmarkData,
        );
        const bookmarkClusterUpdated = itemUpsert(
          bookmarkCluster,
          bookmarkWithUpdates,
          'id',
        );
        const bookmarks = Object.assign({}, state.bookmarks, {
          [action.clusterId]: bookmarkClusterUpdated,
        });
        return Object.assign({}, state, { bookmarks });
      }
      return state;
    }

    case types.DELETE_BOOKMARK: {
      if (state.bookmarks[action.clusterId]) {
        const bookmarkCluster = [...state.bookmarks[action.clusterId]] || [];
        const bookmarkIndex = bookmarkCluster.findIndex(
          b => b.id === action.bookmarkId,
        );
        if (bookmarkIndex >= 0) {
          bookmarkCluster.splice(bookmarkIndex, 1);
        }
        const bookmarks = Object.assign({}, state.bookmarks, {
          [action.clusterId]: bookmarkCluster,
        });
        return Object.assign({}, state, { bookmarks });
      }
      return state;
    }

    case types.REMOVE_CLUSTER: {
      if (state.bookmarks[action.clusterId]) {
        const newBookmarks = {};
        Object.keys(state.bookmarks).forEach(clusterId => {
          if (clusterId !== action.clusterId) {
            newBookmarks[clusterId] = [...state.bookmarks[clusterId]];
          }
        });
        return Object.assign({}, state, { bookmarks: newBookmarks });
      }
      return state;
    }

    default:
      return state || initialState().bookmarks;
  }
}
