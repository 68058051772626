// Libs
import PropTypes from 'prop-types';
import React from 'react';

// Utils
import { Translate } from 'react-localize-redux';

// Components
import { MainContentWrapper } from 'lib';

// Styles
import {
  digitalOutInputRow,
  digitalOutWarning,
  digitalOutWarningContent,
  digitalOutWarningTitle,
  panelWrapper,
  tabPanel,
  tabSubheader,
} from './styles.css';

function ServerDetailDigitalOut(props) {
  const { children, digitalOutputs, isFetching } = props;

  const renderError = () => {
    if (isFetching || digitalOutputs.length > 0) {
      return;
    }
    return (
      <div className={digitalOutWarning}>
        <div className={digitalOutWarningContent}>
          <div className={digitalOutWarningTitle}>
            <Translate id="SERVER.DIGITAL_OUT.WARNING" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <form>
      <MainContentWrapper>
        <div className={panelWrapper}>
          <div className={tabPanel}>
            <div className={tabSubheader}>
              <Translate id="SERVER.DIGITAL_OUT.HEADERS.DIGITAL_OUTPUT" />
            </div>
            <div className={digitalOutInputRow}>
              <Translate id="SERVER.DIGITAL_OUT.INSTRUCTIONS" />
            </div>
            {renderError()}
            {children}
          </div>
        </div>
      </MainContentWrapper>
    </form>
  );
}

ServerDetailDigitalOut.propTypes = {
  children: PropTypes.node,
  digitalOutputs: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
};

ServerDetailDigitalOut.defaultProps = {
  children: null,
  digitalOutputs: [],
  isFetching: false,
};

export default ServerDetailDigitalOut;
