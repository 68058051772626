import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

// Containers
import { PageMessage } from 'containers';

// Components
import { Button, GroupLayout } from 'lib';

// Constants
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { NEW_VIEW_ID, NEW_VIEW_NAME } from 'store/viewDefaults';

// Styles
import {
  buttonGroup,
  buttonGroupCenter,
  formField,
  formWrapper,
  horizontalDivider,
  modalFormGroup,
} from 'sharedStyles/modalForms.css';
import {
  checkboxText,
  saveViewRadioWrapper,
  verticalLabelValue,
  viewSaveForm,
} from './styles.css';
import * as viewSaveConstants from './constants';

class ViewSaveForm extends Component {
  constructor(props) {
    super(props);

    const { currentView, translate } = props;
    const { Id: viewId, Name: viewName, isAccView } = currentView;

    this.state = {
      name:
        viewId === NEW_VIEW_ID && viewName === NEW_VIEW_NAME
          ? translate(viewName)
          : viewName,
      nameInvalid: false,
      saveAs: isAccView,
    };
  }

  onSubmit = () => {
    const { name, saveAs } = this.state;
    const { onSave } = this.props;
    if (name.length <= viewSaveConstants.MAX_VIEW_NAME_CHAR) {
      const saveData = {
        name,
        saveACopy: saveAs,
      };
      onSave(saveData);
    } else {
      this.setState({ nameInvalid: true });
    }
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleRadioChange = () => {
    const { saveAs } = this.state;
    this.setState({ saveAs: !saveAs });
  };

  renderRadioOptions = () => {
    const { currentView } = this.props;
    const { saveAs } = this.state;
    if (!currentView.isAccView) {
      return (
        <div>
          <div className={`${modalFormGroup} ${saveViewRadioWrapper}`}>
            <input
              checked={!saveAs}
              id="radioViewSaveAs"
              onChange={this.handleRadioChange}
              type="radio"
              value={false}
            />
            <p className={checkboxText}>
              <Translate
                data={{ ruleName: currentView.Name }}
                id="VIEWS.SAVE_VIEW_MESSAGE"
              />
            </p>
          </div>
          <div className={horizontalDivider} />
          <div className={`${modalFormGroup} ${saveViewRadioWrapper}`}>
            <input
              checked={saveAs}
              id="radioViewSaveNew"
              onChange={this.handleRadioChange}
              type="radio"
              value={false}
            />
            <p className={checkboxText}>
              <Translate id="VIEWS.SAVE_AS_NEW_VIEW_MESSAGE" />
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  renderAccViewSaveMessage = () => {
    const { currentView } = this.props;
    if (currentView.isAccView) {
      return (
        <div>
          <Translate id="VIEWS.SAVE_ACC_VIEW_AS_NEW_VIEW_MESSAGE" />
        </div>
      );
    }
    return null;
  };

  render() {
    const { name, nameInvalid } = this.state;
    const { currentView, onCancel } = this.props;
    return (
      <div className={`${viewSaveForm} ${formWrapper}`}>
        <PageMessage
          messageStyle={messageStyleStrings.error}
          translateBody="VIEWS.MAX_NAME_LENGTH_ERROR"
          visible={nameInvalid}
        />
        {currentView.Id !== NEW_VIEW_ID && this.renderRadioOptions()}
        <div className={modalFormGroup}>
          <div className={formField}>
            <div className={verticalLabelValue}>
              <label className={formField}>
                <Translate id="COMMON.NAME" />
              </label>
              <input
                onChange={this.handleNameChange}
                type="text"
                value={name}
              />
            </div>
          </div>
        </div>
        {currentView.Id !== NEW_VIEW_ID && this.renderAccViewSaveMessage()}
        <div className={buttonGroup}>
          <GroupLayout additionalClasses={buttonGroupCenter}>
            <Button
              key={viewSaveConstants.idViewCancelButton}
              buttonType="primary"
              id={viewSaveConstants.idViewCancelButton}
              inputType="button"
              onClick={onCancel}
              text={<Translate id="BUTTONS.CANCEL" />}
            />
            <Button
              key={viewSaveConstants.idViewSaveButton}
              buttonType="primary"
              disabled={name.trim().length === 0}
              id={viewSaveConstants.idViewSaveButton}
              inputType="button"
              onClick={this.onSubmit}
              text={<Translate id="BUTTONS.SAVE" />}
            />
          </GroupLayout>
        </div>
      </div>
    );
  }
}

ViewSaveForm.propTypes = {
  currentView: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(ViewSaveForm);
