import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { camelCase } from 'lodash';

// Components
import { ArmDisarmCamera, ConnectedStatus } from 'components';
import { CameraSnapshot } from 'containers';
import { Icon } from 'lib';

// Utils
import * as CameraTypes from 'constants/cameraTypes';
import { PropTypes } from 'prop-types';
import { DeviceSource, findServerCategory } from 'util/deviceCategory';
import { getCameraActualStatus } from 'util/getCameraActualStatus';

// Constants
import { PATH_CAMERAS } from 'constants/urlPaths';

import { IC_NOTIFICATION_OFF } from 'constants/iconNames';
import {
  armDropdownPosition,
  camContainer,
  cameraDetails,
  cameraDetailsContainer,
  cameraDetailsLine,
  cameraDetailsLineAdded,
  cameraDetailsLineCol,
  cameraDetailsLineDisabled,
  cameraDisarm,
  cameraImage,
  cameraLabel,
  cameraLabelDisabled,
  cameraNameTruncated,
  cameraTile,
  cardFooter,
  disabledCameraTitle,
  disarmDropdownPosition,
  iconDisarmed,
} from './styles.css';
import { CAMERA_TILE_ID } from './constants';

const styles = {
  cameraLabelStyle: {
    color: '#000',
    textDecoration: 'none',
  },
};

class CameraCard extends Component {
  componentDidMount = () => {
    const { getCameraSnapshot } = this.props;
    getCameraSnapshot();
  };

  onButtonClick = (cameraId, deviceId) => {
    const { onAuthRequired } = this.props;
    onAuthRequired(cameraId, deviceId);
  };

  render() {
    const {
      actions,
      camera,
      deviceId,
      hasValidSubscription,
      server,
    } = this.props;
    const isConnected = camera.ConnectionState === CameraTypes.CAMERA_CONNECTED;
    const isBlue = findServerCategory(server) === DeviceSource.BLUE;
    const cameraStatus = getCameraActualStatus(camera);
    const cameraStatusText = cameraStatus.statusText;
    const cameraDetailLines = [
      camera.Manufacturer,
      camera.Model,
      camera.IpAddress,
    ];
    const snapshot = (
      <CameraSnapshot
        cameraId={camera.Id}
        cameraName={camera.Name}
        deviceId={deviceId}
        height={100}
        width={100}
      />
    );

    return (
      <div
        className={cameraTile}
        id={`${CAMERA_TILE_ID}-${camelCase(camera.Name)}`}
      >
        {isBlue && isConnected && hasValidSubscription ? (
          <div className={camContainer}>
            <Link
              style={styles.cameraLabelStyle}
              to={`${PATH_CAMERAS}/${camera.Id}`}
            >
              <div className={cameraImage}>{snapshot}</div>
              <div className={cameraDetailsContainer}>
                <div className={cameraDetails}>
                  <div className={cameraLabel} title={camera.Name}>
                    <span className={cameraNameTruncated}>{camera.Name}</span>
                    <span className={cameraDisarm}>
                      {camera.Disarmed ? (
                        <Icon
                          iconClass={iconDisarmed}
                          id={IC_NOTIFICATION_OFF}
                          title="CAMERA.SETTINGS.ACTIONS.CAMERA_DISARM_SUCCESS"
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {cameraDetailLines.map(line => (
                    <div key={line} className={cameraDetailsLine}>
                      {line}
                    </div>
                  ))}
                </div>
                <div
                  className={`${cameraDetailsLine} ${cameraDetailsLineAdded}`}
                >
                  <div className={`${cameraDetailsLineCol}`}>
                    <ConnectedStatus
                      connected={isConnected}
                      data={{
                        cameraId: camera.Id,
                        cameraStatus,
                      }}
                      onSubmit={this.onButtonClick}
                      statusText={<Translate id={cameraStatusText} />}
                    />
                  </div>
                </div>
              </div>
            </Link>
            <div className={`${cardFooter}`}>
              <ArmDisarmCamera
                actions={actions}
                camera={camera}
                device={server}
                dropdownPositionClass={
                  camera.Disarmed ? armDropdownPosition : disarmDropdownPosition
                }
              />
            </div>
          </div>
        ) : (
          <div className={camContainer}>
            <div className={disabledCameraTitle}>
              <div className={cameraImage}>{snapshot}</div>
              <div className={cameraDetailsContainer}>
                <div className={cameraDetails}>
                  <div className={cameraLabelDisabled} title={camera.Name}>
                    <span className={cameraNameTruncated}>{camera.Name}</span>
                    <span className={cameraDisarm}>
                      {camera.Disarmed ? (
                        <Icon
                          iconClass={iconDisarmed}
                          id={IC_NOTIFICATION_OFF}
                          title="CAMERA.SETTINGS.ACTIONS.CAMERA_DISARM_SUCCESS"
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  {cameraDetailLines.map(line => (
                    <div key={line} className={cameraDetailsLineDisabled}>
                      {line}
                    </div>
                  ))}
                </div>
                <div
                  className={`${cameraDetailsLine} ${cameraDetailsLineAdded}`}
                >
                  <div className={`${cameraDetailsLineCol}`}>
                    <ConnectedStatus
                      connected={isConnected}
                      data={{
                        cameraId: camera.Id,
                        cameraStatus,
                        deviceId: camera.DeviceId,
                      }}
                      onSubmit={this.onButtonClick}
                      statusText={<Translate id={cameraStatusText} />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CameraCard.defaultProps = {
  camera: {},
  getCameraSnapshot: () => {},
  hasValidSubscription: false,
  onAuthRequired: () => {},
  server: {},
};

CameraCard.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  camera: PropTypes.objectOf(PropTypes.any),
  deviceId: PropTypes.string.isRequired,
  getCameraSnapshot: PropTypes.func,
  hasValidSubscription: PropTypes.bool,
  onAuthRequired: PropTypes.func,
  server: PropTypes.objectOf(PropTypes.any),
};

export default CameraCard;
