import React from 'react';
import Spinner from 'react-md-spinner';
import { PropTypes } from 'prop-types';
import { loadingSpinner } from './styles.css';

const FormLoading = ({ className }) => (
  <div className={className}>
    <Spinner
      size={30}
      color1="#006e99"
      color2="#3d6f99"
      color3="#0f4c7f"
      color4="#006e99"
    />
  </div>
);

FormLoading.defaultProps = {
  className: loadingSpinner,
};

FormLoading.propTypes = {
  className: PropTypes.string,
};

export default FormLoading;
