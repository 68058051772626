import * as urlConstants from 'constants/urlPaths';
import store from 'store/configureStore';
import * as permissions from '../util/permissions';
// Need to get the store here to retrieve the organization Id

const valueToQuery = (field, values, typeOfValue, operator) => {
  if (operator === 'contains') {
    return `${operator}(tolower(${field}),tolower('${values}'))`;
  }
  if (!typeOfValue || typeOfValue === 'string') {
    values = values.replace(/(')/gi, "'$1");
    return `${field} ${operator} '${values}'`;
  }
  if (
    typeOfValue === 'number' ||
    typeOfValue === 'guid' ||
    typeOfValue === 'boolean'
  ) {
    return `${field} ${operator} ${values}`;
  }
  return `${field} ${operator} ${typeOfValue}'${values}'`;
};

// filterTree: { filters: arrayOf(filterTree), filtersOperator: string } || regular filter
// qoFiltersOperator: filterOperator included in queryOptions.
// parses a filterTree into the corresponding sequence of query params linked by the relevant filter operator
// the filtersOperator is applied per level of each branch
// useful for more complex statements like (x and y) or z
function parseFilterTree(filterTree, qoFiltersOperator) {
  if (!filterTree) return '';
  const filtersOperator =
    filterTree.filtersOperator || qoFiltersOperator || 'and';
  if (filterTree.filters) {
    const branchString = filterTree.filters
      .map(filterBranch => parseFilterTree(filterBranch, qoFiltersOperator))
      .filter(parsedBranch => parsedBranch)
      .join(` ${filtersOperator} `);
    return branchString ? `(${branchString})` : '';
  }
  // handle an individual filter
  const operator = filterTree.operator || 'eq';
  const typeOfValue = filterTree.typeOfValue || 'string';
  const valuesArr = filterTree.values;
  let orString = '(';
  orString += valuesArr
    .map(value => valueToQuery(filterTree.field, value, typeOfValue, operator))
    .join(' or ')
    .concat(')');
  return orString;
}

function getQueryParams(queryOptions) {
  let queryParams = '';
  if (queryOptions) {
    queryParams += '?';
    let { skip, top } = queryOptions;
    const {
      filter,
      filters,
      filtersOperator,
      statFilters,
      sort,
      count,
      params,
      select,
      other,
    } = queryOptions;

    if (filter) {
      const operator = filter.operator || 'eq';
      queryParams += `$filter=${filter.field} ${operator} ${filter.value}`;
    }

    if (filters) {
      const filtersParams = parseFilterTree(
        Array.isArray(filters) ? { filters } : filters,
        filtersOperator,
      );
      if (filtersParams) queryParams += `$filter=${filtersParams}`;
    }

    if (statFilters && statFilters.length > 0) {
      statFilters.forEach(statFilter => {
        const { field, values } = statFilter;
        values.forEach(value => {
          queryParams += `filter.${field}=${value}&`;
        });
      });
    }

    if (select) {
      queryParams += `&$select=${select.value}`;
    }

    if (sort) {
      queryParams += `&$orderby=${sort.col} ${sort.direction}`;
    }

    if (skip >= 0) {
      skip = Math.round(skip);
      queryParams += `&$skip=${skip}`;
    }

    if (top >= 0) {
      top = Math.round(top);
      queryParams += `&$top=${top}`;
    }

    if (count) {
      queryParams += '&$count=true';
    }

    if (params) {
      queryParams += 'parameters=';
      const paramString = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
      queryParams += encodeURIComponent(paramString);
    }

    if (other) {
      other.forEach(param => {
        queryParams += `&${param.key}=${encodeURIComponent(param.value)}`;
      });
    }
  }
  return queryParams;
}

export default function formatUrl({
  id,
  ids,
  orgId,
  path,
  queryOptions,
  template,
  version,
}) {
  let protocol = urlConstants.PROTOCOL;

  let baseUrl = urlConstants.HOST;
  if (process.env.API_ENV === 'local') {
    baseUrl = urlConstants.HOST_LOCAL;
    protocol = 'http://';
  } else if (process.env.API_ENV === 'dev') {
    baseUrl =
      (window.localStorage && window.localStorage.devHost) ||
      urlConstants.HOST_DEV;
  } else if (process.env.API_ENV === 'mock') {
    baseUrl = urlConstants.HOST_MOCK;
    protocol = 'http://';
  } else if (process.env.API_ENV === 'test') {
    baseUrl = urlConstants.HOST_TEST;
    protocol = 'http://';
  }

  // Base path to /api or /ODATA
  let basePath = urlConstants.PATHS_ODATA.includes(path || template)
    ? urlConstants.URL_BASE_ODATA
    : urlConstants.URL_BASE;

  // Add the version
  let apiVersion = version;

  if (apiVersion === undefined) {
    if (urlConstants.PATHS_V4.includes(path || template)) {
      apiVersion = urlConstants.URL_VERSION_4;
    } else if (urlConstants.PATHS_V3.includes(path || template)) {
      apiVersion = urlConstants.URL_VERSION_3;
    } else if (urlConstants.PATHS_V2.includes(path || template)) {
      apiVersion = urlConstants.URL_VERSION_2;
    } else {
      apiVersion = urlConstants.URL_VERSION_1;
    }
  }

  basePath = basePath.concat(apiVersion);

  // To access the mock database, or for testing, override the base segment with an empty string
  if (process.env.API_ENV === 'mock') {
    basePath = urlConstants.MOCK_URL_BASE;
  }

  let strUrl = protocol + baseUrl + basePath;

  if (!orgId && urlConstants.PATHS_ODATA.includes(path || template)) {
    // Hack for wierd oData special case, which adds the orgId at the END of the uri
    orgId = permissions.getOrgIdFromStore(store.getState());
    if (template) {
      template = template.replace(':orgId', orgId);
    }
  } else if (
    !orgId &&
    !urlConstants.PATHS_ORG_EXEMPT.includes(path || template)
  ) {
    orgId = permissions.getOrgIdFromStore(store.getState());
    strUrl += `${urlConstants.URL_INCLUDE_ORG_ID + orgId}/`;
  } else if (orgId) {
    strUrl += `${urlConstants.URL_INCLUDE_ORG_ID + orgId}/`;
  }

  if (path) {
    strUrl += path + (id || '');
  } else if (template) {
    let strTemplate = template;
    if (id) {
      strTemplate = template.replace(/:id/g, id);
    }
    if (ids) {
      const keys = Object.keys(ids);
      keys.forEach(key => {
        strTemplate = strTemplate.replace(`:${key}`, ids[key]);
      });
    }
    strUrl += strTemplate;
  }
  strUrl += getQueryParams(queryOptions);
  return strUrl;
}
