import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import {
  formField,
  formLabel,
} from 'components/VerticalForm/styles.css';
import {
    inputError
} from './styles.css';
const ResponsiveFieldInput = props => {
  const {
    className,
    input: inputProps,
    labelTranslationId,
    meta: { error, touched },
    type,
  } = props;
  return (
    <div className={className}>
      <label className={formLabel}>
        <Translate id={labelTranslationId} />
      </label>
      <input {...inputProps} className={formField} type={type} />
      <span className={inputError}>
        {touched && error && <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />}
      </span>
    </div>
  );
};

ResponsiveFieldInput.defaultProps = {
  className: '',
  input: null,
  labelTranslationId: '',
  meta: null,
  type: '',
};

ResponsiveFieldInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  labelTranslationId: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

export default ResponsiveFieldInput;