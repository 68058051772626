// Libs
import moment from 'moment';
import momentTimezone from 'moment-timezone-all';

// Constants
import { getActiveLanguageCode, getStartOfWeek } from 'util/languagesUtils';
import {
  PREFERRED_LONG_DATE_FORMAT,
  PREFERRED_SHORT_DATE_FORMAT,
  PREFERRED_TIME_FORMAT,
  PREFERRED_TIME_FORMAT_MILLISEC,
  SAVITAR_PREFERRED_TIME_FORMAT,
} from 'constants/dateTimeNumberFormats';
import getUserLocale from './getUserLocale';
import {
  DATE_FORMAT_MMM_D_Y,
  DATE_FORMAT_MMM_D_Y_ES,
  DATE_FORMAT_MMM_D_Y_FR,
  DATE_FORMAT_MMM_DD_YYYY,
  DATE_FORMAT_MMM_DD_YYYY_ES,
  DATE_FORMAT_MMM_DD_YYYY_FR,
  DATETIME_FORMAT_WITH_UTC,
  DATETIME_FORMAT_WITH_ZONE,
  SHORT_DATE_FORMAT,
  SHORT_DATE_FORMAT_ES,
  SHORT_DATE_FORMAT_FR,
} from '../constants/app';

export const TIME_TYPES = {
  LOCAL: 'LOCAL',
  UTC: 'UTC',
};
// Sometimes the moment is not yet setted to the activelanguage code, so we have to force it here also.
moment.locale(getActiveLanguageCode());

export const convertTimeTo = (
  datetime,
  timeType,
  timeZone = null,
  format = null,
) => {
  if (timeType === TIME_TYPES.LOCAL) {
    return momentTimezone
      .tz(datetime, timeZone)
      .format(format || DATETIME_FORMAT_WITH_ZONE);
  }
  if (timeType === TIME_TYPES.UTC) {
    return `${moment.utc(datetime).format(format || DATETIME_FORMAT_WITH_UTC)}`;
  }
  return null;
};

export const replaceZone = (startTime, zone) => {
  // Rather than converting, this takes an existing moment e.g. 12:45 EST
  // and returns the same hours and minutes with the suffix of the provided zone e.g. 12:45 UTC
  // Needed for some ugly datepicker conversions.
  return momentTimezone.tz(
    startTime.format(DATETIME_FORMAT_WITH_ZONE),
    DATETIME_FORMAT_WITH_ZONE,
    zone,
  );
};

export const getSubscriberDatesFormat = () => {
  switch (getUserLocale()) {
    case 'fr':
      return SHORT_DATE_FORMAT_FR;
    case 'es':
      return SHORT_DATE_FORMAT_ES;
    case 'en':
    default:
      return SHORT_DATE_FORMAT;
  }
};

export const getFormatForActiveLocale = () => {
  return `${moment.localeData(getUserLocale()).longDateFormat('lll')} z`;
};

export const getShortFormatForActiveLocale = () => {
  switch (getActiveLanguageCode()) {
    case 'fr':
      return DATE_FORMAT_MMM_DD_YYYY_FR;
    case 'es':
      return DATE_FORMAT_MMM_DD_YYYY_ES;
    case 'en':
    default:
      return DATE_FORMAT_MMM_DD_YYYY;
  }
};

export const getDateFilterFormatForActiveLocale = () => {
  switch (getActiveLanguageCode()) {
    case 'fr':
      return DATE_FORMAT_MMM_D_Y_FR;
    case 'es':
      return DATE_FORMAT_MMM_D_Y_ES;
    case 'en':
    default:
      return DATE_FORMAT_MMM_D_Y;
  }
};

export const getShortD3FormatForActiveLocale = () => {
  switch (getActiveLanguageCode()) {
    case 'fr':
    case 'es':
      return '%d %b, %Y';
    case 'en':
    default:
      return '%B %d, %Y';
  }
};

export const convertCameraTimeTo = (
  datetime,
  timeType,
  device,
  cameraLocation,
  dateTimeFormat = null,
) => {
  const dtFormat = dateTimeFormat || DATETIME_FORMAT_WITH_ZONE;
  if (timeType === TIME_TYPES.LOCAL) {
    if (device && device.TimeZoneOffset && device.TimeZoneOffset !== 0) {
      if (device.TimeZone) {
        return momentTimezone.tz(datetime, device.TimeZone).format(dtFormat);
      }
    } else if (
      !device ||
      !device.TimeZoneOffset ||
      device.TimeZoneOffset === 0
    ) {
      if (cameraLocation && cameraLocation.TimeZone) {
        return momentTimezone
          .tz(datetime, cameraLocation.TimeZone)
          .format(dtFormat);
      }
    }
    // If everything fails we show UTC
    return convertCameraTimeTo(
      datetime,
      TIME_TYPES.UTC,
      device,
      cameraLocation,
    );
  }
  if (timeType === TIME_TYPES.UTC) {
    return `${moment.utc(datetime).format(DATETIME_FORMAT_WITH_UTC)}`;
  }
  return null;
};

export const getCameraTimezoneOffset = (cameraDevice, cameraLocation) => {
  let resultOffset = 0;
  let inputZone = '';
  if (
    cameraDevice &&
    cameraDevice.TimeZoneOffset &&
    cameraDevice.TimeZoneOffset !== 0
  ) {
    if (cameraDevice.TimeZone) {
      inputZone = cameraDevice.TimeZone;
    }
  } else if (
    !cameraDevice ||
    !cameraDevice.TimeZoneOffset ||
    cameraDevice.TimeZoneOffset === 0
  ) {
    if (cameraLocation && cameraLocation.TimeZone) {
      inputZone = cameraLocation.TimeZone;
    }
  }
  if (inputZone.length > 0) {
    resultOffset = momentTimezone.tz(inputZone).utcOffset() / 60;
  }
  return resultOffset;
};

export const getPreferredLongDateFormat = dateFormat => {
  return (
    PREFERRED_LONG_DATE_FORMAT[dateFormat] ||
    moment.localeData(getUserLocale()).longDateFormat('ll')
  );
};

export const convertToLongDateFormat = (data, userFormatDate) => {
  return moment(data).format(getPreferredLongDateFormat(userFormatDate));
};

export const getShortDateFormat = preferredFormat => {
  return (
    PREFERRED_SHORT_DATE_FORMAT[preferredFormat] ||
    moment.localeData(getUserLocale()).longDateFormat('L')
  );
};

export const convertToShortFormat = (data, userFormat) => {
  return moment(data).format(getShortDateFormat(userFormat));
};

export const getTimeFormatMilliSec = preferredFormat => {
  const lts = moment.localeData(getUserLocale()).longDateFormat('LTS');
  let format = 'LTS.SSS';
  if (lts.match(/a/i)) {
    format = 'h:mm:ss.SSS';
  }

  return PREFERRED_TIME_FORMAT_MILLISEC[preferredFormat] || format;
};

export const getTimeFormat = preferredFormat => {
  return (
    PREFERRED_TIME_FORMAT[preferredFormat] ||
    moment.localeData(getUserLocale()).longDateFormat('LTS')
  );
};

export const getPreferredLongDateTimeFormat = (dateFormat, timeFormat) => {
  return `${getPreferredLongDateFormat(dateFormat)} ${getTimeFormat(
    timeFormat,
  )} z`;
};

export const convertToLongDateTimeFormat = (
  data,
  userFormatDate,
  userFormatTime,
) => {
  return moment(data).format(
    getPreferredLongDateTimeFormat(userFormatDate, userFormatTime),
  );
};

const convertToSavitarTimeformat = format =>
  SAVITAR_PREFERRED_TIME_FORMAT[format];

export const getSavitarLocalizationPreferenceProps = localizationPreference => {
  return {
    dateFormat: getShortDateFormat(
      localizationPreference.PreferredShortDateFormat,
    ),
    language: getActiveLanguageCode(),
    timeFormat: convertToSavitarTimeformat(
      localizationPreference.PreferredTimeFormat,
    ),
    weekStartDay: getStartOfWeek(localizationPreference),
  };
};
