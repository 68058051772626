import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import renderIf from 'render-if';

// Icons
import { IconAvatar } from 'icons';

// Styles
import {
  activeItem,
  arrowRight,
  boldTextWrap,
  italicTextWrap,
  loginStatusImg,
  menuItem,
  menuPrimaryText,
  menuSecondaryText,
  menuTextWrapper,
} from './DropdownItem.style.css';

const DropdownItem = ({
  id,
  label,
  onClick,
  primaryLabel,
  showAvatar,
  showRightArrow,
  subLabel,
}) => {
  const [active, setActive] = useState(false);
  function handleClick(ev) {
    if (onClick) {
      onClick(ev);
    }
  }

  const mouseEnter = () => {
    setActive(true);
  };

  const mouseLeave = () => {
    setActive(false);
  };

  return (
    <div
      className={active ? `${menuItem} ${activeItem}` : menuItem}
      id={id}
      onClick={handleClick}
      onKeyPress={handleClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      role="button"
      tabIndex="0"
    >
      {renderIf(showAvatar)(
        <span className={loginStatusImg}>
          <IconAvatar
            height="45px"
            title={<Translate id="MASTER_HEADER.USER_ICON_TITLE" />}
            width="45px"
          />
        </span>,
      )}
      <div className={menuTextWrapper}>
        {renderIf(primaryLabel)(
          <div className={`${boldTextWrap}`}>{primaryLabel}</div>,
        )}
        {!primaryLabel ? (
          <>
            {renderIf(subLabel)(
              <div
                className={`${menuSecondaryText} ${primaryLabel &&
                  `${italicTextWrap}`}`}
              >
                {subLabel}
              </div>,
            )}
            <div
              className={`${menuPrimaryText} ${subLabel && `${boldTextWrap}`}`}
            >
              {label}
              {showRightArrow && <span className={arrowRight} />}
            </div>
          </>
        ) : (
          <>
            <div className={menuPrimaryText}>
              {label}
              {showRightArrow && <span className={arrowRight} />}
            </div>
            {renderIf(subLabel)(
              <div
                className={`${menuSecondaryText} ${primaryLabel &&
                  `${italicTextWrap}`}`}
              >
                {subLabel}
              </div>,
            )}
          </>
        )}
      </div>
    </div>
  );
};

DropdownItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  primaryLabel: PropTypes.string,
  showAvatar: PropTypes.bool,
  showRightArrow: PropTypes.bool,
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

DropdownItem.defaultProps = {
  id: '',
  onClick: () => {},
  primaryLabel: '',
  showAvatar: false,
  showRightArrow: false,
  subLabel: '',
};

export default DropdownItem;
