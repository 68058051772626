// API key => date format value

export const PREFERRED_LONG_DATE_FORMAT = {
  DayMonthYear: 'D MMM YYYY',
  MonthDayYear: 'MMM D[,] YYYY',
  YearMonthDay: 'YYYY MMM D',
};

export const PREFERRED_TIME_FORMAT = {
  TimeFormat12Hours: 'hh:mm:ss A',
  TimeFormat24Hours: 'HH:mm:ss',
};

export const PREFERRED_SHORT_DATE_FORMAT = {
  DayMonthYearDotSeparator: 'DD.MM.YYYY',
  DayMonthYearSlashSeparator: 'DD/MM/YYYY',
  MonthDayYear: 'MM/DD/YYYYY',
  YearMonthDay: 'YYYY-MM-DD',
};

export const PREFERRED_TIME_FORMAT_MILLISEC = {
  TimeFormat12Hours: 'hh:mm:ss.SSS',
  TimeFormat24Hours: 'HH:mm:ss.SSS',
};

export const SAVITAR_PREFERRED_TIME_FORMAT = {
  TimeFormat12Hours: '12',
  TimeFormat24Hours: '24',
};

// API keys
export const twentyFourHourFormat = 'TimeFormat24Hours';

// d3 chart formats
export const PREFERRED_LONG_DATE_D3_FORMAT = {
  DayMonthYear: '%d %b %Y',
  MonthDayYear: '%B %d, %Y',
  YearMonthDay: '%Y %b %d',
};

export const PREFERRED_START_OF_WEEK = {
  Monday: 1,
  Saturday: 6,
  Sunday: 0,
};

// day order by start of week 0(Sunday), 1(Monday), 6(Saturday)
export const DAY_ORDER = {
  0: [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ],
  1: [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ],
  6: [
    'SATURDAY',
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
  ],
};
