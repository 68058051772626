export const colorActive = '#FFF';
export const colorInactive = '#000';

// DashBoard
export const idDashboardInactiveIcon = 'dashboardInactiveIcon';
export const idDashboardActiveIcon = 'dashboardActiveIcon';
export const idDashboardLink = 'dashboardLink';

// Accounts
export const idAccountMgtInactiveIcon = 'accountMgtInactiveIcon';
export const idAccountMgtActiveIcon = 'accountMgtActiveIcon';
export const idAccountMgtLink = 'accountMgtLink';

// Notification
export const idNotificationsInactiveIcon = 'notificationsInactiveIcon';
export const idNotificationsActiveIcon = 'notificationsActiveIcon';
export const idNotificationsLink = 'notificationsLink';
export const notificationKey = 'notifications';

// Devices
export const idDevicesInactiveIcon = 'devicesInactiveIcon';
export const idDevicesActiveIcon = 'devicesActiveIcon';
export const idDevicesLink = 'devicesLink';

// Views
export const idViewsInactiveIcon = 'viewsInactiveIcon';
export const idViewsActiveIcon = 'viewsActiveIcon';
export const idViewsLink = 'viewsLink';

// Health Monitoring
export const idHealthMonitoringInactiveIcon = 'healthMonitoringInactiveIcon';
export const idHealthMonitoringActiveIcon = 'healthMonitoringActiveIcon';
export const idHealthMonitoringLink = 'healthMonitoringLink';

// Bookmarks
export const idBookmarksInactiveIcon = 'bookmarksInactiveIcon';
export const idBookmarksActiveIcon = 'bookmarksActiveIcon';
export const idBookmarksLink = 'bookmarksLink';

// Reports
export const idReportsLink = 'reportsLink';
