export const GENERAL_CONTENT_ID = 'CameraGeneralContent';
export const PRIVACY_CONTENT_ID = 'PrivacyContent';
export const VIDEO_CONTENT_ID = 'VideoContent';
export const RULES_CONTENT_ID = 'RulesContent';
export const DIGITAL_OUT_CONTENT_ID = 'DigitalOutContent';
export const ADVANCED_SETTINGS_LINK_ID = 'AdvancedSettingsLink';
export const imgRotationDefault = 'none';
const cameraDefaultStruct = {
  def: 0,
  max: 1,
  min: 0,
  val: 0,
  id: 0,
};

export const cameraVideoSettings = {
  compression: {
    requestedFrameRate: cameraDefaultStruct,
    imageQuality: cameraDefaultStruct,
    maxBitrate: cameraDefaultStruct,
    resolution: {
      def: '2592x1944',
      options: [
        '2592x1944',
        '2560x1440',
        '2048x1536',
        '1920x1080',
        '1280x960',
        '1280x720',
        '768x576',
        '768x432',
      ],
      val: '2592x1944',
      id: 0,
    },
    keyFrameIntervalFrames: cameraDefaultStruct,
    keyframePeriod: 0,
  },
  acquisition: { imageRotation: 'none' },
  isSaving: false,
};

export const DIGITAL_OUT_FIELDS = [
  'digitalOutputName',
  'circuitStateNormallyClosed',
  'outputModeIsHold',
  'pulseDurationMs',
  'linkedCameras',
];

export const BUTTON_IDS = {
  SAVE: 'digitalOutSettingsButtonSave',
  REVERT: 'digitalOutSettingsButtonRevert',
};

export const DIGITAL_OUT_FIELD_CONFIG = {
  digitalOutputName: {
    name: 'digitalOutputName',
  },
  circuitStateNormallyClosed: {
    name: 'circuitStateNormallyClosed',
  },
  outputModeIsHold: {
    name: 'outputModeIsHold',
  },
  pulseDurationMs: {
    name: 'pulseDurationMs',
  },
};

export const VIDEO_FIELDS = {
  FIELDS: {
    KEY_FRAME_INTERVAL_FRAMES: 'keyFrameIntervalFrames',
    REQUESTED_FRAME_RATE: 'requestedFrameRate',
    IMAGE_QUALITY: 'imageQuality',
    MAX_BITRATE: 'maxBitrate',
    RESOLUTION: 'resolution',
    ENABLE_ISM: 'enableISM',
  },
  BUTTONS: {
    SAVE: {
      ID: 'camSettingSaveButton',
      KEY: 'camSettingSaveButton',
    },
    REVERT: {
      ID: 'camSettingRevertButton',
      KEY: 'camSettingRevertButton',
    },
  },
  TEXT_INPUT: {
    IMAGE_QUALITY: 'select-image-quality',
    MAX_BIT_RATE: 'input-max-bit-rate',
    RESOLUTION: 'select-resolution',
    KEYFRAME_INTERVAL: 'input-keyframe-interval',
    KEYFRAME_PERIOD: 'keyframe-period',
  },
};

export const idSensitivitySlider = 'sensitivitySlider';
export const idDelaySlider = 'delaySlider';
export const idIconBtnZoomFocusIn = 'IconBtnZoomFocusIn';
export const idIconBtnZoomFocusInMore = 'IconBtnZoomFocusInMore';
export const idIconBtnZoomFocusOut = 'IconBtnZoomFocusOut';
export const idIconBtnZoomFocusOutMore = 'IconBtnZoomFocusOutMore';
