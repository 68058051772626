import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'react-md-spinner';

// BlueSpinner to prevent a name clash.
// TODO: MVAAS-7110 all uses of Spinner should use this component or BusySpinner rather than
// loading react-md-spinner directly (see ticket for details)

const BlueSpinner = ({ color1, color2, color3, color4, size }) => {
  return (
    <Spinner
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      size={size}
    />
  );
};

BlueSpinner.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  color3: PropTypes.string,
  color4: PropTypes.string,
  size: PropTypes.number,
};

BlueSpinner.defaultProps = {
  color1: '#006e99',
  color2: '#3d6f99',
  color3: '#0f4c7f',
  color4: '#006e99',
  size: 60,
};

export default BlueSpinner;
