import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import * as OrganizationActions from 'actions/organizations';

// Components
import IntegrationSiteItem from './MonitoringConfigAccordion/IntegrationSiteItem';

// Constants

class IntegrationSiteItemContainer extends Component {
  toggleSecurityMonitoring = (siteId, newStatus) => {
    const { actions } = this.props;
    actions.setSecurityMonitoring(siteId, newStatus);
  };

  updateSiteConfigurationString = (siteId, newValue) => {
    const { actions } = this.props;
    actions.setSiteConfigurationString(siteId, newValue);
  };

  updateMonitoringCenter = (siteId, newValue) => {
    const { actions } = this.props;
    actions.setMonitoringCenter(siteId, newValue);
  };

  render() {
    const { fieldName, handleChange, integrationConfigs, site } = this.props;
    return (
      <IntegrationSiteItem
        fieldName={fieldName}
        handleChange={handleChange}
        integrationConfigs={integrationConfigs}
        readyToSave={this.readyToSave}
        site={site}
        toggleSecurityMonitoring={this.toggleSecurityMonitoring}
        updateMonitoringCenter={this.updateMonitoringCenter}
        updateSiteConfigurationString={this.updateSiteConfigurationString}
      />
    );
  }
}

IntegrationSiteItemContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  integrationConfigs: PropTypes.arrayOf(PropTypes.any).isRequired,
  site: PropTypes.objectOf(PropTypes.any).isRequired,
};

IntegrationSiteItemContainer.defaultProps = {
  handleChange() {},
};

const mapStateToProps = (state, ownProps) => {
  let readyStatus;
  let { site } = ownProps;
  if (site.SecurityEventDealerMonitoringEnabled) {
    readyStatus =
      site.ConfigurationString && site.ConfigurationString.length > 0
        ? 'Ready'
        : 'Incomplete';
  } else {
    readyStatus = 'Disabled';
  }
  site = { ...site, readyStatus };
  return {
    integrationConfigs: state.organizations.integrationConfigs,
    site,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...OrganizationActions }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationSiteItemContainer);
