
import React from 'react';
import { connect } from 'react-redux';
import { PtzTourForm } from 'components';
import { PropTypes } from 'prop-types';

const PtzTourFormContainer = ({
  deviceId,
  cameraId,
  tours,
  presets,
  currentTour,
  constraints,
  initialValues,
  handleCancel,
  onSubmit,
}) => {
  return (
    <div>
      <PtzTourForm
        deviceId={deviceId}
        cameraId={cameraId}
        tours={tours}
        constraints={constraints}
        presets={presets}
        currentTour={currentTour}
        handleCancel={handleCancel}
        onSubmit={onSubmit}
        initialValues={initialValues}
      />
    </div>
  );
};

PtzTourFormContainer.propTypes = {
  deviceId: PropTypes.string,
  cameraId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  tours: PropTypes.objectOf(PropTypes.any),
  presets: PropTypes.objectOf(PropTypes.any),
  currentTour: PropTypes.objectOf(PropTypes.any),
  constraints: PropTypes.objectOf(PropTypes.any),
  initialValues: PropTypes.objectOf(PropTypes.any),
};

PtzTourFormContainer.defaultProps = {
  deviceId: '',
  cameraId: '',
  tours: null,
  presets: null,
  currentTour: null,
  constraints: null,
  initialValues: null,
};

function mapStateToProps(state, ownProps) {
  const { cameraId, initialValues } = ownProps;
  const allPresets = state.devices.ptzPresets;
  const presets =
    (allPresets &&
      cameraId &&
      allPresets[cameraId] &&
      allPresets[cameraId].presets) ||
    [];
  const tours = state.devices.ptzTours;
  const currentTour =
    tours &&
    cameraId &&
    tours[cameraId] &&
    tours[cameraId].tours &&
    initialValues &&
    initialValues.id !== null &&
    tours[cameraId].tours[initialValues.id];
  const constraints =
    tours && cameraId && tours[cameraId] && tours[cameraId].constraints;
  return {
    tours,
    currentTour,
    presets,
    constraints,
  };
}

export default connect(
  mapStateToProps,
  null,
)(PtzTourFormContainer);
