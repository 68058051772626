// Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Actions
import {
  disableCameraFeature,
  enableCameraFeature,
  enableDisableAnalytics,
  getAnalyticsStatus,
} from 'actions/devices';
import { goToHomePtzPresetGateway } from 'actions/devices/ptz';
import { goToHomePtzPreset } from 'actions/aftClient';

// Components
import { CameraRule, Toggle, ToggleIconButton } from 'components';
import { Button, EmptyPlaceholder } from 'lib';

// Constants
import { ACTIVE_COLOR_DEFAULT, INACTIVE_COLOR_DEFAULT } from 'constants/app';
import { AUTO_TBE } from 'constants/cameraTypes';
import { ANALYTICS_TOGGLE_ID, AUTOTBE_TOGGLE_ID } from 'constants/ElementId';
import * as toggleTypes from 'constants/ToggleTypes';

// Icons
import { IconDelete } from 'icons';

// Styles

// Utilities
import { cameraSupportsAFT } from 'util/aftHelper';
import { isAnalyticCamera } from 'util/isAnalyticCamera';
import * as tenantUtils from 'util/tenantTypes';
import {
  addDeleteButtonImage,
  cameraAnalyticsRuleText,
  cameraAnalyticsTitle,
  cameraRuleToggle,
  cameraRuleToggleWrapper,
  deleteButton,
  disabledCameraRuleToggleWrapper,
  encouragementButton,
  encouragementText,
  rightSettings,
  ruleControl,
  settingsPanel,
  settingsPanelHeader,
} from './styles.css';
import { RULES_CONTENT_ID } from './constants';

class CameraSettingsRules extends Component {
  componentDidMount() {
    const { actions, camera } = this.props;
    if (camera && camera.Capabilities && !this.isAnalyticCamera) {
      // If the capability to handle at least the object present analytic rule does not exist,
      // see if analytics are enabled on the device
      actions.getAnalyticsStatus(camera.DeviceId, camera.RemoteId, camera.Id);
    }
  }

  componentDidUpdate(prevProps) {
    const { camera, settings: nextSettings } = this.props;
    const { refreshRuleData, settings } = prevProps;
    if (
      settings &&
      !settings.remoteAnalyticsEnabled &&
      nextSettings.remoteAnalyticsEnabled
    ) {
      refreshRuleData(camera.DeviceId, camera.RemoteId, camera.Id);
    }
  }

  get isAnalyticCamera() {
    const { camera } = this.props;
    return isAnalyticCamera(camera);
  }

  get cameraAnalyticsEnabled() {
    const { settings } = this.props;
    return (
      (settings && settings.remoteAnalyticsEnabled) || this.isAnalyticCamera
    );
  }

  get isCameraAutoTBEEnabled() {
    const { camera } = this.props;

    return (
      camera &&
      camera.FeatureFlags &&
      camera.FeatureFlags.includes(AUTO_TBE.name)
    );
  }

  onToggleAnalytics = value => {
    const { actions, camera } = this.props;

    actions.enableDisableAnalytics(
      camera.DeviceId,
      camera.RemoteId,
      value,
      camera.Id,
    );
  };

  onToggleAutoTBE = value => {
    const { actions, camera, translate } = this.props;
    const cameraFeature = {
      FeatureFlags: AUTO_TBE.name,
      cameraId: camera.Id,
      feature: translate(AUTO_TBE.label),
    };

    value
      ? actions.enableCameraFeature(
          cameraFeature,
          toggleTypes.TOGGLE_CAMERA_RULES_TBE,
        )
      : actions.disableCameraFeature(
          cameraFeature,
          toggleTypes.TOGGLE_CAMERA_RULES_TBE,
        );
  };

  get cameraAnalyticsToggle() {
    const { camera, isSaving } = this.props;

    return (
      <div className={rightSettings}>
        {!this.isAnalyticCamera && this.cameraAnalyticsEnabled ? (
          <div className={cameraRuleToggleWrapper}>
            <div className={cameraAnalyticsTitle}>
              {<Translate id="TAMPERING.ANALYTICS_LABEL" />}
            </div>
            <div className={cameraAnalyticsRuleText}>
              {<Translate id="RULES.DISABLE_ANALYTICS_TITLE" />}
            </div>
            <div className={cameraRuleToggle}>
              <Toggle
                checked={this.cameraAnalyticsEnabled}
                onChange={this.onToggleAnalytics}
                revertOn={toggleTypes.TOGGLE_CAMERA_ANALYTICS}
              />
            </div>
          </div>
        ) : (
          <div className={disabledCameraRuleToggleWrapper}>
            <div className={cameraAnalyticsTitle}>
              {<Translate id="TAMPERING.ANALYTICS_LABEL" />}
            </div>
            <div className={cameraAnalyticsRuleText}>
              {<Translate id="RULES.ENABLE_ANALYTICS_TITLE" />}
            </div>
            <div className={cameraRuleToggle}>
              <Toggle
                checked={this.isCameraAnalyticsEnabled}
                disabled={isSaving}
                id={ANALYTICS_TOGGLE_ID}
                onChange={this.onToggleAnalytics}
                revertOn={toggleTypes.TOGGLE_CAMERA_ANALYTICS}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  get cameraAutoTBEToggle() {
    const { camera, isSaving } = this.props;

    return (
      <div className={rightSettings}>
        {this.isCameraAutoTBEEnabled ? (
          <div className={cameraRuleToggleWrapper}>
            <div className={cameraAnalyticsTitle}>
              {<Translate id="TAMPERING.AUTO_TBE_LABEL_HEADER" />}
            </div>
            <div className={cameraAnalyticsRuleText}>
              {<Translate id="TAMPERING.DISABLE_AUTO_TBE_DESCRIPTION" />}
            </div>
            <div className={cameraRuleToggle}>
              <Toggle
                checked={this.isCameraAutoTBEEnabled}
                id={AUTOTBE_TOGGLE_ID}
                onChange={this.onToggleAutoTBE}
                revertOn={toggleTypes.TOGGLE_CAMERA_RULES_TBE}
              />
            </div>
          </div>
        ) : (
          <div className={disabledCameraRuleToggleWrapper}>
            <div className={cameraAnalyticsTitle}>
              {<Translate id="TAMPERING.AUTO_TBE_LABEL_HEADER" />}
            </div>
            <div className={cameraAnalyticsRuleText}>
              {<Translate id="TAMPERING.ENABLE_AUTO_TBE_DESCRIPTION" />}
            </div>
            <div className={cameraRuleToggle}>
              <Toggle
                checked={this.isCameraAutoTBEEnabled}
                disabled={isSaving}
                id={AUTOTBE_TOGGLE_ID}
                onChange={this.onToggleAutoTBE}
                revertOn={toggleTypes.TOGGLE_CAMERA_RULES_TBE}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  updateRule = ruleData => {
    const { updateRule } = this.props;
    const ruleId = ruleData.sensorId;
    updateRule(ruleId, { event: ruleData });
  };

  calcIsVisible = id => {
    const { rois } = this.props;

    if (rois) {
      return rois.find(roi => roi.id === id)
        ? rois.find(roi => roi.id === id).isVisible
        : false;
    }
    return false;
  };

  goToHomePreset = () => {
    const { actions, camera, clusterId } = this.props;

    cameraSupportsAFT(camera)
      ? actions.goToHomePtzPreset(clusterId, camera.RemoteId)
      : actions.goToHomePtzPresetGateway(camera.ServerId, camera.RemoteId);
  };

  render() {
    const {
      addRule,
      cameraSupportsPtz,
      deleteRule,
      handleCancel,
      initialCameraRules,
      isCameraOverlayPristine,
      isCloudOwner,
      isFetching,
      onChangeActivityType,
      onChangeDirectionality,
      onChangeProhibitedDirection,
      rules,
      saveRule,
      selectRule,
      toggleRule,
    } = this.props;
    let processedRules = [];
    if (rules) {
      processedRules = Object.values(rules)
        .filter(r => r !== null)
        .map(r => ({
          ...r.event,
          directionAngle: r.roi ? r.roi.directionAngle : null,
          directional: r.loi ? r.loi.directional : null,
          event: r.event,
          isSelected: r.isSelected,
          isVisible: r.isVisible !== false,
        }));
      // The data in r.event is duplicated; the expanded values are easier to access as
      // initial values, but the object in the 'event' field is isolated to use in the
      // actual update function (to ensure no state variables are accidentally included)
    }
    if (!this.cameraAnalyticsEnabled) {
      return (
        <div className={rightSettings}>
          {!this.isAnalyticCamera ? this.cameraAnalyticsToggle : null}
          {isCloudOwner && this.cameraAutoTBEToggle}
        </div>
      );
    }
    return (
      <div className={rightSettings} id={RULES_CONTENT_ID}>
        {cameraSupportsPtz ? (
          <div className={settingsPanel}>
            <div>
              <div className={settingsPanelHeader}>
                <Translate id="RULES.ATTENTION_HEADER" />
              </div>
              <span className={encouragementText}>
                <Translate id="RULES.ENCOURAGE_GO_TO_HOME_PRESET" />
              </span>
              <br /> <br />
              <div className={encouragementButton}>
                <Button
                  buttonType="secondary"
                  inputType="button"
                  onClick={this.goToHomePreset}
                  text={<Translate id="RULES.GO_TO_HOME_PRESET" />}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={settingsPanel}>
          <div className={settingsPanelHeader}>
            <Translate id="CAMERA.SETTINGS.RULES.ACTIVE_RULES_HEADER" />
          </div>
          <EmptyPlaceholder
            isFetching={isFetching}
            items={processedRules}
            string={<Translate id="RULES.EMPTY_RULES" />}
          >
            {processedRules.map(rule => (
              <div key={rule.sensorId} className={ruleControl}>
                <CameraRule
                  {...rule}
                  event={rule.event}
                  handleCancel={handleCancel}
                  initialEvent={initialCameraRules[rule.event.id]}
                  isCameraOverlayPristine={isCameraOverlayPristine}
                  onChangeActivityType={onChangeActivityType}
                  onChangeDirectionality={onChangeDirectionality}
                  onChangeProhibitedDirection={onChangeProhibitedDirection}
                  onSave={saveRule}
                  selectRule={selectRule}
                  toggleRule={toggleRule}
                  updateRule={this.updateRule}
                />
                <div className={addDeleteButtonImage}>
                  <ToggleIconButton
                    iconActive={
                      <IconDelete
                        className={deleteButton}
                        fill={ACTIVE_COLOR_DEFAULT}
                        title="COMMON.REMOVE"
                      />
                    }
                    iconInactive={
                      <IconDelete
                        fill={INACTIVE_COLOR_DEFAULT}
                        title="COMMON.REMOVE"
                      />
                    }
                    isActive
                    onClick={() => deleteRule(rule.sensorId)}
                  />
                </div>
              </div>
            ))}
          </EmptyPlaceholder>
        </div>
        <Button
          buttonType="secondary"
          disabled={isFetching}
          inputType="button"
          onClick={addRule}
          text={<Translate id="CAMERA.SETTINGS.RULES.ADD_RULE_BUTTON" />}
        />
        {!this.isAnalyticCamera && this.cameraAnalyticsToggle}
        {isCloudOwner && this.cameraAutoTBEToggle}
      </div>
    );
  }
}

CameraSettingsRules.propTypes = {
  camera: PropTypes.shape({}).isRequired,
  isCameraOverlayPristine: PropTypes.bool,
  rules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  translate: PropTypes.func.isRequired,
  updateRule: PropTypes.func.isRequired,
};

CameraSettingsRules.defaultProps = {
  isCameraOverlayPristine: true,
};

function mapStateToProps(state, ownProps) {
  let isCameraOverlayPristine = true;
  const currentStreamData = state.streamOverlays[ownProps.camera.Id];
  let initialCameraRules = {};
  if (currentStreamData) {
    initialCameraRules = currentStreamData.rules;
    currentStreamData.ruleEdits
      ? (isCameraOverlayPristine = false)
      : (isCameraOverlayPristine = true);
  }
  const isCloudOwner =
    state.user && state.user.profile
      ? state.user.profile.TenantType ===
        tenantUtils.tenantTypeStrings.CloudOwner
      : false;
  return {
    initialCameraRules,
    isCameraOverlayPristine,
    isCloudOwner,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        disableCameraFeature,
        enableCameraFeature,
        enableDisableAnalytics,
        getAnalyticsStatus,
        goToHomePtzPreset,
        goToHomePtzPresetGateway,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(CameraSettingsRules));
