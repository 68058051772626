import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

// Actions
import { hideModal, showModal } from 'actions/modal';
import { deleteBookmarksForCluster } from 'actions/bookmarks';

// Constants
import * as modalTypes from 'constants/ModalTypes';

// Styles
import { bulletPoints } from 'sharedStyles/styles.css';
import { modalMessage } from './styles.css';

class DeleteBookmarksContainer extends React.Component {
  /* A container meant to wrap any element which should, on click,
    delete one or more bookmarks. Includes confirm modal and delete
    functionality */

  handleRequestDelete = () => {
    const { actions, bookmarkNames, onCancelDelete } = this.props;
    const modalProps = {
      handleCancel: () => {
        actions.hideModal(modalTypes.SHOW_CONFIRM);
        onCancelDelete();
        // Re-show bookmarks modal
      },
      message: (
        <div>
          <div className={modalMessage}>
            <Translate id="BOOKMARKS.CONFIRM_DELETE.GROUP_DELETE_MESSAGE" />
          </div>
          <ul className={bulletPoints}>
            {bookmarkNames.map(name => (
              <li key={`bookmark_${name}`}>{name}</li>
            ))}
          </ul>
        </div>
      ),
      onOkClick: () => this.handleDelete(),
      textCancel: 'BUTTONS.CANCEL',
      textConfirm: 'BUTTONS.OK',
      title: <Translate id="BOOKMARKS.CONFIRM_DELETE.TITLE" />,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  handleDelete = () => {
    const {
      actions,
      bookmarkIds,
      bookmarksPerCluster,
      getBookmarkCluster,
    } = this.props;
    const clusterId = getBookmarkCluster(bookmarkIds[0], bookmarksPerCluster);
    actions.deleteBookmarksForCluster(clusterId, bookmarkIds);
    actions.hideModal(modalTypes.SHOW_CONFIRM);
  };

  render() {
    const { children } = this.props;
    return (
      <span
        onClick={this.handleRequestDelete}
        onKeyPress={this.handleRequestDelete}
        role="button"
        tabIndex="0"
      >
        {children}
      </span>
    );
  }
}

const propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  bookmarkIds: PropTypes.array.isRequired,
  bookmarkNames: PropTypes.array.isRequired,
  bookmarksPerCluster: PropTypes.object.isRequired,
  children: PropTypes.node,
  getBookmarkCluster: PropTypes.func.isRequired,
  onCancelDelete: PropTypes.func,
};

const defaultProps = {
  children: null,
  onCancelDelete: () => {},
};

DeleteBookmarksContainer.propTypes = propTypes;
DeleteBookmarksContainer.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { deleteBookmarksForCluster, hideModal, showModal },
      dispatch,
    ),
  };
};

export default connect(null, mapDispatchToProps)(DeleteBookmarksContainer);
