// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

// Component
import {
  CameraSettingsTamperingHelper,
  PanelSettings,
  Toggle,
} from 'components';
import { Button, GroupLayout } from 'lib';

// Utilities
import normalizeCamera from 'util/normalizeCamera';
import { isAnalyticCamera } from 'util/isAnalyticCamera';

// Actions
import * as SettingsActions from 'actions/settings';
import * as DeviceActions from 'actions/devices';

// Constants
import * as toggleTypes from 'constants/ToggleTypes';
import { ANALYTICS_TOGGLE_ID, TAMPER_TOGGLE_ID } from 'constants/ElementId';

// Style
import { formContent } from 'components/CameraSettings/styles.css';
import {
  TAMPERING_BUTTONS,
  TAMPERING_CONTENT_ID,
  TAMPERING_DEFAULT_VALUE,
  TAMPERING_KEYS,
} from './constants';

import {
  buttonGroupBWrap,
  cameraSettingsBasic,
  disabledTamperingToggleWrapper,
  rightSettings,
  tabContentSection,
  tamperingToggle,
  tamperingToggleWrapper,
  toggleAnalyticsTitle,
} from './styles.css';

// Class
class CameraSettingsTampering extends Component {
  constructor(props) {
    const { initialDelay, initialSensitivity } = props;
    super(props);
    this.state = {
      delay: initialDelay,
      disableRestore: true,
      hasChanged: false,
      sensitivity: initialSensitivity,
    };
  }

  componentDidMount() {
    const { actions, camera } = this.props;
    if (camera && camera.Capabilities) {
      // If the capability to handle at least the object present analytic rule does not exist,
      // see if analytics are enabled on the device
      actions.getAnalyticsStatus(camera.DeviceId, camera.RemoteId, camera.Id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      {
        delay: nextProps.initialDelay,
        sensitivity: nextProps.initialSensitivity,
      },
      () => {
        this.setState({
          disableRestore: this.alreadyDefault,
        });
      },
    );
  }

  onRevert = () => {
    const { initialDelay, initialSensitivity } = this.props;
    const originalState = {
      delay: initialDelay,
      hasChanged: false,
      sensitivity: initialSensitivity,
    };
    this.setState(
      {
        ...originalState,
      },
      () => {
        this.setState({ disableRestore: this.alreadyDefault });
      },
    );
  };

  get isAnalyticCamera() {
    const { camera } = this.props;
    return isAnalyticCamera(camera);
  }

  onRestore = () => {
    const prevState = this.state;
    const defaultState = { ...prevState };
    if (defaultState.sensitivity && defaultState.sensitivity.val) {
      defaultState.sensitivity.val = TAMPERING_DEFAULT_VALUE.sensitivity;
    }
    if (defaultState.delay && defaultState.delay.val) {
      defaultState.delay.val = TAMPERING_DEFAULT_VALUE.delay;
    }
    this.setState(
      {
        delay: defaultState.delay,
        hasChanged: true,
        sensitivity: defaultState.sensitivity,
      },
      () => {
        const defaultValueObject = this.constructTamperSettingObject(
          TAMPERING_DEFAULT_VALUE.sensitivity,
          TAMPERING_DEFAULT_VALUE.delay,
        );
        this.saveToDatabase(defaultValueObject);
      },
    );
  };

  onSave = () => {
    const { delay, sensitivity } = this.state;
    const valueObject = this.constructTamperSettingObject(
      sensitivity.val,
      delay.val,
    );
    this.saveToDatabase(valueObject);
  };

  get isCameraAnalyticsEnabled() {
    const { settings } = this.props;
    return settings.remoteAnalyticsEnabled || this.isAnalyticCamera;
  }

  get isTamperNotificationsEnabled() {
    const { camera } = this.props;
    if (!camera) return null;
    const cameraObject = normalizeCamera(camera);
    return cameraObject.IgnoreTamperEvents === false;
  }

  onToggleAnalytics = value => {
    const { actions, camera } = this.props;
    const { analyticsEnabled } = this.state;
    this.setState(
      {
        analyticsEnabled: !analyticsEnabled,
      },
      () => {
        actions.enableDisableAnalytics(
          camera.DeviceId,
          camera.RemoteId,
          value,
          camera.Id,
        );
      },
    );
  };

  onTamperNotifications = () => {
    const { actions, camera } = this.props;
    actions.enableDisableTamperEvents(
      camera.Id,
      !this.isTamperNotificationsEnabled,
    );
  };

  onValueChange = value => {
    this.setState(
      {
        delay: value.delay,
        hasChanged: true,
        sensitivity: value.sensitivity,
      },
      () => {
        this.setState({
          disableRestore: this.alreadyDefault,
        });
      },
    );
  };

  get buttonsDisabled() {
    const { hasChanged } = this.state;
    const { isSaving } = this.props;
    return !hasChanged || isSaving || this.isMatchingSavedValue;
  }

  get disableRestore() {
    const { disableRestore } = this.state;
    return disableRestore;
  }

  get isMatchingSavedValue() {
    const { initialDelay, initialSensitivity } = this.props;
    const { delay, sensitivity } = this.state;
    let isMatchingSensitivity = true;
    let isMatchingDelay = true;

    const initialSensitivityVal = get(initialSensitivity, 'val');
    if (initialSensitivityVal) {
      isMatchingSensitivity = sensitivity.val === initialSensitivityVal;
    }

    const initialDelayVal = get(initialDelay, 'val');
    if (initialDelayVal) {
      isMatchingDelay = delay.val === initialDelayVal;
    }

    return isMatchingSensitivity && isMatchingDelay;
  }

  get alreadyDefault() {
    const { delay, sensitivity } = this.state;
    let isDefaultSensitivity = true;
    let isDefaultDelay = true;

    const sensitivityVal = get(sensitivity, 'val');
    if (sensitivityVal) {
      isDefaultSensitivity =
        sensitivityVal === TAMPERING_DEFAULT_VALUE.sensitivity;
    }

    const delayVal = get(delay, 'val');
    if (delayVal) {
      isDefaultDelay = delayVal === TAMPERING_DEFAULT_VALUE.delay;
    }

    return isDefaultSensitivity && isDefaultDelay;
  }

  get controlsDisabled() {
    const { isFetching, isSaving } = this.props;
    return isFetching !== false || isSaving !== false;
  }

  get tamperingState() {
    const { delay, sensitivity } = this.state;
    return {
      delay,
      isAnalyticCamera: this.isAnalyticCamera,
      sensitivity,
    };
  }

  get panelSettings() {
    return (
      <PanelSettings key={TAMPERING_KEYS.settings}>
        <CameraSettingsTamperingHelper
          disabled={this.controlsDisabled}
          onChange={this.onValueChange}
          tamperingData={this.tamperingState}
        />
      </PanelSettings>
    );
  }

  get buttonGroupB() {
    return (
      <GroupLayout
        key={TAMPERING_KEYS.buttons}
        additionalClasses={buttonGroupBWrap}
        verticalSpacing="Small"
      >
        <Button
          key={TAMPERING_BUTTONS.restore.key}
          buttonType="secondary"
          disabled={this.disableRestore}
          id={TAMPERING_BUTTONS.restore.id}
          inputType="button"
          onClick={this.onRestore}
          text={<Translate id="TAMPERING.RESTORE_BUTTON_LABEL" />}
        />
        <Button
          key={TAMPERING_BUTTONS.revert.key}
          buttonType="primary"
          disabled={this.buttonsDisabled}
          id={TAMPERING_BUTTONS.revert.id}
          inputType="reset"
          onClick={this.onRevert}
          text={<Translate id="BUTTONS.REVERT" />}
        />
        <Button
          key={TAMPERING_BUTTONS.save.key}
          buttonType="primary"
          disabled={this.buttonsDisabled}
          id={TAMPERING_BUTTONS.save.id}
          inputType="button"
          onClick={this.onSave}
          text={<Translate id="BUTTONS.SAVE" />}
        />
      </GroupLayout>
    );
  }

  get tamperingToggle() {
    const { isSaving } = this.props;
    return (
      <div
        key="toggleDiv"
        className={
          this.isCameraAnalyticsEnabled
            ? tamperingToggleWrapper
            : disabledTamperingToggleWrapper
        }
      >
        <div className={formContent}>
          <div className={`${tabContentSection}`}>
            <div className={toggleAnalyticsTitle}>
              <Translate id="TAMPERING.ANALYTICS_LABEL" />
            </div>
            <Translate
              id={
                this.isCameraAnalyticsEnabled
                  ? 'TAMPERING.DISABLE_ANALYTICS_TITLE'
                  : 'TAMPERING.ENABLE_ANALYTICS_TITLE'
              }
            />
            <div className={tamperingToggle}>
              <Toggle
                checked={this.isCameraAnalyticsEnabled}
                disabled={isSaving}
                id={ANALYTICS_TOGGLE_ID}
                onChange={this.onToggleAnalytics}
                revertOn={toggleTypes.TOGGLE_CAMERA_ANALYTICS}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  get tamperNotificationsToggle() {
    return (
      <div
        key="tamperToggleDiv"
        className={
          this.isTamperNotificationsEnabled
            ? tamperingToggleWrapper
            : disabledTamperingToggleWrapper
        }
      >
        <div className={formContent}>
          <div className={`${tabContentSection}`}>
            <div className={toggleAnalyticsTitle}>
              <Translate id="TAMPERING.TAMPER_NOTIFICATIONS_LABEL" />
            </div>
            <Translate
              id={
                this.isTamperNotificationsEnabled
                  ? 'TAMPERING.DISABLE_TAMPER_TITLE'
                  : 'TAMPERING.ENABLE_TAMPER_TITLE'
              }
            />
            <div className={tamperingToggle}>
              <Toggle
                checked={this.isTamperNotificationsEnabled}
                id={TAMPER_TOGGLE_ID}
                onChange={this.onTamperNotifications}
                revertOn={toggleTypes.TOGGLE_CAMERA_TAMPERING}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  get panelContent() {
    const { isFetching, isSaving } = this.props;
    const panelContentArray = [];
    if (!isFetching) {
      if (this.isCameraAnalyticsEnabled) {
        // add the panel controls
        panelContentArray.push(this.panelSettings);
        // add the button groups
        panelContentArray.push(this.buttonGroupB);
      }

      panelContentArray.push(this.tamperNotificationsToggle);
    }
    if (isFetching !== true && isSaving !== true && !this.isAnalyticCamera) {
      panelContentArray.push(this.tamperingToggle);
    }
    return panelContentArray;
  }

  constructTamperSettingObject = (newSensitivityVal, newDelayVal) => {
    const { initialDelay, initialSensitivity } = this.props;
    const valueObject = {};
    if (initialSensitivity && initialSensitivity.val && newSensitivityVal) {
      valueObject.tamperingSensitivity = initialSensitivity;
      valueObject.tamperingSensitivity.val = newSensitivityVal;
    }
    if (initialDelay && initialDelay.val && newDelayVal) {
      valueObject.tamperingGracePeriod_s = initialDelay;
      valueObject.tamperingGracePeriod_s.val = newDelayVal;
    }
    return valueObject;
  };

  saveToDatabase = value => {
    const { actions, camera } = this.props;
    this.setState(
      {
        hasChanged: false,
      },
      () => {
        const data = {
          id: camera.RemoteId,
          settings: {
            analytics: value,
          },
        };

        actions.updateCameraSettings(
          camera.DeviceId,
          camera.RemoteId,
          camera.Id,
          data,
          false,
        );
      },
    );
  };

  render() {
    return (
      <div
        key={TAMPERING_KEYS.tamperingSettings}
        className={`${rightSettings} ${cameraSettingsBasic}`}
        id={TAMPERING_CONTENT_ID}
        onChange={this.handleChange}
      >
        {this.panelContent}
      </div>
    );
  }
}

CameraSettingsTampering.defaultProps = {
  initialDelay: {
    def: 15,
    id: '00000',
    max: 30,
    min: 2,
    val: 15,
  },
  initialSensitivity: {
    def: 5,
    id: '00000',
    max: 10,
    min: 1,
    val: 5,
  },
  isFetching: false,
  isSaving: false,
};

CameraSettingsTampering.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  camera: PropTypes.shape({}).isRequired,
  initialDelay: PropTypes.shape({
    def: PropTypes.number,
    id: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    val: PropTypes.number,
  }),
  initialSensitivity: PropTypes.shape({
    def: PropTypes.number,
    id: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    val: PropTypes.number,
  }),
  isFetching: PropTypes.bool,
  isSaving: PropTypes.bool,
  settings: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let settings = {};
  let tamperingSensitivityVal = {};
  let tamperingDelay = {};

  if (ownProps.camera) {
    settings = state.devices.settings[ownProps.camera.Id];
    if (settings && settings.analytics) {
      const tampSensitivity = get(settings, 'analytics.tamperingSensitivity');
      if (tampSensitivity) {
        tamperingSensitivityVal = tampSensitivity;
      }

      const tampGracePeriod = get(settings, 'analytics.tamperingGracePeriod_s');
      if (tampGracePeriod) {
        tamperingDelay = tampGracePeriod;
      }
    }
  }
  return {
    initialDelay: tamperingDelay,
    initialSensitivity: tamperingSensitivityVal,
    isFetching: state.devices.isFetchingCameraSettings.isFetching,
    isSaving: state.devices.isFetchingRuleData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...SettingsActions,
        ...DeviceActions,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CameraSettingsTampering);
