import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';

// Components
import { PageMessage } from 'containers';
import { Footer } from 'components';
import { Button } from 'lib';
import Logo from 'containers/Login/Logo';

// Actions
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';

// Constants
import { PATH_DEVICES, PATH_SERVERS } from 'constants/urlPaths';
import {
  appContainer,
  wizardButtons,
  wizardContainer,
  wizardFooter,
  wizardForm,
  wizardFormBody,
  wizardHeader,
  wizardHeaderLeft,
  wizardLogo,
  wizardScreen,
} from 'sharedStyles/styles.css';
import * as messageConsts from '../PageMessage/constants';

// Styles

import {
  claimResults,
  container,
  inlineContainer,
  inlineLabel,
} from './styles.css';

const { success } = messageConsts.messageStyleStrings;

const ClaimResults = props => {
  const { currentOrganization, deviceName } = props;
  return (
    <div className={claimResults}>
      <div className={inlineContainer}>
        <span className={inlineLabel}>
          <Translate id="DEVICES.DEVICE_CLAIM.BLUE_CONNECT_LABEL" />
        </span>
        {deviceName}
      </div>
      <div className={inlineContainer}>
        <span className={inlineLabel}>
          <Translate id="DEVICES.DEVICE_CLAIM.SUBSCRIBER_LABEL" />
        </span>
        {currentOrganization && currentOrganization.Name}
      </div>
      {/* Disabled pending server fixes.
      <div className={inlineContainer}>
        <span className={inlineLabel}>
          <Translate id={'COMMON.SITE'} />
        </span>
        {props.locationName}
      </div>
      */}
    </div>
  );
};

ClaimResults.defaultProps = {
  deviceName: '',
};

ClaimResults.propTypes = {
  currentOrganization: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
};

class ActivationSuccessContainer extends Component {
  componentDidUpdate() {
    const {
      actions,
      currentOrganization,
      isFetchingDeviceData,
      isFetchingLocations,
    } = this.props;
    if (
      currentOrganization &&
      currentOrganization.Id &&
      isFetchingLocations === null
    ) {
      actions.getLocations(currentOrganization.Id);
    }
    if (isFetchingLocations && isFetchingDeviceData === null) {
      actions.getAllDevices();
    }
  }

  render() {
    const {
      currentOrganization,
      deviceId,
      deviceName,
      locationName,
    } = this.props;
    return (
      <div className={appContainer}>
        <div className={wizardScreen}>
          <div className={wizardContainer}>
            <div className={wizardLogo}>
              <Logo />
            </div>
            <div className={wizardForm}>
              <div className={container}>
                <div className={wizardHeader}>
                  <div className={wizardHeaderLeft}>
                    <Translate id="DEVICES.DEVICE_CLAIM.COMPLETED_HEADER_TEXT" />
                  </div>
                </div>
                <div className={wizardFormBody}>
                  <PageMessage
                    body={
                      <Translate id="DEVICES.DEVICE_CLAIM.DEVICE_CLAIM_SUCCESS" />
                    }
                    messageStyle={success}
                    visible={deviceName}
                  />
                  <ClaimResults
                    currentOrganization={currentOrganization}
                    deviceName={
                      deviceName || (
                        <Translate id="DEVICES.DEVICE_CLAIM.CLAIM_IN_PROCESS_TEXT" />
                      )
                    }
                    locationName={locationName}
                  />
                  <div className={wizardButtons}>
                    <Link
                      disabled={!deviceName}
                      title="RedirectToEditedGrp"
                      to={
                        deviceName
                          ? `${PATH_SERVERS}/${deviceId}`
                          : `${PATH_DEVICES}`
                      }
                    >
                      <Button
                        inputType="button"
                        onClick={this.goToConfig}
                        text={
                          <Translate id="DEVICES.DEVICE_CLAIM.CONFIGURE_BUTTON_LABEL" />
                        }
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Footer className={wizardFooter} />
          </div>
        </div>
      </div>
    );
  }
}
ActivationSuccessContainer.defaultProps = {
  deviceName: '',
  locationName: <Translate id="DEVICES.DEVICE_CLAIM.LOADING_WITH_ELIPSIS" />,
};
ActivationSuccessContainer.propTypes = {
  actions: PropTypes.func.isRequired,
  currentOrganization: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  isFetchingDeviceData: PropTypes.bool.isRequired,
  isFetchingLocations: PropTypes.bool.isRequired,
  locationName: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const deviceId = ownProps.match.params.id;
  const { devices } = state.devices;
  const activatedDevice = devices && devices.find(d => d.Id === deviceId);
  const deviceName = activatedDevice && activatedDevice.Name;
  const { locations } = state.locations;
  const deviceLocation =
    activatedDevice &&
    locations &&
    locations.find(l => l.Id === activatedDevice.SiteId);
  const locationName = deviceLocation && deviceLocation.Name;
  return {
    currentOrganization: state.user.currentOrganization,
    deviceId,
    deviceName,
    devices,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
    isFetchingLocations: state.isFetching.getLocations,
    locationName,
    locations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...DeviceActions, ...LocationActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivationSuccessContainer);
