import { DEVICE_TYPES } from 'constants/app';

const customCameraObject = cameras => {
  const customCameraArray = [];
  cameras.forEach(camera => {
    const {
      Active,
      ConnectionState,
      ConnectionStatus,
      Disarmed,
      FirmwareVersion,
      Id,
      IpAddress,
      Location,
      LocationId,
      LocationName,
      Manufacturer,
      Model,
      Name,
      PhysicalAddress,
      Serial,
      ServerId,
      ServerName,
    } = camera;
    const customCamera = {
      Active,
      ConnectionState,
      ConnectionStatus,
      Description: Location,
      Disarmed,
      FirmwareVersion,
      Id,
      IPAddress: IpAddress,
      LocationId,
      LocationName,
      MacAddress: PhysicalAddress,
      Manufacturer,
      Model,
      Name,
      SerialNumber: Serial,
      ServerId,
      ServerName,
      Type: DEVICE_TYPES.CAMERA,
    };
    customCameraArray.push(customCamera);
  });
  return customCameraArray;
};

export default customCameraObject;
