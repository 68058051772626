import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VideoPlayer } from 'components';
import Dimensions from 'react-dimensions';
import GetMediaParams from '../GetMediaParams/GetMediaParams';
import { getFullPath } from 'util/getPathName';

class VideoContainer extends Component {
  render() {
    // todo: need to fetch camera and its DefaultHeight and DefaultWidth attr in order to set aspectRatioVideo
    // but we dont have camera id, just camera remote id
    return (
      <div>
        <VideoPlayer
          id="fullscreen"
          width={this.props.containerWidth}
          height={this.props.containerHeight}
          mediaParams={this.props.mediaParams}
          startRecordedVideo={this.startRecordedVideo}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let deviceId;
  let cameraRemoteId;
  const path = getFullPath(window.location.pathname);
  const arrPath = path.split('/');
  if (arrPath.length > 3) {
    deviceId = arrPath.slice(-2).shift();
    cameraRemoteId = arrPath.slice(-1).pop();
  }
  const { mediaParams } = state.devices;
  return {
    deviceId,
    cameraRemoteId,
    mediaParams,
  };
}

VideoContainer.defaultProps = {
  mediaParams: {},
};

export default GetMediaParams(
  connect(mapStateToProps)(Dimensions({ elementResize: true })(VideoContainer)),
);
