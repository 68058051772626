import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import RadioGroup from 'components/Inputs/RadioGroup';
import { FormGroup } from 'lib';
import { Field, reduxForm } from 'redux-form';
import normalizeBoolean from 'util/normalizeBoolean';

import * as LocationFormConsts from '../../constants';

const MonitoringField = props => {
  const { disabled } = props;

  return (
    <FormGroup label={<Translate id="LOCATIONS.LOCATION_FORM.MONITORING" />}>
      <Field
        component={fieldProps => (
          <RadioGroup
            disabled={disabled}
            name={LocationFormConsts.nameMonitoring}
            onChange={e => {
              const changedValue = normalizeBoolean(e.target.value);
              fieldProps.input.onChange(changedValue);
            }}
            options={[
              {
                translateId: 'COMMON.YES',
                value: true,
              },
              {
                translateId: 'COMMON.NO',
                value: false,
              },
            ]}
            value={fieldProps.input.value}
          />
        )}
        name={LocationFormConsts.nameMonitoring}
      />
    </FormGroup>
  );
};

MonitoringField.defaultProps = {};

MonitoringField.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: LocationFormConsts.FORM_NAME,
})(MonitoringField);
