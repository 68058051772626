// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dimensions from 'react-dimensions';
import { Translate, withLocalize } from 'react-localize-redux';
import renderIf from 'render-if';

// utils
import checkProxyAlarmHistoryChange from 'util/checkProxyAlarmHistoryChange';
import { setAftTenantId } from 'util/aftHelper';

// Actions
import * as AlarmActions from 'actions/alarms';
import * as DeviceActions from 'actions/devices';
import * as ContactActions from 'actions/contacts';

// Components
import { ModalContainer, PageMessage } from 'containers';
import { ArmDisarmCamera, ButtonToggle, RadioGroup } from 'components';
import getNotificationsEventsTypeKey from 'util/getNotificationEventTypeKey';
import * as types from 'constants/ActionTypes';
import { ACTIVE_COLOR_DEFAULT, INACTIVE_COLOR_DEFAULT } from 'constants/app';
import { IconPhone } from 'icons';
import {
  convertCameraTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import * as messageTypes from 'constants/MessageTypes';
import { IC_NOTIFICATION_OFF } from 'constants/iconNames';
import { EmptyPlaceholder, Icon } from 'lib';
import ActionLog from './ActionLog';
import CommentComponent from './CommentComponent';
import NotificationStreamVideo from './NotificationStreamVideo';
import NotificationClipVideo from './NotificationClipVideo';
import NotificationsContactsTable from './NotificationsContactsTable';

// Constants
import * as alarmConstants from './constants';

// Styles
import {
  activityBox,
  activityBoxRowCommentItem,
  activityBoxRowLogItem,
  alarmData,
  armDisarmIcon,
  buttonContainer,
  cameraDisarmIconWrap,
  contactsDropdown,
  controlsContainer,
  formWrapper,
  headerButton,
  headerButtonDisabled,
  iconDisarmed,
  messageWrap,
  scrollButtonContainer,
  scrollButtonLeft,
  scrollButtonRight,
  separator,
  staleModalData,
  subheaderBold,
  subheaderLight,
  toggleTextContainer,
  verifyAlarmContainer,
  verifyAlarmPart,
  videoPlayer,
  videoPlayerContainer,
} from './styles.css';

// Class
class EditNotificationModal extends Component {
  constructor(props) {
    super(props);
    this.evoPlayer = null;
    this.state = {
      alarmReopened: false,
      isBusyFetchingRules: false,
      newAlarmRequested: false,
      showContacts: false,
      showFullEvent: false,
      showLiveRulesOverlay: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      alarm,
      alarmId,
      alarmTenantId,
      selectedTenantId,
    } = this.props;
    if (alarm && alarmId === alarm.Id) {
      actions.isFetching &&
        actions.isFetching(types.IS_FETCHING_SELECTED_ALARM);
      this.setState({ alarmReopened: true }, () => {
        actions.getAlarm(alarmId, alarmTenantId);
      }); // Flag so willReceiveProps sets alarm to in-review state
    } else {
      actions.getAlarm(alarmId, alarmTenantId);
    }

    if (alarmTenantId && alarmTenantId !== selectedTenantId) {
      setAftTenantId(alarmTenantId);
    }

    document.addEventListener('keydown', this.handleArrowKeyPress);
  }

  componentWillUnmount() {
    const { alarmTenantId, selectedTenantId } = this.props;
    if (alarmTenantId && alarmTenantId !== selectedTenantId) {
      setAftTenantId(selectedTenantId);
    }
    document.removeEventListener('keydown', this.handleArrowKeyPress);
  }

  getStoreData = (status, newServerData, hasHistory = true) => {
    const { alarm, userId } = this.props;
    const history = alarm.History ? alarm.History : [];
    const storeData = { Status: status };
    if (hasHistory) {
      storeData.History = [
        ...history,
        {
          Timestamp: alarmConstants.justNow,
          UserId: userId,
          ...newServerData,
        },
      ];
    }
    return storeData;
  };

  calculateVideoDimensions = () => {
    const { containerHeight, containerWidth } = this.props;

    // First, try to fill available height
    const playerHeight =
      containerHeight -
      alarmConstants.MODAL_HEADER_HEIGHT -
      alarmConstants.HEADER_HEIGHT -
      alarmConstants.LOG_HEIGHT -
      alarmConstants.VERTICAL_PADDING;
    const playerWidth =
      (containerWidth - alarmConstants.HORIZONTAL_PADDING) / 2;
    return { playerHeight, playerWidth };
  };

  onPrevious = () => {
    const {
      alarm,
      alarmTenantId,
      handlePrevious,
      isLoading,
      isUpdating,
    } = this.props;
    const { newAlarmRequested } = this.state;
    if (!isUpdating && !isLoading && !newAlarmRequested) {
      this.setState({
        isBusyFetchingRules: false,
        newAlarmRequested: true,
        showContacts: false,
      });
      const newServerData = {
        Action: 'Release',
        Context: '',
      };
      const status =
        alarm.Status === alarmConstants.dismissed
          ? alarmConstants.dismissed
          : alarmConstants.unreviewed;
      const newStoreData = this.getStoreData(status, newServerData, false);
      this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
      handlePrevious();
    }
  };

  handleArrowKeyPress = e => {
    const { alarm, alarms } = this.props;
    const isFirst = alarms.length && alarms[0].Id === alarm.Id;
    const isLast = alarms.length && alarms[alarms.length - 1].Id === alarm.Id;
    if (e.keyCode === 37 && !isFirst) {
      this.onPrevious();
    } else if (e.keyCode === 39 && !isLast) {
      this.onNext();
    }
  };

  onNext = () => {
    const {
      alarm,
      alarmTenantId,
      handleNext,
      isLoading,
      isUpdating,
    } = this.props;
    const { newAlarmRequested } = this.state;
    if (!isUpdating && !isLoading && !newAlarmRequested) {
      this.setState({
        isBusyFetchingRules: false,
        newAlarmRequested: true,
        showContacts: false,
      });
      const newServerData = {
        Action: 'Release',
        Context: '',
      };
      const status =
        alarm.Status === alarmConstants.dismissed
          ? alarmConstants.dismissed
          : alarmConstants.unreviewed;
      const newStoreData = this.getStoreData(status, newServerData, false);
      this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
      handleNext();
    }
  };

  onExit = () => {
    const { alarm, alarmTenantId, handleCancel } = this.props;
    this.setState({ showContacts: false });
    if (!alarm || !alarm.Id) {
      handleCancel();
    }
    const newServerData = {
      Action: 'Release',
      Context: '',
    };
    const status =
      alarm.Status === alarmConstants.dismissed
        ? alarmConstants.dismissed
        : alarmConstants.unreviewed;
    const newStoreData = this.getStoreData(status, newServerData);
    this.updateAlarm(newStoreData, newServerData, alarm.Id, alarmTenantId);
    handleCancel();
  };

  onContactClick = () => {
    const { isLoading, isUpdating } = this.props;
    const { showContacts } = this.state;
    if (!isUpdating || !isLoading) {
      this.setState({ showContacts: !showContacts });
    }
    return null;
  };

  onResolvedClicked = () => {
    const { alarm, alarmTenantId, isLoading, isUpdating } = this.props;

    if (isUpdating || isLoading) {
      return null;
    }

    let newStoreData;
    let newServerData;
    if (alarm.Status !== alarmConstants.dismissed) {
      newServerData = {
        Action: 'Dismiss',
        Context: '',
      };
      newStoreData = this.getStoreData(alarmConstants.dismissed, newServerData);
    } else {
      newServerData = {
        Action: 'Reopen',
        Context: 'inreview',
      };
      newStoreData = this.getStoreData(alarmConstants.inReview, newServerData);
    }

    return this.updateAlarm(
      newStoreData,
      newServerData,
      alarm.Id,
      alarmTenantId,
    );
  };

  onFalseAlarmClicked = event => {
    const { value } = event.target;
    const { alarm, alarmTenantId, isLoading, isUpdating } = this.props;

    if (isUpdating || isLoading) return null;

    let isFalseAlarm;
    if (value === 'true') {
      isFalseAlarm = true;
    } else if (value === 'false') {
      isFalseAlarm = false;
    } else {
      isFalseAlarm = null;
    }
    const newServerData = {
      Action: 'MarkFalse',
      Context: isFalseAlarm,
    };

    const newStoreData = this.getStoreData(alarm.Status, newServerData);
    newStoreData.FalseAlarm = isFalseAlarm;
    return this.updateAlarm(
      newStoreData,
      newServerData,
      alarm.Id,
      alarmTenantId,
    );
  };

  submitForm = formData => {
    const { alarm, alarmTenantId } = this.props;
    const serverData = { Action: 'Comment', Context: formData.ReviewComment };
    const storeData = this.getStoreData(alarm.Status, serverData);
    this.updateAlarm(storeData, serverData, alarm.Id, alarmTenantId);
  };

  onGetFullEvent = () => {
    const { device } = this.props;
    if (
      device &&
      device.ConnectionState &&
      device.ConnectionState === CAMERA_CONNECTED
    ) {
      return this.getFullEvent;
    }
    return null;
  };

  getFullEvent = () => {
    this.setState({
      showFullEvent: true,
    });
  };

  onGetClip = () => {
    this.setState({
      showFullEvent: false,
    });
  };

  updateAlarm = (storeData, serverData, alarmId, alarmTenantId) => {
    const { actions, updateAlarmStore } = this.props;
    // update store in the top-level container
    updateAlarmStore(storeData, alarmId);
    // send update to server
    actions.editAlarm(serverData, alarmId, alarmTenantId);
  };

  autofocus = () => {
    const { actions, cameraDeviceId, cameraRemoteId } = this.props;
    actions.cameraAutofocusGateway(cameraDeviceId, cameraRemoteId);
  };

  setLiveRulesOverlayVisibility = show => {
    this.setState({
      showLiveRulesOverlay: show,
    });
  };

  armDisarmClick = () => {
    this.setState({ showContacts: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, alarm, alarmId, camera } = this.props;
    const {
      alarmReopened,
      isBusyFetchingRules,
      showLiveRulesOverlay,
    } = this.state;

    const isAlarmChanged = nextProps.alarmId && nextProps.alarmId !== alarmId;
    const isProxyAlarmHistoryChanged = checkProxyAlarmHistoryChange(
      alarm,
      nextProps.alarm,
    );
    if (isProxyAlarmHistoryChanged) {
      actions.getAlarm(nextProps.alarmId, nextProps.alarmTenantId);
      if (isAlarmChanged) {
        this.setState({
          showFullEvent: false,
          showLiveRulesOverlay: nextProps.shouldRefreshLiveVideo
            ? false
            : showLiveRulesOverlay,
        });
      }
    }
    if (
      nextProps.alarm &&
      nextProps.alarm.Id &&
      nextProps.isUpdating === false &&
      (!alarm || nextProps.alarm.Id !== alarm.Id || alarmReopened)
    ) {
      const history = nextProps.alarm.History ? nextProps.alarm.History : [];
      const status =
        nextProps.alarm.Status === alarmConstants.dismissed
          ? alarmConstants.dismissed
          : alarmConstants.inReview;
      const newServerData = {
        Action: 'Accept',
        Context: '',
      };
      const newStoreData = {
        History: [
          ...history,
          {
            Timestamp: alarmConstants.justNow,
            UserId: nextProps.userId,
            ...newServerData,
          },
        ],
        Status: status,
      };
      this.setState({ alarmReopened: false, newAlarmRequested: false }, () => {
        this.updateAlarm(
          newStoreData,
          newServerData,
          nextProps.alarmId,
          nextProps.alarmTenantId,
        );
      });
    }

    if (
      nextProps.alarm &&
      nextProps.camera &&
      camera &&
      nextProps.camera.RemoteId &&
      nextProps.camera.RemoteId !== camera.RemoteId
    ) {
      if (!nextProps.settings && !nextProps.alarmTenantId) {
        actions.getAcquisitionSettings(
          nextProps.cameraDeviceId,
          nextProps.cameraRemoteId,
          nextProps.cameraId,
        );
      }

      if (
        nextProps.camera &&
        !nextProps.rules &&
        nextProps.isFetchingRules !== true &&
        isBusyFetchingRules === false
      ) {
        this.setState({ isBusyFetchingRules: true }, () => {
          actions.getRules(
            nextProps.cameraDeviceId,
            nextProps.cameraRemoteId,
            nextProps.cameraId,
            nextProps.alarmTenantId ? nextProps.alarmTenantId : null,
          );
        });
      }
    }
  }

  render() {
    const {
      actions,
      alarm,
      alarms,
      camera,
      cameraDeviceId,
      cameraDeviceLocation,
      cameraDeviceName,
      cameraId,
      cameraLocation,
      cameraName,
      cameraRemoteId,
      clipDisposition,
      clipId,
      clipUri,
      contacts,
      device,
      isLoading,
      isUpdating,
      preferredLongDateFormat,
      preferredTimeFormat,
      profileTimeZone,
      rules,
      translate,
      users,
    } = this.props;

    const { showContacts, showFullEvent, showLiveRulesOverlay } = this.state;

    const isFirst = alarms.length && alarms[0].Id === alarm.Id;
    const isLast = alarms.length && alarms[alarms.length - 1].Id === alarm.Id;

    if (alarm) {
      const clipUnavailable =
        clipDisposition !== alarmConstants.uploadSucceeded;
      const fullEventOnly =
        clipDisposition === alarmConstants.fullEventOnly ||
        clipDisposition === alarmConstants.notAvailable;
      const isResolved = alarm.Status === alarmConstants.dismissed;
      const isFalseAlarm =
        alarm.Status === alarmConstants.falseAlarm || alarm.FalseAlarm;
      const alarmDate = alarm.EventStartTime || alarm.Created;
      const formatForLocale = getFormatForActiveLocale();
      const dateTimeString = alarmDate
        ? convertCameraTimeTo(
            alarmDate,
            TIME_TYPES.LOCAL,
            device,
            cameraLocation,
            getPreferredLongDateTimeFormat(
              preferredLongDateFormat,
              preferredTimeFormat,
            ) || formatForLocale,
          )
        : translate('ALARMS.STATUS.UNAVAILABLE_LABEL');
      let header;
      if (isUpdating) {
        header = translate('ALARMS.EDIT_MODAL.LOADING_LABEL');
      } else {
        const eventName = getNotificationsEventsTypeKey(alarm.EventName);
        header = (
          <Translate
            data={{
              eventDateTime: dateTimeString,
              eventName: eventName.translate
                ? translate(eventName.keyName)
                : eventName.name,
            }}
            id="ALARMS.EDIT_MODAL.REVIEW_LABEL"
          />
        );
      }
      const { playerHeight, playerWidth } = this.calculateVideoDimensions(
        alarm.History,
      );

      return (
        <ModalContainer handleCancel={this.onExit} modalTitle={header}>
          {isLoading ? (
            <EmptyPlaceholder isFetching={isLoading} />
          ) : (
            <div className={`${formWrapper} ${isUpdating && staleModalData}`}>
              <div className={messageWrap}>
                <PageMessage
                  messageType={[
                    messageTypes.CAMERA_ARM_SUCCESS,
                    messageTypes.CAMERA_ARM_ERROR,
                    messageTypes.CAMERA_DISARM_SUCCESS,
                    messageTypes.CAMERA_DISARM_ERROR,
                  ]}
                />
              </div>
              <div className={controlsContainer}>
                <div className={alarmData}>
                  <span className={subheaderBold}>
                    {translate('ALARMS.EDIT_MODAL.SITE_LABEL')}
                  </span>
                  <span className={subheaderLight}>
                    {`${cameraDeviceLocation},`}
                  </span>
                  <span className={subheaderBold}>
                    {translate('ALARMS.EDIT_MODAL.SERVER_LABEL')}
                  </span>
                  <span className={subheaderLight}>
                    {`${cameraDeviceName},`}
                  </span>
                  <span className={subheaderBold}>
                    {translate('ALARMS.EDIT_MODAL.CAMERA_LABEL')}
                  </span>
                  <span className={subheaderLight}>{cameraName}</span>
                  <div className={cameraDisarmIconWrap}>
                    {camera && camera.Disarmed ? (
                      <Icon
                        iconClass={iconDisarmed}
                        id={IC_NOTIFICATION_OFF}
                        title="CAMERA.SETTINGS.ACTIONS.CAMERA_DISARM_SUCCESS"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className={buttonContainer}>
                  <ArmDisarmCamera
                    actions={actions}
                    camera={camera}
                    device={device}
                    iconClass={armDisarmIcon}
                    isLoading={isLoading}
                    isUpdating={isUpdating}
                    onArmDisarmClick={this.armDisarmClick}
                    showButtonWrap
                  />
                  <div
                    className={`${headerButton} ${contacts.length === 0 &&
                      headerButtonDisabled}`}
                    onClick={
                      contacts.length !== 0 ? this.onContactClick : undefined
                    }
                    onKeyPress={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    <IconPhone
                      fill={
                        contacts.length === 0
                          ? INACTIVE_COLOR_DEFAULT
                          : ACTIVE_COLOR_DEFAULT
                      }
                      title={translate('ALARMS.EDIT_MODAL.CONTACTS_ICON_TITLE')}
                    />
                  </div>

                  {showContacts && (
                    <div className={contactsDropdown}>
                      <NotificationsContactsTable
                        contacts={contacts.map(contact => ({ contact }))}
                        maxHeight={350}
                      />
                    </div>
                  )}
                </div>
                <div className={verifyAlarmContainer}>
                  <RadioGroup
                    containerClassName={verifyAlarmPart}
                    disabled={clipUnavailable}
                    labelTranslateId="ALARMS.EDIT_MODAL.FALSE_ALARM_LABEL"
                    onChange={this.onFalseAlarmClicked}
                    options={alarmConstants.FALSE_DETECTION_RADIO_OPTIONS}
                    value={isFalseAlarm}
                  />
                  <div className={separator} />
                  <RadioGroup
                    containerClassName={verifyAlarmPart}
                    labelTranslateId="ALARMS.EDIT_MODAL.REVIEWED_LABEL"
                    onChange={this.onResolvedClicked}
                    options={alarmConstants.REVIEWED_RADIO_OPTIONS}
                    value={isResolved}
                  />
                  <div className={separator} />
                  <div className={scrollButtonContainer}>
                    {isFirst ? (
                      <div />
                    ) : (
                      <div
                        alt={translate('ALARMS.EDIT_MODAL.PREVIOUS_LABEL')}
                        className={`${headerButton} ${scrollButtonLeft}`}
                        onClick={this.onPrevious}
                        onKeyPress={() => {}}
                        role="button"
                        tabIndex={0}
                      />
                    )}
                    {isLast ? (
                      <div />
                    ) : (
                      <div
                        alt={translate('ALARMS.EDIT_MODAL.NEXT_LABEL')}
                        className={`${headerButton} ${scrollButtonRight}`}
                        onClick={this.onNext}
                        onKeyPress={() => {}}
                        role="button"
                        tabIndex={0}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={videoPlayerContainer}>
                <div className={videoPlayer}>
                  <NotificationStreamVideo
                    key={`${alarmConstants.liveLabel}_${cameraDeviceName}_${cameraName}`}
                    autofocus={this.autofocus}
                    cameraId={cameraId}
                    cameraName={cameraName}
                    cameraRemoteId={cameraRemoteId}
                    deviceId={cameraDeviceId}
                    deviceName={cameraDeviceName}
                    id={alarmConstants.liveLabel}
                    playerHeight={playerHeight}
                    playerWidth={playerWidth}
                    rules={rules}
                    setRulesOverlayVisibility={this.setLiveRulesOverlayVisibility}
                    showRulesOverlay={showLiveRulesOverlay}
                    siteName={cameraDeviceLocation}
                  />
                </div>
                <div className={videoPlayer}>
                  {showFullEvent || fullEventOnly ? (
                    <NotificationStreamVideo
                      key={`${alarmConstants.recordedId}_${cameraDeviceName}_${cameraName}`}
                      autofocus={this.autofocus}
                      cameraId={cameraId}
                      cameraName={cameraName}
                      cameraRemoteId={cameraRemoteId}
                      deviceId={cameraDeviceId}
                      deviceName={cameraDeviceName}
                      eventStartTime={alarm.EventStartTime}
                      getClipVideo={this.onGetClip}
                      id={alarmConstants.recordedId}
                      playerHeight={playerHeight}
                      playerWidth={playerWidth}
                      rules={rules}
                      setRulesOverlayVisibility={
                        this.setLiveRulesOverlayVisibility
                      }
                      showReturnToLiveVideo={false}
                      showRulesOverlay={showLiveRulesOverlay}
                      siteName={cameraDeviceLocation}
                    />
                  ) : (
                    <NotificationClipVideo
                      cameraId={cameraId}
                      cameraRemoteId={cameraRemoteId}
                      clipDisposition={clipDisposition}
                      clipId={clipId}
                      clipUri={clipUri}
                      deviceId={cameraDeviceId}
                      handleGetFullEvent={this.onGetFullEvent()}
                      isFetching={isLoading}
                      playerHeight={playerHeight}
                      playerWidth={playerWidth}
                    />
                  )}
                  {renderIf(!clipUnavailable && !fullEventOnly)(
                    <ButtonToggle
                      className={toggleTextContainer}
                      label={translate('VIDEO_PLAYER.MODE_LABEL')}
                      leftChecked={!showFullEvent}
                      leftLabel={translate('VIDEO_PLAYER.PREVIEW_LABEL')}
                      leftValue={alarmConstants.clipVideo}
                      name={alarmConstants.toggleVideoMode}
                      onChange={
                        !showFullEvent ? this.onGetFullEvent() : this.onGetClip
                      }
                      rightLabel={translate('VIDEO_PLAYER.FULL_LABEL')}
                      rightValue={alarmConstants.fullVideo}
                    />,
                  )}
                </div>
              </div>
              <div className={activityBox}>
                <div className={activityBoxRowCommentItem}>
                  <CommentComponent
                    handleSubmit={this.submitForm}
                    height="100%"
                    width="100%"
                  />
                </div>
                <div className={activityBoxRowLogItem}>
                  <ActionLog
                    height={100}
                    history={alarm.History || []}
                    profileTimeZone={profileTimeZone}
                    users={users || []}
                    width="100%"
                  />
                </div>
              </div>
            </div>
          )}
        </ModalContainer>
      );
    }
    return (
      <ModalContainer handleCancel={this.onExit} modalTitle="">
        <div className={formWrapper} />
      </ModalContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let rules = null;
  let clipId = '';
  let clipUri = '';
  let clipDisposition = '';
  let contacts = [];
  let cameraDeviceId = null;
  let cameraId = null;
  let cameraRemoteId = null;
  let isLoading = ownProps.isBusy;
  let settings = null;
  let cameraLocation = null;

  let cameraName = <Translate id="ALARMS.EDIT_MODAL.NOT_AVAILABLE" />;
  let cameraDeviceLocation = <Translate id="ALARMS.EDIT_MODAL.NOT_AVAILABLE" />;
  let cameraDeviceName = <Translate id="ALARMS.EDIT_MODAL.NOT_AVAILABLE" />;
  const alarm = state.alarms.selectedAlarm;
  if (alarm) {
    contacts = alarm.Contacts;
    cameraId = alarm.CameraId;
    cameraRemoteId = alarm.CameraRemoteId;
    cameraName = alarm.CameraName;
    cameraDeviceName = alarm.DeviceName;
    cameraDeviceId = alarm.DeviceId;
    rules =
      state.devices.rules[cameraId] && state.devices.rules[cameraId].rules;
    cameraDeviceLocation = alarm.LocationName;
    clipId = alarm.ClipId;
    clipUri = alarm.ClipUri;
    clipDisposition = alarm.ClipDisposition;
    isLoading = !state.alarms.selectedAlarm.Id || ownProps.isBusy; // When first loading or switching pages
    cameraLocation = state.locations.locations.find(
      x => x.Id === alarm.LocationId,
    );
  }
  const camera = state.devices.cameras.find(c => c.Id === cameraId);
  const device = state.devices.devices.find(d => d.Id === cameraDeviceId);
  if (camera) {
    settings = state.devices.settings[alarm.CameraId];
  }

  const isUpdating = !!state.alarms.isFetchingSelectedAlarm; // When cycling from one alarm to the next

  return {
    alarm,
    camera,
    cameraDeviceId,
    cameraDeviceLocation,
    cameraDeviceName,
    cameraId,
    cameraLocation,
    cameraName,
    cameraRemoteId,
    clipDisposition,
    clipId,
    clipUri,
    contacts,
    device,
    isFetchingAcquisitionData: state.devices.isFetchingAcquisitionData,
    isFetchingRules: state.isFetching.getRules,
    isLoading,
    isUpdating,
    preferredLongDateFormat:
      state.user.profile.LocalizationPreference.PreferredLongDateFormat,
    preferredTimeFormat:
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
    rules,
    selectedTenantId: state.user.selectedOrganization.Id,
    settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...AlarmActions, ...DeviceActions, ...ContactActions },
      dispatch,
    ),
  };
}

EditNotificationModal.defaultProps = {
  alarmTenantId: null,
  camera: undefined,
  cameraDeviceId: undefined,
  cameraDeviceLocation: undefined,
  cameraDeviceName: undefined,
  cameraId: undefined,
  cameraLocation: undefined,
  cameraName: undefined,
  cameraRemoteId: undefined,
  clipDisposition: undefined,
  clipId: undefined,
  clipUri: undefined,
  contacts: undefined,
  device: undefined,
  isFetchingRules: null,
  preferredLongDateFormat: '',
  preferredTimeFormat: '',
  rules: undefined,
};

EditNotificationModal.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  alarm: PropTypes.objectOf(PropTypes.any).isRequired,
  alarmId: PropTypes.string.isRequired,
  alarmTenantId: PropTypes.string,
  alarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  camera: PropTypes.objectOf(PropTypes.any),
  cameraDeviceId: PropTypes.string,
  cameraDeviceLocation: PropTypes.string,
  cameraDeviceName: PropTypes.string,
  cameraId: PropTypes.string,
  cameraLocation: PropTypes.shape({}),
  cameraName: PropTypes.string,
  cameraRemoteId: PropTypes.string,
  clipDisposition: PropTypes.string,
  clipId: PropTypes.string,
  clipUri: PropTypes.string,
  contacts: PropTypes.arrayOf(PropTypes.object),
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  device: PropTypes.objectOf(PropTypes.any),
  handleCancel: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  isFetchingRules: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  preferredLongDateFormat: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
  profileTimeZone: PropTypes.string.isRequired,
  rules: PropTypes.objectOf(PropTypes.any),
  selectedTenantId: PropTypes.string.isRequired,
  settings: PropTypes.shape({}).isRequired,
  shouldRefreshLiveVideo: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  updateAlarmStore: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Dimensions({ elementResize: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withLocalize(EditNotificationModal)),
);
