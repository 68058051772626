import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { COPY_ICON_ID } from './constants';

const IconCopy = ({ translate, title, className, width, height, fill }) => {
  return (
    <div title={translate(title)} className={className} id={COPY_ICON_ID}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 21 24"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="icon-copy" fill={fill}>
            <g id="btn-copy">
              <path
                id="ic_copy-a"
                d="M17.9998,2 L5.9998,2 L3.9998,2 L3.9998,4 L3.9998,20 L5.9998,20 L5.9998,4 L17.9998,4 L17.9998,2 Z M12,9.5 L18,9.5 L18,8 L12,8 L12,9.5 Z M12,17.5 L18,17.5 L18,16 L12,16 L12,17.5 Z M12,13.5 L18,13.5 L18,12 L12,12 L12,13.5 Z M9,10 L11,10 L11,8 L9,8 L9,10 Z M9,14 L11,14 L11,12 L9,12 L9,14 Z M9,18 L11,18 L11,16 L9,16 L9,18 Z M7,22 L20,22 L20,5 L7,5 L7,22 Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconCopy.defaultProps = {
  title: 'BUTTONS.COPY',
  width: '21px',
  height: '24px',
  fill: ACTIVE_COLOR_DEFAULT,
  className: '',
};

IconCopy.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(IconCopy);
