exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/modalForms.css"), undefined);

// module
exports.push([module.id, ".formField___2re0G {\r\n  min-width: 0px;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex: 1 0;\r\n      flex: 1 0;\r\n  height: 30px;\r\n  border: #dddddd;\r\n}\r\n\r\n.formColumn___EjLaD .formField___2re0G input,\r\n.formColumn___EjLaD .formField___2re0G select {\r\n  width: 100%;\r\n  padding: 0px 10px;\r\n}\r\n\r\n.formColumn___EjLaD .formField___2re0G input:focus,\r\n.formColumn___EjLaD .formField___2re0G select:focus {\r\n  border-color: #5db6ff !important;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/common/AddCreateUser/styles.css"],"names":[],"mappings":"AAAA;EAEE,eAAe;EACf,qBAAqB;EACrB,cAAc;EACd,cAAc;MACV,UAAU;EACd,aAAa;EACb,gBAAgB;CACjB;;AAED;;EAEE,YAAY;EACZ,kBAAkB;CACnB;;AAED;;EAEE,iCAAiC;CAClC","file":"styles.css","sourcesContent":[".formField {\r\n  composes: formField from '../../../sharedStyles/modalForms.css';\r\n  min-width: 0px;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex: 1 0;\r\n      flex: 1 0;\r\n  height: 30px;\r\n  border: #dddddd;\r\n}\r\n\r\n.formColumn .formField input,\r\n.formColumn .formField select {\r\n  width: 100%;\r\n  padding: 0px 10px;\r\n}\r\n\r\n.formColumn .formField input:focus,\r\n.formColumn .formField select:focus {\r\n  border-color: #5db6ff !important;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formField": "formField___2re0G " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/modalForms.css").locals["formField"] + "",
	"formColumn": "formColumn___EjLaD"
};