import itemUpsert from './itemUpsert';

const concatUpsert = (baseList, incomingList, idField = 'Id') => {
  let newBaseList = baseList;
  incomingList.forEach(item => {
    newBaseList = itemUpsert(newBaseList, item, idField);
  });
  return newBaseList;
};

export default concatUpsert;
