import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconEditField = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 37.46 36.49"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>{props.title && props.translate(props.title)}</title>
      <path
        fill={props.fill}
        d="M23.06,1.75H3.86A3.31,3.31,0,0,0,.63,5.12V32.44a3.31,3.31,0,0,0,3.23,3.38H30a3.31,3.31,0,0,0,3.23-3.38V13.06H28.68v16.6a1.45,1.45,0,0,1-1.41,1.48H6.46a1.37,1.37,0,0,1-1.33-1.4V7.83A1.35,1.35,0,0,1,6.44,6.45H23.07Z"
      />
      <polygon
        fill={props.fill}
        points="32.11 0.24 35.13 3.41 20.44 18.79 17.41 15.62 32.11 0.24"
      />
    </svg>
  );
};

IconEditField.defaultProps = {
  className: null,
  width: '36px',
  height: '37px',
  fill: ACTIVE_COLOR_DEFAULT,
  showTitle: false,
  title: '',
};

export default withLocalize(IconEditField);
