exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".locationSubscriptionExpiredOrSuspended___fMzj- {\r\n    -ms-flex-pack: center;\r\n        justify-content: center;\r\n    color: #b30490;\r\n    font-weight: bold;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -ms-flex-align: center;\r\n        align-items: center;\r\n    -ms-flex-line-pack: center;\r\n        align-content: center;\r\n  }\r\n  \r\n  .hidden___1D9Az {\r\n    display: none;\r\n  }", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/SubscriptionNotice/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;QAClB,wBAAwB;IAC5B,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,uBAAuB;QACnB,oBAAoB;IACxB,2BAA2B;QACvB,sBAAsB;GAC3B;;EAED;IACE,cAAc;GACf","file":"styles.css","sourcesContent":[".locationSubscriptionExpiredOrSuspended {\r\n    -ms-flex-pack: center;\r\n        justify-content: center;\r\n    color: #b30490;\r\n    font-weight: bold;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -ms-flex-align: center;\r\n        align-items: center;\r\n    -ms-flex-line-pack: center;\r\n        align-content: center;\r\n  }\r\n  \r\n  .hidden {\r\n    display: none;\r\n  }"],"sourceRoot":""}]);

// exports
exports.locals = {
	"locationSubscriptionExpiredOrSuspended": "locationSubscriptionExpiredOrSuspended___fMzj-",
	"hidden": "hidden___1D9Az"
};