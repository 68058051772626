import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const highlightColor = '#FFFFFF';

const IconAlarmReviewed = props => {
  const { className, height, highlight, title, translate, width } = props;

  return (
    <div
      height={height}
      style={{ display: 'flex' }}
      title={translate(title)}
      width={width}
    >
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 33 33"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g
          fill={highlight ? highlightColor : ''}
          fillRule="evenodd"
          id="01-notifications-list"
          stroke="none"
          strokeWidth="1"
        >
          <g id="Alarms-List" transform="translate(-127.000000, -888.000000)">
            <g
              id="new-icon-reviewed-01"
              transform="translate(127.000000, 888.000000)"
            >
              <path
                d="M16.4782139,4.10719817 C9.64263056,4.10719817 4.10168611,9.59761101 4.10168611,16.3701798 C4.10168611,23.1427486 9.64263056,28.6331615 16.4782139,28.6331615 C23.3137972,28.6331615 28.8544361,23.1427486 28.8544361,16.3701798 C28.8544361,9.59761101 23.3137972,4.10719817 16.4782139,4.10719817 M32.9562139,16.3701798 C32.9562139,25.3873541 25.5788806,32.6973083 16.4782139,32.6973083 C7.37754722,32.6973083 -9.16666667e-05,25.3873541 -9.16666667e-05,16.3701798 C-9.16666667e-05,7.3530055 7.37754722,0.0433541284 16.4782139,0.0433541284 C25.5788806,0.0433541284 32.9562139,7.3530055 32.9562139,16.3701798"
                fill={highlight ? highlightColor : '#D0D2D3'}
                id="Fill-1"
              />
              <polygon
                fill={highlight ? highlightColor : '#6DBD44'}
                id="Fill-4"
                points="10.6143278 23.1056615 13.7068556 25.8431477 26.0665778 12.1357349 22.9743556 9.39824862"
              />
              <polygon
                fill={highlight ? highlightColor : '#6DBD44'}
                id="Fill-6"
                points="13.7067333 25.8431477 16.4695667 22.7789917 9.41581667 16.5347257 6.65298333 19.5988817"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconAlarmReviewed.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.bool,
  title: PropTypes.string,
  translate: PropTypes.func,
  width: PropTypes.string,
};

IconAlarmReviewed.defaultProps = {
  className: null,
  height: '25px',
  highlight: false,
  title: 'ALARMS.STATUS.REVIEWED_LABEL',
  translate: () => {},
  width: '25px',
};

export default withLocalize(IconAlarmReviewed);
