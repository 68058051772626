import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as toggleActions from 'actions/toggle';
import { toggleIconWrap, toggleWrap } from './styles.css';

const Toggle = ({
  actions,
  checked: isChecked,
  disabled,
  id,
  label,
  onChange,
  revertOn,
  shouldRevert,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked || undefined);
  }, [setChecked, isChecked]);

  useEffect(() => {
    if (shouldRevert) {
      actions.clearToggle(revertOn);
      setChecked(isChecked);
    }
  }, [actions, isChecked, revertOn, shouldRevert]);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(Boolean(newChecked), label, id);
  };

  return (
    <div
      className={toggleWrap}
      onClick={handleToggle}
      onKeyPress={handleToggle}
      role="button"
      tabIndex="0"
    >
      <div className={toggleIconWrap}>
        <msi-toggle-native
          checked={checked || undefined}
          disabled={disabled || undefined}
          id={id}
          text={label}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const shouldRevert =
    ownProps.revertOn &&
    state.toggle.actions.find(
      action => action.toggleAction === ownProps.revertOn,
    );
  return {
    shouldRevert: !!shouldRevert,
  };
};

const mapDispatchtoProps = dispatch => {
  return {
    actions: bindActionCreators({ ...toggleActions }, dispatch),
  };
};

Toggle.defaultProps = {
  checked: undefined,
  disabled: false,
  id: null,
  label: null,
  onChange: () => {},
  revertOn: null,
  shouldRevert: false,
};

Toggle.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  revertOn: PropTypes.string,
  shouldRevert: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(Toggle);
