import React from 'react';
import { SVG_XMLNS, NEW_WINDOW_BUTTON_COLOR } from '../constants/app';

const IconNewWindow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 126.1 124.6"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>new window button image</title>
      <path
        d="M99.1,124.1h-88c-6.1,0-11-5-11-11v-88c0-6,5-11,11-11h88c6,0,11,4.9,11,11v88 C110.1,119.2,105.1,124.1,99.1,124.1z M104.3,111.3V27c0-3.9-3.2-7-7-7H12.9c-3.9,0-7,3.1-7,7v84.4c0,3.8,3.1,7,7,7h84.4
	C101.1,118.3,104.3,115.2,104.3,111.3z M124.3,69.2l0-44.8c0-14.2-6.8-23.4-23.6-23.4H55.5c0,0,0.2,5.7,0.2,5.8h42.7 c15.7,0,20,7.4,20,20v42.3H124.3z M83.8,81.5l0-38l0,0l-4.1-4.1l0,0h-38v5.8H74L26.1,93.1l4.1,4.1l47.9-47.9v32.2L83.8,81.5z"
      />
    </svg>
  );
};

IconNewWindow.defaultProps = {
  className: null,
  width: '30px',
  height: '30px',
  fill: NEW_WINDOW_BUTTON_COLOR,
};

export default IconNewWindow;
