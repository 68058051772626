exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* Overflow content */\r\n\r\n.contentCardsContainer___3ukly {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.contentCard___2uh6Q {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 0px 16px;\r\n}\r\n\r\n.contentHeader___3Omv1 {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  line-height: 16px;\r\n}\r\n\r\n.contentData___1EnxI {\r\n  white-space: nowrap;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/OGTable/OverflowRow.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,qBAAqB;EACrB,cAAc;CACf;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;EAC3B,kBAAkB;CACnB;;AAED;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;CACnB;;AAED;EACE,oBAAoB;CACrB","file":"OverflowRow.css","sourcesContent":["/* Overflow content */\r\n\r\n.contentCardsContainer {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.contentCard {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 0px 16px;\r\n}\r\n\r\n.contentHeader {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  line-height: 16px;\r\n}\r\n\r\n.contentData {\r\n  white-space: nowrap;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"contentCardsContainer": "contentCardsContainer___3ukly",
	"contentCard": "contentCard___2uh6Q",
	"contentHeader": "contentHeader___3Omv1",
	"contentData": "contentData___1EnxI"
};