import React from 'react';
import {
  SVG_XMLNS,
  ICON_COLOR_ONLINE,
  ICON_COLOR_OFFLINE,
} from '../constants/app';

const IconDeviceConnected = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 15 10"
      version="1.1"
      xmlns={SVG_XMLNS}
      width={props.width}
      height={props.height}
      title={props.title}
    >
      <defs />
      <g
        id="notification-center"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-767.000000, -883.000000)"
      >
        <g id="Base" transform="translate(-1.000000, -108.000000)">
          <g
            id="server-icon-green"
            transform="translate(768.000000, 991.000000)"
          >
            <g id="Combined-Shape">
              <rect
                id="path-1"
                x="0"
                y="0"
                width="15"
                height="10"
                fill={props.fill}
              />
              <path
                d="M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 C6.55228475,4 7,4.44771525 7,5 C7,5.55228475 6.55228475,6 6,6 Z M9,6 C8.44771525,6 8,5.55228475 8,5 C8,4.44771525 8.44771525,4 9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 Z M12,6 C11.4477153,6 11,5.55228475 11,5 C11,4.44771525 11.4477153,4 12,4 C12.5522847,4 13,4.44771525 13,5 C13,5.55228475 12.5522847,6 12,6 Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconDeviceConnected.defaultProps = {
  width: '15px',
  height: '10px',
  fill: ICON_COLOR_ONLINE,
  title: 'Connection status',
};

export default IconDeviceConnected;
