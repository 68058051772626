import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { CANCEL_ICON_ID } from '../constants/ElementId';
import { SVG_XMLNS } from '../constants/app';

const IconCancelPtzMode = props => {
  return (
    <div title={props.translate(props.title)} id={CANCEL_ICON_ID}>
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path
            id="ac"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path id="dc" d="M0 0h24v24H0z" />
          <filter
            x="-29.2%"
            y="-20.8%"
            width="158.3%"
            height="158.3%"
            filterUnits="objectBoundingBox"
            id="cc"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g
          transform="translate(-5 0)"
          fillRule="evenodd"
          fill={props.outerFill}
        >
          <path d="M0 0h24v24H0z" />
          <mask id="bc" fill="#fff">
            <use xlinkHref="#ac" />
          </mask>
          <g mask="url(#bc)">
            <use fill="#000" filter="url(#cc)" xlinkHref="#dc" />
            <use fill={props.fill} xlinkHref="#dc" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconCancelPtzMode.defaultProps = {
  className: null,
  title: 'PTZ.PRESETS.CANCEL',
  width: '20px',
  height: '20px',
  fill: '#FFF',
  outerFill: 'none',
};

export default withLocalize(IconCancelPtzMode);
