import React from 'react';

import {
  footer,
  languageDropdownContainer,
  footerSelectWrapper,
} from 'sharedStyles/styles.css';
import { LanguageSelect } from 'containers';
import { LANGUAGES } from 'constants/app';
import { Translate } from 'react-localize-redux';

// Constants
const COPYRIGHT_SYMBOL = '©';
const COMPANY_NAME = 'Avigilon Corporation';

const Footer = props => (
  <div className={`${footer} ${props.className}`}>
    <div>
      <span>{COPYRIGHT_SYMBOL}</span>
      {` ${new Date().getFullYear()} ${COMPANY_NAME}.`}
    </div>

    {LANGUAGES.length > 1 && (
      <div className={`${languageDropdownContainer} ${footerSelectWrapper}`}>
        <Translate id="PROFILE.LABEL_LANGUAGES" />
        <LanguageSelect applyOnChange />
      </div>
    )}
  </div>
);
export default Footer;
