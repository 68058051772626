import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';

const IconSearch = props => {
  return (
    <div height={props.height} width={props.width} title={props.title}>
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 28 28"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <title>{props.translate(props.title)}</title>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {props.showBorder ? (
            <g>
              <rect
                fill="#F7F7F7"
                fillRule="evenodd"
                x="0"
                y="0"
                width="28"
                height="28"
                rx="2"
              />
              <rect
                stroke="#CBCBCB"
                strokeWidth="1"
                x="0.5"
                y="0.5"
                width="27"
                height="27"
                rx="2"
              />
            </g>
          ) : null}
          <path
            fill={props.fill}
            fillRule="evenodd"
            d="M16.8776321,12.4659595 C16.8776321,10.0328539 14.8990651,8.05428695 12.4659595,8.05428695 C10.0328539,8.05428695 8.05428695,10.0328539 8.05428695,12.4659595 C8.05428695,14.8990651 10.0328539,16.8776321 12.4659595,16.8776321 C14.8990651,16.8776321 16.8776321,14.8990651 16.8776321,12.4659595 M21.9003166,20.4482879 L20.4474125,21.9005355 C20.3147932,22.0331548 20.0968247,22.0331548 19.9639865,21.9005355 L15.9608953,17.8963501 C14.9515876,18.5485047 13.7534177,18.9319191 12.4659595,18.9319191 C8.900556,18.9319191 6,16.0313631 6,12.4659595 C6,8.900556 8.900556,6 12.4659595,6 C16.0313631,6 18.9319191,8.900556 18.9319191,12.4659595 C18.9319191,13.7534177 18.5485047,14.9518065 17.8970066,15.9608953 L21.9003166,19.9642054 C22.0338114,20.0968247 22.0338114,20.3147932 21.9003166,20.4482879"
          />
        </g>
      </svg>
    </div>
  );
};

IconSearch.defaultProps = {
  className: null,
  width: '28px',
  height: '28px',
  fill: ACTIVE_COLOR_DEFAULT,
  showBorder: true,
  title: 'BUTTONS.SEARCH',
};

export default withLocalize(IconSearch);
