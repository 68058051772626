export default function actionMenuReducer(
  state = { isVisible: false },
  action,
) {
  switch (action.type) {
    case 'SHOW_MENU':
      return {
        isVisible: true,
        rowIndex: action.rowIndex,
        x: action.x,
        y: action.y,
        menuProps: action.menuProps,
      };

    case 'HIDE_MENU': {
      // reset row ix by default
      const ix = action.keepRowIndex ? state.rowIndex : -1;
      return { isVisible: false, rowIndex: ix, x: -1, y: -1 };
    }

    default:
      return state;
  }
}
