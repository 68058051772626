export const idGoBack = 'returnToList';

export const fieldMonitoring = 'monitoring';
export const fieldSubscription = 'subscription';
export const defaultTermLength = 3;
export const customerTableHeight = `calc(100vh - 360px)`;

// TODO: Add SAP ID and PO to searchable fields
export const textInputFields = ['Name', 'Address', 'City', 'Region'];

// Dashboard
export const UPGRADE_TABLE_HEADER_TRANSLATION_IDS = {
  NumberUsed: 'DEALER_DASHBOARD.CAMERA_COUNT_LABEL_UPGRADE_TABLE',
  ServicePackageName: 'DEALER_DASHBOARD.SUBSCRIPTION_PLAN_TABLE_LABEL',
  SiteName: 'COMMON.SITE',
  SubscriberName: 'DEALER_DASHBOARD.SUBSCRIBER_TABLE_LABEL',
};

export const UPGRADE_TABLE_HEADER_ORDER = [
  'SubscriberName',
  'SiteName',
  'ServicePackageName',
  'NumberUsed',
];

export const RENEWAL_TABLE_HEADER_TRANSLATION_IDS = {
  RenewalDate: 'DEALER_DASHBOARD.RENEWAL_DATE_TABLE_LABEL',
  ServicePackageName: 'DEALER_DASHBOARD.SUBSCRIPTION_PLAN_TABLE_LABEL',
  SiteName: 'COMMON.SITE',
  SubscriberName: 'DEALER_DASHBOARD.SUBSCRIBER_TABLE_LABEL',
};

export const RENEWAL_TABLE_HEADER_ORDER = [
  'SubscriberName',
  'SiteName',
  'ServicePackageName',
  'RenewalDate',
];

// Represents chart columns
// keys correspond to values in database
// same index = counts are aggregated into the same chart bar
export const packageChartConstants = {
  'BLU-C004-S030': {
    color: '#006e99',
    index: 1,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_MINI_LABEL',
  },
  'BLU-C008-S030': {
    color: '#5DB6FF',
    index: 2,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_SMALL_LABEL',
  },
  'BLU-C016-S030': {
    color: '#ADC7C1',
    index: 3,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_MEDIUM_LABEL',
  },
  'BLU-C032-S030': {
    color: '#CABEEE',
    index: 4,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_LARGE_LABEL',
  },
  'BLU-C048-S030': {
    color: '#8E7CC3',
    index: 5,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_XLARGE_LABEL',
  },
  'BLU-C080-S030': {
    color: '#FFD966',
    index: 6,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_XXLARGE_LABEL',
  },
  'BLU-C112-S030': {
    color: '#E56903',
    index: 7,
    labelTranslateKey: 'DEALER_DASHBOARD.PACKAGE_XXXLARGE_LABEL',
  },
};

export const dashboardIds = {
  SUBSCRIBER_STATS_ID: 'subscriberStatsCards',
  SUBSCRIPTION_LEVEL_CHART_ID: 'subscriptionLevelChart',
  RENEWAL_PERIODS_CHART_ID: 'renewalPeriodsChart',
  UPGRADE_OPPORTUNITIES_ID: 'upgradeOpportunitiesTable',
  DUE_FOR_RENEWAL_ID: 'dueForRenewalTable',
};

// translation keys

export const STAT_BOX_LABELS = {
  Subscribers: 'DEALER_DASHBOARD.SUBSCRIBERS_DASHBOARD_STAT_LABEL',
  Subscriptions: 'DEALER_DASHBOARD.SUBSCRIPTION_CHANGES_DASHBOARD_STAT_LABEL',
  Sites: 'DEALER_DASHBOARD.SITES_DASHBOARD_STAT_LABEL',
  MonitoredSites: 'DEALER_DASHBOARD.MONITORED_SITES_DASHBOARD_STAT_LABEL',
  Servers: 'DEALER_DASHBOARD.SERVERS_DASHBOARD_STAT_LABEL',
  Cameras: 'COMMON.CAMERAS',
};

export const DASHBOARD_LABELS = {
  subscriptionLevelHeader: 'DEALER_DASHBOARD.SUBSCRIPTION_PLANS_HEADER',
  upgradeOpportunitiesHeader: 'DEALER_DASHBOARD.UPGRADE_OPPORTUNITIES_HEADER',
  dueForRenewalHeader: 'DEALER_DASHBOARD.DUE_FOR_RENEWAL_HEADER',
  renewalPeriodsHeader: 'DEALER_DASHBOARD.RENEWAL_PERIODS_HEADER',
};
