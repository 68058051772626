import { TOGGLE_REVERT, TOGGLE_TRACK_ACTION } from 'constants/ActionTypes';

export function revertToggle(toggleAction) {
  return {
    toggleAction,
    type: TOGGLE_TRACK_ACTION,
  };
}

export function clearToggle(toggleAction) {
  return {
    toggleAction,
    type: TOGGLE_REVERT,
  };
}
