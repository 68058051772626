import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Moment from 'moment';

import { DateSelect, SelectableGrid } from 'components';
import { ScheduleHeaderContainer } from 'containers';

import {
  active,
  addButton,
  addLabel,
  centerContent,
  contentWrapper,
  dayRow,
  gridWrapper,
  header,
  headerNarrow,
  leftContent,
  spacer,
  spareColumn,
  subtractLabel,
} from './styles.css';

class ExceptionSchedule extends Component {
  addException = () => {
    const emptyRow = [];
    for (let i = 1; i <= 48; i += 1) {
      emptyRow.push(false);
    }
    const { gridMatrix, onGridUpdate } = this.props;
    const newMatrix = gridMatrix ? gridMatrix.slice() : [emptyRow.slice()];
    newMatrix.push(emptyRow);
    onGridUpdate(newMatrix);
  };

  deleteException = i => {
    const {
      exceptionDates,
      gridMatrix,
      onDatesUpdate,
      onGridUpdate,
    } = this.props;
    // Must remove DATE as well as GRID ROW
    const newMatrix = gridMatrix.slice(0, i).concat(gridMatrix.slice(i + 1));
    const newDateArray = exceptionDates
      .slice(0, i)
      .concat(exceptionDates.slice(i + 1));
    onDatesUpdate(newDateArray);
    onGridUpdate(newMatrix);
  };

  handleDateChange = (i, date) => {
    const { exceptionDates, onDatesUpdate } = this.props;
    const newDateArray = exceptionDates.slice();
    newDateArray[i] = Moment(date.format()).format('YYYY-MM-DD');
    onDatesUpdate(newDateArray);
  };

  addActive = i => {
    const { exceptionDates, gridMatrix } = this.props;
    return (
      gridMatrix[i] &&
      exceptionDates[i] &&
      gridMatrix.length === i + 1 &&
      gridMatrix[i].filter(item => item).length > 0
    );
  };

  deleteActive = () => {
    const { exceptionDates, gridMatrix } = this.props;
    return exceptionDates.length > 0 && gridMatrix.length > 0;
  };

  render() {
    const { exceptionDates, gridMatrix, onGridUpdate, timezone } = this.props;
    const matrix =
      gridMatrix && gridMatrix.length > 0 ? gridMatrix : [new Array(48)];
    const maxSelectDate = Moment();
    maxSelectDate.add(100, 'year');
    return (
      <div className={contentWrapper}>
        <div className={leftContent}>
          <span className={headerNarrow} />
          <span className={header} />
          <div className={spacer} />
          {matrix.map((key, i) => (
            <div key={key} className={dayRow}>
              <DateSelect
                initialValue={exceptionDates[i] && Moment(exceptionDates[i])}
                maxDate={maxSelectDate}
                onChange={date => this.handleDateChange(i, date)}
                shouldOverrideCSS={false}
                showCalendarIcon
                timezone={timezone}
              />
            </div>
          ))}
        </div>
        <div className={centerContent}>
          <ScheduleHeaderContainer />
          <SelectableGrid
            gridMatrix={gridMatrix}
            numColumns={48}
            onGridUpdate={onGridUpdate}
            outerClass={gridWrapper}
            rowClass={dayRow}
          />
        </div>
        <div className={spareColumn}>
          <span className={headerNarrow} />
          <span className={header} />
          <div className={spacer} />
          {matrix.map((key, i) => (
            <div key={key} className={dayRow}>
              <div
                className={`${addButton} ${this.deleteActive() && active}`}
                onClick={
                  this.deleteActive()
                    ? () => {
                      this.deleteException(i);
                    }
                    : () => {}
                }
              >
                <span className={subtractLabel}>-</span>
              </div>
              <div
                className={`${addButton} ${this.addActive(i) && active}`}
                onClick={
                  this.addActive(i)
                    ? () => {
                      this.addException();
                    }
                    : () => {}
                }
              >
                <span className={addLabel}>+</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

ExceptionSchedule.defaultProps = {
  exceptionDates: [],
  gridMatrix: null,
  onDatesUpdate: () => {},
  onGridUpdate: () => {},
};

ExceptionSchedule.propTypes = {
  exceptionDates: PropTypes.arrayOf(PropTypes.object),
  gridMatrix: PropTypes.arrayOf(PropTypes.number),
  onDatesUpdate: PropTypes.func,
  onGridUpdate: PropTypes.func,
};

export default ExceptionSchedule;
