/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

import * as styles from './OGTable.css';

import SortIndicator from './SortIndicator';

import { CELL_WIDTHS } from './constants';

const OGHeader = ({
  alignClass,
  bulkActions,
  cellWidths,
  customHeader,
  customHeaderClass,
  defaultSortField,
  hasActions,
  hasOverflow,
  headerClickCallback,
  headerLabels,
  nonSortingFields,
  scrollbarWidth,
  sortable,
  tableIsScrollable,
  visibleFieldOrder,
}) => {
  const [hoverField, setHoverField] = useState(null);
  const [localSortField, setLocalSortField] = useState(null);
  const [localSortDirection, setLocalSortDirection] = useState('ASC');

  useEffect(() => {
    if (defaultSortField && !localSortField) {
      setLocalSortField(defaultSortField);
    }
  }, [defaultSortField, localSortField]);

  const sortByField = field => {
    if (field !== localSortField) {
      setLocalSortField(field);
      setLocalSortDirection('ASC');
    } else if (localSortDirection === 'ASC') {
      setLocalSortDirection('DESC');
    } else {
      setLocalSortDirection('ASC');
    }
  };

  const onHeaderClick = field => {
    if (nonSortingFields.includes(field)) {
      return;
    }
    sortByField(field);
    if (sortable) {
      headerClickCallback(field);
    }
  };

  function handleMouseHoverEnter(field) {
    if (nonSortingFields.includes(field)) {
      return;
    }
    setHoverField(field);
  }

  function handleMouseHoverLeave() {
    setHoverField(null);
  }

  function headerText(fieldName) {
    // Sometimes header labels are validly blank
    if (
      headerLabels[fieldName] !== null &&
      headerLabels[fieldName] !== undefined
    ) {
      return headerLabels[fieldName];
    }
    return `Need Label for: ${fieldName}`;
  }

  return (
    <thead>
      <tr className={styles.ogHeaderRow}>
        {renderIf(bulkActions)(
          <th className={styles.ogHeaderCell} style={{ width: '20px' }} />,
        )}
        {visibleFieldOrder.map(field => {
          return (
            <th
              key={field}
              className={`${styles.ogHeaderCell} ${
                sortable && !nonSortingFields.includes(field)
                  ? styles.clickableHeader
                  : ''
              } ${field === localSortField ? styles.headerColor : null}`}
              onClick={() => onHeaderClick(field)}
              onMouseEnter={() => handleMouseHoverEnter(field)}
              onMouseLeave={handleMouseHoverLeave}
              width={cellWidths[field] || ''}
            >
              <div
                className={`${styles.ogHeaderCellContent} ${alignClass(
                  field,
                )} ${customHeaderClass[field] || ''}`}
              >
                {customHeader[field] ? (
                  customHeader[field]
                ) : (
                  <div className={alignClass(field)}>{headerText(field)}</div>
                )}
                {renderIf(hoverField && sortable)(
                  <SortIndicator
                    field={field}
                    sortingDirection={localSortDirection}
                    sortingField={hoverField}
                  />,
                )}
              </div>
            </th>
          );
        })}
        {renderIf(hasOverflow)(
          <th
            className={styles.ogHeaderCell}
            width={cellWidths.Overflow || CELL_WIDTHS.overflow}
          >
            <div className={styles.ogHeaderCellContent}></div>
          </th>,
        )}
        {renderIf(hasActions)(
          <th className={styles.ogHeaderCell} width={cellWidths.Actions || ''}>
            <div className={styles.ogHeaderCellContent}></div>
          </th>,
        )}
        {renderIf(tableIsScrollable)(
          <th
            style={{ background: '#f0f0f0', width: `${scrollbarWidth}px` }}
          />,
        )}
      </tr>
    </thead>
  );
};

OGHeader.defaultProps = {
  customHeader: null,
  customHeaderClass: null,
  defaultSortField: null,
  hasOverflow: false,
  headerLabels: {},
  scrollbarWidth: 0,
};

OGHeader.propTypes = {
  alignClass: PropTypes.func.isRequired,
  bulkActions: PropTypes.bool.isRequired,
  cellWidths: PropTypes.objectOf(PropTypes.any).isRequired,
  customHeader: PropTypes.objectOf(PropTypes.node),
  customHeaderClass: PropTypes.objectOf(PropTypes.node),
  defaultSortField: PropTypes.string,
  hasActions: PropTypes.bool.isRequired,
  hasOverflow: PropTypes.bool,
  headerClickCallback: PropTypes.func.isRequired,
  headerLabels: PropTypes.objectOf(PropTypes.string),
  nonSortingFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  scrollbarWidth: PropTypes.number,
  sortable: PropTypes.bool.isRequired,
  tableIsScrollable: PropTypes.bool.isRequired,
  visibleFieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OGHeader;
