import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconServer = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <path
        d="m4.315597,3.538126c-0.117187,0.058005 -0.272241,0.169527 -0.344439,0.247839c-0.185963,0.201558 -3.094056,4.398155 -3.223192,4.651323c-0.102883,0.201558 -0.106865,0.294344 -0.106417,2.468458l0.000505,2.259045l0.155278,0.27067c0.177381,0.309322 0.404463,0.501287 0.727977,0.615389c0.341185,0.120385 13.902357,0.119992 14.255716,-0.000393c0.319924,-0.108998 0.56765,-0.325983 0.751762,-0.658416l0.154773,-0.279421l0.015371,-2.055019c0.020307,-2.723196 0.211881,-2.163792 -1.668395,-4.871673c-1.706766,-2.457911 -1.736497,-2.496338 -2.05227,-2.649203c-0.338941,-0.164029 -8.334963,-0.162795 -8.666668,0.001403m9.179006,2.751469l1.080045,1.552442l-2.944613,0.013463c-1.619534,0.007405 -4.283716,0.007405 -5.92036,0l-2.975747,-0.013463l1.080045,-1.552442l1.080101,-1.552442l3.76027,0l3.76027,-0.000056l1.079989,1.552498m1.862268,4.709552l0,1.774251l-6.705613,0l-6.705556,0l0,-1.774251l0,-1.774251l6.705556,0l6.705613,0l0,1.774251m-4.829544,-0.813694c-0.369121,0.16072 -0.610172,0.652526 -0.500053,1.020077c0.301524,1.006444 1.728924,0.836077 1.728924,-0.206383c0,-0.639736 -0.646074,-1.067592 -1.228871,-0.813694m2.644099,0.01582c-0.47077,0.231964 -0.632443,0.879216 -0.325422,1.303089c0.414953,0.572755 1.192857,0.495789 1.524449,-0.150902c0.366092,-0.71384 -0.46589,-1.513341 -1.199028,-1.152186"
        fill={props.fill}
        fillRule="evenodd"
        id="svg_4"
      />
    </svg>
  );
};

IconServer.defaultProps = {
  width: '18px',
  height: '18px',
  fill: ACTIVE_COLOR_DEFAULT,
};

export default IconServer;
