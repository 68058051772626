import imgCamera from 'images/icon-camera-sm.png';
import imgBuilding from 'images/icon-building-sm.png';
import imgScrollLeft from 'images/img-alarm-btn-left.png';
import imgScrollRight from 'images/img-alarm-btn-right.png';
import imgAlarmResolvedOn from 'images/btn-alarm-resolved-modal-on.png';
import imgAlarmResolvedOff from 'images/btn-alarm-resolved-modal-off.png';
import imgAlarmFalseOn from 'images/btn-alarm-false-modal-on.png';
import imgAlarmFalseOff from 'images/btn-alarm-false-modal-off.png';

export const images = {
  imgAlarmFalseOff,
  imgAlarmFalseOn,
  imgAlarmResolvedOff,
  imgAlarmResolvedOn,
  imgBuilding,
  imgCamera,
  imgScrollLeft,
  imgScrollRight,
};

// BackEnd types
export const unreviewed = 'Unreviewed';
export const inReview = 'In-Review';
export const dismissed = 'Reviewed';
export const recordedId = 'Recorded';
export const liveLabel = 'Live';
export const justNow = 'Just now';
export const falseAlarm = 'FalseAlarm';
export const expired = 'Expired';
export const notAvailable = 'NotAvailable';
export const uploadFailed = 'UploadFailed';
export const uploadPending = 'UploadPending';
export const uploadSucceeded = 'UploadSucceeded';
export const fullEventOnly = 'FullEventOnly';

export const PLAYER_WIDTH_MIN = 390;
export const PLAYER_WIDTH_MAX = 775;
export const HORIZONTAL_PADDING = 40;
export const ACTIVITY_COLUMN_PADDING = 10; // Health alarm modal only
export const MODAL_HEADER_HEIGHT = 40;
export const HEADER_HEIGHT = 50;
export const COMMENT_HEIGHT = 80;
export const LOG_ROW_HEIGHT = 44;
export const VERTICAL_PADDING = 25;
export const LOG_HEIGHT = 212;
export const COMMENT_BOX_BUTTON = 32;
export const MAX_COMMENT_CHAR = 256;
export const clipVideo = 'clipVideo';
export const fullVideo = 'fullVideo';
export const liveVideo = 'liveVideo';
export const toggleVideoMode = 'toggleVideoMode';

export const FALSE_DETECTION_RADIO_OPTIONS = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
  {
    translateId: 'ALARMS.EDIT_MODAL.UNANSWERED',
    value: null,
  },
];

export const LOG_TABLE = {
  fieldOrder: ['timestamp', 'description'],
  headerTranslationIds: {
    description: 'COMMON.DESCRIPTION',
    timestamp: 'ALARMS.ACTION_LOG_HEADER_DATE_TIME',
  },
};

export const UNAVAILABLE_MESSAGE = {
  [expired]: 'VIDEO_PLAYER.CLIP_EXPIRED',
  [notAvailable]: 'VIDEO_PLAYER.CLIP_UNAVAILABLE',
  [uploadFailed]: 'VIDEO_PLAYER.CLIP_FAIL',
  [uploadPending]: 'VIDEO_PLAYER.CLIP_PENDING',
};
export const REVIEWED_RADIO_OPTIONS = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
];

export const columnDataFields = {
  fieldName: 'Name',
  fieldPhoneNo: 'PhoneNumber',
};

const FIELD_NAME = 'name';
const FIELD_PHONE = 'phone';

export const FIELD_ORDER = [FIELD_NAME, FIELD_PHONE];
