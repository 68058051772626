const logTimeStamp = () => {
  const now = new Date();
  const date = [now.getMonth() + 1, now.getDate(), now.getFullYear()];
  const time = [now.getHours(), now.getMinutes(), now.getSeconds()];

  // If seconds and minutes are less than 10, add a zero
  [1, 2].forEach(i => {
    if (time[i] < 10) {
      time[i] = `0${time[i]}`;
    }
  });

  let msec = now.getMilliseconds();
  while (msec.toString().length < 3) {
    msec = `0${msec}`;
  }

  // Return the formatted string
  return `${date.join('/')} ${time.join(':')}.${msec}`; // + " " + suffix;
};

export default logTimeStamp;
