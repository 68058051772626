import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
// import itemUpsert from '../util/itemUpsert';
// import generateSort from '../util/generateSort';

export default function dashboardReducer(state, action) {
  switch (action.type) {
    case types.RECEIVE_DEALER_BILLING_CYCLES: {
      return Object.assign({}, state, {
        isFetchingDealerBillingCycles: false,
        dealerBillingCycles: action.dealerBillingCycles,
      });
    }
    case types.SET_CURRENT_BILLING_CYCLE: {
      return Object.assign({}, state, {
        currentBillingCycleId: action.cycleId,
      });
    }

    case types.RECEIVE_DEALER_SUBSCRIBER_STATS: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriberStats: false,
        dealerSubscriberStats: action.dealerSubscriberStats,
      });
    }

    case types.RECEIVE_DEALER_SUBSCRIPTION_LEVELS: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriptionLevels: false,
        dealerSubscriptionLevels: action.subscriptionLevels,
      });
    }

    case types.RECEIVE_SUBSCRIPTION_RENEWAL_PERIODS: {
      return Object.assign({}, state, {
        isFetchingSubscriptionRenewalPeriods: false,
        subscriptionRenewalPeriods: action.subscriptionLevels,
      });
    }

    case types.RECEIVE_DEALER_SUBSCRIBER_UPGRADE_SITES: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriberUpgradeSites: false,
        dealerSubscriberUpgradeSites: action.dealerSubscriberUpgradeSites,
      });
    }

    case types.RECEIVE_DEALER_SUBSCRIBERS_NEARING_RENEWAL: {
      return Object.assign({}, state, {
        isFetchingDealerSubscribersNearingRenewal: false,
        dealerSubscribersNearingRenewal: action.dealerSubscribersNearingRenewal,
      });
    }

    case types.IS_FETCHING_DEALER_SUBSCRIBER_STATS: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriberStats: true,
      });
    }

    case types.IS_FETCHING_DEALER_SUBSCRIPTION_LEVELS: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriptionLevels: action.value,
      });
    }

    case types.IS_FETCHING_SUBSCRIPTION_RENEWAL_PERIODS: {
      return Object.assign({}, state, {
        isFetchingSubscriptionRenewalPeriods: action.value,
      });
    }

    case types.IS_FETCHING_DEALER_SUBSCRIBER_UPGRADE_SITES: {
      return Object.assign({}, state, {
        isFetchingDealerSubscriberUpgradeSites: action.value,
      });
    }

    case types.IS_FETCHING_DEALER_SUBSCRIBERS_NEARING_RENEWAL: {
      return Object.assign({}, state, {
        isFetchingDealerSubscribersNearingRenewal: action.value,
      });
    }
    // clear cache when user logs out or changes selected org/customer
    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().dashboard;
    }

    default: {
      return state || initialState().dashboard;
    }
  }
}
