/* eslint-disable import/prefer-default-export */
import { LIST_NAV_TITLE_HEIGHT, SECONDRY_NAV_TABS_HEIGHT } from 'constants/app';

export const calculateVideoDimensions = (
  containerHeight,
  containerWidth,
  listNavHeight = LIST_NAV_TITLE_HEIGHT + SECONDRY_NAV_TABS_HEIGHT,
  horizontalPadding = 6,
  tabWidth = 400,
  verticalPadding = 25,
) => {
  // Never pass in negative numbers for height, width
  const playerHeight = Math.max(
    containerHeight - listNavHeight - verticalPadding,
    0,
  );
  const playerWidth = Math.max(
    containerWidth - tabWidth - horizontalPadding - 80,
    0,
  );
  return { playerHeight, playerWidth, tabWidth };
};
