import * as LanguageConstants from 'containers/Language/constants';

// Profile form Labels
export const PROFILE_LABELS = {
  LABEL_COMMUNICATION_LANGUAGE: 'PROFILE.LABEL_COMMUNICATION_LANGUAGE',
};

// Profile form fields
export const PROFILE_PROPS = [
  {
    inputId: 'FirstName',
    label: 'PROFILE.LABEL_FIRST_NAME',
    name: 'FirstName',
    type: 'text',
  },
  {
    inputId: 'LastName',
    label: 'PROFILE.LABEL_LAST_NAME',
    name: 'LastName',
    type: 'text',
  },
  {
    inputId: 'EmailAddress',
    label: 'COMMON.EMAIL',
    name: 'EmailAddress',
    type: 'text',
  },
  {
    inputId: 'PhoneNumber',
    label: 'PROFILE.LABEL_PHONE_NUMBER',
    name: 'PhoneNumber',
    type: 'text',
  },
];

// Password Reset fields
export const PASSWORD_PROPS = [
  {
    inputId: 'currentPassword',
    label: 'PROFILE.LABEL_CURRENT_PASSWORD',
    name: 'CurrentPassword',
    preventAutocomplete: true,
    showValidCheckmark: false,
    type: 'password',
    validationWindowVisible: false,
  },
  {
    inputId: 'password',
    label: 'PROFILE.LABEL_NEW_PASSWORD',
    name: 'NewPassword',
    preventAutocomplete: true,
    type: 'password',
  },
  {
    inputId: 'confirmPassword',
    isConfirmation: true,
    label: 'PROFILE.LABEL_CONFIRM_NEW_PASSWORD',
    name: 'ConfirmPassword',
    preventAutocomplete: true,
    type: 'password',
    validationWindowVisible: false,
  },
];

// Schedule/Access Table config
export const ACCESS_TABLE = {
  fieldOrder: ['Location', 'SecuritySchedule', 'HealthSchedule'],
  headerTranslationIds: {
    HealthSchedule: 'PROFILE.ACCESS_FIELDS.HEALTH_SCHEDULE_LABEL',
    Location: 'COMMON.SITE',
    SecuritySchedule: 'PROFILE.ACCESS_FIELDS.SECURITY_SCHEDULE_LABEL',
  },
};

// Redux Config

export const FORM_NAME = 'profileForm';
export const PASSWORD_FORM_NAME = 'passwordForm';

export const EMAIL_NOTIFICATION_FORM_NAME = 'EmailNotification';

export const FORM_FIELDS_LIST = [
  'FirstName',
  'LastName',
  'EmailAddress',
  'PhoneNumber',
  EMAIL_NOTIFICATION_FORM_NAME,
  LanguageConstants.LanguageSelectFieldName,
];

export const PASSWORD_FORM_FIELDS_LIST = [
  'NewPassword',
  'ConfirmPassword',
  'CurrentPassword',
];

// Profile Formats Options

export const COMPUTER_LOCALE = {
  name: 'PreferredDateFormat',
  options: [
    {
      label: 'COMMON.YES',
      value: 'NoPreference',
    },
    {
      label: 'COMMON.NO',
      value: 'UsePreferences',
    },
  ],
};

export const WEEK_BEGINS = {
  name: 'PreferredWeekStartDay',
  options: [
    {
      label: 'SCHEDULES.TABLE.WEEK_DAYS.SATURDAY_LABEL',
      value: 'Saturday',
    },
    {
      label: 'SCHEDULES.TABLE.WEEK_DAYS.SUNDAY_LABEL',
      value: 'Sunday',
    },
    {
      label: 'SCHEDULES.TABLE.WEEK_DAYS.MONDAY_LABEL',
      value: 'Monday',
    },
  ],
};

export const SHORT_DATE = {
  name: 'PreferredShortDateFormat',
  options: [
    {
      label: 'PROFILE.SHORT_DATE_OPTIONS.MM_DD_YY',
      value: 'MonthDayYear',
    },
    {
      label: 'PROFILE.SHORT_DATE_OPTIONS.YYYY_MM_DD',
      value: 'YearMonthDay',
    },
    {
      label: 'PROFILE.SHORT_DATE_OPTIONS.DD_MM_YYYY_DOT',
      value: 'DayMonthYearDotSeparator',
    },
    {
      label: 'PROFILE.SHORT_DATE_OPTIONS.DD_MM_YYYY_SLASH',
      value: 'DayMonthYearSlashSeparator',
    },
  ],
};

export const LONG_DATE = {
  name: 'PreferredLongDateFormat',
  options: [
    {
      label: 'PROFILE.LONG_DATE_OPTIONS.MONTH_DAY_YEAR',
      value: 'MonthDayYear',
    },
    {
      label: 'PROFILE.LONG_DATE_OPTIONS.DAY_MONTH_YEAR',
      value: 'DayMonthYear',
    },
    {
      label: 'PROFILE.LONG_DATE_OPTIONS.YEAR_MONTH_DAY',
      value: 'YearMonthDay',
    },
  ],
};

export const TIME = {
  name: 'PreferredTimeFormat',
  options: [
    {
      label: 'PROFILE.TIME_OPTIONS.AM_PM',
      value: 'TimeFormat12Hours',
    },
    {
      label: 'PROFILE.TIME_OPTIONS.24_HOUR',
      value: 'TimeFormat24Hours',
    },
  ],
};

export const DECIMAL_SEPARATOR = {
  name: 'PreferredDecimalsSeparator',
  options: [
    {
      label: 'PROFILE.DECIMAL_SEPARATOR_OPTIONS.COMMA',
      value: 'Comma',
    },
    {
      label: 'PROFILE.DECIMAL_SEPARATOR_OPTIONS.DOT',
      value: 'Dot',
    },
  ],
};

export const THOUSAND_SEPARATOR = {
  name: 'PreferredThousandsSeparator',
  options: [
    {
      label: 'PROFILE.THOUSAND_SEPARATOR_OPTIONS.APOSTROPHE',
      value: 'Apostrophe',
    },
    {
      label: 'PROFILE.THOUSAND_SEPARATOR_OPTIONS.COMMA',
      value: 'Comma',
    },
    {
      label: 'PROFILE.THOUSAND_SEPARATOR_OPTIONS.DOT',
      value: 'Dot',
    },
    {
      label: 'PROFILE.THOUSAND_SEPARATOR_OPTIONS.SPACE',
      value: 'Space',
    },
  ],
};

export const NO_PREFERENCE_FORMAT_DATA = {
  LocalizationPreference: {
    PreferredDateFormat: 'NoPreference',
    PreferredDecimalsSeparator: 'NoPreference',
    PreferredLongDateFormat: 'NoPreference',
    PreferredShortDateFormat: 'NoPreference',
    PreferredThousandsSeparator: 'NoPreference',
    PreferredTimeFormat: 'NoPreference',
    PreferredWeekStartDay: 'NoPreference',
  },
};
