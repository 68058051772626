/* eslint-disable no-nested-ternary */
/* ************************************************************************* */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/*                 AVOID MAKING CHANGES TO THIS FILE.                        */
/*    ONCE THE EVO PLAYER IS RETIRED, THIS FILE CAN BE DELETED FOREVER.      */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/* ************************************************************************* */

// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputRange from 'react-input-range';
import Moment from 'moment';
import MomentTimeZone from 'moment-timezone-all';
import Spinner from 'react-md-spinner';
import Modal from 'react-modal';
import { Translate } from 'react-localize-redux';

// Container
import {
  BookmarkForm,
  ModalContainer,
  PageMessage,
  PresetTourBoxContainer,
  PtzTourFormContainer,
} from 'containers';
import PrivacyZoneOverlayContainer from 'containers/OverlayContainers/PrivacyZoneOverlayContainer';

// Components
import { CameraVideoDownloadModal, Canvas } from 'components';
import { Button, NoStyleButton, PopupMenu } from 'lib';

import initializePlayer from 'util/initializePlayer';
import requestFullscreen from 'util/requestFullscreen';
import logTimeStamp from 'util/logTimeStamp';

// Actions
import {
  cameraAutofocusGateway,
  cameraZoom,
  downloadSnapshot,
  // TODO: MVAAS-5535 :Enable when parallel fetch mechanism gets a node
  // clearSnapshotSource,
  flagStreamUsed,
  receiveStreamData,
  videoExportGetStream,
  videoExportRequest,
} from 'actions/devices';

import {
  addPtzTour,
  cameraAutofocus,
  cameraPanTiltZoom,
  cameraPanTiltZoomGateway,
  deletePtzPreset,
  getPtzPresets,
  getPtzTours,
  goToHomePtzPreset,
  goToPtzPreset,
  setActivePtzFunction,
  startPtzTour,
  updatePtzTour,
} from 'actions/devices/ptz';
import { hideModal, showModal } from 'actions/modal';

// Styles
import {
  IconBack,
  IconBack10,
  IconDownload,
  IconFastForward,
  IconForward,
  IconFullscreen,
  IconGotoVideo,
  IconLiveVideo,
  IconMicrophone,
  IconPause,
  IconPlay,
  IconPtzMode,
  IconSettings,
  IconSnapshot,
  IconTogglePtzMode,
} from 'icons';
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  canvasTypes,
  DATABASE_DATETIME_EXTRACT_FORMAT,
  DATABASE_DATETIME_FORMAT,
  DATE_FORMAT_EVOPLAYER,
  DATETIME_FORMAT_WITH_ZONE,
  STREAM_TYPES,
} from 'constants/app';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import dataURLToBlob from 'util/dataURLToBlob';
import { getCameraTimezoneOffset } from 'util/convertTimeTo';
import * as tourConsts from 'components/PtzTourForm/constants';

// Functions
import { cameraSupportsPtz } from 'util/cameraSettingPermission';
import WebRtcHelper, { webRtcResponseHandler } from 'util/WebRtcHelper';
import * as playbackConsts from './constants';
import {
  buttonGroup,
  buttonImage,
  buttonSeparator,
  buttonSubGroup,
  errorContainer,
  labelTime,
  overlaySpinner,
  placeholderSnapshot,
  playbackButtonsContainer,
  playerSegmentedButton,
  playerSegmentedButtonDisabled,
  playerSegmentedContainer,
  playerSlider,
  playerStatusMessage,
  timelineGroup,
  txtDuration,
  videoLabelClip,
  videoLabelPtzModeState,
  videoLabelPtzModeStateActive,
  videoLabelStream,
  videoMenuInvisibleSpace,
  videoMenusColumn,
  videoPlayerError,
  videoPlayerErrorBox,
  videoPlayerErrorMessage,
  videoPlayerStarted,
  videoPlayerWaiting,
  videoStreamStatusBox,
  videoStyle,
  videoTopBar,
} from './styles.css';
import PtzFunctionMenu from './PtzFunctionMenu';
import DateTimeTicker from './DateTimeTicker';
import VideoScrubber from './VideoScrubber';
import SettingsPopup from './SettingsPopup';
import PlayerDigitalOutBox from './PlayerDigitalOutBox';
import PlayerStatusBox from './PlayerStatusBox';

const {
  EVO_INTERVAL_FAILURE_THRESHOLD,
  ID_BUTTON_RETURN_TO_LIVE,
  ID_BUTTON_SNAPSHOT,
  LOW,
  PTZ_FUNCTION_NAMES,
  SKIP_SECONDS,
  SKIP_SECONDS_RECORDED,
  arrRatesClip,
  arrRatesFullEvent,
  eventTypes,
} = playbackConsts;

// Images
const imgPoster0 = require('images/avigilon-logo-small-wide.png');
const imgPoster90 = require('images/logo_avigilon_small_wide_270.png');
const imgPoster180 = require('images/logo_avigilon_small_wide_180.png');
const imgPoster270 = require('images/logo_avigilon_small_wide_90.png');

function PlayerManagerHOC(Player) {
  class PlayerWrapper extends Component {
    constructor(props) {
      super(props);
      const { LIST_PRESETS } = props;
      this.evoPlayer = null;
      this.state = {
        activePresetTourList: LIST_PRESETS,
        alwaysShowStatusBox: false, // status msg should always be viz when player is loading, or onHover
        connectionError: null,
        currentTime: 0,
        duration: 10,
        editTourId: null,
        isEditingPtzTour: false,
        isPlayerLoading: false,
        isPlayerRetrying: false,
        isPtzModeEnabled: false,
        play: false,
        playbackRate: 1, // this has some redundancy?
        playerCurrentEventState: null,
        playerEvents: [],
        playerLoadStartTime: null,
        playerStarted: false,
        quality: LOW,
        retryCount: 0,
        scrubberTime: null,
        secondsElapsedSinceLastEvent: 0, // track possible evo connection failure; if no new event in X seconds, retry
        showControls: false,
        showScrubber: false,
        showStatus: false,
        statusFramesDropped: 0,
        statusThroughput: '0kb/sec',
        streamHeight: 0,
        streamWidth: 0,
        timestampPause: null,
        useDataChannel: true,
        valHeight: null,
        valWidth: null,
        videoSecondsElapsed: 0,
        xOffset: null,
        yOffset: null,
        // TODO: MVAAS-5535 :Enable when parallel fetch mechanism gets a node
        // snapShot: null,
      };
    }

    componentDidMount() {
      // TODO: MVAAS-5535 :Enable when parallel fetch mechanism gets a node
      // this.props.actions.clearSnapshotSource(this.props.cameraId);
      const { id, mediaParams, streamType, videoPoster } = this.props;
      if (streamType !== STREAM_TYPES.recorded && !this.evoPlayer) {
        if (mediaParams && mediaParams.streamName && !mediaParams.streamUsed) {
          const newVideoPoster = this.getPoster(this.props);
          this.initializeEvoPlayer(
            id,
            videoPoster || newVideoPoster,
            mediaParams,
          );
        } else {
          this.setIntervalRetry();
        }
      }
    }

    componentWillUnmount() {
      const { actions } = this.props;
      if (this.evoPlayer) {
        this.evoPlayer.registerMetadataHandler(() => {});
        this.evoPlayer.registerEventListener(() => {});
        this.evoPlayer.stop();
        this.evoPlayer = null;
      }
      this.clearInterval();
      this.clearIntervalRetry();
      actions.setActivePtzFunction(null);
      // TODO: MVAAS-5535 :Enable when parallel fetch mechanism gets a node
      // this.props.actions.clearSnapshotSource(this.props.cameraId);
    }

    getPoster = props => {
      const rotationValue = this.getRotationValue(props);
      let result;
      if (rotationValue === 0) {
        result = imgPoster0;
      } else if (rotationValue === 90) {
        result = imgPoster90;
      } else if (rotationValue === 180) {
        result = imgPoster180;
      } else if (rotationValue === 270) {
        result = imgPoster270;
      }
      return result;
    };

    getConnectionError = () => {
      const {
        camera,
        cameraSnapshot,
        connectionError: connectionErrorProp,
        deviceId,
        deviceOnline,
        isDisconnected,
        mediaParams,
        startTime,
      } = this.props;
      const { isPlayerLoading, streamEnd } = this.state;
      // The structure of a connectionError object:
      // connectionError = {
      //   hasConnectionError: bool,
      //   errorMessage: string,
      //   errorComponent: React component, #Optional, will override error message if present
      //   showButtons: bool, #Optional, will hide video controls if false
      // }
      if (isPlayerLoading) {
        // No errors while loading
        return {};
      }
      if (connectionErrorProp && connectionErrorProp.hasConnectionError) {
        return connectionErrorProp;
      }
      let connectionError = {
        hasConnectionError: true,
      };

      // TODO: On full Savitar support to blue and this will be removed
      // and possibly handled by savitar
      if (!camera.aftClient) {
        if (!deviceId || (deviceId && !deviceOnline)) {
          // Disconnected device
          connectionError.errorMessage = (
            <Translate id="VIDEO_PLAYER.DEVICE_OFFLINE_MESSAGE" />
          );
          connectionError.showButtons = false;
        } else if (streamEnd) {
          // Event received that stream has ended
          connectionError.errorComponent = this.renderErrorTranslated(
            'VIDEO_PLAYER.STREAM_ERROR_ENDED',
            'VIDEO_PLAYER.ACCESS_CAMERA_BUTTON',
            cameraSnapshot,
          );
        } else if (
          // No recorded video for this time
          mediaParams &&
          mediaParams.errorMessage
        ) {
          connectionError.errorComponent = this.renderError(
            mediaParams.errorMessage,
            'VIDEO_PLAYER.VIDEO_SEARCH_BUTTON',
            cameraSnapshot,
            () => {
              this.refreshMediaParams(startTime, true);
            },
          );
        } else if (
          // Disconnected camera trying to play live video
          isDisconnected &&
          !startTime
        ) {
          connectionError.errorComponent = this.renderDeletedCameraError();
        } else if (
          // Error getting mediaParams
          mediaParams &&
          mediaParams.isFetching === false &&
          !mediaParams.streamName
        ) {
          connectionError.errorMessage = (
            <Translate id="VIDEO_PLAYER.ERROR_CONNECTION_LIVESTREAMING" />
          );
        } else {
          connectionError = {};
        }
      } else {
        /* We don't handle Savitar's Errors. Savitar is responsible for that.
         */
        connectionError = {};
      }
      return connectionError;
    };

    setDuration = () => {
      const { id } = this.props;
      const { duration } = this.refs[id];
      this.setState({
        duration,
      });
    };

    setIntervalRetry() {
      this.clearIntervalRetry();
      this.setState({
        isPlayerRetrying: true,
      });
      this.retryVideoStream();
      this.intervalRetry = setInterval(this.retryVideoStream, 5000);
    }

    setInterval() {
      this.clearInterval();
      this.intervalVideo = setInterval(this.tickVideo, 1000);
    }

    setVideoQuality = value => {
      const { setQuality } = this.props;
      const { quality } = this.state;
      if (quality !== value) {
        this.setState({
          quality: value,
        });
        setQuality(value);
      }
    };

    setVideoSize(props) {
      const element = this.refs[props.id];
      const rotation = this.getRotationValue(props);

      if (element) {
        let streamHeight = element.videoHeight;
        let streamWidth = element.videoWidth;
        const propHeight = props.height;
        const propWidth = props.width;

        if (rotation === 90 || rotation === 270) {
          streamHeight = element.videoWidth;
          streamWidth = element.videoHeight;
        }

        // fit video within container, given aspect ratio of each
        if (propHeight && propWidth && streamHeight && streamWidth) {
          let height;
          let width;
          const aspectContainer = propWidth / propHeight;
          const aspectVideo = streamWidth / streamHeight;
          if (aspectContainer > aspectVideo) {
            height = propHeight;
            width = propHeight * aspectVideo;
          } else {
            width = propWidth;
            height = propWidth / aspectVideo;
          }

          this.setState({
            height,
            streamHeight,
            streamWidth,
            width,
          });
        }
      }
    }

    initializeEvoPlayer = (tagId, imgReady, mediaProps) => {
      const { actions } = this.props;
      if (mediaProps.streamName && mediaProps.room) {
        this.setState({
          playerEvents: [],
          playerLoadStartTime: new Moment(),
          valHeight: null,
          valWidth: null,
          xOffset: null,
          yOffset: null,
        });
        this.evoPlayer = initializePlayer(
          tagId,
          imgReady,
          mediaProps,
          webRtcResponseHandler,
        );
        if (this.evoPlayer) {
          this.evoPlayer.registerMetadataHandler(this.metadataHandler);
          this.evoPlayer.registerEventListener(this.evoEventHandler);
          this.evoPlayer.play();
          this.setState({
            isPlayerLoading: true,
          });
          actions.flagStreamUsed(
            {
              streamUsed: true,
            },
            mediaProps.streamName,
          );
        }
        this.setInterval();
        this.clearIntervalRetry();

        this.webRtcHelper = new WebRtcHelper(this.evoPlayer);
      }
    };

    metadataHandler = metadataParam => {
      const { actions, mediaParams, onStreamEnd } = this.props;
      avoLog(`[${logTimeStamp()}] Received metadata: ${metadataParam}`);

      const metadata = JSON.parse(metadataParam);
      let streamStartDateTime = '';

      if (
        metadata &&
        !metadata.mediaTracks &&
        metadata.headers &&
        metadata.headers.Range
      ) {
        streamStartDateTime = metadata.headers.Range.split('=')[1];
        this.setState(() => {
          const streamData = mediaParams;
          streamData.streamStartDate = streamStartDateTime;
          actions.receiveStreamData(streamData, mediaParams.streamName);
          return streamData;
        });
      }

      if (metadata.mediaTracks) {
        metadata.mediaTracks.forEach(track => {
          if (track.attributes && track.attributes['x-avg-params']) {
            const xParams = track.attributes['x-avg-params'];
            const paramsArray = xParams.split(';');
            const paramsObj = {};
            paramsArray.forEach(param => {
              const startPos = param.indexOf(' ');
              const splitPos = param.indexOf('=');
              paramsObj[
                param.substring(startPos + 1, splitPos)
              ] = param.substring(splitPos + 1);
            });

            const streamData = {
              rotation: parseInt(paramsObj.rotation, 10) || 0,
              valHeight:
                parseInt(paramsObj['source-height'], 10) ||
                parseInt(paramsObj.height, 10) ||
                0,
              valWidth:
                parseInt(paramsObj['source-width'], 10) ||
                parseInt(paramsObj.width, 10) ||
                0,
              xOffset: parseInt(paramsObj['origin-x'], 10) || 0,
              yOffset: parseInt(paramsObj['origin-y'], 10) || 0,
            };
            this.setState(oldState => {
              if (oldState.valHeight === null) {
                actions.receiveStreamData(streamData, mediaParams.streamName);
                return streamData;
              }
              return null;
            });
          }
        });
      }

      if (
        metadata.firstLine &&
        metadata.firstLine.method === 'PLAY_NOTIFY' &&
        metadata.headers &&
        metadata.headers['Notify-Reason'] === 'end-of-stream'
      ) {
        if (this.evoPlayer) {
          this.evoPlayer.pause(true);
        }
        onStreamEnd();
      }
    };

    evoEventHandler = (type, descParam) => {
      let desc = descParam;
      const { id } = this.props;
      const {
        isPlayerRetrying,
        playerCurrentEventState,
        playerEvents,
        playerLoadStartTime,
      } = this.state;
      const eventName = eventTypes[type];

      if (
        eventName !== eventTypes['rep-estthroughput'] &&
        eventName !== eventTypes['rep-frameloss']
      ) {
        avoLog(
          `[${logTimeStamp()}] Received event ${type}.  Description: ${desc}`,
        );
      }
      let timestamp = '';
      if (eventName) {
        switch (eventName) {
          case eventTypes['rep-estthroughput']: {
            // value is string such as "`123.456KB/second" or "0KB/second", should be displayed as '44kb/sec'
            const arrThroughput = desc
              .toLowerCase()
              .split('kb')[0]
              .split('.');
            if (arrThroughput && arrThroughput.length > 0) {
              const strThroughput = `${arrThroughput[0]}kb/sec`;
              this.setState({
                statusThroughput: strThroughput,
              });
            }
            break;
          }
          case eventTypes.chosencandtype: {
            // TODO: Remove when not needed anymore -- See MVAAS-4855
            avoLog('Local Candidate', desc.localCandidate);
            avoLog('Remote Candidate', desc.remoteCandidate);
            const remoteCand = desc.remoteCandidate;
            if (remoteCand != null) {
              const { type: newType } = remoteCand;
              const { ip } = remoteCand;
              this.setState({
                connectionType: newType,
              });
              this.setState({
                connectionIp: ip,
              });
              this.resetCounter();
            }
            desc = `local candidate: ${desc.localCandidate.ip}/${
              desc.localCandidate.type
            };
              remote candidate: ${desc.remoteCandidate.ip}/${
              desc.remoteCandidate.type
            }`;
            break;
          }
          case eventTypes.connectionfailedpeer:
          case eventTypes.joinedroom:
          case eventTypes.checkingpeer:
          case eventTypes.playerstarted: {
            this.resetCounter();
            break;
          }
          case eventTypes.streamrequestfail: {
            if (!isPlayerRetrying) {
              this.setIntervalRetry();
            }
            break;
          }
          case eventTypes.playerstopped: {
            if (playerCurrentEventState === eventName) {
              // 2 consecutive playerStopped messages; refresh media params
              // if possible, which will re-initialize player
              if (!isPlayerRetrying) {
                this.setIntervalRetry();
              }
            }
            break;
          }
          default: {
            break;
          }
        }
        // update frame counts
        let frames = 0;
        let framesDropped = 0;
        const element = this.refs[id];
        if (element) {
          frames = element.webkitDecodedFrameCount;
          framesDropped = element.webkitDroppedFrameCount;
        }
        const now = new Moment();
        let diffMinutes = 0;
        let strSeconds = '';
        let seconds;
        if (playerLoadStartTime) {
          diffMinutes = now.diff(playerLoadStartTime, 'minutes');
          // reestablish new conn if hasnt started after 1min
          const hasPlayerStarted = playerEvents.some(event =>
            event.includes(eventTypes.playerstarted),
          );
          if (diffMinutes > 1 && !hasPlayerStarted) {
            this.setState(
              prevState => ({
                retryCount: prevState.retryCount + 1,
              }),
              () => {
                if (!isPlayerRetrying) {
                  this.setIntervalRetry();
                }
              },
            );
            return;
          }
          const diffSeconds = now.diff(playerLoadStartTime, 'seconds');
          if (diffMinutes > 0) {
            seconds = Math.round((diffSeconds - diffMinutes * 60) / 10);
          } else {
            seconds = now.diff(playerLoadStartTime, 'seconds');
          }
          strSeconds = seconds >= 10 ? seconds : `0${seconds}`;
          const diffMs = now.diff(playerLoadStartTime, 'milliseconds');
          const ms = Math.round((diffMs - diffSeconds * 1000) / 10);
          timestamp =
            diffMinutes > 0
              ? `${diffMinutes}:${strSeconds}.${ms}`
              : `${seconds}.${ms}`;
        }
        const description = desc
          ? `${timestamp}: ${eventName}: ${desc}`
          : `${timestamp}: ${eventName}`;
        const descriptionArray =
          eventName === eventTypes['rep-estthroughput'] ||
          eventName === eventTypes['rep-frameloss']
            ? []
            : [description];

        const latestState =
          eventName === 'throughput' || eventName === 'frameloss'
            ? playerCurrentEventState
            : eventName;

        this.setState(prevState => ({
          playerCurrentEventState: latestState,
          playerEvents: prevState.playerEvents.concat(descriptionArray),
          statusFrames: frames,
          statusFramesDropped: framesDropped,
        }));
      }
    };

    tickVideo = () => {
      const { isPlayerRetrying } = this.state;
      this.setState(prevState => {
        const lastEvent = prevState.playerCurrentEventState;
        if (
          lastEvent === eventTypes.joinedroom ||
          lastEvent === eventTypes.checkingpeer ||
          lastEvent === eventTypes.chosencandtype ||
          lastEvent === eventTypes.connectionfailedpeer
        ) {
          if (
            prevState.secondsElapsedSinceLastEvent >
            EVO_INTERVAL_FAILURE_THRESHOLD
          ) {
            // player is stalled, restart
            if (!isPlayerRetrying) {
              this.setIntervalRetry();
            }
            return {
              retryCount: prevState.retryCount + 1,
            };
          }

          return {
            secondsElapsedSinceLastEvent:
              prevState.secondsElapsedSinceLastEvent + 1,
            videoSecondsElapsed: 0,
          };
        }
        if (prevState.playerStarted) {
          return {
            secondsElapsedSinceLastEvent: 0,
            videoSecondsElapsed: prevState.videoSecondsElapsed + 1,
          };
        }

        return null;
      });
    };

    handleFullscreen = () => {
      const { id } = this.props;
      const element = this.refs[id];
      requestFullscreen(element);
    };

    onVideoEnded = () => {
      this.setState({
        play: false,
      });
    };

    handlePlayPause = () => {
      const { id } = this.props;
      const { play, playbackRate } = this.state;
      const video = this.refs[id];
      if (!play) {
        if (this.evoPlayer) {
          // the current play button should return to live video
          // we will eventually add btn to resume from point in time when paused
          this.evoPlayer.pause(false);
          this.setInterval();
        } else if (video) {
          // allow video to be restarted from beginning if completed
          if (video.ended) {
            this.restartVideo();
          } else {
            video.playbackRate = playbackRate;
            video.play();
          }
          this.clearInterval();
        }
        this.setState({
          play: true,
        });
      } else {
        if (this.evoPlayer) {
          this.evoPlayer.pause(true);
          this.clearInterval();
        } else if (video) {
          video.pause();
        }
        this.setState({
          play: false,
        });
      }
    };

    handleSkip = seconds => {
      const {
        id,
        mediaParams,
        startRecordedVideo,
        startTime,
        streamType,
      } = this.props;
      const { play, timestampPause } = this.state;
      const skipSeconds = typeof seconds === 'number' ? seconds : SKIP_SECONDS;
      const skipInterval = Math.abs(skipSeconds);
      const video = this.refs[id];
      const timeNow = video.currentTime;
      let newTime;
      if (streamType === STREAM_TYPES.recorded) {
        // Recorded clip can use built-in video functions
        if (video.ended) {
          this.restartVideo();
        } else {
          newTime = timeNow > skipSeconds ? timeNow + skipSeconds : 0;
          video.currentTime = newTime;
          if (newTime === 0) {
            this.restartVideo();
          }
        }
      } else {
        if (this.evoPlayer) {
          this.evoPlayer.stop();
        }
        // Evo needs to be sneakier
        let currentVideoTime;
        if (startTime) {
          currentVideoTime = Moment(startTime).add(timeNow, 'seconds');
          video.currentTime = 0;
        } else if (mediaParams && mediaParams.streamStartDate) {
          currentVideoTime = Moment(
            mediaParams.streamStartDate,
            DATE_FORMAT_EVOPLAYER,
          ).add(timeNow, 'seconds');
          video.currentTime = 0;
        } else if (play === false && timestampPause) {
          currentVideoTime = Moment(timestampPause);
        } else {
          currentVideoTime = Moment();
        }
        newTime =
          skipSeconds > 0
            ? currentVideoTime.add(skipInterval, 'seconds')
            : currentVideoTime.subtract(skipInterval, 'seconds');
        if (newTime.isBefore(Moment().subtract(skipInterval / 2, 'seconds'))) {
          startRecordedVideo(newTime.format());
        } else {
          this.returnToLiveVideo(); // Return to livestream
        }
      }
    };

    returnToLiveVideo = () => {
      const { returnToLiveVideo } = this.props;
      this.setState({ scrubberTime: Moment() }, returnToLiveVideo);
    };

    onScrub = (_slider, value) => {
      const { id } = this.props;
      // Clip scrubber
      const video = this.refs[id];
      this.setState({
        currentTime: value,
      });
      video.currentTime = value;
    };

    handleScrubberDrag = scrubberTime => {
      const { play } = this.state;
      // Timeline scrubber
      if (play) {
        this.handlePlayPause();
      } else {
        this.setState({ scrubberTime });
        // ScrubberTime will be set to null in handleLoaded
      }
    };

    handleScrubberDrop = newTime => {
      const { startRecordedVideo } = this.props;
      if (
        newTime.isBefore(
          Moment().subtract(Math.abs(SKIP_SECONDS / 2), 'seconds'),
        )
      ) {
        startRecordedVideo(newTime.utc().format(DATABASE_DATETIME_FORMAT));
      } else {
        this.returnToLiveVideo(); // Return to livestream if dragged close to end of recording
      }
    };

    downloadVideoClip = () => {
      const { id } = this.props;
      const src = this.refs[id].currentSrc;
      window.open(src, '_blank');
    };

    downloadVideoLive = () => {
      this.toggleModal();
    };

    setPlaybackRate = rate => {
      const { id, streamType } = this.props;
      if (streamType === STREAM_TYPES.recorded) {
        this.refs[id].playbackRate = rate;
        this.setState({
          playbackRate: rate,
        });
      }
      // Not available for evo yet
    };

    syncCurrentTime = () => {
      const { id } = this.props;
      const { currentTime } = this.refs[id];
      this.setState({
        currentTime,
      });
    };

    formatTime = value => {
      if (!Number.isFinite(value)) {
        return null;
      }
      const seconds = Math.round(value);
      const currentSeconds = Moment.duration(seconds, 'seconds').seconds();
      const currentMinutes = Moment.duration(seconds, 'seconds').minutes();
      const currentHours = Moment.duration(seconds, 'seconds').hours();
      const currentTime = `${(currentHours ? `${currentHours}:` : '') +
        currentMinutes}:${currentSeconds < 10 ? '0' : ''}${currentSeconds}`;
      return currentTime;
    };

    downloadCameraSnapshot = () => {
      const { id } = this.props;
      const { streamHeightState, streamWidthState } = this.state;
      // doesnt work for clips yet
      if (this.evoPlayer) {
        const rotation = this.getRotationValue(this.props);
        const canvas = document.createElement('canvas');
        canvas.setAttribute('width', streamWidthState);
        canvas.setAttribute('height', streamHeightState);
        document.body.appendChild(canvas);

        const ctx = canvas.getContext('2d');
        const video = this.refs[id];
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotation * Math.PI) / 180);

        let { streamHeight } = this.state;
        let { streamWidth } = this.state;

        if (rotation === 90 || rotation === 270) {
          streamHeight = streamWidthState;
          streamWidth = streamHeightState;
        }

        ctx.drawImage(video, -streamWidth / 2, -streamHeight / 2);

        const filename = `${Moment().format('YYYY-MM-DD')}.png`;
        // toDataUrl fails with tainted canvas
        const img = canvas.toDataURL('image/png');
        const blob = dataURLToBlob(img);
        const blobURL = (window.URL
          ? window.URL
          : window.webkitURL
        ).createObjectURL(blob);
        const linkDownload = document.createElement('a');
        linkDownload.href = blobURL;
        linkDownload.setAttribute('download', filename);
        linkDownload.setAttribute('target', '_blank');
        linkDownload.setAttribute('crossOrigin', 'Anonymous');
        document.body.appendChild(linkDownload);
        linkDownload.click();
        document.body.removeChild(linkDownload);
        document.body.removeChild(canvas);
      }
    };

    showControls = () => {
      const { cameraName, deviceName } = this.props;
      const { isPlayerLoading } = this.state;
      if (cameraName && deviceName) {
        this.setState({
          showStatus: true,
        });
      }
      if (!isPlayerLoading) {
        this.setState({
          showControls: true,
        });
      }
    };

    hideControls = () => {
      this.setState(prevState => ({
        showControls: false,
        showStatus: prevState.alwaysShowStatusBox,
      }));
    };

    toggleScrubber = newValue => {
      const { showScrubber } = this.state;
      this.setState({
        isPtzModeEnabled: false,
        showScrubber: newValue || !showScrubber,
      });
    };

    handleLoaded = () => {
      this.setState({
        isPlayerLoading: false,
        play: true,
        playerStarted: true,
        scrubberTime: null,
      });
      this.setVideoSize(this.props);
    };

    refreshMediaParams = (startTime = null, search = false) => {
      const { camera, getMedia } = this.props;
      getMedia(camera, startTime, search);
    };

    retryVideoStream = () => {
      const { startTime } = this.props;
      this.getConnectionError().hasConnectionError ||
        this.refreshMediaParams(startTime);
    };

    fullEventVideo = () => {
      const { getFullEventVideo } = this.props;
      getFullEventVideo();
    };

    clipVideo = () => {
      const { getClipVideo } = this.props;
      getClipVideo();
    };

    pinStatusBox = () => {
      this.setState(prevState => ({
        alwaysShowStatusBox: !prevState.alwaysShowStatusBox,
        showStatus: !prevState.alwaysShowStatusBox,
      }));
    };

    toggleModal = () => {
      const { actions, id, isVideoDownloadModalOpen } = this.props;
      isVideoDownloadModalOpen
        ? actions.hideModal()
        : actions.showModal(modalTypes.CAMERA_VIDEO_DOWNLOAD, {
            modalKey: id,
          });
    };

    togglePTZModal = () => {
      const { actions, isPtzTourModalOpen, videoId } = this.props;
      isPtzTourModalOpen
        ? this.hidePtzModal()
        : actions.showModal(modalTypes.PTZ_TOUR, {
            modalKey: videoId,
          });
    };

    toggleBookmarksModal = () => {
      const { actions, id, isBookmarksModalOpen } = this.props;
      isBookmarksModalOpen
        ? actions.hideModal()
        : actions.showModal(modalTypes.EDIT_BOOKMARK, {
            modalKey: id,
          });
    };

    hidePtzModal = () => {
      const { actions } = this.props;
      this.setState({ isEditingPtzTour: false }, () => {
        actions.hideModal();
      });
    };

    handleSubmitPtzForm = formData => {
      const { actions, camera, cameraRemoteId } = this.props;
      const tourId = Number.isNaN(formData.id)
        ? this.getNewTourId()
        : formData.id;

      const idleStartTime = !Number.isNaN(+formData.idleStartTime)
        ? +formData.idleStartTime
        : tourConsts.DEFAULT_IDLE_START_TIME;
      const pauseDurationMs = !Number.isNaN(+formData.pauseDurationMs)
        ? +formData.pauseDurationMs
        : tourConsts.DEFAULT_PAUSE_DURATION_MS;

      const presets = formData.presets || [];

      const augmentedData = {
        ...formData,
        id: tourId,
        idleStartTime,
        pauseDurationMs,
        presets,
      };

      Number.isNaN(formData.id)
        ? actions.addPtzTour(
            this.webRtcHelper,
            camera.Id,
            cameraRemoteId,
            augmentedData,
          )
        : actions.updatePtzTour(
            this.webRtcHelper,
            camera.Id,
            cameraRemoteId,
            augmentedData,
          );
      this.togglePTZModal();
    };

    getNewTourId = () => {
      const { ptzTours } = this.props;
      const { tours } = ptzTours;
      const { minId } = ptzTours.constraints;
      const { maxId } = ptzTours.constraints;

      if (tours.length === 0) {
        return minId;
      }

      const existingIds = tours.map(p => p.id);

      for (let i = minId; i <= maxId; i += 1) {
        if (!existingIds.includes(i)) return i;
      }

      return null;
    };

    switchPresetTourList = isClose => {
      const { LIST_PRESETS, LIST_TOURS } = this.props;
      const { activePresetTourList } = this.state;
      if (isClose) {
        this.setState({
          activePresetTourList: LIST_PRESETS,
        });
        return;
      }

      const currentList =
        activePresetTourList === LIST_PRESETS ? LIST_TOURS : LIST_PRESETS;

      this.setState({
        activePresetTourList: currentList,
      });
    };

    editPtzTour = tourData => {
      this.setState({ editTourId: tourData.id, isEditingPtzTour: true });
      this.togglePTZModal();
    };

    prepareVideoClip = values => {
      const { actions, cameraId, deviceId } = this.props;
      const { quality } = this.state;
      this.toggleModal();
      const startTime = values.startTime.clone();
      const { cameraDevice, cameraLocation } = this.props;
      const zone =
        (cameraLocation && cameraLocation.TimeZone) ||
        (cameraDevice && cameraDevice.TimeZone) ||
        '';
      const convertToUtc = MomentTimeZone.tz(
        startTime.format(DATETIME_FORMAT_WITH_ZONE),
        DATETIME_FORMAT_WITH_ZONE,
        zone,
      );
      const resultDate = convertToUtc.utc().format(DATABASE_DATETIME_FORMAT);
      const videoDownloadStartDateTime = resultDate;
      const duration = Moment()
        .hours(0)
        .minutes(0)
        .seconds(values.duration, 's')
        .format('HH:mm:ss');
      const downloadQuality =
        quality.toLowerCase() === 'low' ? 'LowResolution' : 'HighResolution';

      actions.videoExportRequest(
        deviceId,
        cameraId,
        {
          Duration: duration,
          RequestedQuality: downloadQuality,
          StartTime: videoDownloadStartDateTime,
        },
        cameraDevice.TenantId,
      );
    };

    getVideoDowloadFormInitialvalue = () => {
      const { id, mediaParams } = this.props;
      const { scrubberTime } = this.state;
      const startDateTime =
        scrubberTime || (mediaParams && mediaParams.streamStartDate);
      const video = this.refs[id];
      const timePlaying = (video && video.currentTime) || 0;
      const initialDateTime = startDateTime
        ? Moment(startDateTime, DATE_FORMAT_EVOPLAYER).add(
            timePlaying,
            'seconds',
          )
        : Moment();
      const initialValues = {
        initialDateTime,
      };
      return initialValues;
    };

    getRotationValue = nextProps => {
      return parseInt(
        (nextProps && nextProps.mediaParams && nextProps.mediaParams.rotation
          ? nextProps.mediaParams.rotation
          : 0) * 90,
        10,
      );
    };

    togglePtzMode = () => {
      const { handlePtzEnabledInFeed } = this.props;
      if (this.calculatePtzModeEnabled()) {
        this.setState({ isPtzModeEnabled: false });
        if (handlePtzEnabledInFeed) {
          handlePtzEnabledInFeed(undefined);
        }
      } else {
        this.setState({ isPtzModeEnabled: true, showScrubber: false });
        if (handlePtzEnabledInFeed) {
          handlePtzEnabledInFeed(this);
        }
      }
    };

    exitPtzMode = () => {
      this.setState({ isPtzModeEnabled: false });
    };

    shouldCanvasBeVisible = () => {
      const { showCanvas } = this.props;
      const { isPtzModeEnabled } = this.state;
      const returnValue = showCanvas && !isPtzModeEnabled;
      return returnValue;
    };

    calculatePtzModeEnabled = () => {
      const { enabledPtzFeed } = this.props;
      const { isPtzModeEnabled } = this.state;
      return isPtzModeEnabled && (!enabledPtzFeed || enabledPtzFeed === this);
    };

    shouldShowPtzFunctionMenu = () => {
      const { activePtzFunction, hidePtzFunctionMenu } = this.props;
      const { isPtzModeEnabled, play, showControls } = this.state;
      play &&
        isPtzModeEnabled &&
        !hidePtzFunctionMenu &&
        (showControls || activePtzFunction === PTZ_FUNCTION_NAMES.PAN_TILT);
    };

    toggleUseDataChannel = () => {
      const { useDataChannel } = this.state;
      this.setState({ useDataChannel: !useDataChannel });
    };

    restartVideo() {
      const { id } = this.props;
      const { playbackRate } = this.state;
      // todo:  cant restarted without reloading, but load() causes video to blink
      const video = this.refs[id];
      video.load();
      video.playbackRate = playbackRate;
    }

    clearInterval() {
      if (this.intervalVideo) {
        clearInterval(this.intervalVideo);
      }
    }

    clearIntervalRetry() {
      const { isPlayerRetrying } = this.state;
      if (this.intervalRetry) {
        clearInterval(this.intervalRetry);
      }
      if (isPlayerRetrying) {
        this.setState({
          isPlayerRetrying: false,
        });
      }
    }

    resetCounter() {
      this.setState({
        secondsElapsedSinceLastEvent: 0,
        videoSecondsElapsed: 0,
      });
    }

    renderDeletedCameraError = () => {
      const { cameraDevice, cameraLocation, streamType } = this.props;
      const { play } = this.state;
      return (
        <div className={videoPlayerError}>
          <div className={videoPlayerErrorBox}>
            <span className={videoPlayerErrorMessage}>
              <Translate id="VIDEO_PLAYER.ACCESS_CAMERA_ERROR" />
            </span>
            <DateTimeTicker
              cameraDevice={cameraDevice}
              cameraLocation={cameraLocation}
              customInput={
                <Button
                  key={playbackConsts.idButtonError}
                  id={playbackConsts.idButtonError}
                  inputType="button"
                  text={<Translate id="VIDEO_PLAYER.ACCESS_CAMERA_BUTTON" />}
                />
              }
              isPlayerPaused={!play}
              onDateTimeChange={this.handleScrubberDrop}
              startDateTime={Moment()}
              streamType={streamType}
            />
          </div>
        </div>
      );
    };

    renderError = (errorString, buttonStringId, cameraSnapshot, onClick) => (
      <div className={videoPlayerError}>
        {cameraSnapshot ? (
          <div
            className={placeholderSnapshot}
            style={{ backgroundImage: `url(${cameraSnapshot})` }}
          />
        ) : null}
        {onClick ? (
          <div
            className={videoPlayerErrorBox}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="0"
          >
            <span className={videoPlayerErrorMessage}>
              {errorString}
              <Button
                key={playbackConsts.idButtonError}
                id={playbackConsts.idButtonError}
                inputType="button"
                text={<Translate id={buttonStringId} />}
              />
            </span>
          </div>
        ) : null}
      </div>
    );

    renderErrorTranslated = (
      errorStringId,
      buttonStringId,
      cameraSnapshot,
      onClick,
    ) => (
      <div className={videoPlayerError}>
        {cameraSnapshot ? (
          <div
            className={placeholderSnapshot}
            style={{ backgroundImage: `url(${cameraSnapshot})` }}
          />
        ) : null}
        {onClick ? (
          <div
            className={videoPlayerErrorBox}
            onClick={onClick}
            onKeyPress={onClick}
            role="button"
            tabIndex="0"
          >
            <span className={videoPlayerErrorMessage}>
              <Translate id={errorStringId} />
              <Button
                key={playbackConsts.idButtonError}
                id={playbackConsts.idButtonError}
                inputType="button"
                text={<Translate id={buttonStringId} />}
              />
            </span>
          </div>
        ) : null}
      </div>
    );

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        height,
        id,
        mediaParams,
        syncedStartTime,
        videoPoster,
        width,
      } = this.props;
      if (nextProps.syncVideo) {
        if (
          nextProps.syncedStartTime !== syncedStartTime &&
          nextProps.syncedStartTime === null
        ) {
          // This should send us to live video.
          this.setIntervalRetry();
        } else if (
          nextProps.syncedStartTime &&
          nextProps.syncedStartTime !== syncedStartTime
        ) {
          // This should move to the synced start time
          this.refreshMediaParams(nextProps.syncedStartTime);
        }
      }
      if (
        nextProps.streamType !== STREAM_TYPES.recorded &&
        (!mediaParams ||
          !nextProps.mediaParams ||
          nextProps.mediaParams.streamName !== mediaParams.streamName)
      ) {
        if (this.evoPlayer) {
          this.evoPlayer.stop();
        }
        if (
          nextProps.mediaParams &&
          nextProps.mediaParams.streamName &&
          !nextProps.mediaParams.streamUsed
        ) {
          const newVideoPoster = this.getPoster(nextProps);
          this.initializeEvoPlayer(
            id,
            videoPoster || newVideoPoster,
            nextProps.mediaParams,
          );
          this.setState({
            showStatus: true,
          });
        }
      }
      // need to resize children when user resizes browser by changing layout
      if (
        nextProps.width !== width ||
        nextProps.height !== height ||
        (mediaParams &&
          nextProps.mediaParams &&
          mediaParams.rotation !== nextProps.mediaParams.rotation)
      ) {
        this.setVideoSize(nextProps);
      }

      if (nextProps.enabledPtzFeed && nextProps.enabledPtzFeed !== this) {
        this.setState({ isPtzModeEnabled: false });
      }
    }

    render() {
      const {
        actions,
        activePtzFunction,
        autofocus,
        camera,
        cameraDevice,
        cameraId,
        cameraLocation,
        cameraName,
        cameraRemoteId,
        cameraSupportsPtz: cameraSupportsPtzProp,
        canEditROI,
        canvasItems,
        canvasType,
        children,
        clipUri,
        controlsDisabled,
        deviceId,
        deviceName,
        getMedia,
        height,
        hideFocusButton,
        hideOverlayToggle,
        hidePtzArrow,
        id,
        isBookmarksModalOpen,
        isDisconnected,
        isPtzTourModalOpen,
        isVideoDownloadModalOpen,
        mediaParams,
        modalProps,
        ptzPresets,
        ptzTours,
        selectCanvasItem,
        setRulesOverlayVisibility,
        showReturnToLiveVideo,
        showRulesOverlay,
        showTalkdown,
        siteName,
        startTime,
        streamType,
        streamTypes,
        tenantId,
        updateCanvasItems,
        videoId,
        videoPoster: videoPosterProp,
        width,
      } = this.props;
      const {
        activePresetTourList,
        connectionError,
        connectionIp,
        connectionType,
        currentTime,
        duration,
        editTourId,
        height: heightState,
        isEditingPtzTour,
        isPlayerLoading,
        isPlayerRetrying,
        isPtzModeEnabled,
        play,
        playbackRate,
        playerCurrentEventState,
        playerEvents,
        playerStarted,
        quality,
        scrubberTime,
        showControls,
        showScrubber,
        showStatus,
        statusFrames,
        statusFramesDropped,
        statusThroughput,
        streamHeight,
        streamWidth,
        useDataChannel,
        valHeight,
        valWidth,
        videoSecondsElapsed,
        width: widthState,
        xOffset,
        yOffset,
      } = this.state;
      const rotation = this.getRotationValue(this.props);
      const videoPoster = this.getPoster(this.props);
      const videoWidth =
        rotation === 90 || rotation === 270 ? heightState : widthState;
      const videoHeight =
        rotation === 90 || rotation === 270 ? widthState : heightState;
      const cameraTimezoneUtcOffset = getCameraTimezoneOffset(
        cameraDevice,
        cameraLocation,
      );
      const style = {
        height: `${videoHeight}px`,
        transform: `rotate(${rotation}deg`,
        width: `${videoWidth}px`,
      };

      const liveVideoElement = (
        <div style={style}>
          <PtzFunctionMenu
            actions={actions}
            activePtzFunction={activePtzFunction}
            camera={camera}
            cameraResHeight={valHeight}
            cameraResWidth={valWidth}
            cameraSupportsPtz={cameraSupportsPtzProp}
            canEditROI={canEditROI}
            canvasList={canvasItems}
            deviceId={deviceId}
            evoPlayer={this.evoPlayer}
            exitPtzMode={this.exitPtzMode}
            frameHeight={heightState}
            frameWidth={widthState}
            handleEditPtzTour={this.editPtzTour}
            height={heightState}
            hidePtzArrow={hidePtzArrow}
            isPtzArrowEnabled={isPtzModeEnabled}
            isPtzModeEnabled={this.calculatePtzModeEnabled()}
            isVideoPlaying={play}
            onObjectSelected={selectCanvasItem}
            playerHeight={height}
            ptzPresets={ptzPresets}
            ptzTours={ptzTours}
            rotation={this.getRotationValue(this.props)}
            showMenu={this.shouldShowPtzFunctionMenu() || false}
            streamWidth={streamWidth}
            toggleUseDataChannel={this.toggleUseDataChannel}
            type={canvasType}
            updateCanvasList={updateCanvasItems}
            useDataChannel={useDataChannel}
            videoId={videoId}
            webRtcHelper={this.webRtcHelper}
            width={widthState}
            xOffset={xOffset}
            yOffset={yOffset}
          />
          <video
            ref={id}
            className={videoStyle}
            height={videoHeight}
            id={id}
            onLoadedData={this.handleLoaded}
            poster={videoPosterProp || videoPoster}
            type="video/mp4"
            width={videoWidth}
          />
        </div>
      );
      const recordedVideoElement = (
        <div style={style}>
          <video
            ref={id}
            autoPlay
            height={videoHeight}
            id={id}
            onDurationChange={this.setDuration}
            onEnded={this.onVideoEnded}
            onError={this.onError}
            onLoadedData={this.handleLoaded}
            onTimeUpdate={this.syncCurrentTime}
            poster={videoPosterProp || videoPoster}
            src={clipUri || null}
            type="video/mp4"
            width={videoWidth}
          />
        </div>
      );
      const playbackButtons = (
        <div>
          {showScrubber ? (
            <div
              className={`${buttonGroup} ${timelineGroup}`}
              id={playbackConsts.SCRUBBER_CONTROLS}
            >
              <VideoScrubber
                cameraDevice={cameraDevice}
                cameraDeviceId={deviceId}
                cameraId={cameraId}
                cameraLocation={cameraLocation}
                cameraRemoteId={cameraRemoteId}
                cameraTimezoneUnixOffset={3600000 * cameraTimezoneUtcOffset}
                initialTime={
                  mediaParams && mediaParams.streamStartDate
                    ? Moment(mediaParams.streamStartDate, DATE_FORMAT_EVOPLAYER)
                    : startTime
                    ? Moment(startTime, DATABASE_DATETIME_EXTRACT_FORMAT)
                    : undefined
                }
                isPlayerPaused={!play}
                onScrubberChange={this.handleScrubberDrag}
                onTimeChange={this.handleScrubberDrop}
                streamType={streamType}
                tenantId={tenantId}
                width={widthState}
              />
            </div>
          ) : null}
          <div
            className={`${buttonGroup} ${playbackButtonsContainer}`}
            id={playbackConsts.PLAYBACK_CONTROLS}
          >
            <Translate>
              {({ translate }) => (
                <>
                  <div className={buttonSubGroup}>
                    {streamType === STREAM_TYPES.recorded ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={playbackConsts.idBtnRwd}
                        onClick={this.handleSkip}
                        title={translate(
                          'VIDEO_PLAYER.SKIP_BACKWARD_RECORDED_LABEL',
                        )}
                      >
                        <IconBack10
                          className={buttonImage}
                          number={Math.abs(SKIP_SECONDS_RECORDED)}
                        />
                      </NoStyleButton>
                    ) : (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={playbackConsts.idBtnRwd}
                        onClick={this.handleSkip}
                        title={translate('VIDEO_PLAYER.SKIP_BACKWARD_LABEL')}
                      >
                        <IconBack
                          className={buttonImage}
                          number={Math.abs(SKIP_SECONDS)}
                        />
                      </NoStyleButton>
                    )}{' '}
                    {play ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id="idBtnPause"
                        onClick={this.handlePlayPause}
                        title={translate('VIDEO_PLAYER.PAUSE_LABEL')}
                      >
                        <IconPause className={buttonImage} width="20px" />
                      </NoStyleButton>
                    ) : (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={ID_BUTTON_SNAPSHOT}
                        onClick={this.handlePlayPause}
                        title={translate('VIDEO_PLAYER.PLAY_LABEL')}
                      >
                        <IconPlay className={buttonImage} width="20px" />
                      </NoStyleButton>
                    )}
                    {streamType === STREAM_TYPES.recorded ? (
                      <PopupMenu
                        buttonStyle={playerSegmentedButton}
                        buttonStyleDisabled={`${playerSegmentedButton} ${playerSegmentedButtonDisabled}`}
                        isEnabled={streamType === STREAM_TYPES.recorded}
                        items={
                          streamType === STREAM_TYPES.recorded
                            ? arrRatesClip
                            : arrRatesFullEvent
                        }
                        onSelect={this.setPlaybackRate}
                        selectedValue={playbackRate}
                      >
                        <IconFastForward className={buttonImage} />
                      </PopupMenu>
                    ) : null}
                    {streamType === STREAM_TYPES.playback ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={playbackConsts.idBtnFwd}
                        onClick={() => {
                          this.handleSkip(SKIP_SECONDS * -1);
                        }}
                        title={translate('VIDEO_PLAYER.SKIP_FORWARD_LABEL')}
                      >
                        <IconForward
                          className={buttonImage}
                          number={Math.abs(SKIP_SECONDS)}
                        />
                      </NoStyleButton>
                    ) : null}{' '}
                    {streamType === STREAM_TYPES.recorded ? (
                      <div className={playerSegmentedContainer}>
                        <div className={labelTime}>
                          {this.formatTime(currentTime)}
                        </div>
                        <button
                          className={`${playerSegmentedButton} ${playerSlider}`}
                          type="button"
                        >
                          <InputRange
                            formatLabel={() => ''}
                            maxValue={duration}
                            minValue={0}
                            onChange={this.onScrub}
                            size={5}
                            step={0.01}
                            value={currentTime}
                          />
                        </button>{' '}
                        <div className={labelTime}>
                          <div className={txtDuration}>
                            {this.formatTime(duration - currentTime)}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={playerSegmentedContainer}>
                        <div className={buttonSeparator} />
                        <div className={labelTime}>
                          <DateTimeTicker
                            cameraDevice={cameraDevice}
                            cameraLocation={cameraLocation}
                            isPlayerPaused={!play}
                            onDateTimeChange={this.handleScrubberDrop}
                            startDateTime={
                              scrubberTime ||
                              (mediaParams && mediaParams.streamStartDate)
                            }
                            streamType={streamType}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={buttonSubGroup}>
                    {streamType === STREAM_TYPES.playback &&
                    showReturnToLiveVideo ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={ID_BUTTON_RETURN_TO_LIVE}
                        onClick={this.returnToLiveVideo}
                        title={translate('VIDEO_PLAYER.LIVEVIDEO_ICON_LABEL')}
                      >
                        <IconLiveVideo className={buttonImage} />
                      </NoStyleButton>
                    ) : null}
                    {showTalkdown ? (
                      <NoStyleButton
                        className={playerSegmentedButtonDisabled}
                        id="idBtnMicrophone"
                        title={translate('VIDEO_PLAYER.TALKDOWN_LABEL')}
                      >
                        <IconMicrophone className={buttonImage} />
                      </NoStyleButton>
                    ) : null}
                    {play &&
                    cameraSupportsPtzProp &&
                    streamType === STREAM_TYPES.live ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id="togglePtzMode"
                        onClick={this.togglePtzMode}
                        title={translate('VIDEO_PLAYER.TOGGLE_PTZ_MODE_BUTTON')}
                      >
                        <IconTogglePtzMode
                          className={buttonImage}
                          isPtzModeEnabled={this.calculatePtzModeEnabled()}
                        />
                      </NoStyleButton>
                    ) : null}
                    {streamType !== STREAM_TYPES.recorded ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id="btnSearch"
                        onClick={this.toggleScrubber}
                        title={translate('VIDEO_PLAYER.VIDEO_SEARCH_BUTTON')}
                      >
                        <IconGotoVideo className={buttonImage} />
                      </NoStyleButton>
                    ) : null}
                    {streamType !== STREAM_TYPES.recorded ? (
                      <NoStyleButton
                        className={playerSegmentedButton}
                        id={ID_BUTTON_SNAPSHOT}
                        onClick={this.downloadCameraSnapshot}
                        title={translate('VIDEO_PLAYER.SNAPSHOT_LABEL')}
                      >
                        <IconSnapshot className={buttonImage} />
                      </NoStyleButton>
                    ) : null}
                    <NoStyleButton
                      className={playerSegmentedButton}
                      id="idBtnDownloadVideo"
                      onClick={
                        streamType !== STREAM_TYPES.recorded
                          ? this.downloadVideoLive
                          : this.downloadVideoClip
                      }
                      title={translate('VIDEO_PLAYER.DOWNLOAD_VIDEO_LABEL')}
                    >
                      <IconDownload className={buttonImage} />
                    </NoStyleButton>
                    {streamType !== STREAM_TYPES.recorded ? (
                      <SettingsPopup
                        autofocus={autofocus}
                        buttonStyle={playerSegmentedButton}
                        enableCalibration={streamType === STREAM_TYPES.live}
                        hideFocusButton={hideFocusButton}
                        hideOverlayToggle={hideOverlayToggle}
                        isEnabled={streamTypes !== STREAM_TYPES.recorded}
                        setRulesOverlayVisibility={setRulesOverlayVisibility}
                        setVideoQuality={this.setVideoQuality}
                        showRulesOverlay={showRulesOverlay}
                        title={translate('VIDEO_PLAYER.SETTINGS_LABEL')}
                        videoQuality={quality}
                      >
                        <IconSettings className={buttonImage} />
                      </SettingsPopup>
                    ) : null}{' '}
                    <NoStyleButton
                      className={playerSegmentedButton}
                      id="idBtnFullscreen"
                      onClick={this.handleFullscreen}
                      title={translate('VIDEO_PLAYER.FULLSCREEN_LABEL')}
                    >
                      <IconFullscreen
                        className={buttonImage}
                        height="20px"
                        width="20px"
                      />
                    </NoStyleButton>
                  </div>
                </>
              )}
            </Translate>
          </div>
        </div>
      );
      const playerStatus = (
        <PlayerStatusBox
          cameraName={cameraName}
          connectionIp={connectionIp}
          connectionType={connectionType}
          deviceName={deviceName}
          events={playerEvents}
          frames={statusFrames}
          framesDropped={statusFramesDropped}
          height={height}
          isDisconnected={isDisconnected}
          pinStatusBox={this.pinStatusBox}
          showStatusBox={showStatus}
          siteName={siteName}
          streamHeight={streamHeight}
          streamWidth={streamWidth}
          throughput={statusThroughput}
          videoElapsed={videoSecondsElapsed}
          width={width}
        />
      );

      const playerDigitalOut = (
        <PlayerDigitalOutBox
          cameraId={cameraId}
          cameraRemoteId={cameraRemoteId}
          cameraTenantId={tenantId}
          deviceId={deviceId}
          showDigitalOutBox={
            showControls && activePtzFunction !== PTZ_FUNCTION_NAMES.PAN_TILT
          }
        />
      );

      let initialEditTourData = {};
      if (isEditingPtzTour && !Number.isNaN(parseInt(editTourId, 10))) {
        initialEditTourData = ptzTours.tours.find(
          tour => tour.id === editTourId,
        );
        if (initialEditTourData) {
          initialEditTourData = {
            ...initialEditTourData,
            idleStartTime: initialEditTourData.idleStartTime,
            pauseDurationMs: initialEditTourData.pauseDurationMs,
          };
        }
      }

      // timestamp is hack to force componentWillReceiveProps to be invoked even when mediaParams dont change
      return (
        <div
          key={mediaParams ? mediaParams.timestamp : 0}
          className={playerStarted ? videoPlayerStarted : videoPlayerWaiting}
          onMouseEnter={this.showControls}
          onMouseLeave={this.hideControls}
          style={{
            height: `${height}px`,
            width: `${width}px`,
          }}
        >
          <div>
            {!controlsDisabled ? (
              <div className={videoTopBar}>
                <div className={videoMenusColumn}>{playerStatus}</div>
                {streamType === STREAM_TYPES.live ? (
                  <div className={videoMenusColumn}>{playerDigitalOut}</div>
                ) : null}
                <div
                  className={`${videoMenusColumn} ${videoMenuInvisibleSpace}`}
                />
                <div className={videoStreamStatusBox}>
                  <div
                    className={
                      streamType === STREAM_TYPES.live
                        ? videoLabelStream
                        : videoLabelClip
                    }
                  >
                    {streamType === STREAM_TYPES.live ? (
                      <Translate id="VIDEO_PLAYER.LIVE_LABEL" />
                    ) : (
                      <Translate id="VIDEO_PLAYER.RECORDED_LABEL" />
                    )}
                  </div>
                  {play &&
                  cameraSupportsPtzProp &&
                  streamType === STREAM_TYPES.live ? (
                    <div
                      className={
                        this.calculatePtzModeEnabled()
                          ? videoLabelPtzModeStateActive
                          : videoLabelPtzModeState
                      }
                      onClick={this.togglePtzMode}
                      onKeyPress={this.togglePtzMode}
                      role="button"
                      tabIndex="0"
                    >
                      <IconPtzMode className={buttonImage} />
                      <span>
                        <Translate
                          id={
                            this.calculatePtzModeEnabled()
                              ? 'VIDEO_PLAYER.PTZ_ON'
                              : 'VIDEO_PLAYER.PTZ_OFF'
                          }
                        />
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {activePtzFunction === PTZ_FUNCTION_NAMES.TOURS &&
            showControls &&
            this.shouldShowPtzFunctionMenu() ? (
              <PresetTourBoxContainer
                activeList={activePresetTourList}
                camera={camera}
                close={() => {
                  actions.setActivePtzFunction(null);
                  this.switchPresetTourList(true);
                }}
                deviceId={deviceId}
                frameHeight={heightState}
                handleEditPtzTour={this.editPtzTour}
                switchList={this.switchPresetTourList}
                videoId={videoId}
                webRtcHelper={this.webRtcHelper}
              />
            ) : null}
            <Player
              key={id}
              buttons={playbackButtons}
              connectionError={this.getConnectionError()}
              getMedia={getMedia}
              height={height}
              refreshMediaParams={this.refreshMediaParams} // corresponds to id of recorded or live videoElement
              returnToLiveVideo={this.refreshMediaParams}
              rotation={rotation}
              showControls={!controlsDisabled && showControls}
              showDigitalZoomControls={!this.calculatePtzModeEnabled()}
              startRecordedVideo={this.refreshMediaParams}
              streamHeight={streamHeight}
              streamWidth={streamWidth}
              videoId={id}
              width={width}
            >
              {streamType === STREAM_TYPES.recorded ||
              streamType === STREAM_TYPES.playback
                ? recordedVideoElement
                : liveVideoElement}
              {isPlayerLoading ? (
                <div className={overlaySpinner}>
                  <Spinner />
                  <div className={playerStatusMessage}>
                    {playerCurrentEventState}
                  </div>
                </div>
              ) : null}{' '}
              {isPlayerRetrying && !isPlayerLoading ? (
                <div className={overlaySpinner}>
                  <Spinner />
                  <div className={playerStatusMessage}>
                    <Translate id="VIDEO_PLAYER.CONNECTING_LABEL" />
                  </div>
                </div>
              ) : null}{' '}
              {playerStarted
                ? React.Children.map(children, child =>
                    React.cloneElement(child, {
                      height,
                      width,
                    }),
                  )
                : null}
              {this.shouldCanvasBeVisible() &&
              valHeight &&
              valWidth &&
              canvasItems &&
              canvasItems.length > 0 ? (
                canvasType === canvasTypes.privacy ? (
                  <PrivacyZoneOverlayContainer
                    cameraId={cameraId}
                    canEditROI={canEditROI}
                    screenSpaceHeight={heightState}
                    screenSpaceWidth={widthState}
                    streamDetails={{
                      cameraResHeight: valHeight,
                      cameraResWidth: valWidth,
                      rotation: this.getRotationValue(this.props),
                      xOffset,
                      yOffset,
                    }}
                  />
                ) : (
                  <Canvas
                    key="canvas"
                    ref="canvas"
                    cameraResHeight={valHeight}
                    cameraSupportsPtz={cameraSupportsPtzProp}
                    canEditROI={canEditROI}
                    canvasList={canvasItems}
                    height={heightState}
                    isPtzArrowEnabled={isPtzModeEnabled}
                    onObjectSelected={selectCanvasItem}
                    streamDetails={{
                      cameraResHeight: valHeight,
                      cameraResWidth: valWidth,
                      rotation: this.getRotationValue(this.props),
                      xOffset,
                      yOffset,
                    }}
                    type={canvasType}
                    updateCanvasList={updateCanvasItems}
                    width={widthState}
                    xOffset={xOffset}
                    yOffset={yOffset}
                  />
                )
              ) : null}
            </Player>
            {modalProps && modalProps.modalKey === id ? (
              <Modal
                className={modalContentContainer}
                contentLabel="VideoDownloadModal"
                isOpen={isVideoDownloadModalOpen}
                onRequestClose={this.toggleModal}
                overlayClassName={modalOverlay}
                shouldCloseOnOverlayClick={false}
              >
                <ModalContainer
                  handleCancel={this.toggleModal}
                  modalTitle={
                    <Translate id="VIDEO_PLAYER.DOWNLOAD_VIDEO_LABEL" />
                  }
                >
                  <CameraVideoDownloadModal
                    cameraDevice={cameraDevice}
                    cameraLocation={cameraLocation}
                    handleCancel={this.toggleModal}
                    initialValues={this.getVideoDowloadFormInitialvalue()}
                    onSubmit={this.prepareVideoClip}
                  />
                </ModalContainer>
              </Modal>
            ) : (
              ''
            )}
            <Modal
              className={modalContentContainer}
              contentLabel="BookmarksForm"
              isOpen={isBookmarksModalOpen}
              onRequestClose={this.toggleBookmarksModal}
              overlayClassName={modalOverlay}
              shouldCloseOnOverlayClick={false}
            >
              <ModalContainer
                handleCancel={this.toggleBookmarksModal}
                modalTitle={<Translate id="BOOKMARKS.EDIT_MODAL_TITLE" />}
              >
                <BookmarkForm />
              </ModalContainer>
            </Modal>
            <Modal
              className={modalContentContainer}
              contentLabel="PtzTourForm"
              isOpen={isPtzTourModalOpen}
              onRequestClose={this.togglePTZModal}
              overlayClassName={modalOverlay}
              shouldCloseOnOverlayClick={false}
            >
              <ModalContainer
                handleCancel={this.togglePTZModal}
                modalTitle={
                  <Translate
                    id={
                      isEditingPtzTour
                        ? 'PTZ.TOURS.EDIT_TOUR_LABEL'
                        : 'PTZ.TOURS.CREATE_MODAL_LABEL'
                    }
                  />
                }
              >
                <PtzTourFormContainer
                  cameraId={cameraId}
                  deviceId={deviceId}
                  handleCancel={this.togglePTZModal}
                  initialValues={isEditingPtzTour ? initialEditTourData : {}}
                  onSubmit={this.handleSubmitPtzForm}
                />
              </ModalContainer>
            </Modal>
            {/* props.connectionError displays failure to obtain mediaParams
                        state.connectionError displays failure of evoPlayer to connect */}

            {connectionError && connectionError.hasConnectionError ? (
              <div className={errorContainer}>
                <PageMessage
                  body={connectionError.errorMessage}
                  messageStyle={messageStyleStrings.error}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }

  PlayerWrapper.defaultProps = {
    LIST_PRESETS: 'ListPresets',
    LIST_TOURS: 'ListTours',
    activePtzFunction: '',
    autofocus: () => {},
    cameraDevice: undefined,
    cameraId: '',
    cameraLocation: {},
    cameraName: '',
    cameraRemoteId: '',
    cameraSnapshot: undefined,
    canEditROI: false,
    canvasItems: undefined,
    canvasType: undefined,
    children: null,
    clipUri: undefined,
    connectionError: {},
    controlsDisabled: false,
    deviceId: undefined,
    deviceName: '',
    enabledPtzFeed: false,
    getClipVideo: () => {},
    getFullEventVideo: () => {},
    getMedia: () => {},
    handlePtzEnabledInFeed: () => {},
    height: 0,
    hideFocusButton: false,
    hideOverlayToggle: false,
    hidePtzArrow: true,
    hidePtzFunctionMenu: true,
    isBookmarksModalOpen: false,
    isDisconnected: false,
    isPtzTourModalOpen: false,
    isVideoDownloadModalOpen: false,
    mediaParams: {},
    onStreamEnd: () => {},
    ptzPresets: null,
    ptzTours: null,
    returnToLiveVideo: () => {},
    selectCanvasItem: () => {},
    setQuality: () => {},
    setRulesOverlayVisibility: () => {},
    showCanvas: false,
    showReturnToLiveVideo: true,
    showRulesOverlay: () => {},
    showTalkdown: false,
    siteName: '',
    startRecordedVideo: () => {},
    startTime: '',
    streamTypes: '',
    syncedStartTime: undefined,
    tenantId: '',
    updateCanvasItems: () => {},
    videoId: null,
    videoPoster: null,
    width: 0,
  };

  PlayerWrapper.propTypes = {
    LIST_PRESETS: PropTypes.string,
    LIST_TOURS: PropTypes.string,
    actions: PropTypes.objectOf(PropTypes.any).isRequired,
    activePtzFunction: PropTypes.string,
    autofocus: PropTypes.func,
    camera: PropTypes.objectOf(PropTypes.any).isRequired,
    cameraDevice: PropTypes.objectOf(PropTypes.any),
    cameraId: PropTypes.string,
    cameraLocation: PropTypes.objectOf(PropTypes.any),
    cameraName: PropTypes.string,
    cameraRemoteId: PropTypes.string,
    cameraSnapshot: PropTypes.string,
    cameraSupportsPtz: PropTypes.bool.isRequired,
    canEditROI: PropTypes.bool,
    canvasItems: PropTypes.arrayOf(PropTypes.shape({})),
    canvasType: PropTypes.string,
    children: PropTypes.node,
    clipUri: PropTypes.string,
    connectionError: PropTypes.objectOf(PropTypes.any),
    controlsDisabled: PropTypes.bool,
    deviceId: PropTypes.number,
    deviceName: PropTypes.string,
    deviceOnline: PropTypes.bool.isRequired,
    enabledPtzFeed: PropTypes.bool,
    getClipVideo: PropTypes.func,
    getFullEventVideo: PropTypes.func,
    getMedia: PropTypes.func,
    handlePtzEnabledInFeed: PropTypes.func,
    height: PropTypes.number,
    hideFocusButton: PropTypes.bool,
    hideOverlayToggle: PropTypes.bool,
    hidePtzArrow: PropTypes.bool,
    hidePtzFunctionMenu: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isBookmarksModalOpen: PropTypes.bool,
    isDisconnected: PropTypes.bool,
    isPtzTourModalOpen: PropTypes.bool,
    isVideoDownloadModalOpen: PropTypes.bool,
    mediaParams: PropTypes.objectOf(PropTypes.any),
    modalProps: PropTypes.shape({}).isRequired,
    onStreamEnd: PropTypes.func,
    ptzPresets: PropTypes.objectOf(PropTypes.any),
    ptzTours: PropTypes.objectOf(PropTypes.any),
    returnToLiveVideo: PropTypes.func,
    selectCanvasItem: PropTypes.func,
    setQuality: PropTypes.func,
    setRulesOverlayVisibility: PropTypes.func,
    showCanvas: PropTypes.bool,
    showReturnToLiveVideo: PropTypes.bool,
    showRulesOverlay: PropTypes.func,
    showTalkdown: PropTypes.bool,
    siteName: PropTypes.string,
    startRecordedVideo: PropTypes.func,
    startTime: PropTypes.string,
    streamType: PropTypes.string.isRequired,
    streamTypes: PropTypes.string,
    syncedStartTime: PropTypes.string,
    tenantId: PropTypes.string,
    updateCanvasItems: PropTypes.func,
    videoId: PropTypes.string,
    videoPoster: PropTypes.objectOf(PropTypes.any),
    width: PropTypes.number,
  };

  PlayerWrapper.displayName = 'PlayerManagerHOC';

  function mapStateToProps(state, ownProps) {
    const videoPoster = null;
    let deviceOnline = false;
    let cameraDevice = null;
    let cameraLocation = null;
    const isFetchingSnapshots = false;
    let ptzPresets = null;
    let ptzTours = null;
    const { activePtzFunction } = state.devices;
    const controlsDisabled =
      ownProps.controlsDisabled ||
      activePtzFunction === PTZ_FUNCTION_NAMES.PAN_TILT;
    // If desired could also disable controls when PTZ controls are being used to draw a box & zoom to it
    let cameraDoesSupportPtz = false;
    if (ownProps.deviceId) {
      const device = state.devices.devices.find(
        d => d.Id === ownProps.deviceId,
      );
      deviceOnline = device && device.ConnectionState === CAMERA_CONNECTED;
      cameraDevice = device;
    }

    if (
      state.locations.locations &&
      state.locations.locations.length > 0 &&
      cameraDevice &&
      cameraDevice.SiteId
    ) {
      cameraLocation = state.locations.locations.find(
        x => x.Id === cameraDevice.SiteId,
      );
    }

    if (
      ownProps.camera &&
      state.devices.ptzTours &&
      state.devices.ptzTours[ownProps.camera.Id]
    ) {
      ptzPresets = state.devices.ptzPresets[ownProps.camera.Id].presets;
      ptzTours = state.devices.ptzTours[ownProps.camera.Id];
    }

    cameraDoesSupportPtz = cameraSupportsPtz(ownProps.camera, cameraDevice);

    return {
      activePtzFunction,
      cameraDevice,
      cameraLocation,
      cameraSupportsPtz: cameraDoesSupportPtz,
      controlsDisabled,
      deviceOnline,
      isBookmarksModalOpen: false,
      isFetchingSnapshots,
      isPtzTourModalOpen:
        state.modal.isOpen &&
        state.modal.modalType === modalTypes.PTZ_TOUR &&
        state.modal.modalProps &&
        state.modal.modalProps.modalKey === ownProps.videoId,
      isVideoDownloadModalOpen:
        state.modal.isOpen &&
        state.modal.modalType === modalTypes.CAMERA_VIDEO_DOWNLOAD,
      modalProps: state.modal.modalProps,
      ptzPresets,
      /* TODO: ENABLE WHEN BUTTON TO TRIGGER OPEN is IMPLEMENTED state.modal.isOpen &&
       * state.modal.modalType === modalTypes.EDIT_BOOKMARK,
       */
      ptzTours,
      videoPoster,
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
          addPtzTour,
          cameraAutofocus,
          cameraAutofocusGateway,
          cameraPanTiltZoom,
          cameraPanTiltZoomGateway,
          cameraZoom,
          deletePtzPreset,
          downloadSnapshot,
          flagStreamUsed,
          getPtzPresets,
          getPtzTours,
          goToHomePtzPreset,
          goToPtzPreset,
          hideModal,
          receiveStreamData,
          setActivePtzFunction,
          showModal,
          startPtzTour,
          updatePtzTour,
          videoExportGetStream,
          videoExportRequest,
          // TODO: MVAAS-5535 :Enable when parallel fetch mechanism gets a node
          // clearSnapshotSource,
        },
        dispatch,
      ),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PlayerWrapper);
}

export default PlayerManagerHOC;
