exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "/* Placeholder for possible custom styles needed.*/\r\n\r\n.disabledBorderStyle___3Rj5I > div:hover {\r\n  border: 1px solid #dddddd;\r\n}\r\n\r\n.disabledBorderStyle___3Rj5I > div > div > div {\r\n  color: #cccccc;\r\n}\r\n\r\n.timezoneSelectContainer___2jMKe {\r\n  width: 100%;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/TimeZoneSelect/styles.css"],"names":[],"mappings":"AAAA,mDAAmD;;AAEnD;EACE,0BAA0B;CAC3B;;AAED;EACE,eAAe;CAChB;;AAED;EACE,YAAY;CACb","file":"styles.css","sourcesContent":["/* Placeholder for possible custom styles needed.*/\r\n\r\n.disabledBorderStyle > div:hover {\r\n  border: 1px solid #dddddd;\r\n}\r\n\r\n.disabledBorderStyle > div > div > div {\r\n  color: #cccccc;\r\n}\r\n\r\n.timezoneSelectContainer {\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"disabledBorderStyle": "disabledBorderStyle___3Rj5I",
	"timezoneSelectContainer": "timezoneSelectContainer___2jMKe"
};