

// Utils
import urlBuilder from 'queryBuilder/url';
import {
  sendPostRequestReturningJSON,
  sendDeleteRequestReturningJSON,
} from 'util/fetchHelpers';

// Constants
import { UPDATE_LINKED_CAMERAS } from 'constants/ActionTypes';

export function updateDigitalOutLinks(
  deviceId,
  linksToUpdate,
  entityId,
  orgId,
) {
  return () => {
    const url = urlBuilder(UPDATE_LINKED_CAMERAS, deviceId, null, null, {
      orgId,
    });
    const linksToAdd = linksToUpdate.filter(cameraLink => cameraLink.isLinked);
    const linksToRemove = linksToUpdate.filter(
      cameraLink => !cameraLink.isLinked,
    );

    const addIds = linksToAdd.reduce((idsArray, cameraLink) => {
      idsArray.push(cameraLink.RemoteId);
      return idsArray;
    }, []);
    const addBody = {
      entityId,
      ids: addIds,
    };
    const linkAddPromise =
      linksToAdd.length > 0 &&
      sendPostRequestReturningJSON(url, addBody).catch(error => {
        avoLogError('Error adding digitial out link', error);
        return { error };
      });

    const removeIds = linksToRemove.reduce((idsArray, cameraLink) => {
      idsArray.push(cameraLink.RemoteId);
      return idsArray;
    }, []);
    const body = {
      entityId,
      ids: removeIds,
    };
    const linkRemovePromise =
      linksToRemove.length > 0 &&
      sendDeleteRequestReturningJSON(url, body).catch(error => {
        avoLogError('Error unlinking camera', error);
        return { error };
      });
    return Promise.all([linkAddPromise, linkRemovePromise]);
  };
}
