/* global avoLogError */

import * as types from 'constants/ActionTypes';
import urlBuilder from 'queryBuilder/url';
import {
  sendGetRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers.js';
import { alarmFilters, alarmTypes } from 'constants/alarmSettings';
import { isFetching } from './common';

// Redux action creators

export function selectAlarm(selectedAlarm) {
  return {
    selectedAlarm,
    type: types.ALARM_SELECTED,
  };
}

export function receiveAlarmClipUrl(data) {
  return {
    data,
    type: types.RECEIVE_ALARM_CLIP_URL,
  };
}

export function receiveAlarms(
  alarms,
  count,
  nextLink,
  alarmFetchDirection,
  alarmType,
) {
  return {
    alarmFetchDirection,
    alarmType,
    alarms,
    count,
    nextLink,
    type: types.RECEIVE_ALARMS,
  };
}

export function receiveAlarmsStats(stats) {
  return {
    stats,
    type: types.RECEIVE_ALARMS_STATS,
  };
}

export function populateAlarmFilters(alarms) {
  return {
    alarms,
    type: types.RECEIVE_ALARMS_FOR_FILTERS,
  };
}

export function setAlarmStatusFilter(filter) {
  return {
    filter,
    type: types.ALARMS_FILTER_STATUS,
  };
}

export function setAlarmRuleFilter(filter) {
  return {
    filter,
    type: types.ALARMS_FILTER_RULE,
  };
}

export function setAlarmCameraFilter(filter) {
  return {
    filter,
    type: types.ALARMS_FILTER_CAMERA,
  };
}

export function editAlarmStore(newAlarm, id) {
  return {
    id,
    newAlarm,
    type: types.EDIT_ALARM,
  };
}

function updateAlarmList(alarm) {
  return {
    alarm,
    type: types.ALARM_UPDATE,
  };
}

export function showModalEditAlarm() {
  return {
    type: types.ALARM_MODAL_SHOW,
  };
}

export function hideAlarmModal() {
  return {
    type: types.ALARM_MODAL_HIDE,
  };
}

// Clear Alarm
// + When filters are changed we do not need to maintain redux store alarms
// + We need to decide that time that redux store should have new empty state..
//   ..before receive new batch of result and start storing for infinite scrolling
export function clearAlarms(alarmType) {
  return {
    alarmType,
    type: types.CLEAR_ALARMS,
  };
}

// Clear SelectedAlarm
export function clearSelectedAlarm() {
  return {
    type: types.CLEAR_SELECTED_ALARM,
  };
}

export function setWidgetVisibility(widgetVisibility) {
  return {
    type: types.SET_WIDGET_VISIBILITY,
    widgetVisibility,
  };
}
// Async action creators

export function fetchAlarms(queryOptions) {
  const url = urlBuilder(types.GET_ALARMS, 0, 0, queryOptions);
  return sendGetRequestReturningJSON(url);
}

function fetchAlarmNextPage(alarmsNextLink) {
  const url = alarmsNextLink;
  return sendGetRequestReturningJSON(url);
}

export function getAlarms(queryOptions, alarmFetchDirection, alarmType) {
  let newQueryOptions = queryOptions;

  // apply filter to retrive alarms based on alarmType
  if (alarmFilters[alarmType]) {
    newQueryOptions = {
      ...queryOptions,
      filters: [...queryOptions.filters, alarmFilters[alarmType]],
    };
  }

  const fetchingType = !alarmType
    ? types.IS_FETCHING_ALARM_DATA
    : alarmType === alarmTypes.healthAlarms ? types.IS_FETCHING_HEALTH_ALARM_DATA : types.IS_FETCHING_SECURITY_ALARM_DATA;

  return dispatch => {
    dispatch(isFetching(fetchingType));
    dispatch(clearAlarms(alarmType));
    fetchAlarms(newQueryOptions)
      .then(json =>
        dispatch(
          receiveAlarms(
            json.value,
            json['@odata.count'],
            json['@odata.nextLink'] ? json['@odata.nextLink'] : null,
            alarmFetchDirection,
            alarmType,
          ),
        ),
      )
      .catch(ex => {
        dispatch(isFetching(fetchingType, false));
        avoLogError('Error fetching alarms', ex);
      });
  };
}

export function getAlarmsNextPage(
  alarmsNextLink,
  alarmFetchDirection,
  alarmType,
) {
  const fetchingType = !alarmType
    ? types.IS_FETCHING_ALARM_DATA
    : alarmType === alarmTypes.healthAlarms ? types.IS_FETCHING_HEALTH_ALARM_DATA : types.IS_FETCHING_SECURITY_ALARM_DATA;
  return dispatch => {
    dispatch(isFetching(fetchingType));
    fetchAlarmNextPage(alarmsNextLink)
      .then(json =>
        dispatch(
          receiveAlarms(
            json.value,
            json['@odata.count'],
            json['@odata.nextLink'] ? json['@odata.nextLink'] : null,
            alarmFetchDirection,
            alarmType,
          ),
        ),
      )
      .catch(ex => {
        dispatch(isFetching(fetchingType, false));
        avoLogError('Error getting alarms next page', ex);
      });
  };
}

export function getAlarm(alarmId, orgId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_SELECTED_ALARM));
    const url = urlBuilder(types.GET_ALARM, alarmId, null, null, { orgId });
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(selectAlarm(json)))
      .catch(ex => {
        dispatch(isFetching(types.COMPLETED_FETCHING_SELECTED_ALARM));
        avoLogError('Error fetching an alarm', ex);
      });
  };
}

export function getUpdatedAlarm(alarmId, orgId) {
  return dispatch => {
    const url = urlBuilder(types.GET_ALARM, alarmId, null, null, { orgId });
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(updateAlarmList(json)))
      .catch(ex => {
        avoLogError('Error fetching updated alarm', ex);
      });
  };
}

export function getAlarmsStats(queryOptions) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_ALARMS_STATS));
    const url = urlBuilder(types.GET_ALARMS_STATS, 0, 0, queryOptions);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveAlarmsStats(json)))
      .catch(ex => {
        dispatch(receiveAlarmsStats({ stats: {} }));
        // Set stats to empty on an error
        avoLogError('Error getting alarm stats', ex);
      });
  };
}
export function editAlarm(alarmData, id, orgId) {
  return () => {
    const body = { ...alarmData };
    const url = urlBuilder(types.EDIT_ALARM, id, null, null, { orgId });
    return sendPutRequestReturningJSON(url, body).catch(ex => {
      avoLogError('Error editing an alarm', ex);
    });
  };
}
