import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconSelectThumbnail = props => {
  return (
    <div
      title={props.title}
      style={{ height: props.height, width: props.width }}
    >
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 23 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <title>{props.title}</title>
        <defs>
          <filter
            x="-2.2%"
            y="-2.6%"
            width="104.3%"
            height="110.5%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feOffset
              dx="0"
              dy="1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feComposite
              in="shadowOffsetOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            />
          </filter>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g>
            <rect
              fill={props.on ? 'black' : 'none'}
              fillOpacity="1"
              filter="url(#filter-2)"
              x="0"
              y="0"
              width="23"
              height="19"
              rx="2"
            />
            <rect
              fill={props.on ? '#FBFBFB' : 'none'}
              fillRule="evenodd"
              x="0"
              y="0"
              width="23"
              height="19"
              rx="2"
            />
            <rect
              stroke={props.on ? '#CBCBCB' : 'none'}
              strokeWidth="1"
              x="0.5"
              y="0.5"
              width="22"
              height="18"
              rx="2"
            />
          </g>
          <g>
            <rect
              fill="#7C7C7C"
              fillRule="evenodd"
              x="4"
              y="4"
              width="15"
              height="11"
            />
            <rect
              stroke="#7C7C7C"
              strokeWidth="1"
              x="4.5"
              y="4.5"
              width="14"
              height="10"
            />
          </g>
          <circle id="Oval-4" fill="#FFFFFF" cx="7.5" cy="7.5" r="1.5" />
          <polygon
            id="Path-6"
            fill="#FFFFFF"
            points="6 13.9489746 10.6633733 9.28560128 13.2672444 11.8894724 18.13402 7.02269683 18.13402 13.9054258"
          />
        </g>
      </svg>
    </div>
  );
};

IconSelectThumbnail.defaultProps = {
  className: null,
  width: '23px',
  height: '20px',
  on: true,
  title: 'Select thumbnail view',
};

export default IconSelectThumbnail;
