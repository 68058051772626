import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Components
// TODO andre: put back FieldInput
import { Translate } from 'react-localize-redux';
import { Button, GroupLayout } from 'lib';
import { PageMessage } from 'containers';

// Styles
import {
  buttonGroup,
  buttonGroupCenter,
  formWrapper,
  horizontalDivider,
} from 'sharedStyles/modalForms.css';

import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  bulletPointsOfDoom,
  confirmationInput,
  deviceName,
  deviceNameWrapper,
  instructions,
} from './styles.css';

// Constants

class DeleteDeviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredDeviceName: '',
    };
  }

  handleChange = event => {
    this.setState({ enteredDeviceName: event.target.value });
  };

  render() {
    const { device, handleCancel, handleConfirm } = this.props;
    const { enteredDeviceName } = this.state;
    return (
      <div>
        <div className={formWrapper}>
          <div>
            <div className={deviceNameWrapper}>
              <Translate id="DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_WARNING_TEXT" />
              <span className={deviceName}>{device.Name}</span>
            </div>
            <ul className={bulletPointsOfDoom}>
              <li>
                <Translate id="DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_BULLET_POINTS_OF_DOOM.FIRST_POINT" />
              </li>
              <li>
                <Translate id="DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_BULLET_POINTS_OF_DOOM.SECOND_POINT" />
              </li>
            </ul>
            <div className={horizontalDivider} />
            <div className={instructions}>
              <Translate id="DEVICES.DEVICE_REMOVE.INSTRUCTIONS" />
            </div>
            <div className={confirmationInput}>
              <div>
                <Translate id="DEVICES.DEVICE_REMOVE.SERVER_NAME" />
              </div>
              <input
                onChange={this.handleChange.bind(this)}
                size="50"
                value={enteredDeviceName}
              />
            </div>
          </div>
          <div className={buttonGroup}>
            <GroupLayout additionalClasses={buttonGroupCenter}>
              <Button
                buttonType="primary"
                inputType="button"
                onClick={handleCancel}
                text={<Translate id="BUTTONS.CANCEL" />}
              />
              <Button
                buttonType="primary"
                disabled={enteredDeviceName !== device.Name}
                inputType="button"
                onClick={handleConfirm}
                text={<Translate id="BUTTONS.DELETE" />}
              />
            </GroupLayout>
          </div>
        </div>
      </div>
    );
  }
}

DeleteDeviceForm.defaultProps = {
  device: {},
};
DeleteDeviceForm.propTypes = {
  device: PropTypes.objectOf(PropTypes.any),
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default DeleteDeviceForm;
