import React from 'react';
import { SelectableGrid } from 'components';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { DAY_ORDER } from 'constants/dateTimeNumberFormats';
import {
  gridWrapper,
  recordingScheduleCell,
  scheduleRow,
  templateContentWrapper,
  templateHeader,
  templateHeaderItem,
  topContent,
} from './styles.css';

const RecordingScheduleSelectable = props => {
  const {
    camerasArrayLength,
    gridMatrix,
    groupId,
    recordingSchedulesGrid,
    selectedRecordingTemplate,
    startOfWeek,
    updateRecordingSchedule,
  } = props;

  const updateGrid = (grid, selectedKeys) => {
    if (!selectedRecordingTemplate) {
      return;
    }
    updateRecordingSchedule(
      groupId,
      selectedRecordingTemplate.id || selectedRecordingTemplate.tempId,
      recordingSchedulesGrid,
      selectedKeys,
      startOfWeek,
    );
  };

  return (
    <div className={templateContentWrapper}>
      <div className={topContent}>
        <span className={templateHeader}>
          {DAY_ORDER[startOfWeek].map(field => {
            return (
              <div key={field} className={templateHeaderItem}>
                <Translate id={`SCHEDULES.TABLE.WEEK_DAYS.${field}_LABEL`} />
              </div>
            );
          })}
        </span>
        <SelectableGrid
          auxData={recordingSchedulesGrid}
          cellClassName={recordingScheduleCell}
          gridMatrix={gridMatrix}
          numColumns={7}
          numRows={camerasArrayLength}
          onGridUpdate={updateGrid}
          outerClass={gridWrapper}
          rowClass={scheduleRow}
        />
      </div>
    </div>
  );
};

RecordingScheduleSelectable.propTypes = {
  camerasArrayLength: PropTypes.number.isRequired,
  gridMatrix: PropTypes.arrayOf(PropTypes.any),
  groupId: PropTypes.string.isRequired,
  recordingSchedulesGrid: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]),
  ).isRequired,
  selectedRecordingTemplate: PropTypes.objectOf(PropTypes.any),
  startOfWeek: PropTypes.number,
  updateRecordingSchedule: PropTypes.func.isRequired,
};

RecordingScheduleSelectable.defaultProps = {
  gridMatrix: null,
  selectedRecordingTemplate: null,
  startOfWeek: 0,
};

export default RecordingScheduleSelectable;
