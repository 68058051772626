import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// Components
import {
  DateSelect,
  DurationInput,
  LabeledBox,
  PlayerHost,
  RadioGroup,
  TimeSelect,
  VerticalFormFieldLayout,
} from 'components';
import { Button, GroupLayout } from 'lib';
import { SelectCamera } from 'cameraSelection';

// Utils
import { generateSort } from 'util/generateSort';

// Styles
import * as modalStyles from 'sharedStyles/modalForms.css';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import {
  DATABASE_DATETIME_EXTRACT_FORMAT,
  DATABASE_DATETIME_FORMAT,
} from 'constants/app';
import {
  CANCEL_BUTTON_ID,
  DELETE_BUTTON_ID,
  DESCRIPTION_INPUT_ID,
  NAME_INPUT_ID,
  NO_BUTTON_ID,
  SAVE_BUTTON_ID,
  YES_BUTTON_ID,
} from 'constants/ElementId';
import {
  cameraContent,
  playerWrapper,
} from '../BookmarkDetailsContainer/styles.css';
import * as styles from './styles.css';

// Constants
import * as consts from './constants';
import { TIME } from '../../../components/ProfileForm/constants';

const DateTimeSelect = ({
  initialValue,
  labelId,
  onChange,
  timezone,
  use12Hours,
}) => (
  <VerticalFormFieldLayout
    extraLabel={` (${moment.tz(timezone).zoneAbbr()})`}
    label={labelId}
  >
    <div className={styles.bookmarkFormTimeSelectDateInput}>
      <DateSelect
        initialTime={initialValue}
        initialValue={initialValue}
        onChange={onChange}
        showCalendarIcon
        timezone={timezone}
      />
    </div>
    <div className={styles.bookmarkFormTimeSelectTimeInput}>
      <TimeSelect
        initialDay={initialValue}
        onChange={onChange}
        showMilliSeconds
        timezone={timezone}
        use12Hours={use12Hours}
        value={initialValue}
      />
    </div>
  </VerticalFormFieldLayout>
);

DateTimeSelect.propTypes = {
  initialValue: PropTypes.objectOf(PropTypes.any).isRequired,
  labelId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  use12Hours: PropTypes.bool.isRequired,
};

class BookmarkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraIds: props.selectedCameraIds,
      description: props.initialValues.description,
      endTime: props.initialValues.endTime
        ? moment(props.initialValues.endTime, DATABASE_DATETIME_EXTRACT_FORMAT)
        : moment(),
      id: props.bookmarkId || null,
      isDeleteConfirm: false,
      isProtected: props.initialValues.isProtected,
      name: props.initialValues.name,
      selectedCameraId: null,
      startTime: props.initialValues.startTime
        ? moment(
            props.initialValues.startTime,
            DATABASE_DATETIME_EXTRACT_FORMAT,
          )
        : moment(),
    };
  }

  componentDidMount() {
    this.setState(({ cameraIds }) => {
      const { selectedCamIndices } = this;
      let selectedCameraId = null;
      if (selectedCamIndices && selectedCamIndices.length > 0) {
        const indicesToIds = this.selectedCamIndices
          .map((value, idx) => ({ id: cameraIds[idx], index: value }))
          .sort(generateSort(item => item.index));
        selectedCameraId = indicesToIds[0].id;
      } else {
        const { selectionList } = this.props;
        selectedCameraId =
          selectionList[0].cameras[0] && selectionList[0].cameras[0].RemoteId;
      }
      return { selectedCameraId };
    });
  }

  get formIsClean() {
    const { initialKeys, initialValues } = this.props;
    const valuesChanged = initialKeys.filter(key => {
      if (this.state[key] !== initialValues[key]) {
        return true;
      }
      return false;
    });
    return valuesChanged.length < 1;
  }

  get formIsValid() {
    const { cameraIds, endTime, name, startTime } = this.state;

    if (
      !cameraIds.length ||
      !startTime ||
      !endTime ||
      !name ||
      startTime > endTime
    ) {
      return true;
    }
    return false;
  }

  get selectedCamIndices() {
    const { selectionList } = this.props;
    const { cameraIds } = this.state;
    return (
      (selectionList[0].cameras &&
        cameraIds &&
        cameraIds
          .map(cId =>
            selectionList[0].cameras.findIndex(cam => cam.RemoteId === cId),
          )
          .filter(idx => idx >= 0)) ||
      []
    );
  }

  onSelectCamera = camera => {
    this.setState({ selectedCameraId: camera.RemoteId });
  };

  handleCameraChecked = selectedCameraIndices => {
    const { selectionList } = this.props;
    const actualCameras = selectionList[0].cameras;
    const cameraIds = selectedCameraIndices.map(selectedCameraIndex => {
      return (
        actualCameras[selectedCameraIndex] &&
        actualCameras[selectedCameraIndex].RemoteId
      );
    });

    this.setState({ cameraIds });
  };

  handleChangeStartTime = dateTime => {
    this.setState({ startTime: dateTime });
  };

  handleChangeEndTime = dateTime => {
    this.setState({ endTime: dateTime });
  };

  handleChangeDuration = duration => {
    this.setState(({ startTime }) => {
      const endTime = startTime.clone().add(duration, 'seconds');
      return { endTime };
    });
  };

  handleChangeName = evt => {
    const name = evt.target.value;
    this.setState({ name });
  };

  handleChangeDescription = evt => {
    const description = evt.target.value;
    this.setState({ description });
  };

  handleToggleProtected = () => {
    this.setState(({ isProtected }) => ({ isProtected: !isProtected }));
  };

  updateBookmark = () => {
    // Only export fields that were requested
    const { clusterId, initialKeys, onSubmit } = this.props;
    const cleanFormData = {};
    initialKeys.forEach(fieldName => {
      let fieldValue = this.state[fieldName];
      if (moment.isMoment(fieldValue)) {
        fieldValue = fieldValue.utc().format(DATABASE_DATETIME_FORMAT);
      }
      cleanFormData[fieldName] = fieldValue;
    });
    onSubmit(cleanFormData, clusterId);
  };

  toggleConfirmDelete = () => {
    this.setState(state => ({ isDeleteConfirm: !state.isDeleteConfirm }));
  };

  handleDelete = () => {
    const { bookmarkId, clusterId, onBackClick, onDelete } = this.props;
    onBackClick();
    onDelete(clusterId, bookmarkId);
  };

  render() {
    const { bookmarkId, onCancel, profile, selectionList } = this.props;
    const {
      cameraIds,
      description,
      endTime,
      id,
      isDeleteConfirm,
      isProtected,
      name,
      selectedCameraId,
      startTime,
    } = this.state;
    const camera =
      selectionList !== undefined &&
      selectionList[0].cameras.find(
        c => c.Id === selectedCameraId || c.RemoteId === selectedCameraId,
      );
    return (
      <div
        className={`${modalStyles.formWrapper} ${styles.bookmarkFormWrapper}`}
      >
        <div className={modalStyles.formBox}>
          <div className={modalStyles.formColumn}>
            <LabeledBox labelId="COMMON.CAMERAS">
              <div
                className={`${cameraContent} ${styles.cameraModalSelectWrap}`}
              >
                {selectionList !== undefined && (
                  <SelectCamera
                    isBookmarks={false}
                    onSelectedRowsChanged={this.handleCameraChecked}
                    preSelectedCameraIds={cameraIds}
                    selectCamera={this.onSelectCamera}
                    selectedCameraId={selectedCameraId}
                    selectedCamIndices={this.selectedCamIndices}
                    selectionList={selectionList}
                    showCheckBoxes
                    showCompactLayout
                    showFilter
                  />
                )}
              </div>
            </LabeledBox>
          </div>
          <div className={modalStyles.verticalDivider} />
          <div className={modalStyles.formColumn}>
            <LabeledBox labelId="BOOKMARKS.EDIT_FORM_LABEL">
              <div style={consts.MEDIA_PLAYER_PROPERTIES}>
                {!camera ? (
                  <div
                    className={playerWrapper}
                    style={consts.MEDIA_PLAYER_PROPERTIES}
                  />
                ) : (
                  <PlayerHost
                    key={`${camera.Id}_${bookmarkId}_${startTime.format()}`}
                    camera={camera}
                    cameraId={camera.Id}
                    cameraRemoteId={camera.RemoteId}
                    deviceId={camera.DeviceId}
                    height={consts.MEDIA_PLAYER_PROPERTIES.height}
                    hideBookmarkButton
                    id={`${camera.Id}_${bookmarkId}`}
                    isDisconnected={camera.ConnectionState !== CAMERA_CONNECTED}
                    paused
                    startTime={startTime.utc().format(DATABASE_DATETIME_FORMAT)}
                    width={consts.MEDIA_PLAYER_PROPERTIES.width}
                  />
                )}
              </div>
              <VerticalFormFieldLayout key="name" label="COMMON.NAME">
                <input
                  id={NAME_INPUT_ID}
                  maxLength={consts.MAX_CHARACTERS}
                  onChange={this.handleChangeName}
                  type="text"
                  value={name}
                />
              </VerticalFormFieldLayout>
              <VerticalFormFieldLayout
                key="description"
                label="COMMON.DESCRIPTION"
              >
                <textarea
                  id={DESCRIPTION_INPUT_ID}
                  onChange={this.handleChangeDescription}
                  style={{ resize: 'none' }}
                  value={description}
                />
              </VerticalFormFieldLayout>
              <div className={styles.bookmarkFormStartEndTimeWrapper}>
                <DateTimeSelect
                  key="startTime"
                  initialValue={startTime}
                  labelId="BOOKMARKS.FORM.START_TIME"
                  onChange={this.handleChangeStartTime}
                  timezone={profile.TimeZone}
                  use12Hours={
                    profile.LocalizationPreference.PreferredTimeFormat ===
                    TIME.options[0].value
                  }
                />
                <DateTimeSelect
                  key="endValue"
                  initialValue={endTime}
                  labelId="BOOKMARKS.FORM.END_TIME"
                  onChange={this.handleChangeEndTime}
                  timezone={profile.TimeZone}
                  use12Hours={
                    profile.LocalizationPreference.PreferredTimeFormat ===
                    TIME.options[0].value
                  }
                />
              </div>
              <VerticalFormFieldLayout label="BOOKMARKS.FORM.DURATION">
                <DurationInput
                  initialDuration={endTime.diff(startTime, 'seconds')}
                  maxDuration={consts.MAX_DURATION_SECONDS}
                  minDuration={consts.MIN_DURATION_SECONDS}
                  onChange={this.handleChangeDuration}
                />
              </VerticalFormFieldLayout>
              <VerticalFormFieldLayout showLabel={false}>
                <RadioGroup
                  labelTranslateId="BOOKMARKS.FORM.PROTECT_LABEL"
                  onChange={this.handleToggleProtected}
                  options={consts.PROTECTED_RADIO_OPTIONS}
                  value={isProtected}
                />
              </VerticalFormFieldLayout>
            </LabeledBox>
          </div>
        </div>
        {isDeleteConfirm ? (
          <GroupLayout
            horizontalPositioning="left"
            verticalCenter
            verticalSpacing="medium"
          >
            <div className={styles.deleteConfirmText}>
              <Translate id="BOOKMARKS.CONFIRM_DELETE.SINGLE_DELETE_MESSAGE" />
            </div>
            <Button
              key={YES_BUTTON_ID}
              buttonType="action"
              id={YES_BUTTON_ID}
              inputType="button"
              onClick={this.handleDelete}
            >
              <Translate id="COMMON.YES" />
            </Button>
            <Button
              key={NO_BUTTON_ID}
              buttonType="action"
              id={NO_BUTTON_ID}
              inputType="button"
              onClick={this.toggleConfirmDelete}
            >
              <Translate id="COMMON.NO" />
            </Button>
          </GroupLayout>
        ) : (
          <GroupLayout
            horizontalPositioning="center"
            separatorWidth="0"
            verticalCenter
            verticalSpacing="medium"
          >
            {id !== null ? (
              <div className={styles.bookmarkFormFooterWrapper}>
                <Button
                  key={DELETE_BUTTON_ID}
                  buttonType="secondary"
                  id={DELETE_BUTTON_ID}
                  inputType="button"
                  onClick={this.toggleConfirmDelete}
                  text={<Translate id="BUTTONS.DELETE" />}
                />
              </div>
            ) : (
              ''
            )}
            <GroupLayout
              additionalClasses={`${styles.bookmarkFormFooterWrapper} ${styles.bookmarkFormFooterButtonsLarge}`}
            >
              <Button
                key="bookmarkFormButtonCancel"
                buttonType="primary"
                disabled={this.formIsClean}
                id={CANCEL_BUTTON_ID}
                inputType="reset"
                onClick={onCancel}
                text={<Translate id="BUTTONS.CANCEL" />}
              />
              <Button
                key={SAVE_BUTTON_ID}
                disabled={this.formIsClean || this.formIsValid}
                id={SAVE_BUTTON_ID}
                inputType="button"
                onClick={this.updateBookmark}
                text={<Translate id="BUTTONS.SAVE" />}
              />
            </GroupLayout>
          </GroupLayout>
        )}
      </div>
    );
  }
}

BookmarkForm.propTypes = {
  bookmarkId: PropTypes.string,
  clusterId: PropTypes.string,
  initialKeys: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.objectOf(PropTypes.any),
  onBackClick: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  profile: PropTypes.shape({
    LocalizationPreference: PropTypes.object,
    TimeZone: PropTypes.string,
  }),
  selectedCameraIds: PropTypes.arrayOf(PropTypes.any),
  selectionList: PropTypes.arrayOf(PropTypes.object),
};

BookmarkForm.defaultProps = {
  bookmarkId: null,
  clusterId: null,
  initialKeys: [],
  initialValues: {},
  onBackClick: () => {},
  onCancel: () => {},
  onDelete: () => {},
  onSubmit: () => {},
  profile: { TimeZone: 'UTC' },
  selectedCameraIds: null,
  selectionList: undefined,
};

export default BookmarkForm;
