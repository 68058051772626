export const YesNo = 'YesNo';
export const OK = 'OK';
export const Save = 'Save';
export const DontSave = "Don't Save";
export const Delete = 'Delete';
export const Cancel = 'Cancel';
export const Clear = 'Revert';
export const Submit = 'Submit';
export const Close = 'Close';
export const Add = 'Add';
export const Decline = 'Decline';
export const Accept = 'Accept';
export const Download = 'Download';
export const Update = 'Update';
