// Libs
import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment-timezone-all';

// Actions
import { updatePendingSiteRequests } from 'actions/locations';
import { hideModal, showModal } from 'actions/modal';

// Components
import { Button, EmptyPlaceholder, GroupLayout, ListView } from 'lib';

// Constants
import { translatedConnect } from 'util/translatedConnect';
import * as modalTypes from 'constants/ModalTypes';
import {
  DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
  DEFAULT_LANGUAGE_CODE,
  SHORT_DATE_FORMAT,
  SHORT_INTERNATIONAL_DATE_FORMAT,
} from 'constants/app';

// Utils
import { convertToShortFormat } from 'util/convertTimeTo';

import { getActiveLanguageCode } from 'util/languagesUtils';
// Styles
import { sectionTitle } from 'sharedStyles/styles.css';
import {
  pendingRequestTableStyle,
  pendingRequestTableWrapper,
} from './MonitoringStyles.css';
import { pendingRequestListViewConfig } from './constants';

const profileShape = {
  LocalizationPreference: PropTypes.shape({
    PreferredShortDateFormat: PropTypes.string.isRequired,
  }).isRequired,
};

const PendingRequestTable = ({
  actions,
  isFetchingPendingRequests = false,
  pendingRequestData,
  profile,
}) => {
  const confirmPendingRequestAction = (rowData, decision) => {
    const message = [
      <div key="confirmPendingSiteRequestAction">
        <Translate id="INTEGRATIONS.CONFIRM_PENDING_REQUEST_DECLINE_BODY" />
        <br />
        <br />
      </div>,
    ];

    message.push(
      <div key="confirmPendingSiteData">
        <Translate id="INTEGRATIONS.PENDING_REQUEST_TABLE.SUBSCRIBER" /> :{' '}
        {rowData.SubscriberName}
        <br />
        <Translate id="INTEGRATIONS.PENDING_REQUEST_TABLE.SITE" /> :{' '}
        {rowData.SiteName}
        <br />
        <Translate id="INTEGRATIONS.PENDING_REQUEST_TABLE.SERVICE_PLAN" /> :{' '}
        {rowData.ServicePackageName}
        <br />
      </div>,
    );

    const onOkClick = () => {
      actions.updatePendingSiteRequests(
        rowData.dealerId,
        rowData.orgId,
        rowData,
        decision,
      );
      actions.hideModal();
    };

    const modalProps = {
      handleCancel: () => {
        actions.hideModal();
      },
      message,
      onOkClick,
      textConfirm: 'BUTTONS.DECLINE',
      title: (
        <Translate id="INTEGRATIONS.CONFIRM_PENDING_REQUEST_DECLINE_TITLE" />
      ),
    };

    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  const createdDateCell = rowData => {
    const format =
      getActiveLanguageCode() === DEFAULT_LANGUAGE_CODE
        ? SHORT_DATE_FORMAT
        : SHORT_INTERNATIONAL_DATE_FORMAT;

    const date = Moment(
      rowData.Created,
      DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
    ).format(format);

    return (
      <div>
        {convertToShortFormat(
          date,
          profile.LocalizationPreference.PreferredShortDateFormat,
        )}
      </div>
    );
  };

  const requestDecisionCell = rowData => {
    return (
      <GroupLayout horizontalPositioning="center">
        <Button
          disabled={false}
          inputType="button"
          onClick={() => {
            actions.updatePendingSiteRequests(
              rowData.dealerId,
              rowData.orgId,
              rowData,
              true,
            );
          }}
          text={<Translate id="BUTTONS.ACCEPT" />}
        />
        <Button
          disabled={false}
          inputType="button"
          onClick={() => {
            confirmPendingRequestAction(rowData, false);
          }}
          text={<Translate id="BUTTONS.DECLINE" />}
        />
      </GroupLayout>
    );
  };

  const CUSTOM_CELLS = {
    Created: rowData => createdDateCell(rowData),
    ServicePackageName: rowData => {
      return <>{rowData.ServicePackageName}</>;
    },
    SiteName: rowData => {
      return <>{rowData.SiteName}</>;
    },
    SubscriberName: rowData => {
      return <>{rowData.SubscriberName}</>;
    },
    requestDecision: rowData => requestDecisionCell(rowData),
  };

  return (
    <>
      {pendingRequestData.length > 0 ? (
        <div className={pendingRequestTableWrapper}>
          <div className={sectionTitle}>
            <Translate id="INTEGRATIONS.PENDING_REQUEST_TITLE" />
          </div>
          <EmptyPlaceholder
            isFetching={isFetchingPendingRequests}
            items={pendingRequestData}
            translateKey="DEV_DO_NOT_TRANSLATE.ACTIONS"
          >
            <ListView
              bodyHeight={200}
              bulkActions={false}
              cellAlignments={pendingRequestListViewConfig.CELL_ALIGNMENTS}
              cellWidths={pendingRequestListViewConfig.CELL_WIDTHS}
              className={pendingRequestTableStyle}
              customCells={CUSTOM_CELLS}
              data={pendingRequestData}
              fieldOrder={Object.keys(
                pendingRequestListViewConfig.HEADER_TRANSLATION_IDS,
              )}
              headerTranslationIds={
                pendingRequestListViewConfig.HEADER_TRANSLATION_IDS
              }
              hideFilter={false}
              resizable
              sortType="local"
            />
          </EmptyPlaceholder>
        </div>
      ) : null}
    </>
  );
};

PendingRequestTable.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isFetchingPendingRequests: PropTypes.bool.isRequired,
  pendingRequestData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profile: PropTypes.shape(profileShape).isRequired,
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
        updatePendingSiteRequests,
      },
      dispatch,
    ),
  };
}

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingRequestTable);
