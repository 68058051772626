/* eslint-disable jsx-a11y/control-has-associated-label */
// Libs
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { ModalCancelIcon } from 'components';
import { ai } from 'util/telemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Icon } from 'lib';

// CSS
import {
  disabledArrow,
  formHeader,
  headerText,
  leftArrow,
  leftArrowBar,
  modalContent,
  rightArrow,
  rightArrowBar,
  titleIconWrapper,
} from 'sharedStyles/modalForms.css';
import 'sharedStyles/savitarOverride.css';

const ModalContainer = ({
  cancelVisible,
  children,
  handleCancel,
  handleLeftArrowClick,
  handleRightArrowClick,
  modalGallery,
  modalTitle,
  modalTitleIcon,
}) => {
  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {modalGallery ? (
        <div className={leftArrowBar}>
          <div
            className={
              leftArrow + (!handleLeftArrowClick ? ` ${disabledArrow}` : '')
            }
            onClick={handleLeftArrowClick}
            onKeyPress={handleLeftArrowClick}
            role="button"
            tabIndex="0"
          />
        </div>
      ) : null}
      {modalGallery ? (
        <div className={rightArrowBar}>
          <div
            className={
              rightArrow + (!handleRightArrowClick ? ` ${disabledArrow}` : '')
            }
            onClick={handleRightArrowClick}
            onKeyPress={handleRightArrowClick}
            role="button"
            tabIndex="0"
          />
        </div>
      ) : null}
      <div className={modalContent}>
        <div className={formHeader}>
          {modalTitleIcon ? (
            <span className={titleIconWrapper}>
              <Icon
                color={modalTitleIcon.color}
                id={modalTitleIcon.iconId}
                renderBare
              />
            </span>
          ) : null}
          <div className={headerText}>{modalTitle}</div>
          <ModalCancelIcon
            handleCancel={handleCancel}
            isVisible={cancelVisible}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

ModalContainer.propTypes = {
  cancelVisible: PropTypes.bool,
  children: PropTypes.node,
  handleCancel: PropTypes.func.isRequired,
  handleLeftArrowClick: PropTypes.func,
  handleRightArrowClick: PropTypes.func,
  modalGallery: PropTypes.bool,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalTitleIcon: PropTypes.shape({
    color: PropTypes.string,
    iconId: PropTypes.string,
  }),
};

ModalContainer.defaultProps = {
  cancelVisible: true,
  children: null,
  handleLeftArrowClick: () => {},
  handleRightArrowClick: () => {},
  modalGallery: false,
  modalTitle: null,
  modalTitleIcon: null,
};

export default withAITracking(ai.reactPlugin, ModalContainer, 'ModalContainer');
