import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR_DEFAULT } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { UNSUSPEND_ICON_ID } from './constants';

const IconUnsuspendLocation = ({
  className,
  fill,
  height,
  title,
  translate,
  width,
}) => {
  return (
    <div
      className={className}
      id={UNSUSPEND_ICON_ID}
      title={title && translate(title)}
    >
      <svg
        fill={fill}
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.0008388,1.33260805 C16.0342515,2.43032827 19,6.1188203 19,10.5 C19,15.7467051 14.7467051,20 9.5,20 C4.25329488,20 0,15.7467051 0,10.5 C0,6.11845846 2.96623836,2.42971901 7.00016061,1.33233612 L7.00072219,3.67663768 C4.21979502,4.695537 2.23529412,7.36601599 2.23529412,10.5 C2.23529412,14.5121863 5.48781373,17.7647059 9.5,17.7647059 C13.5121863,17.7647059 16.7647059,14.5121863 16.7647059,10.5 C16.7647059,7.36638922 14.7806776,4.69617306 12.0002713,3.67700177 L12.0008388,1.33260805 Z M11,0 L11,12 L8,12 L8,0 L11,0 Z" />
      </svg>
    </div>
  );
};

IconUnsuspendLocation.defaultProps = {
  className: null,
  fill: ACTIVE_COLOR_DEFAULT,
  height: '24px',
  title: 'GENERIC_MESSAGES.UNSUSPEND_SITE',
  width: '24px',
};

IconUnsuspendLocation.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconUnsuspendLocation);
