import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import moment from 'moment-timezone-all';

// Actions
import * as UserActions from 'actions/user';

// Utilities
import { getSecurityTokens } from 'util/getSecurityTokens';
import { translatedConnect } from 'util/translatedConnect';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import { PATH_LOGIN, PATH_REQUEST_RESET } from 'constants/urlPaths';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Components
import {
  BusyIndicator,
  LoggedOutContentWrapper,
  ResetPasswordForm,
} from 'components';
import PageMessage from '../PageMessage/PageMessage';

// Styles
import { pageError, resetLinkLabel } from './styles.css';

class ResetPasswordContainer extends Component {
  // logout in order to invalidate cookie for another user's session
  componentDidMount() {
    const { actions } = this.props;
    actions.invalidateUserSession();
  }

  // forward user to login after they reset pw.  force them to login with username and new password
  componentDidUpdate() {
    const { history, user } = this.props;
    if (user.isPasswordChangeSuccessful) {
      history.push(PATH_LOGIN);
    }
  }

  changePassword = data => {
    const { actions, code } = this.props;
    const formData = {
      ConfirmPassword: data.password2.trim(),
      NewPassword: data.password1.trim(),
      SecurityToken: code,
    };
    actions.changePassword(formData);
  };

  resetLinkHasExpired() {
    const { expirationDate } = this.props;
    const expiration = moment(expirationDate);
    const now = moment.utc();
    return now.isAfter(expiration, 'minute');
  }

  render() {
    let busyIndicator;
    const { code, isBusy, isNotLast3Passwords, translate, user } = this.props;
    const hasExpired = this.resetLinkHasExpired();
    if (isBusy) {
      busyIndicator = <BusyIndicator />;
    }
    return hasExpired ? (
      <div className={pageError}>
        <PageMessage
          body={
            <span className={resetLinkLabel}>
              <Translate
                data={{
                  link: (
                    <Link to={PATH_REQUEST_RESET}>
                      <Translate id="RESET_PASSWORD.LINK_EXPIRED_RESET_LINK" />
                    </Link>
                  ),
                }}
                id="RESET_PASSWORD.LINK_EXPIRED_ERROR"
              />
            </span>
          }
          messageStyle={messageStyleStrings.error}
          messageType={messageTypes.RESET_PASSWORD_LINK_EXPIRED_ERROR}
          visible
        />
      </div>
    ) : (
      <LoggedOutContentWrapper
        header={<Translate id="RESET_PASSWORD.CHANGE_PASSWORD" />}
      >
        <ResetPasswordForm
          code={code}
          error={user.error}
          isNotLast3Passwords={isNotLast3Passwords}
          onSubmit={this.changePassword}
        />
        {busyIndicator}
      </LoggedOutContentWrapper>
    );
  }
}

ResetPasswordContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

/* ResetPasswordContainer.defaultProps = {
  user: {
    error: null,
    isAuthenticated: false,
  },
}; */

ResetPasswordContainer.propTypes = {
  expirationDate: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state, ownProps) {
  let code;
  let expirationDate;

  if (ownProps.location.search) {
    const params = getSecurityTokens(ownProps.location);
    code = params.code;
    expirationDate = params.expirydate;
  }
  return {
    code,
    expirationDate,
    isBusy: state.user.isBusy,
    isNotLast3Passwords: state.user.isNotLast3Passwords,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = UserActions;
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordContainer);
