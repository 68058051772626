import { PropTypes } from 'prop-types';
import renderIf from 'render-if';
import React, { useMemo } from 'react';
import { withLocalize } from 'react-localize-redux';

import { CameraSnapshot } from 'containers';

// Components
import { ArmDisarmCamera, ConnectedStatus, Spinner } from 'components';
import {
  CircularProgressBar,
  Icon,
  ListView,
  NoStyleButton,
  Popover,
} from 'lib';

// Constants
import { DEVICE_TYPES, SERVICE_FAMILIES } from 'constants/app';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import { IC_CAMERA_MOBILE, IC_SERVER, IC_TRASH } from 'constants/iconNames';
import * as modalTypes from 'constants/ModalTypes';
import {
  PATH_CAMERAS,
  PATH_SEGMENT_LIST,
  PATH_SERVERS,
} from 'constants/urlPaths';

import { DOWNLOADING } from 'constants/SignalTypes';

// Icons
import { getCameraActualStatus } from 'util/getCameraActualStatus';
import { getSubscriptionState } from 'util/validation';
import * as DevicesTableConsts from './constants';

// Styles
import {
  actionIcons,
  armDropdownPosition,
  cameraAuthError,
  cameraPopover,
  deviceDeleteIcon,
  deviceName,
  deviceType,
  disarmDropdownPosition,
  iconStyle,
  progressBar,
  progressWrap,
} from './DevicesTable.css';

const DevicesTable = props => {
  const {
    actions,
    canDeleteSuspendedDevice,
    deviceTableData,
    firmwareUpgradeStatus,
    handleDeleteClick,
    history,
    locations,
    onAuthRequired,
    translate,
    upgradeFirmwareWrap,
  } = props;

  const subscribedLocations = locations.filter(
    loc => getSubscriptionState(loc.PackageSubscription).isValid,
  );

  const accSites = locations.filter(
    loc =>
      loc.PackageSubscription.ServiceFamily ===
      SERVICE_FAMILIES.AccCloudServices,
  );

  const expiredLocations = locations.filter(
    loc => getSubscriptionState(loc.PackageSubscription).isExpired,
  );

  const tableData = useMemo(
    () =>
      deviceTableData.filter(
        device =>
          device.Type === DEVICE_TYPES.SERVER ||
          device.ConnectionState === CAMERA_CONNECTED ||
          device.Active,
      ),
    [deviceTableData],
  );

  const rowDisabled = rowData => {
    return (
      accSites.some(site => site.Id === rowData.LocationId) ||
      (rowData.ConnectionState !== CAMERA_CONNECTED &&
        rowData.Type === DEVICE_TYPES.CAMERA) ||
      !subscribedLocations.some(site => site.Id === rowData.LocationId) ||
      (rowData.type === DEVICE_TYPES.SERVER &&
        !subscribedLocations.some(site => site.Id === rowData.LocationId) &&
        !expiredLocations.some(site => site.Id === rowData.LocationId))
    );
  };

  const cameraStatusText = rowData => {
    return getCameraActualStatus(rowData).statusText;
  };

  const deleteIcon = rowData => {
    return (
      <NoStyleButton
        id={DevicesTableConsts.idDeleteDeviceButton}
        onClick={() => handleDeleteClick(rowData)}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        <Icon
          iconClass={`${iconStyle} ${deviceDeleteIcon}`}
          id={IC_TRASH}
          title="BUTTONS.DELETE"
        />
      </NoStyleButton>
    );
  };

  const getDevice = rowData => {
    return deviceTableData.find(d => d.Id === rowData.Id);
  };

  const handleRowClick = rowData => {
    let subroute = '';
    switch (rowData.Type) {
      case DevicesTableConsts.DEVICE_TYPES.CAMERA:
        subroute = PATH_CAMERAS;
        break;
      case DevicesTableConsts.DEVICE_TYPES.SERVER:
        subroute = PATH_SERVERS;
        break;
      default:
    }
    history.push({
      pathname: `${subroute}/${rowData.Id}`,
      state: {
        fromSegment: PATH_SEGMENT_LIST,
      },
    });
  };

  const customCells = {
    ConnectionState: rowData => {
      return rowData.type === DEVICE_TYPES.CAMERA ? (
        <ConnectedStatus
          connected={rowData.ConnectionState === CAMERA_CONNECTED}
          data={{
            cameraId: rowData.Id,
            cameraStatus: getCameraActualStatus(rowData),
            deviceId: rowData.serverId,
          }}
          errorClass={cameraAuthError}
          onSubmit={() =>
            onAuthRequired(
              modalTypes.CAMERA_LOGIN,
              true,
              rowData.Id,
              rowData.serverId,
            )
          }
          statusText={translate(cameraStatusText(rowData))}
        />
      ) : (
        <ConnectedStatus
          connected={rowData.ConnectionState === CAMERA_CONNECTED}
          statusText={translate(`CAMERA.STATUS.${rowData.ConnectionState}`)}
        />
      );
    },
    Name: (rowData, callbackParams) => {
      const upgrade = firmwareUpgradeStatus[rowData.Id] || {};
      const { idx = 0 } = callbackParams;
      const isLastThreeRow =
        tableData && idx && idx > 0 && idx + 3 >= tableData.length;
      const isUpgrading = !!upgrade.status;
      return rowData.Type === DEVICE_TYPES.CAMERA ? (
        <Popover
          className={cameraPopover}
          horizontalOffset="left"
          trigger={
            <div className={deviceType}>
              <Icon iconClass={iconStyle} id={IC_CAMERA_MOBILE} />
              <div className={deviceName}>{rowData.Name}</div>
            </div>
          }
          verticalOffset={isLastThreeRow ? 'bottom' : 'top'}
        >
          <CameraSnapshot
            cameraId={rowData.Id}
            cameraName={rowData.Name}
            deviceId={rowData.serverId}
          />
        </Popover>
      ) : (
        <div className={deviceType}>
          <Icon iconClass={iconStyle} id={IC_SERVER} />
          <div className={deviceName}>{rowData.Name}</div>
          {renderIf(isUpgrading)(
            <div className={progressWrap}>
              {upgrade.status === DOWNLOADING ? (
                <CircularProgressBar
                  className={progressBar}
                  percentage={upgrade && upgrade.progress}
                />
              ) : (
                <Spinner
                  borderSize={10}
                  duration={2000}
                  singleColor="#5DB6FF"
                  size={30}
                />
              )}
              <div className={upgradeFirmwareWrap}>
                {translate(
                  'DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_UPGRADING_TITLE',
                )}
              </div>
            </div>,
          )}
        </div>
      );
    },
  };

  const ROW_ACTIONS = [
    ({ rowData }) => {
      const isRowDisabled = rowDisabled(rowData);
      const isExpiredSite = expiredLocations.some(
        site => site.Id === rowData.locationId,
      );
      return (
        <div className={actionIcons}>
          <>
            {renderIf(
              !isRowDisabled || (canDeleteSuspendedDevice && !isExpiredSite),
            )(
              rowData.Type === DEVICE_TYPES.SERVER ? (
                deleteIcon(rowData)
              ) : (
                <div>
                  <ArmDisarmCamera
                    actions={actions}
                    camera={rowData}
                    device={getDevice(rowData)}
                    dropdownPositionClass={
                      rowData.Disarmed
                        ? armDropdownPosition
                        : disarmDropdownPosition
                    }
                    iconClass={iconStyle}
                    isDeviceCameraList
                  />
                </div>
              ),
            )}
            {renderIf(
              isRowDisabled &&
                isExpiredSite &&
                rowData.Type === DEVICE_TYPES.SERVER,
            )(deleteIcon(rowData))}
          </>
        </div>
      );
    },
  ];
  return (
    <ListView
      cellWidths={DevicesTableConsts.CELL_WIDTHS}
      clickableRows
      customCells={customCells}
      data={tableData}
      fieldOrder={DevicesTableConsts.fieldOrder}
      headerTranslationIds={DevicesTableConsts.columnHeaders}
      hideFilter
      nonSortingFields={['actions']}
      overflowFieldOrder={DevicesTableConsts.overflowFieldOrder}
      overflowFieldOrderField="Type"
      overflowHeaderTranslations={DevicesTableConsts.overflowColumnHeaders}
      rowActions={ROW_ACTIONS}
      rowClickCallback={handleRowClick}
      rowDisabled={rowDisabled}
      sortType="local"
      truncatedFields={['ServerName']}
    />
  );
};

DevicesTable.defaultProps = {
  handleDeleteClick: () => {},
  onAuthRequired: () => {},
};

DevicesTable.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canDeleteSuspendedDevice: PropTypes.bool.isRequired,
  deviceTableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleDeleteClick: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAuthRequired: PropTypes.func,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(DevicesTable);
