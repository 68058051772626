// Libs
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

// Component
import { Button, Icon, ListView } from 'lib';
import { IC_TRASH } from 'constants/iconNames';

// Styles
import { truncatedText } from 'sharedStyles/tables.css';

// Consts
import { BULK_ACTION_TABLES } from 'constants/app';
import * as ContactConsts from './constants';

// Class
class ContactsTable extends Component {
  handleRowClick = (data, index) => {
    const { onRowClicked } = this.props;
    onRowClicked(data, index);
  };

  render() {
    const {
      bulkActions,
      contacts,
      hideColumns,
      onDeleteClick,
      rowActions,
      rowsSelected,
    } = this.props;
    const {
      fieldName,
      fieldPhoneNo,
      fieldSite,
    } = ContactConsts.columnDataFields;
    const { black, white } = ContactConsts.iconColors;

    const isSelected = row => rowsSelected.includes(row.Id);

    const ROW_ACTIONS = [
      ({rowData}) => (
        <Button
          icon
          onClick={() => {
            onDeleteClick([rowData.Id]);
          }}
        >
          <Icon
            color={isSelected(rowData) ? white : black}
            id={IC_TRASH}
            title="BUTTONS.DELETE"
          />
        </Button>
      ),
    ];

    const parseDataMap = (map, dataArray) =>
      (dataArray || []).map(row => {
        const found = map.find(item => item && item.Id === row);
        return (
          found &&
          found.Name && (
            <div key={`contactable-site-${found.Id}`} className={truncatedText}>
              {found.Name}
            </div>
          )
        );
      });

    const data = contacts.map(row => ({
      Id: row.contact.Id,
      name: row.contact[fieldName],
      phone: row.contact[fieldPhoneNo],
      site: parseDataMap(row.sites, row.contact[fieldSite]),
    }));
    const CUSTOM_CELLS = {
      name: rowData => {
        return <span>{rowData.name}</span>;
      },
      phone: rowData => {
        return (
          <span>
            <a href={`tel:${rowData.phone}`}>{rowData.phone}</a>
          </span>
        );
      },
      site: rowData => {
        return <span>{rowData.site}</span>;
      },
    };
    const fieldOrder = ContactConsts.FIELD_ORDER.filter(
      field => !hideColumns.includes(field),
    );
    const translationIds = {
      name: 'COMMON.NAME',
      phone: 'GENERAL_TABLE.HEADER.PHONE_LABEL',
      site: 'COMMON.SITE',
    };
    return (
      <ListView
        bulkActions={bulkActions}
        bulkActionsTable={BULK_ACTION_TABLES.CONTACTS}
        cellWidths={{ Actions: 50 }}
        clickableRows
        customCells={CUSTOM_CELLS}
        data={data}
        fieldOrder={fieldOrder}
        fillContainerHeight
        headerTranslationIds={translationIds}
        hideFilter
        inlineDetails={false}
        resizable={false}
        rowActions={rowActions ? ROW_ACTIONS : []}
        rowClickCallback={this.handleRowClick}
        sortType="local"
        tableBodyStyle={null}
        truncatedFields={fieldOrder}
      />
    );
  }
}

ContactsTable.propTypes = {
  bulkActions: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.any),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  onDeleteClick: PropTypes.func,
  onRowClicked: PropTypes.func.isRequired,
  rowActions: PropTypes.bool,
  rowsSelected: PropTypes.arrayOf(PropTypes.any),
};

ContactsTable.defaultProps = {
  bulkActions: true,
  contacts: [],
  hideColumns: [],
  onDeleteClick: () => {},
  rowActions: true,
  rowsSelected: [],
};

export default ContactsTable;
