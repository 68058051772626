import {
  PATH_SEGMENT_ALL,
  PATH_SEGMENT_CONTACTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_FORMATS,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_HEALTH,
  PATH_SEGMENT_MONITORING,
  PATH_SEGMENT_PASSWORD,
  PATH_SEGMENT_PERSONAL_INFO,
  PATH_SEGMENT_SCHEDULES,
  PATH_SEGMENT_SECURITY,
  PATH_SEGMENT_SERVICE_PLANS,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
  PATH_SEGMENT_SUMMARY,
  PATH_SEGMENT_USERS,
} from 'constants/urlPaths';
import * as permissions from 'util/permissions';

const getPathName = str => {
  // then return first segment of path. should be same as "page" name
  const pathArr = str.split('/');
  const path = pathArr.filter(seg => seg !== '')[0];
  return path ? `/${path}` : '';
};

export const getFullPath = str => {
  // replace leading char if it's a /
  const pathArr = str.split('/');
  const path = pathArr.filter(seg => seg !== '').join('/');
  return path ? `/${path}` : '';
};

export const getCurrentPath = (fullPath, path, profile, subRouteFunc) => {
  let pathNew = `${path}`;
  const tabAccess = permissions.accountTabAccess(profile);
  const subroute = subRouteFunc(fullPath, tabAccess);
  pathNew += subroute;
  return pathNew;
};
// Accounts page should start on customers or sites, if child route isnt set
// If child route is set, redirect away from forbidden paths
export const getAccountSubRoute = (path, tabAccess) => {
  const pathArr = path.split('/');
  let subroute = tabAccess.subscribers
    ? PATH_SEGMENT_SUBSCRIBERS
    : PATH_SEGMENT_SITES;
  if (pathArr.length > 2) {
    const currentRoute = `/${pathArr[2]}`;
    if (
      (currentRoute === PATH_SEGMENT_GENERAL && tabAccess.general) ||
      (currentRoute === PATH_SEGMENT_SUBSCRIBERS && tabAccess.subscribers) ||
      (currentRoute === PATH_SEGMENT_DEALERS && tabAccess.dealers) ||
      (currentRoute === PATH_SEGMENT_SITES && tabAccess.sites) ||
      (currentRoute === PATH_SEGMENT_USERS && tabAccess.users) ||
      (currentRoute === PATH_SEGMENT_CONTACTS && tabAccess.contacts) ||
      (currentRoute === PATH_SEGMENT_SERVICE_PLANS && tabAccess.servicePlans) ||
      (currentRoute === PATH_SEGMENT_SCHEDULES && tabAccess.schedules) ||
      (currentRoute === PATH_SEGMENT_MONITORING && tabAccess.monitoring)
    ) {
      subroute = currentRoute;
    }
  }
  return subroute;
};

export const getAlarmSubRoute = (path, tabAccess) => {
  const pathArr = path.split('/');
  let subroute = PATH_SEGMENT_ALL;
  if (pathArr.length > 2) {
    const currentRoute = `/${pathArr[2]}`;
    if (
      (currentRoute === PATH_SEGMENT_HEALTH && tabAccess.health) ||
      (currentRoute === PATH_SEGMENT_SECURITY && tabAccess.security)
    ) {
      subroute = currentRoute;
    }
  }
  return subroute;
};

export const getProfileSubRoute = (path, tabAccess) => {
  const pathArr = path.split('/');
  let subroute = PATH_SEGMENT_SUMMARY;
  if (pathArr.length > 2) {
    const currentRoute = `/${pathArr[2]}`;
    if (
      (currentRoute === PATH_SEGMENT_PERSONAL_INFO && tabAccess.personalInfo) ||
      (currentRoute === PATH_SEGMENT_PASSWORD && tabAccess.password) ||
      (currentRoute === PATH_SEGMENT_FORMATS && tabAccess.formats)
    ) {
      subroute = currentRoute;
    }
  }
  return subroute;
};

export default getPathName;
