import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconDownArrow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          id={`a_${props.iconId}`}
          d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z"
        />
        <path id={`d_${props.iconId}`} d="M0 0h24v24H0z" />
        <filter
          x="-29.2%"
          y="-20.8%"
          width="158.3%"
          height="158.3%"
          filterUnits="objectBoundingBox"
          id="c_ic_arrow_downward"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <mask id={`b_${props.iconId}`} fill="#fff">
          <use xlinkHref={`#a_${props.iconId}`} />
        </mask>
        <g mask={`url(#b_${props.iconId})`}>
          <use
            fill="#000"
            filter={`url(#c_${props.iconId})`}
            xlinkHref="#d_ic_arrow_downward"
          />
          <use fill={props.fillColor} xlinkHref={`#d_${props.iconId}`} />
        </g>
      </g>
    </svg>
  );
};

IconDownArrow.defaultProps = {
  iconId: 'down_0',
  title: 'Up Triangle Arrow',
  className: null,
  width: '21px',
  height: '24px',
  fillColor: ACTIVE_COLOR_DEFAULT,
};

export default IconDownArrow;
