export const GENERAL_INFO_FORM_FIELDS = {
  adminId: {
    id: 'AdministratorId',
    name: 'AdministratorId',
  },
  adminName: {
    id: 'AdminName',
    name: 'AdminName',
  },
  adminPhone: {
    id: 'AdminPhoneNumber',
    name: 'AdministratorPhone',
  },
  dealer: {
    id: 'Dealer',
    name: 'DealerName',
  },
  email: {
    id: 'AdminEmailAddress',
    name: 'AdministratorEmail',
  },
  orgAddress1: {
    id: 'Address',
    name: 'Address',
  },
  orgAddress2: {
    id: 'Address2',
    name: 'Address2',
  },
  orgCity: {
    id: 'City',
    name: 'City',
  },
  orgCountry: {
    id: 'Country',
    name: 'Country',
  },
  orgId: {
    id: 'OrganizationId',
    name: 'OrganizationId',
  },
  orgName: {
    id: 'Name',
    name: 'Name',
  },
  orgPhone: {
    id: 'Phone',
    name: 'Phone',
  },
  orgPostalCode: {
    id: 'PostalCode',
    name: 'PostalCode',
  },
  orgState: {
    id: 'Region',
    name: 'Region',
  },
  tenantEntitlements: {
    id: 'Entitlements',
    name: 'Entitlements',
    options: [
      {
        translateId: 'COMMON.YES',
        value: true,
      },
      {
        translateId: 'COMMON.NO',
        value: false,
      },
    ],
    translatedLabel:
      'USERS.USER_FORM_FIELDS.LABELS.HEALTH_MONITOR_SYSTEM_ENABLED',
  },
};
export const USA_USA_USA = 'United States';

export const FORM_NAME = 'organizationGeneralInfo';
