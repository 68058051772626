import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-md-spinner';
import { Translate } from 'react-localize-redux';

// Styles
import {
  placeholder,
  placeHolderSpinner,
  placeholderWrapper,
} from './styles.css';

const EmptyPlaceholder = ({
  children,
  className,
  isFetching,
  items,
  showDataWhileFetching,
  size,
  string,
  translateKey,
}) => {
  // A wrapper component for table or card views which will display a placeholder
  // if the item list for the table or cards returns empty
  if (isFetching === false && items.length === 0) {
    return (
      <div className={`${placeholderWrapper} ${className}`}>
        <div className={placeholder}>
          {translateKey ? <Translate id={translateKey} /> : string}
        </div>
      </div>
    );
  }
  if (isFetching === null && items.length === 0) {
    return <div />;
  }
  if (
    isFetching === true &&
    (items.length === 0 || showDataWhileFetching === false)
  ) {
    return (
      <div className={placeHolderSpinner}>
        <Spinner singleColor="#5DB6FF" size={size} />
      </div>
    );
  }
  return children;
};

EmptyPlaceholder.defaultProps = {
  className: '',
  isFetching: null,
  items: [],
  showDataWhileFetching: false, // If set to true, the spinner or loading message will only be shown when items is empty
  size: 80,
  string: '',
  translateKey: null,
};

EmptyPlaceholder.propTypes = {
  translateKey: PropTypes.string,
};

export default EmptyPlaceholder;
