import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconZoomOut = props => {
  return (
    <div className={props.className} title={props.title}>
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path
            d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7V9z"
            id="a_icon_zoom_out"
          />
          <path id="d_icon_zoom_out" d="M0 0h24v24H0z" />
          <filter
            x="-81.2%"
            y="-52.1%"
            width="262.5%"
            height="300%"
            filterUnits="objectBoundingBox"
            id="c_icon_zoom_out"
          >
            <feOffset
              dy="16"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              stdDeviation="6"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.501960784 0 0 0 0 0.615686275 0 0 0 0 0.937254902 0 0 0 0.496490036 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g transform="translate(-3 -3)" fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <mask id="b_icon_zoom_out" fill="#fff">
            <use xlinkHref="#a_icon_zoom_out" />
          </mask>
          <g mask="url(#b_icon_zoom_out)">
            <use
              fill="#000"
              filter="url(#c_icon_zoom_out)"
              xlinkHref="#d_icon_zoom_out"
            />
            <use fill="#FFF" xlinkHref="#d_icon_zoom_out" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconZoomOut.defaultProps = {
  className: null,
  width: '48px',
  height: '48px',
  fill: 'white',
  title: 'Zoom out',
};

export default IconZoomOut;
