export const idSiteSearchInput = 'siteSearchInput';
export const idBookmarksSearchInput = 'searchInput';

export const siteTextInputfield = ['Name'];
export const textInputFields = ['name', 'description'];

// Accordian data
export const FIELD_ORDER = [
  'name',
  'startTime',
  'creationTime',
  'description',
  'isProtected',
  'Actions',
];

export const HEADER_TRANSLATION_IDS = {
  name: 'BOOKMARKS.VIEW_BOOKMARKS_HEADER_LABELS.BOOKMARK_NAME',
  startTime: 'BOOKMARKS.VIEW_BOOKMARKS_HEADER_LABELS.START_TIME',
  creationTime: 'BOOKMARKS.VIEW_BOOKMARKS_HEADER_LABELS.DATE_CREATED',
  description: 'COMMON.DESCRIPTION',
  isProtected: 'BOOKMARKS.VIEW_BOOKMARKS_HEADER_LABELS.PROTECTED_FLAG',
  Actions: 'BOOKMARKS.VIEW_BOOKMARKS_HEADER_LABELS.ACTIONS',
};

export const SITE_SEARCH_PLACEHOLDER =
  'BOOKMARKS.SEARCH_BOOKMARKS.SITE_SEARCH_PLACEHOLDER';
export const BOOKMARK_SEARCH_PLACEHOLDER =
  'BOOKMARKS.SEARCH_BOOKMARKS.BOOKMARK_SEARCH_PLACEHOLDER';

export const CELL_WIDTHS = {
  isProtected: 105,
  Actions: 160,
  startTime: 200,
  creationTime: 200,
};

export const CELL_ALIGNMENTS = {
  isProtected: 'center',
  Actions: 'right',
};

export const idEditBookmark = 'editBookmark';
export const idDeleteBookmark = 'deleteBookmark';
