import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldSelect, FormGroup } from 'lib';

import * as LocationFormConsts from '../../constants';

import { formField } from '../../styles.css';

const ServicePackageField = props => {
  const {
    disabled,
    handleFamilyChange,
    handlePackageChange,
    initialValues,
    readOnly,
    selfService,
    servicePackageFamilies,
    servicePackages,
  } = props;

  return (
    <>
      <FormGroup
        key={0}
        label={<Translate id="LOCATIONS.LOCATION_FORM.SERVICE_FAMILY" />}
      >
        {readOnly ? (
          initialValues.Family
        ) : (
          <Field
            className={formField}
            component={FieldSelect}
            disabled={disabled}
            isNewVersion
            name={LocationFormConsts.nameSubscriptionFamily}
            onChange={handleFamilyChange}
          >
            {servicePackageFamilies
              // TODO: MVAAS-8114 - Translate the options when there is a solution for how the data is persisted on the data base
              .map(type => {
                return (
                  <option key={type.Value} value={type.Value}>
                    {type.Key}
                  </option>
                );
              })}
          </Field>
        )}
      </FormGroup>
      <FormGroup
        key={1}
        label={<Translate id="LOCATIONS.LOCATION_FORM.SUBSCRIPTION_TYPE" />}
      >
        {readOnly ? (
          initialValues.Subscription
        ) : (
          <Field
            className={formField}
            component={FieldSelect}
            disabled={disabled}
            isNewVersion
            name={LocationFormConsts.nameSkuId}
            onChange={handlePackageChange}
            translate
            validate={validation.required}
          >
            {servicePackages.map(type => {
              return (
                <option
                  key={type.SkuId}
                  disabled={
                    !selfService ||
                    parseInt(selfService, 10) > parseInt(type.SelfService, 10)
                  }
                  value={type.SkuId}
                >
                  {`${type.Name} ${type.Description} ${type.SkuId}`}
                </option>
              );
            })}
          </Field>
        )}
      </FormGroup>
    </>
  );
};

ServicePackageField.defaultProps = {
  initialValues: [],
  readOnly: false,
  servicePackage: undefined,
};

ServicePackageField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleFamilyChange: PropTypes.func.isRequired,
  handlePackageChange: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  readOnly: PropTypes.bool,
  selfService: PropTypes.number.isRequired,
  servicePackage: PropTypes.shape({}),
  servicePackageFamilies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  servicePackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ServicePackageField;
