import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { inputError, isInputError } from './styles.css';

const FieldTextArea = ({
  className,
  disabled,
  input,
  maxLength,
  meta: { error, touched },
  placeholder,
  translate,
  type,
}) => (
  <div className={className}>
    <textarea
      {...input}
      className={touched && error ? isInputError : ''}
      disabled={disabled}
      maxLength={maxLength}
      placeholder={placeholder}
      type={type}
    />
    {touched && error && (
      <div className={inputError}>
        {translate ? <Translate id={error} /> : error}
      </div>
    )}
  </div>
);
FieldTextArea.defaultProps = {
  className: '',
  disabled: false,
  input: null,
  maxLength: '',
  placeholder: '',
  type: '',
};

FieldTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any),
  maxLength: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool })
    .isRequired,
  placeholder: PropTypes.string,
  translate: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default FieldTextArea;
