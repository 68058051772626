import * as types from 'constants/ActionTypes';

export function beginRedirectingToHMSRoot() {
  return {
    type: types.REDIRECT_TO_HMS_ROOT,
  };
}

export function stopRedirectingToHMSRoot() {
  return {
    type: types.STOP_REDIRECTING_TO_HMS_ROOT,
  };
}
