import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Utilities
import * as permissions from 'util/permissions';

// Actions
import * as ContactActions from 'actions/contacts';
import * as LocationActions from 'actions/locations';

// Components
import { PATH_ACCOUNTS, PATH_SEGMENT_CONTACTS } from 'constants/urlPaths';
import ContactForm from './ContactForm/ContactForm';

// Constants

function ContactDetailsContainer({
  actions,
  canChangeCustomer,
  contacts,
  history,
  isFetchingContacts,
  isFetchingLocations,
  locations,
  match,
}) {
  const { params } = match;
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (isFetchingContacts === null) {
      actions.getContacts();
    }
  }, [actions, isFetchingContacts]);

  useEffect(() => {
    if (isFetchingLocations === null) {
      actions.getLocations();
    }
  }, [actions, isFetchingLocations]);
  avoLog('Params for contact details page', params);

  function getInitialValues() {
    const contact = contacts.find(c => c.Id === params.contactid) || {};
    const initialValues = contact || {};
    if (contact && contact.Sites) {
      if (contact.Sites.length === 0) {
        initialValues.Sites = null;
      }
    }
    return initialValues;
  }

  function submitForm(formData) {
    setIsSubmitting(true);
    const contactObject = {
      ...formData,
    };

    if (formData.Id) {
      actions.editContact(contactObject, {});
    } else {
      actions.addContact(contactObject, {});
    }
    setIsSubmitting(false);
    history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_CONTACTS}`);
  }

  return (
    <>
      <ContactForm
        canChangeCustomer={canChangeCustomer}
        contactId={params.contactid}
        initialValues={getInitialValues()}
        onSubmit={submitForm}
        sites={locations}
        submittingForm={isSubmitting}
      />
    </>
  );
}

ContactDetailsContainer.defaultProps = {
  canChangeCustomer: false,
  contacts: [],
  history: [],
  isFetchingContacts: null,
  isFetchingLocations: null,
  locations: [],
  match: {},
};

ContactDetailsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canChangeCustomer: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.objectOf(PropTypes.any),
  isFetchingContacts: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.any),
};

function mapStateToProps(state, ownProps) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    contacts: state.contacts.contacts,
    isFetchingContacts: state.isFetching.getContacts,
    isFetchingLocations: state.isFetching.getLocations,
    locations: state.locations.locations,
    orgId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...ContactActions,
        ...LocationActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ContactDetailsContainer));
