import PropTypes from 'prop-types';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { MenuItem } from 'components';
import { NoStyleButton } from 'lib';
import {
  container,
  menuItem,
  menuItemSelected,
  popupHidden,
  popupShow,
} from './styles.css';

const MENU_ID = 'idBtnMenu';

class PopupMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  handleClickOutside = () => {
    this.setState({ showMenu: false });
  };

  toggleMenu = () => {
    const { isEnabled } = this.props;

    if (isEnabled) {
      this.setState(prevState => ({
        showMenu: !prevState.showMenu,
      }));
    }
  };

  onItemClicked = value => {
    const { onSelect } = this.props;

    onSelect(value);
    this.setState({ showMenu: false });
  };

  render() {
    const {
      buttonStyle,
      buttonStyleDisabled,
      children,
      isEnabled,
      items,
      selectedValue,
    } = this.props;
    const { showMenu } = this.state;

    const buttonClassName =
      buttonStyleDisabled && !isEnabled ? buttonStyleDisabled : buttonStyle;
    return (
      <div className={container}>
        <div className={showMenu ? popupShow : popupHidden}>
          {items.map((item, ix) => {
            return (
              <MenuItem
                key={ix}
                className={menuItem}
                contentStyle={
                  selectedValue === item.value ? menuItemSelected : null
                }
                onClick={this.onItemClicked}
                {...item}
              />
            );
          })}
        </div>
        <NoStyleButton
          className={buttonClassName}
          id={MENU_ID}
          onClick={this.toggleMenu}
        >
          {children}
        </NoStyleButton>
      </div>
    );
  }
}

PopupMenu.defaultProps = {
  isEnabled: true,
  items: [],
  onSelect: () => {},
};

PopupMenu.propTypes = {
  isEnabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  onSelect: PropTypes.func,
};

export default onClickOutside(PopupMenu);
