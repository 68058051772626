exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".messageBody___3ZbtS {\r\n  text-align: left;\r\n  display: inline-block;\r\n  color: #575757;\r\n}\r\n\r\n.messageHeader___Ozjfa {\r\n  font-weight: bold;\r\n  font-size: 14px;\r\n  margin-right: 5px;\r\n  display: inline-block;\r\n  line-height: 18px;\r\n}\r\n\r\n.messageContentLeft___21k5u {\r\n  display: inline-flex;\r\n  align-items: center;\r\n  margin: 6px 10px 6px 6px;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/sharedStyles/message.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;CAChB;;AAED;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;CACnB;;AAED;EACE,qBAAqB;EACrB,oBAAoB;EACpB,yBAAyB;CAC1B","file":"message.css","sourcesContent":[".messageBody {\r\n  text-align: left;\r\n  display: inline-block;\r\n  color: #575757;\r\n}\r\n\r\n.messageHeader {\r\n  font-weight: bold;\r\n  font-size: 14px;\r\n  margin-right: 5px;\r\n  display: inline-block;\r\n  line-height: 18px;\r\n}\r\n\r\n.messageContentLeft {\r\n  display: inline-flex;\r\n  align-items: center;\r\n  margin: 6px 10px 6px 6px;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"messageBody": "messageBody___3ZbtS",
	"messageHeader": "messageHeader___Ozjfa",
	"messageContentLeft": "messageContentLeft___21k5u"
};