exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".modalCancelDiv___WJXf- {\r\n  border: none;\r\n  background: none;\r\n  cursor: pointer;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: end;\r\n      align-items: flex-end;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 4px;\r\n}\r\n\r\n.modalCancelDiv___WJXf-:hover {\r\n  background-color: #ccc;\r\n}\r\n\r\n.modalCancelIcon___1tm4- {\r\n  width: auto; \r\n  height: auto;\r\n  max-width: 22px;\r\n  max-height: 22px;\r\n  vertical-align: middle;\r\n} ", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/ModalCancelIcon/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,cAAc;EACd,oBAAoB;MAChB,sBAAsB;EAC1B,2BAA2B;MACvB,uBAAuB;EAC3B,aAAa;CACd;;AAED;EACE,uBAAuB;CACxB;;AAED;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;CACxB","file":"styles.css","sourcesContent":[".modalCancelDiv {\r\n  border: none;\r\n  background: none;\r\n  cursor: pointer;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: end;\r\n      align-items: flex-end;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 4px;\r\n}\r\n\r\n.modalCancelDiv:hover {\r\n  background-color: #ccc;\r\n}\r\n\r\n.modalCancelIcon {\r\n  width: auto; \r\n  height: auto;\r\n  max-width: 22px;\r\n  max-height: 22px;\r\n  vertical-align: middle;\r\n} "],"sourceRoot":""}]);

// exports
exports.locals = {
	"modalCancelDiv": "modalCancelDiv___WJXf-",
	"modalCancelIcon": "modalCancelIcon___1tm4-"
};