import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconClear = props => {
  return (
    <svg
      title={props.title}
      width={props.width}
      height={props.height}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          d="M5 15.833c0 .917.75 1.667 1.667 1.667h6.666c.917 0 1.667-.75 1.667-1.667v-10H5v10zm10.833-12.5h-2.916l-.834-.833H7.917l-.834.833H4.167V5h11.666V3.333z"
          id={`a_${props.iconId}`}
        />
        <path id={`d_${props.iconId}`} d="M0 0h20v20H0z" />
        <filter
          x="-35%"
          y="-25%"
          width="170%"
          height="170%"
          filterUnits="objectBoundingBox"
          id={`c_${props.iconId}`}
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g transform="translate(-4 -2)" fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <mask id={`b_${props.iconId}`} fill="#fff">
          <use xlinkHref={`#a_${props.iconId}`} />
        </mask>
        <g mask={`url(#b_${props.iconId})`}>
          <use
            fill="#000"
            filter={`url(#c_${props.iconId})`}
            xlinkHref={`#d_${props.iconId}`}
          />
          <use fill={props.fillColor} xlinkHref={`#d_${props.iconId}`} />
        </g>
      </g>
    </svg>
  );
};

IconClear.defaultProps = {
  iconId: 'clear_0',
  title: 'Clear',
  className: null,
  width: '12px',
  height: '16px',
  fillColor: '#FFFFFF',
};

export default IconClear;
