import React from 'react';
import { Translate } from 'react-localize-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { FieldInput, FieldInputWithOnBlur } from 'components/FormElements';
import { FormGroup } from 'lib';

// Utils
import * as validation from 'util/validation';

// Constants
import { SERVICE_FAMILIES } from 'constants/app';
import * as LocationFormConsts from '../constants';

// Styles
import { formColumn, formField } from '../styles.css';

const fieldValidation = (allowEmptyValue, servicePackageFamily, field) => {
  const accServiceFamilies = [
    SERVICE_FAMILIES.ACCHealthMonitoring,
    SERVICE_FAMILIES.AccCloudServices,
  ];
  if (accServiceFamilies.includes(servicePackageFamily)) {
    return [];
  }
  if (allowEmptyValue) {
    if (field === LocationFormConsts.nameBillingNumber)
      return [
        validation.tenDigitsRequired,
        validation.dealerBillingNumberOrZeros,
      ];
    return [validation.tenDigitsRequired, validation.dealerOrderNumberOrZeros];
  }
  return [validation.tenDigitsRequired, validation.dealerBillingNumber];
};

const SiteDetailsFieldSet = ({
  allowEmptyValue,
  readOnly,
  servicePackageFamily,
  showBillingInfo,
}) => {
  return (
    <fieldset className={formColumn} disabled={readOnly}>
      <FormGroup
        id={LocationFormConsts.idLocationName}
        label={<Translate id="COMMON.NAME" />}
      >
        <Field
          className={formField}
          component={FieldInput}
          disabled={readOnly}
          isNewVersion
          name={LocationFormConsts.nameLocationName}
          translate
          type="text"
          validate={validation.required}
        />
      </FormGroup>
      {showBillingInfo && (
        <FormGroup
          label={<Translate id="LOCATIONS.LOCATION_FORM.BILLING_ID" />}
        >
          <Field
            className={formField}
            component={FieldInputWithOnBlur}
            disabled={readOnly}
            isNewVersion
            name={LocationFormConsts.nameBillingNumber}
            normalize={validation.tenDigits}
            normalizeOnBlur={validation.tenDigitsLeftZeroPadding}
            translate
            type="text"
            validate={fieldValidation(
              allowEmptyValue,
              servicePackageFamily,
              LocationFormConsts.nameBillingNumber,
            )}
          />
        </FormGroup>
      )}
      {showBillingInfo && (
        <FormGroup label={<Translate id="LOCATIONS.LOCATION_FORM.ORDER_ID" />}>
          <Field
            className={formField}
            component={FieldInputWithOnBlur}
            disabled={readOnly}
            isNewVersion
            name={LocationFormConsts.namePurchaseOrderNumber}
            normalize={validation.tenDigits}
            normalizeOnBlur={validation.tenDigitsLeftZeroPadding}
            translate
            type="text"
            validate={fieldValidation(
              allowEmptyValue,
              servicePackageFamily,
              LocationFormConsts.namePurchaseOrderNumber,
            )}
          />
        </FormGroup>
      )}
    </fieldset>
  );
};

SiteDetailsFieldSet.propTypes = {
  allowEmptyValue: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  servicePackageFamily: PropTypes.string.isRequired,
  showBillingInfo: PropTypes.bool.isRequired,
};

export default SiteDetailsFieldSet;
