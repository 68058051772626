// Accordion Item Hold
// Libs
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { camelCase } from 'lodash';

// Styles
import * as styles from './AccordionItem.css';

// Component
import ExpansionIndicator from './ExpansionIndicator';

const AccordionItem = ({
  canToggle,
  children,
  disabled,
  expansionCallback,
  icon,
  isExpanded,
  leftItems,
  rightItems,
  theme,
  title,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (isExpanded && !loaded) {
      setLoaded(true);
      setExpanded(isExpanded);
    }
  }, [isExpanded, loaded]);
  function toggleDetails() {
    if (canToggle) {
      const newExpanded = !expanded;
      setExpanded(newExpanded);
      expansionCallback(newExpanded);
    }
  }

  const iconWrap = <span className={styles.accordionIcon}>{icon}</span>;
  return (
    <div
      className={`${styles.accordionItem} ${styles[`${theme}Theme`]}`}
      id={`accordion-${camelCase(title)}`}
    >
      <div
        className={`${styles.accordionBar} ${disabled &&
          styles.accordionBarDisabled}`}
        onClick={disabled ? () => {} : toggleDetails}
        onKeyPress={disabled ? () => {} : toggleDetails}
        role="button"
        tabIndex="0"
      >
        <div key="leftitems" className={styles.leftItems}>
          <div className={styles.itemName}>
            {icon && iconWrap}
            <div className={styles.accordionTitle} title={title}>
              {title}
            </div>
          </div>
          {leftItems}
        </div>
        <div key="rightitems" className={styles.rightSideWrap}>
          <div key="innerRightItems" className={styles.rightItemsContainer}>
            {rightItems}
          </div>
          {canToggle && !disabled ? (
            <ExpansionIndicator
              key="indicator"
              expanded={expanded && !disabled}
            />
          ) : (
            <div key="indicator" className={styles.indicatorSpace} />
          )}
        </div>
      </div>
      {expanded && !disabled ? <div>{children}</div> : ''}
    </div>
  );
};

AccordionItem.defaultProps = {
  canToggle: true,
  children: null,
  disabled: false,
  expansionCallback: () => {},
  icon: null,
  isExpanded: false,
  leftItems: null,
  rightItems: null,
  theme: 'blue',
  title: '',
};

AccordionItem.propTypes = {
  canToggle: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  expansionCallback: PropTypes.func,
  icon: PropTypes.objectOf(PropTypes.any),
  isExpanded: PropTypes.bool,
  leftItems: PropTypes.objectOf(PropTypes.any),
  rightItems: PropTypes.node,
  theme: PropTypes.oneOf([
    'blue',
    'gray',
    'lightBlue',
    'green',
    'magenta',
    'darkGray',
    'orange',
    'persist',
    'purple',
    'detail',
    'listView',
  ]),
  title: PropTypes.string,
};

export default AccordionItem;
