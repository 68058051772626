import initialState from 'store/initialState';

// Utilities
import * as types from 'constants/ActionTypes';
import itemUpsert from '../util/itemUpsert';
import { sortFirstAndLastName } from '../util/generateSort';

// Constants

export default function contactReducer(state, action) {
  switch (action.type) {
    case types.RECEIVE_CONTACTS: {
      const contacts = action.contacts.sort(
        sortFirstAndLastName(contact => {
          return contact.Name.toUpperCase();
        }),
      );
      return Object.assign({}, state, {
        contacts,
      });
    }

    case types.RECEIVE_CONTACT: {
      if (action.contact && action.contact.Id) {
        const contacts = itemUpsert(state.contacts, action.contact);
        return Object.assign({}, state, {
          contacts,
        });
      }
      return state;
    }
    default: {
      return state || initialState().contacts;
    }
  }
}
