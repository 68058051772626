import fetch from 'isomorphic-fetch';
import { camelCase } from 'lodash';

import { isFetchingData } from 'actions/common';
// TODO: Consider moving runGetActionReturningJSON to actions/common, since it is a complex action
// rather than a simple helper for a commonly used fetch. This will eliminate the import cycle.
import { getActiveLanguage } from 'util/languagesUtils';
import handleErrors from './handleErrors';

// This had to be a function so if the UI language changes, the headers will get the correct Language
const DefaultHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Charset': 'utf-8',
  'Accept-Language': getActiveLanguage(),
});

export function sendGetRequest(url, params) {
  const getRequest = new Request(url, {
    ...params,
    credentials: 'include',
    headers: { ...DefaultHeaders() },
  });
  return fetch(getRequest);
}

export function sendGetRequestReturningJSON(url, params) {
  return sendGetRequest(url, params).then(handleErrors);
}

export function runGetActionReturningJSON({
  dispatch,
  fetchType: rawFetchType,
  fetchScope,
  onSuccess,
  onError,
  url,
  params,
}) {
  // Convert GET_STUFF_AND_JUNK to getStuffAndJunk
  const fetchType = camelCase(rawFetchType);
  // App insights to track fetch events
  // ai.appInsights.trackEvent({
  //   name: `get action with fetchType ${fetchType}`,
  // });
  dispatch(isFetchingData(fetchType, true, { fetchScope }));
  return sendGetRequestReturningJSON(url, params)
    .then(onSuccess)
    .catch(onError)
    .then(json => {
      dispatch(isFetchingData(fetchType, false, { fetchScope }));
      return json;
    });
}
export function sendPostRequest(
  url,
  params,
  extraParams = {},
  extraHeaders = {},
  skipBodyStringify = false,
) {
  const body = skipBodyStringify ? params : JSON.stringify(params);

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...DefaultHeaders(),
      ...extraHeaders,
    },
    body,
    ...extraParams,
  });
}

export function sendPostRequestReturningJSON(...args) {
  return sendPostRequest(...args).then(handleErrors);
}

export function sendPutRequest(
  url,
  params,
  extraParams = {},
  extraHeaders = {},
) {
  const body = JSON.stringify({ ...params });
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      ...DefaultHeaders(),
      ...extraHeaders,
    },
    body,
    ...extraParams,
  });
}

export function sendPutRequestReturningJSON(...args) {
  return sendPutRequest(...args).then(handleErrors);
}

// For when more than one record is sent at once.
export function sendMultiPutRequest(
  url,
  params,
  extraParams = {},
  extraHeaders = {},
) {
  const body = JSON.stringify([...params]);
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      ...DefaultHeaders(),
      ...extraHeaders,
    },
    body,
    ...extraParams,
  });
}

export function sendMultiPutRequestReturningJSON(...args) {
  return sendMultiPutRequest(...args).then(handleErrors);
}

export function sendDeleteRequest(url, params) {
  const body = JSON.stringify({ ...params });
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: { ...DefaultHeaders() },
    body,
  });
}

export function sendDeleteRequestReturningJSON(...args) {
  return sendDeleteRequest(...args).then(handleErrors);
}
