import * as types from 'constants/ActionTypes';

export function hideIpInput() {
  return {
    type: types.HIDE_IP_INPUT,
  };
}

export function showIpInput() {
  return {
    type: types.SHOW_IP_INPUT,
  };
}
export function selectCamera(camera) {
  return {
    type: types.SELECT_CAMERA,
    camera,
  };
}
