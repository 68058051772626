
import React from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

// Components

import { Polygon, PrivacyRectangle, Line } from 'components';

// Utils
import {
  canvasTypes,
  canvasObjectTypes,
  streamDetailDefaults,
} from 'constants/app';
import {
  newRoi,
  newLoi,
  newPrivacyZoneDimensions,
  newPlaceholder,
} from 'constants/cameraSettings';
import {
  AdjustRulesToVideoRotation,
  RevertRulesFromVideoRotation,
  AdjustToVideoRotation,
  RevertFromVideoRotation,
} from 'util/AdjustPrivacyZone';

import { cloneDeep } from 'util/cameraSettingLinks';

// Styles
import { drawingContainer, canvas } from './styles.css';

// Constants
const hasNewItem = canvasList =>
  canvasList.find(
    canvasItem =>
      canvasItem.roi === newPlaceholder ||
      canvasItem.loi === newPlaceholder ||
      canvasItem.privacyZoneDimensions === newPlaceholder,
  );

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasDocumentCoords: {}, // Canvas boundaries in DOM space
      canvasDrawingMouseDown: false,
    };
  }

  componentDidMount() {
    const { canvasList, updateCanvasList } = this.props;
    /* See note in didUpdate */
    if (hasNewItem(canvasList)) {
      updateCanvasList(this.canvasList, hasNewItem(canvasList).id);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { canvasList, height, width } = this.props;
    const {
      canvasList: newCanvasList,
      height: newHeight,
      width: newWidth,
    } = nextProps;
    // TODO: Updating height or width should also update canvasDocumentCoords
    return (
      canvasList !== newCanvasList || height !== newHeight || width !== newWidth
    );
  }

  componentDidUpdate() {
    const { canvasList, updateCanvasList } = this.props;

    /* this.canvasList will automatically fill in empty boundaries, so
      if a new item has been added with an empty boundary, call the
      update function with this.canvasList
      */
    if (hasNewItem(canvasList)) {
      updateCanvasList(this.canvasList, hasNewItem(canvasList).id);
    }
  }

  get streamDetails() {
    const { streamDetails } = this.props;
    return {
      ...streamDetailDefaults,
      ...streamDetails,
    };
  }

  get canvasList() {
    // No privacy zone transformations here
    // rules are passed in more or less directly
    // (they are transformed from hash to array, but otherwise untouched)
    // from the reducer, and then processed & rotated here.
    // In the future, a CanvasContainer may pass rules in directly from the reducer, eliminating the
    // need for props.canvasList
    const { canvasList } = this.props;
    return canvasList
      .map(rule => {
        let boundary;
        let type;
        const directionAngleInfo = {
          isProhibitedDirectionRule: false,
          directionAngle: 0,
          key: null,
        };
        const {
          cameraResWidth,
          cameraResHeight,
          xOffset,
          yOffset,
          rotation,
        } = this.streamDetails;
        const { width, height } = this.props;
        if (rule.roi && rule.event) {
          if (rule.roi === newPlaceholder) {
            boundary = newRoi(rule.event.sensorId, this.streamDetails);
          } else {
            boundary = cloneDeep(rule.roi);
          }
          if (boundary.region.point) {
            boundary.region.point = boundary.region.point.map(p =>
              AdjustRulesToVideoRotation(
                { cameraResWidth, cameraResHeight, xOffset, yOffset },
                rotation,
                p,
              ),
            );
          }
          type = canvasObjectTypes.roi;

          if (rule.event.activityType === 'PROHIBITED_DIRECTION') {
            directionAngleInfo.isProhibitedDirectionRule = true;
            directionAngleInfo.directionAngle = rule.roi.directionAngle;
            directionAngleInfo.key = `${rule.roi.directionAngle}`;
          }
        } else if (rule.loi && rule.event) {
          if (rule.loi === newPlaceholder) {
            boundary = newLoi(rule.event.sensorId, this.streamDetails);
          } else {
            boundary = cloneDeep(rule.loi);
          }
          boundary.point1 = AdjustRulesToVideoRotation(
            { cameraResWidth, cameraResHeight, xOffset, yOffset },
            rotation,
            boundary.point1,
          );
          boundary.point2 = AdjustRulesToVideoRotation(
            { cameraResWidth, cameraResHeight, xOffset, yOffset },
            rotation,
            boundary.point2,
          );
          type = canvasObjectTypes.loi;
        } else if (rule.privacyZoneDimensions) {
          if (rule.privacyZoneDimensions === newPlaceholder) {
            const privacyZoneDimensions = newPrivacyZoneDimensions(
              xOffset,
              yOffset,
            );
            boundary = { ...rule, privacyZoneDimensions };
          } else {
            boundary = { ...rule };
          }
          type = canvasObjectTypes.privacy;
        } else {
          boundary = cloneDeep(rule);
          boundary.point1 = {
            x: width / 2,
            y: height / 2,
          };
          boundary.point2 = {
            x: width / 2,
            y: height / 2,
          };
          type = canvasObjectTypes.ptzArrow;
        }

        return {
          ...boundary,
          ...directionAngleInfo,
          isVisible: rule.isVisible,
          isSelected: rule.isSelected,
          type,
        };
      })
      .filter(r => r !== undefined);
  }

  onCanvasLoad = canvasElement => {
    if (canvasElement) {
      const {
        top,
        bottom,
        left,
        right,
      } = canvasElement.getBoundingClientRect();
      this.setState({ canvasDocumentCoords: { top, bottom, left, right } });
    }
  };

  scalePoints = points => {
    // A point must be an object of form {x: int, y: int}
    const {
      cameraResWidth,
      cameraResHeight,
      xOffset,
      yOffset,
    } = this.streamDetails;
    const { width, height } = this.props;
    const scaleWidth = width / cameraResWidth;
    const scaleHeight = height / cameraResHeight;
    return points.map(({ x, y }) => {
      return {
        x: Math.round((x - xOffset) * scaleWidth),
        y: Math.round((y - yOffset) * scaleHeight),
      };
    });
  };

  getRectangleDimensions = (rectangle, direction = 0) => {
    // A rectangle has fields top, bottom, left, and right
    // A PrivacyRectangle element has x, y, rectangleWidth, and rectangleHeight
    // Pass a direction of 0 for camera space (rectangle) => canvas space (PrivacyRectangle)
    // or a direction of 1 for canvas space (PrivacyRectangle) => camera space (rectangle)
    const {
      cameraResWidth,
      cameraResHeight,
      xOffset,
      yOffset,
    } = this.streamDetails;
    const { width, height } = this.props;
    const widthAspect = width / cameraResWidth;
    const heightAspect = height / cameraResHeight;
    const dimensions = {};
    if (direction === 0) {
      const { left, right, top, bottom } = rectangle;
      dimensions.x = (left - xOffset) * widthAspect;
      dimensions.y = (top - yOffset) * heightAspect;
      if ((top || top === 0) && (bottom || bottom === 0)) {
        dimensions.rectangleHeight = (bottom - top) * heightAspect;
      }
      if ((right || right === 0) && (left || left === 0)) {
        dimensions.rectangleWidth = (right - left) * widthAspect;
      }
    }
    if (direction === 1) {
      const { x, y, rectangleWidth, rectangleHeight } = rectangle;
      dimensions.left = x / widthAspect + xOffset;
      dimensions.top = y / heightAspect + yOffset;
      dimensions.right = dimensions.left + rectangleWidth / widthAspect;
      dimensions.bottom = dimensions.top + rectangleHeight / heightAspect;
    }
    return dimensions;
  };

  objectUpdated = (updatedObjValue, updatedObjectId) => {
    const {
      xOffset,
      yOffset,
      cameraResWidth,
      cameraResHeight,
      rotation,
    } = this.streamDetails;
    const { updateCanvasList, width, height } = this.props;
    const canvasListCopy = cloneDeep(this.canvasList);
    const heightScale = cameraResHeight / height;
    const widthScale = cameraResWidth / width;
    const selectedObject = canvasListCopy.find(
      canvasObj => canvasObj.id === updatedObjectId,
    );
    if (!selectedObject) {
      return;
    }
    if (selectedObject.privacyZoneDimensions) {
      selectedObject.privacyZoneDimensions = RevertFromVideoRotation(
        { cameraResWidth, cameraResHeight, xOffset, yOffset },
        rotation,
        this.getRectangleDimensions(updatedObjValue, 1),
      );
    }
    if (selectedObject.region && selectedObject.region.point) {
      // ROI
      selectedObject.region.point = updatedObjValue.map(point =>
        RevertRulesFromVideoRotation(
          { cameraResWidth, cameraResHeight, xOffset, yOffset },
          rotation,
          {
            x: point.x * widthScale + xOffset,
            y: point.y * heightScale + yOffset,
          },
        ),
      );
    }
    if (selectedObject.point1 && selectedObject.point2) {
      // LOI
      const { point1, point2 } = updatedObjValue;
      selectedObject.point1 = RevertRulesFromVideoRotation(
        { cameraResWidth, cameraResHeight, xOffset, yOffset },
        rotation,
        {
          x: point1.x * widthScale + xOffset,
          y: point1.y * heightScale + yOffset,
        },
      );
      selectedObject.point2 = RevertRulesFromVideoRotation(
        { cameraResWidth, cameraResHeight, xOffset, yOffset },
        rotation,
        {
          x: point2.x * widthScale + xOffset,
          y: point2.y * heightScale + yOffset,
        },
      );
    }
    const selectedObjIndex = canvasListCopy.findIndex(
      x => x.id === updatedObjectId,
    );
    canvasListCopy[selectedObjIndex] = selectedObject;
    updateCanvasList(canvasListCopy, updatedObjectId);
  };

  objectSelected = id => {
    const { onObjectSelected } = this.props;
    onObjectSelected(id);
  };

  getProcessedCanvasList = () => {
    const canvasListComponents = this.canvasList.map(item => {
      let top;
      let left;
      let points;
      const {
        isPtzArrowEnabled,
        initialPolygonHeight,
        initialPolygonWidth,
        canvasMouseDown,
        canvasMouseUp,
        canvasMouseMove,
        canvasElementMouseLeave,
        width,
        height,
      } = this.props;
      const {
        cameraResHeight,
        cameraResWidth,
        xOffset,
        yOffset,
        rotation,
      } = this.streamDetails;
      const { canvasDocumentCoords } = this.state;
      if (item.isVisible === false) {
        return null;
      }
      if (
        item.type === canvasObjectTypes.roi &&
        !isPtzArrowEnabled &&
        item.region &&
        item.region.point
      ) {
        points = this.scalePoints(item.region.point);
        return (
          <Polygon
            key={item.isProhibitedDirectionRule ? item.key : item.id}
            id={item.id}
            ref={item.id}
            type={item.type}
            parentHeight={height}
            parentWidth={width}
            initialHeight={initialPolygonHeight}
            initialWidth={initialPolygonWidth}
            canvasDocumentCoords={canvasDocumentCoords}
            top={top}
            left={left}
            points={points}
            isSelected={item.isSelected}
            onObjectSelected={this.objectSelected}
            onObjectUpdated={this.objectUpdated}
            isProhibitedDirectionRule={item.isProhibitedDirectionRule}
            directionAngle={item.directionAngle}
          />
        );
      }
      if (
        item.type === canvasObjectTypes.loi &&
        !isPtzArrowEnabled &&
        item.point1 &&
        item.point2
      ) {
        const pointsUnscaled = [item.point1, item.point2];
        // A unique key will cause the line to be re-rendered if the input point set changes
        points = this.scalePoints(pointsUnscaled);
        const key = `${points.reduce((a = '', c) => a.concat(c.x, c.y), '')}${
          item.id
        }`;
        return (
          <Line
            key={key}
            id={item.id}
            ref={item.id}
            type={item.type}
            parentHeight={height}
            parentWidth={width}
            initialHeight={initialPolygonHeight}
            initialWidth={initialPolygonWidth}
            canvasDocumentCoords={canvasDocumentCoords}
            top={top}
            left={left}
            points={points}
            directional={item.directional}
            isSelected={item.isSelected}
            onObjectSelected={this.objectSelected}
            onObjectUpdated={this.objectUpdated}
          />
        );
      }
      if (
        item.type === canvasObjectTypes.privacy &&
        !isPtzArrowEnabled &&
        item.privacyZoneDimensions
      ) {
        const privacyZoneDimensions = this.getRectangleDimensions(
          AdjustToVideoRotation(
            { cameraResWidth, cameraResHeight, xOffset, yOffset },
            rotation,
            item.privacyZoneDimensions,
          ),
          0,
        );
        // A unique key will cause the zone to re-render if the input dimensions change
        const key = Object.values(privacyZoneDimensions).reduce(
          (a = '', c) => a.concat(c),
          '',
        );
        return (
          <PrivacyRectangle
            key={`${item.id}_${key}`}
            id={item.id}
            zoneId={item.id}
            name={item.name}
            rectangle={privacyZoneDimensions}
            isSelected={item.isSelected}
            onObjectSelected={this.objectSelected}
            onZoneUpdated={this.objectUpdated}
          />
        );
      }
      if (item.type === canvasObjectTypes.ptzArrow && isPtzArrowEnabled) {
        const pointsUnscaled = [item.point1, item.point2];
        // There will only be one PTZ arrow element, so it should always have the same key
        const key = 'ptzArrow';
        return (
          <Line
            key={key}
            id={item.id}
            ref={item.id}
            type={item.type}
            parentHeight={height}
            parentWidth={width}
            initialHeight={initialPolygonHeight}
            initialWidth={initialPolygonWidth}
            canvasDocumentCoords={canvasDocumentCoords}
            top={top}
            left={left}
            points={pointsUnscaled}
            directional={item.directional}
            isSelected={item.isSelected}
            onObjectSelected={this.objectSelected}
            onObjectUpdated={this.objectUpdated}
            onMouseDown={canvasMouseDown}
            onMouseUp={canvasMouseUp}
            onMouseDrag={canvasMouseMove}
            onMouseLeave={canvasElementMouseLeave}
          />
        );
      }
      return null;
    });
    return canvasListComponents;
  };

  handleCanvasMouseOut = () => {
    const { canvasMouseOut } = this.props;
    canvasMouseOut(true);
  };

  onCanvasMouseMove = e => {
    const { canvasDrawingMouseDown } = this.state;
    const { onCanvasMouseMove } = this.props;
    if (!canvasDrawingMouseDown) {
      onCanvasMouseMove(e, this.props);
    }
  };

  render() {
    const {
      className,
      canEditROI,
      cameraSupportsPtz,
      showCanvas,
      type,
    } = this.props;
    const { height, width } = this.props;
    return (
      <div
        key="canvasContainer"
        className={`${drawingContainer} ${className}`}
        style={{ pointerEvents: canEditROI ? 'all' : 'none' }}
      >
        <div
          role="button"
          tabIndex="0"
          style={{
            height,
            width,
            pointerEvents: canEditROI ? 'all' : 'none',
          }}
          onMouseLeave={this.handleCanvasMouseOut()}
          onMouseMove={this.onCanvasMouseMove}
          onMouseDown={() => {
            this.setState({
              canvasDrawingMouseDown: true,
            });
          }}
          onMouseUp={() => {
            this.setState({
              canvasDrawingMouseDown: false,
            });
          }}
          ref={this.onCanvasLoad}
          id="canvasDrawing"
        >
          {renderIf(showCanvas)(
            type && (type === canvasTypes.privacy && !cameraSupportsPtz) ? (
              this.getProcessedCanvasList() || ''
            ) : (
              <svg
                key="canvasSVG"
                className={canvas}
                width={width}
                height={height}
              >
                {this.getProcessedCanvasList() || ''}
              </svg>
            ),
          )}
        </div>
      </div>
    );
  }
}

Canvas.defaultProps = {
  className: '',
  initialPolygonHeight: 75,
  initialPolygonWidth: 75,
  canvasList: [],
  height: 0,
  width: 0,
  isPtzArrowEnabled: false,
  canEditROI: false,
  cameraSupportsPtz: false,
  canvasMouseDown: () => {},
  canvasMouseUp: () => {},
  canvasMouseMove: () => {},
  canvasMouseOut: () => {},
  canvasElementMouseLeave: () => {},
  onCanvasMouseMove: () => {},
  updateCanvasList: () => {},
  onObjectSelected: () => {},
  showCanvas: true,
  streamDetails: streamDetailDefaults,
};

Canvas.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(canvasTypes)).isRequired,
  initialPolygonHeight: PropTypes.number,
  initialPolygonWidth: PropTypes.number,
  canvasList: PropTypes.arrayOf(PropTypes.object),
  isPtzArrowEnabled: PropTypes.bool,
  canvasMouseDown: PropTypes.func,
  canvasMouseUp: PropTypes.func,
  canvasMouseMove: PropTypes.func,
  canvasMouseOut: PropTypes.func,
  canvasElementMouseLeave: PropTypes.func,
  onCanvasMouseMove: PropTypes.func,
  updateCanvasList: PropTypes.func,
  onObjectSelected: PropTypes.func,
  canEditROI: PropTypes.bool,
  cameraSupportsPtz: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  showCanvas: PropTypes.bool,
  streamDetails: PropTypes.shape({
    cameraResWidth: PropTypes.number,
    cameraResHeight: PropTypes.number,
    xOffset: PropTypes.number,
    yOffset: PropTypes.number,
    rotation: PropTypes.number,
  }),
};

export default Canvas;
