// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

// Actions
import * as DeviceActions from 'actions/devices';
import * as UserActions from 'actions/user';
import { hideModal, showModal } from 'actions/modal';

// Components
import { ServerCard } from 'components';
import { CameraCard } from 'containers';
import { EmptyPlaceholder } from 'lib';

// Constants
import * as CameraTypes from 'constants/cameraTypes';
import * as modalTypes from 'constants/ModalTypes';

// Utils
import { findCameraCategory } from 'util/deviceCategory';
import { generateSort } from 'util/generateSort';
import NavigationWrapper from '../NavigationWrapper/NavigationWrapper';

import {
  cameraLoadingTile,
  cameraTileListItem,
  deviceCameraContainer,
  deviceContentContainer,
} from './styles.css';

class DeviceContainer extends Component {
  componentDidMount() {
    const { actions, device, isFetchingDeviceCameras } = this.props;
    if (!isFetchingDeviceCameras) {
      actions.getCameras({
        field: 'DeviceId',
        value: device.Id,
      });
    }
  }

  render() {
    const {
      actions,
      cameras,
      canDeleteSuspendedDevice,
      device,
      handleDeleteClick,
      hasValidSubscription,
      isFetchingDeviceCameras,
      onAuthRequired,
      upgrade,
    } = this.props;
    return (
      <div>
        <div className={deviceContentContainer}>
          <div className={deviceCameraContainer}>
            <div className={cameraTileListItem}>
              <ServerCard
                canDeleteSuspendedDevice={canDeleteSuspendedDevice}
                hasValidSubscription={hasValidSubscription}
                onDeleteClick={handleDeleteClick}
                server={device}
                upgrade={upgrade}
              />
            </div>
            <EmptyPlaceholder
              isFetching={isFetchingDeviceCameras}
              items={[1]}
              overrideClassName={cameraLoadingTile}
            >
              {cameras.sort(generateSort(camera => camera.Name)).map((camera, index) => {
                const camCategory = findCameraCategory(camera);
                if (camCategory === CameraTypes.MANAGED_CAMERA) {
                  return (
                    <div key={camera.Id} className={cameraTileListItem}>
                      <CameraCard
                        actions={actions}
                        camera={camera}
                        deviceId={device.Id}
                        hasValidSubscription={hasValidSubscription}
                        index={index}
                        onAuthRequired={(cameraId, deviceId) => {
                          onAuthRequired(cameraId, deviceId);
                        }}
                        server={device}
                      />
                    </div>
                  );
                }
              })}
            </EmptyPlaceholder>
          </div>
        </div>
      </div>
    );
  }
}

DeviceContainer.defaultProps = {
  cameras: null,
  device: {},
  handleDeleteClick: () => {},
  hasValidSubscription: false,
  isFetchingDeviceCameras: false,
  onAuthRequired: () => {},
  upgrade: {},
};

DeviceContainer.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
};

DeviceContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.any),
  canDeleteSuspendedDevice: PropTypes.bool.isRequired,
  device: PropTypes.objectOf(PropTypes.any),
  handleDeleteClick: PropTypes.func,
  hasValidSubscription: PropTypes.bool,
  isFetchingDeviceCameras: PropTypes.bool,
  onAuthRequired: PropTypes.func,
  upgrade: PropTypes.objectOf(PropTypes.any),
};

function mapStateToProps(state, ownProps) {
  const { device } = ownProps;
  let upgrade = {};
  let cameras = [];
  let isFetchingDeviceCameras = null;
  if (device && device.Id) {
    const id = device.Id;
    cameras = state.devices.cameras.filter(camera => camera.DeviceId === id);
    cameras.sort(generateSort(item => item.name && item.Name.toLowerCase()));
    upgrade = state.devices.firmwareUpgrade[id];
    isFetchingDeviceCameras = state.isFetching.getCameras[device.Id];
  }

  return {
    cameras,
    device: ownProps.device,
    isAuthenticated: state.user.isAuthenticated,
    isFetching: state.devices.isFetchingDeviceData,
    isFetchingDeviceCameras,
    modalIsOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.DELETE_DEVICE,
    upgrade,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...DeviceActions, ...UserActions, hideModal, showModal },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  DeviceContainer,
  mapStateToProps,
  mapDispatchToProps,
  modalTypes.DELETE_DEVICE,
);
