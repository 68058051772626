import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

import { getTimeFormat } from 'util/convertTimeTo';
import {
  PREFERRED_TIME_FORMAT_MILLISEC,
  twentyFourHourFormat,
} from 'constants/dateTimeNumberFormats';

import {
  header,
  headerItem,
  headerLabel,
  headerNarrow,
  spacer,
} from '../../../components/Notifications/styles.css';

const generateTimeSlots = useMilitaryTime => {
  const MAX_HOURS = useMilitaryTime ? 24 : 12;
  const timeSlots = [
    <div key={0} className={headerItem}>
      {useMilitaryTime ? 0 : MAX_HOURS}
    </div>,
  ];
  for (let i = 1; i < MAX_HOURS; i += 1) {
    timeSlots.push(<div key={i} className={headerItem}>{`${i}`}</div>);
  }
  timeSlots.push(
    <div key={MAX_HOURS} className={headerItem}>
      {MAX_HOURS}
    </div>,
  );

  if (useMilitaryTime) {
    return timeSlots;
  }

  for (let i = 1; i < MAX_HOURS; i += 1) {
    timeSlots.push(
      <div key={MAX_HOURS + i} className={headerItem}>{`${i}`}</div>,
    );
  }
  return timeSlots;
};

const ScheduleHeaderContainer = ({ translate, useMilitaryTime }) => (
  <>
    <span className={headerNarrow}>
      {renderIf(!useMilitaryTime)(
        <>
          <div className={headerLabel}>
            {translate('SCHEDULES.TABLE.HEADERS.AM_LABEL')}
          </div>
          <div className={headerLabel}>
            {translate('SCHEDULES.TABLE.HEADERS.PM_LABEL')}
          </div>
        </>,
      )}
    </span>

    <span className={header}>{generateTimeSlots(useMilitaryTime)}</span>
    <div className={spacer} />
  </>
);

ScheduleHeaderContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  useMilitaryTime: PropTypes.bool,
};

ScheduleHeaderContainer.defaultProps = {
  useMilitaryTime: false,
};

function mapStateToProps(state) {
  const useMilitaryTime =
    PREFERRED_TIME_FORMAT_MILLISEC[twentyFourHourFormat] ===
    getTimeFormat(
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
    );
  return {
    useMilitaryTime,
  };
}

export default connect(
  mapStateToProps,
  null,
)(withLocalize(ScheduleHeaderContainer));
