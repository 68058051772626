// Libs
import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-md-spinner';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import renderIf from 'render-if';
import { camelCase } from 'lodash';

// Components
import { ConnectedStatus, ServerImage } from 'components';
import { Button, CircularProgressBar } from 'lib';

// Icons
import { IconDelete } from 'icons';

// Style

// Utilities
import { DeviceSource, findServerCategory } from 'util/deviceCategory';

// Constants
import { PATH_SERVERS } from 'constants/urlPaths';
import {
  DOWNLOADING,
  DOWNLOADING_COMPLETE,
  REBOOTING_DEVICE,
} from 'constants/SignalTypes';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import {
  accServercardFooter,
  cardFooter,
  disabledServerContainer,
  disabledServerDetailsContainer,
  serverBodyContainer,
  serverContainer,
  serverDetails,
  serverDetailsContainer,
  serverDetailsLineDisabled,
  serverImageContainer,
  serverLabel,
  serverLabelDisabled,
  serverTile,
  uploadProgress,
  uploadProgressBar,
  uploadStatusText,
} from './styles.css';
import { SERVER_TILE_ID } from './constants';

const serverLabelStyle = {
  color: '#000',
  textDecoration: 'none',
};

const idBtnDeleteServer = 'deleteBtn';

const serverPropTypes = PropTypes.shape({
  ConnectionState: PropTypes.string,
  IPAddress: PropTypes.string,
  Id: PropTypes.string,
  Model: PropTypes.string,
  Name: PropTypes.string,
});

const upgradePropTypes = PropTypes.shape({
  status: PropTypes.string,
});

const UpgradeContent = ({ server, upgrade }) => {
  const firmwareDetails = () => {
    switch (upgrade.status) {
      case DOWNLOADING:
        return (
          <Translate
            data={{ speed: upgrade.speed }}
            id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_DOWNLOADING_SPEED"
          />
        );
      case DOWNLOADING_COMPLETE:
        return (
          <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_INSTALLING_UPDATE" />
        );
      case REBOOTING_DEVICE:
        return <Translate id="DEVICE_DETAILS.GENERAL_TAB.REBOOTING_DEVICE" />;
      default:
        return null;
    }
  };

  return (
    <div className={uploadProgress}>
      <div className={serverImageContainer}>
        {upgrade.status === DOWNLOADING ? (
          <CircularProgressBar
            className={uploadProgressBar}
            percentage={upgrade.progress}
          />
        ) : (
          <div className={uploadProgressBar}>
            <Spinner
              borderSize={10}
              duration={2000}
              singleColor="#5DB6FF"
              size="80"
            />
          </div>
        )}
      </div>
      <div className={serverBodyContainer}>
        <div className={serverDetails}>
          <div className={serverLabel}> {server.Name} </div>
          <p>{server.Model}</p>
          <p>{server.IPAddress}</p>
        </div>
        <span className={uploadStatusText}>
          <h1>
            <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_UPDATE_TITLE" />
          </h1>
          {firmwareDetails()}
        </span>
      </div>
    </div>
  );
};

UpgradeContent.propTypes = {
  server: serverPropTypes.isRequired,
  upgrade: upgradePropTypes.isRequired,
};

const ServerCard = ({
  canDeleteSuspendedDevice,
  hasValidSubscription,
  onDeleteClick,
  server,
  upgrade,
}) => {
  const isUpgrading = !!upgrade.status;
  const isBlue = findServerCategory(server) === DeviceSource.BLUE;
  const isConnected = server.ConnectionState === CAMERA_CONNECTED;
  return (
    <div
      className={serverTile}
      id={`${SERVER_TILE_ID}-${camelCase(server.Name)}`}
    >
      {isBlue && hasValidSubscription ? (
        <div className={serverContainer}>
          {isUpgrading ? (
            <Link style={serverLabelStyle} to={`${PATH_SERVERS}/${server.Id}`}>
              <UpgradeContent server={server} upgrade={upgrade} />
            </Link>
          ) : (
            <div className={serverBodyContainer}>
              <Link
                style={serverLabelStyle}
                to={`${PATH_SERVERS}/${server.Id}`}
              >
                <ServerImage serverModel={server.Model} />
                <div className={serverDetailsContainer}>
                  <div className={serverDetails}>
                    <div className={serverLabel} title={server.Name}>
                      {server.Name}
                    </div>
                    <p>{server.Model}</p>
                    <p>{server.IPAddress}</p>
                  </div>
                  <ConnectedStatus
                    connected={isConnected}
                    deviceType="server"
                    statusText={
                      <Translate
                        id={`SERVER.STATUS.${server.ConnectionState}`}
                      />
                    }
                  />
                </div>
              </Link>
              <div className={cardFooter}>
                <Button
                  icon
                  id={idBtnDeleteServer}
                  inputType="button"
                  onClick={onDeleteClick}
                  text={<Translate id="BUTTONS.DELETE" />}
                >
                  <IconDelete height="12px" />
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={disabledServerContainer}>
          <ServerImage serverModel={server.Model} />
          <div className={disabledServerDetailsContainer}>
            <div className={serverLabelDisabled} title={server.Name}>
              {server.Name}
            </div>
            <div className={serverDetailsLineDisabled}>
              <p>{server.Model}</p>
              <p>{server.IPAddress}</p>
            </div>
            <ConnectedStatus
              connected={isConnected}
              deviceType="server"
              statusText={
                <Translate id={`CAMERA.STATUS.${server.ConnectionState}`} />
              }
            />
          </div>
          <div className={accServercardFooter}>
            {renderIf(canDeleteSuspendedDevice)(
              <Button
                icon
                id={idBtnDeleteServer}
                inputType="button"
                onClick={onDeleteClick}
                text="Delete Button"
              >
                <IconDelete height="12px" />
              </Button>,
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ServerCard.propTypes = {
  canDeleteSuspendedDevice: PropTypes.bool.isRequired,
  hasValidSubscription: PropTypes.bool.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  server: serverPropTypes.isRequired,
  upgrade: upgradePropTypes.isRequired,
};

export default ServerCard;
