import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_SERVERS } from 'constants/urlPaths';
import { TABS_CONNECTED, TABS_DISCONNECTED } from './constants';

const ServerDetailsNavMenu = props => {
  const { isDisconnected, serverId, translate } = props;

  const tabs = isDisconnected ? TABS_DISCONNECTED : TABS_CONNECTED;
  return (
    <NavTabs
      tabs={tabs.map(tab => ({
        id: tab.id,
        key: tab.id,
        label: translate(tab.label),
        path: `${PATH_SERVERS}/${serverId}${tab.path}`,
      }))}
    />
  );
};

ServerDetailsNavMenu.propTypes = {
  isDisconnected: PropTypes.bool.isRequired,
  serverId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(ServerDetailsNavMenu);
