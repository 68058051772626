// Libs
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Util
import {
  convertTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
} from 'util/convertTimeTo';
import { withLocalize } from 'react-localize-redux';

// Styles
import {
  cellText,
  preformattedCellText,
  truncatedText,
} from 'sharedStyles/tables.css';

const ShowDateTime = ({
  datetime,
  preferredLongDateFormat,
  preferredTimeFormat,
  timeType,
  timeZone,
  translate,
  truncate,
}) => {
  return (
    <div
      className={`${cellText} ${preformattedCellText} ${truncate &&
        truncatedText}`}
    >
      {datetime
        ? convertTimeTo(
            datetime,
            timeType,
            timeZone,
            getPreferredLongDateTimeFormat(
              preferredLongDateFormat,
              preferredTimeFormat,
            ) || getFormatForActiveLocale(),
          )
        : translate('ALARMS.STATUS.UNAVAILABLE_LABEL')}
    </div>
  );
};

ShowDateTime.propTypes = {
  preferredLongDateFormat: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
};

ShowDateTime.defaultProps = {
  preferredLongDateFormat: '',
  preferredTimeFormat: '',
};

function mapStateToProps(state) {
  return {
    preferredLongDateFormat:
      state.user.profile.LocalizationPreference.PreferredLongDateFormat,
    preferredTimeFormat:
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
  };
}

export default connect(mapStateToProps, null)(withLocalize(ShowDateTime));
