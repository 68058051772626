import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

// Styles
import {
  tooltipTrigger,
  tooltipWrapper,
  tooltipWrapperArrowLeft,
} from './styles.css';

// Constants
// Not enough of these to justify a consts file yet
const toggleMethods = {
  click: 'click',
  hover: 'hover',
};

const arrowPositionValues = {
  left: 'left',
  right: 'right',
};

class Tooltip extends React.Component {
  /**
   * Unlike the popover or dropdown components,
   * tooltip is designed to display a sinlge line of text.
   * Tooltip can be triggered on hover or on click,
   * and the offest is adjustable to allow for a variety of trigger elements
   * Tooltip arrow can be set to left or right using 'arrowPosition' prop,
   * by default it is right.
   */

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
  }

  renderStyles = () => {
    // Some styles are set from prop values
    // and must be inline rather than in the stylesheet
    const { offsetRight, offsetTop, whiteSpace, width } = this.props;
    let styles = {
      right: `${offsetRight}px`,
      top: `${offsetTop}px`,
      whiteSpace,
    };
    if (width) {
      styles = {
        ...styles,
        padding: '5px 15px',
        width: `${width}px`,
      };
    }
    return styles;
  };

  handleClickOutside = () => {
    this.hideTooltip();
  };

  showTooltip = () => {
    this.setState({ showTooltip: true });
  };

  hideTooltip = () => {
    this.setState({ showTooltip: false });
  };

  toggleTooltip = () => {
    const { showTooltip } = this.state;

    this.setState({ showTooltip: !showTooltip });
  };

  render() {
    const { arrowPostion, children, message, toggleMethod } = this.props;
    const { showTooltip } = this.state;

    return (
      <span
        className={tooltipTrigger}
        onClick={
          toggleMethod === toggleMethods.click ? this.toggleTooltip : null
        }
        onMouseEnter={
          toggleMethod === toggleMethods.hover ? this.showTooltip : null
        }
        onMouseLeave={
          toggleMethod === toggleMethods.hover ? this.hideTooltip : null
        }
        role="button"
        tabIndex={0}
      >
        {children}
        {showTooltip ? (
          <div
            className={
              arrowPostion === arrowPositionValues.left
                ? `${tooltipWrapper} ${tooltipWrapperArrowLeft}`
                : tooltipWrapper
            }
            style={this.renderStyles()}
          >
            {message}
          </div>
        ) : null}
      </span>
    );
  }
}

Tooltip.defaultProps = {
  arrowPostion: arrowPositionValues.right,
  children: null,
  message: '',
  offsetRight: -20,
  offsetTop: 30,
  toggleMethod: toggleMethods.hover,
  whiteSpace: 'normal',
  width: undefined,
};

Tooltip.propTypes = {
  arrowPostion: PropTypes.string,
  children: PropTypes.node,
  message: PropTypes.string,
  offsetRight: PropTypes.number,
  offsetTop: PropTypes.number,
  toggleMethod: PropTypes.oneOf([toggleMethods.hover, toggleMethods.click]),
  whiteSpace: PropTypes.string,
  width: PropTypes.number,
};

export default onClickOutside(Tooltip);
