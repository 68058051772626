exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!./brushes.css"), undefined);

// module
exports.push([module.id, ".isWaiting___DLFCh:after {\r\n  overflow: hidden;\r\n  display: inline-block;\r\n  vertical-align: bottom;\r\n  -webkit-animation: ellipsis___1Woxl steps(4,end) 2500ms infinite;\r\n  animation: ellipsis___1Woxl steps(4,end) 2500ms infinite;\r\n  content: \"\\2026\\2026\"; /* ascii code for the ellipsis character */\r\n  width: 0px;\r\n}\r\n@keyframes ellipsis___1Woxl {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n\r\n@-webkit-keyframes ellipsis___1Woxl {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n\r\n.isInputError___1hgXo {\r\n}\r\n\r\n.isHidden___LNUcW {\r\n  display: none;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/sharedStyles/isState.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,uBAAuB;EACvB,iEAAyD;EACzD,yDAAiD;EACjD,sBAAsB,CAAC,2CAA2C;EAClE,WAAW;CACZ;AACD;EACE;IACE,cAAc;GACf;CACF;;AAED;EACE;IACE,cAAc;GACf;CACF;;AAED;CAEC;;AAED;EACE,cAAc;CACf","file":"isState.css","sourcesContent":[".isWaiting:after {\r\n  overflow: hidden;\r\n  display: inline-block;\r\n  vertical-align: bottom;\r\n  -webkit-animation: ellipsis steps(4,end) 2500ms infinite;\r\n  animation: ellipsis steps(4,end) 2500ms infinite;\r\n  content: \"\\2026\\2026\"; /* ascii code for the ellipsis character */\r\n  width: 0px;\r\n}\r\n@keyframes ellipsis {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n\r\n@-webkit-keyframes ellipsis {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n\r\n.isInputError {\r\n  composes: errorBorderColor from './brushes.css';\r\n}\r\n\r\n.isHidden {\r\n  display: none;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"isWaiting": "isWaiting___DLFCh",
	"ellipsis": "ellipsis___1Woxl",
	"isInputError": "isInputError___1hgXo " + require("-!../../node_modules/css-loader/index.js??ref--6-1!./brushes.css").locals["errorBorderColor"] + "",
	"isHidden": "isHidden___LNUcW"
};