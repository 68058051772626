import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'lib';
import {
  PATH_ACCOUNTS,
  PATH_ALARMS,
  PATH_BOOKMARKS,
  PATH_DASHBOARD,
  PATH_DEVICES,
  PATH_HEALTH_MONITOR,
  PATH_REPORTS,
  PATH_VIEWS,
} from 'constants/urlPaths';
import getPathName from 'util/getPathName';
import {
  IconGear,
  IconNavBookmarks,
  IconNavDashboard,
  IconNavHealthMonitor,
  IconNavNotifications,
  IconNavOrgMgmt,
  IconNavViews,
} from 'icons';

import { IC_DATA_GRAPH } from 'constants/iconNames';
import {
  navMenu,
  navMenuItem,
  navMenuItemActive,
  navMenuItemImage,
} from './NavMenu.css';

import * as consts from './NavMenu.constants';

const NavMenu = ({
  canViewAccounts,
  canViewAlarms,
  canViewBookmarks,
  canViewDashboard,
  canViewDevices,
  canViewHealthMonitoring,
  canViewReports,
  canViewViews,
  path,
  translate,
}) => {
  const urlPath = getPathName(path).toLowerCase();

  const iconReports = () => {
    return (
      <Icon
        color={
          urlPath.includes(PATH_REPORTS.toLowerCase())
            ? consts.colorActive
            : consts.colorInactive
        }
        iconClass={navMenuItemImage}
        id={IC_DATA_GRAPH}
        title="NAV_MENU.TITLES.REPORTS"
      />
    );
  };

  const TABS = [
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_DASHBOARD)
            ? consts.colorActive
            : consts.colorInactive,
        },
        tag: IconNavDashboard,
      },
      link: {
        id: consts.idDashboardLink,
        title: translate('NAV_MENU.TITLES.DASHBOARD'),
        to: PATH_DASHBOARD,
      },
      permission: canViewDashboard,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_VIEWS)
            ? consts.colorActive
            : consts.colorInactive,
        },
        tag: IconNavViews,
      },
      link: {
        id: consts.idViewsLink,
        title: translate('NAV_MENU.TITLES.VIEWS'),
        to: PATH_VIEWS,
      },
      permission: canViewViews,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_BOOKMARKS)
            ? consts.colorActive
            : consts.colorInactive,
        },
        tag: IconNavBookmarks,
      },
      link: {
        id: consts.idBookmarksLink,
        title: translate('NAV_MENU.TITLES.BOOKMARKS'),
        to: PATH_BOOKMARKS,
      },
      permission: canViewBookmarks,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_ALARMS)
            ? consts.colorActive
            : consts.colorInactive,
        },
        tag: IconNavNotifications,
      },
      link: {
        id: consts.idNotificationsLink,
        title: translate('NAV_MENU.TITLES.NOTIFICATIONS'),
        to: PATH_ALARMS,
      },
      permission: canViewAlarms,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_ACCOUNTS)
            ? consts.colorActive
            : consts.colorInactive,
        },
        tag: IconNavOrgMgmt,
      },
      link: {
        id: consts.idAccountMgtLink,
        title: translate('NAV_MENU.TITLES.ACCOUNT'),
        to: PATH_ACCOUNTS,
      },
      permission: canViewAccounts,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_DEVICES)
            ? consts.colorActive
            : consts.colorInactive,
          height: '24px',
          title: 'NAV_MENU.TITLES.DEVICES',
          width: '24px',
        },
        tag: IconGear,
      },
      link: {
        id: consts.idDevicesLink,
        title: translate('NAV_MENU.TITLES.DEVICES'),
        to: PATH_DEVICES,
      },
      permission: canViewDevices,
    },
    {
      icon: {
        props: {
          className: navMenuItemImage,
          fill: urlPath.includes(PATH_HEALTH_MONITOR.toLowerCase())
            ? consts.colorActive
            : consts.colorInactive,
          title: translate('NAV_MENU.TITLES.HEALTH_MONITOR'),
        },
        tag: IconNavHealthMonitor,
      },
      link: {
        id: consts.idHealthMonitoringLink,
        title: translate('NAV_MENU.TITLES.HEALTH_MONITOR'),
        to: PATH_HEALTH_MONITOR,
      },
      permission: canViewHealthMonitoring,
    },
    {
      icon: {
        tag: iconReports,
      },
      link: {
        id: consts.idReportsLink,
        title: translate('NAV_MENU.TITLES.REPORTS'),
        to: PATH_REPORTS,
      },
      permission: canViewReports,
    },
  ];

  return (
    <ul className={navMenu}>
      {TABS.map(tab => {
        const IconElement = tab.icon.tag;
        return tab.permission ? (
          <li key={tab.link.id} className={navMenuItem}>
            <NavLink {...tab.link} activeClassName={navMenuItemActive}>
              <IconElement {...tab.icon.props} />
            </NavLink>
          </li>
        ) : (
          ''
        );
      })}
    </ul>
  );
};

NavMenu.propTypes = {
  canViewAccounts: PropTypes.bool,
  canViewAlarms: PropTypes.bool,
  canViewBookmarks: PropTypes.bool,
  canViewDashboard: PropTypes.bool,
  canViewDevices: PropTypes.bool,
  canViewHealthMonitoring: PropTypes.bool,
  canViewReports: PropTypes.bool,
  canViewViews: PropTypes.bool,
  path: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

NavMenu.defaultProps = {
  canViewAccounts: false,
  canViewAlarms: false,
  canViewBookmarks: false,
  canViewDashboard: false,
  canViewDevices: false,
  canViewHealthMonitoring: false,
  canViewReports: false,
  canViewViews: false,
  path: '',
};

export default NavMenu;
