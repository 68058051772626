import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@avo-svtr/savitar';

// Actions
import { hideModal, showModal } from 'actions/modal';

// Utils
import { translatedConnect } from 'util/translatedConnect';

// Icons
import * as modalTypes from 'constants/ModalTypes';
import { IC_ADD_BOOKMARK } from 'constants/iconNames';

// Components
import { Button, Icon } from 'lib';

// Styles
import {
  addBookmarkIcon,
  bookmarkButton,
  buttonWrapper,
} from './savitarPlayer.styles.css';

const BookmarkButton = ({
  actions,
  hostId,
  isBookmarksModalOpen,
  translate,
}) => {
  const toggleBookmarkModal = useCallback(() => {
    if (isBookmarksModalOpen) {
      actions.hideModal();
    } else {
      actions.showModal(modalTypes.EDIT_BOOKMARK, { modalKey: hostId });
    }
  }, [actions, hostId, isBookmarksModalOpen]);
  
  return (
    <div
      className={buttonWrapper}
      title={translate('BOOKMARKS.ADD_BOOKMARK_TOOLTIP')}
    >
      <Tooltip content={translate('BOOKMARKS.ADD_BOOKMARK_TOOLTIP')}>
        <div>
          <Button
            buttonClass={bookmarkButton}
            buttonType="icon"
            id="idBookmarkBtn"
            inputType="button"
            onClick={toggleBookmarkModal}
          >
            <Icon iconClass={addBookmarkIcon} id={IC_ADD_BOOKMARK} />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isBookmarksModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.EDIT_BOOKMARK,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

BookmarkButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  hostId: PropTypes.string.isRequired,
  isBookmarksModalOpen: PropTypes.bool,
  translate: PropTypes.func.isRequired,
};

BookmarkButton.defaultProps = {
  isBookmarksModalOpen: false,
};

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(BookmarkButton);
