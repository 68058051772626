// IsFetching
export const IS_FETCHING = 'IS_FETCHING';
export const RESET_IS_FETCHING = 'RESET_IS_FETCHING';

// IsSaving
export const IS_SAVING = 'IS_SAVING';

// AFT
export const AFT = {
  GET_CAMERA_LIST: 'GET_AFT_CAMERA_LIST',
  GET_SERVER_LIST: 'GET_AFT_SERVER_LIST',
  GET_SITEVIEW_TREE: 'GET_AFT_SITEVIEW_TREE',
  RECEIVE_ACC_SERVER_LIST: 'AFT_RECEIVE_ACC_SERVER_LIST',
  RECEIVE_CAMERA_LIST: 'AFT_RECEIVE_CAMERA_LIST',
  RECEIVE_SITEVIEW_TREE: 'AFT_RECEIVE_SITEVIEW_TREE',
  REMOVE_CAMERAS: 'AFT_REMOVE_CAMERAS',
  UPSERT_CAMERA_LIST: 'AFT_UPSERT_CAMERA_LIST',
};

// Devices
export const GET_ALL_DEVICES = 'GET_ALL_DEVICES';
export const GET_ALL_SERVERS_AND_CAMERAS = 'GET_ALL_SERVERS_AND_CAMERAS';
export const RECEIVE_ALL_SERVERS_AND_CAMERAS =
  'RECEIVE_ALL_SERVERS_AND_CAMERAS';
export const ADD_DEVICE = 'ADD_DEVICE';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const REMOVE_DEVICE = 'REMOVE_DEVICE';
export const GET_DEVICES = 'GET_DEVICES';
export const IS_FETCHING_DEVICE_DATA = 'IS_FETCHING_DEVICE_DATA';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_EVENTS = 'GET_EVENTS';
export const RECEIVE_DEVICES = 'RECEIVE_DEVICES';
export const RECEIVE_DEVICE = 'RECEIVE_DEVICE';
export const GET_LOCATION_DEVICES = 'GET_LOCATION_DEVICES';
export const IS_FETCHING_LOCATION_DEVICES = 'IS_FETCHING_LOCATION_DEVICES';
export const RECEIVE_LOCATION_DEVICES = 'RECEIVE_LOCATION_DEVICES';
export const GATEWAY_DEVICE_POE = 'GATEWAY_DEVICE_POE';
export const RECEIVE_DEVICE_POE = 'RECEIVE_DEVICE_POE';
export const GATEWAY_DEVICE_POE_INFO = 'GATEWAY_DEVICE_POE_INFO';
export const PTZ_DRAG_TO_ZOOM = 'PTZ_DRAG_TO_ZOOM';
export const IS_FETCHING_DEVICE_CAMERAS = 'IS_FETCHING_DEVICE_CAMERAS';
export const IS_FETCHING_DEVICE_SNAPSHOTS = 'IS_FETCHING_DEVICE_SNAPSHOTS';
export const CLEAR_LOCATION_DEVICES = 'CLEAR_LOCATION_DEVICES';
export const CLAIM_DEVICE = 'CLAIM_DEVICE';
export const CLAIM_DEVICE_BY_ID = 'CLAIM_DEVICE_BY_ID';
export const CLAIM_DEVICE_SUCCESS = 'CLAIM_DEVICE_SUCCESS';
export const DEVICE_FETCH_COMPLETED = 'DEVICE_FETCH_COMPLETED';
export const UPGRADE_DEVICE_FIRMWARE = 'UPGRADE_DEVICE_FIRMWARE';
export const UPGRADE_DEVICE_PROGRESS = 'UPGRADE_DEVICE_PROGRESS';
export const GET_LATEST_FIRMWARE = 'GET_LATEST_FIRMWARE';
export const RECEIVE_LATEST_FIRMWARE = 'RECEIVE_LATEST_FIRMWARE';
export const GET_UPGRADEABLE_DEVICES = 'GET_UPGRADEABLE_DEVICES';
export const IS_RECEIVING_SITEIDSWITHUPGRADES =
  'IS_RECEIVING_SITEIDSWITHUPGRADES';
export const IS_FETCHING_SITEIDSWITHUPGRADES =
  'IS_FETCHING_SITEIDSWITHUPGRADES';
export const IS_FETCHING_LATEST_FIRMWARE = 'IS_FETCHING_LATEST_FIRMWARE';
export const RESTART_APPLIANCE = 'RESTART_APPLIANCE';
export const REQUEST_DEVICE_LOGS = 'REQUEST_DEVICE_LOGS'; // Request the server create log file for download
export const GET_DEVICE_LOGS = 'GET_DEVICE_LOGS'; // Get log download data
export const IS_FETCHING_DEVICE_LOGS = 'IS_FETCHING_DEVICE_LOGS'; // True from request until download uri is available
export const RECEIVE_DEVICE_LOGS = 'RECEIVE_DEVICE_LOGS';
export const IS_FETCHING_DEVICE_ENTITIES = 'IS_FETCHING_DEVICE_ENTITIES';
export const GET_DEVICE_ENTITIES = 'GET_DEVICE_ENTITIES';
export const RECEIVE_DEVICE_ENTITIES = 'RECEIVE_DEVICE_ENTITIES';
export const TRIGGER_DIGITAL_OUT = 'TRIGGER_DIGITAL_OUT';
export const UPDATE_LINKED_CAMERAS = 'UPDATE_LINKED_CAMERAS';
export const GET_ENTITY_SETTINGS = 'GET_ENTITY_SETTINGS';
export const RECEIVE_ENTITY_SETTINGS = 'RECEIVE_ENTITY_SETTINGS';
export const IS_FETCHING_ENTITY_SETTINGS = 'IS_FETCHING_ENTITY_SETTINGS';
export const UPDATE_ENTITY_SETTINGS = 'UPDATE_ENTITY_SETTINGS';
export const GET_SERVER_TIME_ZONE_LIST = 'GET_SERVER_TIME_ZONE_LIST';
export const RECEIVE_SERVER_TIME_ZONE_LIST = 'RECEIVE_SERVER_TIME_ZONE_LIST';
// Devices

// Cameras
export const SELECT_DISCOVERED_CAMERA = 'SELECT_DISCOVERED_CAMERA';
export const CONNECT_CAMERA = 'CONNECT_CAMERA';
export const DELETE_CAMERA = 'DELETE_CAMERA';
export const LOGIN_CAMERA = 'LOGIN_CAMERA';
export const ENABLE_CAMERA = 'ENABLE_CAMERA';
export const DISABLE_CAMERA = 'DISABLE_CAMERA';
export const UNMANAGE_CAMERA = 'UNMANAGE_CAMERA';
export const PTZ_PRESET = 'PTZ_PRESET';
export const IS_FETCHING_PTZ_PRESET = 'IS_FETCHING_PTZ_PRESET';
export const ARM_CAMERA = 'ARM_CAMERA';
export const DISARM_CAMERA = 'DISARM_CAMERA';
export const ENABLE_CAMERA_FEATURE = 'ENABLE_CAMERA_FEATURE';
export const DISABLE_CAMERA_FEATURE = 'DISABLE_CAMERA_FEATURE';

export const MANUALLY_DISCOVER_CAMERA = 'MANUALLY_DISCOVER_CAMERA';
export const MANUALLY_DISCOVER_RTSP_CAMERA = 'MANUALLY_DISCOVER_RTSP_CAMERA';
export const MANUALLY_DISCOVER_CAMERAS_BY_RANGE =
  'MANUALLY_DISCOVER_CAMERAS_BY_RANGE';

export const UPDATE_DISCOVERED_CAMERAS_CREDENTIALS =
  'UPDATE_DISCOVERED_CAMERAS_CREDENTIALS';
export const FDS_PASSWORD_RESET = 'FDS_PASSWORD_RESET';

export const GET_CAMERA = 'GET_CAMERA';
export const GET_CAMERAS = 'GET_CAMERAS';
export const RECEIVE_CAMERAS = 'RECEIVE_CAMERAS';
export const GET_ALL_CAMERAS = 'GET_ALL_CAMERAS';
export const RECEIVE_CAMERAS_FOR_DEVICE = 'RECEIVE_CAMERAS_FOR_DEVICE';
export const REQUEST_DISCOVERED_CAMERAS = 'REQUEST_DISCOVERED_CAMERAS';
export const GET_DISCOVERED_CAMERAS = 'GET_DISCOVERED_CAMERAS';
export const GET_DISCOVERED_CAMERAS_RESULT = 'GET_DISCOVERED_CAMERAS_RESULT';
export const IS_FETCHING_DISCOVERED_CAMERAS = 'IS_FETCHING_DISCOVERED_CAMERAS';
export const RECEIVE_DISCOVERED_CAMERAS = 'RECEIVE_DISCOVERED_CAMERAS';
export const IS_FETCHING_SUPPORTED_CAMERAS = 'IS_FETCHING_SUPPORTED_CAMERAS';
export const RECEIVE_SUPPORTED_CAMERAS = 'RECEIVE_SUPPORTED_CAMERAS';
export const GET_CONNECTED_CAMERAS = 'GET_CONNECTED_CAMERAS';
export const RECEIVE_CONNECTED_CAMERAS = 'RECEIVE_CONNECTED_CAMERAS';
export const CLEAR_CAMERAS = 'CLEAR_CAMERAS';
export const REMOVE_FROM_CONNECTED_CAMERA = 'REMOVE_FROM_CONNECTED_CAMERA';
export const GET_SUPPORTED_CAMERAS = 'GET_SUPPORTED_CAMERAS';
export const GET_SERVER_CAPABILITIES = 'GET_SERVER_CAPABILITIES';
export const IS_FETCHING_SERVER_CAPABILITIES =
  'IS_FETCHING_SERVER_CAPABILITIES';
export const RECEIVE_SERVER_CAPABILITIES = 'RECEIVE_SERVER_CAPABILITIES';

export const GET_CAMERA_SETTINGS = 'GET_CAMERA_SETTINGS';
export const IS_FETCHING_CAMERA_SETTINGS = 'IS_FETCHING_CAMERA_SETTINGS';
export const GET_RULES = 'GET_RULES';
export const RECEIVE_RULES = 'RECEIVE_RULES';
export const UPDATE_CAMERA_SETTINGS = 'UPDATE_CAMERA_SETTINGS';
export const IS_UPDATING_CAMERA_SETTINGS = 'IS_UPDATING_CAMERA_SETTINGS';
export const DONE_UPDATING_CAMERA_SETTINGS = 'DONE_UPDATING_CAMERA_SETTINGS';
export const GET_ACQUISITION_SETTINGS = 'GET_ACQUISITION_SETTINGS';
export const GET_COMPRESSION_SETTINGS = 'GET_COMPRESSION_SETTINGS';
export const IS_FETCHING_ACQUISITION_DATA = 'IS_FETCHING_ACQUISITION_DATA';
export const IS_FETCHING_COMPRESSION_DATA = 'IS_FETCHING_COMPRESSION_DATA';
export const DONE_FETCHING_ACQUISITION_DATA = 'DONE_FETCHING_ACQUISITION_DATA';
export const DONE_FETCHING_COMPRESSION_DATA = 'DONE_FETCHING_COMPRESSION_DATA';
export const RECEIVE_IMAGE_SETTINGS = 'RECEIVE_IMAGE_SETTINGS';

export const DONE_FETCHING_GATEWAY_GENERAL_SETTINGS =
  'DONE_FETCHING_GATEWAY_GENERAL_SETTINGS';
export const UPDATE_CAMERA_ZOOM = 'UPDATE_CAMERA_ZOOM';
export const UPDATE_CAMERA_FOCUS = 'UPDATE_CAMERA_FOCUS';
export const UPDATE_CAMERA_AUTOFOCUS = 'UPDATE_CAMERA_AUTOFOCUS';
export const UPDATE_CAMERA_FOCUS_RELATIVE = 'UPDATE_CAMERA_FOCUS_RELATIVE';
export const UPDATE_CAMERA_FOCUS_CONTINUOUS = 'UPDATE_CAMERA_FOCUS_CONTINUOUS';
export const UPDATE_CAMERA_PAN_TILT_ZOOM = 'UPDATE_CAMERA_PAN_TILT_ZOOM';
export const RESTART_CAMERA = 'RESTART_CAMERA';
export const REFRESH_SNAPSHOT = 'REFRESH_SNAPSHOT';
export const IS_FETCHING_ANALYTICS_STATUS = 'IS_FETCHING_ANALYTICS_STATUS';
export const GET_ANALYTICS_STATUS = 'GET_ANALYTICS_STATUS';
export const RECEIVE_ANALYTICS_STATUS = 'RECEIVE_ANALYTICS_STATUS';
export const UPDATE_ANALYTICS_STATUS = 'UPDATE_ANALYTICS_STATUS';
export const IS_FETCHING_RECORDING_TIMELINE = 'IS_FETCHING_RECORDING_TIMELINE';
export const GET_RECORDING_TIMELINE = 'GET_RECORDING_TIMELINE';
export const RECEIVE_RECORDING_TIMELINE = 'RECEIVE_RECORDING_TIMELINE';
export const CAMERA_VIDEO_EXPORT = 'CAMERA_VIDEO_EXPORT';
export const CAMERA_VIDEO_GET_STREAM = 'CAMERA_VIDEO_GET_STREAM';
export const RECEIVE_CAMERA_VIDEO_EXPORT = 'RECEIVE_CAMERA_VIDEO_EXPORT';
export const RECEIVE_CAMERA_VIDEO_STREAM = 'RECEIVE_CAMERA_VIDEO_STREAM';
export const ENABLE_TAMPER_EVENTS = 'ENABLE_TAMPER_EVENTS';
export const DISABLE_TAMPER_EVENTS = 'DISABLE_TAMPER_EVENTS';

export const SENT_WEBRTC_REQUEST = 'SENT_WEBRTC_REQUEST';
export const RECEIVED_WEBRTC_RESPONSE = 'RECEIVED_WEBRTC_RESPONSE';
export const RETRY_WEBRTC_REQUEST = 'RETRY_WEBRTC_REQUEST';
export const REMOVE_FAILED_WEBRTC_REQUEST = 'REMOVE_FAILED_WEBRTC_REQUEST';

export const CREATE_PTZ_PRESET = 'CREATE_PTZ_PRESET';
export const GET_PTZ_PRESETS = 'GET_PTZ_PRESETS';
export const IS_FETCHING_PTZ_PRESETS = 'IS_FETCHING_PTZ_PRESETS';
export const DONE_FETCHING_PTZ_PRESETS = 'DONE_FETCHING_PTZ_PRESETS';
export const RECEIVE_PTZ_PRESETS = 'RECEIVE_PTZ_PRESETS';
export const RECEIVE_PTZ_PRESET = 'RECEIVE_PTZ_PRESET';
export const UPDATE_PTZ_PRESET = 'UPDATE_PTZ_PRESET';
export const SELECT_PTZ_PRESET = 'SELECT_PTZ_PRESET';
export const DELETE_PTZ_PRESET = 'DELETE_PTZ_PRESET';
export const GO_TO_HOME_PTZ_PRESET = 'GO_TO_HOME_PTZ_PRESET';

export const ADD_PTZ_TOUR = 'ADD_PTZ_TOUR';
export const UPDATE_PTZ_TOUR = 'UPDATE_PTZ_TOUR';
export const GET_PTZ_TOURS = 'GET_PTZ_TOURS';
export const RECEIVE_PTZ_TOURS = 'RECEIVE_PTZ_TOURS';
export const IS_FETCHING_PTZ_TOURS = 'IS_FETCHING_PTZ_TOURS';
export const START_PTZ_TOUR = 'START_PTZ_TOUR';
export const STOP_PTZ_TOUR = 'STOP_PTZ_TOUR';
export const SET_ACTIVE_PTZ_FUNCTION = 'SET_ACTIVE_PTZ_FUNCTION';

export const GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS =
  'GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS';
// Cameras

// Stream overlays
export const SET_RULE = 'SET_RULE';
export const RESET_RULE = 'RESET_RULE';
export const RESET_ALL_RULES = 'RESET_ALL_RULES';
export const DELETE_RULE = 'DELETE_RULE';
export const ADD_RULE = 'ADD_RULE';
export const CLEAR_RULE_EDITS = 'CLEAR_RULE_EDITS';
export const SET_RULE_CANVAS_VISIBILITY = 'SET_RULE_CANVAS_VISIBILITY';
export const SET_ZONE = 'SET_ZONE';
export const ADD_ZONE = 'ADD_ZONE';
export const RESET_ALL_ZONES = 'RESET_ALL_ZONES';
export const DELETE_ZONE = 'DELETE_ZONE';
// Stream overlays

export const RECEIVE_CLUSTERS = 'RECEIVE_CLUSTERS';
export const RECEIVE_ALL_CLUSTERS = 'RECEIVE_ALL_CLUSTERS';
export const REMOVE_CLUSTER = 'REMOVE_CLUSTER';
export const RECEIVE_CLUSTER = 'RECEIVE_CLUSTER';
export const GET_CLUSTER = 'GET_CLUSTER';
export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const RECEIVE_BOOKMARKS = 'RECEIVE_BOOKMARKS';
export const IS_FETCHING_BOOKMARKS = 'IS_FETCHING_BOOKMARKS';
export const GET_BOOKMARKS_BY_LOCATION = 'GET_BOOKMARKS_BY_LOCATION';

// User
export const BUSY = 'BUSY';
export const NOT_BUSY = 'NOT_BUSY';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_USER = 'SET_USER';
export const SET_USERNAME = 'SET_USERNAME';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_USERS_PUBLIC_INFO = 'RECEIVE_USERS_PUBLIC_INFO';
export const EDIT_USER = 'EDIT_USER';
export const UNSET_USER = 'UNSET_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_BANNER_NOTICES = 'GET_BANNER_NOTICES';
export const RECEIVE_BANNER_NOTICES = 'RECEIVE_BANNER_NOTICES';
export const REGISTER = 'REGISTER';
export const REGISTER_UNPROVISIONED = 'REGISTER_UNPROVISIONED';
export const RESEND_INVITE = 'RESEND_INVITE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const USER_MODAL_SHOW = 'USER_MODAL_SHOW';
export const USER_MODAL_HIDE = 'USER_MODAL_HIDE';
export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE';
export const GET_USERS_PUBLIC_INFO = 'GET_USERS_PUBLIC_INFO';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const GET_SCHEDULES = 'GET_SCHEDULES';
export const RECEIVE_SCHEDULES = 'RECEIVE_SCHEDULES';
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const CUSTOMER_DATA_FETCH_COMPLETED = 'CUSTOMER_DATA_FETCH_COMPLETED';
export const EULA_ACCEPTANCE_REQUIRED = 'EULA_ACCEPTANCE_REQUIRED';
export const GET_CURRENT_ORGANIZATION = 'GET_CURRENT_ORGANIZATION';
export const RECEIVE_CURRENT_ORGANIZATION = 'RECEIVE_CURRENT_ORGANIZATION';
export const UPDATE_NOT_3_OLD_PASSWORD_STATUS =
  'UPDATE_NOT_3_OLD_PASSWORD_STATUS';
export const REMOVE_DELETED_CUSTOMER = 'REMOVE_DELETED_CUSTOMER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
// User

// Sites
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SCOPED = 'GET_LOCATIONS_SCOPED';
export const GET_CLUSTERS = 'GET_CLUSTERS';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const TOGGLE_LOCATION_MODAL = 'TOGGLE_LOCATION_MODAL';
export const ENABLE_MONITORING = 'ENABLE_MONITORING';
export const DISABLE_MONITORING = 'DISABLE_MONITORING';
export const ADD_LOCATION = 'ADD_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const LOCATION_UPDATE = 'LOCATION_UPDATE';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';
export const SUSPEND_LOCATION = 'SUSPEND_LOCATION';
export const UNSUSPEND_LOCATION = 'UNSUSPEND_LOCATION';
export const GET_SUBSCRIBER_SERVICE_PACKAGES =
  'GET_SUBSCRIBER_SERVICE_PACKAGES';
export const IS_FETCHING_SUBSCRIBER_SERVICE_PACKAGES =
  'IS_FETCHING_SUBSCRIBER_SERVICE_PACKAGES';
export const RECEIVE_SUBSCRIBER_SERVICE_PACKAGES =
  'RECEIVE_SUBSCRIBER_SERVICE_PACKAGES';

/** Pending Site Requests */

export const GET_PENDING_SITE_REQUESTS = 'GET_PENDING_SITE_REQUESTS';
export const IS_FETCHING_PENDING_SITE_REQUESTS =
  'IS_FETCHING_PENDING_SITE_REQUESTS';
export const RECEIVE_PENDING_SITE_REQUESTS = 'RECEIVE_PENDING_SITE_REQUESTS';
export const PENDING_SITE_REQUESTS_CREATE = 'PENDING_SITE_REQUESTS_CREATE';
export const PENDING_SITE_REQUESTS_ACCEPT = 'PENDING_SITE_REQUESTS_ACCEPT';
export const PENDING_SITE_REQUESTS_DECLINE = 'PENDING_SITE_REQUESTS_DECLINE';
/** Pending Site Requests */
// Sites

// Subscriptions
export const GET_ALL_SERVICE_PACKAGES = 'GET_ALL_SERVICE_PACKAGES';
export const RECEIVE_ALL_SERVICE_PACKAGES = 'RECEIVE_ALL_SERVICE_PACKAGES';
export const GET_SERVICE_PACKAGE_FAMILIES = 'GET_SERVICE_PACKAGE_FAMILIES';
export const GET_DEALER_SERVICE_PACKAGE_FAMILIES =
  'GET_DEALER_SERVICE_PACKAGE_FAMILIES';
export const RECEIVE_DEALER_SERVICE_PACKAGE_FAMILIES =
  'RECEIVE_DEALER_SERVICE_PACKAGE_FAMILIES';
export const GET_SERVICE_PACKAGES = 'GET_SERVICE_PACKAGES';
export const GET_SERVICE_PACKAGE = 'GET_SERVICE_PACKAGE';
export const GET_SERVICE_PACKAGE_BY_CODE = 'GET_SERVICE_PACKAGE_BY_CODE';
export const RECEIVE_SERVICE_PACKAGES = 'RECEIVE_SERVICE_PACKAGES';
export const RECEIVE_SERVICE_PACKAGE = 'RECEIVE_SERVICE_PACKAGE';
export const RECEIVE_DEALER_SERVICE_PACKAGES =
  'RECEIVE_DEALER_SERVICE_PACKAGES';
export const EDIT_SUBSCRIPTION_TERM_LENGTH = 'EDIT_SUBSCRIPTION_TERM_LENGTH';
export const UPGRADE_SERVICE_PACKAGE = 'UPGRADE_SERVICE_PACKAGE';
export const DOWNGRADE_SERVICE_PACKAGE = 'DOWNGRADE_SERVICE_PACKAGE';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS = 'RECEIVE_SUBSCRIPTIONS';
export const GET_ORG_SUBSCRIPTIONS = 'GET_ORG_SUBSCRIPTIONS';
export const RECEIVE_ORG_SUBSCRIPTIONS = 'RECEIVE_ORG_SUBSCRIPTIONS';
export const IS_UPDATING_DEALER_HEALTH_DATA = 'IS_UPDATING_DEALER_HEALTH_DATA';
// Subscriptions

// Dashboard
export const GET_DEALER_SUBSCRIBER_STATS = 'GET_DEALER_SUBSCRIBER_STATS';
export const IS_FETCHING_DEALER_SUBSCRIBER_STATS =
  'IS_FETCHING_DEALER_SUBSCRIBER_STATS';
export const RECEIVE_DEALER_SUBSCRIBER_STATS =
  'RECEIVE_DEALER_SUBSCRIBER_STATS';
export const IS_FETCHING_DEALER_SUBSCRIPTION_LEVELS =
  'IS_FETCHING_DEALER_SUBSCRIPTION_LEVELS';
export const GET_DEALER_SUBSCRIPTION_LEVELS = 'GET_DEALER_SUBSCRIPTION_LEVELS';
export const RECEIVE_DEALER_SUBSCRIPTION_LEVELS =
  'RECEIVE_DEALER_SUBSCRIPTION_LEVELS';
export const IS_FETCHING_SUBSCRIPTION_RENEWAL_PERIODS =
  'IS_FETCHING_SUBSCRIPTION_RENEWAL_PERIODS';
export const GET_SUBSCRIPTION_RENEWAL_PERIODS =
  'GET_SUBSCRIPTION_RENEWAL_PERIODS';
export const RECEIVE_SUBSCRIPTION_RENEWAL_PERIODS =
  'RECEIVE_SUBSCRIPTION_RENEWAL_PERIODS';
export const GET_DEALER_SUBSCRIBER_UPGRADE_SITES =
  'GET_DEALER_SUBSCRIBER_UPGRADE_SITES';
export const IS_FETCHING_DEALER_SUBSCRIBER_UPGRADE_SITES =
  'IS_FETCHING_DEALER_SUBSCRIBER_UPGRADE_SITES';
export const RECEIVE_DEALER_SUBSCRIBER_UPGRADE_SITES =
  'RECEIVE_DEALER_SUBSCRIBER_UPGRADE_SITES';
export const GET_DEALER_SUBSCRIBERS_NEARING_RENEWAL =
  'GET_DEALER_SUBSCRIBERS_NEARING_RENEWAL';
export const IS_FETCHING_DEALER_SUBSCRIBERS_NEARING_RENEWAL =
  'IS_FETCHING_DEALER_SUBSCRIBERS_NEARING_RENEWAL';
export const RECEIVE_DEALER_SUBSCRIBERS_NEARING_RENEWAL =
  'RECEIVE_DEALER_SUBSCRIBERS_NEARING_RENEWAL';
export const GET_DEALER_BILLING_CYCLES = 'GET_DEALER_BILLING_CYCLES';
export const IS_FETCHING_DEALER_BILLING_CYCLES =
  'IS_FETCHING_DEALER_BILLING_CYCLES';
export const RECEIVE_DEALER_BILLING_CYCLES = 'RECEIVE_DEALER_BILLING_CYCLES';
export const SET_CURRENT_BILLING_CYCLE = 'SET_CURRENT_BILLING_CYCLE';
// Dashboard

// Cameragroups
export const SET_CAMERAGROUP = 'SET_CAMERAGROUP';
export const GET_CAMERAGROUP = 'GET_CAMERAGROUP';
export const GET_CAMERAGROUPS = 'GET_CAMERAGROUPS';
export const GET_LOCATION = 'GET_LOCATION';
export const RECEIVE_CAMERAGROUPS = 'RECEIVE_CAMERAGROUPS';
// Cameragroups

// Alarms
export const IS_FETCHING_ALARM_DATA = 'IS_FETCHING_ALARM_DATA';
export const IS_FETCHING_HEALTH_ALARM_DATA = 'IS_FETCHING_HEALTH_ALARM_DATA';
export const IS_FETCHING_SECURITY_ALARM_DATA =
  'IS_FETCHING_SECURITY_ALARM_DATA';
export const IS_FETCHING_SELECTED_ALARM = 'IS_FETCHING_SELECTED_ALARM';
export const RECEIVE_ALARMS = 'RECEIVE_ALARMS';
export const RECEIVE_ALARMS_FOR_FILTERS = 'RECEIVE_ALARMS_FOR_FILTERS';
export const GET_ALARMS = 'GET_ALARMS';
export const GET_ALARMS_STATS = 'GET_ALARMS_STATS';
export const RECEIVE_ALARMS_STATS = 'RECEIVE_ALARMS_STATS';
export const RECEIVE_HEALTH_ALARMS_STATS = 'RECEIVE_HEALTH_ALARMS_STATS';
export const RECEIVE_SECURITY_ALARMS_STATS = 'RECEIVE_SECURITY_ALARMS_STATS';
export const IS_FETCHING_ALARMS_STATS = 'IS_FETCHING_ALARMS_STATS';
export const DONE_FETCHING_ALARMS_STATS = 'DONE_FETCHING_ALARMS_STATS';
export const GET_ALARM = 'GET_ALARM';
export const EDIT_ALARM = 'EDIT_ALARM';
export const GET_ALARM_CLIP_URL = 'GET_ALARM_CLIP_URL';
export const IS_FETCHING_ALARM_CLIP_URL = 'IS_FETCHING_ALARM_CLIP_URL';
export const RECEIVE_ALARM_CLIP_URL = 'RECEIVE_ALARM_CLIP_URL';
export const ALARMS_FILTER_STATUS = 'ALARMS_FILTER_STATUS';
export const ALARMS_FILTER_CAMERA = 'ALARMS_FILTER_CAMERA';
export const ALARMS_FILTER_DATE = 'ALARMS_FILTER_DATE';
export const ALARMS_FILTER_RULE = 'ALARMS_FILTER_RULE';
export const ALARMS_SORT = 'ALARMS_SORT';
export const ALARM_SELECTED = 'ALARM_SELECTED';
export const ALARM_MODAL_SHOW = 'ALARM_MODAL_SHOW';
export const ALARM_MODAL_HIDE = 'ALARM_MODAL_HIDE';
export const ALARM_UPDATE = 'ALARM_UPDATE';
export const CLEAR_ALARMS = 'CLEAR_ALARMS';
export const ALARM_FETCH_DIRECTION_NEXT = 'ALARM_FETCH_DIRECTION_NEXT';
export const ALARM_FETCH_DIRECTION_PREV = 'ALARM_FETCH_DIRECTION_PREV';
export const CLEAR_SELECTED_ALARM = 'CLEAR_SELECTED_ALARM';
export const COMPLETED_FETCHING_ALARM_DATA = 'COMPLETED_FETCHING_ALARM_DATA';
export const COMPLETED_FETCHING_SELECTED_ALARM =
  'COMPLETED_FETCHING_SELECTED_ALARM';
export const SET_WIDGET_VISIBILITY = 'SET_WIDGET_VISIBILITY';
// Alarms

// Settings
export const HIDE_IP_INPUT = 'HIDE_IP_INPUT';
export const SHOW_IP_INPUT = 'SHOW_IP_INPUT';
export const SELECT_CAMERA = 'SELECT_CAMERA';
// Settings

// Breadcrumbs
export const SET_SERVER_BREADCRUMB = 'SET_SERVER_BREADCRUMB';
export const UNSET_SERVER_BREADCRUMB = 'UNSET_SERVER_BREADCRUMB';
export const RECEIVE_SELECTED_CAMERA = 'RECEIVE_SELECTED_CAMERA';
export const RECEIVE_SELECTED_SERVER = 'RECEIVE_SELECTED_SERVER';
// Breadcrumbs

// Feedback
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const TOGGLE_ACCORDION = 'TOGGLE_ACCORDION';
// Feedback

// Filters
export const ADD_FILTER_VALUES = 'ADD_FILTER_VALUES';
export const CLEAR_FILTER_VALUES = 'CLEAR_FILTER_VALUES';

// Filters

export const RECEIVE_ORGANIZATIONS = 'RECEIVE_ORGANIZATIONS';
export const SET_EVENTS = 'SET_EVENTS';

export const ADD_CAMERA_GROUP = 'ADD_CAMERA_GROUP';
export const ADD_CAMERA_GROUP_SUCCESS = 'ADD_CAMERA_GROUP_SUCCESS';
export const RESET_USER_CONTEXT = 'RESET_USER_CONTEXT';
export const RECEIVE_CLAIM_KEY = 'RECEIVE_CLAIM_KEY';
export const CREATE_CLAIM_KEY = 'CREATE_CLAIM_KEY';
export const GET_ACTIVATION_CODE = 'GET_ACTIVATION_CODE';
export const RECEIVE_ACTIVATION_CODE = 'RECEIVE_ACTIVATION_CODE';
export const RECEIVE_CLAIM_ERROR = 'RECEIVE_CLAIM_ERROR';

// Snapshot
export const GET_SNAPSHOT_URL = 'GET_SNAPSHOT_URL';
export const RECEIVE_SNAPSHOT_URL = 'RECEIVE_SNAPSHOT_URL';
export const FLAG_STALE_SNAPSHOT_URL = 'FLAG_STALE_SNAPSHOT_URL';
export const IS_FETCHING_SNAPSHOTS = 'IS_FETCHING_SNAPSHOTS';
export const IS_FETCHING_SNAPSHOT_SOURCE = 'IS_FETCHING_SNAPSHOT_SOURCE';
export const SNAPSHOT_REQUEST_COMPLETED = 'SNAPSHOT_REQUEST_COMPLETED';
export const GET_SNAPSHOT_SOURCE = 'GET_SNAPSHOT_SOURCE';
export const RECEIVE_SNAPSHOT_SOURCE = 'RECEIVE_SNAPSHOT_SOURCE';
export const CLEAR_SNAPSHOT_SOURCE = 'CLEAR_SNAPSHOT_SOURCE';
// Snapshot

// Media params
export const GET_MEDIA_PARAMS = 'GET_MEDIA_PARAMS';
export const IS_FETCHING_MEDIA_PARAMS = 'IS_FETCHING_MEDIA_PARAMS';
export const DONE_FETCHING_MEDIA_PARAMS = 'DONE_FETCHING_MEDIA_PARAMS';
export const RECEIVE_MEDIA_PARAMS = 'RECEIVE_MEDIA_PARAMS';
export const FLAG_STREAM_USED = 'FLAG_STREAM_USED';
// Media params

// Stream metadata
export const RECEIVE_STREAM_DATA = 'RECEIVE_STREAM_DATA';
// Stream metadata

// Customers (Organizations)
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const IS_FETCHING_CURRENT_ORGANIZATION =
  'IS_FETCHING_CURRENT_ORGANIZATION';
export const IS_FETCHING_CUSTOMER_DATA = 'IS_FETCHING_CUSTOMER_DATA';
export const IS_FETCHING_CUSTOMER_ORGANIZATIONS =
  'IS_FETCHING_CUSTOMER_ORGANIZATIONS';
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS';
export const RECEIVE_PROVIDERS = 'RECEIVE_PROVIDERS';
export const CHANGE_CUSTOMER_CONTEXT = 'CHANGE_CUSTOMER_CONTEXT';
export const CHANGE_ORGANIZATION_CONTEXT = 'CHANGE_ORGANIZATION_CONTEXT';
export const GET_CUSTOMER_ORGANIZATIONS = 'GET_CUSTOMER_ORGANIZATIONS';
export const CLEAR_CUSTOMER_ORGANIZATIONS = 'CLEAR_CUSTOMER_ORGANIZATIONS';
export const GET_CUSTOMER_ORGANIZATION = 'GET_CUSTOMER_ORGANIZATION';
export const RECEIVE_CUSTOMER_ORGANIZATIONS = 'RECEIVE_CUSTOMER_ORGANIZATIONS';
export const UPDATE_CUSTOMER_ORGANIZATIONS_INFINITE_FILTER =
  'UPDATE_CUSTOMER_ORGANIZATIONS_INFINITE_FILTER';
export const RECEIVE_CUSTOMER_ORGANIZATION = 'RECEIVE_CUSTOMER_ORGANIZATION';
export const RESEND_CUSTOMER_INVITE = 'RESEND_CUSTOMER_INVITE';
export const UPDATE_CUSTOMER_STORE = 'UPDATE_CUSTOMER_STORE';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
// SureView/Monitoring
export const GET_INTEGRATION_CONFIGS = 'GET_INTEGRATION_CONFIGS';
export const ENABLE_INTEGRATION_CONFIG = 'ENABLE_INTEGRATION_CONFIG';
export const IS_FETCHING_INTEGRATION_CONFIGS =
  'IS_FETCHING_INTEGRATION_CONFIGS';
export const IS_FETCHING_DEALER_INTEGRATION_CONFIGS =
  'IS_FETCHING_DEALER_INTEGRATION_CONFIGS';
export const RECEIVE_INTEGRATION_CONFIGS =
  'RECEIVE_DEALER_INTNEGRATION_CONFIGS';
export const CREATE_INTEGRATION_CONFIG = 'CREATE_INTEGRATION_CONFIG';
export const EDIT_INTEGRATION_CONFIG = 'EDIT_INTEGRATION_CONFIG';
export const CAN_DELETE_INTEGRATION_CONFIG = 'CAN_DELETE_INTEGRATION_CONFIG';
export const DELETE_INTEGRATION_CONFIG = 'DELETE_INTEGRATION_CONFIG';
export const GET_INTEGRATION_SUBSCRIBER_SUMMARY =
  'GET_INTEGRATION_SUBSCRIBER_SUMMARY';
export const RECEIVE_INTEGRATION_SUBSCRIBER_SUMMARY =
  'RECEIVE_INTEGRATION_SUBSCRIBER_SUMMARY';
export const IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY =
  'IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY';
export const GET_INTEGRATION_SUBSCRIBER_SITES =
  'GET_INTEGRATION_SUBSCRIBER_SITES';
export const RECEIVE_INTEGRATION_SUBSCRIBER_SITES =
  'RECEIVE_INTEGRATION_SUBSCRIBER_SITES';
export const SET_SECURITY_MONITORING = 'SET_SECURITY_MONITORING';
export const SET_MONITORING_CENTER = 'SET_MONITORING_CENTER';
export const SET_SITE_CONFIGURATION_STRING = 'SET_SITE_CONFIGURATION_STRING';
export const GET_CONNECTION_TEST_RESULT = 'GET_CONNECTION_TEST_RESULT';
export const SET_CONNECTION_TEST_RESULT = 'SET_CONNECTION_TEST_RESULT';
export const GET_INTEGRATION_TYPES = 'GET_INTEGRATION_TYPES';
export const RECEIVE_INTEGRATION_TYPES = 'RECEIVE_INTEGRATION_TYPES';
export const VALIDATE_CONNECTION_STRING = 'VALIDATE_CONNECTION_STRING';
export const UPDATE_CUSTOMER_ORGANIZATION = 'UPDATE_CUSTOMER_ORGANIZATION';

// VIEWS
export const GET_VIEWS = 'GET_VIEWS';
export const GET_VIEW = 'GET_VIEW';
export const RECEIVE_VIEW = 'RECEIVE_VIEW';
export const CREATE_VIEW = 'CREATE_VIEW';
export const RECEIVE_VIEWS = 'RECEIVE_VIEWS';
export const RECEIVE_ACC_SAVED_VIEWS = 'RECEIVE_ACC_SAVED_VIEWS';
export const DELETE_VIEW = 'DELETE_VIEW';
export const EDIT_VIEW = 'EDIT_VIEW';
export const SET_CURRENT_VIEW_CAMERA = 'SET_CURRENT_VIEW_CAMERA';
export const EDIT_CURRENT_VIEW = 'EDIT_CURRENT_VIEW';
export const MAXIMIZE_VIEW = 'MAXIMIZE_VIEW';
export const RECORDED_VIDEO_DATE = 'RECORDED_VIDEO_DATE';
export const RECORDED_VIDEO_REQUESTED = 'RECORDED_VIDEO_REQUESTED';
export const TOGGLE_SYNC_VIDEO = 'TOGGLE_SYNC_VIDEO';
export const SET_SYNCED_START_TIME = 'SET_SYNCED_START_TIME';
export const CLEAR_SYNCED_START_TIME = 'CLEAR_SYNCED_START_TIME';
export const SET_CURRENT_PANEL_ID = 'SET_CURRENT_PANEL_ID';
export const SET_ELEMENT_TURN_STATUS = 'SET_ELEMENT_TURN_STATUS';
export const IS_FETCHING_VIEWS = 'IS_FETCHING_VIEWS';
export const VIEW_MODAL_SHOW = 'VIEW_MODAL_SHOW';
export const VIEW_MODAL_HIDE = 'VIEW_MODAL_HIDE';
export const SET_TURN_STREAMS_PAUSED = 'SET_TURN_STREAMS_PAUSED';
export const SET_RESUME_STREAM_START_TIME = 'SET_RESUME_STREAM_START_TIME';

// CONTACTS
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACT = 'GET_CONTACT';
export const IS_FETCHING_CONTACTS_DATA = 'IS_FETCHING_CONTACTS_DATA';
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';
export const RECEIVE_CONTACT = 'RECEIVE_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const GET_CONTACT_BY_LOCATION = 'GET_CONTACT_BY_LOCATION';
// CONTACTS

// NOTIFICATIONS
export const NOTIFICATION_CALLBACK = 'NOTIFICATION_CALLBACK';
export const CONNECTION_SUSBSCRIPTION_STATUS_CHANGE =
  'CONNECTION_SUSBSCRIPTION_STATUS_CHANGE';
export const TRY_NOTIFICATION_CONNECTION = 'TRY_NOTIFICATION_CONNECTION';
export const IS_UPDATING_CONNECTION_SUBSCRIPTION =
  'IS_UPDATING_CONNECTION_SUBSCRIPTION';

// GATEWAY
export const IS_FETCHING_GATEWAY_GENERAL_SETTINGS =
  'IS_FETCHING_GATEWAY_GENERAL_SETTINGS';
export const GET_GATEWAY_GENERAL_SETTINGS = 'GET_GATEWAY_GENERAL_SETTINGS';
export const RECEIVE_GATEWAY_GENERAL_SETTINGS =
  'RECEIVE_GATEWAY_GENERAL_SETTINGS';
export const UPDATE_SERVER_NAME = 'UPDATE_SERVER_NAME';
export const UPDATE_SERVER_TIME_ZONE = 'UPDATE_SERVER_TIME_ZONE';

// Audio
export const IS_COMMITTING_SPEAKER_SETTINGS = 'IS_COMMITTING_SPEAKER_SETTINGS';
export const IS_FETCHING_SPEAKER_SETTINGS = 'IS_FETCHING_SPEAKER_SETTINGS';
export const RECEIVE_SPEAKER_SETTINGS = 'RECEIVE_SPEAKER_SETTINGS';
export const GET_GATEWAY_SPEAKER_SETTINGS = 'GET_GATEWAY_SPEAKER_SETTINGS';
export const UPDATE_GATEWAY_SPEAKER_SETTINGS =
  'UPDATE_GATEWAY_SPEAKER_SETTINGS';

// Bookmarks
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const UPDATE_BOOKMARK = 'UPDATE_BOOKMARK';
export const CREATE_BOOKMARK = 'CREATE_BOOKMARK';

// Site View
export const SITE_LIST_COMPRESSED = 'SITE_LIST_COMPRESSED';
export const SITE_LIST_TOP_SCROLLED_ITEM = 'SITE_LIST_TOP_SCROLLED_ITEM';
export const SITE_VIEW_SITE_IS_COLLAPSED = 'SITE_VIEW_SITE_IS_COLLAPSED';

// SignalR Logging
export const LOG_SIGNAL_R_MESSAGE = 'LOG_SIGNAL_R_MESSAGE';
export const CLEAR_SIGNAL_R_LOG = 'CLEAR_SIGNAL_R_LOG';

// Filter Persistance
export const PERSIST_FILTER = 'PERSIST_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';

// Recording Templates
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const EDIT_TEMPLATE_NAME = 'EDIT_TEMPLATE_NAME';
export const UPDATE_RECORDING_TEMPLATE = 'UPDATE_RECORDING_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const UPDATE_RECORDING_SCHEDULE = 'UPDATE_RECORDING_SCHEDULE';
export const GET_RECORDING_SCHEDULES = 'GET_RECORDING_SCHEDULES';
export const RECEIVE_RECORDING_SCHEDULES = 'RECEIVE_RECORDING_SCHEDULES';
export const SAVE_RECORDING_SCHEDULES = 'SAVE_RECORDING_SCHEDULES';
export const GET_RECORDING_SETTINGS = 'GET_RECORDING_SETTINGS';
export const RECEIVE_RECORDING_SETTINGS = 'RECEIVE_RECORDING_SETTINGS';
export const SAVE_RECORDING_SETTINGS = 'SAVE_RECORDING_SETTINGS';
export const UPDATE_RECORDING_SCHEDULE_ID = 'UPDATE_RECORDING_SCHEDULE_ID';

// Utilities
export const GET_TIMEZONES = 'GET_TIMEZONES';
export const RECEIVE_TIMEZONES = 'RECEIVE_TIMEZONES';
export const GET_AI_KEY = 'GET_AI_KEY';
export const RECEIVE_AI_KEY = 'RECEIVE_AI_KEY';
export const GET_REGIONAL_DEPLOYMENTS = 'GET_REGIONAL_DEPLOYMENTS';
export const RECEIVE_REGIONAL_DEPLOYMENTS = 'RECEIVE_REGIONAL_DEPLOYMENTS';
export const GET_LINKS = 'GET_LINKS';
export const RECEIVE_LINKS = 'RECEIVE_LINKS';
// Utilities

// Toggle
export const TOGGLE_TRACK_ACTION = 'TOGGLE_TRACK_ACTION';
export const TOGGLE_REVERT = 'TOGGLE_REVERT';

// Health Monitoring
export const GET_HMS_CONFIG = 'GET_HMS_CONFIG';
export const GET_HMS_JWT = 'GET_HMS_JWT';
export const REDIRECT_TO_HMS_ROOT = 'REDIRECT_TO_HMS_ROOT';
export const STOP_REDIRECTING_TO_HMS_ROOT = 'STOP_REDIRECTING_TO_HMS_ROOT';
