export function showMenu(rowIndex, x, y, menuProps) {
  return {
    type: 'SHOW_MENU',
    rowIndex,
    x,
    y,
    menuProps,
  };
}

export function hideMenu(keepRowIndex) {
  return {
    type: 'HIDE_MENU',
    keepRowIndex,
  };
}
