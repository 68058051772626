import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import { generateSort } from 'util/generateSort';
import itemUpsert from '../util/itemUpsert';

export default function locationReducer(state, action) {
  switch (action.type) {
    case types.RECEIVE_LOCATIONS: {
      return {
        ...state,
        lastFetchedOrgId: action.orgId,
        locations:
          action.locations.length > 0
            ? action.locations.sort(generateSort(l => l.Name))
            : action.locations,
      };
    }

    // update from push notification triggers fetch of new alarm entity
    case types.LOCATION_UPDATE: {
      if (action.location && action.location.Id) {
        // update if already in list, otherwise insert
        const locations = itemUpsert(state.locations, action.location);
        return { ...state, locations };
      }
      return state;
    }

    case types.TOGGLE_LOCATION_MODAL: {
      return { ...state, modalIsOpen: !state.modalIsOpen };
    }

    case types.REMOVE_LOCATION: {
      const locationIndex = state.locations.findIndex(
        loc => loc.Id === action.locationId,
      );
      if (locationIndex >= 0) {
        const locations = state.locations
          .slice(0, locationIndex)
          .concat(state.locations.slice(locationIndex + 1));
        return { ...state, locations };
      }
      return state;
    }

    case types.RECEIVE_DEALER_SERVICE_PACKAGE_FAMILIES: {
      return { ...state, dealerServiceFamilies: action.data };
    }

    case types.RECEIVE_DEALER_SERVICE_PACKAGES: {
      const { dealerServicePackages } = action;

      const newState = {
        ...state,
        dealerServicePackages,
      };
      return newState;
    }

    case types.RECEIVE_ALL_SERVICE_PACKAGES: {
      return { ...state, servicePackages: action.servicePackages };
    }

    case types.RECEIVE_SERVICE_PACKAGE: {
      let { servicePackages } = state;
      if (action.scope) {
        servicePackages = itemUpsert(
          state.servicePackages,
          action.servicePackage,
        );
      }
      return { ...state, servicePackages };
    }

    case types.RECEIVE_SUBSCRIPTIONS: {
      return { ...state, subscriptions: action.subscriptions };
    }

    case types.IS_UPDATING_DEALER_HEALTH_DATA: {
      return { ...state, isUpdatingDealerHealthMonitoring: action.value };
    }

    case types.RECEIVE_ORG_SUBSCRIPTIONS: {
      return { ...state, orgSubscriptions: action.subscriptions };
    }
    // clear cache when user logs out or changes selected org/customer
    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().locations;
    }

    /** Pending Subscription Requests for Sites */
    case types.IS_FETCHING_PENDING_SITE_REQUESTS: {
      return { ...state, isFetchingPendingSiteRequests: action.value };
    }
    case types.RECEIVE_PENDING_SITE_REQUESTS: {
      return { ...state, pendingSiteRequests: action.pendingSiteRequests };
    }
    /** Pending Subscription Requests for Sites */

    default:
      return state || initialState().locations;
  }
}
