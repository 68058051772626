// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

// Components
import { Button, GroupLayout, ProgressBar } from 'lib';
import CameraList from './CameraList';

import { poeBarColumn, spaceBlock, tabSubheader } from './styles.css';

const ServerDetailRetention = props => {
  const {
    cameras,
    estimatedRetention,
    handleLinkedRetentionChange,
    handleRetentionChange,
    isDirty,
    modalActions,
    onLinkedStatusChange,
    onRevert,
    onSave,
    recordingSettings,
    server,
    storageCapacity,
    storageUsed,
    videoSettings,
  } = props;
  return (
    <div className={poeBarColumn}>
      <div className={spaceBlock}>
        <h4 className={tabSubheader}>
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.VIDEO_RETENTION_SUBHEADER" />
        </h4>
        <p>
          <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.VIDEO_RETENTION_TEXT" />
        </p>
      </div>
      <div className={spaceBlock}>
        <ProgressBar
          label={
            <Translate
              data={{
                capacity: storageCapacity,
                measure: (
                  <Translate id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAPACITY_MEASURES.TERABYTES_MEASURE" />
                ),
              }}
              id="DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAPACITY_MESSAGE"
            />
          }
          leftValue={storageUsed}
          name="storage_used"
          showPercentage
          totalValue={storageCapacity}
        />
      </div>
      <div className={spaceBlock}>
        <CameraList
          cameras={cameras.filter(
            camera => camera.ServerId === server.Id && camera.RecordedData,
          )}
          estimatedRetention={estimatedRetention}
          handleLinkedRetentionChange={handleLinkedRetentionChange}
          handleRetentionChange={handleRetentionChange}
          modalActions={modalActions}
          onLinkedStatusChange={onLinkedStatusChange}
          recordingSettings={recordingSettings}
          videoSettings={videoSettings}
        />
      </div>
      <GroupLayout horizontalPositioning="center" verticalSpacing="large">
        <Button
          key="retentionSettingsButtonRevert"
          buttonType="primary"
          disabled={!isDirty}
          id="retentionSettingsButtonRevert"
          inputType="button"
          onClick={onRevert}
          text={<Translate id="BUTTONS.REVERT" />}
        />
        <Button
          key="retentionSettingsButtonSave"
          buttonType="primary"
          disabled={!isDirty}
          id="retentionSettingsButtonSave"
          inputType="button"
          onClick={onSave}
          text={<Translate id="BUTTONS.SAVE" />}
        />
      </GroupLayout>
    </div>
  );
};

ServerDetailRetention.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.object).isRequired,
  estimatedRetention: PropTypes.objectOf(PropTypes.object).isRequired,
  handleLinkedRetentionChange: PropTypes.func.isRequired,
  handleRetentionChange: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  modalActions: PropTypes.objectOf(PropTypes.func).isRequired,
  onLinkedStatusChange: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  recordingSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  server: PropTypes.objectOf(PropTypes.any).isRequired,
  storageCapacity: PropTypes.number.isRequired,
  storageUsed: PropTypes.number.isRequired,
  videoSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ServerDetailRetention;
