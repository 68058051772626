import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { container, checkbox, boxChecked, boxDisabled } from './styles.css';

const PlainCheckbox = ({ checked, disabled, id, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const toggleCheckboxChange = () => {
    if (!disabled) {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      onChange(newChecked);
    }
  };

  return (
    <div
      className={`${container} ${isChecked ? boxChecked : ''} ${
        disabled ? boxDisabled : ''
      }`}
    >
      <div
        className={checkbox}
        onClick={toggleCheckboxChange}
        id={id}
        onKeyPress={toggleCheckboxChange}
        role="button"
        tabIndex="0"
      />
    </div>
  );
};

PlainCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  id: 'checkBox',
};

PlainCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  checked: PropTypes.bool,
};

export default PlainCheckbox;
