import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Spinner from 'react-md-spinner';
import renderIf from 'render-if';
import get from 'lodash.get';

import {
  FieldInput,
  FieldInputWithOnBlur,
  VerticalFormFieldLayout,
} from 'components';
import { Button, GroupLayout, ListNav, PageTitle } from 'lib';
import { PageMessage } from 'containers';
import { IconSmallCheck, IconSmallWrong } from 'icons';

import {
  formField,
  formWrapper,
  horizontalDividerSolid,
  inputError,
  pageError,
} from 'sharedStyles/modalForms.css';

import { dashes } from 'constants/app';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  codeStatus,
  customFormGroup,
  formFieldReadOnly,
  subHeader,
  validationIcons,
} from './styles.css';
import * as ChangeProviderFormConsts from './constants';

const mainCheckboxSize = { height: '20px', width: '20px' };

export const fields = [
  ChangeProviderFormConsts.fieldCode,
  ChangeProviderFormConsts.fieldServiceProviderName,
  ChangeProviderFormConsts.fieldSubscription,
  ChangeProviderFormConsts.fieldTerm,
  ChangeProviderFormConsts.fieldAutoRenewable,
];
const formLevelValidation = values => {
  const errors = {};
  const emptyFields = ChangeProviderFormConsts.requiredFields.filter(
    field =>
      !values[field] ||
      values[field].toString().trim() === '' ||
      values[field].length === 0,
  );
  if (emptyFields.length > 0) {
    errors.summary = (
      <Translate id="USERS.USER_FORM_FIELDS.VALIDATION.REQUIRED_FIELDS" />
    );
  }
  return errors;
};

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        body={error}
        messageStyle={messageStyleStrings.error}
        translateHeader="GENERAL_MESSAGES.MISSING_INFO_HEADER"
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.defaultProps = {
  meta: null,
};

errorAggregator.propTypes = {
  meta: PropTypes.objectOf(PropTypes.any),
};

// Claim code formatting
export const formattedCode = content => {
  if (!content) return content;
  let onlyCode = content.replace('/[^A-Za-z0-9]/g', '');
  onlyCode = onlyCode.toUpperCase();

  if (onlyCode.length < 9) {
    return onlyCode;
  }
  if (
    !onlyCode.includes('-') &&
    onlyCode.length === 9 &&
    !/^([A-Z0-9])$/i.test(onlyCode)
  ) {
    return `${onlyCode.substr(0, 3)}-${onlyCode.substr(3, 3)}-${onlyCode.substr(
      6,
    )}`;
  }
  return onlyCode;
};

// Claim code validations
export const providerCode = content => {
  if (!content) {
    return 'SIGNUP.REQUIRED_VALUE_MESSAGE';
  }
  if (content.length < 11 && content.length !== 9) {
    return 'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_CODE_FORMAT_ERROR';
  }
  if (content.length > 11) {
    return 'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_CODE_FORMAT_ERROR';
  }

  if (
    content.length === 11 &&
    !/^([A-Z0-9]{3})+-([A-Z0-9]{3})+-([A-Z0-9]{3})$/i.test(content)
  ) {
    return 'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_CODE_FORMAT_ERROR';
  }
  return undefined;
};

class Form extends Component {
  componentWillUnmount() {
    const { clearServicePackageByCode } = this.props;
    clearServicePackageByCode();
  }

  validationStatusIcon = () => {
    const { code, dirty, isFetchingServicePackageByCode, valid } = this.props;

    const servicePackage = this.servicePackageValid(code);

    return (
      <>
        <div className={validationIcons}>
          {servicePackage !== undefined &&
            servicePackage !== '' &&
            code &&
            isFetchingServicePackageByCode !== null &&
            !isFetchingServicePackageByCode[code] && (
              <IconSmallCheck
                height={mainCheckboxSize.height}
                width={mainCheckboxSize.width}
              />
            )}
          {valid &&
            code &&
            isFetchingServicePackageByCode !== null &&
            isFetchingServicePackageByCode[code] === true && (
              <Spinner
                color1="#006e99"
                color2="#3d6f99"
                color3="#0f4c7f"
                color4="#006e99"
                size={mainCheckboxSize.height}
              />
            )}
          {valid &&
            code &&
            !servicePackage &&
            isFetchingServicePackageByCode !== null &&
            !isFetchingServicePackageByCode[code] && (
              <IconSmallWrong
                height={mainCheckboxSize.height}
                width={mainCheckboxSize.width}
              />
            )}
        </div>
        {renderIf(dirty && (!code || !valid))(
          <div className={validationIcons}>
            <IconSmallWrong
              height={mainCheckboxSize.height}
              width={mainCheckboxSize.width}
            />
          </div>,
        )}
      </>
    );
  };

  handleCodeChange = event => {
    const {
      changeFieldValue,
      servicePackageForCode,
      translate,
      validateCode,
    } = this.props;

    let validCode = '';
    if (event && event.target.value) {
      if (
        event.target.value.length === 11 &&
        providerCode(event.target.value) === undefined
      ) {
        validCode = event.target.value.toUpperCase();
      } else if (event.target.value.length === 9) {
        validCode = formattedCode(event.target.value);
      }
    }

    if (validCode.length === 11 && providerCode(validCode) === undefined) {
      validateCode(validCode);
      const servicePackage = this.servicePackageValid(validCode);
      servicePackageForCode(validCode);
      if (servicePackage !== undefined) {
        changeFieldValue(ChangeProviderFormConsts.fieldCode, validCode);
        changeFieldValue(ChangeProviderFormConsts.fieldId, servicePackage.Id);
        changeFieldValue(
          ChangeProviderFormConsts.fieldServiceProviderName,
          servicePackage.ProviderName,
        );
        changeFieldValue(
          ChangeProviderFormConsts.fieldSubscription,
          servicePackage.Name,
        );
        changeFieldValue(
          ChangeProviderFormConsts.fieldTerm,
          translate('SUBSCRIPTIONS.SUBSCRIPTION_TERM_LENGTH_PLURAL', {
            month_qty: servicePackage.FixedTermLength,
          }),
        );
        changeFieldValue(
          ChangeProviderFormConsts.fieldAutoRenewable,
          servicePackage.IsAutoRenewable
            ? translate('COMMON.YES')
            : translate('COMMON.NO'),
        );
      }
    } else {
      changeFieldValue(ChangeProviderFormConsts.fieldCode, event.target.value);
      changeFieldValue(
        ChangeProviderFormConsts.fieldServiceProviderName,
        dashes,
      );
      changeFieldValue(ChangeProviderFormConsts.fieldSubscription, dashes);
      changeFieldValue(ChangeProviderFormConsts.fieldTerm, dashes);
      changeFieldValue(ChangeProviderFormConsts.fieldAutoRenewable, dashes);
    }
  };

  servicePackageValid = code => {
    const { servicePackages } = this.props;
    let servicePackage = '';
    if (code) {
      const servicePackageByCode = servicePackages.find(
        sp => sp.ClaimCode === code,
      );
      if (this.isExistingServicePackageCode(servicePackageByCode) === false) {
        servicePackage = servicePackageByCode;
      }
    }
    return servicePackage;
  };

  isExistingServicePackageCode = servicePackage => {
    const { currentLocation } = this.props;
    const packageSubscription = get(currentLocation, 'PackageSubscription');
    const servicePackageId = get(packageSubscription, 'ServicePackageId');
    if (
      servicePackage &&
      servicePackage.Id &&
      currentLocation &&
      packageSubscription &&
      servicePackageId === servicePackage.Id
    ) {
      return true;
    }
    return false;
  };

  render() {
    const {
      backPath,
      canChangeCustomer,
      code,
      currentLocation,
      handleSubmit,
      isFetchingServicePackageByCode,
      onCancel,
      submittingForm,
      translate,
      valid,
    } = this.props;

    const currentPackage = this.servicePackageValid(code);
    return (
      <>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          pageTitle={
            <PageTitle
              onBackClick={() => backPath()}
              showBackButton
              title={translate(
                'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_FORM_HEADER',
                { siteName: currentLocation.Name },
              )}
            />
          }
        />
        <div className={horizontalDividerSolid} />
        <div className={`${subHeader} ${formField}`}>
          {translate(
            'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_FORM_TITLE',
          )}
        </div>
        <div className={formWrapper}>
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                component={errorAggregator}
                name={ChangeProviderFormConsts.fieldSummary}
              />
            </div>
            <Field
              component={FieldInput}
              name={ChangeProviderFormConsts.fieldId}
              type="hidden"
            />
            <div className={codeStatus}>
              <VerticalFormFieldLayout
                customFormGroup={customFormGroup}
                label="SUBSCRIPTIONS.CHANGE_PROVIDER.PROVIDER_CODE"
              >
                <Field
                  className={formField}
                  component={FieldInputWithOnBlur}
                  isCodeError
                  maxLength={ChangeProviderFormConsts.maxCodeLength}
                  name={ChangeProviderFormConsts.fieldCode}
                  normalize={formattedCode}
                  onChange={this.handleCodeChange}
                  translate
                  type="text"
                  validate={providerCode}
                />
              </VerticalFormFieldLayout>
              {this.validationStatusIcon()}
            </div>
            {renderIf(
              valid &&
                !currentPackage &&
                code &&
                isFetchingServicePackageByCode !== null &&
                !isFetchingServicePackageByCode[code],
            )(
              currentPackage === undefined ? (
                <div className={inputError}>
                  {translate(
                    'SUBSCRIPTIONS.CHANGE_PROVIDER.INVALID_CODE_ERROR',
                  )}
                </div>
              ) : (
                <div className={inputError}>
                  {translate(
                    'SUBSCRIPTIONS.CHANGE_PROVIDER.EXISTING_PROVIDER_CODE_ERROR',
                  )}
                </div>
              ),
            )}

            <VerticalFormFieldLayout label="GENERAL_TABLE.HEADER.SERVICE_PROVIDER">
              <Field
                className={formFieldReadOnly}
                component={FieldInput}
                disabled
                name={ChangeProviderFormConsts.fieldServiceProviderName}
                translate
              />
            </VerticalFormFieldLayout>

            <VerticalFormFieldLayout label="GENERAL_TABLE.HEADER.SUBSCRIPTION_LABEL">
              <Field
                className={formFieldReadOnly}
                component={FieldInput}
                disabled
                name={ChangeProviderFormConsts.fieldSubscription}
                translate
              />
            </VerticalFormFieldLayout>

            <VerticalFormFieldLayout label="GENERAL_TABLE.HEADER.TERM_LABEL">
              <Field
                className={formFieldReadOnly}
                component={FieldInput}
                disabled
                name={ChangeProviderFormConsts.fieldTerm}
                translate
              />
            </VerticalFormFieldLayout>

            <VerticalFormFieldLayout label="GENERAL_TABLE.HEADER.AUTO_RENEWAL">
              <Field
                className={formFieldReadOnly}
                component={FieldInput}
                disabled
                name={ChangeProviderFormConsts.fieldAutoRenewable}
                translate
              />
            </VerticalFormFieldLayout>

            <GroupLayout horizontalPositioning="left" verticalSpacing="large">
              <Button
                key="buttonRevert"
                buttonType="action"
                id="buttonRevert"
                inputType="button"
                onClick={onCancel}
                text={<Translate id="BUTTONS.CANCEL" />}
              />
              <Button
                key="buttonSave"
                buttonType="primary"
                disabled={submittingForm || !valid || !currentPackage}
                id="buttonSave"
                inputType="button"
                onClick={handleSubmit}
                text={<Translate id="BUTTONS.SAVE" />}
              />
            </GroupLayout>
          </form>
        </div>
      </>
    );
  }
}

Form.defaultProps = {
  canChangeCustomer: false,
  code: '',
  dirty: false,
  isFetchingServicePackageByCode: null,
  valid: false,
};

Form.propTypes = {
  backPath: PropTypes.func.isRequired,
  canChangeCustomer: PropTypes.bool,
  changeFieldValue: PropTypes.func.isRequired,
  clearServicePackageByCode: PropTypes.func.isRequired,
  code: PropTypes.string,
  currentLocation: PropTypes.objectOf(PropTypes.any).isRequired,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isFetchingServicePackageByCode: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  servicePackageForCode: PropTypes.func.isRequired,
  servicePackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  submittingForm: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  validateCode: PropTypes.func.isRequired,
};

const ReduxForm = reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: ChangeProviderFormConsts.formName,
  validate: formLevelValidation,
})(Form);

const selector = formValueSelector(ChangeProviderFormConsts.formName);

const ChangeProviderForm = connect(state => {
  const code = selector(state, ChangeProviderFormConsts.fieldCode);

  return {
    code,
  };
})(withLocalize(ReduxForm));

export default ChangeProviderForm;
