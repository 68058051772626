import React from 'react';
import { PropTypes } from 'prop-types';
import renderIf from 'render-if';

// Components
import { Icon } from 'lib';
import { IconCloud } from 'icons';

// Constants
import { IC_FAVORITE_DEFAULT_ON, IC_FAVORITE_ON } from 'constants/iconNames';
import { VIEW_EDITOR_ICON_SIZE } from './constants';

// Styles
import {
  defaultIconWrap,
  editorDropdownValueWrap,
  selectedValueText,
  truncatedSelectText,
  viewEditorIcons,
} from './styles.css';

const ViewEditorDropdownValue = ({ children, value }) => {
  return (
    <div className="Select-value">
      <div className={`${editorDropdownValueWrap} "Select-value-label"`}>
        <span className={`${truncatedSelectText} ${selectedValueText}`}>
          {children}
        </span>
        <div className={viewEditorIcons}>
          {renderIf(value && value.isDefault)(
            <Icon
              iconClass={defaultIconWrap}
              id={IC_FAVORITE_DEFAULT_ON}
              size={VIEW_EDITOR_ICON_SIZE}
            />,
          )}
          {renderIf(value && !value.isDefault && value.isFavorite)(
            <Icon id={IC_FAVORITE_ON} size={VIEW_EDITOR_ICON_SIZE} />,
          )}
          <IconCloud
            fill="#aaa"
            height={VIEW_EDITOR_ICON_SIZE}
            width={VIEW_EDITOR_ICON_SIZE}
          />
        </div>
      </div>
    </div>
  );
};

ViewEditorDropdownValue.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string.isRequired,
};

ViewEditorDropdownValue.defaultProps = {
  children: null,
};

export default ViewEditorDropdownValue;
