import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ServerDetailRecordingSchedule } from 'components';
import { MainContentWrapper } from 'lib';
import { getStartOfWeek } from 'util/languagesUtils';
// Actions
import * as RecordingDataActions from 'actions/recordingData';

const ServerDetailRecordingScheduleContainer = props => {
  const {
    actions,
    cameras,
    isDirty,
    isFetchingTemplates,
    recordingSchedules,
    recordingSchedulesGrid,
    selectedRecordingTemplate,
    selectedTemplateId,
    server,
    startOfWeek,
    templates,
    usingRealTemplateIds,
  } = props;

  function setSelectedTemplateId(templateId) {
    actions.setSelectedTemplate(templateId);
  }

  function deleteTemplate(templateIdToDelete) {
    actions.deleteTemplate(templateIdToDelete, server.GroupId);
  }

  const initialState = useRef(templates);
  useEffect(() => {
    actions.getTemplates(server.GroupId);
    actions.getRecordingSchedules(server.GroupId);
  }, [actions, server.GroupId]);

  if (templates && !initialState.current) {
    initialState.current = templates;
  }

  return (
    <MainContentWrapper>
      <ServerDetailRecordingSchedule
        actions={actions}
        cameras={cameras}
        deleteTemplate={deleteTemplate}
        firmwareVersion={server.FirmwareVersion}
        groupId={server.GroupId}
        initialState={initialState}
        isDirty={isDirty}
        isFetchingTemplates={isFetchingTemplates}
        recordingSchedules={recordingSchedules}
        recordingSchedulesGrid={recordingSchedulesGrid}
        saveTemplate={actions.saveTemplate}
        selectedRecordingTemplate={selectedRecordingTemplate}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        startOfWeek={startOfWeek}
        templates={templates}
        usingRealTemplateIds={usingRealTemplateIds}
      />
    </MainContentWrapper>
  );
};

ServerDetailRecordingScheduleContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  isDirty: PropTypes.bool,
  isFetchingTemplates: PropTypes.bool,
  recordingSchedules: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]),
  ),
  recordingSchedulesGrid: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object]),
  ).isRequired,
  selectedRecordingTemplate: PropTypes.shape({}),
  selectedTemplateId: PropTypes.string,
  server: PropTypes.objectOf(PropTypes.any).isRequired,
  startOfWeek: PropTypes.number,
  templates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  usingRealTemplateIds: PropTypes.bool.isRequired,
};

ServerDetailRecordingScheduleContainer.defaultProps = {
  isDirty: false,
  isFetchingTemplates: false,
  recordingSchedules: [],
  selectedRecordingTemplate: null,
  selectedTemplateId: null,
  startOfWeek: 0,
  templates: undefined,
};

/*
  Shift columns depending on locale/ user preferred day start of week
  Default sent by API is 0(Sunday)
*/
const getScheduleByStartOfWeek = (
  templatesMap,
  schedule,
  defaultTemplate,
  startOfWeek,
) => {
  let scheduleObj = {
    0: templatesMap.get(schedule.sunday) || defaultTemplate,
    1: templatesMap.get(schedule.monday) || defaultTemplate,
    2: templatesMap.get(schedule.tuesday) || defaultTemplate,
    3: templatesMap.get(schedule.wednesday) || defaultTemplate,
    4: templatesMap.get(schedule.thursday) || defaultTemplate,
    5: templatesMap.get(schedule.friday) || defaultTemplate,
    6: templatesMap.get(schedule.saturday) || defaultTemplate,
    id: schedule.id,
  };
  switch (startOfWeek) {
    case 0:
      break;
    case 1:
      // 0(Sunday) => 1(Monday) shift
      scheduleObj = {
        0: templatesMap.get(schedule.monday) || defaultTemplate,
        1: templatesMap.get(schedule.tuesday) || defaultTemplate,
        2: templatesMap.get(schedule.wednesday) || defaultTemplate,
        3: templatesMap.get(schedule.thursday) || defaultTemplate,
        4: templatesMap.get(schedule.friday) || defaultTemplate,
        5: templatesMap.get(schedule.saturday) || defaultTemplate,
        6: templatesMap.get(schedule.sunday) || defaultTemplate,
        id: schedule.id,
      };
      break;
    case 6:
      // 0(Sunday) => 6(Saturday) shift
      scheduleObj = {
        0: templatesMap.get(schedule.saturday) || defaultTemplate,
        1: templatesMap.get(schedule.sunday) || defaultTemplate,
        2: templatesMap.get(schedule.monday) || defaultTemplate,
        3: templatesMap.get(schedule.tuesday) || defaultTemplate,
        4: templatesMap.get(schedule.wednesday) || defaultTemplate,
        5: templatesMap.get(schedule.thursday) || defaultTemplate,
        6: templatesMap.get(schedule.friday) || defaultTemplate,
        id: schedule.id,
      };
      break;
    default:
      break;
  }

  return scheduleObj;
};

function mapStateToProps(state, ownProps) {
  const selectedTemplateId = state.recordingData.selectedTemplate;
  const isFetchingTemplates = state.isFetching.getTemplates
    ? state.isFetching.getTemplates[ownProps.server.GroupId]
    : null;
  const templates = state.recordingData.templates[ownProps.server.GroupId];
  const { isDirty } = state.recordingData;
  const { usingRealTemplateIds } = state.recordingData;
  let selectedRecordingTemplate = null;
  if (templates) {
    selectedRecordingTemplate = templates.find(template => {
      return template.id
        ? template.id === selectedTemplateId
        : template.tempId === selectedTemplateId;
    });
  }

  const startOfWeek = getStartOfWeek(state.user.profile.LocalizationPreference);

  const recordingSchedules =
    state.recordingData.recordingSchedules[ownProps.server.GroupId];

  const recordingSchedulesGrid = [];
  if (templates && recordingSchedules) {
    const templatesMap = new Map(
      templates.map(obj => [obj.id || obj.tempId, obj]),
    );
    const defaultTemplate = templates.find(
      template => template.name === 'Default',
    );
    for (let i = 0; i < ownProps.cameras.length; i += 1) {
      const camId = ownProps.cameras[i].RemoteId;
      const schedule =
        recordingSchedules.find(
          sched => camId === sched.id || camId === sched.tempId,
        ) || {};
      recordingSchedulesGrid[i] = getScheduleByStartOfWeek(
        templatesMap,
        schedule,
        defaultTemplate,
        startOfWeek,
      );
    }
  }

  return {
    isDirty,
    isFetchingTemplates: isFetchingTemplates || false,
    recordingSchedules,
    recordingSchedulesGrid,
    selectedRecordingTemplate,
    selectedTemplateId,
    startOfWeek,
    templates,
    usingRealTemplateIds,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...RecordingDataActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServerDetailRecordingScheduleContainer);
