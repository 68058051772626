import initialState from 'store/initialState';

// Utilities
import * as types from 'constants/ActionTypes';
import { tenantTypes, tenantTypeStrings } from 'util/tenantTypes';
import * as PERMISSIONS from 'constants/PermissionTypes';
import { getEntitlementValues } from 'util/getEntitlementValues';
import itemUpsert from '../util/itemUpsert';
import { generateSort, sortFirstAndLastName } from '../util/generateSort';
import { hasPermission } from '../util/permissions';
import concatUpsert from '../util/concatUpsert';

// Constants

export default function userReducer(state, action) {
  switch (action.type) {
    case types.CLEAR_ERROR: {
      return { ...state, error: null };
    }

    case types.SET_USER: {
      const userSet = {
        disableAutoComplete: false,
        error: null,
        hasAcceptedEula: true,
        isAuthenticated: true,
        isLoggingOut: false,
      };
      return { ...state, ...userSet };
    }

    case types.UNSET_USER: {
      const userLoggedOut = {
        isLoggingOut: true,
      };
      return { ...state, ...userLoggedOut };
    }

    case types.LOGIN_FAILURE: {
      const userLoginFailure = {
        bodyError: action.errorFinalMessage,
        error: action.errorMessage,
        isAuthenticated: false,
      };
      return { ...state, ...userLoginFailure };
    }

    case types.PASSWORD_CHANGE_SUCCESS: {
      const changeSuccess = {
        disableAutoComplete: true,
        isAuthenticated: false,
        isPasswordChangeSuccessful: true,
      };
      return { ...state, ...changeSuccess };
    }

    case types.PASSWORD_CHANGE_FAILURE: {
      const changeFailure = {
        disableAutoComplete: true,
        error: action.errorMessage || 'Failed to change password',
        isAuthenticated: false,
        isPasswordChangeSuccessful: false,
      };
      return { ...state, ...changeFailure };
    }

    case types.REDIRECT_AFTER_LOGIN: {
      return { ...state, redirectToOnSuccess: action.redirectToOnSuccess };
    }

    case types.IS_FETCHING_CUSTOMER_DATA: {
      return { ...state, isFetchingCustomerData: action.value };
    }

    case types.IS_FETCHING_CUSTOMER_ORGANIZATIONS: {
      return { ...state, isFetchingCustomerOrganizations: action.value };
    }

    case types.IS_FETCHING_CURRENT_ORGANIZATION: {
      return { ...state, isFetchingCurrentOrganization: true };
    }

    case types.RECEIVE_BANNER_NOTICES: {
      return { ...state, bannerNotices: action.bannerNotices };
    }

    case types.RECEIVE_USERS: {
      let users = concatUpsert(state.users, action.users);
      users = users.sort(
        sortFirstAndLastName(user => `${user.LastName} ${user.FirstName}`),
      );
      return { ...state, users };
    }

    case types.RECEIVE_USER: {
      if (action.user && action.user.Id) {
        // update if already in list, otherwise insert
        const users = itemUpsert(state.users, action.user);
        return { ...state, users };
      }
      return state;
    }

    case types.RECEIVE_USERS_PUBLIC_INFO: {
      const { publicDataSubset, scope, users } = action;
      const usersPublicInfoScoped = users
        .sort(
          sortFirstAndLastName(user => `${user.LastName} ${user.FirstName}`),
        )
        .map(user => ({ ...user, publicDataSubset }));
      return {
        ...state,
        usersPublicInfo: {
          ...state.usersPublicInfo,
          [scope]: usersPublicInfoScoped,
        },
      };
    }

    case types.REMOVE_USER: {
      const users = state.users.filter(user => user.Id !== action.id);
      return { ...state, users };
    }

    case types.REMOVE_DELETED_CUSTOMER: {
      const organizations = state.organizations.filter(
        org => org.TenantId !== action.deletedOrganizationId,
      );
      return { ...state, organizations };
    }

    case types.RECEIVE_USER_PROFILE: {
      const tenant = {
        Id: action.profile.TenantId,
        Name: action.profile.TenantName,
        TenantType: action.profile.TenantType,
      };
      const selectedOrganization = tenant;

      const userName = `${action.profile.FirstName || ''} ${action.profile
        .LastName || ''}`;

      return {
        ...state,
        isAuthenticated: true,
        profile: action.profile,
        selectedOrganization,
        userName,
      };
    }

    case types.RECEIVE_CUSTOMERS: {
      // TODO: CHANGE VARIABLE NAMES, REMOVE THIS MAP
      const newCustomers = action.customers.map(org => ({
        ...org,
        Id: org.TenantId,
        Name: org.TenantName,
      }));

      const customers = action.preserveOldData
        ? [...state.customers, ...newCustomers]
        : newCustomers;

      return {
        ...state,
        customers,
        customersNextPageLink: action.nextPageLink,
        isFetchingCustomerData: false,
      };
    }

    case types.RECEIVE_CURRENT_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: action.organization,
        isFetchingCurrentOrganization: false,
      };
    }

    case types.RECEIVE_ORGANIZATIONS: {
      // TODO: CHANGE VARIABLE NAMES, REMOVE THIS MAP
      const organizations = action.organizations.map(org => ({
        ...org,
        Id: org.TenantId,
        Name: org.TenantName,
      }));
      return { ...state, isFetchingCustomerData: false, organizations };
    }

    case types.RECEIVE_SCHEDULES: {
      return { ...state, schedules: action.schedules };
    }

    case types.RECEIVE_CUSTOMER_ORGANIZATIONS: {
      // Extract from oData response
      const customerOrganizations =
        action.customerOrganizations.Items || action.customerOrganizations;
      const allOrg = concatUpsert(
        state.customerOrganizations,
        customerOrganizations,
      );
      const sortedCustomerOrganizations = tenantTypes
        .map(tenantType => {
          return allOrg
            .filter(org => org.Type === tenantType)
            .sort(generateSort(org => org.Name.toUpperCase()));
        })
        .reduce((acc, orgs) => {
          return acc.concat(orgs);
        }, []);
      return {
        ...state,
        customerOrganizations: sortedCustomerOrganizations,
        isFetchingCustomerOrganizations: false,
      };
    }

    case types.UPDATE_CUSTOMER_ORGANIZATION: {
      return {
        ...state,
        customerOrganizations: [
          ...state.customerOrganizations.map(c =>
            c.Id === action.data.Id ? { ...c, ...action.data } : c,
          ),
        ],
      };
    }

    case types.RECEIVE_CUSTOMER_ORGANIZATION: {
      let { currentOrganization } = state;
      if (action.customerOrganization && action.customerOrganization.Id) {
        let newCustomerOrganizations = [...state.customerOrganizations];
        if (
          state.selectedOrganization &&
          state.selectedOrganization.Id !== action.customerOrganization.Id // &&
          // state.selectedOrganization.TenantType !== tenantLabels.Dealer
        ) {
          newCustomerOrganizations = itemUpsert(
            newCustomerOrganizations,
            action.customerOrganization,
          );
        }
        if (state.currentOrganization.Id === action.customerOrganization.Id) {
          currentOrganization = action.customerOrganization;
        }
        return {
          ...state,
          currentOrganization: { ...currentOrganization },
          customerOrganizations: newCustomerOrganizations,
        };
      }
      return state;
    }

    case types.UPDATE_CUSTOMER_STORE: {
      if (action.id) {
        const index = state.customerOrganizations.findIndex(
          co => co.Id === action.id,
        );
        if (index > 0) {
          const customerOrganization = {
            ...state.customerOrganizations[index],
            ...action.content,
          };
          const customerOrganizations = state.customerOrganizations
            .slice(0, index)
            .concat([customerOrganization])
            .concat(state.customerOrganizations.slice(index + 1));
          return { ...state, customerOrganizations };
        }
        return state;
      }
      return state;
    }

    case types.CUSTOMER_DATA_FETCH_COMPLETED: {
      return { ...state, isFetchingCustomerData: false };
    }

    case types.RECEIVE_PARTNERS: {
      const partners = action.partners.Items || action.partners;
      return {
        ...state,
        partners: partners.sort(
          generateSort(partner => partner.Name.toUpperCase()),
        ),
      };
    }

    case types.RECEIVE_PROVIDERS: {
      return { ...state, providers: action.providers };
    }

    case types.USER_MODAL_HIDE:
    case types.USER_MODAL_SHOW: {
      return {
        ...state,
        userModal: action.type,
        userModalOperation: action.operation,
      };
    }

    case types.BUSY: {
      return { ...state, isBusy: true };
    }

    case types.NOT_BUSY: {
      return { ...state, isBusy: false };
    }

    case types.SET_PERMISSIONS: {
      if (
        state.profile &&
        state.currentOrganization &&
        state.currentPermissionsFor !==
          state.profile.Id + state.currentOrganization.Id
      ) {
        const permissions = {};
        Object.keys(state.permissions).forEach(key => {
          permissions[key] = hasPermission(
            key,
            state.profile,
            state.currentOrganization,
          );
        });
        return {
          ...state,
          currentPermissionsFor:
            state.profile.Id + state.currentOrganization.Id,
          permissions,
        };
      }
      return state;
    }

    case types.CHANGE_CUSTOMER_CONTEXT: {
      const newCustomer = state.customers // The new customer is either a subordinate customer
        .concat([
          {
            ...state.profile,
          },
        ]) // Or the top-level organization
        .find(c => c.TenantId === action.id);
      if (newCustomer) {
        const profile = {
          ...state.profile,
          CustomerTenantEntitlements: newCustomer.TenantEntitlements,
          CustomerTenantFlags: newCustomer.TenantFlags,
          CustomerTenantId: newCustomer.TenantId,
          CustomerTenantName: newCustomer.TenantName,
          CustomerTenantType: newCustomer.TenantType,
        };
        const parentOrganization = {
          Id: state.profile.TenantId,
          Name: state.profile.TenantName,
          TenantType: state.profile.TenantType,
        };
        const selectedOrganizationLabel = newCustomer.BillingNumber
          ? `${newCustomer.TenantName} - ${newCustomer.BillingNumber}`
          : newCustomer.TenantName;
        const selectedOrganization = {
          Entitlements: newCustomer.TenantEntitlements,
          Id: newCustomer.TenantId,
          Name: selectedOrganizationLabel,
          TenantType: newCustomer.TenantType,
        };
        return {
          ...state,
          currentOrganization: null,
          customerOrganizations: [],
          isFetchingCurrentOrganization: null,
          isFetchingCustomerData: null,
          parentOrganization,
          profile,
          selectedOrganization,
        };
      }
      return state;
    }

    case types.RESET_USER_CONTEXT: {
      return {
        ...state,
        currentOrganization: null,
        customerOrganizations: [],
        customers: [],
        isFetchingCurrentOrganization: null,
        isFetchingCustomerData: null,
        schedules: [],
        users: [],
      };
    }

    case types.EULA_ACCEPTANCE_REQUIRED: {
      const userEulaAcceptancePending = {
        hasAcceptedEula: false,
      };
      return { ...state, ...userEulaAcceptancePending };
    }

    case types.CLEAR_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        customerOrganizations: state.customerOrganizations.filter(
          org => org.Id !== action.toDeleteOrgId,
        ),
        customers: state.customers.filter(
          org => org.TenantId !== action.toDeleteOrgId,
        ),
      };
    }

    case types.UPDATE_NOT_3_OLD_PASSWORD_STATUS: {
      const isNotLast3Passwords = {
        ...state.isNotLast3Passwords,
        [action.passwordFieldName]: action.status,
      };

      return { ...state, isNotLast3Passwords };
    }

    case types.RECEIVE_SUBSCRIBER_SERVICE_PACKAGES: {
      const newSubScriberServicePackages = action.subscriberServicePackages;
      let permissions = {
        [PERMISSIONS.CAN_CREATE_SITE]: false,
        [PERMISSIONS.CAN_DELETE_SITE]: false,
        [PERMISSIONS.CAN_EDIT_SITE]: false,
        [PERMISSIONS.CAN_EDIT_SUBSCRIPTION]: false,
      };

      if (
        newSubScriberServicePackages &&
        newSubScriberServicePackages.length > 0 &&
        newSubScriberServicePackages.some(item =>
          item.SelfService.includes(state.profile.TenantType),
        )
      ) {
        permissions = {
          [PERMISSIONS.CAN_CREATE_SITE]: true,
          [PERMISSIONS.CAN_DELETE_SITE]: true,
          [PERMISSIONS.CAN_EDIT_SITE]: true,
          [PERMISSIONS.CAN_EDIT_SUBSCRIPTION]: true,
        };
      }

      return {
        ...state,
        isFetchingSubscriberServicePackages: false,
        permissions: { ...state.permissions, ...permissions },
        subscriberServicePackages: newSubScriberServicePackages,
      };
    }

    default:
      return state || initialState().user;
  }
}
