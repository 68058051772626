import React from 'react';
import { Translate } from 'react-localize-redux';

import { PropTypes } from 'prop-types';
import { sectionHeader, labeledBox, labeledBoxContent } from './styles.css';

const SectionHeader = ({ translationId }) => (
  <div className={sectionHeader}>
    <Translate id={translationId} />
  </div>
);

SectionHeader.propTypes = {
  translationId: PropTypes.string.isRequired,
};

const LabeledBox = ({ labelId, children }) => {
  return (
    <div className={labeledBox}>
      <SectionHeader translationId={labelId} />
      <div className={labeledBoxContent}>{children}</div>
    </div>
  );
};

LabeledBox.propTypes = {
  labelId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

LabeledBox.defaultProps = {
  children: null,
};

export default LabeledBox;
