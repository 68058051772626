/* Displays boundaries associated with analytics rules for a camera, including ROIs and LOIs */

// Libs
import React, { useCallback, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setRule } from 'actions/streamOverlays';
import { getRules } from 'actions/devices';

// Components
import Canvas from 'components/Drawing/Canvas';

// Constants
import { canvasTypes } from 'constants/app';

const RuleOverlayContainer = ({
  actions,
  cameraId,
  canEditROI,
  canvasItems,
  deviceId,
  isFetchingCameraRules,
  remoteId,
  screenSpaceHeight,
  screenSpaceWidth,
  streamDetails,
}) => {
  useEffect(() => {
    if (!isFetchingCameraRules) {
      actions.getRules(deviceId, remoteId, cameraId);
    }
  }, [isFetchingCameraRules, actions, deviceId, remoteId, cameraId]);

  const canvasList = useMemo(() => {
    if (!canvasItems) return null;
    return Object.keys(canvasItems).map(key => (
      { ...canvasItems[key], id: key }))
  }, [canvasItems]);

  const selectedRule = useMemo(() => {
    if (!canvasItems) return null;
    const selectedRuleIndex = Object.values(canvasItems).findIndex(
      r => r.isSelected === true,
    );
    const selectedRule = {
      ...Object.values(canvasItems)[selectedRuleIndex],
      id: Object.keys(canvasItems)[selectedRuleIndex],
    };
    if (selectedRuleIndex > -1) return selectedRule;
  }, [canvasItems]);

  const selectRule = id => {
    if (!canvasItems) return;
    const oldSelectedRule = selectedRule;
    if (oldSelectedRule) {
      if (oldSelectedRule.id === id) return;
      const deselectedRule = { ...oldSelectedRule, isSelected: false };
      actions.setRule(cameraId, deselectedRule.id, deselectedRule);
    }
    const newSelectedRule = canvasItems[id];
    if (newSelectedRule) {
      const selectedRule = { ...newSelectedRule, isSelected: true };
      actions.setRule(cameraId, id, selectedRule);
    }
  };

  const updateAllRules = useCallback(
    (updatedObjectValues, updatedObjectId) => {
      // This can be simplified with some changes to Canvas, but I'm trying to avoid touching that for now
      // as any changes to Canvas risk impacting privacy zones and PTZ functionality.
      const updatedObjectValue = updatedObjectValues.find(i => i.id == updatedObjectId);
      const updatedCanvasItem = canvasList.find(i => i.id == updatedObjectId);
      const roi = updatedObjectValue.region ? updatedObjectValue : null;
      const loi = updatedObjectValue.point1 ? updatedObjectValue : null;
      actions.setRule(cameraId, updatedObjectId, { ...updatedCanvasItem, roi, loi });
    },
    [actions, canvasList],
  );

  if (canvasItems) {
    return (
      <Canvas
        key="canvas"
        canEditROI={canEditROI}
        canvasList={canvasList}
        height={screenSpaceHeight}
        onObjectSelected={selectRule}
        streamDetails={streamDetails}
        type={canvasTypes.rules}
        updateCanvasList={updateAllRules}
        width={screenSpaceWidth}
      />
    );
  }
  return null;
};

RuleOverlayContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  cameraId: PropTypes.string.isRequired,
  canEditROI: PropTypes.bool,
  canvasItems: PropTypes.objectOf(
    PropTypes.shape({
      event: PropTypes.object,
      loi: PropTypes.object,
      roi: PropTypes.object,
    }),
  ),
  deviceId: PropTypes.string.isRequired,
  isFetchingCameraRules: PropTypes.bool,
  remoteId: PropTypes.string.isRequired,
  screenSpaceHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  screenSpaceWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  streamDetails: PropTypes.shape({
    cameraResHeight: PropTypes.number,
    cameraResWidth: PropTypes.number,
    xOffset: PropTypes.number,
    yOffset: PropTypes.number,
  }).isRequired,
};

RuleOverlayContainer.defaultProps = {
  canEditROI: false,
  canvasItems: null,
  isFetchingCameraRules: null,
};

const mapStateToProps = (state, ownProps) => {
  let canvasItems = null;
  let mappedProps = {};
  let deviceId;
  let remoteId;
  const { cameraId } = ownProps;
  const currentCameraData = state.devices.cameras.find(c => c.Id === cameraId);
  const currentStreamData = state.streamOverlays[cameraId];
  if (currentStreamData) {
    canvasItems = {};
    const canvasItemsRaw = {
      ...currentStreamData.rules,
      ...currentStreamData.ruleEdits,
    };
    Object.keys(canvasItemsRaw).forEach(key => {
      if (canvasItemsRaw[key] !== null) {
        canvasItems[key] = canvasItemsRaw[key];
      }
    })
  }
  if (currentCameraData) {
    deviceId = currentCameraData.ServerId;
    remoteId = currentCameraData.RemoteId;
  }
  return {
    cameraId,
    canvasItems,
    deviceId,
    mappedProps,
    remoteId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ getRules, setRule }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RuleOverlayContainer);
