import React from 'react';
import PropTypes from 'prop-types';

import * as styles from '../OGTable.css';

const OGRowActions = ({
  cellWidths,
  disabled,
  expanded,
  idx,
  rowActions,
  rowData,
  selected,
}) => {
  return (
    <>
      {rowActions.map((action, actionIdx) => {
        const itemId = rowData.id || rowData.Id;
        const rowKey = `row-action-${
          expanded ? 'details' : 'summary'
        }-${idx}-${actionIdx}-${itemId}`;
        const content = action({ rowData, idx, expanded, selected });
        return content ? (
          <div key={rowKey} className={expanded ? styles.detailActionItem : ''}>
            {content}
          </div>
        ) : (
          ''
        );
      })}
    </>
  );
};

OGRowActions.defaultProps = {
  cellWidths: null,
  disabled: false,
  expanded: false,
  isLastRow: false,
  overflowFieldOrder: [],
  overflowHeaderTranslations: {},
  rowActions: [],
  selected: false,
};

OGRowActions.propTypes = {
  cellWidths: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  expanded: PropTypes.bool,
  idx: PropTypes.number.isRequired,
  isLastRow: PropTypes.bool,
  overflowFieldOrder: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  overflowHeaderTranslations: PropTypes.objectOf(PropTypes.string),
  rowActions: PropTypes.arrayOf(PropTypes.any),
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.bool,
};

export default OGRowActions;
