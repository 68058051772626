import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getFullPath } from 'util/getPathName';

import HealthMonitor from './HealthMonitor';

const HMSContainer = ({ canChangeCustomer, fullPath, history, profile }) => (
  <HealthMonitor
    canChangeCustomer={canChangeCustomer}
    fullPath={fullPath}
    history={history}
    profile={profile}
  />
);

HMSContainer.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  fullPath: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const fullPath = getFullPath(ownProps.location.pathname);
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    fullPath,
    profile: state.user.profile,
  };
};

export default connect(
  mapStateToProps,
  null,
)(HMSContainer);
