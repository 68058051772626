import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { DropdownItemWrapper } from 'components';
import get from 'lodash.get';

// Styles
import { separator } from 'sharedStyles/styles.css';
import { menuPrimaryText } from 'components/DropdownItem/DropdownItem.style.css';
import {
  feedbackLink,
  userVoiceLink,
  userVoiceLinkText,
} from './UserVoiceLink.css';

const UserVoiceLinkContainer = ({
  customerToken,
  showAsDropdown,
  userVoiceUrl,
}) => {
  if (!userVoiceUrl || !customerToken) {
    return null;
  }

  const anchor = createRef();

  const renderInner = (linkClass, spanClass) => {
    return (
      <a
        ref={anchor}
        className={`${linkClass} ${feedbackLink}`}
        href={`${userVoiceUrl}?sso=${customerToken}`}
        id="docLinkButton_userVoice"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span className={spanClass}>
          <Translate id="FEEDBACK.BUTTON" />
        </span>
      </a>
    );
  };
  if (customerToken && customerToken !== '' && showAsDropdown) {
    const clickHandler = ev => {
      const { tagName } = ev.target;
      if (tagName !== 'A' && tagName !== 'SPAN') {
        anchor.current.click();
      }
    };
    return (
      <DropdownItemWrapper onClick={clickHandler}>
        {renderInner(menuPrimaryText, '')}
      </DropdownItemWrapper>
    );
  }
  return customerToken && customerToken !== '' ? (
    <>
      <span className={separator} />
      <div id="idDocLinkContainerWrapper" style={{ display: 'flex' }}>
        {renderInner(userVoiceLink, userVoiceLinkText)}
      </div>
    </>
  ) : null;
};

UserVoiceLinkContainer.defaultProps = {
  customerToken: '',
  showAsDropdown: false,
  userVoiceUrl: null,
};

UserVoiceLinkContainer.propTypes = {
  customerToken: PropTypes.string,
  showAsDropdown: PropTypes.bool,
  userVoiceUrl: PropTypes.string,
};

function mapStateToProps(state) {
  const { Token } = state.user.profile;
  const userVoiceUrl = get(state, 'utilities.links.UserVoice', null);

  return {
    customerToken: Token,
    userVoiceUrl,
  };
}

export default connect(
  mapStateToProps,
  null,
)(withLocalize(UserVoiceLinkContainer));
