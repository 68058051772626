import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Utils
import { accountTabAccess as tabAccess } from 'util/permissions';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_ACCOUNTS, PATH_SEGMENT_MONITORING } from 'constants/urlPaths';
import { tabs } from './constants';

const MonitoringNavMenu = props => {
  const { profile, translate } = props;
  const tabAccessPass = tabAccess(profile);
  const tabKeys = Object.keys(tabs);

  return (
    <NavTabs
      tabs={tabKeys
        .filter(key => tabAccessPass[key])
        .map(key => ({
          id: tabs[key].id,
          key,
          label: translate(tabs[key].label),
          path: `${PATH_ACCOUNTS}${PATH_SEGMENT_MONITORING}${tabs[key].path}`,
        }))}
    />
  );
};

MonitoringNavMenu.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(MonitoringNavMenu);
