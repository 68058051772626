import * as ENTITLEMENTS from 'constants/EntitlementTypes';

export const getEntitlementValues = organization => {
  let entitlementValues = {
    ACC: false,
    BLUE: false,
    HMS: false,
    REPORTS: false,
    SELF_PROVISIONED: false,
  };
  if (organization && organization.Entitlements) {
    const entitlements = organization.Entitlements;
    entitlementValues = {
      ACC: entitlements.includes(ENTITLEMENTS.AccCloudServices),
      BLUE: entitlements.includes(ENTITLEMENTS.BlueConnectSelfMonitoring),
      HMS: entitlements.includes(ENTITLEMENTS.HealthMonitorSystem),
      REPORTS: entitlements.includes(ENTITLEMENTS.SafetyMonitorSystem),
      SELF_PROVISIONED:  entitlements.includes(ENTITLEMENTS.SelfProvisioned),
    };
  }

  return entitlementValues;
};
