import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

export const translatedConnect = (
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  options,
) => {
  const translatedMapStateToProps = function(state, ownProps) {
    const translate = getTranslate(state.localize);
    const newOwnProps = { translate, ...ownProps };
    return mapStateToProps !== undefined
      ? {
          ...mapStateToProps(state, newOwnProps),
          translate,
        }
      : {
          translate,
        };
  };
  const translatedMapDispatchToProps = function() {
    return mapDispatchToProps !== undefined ? mapDispatchToProps : () => ({});
  };
  return connect(
    translatedMapStateToProps,
    translatedMapDispatchToProps,
    mergeProps,
    options,
  );
};
