// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone-all';
import { Translate, withLocalize } from 'react-localize-redux';

// Components
import {
  BarChartComponent,
  DateCell,
  GraphBar,
  TemplateCell,
} from 'components';
import { EmptyPlaceholder, ListNav, ListView, MainContentWrapper, PageTitle } from 'lib';
import {
  DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
  DEFAULT_LANGUAGE_CODE,
  SHORT_DATE_FORMAT,
  SHORT_INTERNATIONAL_DATE_FORMAT,
} from 'constants/app';
import { convertToShortFormat } from 'util/convertTimeTo';
import { warningText } from 'sharedStyles/tables.css';
import {
  getActiveLanguageCode,
  MISSING_TRANSLATE_FUNCTION,
} from 'util/languagesUtils';
import BillingDropdown from './BillingDropdown';
import LargeCard from './LargeCard';
import SmallCard from './SmallCard';

// Consts
import {
  DASHBOARD_LABELS,
  dashboardIds,
  packageChartConstants,
  RENEWAL_TABLE_HEADER_ORDER,
  RENEWAL_TABLE_HEADER_TRANSLATION_IDS,
  STAT_BOX_LABELS,
  UPGRADE_TABLE_HEADER_ORDER,
  UPGRADE_TABLE_HEADER_TRANSLATION_IDS,
} from './constants';

// Styles
import * as styles from './dashboard.styles.css';

class Dashboard extends Component {
  getBarChart = (colorArray, data, emptyColumns, isFetching) => {
    return (
      <LargeCard
        headerTextKey={DASHBOARD_LABELS.renewalPeriodsHeader}
        id={dashboardIds.RENEWAL_PERIODS_CHART_ID}
      >
        <EmptyPlaceholder
          isFetching={isFetching}
          items={emptyColumns.filter(x => x)}
          overrideClassName={styles.dashboardSpinner}
          translateKey="DEALER_DASHBOARD.NO_SUBSCRIPTION_PLANS_RENEWAL_MESSAGE"
        >
          <BarChartComponent
            chartKeys={Object.values(packageChartConstants).filter(
              (row, index, packageArray) =>
                index === 0 || row.index !== packageArray[index - 1].index,
            )}
            colorArray={colorArray}
            data={data}
          />
        </EmptyPlaceholder>
      </LargeCard>
    );
  };

  getGraphBar = (data, isFetching, maximum) => {
    return (
      <LargeCard
        headerTextKey={DASHBOARD_LABELS.subscriptionLevelHeader}
        id={dashboardIds.SUBSCRIPTION_LEVEL_CHART_ID}
      >
        <EmptyPlaceholder
          isFetching={isFetching}
          items={data.filter(x => x.count)}
          overrideClassName={styles.dashboardSpinner}
          translateKey="DEALER_DASHBOARD.NO_SUBSCRIPTION_PLANS_MESSAGE"
        >
          <GraphBar maximum={maximum} packageChartData={data} />
        </EmptyPlaceholder>
      </LargeCard>
    );
  };

  getIntervalDate = billingCycleDate => {
    const { preferredShortDateFormat } = this.props;
    const format =
      getActiveLanguageCode() === DEFAULT_LANGUAGE_CODE
        ? SHORT_DATE_FORMAT
        : SHORT_INTERNATIONAL_DATE_FORMAT;
    const date = Moment(
      billingCycleDate,
      DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
    ).format(format);
    return convertToShortFormat(date, preferredShortDateFormat);
  };

  getRenewalPeriodLabel(termLength) {
    const { translate } = this.props;
    if (termLength % 12 === 0) {
      if (termLength === 12) {
        return translate('DEALER_DASHBOARD.RENEWAL_PERIOD_LENGTH_YEARLY', {
          term: termLength / 12,
        });
      }
      return translate('DEALER_DASHBOARD.RENEWAL_PERIOD_LENGTH_YEARLY_PLURAL', {
        term: termLength / 12,
      });
    }
    return translate('DEALER_DASHBOARD.RENEWAL_PERIOD_LENGTH_MONTHLY_PLURAL', {
      term: termLength,
    });
  }

  getServicePackageNameCell = rowData => {
    const {
      IsSubscriptionExpired: isExpired,
      IsSubscriptionSuspended: isSuspended,
      ServicePackageName: name,
    } = rowData;

    if (isSuspended || isExpired) {
      const translateId = isSuspended
        ? 'ACCOUNTS.LOCATIONS.SUSPENDED'
        : 'ACCOUNTS.LOCATIONS.EXPIRED';

      return (
        <span>
          {name}&nbsp;
          <span className={warningText} style={{ display: 'inline-block' }}>
            <Translate id={translateId} />
          </span>
        </span>
      );
    }
    return <span>{name}</span>;
  };

  getCustomRenewalCells = data => {
    const { preferredLongDateFormat } = this.props;
    return {
      RenewalDate: (rowData, rowIndex) => {
        return (
          <DateCell
            data={data}
            field="RenewalDate"
            preferredLongDateFormat={preferredLongDateFormat}
            rowIndex={rowIndex}
          />
        );
      },
      ServicePackageName: rowData => this.getServicePackageNameCell(rowData),
    };
  };

  getRenewalTable = (data, isFetching) => {
    return (
      <LargeCard
        headerTextKey={DASHBOARD_LABELS.dueForRenewalHeader}
        id={dashboardIds.DUE_FOR_RENEWAL_ID}
      >
        <EmptyPlaceholder
          isFetching={isFetching}
          items={data}
          overrideClassName={styles.dashboardSpinner}
          translateKey="DEALER_DASHBOARD.NO_RENEWAL_SITES_MESSAGE"
        >
          <ListView
            customCells={this.getCustomRenewalCells(data)}
            data={data}
            fieldOrder={RENEWAL_TABLE_HEADER_ORDER}
            fillContainerHeight
            headerTranslationIds={RENEWAL_TABLE_HEADER_TRANSLATION_IDS}
            hideFilter
            sortType="local"
          />
        </EmptyPlaceholder>
      </LargeCard>
    );
  };

  getCustomUpgradeCells = data => {
    return {
      NumberUsed: (rowData, rowIndex) => {
        return (
          <TemplateCell
            data={data}
            rowIndex={rowIndex}
            template="DEALER_DASHBOARD.CAMERA_COUNT_VALUE_UPGRADE_TABLE"
          />
        );
      },
      ServicePackageName: rowData => this.getServicePackageNameCell(rowData),
    };
  };

  getUpgradeTable = (data, isFetching) => {
    return (
      <LargeCard
        headerTextKey={DASHBOARD_LABELS.upgradeOpportunitiesHeader}
        id={dashboardIds.UPGRADE_OPPORTUNITIES_ID}
      >
        <EmptyPlaceholder
          isFetching={isFetching}
          items={data}
          overrideClassName={styles.dashboardSpinner}
          translateKey="DEALER_DASHBOARD.NO_UPGRADE_OPPORTUNITIES_MESSAGE"
        >
          <ListView
            customCells={this.getCustomUpgradeCells(data)}
            data={data}
            fieldOrder={UPGRADE_TABLE_HEADER_ORDER}
            fillContainerHeight
            headerTranslationIds={UPGRADE_TABLE_HEADER_TRANSLATION_IDS}
            hideFilter
            sortType="local"
          />
        </EmptyPlaceholder>
      </LargeCard>
    );
  };

  handleChangeBillingCycle = option => {
    const { handleCurrentBillingCycleChange } = this.props;
    handleCurrentBillingCycleChange(option.cycleId);
  };

  processPackageChartData = (packages = null) => {
    const packageArray = [];
    if (packages) {
      packages.forEach(servicePackage => {
        const dataRow =
          packageChartConstants[servicePackage.ServicePackageSkuId];
        if (dataRow) {
          if (packageArray[dataRow.index]) {
            packageArray[dataRow.index].count += servicePackage.Count;
            packageArray[dataRow.index].numberUp += servicePackage.NumberAdded;
            packageArray[dataRow.index].numberDown +=
              servicePackage.NumberDeleted;
            // up, down
          } else {
            packageArray[dataRow.index] = {
              ...dataRow,
              count: servicePackage.Count,
              numberDown: servicePackage.NumberDeleted,
              numberUp: servicePackage.NumberAdded,
            };
          }
        }
      });
    }
    return packageArray;
  };

  processRenewalChartData = (data = []) => {
    const renewalChartColorArray = [];
    const emptyColumnsArray = Array(data.length); // Array representing which bar groups contain data
    const renewalChartData = data.map((renewalPeriod, groupIndex) => {
      // expected data is subscriptionRenewalPeriods
      // packageChartConstants from constants
      // forming the colorArray here guarantees colors are correctly ordered
      const yArray = [];
      renewalPeriod.Levels.forEach(subscriptionLevel => {
        const dataRow =
          packageChartConstants[subscriptionLevel.ServicePackageSkuId];
        if (dataRow) {
          yArray[dataRow.index] =
            (yArray[dataRow.index] || 0) + subscriptionLevel.Count;
          renewalChartColorArray[dataRow.index] = dataRow.color;
          if (subscriptionLevel.Count > 0) emptyColumnsArray[groupIndex] = true;
        }
      });
      return {
        label: this.getRenewalPeriodLabel(renewalPeriod.TermLength),
        y: yArray,
      };
    });
    return { emptyColumnsArray, renewalChartColorArray, renewalChartData };
  };

  render() {
    const {
      canChangeCustomer,
      dealerBillingCycles,
      dealerSubscriberStats,
      dealerSubscriberUpgradeSites,
      dealerSubscribersNearingRenewal,
      dealerSubscriptionLevels,
      isFetchingDealerSubscriberStats,
      isFetchingDealerSubscriberUpgradeSites,
      isFetchingDealerSubscribersNearingRenewal,
      isFetchingDealerSubscriptionLevels,
      isFetchingSubscriptionRenewalPeriods,
      profile,
      subscriptionRenewalPeriods,
      translate,
    } = this.props;

    const packageChartData = this.processPackageChartData(
      dealerSubscriptionLevels,
    );

    const packageChartDataMaximum = packageChartData.reduce((max, cur) => {
      if (cur.count > max) {
        return cur.count;
      }
      return max;
    }, 0);

    const chartData = this.processRenewalChartData(subscriptionRenewalPeriods);

    const {
      emptyColumnsArray,
      renewalChartColorArray,
      renewalChartData,
    } = chartData;

    const statOrder = [
      'Subscribers',
      'Subscriptions',
      'Sites',
      'MonitoredSites',
      'Servers',
      'Cameras',
    ];

    return (
      <div className={styles.dashboardContent}>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          hasFilters={false}
          pageTitle={<PageTitle titleId="NAV_MENU.TITLES.DASHBOARD" />}
          profile={profile}
        />
        <MainContentWrapper>
          <BillingDropdown
            dealerBillingCycles={dealerBillingCycles}
            getIntervalDate={this.getIntervalDate}
            handleChangeBillingCycle={this.handleChangeBillingCycle}
          />

          <div
            className={styles.dashboardRowTop}
            id={dashboardIds.SUBSCRIBER_STATS_ID}
          >
            {statOrder.map(statKey => (
              <SmallCard
                key={statKey}
                data={dealerSubscriberStats[statKey]}
                isFetching={isFetchingDealerSubscriberStats}
                translateKeyLabel={STAT_BOX_LABELS[statKey]}
              />
            ))}
          </div>
          <div className={styles.dashboardRowBottom}>
            <div className={styles.dashboardColumnLeft}>
              {this.getGraphBar(
                packageChartData,
                isFetchingDealerSubscriptionLevels,
                packageChartDataMaximum,
              )}
              {this.getRenewalTable(
                dealerSubscribersNearingRenewal,
                isFetchingDealerSubscribersNearingRenewal,
              )}
            </div>
            <div className={styles.dashboardColumnRight}>
              {this.getBarChart(
                renewalChartColorArray,
                renewalChartData,
                emptyColumnsArray,
                isFetchingSubscriptionRenewalPeriods,
              )}
              {this.getUpgradeTable(
                dealerSubscriberUpgradeSites,
                isFetchingDealerSubscriberUpgradeSites,
              )}
            </div>
          </div>
        </MainContentWrapper>
      </div>
    );
  }
}

Dashboard.defaultProps = {
  dealerBillingCycles: [],
  dealerSubscriberStats: {},
  dealerSubscriberUpgradeSites: [],
  dealerSubscribersNearingRenewal: [],
  dealerSubscriptionLevels: [],
  isFetchingDealerSubscriberStats: null,
  isFetchingDealerSubscriberUpgradeSites: null,
  isFetchingDealerSubscribersNearingRenewal: null,
  isFetchingDealerSubscriptionLevels: null,
  isFetchingSubscriptionRenewalPeriods: null,
  preferredLongDateFormat: '',
  preferredShortDateFormat: '',
  profile: {},
  subscriptionRenewalPeriods: [],
  translate: () => MISSING_TRANSLATE_FUNCTION,
};

Dashboard.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  dealerBillingCycles: PropTypes.arrayOf(PropTypes.shape({})),
  dealerSubscriberStats: PropTypes.shape({}),
  dealerSubscriberUpgradeSites: PropTypes.arrayOf(PropTypes.shape({})),
  dealerSubscribersNearingRenewal: PropTypes.arrayOf(PropTypes.shape({})),
  dealerSubscriptionLevels: PropTypes.arrayOf(PropTypes.shape({})),
  handleCurrentBillingCycleChange: PropTypes.func.isRequired,
  isFetchingDealerSubscriberStats: PropTypes.bool,
  isFetchingDealerSubscriberUpgradeSites: PropTypes.bool,
  isFetchingDealerSubscribersNearingRenewal: PropTypes.bool,
  isFetchingDealerSubscriptionLevels: PropTypes.bool,
  isFetchingSubscriptionRenewalPeriods: PropTypes.bool,
  preferredLongDateFormat: PropTypes.string,
  preferredShortDateFormat: PropTypes.string,
  profile: PropTypes.objectOf(PropTypes.any),
  subscriptionRenewalPeriods: PropTypes.arrayOf(PropTypes.shape({})),
  translate: PropTypes.func,
};

export default withLocalize(Dashboard);
