export const columnHeaders = {
  ConnectionState: 'COMMON.STATUS',
  IPAddress: 'DEVICES.TABLE_HEADERS.IP_ADDRESS',
  LocationName: 'COMMON.SITE',
  Model: 'DEVICES.TABLE_HEADERS.MODEL',
  Name: 'COMMON.NAME',
  ServerName: 'DEVICES.TABLE_HEADERS.SERVER',
};

export const overflowColumnHeaders = {
  FirmwareVersion: 'DEVICES.TABLE_HEADERS.FIRMWARE',
  HostName: 'DEVICES.TABLE_HEADERS.HOST_NAME',
  MacAddress: 'DEVICES.TABLE_HEADERS.MAC_ADDRESS',
  Manufacturer: 'DEVICES.TABLE_HEADERS.MANUFACTURER',
  SerialNumber: 'DEVICES.TABLE_HEADERS.SERIAL_NUMBER',
};

export const fieldOrder = [
  'Name',
  'ConnectionState',
  'LocationName',
  'ServerName',
  'IPAddress',
  'Model',
];

export const overflowFieldOrder = {
  Camera: ['MacAddress', 'Manufacturer', 'FirmwareVersion', 'SerialNumber'],
  Server: ['MacAddress', 'HostName', 'FirmwareVersion', 'SerialNumber'],
};

export const CELL_WIDTHS = {
  Actions: 40,
  Name: 250,
};

export const idDeleteDeviceButton = 'deleteDeviceButton';

export const DEVICE_TYPES = {
  CAMERA: 'Camera',
  SERVER: 'Server',
};
