/* eslint max-classes-per-file: ["error", 2] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { translatedConnect } from 'util/translatedConnect';

// Actions
import * as DeviceActions from 'actions/devices';

// Components
import { DigitalOutConfigForm, ServerDetailDigitalOut } from 'components';
import { AccordionItem, EmptyPlaceholder } from 'lib';
import { generateSort } from 'util/generateSort';

// Utils
import {
  cloneDeep,
  getDigitalOutLinksFromCamera,
  setCamerasLinkFlag,
} from 'util/cameraSettingLinks';

class FormLoadingElement extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  render() {
    return <EmptyPlaceholder isFetching />;
  }
}

class ServerDetailDigitalOutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAccordions: [],
    };
  }

  componentDidMount() {
    this.props.actions.getEntities(this.props.serverId);
  }

  handleFormOpen = entityId => {
    this.props.isFetchingServerEntities[entityId] ||
      this.props.actions.getEntitySettings(this.props.serverId, entityId);
  };

  handleFormSubmit = (formData, entityId, links) => {
    const data = {
      id: entityId,
      settings: {
        digital_output: formData,
      },
    };
    const linkedCamerasToUpdate = links.filter(
      camera => camera.isLinked !== undefined,
    );
    this.props.actions.updateEntitySettingsAndLinks(
      data,
      entityId,
      this.props.serverId,
      linkedCamerasToUpdate,
    );
  };

  render() {
    return (
      <ServerDetailDigitalOut
        cameras={this.props.connectedCameras}
        digitalOutputs={this.props.serverDigitalOutputs}
        isFetching={this.props.isFetchingServerEntities}
      >
        <EmptyPlaceholder
          isFetching={this.props.isFetchingServerEntities}
          items={this.props.serverDigitalOutputs}
        >
          {this.props.serverDigitalOutputs &&
            this.props.serverDigitalOutputs.map(entity => (
              <AccordionItem key={entity.id} theme="gray" title={entity.name}>
                {this.props.digitalOutConfig[entity.id] &&
                this.props.digitalOutConfig[entity.id].digital_output ? (
                  <DigitalOutConfigForm
                    key={entity.id}
                    digitalOutData={
                      this.props.digitalOutConfig[entity.id].digital_output
                    }
                    digitalOutLinks={this.props.links[entity.id]}
                    digitalOutputAvailableCameras={
                      this.props.digitalOutCameras[entity.id]
                    }
                    onSubmit={(formData, links) =>
                      this.handleFormSubmit(formData, entity.id, links)
                    }
                    serverId={this.props.server.Id}
                    serverName={this.props.server.Name}
                  />
                ) : (
                  <FormLoadingElement
                    onMount={() => this.handleFormOpen(entity.id)}
                  />
                )}
              </AccordionItem>
            ))}
        </EmptyPlaceholder>
      </ServerDetailDigitalOut>
    );
  }
}

ServerDetailDigitalOutContainer.propTypes = {
  serverId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let server;
  let serverDigitalOutputs;
  let isFetchingServerEntities;
  let connectedCameras;
  const digitalOutCameras = {};
  const links = {};
  if (ownProps.serverId) {
    server = state.devices.devices.find(d => d.Id === ownProps.serverId);
    const serverEntities = state.devices.entities[ownProps.serverId];
    isFetchingServerEntities =
      state.devices.isFetchingDeviceEntities[ownProps.serverId];
    if (serverEntities) {
      serverDigitalOutputs = serverEntities.filter(
        entity =>
          entity.type === 'DIGITAL_OUTPUT' &&
          entity.apiType === 'LOCAL_DIGITAL_IO',
      );
      serverDigitalOutputs.sort(
        generateSort(item => item.name && item.name.toLowerCase()),
      );
    }
    connectedCameras =
      state.devices.cameras &&
      state.devices.cameras.filter(
        camera =>
          camera.DeviceId === ownProps.serverId && camera.Active === true,
      );
    if (serverEntities && connectedCameras) {
      serverDigitalOutputs.forEach(entity => {
        const entityId = entity.id;
        const thisLinks = getDigitalOutLinksFromCamera(
          connectedCameras,
          [entity],
          server,
        );
        const thisCameras = setCamerasLinkFlag(
          cloneDeep(connectedCameras),
          thisLinks,
        );
        links[entityId] = thisLinks;
        digitalOutCameras[entityId] = thisCameras;
      });
    }
  }
  return {
    connectedCameras,
    digitalOutCameras,
    digitalOutConfig: state.devices.entitySettings,
    isFetchingEntitySettings: state.devices.isFetchingEntitySettings, // entityId => config
    isFetchingServerEntities,
    links,
    server,
    serverDigitalOutputs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ ...DeviceActions }, dispatch),
  };
};

export default translatedConnect(mapStateToProps, mapDispatchToProps)(
  ServerDetailDigitalOutContainer,
);
