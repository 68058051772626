import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconClock = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 16 17"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <circle id="path-1" cx="6" cy="6" r="6" />
        <filter
          x="-20.8%"
          y="-20.8%"
          width="141.7%"
          height="150.0%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dx="0"
            dy="1"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="shadowInner"
          />
          <feOffset dx="0" dy="1" in="shadowInner" result="shadowInner" />
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <filter
          x="-10.0%"
          y="-8.3%"
          width="120.0%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
      </defs>
      <g
        id="view-live-panel-redlines"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="view-live-panel" transform="translate(-244.000000, -175.000000)">
          <g id="Group-6" transform="translate(63.000000, 48.000000)">
            <g id="Group" transform="translate(183.000000, 129.000000)">
              <g id="Oval-4">
                <circle
                  id="path-1"
                  cx="6"
                  cy="6"
                  r="6"
                  fill="black"
                  fillOpacity="1"
                  filter="url(#filter-2)"
                />
                <circle stroke="#006e99" strokeWidth="2" cx="6" cy="6" r="7" />
              </g>
              <g id="Combined-Shape">
                <path
                  d="M6.25,6.5 L6.25,2 L5,2 L5,7.25 L5,8 L10,8 L10,6.5 L6.25,6.5 Z"
                  fill="black"
                  fillOpacity="1"
                />
                <path
                  d="M6.25,6.5 L6.25,2 L5,2 L5,7.25 L5,8 L10,8 L10,6.5 L6.25,6.5 Z"
                  fill="#006e99"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconClock;
