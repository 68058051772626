import React from 'react';
import { Translate } from 'react-localize-redux';
import { Button } from 'lib';
import { PropTypes } from 'prop-types';
import {
  wrapper,
  inactivityInfo,
  centerContent,
  titleWrap,
} from './InactivityPrompt.styles.css';

const InactivityPrompt = ({ onResumeStreaming }) => {
  return (
    <div className={wrapper}>
      <div className={`${centerContent} ${titleWrap}`}>
        <Translate id="VIEWS.INACTIVITY_TITLE" />
      </div>
      <div className={inactivityInfo}>
        <Translate id="VIEWS.INACTIVITY_INFO" />
        <span> </span>
      </div>
      <div className={centerContent}>
        <Button
          key="resumeStreamingButton"
          id="resumeStreamingButton"
          inputType="button"
          onClick={onResumeStreaming}
          text={<Translate id="BUTTONS.RESUME" />}
        />
      </div>
    </div>
  );
};

InactivityPrompt.propTypes = {
  onResumeStreaming: PropTypes.func.isRequired,
};

export default InactivityPrompt;
