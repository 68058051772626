export const flattenCameraIds = children =>
  // SiteView contains cameras that are nested in folders that can also be nested.
  // This will retrieve a flattened list of cameraIds that are in SiteView.
  children.reduce((flattened, child) => {
    if (child.children && child.children.length) {
      flattened.push(...flattenCameraIds(child.children));
      return flattened;
    }

    if (child.type === 'CAMERA') {
      flattened.push(child.id);
    }

    return flattened;
  }, []);

export const getMissingCameras = (siteView, cameras = []) => {
  if (siteView) {
    // Check if any new cameras are absent from the site view tree
    const siteViewCameraIds = flattenCameraIds(siteView);
    const cameraIds = []; // Cameras array may contain duplicates, so track ids as they are added
    const missingCameras = [];
    cameras.forEach(c => {
      if (!siteViewCameraIds.includes(c.id) && !cameraIds.includes(c.id)) {
        cameraIds.push(c.id);
        missingCameras.push({ name: '', ...c, type: 'CAMERA' });
      }
    });
    if (missingCameras.length > 0) {
      const sortedMissingCameras = missingCameras.sort((curr, next) =>
        // a placeholder name is added when we push each camera onto missingCameras,
        // so the existence of curr.name can be safely assumed.
        curr.name.localeCompare(next.name),
      );
      return sortedMissingCameras;
    }
    return missingCameras;
  }
};
