import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';

const Tag = ({ className, filled, label, tagType, translate }) => {
  return (
    <div className={`${tagType} ${className} ${filled && 'filled'} msi-tag`}>
      <a href="#" tabIndex="-1">
        {translate(label)}
      </a>
    </div>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.bool,
  label: PropTypes.string,
  tagType: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

Tag.defaultProps = {
  className: null,
  filled: true,
  label: '',
  tagType: 'secondary',
};

export default withLocalize(React.memo(Tag));
