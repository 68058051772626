import React from 'react';
import { PropTypes } from 'prop-types';
import { panelSettings } from './panelSettings.css';

const PanelSettings = ({ children }) => (
  <div className={panelSettings}>{children}</div>
);

PanelSettings.propTypes = {
  children: PropTypes.node,
};

PanelSettings.defaultProps = {
  children: null,
};

export default PanelSettings;
