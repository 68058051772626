// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccordionItem } from 'lib';

// Actions
import { toggleAccordion } from 'actions/accordions';

// Styles
import {
  accordionItem,
  itemStatCount,
  itemStatIcon,
  itemStatItem,
  itemStatLabel,
  itemStatsContainer,
  noticeWrapper,
} from './styles.css';

const StatsDisplay = props => {
  const { stats } = props;
  return (
    <div className={itemStatsContainer}>
      {stats.map(statItem => (
        <div key={statItem.label} className={itemStatItem}>
          <div className={itemStatLabel}>
            <Translate id={statItem.label} />
          </div>
          <div className={itemStatIcon}>{statItem.icon}</div>
          <div className={itemStatCount}>{statItem.count}</div>
        </div>
      ))}
    </div>
  );
};

StatsDisplay.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.any),
};

StatsDisplay.defaultProps = {
  stats: [],
};

class PersistentAccordionItem extends Component {
  componentDidMount() {
    const {
      disabled,
      isExpanded,
      item,
      loadExpanded,
      retrieveContainedData,
    } = this.props;
    if (isExpanded) {
      retrieveContainedData(item);
    }
    // toggle first accordion
    if (loadExpanded && !isExpanded && !disabled) {
      this.toggleSite();
    }
  }

  componentDidUpdate(prevProps) {
    const { disabled, isExpanded, loadExpanded } = this.props;
    if (
      prevProps.loadExpanded !== loadExpanded &&
      loadExpanded !== !!isExpanded &&
      !disabled
    ) {
      this.toggleSite();
    }
  }

  get canBeExpanded() {
    const { canBeExpanded, item } = this.props;
    return canBeExpanded(item) || true;
  }

  get leftSideContent() {
    const { notice } = this.props;
    return (
      <>
        <span className={noticeWrapper}>{notice}</span>
      </>
    );
  }

  get rightSideContent() {
    const { disabled, isExpanded, rightActionButton, stats } = this.props;
    return (
      <>
        <StatsDisplay isExpanded={isExpanded && !disabled} stats={stats} />
        {rightActionButton}
      </>
    );
  }

  toggleSite = (e, show = null) => {
    const {
      accordionType,
      actions,
      isExpanded,
      item,
      retrieveContainedData,
    } = this.props;
    if (this.canBeExpanded) {
      const newExpandedState = show === null ? !isExpanded : show;
      actions.toggleAccordion(
        accordionType,
        item.Id || item.SubscriberId,
        newExpandedState,
      );

      if (newExpandedState) {
        retrieveContainedData(item);
      }
    }
  };

  render() {
    const {
      children,
      disabled,
      isExpanded,
      item,
      leftIcon,
      nameField,
    } = this.props;
    return (
      <div className={accordionItem}>
        <AccordionItem
          disabled={disabled}
          expansionCallback={() => this.toggleSite(item)}
          icon={leftIcon}
          isExpanded={isExpanded}
          leftItems={this.leftSideContent}
          rightItems={this.rightSideContent}
          theme="persist"
          title={item[nameField]}
        >
          {children}
        </AccordionItem>
      </div>
    );
  }
}

PersistentAccordionItem.propTypes = {
  accordionType: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.any),
  canBeExpanded: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  leftIcon: PropTypes.element,
  loadExpanded: PropTypes.bool,
  nameField: PropTypes.string.isRequired,
  notice: PropTypes.element,
  retrieveContainedData: PropTypes.func,
  rightActionButton: PropTypes.element,
  stats: PropTypes.arrayOf(PropTypes.any),
};

PersistentAccordionItem.defaultProps = {
  accordionType: null,
  actions: null,
  canBeExpanded: () => {
    return true;
  },
  children: null,
  disabled: false,
  isExpanded: false,
  leftIcon: null,
  loadExpanded: false,
  notice: null,
  retrieveContainedData: () => {},
  rightActionButton: null,
  stats: [],
};

function mapStateToProps(state, ownProps) {
  let isExpanded;
  const accordionsForType = state.accordions.types[ownProps.accordionType];
  if (accordionsForType) {
    const accordionId = ownProps.item.Id || ownProps.item.SubscriberId;
    const accordion = accordionsForType.accordions.find(
      ac => ac.accordionId === accordionId,
    );
    isExpanded = accordion ? accordion.isExpanded : false;
  }
  return {
    isExpanded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        toggleAccordion,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersistentAccordionItem);
