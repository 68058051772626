// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

// Components
import { DigitalOutLinkCameras, DurationInput } from 'components';
import { Button, GroupLayout } from 'lib';

// Utils
import jsonParseRobust from 'util/jsonParseRobust';
import { cloneDeep } from 'util/cameraSettingLinks';

// Styles
import {
  digitalOutForm,
  digitalOutInputGroup,
  formContent,
  formFieldMultiLine,
  formGroupMultiLine,
  inputWrapper,
  settingsPanelHeader,
} from './styles.css';
import { formField, formGroup } from '../CameraSettings/styles.css';

// Constants
import {
  DIGITAL_OUT_BUTTON_IDS,
  DIGITAL_OUT_FIELDS,
  DIGITAL_OUT_FORM_TRANSLATED,
  idCircuitClosed,
  idCircuitOpen,
  idDigitalOutName,
  idHold,
  idPulse,
} from './constants';

const getInitialState = props => {
  return {
    digitalOutSettings: props.digitalOutData,
    digitalOutputAvailableCameras: cloneDeep(
      props.digitalOutputAvailableCameras,
    ),
    isSaving: props.isSaving,
    settingsChanged: false,
  };
};

class DigitalOutConfigForm extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
  }

  // Setup
  get outputName() {
    const { digitalOutData } = this.props;

    if (digitalOutData.digitalOutputName) {
      return digitalOutData.digitalOutputName.val;
    }
    return null;
  }

  getDigitalOutForm(settings) {
    const { children } = this.props;
    return (
      <div className={digitalOutForm}>
        {DIGITAL_OUT_FIELDS.map(fieldName => {
          return (
            <div
              key={fieldName}
              className={
                fieldName === 'digitalOutputName'
                  ? `${formGroup} ${formGroupMultiLine}`
                  : digitalOutInputGroup
              }
            >
              <div className={settingsPanelHeader}>
                {this.renderFieldLabel(fieldName) && (
                  <Translate id={this.renderFieldLabel(fieldName)} />
                )}
              </div>
              {this.renderFormField(fieldName, settings[fieldName])}
            </div>
          );
        })}
        {children}
      </div>
    );
  }

  showPulseDurationFields = () => {
    const { digitalOutSettings } = this.state;
    return digitalOutSettings.outputModeIsHold.val === false;
  };

  // Subcomponents
  renderFieldLabel = fieldName => {
    if (fieldName === 'pulseDurationMs' && !this.showPulseDurationFields()) {
      return null;
    }
    return DIGITAL_OUT_FORM_TRANSLATED.FIELDS[fieldName].displayTranslatedName;
  };

  renderFormField = (fieldName, data) => {
    const { digitalOutLinks, serverId, serverName } = this.props;
    const { digitalOutputAvailableCameras } = this.state;
    const server = { Id: serverId, Name: serverName };
    let returnValue;

    switch (fieldName) {
      case 'digitalOutputName':
        returnValue = (
          <div className={`${formField} ${formFieldMultiLine}`}>
            <input
              id={idDigitalOutName}
              onChange={this.changeName}
              value={data.val}
            />
          </div>
        );
        break;
      case 'circuitStateNormallyClosed':
        returnValue = (
          <div className={inputWrapper}>
            <input
              checked={!data.val}
              id={idCircuitClosed}
              onChange={this.changeCircuitDefaultState}
              type="radio"
              value={false}
            />
            <Translate id="DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_OPTIONS.CIRCUIT_STATE_NORMALLY_OPEN_OPTION" />
            <br />
            <input
              checked={!!data.val}
              id={idCircuitOpen}
              onChange={this.changeCircuitDefaultState}
              type="radio"
              value
            />
            <Translate id="DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_OPTIONS.CIRCUIT_STATE_NORMALLY_CLOSED_OPTION" />
          </div>
        );
        break;
      case 'outputModeIsHold':
        returnValue = (
          <div className={inputWrapper}>
            <input
              checked={!!data.val}
              id={idHold}
              maxLength="85"
              onChange={this.changeOutputMode}
              type="radio"
              value
            />
            <Translate id="DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_OPTIONS.OUTPUT_MODE_HOLD_OPTION" />
            <br />
            <input
              checked={!data.val}
              id={idPulse}
              onChange={this.changeOutputMode}
              type="radio"
              value={false}
            />
            <Translate id="DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_OPTIONS.OUTPUT_MODE_PULSE_OPTION" />
          </div>
        );
        break;
      case 'pulseDurationMs':
        if (!this.showPulseDurationFields()) {
          returnValue = null;
          break;
        }
        returnValue = (
          <DurationInput
            initialDuration={data.val / 1000}
            maxDuration={data.max / 1000}
            minDuration={data.min / 1000}
            onChange={this.changeDuration}
          />
        );
        break;
      case 'linkedCameras':
        returnValue = (
          <DigitalOutLinkCameras
            cameras={digitalOutputAvailableCameras}
            links={digitalOutLinks}
            onCameraSelect={this.changeLinkedCamera}
            server={server}
          />
        );
        break;
      default:
        returnValue = null;
        break;
    }
    return returnValue;
  };

  filterLinkedCameras = () => {
    const { digitalOutputAvailableCameras: propCameras } = this.props;
    const { digitalOutputAvailableCameras: stateCameras } = this.state;

    return stateCameras.map(camera => {
      if (
        camera.isLinked &&
        propCameras.find(
          propsCam => camera.Id === propsCam.Id && propsCam.isLinked,
        )
      ) {
        // ignore if was already linked
        delete camera.isLinked;
      }
      return camera;
    });
  };

  handleSave = () => {
    const { onSubmit } = this.props;
    const { digitalOutSettings } = this.state;

    this.setState({ isSaving: true, settingsChanged: false }, () =>
      onSubmit(digitalOutSettings, this.filterLinkedCameras()),
    );
  };

  handleCancel = () => {
    this.setState(getInitialState(this.props));
  };

  changeDigitalOutSetting = (propName, value) => {
    const { digitalOutSettings } = this.state;
    const updatedOutputSetting = {
      ...digitalOutSettings[propName],
      val: value,
    };
    const newOutput = {
      ...digitalOutSettings,
      [propName]: updatedOutputSetting,
    };
    const isNameFilled =
      propName === 'digitalOutputName' ? value && value.trim().length : true;
    this.setState({
      digitalOutSettings: newOutput,
      settingsChanged: true && isNameFilled,
    });
  };

  changeName = evt => {
    const newValue = evt.target.value;
    this.changeDigitalOutSetting('digitalOutputName', newValue);
  };

  changeOutputMode = evt => {
    const isHold = jsonParseRobust(evt.target.value);
    this.changeDigitalOutSetting('outputModeIsHold', isHold);
  };

  changeDuration = newDuration => {
    this.changeDigitalOutSetting('pulseDurationMs', newDuration * 1000);
  };

  changeCircuitDefaultState = evt => {
    const newValue = jsonParseRobust(evt.target.value);
    this.changeDigitalOutSetting('circuitStateNormallyClosed', newValue);
  };

  changeLinkedCamera = cameraId => {
    const { digitalOutputAvailableCameras: propsAvailableCameras } = this.props;
    const { digitalOutputAvailableCameras: stateAvailableCameras } = this.state;
    const cameras = cloneDeep(stateAvailableCameras).map(camera => {
      if (camera.Id !== cameraId) {
        return camera;
      }

      if (camera.isLinked !== true) {
        camera.isLinked = true;
        return camera;
      }

      const wasPreviouslyLinked = propsAvailableCameras.find(
        propsCam => camera.Id === propsCam.Id && propsCam.isLinked,
      );

      if (wasPreviouslyLinked) {
        // if camera was previously saved as linked, set isLinked to false for unlink action
        camera.isLinked = false;
      } else {
        // user linked the camera before but didn't save, delete to not trigger link/unlink actions
        delete camera.isLinked;
      }
      return camera;
    });

    this.setState({
      digitalOutputAvailableCameras: cameras,
      settingsChanged: true,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      digitalOutData,
      digitalOutLinks,
      digitalOutputAvailableCameras,
      isSaving,
    } = this.props;

    const { settingsChanged } = this.state;
    // Handles receipt of new d/o data only. Provide a unique key
    // based on entityId so that this component is reinitialized if
    // entityId changes
    if (
      digitalOutData !== nextProps.digitalOutData ||
      isSaving !== nextProps.isSaving
    ) {
      this.setState(getInitialState(nextProps));
    }
    if (
      !settingsChanged &&
      (digitalOutputAvailableCameras !==
        nextProps.digitalOutputAvailableCameras ||
        digitalOutLinks !== nextProps.digitalOutLinks)
    ) {
      this.setState(getInitialState(nextProps));
    }
  }

  render() {
    const { digitalOutSettings, isSaving, settingsChanged } = this.state;

    return (
      <div>
        <div className={formContent}>
          {this.getDigitalOutForm(digitalOutSettings)}
        </div>
        <GroupLayout verticalCenter verticalSpacing="medium">
          <Button
            key={DIGITAL_OUT_BUTTON_IDS.REVERT}
            buttonType="primary"
            disabled={isSaving || !settingsChanged}
            id={DIGITAL_OUT_BUTTON_IDS.REVERT}
            inputType="button"
            onClick={this.handleCancel}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
          <Button
            key={DIGITAL_OUT_BUTTON_IDS.SAVE}
            buttonType="primary"
            disabled={isSaving || !settingsChanged}
            id={DIGITAL_OUT_BUTTON_IDS.SAVE}
            inputType="button"
            onClick={this.handleSave}
            text={<Translate id="BUTTONS.SAVE" />}
          />
        </GroupLayout>
      </div>
    );
  }
}

DigitalOutConfigForm.propTypes = {
  children: PropTypes.node,
  digitalOutData: PropTypes.shape({}).isRequired,
  digitalOutLinks: PropTypes.arrayOf(PropTypes.shape({})),
  digitalOutputAvailableCameras: PropTypes.arrayOf(PropTypes.shape({})),
  // Don't forget to provide the component a unique key based on the
  // cameraId or entityId in a case where multiple d/os are loaded
  isSaving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  serverId: PropTypes.string.isRequired,
  serverName: PropTypes.string.isRequired,
};

DigitalOutConfigForm.defaultProps = {
  children: '',
  digitalOutLinks: [],
  digitalOutputAvailableCameras: [],
  isSaving: false,
};

export default DigitalOutConfigForm;
