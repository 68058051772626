// Libs
import PropTypes from 'prop-types';

import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import renderIf from 'render-if';

// Components
import { DropdownItem } from 'components';
import { Dropdown, NoStyleButton } from 'lib';

// Icons
import { IconAvatar } from 'icons';

// Constants
import { PATH_PROFILE } from 'constants/urlPaths';
import * as userUtils from 'util/userRoles';
import * as tenantUtils from 'util/tenantTypes';
import HelpDropdown from './HelpDropdown';
import * as LoginConsts from './LoginStatus.constants';

// Styles
import {
  arrowDown,
  arrowUp,
  horizontalSeperator,
  loginStatusContainer,
  loginStatusImg,
  menuActive,
  orgs,
  status,
  switchOrgsDropdown,
  usernameArrowContainer,
  userNameDisabled,
  userNameWrapper,
} from './LoginStatus.css';

const LoginLink = (
  <Link to="login">
    <Translate id="MASTER_HEADER.SIGN_IN" />
  </Link>
);

// Class
export function LoginStatus(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOrgs, setShowOrgs] = useState(false);

  const {
    currentOrganization,
    isAuthenticated,
    isFetchingProfile,
    organizations,
    userName,
  } = props;

  function toggleDropdown() {
    const { isEnabled } = props;
    // if snapshots are being fetched, do not let user logout or change org
    if (isEnabled) {
      setShowDropdown(!showDropdown);
    }
  }

  function routeToProfile() {
    const { history } = props;
    history.push(PATH_PROFILE);
  }
  function orgClassName() {
    const { organizations: orgsProp } = props;
    return orgsProp.length > 0 ? ` ${orgs}` : '';
  }

  function orgDropdownClassName() {
    const { organizations: orgsProp } = props;
    return orgsProp.length > 0 ? ` ${switchOrgsDropdown}` : '';
  }

  function currentUserRole() {
    const { currentRole } = props;
    return <Translate id={userUtils.parseUserRolesKey(currentRole)} />;
  }

  function changeOrganization(id) {
    const { changeOrganization: changeOrg } = props;
    changeOrg(id);
  }

  function mouseEnter() {
    setShowOrgs(true);
  }

  function mouseLeave() {
    setShowOrgs(false);
  }

  function toggleOrgSubmenu(e) {
    e.stopPropagation();
    setShowOrgs(!showOrgs);
  }

  function parseTenantTypes(org) {
    const parsedTenantType = tenantUtils.parseTenantTypesTranslateId(
      org.TenantType,
    );
    const parsedUserRoles = userUtils.parseUserRolesKey(org.UserRoles);
    const parsedContent = [];
    if (
      parsedTenantType &&
      org.TenantType !== tenantUtils.tenantTypeStrings.Subscriber
    ) {
      parsedContent.push(
        <Translate
          key={parsedTenantType}
          id={
            org.TenantType === tenantUtils.tenantTypeStrings.Provider
              ? 'TENANTS.PROVIDER_FULL'
              : parsedTenantType
          }
        />,
      );
      parsedContent.push(', ');
    }
    parsedContent.push(
      <Translate key={parsedUserRoles} id={parsedUserRoles} />,
    );

    return parsedContent;
  }

  let dropdown;
  let dropdownOrgs;
  if (showDropdown) {
    let orgItems;
    if (organizations && organizations.length > 0) {
      orgItems = (
        <div>
          {[...organizations]
            .sort((curr, next) => curr.Name.localeCompare(next.Name))
            .map(org => (
              <DropdownItem
                key={org.Id}
                label={org.Name}
                onClick={() => changeOrganization(org.UserId)}
                subLabel={parseTenantTypes(org)}
              />
            ))}
        </div>
      );
    }
    dropdown = (
      <Translate>
        {({ translate }) => (
          <Dropdown
            containerClasses={orgClassName()}
            id={LoginConsts.idOrganizationDropdown}
            toggleDropdown={toggleDropdown}
          >
            <DropdownItem
              label={currentOrganization}
              primaryLabel={userName}
              showAvatar
              subLabel={currentUserRole()}
            />
            <div className={horizontalSeperator} />
            {renderIf(organizations && organizations.length >= 1)(
              <div onClick={toggleOrgSubmenu}>
                <DropdownItem
                  label={translate('MASTER_HEADER.SWITCH_ACCOUNT')}
                  showRightArrow
                />
              </div>,
            )}
            <DropdownItem
              label={translate('MASTER_HEADER.EDIT_PROFILE')}
              onClick={routeToProfile}
            />
            <DropdownItem
              id={LoginConsts.idLogoutButton}
              label={translate('MASTER_HEADER.LOGOUT')}
              onClick={props.onLogout}
            />
          </Dropdown>
        )}
      </Translate>
    );

    dropdownOrgs = (
      <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <Dropdown
          containerClasses={orgDropdownClassName()}
          id={LoginConsts.idOrganizationDropdown}
        >
          {orgItems}
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={status}>
      <HelpDropdown />
      {isAuthenticated ? (
        <div
          className={`${loginStatusContainer} ${
            showDropdown ? menuActive : ''
          }`}
        >
          <span className={loginStatusImg}>
            <IconAvatar
              title={<Translate id="MASTER_HEADER.USER_ICON_TITLE" />}
            />
          </span>
          <div
            className={`${usernameArrowContainer}
                ${isFetchingProfile && userNameDisabled}`}
            onClick={toggleDropdown}
            onKeyPress={toggleDropdown}
            role="button"
            tabIndex="0"
          >
            <span className={userNameWrapper} id={LoginConsts.idUserName}>
              {userName}
            </span>
            <NoStyleButton>
              <span className={showDropdown ? arrowUp : arrowDown} />
            </NoStyleButton>
            {dropdown}
            {showOrgs &&
              organizations &&
              organizations.length >= 1 &&
              dropdownOrgs}
          </div>
        </div>
      ) : (
        <div>{LoginLink}</div>
      )}
    </div>
  );
}

LoginStatus.defaultProps = {
  isEnabled: true,
  isFetchingProfile: null,
};

LoginStatus.contextTypes = {
  router: PropTypes.shape({}).isRequired,
};

LoginStatus.propTypes = {
  changeOrganization: PropTypes.func.isRequired,
  currentOrganization: PropTypes.string.isRequired,
  currentRole: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isEnabled: PropTypes.bool,
  isFetchingProfile: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  userName: PropTypes.string.isRequired,
};

export default withRouter(LoginStatus);
