/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { translatedConnect } from 'util/translatedConnect';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import get from 'lodash.get';

// Actions
import * as LocationActions from 'actions/locations';
import * as SubscriptionActions from 'actions/subscriptions';
import { getTimezones } from 'actions/utilities';
import { getUsersForTenant } from 'actions/user';
import * as UserActions from 'actions/user';

// Utils
import * as permissions from 'util/permissions';
import * as tableUtils from 'util/tableData';
import { isSelfServiceableSite } from 'util/validation';

// Constants
import { FORM_DEFAULTS, SERVICE_FAMILIES } from 'constants/app';
import { PageMessage } from 'containers';
import * as messageTypes from 'constants/MessageTypes';
import { EmptyPlaceholder } from 'lib';
import * as constants from './constants';
import { subscriptionTermOptions } from './SiteForm/constants';

// Components
import SiteForm from './SiteForm/SiteForm';

class SiteFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastSubmission: this.currentLocation(),
    };
  }

  componentDidMount() {
    const {
      actions,
      customerId,
      isFetchingServicePackages,
      isFetchingSubscriberServicePackages,
      isFetchingTimezones,
      isFetchingUserData,
      orgId,
      timezones,
    } = this.props;
    if ((!timezones || timezones === 0) && isFetchingTimezones === null) {
      actions.getTimezones();
    }
    if (customerId) {
      this.getLocationData(customerId);
    }
    if (orgId) {
      this.getServicePackages(this.props);
    }
    if (isFetchingUserData !== true) {
      actions.getUsersForTenant(customerId || orgId);
    }
    if (isFetchingServicePackages === null) {
      this.getServicePackages(this.props);
    }
    if (customerId && isFetchingSubscriberServicePackages === null) {
      actions.getSubscriberServicePackages(customerId);
    } else if (orgId && isFetchingSubscriberServicePackages === null) {
      actions.getSubscriberServicePackages(orgId);
    }
  }

  componentDidUpdate() {
    const { isFetchingServicePackage } = this.props;
    if (isFetchingServicePackage === null) {
      this.getCurrentLocationServicePackage(this.props);
    }
  }

  unflattenData = locationData => {
    return {
      ...locationData,
      PackageSubscription: {
        ...locationData.PackageSubscription,
        DealerId: locationData.DealerId,
        RemoteHealthMonitoringEnabled: locationData.DealerMonitoringEnabled,
        ServicePackageId: locationData.ServicePackageId,
        ServicePackageSkuId: locationData.ServicePackageSkuId,
      },
    };
  };

  getLocationData = () => {
    const {
      actions,
      customerId,
      isFetchingLocations,
      lastFetchedOrgId,
    } = this.props;
    // Not prop dependent
    if (isFetchingLocations === null || lastFetchedOrgId !== customerId) {
      actions.getLocations(customerId);
    }
  };

  getServicePackages = ({
    actions,
    currentLocation,
    currentOrg,
    isFetchingDealerServicePackageFamilies,
    isFetchingServicePackages,
  }) => {
    // Prop dependent data
    const dealerId =
      get(currentLocation, 'DealerId') || get(currentOrg, 'DealerId');
    if (dealerId && isFetchingServicePackages === null) {
      actions.getServicePackages(dealerId);
    }
    if (isFetchingDealerServicePackageFamilies === null) {
      actions.getDealerServicePackageFamilies();
    }
  };

  getCurrentLocationServicePackage = ({
    actions,
    currentLocation,
    isFetchingServicePackage,
  }) => {
    if (currentLocation.PackageSubscription && !isFetchingServicePackage) {
      actions.getServicePackage(
        currentLocation.PackageSubscription.ServicePackageId,
      );
    }
  };

  currentLocation = () => {
    const {
      currentLocation,
      servicePackages,
      subscriberServicePackages,
    } = this.props;

    if (currentLocation && currentLocation.PackageSubscription) {
      let subscriptionPackage = null;
      if (this.canUserAddOrEditCurrentSite()) {
        subscriptionPackage =
          subscriberServicePackages &&
          subscriberServicePackages.find(
            sp =>
              sp.Id === currentLocation.PackageSubscription.ServicePackageId,
          );
        const ServicePackageFamily =
          subscriptionPackage && subscriptionPackage.ServiceFamily;
        const ServicePackageSkuId =
          subscriptionPackage && subscriptionPackage.SkuId;
        return {
          ...currentLocation,
          ServicePackageFamily,
          ServicePackageSkuId,
          SubscriptionTermLength:
            currentLocation.SubscriptionTermLength ||
            String(currentLocation.PackageSubscription.TermLength),
        };
      }
      if (
        servicePackages &&
        servicePackages.some(
          sp => sp.Id === currentLocation.PackageSubscription.ServicePackageId,
        )
      ) {
        const servicePackage = servicePackages.find(
          sp => sp.Id === currentLocation.PackageSubscription.ServicePackageId,
        );
        const Family = servicePackage.ServiceFamily;
        const ServicePackageSkuId = servicePackage && servicePackage.SkuId;
        const Subscription = `${servicePackage.Name} ${servicePackage.Description} ${servicePackage.SkuId}`;
        const Term = subscriptionTermOptions.find(
          opt => opt.value === currentLocation.PackageSubscription.TermLength,
        ).label;
        return {
          ...currentLocation,
          Family,
          ServicePackageSkuId,
          Subscription,
          Term,
        };
      }
    }

    return {
      AdminUserId: FORM_DEFAULTS.new,
      Country: constants.USA_USA_USA,
      Region: constants.defaultRegion,
      RemoteHealthMonitoringEnabled: false,
      ServicePackageFamily:
        subscriberServicePackages &&
        subscriberServicePackages[0] &&
        subscriberServicePackages[0].ServiceFamily,
      ServicePackageSkuId:
        subscriberServicePackages &&
        subscriberServicePackages[0] &&
        subscriberServicePackages[0].SkuId,
      SubscriptionTermLength: constants.defaultTermLength,
    };
  };

  canUserAddOrEditCurrentSite = () => {
    const {
      canAddLocation,
      canEditLocation,
      currentLocation,
      subscriberServicePackages,
    } = this.props;
    if (currentLocation && currentLocation.PackageSubscription) {
      const selfServicealbleSite = isSelfServiceableSite(
        currentLocation,
        subscriberServicePackages,
      );
      return selfServicealbleSite && canEditLocation;
    }
    return canAddLocation;
  };

  handleSubmitForm = formData => {
    const {
      actions,
      goBack,
      servicePackages,
      subscriberServicePackages,
      usersPublicInfo,
    } = this.props;
    this.setState({ lastSubmission: { ...formData } });
    let locationObject = {
      ...formData,
    };
    // Circumvent the vagueries of Redux Forms and automatically set the term length
    // if the selected package has a fixed term length
    const servicePackage =
      subscriberServicePackages.find(
        p => p.SkuId === formData.ServicePackageSkuId,
      ) || servicePackages.find(p => p.SkuId === formData.ServicePackageSkuId);
    if (servicePackage) {
      if (
        servicePackage.FixedTermLength &&
        servicePackage.FixedTermLength > 0
      ) {
        locationObject.SubscriptionTermLength = servicePackage.FixedTermLength;
      }
      const termLength = servicePackage.TermLengths.find(
        tl =>
          String(tl.NumberOfMonths) ===
          String(locationObject.SubscriptionTermLength),
      );
      if (termLength) {
        locationObject.ServicePackageSkuId = `${formData.ServicePackageSkuId}-${termLength.SkuSuffix}`;
      }
      locationObject.DealerId = servicePackage.DealerId;
    }

    const { customerId, orgId } = this.props; // Optional
    locationObject = this.unflattenData(locationObject);
    const validOrgId = customerId || orgId;
    const existingAdminUser = usersPublicInfo[validOrgId].find(
      user => user.EmailAddress === locationObject.AdminEmailAddress,
    );
    if (existingAdminUser) {
      locationObject.AdminUserId = existingAdminUser.Id;
    }
    if (formData.Id) {
      actions.editLocation(locationObject, validOrgId);
    } else {
      actions.addLocation(locationObject, validOrgId);
      goBack();
    }
  };

  render() {
    const {
      canEditLocationBillingInfo,
      canEnableHealthMonitoring,
      currentLocation,
      customerId,
      goBack,
      isFetchingServicePackage,
      isFetchingSubscriberServicePackages,
      isSavingLocation,
      locationFormData,
      locationId,
      selfService,
      serviceFamilies,
      subscriberServicePackages,
      timezones,
      users,
    } = this.props;
    const { lastSubmission } = this.state;

    const remoteHealthMonitoringEnabled = get(
      currentLocation,
      'PackageSubscription.RemoteHealthMonitoringEnabled',
    );

    const showHealthMonitoring =
      get(currentLocation, 'PackageSubscription.ServiceFamily') !==
      SERVICE_FAMILIES.AccCloudServices;

    const showBillingInfo =
      currentLocation &&
      get(currentLocation, 'PackageSubscription.ServiceFamily') !==
        SERVICE_FAMILIES.AccCloudServices;

    return (
      <>
        <PageMessage
          messageType={[
            messageTypes.LOCATION_ERROR,
            messageTypes.LOCATION_SUCCESS,
          ]}
        />

        <EmptyPlaceholder
          isFetching={
            isFetchingSubscriberServicePackages || isFetchingServicePackage
          }
          items={subscriberServicePackages}
        >
          <SiteForm
            canEditSubscription={this.canUserAddOrEditCurrentSite()}
            canEnableHealthMonitoring={canEnableHealthMonitoring}
            customerId={customerId}
            dealerHealthMonitoring={remoteHealthMonitoringEnabled}
            disableSave={
              isSavingLocation || isEqual(lastSubmission, locationFormData)
            }
            handleCancel={() => goBack()}
            initialValues={this.currentLocation()}
            locationId={locationId}
            onSubmit={this.handleSubmitForm}
            readOnly={!this.canUserAddOrEditCurrentSite()}
            selfService={selfService}
            servicePackageFamilies={serviceFamilies}
            servicePackages={subscriberServicePackages}
            showBillingInfo={canEditLocationBillingInfo && showBillingInfo}
            showHealthMonitoring={showHealthMonitoring}
            submittingForm={isSavingLocation}
            timezones={timezones}
            users={users}
            // TODO: Disabled as UX team still needs to think about it
            // isBusy={this.props.isFetchingUserData || this.props.isFetchingTimezones ||
            //   this.props.isFetchingServicePackages || this.props.isFetchingLocations}
          />
        </EmptyPlaceholder>
      </>
    );
  }
}

SiteFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canAddLocation: PropTypes.bool,
  canEditLocation: PropTypes.bool.isRequired,
  canEditLocationBillingInfo: PropTypes.bool.isRequired,
  canEditSubscription: PropTypes.bool.isRequired,
  canEnableHealthMonitoring: PropTypes.bool.isRequired,
  currentLocation: PropTypes.objectOf(PropTypes.any),
  customerId: PropTypes.string,
  goBack: PropTypes.func,
  isFetchingLocations: PropTypes.bool,
  isFetchingServicePackage: PropTypes.bool,
  isFetchingServicePackages: PropTypes.bool,
  isFetchingSubscriberServicePackages: PropTypes.bool,
  isFetchingTimezones: PropTypes.bool,
  isFetchingUserData: PropTypes.bool,
  isSavingLocation: PropTypes.bool,
  locationFormData: PropTypes.objectOf(PropTypes.any),
  locationId: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  selfService: PropTypes.number.isRequired,
  serviceFamilies: PropTypes.arrayOf(PropTypes.object),
  servicePackages: PropTypes.arrayOf(PropTypes.object),
  subscriberServicePackages: PropTypes.arrayOf(PropTypes.object),
  timezones: PropTypes.arrayOf(PropTypes.any),
  users: PropTypes.arrayOf(PropTypes.object),
  usersPublicInfo: PropTypes.arrayOf(PropTypes.object),
};

SiteFormContainer.defaultProps = {
  canAddLocation: false,
  currentLocation: {},
  customerId: null,
  goBack: () => {},
  isFetchingLocations: null,
  isFetchingServicePackage: null,
  isFetchingServicePackages: null,
  isFetchingSubscriberServicePackages: null,
  isFetchingTimezones: null,
  isFetchingUserData: null,
  isSavingLocation: null,
  locationFormData: {},
  locationId: null,
  serviceFamilies: [],
  servicePackages: {},
  subscriberServicePackages: [],
  timezones: [],
  users: [],
  usersPublicInfo: [],
};

const mapStateToProps = (state, ownProps) => {
  const orgId = permissions.getOrgIdFromStore(state);
  const { customerId, locationId } = ownProps;
  const { dealerServicePackages } = state.locations;
  const { subscriberServicePackages } = state.user;
  const locationForm = state.form.locationForm || {};
  let currentLocation =
    locationId && state.locations.locations.find(l => l.Id === locationId);
  const isFetchingServicePackage =
    currentLocation &&
    currentLocation.PackageSubscription &&
    state.isFetching.getServicePackage[
      currentLocation.PackageSubscription.ServicePackageId
    ];
  if (currentLocation && dealerServicePackages) {
    const [enhancedLocation] = tableUtils.locationTableData(
      [currentLocation],
      dealerServicePackages,
      ownProps.translate,
    );
    currentLocation = enhancedLocation;
  }
  const serviceFamilies = [];
  if (subscriberServicePackages) {
    subscriberServicePackages.forEach(pack => {
      if (!serviceFamilies.some(family => family.Key === pack.ServiceFamily)) {
        serviceFamilies.push({
          Key: pack.ServiceFamily,
          Value: pack.ServiceFamily,
        });
      }
    });
  }
  return {
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    canEditLocation: state.user.permissions.CAN_EDIT_SITE,
    canEditLocationBillingInfo:
      state.user.permissions.CAN_EDIT_SITE_BILLING_INFO,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    currentLocation,
    currentOrg: state.user.currentOrganization,
    customerId,
    dealerServicePackages: state.locations.dealerServicePackages,
    isFetchingDealerServicePackageFamilies:
      state.isFetching.getDealerServicePackageFamilies,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingServicePackage,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    isFetchingSubscriberServicePackages:
      state.isFetching.getSubscriberServicePackages,
    isFetchingTimezones: state.isFetching.getTimezones,
    isFetchingUserData: state.user.isFetchingUserData,
    isSavingLocation: state.isSaving.location,
    isUpdatingDealerHealthMonitoring:
      state.locations.isUpdatingDealerHealthMonitoring,
    lastFetchedOrgId: state.locations.lastFetchedOrgId,
    locationFormData: locationForm.values,
    locationId,
    modalProps: state.modal.modalProps,
    orgId,
    selfService: state.user.permissions.SELF_SERVICE,
    serviceFamilies,
    servicePackages: state.locations.servicePackages,
    subscriberServicePackages,
    timezones: state.utilities.timezones,
    userTenantType: state.user.profile.TenantType,
    users: state.user.users || [],
    usersPublicInfo: state.user.usersPublicInfo || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...SubscriptionActions,
        ...UserActions,
        getTimezones,
        getUsersForTenant,
      },
      dispatch,
    ),
  };
};

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteFormContainer);
