import * as userUtils from '../util/userRoles';
import * as tenantUtils from '../util/tenantTypes';

export const GA_APPLICATION_ID = 'UA-142922258-2';

export const unlimitedCameras = 9223372036854776000;

// In other environments, the application may not be served from a root directory
export const ERS_DOMAIN = 'ers.develophelios.com';
export const ERS_PORT = '443';
export const ERS_PAGE_SMALL = 'demo/evo_iframe.html';
export const APP_NAME = 'Avigilon Cloud Services';

// Color Gen
export const CUSTOM_SATURATION_BRIGHTNESS = { brightness: 1, saturation: 0.15 };

// Icon defaults
export const SVG_XMLNS = 'http://www.w3.org/2000/svg';
export const ACTIVE_COLOR_DEFAULT = 'black';
export const ICON_COLOR_DEFAULT = 'white';
export const INACTIVE_COLOR_DEFAULT = '#999';
export const NEW_WINDOW_BUTTON_COLOR = '#055EAA';
export const ICON_CLOSE = '#ccc';
export const ICON_CLOSE_HOVER = '#999';
export const ICON_COLOR_ONLINE = '#6ECA3D';
export const ICON_COLOR_OFFLINE = '#D54039';
export const ROW_HEIGHT_DEFAULT = 44;
export const HEADER_HEIGHT_DEFAULT = 40;
export const LIST_NAV_TITLE_HEIGHT = 48;
export const SECONDRY_NAV_TABS_HEIGHT = 61;
export const PROXY_ORGANIZATION_DROPDOWN_HEIGHT = 34;
export const LOGO_FONT_COLOR = '#005DAA';
export const NAVIGATION_TABS = {
  ACCOUNTS: 'accounts',
  ALARMS: 'notifications',
  BOOKMARKS: 'bookmarks',
  DASHBOARD: 'dashboard',
  DEVICES: 'devices',
  HEALTH_MONITOR: 'health monitor',
  REPORTS: 'reports',
  VIEWS: 'views',
};

export const { ROLES } = userUtils;
export const { ROLES_KEYS } = userUtils;
export const { STATUS_KEYS } = userUtils;
export const USER_TYPES = { proxy: 'Proxy', user: 'User' };

export const CUSTOMER_TYPES = tenantUtils.tenantLabels;
export const CUSTOMER_TYPES_TRANSLATED = tenantUtils.tenantTranslatedLabelsId;

export const SELF_PROVISIONED_TENANT_FLAG = 1;
export const SIGNALR_HUBNAME = 'HeliosRouteHub';

export const MEDIA_QUALITY = {
  high: 'high',
  low: 'low',
};

export const DATETIME_FORMAT_WITH_UTC = 'MMM D[,] YYYY h:mm:ss A';
export const DATETIME_FORMAT_WITH_ZONE = 'MMM D[,] YYYY h:mm:ss A z';
export const DATETIME_FORMAT_WITH_ZONE_ES = 'D [de] MMM [de] YYYY h:mm:ss A z';
export const DATETIME_FORMAT_WITH_ZONE_FR = 'D MMM YYYY H:mm:ss z';
export const DATE_FORMAT_MMM_DD_YYYY = 'MMM DD, YYYY';
export const DATE_FORMAT_MMM_DD_YYYY_ES = 'D [de] MMM [de] YYYY';
export const DATE_FORMAT_MMM_DD_YYYY_FR = 'DD MMM YYYY';
export const DATE_FORMAT = 'dddd[, ] MMM D[,] YYYY';
export const TIME_FORMAT = 'h:mm:ss [ ]A z';
export const DATABASE_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'; // For putting things into the db
export const DATABASE_DATETIME_EXTRACT_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ'; // For creating moments out of db timestamps
export const DATE_FORMAT_EVOPLAYER = 'YYYYMMDD[T]HHmmss.SSSZ';
export const DATE_FORMAT_EVOPLAYER_WITHOUT_ZONE = 'YYYYMMDD[T]HHmmss.SSS';
export const DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE =
  'YYYY-MM-DDTHH:mm:ss[Z]';
export const DATE_FORMAT_MMM_D_Y = 'MMM D, Y';
export const DATE_FORMAT_MMM_D_Y_ES = 'D [de] MMM [de] Y';
export const DATE_FORMAT_MMM_D_Y_FR = 'D MMM Y';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const SHORT_DATE_FORMAT = 'M/D/Y';
export const SHORT_DATE_FORMAT_ES = 'D/M/Y';
export const SHORT_DATE_FORMAT_FR = 'DD.MM.Y';
export const SHORT_INTERNATIONAL_DATE_FORMAT = 'DD/MM/YYYY';
export const STREAM_TYPES = {
  live: 'live', // a live stream, as it occurs
  playback: 'playback', // a stream started from an earlier point in time
  recorded: 'recorded', // a clip saved to the cloud
};

export const FORM_DEFAULTS = {
  all: 'ALL', // For dropdowns with a 'create new' option that should be treated differently from normal vals
  new: 'NEW', // For clearing filters
};

// It is important that each of these values be unique.
export const FILTER_GROUPS = {
  BOOKMARKS: 'bookmarks',
  CONTACTS: 'contacts',
  CUSTOMERS: 'customers',
  INTEGRATION_SUBSCRIBERS: 'integrationSubscribers',
  ORGANIZATION: 'organizations',
  ORG_DEALER: 'organizationDealer',
  ORG_DEALERS: 'organizationDealers',
  ORG_SUBSCRIBERS: 'organizationSubscribers',
  ORG_USERS: 'organizationUsers',
  SERVICE_PLANS: 'servicePlans',
  SITES: 'sites',
  SUBSCRIBER_SITES: 'subscriberSites',
};

// IDS to identify bulk action subsets
export const BULK_ACTION_TABLES = {
  CONTACTS: 'contacts',
  LOCATIONS: 'locations',
  ORGS: 'organizations',
  SERVICE_PLANS: 'servicePlans',
  USERS: 'users',
  USER_SITE_ACCESS: 'userSiteAccess',
};

export const INTEGRATION_TYPES = {
  SUREVIEW: 'SureviewImmix',
  WEBHOOKS: 'WebhookJson',
};

// Keys to find error message during async validation
export const MODEL_STATE = 'ModelState';
export const BAD_CONNECTION_STRING =
  'integrationConnectionString.ConnectionString';

// Types of canvas
// TODO: It should always be the same canvas, with each item treated differently based on type
export const canvasTypes = {
  general: 'General',
  privacy: 'Privacy',
  rules: 'Rules',
};

// Types of object that can be drawn on a canvas
export const canvasObjectTypes = {
  loi: 'loi',
  polygon: 'polygon',
  privacy: 'privacy',
  ptzArrow: 'ptzArrow', // aka line, beam
  roi: 'roi',
};

// Default stream parameters
export const streamDetailDefaults = {
  cameraResHeight: 0, // Camera sensor
  cameraResWidth: 0,
  rotation: 0, // Margin of camera sensor cut trimmed from stream at the current resolution
  xOffset: 0, // (in camera space, not screen space)
  yOffset: 0, // Stream rotation in degrees
};

export const PTZ_BASE_CAPABILITY = 'PAN_TILT_CONTINUOUS';

// Minimum Version for Features
export const PTZ_MINIMUM_SERVER_FIRMWARE_VERSION = '3.4.24.42';
export const CHANGE_PROVIDER_MINIMUM_ACC_VERSION = '7.8.0.0';

// LanguagesUtils
export const DEFAULT_LANGUAGE = 'en-US'; // NOTE: Could change if Server team has the format: languageCode-country => es-ES, es-CO, et
export const DEFAULT_LANGUAGE_CODE = 'en'; // For Momentjs localization
export const LOCAL_STORAGE_LANGUAGE = 'language';
// NOTE: Make sure to remember that, whenever you add a new language below, you'll have to import the moment/locale for that language in the file languagesUtils.js .
export const LANGUAGES = [
  { code: 'en', fullCode: 'en-US', label: 'English', name: 'ENGLISH' },
  { code: 'es', fullCode: 'es-ES', label: 'Español', name: 'SPANISH' },
  { code: 'fr', fullCode: 'fr-FR', label: 'Français', name: 'FRENCH' },
];

export const SELECT_STYLE = { cursor: 'text' };

export const ACTION_IMAGES = {
  CONTACT: 'Contact',
  DEALER: 'Dealer',
  DEALER_SELECTED: 'DealerSubscriber',
  LOCATION: 'Location',
  NOTIFICATION: 'Notification',
  SCHEDULE: 'Schedule',
  SERVICE_PLAN: 'Service Plan',
  SUBSCRIBER: 'Subscriber',
  USER: 'User',
};

export const DEVICE_TYPES = {
  CAMERA: 'Camera',
  SERVER: 'Server',
};

export const SERVICE_FAMILIES = {
  ACCHealthMonitoring: 'ACCHealthMonitoring',
  AccCloudServices: 'AccCloudServices',
  BlueDemoSelfMonitoring: 'BlueDemoSelfMonitoring',
  BlueSelfMonitoring: 'BlueSelfMonitoring',
};

export const serviceFamilyMap = {
  // Note both KEYS and VALUES in this hash correspond to server strings
  // This MUST be updated if a new serviceFamily is added
  [SERVICE_FAMILIES.ACCHealthMonitoring]: 'ACC',
  [SERVICE_FAMILIES.AccCloudServices]: 'ACC',
  [SERVICE_FAMILIES.BlueDemoSelfMonitoring]: 'BlueConnect',
  [SERVICE_FAMILIES.BlueSelfMonitoring]: 'BlueConnect',
};

export const SITE_STATUS = {
  ACTIVE: 'Active',
  CLAIMING: 'Claiming',
  DISCONNECTED: 'Disconnected',
  EXPIRED: 'Expired',
  NONE: 'None',
};

// app insights events
export const APP_INSIGHTS_EVENTS = {
  registerLinkClick: 'registerLinkClick',
  registrationSubmit: 'registrationSubmit',
  sitesTabVisit: 'sitesTabVisit',
};

// dashes for showing blank readonly text fields
export const dashes = '---';
