/* eslint-disable no-unused-expressions */
export const attachScript = (id, host, onLoad, onError, async = true) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = id;
  script.async = async;
  script.src = host;
  script.onload = onLoad;
  script.onerror = onError;
  document.head.appendChild(script);
};

export const renderMicroFrontend = (name, config) => {
  const renderHandler = window[`render${name}`];
  renderHandler
    ? renderHandler(`${name}-container`, config)
    : avoLogError(`render${name} handler does not exist.`);
};

export const unmountMicroFrontend = name => {
  const unmountHandler = window[`unmount${name}`];
  unmountHandler
    ? unmountHandler(`${name}-container`)
    : avoLogError(`unmount${name} handler does not exist.`);
};
