import React from 'react';
import { PropTypes } from 'prop-types';

// Constants
import * as buttonLabels from 'constants/messageBoxButtons';

// Components
import { FormSuccess } from 'components';
import { Button } from 'lib';
import { ModalContainer } from 'containers';

// Styles
import { buttonGroup } from 'sharedStyles/modalForms.css';
import { Translate } from 'react-localize-redux';
import { modalContentConfirm } from './styles.css';
import * as modalConsts from './constants';

const LogDownloadModal = ({ hideModal, url }) => (
  <ModalContainer modalTitle={buttonLabels.Download} handleCancel={hideModal}>
    <div className={modalContentConfirm}>
      <FormSuccess message={<Translate id="CAMERA.LOG_SUCCESS" />} />
      <div className={buttonGroup}>
        <a href={url}>
          <Button
            key={modalConsts.idLogDownload}
            id={modalConsts.idLogDownload}
            inputType="button"
            onClick={hideModal}
            text={buttonLabels.Download}
          />
        </a>
      </div>
    </div>
  </ModalContainer>
);

LogDownloadModal.defaultProps = {
  hideModal() {},
  url: '',
};

LogDownloadModal.propTypes = {
  hideModal: PropTypes.func,
  url: PropTypes.string,
};

export default LogDownloadModal;
