export const ON_EVENT = 'ON_EVENT';
export const CONTINUOUS = 'CONTINUOUS';

export const templateNameLength = 256;

export const EVENT_TO_RECORD_ON_ON_EVENT = ['MOTION'];
export const EVENT_TO_RECORD_ON_CONTINUOUS = [
  'MOTION',
  'DIGITAL_INPUT_TRIGGER',
  'ALARM_TRIGGER',
  'POS_TRANSACTION',
  'LPR_MATCH',
];

export const continuous = { backgroundColor: '#8DCCFF' };
export const motionAndAnalytics = { backgroundColor: '#C0E3FF' };

const templateInterval = {
  eventToRecordOn: EVENT_TO_RECORD_ON_CONTINUOUS,
  frameRecordIntervalMs: 0,
  recordingMode: 'CONTINUOUS',
};

const templateIntervals = [];
for (let i = 0; i < 48; i += 1) {
  templateIntervals.push({ ...templateInterval });
}

export const templateData = {
  name: '',
  templateIntervals,
};

export const days = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};
