import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';
import { CONNECT_CAMERA_ICON_ID } from './constants';

const IconConnectCamera = props => {
  return (
    <div
      title={props.translate(props.title)}
      className={props.className}
      width={props.width}
      height={props.height}
      id={CONNECT_CAMERA_ICON_ID}
    >
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 12 12"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <title>{props.translate(props.title)}</title>
        <defs />
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M8,4 L8,0 L4,0 L4,4 L0,4 L0,8 L4,8 L4,12 L8,12 L8,8 L12,8 L12,4 L8,4 Z'"
            id="Combined-Shape"
            fill={props.fill}
          />
        </g>
      </svg>
    </div>
  );
};

IconConnectCamera.defaultProps = {
  title: 'DEVICE_DETAILS.CAMERAS_TAB.ADD_CAMERA_ICON_LABEL',
  width: '30px',
  height: '30px',
  fill: '#006e99',
};

IconConnectCamera.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default withLocalize(IconConnectCamera);
