export const dangerousCharacters = [
  '(',
  ')',
  '[',
  ']',
  '{',
  '}',
  '*',
  '$',
  '^',
  '.',
  '?',
  '+',
  '|',
  '\\',
];

export function searchText(
  dataSet = [{}],
  filterText = '',
  fieldsToSearch = [],
) {
  if (filterText) {
    const searchSet = dataSet.map(item => {
      if (fieldsToSearch.length > 0) {
        const constrainedValues = [];

        Object.keys(item).forEach(key => {
          if (fieldsToSearch.includes(key)) {
            constrainedValues.push(item[key]);
          }
        });

        return constrainedValues.join(' ');
      }
      return `${Object.values(item).join(' ')}`;
    });

    const escapedFilterText = filterText.replace(
      new RegExp(`([\\${dangerousCharacters.join('\\')}])`, 'g'),
      '\\$1',
    );

    const searchRegExp = new RegExp(`.*${escapedFilterText}.*`, 'i');
    const filteredData = dataSet.filter((item, itemIdx) => {
      const matches = searchRegExp.test(searchSet[itemIdx]);
      return matches;
    });

    return filteredData;
  }

  return dataSet;
}

export default searchText;
