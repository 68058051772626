import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconNavDevices = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 44 42"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Vertical-Nav"
          transform="translate(-18.000000, -333.000000)"
          stroke={props.fill}
        >
          <g id="Stroke-36" transform="translate(19.000000, 334.000000)">
            <path
              d="M16.172,27.225 C16.172,29.35 14.41,31.079 12.246,31.079 C10.084,31.079 8.319,29.35 8.319,27.225 C8.319,25.102 10.084,23.374 12.246,23.374 C14.411,23.375 16.172,25.102 16.172,27.225 Z M21.125,24.468 L20.506,22.996 C20.506,22.996 21.946,19.741 21.817,19.615 L19.914,17.75 C19.781,17.621 16.535,19.107 16.535,19.107 L15.037,18.5 C15.037,18.5 13.714,15.202 13.532,15.202 L10.846,15.202 C10.658,15.202 9.434,18.508 9.434,18.508 L7.94,19.117 C7.94,19.117 4.625,17.704 4.497,17.829 L2.596,19.698 C2.464,19.825 3.978,23.015 3.978,23.015 L3.36,24.487 C3.36,24.487 0,25.785 0,25.959 L0,28.601 C0,28.786 3.368,29.989 3.368,29.989 L3.986,31.457 C3.986,31.457 2.547,34.71 2.673,34.835 L4.577,36.704 C4.706,36.831 7.954,35.346 7.954,35.346 L9.453,35.954 C9.453,35.954 10.776,39.251 10.958,39.251 L13.646,39.251 C13.832,39.251 15.057,35.945 15.057,35.945 L16.557,35.335 C16.557,35.335 19.865,36.749 19.993,36.625 L21.896,34.758 C22.029,34.628 20.512,31.44 20.512,31.44 L21.129,29.969 C21.129,29.969 24.49,28.668 24.49,28.492 L24.49,25.851 C24.492,25.668 21.125,24.468 21.125,24.468 Z"
              strokeWidth="2"
            />
            <path
              d="M35.691,15.894 C35.691,17.429 34.418,18.68 32.853,18.68 C31.29,18.68 30.014,17.429 30.014,15.894 C30.014,14.359 31.29,13.109 32.853,13.109 C34.419,13.11 35.691,14.359 35.691,15.894 Z M39.272,13.901 L38.825,12.835 C38.825,12.835 39.865,10.483 39.772,10.392 L38.396,9.044 C38.3,8.951 35.954,10.025 35.954,10.025 L34.871,9.586 C34.871,9.586 33.915,7.202 33.783,7.202 L31.841,7.202 C31.705,7.202 30.82,9.592 30.82,9.592 L29.739,10.032 C29.739,10.032 27.343,9.01 27.251,9.101 L25.877,10.452 C25.781,10.543 26.876,12.85 26.876,12.85 L26.429,13.914 C26.429,13.914 24,14.852 24,14.979 L24,16.888 C24,17.022 26.435,17.891 26.435,17.891 L26.881,18.954 C26.881,18.954 25.841,21.304 25.932,21.395 L27.309,22.747 C27.402,22.838 29.75,21.764 29.75,21.764 L30.834,22.205 C30.834,22.205 31.79,24.587 31.922,24.587 L33.865,24.587 C34,24.587 34.885,22.197 34.885,22.197 L35.97,21.756 C35.97,21.756 38.361,22.779 38.453,22.69 L39.829,21.339 C39.925,21.246 38.829,18.941 38.829,18.941 L39.275,17.877 C39.275,17.877 41.705,16.937 41.705,16.809 L41.705,14.9 C41.706,14.768 39.272,13.901 39.272,13.901 Z"
              id="Stroke-38"
              strokeWidth="1.5"
            />
            <path
              d="M19.679,6.541 C19.679,7.744 18.681,8.724 17.455,8.724 C16.231,8.724 15.232,7.744 15.232,6.541 C15.232,5.338 16.231,4.359 17.455,4.359 C18.682,4.36 19.679,5.338 19.679,6.541 Z M22.441,5.221 L22.106,4.423 C22.106,4.423 22.886,2.66 22.816,2.593 L21.785,1.583 C21.713,1.513 19.955,2.317 19.955,2.317 L19.144,1.988 C19.144,1.988 18.428,0.202 18.329,0.202 L16.874,0.202 C16.772,0.202 16.11,1.993 16.11,1.993 L15.3,2.322 C15.3,2.322 13.505,1.558 13.436,1.625 L12.406,2.637 C12.335,2.706 13.155,4.434 13.155,4.434 L12.82,5.23 C12.82,5.23 11,5.934 11,6.028 L11,7.459 C11,7.559 12.824,8.211 12.824,8.211 L13.159,9.006 C13.159,9.006 12.38,10.768 12.448,10.836 L13.479,11.848 C13.549,11.916 15.308,11.112 15.308,11.112 L16.119,11.441 C16.119,11.441 16.836,13.227 16.935,13.227 L18.39,13.227 C18.491,13.227 19.155,11.437 19.155,11.437 L19.967,11.106 C19.967,11.106 21.759,11.872 21.828,11.805 L22.859,10.794 C22.93,10.723 22.109,8.996 22.109,8.996 L22.443,8.2 C22.443,8.2 24.263,7.496 24.263,7.399 L24.263,5.97 C24.264,5.871 22.441,5.221 22.441,5.221 Z"
              id="Stroke-40"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconNavDevices.defaultProps = {
  className: null,
  width: '44px',
  height: '42px',
  fill: '#ADADAD',
};

export default IconNavDevices;
