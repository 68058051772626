/* ************************************************************************* */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/*   Use NoStyleButton only if you want onClick / button                     */
/*   functionality to be added to an element completely invisibly,           */
/*   otherwise use regular Button                                            */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/* ************************************************************************* */

import PropTypes from 'prop-types';
import React from 'react';
import { noStyleButton, noStyleButtonPointer } from './styles.css';

// button that has no visual appearance, just displays the child content

const NoStyleButton = props => {
  const {
    children,
    className,
    disabled,
    id,
    isBackButton,
    onClick,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    title,
  } = props;
  return (
    <button
      className={`${
        !disabled || isBackButton ? className : null
      } ${noStyleButtonPointer}`}
      id={id}
      onClick={() => (!disabled ? onClick() : null)}
      onMouseDown={() => (!disabled ? onMouseDown() : null)}
      onMouseLeave={() => (!disabled ? onMouseLeave() : null)}
      onMouseUp={() => (!disabled ? onMouseUp() : null)}
      title={title}
      type="button"
    >
      {children}
    </button>
  );
};

NoStyleButton.defaultProps = {
  children: null,
  className: noStyleButton,
  disabled: false,
  id: 'idBtnContent',
  isBackButton: false,
  onClick() {},
  onMouseDown() {},
  onMouseLeave() {},
  onMouseUp() {},
  title: '',
};

NoStyleButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  isBackButton: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseUp: PropTypes.func,
  title: PropTypes.string,
};

export default NoStyleButton;
