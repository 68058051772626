/* eslint-disable import/prefer-default-export */
import {
  PATH_SEGMENT_INFO,
  PATH_SEGMENT_SERVICE_PLANS,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';

export const tabs = [
  {
    label: 'TENANTS.CUSTOMER_PLURAL',
    pathSegment: PATH_SEGMENT_SUBSCRIBERS,
  },
  {
    label: 'CUSTOMERS.CUSTOMERS_DETAIL.SERVCIE_PLANS_TAB_LABEL',
    pathSegment: PATH_SEGMENT_SERVICE_PLANS,
  },
  {
    label: 'CUSTOMERS.CUSTOMERS_DETAIL.INFO_TAB_LABEL',
    pathSegment: PATH_SEGMENT_INFO,
  },
];
