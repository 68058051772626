import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import { Icon, NoStyleButton } from 'lib';

// Styles
import {
  backButton,
  backWrapper,
  listNavTitle,
  titleWrapper,
} from './styles.css';

// Constants

const BackButtonWithTitle = props => {
  const { className, onBackClick, path, title } = props;
  const BackButtonWrapper = path ? Link : 'div';
  return (
    <div className={className}>
      <BackButtonWrapper className={backWrapper} to={path}>
        <NoStyleButton
          className={backButton}
          id="backBtn"
          isBackButton
          onClick={onBackClick}
        >
          <Icon id="ic_back" size="26" />
        </NoStyleButton>
      </BackButtonWrapper>
      <span className={listNavTitle}>{title}</span>
    </div>
  );
};

BackButtonWithTitle.propTypes = {
  className: PropTypes.string,
  onBackClick: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.node,
};

BackButtonWithTitle.defaultProps = {
  className: titleWrapper,
  onBackClick: () => {},
  path: '',
  title: '',
};

export default BackButtonWithTitle;
