import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';

const IconCalendarAdd = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 35 22"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>add-calendar-icon</title>
      <g
        id="05-Org-Mgmt-Notifications"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-501.000000, -146.000000)" id="Add-Calendar">
          <g transform="translate(501.000000, 144.000000)">
            <g id="Inactive" transform="translate(0.000000, 2.000000)" />
            <g id="Active" transform="translate(0.000000, 2.000000)">
              <path
                d="M30,7 L27,7 L27,9 L30,9 L30,12 L32,12 L32,9 L35,9 L35,7 L32,7 L32,4 L30,4 L30,7 Z"
                id="Combined-Shape-Copy-2"
                fill={this.props.fill}
              />
              <rect
                id="Rectangle-6-Copy"
                stroke={props.fill}
                strokeWidth="2"
                x="1"
                y="6"
                width="22"
                height="14"
              />
              <path
                d="M19,2 L24,2 L24,5 L0,5 L0,2 L4,2 L4,0 L6,0 L6,2 L17,2 L17,0 L19,0 L19,2 Z M5,9 L7,9 L7,11 L5,11 L5,9 Z M5,12 L7,12 L7,14 L5,14 L5,12 Z M5,15 L7,15 L7,17 L5,17 L5,15 Z M8,9 L10,9 L10,11 L8,11 L8,9 Z M8,12 L10,12 L10,14 L8,14 L8,12 Z M8,15 L10,15 L10,17 L8,17 L8,15 Z M11,9 L13,9 L13,11 L11,11 L11,9 Z M11,12 L13,12 L13,14 L11,14 L11,12 Z M11,15 L13,15 L13,17 L11,17 L11,15 Z M14,9 L16,9 L16,11 L14,11 L14,9 Z M14,12 L16,12 L16,14 L14,14 L14,12 Z M14,15 L16,15 L16,17 L14,17 L14,15 Z M17,9 L19,9 L19,11 L17,11 L17,9 Z M17,12 L19,12 L19,14 L17,14 L17,12 Z M17,15 L19,15 L19,17 L17,17 L17,15 Z"
                id="Combined-Shape-Copy"
                fill={this.props.fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconCalendarAdd.defaultProps = {
  className: null,
  width: '35px',
  height: '22px',
  fill: ACTIVE_COLOR_DEFAULT,
};

export default IconCalendarAdd;
