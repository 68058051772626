import React from 'react';
import PropTypes from 'prop-types';
import { PlainCheckBox } from 'components';
import { cellText, highlight, tableCell } from 'sharedStyles/tables.css';
import { pointer } from 'sharedStyles/styles.css';

const checboxClassName = (
  defaultChecked,
  isCameraSelect,
  rowsSelected,
  rowIndex,
) => {
  return isCameraSelect && rowsSelected.includes(rowIndex)
    ? ''
    : !defaultChecked && rowsSelected.includes(rowIndex)
    ? highlight
    : '';
};

const SelectCell = ({
  rowIndex,
  rowsSelected,
  defaultChecked,
  data,
  className,
  cellClassName,
  customClass,
  field,
  subFields,
  onChange,
  isCameraSelect,
  ...props
}) => (
  <div
    className={`${tableCell} ${checboxClassName(
      defaultChecked,
      isCameraSelect,
      rowsSelected,
      rowIndex,
    )}
        ${cellClassName || ''}$`}
    {...props}
  >
    {defaultChecked !== undefined ? (
      <PlainCheckBox
        id={`camera${rowIndex}`}
        key={`camera${rowIndex}`}
        checked={
          defaultChecked === true ? true : rowsSelected.includes(rowIndex)
        }
        onChange={onChange}
      />
    ) : (
      <div className={className || cellText}>
        <input
          className={`${pointer} ${customClass}`}
          type="checkbox"
          value={rowIndex}
          checked={
            defaultChecked === true ? true : rowsSelected.includes(rowIndex)
          }
          onChange={onChange}
        />
      </div>
    )}
  </div>
);

SelectCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowsSelected: PropTypes.arrayOf(PropTypes.any),
};

SelectCell.defaultProps = {
  rowsSelected: [],
};

export default SelectCell;
