// Key = app identification, value = database identification
export const tenantTypeStrings = {
  CloudOwner: 'Avigilon',
  Customer: 'Customer',
  Dealer: 'Dealer',
  Provider: 'Provider',
  ProviderFull: 'Service Provider',
  Subscriber: 'Subscriber',
};

export const tenantSubtypeStrings = {
  dealer: 'Dealer',
  none: 'None',
  publicPrivatePartner: 'PublicPrivatePartner',
};

// Key = database id, value = what they get called in the app
export const tenantLabels = {
  Avigilon: 'Cloud Owner',
  Dealer: 'Dealer',
  Provider: 'Provider',
  Subscriber: 'Subscriber',
};

export const tenantTypes = [
  tenantTypeStrings.Dealer,
  tenantTypeStrings.Subscriber,
  tenantTypeStrings.CloudOwner,
  tenantTypeStrings.Provider,
];

export const parseTenantTypes = string => {
  if (string.includes(tenantTypeStrings.Subscriber)) {
    return tenantLabels.Subscriber;
  }
  if (string.includes(tenantTypeStrings.Dealer)) {
    return tenantLabels.Dealer;
  }
  if (string.includes(tenantTypeStrings.CloudOwner)) {
    return tenantLabels.Avigilon;
  }
  if (string.includes(tenantTypeStrings.Provider)) {
    return tenantLabels.Provider;
  }
  return null;
};

// Key = database id, value = translated string id
export const tenantTranslatedLabelsId = {
  Avigilon: 'TENANTS.AVIGILON',
  Dealer: 'TENANTS.DEALER',
  Provider: 'TENANTS.PROVIDER',
  Subscriber: 'TENANTS.SUBSCRIBER',
};

export const tenantTranslatedPluralLabelsId = {
  Avigilon: 'TENANTS.AVIGILON_PLURAL',
  Dealer: 'TENANTS.DEALER_PLURAL',
  Provider: 'TENANTS.PROVIDER_PLURAL',
  Subscriber: 'TENANTS.SUBSCRIBER_PLURAL',
};

export const parseTenantTypesTranslateId = string => {
  if (string.includes(tenantTypeStrings.Subscriber)) {
    return tenantTranslatedLabelsId.Subscriber;
  }
  if (string.includes(tenantTypeStrings.Dealer)) {
    return tenantTranslatedLabelsId.Dealer;
  }
  if (string.includes(tenantTypeStrings.CloudOwner)) {
    return tenantTranslatedLabelsId.Avigilon;
  }
  if (string.includes(tenantTypeStrings.Provider)) {
    return tenantTranslatedLabelsId.Provider;
  }
  return null;
};

export const tenantTranslatedSubTypesId = {
  dealer: 'TENANTS.PROVIDER_SUBTYPES.DEALER',
  publicPrivatePartner: 'TENANTS.PROVIDER_SUBTYPES.PUBLIC_PRIVATE_PARTNER',
};

export const parseProviderSubtypesTranslateId = string => {
  if (string.includes(tenantSubtypeStrings.dealer)) {
    return tenantTranslatedSubTypesId.dealer;
  }
  if (string.includes(tenantSubtypeStrings.publicPrivatePartner)) {
    return tenantTranslatedSubTypesId.publicPrivatePartner;
  }
  return null;
};