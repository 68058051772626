import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Components
import { Icon } from 'lib';

// Styles
import {
  iconButton,
  iconSwitch,
  selectedDark,
  selectedLight,
} from './IconSwitch.css';

// Constants
import { DEFAULT_ICON_SIZE } from './constants';

const IconSwitch = ({ className, iconButtons, light }) => (
  <div className={className}>
    <div className={iconSwitch}>
      {iconButtons.map(ib => (
        <NavLink
          key={ib.id}
          activeClassName={light ? selectedLight : selectedDark}
          className={iconButton}
          exact
          to={ib.path}
        >
          <Icon id={ib.id} size={ib.size || DEFAULT_ICON_SIZE} />
        </NavLink>
      ))}
    </div>
  </div>
);

IconSwitch.defaultProps = {
  className: '',
  light: false,
};

IconSwitch.propTypes = {
  className: PropTypes.string,
  iconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      size: PropTypes.number,
    }),
  ).isRequired,
  light: PropTypes.bool,
};

export default IconSwitch;
