// Libs
import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Components
import { ShowDateTime } from 'components';
import { EmptyPlaceholder, ListView } from 'lib';

// Styles
import { TIME_TYPES } from 'util/convertTimeTo';
import { eventLog, logContent, logHeader } from './styles.css';

// Constants
import * as alarmConstants from './constants';

class ActionLog extends Component {
  get logData() {
    const { history: initialHistory, profileTimeZone, translate } = this.props;
    const map = this.userMap();
    // history appears to be returned in timestamp order ascending.
    // should be in timestamp order descending.
    const history = initialHistory.slice().reverse();
    return history.map(event => {
      let user = {
        FirstName: translate('ALARMS.EDIT_MODAL.PLACEHOLDER_FIRSTNAME'),
        LastName: translate('ALARMS.EDIT_MODAL.PLACEHOLDER_LASTNAME'),
      };
      if (event.UserFirstName || event.UserLastName) {
        user = {
          FirstName: event.UserFirstName,
          LastName: event.UserLastName,
        };
      } else if (map[event.UserId]) {
        user = map[event.UserId];
      }
      const name = `${user.FirstName || ''} ${user.LastName || ''}`;
      const description = this.getEventDescription(name, event) || (
        <Translate id="ALARMS.EDIT_MODAL.INTERACTED_WITH_ALARM" />
      );
      const timestamp =
        event.Timestamp === alarmConstants.justNow ? (
          <Translate id="ALARMS.EDIT_MODAL.JUST_NOW_LABEL" />
        ) : (
          <ShowDateTime
            datetime={event.Timestamp}
            timeType={TIME_TYPES.LOCAL}
            timeZone={profileTimeZone}
          />
        );
      return {
        ...event,
        description,
        icon: event.Action.charAt(0),
        name,
        timestamp,
      };
    });
  }

  getEventDescription = (userName, event) => {
    const { Action, Context } = event;

    let translationStringId;
    const translationData = { userName };

    const isMarkFalse =
      Context === true || (Context && Context.toString() === 'true');
    const isMarkNotFalse =
      Context === false || (Context && Context.toString() === 'false');

    switch (Action) {
      case 'MarkFalse': {
        if (isMarkFalse) {
          translationStringId = 'ALARMS.NOTIFICATIONS.MARK_FALSE';
        } else if (isMarkNotFalse) {
          translationStringId = 'ALARMS.NOTIFICATIONS.MARK_NOT_FALSE';
        } else if (Context === null) {
          translationStringId = 'ALARMS.NOTIFICATIONS.MARK_UNSPECIFIED';
        }
        break;
      }
      case 'Comment': {
        translationStringId = 'ALARMS.NOTIFICATIONS.COMMENT';
        translationData.comment = Context;
        break;
      }
      default: {
        translationStringId = `ALARMS.NOTIFICATIONS.${Action.toUpperCase()}`;
        break;
      }
    }
    return <Translate data={translationData} id={translationStringId} />;
  };

  userMap = (userMap = {}) => {
    const { users } = this.props;
    users.forEach(u => {
      userMap[u.Id] = u;
    });
    return userMap;
  };

  render() {
    const { height } = this.props;
    const history = this.logData;
    return (
      <div className={eventLog}>
        <div className={logHeader}>
          <label>
            <Translate id="ALARMS.EDIT_MODAL.ALARM_LOG_LABEL" />
          </label>
        </div>
        <div className={logContent}>
          <EmptyPlaceholder
            isFetching={false}
            items={history}
            string={<Translate id="ALARMS.EDIT_MODAL.NO_ACTIVITY_LABEL" />}
          >
            <ListView
              bodyHeight={height}
              cellWidths={{
                timestamp: '200',
              }}
              data={history}
              fieldOrder={alarmConstants.LOG_TABLE.fieldOrder}
              headerTranslationIds={
                alarmConstants.LOG_TABLE.headerTranslationIds
              }
              hideFilter
            />
          </EmptyPlaceholder>
        </div>
      </div>
    );
  }
}

ActionLog.propTypes = {
  height: PropTypes.node.isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profileTimeZone: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withLocalize(ActionLog);
