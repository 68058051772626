// Libs
import React, { useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Consts
import { CONTROL_TYPE, getControlTypeTheme, WHITE_COLOR } from './constants';

// Styles
import {
  radioCheckmark,
  radioGroupCheckmark,
  radioGroupContainer,
  radioGroupInputsWrap,
  radioGroupLabel,
  radioGroupLabelHidden,
  spanActive,
  spanInactive,
  verticalLayoutSpacing,
  verticalLayoutWrap,
  verticalTitleWrap,
} from './styles.css';

const RadioGroup = ({
  containerClassName,
  disabled,
  id,
  labelClass,
  labelTranslateId,
  onChange,
  options,
  register,
  value,
  verticalLayout,
}) => {
  const inputs = useMemo(() => {
    return options.map(option => {
      const isSelectedOption = value === option.value;
      const radioColor = getControlTypeTheme(disabled, isSelectedOption);

      return (
        <label
          key={`RadioGroup-option-${option.value}`}
          className={
            verticalLayout
              ? `${radioGroupContainer} ${verticalLayoutSpacing}`
              : `${radioGroupContainer}`
          }
        >
          <span className={disabled ? spanInactive : spanActive}>
            <Translate id={option.translateId} />
          </span>
          <input
            ref={register}
            checked={isSelectedOption}
            disabled={disabled || option.disabled}
            onChange={onChange}
            style={{
              borderColor: radioColor,
              color: isSelectedOption ? radioColor : WHITE_COLOR,
            }}
            type="radio"
            value={option.value === null ? '' : option.value}
          />
          <span
            className={radioGroupCheckmark}
            style={{ borderColor: radioColor }}
          >
            <div
              className={radioCheckmark}
              style={{
                backgroundColor: isSelectedOption ? radioColor : WHITE_COLOR,
              }}
            />
          </span>
        </label>
      );
    });
  }, [disabled, onChange, options, register, value, verticalLayout]);

  return (
    <div
      className={
        verticalLayout
          ? `${containerClassName || ''} ${verticalLayoutWrap}`
          : `${containerClassName || ''}`
      }
      id={id}
    >
      {labelTranslateId && (
        <span
          className={
            verticalLayout
              ? `${verticalLayoutSpacing} ${verticalTitleWrap}`
              : `${radioGroupLabel}`
          }
        >
          <label
            className={`${
              !labelTranslateId ? radioGroupLabelHidden : labelClass || ''
            }`}
          >
            <Translate id={labelTranslateId} />
          </label>
        </span>
      )}
      <div
        className={
          verticalLayout
            ? `${radioGroupInputsWrap} ${verticalLayoutWrap}`
            : `${radioGroupInputsWrap}`
        }
      >
        {inputs}
      </div>
    </div>
  );
};

RadioGroup.defaultProps = {
  containerClassName: null,
  disabled: false,
  id: 'idRadioGroup',
  labelClass: null,
  labelTranslateId: '',
  register: () => {},
  type: CONTROL_TYPE.ELEMENT,
  value: null,
  verticalLayout: false,
};

RadioGroup.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  labelClass: PropTypes.string,
  labelTranslateId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      translateId: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
    }),
  ).isRequired,
  register: PropTypes.func,
  type: PropTypes.oneOf([CONTROL_TYPE.FORM, CONTROL_TYPE.ELEMENT]),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
  verticalLayout: PropTypes.bool,
};

export default RadioGroup;
