import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CentralStationFormContainer from './CentralStationFormContainer';

function MonitoringConfigDetailContainer({ match }) {
  const { params } = match;
  const configId = params.configid;
  return <CentralStationFormContainer configId={configId} />;
}

MonitoringConfigDetailContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ configid: PropTypes.string }),
  }),
};

MonitoringConfigDetailContainer.defaultProps = {
  match: { params: { configid: null } },
};

export default withRouter(MonitoringConfigDetailContainer);
