exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".docHelpLinkText___m2rJV {\r\n  font-size: 12px;\r\n  font-weight: bold;\r\n}\r\n\r\n.docLinkText___2Ls35 {\r\n  font-size: 12px;\r\n  font-weight: normal;\r\n}\r\n\r\n.docLinkButton___33_C7 {\r\n  border: none;\r\n  white-space: nowrap;\r\n  cursor: pointer;\r\n  background-color: transparent;\r\n}\r\n\r\n.docLinkButton___33_C7.active___6sWLV .docHelpLinkText___m2rJV,\r\n.docLinkButton___33_C7:hover .docHelpLinkText___m2rJV {\r\n  color: #5bd6ff;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/common/DocLink/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;CAEnB;;AAED;EACE,gBAAgB;EAChB,oBAAoB;CAErB;;AAED;EACE,aAAa;EACb,oBAAoB;EACpB,gBAAgB;EAChB,8BAA8B;CAC/B;;AAED;;EAEE,eAAe;CAChB","file":"styles.css","sourcesContent":[".docHelpLinkText {\r\n  font-size: 12px;\r\n  font-weight: bold;\r\n  composes: avigilonBlueTextColor from '../../../sharedStyles/brushes.css';\r\n}\r\n\r\n.docLinkText {\r\n  font-size: 12px;\r\n  font-weight: normal;\r\n  composes: avigilonBlueTextColor from '../../../sharedStyles/brushes.css';\r\n}\r\n\r\n.docLinkButton {\r\n  border: none;\r\n  white-space: nowrap;\r\n  cursor: pointer;\r\n  background-color: transparent;\r\n}\r\n\r\n.docLinkButton.active .docHelpLinkText,\r\n.docLinkButton:hover .docHelpLinkText {\r\n  color: #5bd6ff;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"docHelpLinkText": "docHelpLinkText___m2rJV " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/brushes.css").locals["avigilonBlueTextColor"] + "",
	"docLinkText": "docLinkText___2Ls35 " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/brushes.css").locals["avigilonBlueTextColor"] + "",
	"docLinkButton": "docLinkButton___33_C7",
	"active": "active___6sWLV"
};