import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomFocusIn = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 8 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1163.000000, -447.000000)"
      >
        <path
          d="M1163.31856,454 C1163.27266,453.711801 1163.36078,453.406396 1163.58291,453.184259 L1169.08375,447.683418 C1169.45194,447.315228 1170.0489,447.315228 1170.41709,447.683418 C1170.78528,448.051607 1170.78528,448.648561 1170.41709,449.016751 L1165.43384,454 L1170.41709,458.983249 C1170.78528,459.351439 1170.78528,459.948393 1170.41709,460.316582 C1170.0489,460.684772 1169.45194,460.684772 1169.08375,460.316582 L1163.58291,454.815741 C1163.36078,454.593604 1163.27266,454.288199 1163.31856,454 Z"
          id="icon-btn-zoomfocus-in-v2"
          fill={props.fill}
          transform="translate(1167.000000, 454.000000) rotate(180.000000) translate(-1167.000000, -454.000000) "
        />
      </g>
    </svg>
  );
};

IconBtnZoomFocusIn.defaultProps = {
  className: null,
  width: '8px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomFocusIn;
