import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { ACTIVE_COLOR_DEFAULT, SVG_XMLNS } from '../constants/app';

const IconUnsync = ({
  className,
  fillColor,
  height,
  title,
  translate,
  width,
}) => {
  return (
    <svg
      className={className}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          d="M18.7039192,11.7507824 C18.7592781,11.7555431 20.0233772,12.1693004 20.0208367,12.3241054 C19.9602926,16.013365 17.4165114,19.6847203 13.611525,21.1475529 L13.611525,21.1475529 L14.5529692,23.6060183 L9.30225239,21.6754533 L11.2350175,18.0109761 C11.8305797,17.522827 12.1288264,17.2799674 12.1297576,17.2823972 C12.2914322,17.704251 12.5339442,18.3370317 12.8572934,19.1807394 C15.7661627,18.0625057 17.6356916,15.4099445 17.8969575,12.5008768 Z M18.9715843,3.03151256 C19.3990291,2.68178498 20.0290518,2.74478725 20.3787794,3.17223207 C20.728507,3.59967688 20.6655047,4.22969958 20.2380599,4.57942716 L20.2380599,4.57942716 L1.66308472,19.7771341 C1.23563991,20.1268617 0.60561721,20.0638594 0.255889635,19.6364146 C-0.0938379394,19.2089698 -0.0308356693,18.5789471 0.396609144,18.2292195 L0.396609144,18.2292195 Z M5.48106978,5.13478149e-16 L10.7321933,1.92965149 L8.11951003,6.88323747 L7.17806584,4.42477213 C3.86328094,5.6990487 1.8981427,8.96574293 2.12452427,12.3339767 L0.120868183,13.3537046 C-0.597744007,8.78801108 1.936783,4.18235271 6.42383425,2.45795853 L6.42383425,2.45795853 L5.48106978,5.13478149e-16 Z"
          id="ic_unsync-a"
        />
      </defs>
      <use
        fill={fillColor}
        fillRule="evenodd"
        transform="translate(1.97 .195)"
        xlinkHref="#ic_unsync-a"
      >
        <title>{translate(title)}</title>
      </use>
    </svg>
  );
};

IconUnsync.defaultProps = {
  className: '',
  fillColor: ACTIVE_COLOR_DEFAULT,
  height: '24',
  title: '',
  width: '24',
};

IconUnsync.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconUnsync);
