import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { ListView } from 'lib';

function ObjectDetailTable({
  bodyHeight,
  customCells,
  fieldOrder,
  headerLabels,
  item,
  resizable,
  resizableFrom,
  rowIndex,
  sortType,
}) {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };
  const keysInFieldOrder = fieldOrder || Object.keys(item);
  const data = keysInFieldOrder.map(fieldName => {
    let fieldDisplay = fieldName;
    if (headerLabels && headerLabels[fieldName]) {
      fieldDisplay = headerLabels[fieldName];
    }
    let value;
    if (customCells[fieldName]) {
      value = customCells[fieldName](item, rowIndex);
    } else {
      value =
        typeof item[fieldName] === 'string'
          ? item[fieldName]
          : JSON.stringify(item[fieldName], getCircularReplacer);
    }
    return { fieldName: fieldDisplay, value };
  });
  return (
    <ListView
      bodyHeight={bodyHeight}
      data={data}
      fieldOrder={['fieldName', 'value']}
      headerTranslationIds={{
        fieldName: 'DEV_DO_NOT_TRANSLATE.DEFAULT_DETAIL_FIELD',
        value: 'DEV_DO_NOT_TRANSLATE.DEFAULT_DETAIL_VALUE',
      }}
      hideFilter
      resizable={resizable}
      resizableFrom={resizableFrom}
      sortType={sortType}
      truncatedFields={['value']}
    />
  );
}

ObjectDetailTable.propTypes = {
  customCells: PropTypes.objectOf(PropTypes.func),
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
  headerTranslationIds: PropTypes.objectOf(PropTypes.string),
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  resizable: PropTypes.bool,
  resizableFrom: PropTypes.oneOf(['top', 'bottom']),
  rowIndex: PropTypes.number,
  translate: PropTypes.func.isRequired,
};

ObjectDetailTable.defaultProps = {
  customCells: {},
  fieldOrder: null,
  headerTranslationIds: {
    fieldName: 'DEV_DO_NOT_TRANSLATE.DEFAULT_DETAIL_FIELD',
    value: 'DEV_DO_NOT_TRANSLATE.DEFAULT_DETAIL_VALUE',
  },
  resizable: false,
  resizableFrom: 'bottom',
  rowIndex: null,
};

export default withLocalize(ObjectDetailTable);
