import 'react-datepicker/dist/react-datepicker.css';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { Translate } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

import {
  alarmFilterInput,
  datePickerContainerStart,
  datePickerContainerEnd,
  datesContainer,
  alarmFilterCalendar,
  disabled,
} from './styles.css';

class InputDateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeStart: undefined,
      rangeStartMax: Moment(),
      rangeEnd: undefined,
      rangeEndMin: undefined,
      rangeEndMax: Moment(),
    };
  }

  onDateRangeStartChange = value => {
    const { start } = this.props;
    this.setState({
      rangeStart: value,
      rangeEndMin: value,
    });
    const startDate = value === null ? undefined : Moment(value.format());
    // Clone the moment so that is is not manipulated outside this component
    start(startDate);
  };

  onDateRangeEndChange = value => {
    const { end } = this.props;
    this.setState({
      rangeEnd: value,
      rangeStartMax: value,
    });
    const endDate = value === null ? undefined : Moment(value.format());
    // Clone the moment so that is is not manipulated outside this component
    end(endDate);
  };

  resetDateRange() {
    this.setState({
      rangeStart: undefined,
      rangeStartMax: Moment(),
      rangeEnd: undefined,
      rangeEndMin: undefined,
      rangeEndMax: Moment(),
    });
  }

  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceReset && !nextProps.disabled) {
      this.resetDateRange();
    }
  }

  render() {
    const { disabled: disabledProp } = this.props;
    const {
      rangeStart,
      rangeStartMax,
      rangeEnd,
      rangeEndMin,
      rangeEndMax,
    } = this.state;
    return (
      <div className={datesContainer + (disabledProp ? ` ${disabled}` : '')}>
        <div className={datePickerContainerStart}>
          <DatePicker
            className={alarmFilterInput}
            calendarClassName={alarmFilterCalendar}
            onChange={this.onDateRangeStartChange}
            isClearable
            placeholderText={<Translate id="DATE_RANGE.START_DATE" />}
            selected={rangeStart}
            maxDate={rangeStartMax}
            popoverTargetOffset="10px -100px"
          />
        </div>
        <div className={datePickerContainerEnd}>
          <DatePicker
            className={alarmFilterInput}
            calendarClassName={alarmFilterCalendar}
            onChange={this.onDateRangeEndChange}
            isClearable
            placeholderText={<Translate id="DATE_RANGE.END_DATE" />}
            selected={rangeEnd}
            minDate={rangeEndMin}
            maxDate={rangeEndMax}
            popoverTargetOffset="10px -110px"
          />
        </div>
      </div>
    );
  }
}

InputDateRange.propTypes = {
  disabled: PropTypes.bool,
  start: PropTypes.func,
  end: PropTypes.func,
};

InputDateRange.defaultProps = {
  disabled: false,
  start() {},
  end() {},
};

export default InputDateRange;
