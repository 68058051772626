import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { PropTypes } from 'prop-types';
import renderIf from 'render-if';
import { Field, formValueSelector, reduxForm } from 'redux-form';

// Containers
import AddCreateUser from 'containers/common/AddCreateUser/AddCreateUser';
import PageMessage from 'containers/PageMessage/PageMessage';

import { messageStyleStrings } from 'containers/PageMessage/constants';

// Styles
import {
  buttonGroup,
  buttonSubmit,
  formWrapper,
  horizontalDivider,
  pageError,
} from 'sharedStyles/modalForms.css';
import { sectionHeader } from 'components/VerticalForm/styles.css';

// Components
import { Button, GroupLayout } from 'lib';
import { FORM_DEFAULTS } from 'constants/app';
import * as messageTypes from 'constants/MessageTypes';
import AddressFieldSet from './AddressFieldSet';
import SiteDetailsFieldSet from './SiteDetailsFieldSet';
import SiteEntitlementsFieldSet from './SiteEntitlementsFieldSet';
import SubscriptionFieldSet from './SubscriptionFieldSet';

// Constants
import * as SiteFormConsts from './constants';

// Styles
import { formColumn } from './styles.css';

const formLevelValidation = values => {
  const {
    AdminUserId,
    adminUserNew,
    newAdminFields,
    requiredExistingAdminFields,
    requiredNewAdminFields,
  } = SiteFormConsts;
  const errors = {};
  const requiredFields =
    values[AdminUserId] === adminUserNew
      ? requiredNewAdminFields
      : requiredExistingAdminFields;
  const emptyFields = requiredFields.filter(
    field => !values[field] || values[field].trim() === '',
  );
  if (emptyFields.length > 0) {
    const emptyNewAdmin = emptyFields.filter(name =>
      newAdminFields.includes(name),
    );
    errors.summary =
      values.AdminUserId === adminUserNew && emptyNewAdmin.length > 0
        ? 'ERROR_MESSAGES.NEW_ADMIN_ERROR'
        : 'PROFILE.FILL_REQUIRED_FIELDS_ERROR';
  }
  return errors;
};

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        messageStyle={messageStyleStrings.error}
        translateBody={error}
        translateHeader="GENERAL_MESSAGES.MISSING_INFO_HEADER"
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.propTypes = {
  meta: PropTypes.shape({ error: PropTypes.any, touched: PropTypes.bool })
    .isRequired,
};

class Form extends Component {
  currentAdmin() {
    const { adminUserId, users } = this.props;
    if (adminUserId === FORM_DEFAULTS.new) {
      return {};
    }
    const user = users.find(u => u.Id === adminUserId);
    return user && user.Id ? user : {};
  }

  render() {
    const {
      adminUserId,
      allowEmptyValue,
      canEditSubscription,
      canEnableHealthMonitoring,
      change,
      country,
      customerId,
      disableSave,
      handleSubmit,
      initialValues,
      locationId,
      onSubmit,
      pristine,
      readOnly,
      reset,
      selfService,
      servicePackageFamilies,
      servicePackageFamily,
      servicePackageSkuId,
      servicePackages,
      showBillingInfo,
      showHealthMonitoring,
      submittingForm,
      subscriptionTermOptions,
      timezone,
      valid,
    } = this.props;
    return (
      <div>
        {/* Disabled this as UX team still needs to think about it
         <div key={'formLoader'} id={'formLoader'} className={isBusy ? formLoaderOverlay : null}>
          {isBusy ? <FormLoading /> : null}
        </div> */}
        <div className={formWrapper}>
          <PageMessage
            messageType={[
              messageTypes.HEALTH_MONITORING_ACTIVATION_SUCCESS,
              messageTypes.HEALTH_MONITORING_ACTIVATION_ERROR,
              messageTypes.HEALTH_MONITORING_DEACTIVATION_SUCCESS,
              messageTypes.HEALTH_MONITORING_DEACTIVATION_ERROR,
              messageTypes.EDIT_SUBSCRIPTION_TERM_SUCCESS,
              messageTypes.EDIT_SUBSCRIPTION_TERM_ERROR,
              messageTypes.SERVICE_PACKAGE_UPGRADE_SUCCESS,
              messageTypes.SERVICE_PACKAGE_UPGRADE_ERROR,
              messageTypes.SERVICE_PACKAGE_DOWNGRADE_SUCCESS,
              messageTypes.SERVICE_PACKAGE_DOWNGRADE_ERROR,
              messageTypes.ADD_EDIT_LOCATION_ERROR,
            ]}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Field
                component={errorAggregator}
                name={SiteFormConsts.nameSummary}
              />
            </div>
            <Field
              component="input"
              name={SiteFormConsts.nameId}
              type="hidden"
            />
            <div className={sectionHeader}>
              <Translate id="ACCOUNTS.GENERAL_INFO.DETAILS" />
            </div>
            <SiteDetailsFieldSet
              allowEmptyValue={allowEmptyValue}
              readOnly={readOnly}
              servicePackageFamily={servicePackageFamily}
              showBillingInfo={showBillingInfo}
            />
            {renderIf(canEditSubscription)(
              <>
                <div className={horizontalDivider} />
                <SubscriptionFieldSet
                  canEditSubscription
                  canEnableHealthMonitoring={canEnableHealthMonitoring}
                  change={change}
                  initialValues={initialValues}
                  readOnly={readOnly}
                  selfService={selfService}
                  servicePackageFamilies={servicePackageFamilies}
                  servicePackageFamily={servicePackageFamily}
                  servicePackages={servicePackages}
                  servicePackageSkuId={servicePackageSkuId}
                  showHealthMonitoring={showHealthMonitoring}
                  subscriptionTermOptions={subscriptionTermOptions}
                  timezone={timezone}
                />
              </>,
            )}
            {renderIf(showHealthMonitoring)(
              <>
                <div className={horizontalDivider} />
                <SiteEntitlementsFieldSet
                  canEnableHealthMonitoring={canEnableHealthMonitoring}
                  change={change}
                  initialValues={initialValues}
                  showHealthMonitoring={showHealthMonitoring}
                />
              </>,
            )}
            <div className={horizontalDivider} />
            <AddressFieldSet
              country={country}
              readOnly={readOnly}
              timezone={timezone}
            />
            <fieldset className={formColumn} disabled={readOnly}>
              <div className={sectionHeader}>
                <Translate id="GENERAL_MESSAGES.ADMIN_HEADER" />
              </div>
              <AddCreateUser
                disabled={readOnly}
                id={SiteFormConsts.idUser}
                initialValues={initialValues}
                selectedId={adminUserId}
                tenantId={customerId}
                translatedLabel="USERS.USER_FORM_FIELDS.LABELS.ID"
              />
            </fieldset>
            {renderIf(
              !readOnly || (canEnableHealthMonitoring && showHealthMonitoring),
            )(
              <div className={buttonGroup}>
                <GroupLayout>
                  <Button
                    buttonType="action"
                    disabled={submittingForm}
                    inputType="button"
                    onClick={reset}
                    text={<Translate id="BUTTONS.REVERT" />}
                  />
                  <Button
                    buttonType="primary"
                    className={buttonSubmit}
                    disabled={
                      pristine ||
                      submittingForm ||
                      (readOnly && !canEnableHealthMonitoring) ||
                      !valid ||
                      disableSave
                    }
                    inputType="submit"
                    text={
                      <Translate
                        id={
                          locationId === 'new' ? 'BUTTONS.ADD' : 'BUTTONS.SAVE'
                        }
                      />
                    }
                  />
                </GroupLayout>
              </div>,
            )}
          </form>
        </div>
      </div>
    );
  }
}

Form.defaultProps = {
  adminUserId: SiteFormConsts.adminUserNew,
  country: undefined,
  customerId: null,
  locationId: null,
  pristine: true,
  servicePackageFamilies: [],
  servicePackageFamily: undefined,
  servicePackageSkuId: '',
  servicePackages: [],
  // Intentionally hard coded for now
  showBillingInfo: true,
  subscriptionTermOptions: SiteFormConsts.subscriptionTermOptions,
  timezone: undefined,
};

Form.propTypes = {
  adminUserId: PropTypes.string,
  allowEmptyValue: PropTypes.bool.isRequired,
  canEditSubscription: PropTypes.bool.isRequired,
  canEnableHealthMonitoring: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  country: PropTypes.string,
  customerId: PropTypes.string,
  disableSave: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ DealerMonitoringEnabled: PropTypes.bool })
    .isRequired,
  locationId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  selfService: PropTypes.number.isRequired,
  servicePackageFamilies: PropTypes.arrayOf(PropTypes.shape({})),
  servicePackageFamily: PropTypes.number,
  servicePackageSkuId: PropTypes.string,
  servicePackages: PropTypes.arrayOf(PropTypes.shape({})),
  showBillingInfo: PropTypes.bool,
  showHealthMonitoring: PropTypes.bool.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  subscriptionTermOptions: PropTypes.arrayOf(PropTypes.shape({})),
  timezone: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  valid: PropTypes.bool.isRequired,
};

const ReduxForm = reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: SiteFormConsts.FORM_NAME,
  validate: formLevelValidation,
})(Form);

const selector = formValueSelector(SiteFormConsts.FORM_NAME);

const SiteForm = connect(state => {
  const country = selector(state, SiteFormConsts.nameCountry);
  const timezone = selector(state, SiteFormConsts.nameTimeZone);
  const adminUserId = selector(state, SiteFormConsts.AdminUserId);
  const servicePackageSkuId = selector(state, SiteFormConsts.nameSkuId);
  const servicePackageFamily = selector(
    state,
    SiteFormConsts.nameSubscriptionFamily,
  );
  const subscriptionTermLength = selector(
    state,
    SiteFormConsts.nameSubscriptionTermLength,
  );
  return {
    adminUserId,
    allowEmptyValue: state.user.permissions.CAN_VIEW_BILLING_ORDER_ID,
    country,
    servicePackageFamily,
    servicePackageSkuId,
    subscriptionTermLength,
    timezone,
  };
})(ReduxForm);

export default SiteForm;
