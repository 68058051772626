import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

// Containers
import {
  SiteDetailListNavContainer,
  SiteFormContainer,
  SiteSubscriptionsContainer,
} from 'containers';

// Consts
import {
  PATH_ACCOUNTS,
  PATH_CHANGE_PROVIDER,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';
import { SUBSCRIPTIONS } from './constants';

const SiteDetailContainer = props => {
  const { history, match } = props;
  const { siteId, subscriberId } = match.params;
  const { pathname } = history.location;
  const isSubscriptionTab = pathname.includes(SUBSCRIPTIONS);

  const pathSite = `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}`;
  const pathSiteUnderSubscriber = `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${subscriberId}${PATH_SEGMENT_SITES}`;

  const goBack = () => {
    return subscriberId
      ? history.push(pathSiteUnderSubscriber)
      : history.push(pathSite);
  };

  const pathChangeProviderUnderSubscriber = `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${subscriberId}${PATH_SEGMENT_SITES}/${siteId}${PATH_CHANGE_PROVIDER}`;
  const pathChangeProvider = `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}/${siteId}${PATH_CHANGE_PROVIDER}`;

  const onChangeProviderClick = () => {
    return subscriberId
      ? history.push(pathChangeProviderUnderSubscriber)
      : history.push(pathChangeProvider);
  };

  return (
    <>
      <SiteDetailListNavContainer
        {...props}
        goBack={goBack}
        isSubscriptionTab={isSubscriptionTab}
        locationId={siteId}
        onChangeProviderClick={onChangeProviderClick}
        subscriberId={subscriberId}
      />
      {!isSubscriptionTab ? (
        <SiteFormContainer
          {...props}
          customerId={subscriberId}
          goBack={goBack}
          locationId={siteId}
        />
      ) : (
        <SiteSubscriptionsContainer
          {...props}
          customerId={subscriberId}
          goBack={goBack}
          locationId={siteId}
        />
      )}
    </>
  );
};

SiteDetailContainer.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};

SiteDetailContainer.defaultProps = {
  history: {},
  match: {},
};

const mapStateToProps = state => {
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
  };
};

export default connect(mapStateToProps, null)(SiteDetailContainer);
