export const englishCode = 'en';
export const englishCodeUs = 'en-us';

/* Each of these keys is also defined in the Server code found at:
 *
 * server\HeliosAPI\Controllers\UtilitiesController.cs
 *
 * If any of these change, a corresponding change must be made there
 * or the keys we get in the API response will not match and we will
 * be stuck with links that do not go anywhere.
 */
export const keyEULA = 'EULA';
export const keyHelp = 'Help';
export const keyPrivacyPolicy = 'PrivacyPolicy';
export const keyTermsOfService = 'TermsOfService';

export const templateLanguageToken = '{languageCode}';
