import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Moment from 'moment';

// Components
import { DateSelect } from 'components';

// Lib
import { FormGroup } from 'lib';

// Consts
import { DATABASE_DATETIME_FORMAT } from 'constants/app';
import * as LocationFormConsts from '../../constants';

const TrialTermField = ({
  disabled,
  initialValue,
  isExtendable,
  onChange,
  timezone,
}) => {
  const [endDate, setEndDate] = useState(initialValue && Moment(initialValue));

  // Hide Extend Term field for non-trial subscriptions
  if (!isExtendable) {
    return null;
  }

  const handleDateChange = date => {
    setEndDate(date);
    onChange(
      'PackageSubscription.TermEndDate',
      String(date.utc().format(DATABASE_DATETIME_FORMAT)),
    );
  };

  return (
    <FormGroup
      key={LocationFormConsts.nameExtendTrialTermLength}
      label={<Translate id="LOCATIONS.LOCATION_FORM.EXTEND_TRIAL" />}
    >
      <DateSelect
        disabled={disabled}
        initialValue={endDate}
        maxDate={Moment().add(100, 'year')}
        minDate={Moment().add(1, 'day')}
        onChange={date => handleDateChange(date)}
        showCalendarIcon
        timezone={timezone}
      />
    </FormGroup>
  );
};

TrialTermField.defaultProps = {
  disabled: false,
  initialValue: undefined,
  isExtendable: false,
  onChange: () => {},
  timezone: undefined,
};

TrialTermField.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  isExtendable: PropTypes.bool,
  onChange: PropTypes.func,
  timezone: PropTypes.string,
};

export default TrialTermField;
