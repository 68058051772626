import { TOGGLE_REVERT, TOGGLE_TRACK_ACTION } from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function toggleReducer(
  state = initialState().toggle,
  action = {},
) {
  switch (action.type) {
    case TOGGLE_TRACK_ACTION:
      return {
        actions: [...state.actions, { toggleAction: action.toggleAction }],
      };

    case TOGGLE_REVERT:
      return {
        actions: [
          ...state.actions.filter(
            fromState => fromState.toggleAction !== action.toggleAction,
          ),
        ],
      };

    default:
      return state;
  }
}
