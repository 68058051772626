import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { GRID_ICON_ID } from '../constants/ElementId';

const IconGridView = ({ translate, title, className, width, height, fill }) => {
  return (
    <div title={translate(title)} className={className} id={GRID_ICON_ID}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill={fill}
            d="M5,5 L11,5 L11,11.3 L5,11.3 L5,5 Z M13,5 L19,5 L19,11.3 L13,11.3 L13,5 Z M5,12.7 L11,12.7 L11,19 L5,19 L5,12.7 Z M13,12.7 L19,12.7 L19,19 L13,19 L13,12.7 Z"
          />
        </g>
      </svg>
    </div>
  );
};

IconGridView.defaultProps = {
  title: 'LIST_VIEWS.GRID',
  width: '24px',
  height: '24px',
  fill: '#494949',
  className: '',
};

IconGridView.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(IconGridView);
