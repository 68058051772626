/* global avoLogError */

import { Client as AftClient } from '@avo-aft/aft-client';
import moment from 'moment';
// Actions

// Utils

// Constants
import { DATABASE_DATETIME_FORMAT } from 'constants/app';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as types from 'constants/ActionTypes';
import { showMessage } from './pageMessage';
import { isFetchingData } from './common';

const bookmarkSearchDefaults = {
  to: moment()
    .add(1, 'days')
    .format(DATABASE_DATETIME_FORMAT),
  from: moment()
    .add(1, 'days')
    .subtract(14, 'days')
    .format(DATABASE_DATETIME_FORMAT),
};

// Redux action creators

function receiveBookmarksList(accBookmarks, clusterId, queryOptions) {
  return {
    type: types.RECEIVE_BOOKMARKS,
    accBookmarks,
    clusterId,
    queryOptions,
  };
}

function receiveDeleteBookmark(clusterId, bookmarkId) {
  return {
    type: types.DELETE_BOOKMARK,
    clusterId,
    bookmarkId,
  };
}

function receiveCreateBookmark(clusterId, bookmarkData) {
  return {
    type: types.CREATE_BOOKMARK,
    clusterId,
    bookmarkData,
  };
}

function receiveUpdateBookmark(clusterId, bookmarkData) {
  return {
    type: types.UPDATE_BOOKMARK,
    clusterId,
    bookmarkData,
  };
}

// Async action creators

export function getBookmarks(clusterId, initialQueryOptions = {}) {
  return async dispatch => {
    const fetchOptions = { fetchScope: clusterId };
    dispatch(isFetchingData(types.GET_BOOKMARKS, true, fetchOptions));
    try {
      const client = new AftClient({ clusterId });
      const queryOptions = Object.assign(
        {},
        bookmarkSearchDefaults,
        initialQueryOptions,
      );
      const response = await client.readBookmarkSearch({ ...queryOptions });
      const accBookmarks = response.payload.bookmarks;
      dispatch(receiveBookmarksList(accBookmarks, clusterId, queryOptions));
    } catch (ex) {
      avoLogError('Error getting bookmarks', { ex, clusterId });
    } finally {
      dispatch(isFetchingData(types.GET_BOOKMARKS, false, fetchOptions));
    }
  };
}

export function getBookmarksForLocationClusters(clustersForSite, queryOptions) {
  return async dispatch => {
    clustersForSite.forEach(clusterId =>
      dispatch(getBookmarks(clusterId, queryOptions)),
    );
  };
}

export function updateBookmark(clusterId, bookmarkData) {
  return async dispatch => {
    try {
      const client = new AftClient({ clusterId });
      await client.updateBookmark({ bookmark: bookmarkData });
      dispatch(receiveUpdateBookmark(clusterId, bookmarkData));
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.success,
          translateBody: 'BOOKMARKS.EDIT_SUCCESS',
        }),
      );
    } catch (error) {
      avoLogError('Error updating bookmark', error);
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'BOOKMARKS.EDIT_ERROR',
        }),
      );
    }
  };
}

export function deleteBookmarksForCluster(clusterId, bookmarkIds) {
  return async dispatch => {
    try {
      const client = new AftClient({ clusterId });
      await client.deleteBookmark({ ids: bookmarkIds });
      bookmarkIds.forEach(bookmarkId =>
        dispatch(receiveDeleteBookmark(clusterId, bookmarkId)),
      );
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.success,
          translateBody: 'BOOKMARKS.DELETE_SUCCESS',
        }),
      );
    } catch (error) {
      avoLogError('Error deleting bookmark', { clusterId, bookmarkIds, error });
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'BOOKMARKS.DELETE_ERROR',
        }),
      );
    }
  };
}

export function createBookmark(clusterId, bookmarkData) {
  return async dispatch => {
    try {
      const client = new AftClient({ clusterId });
      await client.createBookmark({ bookmark: bookmarkData });
      dispatch(receiveCreateBookmark(clusterId, bookmarkData));
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.success,
          translateBody: 'BOOKMARKS.CREATE_SUCCESS',
        }),
      );
    } catch (error) {
      avoLogError('Error creating a bookmark', {
        clusterId,
        bookmarkData,
        error,
      });
      dispatch(
        showMessage(messageTypes.BOOKMARKS_MESSAGE, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'BOOKMARKS.CREATE_ERROR',
        }),
      );
    }
  };
}
