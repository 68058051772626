/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { translatedConnect } from 'util/translatedConnect';
import PropTypes from 'prop-types';
import { EmptyPlaceholder, MainContentWrapper } from 'lib';

// Actions
import * as LocationActions from 'actions/locations';
import * as SubscriptionActions from 'actions/subscriptions';
import * as UserActions from 'actions/user';

// Utils
import * as permissions from 'util/permissions';
import * as tableUtils from 'util/tableData';

// Components
import { PageMessage } from 'containers';
import * as messageTypes from 'constants/MessageTypes';
import SiteSubscriptionsTable from './SiteSubscriptions/SiteSubscriptionsTable';

const SiteSubscriptionsContainer = ({
  actions,
  currentSite,
  customerId,
  isFetchingServicePackage,
  isFetchingSites,
  preferredShortDateFormat,
  selfService,
  servicePackages,
  subscriptions,
  translate,
}) => {
  useEffect(() => {
    if (!currentSite && !isFetchingSites) {
      actions.getLocations(customerId);
    }
  }, [actions, currentSite, customerId, isFetchingSites]);

  useEffect(() => {
    if (
      currentSite &&
      currentSite.PackageSubscription &&
      isFetchingServicePackage === null
    ) {
      actions.getServicePackage(
        currentSite.PackageSubscription.ServicePackageId,
      );
    }
  }, [actions, currentSite, isFetchingServicePackage]);

  return (
    <MainContentWrapper>
      <PageMessage messageType={[messageTypes.CHANGE_REQUEST_SUCCESS]} />
      <EmptyPlaceholder
        isFetching={isFetchingServicePackage === true}
        items={servicePackages}
      >
        <SiteSubscriptionsTable
          preferredShortDateFormat={preferredShortDateFormat}
          selfService={selfService}
          subscriptions={subscriptions}
          translate={translate}
        />
      </EmptyPlaceholder>
    </MainContentWrapper>
  );
};

SiteSubscriptionsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  currentSite: PropTypes.objectOf(PropTypes.any).isRequired,
  customerId: PropTypes.string,
  isFetchingServicePackage: PropTypes.bool,
  isFetchingSites: PropTypes.bool.isRequired,
  preferredShortDateFormat: PropTypes.string.isRequired,
  selfService: PropTypes.number.isRequired,
  servicePackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  translate: PropTypes.func.isRequired,
};

SiteSubscriptionsContainer.defaultProps = {
  customerId: null,
  isFetchingServicePackage: null,
};

const mapStateToProps = (state, ownProps) => {
  const orgId = permissions.getOrgIdFromStore(state);
  const { customerId, locationId } = ownProps;
  const { dealerServiceFamilies, dealerServicePackages } = state.locations;
  const { currentOrganization, subscriberServicePackages } = state.user;
  const { servicePackages } = state.locations;
  const subscriptions = [];

  let currentSite =
    locationId && state.locations.locations.find(l => l.Id === locationId);
  if (currentSite && dealerServicePackages) {
    const [enhancedLocation] = tableUtils.locationTableData(
      [currentSite],
      dealerServicePackages,
      ownProps.translate,
    );
    currentSite = enhancedLocation;
  }

  const subscription = currentSite && currentSite.PackageSubscription;
  const isFetchingServicePackage =
    subscription &&
    state.isFetching.getServicePackage[subscription.ServicePackageId];
  const IsChangePending = subscription && !!subscription.ChangeRequestGrantDate;

  const serviceDetails = servicePackages.find(
    sp => sp.Id === currentSite.PackageSubscription.ServicePackageId,
  );

  const currentPackage = {
    ...subscription,
    ...serviceDetails,
    IsChangePending,
    Term: currentSite && currentSite.Term,
  };

  subscriptions.push(currentPackage);

  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    canEditLocation: state.user.permissions.CAN_EDIT_SITE,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    currentOrg: state.user.currentOrganization,
    currentSite,
    customerId,
    dealerServiceFamilies,
    dealerServicePackages,
    isFetchingServicePackage,
    isFetchingSites: state.isFetching.getLocations,
    orgId,
    preferredShortDateFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
    selfService: state.user.permissions.SELF_SERVICE,
    servicePackages,
    subscriberServicePackages,
    subscriptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...SubscriptionActions,
        ...UserActions,
      },
      dispatch,
    ),
  };
};

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteSubscriptionsContainer);
