import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { cellText, highlight, tableCell } from 'sharedStyles/tables.css';

const compileTemplate = (data, rowIndex, template) => {
  const dataItem = data[rowIndex.idx];
  return <Translate id={template} data={{ ...dataItem }} />;
};

const TemplateCell = ({
  rowIndex,
  rowsSelected,
  data,
  className,
  cellClassName,
  field,
  template,
  ...props
}) => (
  <div
    className={`${tableCell} ${rowsSelected.includes(rowIndex) ? highlight : ''}
        ${cellClassName || ''}`}
    {...props}
  >
    <div className={className || cellText}>
      {compileTemplate(data, rowIndex, template)}
    </div>
  </div>
);

TemplateCell.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

TemplateCell.defaultProps = {
  rowsSelected: [],
};

export default TemplateCell;
