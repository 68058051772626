// Message types refer to where the message should be displayed in the app
// but are content-agnostic

// Authentication
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CLAIM_ERROR = 'CLAIM_ERROR';
export const CLAIM_SUCCESS = 'CLAIM_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const REQUEST_RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_LINK_EXPIRED_ERROR =
  'RESET_PASSWORD_LINK_EXPIRED_ERROR';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
// Authentication

// General
export const ACCOUNT_GENERAL_SUCCESS = 'ACCOUNT_GENERAL_SUCCESS';
export const ACCOUNT_GENERAL_ERROR = 'ACCOUNT_GENERAL_ERROR';
// General

// Customer
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
// Customer

// Integrations (SureView)
export const INTEGRATION_CONFIG = 'INTEGRATION_CONFIG';
export const UPDATE_INTEGRATION_SUBSCRIBER_SITES_SUCCESS =
  'UPDATE_INTEGRATION_SUSBSCRIBER_SITES_SUCCESS';
export const UPDATE_INTEGRATION_SUBSCRIBER_SITES_ERROR =
  'UPDATE_INTEGRATION_SUBSCRIBER_SITES_ERROR';
// Integrations

// User
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
// User

// Location
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_ERROR = 'LOCATION_ERROR';
export const ADD_EDIT_LOCATION_ERROR = 'ADD_EDIT_LOCATION_ERROR';
export const SERVICE_PACKAGE_UPGRADE_SUCCESS =
  'SERVICE_PACKAGE_UPGRADE_SUCCESS';
export const SERVICE_PACKAGE_UPGRADE_ERROR = 'SERVICE_PACKAGE_UPGRADE_ERROR';
export const SERVICE_PACKAGE_DOWNGRADE_SUCCESS =
  'SERVICE_PACKAGE_DOWNGRADE_SUCCESS';
export const SERVICE_PACKAGE_DOWNGRADE_ERROR =
  'SERVICE_PACKAGE_DOWNGRADE_ERROR';
export const HEALTH_MONITORING_ACTIVATION_SUCCESS =
  'HEALTH_MONITORING_ACTIVATION_SUCCESS';
export const HEALTH_MONITORING_DEACTIVATION_SUCCESS =
  'HEALTH_MONITORING_DEACTIVATION_SUCCESS';
export const HEALTH_MONITORING_ACTIVATION_ERROR =
  'HEALTH_MONITORING_ACTIVATION_ERROR';
export const HEALTH_MONITORING_DEACTIVATION_ERROR =
  'HEALTH_MONITORING_DEACTIVATION_ERROR';
export const EDIT_SUBSCRIPTION_TERM_SUCCESS = 'EDIT_SUBSCRIPTION_TERM_SUCCESS';
export const EDIT_SUBSCRIPTION_TERM_ERROR = 'EDIT_SUBSCRIPTION_TERM_ERROR';
export const SERVICE_PACKAGE_ERROR = 'SERVICE_PACKAGE_ERROR';
export const CHANGE_REQUEST_SUCCESS = 'CHANGE_REQUEST_SUCCESS';
export const CHANGE_REQUEST_ERROR = 'CHANGE_REQUEST_ERROR';
// Location

// Schedules
export const SCHEDULE_SUCCESS = 'SCHEDULE_SUCCESS';
export const SCHEDULE_ERROR = 'SCHEDULE_ERROR';
// Schedules

// Cameras
export const DISCOVERED_CAMERAS_ERROR = 'DISCOVERED_CAMERAS_ERROR';
export const CONNECTED_CAMERAS_ERROR = 'CONNECTED_CAMERAS_ERROR';
export const CONNECT_DISCONNECT_SUCCESS = 'CONNECT_DISCONNECT_SUCCESS';
export const CONNECT_DISCONNECT_ERROR = 'CONNECT_DISCONNECT_ERROR';
export const CAMERA_ENABLE_ERROR = 'CAMERA_ENABLE_ERROR';
export const CAMERA_DISABLE_ERROR = 'CAMERA_DISABLE_ERROR';
export const CAMERA_ENABLE_SUCCESS = 'CAMERA_ENABLE_SUCCESS';
export const CAMERA_DISABLE_SUCCESS = 'CAMERA_DISABLE_SUCCESS';
export const SUPPORTED_CAMERAS_ERROR = 'SUPPORTED_CAMERAS_ERROR';
export const CAMERA_UPDATE_ERROR = 'CAMERA_UPDATE_ERROR';
export const ENABLE_TAMPER_EVENTS_SUCCESS = 'ENABLE_TAMPER_EVENTS_SUCCESS';
export const DISABLE_TAMPER_EVENTS_SUCCESS = 'DISABLE_TAMPER_EVENTS_SUCCESS';
export const ENABLE_TAMPER_EVENTS_ERROR = 'ENABLE_TAMPER_EVENTS_ERROR';
export const DISABLE_TAMPER_EVENTS_ERROR = 'DISABLE_TAMPER_EVENTS_ERROR';
export const CAMERA_ARM_SUCCESS = 'CAMERA_ARM_SUCCESS';
export const CAMERA_ARM_ERROR = 'CAMERA_ARM_ERROR';
export const CAMERA_DISARM_SUCCESS = 'CAMERA_DISARM_SUCCESS';
export const CAMERA_DISARM_ERROR = 'CAMERA_DISARM_ERROR';
export const CAMERA_ENABLE_FEATURE_ERROR = 'CAMERA_ENABLE_FEATURE_ERROR';
export const CAMERA_DISABLE_FEATURE_ERROR = 'CAMERA_DISABLE_FEATURE_ERROR';
export const CAMERA_ENABLE_FEATURE_SUCCESS = 'CAMERA_ENABLE_FEATURE_SUCCESS';
export const CAMERA_DISABLE_FEATURE_SUCCESS = 'CAMERA_DISABLE_FEATURE_SUCCESS';
export const FETCHING_DISCOVERED_CAMERA_MANUALLY =
  'FETCHING_DISCOVERED_CAMERA_MANUALLY';
export const FETCHING_DISCOVERED_CAMERA_RESULT =
  'FETCHING_DISCOVERED_CAMERA_RESULT';

// Devices
export const DEVICE_SUCCESS = 'DEVICE_SUCCESS';
export const DEVICE_ERROR = 'DEVICE_ERROR';
// Devices

// Views
export const VIEW_SUCCESS = 'VIEW_SUCCESS';
export const VIEW_ERROR = 'VIEW_ERROR';
export const DELETE_VIEW_SUCCESS = 'DELETE_VIEW_SUCCESS';
export const DELETE_VIEW_ERROR = 'DELETE_VIEW_ERROR';
// Views

// Profile
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';
// Profile

// Contacts
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_ERROR = 'CONTACT_ERROR';
// Contacts

// Service Plans
export const SERVICE_PLAN_SUCCESS = 'SERVICE_PLAN_SUCCESS';
export const SERVICE_PLAN_ERROR = 'SERVICE_PLAN_ERROR';
// Service Plans

// Gateway
export const GATEWAY_SUCCESS = 'GATEWAY_SUCCESS';
export const GATEWAY_ERROR = 'GATEWAY_ERROR';
// Gateway

// POE
export const POE_GET_ERROR = 'POE_GET_ERROR';
export const POE_UPDATE_SUCCESS = 'POE_UPDATE_SUCCESS';
export const POE_UPDATE_ERROR = 'POE_UPDATE_ERROR';
export const POE_BUDGET_WARNING = 'POE_BUDGET_WARNING';
// POE

// PTZ
export const PTZ_GO_TO_HOME_ERROR = 'PTZ_GO_TO_HOME_ERROR';
// PTZ

// Bookmarks
export const BOOKMARKS_MESSAGE = 'BOOKMARKS_MESSAGE';
// Bookmarks

// Recording Data
export const TEMPLATES_MESSAGE = 'TEMPLATES_MESSAGE';

// Video Retention
export const VIDEO_RETENTION_MESSAGE = 'VIDEO_RETENTION_MESSAGE';
export const ERROR_GETTING_RECORDING_SETTINGS_MESSAGE =
  'ERROR_GETTING_RECODING_SETTINGS_MESSAGE';

// Organizations
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
