import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function isSavingReducer(state, action = {}) {
  switch (action.type) {
    case types.IS_SAVING: {
      return {
        ...state,
        [action.objectType]: action.value,
      };
    }
    default:
      return state || initialState().isSaving;
  }
}
