import fetch from 'isomorphic-fetch';

// Actions

// Utilities
import urlBuilder from 'queryBuilder/url';
import handleErrors from 'util/handleErrors';
import handleJsonErrors from 'util/handleJsonErrors';
import logTimeStamp from 'util/logTimeStamp';
import {
  runGetActionReturningJSON,
  sendDeleteRequest,
  sendDeleteRequestReturningJSON,
  sendGetRequestReturningJSON,
  sendPostRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';

// Constants
import * as types from 'constants/ActionTypes';
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import * as toggleTypes from 'constants/ToggleTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import * as findCameraConst from 'components/FindCameraForm/constant';
import { sendTypes as ServerDetailSendTypes } from 'components/ServerDetail/constants';

import { isFetching, isFetchingData } from './common';
import { showMessage } from './pageMessage';
import { hideModal, showModal } from './modal';
import { revertToggle } from './toggle';

export * from './devices/audio';
export * from './devices/cameraSettings';
export * from './devices/entities';
export * from './devices/links';
export * from './devices/ptz';

export function receiveDevices(data) {
  return {
    devices: data,
    type: types.RECEIVE_DEVICES,
  };
}

export function receiveSitesWithUpgrades(sitesWithUpgrades) {
  return {
    sitesWithUpgrades: sitesWithUpgrades.Items,
    type: types.IS_RECEIVING_SITEIDSWITHUPGRADES,
  };
}

export function receiveCameras(data) {
  return {
    cameras: data,
    type: types.RECEIVE_CAMERAS,
  };
}

export function receivePoe(deviceId, poe) {
  return {
    deviceId,
    poe,
    type: types.RECEIVE_DEVICE_POE,
  };
}

export function receiveSnapshotURL(data) {
  return {
    cameraId: data.cameraId,
    type: types.RECEIVE_SNAPSHOT_URL,
    uri: data.uri,
  };
}

export function receiveSnapshotSource(data) {
  return {
    cameraId: data.cameraId,
    image: data.params.image,
    isFetching: false,
    type: types.RECEIVE_SNAPSHOT_SOURCE,
  };
}

export function receiveMediaParams(data, mediaParamsId) {
  return {
    data,
    mediaParamsId,
    type: types.RECEIVE_MEDIA_PARAMS,
  };
}

export function receiveStreamData(data, streamName) {
  return {
    data,
    streamName,
    type: types.RECEIVE_STREAM_DATA,
  };
}

export function flagStreamUsed(data, streamName) {
  return {
    data,
    streamName,
    type: types.FLAG_STREAM_USED,
  };
}

export function setSelectedCamera(cameraId) {
  return {
    cameraId,
    type: types.RECEIVE_SELECTED_CAMERA,
  };
}

export function setSelectedServer(serverId) {
  return {
    serverId,
    type: types.RECEIVE_SELECTED_SERVER,
  };
}

export function receiveLatestFirmware(data) {
  return {
    firmwareImage: data,
    type: types.RECEIVE_LATEST_FIRMWARE,
  };
}

export function getAllDevices(queryOptions) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.GET_DEVICES, 0, 0, queryOptions);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json) {
          dispatch(receiveDevices(json));
        }
      })
      .catch(ex => {
        avoLogError('Error getting all device data', ex);
      })
      .then(() => dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA, false)));
  };
}
export function getSitesWithUpgrades(queryOptions) {
  return dispatch => {
    dispatch(isFetchingData(types.GET_UPGRADEABLE_DEVICES));
    const url = urlBuilder(types.GET_UPGRADEABLE_DEVICES, 0, 0, queryOptions);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json) {
          dispatch(receiveSitesWithUpgrades(json));
        }
      })
      .catch(ex => {
        avoLogError('Error getting sites with upgrades', ex);
      })
      .then(() =>
        dispatch(isFetchingData(types.GET_UPGRADEABLE_DEVICES, false)),
      );
  };
}

export function receiveLocationDevices(locationDevices) {
  return dispatch => {
    dispatch({
      locationDevices,
      type: types.RECEIVE_LOCATION_DEVICES,
    });
  };
}

export function getLocationDevices(locationId) {
  const filtersWithLocationDelimiter = [
    {
      field: 'SiteId',
      typeOfValue: 'guid',
      values: [locationId],
    },
  ];
  return dispatch => {
    dispatch(
      isFetching(types.IS_FETCHING_LOCATION_DEVICES, true, { locationId }),
    );
    const url = urlBuilder(types.GET_DEVICES, 0, 0, {
      filters: filtersWithLocationDelimiter,
    });
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json) {
          dispatch(receiveLocationDevices(json));
        }
      })
      .catch(ex => {
        avoLogError('Error getting location devices', ex);
      })
      .then(() =>
        dispatch(
          isFetching(types.IS_FETCHING_LOCATION_DEVICES, false, { locationId }),
        ),
      );
  };
}

export function isFetchingDeviceSnapshots(deviceId, value = true) {
  return dispatch => {
    dispatch(
      isFetching(types.IS_FETCHING_DEVICE_SNAPSHOTS, value, { deviceId }),
    );
  };
}

export function getAllServersAndCameras(orgId) {
  return dispatch => {
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_ALL_SERVERS_AND_CAMERAS,
      onError: err => {
        avoLogError('Error getting data for devices table.');
      },
      onSuccess: json => {
        dispatch({
          serversAndCameras: json.Items,
          type: types.RECEIVE_ALL_SERVERS_AND_CAMERAS,
        });
      },
      url: urlBuilder(types.GET_ALL_SERVERS_AND_CAMERAS, null, null, null, {
        orgId,
      }),
    });
  };
}

export function receiveAllServersAndCameras(serversAndCameras) {
  dispatch({
    serversAndCameras,
    type: types.RECEIVE_ALL_SERVERS_AND_CAMERAS,
  });
}

export function getPoe(deviceId, orgId) {
  return dispatch => {
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GATEWAY_DEVICE_POE,
      onError: err => {
        dispatch(
          showMessage(messageTypes.POE_GET_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'POE.POE_NOT_FOUND',
            translateHeader: 'POE.POE_FETCH_ERROR',
          }),
        );
        avoLogError('Error getting POE', err);
      },
      onSuccess: json => {
        dispatch(receivePoe(deviceId, json));
      },
      url: urlBuilder(types.GATEWAY_DEVICE_POE_INFO, deviceId, null, null, {
        orgId,
      }),
    });
  };
}

export function updatePoe(deviceId, orgId, data = []) {
  return dispatch => {
    const url = urlBuilder(types.GATEWAY_DEVICE_POE, deviceId, null, null);
    const body = { ports: data };
    sendPutRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.POE_UPDATE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'POE.POE_UPDATE_SUCESS',
            translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
          }),
        );
        dispatch(getPoe(deviceId, orgId));
      })
      .catch(error => {
        avoLogError('Error updating POE', error);
        dispatch(
          showMessage(messageTypes.POE_UPDATE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'POE.POE_NOT_FOUND',
            translateHeader: 'GENERAL_MESSAGES.ERROR_HEADER',
          }),
        );
      });
  };
}

export function clearLocationDevicesAndDeviceCameras() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_LOCATION_DEVICES,
    });
  };
}

export function getAllCameras(queryOptions, orgId) {
  // This should be used only without query options, so that it actually gets ALL cameras
  // To get a subset of cameras, use getCameras, below
  return dispatch => {
    const url = urlBuilder(types.GET_ALL_CAMERAS, 0, 0, queryOptions, {
      orgId,
    });
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_ALL_CAMERAS,
      onError: err => {
        avoLogError('Error getting all cameras', err);
      },
      onSuccess: json => {
        dispatch(receiveCameras(json));
      },
      url,
    });
  };
}

export function getCameras(scope, orgId) {
  // Valid scope fields include LocationId, DeviceId
  return dispatch => {
    if (!scope) {
      dispatch(getAllCameras(null, orgId));
      return;
    }
    const { field, value } = scope;
    const url = urlBuilder(
      types.GET_CAMERAS,
      0,
      0,
      {
        filters: [
          {
            field,
            typeOfValue: 'guid',
            values: [value],
          },
        ],
      },
      { orgId },
    );
    runGetActionReturningJSON({
      dispatch,
      fetchScope: value,
      fetchType: types.GET_CAMERAS,
      onError: err => {
        avoLogError('Error getting cameras', err);
      },
      onSuccess: json => {
        dispatch(receiveCameras(json));
      },
      url,
    });
  };
}

export function removeFromConnectedCameras(id) {
  return {
    cameraId: id,
    type: types.REMOVE_FROM_CONNECTED_CAMERA,
  };
}

export function removeDevice(id) {
  return {
    deviceId: id,
    type: types.REMOVE_DEVICE,
  };
}

export function getCamera(id) {
  return dispatch => {
    const url = urlBuilder(types.GET_CAMERA, id);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveCameras([json]));
      })
      .catch(ex => {
        avoLogError('Error getting camera', { ex, id });
      });
  };
}

export function snapshotRequestCompleted() {
  return {
    type: types.SNAPSHOT_REQUEST_COMPLETED,
  };
}

function getSnapshotCompleted(id, _dispatch, json) {
  const uri = json.result;
  const data = {
    cameraId: id,
    uri,
  };
  _dispatch(receiveSnapshotURL(data));
}

export function flagStaleSnapshotURL(cameraId, value = true) {
  return {
    cameraId,
    type: types.FLAG_STALE_SNAPSHOT_URL,
    value,
  };
}

export function getSnapshotURL(deviceId, cameraId) {
  return dispatch => {
    const url = urlBuilder(types.GET_SNAPSHOT_URL, deviceId, cameraId);
    const bindGetSnapshotCompleted = getSnapshotCompleted.bind(this, cameraId);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        bindGetSnapshotCompleted(dispatch, json);
      })
      .catch(() => {})
      .then(() => dispatch(flagStaleSnapshotURL(cameraId, false)));
  };
}

export function clearSnapshotSource(cameraId) {
  return {
    cameraId,
    type: types.CLEAR_SNAPSHOT_SOURCE,
  };
}

export function downloadSnapshot(
  deviceId,
  cameraId,
  cameraRemoteId,
  format,
  startTime,
  size,
  media,
  quality,
  frames,
) {
  return dispatch => {
    dispatch(clearSnapshotSource(cameraId));
    dispatch(
      isFetching(types.IS_FETCHING_SNAPSHOT_SOURCE, true, {
        cameraId,
      }),
    );
    const url = urlBuilder(
      types.GET_SNAPSHOT_SOURCE,
      deviceId,
      cameraRemoteId,
      {
        format,
        frames,
        media,
        quality,
        size,
        startTime,
      },
    );
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json.errorMessage) {
          throw new Error(`Server Error:  ${json.errorMessage}`);
        } else if (!json || json === null || json === {}) {
          throw new Error('Server Error: json empty');
        } else {
          const data = {
            cameraId,
            params: json,
          };
          dispatch(receiveSnapshotSource(data));
        }
      })
      .catch(ex => {
        avoLogError(`[${logTimeStamp()}] ${ex.message}`, ex);
        dispatch(
          isFetching(types.IS_FETCHING_SNAPSHOT_SOURCE, false, {
            cameraId,
          }),
        );
      });
  };
}

export function getMediaParams(
  deviceId,
  cameraId,
  format,
  mediaQuality,
  startTime,
  mediaParamsId,
  search,
  orgId,
) {
  return dispatch => {
    dispatch(
      isFetching(types.IS_FETCHING_MEDIA_PARAMS, true, { mediaParamsId }),
    );
    const url = urlBuilder(types.GET_MEDIA_PARAMS, deviceId, cameraId, null, {
      orgId,
    });
    const body = {
      cameraId,
      media: format || 'video',
      quality: mediaQuality || 'low',
    };
    if (startTime) body.t = startTime;
    if (search) body.search = search;
    // TODO: MVAAS-13280: This is related to EVO player and will be deprecated in 2.16.
    return fetch(url, {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(response => {
        if (response.status >= 400) {
          throw new Error(
            `Server Error: ${response.status} (${response.statusText})`,
          );
        } else {
          return response.json();
        }
      })
      .then(json => {
        if (json.errorMessage) {
          throw new Error(`Server Error:  ${json.errorMessage}`);
        } else if (!json || json === null || json === {}) {
          throw new Error('Server Error: json empty');
        } else {
          const data = {
            cameraId,
            mediaQuality,
            params: json,
            startTime,
            timestamp: Date.now(),
          };
          dispatch(receiveMediaParams(data, mediaParamsId));
        }
      })
      .catch(ex => {
        avoLogError(`[${logTimeStamp()}] ${ex.message}`, ex);
        if (ex.message.includes('Server Error')) {
          dispatch(
            receiveMediaParams({ errorMessage: ex.message }, mediaParamsId),
          );
          /* On a server error, clear current media params */
        }
      });
  };
}

export function receiveRecordingTimeline(data, scope) {
  return {
    cameraId: data.cameraId,
    motion: data.motion,
    record: data.record,
    scope,
    type: types.RECEIVE_RECORDING_TIMELINE,
  };
}

export function getRecordingTimeline(
  deviceId,
  cameraId,
  start,
  end,
  scope,
  orgId,
) {
  return dispatch => {
    dispatch(
      isFetching(types.IS_FETCHING_RECORDING_TIMELINE, true, { cameraId }),
    );
    const queryParams = {
      end,
      start,
    };
    if (scope) {
      queryParams.scope = scope;
    }
    const url = urlBuilder(
      types.GET_RECORDING_TIMELINE,
      deviceId,
      cameraId,
      queryParams,
      { orgId },
    );

    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json.errorMessage) {
          throw new Error(`Server Error:  ${json.errorMessage}`);
        } else if (!json || json === null || json === {}) {
          throw new Error('Server Error: json empty');
        } else if (!json.timelines || json.timelines.length <= 0) {
          throw new Error('No recording timeline found for this camera');
        } else {
          dispatch(receiveRecordingTimeline(json.timelines[0], scope));
        }
      })
      .catch(ex => {
        dispatch(
          isFetching(types.IS_FETCHING_RECORDING_TIMELINE, false, { cameraId }),
        );
        avoLogError(`[${logTimeStamp()}] ${ex.message}`, ex);
      });
  };
}

export function receiveDevice(device) {
  return {
    device,
    type: types.RECEIVE_DEVICE,
  };
}

export function getDevice(id, orgId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(
      types.GET_DEVICE,
      0,
      0,
      {
        filters: [
          {
            field: 'Id',
            typeOfValue: 'guid',
            values: [id],
          },
        ],
      },
      { orgId },
    );
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveDevice(json.Items)))
      .catch(ex => {
        avoLogError('Error getting device', { ex, id });
      })
      .then(() => {
        dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA, false));
      });
  };
}

export function claimDeviceCompleted(id, _dispatch) {
  _dispatch({
    deviceId: id,
    type: types.CLAIM_DEVICE_SUCCESS,
  });
  _dispatch(
    showMessage(messageTypes.CLAIM_SUCCESS, null, null, {
      messageStyle: messageStyleStrings.success,
      translateBody: 'DEVICES.DEVICE_CLAIM.DEVICE_CLAIM_SUCCESS',
    }),
  );
}

export function addCameraGroupCompleted(id, _dispatch) {
  // show modal.  modal contains link to redirect
  _dispatch(showModal(modalTypes.ADD_CAMERA_GROUP_SUCCESS, { redirectTo: id }));
}

// used by user already logged in, provided with ClaimingKey
export function claimDevice(formData) {
  const url = urlBuilder(types.CLAIM_DEVICE, null, null, null, {
    claimKey: formData.ClaimingKey,
    locationId: formData.ParentId,
  });
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    return sendPostRequestReturningJSON(url, {})
      .then(() => {
        claimDeviceCompleted(formData.ServerId, dispatch);
      })
      .catch(error => {
        dispatch(
          showMessage(messageTypes.CLAIM_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'DEVICES.DEVICE_CLAIM.DEVICE_CLAIM_ERROR',
          }),
        );
        avoLogError('Error claiming device', error);
      })
      .then(() => {
        dispatch(isFetching(types.DEVICE_FETCH_COMPLETED));
      });
  };
}

// internal user
export function claimDeviceById(formData) {
  const url = urlBuilder(
    types.CLAIM_DEVICE_BY_ID,
    formData.ServerId,
    null,
    null,
    { locationId: formData.ParentId },
  );
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    return sendPostRequestReturningJSON(url, {})
      .then(() => {
        claimDeviceCompleted(formData.ServerId, dispatch);
      })
      .catch(error => {
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'DEVICES.DEVICE_CLAIM.DEVICE_CLAIM_ERROR',
          }),
        );
        avoLogError('Error claiming device by id', error);
      })
      .then(() => {
        dispatch(isFetching(types.DEVICE_FETCH_COMPLETED));
      });
  };
}

export function receiveClaimKey(ServerId, ClaimKey) {
  return {
    data: {
      claimingkey: ClaimKey,
      serverId: ServerId,
    },
    type: types.RECEIVE_CLAIM_KEY,
  };
}

export function receiveClaimError(error) {
  return {
    error,
    type: types.RECEIVE_CLAIM_ERROR,
  };
}

// called by external user, both authenticated and un-auth
export function createClaimKey(serverId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.CREATE_CLAIM_KEY, serverId);
    const body = {
      serverId,
    };
    // api shouldnt require authentication, but include credentials so that cookie set in response
    return sendPostRequestReturningJSON(url, body)
      .then(json => dispatch(receiveClaimKey(serverId, json)))
      .catch(ex => {
        avoLogError('Error getting claim key', ex);
        dispatch(isFetching(types.DEVICE_FETCH_COMPLETED));
        dispatch(
          receiveClaimError({
            message: ex.message,
            status: ex.status,
          }),
        );
      });
  };
}

export function receiveActivationCode(deviceActivationCode) {
  return {
    deviceActivationCode,
    type: types.RECEIVE_ACTIVATION_CODE,
  };
}

export function getActivationCode(locationId, serverFamily) {
  return dispatch => {
    dispatch(
      isFetchingData(types.GET_ACTIVATION_CODE, true, {
        fetchScope: locationId,
      }),
    );
    const url = urlBuilder(types.GET_ACTIVATION_CODE, locationId, 0, {
      other: [{ key: 'serverFamily', value: serverFamily }],
    });

    sendPostRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveActivationCode(json.ActivationCode));
      })
      .catch(err => {
        avoLogError('Error getting activation code', err);
      })
      .finally(() => {
        dispatch(
          isFetchingData(types.GET_ACTIVATION_CODE, false, {
            fetchScope: locationId,
          }),
        );
      });
  };
}

export function receiveUsers(users, id) {
  return {
    cameraGroupId: id,
    type: types.RECEIVE_USERS,
    users: users || [],
  };
}

export function receiveUsersCompleted(id, _dispatch, json) {
  _dispatch(receiveUsers(json, id));
}

export function getCameraGroupUsers(locationId) {
  const bindGetCameraGroupUsersCompleted = receiveUsersCompleted.bind(
    this,
    locationId,
  );
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.GET_USERS, locationId);
    return sendGetRequestReturningJSON(url)
      .then(() => {
        dispatch(isFetching(types.DEVICE_FETCH_COMPLETED));
      })
      .then(json => bindGetCameraGroupUsersCompleted(dispatch, json))
      .catch(() => {
        dispatch(
          showModal(modalTypes.SHOW_ERROR, {
            translatedMessage: 'GENERIC_ACTION_MESSAGES.FETCH_ERROR',
          }),
        );
        dispatch(isFetching(types.DEVICE_FETCH_COMPLETED));
      });
  };
}

export function deleteDevice(deviceId) {
  return dispatch => {
    const url = urlBuilder(types.DELETE_DEVICE, deviceId);
    sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(hideModal());
        dispatch(getAllDevices());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, ex.message, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
      });
  };
}

export function setServerBreadcrumb(server) {
  return {
    server,
    type: types.SET_SERVER_BREADCRUMB,
  };
}

export function unSetServerBreadcrumb() {
  return {
    type: types.UNSET_SERVER_BREADCRUMB,
  };
}

// Server

export function receiveServerCapabilitiesList(response) {
  return {
    serverCapabilities: response.capabilities,
    type: types.RECEIVE_SERVER_CAPABILITIES,
  };
}

export function getServerCapabilities(serverId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_SERVER_CAPABILITIES));
    const url = urlBuilder(types.GET_SERVER_CAPABILITIES, serverId);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveServerCapabilitiesList(json)))
      .catch(ex => {
        dispatch(isFetching(types.IS_FETCHING_SERVER_CAPABILITIES, false));
        avoLogError('Error getting server capabilities', ex);
      });
  };
}

export function receiveSupportedCameraList(cameras) {
  return {
    cameras,
    type: types.RECEIVE_SUPPORTED_CAMERAS,
  };
}

export function getSupportedCameraList(serverId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_SUPPORTED_CAMERAS));
    const url = urlBuilder(types.GET_SUPPORTED_CAMERAS, serverId);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveSupportedCameraList(json)))
      .catch(ex => {
        dispatch(isFetching(types.IS_FETCHING_SUPPORTED_CAMERAS, false));
        avoLogError('Error getting supported cameras', ex);
      });
  };
}

export function receiveDiscoveredCameras(cameras) {
  return {
    cameras,
    type: types.RECEIVE_DISCOVERED_CAMERAS,
  };
}

export function getDiscoveredCameras(serverId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DISCOVERED_CAMERAS));
    const url = urlBuilder(types.GET_DISCOVERED_CAMERAS, serverId);
    return sendGetRequestReturningJSON(url).catch(ex => {
      // Only need a catch, as there is no followup action if the request goes through.
      // SignalR will send a resultId which is handled in getDiscoveredCamerasResults below.
      dispatch(receiveDiscoveredCameras([]));
      avoLogError('Error getting discovered cameras', ex);
    });
  };
}

export function showMessageOnDiscoveredCameraResults() {
  return dispatch => {
    dispatch(
      showMessage(
        messageTypes.FETCHING_DISCOVERED_CAMERA_RESULT,
        null,
        null,
        {},
      ),
    );
  };
}

export function getDiscoveredCameraResults(
  serverId,
  resultId,
  isTriggeredByManualSearch,
) {
  return dispatch => {
    if (isTriggeredByManualSearch) {
      dispatch(
        showMessage(
          messageTypes.FETCHING_DISCOVERED_CAMERA_RESULT,
          null,
          null,
          {},
        ),
      );
    }

    const url = urlBuilder(
      types.GET_DISCOVERED_CAMERAS_RESULT,
      resultId,
      serverId,
    );
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveDiscoveredCameras(json.cameras)))
      .catch(ex => {
        dispatch(receiveDiscoveredCameras([]));
        dispatch(
          showMessage(messageTypes.DISCOVERED_CAMERAS_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERIC_ACTION_MESSAGES.FETCH_ERROR',
          }),
        );
        avoLogError('Discovered Camera Request Field', ex);
      });
  };
}

export function connectCamera(
  serverId,
  cameraId,
  userName,
  password,
  model,
  msgArgs,
) {
  return dispatch => {
    const url = urlBuilder(types.CONNECT_CAMERA, serverId, cameraId);

    const body = {
      CameraName: msgArgs.cameraName,
      ConfirmPassword: msgArgs.factoryDefault ? password : undefined,
      Initialize: msgArgs.factoryDefault,
      IpAddress: msgArgs.ipAddress,
      Manufacturer: msgArgs.manufacturer,
      Model: model,
      Password: password,
      PhysicalAddress: msgArgs.physicalAddress,
      Uri: msgArgs.uri,
      Username: userName,
      cameraId,
      id: serverId,
    };

    if (userName.length > 0) {
      body.Username = userName;
      body.Password = password;
    }

    return sendPostRequestReturningJSON(url, body)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        }
        dispatch(
          showMessage(messageTypes.CONNECT_DISCONNECT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CONNECT_CAMERA_SUCCESS',
            translateBodyData: {
              cameraName: msgArgs.cameraName,
              deviceName: msgArgs.deviceName,
            },
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(ex => {
        dispatch(
          showMessage(
            messageTypes.CONNECT_DISCONNECT_ERROR,
            ex.serverMessage,
            null,
            {
              messageStyle: messageStyleStrings.error,
            },
          ),
        );
      });
  };
}

export function disconnectCamera(
  serverId,
  cameraId,
  serverConnectionState,
  msgArgs,
) {
  return dispatch => {
    const url = urlBuilder(types.UNMANAGE_CAMERA, serverId, cameraId);
    const messageText =
      serverConnectionState === CAMERA_CONNECTED
        ? 'CAMERA.SETTINGS.ACTIONS.DISCONNECT_CAMERA_SUCCESS'
        : 'CAMERA.SETTINGS.ACTIONS.DISCONNECT_DEVICE_CAMERA_SUCCESS';
    return sendDeleteRequest(url)
      .then(handleErrors)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CONNECT_DISCONNECT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: messageText,
            translateBodyData: {
              cameraName: msgArgs.cameraName,
              deviceName: msgArgs.deviceName,
            },
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(error => {
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.DISCONNECT_CAMERA_ERROR',
            translateBodyData:
              error.result || error.ExceptionMessage || error.message || error,
          }),
        );
      });
  };
}

export function clearCameras() {
  // Clears Connected and Discovered cameras from redux store
  return {
    type: types.CLEAR_CAMERAS,
  };
}

export function setDiscoveredCameraCredentials(credentials) {
  return {
    credentials,
    type: types.UPDATE_DISCOVERED_CAMERAS_CREDENTIALS,
  };
}

export function setFDSPasswordReset(serverId, cameraId, credentials) {
  return {
    cameraIdentifier: cameraId,
    credentials,
    serverId,
    type: types.FDS_PASSWORD_RESET,
  };
}

export function showMessageOnDiscoveredCameraManualSearch() {
  return dispatch => {
    dispatch(
      showMessage(
        messageTypes.FETCHING_DISCOVERED_CAMERA_MANUALLY,
        null,
        null,
        {},
      ),
    );
  };
}

export function manuallyDiscoverCamera(serverId, formData, actionType) {
  // actionType is one of types.MANUALLY_DISCOVER_CAMERA,
  // types.MANUALLY_DISCOVER_RTSP_CAMERA, or types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE
  return dispatch => {
    dispatch(
      showMessage(
        messageTypes.FETCHING_DISCOVERED_CAMERA_MANUALLY,
        null,
        null,
        {},
      ),
    );
    const url = urlBuilder(actionType, serverId);
    const body = formData;
    const {
      address: ipAddressKey,
      endIpAddress: endIpAddressKey,
      password: passwordKey,
      startIpAddress: startIpAddressKey,
      urlStream: uriKey,
      username: usernameKey,
    } = findCameraConst.field.VALUE;

    if (formData.username || formData.password) {
      const store = {
        actionType,
        [passwordKey]: formData[passwordKey],
        [usernameKey]: formData[usernameKey],
      };

      switch (actionType) {
        case types.MANUALLY_DISCOVER_CAMERA:
          store[ipAddressKey] = formData[ipAddressKey];
          break;
        case types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE:
          store[startIpAddressKey] = formData[startIpAddressKey];
          store[endIpAddressKey] = formData[endIpAddressKey];
          break;
        case types.MANUALLY_DISCOVER_RTSP_CAMERA:
          store[uriKey] = formData[uriKey];
          break;
        default:
          break;
      }

      dispatch(setDiscoveredCameraCredentials(store));
    }
    return sendPostRequestReturningJSON(url, body).catch(() => {
      dispatch(
        showMessage(messageTypes.CONNECT_DISCONNECT_ERROR, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'GENERIC_ACTION_MESSAGES.FETCH_ERROR',
        }),
      );
    });
  };
}

export function updateServerInfo(serverId, name, timeZone) {
  return dispatch => {
    const requests = [];
    let sendType = ServerDetailSendTypes.NONE;

    if (name !== null) {
      const nameUrl = urlBuilder(types.UPDATE_SERVER_NAME, serverId);
      const nameBody = { name };
      const nameRequest = sendPutRequestReturningJSON(nameUrl, nameBody);
      requests.push(nameRequest);

      sendType = ServerDetailSendTypes.NAME;
    }
    if (timeZone !== null) {
      const timeZoneUrl = urlBuilder(types.UPDATE_SERVER_TIME_ZONE, serverId);
      const timeZoneBody = { timeZone };
      const timeZoneRequest = sendPutRequestReturningJSON(
        timeZoneUrl,
        timeZoneBody,
      );
      requests.push(timeZoneRequest);

      if (sendType === ServerDetailSendTypes.NONE) {
        sendType = ServerDetailSendTypes.TIME_ZONE;
      } else {
        sendType = ServerDetailSendTypes.ALL;
      }
    }

    Promise.all(requests).then(responses => {
      const errorMessage = responses.find(res => res.errorMessage);
      if (errorMessage) {
        if (
          sendType === ServerDetailSendTypes.ALL ||
          sendType === ServerDetailSendTypes.NAME
        ) {
          dispatch(
            showMessage(messageTypes.DEVICE_ERROR, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'DEVICE_DETAILS.GENERAL_TAB.UPDATE_SERVER_ERROR_MESSAGE',
              translateBodyData: {
                errorMessage,
                serverName: name,
              },
            }),
          );
        } else {
          dispatch(
            showMessage(messageTypes.DEVICE_ERROR, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'DEVICE_DETAILS.GENERAL_TAB.UPDATE_SERVER_TIME_ZONE_ERROR_MESSAGE',
              translateBodyData: {
                errorMessage,
              },
            }),
          );
        }
      } else {
        dispatch(
          showMessage(messageTypes.DEVICE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'DEVICE_DETAILS.GENERAL_TAB.UPDATE_SERVER_SUCCESS_MESSAGE',
          }),
        );
      }
    });
  };
}

export function cameraZoom(deviceId, cameraId, step, relative = true) {
  return dispatch => {
    const url = urlBuilder(types.UPDATE_CAMERA_ZOOM, deviceId);
    const body = {
      amount: step,
      id: cameraId,
      relative,
    };
    return sendPutRequestReturningJSON(url, body).catch(ex => {
      avoLogError('Error updating camera zoom', ex);
    });
  };
}

export function cameraFocus(deviceId, cameraId, step) {
  return dispatch => {
    const url = urlBuilder(types.UPDATE_CAMERA_FOCUS, deviceId);
    const body = { amount: step, id: cameraId };
    return sendPutRequestReturningJSON(url, body).catch(ex => {
      avoLogError('Error updating camera focus', {
        cameraId,
        deviceId,
        ex,
        step,
      });
    });
  };
}

export function cameraAutofocusGateway(deviceId, cameraId) {
  return dispatch => {
    const url = urlBuilder(types.UPDATE_CAMERA_AUTOFOCUS, deviceId);
    const body = { id: cameraId };
    return sendPutRequestReturningJSON(url, body).catch(ex => {
      avoLogError('Error auto focusing', ex);
    });
  };
}

export function enableCamera(cameraId) {
  return dispatch => {
    const url = urlBuilder(types.ENABLE_CAMERA, cameraId);
    sendPutRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_ENABLE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_ENABLE_SUCCESS',
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CAMERA_ENABLE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_ENABLE_ERROR',
            translateBodyData: {
              error: ex.message,
            },
          }),
        );
      });
  };
}

export function disableCamera(cameraId) {
  return dispatch => {
    const url = urlBuilder(types.DISABLE_CAMERA, cameraId);
    sendPutRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISABLE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_DISABLE_SUCCESS',
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISABLE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_DISABLE_ERROR',
            translateBodyData: {
              error: ex.message,
            },
          }),
        );
      });
  };
}

export function firmwareUpgradeProgress(id, status, progress, speed) {
  return {
    id,
    progress,
    speed,
    status,
    type: types.UPGRADE_DEVICE_PROGRESS,
  };
}

export function firmwareUpgrade(deviceId, msgArgs) {
  return dispatch => {
    dispatch(firmwareUpgradeProgress(deviceId, 'BEGIN_UPGRADE', 0));
    const url = urlBuilder(types.UPGRADE_DEVICE_FIRMWARE, deviceId);
    return sendPutRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.DEVICE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'DEVICE_DETAILS.GENERAL_TAB.DEVICE_FIRMWARE_UPGRADE_SUCCESS_MESSAGE',
            translateBodyData: { deviceName: msgArgs.deviceName },
          }),
        );
      })
      .catch(ex => {
        dispatch(firmwareUpgradeProgress(deviceId, null, null));
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'DEVICE_DETAILS.GENERAL_TAB.DEVICE_FIRMWARE_UPGRADE_ERROR_MESSAGE',
            translateBodyData: { error: ex.message },
          }),
        );
      });
  };
}

export function getLatestFirmware(deviceId, platform) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_LATEST_FIRMWARE, true));
    const url = urlBuilder(types.GET_LATEST_FIRMWARE, deviceId, null, null, {
      platform,
    });
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveLatestFirmware(json)))
      .catch(ex => {
        dispatch(isFetching(types.IS_FETCHING_LATEST_FIRMWARE, false));
        avoLogError('Error getting latest firmware', ex);
      });
  };
}

export function requestDeviceLogs(deviceId, logType = null) {
  // logType=null means get all logs; for now that is the only option
  const correlationId = deviceId;
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_LOGS, true, { id: deviceId }));
    const url = urlBuilder(types.REQUEST_DEVICE_LOGS, deviceId);
    sendPostRequestReturningJSON(
      url,
      {},
      {},
      { 'correlation-id': correlationId },
    ).catch(ex => {
      dispatch(
        showMessage(messageTypes.DEVICE_ERROR, ex.message, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'CAMERA.LOG_ERROR',
        }),
      );
      dispatch(
        isFetching(types.IS_FETCHING_DEVICE_LOGS, false, {
          error: ex.message,
          id: deviceId,
        }),
      );
      avoLogError('Error requesting device logs', ex);
    });
  };
}

export function receiveDeviceLogs(deviceId, url) {
  return {
    id: deviceId,
    type: types.RECEIVE_DEVICE_LOGS,
    uri: url,
  };
}

export function getDeviceLogs(deviceId, logId) {
  return dispatch => {
    const url = urlBuilder(types.GET_DEVICE_LOGS, deviceId, null, null, {
      logId,
    });
    // Note that we don't want to access this url yet, just build it.
    dispatch(receiveDeviceLogs(deviceId, url));
    dispatch(showModal(modalTypes.DOWNLOAD_LOGS, { url }));
  };
}

export function restartAppliance(deviceId, msgArgs) {
  return dispatch => {
    const url = urlBuilder(types.RESTART_APPLIANCE, deviceId);
    return sendPostRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.DEVICE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'DEVICE_DETAILS.GENERAL_TAB.RESTART_APPLIANCE_SUCCESS_MESSAGE',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'DEVICE_DETAILS.GENERAL_TAB.RESTART_APPLIANCE_ERROR_MESSAGE',
            translateBodyData: { error: ex.message },
          }),
        );
      });
  };
}

export function cameraRestart(deviceId, cameraId, cameraName) {
  return dispatch => {
    const url = urlBuilder(types.RESTART_CAMERA, deviceId);
    const body = { id: cameraId };
    return sendPutRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.CAMERA_RESTART_SUCCESS',
            translateBodyData: { cameraName },
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error restarting camera', ex);
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, ex.message, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.CAMERA_RESTART_ERROR',
            translateHeaderData: { cameraName },
          }),
        );
      });
  };
}

export function refreshSnapshot(deviceId, cameraId, cameraName) {
  const url = urlBuilder(types.REFRESH_SNAPSHOT, deviceId, cameraId);
  return dispatch => {
    const body = {
      CameraId: cameraId,
      Id: deviceId,
    };
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.GENERAL.SNAPSHOT_REFRESH_SUCCESS',
            translateBodyData: { cameraName },
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error refreshing snapshot', ex);
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.GENERAL.SNAPSHOT_REFRESH_ERROR',
            translateBodyData: { cameraName },
          }),
        );
      });
  };
}

export function receiveAnalyticsStatus(json, cameraId) {
  return {
    id: cameraId,
    status: json.enabled,
    type: types.RECEIVE_ANALYTICS_STATUS,
  };
}

export function getAnalyticsStatus(deviceId, cameraRemoteId, cameraId) {
  return dispatch => {
    const url = urlBuilder(
      types.GET_ANALYTICS_STATUS,
      deviceId,
      cameraRemoteId,
    );
    sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveAnalyticsStatus(json, cameraId)))
      .catch(ex => {
        avoLogError('Error getting analytics status', {
          cameraId,
          cameraRemoteId,
          deviceId,
          ex,
        });
      });
  };
}

export function enableDisableAnalytics(
  deviceId,
  cameraRemoteId,
  enable,
  cameraId = null,
) {
  return dispatch => {
    const url = urlBuilder(
      types.UPDATE_ANALYTICS_STATUS,
      deviceId,
      cameraRemoteId,
    );
    const body = { cameraId: cameraRemoteId, enable };
    sendPutRequestReturningJSON(url, body)
      .then(() => {
        if (cameraId) {
          dispatch(getAnalyticsStatus(deviceId, cameraRemoteId, cameraId));
        }
        dispatch(
          showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: enable
              ? 'CAMERA.SETTINGS.ACTIONS.ENABLE_ANALYTICS_SUCCESS'
              : 'CAMERA.SETTINGS.ACTIONS.DISABLE_ANALYTICS_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        dispatch(revertToggle(toggleTypes.TOGGLE_CAMERA_ANALYTICS));
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.TOGGLE_ANALYTICS_ERROR',
          }),
        );
        avoLogError('Error toggling analytics', ex);
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: enable
              ? 'CAMERA.SETTINGS.ACTIONS.ENABLE_ANALYTICS_ERROR'
              : 'CAMERA.SETTINGS.ACTIONS.DISABLE_ANALYTICS_ERROR',
          }),
        );
      });
  };
}

export function enableDisableTamperEvents(cameraId, ignoreTamper) {
  return dispatch => {
    let url;
    if (ignoreTamper) {
      url = urlBuilder(types.ENABLE_TAMPER_EVENTS, cameraId);
      return sendPostRequestReturningJSON(url)
        .then(json => {
          if (json.isError) {
            throw handleJsonErrors(json);
          }
          dispatch(
            showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.ENABLE_TAMPER_EVENTS_SUCCESS',
            }),
          );
        })
        .catch(ex => {
          avoLogError('Error toggling tamper events', ex);
          dispatch(revertToggle(toggleTypes.TOGGLE_CAMERA_TAMPERING));
          dispatch(
            showMessage(messageTypes.GATEWAY_ERROR, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.ENABLE_TAMPER_EVENTS_ERROR',
            }),
          );
        });
    }
    url = urlBuilder(types.DISABLE_TAMPER_EVENTS, cameraId);

    return sendPostRequestReturningJSON(url)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        }
        dispatch(
          showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.DISABLE_TAMPER_EVENTS_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error toggling tamper events: ', ex);
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.DISABLE_TAMPER_EVENTS_ERROR',
          }),
        );
      });
  };
}

export function receiveVideoExport(serverId, cameraId, exportId, orgId) {
  return {
    data: {
      cameraId,
      exportId,
      orgId,
      serverId,
    },
    type: types.RECEIVE_CAMERA_VIDEO_EXPORT,
    videoExportId: exportId,
  };
}

export function videoExportRequest(
  serverId,
  cameraId,
  exportDirectives,
  orgId,
) {
  return dispatch => {
    const url = urlBuilder(
      types.CAMERA_VIDEO_EXPORT,
      serverId,
      cameraId,
      {},
      { orgId },
    );

    return sendPostRequestReturningJSON(url, exportDirectives)
      .then(json => {
        dispatch(receiveVideoExport(serverId, cameraId, json, orgId));
        dispatch(
          showMessage(messageTypes.CONNECT_DISCONNECT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.CONNECT_CAMERA_VIDEO_EXPORT_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CONNECT_DISCONNECT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CONNECT_CAMERA_ERROR',
          }),
        );
        avoLogError('Error exporting video', ex);
      });
  };
}

export function receiveVideoExportStream(dataStream, exportId) {
  return {
    type: types.RECEIVE_CAMERA_VIDEO_STREAM,
    videoExportId: exportId,
  };
}

export function videoExportGetStream(serverId, cameraId, exportId, orgId) {
  return dispatch => {
    const url = urlBuilder(
      types.CAMERA_VIDEO_GET_STREAM,
      serverId,
      cameraId,
      { exportId },
      { orgId },
    );

    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch(receiveVideoExportStream(response, exportId));
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'my_data.mp4');
        document.body.appendChild(link);
        link.click();
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CONNECT_DISCONNECT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CONNECT_CAMERA_ERROR',
          }),
        );
        avoLogError('Error getting video export stream', ex);
      });
  };
}

export function armCamera(cameraId) {
  return dispatch => {
    const url = urlBuilder(types.ARM_CAMERA, cameraId);
    sendPutRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_ARM_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_ARM_SUCCESS',
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CAMERA_ARM_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_ARM_ERROR',
            translateBodyData: {
              error: ex.message,
            },
          }),
        );
      });
  };
}

export function disarmCamera(cameraId, rearmUtc) {
  return dispatch => {
    const url = urlBuilder(types.DISARM_CAMERA, cameraId);
    const body = { RearmAtUtc: rearmUtc };
    sendPutRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISARM_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_DISARM_SUCCESS',
          }),
        );
        dispatch(getAllCameras());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISARM_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CAMERA.SETTINGS.ACTIONS.CAMERA_DISARM_ERROR',
            translateBodyData: {
              error: ex.message,
            },
          }),
        );
      });
  };
}

export function enableCameraFeature(cameraFeature, toggleAction) {
  const { FeatureFlags, cameraId, feature } = cameraFeature;

  return dispatch => {
    const url = urlBuilder(types.ENABLE_CAMERA_FEATURE, cameraId);
    const body = { FeatureFlags };
    sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_ENABLE_FEATURE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.CAMERA_ENABLE_FEATURE_SUCCESS',
            translateBodyData: {
              feature,
            },
          }),
        );
      })
      .catch(() => {
        if (toggleAction) {
          dispatch(revertToggle(toggleAction));
        }
        dispatch(
          showMessage(messageTypes.CAMERA_ENABLE_FEATURE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.CAMERA_ENABLE_FEATURE_ERROR',
            translateBodyData: {
              feature,
            },
          }),
        );
      });
  };
}

export function disableCameraFeature(cameraFeature) {
  const { FeatureFlags, cameraId, feature } = cameraFeature;

  return dispatch => {
    const url = urlBuilder(types.DISABLE_CAMERA_FEATURE, cameraId);
    const body = { FeatureFlags };
    sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISABLE_FEATURE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.CAMERA_DISABLE_FEATURE_SUCCESS',
            translateBodyData: {
              feature,
            },
          }),
        );
      })
      .catch(() => {
        dispatch(
          showMessage(messageTypes.CAMERA_DISABLE_FEATURE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.CAMERA_DISABLE_FEATURE_ERROR',
            translateBodyData: {
              feature,
            },
          }),
        );
      });
  };
}
