// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { PageMessage } from 'containers';
import { Button, GroupLayout } from 'lib';

// Styles
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { Translate } from 'react-localize-redux';
import { inputBox } from 'sharedStyles/styles.css';
import { pageError } from 'sharedStyles/modalForms.css';
import {
  cameraLoginFormWrapper,
  cameraLoginInputRow,
  cameraLoginLabel,
  cameraLoginTopRow,
  normalLabel,
} from './styles.css';

// Const
let errors = null;
const formLevelValidation = values => {
  errors = {};
  let invalidDataCombination = true;

  if (values.cameraUserName) {
    if (
      (values.cameraUserName.toString().trim().length === 0 &&
        !values.cameraPassword) ||
      (values.cameraUserName.toString().trim().length > 0 &&
        !values.cameraPassword) ||
      (values.cameraUserName.toString().trim().length > 0 &&
        values.cameraPassword)
    ) {
      invalidDataCombination = false;
    }
  } else if (!values.cameraUserName && !values.cameraPassword) {
    invalidDataCombination = false;
  }

  if (invalidDataCombination) {
    errors.summary = (
      <Translate id="DEVICES.DEVICE_LOGIN.CONNECT_CAMERA_MODAL_ERROR" />
    );
  }
  return errors;
};

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        body={error}
        messageStyle={messageStyleStrings.error}
        translateHeader="GENERAL_MESSAGES.MISSING_INFO_HEADER"
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.propTypes = {
  meta: PropTypes.shape({
    error: PropTypes.bool,
    touched: PropTypes.bool,
  }),
};

errorAggregator.defaultProps = {
  meta: {
    error: false,
    touched: false,
  },
};

const CameraLoginModal = props => {
  const { handleCancel, handleSubmit, onSubmit } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cameraLoginFormWrapper}>
        <div>
          <Field component={errorAggregator} name="summary" />
        </div>
        <div className={cameraLoginTopRow}>
          <label className={normalLabel}>
            <Translate id="CAMERA.LOGIN_MODAL.MODAL_DESCRIPTION" />
          </label>
        </div>
        <div className={cameraLoginInputRow}>
          <label className={cameraLoginLabel}>
            {' '}
            <Translate id="CAMERA.LOGIN_MODAL.MODAL_USER_NAME_LABEL" />{' '}
          </label>
          <Field
            autoComplete="off"
            className={inputBox}
            component="input"
            disabled={false}
            name="cameraUserName"
            spellCheck="false"
            type="text"
          />
        </div>
        <div className={cameraLoginInputRow}>
          <label className={cameraLoginLabel}>
            {' '}
            <Translate id="CAMERA.LOGIN_MODAL.MODAL_USER_PASSWORD_LABEL" />{' '}
          </label>
          <Field
            autoComplete="off"
            className={inputBox}
            component="input"
            disabled={false}
            name="cameraPassword"
            spellCheck="false"
            type="password"
          />
        </div>
        <GroupLayout verticalSpacing="large">
          <Button
            key="cameraLoginCancelButton"
            buttonType="secondary"
            id="cameraLoginCancelButton"
            inputType="button"
            onClick={handleCancel}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
          <Button
            key="cameraLoginSubmitButton"
            buttonType="primary"
            id="cameraLoginSubmitButton"
            inputType="button"
            onClick={handleSubmit(onSubmit)}
            text={<Translate id="BUTTONS.LOGIN" />}
          />
        </GroupLayout>
      </div>
    </form>
  );
};

CameraLoginModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    initialValues: {
      cameraPassword: '',
      cameraUserName: '',
    },
  };
}
const ReduxForm = reduxForm({
  enableReinitialize: true,
  form: 'CAMERA_LOGIN_FORM',
  validate: formLevelValidation,
})(CameraLoginModal);

const CameraLoginForm = connect(mapStateToProps)(ReduxForm);

export default CameraLoginForm;
