/* eslint-disable import/no-cycle */
// Libs
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Components
import { TextInput } from 'components';
import { Button, GroupLayout } from 'lib';

// Constants
import {
  CAMERA_PERMISSIONS,
  getCameraPermission,
} from 'util/cameraSettingPermission';
import { GENERAL_CONTENT_ID } from './constants';

// Styles
import 'react-input-range/lib/css/index.css';
import {
  cameraSettingsBasic,
  formContent,
  formGroup,
  formReadOnly,
  rightSettings,
  settingsPanel,
  settingsPanelHeader,
} from './styles.css';
import { formField, formLabel } from 'components/VerticalForm/styles.css';

// Class
class CameraSettingsGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(nextProps) {
    const { camera, general } = this.props;
    if (camera !== nextProps.camera || general !== nextProps.general) {
      this.updateState(nextProps);
    }
  }

  getInitialState = props => {
    const { camera } = this.props;
    const newCamera = props ? props.camera : camera || {};
    // Since all this is copied into the camera object,
    // initialize from there instead of drilling into settings.general,
    // which has a more complex structure
    return {
      general: {
        deviceName: newCamera.Name,
        locationString: newCamera.Location,
      },
      isSaving: false,
    };
  };

  getfields() {
    const { general } = this.state;
    const { camera, cameraDeviceLocation, translate } = this.props;
    const unavailableText = translate('ALARMS.STATUS.UNAVAILABLE_LABEL');

    return [
      {
        id: 'camera-name',
        label: translate('COMMON.NAME'),
        onChange: this.updateGeneralField.bind(this, 'deviceName'),
        value: general.deviceName,
      },
      {
        id: 'camera-location',
        label: translate('COMMON.DESCRIPTION'),
        onChange: this.updateGeneralField.bind(this, 'locationString'),
        value: general.locationString,
      },
      {
        disabled: true,
        id: 'camera-site',
        label: translate('COMMON.SITE'),
        value: cameraDeviceLocation || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-manufacturer',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.MANUFACTURER'),
        value: camera.Manufacturer || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-model',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.MODEL'),
        value: camera.Model || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-serial',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.SERIAL_NUMBER'),
        value: camera.Serial || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-firmware-version',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.FIRMWARE_VERSION'),
        value: camera.FirmwareVersion || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-ip',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.IP_ADDRESS'),
        value: camera.IpAddress || unavailableText,
      },
      {
        disabled: true,
        id: 'camera-mac-address',
        label: translate('CAMERA.SETTINGS.GENERAL.LABELS.MAC_ADDRESS'),
        value: camera.PhysicalAddress || unavailableText,
      },
    ];
  }

  checkSaved = () => {
    let isSaved = true;
    const { general } = this.getInitialState();
    const { general: stateGeneral } = this.state;
    const propKeysG = Object.keys(general);
    propKeysG.forEach(key => {
      // untyped comparison prevents unnescessary saves

      if (stateGeneral[key] != general[key]) {
        isSaved = false;
      }
    });
    return isSaved;
  };

  onCancel = () => {
    this.setState(this.getInitialState());
  };

  onRestartCamera = () => {
    const { onRestartCamera } = this.props;
    onRestartCamera();
  };

  onRefreshSnapshot = () => {
    const { onRefreshSnapshot } = this.props;
    onRefreshSnapshot();
  };

  onSave = () => {
    const { onSave } = this.props;
    const { acquisition, general } = this.state;
    this.setState({ isSaving: true }, onSave.bind(this, general, acquisition));
  };

  updateState = nextProps => {
    this.setState(this.getInitialState(nextProps));
  };

  updateGeneralField(field, e, v) {
    const { general } = this.state;
    const currentG = general;
    const newG = {};
    newG[field] = e.target ? e.target.value : v;
    const newGeneral = { ...currentG, ...newG };
    this.setState({ general: newGeneral });
  }

  render() {
    const inputClassNames = {
      class: formGroup,
      input: formField,
      label: formLabel,
    };
    const { isSaving } = this.state;
    const { capabilities, translate } = this.props;
    const fields = this.getfields();
    return (
      <div
        className={`${rightSettings} ${cameraSettingsBasic}`}
        id={GENERAL_CONTENT_ID}
      >
        <div className={settingsPanel}>
          <div className={settingsPanelHeader}>
            {translate('CAMERA.SETTINGS.GENERAL.INFORMATION_HEADER')}
          </div>
          <div className={formContent}>
            {fields.map(props =>
              props.disabled ? (
                <div key={props.id} className={formReadOnly}>
                  <div className={formLabel}>{props.label}</div>
                  <div className={formField}>
                    <input
                      disabled
                      id={`input_${props.label}`}
                      value={props.value}
                    />
                  </div>
                </div>
              ) : (
                <TextInput
                  key={props.id}
                  className={inputClassNames}
                  {...props}
                />
              ),
            )}
          </div>
        </div>
        <div className={settingsPanel}>
          <GroupLayout horizontalPositioning="split">
            {getCameraPermission(
              capabilities,
              CAMERA_PERMISSIONS.CAN_REBOOT_CAMERA,
            ) ? (
              <Button
                key="btnRestartCamera"
                buttonType="secondary"
                id="btnRestartCamera"
                inputType="button"
                onClick={this.onRestartCamera}
                text={translate(
                  'CAMERA.SETTINGS.GENERAL.RESTART_CAMERA_BUTTON',
                )}
              />
            ) : (
              ''
            )}
            <Button
              key="btnRefreshSnapshot"
              buttonType="secondary"
              id="btnRefreshSnapshot"
              inputType="button"
              onClick={this.onRefreshSnapshot}
              text={translate(
                'CAMERA.SETTINGS.GENERAL.REFRESH_SNAPSHOT_BUTTON',
              )}
            />
          </GroupLayout>
        </div>
        <GroupLayout bottomMarginNone="none" verticalSpacing="none">
          <Button
            key="generalSettingsButtonRevert"
            buttonType="primary"
            disabled={this.checkSaved() || isSaving}
            id="generalSettingsButtonRevert"
            inputType="button"
            onClick={this.onCancel}
            text={translate('BUTTONS.REVERT')}
          />
          <Button
            key="generalSettingsButtonSave"
            buttonType="primary"
            disabled={this.checkSaved() || isSaving}
            id="generalSettingsButtonSave"
            inputType="button"
            onClick={this.onSave}
            text={translate('BUTTONS.SAVE')}
          />
        </GroupLayout>
      </div>
    );
  }
}

CameraSettingsGeneral.propTypes = {
  camera: PropTypes.shape({
    FirmwareVersion: PropTypes.string.isRequired,
    IpAddress: PropTypes.string.isRequired,
    Manufacturer: PropTypes.string.isRequired,
    Model: PropTypes.string.isRequired,
    PhysicalAddress: PropTypes.string.isRequired,
    Serial: PropTypes.string.isRequired,
  }).isRequired,
  cameraDeviceLocation: PropTypes.string.isRequired,
  capabilities: PropTypes.string.isRequired,
  general: PropTypes.shape({
    deviceName: PropTypes.string.isRequired,
    locationString: PropTypes.string.isRequired,
  }).isRequired,
  onRefreshSnapshot: PropTypes.func.isRequired,
  onRestartCamera: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(CameraSettingsGeneral);
