import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_ACCOUNTS, PATH_SEGMENT_DEALERS } from 'constants/urlPaths';
import { tabs } from './constants';

const DealerNavMenu = props => {
  const { currentOrgId } = props;

  return (
    <NavTabs
      tabs={tabs.map(({ label, pathSegment }) => ({
        label,
        path: `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${currentOrgId}${pathSegment}`,
      }))}
      translateLabels
    />
  );
};

DealerNavMenu.propTypes = {
  currentOrgId: PropTypes.string.isRequired,
};

export default withLocalize(DealerNavMenu);
