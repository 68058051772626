import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { LIST_ICON_ID } from '../constants/ElementId';

const IconListView = ({ translate, title, className, width, height, fill }) => {
  return (
    <div title={translate(title)} className={className} id={LIST_ICON_ID}>
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill={fill}
            d="M5,5 L7.33333333,5 L7.33333333,7.8 L5,7.8 L5,5 Z M9.66666667,5 L19,5 L19,7.8 L9.66666667,7.8 L9.66666667,5 Z M5,10.6 L7.33333333,10.6 L7.33333333,13.4 L5,13.4 L5,10.6 Z M9.66666667,10.6 L19,10.6 L19,13.4 L9.66666667,13.4 L9.66666667,10.6 Z M5,16.2 L7.33333333,16.2 L7.33333333,19 L5,19 L5,16.2 Z M9.66666667,16.2 L19,16.2 L19,19 L9.66666667,19 L9.66666667,16.2 Z"
          />
        </g>
      </svg>
    </div>
  );
};

IconListView.defaultProps = {
  title: 'LIST_VIEWS.LIST',
  width: '24px',
  height: '24px',
  fill: '#494949',
  className: '',
};

IconListView.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(IconListView);
