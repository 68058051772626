/* ************************************************************************* */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/*                 AVOID MAKING CHANGES TO THIS FILE.                        */
/*                  PLEASE USE BUTTON IN LIB INSTEAD                         */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/* ************************************************************************* */

import React from 'react';
import PropTypes from 'prop-types';
import { NoStyleButton } from 'lib';

const MenuItem = ({ text, value, className, contentStyle, onClick }) => {
  const selected = () => {
    onClick(value);
  };
  return (
    <NoStyleButton
      id={`idBtnSelectPlayback_${text}`}
      onMouseDown={selected}
      className={className}
    >
      <span className={contentStyle}>{text}</span>
    </NoStyleButton>
  );
};

MenuItem.defaultProps = {
  text: '',
  onClick() {},
  className: '',
  contentStyle: '',
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  contentStyle: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default MenuItem;
