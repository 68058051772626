import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function signalrReducer(state, action) {
  switch (action.type) {
    case types.LOG_SIGNAL_R_MESSAGE: {
      const existingLog = state.log;
      const log = [...existingLog, action.payload];
      return Object.assign({}, state, {
        log,
      });
    }

    case types.CLEAR_SIGNAL_R_LOG: {
      return Object.assign({}, state, { log: [] });
    }

    default:
      return state || initialState().signalr;
  }
}
