// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Translate } from 'react-localize-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

import get from 'lodash.get';

// Container
import { ModalContainer } from 'containers';

// Components
import {
  AppStoreBadge,
  BusyIndicator,
  EulaModal,
  LoggedOutContentWrapper,
  LoginForm,
  PlayStoreBadge,
} from 'components';

// Actions
import * as UserActions from 'actions/user';
import * as UtilitiesActions from 'actions/utilities';
import { hideModal, showModal } from 'actions/modal';
import * as LocationActions from 'actions/locations';

// Utils

// Styles
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import {
  PATH_ACCOUNTS,
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_SEGMENT_SITES,
} from 'constants/urlPaths';
import * as permissions from 'util/permissions';
import getPathName from 'util/getPathName';
import { getSecurityTokens } from 'util/getSecurityTokens';
import { englishCode } from 'constants/links';
import { badgeRow } from './styles.css';

// Class
// this container used for normal login, and for /register by user authenticating in order to claim device
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    // display busy until response from getProfile received.  if profile exists, redirect.  if profile doesnt exist, force authentication
    this.state = {
      enableEulaAccept: false,
      isBusy: false,
    };
  }

  login = formData => {
    const { actions, code } = this.props;
    const { email, password } = formData;

    actions.receiveUserProfile({});

    actions.login({
      code,
      email,
      password,
    });

    // show busy indicator and dont remove it until user is redirected away from this page
    this.setState({ isBusy: true });
  };

  resetModalCheckBox = () => {
    this.setState({ enableEulaAccept: false });
  };

  toggleModal = () => {
    const { actions, isEULAModalOpen } = this.props;

    this.resetModalCheckBox();
    if (isEULAModalOpen) {
      actions.hideModal();
    } else {
      actions.showModal(modalTypes.EULA, {});
    }
  };

  onEulaAccept = () => {
    const { EulaModalProps: eulaModalProps, actions } = this.props;
    actions.acceptEula(eulaModalProps);
    this.resetModalCheckBox();
  };

  onEnableEulaAccept = () => {
    const { enableEulaAccept } = this.state;
    this.setState({ enableEulaAccept: !enableEulaAccept });
  };

  // forward user to next page after they authenticate

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, history, isFetchingLocations, path } = this.props;
    const { isBusy: isBusyState } = this.state;

    const isBusy = nextProps.isFetchingUserProfile || nextProps.user.isBusy;
    if (isBusyState !== isBusy) {
      this.setState({ isBusy });
    }
    if (
      nextProps.profile &&
      nextProps.profile.TenantId &&
      isFetchingLocations === null
    ) {
      actions.getLocations(nextProps.profile.TenantId);
    }
    if (nextProps.isAuthenticated) {
      // claiming
      if (nextProps.inClaimFlow) {
        history.push(nextProps.redirectToOnSuccess);
      } else if (path === PATH_LOGIN) {
        // normal login
        if (nextProps.profile && nextProps.profile.Role) {
          if (
            nextProps.redirectToOnSuccess &&
            nextProps.redirectToOnSuccess.length > 0
          ) {
            history.push(nextProps.redirectToOnSuccess);
          } else if (nextProps.userVoiceRedirect) {
            const { profile, userVoiceUrl } = nextProps;
            if (userVoiceUrl) {
              window.location = `${userVoiceUrl}?sso=${profile.Token}`;
            } else {
              actions.getLinks();
            }
          } else {
            history.push('');
            /*
            const homepage = permissions.getUserHomepage(nextProps.profile);
            history.push(
              nextProps.locations &&
                nextProps.isFetchingLocations === false &&
                nextProps.locations.length >= 1
                ? homepage
                : `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}`,
            );
            */
          }
        } else if (!nextProps.isFetchingUserProfile) {
          actions.getUserProfile();
        }
      }
    }
  }

  renderBadgeRow = () => (
    <div className={badgeRow}>
      <AppStoreBadge />
      <PlayStoreBadge />
    </div>
  );

  render() {
    const {
      EulaModalProps: eulaModalProps,
      hasAcceptedEula,
      inClaimFlow,
      isEULAModalOpen,
      language,
      path,
      redirectToOnSuccess,
      user,
    } = this.props;
    const { enableEulaAccept, isBusy } = this.state;

    let busyIndicator;
    if (isBusy) {
      busyIndicator = <BusyIndicator />;
    }

    return (
      <LoggedOutContentWrapper
        header={
          inClaimFlow ? (
            <Translate id="LOGIN.ACTIVATION_SIGN_IN" />
          ) : (
            <Translate id="LOGIN.REGULAR_SIGN_IN" />
          )
        }
        rightheader={
          inClaimFlow ? <Translate id="LOGIN.STEP_INDICATOR" /> : <div />
        }
      >
        <LoginForm
          data-disableautocomplete={user.disableAutoComplete}
          onChange={this.login}
          path={path}
          redirectToOnSuccess={redirectToOnSuccess}
          user={user}
        />
        {busyIndicator}
        {hasAcceptedEula === false ? (
          <Modal
            className={modalContentContainer}
            contentLabel="EulaModal"
            isOpen={isEULAModalOpen}
            onRequestClose={this.toggleModal}
            overlayClassName={modalOverlay}
            shouldCloseOnOverlayClick={false}
          >
            <Translate>
              {({ translate }) => (
                <ModalContainer
                  handleCancel={this.toggleModal}
                  modalTitle={translate('LOGIN.EULA_MODAL_HELPER')}
                >
                  <EulaModal
                    enableAcceptButton={enableEulaAccept}
                    EulaModalProps={eulaModalProps}
                    handleCancel={this.toggleModal}
                    language={language}
                    onEnableEulaAccept={this.onEnableEulaAccept}
                    onSubmit={this.onEulaAccept}
                  />
                </ModalContainer>
              )}
            </Translate>
          </Modal>
        ) : (
          ''
        )}
      </LoggedOutContentWrapper>
    );
  }
}

LoginContainer.propTypes = {
  EulaModalProps: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({}).isRequired,
  code: PropTypes.string,
  hasAcceptedEula: PropTypes.bool,
  history: PropTypes.shape({}).isRequired,
  inClaimFlow: PropTypes.bool.isRequired,
  isEULAModalOpen: PropTypes.bool.isRequired,
  isFetchingLocations: PropTypes.bool,
  language: PropTypes.shape({
    active: PropTypes.bool,
    code: PropTypes.string,
  }),
  locations: PropTypes.arrayOf(PropTypes.object),
  path: PropTypes.string.isRequired,
  profile: PropTypes.shape({}),
  redirectToOnSuccess: PropTypes.string,
  router: PropTypes.shape({}),
  user: PropTypes.shape({}),
  userVoiceRedirect: PropTypes.bool,
  userVoiceUrl: PropTypes.string,
};

LoginContainer.defaultProps = {
  code: undefined,
  hasAcceptedEula: null,
  isFetchingLocations: null,
  language: { code: englishCode },
  locations: null,
  profile: {},
  redirectToOnSuccess: null,
  router: undefined,
  user: {
    disableAutoComplete: true,
  },
  userVoiceRedirect: false,
  userVoiceUrl: null,
};

function mapStateToProps(state, ownProps) {
  const path = getPathName(ownProps.location.pathname);
  const params = new URLSearchParams(ownProps.location.search);
  let code;
  let userVoiceRedirect = false;
  if (ownProps.location.search) {
    const params = getSecurityTokens(ownProps.location);
    ({ code } = params);
  }

  if (
    state.user.isAuthenticated &&
    params.get('return') &&
    params.get('uv_login') &&
    params.get('uv_size')
  ) {
    userVoiceRedirect = true;
  }

  const language = state.localize.languages.find(lang => lang.active === true);

  const userVoiceUrl = get(state, 'utilities.links.UserVoice', null);

  return {
    // isBusy: state.user.isBusy,
    EulaModalProps: state.modal.modalProps || null,
    code,
    hasAcceptedEula: state.user.hasAcceptedEula,
    inClaimFlow: path === PATH_REGISTER,
    isAuthenticated: state.user.isAuthenticated,
    isEULAModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.EULA,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingUserProfile: state.isFetching.getUserProfile,
    language,
    locations: state.locations.locations,
    path,
    profile: state.user.profile,
    redirectToOnSuccess: state.user.redirectToOnSuccess,
    user: state.user,
    userVoiceRedirect,
    userVoiceUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...UserActions,
        ...UtilitiesActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAITracking(ai.reactPlugin, LoginContainer, 'LoginContainer'));
