import { createSelector } from 'reselect';
import moment from 'moment';
import { DATE_FORMAT_MMM_D_Y } from '../constants/app';

const alarmsSelector = (state, alarmType) => state.alarms[alarmType];
const queryOptionsSelector = state => state.filters.filterStatus.alarms;

const filterAlarmSelector = createSelector(
  alarmsSelector,
  queryOptionsSelector,
  (alarms, filterBy) => {
    const sortQueryOptions = queryOptions => {
      const filterBy = {};
      if (queryOptions) {
        queryOptions.forEach(filter => {
          switch (filter.field) {
            case 'Status': {
              filterBy.status = filter.values;
              break;
            }
            case 'LocationId': {
              filterBy.locationId = filter.values;
              break;
            }
            case 'CameraId': {
              filterBy.cameraId = filter.values;
              break;
            }
            case 'Description': {
              filterBy.description = filter.values;
              break;
            }
            case 'Created': {
              if (filter.operator === 'ge') {
                filterBy.stateDate = moment(filter.values, DATE_FORMAT_MMM_D_Y);
              } else if (filter.operator === 'le') {
                filterBy.endDate = moment(filter.values, DATE_FORMAT_MMM_D_Y);
              }
            }
            default: {
              break;
            }
          }
        });
      }
      return filterBy;
    };

    const selectAlarms = (alarms, filterBy) =>
      alarms.filter(alarm => {
        let status, locationId, cameraId, description, created;
        status = locationId = cameraId = description = created = true;
        if (filterBy.status) {
          status = filterBy.status.includes(alarm.Status);
        }

        if (filterBy.locationId) {
          locationId = filterBy.locationId.includes(alarm.LocationId);
        }

        if (filterBy.cameraId) {
          cameraId = filterBy.cameraId.includes(alarm.CameraId);
        }

        if (filterBy.description) {
          description = filterBy.description.includes(alarm.Description);
        }

        if (filterBy.created) {
          created = moment(alarm.Created, DATE_FORMAT_MMM_D_Y).isBetween(
            filterBy.stateDate,
            filterBy.endDate,
          );
        }

        if (status && locationId && cameraId && description) {
          return alarm;
        }
      });

    return selectAlarms(alarms, sortQueryOptions(filterBy));
  },
);

export default filterAlarmSelector;
