import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Translate } from 'react-localize-redux';

// Components
import { Icon } from 'lib';

// ICONS
import { IconCheck } from 'icons';
// Constants
import { ACTIVE_COLOR_DEFAULT } from 'constants/app';
import { NAME_INPUT_ID } from 'constants/ElementId';
import { IC_REMOVE } from 'constants/iconNames';
// Styles
import {
  clickable,
  editorBody,
  editorField,
  editorTitle,
  iconHolder,
  iconsHolder,
  lineEditor,
} from './styles.css';

const LineEditor = props => {
  const {
    cancelEdit,
    className,
    createFunction,
    initialValue,
    length,
    title,
  } = props;
  const [inputValue, setInputValue] = useState(initialValue);

  return (
    <div className={lineEditor}>
      {title ? (
        <div className={editorTitle}>
          <Translate id={title} />
        </div>
      ) : null}
      <div className={editorBody}>
        <input
          autoFocus
          maxLength={length}
          value={inputValue}
          id={NAME_INPUT_ID}
          className={className || editorField}
          onChange={event => {
            setInputValue(event.target.value);
          }}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              createFunction(inputValue);
            }
          }}
        />
        <div className={`${iconsHolder} ${clickable}`}>
          <div
            className={iconHolder}
            onClick={() => {
              createFunction(inputValue);
            }}
            onKeyPress={() => {
              createFunction(inputValue);
            }}
            role="button"
            tabIndex={0}
          >
            <IconCheck fillColor="#000" />
          </div>
          <div
            className={iconHolder}
            onClick={cancelEdit}
            onKeyPress={cancelEdit}
            role="button"
            style={{ marginLeft: '10px' }}
            tabIndex={0}
          >
            <Icon id={IC_REMOVE} />
          </div>
        </div>
      </div>
    </div>
  );
};

LineEditor.propTypes = {
  cancelEdit: PropTypes.func,
  className: PropTypes.string,
  createFunction: PropTypes.func,
  iconColor: PropTypes.string,
  initialValue: PropTypes.string,
  length: PropTypes.number,
  title: PropTypes.string,
};

LineEditor.defaultProps = {
  cancelEdit: () => {},
  className: '',
  createFunction: () => {},
  iconColor: ACTIVE_COLOR_DEFAULT,
  initialValue: '',
  length: 40,
  title: '',
};

export default LineEditor;
