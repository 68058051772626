import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ICON_COLOR_ONLINE } from 'constants/app';

// Actions
import { getCameras } from 'actions/devices';

// Components
import { Spinner } from 'components';

// Icons
import { IconCopy } from 'icons';

import {
  monitoringConfigClickToCopy,
  monitoringConfigHiddenCopyableInput,
  monitoringConfigIsCopyingDiv,
} from './styles.css';

class CopyCameraInfoButtonContainer extends React.Component {
  constructor(props) {
    super(props);
    this.valueRef = null;
    this.setValueRef = x => {
      this.valueRef = x;
      return this.valueRef;
    };
    this.state = {
      copied: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isFetching } = this.props;
    if (prevProps.isFetching === true && isFetching === false) {
      this.copyToClipboard();
    }
  }

  get cameraData() {
    let output = '';
    const { cameraData } = this.props;
    cameraData.forEach(camera => {
      const simplerCamera = { ...camera };
      delete simplerCamera.Name;
      output += `\r\n\r\nCamera Name\r\n\r\n${
        camera.Name
      }\n\nCamera Extra Value\r\n\r\n${JSON.stringify(simplerCamera)} `;
    });
    return output;
  }

  copyToClipboard = () => {
    // Select text
    this.valueRef.select();
    avoLog('Copied to clipboard', this.valueRef);
    // Copy selected text
    document.designMode = 'on';
    if (document.execCommand('copy')) {
      this.setState({ copied: true }, () => {
        setTimeout(() => {
          this.setState({ copied: false });
        }, 3000);
      });
    }
    document.designMode = 'off';
    this.valueRef.blur();
  };

  handleCopyClick = () => {
    const { actions, isFetching, locationId, tenantId } = this.props;
    if (isFetching === null) {
      actions.getCameras(
        {
          field: 'LocationId',
          value: locationId,
        },
        tenantId,
      );
    } else {
      this.copyToClipboard();
    }
  };

  render() {
    const { fieldName, isFetching } = this.props;
    const { copied } = this.state;
    if (isFetching === true) {
      return (
        <div className={monitoringConfigIsCopyingDiv}>
          <Spinner size={30} />
        </div>
      );
    }
    return (
      <div
        key={`${fieldName}Btn`}
        className={monitoringConfigClickToCopy}
        id={`${fieldName}Btn`}
        onClick={this.handleCopyClick}
        onKeyPress={this.handleCopyClick}
        role="button"
        tabIndex="0"
      >
        {copied ? (
          <IconCopy
            fill={ICON_COLOR_ONLINE}
            title="INTEGRATIONS.COPY_SITE_DATA_LABELS.COPIED"
          />
        ) : (
          <IconCopy title="INTEGRATIONS.COPY_SITE_DATA_LABELS.COPY" />
        )}
        <textarea
          ref={this.setValueRef}
          className={monitoringConfigHiddenCopyableInput}
          readOnly
          type="text"
          value={this.cameraData}
        />
      </div>
    );
  }
}

CopyCameraInfoButtonContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  cameraData: PropTypes.arrayOf(PropTypes.shape({})),
  fieldName: PropTypes.string,
  isFetching: PropTypes.bool,
  locationId: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
};

CopyCameraInfoButtonContainer.defaultProps = {
  cameraData: [],
  fieldName: 'copyCameraInfoButton',
  isFetching: null,
};

const mapStateToProps = (state, ownProps) => {
  const cameraDataArray = state.devices.cameras
    .filter(c => c.LocationId === ownProps.locationId && c.Active === true)
    .filter(c => !c.IsDisconnected)
    .map(c => ({
      Name: c.Name,
      cameraDeviceId: c.ServerId,
      cameraId: c.Id,
      cameraRemoteId: c.RemoteId,
      cameraTenantId: c.TenantId,
    }));
  return {
    cameraData: cameraDataArray,
    isFetching: state.isFetching.getCameras[ownProps.locationId],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ getCameras }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CopyCameraInfoButtonContainer);
