

// Libs
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';

// Components
import { ModalCancelIcon, SelectCell, TextFilter } from 'components';
import { CameraSnapshot } from 'containers';

// Icons
import { IconCamera, IconClip, IconX } from 'icons';

// Constants
import { CAMERA_CONNECTED } from 'constants/cameraTypes';

// Styles
import {
  buttonImgSpan,
  cameraDetailHeaderLeft,
  cameraDetailHeaderSpan,
  cameraDetails,
  cameraDetailSpan,
  cameraIconWrapper,
  cameraList,
  cameraListItem,
  cameraSelectedHeader,
  checkboxes,
  checkBoxWrapper,
  compact,
  deleteCameraX,
  imgCamera,
  locationHeader,
  selectCameraContainer,
  selectedCamera,
  siteImg,
  siteImgSpan,
  titleHeader,
  visibleCamera,
} from './SelectCamera.styles.css';
import { checkboxAlign } from 'sharedStyles/tables.css';

// Constants
import * as SelectCameraConst from './constants';

class SelectCamera extends Component {
  UNSAFE_componentWillMount() {
    const { selectedCamIndices, selectionList } = this.props;
    const rowsSelected = new Map();
    if (selectedCamIndices) {
      selectedCamIndices.forEach(ind => {
        if (ind >= 0) {
          rowsSelected.set(selectionList[0].cameras[ind].RemoteId, ind);
        }
      });
    }
    this.setState({
      bookmarkCameras: selectionList[0].cameras,
      filteredBookmarkCameras: selectionList[0].cameras,
      rowsSelected,
      title: selectionList[0].locationName,
    });
  }

  getButtonId = (cameraId, selectedCameraId) => {
    return cameraId === selectedCameraId
      ? 'btnSelectedListItem'
      : 'btnUnSelectedListItem';
  };

  setHeader = (event, index) => {
    const { selectionList } = this.props;

    if (event.currentPosition === 'below' && index !== 0) {
      this.setState({
        title: selectionList[index - 1].locationName,
      });
    } else {
      this.setState({ title: selectionList[index].locationName });
    }
  };

  filterBookmarkCameras = cameraFilter => {
    const { bookmarkCameras, rowsSelected } = this.state;

    let filteredBookmarkCameras = bookmarkCameras;
    filteredBookmarkCameras = filteredBookmarkCameras.filter(camera => {
      const cameraName = camera.Name.toLowerCase();
      return cameraName.indexOf(cameraFilter.toLowerCase()) !== -1;
    });

    const selectedRows = rowsSelected;
    if (selectedRows.size) {
      // reset indeces
      selectedRows.forEach((value, key) => {
        selectedRows.set(key, -1);
      });

      // update checkmark indecis
      if (filteredBookmarkCameras) {
        filteredBookmarkCameras.forEach((cam, index) => {
          if (selectedRows.has(cam.RemoteId)) {
            selectedRows.set(cam.RemoteId, index);
          }
        });
      }
    }

    this.setState({
      filteredBookmarkCameras,
      rowsSelected: selectedRows,
    });
  };

  renderListSection = (cameras, ix, id, locationName) => {
    const { showCompactLayout, showFilter } = this.props;

    const { filteredBookmarkCameras } = this.state;

    const sectionCameras = showFilter ? filteredBookmarkCameras : cameras;
    return (
      <div key={`cameraListWrapper_${id}`}>
        {!showCompactLayout ? (
          <div className={locationHeader}>
            <span className={siteImgSpan}>
              <Translate>
                {({ translate }) => (
                  <img
                    alt={translate('ACCESSIBILITY.ICON_BUILDING')}
                    className={siteImg}
                    src={SelectCameraConst.imgBuilding}
                  />
                )}
              </Translate>
            </span>
            <span>{locationName || <Translate id="VIEWS.UNKNOWN" />}</span>
            <Waypoint
              bottomOffset="100%"
              onLeave={event => this.setHeader(event, ix)}
              topOffset="30px"
            />
          </div>
        ) : null}
        {sectionCameras.map((camera, iy) => this.renderListItem(camera, iy))}
      </div>
    );
  };

  isSelected = camera => {
    // Support unprocessed Blue cameras
    const { selectedCameraId } = this.props;
    const { Id, RemoteId, id } = camera;
    return [Id, RemoteId, id].includes(selectedCameraId);
  };

  isPreSelectedCamera = camera => {
    const { preSelectedCameraIds } = this.props;

    const id = camera.Id;
    return preSelectedCameraIds.includes(id);
  };

  isConnected = camera => camera.ConnectionState === CAMERA_CONNECTED;

  isVisible = id => {
    const { visibleList } = this.props;
    visibleList.includes(id);
  };

  selectedCameraIndex = camera => {
    const { selectionList, showFilter } = this.props;

    const { filteredBookmarkCameras } = this.state;

    const cameras = showFilter
      ? filteredBookmarkCameras
      : selectionList[0].cameras;
    return cameras.findIndex(cam => cam.Id === camera.Id);
  };

  icon = camera => {
    const isClipsOnly = !camera.Active && camera.RecordedData;
    if (isClipsOnly) {
      return (
        <IconClip
          fill={
            this.isSelected(camera)
              ? SelectCameraConst.clipOnlyIconConstants.fillColors.SELECTED
              : SelectCameraConst.clipOnlyIconConstants.fillColors.NORMAL
          }
          height={SelectCameraConst.clipOnlyIconConstants.HEIGHT}
          title={<Translate id="VIEWS.CAMERA_CLIPPED" />}
          width={SelectCameraConst.clipOnlyIconConstants.WIDTH}
        />
      );
    }
    return (
      <IconCamera
        fill={
          
          this.isSelected(camera)
            ? SelectCameraConst.cameraIconConstants.fillColors.SELECTED
            : this.isConnected(camera)
            ? SelectCameraConst.cameraIconConstants.fillColors.CONNECTED
            : SelectCameraConst.cameraIconConstants.fillColors.DISCONNECTED
        }
        height={SelectCameraConst.cameraIconConstants.HEIGHT}
        title={
          this.isConnected(camera) ? (
            <Translate id="VIEWS.CAMERA_CONNECTED" />
          ) : (
            <Translate id="VIEWS.CAMERA_DISCONNECTED" />
          )
        }
        width={SelectCameraConst.cameraIconConstants.WIDTH}
      />
    );
  };

  cardClasses = (isVisible, isSelected) => {
    if (isSelected) {
      return `${cameraListItem} ${selectedCamera}`;
    }
    if (isVisible) {
      return `${cameraListItem} ${visibleCamera}`;
    }
    return cameraListItem;
  };

  cameraHeaderClasses = isVisible => {
    if (isVisible) {
      return `${cameraDetailHeaderLeft} ${cameraSelectedHeader}`;
    }
    return cameraDetailHeaderLeft;
  };

  onCameraSelect = currentIndex => {
    const { onSelectedRowsChanged } = this.props;
    const { filteredBookmarkCameras, rowsSelected } = this.state;
    const selectedRows = rowsSelected;

    if (filteredBookmarkCameras) {
      const key = filteredBookmarkCameras[currentIndex].RemoteId;
      if (selectedRows.has(key)) {
        selectedRows.delete(key);
      } else {
        selectedRows.set(key, currentIndex);
      }
    }

    this.setState({ rowsSelected: selectedRows }, () => {
      onSelectedRowsChanged(this.getSelectedRowsFromFiltered(selectedRows));
    });
  };

  getSelectedRowsFromFiltered = selectedRows => {
    const { selectionList } = this.props;
    const rows = [];

    if (selectionList[0].cameras) {
      selectionList[0].cameras.forEach((cam, ind) => {
        if (selectedRows.has(cam.RemoteId)) {
          rows.push(ind);
        }
      });
    }
    return rows;
  };

  renderListItem = (camera, ix) => {
    const {
      preSelectedCameraIds,
      selectedCameraId,
      showCheckBoxes,
      showCompactLayout,
    } = this.props;
    const { rowsSelected } = this.state;

    const isSelected = this.isSelected(camera);
    const isVisible = this.isVisible(camera.Id);
    const isPreSelectedCamera =
      preSelectedCameraIds !== undefined && this.isPreSelectedCamera(camera);
    const currentIndex = this.selectedCameraIndex(camera);

    const rowsSelectedItems = [];
    rowsSelected.forEach(value => {
      rowsSelectedItems.push(value);
    });

    return (
      <button
        key={ix}
        className={this.cardClasses(isVisible, isSelected)}
        id={this.getButtonId(camera.Id, selectedCameraId)}
        onMouseDown={this.selectCamera.bind(this, camera)}
        type="button"
      >
        {showCheckBoxes ? (
          <div className={checkBoxWrapper}>
            <SelectCell
              customClass={checkboxAlign}
              defaultChecked={isPreSelectedCamera === true}
              isCameraSelect
              onChange={() => this.onCameraSelect(currentIndex)}
              rowIndex={ix}
              rowsSelected={rowsSelectedItems}
            />
          </div>
        ) : null}
        {showCompactLayout ? (
          <div className={cameraIconWrapper}>
            <IconCamera
              fill={
                this.isSelected(camera)
                  ? SelectCameraConst.bookmarkCameraIconConstants.fillColors
                      .SELECTED
                  : SelectCameraConst.bookmarkCameraIconConstants.fillColors
                      .UNSELECTED
              }
              title={<Translate id="VIEWS.CAMERA_CONNECTED" />}
              width={
                showCompactLayout
                  ? SelectCameraConst.cameraIconConstants.WIDTH
                  : null
              }
            />
          </div>
        ) : (
          <div className={imgCamera}>
            <CameraSnapshot
              cameraId={camera.Id}
              cameraName={camera.Name}
              deviceId={camera.DeviceId}
              getRevokableSnapshotURL={camera.getRevokableSnapshotURL}
              height={60}
            />
          </div>
        )}
        <div className={cameraDetails}>
          <span className={cameraDetailHeaderSpan}>
            <span className={this.cameraHeaderClasses(isVisible)}>
              {camera.Name}
            </span>
            {showCompactLayout ? null : (
              <span className={buttonImgSpan}>{this.icon(camera)}</span>
            )}
          </span>
          {showCompactLayout ? null : (
            <>
              <span className={cameraDetailSpan}>
                {camera.Manufacturer ? (
                  <Translate
                    data={{ manufacturer: camera.Manufacturer }}
                    id="VIEWS.SELECT_CAMERA_MANUFACTURER"
                  />
                ) : (
                  <Translate id="VIEWS.SELECT_CAMERA_MANUFACTURER_UNKNOWN" />
                )}
              </span>
              <span className={cameraDetailSpan}>
                {camera.Model ? (
                  <Translate
                    data={{ model: camera.Model }}
                    id="VIEWS.SELECT_CAMERA_MODEL"
                  />
                ) : (
                  <Translate id="VIEWS.SELECT_CAMERA_MODEL_UNKNOWN" />
                )}
              </span>
            </>
          )}
        </div>
        {isSelected && !showCompactLayout && (
          <div className={deleteCameraX}>
            <IconX height="10px" strokeFill="#7C7C7C" width="10px" />
          </div>
        )}
      </button>
    );
  };

  getMaxHeight = () => {
    return `calc(100% - ${SelectCameraConst.POPUP_HEADER_HEIGHT}px)`;
  };

  hideDropdown = () => {
    const { showDropdown } = this.props;
    showDropdown(false);
  };

  selectCamera(camera) {
    const { removeCamera, selectCamera } = this.props;

    const isSelected = this.isSelected(camera);

    if (isSelected) {
      removeCamera();
    } else {
      selectCamera(camera);
    }
  }

  render() {
    const {
      selectionList,
      showCheckBoxes,
      showCompactLayout,
      showFilter,
    } = this.props;
    const { title } = this.state;
    const dropdownStyle = {
      maxHeight: this.getMaxHeight(),
    };

    return (
      <div
        className={`${selectCameraContainer} ${
          showCompactLayout ? compact : ''
        } ${showCheckBoxes ? checkboxes : ''}`}
      >
        {showFilter ? (
          <TextFilter onChange={this.filterBookmarkCameras} />
        ) : null}
        <div className={titleHeader}>
          <div className={title}>{title}</div>
          {!showCompactLayout ? (
            <ModalCancelIcon
              color={SelectCameraConst.cancelIconConstants.COLOR}
              handleCancel={this.hideDropdown}
              lineHeight={SelectCameraConst.cancelIconConstants.LINE_HEIGHT}
              size={SelectCameraConst.cancelIconConstants.SIZE}
            />
          ) : null}
        </div>
        <div className={cameraList} style={dropdownStyle}>
          {selectionList.map((listCamera, ix) =>
            this.renderListSection(
              listCamera.cameras,
              ix,
              listCamera.locationId,
              listCamera.locationName,
            ),
          )}
        </div>
      </div>
    );
  }
}

SelectCamera.defaultProps = {
  onSelectedRowsChanged: () => undefined,
  preSelectedCameraIds: undefined,
  removeCamera: () => undefined,
  selectCamera: () => undefined,
  selectedCamIndices: [],
  selectedCameraId: '',
  selectionList: [],
  showCheckBoxes: false,
  showCompactLayout: false,
  showDropdown: true,
  showFilter: false,
  visibleList: [],
};

SelectCamera.propTypes = {
  onSelectedRowsChanged: PropTypes.func,
  preSelectedCameraIds: PropTypes.arrayOf(PropTypes.shape({})),
  removeCamera: PropTypes.func,
  selectCamera: PropTypes.func,
  selectedCamIndices: PropTypes.arrayOf(PropTypes.any),
  selectedCameraId: PropTypes.string,
  selectionList: PropTypes.arrayOf(PropTypes.shape({})),
  showCheckBoxes: PropTypes.bool,
  showCompactLayout: PropTypes.bool,
  showDropdown: PropTypes.bool,
  showFilter: PropTypes.bool,
  visibleList: PropTypes.arrayOf(PropTypes.shape({})),
};
export default SelectCamera;
