// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';

// Component
import { CameraMask, ToggleIconButton } from 'components';
import { Button, EmptyPlaceholder, GroupLayout, Tooltip } from 'lib';
import { IconDelete, IconInfoCircle } from 'icons';
import { PageMessage } from 'containers';

// Constants
import { ACTIVE_COLOR_DEFAULT, INACTIVE_COLOR_DEFAULT } from 'constants/app';

// Style
import {
  addDeleteButtonImage,
  deleteButton,
  rightSettings,
  ruleControl,
  settingsPanel,
  settingsPanelHeader,
  tabButtonGroup,
} from './styles.css';
import { PRIVACY_CONTENT_ID } from './constants';

function CameraSettingsPrivacy(props) {
  const {
    addPrivacyZone,
    cameraSupportsPtz,
    deletePrivacyZone,
    handleCancel,
    isFetchingPrivacyZones,
    privacyZones,
    selectPrivacyZone,
    translate,
    updatePrivacyZones,
  } = props;

  const onSelectedMaskChanged = id => {
    selectPrivacyZone(id);
  };

  const prepareAndSavePrivacyZones = () => {
    updatePrivacyZones();
  };

  const createCameraMask = element => {
    return (
      <div key={element.id} className={ruleControl}>
        <CameraMask
          isSelected={element.isSelected}
          maskId={element.id}
          maskName={translate('CAMERA.SETTINGS.PRIVACY_ZONE_LABEL', {
            number: parseInt(element.id, 10) + 1,
          })}
          onSelectedMaskChanged={onSelectedMaskChanged}
        />
        <div className={addDeleteButtonImage}>
          <ToggleIconButton
            iconActive={
              <IconDelete
                className={deleteButton}
                fill={ACTIVE_COLOR_DEFAULT}
                title="COMMON.REMOVE"
              />
            }
            iconInactive={
              <IconDelete fill={INACTIVE_COLOR_DEFAULT} title="COMMON.REMOVE" />
            }
            isActive
            onClick={() => deletePrivacyZone(element.id)}
          />
        </div>
      </div>
    );
  };

  if (cameraSupportsPtz) {
    return (
      <PageMessage
        body={
          <>
            <span className="alertText" style={{ textAlign: 'left' }}>
              <Translate id="CAMERA.SETTINGS.PTZ.EDIT_PTZ_UNAVAILABLE" />
            </span>
            <Tooltip
              message={<Translate id="CAMERA.SETTINGS.PTZ.PTZ_TOOLTIP" />}
              toggleMethod="click"
              width={250}
            >
              <div
                style={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                }}
              >
                <IconInfoCircle height={10} width={10} />
              </div>
            </Tooltip>
          </>
        }
        keepOpen
        visible
      />
    );
  }

  const privacyDivList = [];
  if (privacyZones) {
    privacyZones.forEach(element => {
      privacyDivList.push(createCameraMask(element));
    }, this);
  }
  return (
    <div>
      <div className={rightSettings} id={PRIVACY_CONTENT_ID}>
        <div className={settingsPanel}>
          <div className={settingsPanelHeader}>
            <Translate id="CAMERA.SETTINGS.PRIVACY_ZONE.ZONES_HEADER" />
          </div>
          <EmptyPlaceholder
            isFetching={isFetchingPrivacyZones}
            items={privacyDivList}
            string={
              <Translate id="CAMERA.SETTINGS.PRIVACY_ZONE.EMPTY_PRIVACY_MASK" />
            }
          >
            <div>{privacyDivList}</div>
          </EmptyPlaceholder>
        </div>
      </div>
      <GroupLayout additionalClasses={tabButtonGroup}>
        <Button
          buttonType="secondary"
          inputType="button"
          onClick={addPrivacyZone}
          text={
            <Translate id="CAMERA.SETTINGS.PRIVACY_ZONE.ADD_PRIVACY_ZONE_BUTTON" />
          }
        />
        <Button
          key="serverDetailGeneralCancelButton"
          buttonType="primary"
          disabled={false}
          id="serverDetailGeneralCancelButton"
          inputType="button"
          onClick={handleCancel}
          text={<Translate id="BUTTONS.REVERT" />}
        />
        <Button
          key="PrivacySaveButton"
          buttonType="primary"
          disabled={false}
          id="PrivacySaveButton"
          inputType="button"
          onClick={prepareAndSavePrivacyZones}
          text={<Translate id="BUTTONS.SAVE" />}
        />
      </GroupLayout>
    </div>
  );
}

CameraSettingsPrivacy.defaultProps = {
  addPrivacyZone: () => {},
  cameraSupportsPtz: false,
  deletePrivacyZone: () => {},
  handleCancel: () => {},
  isFetchingPrivacyZones: null,
  privacyZones: [],
  selectPrivacyZone: () => {},
  updatePrivacyZones: () => {},
};

CameraSettingsPrivacy.propTypes = {
  addPrivacyZone: PropTypes.func,
  cameraSupportsPtz: PropTypes.bool,
  deletePrivacyZone: PropTypes.func,
  handleCancel: PropTypes.func,
  isFetchingPrivacyZones: PropTypes.bool,
  privacyZones: PropTypes.arrayOf(PropTypes.any),
  selectPrivacyZone: PropTypes.func,
  translate: PropTypes.func.isRequired,
  updatePrivacyZones: PropTypes.func,
};

export default withLocalize(CameraSettingsPrivacy);
