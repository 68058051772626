/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  attachScript,
  renderMicroFrontend,
  unmountMicroFrontend,
} from './MicroFrontend.helpers';

const MicroFrontend = ({ config, host, name, onError }) => {
  const [loadingError, setLoadingError] = useState();

  useEffect(() => {
    const scriptId = `micro-frontend-${name}`;

    const handleRender = () => renderMicroFrontend(name, config);

    const handleError = err => {
      setLoadingError('Oops! Something went wrong.');
      onError(err);
    };

    document.getElementById(scriptId)
      ? handleRender()
      : attachScript(scriptId, host, handleRender, handleError);

    return () => unmountMicroFrontend(name);
  }, [host, name, config, onError]);

  return <main id={`${name}-container`}>{loadingError}</main>;
};

MicroFrontend.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  host: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onError: PropTypes.func,
};

MicroFrontend.defaultProps = {
  onError: () => {},
};

export default MicroFrontend;
