import { useContext, useEffect } from 'react';
import { PlayerContext } from '@avo-svtr/savitar';

function RoiObserver({ saveRoiObserver }) {
  const { roi } = useContext(PlayerContext);

  useEffect(() => {
    if (roi) {
      saveRoiObserver(roi);
    }
    return null;
  }, [roi, saveRoiObserver]);

  return null;
}

RoiObserver.defaultProps = {
  saveRoiObserver: () => {},
};

export default RoiObserver;
