import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { ListNav, MainContentWrapper, PageTitle } from 'lib';
import { editCustomer, getUsers } from 'actions/user';
import PageMessage from 'containers/PageMessage/PageMessage';
import AccountNavMenu from './AccountNavMenu';
import OrganizationGeneralInfoForm from '../../components/OrganizationGeneralInfo/OrganizationGeneralInfoForm';
import * as messageTypes from '../../constants/MessageTypes';
import { USA_USA_USA } from './constants';

const AccountContainer = props => {
  let content;
  const {
    actions,
    canChangeCustomer,
    canEditGeneralInfo,
    changeFieldValue,
    currentOrg,
    isFetchingUsers,
    profile,
    selectedOrg,
    translate,
    users,
  } = props;

  useEffect(() => {
    if (users.length <= 0 && isFetchingUsers === null) {
      actions.getUsers();
    }
  }, [actions, isFetchingUsers, users]);

  if (selectedOrg && currentOrg) {
    content = (
      <MainContentWrapper>
        <PageMessage
          messageType={[
            messageTypes.CUSTOMER_ERROR,
            messageTypes.EDIT_CUSTOMER_SUCCESS,
          ]}
        />
        <OrganizationGeneralInfoForm
          canEditGeneralInfo={canEditGeneralInfo}
          changeFieldValue={changeFieldValue}
          currentOrg={currentOrg}
          editCustomer={actions.editCustomer}
          initialValues={{
            ...currentOrg,
            Country: currentOrg.Country || USA_USA_USA,
          }}
          TenantType={selectedOrg.TenantType}
          users={users}
        />
      </MainContentWrapper>
    );
  }
  return (
    <div>
      <ListNav
        canChangeCustomer={canChangeCustomer}
        navigationTabs={<AccountNavMenu profile={profile} />}
        pageTitle={
          <PageTitle
            title={translate('ACCOUNTS.GENERAL_INFO.GENERAL_INFO_HEADER')}
          />
        }
      />
      <div>{content}</div>
    </div>
  );
};

AccountContainer.defaultProps = {
  canEditGeneralInfo: false,
  currentOrg: {},
  isFetchingUsers: null,
  selectedOrg: {},
  users: [],
};

AccountContainer.propTypes = {
  actions: PropTypes.shape({
    editCustomer: PropTypes.func,
    getUsers: PropTypes.func,
  }).isRequired,
  canChangeCustomer: PropTypes.bool.isRequired,
  canEditGeneralInfo: PropTypes.bool,
  changeFieldValue: PropTypes.func.isRequired,
  currentOrg: PropTypes.objectOf(PropTypes.any),
  isFetchingUsers: PropTypes.bool,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedOrg: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

function mapStateToProps(state) {
  return {
    canEditGeneralInfo: state.user.permissions.CAN_EDIT_GENERAL_INFO,
    currentOrg: state.user.currentOrganization,
    isFetchingUsers: state.isFetching.getUsers,
    selectedOrg: state.user.selectedOrganization,
    users: state.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ editCustomer, getUsers }, dispatch),
    changeFieldValue: (field, value) => {
      dispatch(change('organizationGeneralInfo', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(AccountContainer));
