import { canvasObjectTypes } from 'constants/app';

export const vehicleFlag = 'VEHICLE';
export const personFlag = 'PERSON';

export const newPlaceholder = 'NEW';

export const newRule = (id, type = 'roi') => {
  return {
    event: newEvent(id),
    roi: type === canvasObjectTypes.roi ? newPlaceholder : null,
    loi: type === canvasObjectTypes.loi ? newPlaceholder : null,
    isSelected: true,
    isVisible: true,
  };
};

export const newEvent = id => ({
  id,
  sensorId: id,
  enabled: true,
  activityType: 'OBJECT_PRESENT',
  schedule: 'Always',
  objectTypeFlags: [personFlag, vehicleFlag], // Classified object
  objectSubtypeFlags: [],
  detectionTime: 2, // Threshold
  sensitivity: 8, // Sensitivity
  limit: 1, // Objects
  timeout: 3600, // Timeout
  name: '', // Name
});

export const newRoi = (id, mediaParams) => {
  let [minX, maxX, minY, maxY] = [1, 1, 0, 0];
  if (mediaParams) {
    const { cameraResHeight: valHeight, cameraResWidth: valWidth, xOffset, yOffset } = mediaParams;
    minX = xOffset || 0;
    maxX = valWidth ? valWidth + minX : 1;
    minY = yOffset || 0;
    maxY = valHeight ? valHeight + minY : 1;
  }
  return {
    id,
    region: {
      point: [
        { x: maxX, y: minY },
        { x: maxX, y: maxY },
        { x: minX, y: maxY },
        { x: minX, y: minY },
      ],
    },
    rod: [],
    type: canvasObjectTypes.roi,
  };
};

export const newLoi = (id, mediaParams) => {
  let [minX, maxX, minY, maxY] = [1, 1, 0, 0];
  if (mediaParams) {
    const { cameraResHeight: valHeight, cameraResWidth: valWidth, xOffset, yOffset } = mediaParams;
    minX = xOffset || 0;
    maxX = valWidth ? valWidth + minX : 1;
    minY = yOffset || 0;
    maxY = valHeight ? valHeight + minY : 1;
  }
  const borderX = maxX / 8;
  const borderY = maxY / 8;
  // New beam is easier to deal with if it's in the middle of the screen somewhere
  return {
    id,
    point1: { x: maxX - borderX, y: maxY - borderY },
    point2: { x: minX + borderX, y: minY + borderY },
    directional: true,
    type: canvasObjectTypes.loi,
  };
};

export const newPrivacyZone = () => ({
  type: 'privacy',
  name: 'Privacy Zone ',
  isSelected: false,
  privacyZoneDimensions: newPlaceholder,
});

export const newPrivacyZoneDimensions = (offsetX, offsetY) => ({
  left: 100 + offsetX,
  top: 100 + offsetY,
  right: 300 + offsetX,
  bottom: 300 + offsetY,
});

export const calcPrivacyZoneId = privacyZones => {
  let id = 100;
  while (
    privacyZones.find(z => z.id === id || z.sensorId === id) !== undefined
  ) {
    id++;
  }
  return id;
};

export const calcRuleId = rules => {
  const currentIds = Object.keys(rules).map(k => parseInt(k));
  let id = 100;
  while (currentIds.includes(id)) {
    id++;
  }
  return id;
};
