import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Icon } from 'lib';

// Constants
import {
  ACTIVE_COLOR_DEFAULT,
  INACTIVE_COLOR_DEFAULT,
} from 'app/constants/app';
import { IC_SITE_SUSPEND, IC_SITE_UNSUSPEND } from 'app/constants/iconNames';
import * as IconConsts from '../constants';

function SuspendUnsuspendIcons(props) {
  const {
    handleSuspendSite,
    handleUnsuspendSite,
    isSuspendActive,
    isUnsuspendActive,
  } = props;
  const onSuspendClick = isSuspendActive ? handleSuspendSite : () => null;
  const onUnsuspendClick = isUnsuspendActive ? handleUnsuspendSite : () => null;
  const suspendFill = isSuspendActive
    ? ACTIVE_COLOR_DEFAULT
    : INACTIVE_COLOR_DEFAULT;
  const unsuspendFill = isUnsuspendActive
    ? ACTIVE_COLOR_DEFAULT
    : INACTIVE_COLOR_DEFAULT;

  return (
    // suspendIconSvg provides height and width to the suspend icon through css
    <div>
      <Button
        disabled={!isSuspendActive}
        icon
        id={IconConsts.idSuspendBtn}
        inputType="button"
        onClick={onSuspendClick}
      >
        <Icon id={IC_SITE_SUSPEND} title="BUTTONS.SUSPEND" />
      </Button>
      <Button
        disabled={!isUnsuspendActive}
        icon
        id={IconConsts.idUnsuspendBtn}
        inputType="button"
        onClick={onUnsuspendClick}
      >
        <Icon id={IC_SITE_UNSUSPEND} title="BUTTONS.UNSUSPEND" />
      </Button>
    </div>
  );
}

SuspendUnsuspendIcons.defaultProps = {
  handleSuspendSite: () => {},
  handleUnsuspendSite: () => {},
  id: '',
  isSuspendActive: null,
  isUnsuspendActive: null,
};

SuspendUnsuspendIcons.propTypes = {
  handleSuspendSite: PropTypes.func,
  handleUnsuspendSite: PropTypes.func,
  id: PropTypes.string,
  isSuspendActive: PropTypes.bool,
  isUnsuspendActive: PropTypes.bool,
};

export default SuspendUnsuspendIcons;
