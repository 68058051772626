exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".iconSwitch___1Up_9 {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.iconSwitch___1Up_9 .iconButton___Ep4PU {\r\n  margin-right: 4px;\r\n}\r\n\r\n.iconSwitch___1Up_9 .iconButton___Ep4PU:last-child {\r\n  margin: 0 !important;\r\n}\r\n\r\n.iconButton___Ep4PU {\r\n  height: 24px;\r\n  width: 24px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.selectedLight___3pTO- {\r\n}\r\n\r\n.selectedDark___20SC8 {\r\n}\r\n\r\n.iconButton___Ep4PU,\r\n.iconButton___Ep4PU div {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/IconSwitch/IconSwitch.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;CACf;;AAED;EACE,kBAAkB;CACnB;;AAED;EACE,qBAAqB;CACtB;;AAED;EAEE,aAAa;EACb,YAAY;EACZ,mBAAmB;CACpB;;AAED;CAEC;;AAED;CAEC;;AAED;;EAEE,qBAAqB;EACrB,cAAc;EACd,sBAAsB;MAClB,wBAAwB;EAC5B,uBAAuB;MACnB,oBAAoB;CACzB","file":"IconSwitch.css","sourcesContent":[".iconSwitch {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.iconSwitch .iconButton {\r\n  margin-right: 4px;\r\n}\r\n\r\n.iconSwitch .iconButton:last-child {\r\n  margin: 0 !important;\r\n}\r\n\r\n.iconButton {\r\n  composes: secondaryNavLinkActiveTextColor from '../../sharedStyles/brushes.css';\r\n  height: 24px;\r\n  width: 24px;\r\n  border-radius: 50%;\r\n}\r\n\r\n.selectedLight {\r\n  composes: backgroundSecondaryMenu from '../../sharedStyles/brushes.css';\r\n}\r\n\r\n.selectedDark {\r\n  composes: whiteBg from '../../sharedStyles/brushes.css';\r\n}\r\n\r\n.iconButton,\r\n.iconButton div {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"iconSwitch": "iconSwitch___1Up_9",
	"iconButton": "iconButton___Ep4PU " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["secondaryNavLinkActiveTextColor"] + "",
	"selectedLight": "selectedLight___3pTO- " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["backgroundSecondaryMenu"] + "",
	"selectedDark": "selectedDark___20SC8 " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["whiteBg"] + ""
};