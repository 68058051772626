import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import renderIf from 'render-if';

// Components
import { ListNav, PageTitle } from 'lib';
import { SubscriberSitesContainer } from 'containers';

// Actions
import * as LocationActions from 'actions/locations';
import * as UserActions from 'actions/user';
import * as FilterActions from 'actions/filters';
import * as SubscriptionActions from 'actions/subscriptions';
import { hideModal, showModal } from 'actions/modal';
// import * as MenuActions from 'actions/actionMenu';

// Utilities
import { generateSort } from 'util/generateSort';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_INFO,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';

// Styles
import { listNavWrapper } from 'sharedStyles/styles.css';
import { horizontalDividerSolid } from 'sharedStyles/modalForms.css';

// Constants
import { FILTER_GROUPS } from 'constants/app';
import * as modalTypes from 'constants/ModalTypes';
import { tenantTypeStrings } from 'util/tenantTypes';
import SubscriberNavMenu from './SubscriberNavMenu';
import CustomerFormContainer from '../../Customers/CustomerFormContainer';

function SubscriberDetailsContainer(props) {
  const {
    actions,
    canAddOrg,
    canChangeCustomer,
    canEditOrg,
    canSuspendLocations,
    canViewBillingOrderId,
    containerHeight,
    customer,
    dealerServicePackages,
    filteredItems,
    handleAddClick,
    handleFilter,
    isAdding,
    isFetchingCustomerOrganization,
    match,
    modalIsOpen,
    onEditClick,
    path,
    profile,
    queryOptions,
    selectedLocationId,
    selectedSubscriberId,
    tableFilterId,
    toggleModal,
    userTenantType,
  } = props;

  useEffect(() => {
    const { subscriberId } = match.params;
    if (isFetchingCustomerOrganization === null && subscriberId) {
      actions.getCustomerOrganization(subscriberId);
    }
  }, [actions, isFetchingCustomerOrganization, match]);
  // Need to get new data only if subscriberID has not already been called.
  // Probably need to drop something in redux store for this.

  function handleBackClick() {
    const { history, onBackClick } = props;
    const selectedDealerId = match.params.dealerId;
    const navigateTo = selectedDealerId
      ? `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${selectedDealerId}${PATH_SEGMENT_SUBSCRIBERS}`
      : `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}`;
    return onBackClick ? onBackClick() : history.push(navigateTo);
  }

  function renderTitle() {
    const { translate } = props;
    const addNewCustomer = {
      Name: `${translate('BUTTONS.ADD_ORGANIZATION', {
        tenantType: translate('TENANTS.CUSTOMER'),
      })}`,
      Type: translate('TENANTS.CUSTOMER'),
    };
    const customerObj = isAdding ? addNewCustomer : customer;
    return (
      <div className={listNavWrapper}>
        <PageTitle
          onBackClick={handleBackClick}
          showBackButton
          title={customerObj.Name}
          titleData={{
            tenantType: customerObj.Type,
          }}
        />
      </div>
    );
  }

  function selectedCustomer() {
    const { customerOrganizations } = props;
    if (customerOrganizations && customerOrganizations.length > 0) {
      return (
        customerOrganizations.find(
          org => org.Id === (selectedSubscriberId || match.params.subscriberId),
        ) || { DealerId: null, Id: null }
      );
    }
    return { DealerId: null, Id: null };
  }

  function renderSubscriberSites() {
    return (
      <SubscriberSitesContainer
        access={canViewBillingOrderId}
        canChangeCustomer={canChangeCustomer}
        canSuspendLocations={canSuspendLocations}
        containerHeight={containerHeight}
        customer={selectedCustomer()}
        dealerId={selectedCustomer().DealerId}
        dealerServicePackages={dealerServicePackages}
        filteredItems={filteredItems}
        handleAddClick={handleAddClick}
        handleFilter={handleFilter}
        modalIsOpen={modalIsOpen}
        onEditClick={onEditClick}
        orgId={selectedCustomer().Id}
        path={path}
        profile={profile}
        selectedLocationId={selectedLocationId}
        title={renderTitle()}
        toggleModal={toggleModal}
      />
    );
  }
  function renderSubscriberInfo() {
    return (
      <CustomerFormContainer
        canAdd={canAddOrg}
        canEdit={canEditOrg} // If viewing customers for a dealer
        isAdding={isAdding}
        navigateBack={handleBackClick}
        pageTenantType={tenantTypeStrings.Subscriber}
        queryOptions={queryOptions}
        selectedCustomer={selectedCustomer()}
        selectedDealerId={match.params.dealerId}
        tableFilterId={tableFilterId}
        userTenantType={userTenantType}
      />
    );
  }

  return (
    <>
      {path.includes(PATH_SEGMENT_INFO) || isAdding ? (
        <>
          <ListNav
            canChangeCustomer={canChangeCustomer}
            currentOrg={customer}
            navigationTabs={
              !isAdding && (
                <SubscriberNavMenu currentOrg={customer} path={path} />
              )
            }
            pageTitle={renderTitle()}
          />
          {renderIf(isAdding)(<div className={horizontalDividerSolid} />)}
        </>
      ) : null}
      <Switch>
        <Route
          path={`${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_SITES}`}
          render={renderSubscriberSites}
        />
        <Route
          path={`${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${customer.DealerId}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_SITES}`}
          render={renderSubscriberSites}
        />
        <Route
          exact
          path={[
            `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_INFO}`,
            `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}${PATH_SEGMENT_NEW}`,
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/:dealerId${PATH_SEGMENT_NEW}`,
          ]}
          render={renderSubscriberInfo}
        />
        <Route
          path={`${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/:dealerId${PATH_SEGMENT_NEW}`}
          render={renderSubscriberInfo}
        />
        <Route
          exact
          path={`${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${customer.DealerId}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_INFO}`}
          render={renderSubscriberInfo}
        />
      </Switch>
    </>
  );
}

SubscriberDetailsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canAddOrg: PropTypes.bool.isRequired,
  canChangeCustomer: PropTypes.bool.isRequired,
  canEditOrg: PropTypes.bool,
  canSuspendLocations: PropTypes.bool,
  canViewBillingOrderId: PropTypes.bool,
  containerHeight: PropTypes.string,
  customer: PropTypes.objectOf(PropTypes.any),
  customerOrganizations: PropTypes.objectOf(PropTypes.any),
  dealerServicePackages: PropTypes.objectOf(PropTypes.any),
  filteredItems: PropTypes.objectOf(PropTypes.any),
  handleAddClick: PropTypes.func,
  handleFilter: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isAdding: PropTypes.bool,
  isFetchingCustomerOrganization: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  modalIsOpen: PropTypes.bool,
  onBackClick: PropTypes.func,
  onEditClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  profile: PropTypes.objectOf(PropTypes.any),
  queryOptions: PropTypes.objectOf(PropTypes.any),
  selectedLocationId: PropTypes.string,
  selectedSubscriberId: PropTypes.string,
  tableFilterId: PropTypes.string,
  toggleModal: PropTypes.func,
  userTenantType: PropTypes.string,
};

SubscriberDetailsContainer.defaultProps = {
  canEditOrg: false,
  canSuspendLocations: false,
  canViewBillingOrderId: false,
  containerHeight: null,
  customer: {},
  customerOrganizations: {},
  dealerServicePackages: {},
  filteredItems: {},
  handleAddClick: () => null,
  handleFilter: () => null,
  isAdding: false,
  isFetchingCustomerOrganization: false,
  modalIsOpen: false,
  onBackClick: null,
  onEditClick: () => null,
  profile: {},
  queryOptions: {},
  selectedLocationId: null,
  selectedSubscriberId: null,
  tableFilterId: null,
  toggleModal: () => null,
  translate: () => null,
  userTenantType: null,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...FilterActions,
        ...LocationActions,
        ...UserActions,
        ...SubscriptionActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state, ownProps) {
  const hasTableData = !!(
    state.locations.locations &&
    state.locations.locations.length > 0 &&
    state.locations.servicePackages
  );
  const { customerOrganizations } = state.user;
  let orgId;
  let newCustomer = {};
  if (ownProps.customer && ownProps.customer.Id) {
    orgId = ownProps.customer.Id;
    newCustomer = ownProps.customer;
  } else if (
    ownProps.match &&
    ownProps.match.params &&
    ownProps.match.params.subscriberId &&
    customerOrganizations.length > 0
  ) {
    orgId = ownProps.match.params.subscriberId;
    newCustomer = customerOrganizations.find(orgId);
  }
  let users = {};
  const userData = state.user.users || [];
  userData.forEach(user => {
    users[user.Id] = user;
  });
  if (state.user.users.length > 0) {
    users = state.user.users.sort(
      generateSort(user => `${user.FirstName} ${user.LastName}`),
    );
  }
  /*
  const orgLocations = state.locations.locations.filter(
    location => location.TenantId === orgId,
  );
  */
  return {
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canAddOrg: state.user.permissions.CAN_CREATE_ORG,
    canEditOrg: state.user.permissions.CAN_EDIT_ORG,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    canSuspendLocations: state.user.permissions.CAN_SUSPEND_SITE,
    canViewBillingOrderId: state.user.permissions.CAN_VIEW_BILLING_ORDER_ID,
    customer: newCustomer,
    customerOrganizations: state.user.customerOrganizations,
    dealerServiceFamilies: state.locations.dealerServiceFamilies,
    dealerServicePackages: state.locations.dealerServicePackages,
    filterGroup: FILTER_GROUPS.CUSTOMERS,
    filterStatus: state.filters.filterStatus,
    filters: state.filters.locations,
    hasTableData,
    isFetchingCustomerOrganization: state.user.isFetchingCustomerOrganizations,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    lastFetchedOrgId: state.locations.lastFetchedOrgId,
    locations: state.locations.locations,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_EDIT_LOCATION,
    orgId,
    preferredShortDateFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
    profile: state.user.profile,
    selectedLocationId: state.modal.modalProps.locationId,
    selfService: state.user.permissions.SELF_SERVICE,
    timezones: state.utilities.timezones,
    userTenantType:
      state.user.profile.CustomerTenantType || state.user.profile.TenantType,
    users: users || {},
    // filterValues: state.filters.locations,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(withRouter(SubscriberDetailsContainer)));
