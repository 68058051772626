export default {
  'Application Bad Shutdown': 'EVENTS_TYPE.EVENT_APPLICATION_BAD_SHUTDOWN',
  'Application Server Stop': 'EVENTS_TYPE.EVENT_APPLICATION_SERVER_STOP',
  'Camera Connected': 'EVENTS_TYPE.EVENT_DEVICE_CONNECTED',
  'Camera Disconnected': 'EVENTS_TYPE.EVENT_DEVICE_DISCONNECTED',
  'Camera Tampering': 'EVENTS_TYPE.EVENT_DEVICE_TAMPERING',
  'Db Environment Deleted': 'EVENTS_TYPE.EVENT_DB_ENVIRONMENT_DELETED',
  'Db Environment Deleted With Dbs':
    'EVENTS_TYPE.EVENT_DB_ENVIRONMENT_DELETED_WITH_DBS',
  'Db Environment Recovered': 'EVENTS_TYPE.EVENT_DB_ENVIRONMENT_RECOVERED',
  'Db Lost': 'EVENTS_TYPE.EVENT_DB_LOST',
  'Db Reindex': 'EVENTS_TYPE.EVENT_DB_REINDEX',
  'Device Communication Lost': 'EVENTS_TYPE.EVENT_DEVICE_COMMUNICATION_LOST',
  'Device Communication Restored':
    'EVENTS_TYPE.EVENT_DEVICE_COMMUNICATION_RESTORED',
  'Device Connection Error': 'EVENTS_TYPE.EVENT_DEVICE_CONNECTION_ERROR',
  'Device Connection Restored': 'EVENTS_TYPE.EVENT_DEVICE_CONNECTION_RESTORED',
  'Device Disconnect': 'EVENTS_TYPE.EVENT_DEVICE_DISCONNECT',
  'Device Firmware Upgrade Done': 'EVENTS_TYPE.EVENT_DEVICE_FW_UPGRADE_DONE',
  'Device Firmware Upgrade Error': 'EVENTS_TYPE.EVENT_DEVICE_FW_UPGRADE_ERROR',
  'Device Firmware Upgrade Started':
    'EVENTS_TYPE.EVENT_DEVICE_FW_UPGRADE_STARTED',
  'Device Packets Lost': 'EVENTS_TYPE.EVENT_DEVICE_PACKETS_LOST',
  'Device Packets Recovered': 'EVENTS_TYPE.EVENT_DEVICE_PACKETS_RECOVERED',
  'Device Record Interrupted': 'EVENTS_TYPE.EVENT_DEVICE_RECORD_INTERRUPTED',
  'Device Record Restored': 'EVENTS_TYPE.EVENT_DEVICE_RECORD_RESTORED',
  'Storage Init Error': 'EVENTS_TYPE.EVENT_STORAGE_INIT_ERROR',
  'Storage Low Disk Space': 'EVENTS_TYPE.EVENT_STORAGE_LOW_DISK_SPACE',
  'Storage Volume Failed': 'EVENTS_TYPE.EVENT_STORAGE_VOLUME_FAILED',
  'Storage Volume Restored': 'EVENTS_TYPE.EVENT_STORAGE_VOLUME_RESTORED',
  'Storage Write Failed': 'EVENTS_TYPE.EVENT_STORAGE_WRITES_FAILED',
  'Storage Write Queue Full': 'EVENTS_TYPE.EVENT_STORAGE_WRITE_QUEUE_FULL',
  'Storage Writes Blocked': 'EVENTS_TYPE.EVENT_STORAGE_WRITES_BLOCKED',
  'System Analytics Connection Lost':
    'EVENTS_TYPE.EVENT_SYSTEM_ANALYTICS_CONNECTION_LOST',
  'System Analytics Queue Full':
    'EVENTS_TYPE.EVENT_SYSTEM_ANALYTICS_QUEUE_FULL',
  'System Low Resources': 'EVENTS_TYPE.EVENT_SYSTEM_LOW_RESOURCES',
  'System Plugin Load Error': 'EVENTS_TYPE.EVENT_SYSTEM_PLUGIN_LOAD_ERROR',
};
