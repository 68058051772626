import initialState from 'store/initialState';
import * as types from 'constants/ActionTypes';
import { generateSort } from '../util/generateSort';
import itemUpsert from '../util/itemUpsert';

export default function filterReducer(state, action = {}) {
  const sortByField = (array, field) =>
    array.sort(generateSort(item => item[field].toLowerCase()));

  switch (action.type) {
    case types.ADD_FILTER_VALUES: {
      const { filterGroup, newFilters } = action;
      const { filterStatus } = state;

      const updatedStatus = Object.assign({}, state, {
        filterStatus: {
          ...filterStatus,
          [filterGroup]: newFilters,
        },
      });
      return Object.assign({}, state, updatedStatus);
    }

    case types.CLEAR_FILTER_VALUES: {
      const newFilterStatus = {};
      Object.keys(state.filterStatus)
        .filter(filterGroupName => filterGroupName !== action.filterGroup)
        .forEach(fgname => {
          newFilterStatus[fgname] = state.filterStatus[fgname];
        });

      return Object.assign({}, state, {
        filterStatus: newFilterStatus,
      });
    }

    case types.RECEIVE_CAMERAGROUPS: {
      let alarmLocation = [...state.alarms.location];
      action.cameragroups.forEach(location => {
        alarmLocation = itemUpsert(
          alarmLocation,
          {
            value: location.Id,
            text: location.Name,
          },
          'value',
        );
      });
      const alarmFilters = Object.assign({}, state.alarms, {
        location: sortByField(alarmLocation, 'text'),
      });
      return Object.assign({}, state, {
        alarms: alarmFilters,
      });
    }

    case types.RECEIVE_LOCATIONS: {
      // Clone existing state
      let deviceLocation = [...state.devices.location];
      let userLocation = [...state.users.location];
      let city = [...state.locations.city];
      let region = [...state.locations.region];
      let country = [...state.locations.country];
      const { locations } = action;
      locations.forEach(location => {
        // Include Id to make itemUpsert Happy
        const locationEntry = {
          value: location.Id,
          text: location.Name,
        };
        deviceLocation = itemUpsert(deviceLocation, locationEntry, 'value');
        userLocation = itemUpsert(userLocation, locationEntry, 'value');
        city = itemUpsert(city, {
          Id: location.Id,
          value: location.City,
          text: location.City,
        });
        region = itemUpsert(region, {
          Id: location.Id,
          value: location.Region,
          text: location.Region,
        });
        country = itemUpsert(country, {
          Id: location.Id,
          value: location.Country,
          text: location.Country,
        });
      });

      const deviceFilters = Object.assign({}, state.devices, {
        location: sortByField(deviceLocation, 'text'),
      });
      const userFilters = Object.assign({}, state.users, {
        location: sortByField(userLocation, 'text'),
      });
      const locationFilters = Object.assign({}, state.locations, {
        city,
        region,
        country,
      });

      return Object.assign({}, state, {
        devices: deviceFilters,
        users: userFilters,
        locations: locationFilters,
      });
    }

    case types.RECEIVE_DEVICES: {
      const { devices } = action;

      const model = [];
      devices.forEach(function(device) {
        if (device.Model) {
          const modelObj = {
            value: device.Model,
            text: device.Model,
          };
          if (!(modelObj.value in model)) {
            model.push(modelObj);
          }
        }
      }, this);

      const deviceFilters = Object.assign({}, state.devices, {
        model,
      });
      return Object.assign({}, state, {
        devices: deviceFilters,
      });
    }

    case types.RECEIVE_USERS: {
      const { role } = state.users;
      const { users } = action;

      users.forEach(user => {
        role.includes(user.Role) ? '' : role.push(user.Role);
      });

      const userFilters = Object.assign({}, state.users, {
        role,
      });
      return Object.assign({}, state, {
        users: userFilters,
      });
    }

    case types.RECEIVE_ALARMS:
    case types.RECEIVE_ALARMS_FOR_FILTERS: {
      const alarms = action.alarms.slice();
      const status = state.alarms.status.slice();
      const rule = state.alarms.rule.slice();
      const assignedTo = state.alarms.assignedTo.slice();
      // TODO:  there is no camera name in the current obj.  so use description
      alarms.forEach(alarm => {
        alarm.Description === undefined ||
        rule.find(r => r.value === alarm.Description)
          ? ''
          : rule.push({
              value: alarm.Description,
              text: alarm.EventName,
              subText: alarm.CameraName || alarm.DeviceName,
            });
        alarm.Status === undefined || status.includes(alarm.Status)
          ? ''
          : status.push(alarm.Status);
        alarm.AssignedTo === undefined || assignedTo.includes(alarm.AssignedTo)
          ? ''
          : assignedTo.push(alarm.AssignedTo);
      });
      const filters = Object.assign({}, state.alarms, {
        status,
        assignedTo,
        rule,
      });
      return Object.assign({}, state, {
        alarms: filters,
      });
    }

    case types.RECEIVE_CAMERAS: {
      const cameras = action.cameras.slice();
      let cameraName = state.alarms.cameraName.slice();
      cameras.forEach(camera => {
        if (
          camera.Id !== undefined &&
          !cameraName.find(c => c.value === camera.Id)
        ) {
          cameraName.push({
            value: camera.Id,
            text: camera.Name,
          });
        }
      });
      cameraName = cameraName.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      const filters = Object.assign({}, state.alarms, {
        cameraName,
      });
      return Object.assign({}, state, {
        alarms: filters,
      });
    }

    case types.RECEIVE_CUSTOMERS: {
      const customers = action.customers.slice();
      const subscriber = state.alarms.subscriber.slice();
      customers.forEach(customer => {
        if (
          customer.TenantId &&
          !subscriber.find(s => s.value === customer.TenantId)
        ) {
          subscriber.push({
            value: customer.TenantId,
            text: customer.TenantName,
          });
        }
      });
      const filters = Object.assign({}, state.alarms, {
        subscriber,
      });
      return Object.assign({}, state, {
        alarms: filters,
      });
    }

    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().filters;
    }

    case types.UPDATE_CUSTOMER_ORGANIZATIONS_INFINITE_FILTER: {
      // This reducer assumes that the component calling infiniteWithoutOdata
      // will properly extract the existing query options from the redux store
      // and use them in the action, when desired

      const existingQueryOption = state.infiniteWithoutOData.find(
        x => x.type === action.tableType,
      );

      let newQueryOption = null;
      if (action.queryOptions) {
        newQueryOption = action.queryOptions;
        newQueryOption.skip =
          action.queryOptions.top + action.queryOptions.skip;
      }

      const newTableObject = {
        type: action.tableType,
        queryOption: newQueryOption,
      };

      const newInfiniteWithoutOData = state.infiniteWithoutOData.slice(); // Make a copy
      if (existingQueryOption) {
        // Remove old query options, if necessary
        const existingElementIndex = state.infiniteWithoutOData.findIndex(
          x => x.type === existingQueryOption.type,
        );
        newInfiniteWithoutOData.splice(existingElementIndex, 1);
      }
      newInfiniteWithoutOData.push(newTableObject); // Add new query options

      return Object.assign({}, state, {
        infiniteWithoutOData: newInfiniteWithoutOData,
      });
    }

    default: {
      return state || initialState().filters;
    }
  }
}
