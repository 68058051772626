import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import concatUpsert from '../util/concatUpsert';
import itemUpsert from '../util/itemUpsert';

export default function organizationReducer(state, action) {
  switch (action.type) {
    case types.RECEIVE_INTEGRATION_CONFIGS: {
      const { integrationConfigs } = action;
      return Object.assign({}, state, {
        integrationConfigs,
        isFetchingIntegrationConfigs: false,
      });
    }
    case types.IS_FETCHING_INTEGRATION_CONFIGS: {
      return Object.assign({}, state, {
        isFetchingIntegrationConfigs: action.value,
      });
    }

    case types.DELETE_INTEGRATION_CONFIG: {
      const { id } = action;
      const integrationConfigs = state.integrationConfigs.filter(
        config => config.IntegrationConfigurationId !== id,
      );
      return Object.assign({}, state, {
        integrationConfigs,
      });
    }
    case types.RECEIVE_INTEGRATION_SUBSCRIBER_SUMMARY: {
      const { integrationSubscriberSummary } = action;
      return Object.assign({}, state, {
        integrationSubscriberSummary,
        isFetchingIntegrationSubscriberSummary: false,
      });
    }
    case types.IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY: {
      return Object.assign({}, state, {
        isFetchingIntegrationSubscriberSummary: action.value,
      });
    }

    case types.RECEIVE_INTEGRATION_TYPES: {
      const { integrationTypes } = action;
      return Object.assign({}, state, {
        integrationTypes,
      });
    }

    case types.RECEIVE_INTEGRATION_SUBSCRIBER_SITES: {
      const integrationSubscriberSites = concatUpsert(
        state.integrationSubscriberSites,
        action.integrationSubscriberSites,
        'SiteId',
      );
      return Object.assign({}, state, {
        integrationSubscriberSites,
      });
    }
    case types.CAN_DELETE_INTEGRATION_CONFIG: {
      const canDeleteConfiguration = {
        id: action.id,
        canDelete: action.canDelete,
        sites: action.sites || [],
      };
      return Object.assign({}, state, { canDeleteConfiguration });
    }
    case types.SET_SECURITY_MONITORING: {
      const { siteId, updatedStatus } = action;
      const siteToUpdate = state.integrationSubscriberSites.find(site => {
        return site.SiteId === siteId;
      });
      if (siteToUpdate) {
        siteToUpdate.SecurityEventDealerMonitoringEnabled = updatedStatus;
        const integrationSubscriberSites = itemUpsert(
          state.integrationSubscriberSites,
          siteToUpdate,
          'SiteId',
        );
        return Object.assign({}, state, { integrationSubscriberSites });
      } else {
        return state;
      }
    }
    case types.SET_MONITORING_CENTER: {
      const { updatedMonitoringCenter } = action;
      const siteToUpdate = state.integrationSubscriberSites.find(site => {
        return site.SiteId === action.siteId;
      });
      if (siteToUpdate) {
        const newSite = {
          ...siteToUpdate,
          IntegrationConfigurationId: updatedMonitoringCenter,
          ConfigurationString: null,
          SecurityEventDealerMonitoringEnabled: false,
        };
        // If monitoringCenter is changed to unassigned (null), disable
        const integrationSubscriberSites = itemUpsert(
          state.integrationSubscriberSites,
          newSite,
          'SiteId',
        );
        return Object.assign({}, state, { integrationSubscriberSites });
      } else {
        return state;
      }
    }
    case types.SET_SITE_CONFIGURATION_STRING: {
      const { siteId, configurationString } = action;
      const siteToUpdate = state.integrationSubscriberSites.find(site => {
        return site.SiteId === siteId;
      });
      if (siteToUpdate) {
        siteToUpdate.ConfigurationString = configurationString;
        const integrationSubscriberSites = itemUpsert(
          state.integrationSubscriberSites,
          siteToUpdate,
          'SiteId',
        );
        return Object.assign({}, state, { integrationSubscriberSites });
      } else {
        return state;
      }
    }

    case types.SET_CONNECTION_TEST_RESULT: {
      const { configurationId, value } = action;
      const oldIntegrationConfigTest = { state };
      let integrationConfigTest = {};
      if (value === true || value === false) {
        integrationConfigTest = Object.assign({}, oldIntegrationConfigTest, {
          [configurationId]: value,
        });
      } else {
        Object.keys(oldIntegrationConfigTest).forEach(cId => {
          if (cId !== configurationId) {
            integrationConfigTest[cId] = oldIntegrationConfigTest[cId];
          }
        });
      }
      return Object.assign({}, state, { integrationConfigTest });
    }

    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().organizations;
    }

    default: {
      return state || initialState().organizations;
    }
  }
}
