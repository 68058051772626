import React, { useCallback, useMemo, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import FieldInput from 'components/FormElements/FieldInput';
import { VerticalFormFieldLayout } from 'components';
import { getEntitlementValues } from 'util/getEntitlementValues';
import { Button, FieldSelect } from 'lib';

import { formField, sectionHeader } from 'components/VerticalForm/styles.css';
import { PropTypes } from 'prop-types';
import { parseUserRoles, ROLES, STATUS_KEYS } from 'util/userRoles';
import RadioGroup from 'components/Inputs/RadioGroup';
import { countries, states } from 'constants/dropdownContent';
import {
  addressLabel,
  addressWrap,
  fieldWrapper,
  formRadioGroup,
  formRadioLabel,
  stateWrapper,
} from './styles.css';
import { FORM_NAME, GENERAL_INFO_FORM_FIELDS, USA_USA_USA } from './constants';

const acceptablePrimaryAdminUsers = u =>
  u.UserType !== 'Proxy' &&
  u.Status === STATUS_KEYS.Enabled.value &&
  parseUserRoles(u.Role) === ROLES.Administrator;

const getUserListOption = (id, firstName, lastName) => (
  <option key={id} value={id}>
    {`${lastName}, ${firstName}`}
  </option>
);

const OrganizationGeneralInfoForm = ({
  canEditGeneralInfo,
  changeFieldValue,
  currentOrg,
  dirty,
  editCustomer,
  users,
}) => {
  const [adminId, setAdminId] = useState(currentOrg.AdministratorId);
  const [adminEmail, setAdminEmail] = useState(currentOrg.AdministratorEmail);
  const [adminPhone, setAdminPhone] = useState(currentOrg.AdministratorPhone);
  const [orgName, setOrgName] = useState(currentOrg.Name);
  const [orgCountry, setOrgCountry] = useState(
    currentOrg.Country || USA_USA_USA,
  );
  const [orgCity, setOrgCity] = useState(currentOrg.City);
  const [orgState, setOrgState] = useState(currentOrg.Region);
  const [orgPostalCode, setOrgPostalCode] = useState(currentOrg.PostalCode);
  const [orgAddress1, setOrgAddress1] = useState(currentOrg.Address);
  const [orgAddress2, setOrgAddress2] = useState(currentOrg.Address2);
  const [orgPhone, setOrgPhone] = useState(currentOrg.Phone);

  const isSubmitDisabled =
    adminId === currentOrg.AdministratorId && !canEditGeneralInfo;

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const newOrg = {
        ...currentOrg,
        Address: orgAddress1,
        Address2: orgAddress2,
        AdministratorId: adminId,
        City: orgCity,
        Country: orgCountry,
        Name: orgName,
        Phone: orgPhone,
        PostalCode: orgPostalCode,
        Region: orgState,
      };

      editCustomer(newOrg, currentOrg.Id);
      currentOrg.AdministratorId = adminId;
      currentOrg.AdministratorEmail = adminEmail;
      currentOrg.AdministratorPhone = adminPhone;
    },
    [
      adminEmail,
      adminId,
      orgAddress1,
      orgAddress2,
      orgCountry,
      orgCity,
      orgState,
      orgPhone,
      orgPostalCode,
      adminPhone,
      currentOrg,
      editCustomer,
      orgName,
    ],
  );

  const handleChange = useCallback(
    (e, newVal) => {
      const newAdmin = users.find(u => u.Id === newVal);

      setAdminId(newAdmin.Id);
      setAdminEmail(newAdmin.EmailAddress);
      setAdminPhone(newAdmin.PhoneNumber);

      changeFieldValue(
        GENERAL_INFO_FORM_FIELDS.email.name,
        newAdmin.EmailAddress,
      );
      changeFieldValue(
        GENERAL_INFO_FORM_FIELDS.adminPhone.name,
        newAdmin.PhoneNumber,
      );
    },
    [changeFieldValue, users],
  );

  const handleNameChange = e => {
    setOrgName(e.target.value);
    changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgName.name, e.target.value);
  };

  const userOptions = useMemo(() => {
    if (users.length <= 0) {
      const {
        AdministratorFirstName: firstName,
        AdministratorId: id,
        AdministratorLastName: lastName,
      } = currentOrg;

      return getUserListOption(id, firstName, lastName);
    }

    return users
      .filter(acceptablePrimaryAdminUsers)
      .map(u => getUserListOption(u.Id, u.FirstName, u.LastName));
  }, [currentOrg, users]);

  return (
    <form onSubmit={handleSubmit}>
      <VerticalFormFieldLayout label="ACCOUNTS.GENERAL_INFO.ORGANIZATION_NAME">
        <Field
          className={formField}
          component={FieldInput}
          disabled={!canEditGeneralInfo}
          name={GENERAL_INFO_FORM_FIELDS.orgName.name}
          onChange={handleNameChange}
        />
      </VerticalFormFieldLayout>
      <fieldset className={addressLabel} disabled={!canEditGeneralInfo}>
        <div className={sectionHeader}>
          {<Translate id="GENERAL_MESSAGES.ADDRESS_HEADER" />}
        </div>
        <VerticalFormFieldLayout label="ADDRESS.COUNTRY">
          {canEditGeneralInfo ? (
            <Field
              className={`${formField}`}
              component={FieldSelect}
              name={GENERAL_INFO_FORM_FIELDS.orgCountry.name}
              onChange={(e, val) => {
                setOrgCountry(val);
                changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgCountry.name, val);
              }}
            >
              {countries.map(c => (
                <Translate key={c.code}>
                  {({ translate }) => (
                    <option value={c.dbValue}>{translate(c.name)}</option>
                  )}
                </Translate>
              ))}
            </Field>
          ) : (
            <Field
              className={formField}
              component={FieldInput}
              name={GENERAL_INFO_FORM_FIELDS.orgCountry.name}
            />
          )}
        </VerticalFormFieldLayout>
        <div className={fieldWrapper}>
          <div className={addressWrap}>
            <label>
              <Translate id="ADDRESS.STREET_ONE" />
            </label>
            <Field
              className={formField}
              component={FieldInput}
              name={GENERAL_INFO_FORM_FIELDS.orgAddress1.name}
              onChange={(e, val) => {
                setOrgAddress1(val);
                changeFieldValue(
                  GENERAL_INFO_FORM_FIELDS.orgAddress1.name,
                  val,
                );
              }}
            />
          </div>
          <div className={addressWrap}>
            <label>
              <Translate id="ADDRESS.STREET_TWO" />
            </label>
            <Field
              className={formField}
              component={FieldInput}
              name={GENERAL_INFO_FORM_FIELDS.orgAddress2.name}
              onChange={(e, val) => {
                setOrgAddress2(val);
                changeFieldValue(
                  GENERAL_INFO_FORM_FIELDS.orgAddress2.name,
                  val,
                );
              }}
            />
          </div>
        </div>
        <div className={fieldWrapper}>
          <div>
            <label>
              <Translate id="ADDRESS.CITY" />
            </label>
            <Field
              className={formField}
              component={FieldInput}
              name={GENERAL_INFO_FORM_FIELDS.orgCity.name}
              onChange={(e, val) => {
                setOrgCity(val);
                changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgCity.name, val);
              }}
            />
          </div>
          <div>
            <label>
              <Translate id="ADDRESS.STATE" />
            </label>
            {orgCountry !== USA_USA_USA || !canEditGeneralInfo ? (
              <Field
                className={`${formField} ${stateWrapper}`}
                component={FieldInput}
                name={GENERAL_INFO_FORM_FIELDS.orgState.name}
                onChange={(e, val) => {
                  setOrgState(val);
                  changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgState.name, val);
                }}
              />
            ) : (
              <Field
                className={`${formField} ${stateWrapper}`}
                component={FieldSelect}
                isNewVersion
                name={GENERAL_INFO_FORM_FIELDS.orgState.name}
                onChange={(e, val) => {
                  setOrgState(val);
                  changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgState.name, val);
                }}
              >
                {states.map(s => (
                  <option key={s.abbreviation} value={s.abbreviation}>
                    {s.abbreviation}
                  </option>
                ))}
              </Field>
            )}
          </div>
          <div>
            <label>
              <Translate id="ADDRESS.ZIP" />
            </label>
            <Field
              className={formField}
              component={FieldInput}
              name={GENERAL_INFO_FORM_FIELDS.orgPostalCode.name}
              onChange={(e, val) => {
                setOrgPostalCode(val);
                changeFieldValue(
                  GENERAL_INFO_FORM_FIELDS.orgPostalCode.name,
                  val,
                );
              }}
            />
          </div>
        </div>
        <label>
          <Translate id="ACCOUNTS.GENERAL_INFO.PRIMARY_ADMIN_PHONE" />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={GENERAL_INFO_FORM_FIELDS.orgPhone.name}
          onChange={(e, val) => {
            setOrgPhone(val);
            changeFieldValue(GENERAL_INFO_FORM_FIELDS.orgPhone.name, val);
          }}
        />
      </fieldset>
      <VerticalFormFieldLayout label="ACCOUNTS.GENERAL_INFO.PRIMARY_ADMIN">
        <Field
          className={formField}
          component={FieldSelect}
          name={GENERAL_INFO_FORM_FIELDS.adminId.name}
          onChange={handleChange}
          users={users}
        >
          {userOptions}
        </Field>
      </VerticalFormFieldLayout>
      <VerticalFormFieldLayout label="COMMON.EMAIL">
        <Field
          className={formField}
          component={FieldInput}
          disabled
          name={GENERAL_INFO_FORM_FIELDS.email.name}
        />
      </VerticalFormFieldLayout>
      <VerticalFormFieldLayout label="ACCOUNTS.GENERAL_INFO.PRIMARY_ADMIN_PHONE">
        <Field
          className={formField}
          component={FieldInput}
          disabled
          name={GENERAL_INFO_FORM_FIELDS.adminPhone.name}
        />
      </VerticalFormFieldLayout>

      <div className={sectionHeader}>
        {<Translate id="ACCOUNTS.GENERAL_INFO.ORGANIZATION_ENTITLEMENTS" />}
      </div>

      <div className={formRadioGroup}>
        <label className={formRadioLabel}>
          <RadioGroup
            disabled
            labelTranslateId={
              GENERAL_INFO_FORM_FIELDS.tenantEntitlements.translatedLabel
            }
            onChange={() => {}}
            options={GENERAL_INFO_FORM_FIELDS.tenantEntitlements.options}
            value={getEntitlementValues(currentOrg).HMS}
          />
        </label>
      </div>

      <Button
        disabled={isSubmitDisabled || !dirty}
        inputType="submit"
        text={<Translate id="BUTTONS.SAVE" />}
      />
    </form>
  );
};

OrganizationGeneralInfoForm.propTypes = {
  canEditGeneralInfo: PropTypes.bool,
  changeFieldValue: PropTypes.func.isRequired,
  currentOrg: PropTypes.shape({
    Address: PropTypes.string,
    Address2: PropTypes.string,
    AdministratorEmail: PropTypes.string,
    AdministratorId: PropTypes.string,
    AdministratorPhone: PropTypes.string,
    City: PropTypes.string,
    Country: PropTypes.string,
    Entitlements: PropTypes.string,
    Id: PropTypes.string,
    Name: PropTypes.string,
    Phone: PropTypes.string,
    PostalCode: PropTypes.string,
    Region: PropTypes.string,
  }),
  dirty: PropTypes.bool.isRequired,
  editCustomer: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

OrganizationGeneralInfoForm.defaultProps = {
  canEditGeneralInfo: false,
  currentOrg: {},
  users: [],
};

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: FORM_NAME,
})(OrganizationGeneralInfoForm);
