import PropTypes from 'prop-types';
import React from 'react';
import { SVG_XMLNS } from 'constants/app';

function IconNavNotifications({ className, fill, height, width }) {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <g>
        <path d="M19,16V9.75a6.6,6.6,0,0,0-5.5-6.66V2.48A1.45,1.45,0,0,0,12,1h0A1.45,1.45,0,0,0,10.5,2.48v.61A6.6,6.6,0,0,0,5,9.75V16L2,20H22Z" />
        <path d="M9.5,21s-.12,2,2.5,2,2.5-2,2.5-2Z" />
      </g>
    </svg>
  );
}
IconNavNotifications.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
IconNavNotifications.defaultProps = {
  className: null,
  fill: '#999999',
  height: '36px',
  width: '40px',
};

export default IconNavNotifications;
