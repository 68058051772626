// Libs
import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

// Components
import { CustomerDetailsTitle, CustomersTable, InputFilter } from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';

// Utilities
import * as permissions from 'util/permissions';
import FilterableContainer from 'util/FilterableContainer';

// Actions
import * as UserActions from 'actions/user';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';
import * as SubscriptionActions from 'actions/subscriptions';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';
import { showMessage } from 'actions/pageMessage';
import { deleteOrganization } from 'actions/organizations';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import { ACTION_IMAGES, FILTER_GROUPS } from 'constants/app';
import { tenantTypeStrings } from 'util/tenantTypes';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';

import { contentContainer } from 'sharedStyles/global.css';
import DealerNavMenu from './DealerNavMenu';
import AccountNavMenu from '../AccountNavMenu';
import {
  filters as filterTemplate,
  idSearchInput,
  SEARCH_PLACEHOLDER,
  orgtextInputFields as textInputFields,
} from '../constants';
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';
import SubscriberDetailsContainer from '../sites/SubscriberDetailsContainer';
import PageMessage from '../../PageMessage/PageMessage';

const MODAL_FORM_NAME = 'OrganizationCustomerContainer_AddCustomer';
const itemsPerFetch = 50;
const fetchWhenItemsLeft = 25;

class OrganizationCustomerContainer extends FilterableContainer {
  constructor(props) {
    super(props);
    this.state = {
      addedCustomerId: null,
      dealerId: null,
      queryOptions: {
        filters: [],
        skip: 0,
        sort: {
          col: 'Type,Name',
          direction: 'asc',
        },
        top: itemsPerFetch,
      },
    };
  }

  componentDidMount() {
    const { actions, isFetchingPendingRequests } = this.props;

    this.filterByRoute();
    actions.getPartners();

    if (isFetchingPendingRequests === null) {
      actions.getPendingSiteRequests();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      filterGroup,
      filterStatus,
      fullPath,
      isFetchingCustomerOrganizations,
      isFetchingServicePackages,
      modalProps,
      orgId,
    } = this.props;
    const { queryOptions } = this.state;
    if (
      modalProps.dealerId !== prevProps.modalProps.dealerId &&
      !isFetchingServicePackages
    ) {
      actions.getDealerServicePackageFamilies(modalProps.dealerId, orgId);
    }

    if (
      (filterStatus &&
        filterStatus[filterGroup] &&
        !isFetchingCustomerOrganizations &&
        prevProps.filterStatus[filterGroup] !== filterStatus[filterGroup]) ||
      prevProps.orgId !== orgId
    ) {
      this.treeifyFilters([...filterStatus[filterGroup]], queryOptions);
    }

    if (fullPath !== prevProps.fullPath) {
      this.filterByRoute();
    }
  }

  treeifyFilters = (filters, queryOptions) => {
    const {
      actions: { getCustomerOrganizations },
      tableFilterId,
    } = this.props;
    const orgFilterQueryOption = queryOptions;
    const typeFilter = filters.find(
      filter => filter.field === filterTemplate.orgs.type.field,
    );
    const otherFilters = filters.filter(
      filter => filter.field !== filterTemplate.orgs.type.field,
    );
    const filterTree = {
      filters: [
        typeFilter,
        {
          filters: otherFilters,
          filtersOperator: 'or',
        },
      ],
      filtersOperator: 'and',
    };
    orgFilterQueryOption.skip = 0;
    orgFilterQueryOption.top = 50;
    orgFilterQueryOption.filters = filterTree;
    orgFilterQueryOption.count = true;
    if (orgFilterQueryOption) {
      getCustomerOrganizations(orgFilterQueryOption, tableFilterId);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.scrollTo === null &&
      JSON.stringify(this.props) === JSON.stringify(nextProps)
    ) {
      return false;
    }
    return true;
  }

  get selectedCustomerId() {
    return this.selectedSubscriberId || this.selectedDealerId;
  }

  get selectedSubscriberId() {
    const { match } = this.props;
    return match.params.subscriberId;
  }

  get selectedDealerId() {
    const { match } = this.props;
    return match.params.dealerId;
  }

  get selectedTenantType() {
    const { fullPath } = this.props;
    if (fullPath.includes(PATH_SEGMENT_SUBSCRIBERS)) {
      return tenantTypeStrings.Subscriber;
    }
    if (fullPath.includes(PATH_SEGMENT_DEALERS)) {
      return tenantTypeStrings.Provider;
    }
    return '';
  }

  filterByRoute() {
    const { handleFilter } = this.props;
    this.handleFilter(
      filterTemplate.orgs.type.field,
      [this.selectedTenantType],
      false,
    );
    if (this.selectedDealerId) {
      handleFilter('DealerId', [this.selectedDealerId], true);
    } else {
      handleFilter('DealerId', 'All', false);
    }
  }

  handleDeleteClick = org => {
    const {
      actions: {
        deleteOrganization: deleteOrganizationAction,
        hideModal: hideModalAction,
        showModal: showModalAction,
      },
      currentInfiniteFilter,
      tableFilterId,
    } = this.props;
    const toDeleteId = org.Id;
    const toDeleteName = org.Name;
    const filter = currentInfiniteFilter.find(
      filterObj => filterObj.type === tableFilterId,
    );
    const queryOption =
      filter.queryOption !== null
        ? {
            ...filter.queryOption,
            skip: 0,
            top: filter.queryOption.skip,
          }
        : null;
    showModalAction(modalTypes.DELETE_SUBSCRIBER_MODAL, {
      onOkClick: () => {
        deleteOrganizationAction(org.Id, tableFilterId, queryOption);
        hideModalAction();
      },
      orgId: toDeleteId,
      orgName: toDeleteName,
      title: (
        <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_TITLE" />
      ),
    });
  };

  onSelectedRowsChanged = (rowsSelected, rowIndex, isDoubleClick) => {
    const { onSelectedRowsChanged } = this.props;
    if (isDoubleClick) {
      onSelectedRowsChanged(
        rowsSelected,
        rowIndex,
        false,
        this.navToNextPage, // Submits optional callback function
      );
    } else {
      this.setState({ scrollTo: null }, () =>
        onSelectedRowsChanged(rowsSelected, rowIndex),
      );
    }
  };

  navToNextPage = selectedCustomer => {
    // Set the dealer or subscriber here, then proceed.
    // Should be called on click, after props are set
    const { history } = this.props;
    let nextRoute;
    if (selectedCustomer) {
      if (selectedCustomer.Type === tenantTypeStrings.Provider) {
        // Clicked on Dealer
        nextRoute = `${PATH_SEGMENT_DEALERS}/${selectedCustomer.Id}${PATH_SEGMENT_SUBSCRIBERS}`;
      } else if (selectedCustomer.Type === tenantTypeStrings.Subscriber) {
        nextRoute = this.selectedDealerId
          ? `${PATH_SEGMENT_DEALERS}/${this.selectedDealerId}${PATH_SEGMENT_SUBSCRIBERS}/${selectedCustomer.Id}${PATH_SEGMENT_SITES}`
          : `${PATH_SEGMENT_SUBSCRIBERS}/${selectedCustomer.Id}${PATH_SEGMENT_SITES}`;
      }
    } else if (this.selectedDealerId && this.selectedSubscriberId) {
      // Back from dealer->subscriber sequence
      nextRoute = `${PATH_SEGMENT_DEALERS}/${this.selectedDealerId}`;
    } else if (this.selectedDealerId) {
      // Back from direct dealer click
      nextRoute = `${PATH_SEGMENT_DEALERS}`;
    } else {
      // Back from direct subscriber click
      nextRoute = `${PATH_SEGMENT_SUBSCRIBERS}`;
    }
    history.push(`${PATH_ACCOUNTS}${nextRoute}`);
  };

  componentWillUnmount() {
    const { handleClearFilters } = this.props;
    handleClearFilters();
  }

  renderTitle = () => {
    const { customers, fullPath } = this.props;
    const titleString = fullPath.includes(PATH_SEGMENT_SUBSCRIBERS)
      ? 'TENANTS.CUSTOMER_PLURAL'
      : 'TENANTS.PROVIDER_FULL_PLURAL';

    const customerOrg =
      customers && customers.find(c => c.Id === this.selectedDealerId);

    if (this.selectedDealerId && customerOrg) {
      return (
        <PageTitle
          onBackClick={this.navToNextPage}
          showBackButton
          title={customerOrg.Name}
        />
      );
    }

    return <PageTitle titleId={titleString} />;
  };

  handleFilter = (field, values, replaceFilters = false) => {
    const { handleFilter } = this.props;

    if (this.selectedDealerId) {
      handleFilter('DealerId', [this.selectedDealerId], true);
    }

    handleFilter(field, values, replaceFilters, 'contains');
  };

  render() {
    const {
      actions,
      canAddOrg,
      canChangeCustomer,
      canDeleteOrg,
      canViewCustomerType,
      canViewOrgControls,
      currentInfiniteFilter,
      filteredItems,
      fullPath,
      history,
      isFetchingCustomerOrganizations,
      profile,
      selectedId,
      selectedIndices,
      tableFilterId,
      userTenantType,
    } = this.props;

    const { queryOptions, scrollTo } = this.state;
    const filterElement = currentInfiniteFilter.find(
      x => x.type === tableFilterId,
    );
    let actionImage;
    if (
      this.selectedTenantType === tenantTypeStrings.Subscriber &&
      !this.selectedDealerId
    ) {
      actionImage = ACTION_IMAGES.SUBSCRIBER;
    } else if (this.selectedDealerId) {
      actionImage = ACTION_IMAGES.DEALER_SELECTED;
    } else {
      actionImage = ACTION_IMAGES.DEALER;
    }

    const orgFilterQueryOption = filterElement
      ? filterElement.queryOption
      : null;

    const isEditing = selectedId !== null;

    const items = filteredItems;

    // Not copying items into state maintains reducer as single source of truth

    // subscriberDetailCustomer assigment and calculation here is important
    // We calculate customer from filters. When you search customer and open subscriberDetail
    // and then change the name, the search text will still reamin same on organization page
    // however the selected customer is changed. The search was done by search text
    // which is now can't find renamed customer by name. So in that subscriberDetailCustomer
    // will come as empty which is incorrect as customer name was changed and it still exists
    // We enforce calculation here to make sure it is still in.
    const subscriberDetailCustomer = items.find(
      x => x.Id === this.selectedSubscriberId,
    );

    const handleAddClick = () => {
      const addSubscriberPath = this.selectedDealerId
        ? `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${this.selectedDealerId}${PATH_SEGMENT_NEW}`
        : `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}${PATH_SEGMENT_NEW}`;
      this.props.history.push(
        fullPath.includes(PATH_SEGMENT_SUBSCRIBERS)
          ? addSubscriberPath
          : `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}${PATH_SEGMENT_NEW}`,
      );
    };

    const pageContent = this.selectedSubscriberId ? (
      <SubscriberDetailsContainer
        canAddOrg={canAddOrg}
        canChangeCustomer={canChangeCustomer}
        customer={subscriberDetailCustomer}
        history={history}
        isEditing={isEditing}
        items={items}
        onBackClick={this.navToNextPage}
        onRowClicked={this.navToNextPage}
        path={fullPath}
        profile={profile}
        queryOptions={queryOptions}
        tableFilterId={tableFilterId}
        userTenantType={userTenantType}
      />
    ) : (
      <>
        <ListNav
          actionImage={actionImage}
          canAdd={canAddOrg}
          canChangeCustomer={canChangeCustomer}
          hideControls={!canViewOrgControls}
          navigationTabs={
            this.selectedDealerId ? (
              <DealerNavMenu currentOrgId={this.selectedDealerId} />
            ) : (
              <AccountNavMenu fullPath={fullPath} profile={profile} />
            )
          }
          onAddClick={handleAddClick}
          pageTitle={this.renderTitle(fullPath)}
          path={fullPath}
          profile={profile}
        >
          <>
            <InputFilter
              key={
                this.selectedDealerId
                  ? this.selectedDealerId
                  : this.getFilterValue(filterTemplate.orgs.type.field)
              }
              field={textInputFields}
              id={idSearchInput}
              onChange={this.handleFilter}
              overrideValue
              placeholder={SEARCH_PLACEHOLDER}
              value={this.getFilterValue(filterTemplate.orgs.name.field)}
            />
          </>
        </ListNav>
        <MainContentWrapper>
          <PageMessage
            messageType={[
              messageTypes.LOCATION_SUCCESS,
              messageTypes.LOCATION_ERROR,
              messageTypes.EDIT_CUSTOMER_SUCCESS,
              messageTypes.CUSTOMER_ERROR,
              messageTypes.CUSTOMER_SUCCESS,
              messageTypes.DELETE_ORGANIZATION,
            ]}
          />
          <EmptyPlaceholder
            isFetching={isFetchingCustomerOrganizations && items.length === 0}
            items={items}
            translateKey="FILTER.NO_RESULTS_FOUND"
          >
            <CustomersTable
              canDeleteOrg={canDeleteOrg}
              customers={items}
              fetchNextPageWhenAlarmLeft={fetchWhenItemsLeft}
              getNextPage={actions.getCustomerOrganizations}
              handleDeleteClick={this.handleDeleteClick}
              isFetchingNextPage={isFetchingCustomerOrganizations}
              nextPageURL={orgFilterQueryOption}
              onClickedRow={this.navToNextPage}
              onSelectedRowsChanged={this.onSelectedRowsChanged}
              rowsSelected={selectedIndices}
              scrollTo={scrollTo}
              showCustomerColumn={canViewCustomerType}
              tableFilterId={tableFilterId}
            />
          </EmptyPlaceholder>
        </MainContentWrapper>
      </>
    );
    return <div className={contentContainer}>{pageContent}</div>;
  }
}

OrganizationCustomerContainer.defaultProps = {
  actionMenu: {},
  customers: [],
  dealerServicePackages: [],
  tableFilterId: 'organizationCustomer',
};

function mapStateToProps(state, ownProps) {
  const { fullPath, match } = ownProps;
  const orgId = permissions.getOrgIdFromStore(state);
  const filterGroup = match.params.dealerId
    ? FILTER_GROUPS.ORG_DEALER
    : fullPath.includes(PATH_SEGMENT_SUBSCRIBERS)
    ? FILTER_GROUPS.ORG_SUBSCRIBERS
    : FILTER_GROUPS.ORG_DEALERS;
  return {
    actionMenu: state.actionMenu,
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canAddOrg: state.user.permissions.CAN_CREATE_ORG,
    canDeleteOrg: state.user.permissions.CAN_DELETE_ORG,
    canEditOrg: state.user.permissions.CAN_EDIT_ORG,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    canViewCustomerType: state.user.permissions.CAN_HAVE_DEALER_CUSTOMERS,
    canViewOrgControls: state.user.permissions.CAN_VIEW_ORG_CONTROLS,
    currentInfiniteFilter: state.filters.infiniteWithoutOData,
    currentOrganization: state.user.currentOrganization,
    customers: state.user.customers,
    dealerServicePackages: state.locations.dealerServicePackages,
    filterGroup,
    filterStatus: state.filters.filterStatus,
    isFetchingCustomer: state.isFetchingCustomer,
    isFetchingCustomerOrganizations: state.user.isFetchingCustomerOrganizations,
    isFetchingLocations: state.locations.isFetchingLocationData,
    isFetchingPendingRequests: state.locations.isFetchingPendingSiteRequests,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    items: state.user.customerOrganizations,
    locationModalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_SITE_TO_ADDED_ORG,
    modalIsOpen:
      state.modal.isOpen && state.modal.modalType === MODAL_FORM_NAME,
    modalProps: state.modal.modalProps,
    orgId,
    orgSubscriptions: state.locations.orgSubscriptions,
    profile: state.user.profile,
    selectedLocationId: state.modal.modalProps.locationId,
    timezones: state.utilities.timezones,
    userTenantType:
      state.user.profile.CustomerTenantType || state.user.profile.TenantType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...UserActions,
        ...LocationActions,
        ...FilterActions,
        ...SubscriptionActions,
        deleteOrganization,
        hideModal,
        showMessage,
        showModal,
      },
      dispatch,
    ),
  };
}

export default withLocalize(
  NavigationWrapper(
    withRouter(OrganizationCustomerContainer),
    mapStateToProps,
    mapDispatchToProps,
    MODAL_FORM_NAME, // modal name
    { textInputFields },
  ),
);
