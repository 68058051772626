exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".titleWrapper___VNCra {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-line-pack: center;\r\n      align-content: center;\r\n}\r\n\r\n.backWrapper___3R5Df {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  padding-right: 12px;\r\n}\r\n\r\n.backButton___iXZ5g {\r\n  border: none;\r\n  background-color: transparent;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  cursor: pointer;\r\n}\r\n\r\n.listNavTitle___3qZcs {\r\n  font-family: Arial;\r\n  font-size: 30px;\r\n  font-weight: 400;\r\n  line-height: 32px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/BackButtonWithTitle/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,sBAAsB;CAC3B;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,uBAAuB;MACnB,oBAAoB;EACxB,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,8BAA8B;EAC9B,4BAA4B;EAC5B,qBAAqB;EACrB,uBAAuB;MACnB,oBAAoB;EACxB,sBAAsB;MAClB,wBAAwB;EAC5B,gBAAgB;CACjB;;AAED;EACE,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".titleWrapper {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-line-pack: center;\r\n      align-content: center;\r\n}\r\n\r\n.backWrapper {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  padding-right: 12px;\r\n}\r\n\r\n.backButton {\r\n  border: none;\r\n  background-color: transparent;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  cursor: pointer;\r\n}\r\n\r\n.listNavTitle {\r\n  font-family: Arial;\r\n  font-size: 30px;\r\n  font-weight: 400;\r\n  line-height: 32px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"titleWrapper": "titleWrapper___VNCra",
	"backWrapper": "backWrapper___3R5Df",
	"backButton": "backButton___iXZ5g",
	"listNavTitle": "listNavTitle___3qZcs"
};