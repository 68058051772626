import React from 'react';
import { Translate } from 'react-localize-redux';
import get from 'lodash.get';

// Components
import { EmptyPlaceholder, ListView } from 'lib';

// Styles
import { PropTypes } from 'prop-types';
import { accessTable } from './styles.css';

// Constants
import * as consts from './constants';

function AccessTable(props) {
  const { accessData, canViewSites } = props;
  const rowData =
    (accessData &&
      accessData.map(element => {
        return {
          HealthSchedule: get(element, 'HealthSchedule.Name'),
          Location: get(element, 'Location.Name'),
          SecuritySchedule:
            element &&
            element.SecuritySchedule &&
            element.SecuritySchedule.Name,
        };
      })) ||
    [];
  return (
    <div className={accessTable}>
      <EmptyPlaceholder
        isFetching={false}
        items={rowData}
        string={
          canViewSites ? (
            <Translate id="FILTER.NO_RESULTS_FOUND" />
          ) : (
            <Translate id="PROFILE.NOT_APPLICABLE" />
          )
        }
      >
        <ListView
          data={rowData}
          fieldOrder={consts.ACCESS_TABLE.fieldOrder}
          headerTranslationIds={consts.ACCESS_TABLE.headerTranslationIds}
          hideFilter
        />
      </EmptyPlaceholder>
    </div>
  );
}

AccessTable.defaultProps = {
  accessData: [],
};

AccessTable.propTypes = {
  accessData: PropTypes.arrayOf(PropTypes.any),
  canViewSites: PropTypes.bool.isRequired,
};

export default AccessTable;
