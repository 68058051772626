import { FORM_DEFAULTS } from 'constants/app';

export const FORM_NAME = 'siteForm';

// Ids
export const idLocationName = 'LocationName';
export const idCountry = 'Country';
export const idStreet = 'Street1';
export const idStreet2 = 'Street 2';
export const idRegionDropdown = 'RegionDropdown';
export const idCity = 'City';
export const idTimezone = 'Timezone';
export const idPhone = 'Phone';
export const idUser = 'User';
export const idPostalCode = 'PostalCode';

// Field names
export const nameBillingNumber = 'BillingNumber';
export const nameCity = 'City';
export const nameCountry = 'Country';
export const nameId = 'Id';
export const nameLocationName = 'Name';
export const nameMonitoring = 'DealerMonitoringEnabled';
export const namePhone = 'PhoneNumber';
export const namePostalCode = 'PostalCode';
export const namePurchaseOrderNumber = 'PurchaseOrderNumber';
export const nameRegion = 'Region';
export const nameSkuId = 'ServicePackageSkuId';
export const nameStreet = 'Address';
export const nameStreet2 = 'Address2';
export const nameSubscriptionFamily = 'ServicePackageFamily';
export const nameSubscriptionTermLength = 'SubscriptionTermLength';
export const nameSummary = 'summary';
export const nameTimeZone = 'TimeZone';
export const nameExtendTrialTermLength = 'ExtendTrialTermLength';

export const AdminUserId = 'AdminUserId';
export const adminUserNew = FORM_DEFAULTS.new;

export const buttonTextCancel = 'Cancel';
export const buttonTextSave = 'Save';
export const buttonTextAdd = 'Add';

export const subscriptionTermOptions = [
  { family: 'BlueSelfMonitoring', label: 'Beta', value: 3 },
  { family: 'AccCloudServices', label: 'ACC Beta', value: 4 },
  { family: 'BlueSelfMonitoring', label: '1 Year', value: 12 },
  { family: 'AccCloudServices', label: '1 Year', value: 12 },
  { family: 'BlueSelfMonitoring', label: '2 Years', value: 24 },
  { family: 'BlueSelfMonitoring', label: '3 Years', value: 36 },
  { family: 'BlueSelfMonitoring', label: '4 Years', value: 48 },
  { family: 'BlueSelfMonitoring', label: '5 Years', value: 60 },
];

const alwaysRequiredFields = [
  'Name',
  'Country',
  'City',
  'Region',
  'PostalCode',
  'TimeZone',
  'PhoneNumber',
  'ServicePackageSkuId',
];

export const newAdminFields = [
  'AdminFirstName',
  'AdminLastName',
  'AdminEmailAddress',
  'AdminTimeZone',
];

export const requiredExistingAdminFields = [
  ...alwaysRequiredFields,
  'AdminUserId',
];

export const requiredNewAdminFields = [
  ...alwaysRequiredFields,
  ...newAdminFields,
];

export const USA = 'United States';

export const defaultTermLength = 3;
export const demoSubscriptionSkuId = 'BLU-DEMO-C008-S030';
export const trialSubscriptionSkuId = 'BLU-C000-S030';
export const placeholderSubscriptionType = 'Subscription Type Options';
export const placeholderSubscriptionTermOptions = 'Renewal Period Options';
export const placeholderMonitoringOptions = 'Monitoring Options';
export const activatedPackage = 'Activated';
export const expiredPackage = 'Expired';

export const monitoringOptions = {
  health: 'Health monitoring',
  off: 'Off',
};

export const nonRenewableSubscriptionSkuIds = [trialSubscriptionSkuId];
export const extendableTrialSiteStates = [activatedPackage, expiredPackage];
