import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';

// Actions
import * as DeviceActions from 'actions/devices';
import * as UserActions from 'actions/user';
import Logo from 'containers/Login/Logo';

// Constants

// Components
import { BusyIndicator, Footer } from 'components';
import { Button } from 'lib';

// Styles
import {
  appContainer,
  wizardScreen,
  wizardContainer,
  wizardForm,
  wizardFooter,
  wizardLogo,
  wizardHeader,
  wizardHeaderLeft,
  wizardButtonsLeft,
  wizardButtonsLeftButton,
  wizardFormBody,
  wizardPlainText,
} from 'sharedStyles/styles.css';
import { codeContainer, code } from './styles.css';
import * as claimConsts from './constants';

class GuestClaimDeviceFormContainer extends Component {
  getClaimKey() {
    // doesnt require authentication
    this.props.actions.createClaimKey(this.props.match.params.id);
  }

  render() {
    // 4 possible states:  error, claim key exists, is fetching, or default(need to be able to get key)
    let content;
    const { error } = this.props;
    if (error) {
      content = (
        <div>
          <div>
            <Translate
              id="DEVICES.GUEST_DEVICE_CLAIM.ERROR_CODE_LABEL"
              data={{
                errorCode: error.status,
              }}
            />
          </div>
          <div>
            <Translate
              id="DEVICES.GUEST_DEVICE_CLAIM.ERROR_MESSAGE_LABEL"
              data={{
                errorMessage: error.message,
              }}
            />
          </div>
          <div>
            <Translate id="DEVICES.GUEST_DEVICE_CLAIM.ERROR_MESSAGE_GENERAL" />
          </div>
        </div>
      );
    } else if (this.props.claimingKey) {
      content = (
        <div>
          <div className={codeContainer}>
            <span className={code}>
              <Translate id="DEVICES.GUEST_DEVICE_CLAIM.ACTIVATION_CODE_LABEL" />
            </span>
            <span className={code}> {this.props.claimingKey} </span>
          </div>
        </div>
      );
    } else if (this.props.isFetching) {
      content = <BusyIndicator />;
    } else {
      content = (
        <div className={wizardButtonsLeftButton}>
          <Button
            buttonType={claimConsts.GUEST_ACTIVATION_BUTTON.type}
            id={claimConsts.GUEST_ACTIVATION_BUTTON.id}
            inputType="button"
            onClick={this.getClaimKey.bind(this)}
            text={
              <Translate id="DEVICES.GUEST_DEVICE_CLAIM.GUEST_ACTIVATE_BUTTON_TEXT" />
            }
          />
        </div>
      );
    }
    return (
      <div className={appContainer}>
        <div className={wizardScreen}>
          <div className={wizardContainer}>
            <div className={wizardLogo}>
              <Logo />
            </div>
            <div className={wizardForm}>
              <div className={wizardHeader}>
                <div className={wizardHeaderLeft}>
                  <Translate id="DEVICES.GUEST_DEVICE_CLAIM.GUEST_HEADER_LABEL" />
                </div>
              </div>
              <div className={wizardFormBody}>
                <div className={wizardPlainText}>
                  <Translate id="DEVICES.GUEST_DEVICE_CLAIM.GUEST_BODY" />
                </div>
                <div className={wizardButtonsLeft}> {content} </div>
              </div>
            </div>
            <Footer className={wizardFooter} />
          </div>
        </div>
      </div>
    );
  }
}

GuestClaimDeviceFormContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  // parentId: PropTypes.string,
  params: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

GuestClaimDeviceFormContainer.defaultProps = {
  isFetching: false,
  claimingKey: undefined,
  error: undefined,
};

function mapStateToProps(state, ownProps) {
  const serverId = ownProps.match.params.id;
  const obj = state.devices.claimKeys[serverId];
  let claimingKey;
  if (obj) {
    claimingKey = obj.claimKey;
  }
  return {
    isAuthenticated: state.user.isAuthenticated,
    claimingKey,
    error: state.devices.claimError,
    isFetching: state.devices.isFetchingDeviceData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, DeviceActions, UserActions),
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuestClaimDeviceFormContainer);
