import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from 'lib';
import * as styles from './Oasis.css';

function Settings({ moduleState, updateModuleState }) {
  let modules;
  if (process.env.API_ENV === 'dev') {
    modules = [
      'oasis-dev-flags',
      'oasis-environment',
      'oasis-tenant',
      'oasis-fetches',
      'oasis-signals',
      'oasis-debug',
    ];
  } else {
    modules = [
      'oasis-tenant',
      'oasis-fetches',
      'oasis-signals',
      'oasis-debug',
      'oasis-dev-flags',
    ];
  }
  const displayNames = {
    'oasis-debug': 'Debug Messages',
    'oasis-dev-flags': 'Developer Flags',
    'oasis-environment': 'Data Source Environment',
    'oasis-fetches': 'isFetching Status',
    'oasis-signals': 'Signals Received',
    'oasis-tenant': 'Logged In Tenant',
  };

  function toggleModule(moduleName) {
    let newActiveState;
    const existingState =
      moduleState[moduleName] && moduleState[moduleName].active;
    if (existingState !== undefined) {
      newActiveState = !existingState;
    } else {
      newActiveState = true;
    }
    updateModuleState(moduleName, 'active', newActiveState);
  }


  return (
    <AccordionItem
      canToggle={false}
      isExpanded
      theme="gray"
      title="Oasis Settings"
    >
      <div className={styles.settings}>
        <div className={styles.settingsHeader}>Active Modules</div>
        <ul className={styles.moduleList}>
          {modules.map(module => (
            <li
              className={
                moduleState[module] && moduleState[module].active
                  ? styles.active
                  : ''
              }
              key={module}
              onClick={() => {
                toggleModule(module);
              }}
            >
              {displayNames[module]}
            </li>
          ))}
        </ul>
      </div>
    </AccordionItem>
  );
}

Settings.propTypes = {
  moduleState: PropTypes.objectOf(PropTypes.any).isRequired,
  updateModuleState: PropTypes.func.isRequired,
};

export default Settings;
