import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import {
  checkboxContainer,
  checkboxCustom,
  checkboxLabel,
  alignBaseline,
  truncatedLabel,
} from './styles.css';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      checked: nextProps.checked,
    });
  }

  toggleCheckboxChange = () => {
    const { onChange, label } = this.props;
    const checked = !this.state.checked;

    this.setState({ checked });

    onChange(checked, label);
  };

  render() {
    const {
      alignBaseline: baseline,
      customClass,
      id,
      label,
      labelTranslateId,
      truncate,
    } = this.props;
    const { checked } = this.state;

    return (
      <div className={`${checkboxContainer} ${customClass}`}>
        <label>
          <input
            id={id}
            type="checkbox"
            value={label}
            checked={checked}
            onChange={this.toggleCheckboxChange}
          />
          <span className={checkboxCustom} />
          <span
            className={`${checkboxLabel} ${truncate ? truncatedLabel : ''} ${
              baseline ? alignBaseline : ''
            }`}
          >
            {labelTranslateId ? <Translate id={labelTranslateId} /> : label}
          </span>
        </label>
      </div>
    );
  }
}

Checkbox.defaultProps = {
  alignBaseline: false,
  checked: false,
  customClass: '',
  label: '',
  truncate: false,
};

Checkbox.propTypes = {
  alignBaseline: PropTypes.bool,
  checked: PropTypes.bool,
  customClass: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelTranslateId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  truncate: PropTypes.bool,
};

export default Checkbox;
