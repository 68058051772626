import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import RadioGroup from 'components/Inputs/RadioGroup';
import { FormGroup } from 'lib';

const MonitoringField = props => {
  const { disabled, monitoringChanged, value } = props;

  return (
    <FormGroup label={<Translate id="LOCATIONS.LOCATION_FORM.MONITORING" />}>
      <RadioGroup
        disabled={disabled}
        onChange={monitoringChanged}
        options={[
          {
            translateId: 'COMMON.YES',
            value: true,
          },
          {
            translateId: 'COMMON.NO',
            value: false,
          },
        ]}
        value={value}
      />
    </FormGroup>
  );
};

MonitoringField.defaultProps = {
  value: false,
};

MonitoringField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  monitoringChanged: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

export default MonitoringField;
