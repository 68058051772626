// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components

import { Button, Icon, ListView } from 'lib';
import { IC_TRASH } from 'constants/iconNames';

// Styles
import { roleIndicatorPrimary } from 'sharedStyles/global.css';

// Consts
import { BULK_ACTION_TABLES, USER_TYPES } from 'constants/app';
import * as UserConsts from './constants';

// Class
class UserTable extends Component {
  handleRowClick = data => {
    const { history } = this.props;
    history.push(`/accounts/users/${data.Id}`);
  };

  handleInfiniteScroll = () => {
    const { getNextPage, nextPageURL } = this.props;
    nextPageURL.skip += 50;
    getNextPage(nextPageURL);
  };

  render() {
    const {
      isFetchingNextPage,
      locations,
      onDeleteClick,
      profile,
      rowsSelected,
      schedules,
      showSchedules,
      showSites,
      translate,
      users,
    } = this.props;
    let fieldOrder = ['LastName', 'FirstName', 'EmailAddress', 'Role'];
    if (showSites) {
      fieldOrder.push('location');
    }
    if (showSchedules) {
      fieldOrder.push('securitySchedule');
      fieldOrder.push('healthSchedule');
    }
    fieldOrder = [...fieldOrder, 'Status'];
    const cardFieldOrder = ['Name', 'EmailAddress', 'Role', 'Status'];
    const {
      fieldEmailAddr,
      fieldGroupId,
      fieldHealthSchedule,
      fieldPolicy,
      fieldSecuritySchedule,
      fieldType,
    } = UserConsts.columnDataFields;
    const parseDataMap = (map, dataArray, groupId) =>
      dataArray.map(row => {
        const data = map.find(item => item.Id === row[groupId]);
        return (
          data &&
          data.Name && (
            <div key={`usertable-loc-${row.GroupId}`}>{data.Name}</div>
          )
        );
      });

    const isProtectedUser = userData =>
      userData &&
      (userData.UserType === USER_TYPES.proxy ||
        userData.IsPrimaryAdmin ||
        userData.Id === profile.Id);

    const conditionalData = (data, user, expression) =>
      expression(user) && data;

    const data = users.map(user => ({
      ...user,
      emailAddress: conditionalData(
        user[fieldEmailAddr],
        user[fieldType],
        userType => userType !== USER_TYPES.proxy,
      ),
      healthSchedule:
        user[fieldPolicy] !== null &&
        parseDataMap(schedules, user[fieldPolicy], fieldHealthSchedule),
      location:
        user[fieldPolicy] !== null &&
        parseDataMap(locations, user[fieldPolicy], fieldGroupId),
      securitySchedule:
        user[fieldPolicy] !== null &&
        parseDataMap(schedules, user[fieldPolicy], fieldSecuritySchedule),
    }));

    const CUSTOM_CELLS = {
      Health: user =>
        user[fieldPolicy] !== null &&
        listItems(
          parseDataMap(schedules, user[fieldPolicy], fieldHealthSchedule),
        ),
      Name: rowData => {
        return (
          <>
            {rowData.FirstName} {rowData.LastName}
          </>
        );
      },
      Role: rowData => {
        let roleIndicatorId = '';
        if (rowData.IsPrimaryAdmin) {
          roleIndicatorId = 'USERS.PRIMARY';
        } else if (rowData.isProxyUser) {
          roleIndicatorId = 'USERS.SYSTEM_USER';
        }
        return (
          <>
            {rowData.Role}
            {roleIndicatorId !== '' ? (
              <span className={roleIndicatorPrimary}>
                {translate(roleIndicatorId)}
              </span>
            ) : (
              ''
            )}
          </>
        );
      },
      Security: user =>
        user[fieldPolicy] !== null &&
        listItems(
          parseDataMap(schedules, user[fieldPolicy], fieldSecuritySchedule),
        ),
      Site: user =>
        user[fieldPolicy] !== null &&
        listItems(parseDataMap(locations, user[fieldPolicy], fieldGroupId)),
    };

    const isSelected = row => rowsSelected.includes(row.Id);

    const ROW_ACTIONS = [
      ({ rowData }) => {
        const { black, white } = UserConsts.iconColors;
        return !isProtectedUser(rowData) ? (
          <Button
            icon
            onClick={() => {
              onDeleteClick([rowData.Id]);
            }}
          >
            <Icon
              color={isSelected(rowData) ? white : black}
              id={IC_TRASH}
              title="BUTTONS.DELETE"
            />
          </Button>
        ) : (
          ''
        );
      },
    ];

    return (
      <ListView
        bodyHeight={UserConsts.userTableHeight}
        bulkActions
        bulkActionsTable={BULK_ACTION_TABLES.USERS}
        cardFieldOrder={cardFieldOrder}
        cellWidths={{ Actions: 50, Schedule: 400 }}
        clickableRows
        customCells={CUSTOM_CELLS}
        data={data}
        fieldOrder={fieldOrder}
        headerTranslationIds={UserConsts.columnHeader}
        hideBulkActionsWhen={isProtectedUser}
        hideFilter
        infiniteScroll
        isFetchingMoreData={isFetchingNextPage}
        onInfiniteScroll={this.handleInfiniteScroll}
        resizable={false}
        rowActions={ROW_ACTIONS}
        rowClickCallback={this.handleRowClick}
        rowsSelected={rowsSelected}
        tableBodyStyle={null}
        truncatedFields={fieldOrder}
      />
    );
  }
}

UserTable.propTypes = {
  getNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  nextPageURL: PropTypes.objectOf(PropTypes.any),
  onDeleteClick: PropTypes.func,
  onRowClicked: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any),
  rowsSelected: PropTypes.arrayOf(PropTypes.number),
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  showSchedules: PropTypes.bool,
  showSites: PropTypes.bool,
  translate: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UserTable.defaultProps = {
  getNextPage: () => {},
  isFetchingNextPage: false,
  nextPageURL: {},
  onDeleteClick: () => {},
  onRowClicked: () => {},
  profile: {},
  rowsSelected: [],
  showSchedules: true,
  showSites: true,
  translate: () => {},
};

export default withRouter(UserTable);
