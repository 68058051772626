import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnFocusInfinity = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>{props.title}</title>
      <defs>
        <rect
          id="path-1"
          x="107"
          y="192"
          width="1186.57422"
          height="683.140625"
        />
        <rect
          id="path-2"
          x="1045"
          y="482"
          width="225.015625"
          height="30"
          rx="3"
        />
        <filter
          x="-0.2%"
          y="-1.7%"
          width="100.4%"
          height="106.7%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feComposite
            in="shadowOffsetOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <filter
          x="-0.4%"
          y="-3.3%"
          width="100.9%"
          height="110.0%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur
            stdDeviation="0.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="-1"
            dy="-1"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.419893569 0"
            type="matrix"
            in="shadowInnerInner1"
          />
        </filter>
      </defs>
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1233.000000, -490.000000)"
      >
        <polygon
          id="light-gray-3"
          fill="#F8F8F8"
          points="80 79 1319.59375 79.8752371 1319.59375 904.355469 80 904.355469"
        />
        <g id="Rectangle-3">
          <use fill="#FFFFFF" fillRule="evenodd" />
          <rect
            stroke="#DDDDDD"
            strokeWidth="1"
            x="107.5"
            y="192.5"
            width="1185.57422"
            height="682.140625"
          />
        </g>
        <rect
          id="Rectangle-2"
          fill="#FBFBFB"
          x="884"
          y="194"
          width="409"
          height="680"
        />
        <g id="Rectangle-22-Copy">
          <use fill="black" fillOpacity="1" filter="url(#filter-3)" />
          <use fill="#F8F8F8" fillRule="evenodd" />
          <use fill="black" fillOpacity="1" filter="url(#filter-4)" />
          <rect
            stroke="#CBCBCB"
            strokeWidth="1"
            x="1045.5"
            y="482.5"
            width="224.015625"
            height="29"
            rx="3"
          />
        </g>
        <g
          id="icon-btn-focus-infinity"
          transform="translate(1233.000000, 490.000000)"
          stroke="#006e99"
          strokeWidth="2"
        >
          <ellipse id="Oval-3" cx="16.5" cy="7" rx="6.5" ry="6" />
          <ellipse id="Oval-3" cx="7.5" cy="7" rx="6.5" ry="6" />
        </g>
      </g>
    </svg>
  );
};

IconBtnFocusInfinity.defaultProps = {
  className: null,
  width: '24px',
  height: '14px',
  fill: '#006e99',
  title: '',
};

export default IconBtnFocusInfinity;
