// Libs
import React, { Component } from 'react';
import moment from 'moment-timezone-all';
import colorGen from 'color-generator';
import { Translate } from 'react-localize-redux';
import renderIf from 'render-if';
import PropTypes from 'prop-types';

// Utilities
import { Checkbox, LineChartComponent } from 'components';
import { EmptyPlaceholder } from 'lib';
import {
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  getShortFormatForActiveLocale,
} from 'util/convertTimeTo';
import { getActiveLanguageCode } from 'util/languagesUtils';
import { alarmFilterFieldsMap } from 'util/filterUtils';
import {
  DATABASE_DATETIME_EXTRACT_FORMAT,
  DATE_FORMAT_YYYY_MM_DD,
} from 'constants/app';
import * as NotificationsConsts from 'containers/Notifications/constants';
import { chartHeaderText, tableDataRows } from './utils';

// Components

// Styles
import {
  dashboardContent,
  detailBox,
  lineGraph,
  lineGraphContent,
  lineGraphHeader,
  lineGraphSelectedLegend,
  lineGraphWarning,
  summaryTable,
  summaryTableContent,
  summaryTableHeader,
  summaryTableName,
  summaryTableNumber,
  summaryTableRow,
} from './styles.css';

// Constants
import {
  COLOR_ARRAY,
  dashboardIds,
  ROW_HEIGHT,
  unitsOfTime,
  valKey,
} from './constants';

class NotificationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorArray: COLOR_ARRAY,
      linesHidden: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { chartData } = this.props;
    const { colorArray } = this.state;
    if (chartData && colorArray.length < chartData.length) {
      const oldArray = colorArray.slice();
      this.setState({
        colorArray: this.getColorArray(
          chartData.length, // validate this change
          oldArray,
        ),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { colorArray } = this.state;
    const { chartData, maximumLines } = this.props;
    if (nextProps.chartData && colorArray.length < nextProps.chartData.length) {
      const oldArray = colorArray.slice();
      this.setState({
        colorArray: this.getColorArray(nextProps.chartData.length, oldArray),
      });
    }
    if (
      nextProps.chartData &&
      nextProps.chartData.length !== chartData.length
    ) {
      this.setState({
        linesHidden: maximumLines < nextProps.chartData.length,
      });
    }
  }

  getVal = datum => datum[valKey];

  getX = datum => {
    const x = moment(datum.Period, DATABASE_DATETIME_EXTRACT_FORMAT);
    return x.toDate();
  };

  xString = datum => {
    const { chartUnits, profileTimeZone } = this.props;
    const x = datum.LabelPeriod ? datum.LabelPeriod : moment(this.getX(datum));
    let result;
    if (x.isValid()) {
      result = moment(
        moment.tz(x, this.xStringFormat(chartUnits), profileTimeZone),
      ).format(this.xStringFormat(chartUnits));
    } else {
      result = moment();
    }
    return result;
  };

  xStringFormat = units => {
    const { profileLongDateFormat, profileTimeFormat } = this.props;
    const result =
      units === unitsOfTime.days
        ? getShortFormatForActiveLocale()
        : getFormatForActiveLocale();
    return (
      getPreferredLongDateTimeFormat(
        profileLongDateFormat,
        profileTimeFormat,
      ) || result
    );
  };

  xFromString = xString => {
    const { chartUnits, profileTimeZone } = this.props;
    const dateToMoment = moment.tz(
      xString,
      this.xStringFormat(chartUnits),
      profileTimeZone,
    );
    return moment(dateToMoment.utc());
  };

  getY = datum => datum.Total;

  yString = datum => this.getY(datum).toString();

  getFilterRange = datum => [
    datum.FilterInfo.BeginDate
      ? moment(datum.FilterInfo.BeginDate, DATE_FORMAT_YYYY_MM_DD)
      : moment(),
    datum.FilterInfo.EndDate
      ? moment(datum.FilterInfo.EndDate, DATE_FORMAT_YYYY_MM_DD)
      : moment(),
  ];

  getFilterValue = datum => datum.FilterInfo.FilterCondition;

  getFilterName = datum => {
    const { stateFilters } = this.props;
    const filterType = datum.FilterInfo.FilterType;
    const filterValue = this.getFilterValue(datum);
    if (filterType === 'Rule') {
      return filterValue;
    }
    const filterMapRow = alarmFilterFieldsMap.find(
      r => r.chartFilterField === filterType || r.chartLabel === filterType,
    );
    if (
      filterMapRow &&
      stateFilters[filterMapRow.stateFilterKey] &&
      stateFilters[filterMapRow.stateFilterKey].find(
        f => f.value === filterValue,
      )
    ) {
      return stateFilters[filterMapRow.stateFilterKey].find(
        f => f.value === filterValue,
      ).text;
    }
    return undefined;
  };

  getFilterType = datum => datum.FilterInfo.FilterType;

  getColorArray = (length, oldArray) => {
    const colorArray = oldArray || [];
    while (colorArray.length < length) {
      colorArray.push(colorGen().hexString());
    }
    return colorArray;
  };

  handleComparePeriods = state => {
    const { chartRange, onComparePeriods } = this.props;
    const comparePeriodsIsChecked = state;
    this.setState({ comparePeriodsIsChecked });
    if (comparePeriodsIsChecked) {
      const [beginTime, endTime] = this.roundChartRange(chartRange);
      const periodLength = endTime.diff(beginTime, unitsOfTime.days) + 1;
      const beginPriorPeriod = beginTime
        .subtract(periodLength, unitsOfTime.days)
        .format();
      onComparePeriods(beginPriorPeriod);
    } else {
      onComparePeriods(null);
    }
  };

  getTableData = tableDataRaw => {
    const tableDataKeys = Object.keys(tableDataRows);
    return tableDataKeys.map(key => {
      if (tableDataRows[key]) {
        return {
          active: tableDataRows[key].field !== null,
          key,
          name: tableDataRows[key].label,
          number: tableDataRaw[key]
            ? tableDataRows[key].getValue(tableDataRaw[key], tableDataRaw)
            : '',
        };
      }
      return undefined;
    });
  };

  handleSummaryClick = (key, active) => {
    const { changeFilters, tableData } = this.props;
    if (active) {
      // const yesterday = this.roundChartRange(chartRange)[0].subtract(1, 'day');
      const typeFilter = {
        field: tableDataRows[key].field,
        typeOfValue: tableDataRows[key].typeOfValue,
        values: tableDataRows[key].getFilterValues(tableData),
      };
      const resetFilters = [];
      const tableDataKeys = Object.keys(tableDataRows);
      tableDataKeys.forEach(tableDataKey => {
        if (
          tableDataRows[tableDataKey] &&
          tableDataRows[tableDataKey].field &&
          tableDataRows[tableDataKey].field !== tableDataRows[key].field
        ) {
          resetFilters.push({
            field: tableDataRows[tableDataKey].field,
            values: 'All',
          });
          // Clear fields not currently in use
        }
      });
      changeFilters([typeFilter, ...resetFilters]);
    }
  };

  handleTooltipClick = (_key, pointData, _lineData, lineIndex) => {
    const { changeFilters, chartUnits } = this.props;
    const startTime = this.xFromString(pointData.xString);
    const endTime = startTime.clone().add(1, chartUnits);
    const { chartData } = this.props;
    const { stateFilters } = this.props;
    if (chartData[lineIndex]) {
      const filterName = this.getFilterValue(chartData[lineIndex]);
      const filterType = this.getFilterType(chartData[lineIndex]);
      // Convert to list filter field and values
      const filterMapRow = alarmFilterFieldsMap.find(
        r => r.chartFilterField === filterType || r.chartLabel === filterType,
      );
      let lineFilter = {};
      if (
        filterMapRow &&
        filterMapRow.listFilterField &&
        filterMapRow.stateFilterKey &&
        stateFilters[filterMapRow.stateFilterKey] &&
        stateFilters[filterMapRow.stateFilterKey].find(
          f => f.text.trim() === filterName.trim() || f.value === filterName,
        )
      ) {
        lineFilter = {
          field: filterMapRow.listFilterField,
          values: [
            stateFilters[filterMapRow.stateFilterKey].find(
              f =>
                f.text.trim() === filterName.trim() || f.value === filterName,
            ).value,
          ],
        };
      }
      const filters = [
        { field: 'Created', operator: 'le', values: [endTime.format()] },
        { field: 'Created', operator: 'ge', values: [startTime.format()] },
        lineFilter,
      ];
      changeFilters(filters);
    }
  };

  getContentHeight = tableData => ROW_HEIGHT * tableData.length;

  limitChartData = chartData => {
    const { maximumLines } = this.props;
    if (maximumLines && maximumLines > 0) {
      return chartData.slice(0, maximumLines);
    }
    return undefined;
  };

  addZeroes = chartData => {
    const { chartRange, chartUnits } = this.props;
    const range = this.roundChartRange(chartRange);
    const units = chartUnits;
    const [currentTime, endTime] = range;
    const timeArray = [];
    let i = 0;
    while (
      (currentTime.isBefore(endTime) || currentTime.isSame(endTime, units)) &&
      i < 500
    ) {
      timeArray.push(currentTime.clone().utc());
      currentTime.add(1, units);
      i += 1;
      // Incrementing i prevents this from becoming an infinite loop if given bad input
    }
    const dataWithZeroes = chartData.map(chartLine => {
      const filterRange = this.getFilterRange(chartLine);
      const periodLength = filterRange[1].diff(filterRange[0], units);
      const isPreviousPeriod = filterRange[1].isSameOrBefore(
        timeArray[0],
        units,
      );
      return {
        ...chartLine,
        [valKey]: timeArray.map(dateTime => {
          const matchCoordinate = chartLine[valKey].find(f => {
            const xAxisPeriod = isPreviousPeriod
              ? moment(this.getX(f)).add(periodLength, units)
              : this.getX(f);
            return dateTime.isSame(xAxisPeriod, units);
          });
          return {
            LabelPeriod: isPreviousPeriod
              ? dateTime.clone().subtract(periodLength, units)
              : dateTime.clone(),
            Period: dateTime.toDate(),
            Total: matchCoordinate ? this.getY(matchCoordinate) : 0,
          };
        }),
      };
    });
    return dataWithZeroes;
  };

  roundChartRange = range => {
    const { chartUnits } = this.props;
    const startTime = moment(range[0]).isBefore(moment(range[1]))
      ? moment(range[0])
      : moment(range[1]);
    const endTime = moment(range[0]).isBefore(moment(range[1]))
      ? moment(range[1])
      : moment(range[0]);
    if (chartUnits === unitsOfTime.days) {
      startTime.hour(24).minute(0);
      endTime.hour(0).minute(0);
    } else {
      startTime.minute(60).second(0);
      endTime.minute(0).second(0);
    }
    return [startTime, endTime];
  };

  render() {
    let { tableData } = this.props;
    const {
      appliedChartFilter,
      chartData,
      chartRange,
      isFetching,
      maximumLines,
      profileLongDateFormat,
      widgetVisibility,
    } = this.props;
    const { colorArray, comparePeriodsIsChecked, linesHidden } = this.state;
    tableData = this.getTableData(tableData);
    const contentHeight = this.getContentHeight(tableData);
    const spanDays = Math.abs(
      moment(chartRange[0]).diff(moment(chartRange[1]), unitsOfTime.days),
    );
    return (
      <>
        {renderIf(Object.values(widgetVisibility).find(value => value))(
          <div className={dashboardContent}>
            {renderIf(
              !!widgetVisibility[NotificationsConsts.WIDGETS.SUMMARY_TABLE],
            )(
              <div className={summaryTable}>
                <div className={summaryTableHeader}>
                  <h1>
                    <Translate id="ALARMS.TABLE.HEADER_SUMMARY_LABEL" />
                  </h1>
                </div>
                <div className={summaryTableContent}>
                  {tableData.map((row, index) => (
                    <div key={row.key} className={summaryTableRow}>
                      <span className={summaryTableName}>{row.name}</span>
                      <span
                        className={row.active ? summaryTableNumber : ''}
                        id={dashboardIds[row.key]}
                        onClick={() =>
                          this.handleSummaryClick(row.key, row.active)
                        }
                        onKeyPress={() => {}}
                        role="button"
                        tabIndex={index}
                      >
                        {row.number}
                      </span>
                    </div>
                  ))}
                </div>
              </div>,
            )}

            {renderIf(
              !!widgetVisibility[NotificationsConsts.WIDGETS.VOLUME_CHART],
            )(
              <div className={lineGraph}>
                <div className={lineGraphHeader}>
                  <h1>
                    {chartHeaderText(
                      chartData[0]
                        ? this.getFilterType(chartData[0])
                        : appliedChartFilter || null,
                      spanDays,
                    )}
                  </h1>
                  <span>
                    <Translate>
                      {({ translate }) => (
                        <Checkbox
                          checked={!!comparePeriodsIsChecked}
                          id={dashboardIds.idCompareCheckbox}
                          label={translate('ALARMS.DASHBOARD.COMPARE_LABEL')}
                          onChange={this.handleComparePeriods}
                        />
                      )}
                    </Translate>
                  </span>
                </div>

                <div
                  className={lineGraphContent}
                  id={dashboardIds.idLineGraph}
                  style={
                    {
                      height: `${contentHeight}px`,
                    } /* Set height explicitly to avoid confusing the LineChartComponent */
                  }
                >
                  {linesHidden && !isFetching ? (
                    <div className={lineGraphWarning}>
                      <Translate
                        data={{
                          max: maximumLines,
                        }}
                        id="ALARMS.DASHBOARD.MAX_CHART_ITEMS_WARNING"
                      />
                    </div>
                  ) : null}
                  <EmptyPlaceholder
                    isFetching={isFetching}
                    items={chartData}
                    string={
                      <Translate id="ALARMS.DASHBOARD.EMPTY_NOTIFICATIONS_LABEL" />
                    }
                  >
                    <LineChartComponent
                      chartRange={this.roundChartRange(chartRange)}
                      circleRadius={4}
                      color={colorArray}
                      data={this.addZeroes(this.limitChartData(chartData))}
                      getFilterName={this.getFilterName}
                      getFilterRange={this.getFilterRange}
                      getFilterType={this.getFilterType}
                      getVal={this.getVal}
                      getX={this.getX}
                      getY={this.getY}
                      locale={getActiveLanguageCode()}
                      margin={25}
                      onTooltipClick={this.handleTooltipClick}
                      profileLongDateFormat={profileLongDateFormat}
                      selectedLegendClassName={lineGraphSelectedLegend}
                      tooltipClass={detailBox}
                      xString={this.xString}
                      xTicks={5}
                      yString={this.yString}
                      yTicks={4}
                    />
                  </EmptyPlaceholder>
                </div>
              </div>,
            )}
          </div>,
        )}
      </>
    );
  }
}

NotificationsDashboard.propTypes = {
  appliedChartFilter: PropTypes.string.isRequired,
  changeFilters: PropTypes.func.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartRange: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  chartUnits: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  maximumLines: PropTypes.number,
  onComparePeriods: PropTypes.func.isRequired,
  profileLongDateFormat: PropTypes.string,
  profileTimeZone: PropTypes.string.isRequired,
  stateFilters: PropTypes.shape({}).isRequired,
  tableData: PropTypes.objectOf(PropTypes.any).isRequired,
  widgetVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
};

NotificationsDashboard.defaultProps = {
  isFetching: null,
  maximumLines: null,
  profileLongDateFormat: '',
};

export default NotificationsDashboard;
