import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, GroupLayout, ListView } from 'lib';
import OasisModule from './OasisModule';

class DebugLogModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logEntries: [],
    };
  }

  componentDidMount = () => {
    setTimeout(this.ingest, 1000);
  };

  ingest = () => {
    if (window.avoLogItems && window.avoLogItems.length > 0) {
      const { logEntries } = this.state;
      const nextItem = window.avoLogItems.shift();
      this.setState({ logEntries: [...logEntries, nextItem] }, () => {
        setTimeout(this.ingest, 100);
      });
    } else {
      // avoLog("No New Log Items", {});
      setTimeout(this.ingest, 5 * 1000);
    }
  };

  clearLog = () => {
    this.setState({ logEntries: [] });
  };

  dumpLogDetails = logDetails => {
    console.info(logDetails);
  };

  render() {
    const { logEntries } = this.state;
    const { updateModuleState } = this.props;
    const CELL_WIDTHS = {
      type: '40px',
    };
    const CUSTOM_CELLS = {
      logType: rowData => {
        switch (rowData.logType) {
          case 'log': {
            return rowData.logType;
          }
          case 'error': {
            return <span style={{ color: 'red' }}>{rowData.logType}</span>;
          }
          case 'warn': {
            return <span style={{ color: 'orange' }}>{rowData.logType}</span>;
          }
          default: {
            return `Unknown type: ${rowData.logType}`;
          }
        }
      },
    };
    return (
      <OasisModule
        indicatorValue={logEntries.length}
        moduleId="oasis-debug"
        theme="darkGray"
        title="Debug Log"
        updateModuleState={updateModuleState}
      >
        <ListView
          bodyHeight={125}
          canChangeView={false}
          cellWidths={CELL_WIDTHS}
          customCells={CUSTOM_CELLS}
          data={logEntries}
          defaultDisplay="table"
          fieldOrder={['logMessage', 'logType']}
          headerLabels={{
            logMessage: 'Message',
            logType: 'Type',
          }}
          inlineDetails={false}
          resizable
          resizableFrom="top"
          rowActions={[
            ({rowData}) => (
              <Button
                compact
                inputType="button"
                onClick={() => {
                  console.info(rowData);
                }}
                size="small"
                text="Console.info"
              />
            ),
          ]}
          sortType="local"
        />
        <GroupLayout dropshadow verticalCenter verticalSpacing="none">
          <Button inputType="button" onClick={this.clearLog} text="Clear Log" />
        </GroupLayout>
      </OasisModule>
    );
  }
}
DebugLogModule.propTypes = {
  updateModuleState: PropTypes.func.isRequired,
};
export default DebugLogModule;
