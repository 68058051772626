// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { Translate } from 'react-localize-redux';

// lib
import { AccordionItem } from 'lib';

// Components
import { IconServer } from 'icons';
import { Checkbox } from 'components';

// Styles
import {
  linkCamerasFeedback,
  linkCamerasFeedbackItem,
  linkCamerasFeedbackList,
  linkCamerasFormItem,
  linkCamerasInputList,
  linkCamerasList,
  panelWrapper,
  tabPanel,
} from './styles.css';

class DigitalOutLinkCameras extends React.Component {
  handleCameraLink(cameraId) {
    this.props.onCameraSelect(cameraId);
  }

  renderCameras() {
    const cameras = this.props.cameras.map(camera => {
      return (
        <div key={`checkbox_${camera.Id}`} className={linkCamerasFormItem}>
          <Checkbox
            checked={camera.isLinked}
            id={`${camera.Id}_checkbox-enabled`}
            label={camera.Name}
            onChange={() => this.handleCameraLink(camera.Id)}
            truncate
          />
        </div>
      );
    });
    return <div className={linkCamerasList}>{cameras}</div>;
  }

  renderFormFeedback() {
    const linkedCamerasList = this.props.links.map(camera => {
      return (
        <div key={`feedback_${camera.Id}`} className={linkCamerasFeedbackItem}>
          {camera.Name}
        </div>
      );
    });
    const listWrapper = (
      <div className={linkCamerasFeedbackList}>{linkedCamerasList}</div>
    );
    let messageCopyId;
    if (this.props.cameras.length < 1) {
      messageCopyId = 'CAMERA.DIGITAL_OUT.NO_CAMERAS_AVAILABLE_LABEL';
    } else {
      messageCopyId =
        linkedCamerasList.length > 0
          ? 'CAMERA.DIGITAL_OUT.LINKED_CAMERAS_LABEL'
          : 'CAMERA.DIGITAL_OUT.NO_LINKED_CAMERAS_LABEL';
    }
    return (
      <div key="formFeedback" className={linkCamerasFeedback}>
        <div>
          <Translate id={messageCopyId} />
        </div>
        {linkedCamerasList.length > 0 && listWrapper}
      </div>
    );
  }

  renderAccordion() {
    const { cameras, server } = this.props;
    const cameraCheckboxes = this.renderCameras();
    if (cameras.length < 1) {
      return null;
    }
    return (
      <AccordionItem
        key={server.Id}
        expanded={false}
        icon={<IconServer />}
        theme="gray"
        title={server.Name}
      >
        <div className={linkCamerasInputList}>{cameraCheckboxes}</div>
      </AccordionItem>
    );
  }

  render() {
    return (
      <div className={panelWrapper}>
        <div className={tabPanel}>
          {this.renderAccordion()}
          {this.renderFormFeedback()}
        </div>
      </div>
    );
  }
}

DigitalOutLinkCameras.propTypes = {
  cameras: PropTypes.array.isRequired,
  links: PropTypes.array.isRequired,
  onCameraSelect: PropTypes.func.isRequired,
  server: PropTypes.shape({ Id: PropTypes.string, Name: PropTypes.string })
    .isRequired,
};

export default DigitalOutLinkCameras;
