import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';

import * as styles from './popover.css';

const getPopoverContainerStyle = (
  horizontalOffset,
  verticalOffset,
  triggerCoords,
) => {
  const { innerHeight: height, innerWidth: width } = window;
  const { bottom, left, right, top } = triggerCoords;
  const containerStyle = {};
  if (horizontalOffset === 'left') {
    containerStyle.left = parseInt(left, 10) + 10;
  } else if (horizontalOffset === 'right') {
    containerStyle.right = parseInt(width, 10) - parseInt(right, 10) + 10;
  }
  if (verticalOffset === 'top') {
    containerStyle.top = parseInt(top, 10) + 25;
  } else if (verticalOffset === 'bottom') {
    containerStyle.bottom = parseInt(height, 10) - parseInt(bottom, 10) + 25;
  }
  return containerStyle;
};

const Popover = ({
  children,
  className,
  horizontalOffset,
  trigger,
  verticalOffset,
}) => {
  // Appears on hover, holds in place on click
  // Future plan: extend to switch between appear on hover / appear on click based on props

  const [freeze, setFreeze] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [contentStyle, setContentStyle] = useState({});

  const triggerElement = useRef();

  const {
    popoverContainer,
    popoverContent,
    popoverContentContainer,
    popoverContentHandle,
    popoverTrigger,
    popoverTriggerContainer,
  } = styles;

  const showContent = () => {
    if (triggerElement && triggerElement.current) {
      const triggerCoords = triggerElement.current.getBoundingClientRect();
      setContentStyle(
        getPopoverContainerStyle(
          horizontalOffset,
          verticalOffset,
          triggerCoords,
        ),
      );
    }
    setContentVisible(true);
  };

  const hideContent = () => {
    if (freeze) setFreeze(false);
    else setContentVisible(false);
  };

  const freezeContent = () => {
    if (contentVisible) setFreeze(true);
  };

  const renderVisibleTrigger = useMemo(() => {
    if (trigger) {
      return trigger;
    }
    return <div className={popoverTrigger}>i</div>;
  }, [trigger, popoverTrigger]);

  Popover.handleClickOutside = hideContent;

  return (
    <div className={popoverContainer} onMouseLeave={hideContent}>
      <div
        ref={triggerElement}
        className={popoverTriggerContainer}
        onClick={freezeContent}
        onKeyPress={freezeContent}
        onMouseEnter={showContent}
        role="button"
        tabIndex={0}
      >
        {renderVisibleTrigger}
      </div>
      <div className={popoverContentContainer} style={contentStyle}>
        {contentVisible ? (
          <>
            <div className={popoverContentHandle} />
            <div className={`${popoverContent} ${className}`}>{children}</div>
          </>
        ) : null}
      </div>
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  horizontalOffset: PropTypes.oneOf(['left', 'right']),
  trigger: PropTypes.objectOf(PropTypes.any),
  verticalOffset: PropTypes.oneOf(['top', 'bottom']),
};

Popover.defaultProps = {
  children: null,
  className: null,
  horizontalOffset: 'left',
  trigger: {},
  verticalOffset: 'top',
};

// export default onClickOutside(Popover);
export default Popover;
