import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconZoomOutFull = props => {
  return (
    <div className={props.className} title={props.title}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 90 90"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <filter
            x="-36.4%"
            y="-36.4%"
            width="172.7%"
            height="172.7%"
            filterUnits="objectBoundingBox"
            id="filter-1-zoom-out-full"
          >
            <feOffset
              dx="0"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
              type="matrix"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <path
            d="M23.4166667,20.6666667 L21.9683334,20.6666667 L21.455,20.1716667 C23.2516667,18.0816667 24.3333334,15.3683333 24.3333334,12.4166666 C24.3333334,5.83499995 18.9983333,0.499999931 12.4166666,0.499999931 C5.83499995,0.499999931 0.499999931,5.83499995 0.499999931,12.4166666 C0.499999931,18.9983333 5.83499995,24.3333334 12.4166666,24.3333334 C15.3683333,24.3333334 18.0816667,23.2516667 20.1716667,21.455 L20.6666667,21.9683334 L20.6666667,23.4166667 L29.8333334,32.5650001 L32.5650001,29.8333334 L23.4166667,20.6666667 Z M12.4166666,20.6666667 C7.85166663,20.6666667 4.16666661,16.9816667 4.16666661,12.4166666 C4.16666661,7.85166663 7.85166663,4.16666661 12.4166666,4.16666661 C16.9816667,4.16666661 20.6666667,7.85166663 20.6666667,12.4166666 C20.6666667,16.9816667 16.9816667,20.6666667 12.4166666,20.6666667 Z M7.8333333,11.5 L17,11.5 L17,13.3333333 L7.8333333,13.3333333 L7.8333333,11.5 Z"
            id="path-2-zoom-out-full"
          />
          <rect id="path-4-zoom-out-full" x="-5" y="20" />
          <filter
            x="-44.3%"
            y="-28.4%"
            width="188.6%"
            height="209.1%"
            filterUnits="objectBoundingBox"
            id="filter-5-zoom-out-full"
          >
            <feMorphology
              radius="1.5"
              operator="erode"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            />
            <feOffset
              dx="0"
              dy="16"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            />
            <feGaussianBlur
              stdDeviation="6"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.501960784   0 0 0 0 0.615686275   0 0 0 0 0.937254902  0 0 0 0.496490036 0"
              type="matrix"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g
          id="PTZ-UI-FINAL-zoom-out-full"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="UI-PTZ-Zoom-On-no-Scrubber-zoom-out-full"
            transform="translate(-229.000000, -300.000000)"
          >
            <g
              id="Group-3-zoom-out-full"
              transform="translate(216.000000, 107.000000)"
            >
              <g
                id="Group-5-zoom-out-full"
                transform="translate(13.000000, 198.000000)"
              >
                <g id="Group-Copy-2-zoom-out-full">
                  <g
                    id="zoom-out-zoom-out-full"
                    filter="url(#filter-1-zoom-out-full)"
                    transform="translate(29.000000, 14.000000)"
                  >
                    <mask id="mask-3-zoom-out-full" fill="white">
                      <use xlinkHref="#path-2-zoom-out-full" />
                    </mask>
                    <g id="Icon-zoom-out-full" fillRule="nonzero" />
                    <g
                      id="↳-🎨-Color-zoom-out-full"
                      mask="url(#mask-3-zoom-out-full)"
                    >
                      <use
                        fill="black"
                        fillOpacity="1"
                        filter="url(#filter-5-zoom-out-full)"
                        xlinkHref="#path-4-zoom-out-full"
                      />
                      <rect
                        strokeOpacity="0.5"
                        stroke="#809DEF"
                        strokeWidth="1"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        x="-5.5"
                        y="-5.55"
                        width="45"
                        height="45"
                      />
                    </g>
                  </g>
                  <text
                    id="0-%"
                    fill="#FFFFFF"
                    fontFamily="Arial-BoldMT, Arial"
                    fontSize="20"
                    fontWeight="bold"
                  >
                    <tspan x="35" y="70">
                      0 %
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconZoomOutFull.defaultProps = {
  className: null,
  width: '90px',
  height: '90px',
  fill: 'white',
  title: 'Zoom out full',
};

export default IconZoomOutFull;
