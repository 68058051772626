import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { CHECK_ICON_ID } from '../constants/ElementId';
import { SVG_XMLNS } from '../constants/app';

const IconCheck = props => {
  return (
    <div title={props.translate(props.title)} id={CHECK_ICON_ID}>
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g transform="translate(0 -1)" fill="none" fillRule="evenodd">
          <g fill={props.fillColor}>
            <path d="M8.252 17.252l-4.17-4.17-1.415 1.415 5.585 5.585 12-12-1.415-1.415z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconCheck.defaultProps = {
  title: 'PTZ.PRESETS.CHECKMARK',
  className: null,
  width: '20px',
  height: '20px',
  fillColor: '#FFFFFF',
};

export default withLocalize(IconCheck);
