import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldInput } from 'components/FormElements';
import { FormGroup } from 'lib';
import * as LocationFormConsts from '../../constants';

import { formField } from '../../styles.css';

const NonUsaPostalCode = props => {
  const { disabled } = props;
  return (
    <FormGroup
      id={LocationFormConsts.idPostalCode}
      keyName="nonUsaPostalCode"
      label={<Translate id="ADDRESS.NON_USA_ZIP" />}
    >
      <Field
        className={formField}
        component={FieldInput}
        disabled={disabled}
        isNewVersion
        name={LocationFormConsts.namePostalCode}
        translate
        validate={validation.required}
      />
    </FormGroup>
  );
};

NonUsaPostalCode.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default NonUsaPostalCode;
