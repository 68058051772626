import 'react-datepicker/dist/react-datepicker.css';
import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { IC_CALENDAR } from 'constants/iconNames';
import { withLocalize } from 'react-localize-redux';
import { Icon } from 'lib';
import { getShortDateFormat } from 'util/convertTimeTo';
import {
  alarmFilterCalendar,
  alarmFilterInput,
  calendarIcon,
  customInput,
  datesContainer,
} from './styles.css';

// Components

const IconDateInput = forwardRef((props, ref) => {
  const { onClick, placeholder, value } = props;
  return (
    <div
      ref={ref}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <input
        className={customInput}
        onChange={function noop() {}}
        placeholder={placeholder}
        type="text"
        value={value}
      />
      <Icon
        iconClass={calendarIcon}
        id={IC_CALENDAR}
        title="TIME_SELECT.PLACEHOLDERS.DATE"
      />
    </div>
  );
});

IconDateInput.propTypes = {
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

IconDateInput.defaultProps = {
  onClick: function noop() {},
  placeholder: '',
  value: '',
};

class DateSelect extends Component {
  constructor(props) {
    super(props);
    const { initialValue } = props;
    this.state = {
      selectedDate: this.convertTimeToTZ(initialValue),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { initialValue } = this.props;
    initialValue = this.appendTimeToDate(initialValue);
    const nextValue = this.appendTimeToDate(nextProps.initialValue);
    // Outside props can override internal state
    if (nextValue && !nextValue.isSame(initialValue)) {
      this.setState({ selectedDate: this.convertTimeToTZ(nextValue) });
    }
  }

  onDateChange = date => {
    const { onChange } = this.props;
    this.setState(
      {
        selectedDate: date,
      },
      () => {
        onChange(this.appendTimeToDate(moment(date)));
      },
    );
  };

  appendTimeToDate = date => {
    const { initialTime } = this.props;
    const oldDateTime = moment(this.convertTimeToTZ(initialTime));
    let newDate;
    if (date && oldDateTime) {
      newDate = date.clone().set({
        hour: oldDateTime.get('hour'),
        millisecond: oldDateTime.get('millisecond'),
        minute: oldDateTime.get('minute'),
        second: oldDateTime.get('second'),
      });
    }
    return newDate || date;
  };

  convertTimeToTZ = initialDateTime => {
    if (!initialDateTime) {
      return null;
    }
    const { timezone } = this.props;
    let convertedDate = initialDateTime;
    if (!moment.isMoment(initialDateTime)) {
      convertedDate = moment(initialDateTime);
    }

    if (timezone) {
      convertedDate = convertedDate.tz(timezone);
    }

    return convertedDate.toDate();
  };

  processFormat = format => {
    let processedFormat = '';
    // only month part should be uppercase for date-fns format - https://reactdatepicker.com/
    for (const char of format) {
      if (char !== 'M') {
        processedFormat += char.toLowerCase();
      } else {
        processedFormat += char;
      }
    }

    return processedFormat;
  };

  render() {
    const {
      className,
      customCalendarClassName,
      maxDate,
      minDate,
      preferredFormat,
      shouldOverrideCSS,
      showCalendarIcon,
      translate,
    } = this.props;
    const { selectedDate } = this.state;

    return (
      <div className={`${datesContainer} ${className}`}>
        <DatePicker
          {...this.props}
          calendarClassName={
            shouldOverrideCSS ? customCalendarClassName : alarmFilterCalendar
          }
          className={alarmFilterInput}
          customInput={showCalendarIcon ? <IconDateInput /> : null}
          dateFormat={this.processFormat(getShortDateFormat(preferredFormat))}
          maxDate={maxDate}
          minDate={minDate}
          onChange={this.onDateChange}
          placeholderText={translate('TIME_SELECT.PLACEHOLDERS.DATE')}
          popoverTargetOffset="10px -100px"
          selected={selectedDate}
        />
      </div>
    );
  }
}

DateSelect.propTypes = {
  className: PropTypes.string,
  customCalendarClassName: PropTypes.string,
  initialTime: PropTypes.instanceOf(moment),
  initialValue: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
  preferredFormat: PropTypes.string,
  shouldOverrideCSS: PropTypes.bool,
  showCalendarIcon: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

DateSelect.defaultProps = {
  className: '',
  customCalendarClassName: '',
  initialTime: null,
  initialValue: null,
  maxDate: undefined,
  minDate: undefined,
  preferredFormat: null,
  shouldOverrideCSS: false,
  showCalendarIcon: false,
  // include an initialTime moment so DateSelect will return a moment
  // with the selected day/month/year and the provided initialTime
};

function mapStateToProps(state) {
  return {
    preferredFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
  };
}

export default connect(mapStateToProps, null)(withLocalize(DateSelect));
