/* ************************************************************************* */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/*                 AVOID MAKING CHANGES TO THIS FILE.                        */
/*    ONCE THE EVO PLAYER IS RETIRED, THIS FILE CAN BE DELETED FOREVER.      */
/*                                                                           */
/*                                                                           */
/*                                                                           */
/* ************************************************************************* */

import React, { Fragment } from 'react';

// Components
import { NoStyleButton } from 'lib';
import { Scrollbars } from 'react-custom-scrollbars';
import { Translate } from 'react-localize-redux';
// Icons
import { IconInfoCircle, IconPin, IconX } from 'icons';
import Duration from './Duration';

// Style
import {
  buttonImage,
  buttonToggleStatus,
  connectedStatusIcon,
  eventDescription,
  eventList,
  eventListHeader,
  eventListHeaderLabel,
  eventListSeparator,
  eventsOpen,
  statusBox,
  statusBoxArrow,
  statusBoxContainer,
  statusBoxContainerHidden,
  statusBoxEvents,
  statusBoxIconRow,
  statusBoxLabel,
  statusBoxRow,
  statusBoxRowSeparator,
  statusBoxText,
} from './styles.css';

// Constants
import * as playerConsts from './constants';

const imgConnected = require('images/online-icon@2x.png');
const imgDisconnected = require('images/offline-icon@2x.png');

class PlayerStatusBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      showEvents: false,
    };
  }

  toggleDetailsVisibility = () => {
    this.setState(prevState => ({
      showDetails: !prevState.showDetails,
      showEvents: false,
    }));
  };

  toggleEventsVisibility = () => {
    this.setState(prevState => ({
      showEvents: !prevState.showEvents,
    }));
  };

  renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: 'white',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  onMouseWheel = ev => {
    ev.preventDefault();
    const currentScrollDelta = this.scrollbars.getScrollTop();
    this.scrollbars.scrollTop(currentScrollDelta + ev.deltaY);
  };

  render() {
    return (
      <div
        className={`${statusBoxContainer} ${
          this.props.showStatusBox ? '' : statusBoxContainerHidden
        }`}
      >
        <div className={statusBox}>
          {this.state.showDetails ? (
            <>
              <div className={`${statusBoxRow} ${statusBoxIconRow}`}>
                <div className={statusBoxLabel}>
                  <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.SITE" />
                </div>
                <div className={statusBoxText} title={this.props.siteName}>
                  {this.props.siteName}
                </div>
                <Translate>
                  {({ translate }) => (
                    <NoStyleButton
                      className={buttonToggleStatus}
                      id={playerConsts.idBtnCloseDetails}
                      onClick={this.toggleDetailsVisibility}
                      title={translate(
                        'VIDEO_PLAYER.PLAYER_STATUS_BOX.TITLES.CLOSE',
                      )}
                    >
                      <IconX className={buttonImage} strokeFill="#fff" />
                    </NoStyleButton>
                  )}
                </Translate>
              </div>
              <div className={statusBoxRow}>
                <div className={statusBoxLabel}>
                  <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.DEVICE" />
                </div>
                <div className={statusBoxText} title={this.props.deviceName}>
                  {this.props.deviceName}
                </div>
              </div>
              <div className={statusBoxRow}>
                <div className={statusBoxLabel}>
                  <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.CAMERA" />
                </div>
                <div className={statusBoxText} title={this.props.cameraName}>
                  {this.props.cameraName}
                </div>
              </div>
              <div className={statusBoxRowSeparator} />
              <div className={`${statusBoxRow} ${statusBoxIconRow}`}>
                <Translate>
                  {({ translate }) => (
                    <div
                      className={`${statusBoxArrow} ${
                        this.state.showEvents ? eventsOpen : null
                      }`}
                      id={playerConsts.idToggleEvents}
                      onClick={this.toggleEventsVisibility}
                      title={translate(
                        'VIDEO_PLAYER.PLAYER_STATUS_BOX.TITLES.SHOW_EVENTS',
                      )}
                    />
                  )}
                </Translate>
                <div className={statusBoxText}>
                  <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.HEADERS.DETAILS" />
                </div>
                <Translate>
                  {({ translate }) => (
                    <img
                      alt={
                        this.props.isDisconnected
                          ? translate('ACCESSIBILITY.ICON_OFFLINE')
                          : translate('ACCESSIBILITY.ICON_ONLINE')
                      }
                      className={connectedStatusIcon}
                      src={
                        this.props.isDisconnected
                          ? imgDisconnected
                          : imgConnected
                      }
                    />
                  )}
                </Translate>
                <Translate>
                  {({ translate }) => (
                    <NoStyleButton
                      className={buttonToggleStatus}
                      id={playerConsts.idBtnPushpin}
                      onClick={this.props.pinStatusBox}
                      title={translate(
                        'VIDEO_PLAYER.PLAYER_STATUS_BOX.TITLES.PIN',
                      )}
                    >
                      <IconPin className={buttonImage} />
                    </NoStyleButton>
                  )}
                </Translate>
              </div>
            </>
          ) : (
            <div className={`${statusBoxRow} ${statusBoxIconRow}`}>
              <Translate>
                {({ translate }) => (
                  <NoStyleButton
                    className={buttonToggleStatus}
                    id={playerConsts.idShowPlayerStatus}
                    onClick={this.toggleDetailsVisibility}
                    title={translate(
                      'VIDEO_PLAYER.PLAYER_STATUS_BOX.TITLES.SHOW_DETAILS',
                    )}
                  >
                    <IconInfoCircle className={buttonImage} />
                  </NoStyleButton>
                )}
              </Translate>
              <img
                alt={
                  this.props.isDisconnected ? (
                    <Translate id="ACCESSIBILITY.ICON_OFFLINE" />
                  ) : (
                    <Translate id="ACCESSIBILITY.ICON_ONLINE" />
                  )
                }
                className={connectedStatusIcon}
                src={this.props.isDisconnected ? imgDisconnected : imgConnected}
              />
              <div className={statusBoxText} title={this.props.siteName}>
                {this.props.cameraName}
              </div>
            </div>
          )}
        </div>
        {this.state.showEvents ? (
          <div className={statusBoxEvents}>
            <div className={eventListHeader}>
              <span>
                <span>
                  <span className={eventListHeaderLabel}>
                    <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.DURATION" />
                  </span>
                  <Duration seconds={this.props.videoElapsed} />
                </span>
                {this.props.streamWidth && (
                  <span>
                    <span className={eventListHeaderLabel}>
                      <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.RESOLUTION" />
                    </span>
                    <span>{`${this.props.streamWidth}x${
                      this.props.streamHeight
                    }`}</span>
                  </span>
                )}
              </span>
            </div>
            <div>
              <span className={eventListHeaderLabel}>
                <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.DATA" />
              </span>
              <span>{this.props.throughput}</span>
              <span className={eventListHeaderLabel}>
                <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.FRAMES" />
              </span>
              <span>{`${this.props.frames}/${this.props.framesDropped}`}</span>
              {this.props.connectionType ? (
                <span>
                  <span className={eventListHeaderLabel}>
                    <Translate id="VIDEO_PLAYER.PLAYER_STATUS_BOX.LABELS.CXN" />
                  </span>
                  <span>
                    {this.props.connectionIp}
                    {'/'}
                    {this.props.connectionType}
                  </span>
                </span>
              ) : null}
            </div>
            <div className={eventListSeparator} />
            <Scrollbars
              ref={component => (this.scrollbars = component)}
              autoHeight
              autoHeightMax={this.props.height - playerConsts.VIDEO_PADDING}
              onWheel={this.onMouseWheel}
              renderThumbVertical={this.renderThumb}
            >
              <div>
                <div className={eventList}>
                  {this.props.events.map((event, ix) => (
                    <div key={`${event}_${ix}`} className={statusBoxRow}>
                      <div className={eventDescription}>{event}</div>
                    </div>
                  ))}
                </div>
              </div>
            </Scrollbars>
          </div>
        ) : null}
      </div>
    );
  }
}

PlayerStatusBox.defaultProps = {
  events: [],
};

PlayerStatusBox.propTypes = {};

export default PlayerStatusBox;
