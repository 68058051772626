// Libs
import React from 'react';

// Constants
import { SVG_XMLNS } from '../constants/app';

const IconInfoCircle = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns={SVG_XMLNS}
      title={props.title}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-344.000000, -241.000000)">
          <g transform="translate(344.000000, 238.000000)">
            <circle fill="#006e99" cx="7" cy="10" r="7" />
            <text
              fontFamily="LucidaCalligraphy-Italic, Lucida Calligraphy"
              fontSize="14"
              fontStyle="italic"
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x="5" y="15">
                {'i'}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconInfoCircle.defaultProps = {
  width: '14px',
  height: '14px',
  title: '',
};

export default IconInfoCircle;
