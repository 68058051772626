import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  AutoFocus,
  ResolutionSelector,
  VideoQualityDropdown,
} from '@avo-svtr/savitar';
import RuleOverlayButton from './RuleOverlayButton';
import * as classes from './savitarPlayer.styles.css';

const SettingOptionsGroup = ({ cameraId, hideOverlayToggle, hostId }) => {
  const settingMenuRef = useRef();
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const toggleDisplay = useCallback(
    event => {
      event.stopPropagation();
      setDisplayDropdown(prev => !prev);
    },
    [setDisplayDropdown],
  );

  return (
    <div className={classes.settingOptionsGroup} ref={settingMenuRef}>
      {displayDropdown ? (
        <VideoQualityDropdown toggleToMenu={toggleDisplay} />
      ) : (
        <>
          <AutoFocus />
          <ResolutionSelector onClick={toggleDisplay} />
          {hideOverlayToggle ? null : (
            <RuleOverlayButton cameraId={cameraId} hostId={hostId} />
          )}
        </>
      )}
    </div>
  );
};

SettingOptionsGroup.propTypes = {
  cameraId: PropTypes.string,
  hideOverlayToggle: PropTypes.bool,
  hostId: PropTypes.string,
};

SettingOptionsGroup.defaultProps = {
  cameraId: null,
  hideOverlayToggle: false,
  hostId: null,
};

export default SettingOptionsGroup;
