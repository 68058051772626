import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import * as LinkActions from 'actions/utilities';

// Constants
import * as consts from 'constants/links';

// Styles
import { docHelpLinkText, docLinkButton, docLinkText } from './styles.css';

class DocLinkContainer extends Component {
  UNSAFE_componentWillReceiveProps(props) {
    const { actions, isFetchingLinks } = props;
    if (isFetchingLinks === null) {
      actions.getLinks();
    }
  }

  clickHandler = ev => {
    // left mouse click
    if (ev.button === 0) {
      const { language, linkKey, links } = this.props;
      const {
        englishCode,
        englishCodeUs,
        keyHelp,
        templateLanguageToken,
      } = consts;

      const urlTemplate = links[linkKey];
      let { code: languageCode } = language;

      /* The English Help URL specifically requires 'en-us',
       * despite other languages being 2-character codes (e.g. 'es' or 'fr')
       */
      if (linkKey === keyHelp) {
        if (languageCode === englishCode) {
          languageCode = englishCodeUs;
        }
      }

      /* This component expects that the links array is an array of strings
       * that each contain a {languageCode} token (stored as a const and
       * used here as templateLanguageToken). If anything about this code
       * changes, you MUST check that the corresponding values coming from the
       * server include {languageCode} to prevent the localization breaking.
       * Reference $utilityHelpLink, $utilityTermsOfServiceLink,
       * $utilityPrivacyPolicyLink, and $utilityEULALink in the following files
       * found in the server\Tools\AzureEnvironment folder:
       *
       * dev.ps1, ci.ps1, qa.ps1, prod.ps1
       */
      const localizedSrc = urlTemplate.replace(
        templateLanguageToken,
        languageCode,
      );
      const src = localizedSrc;

      window.open(src, '_blank');
    }
  };

  renderFull() {
    const { additionalClassNames, children, linkKey, linkText } = this.props;
    return (
      <div id="idDocLinkContainerWrapper" style={{ display: 'flex' }}>
        <button
          className={`${docLinkButton} ${additionalClassNames}`}
          id={`docLinkButton_${linkKey}`}
          onMouseDown={this.clickHandler}
          type="button"
        >
          <span
            className={
              linkKey === consts.keyHelp ? docHelpLinkText : docLinkText
            }
          >
            {linkText || children}
          </span>
        </button>
      </div>
    );
  }

  renderCompact() {
    const { children, linkText } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        label: linkText,
        onClick: this.clickHandler,
      }),
    );
    return childrenWithProps;
  }

  render() {
    const { compactMode } = this.props;
    return compactMode ? this.renderCompact() : this.renderFull();
  }
}

function mapStateToProps(state) {
  const { links } = state.utilities;
  const { getLinks: isFetchingLinks } = state.isFetching;

  const language = state.localize.languages.find(lang => lang.active === true);
  return {
    isFetchingLinks,
    language,
    links,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...LinkActions,
      },
      dispatch,
    ),
  };
}

DocLinkContainer.defaultProps = {
  additionalClassNames: '',
  children: null,
  compactMode: false,
  isFetchingLinks: null,
  language: { code: consts.englishCode },
  linkText: null,
};

DocLinkContainer.propTypes = {
  actions: PropTypes.shape({
    getLinks: PropTypes.func,
  }).isRequired,
  additionalClassNames: PropTypes.string,
  children: PropTypes.node,
  compactMode: PropTypes.bool,
  isFetchingLinks: PropTypes.bool,
  language: PropTypes.shape({
    code: PropTypes.string,
  }),
  linkKey: PropTypes.oneOf([
    consts.keyEULA,
    consts.keyHelp,
    consts.keyPrivacyPolicy,
    consts.keyTermsOfService,
  ]).isRequired,
  /**
   * TODO : MVAAS-17402 Links should be isRequired but it is throwing error in the test even passing inside
   *        the store.More to investigate on why store is not being passed properly here?
   */
  linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  links: PropTypes.shape({
    [consts.keyEULA]: PropTypes.string,
    [consts.keyHelp]: PropTypes.string,
    [consts.keyPrivacyPolicy]: PropTypes.string,
    [consts.keyTermsOfService]: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocLinkContainer);
