// Libs
import React, { useMemo } from 'react';
import renderIf from 'render-if';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';

// Components
import {
  FieldInput,
  LabeledBox,
  RadioGroup,
  VerticalFormFieldLayout,
} from 'components';
import { PageMessage, TimeZoneSelect } from 'containers';
import { Button, FieldSelect, GroupLayout } from 'lib';

// Utilities
import * as validation from 'util/validation';
import normalizeBoolean from 'util/normalizeBoolean';
import { ROLES } from 'util/userRoles';
import { tenantTypeStrings } from 'util/tenantTypes';

// Styles
import {
  disabledHeaderLink,
  formBox,
  formField,
  formWrapper,
  horizontalDividerSolid,
  infoGroup,
  modalFormGroup,
  pageError,
  subHeaderDetail,
} from 'sharedStyles/modalForms.css';
import { formGroup, formLabel } from 'components/VerticalForm/styles.css';

// Constants
import { ROLES_KEYS, USER_TYPES } from 'constants/app';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  RESEND_INVITE_LINK_ID,
  RESET_PASSWORD_LINK_ID,
} from 'constants/ElementId';
import * as messageTypes from 'constants/MessageTypes';
import UserAccessTable from './UserAccessTable';
import {
  formInput,
  formRadioGroup,
  formRadioLabel,
  requiredHeader,
  siteAccessWrapper,
} from './UserForm.styles.css';
import * as consts from './constants';

const formLevelValidation = values => {
  const errors = {};
  const requiredFields =
    values.UserType !== USER_TYPES.proxy
      ? ['FirstName', 'LastName', 'EmailAddress', 'Role', 'TimeZone']
      : [];
  const emptyFields = requiredFields.filter(
    field => !values[field] || values[field].trim() === '',
  );
  if (emptyFields.length > 0) {
    errors.summary = (
      <Translate id="USERS.USER_FORM_FIELDS.VALIDATION.REQUIRED_FIELDS" />
    );
  }
  return errors;
};

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        body={error}
        messageStyle={messageStyleStrings.error}
        translateHeader="USERS.USER_FORM_FIELDS.VALIDATION.MISSING_INFORMATION"
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.defaultProps = {
  meta: null,
};

errorAggregator.propTypes = {
  meta: PropTypes.objectOf(PropTypes.any),
};

const ManageUser = props =>
  props.isEditing &&
  props.initialValues.UserType === USER_TYPES.user && (
    <div>
      {props.disabled ? (
        <div clssName={infoGroup}>
          {props.initialValues[consts.fieldStatus] === consts.statusEnabled && (
            <div className={disabledHeaderLink}>
              <Translate id="USERS.ADD_USER_MODAL.LABELS.RESET_PASSWORD" />
            </div>
          )}
          {props.initialValues[consts.fieldStatus] === consts.statusInvited && (
            <div className={disabledHeaderLink}>
              <Translate id="USERS.ADD_USER_MODAL.LABELS.RESEND_INVITE" />
            </div>
          )}
        </div>
      ) : (
        <div className={infoGroup}>
          {props.initialValues[consts.fieldStatus] === consts.statusEnabled && (
            <Button
              key={RESET_PASSWORD_LINK_ID}
              buttonType="secondary"
              id={RESET_PASSWORD_LINK_ID}
              inputType="button"
              onClick={() => props.resetPassword()}
              text={
                <Translate id="USERS.ADD_USER_MODAL.LABELS.RESET_PASSWORD" />
              }
            />
          )}
          {props.initialValues[consts.fieldStatus] === consts.statusInvited && (
            <Button
              key={RESEND_INVITE_LINK_ID}
              buttonType="secondary"
              id={RESEND_INVITE_LINK_ID}
              inputType="button"
              onClick={() => props.resendInvite()}
              text={
                <Translate id="USERS.ADD_USER_MODAL.LABELS.RESEND_INVITE" />
              }
            />
          )}
        </div>
      )}
    </div>
  );

let UserForm = props => {
  const {
    canAccessUserRoles,
    canEditProxySiteAccess,
    change,
    currentOrganizationType,
    handleSubmit,
    hasRemoteUsers,
    initialValues,
    isEditing,
    locations,
    policies,
    pristine,
    reset,
    role,
    schedules,
    showSites,
    submittingForm,
    valid,
  } = props;

  const isProxyUser =
    initialValues.UserType && initialValues.UserType !== USER_TYPES.user; // true if the edited user is a proxy
  const isPrimaryAdmin = initialValues.IsPrimaryAdmin;

  const userRoles = useMemo(() => {
    return (
      <Translate>
        {({ translate }) =>
          Object.keys(ROLES_KEYS)
            .filter(
              r =>
                ROLES_KEYS[r].key !== ROLES_KEYS.Viewer.key ||
                currentOrganizationType !== tenantTypeStrings.Provider,
            )
            .map(r => (
              <option key={r} value={ROLES_KEYS[r].value}>
                {translate(ROLES_KEYS[r].key)}
              </option>
            ))
        // eslint-disable-next-line react/jsx-curly-newline
        }
      </Translate>
    );
  }, [currentOrganizationType]);

  return (
    <>
      <div className={horizontalDividerSolid} />
      <div className={formWrapper}>
        <form onSubmit={handleSubmit}>
          <div>
            <Field component={errorAggregator} name={consts.fieldSummary} />
            <PageMessage
              messageType={[messageTypes.USER_ERROR, messageTypes.USER_SUCCESS]}
            />
          </div>
          <div className={formBox}>
            <fieldset>
              <LabeledBox labelId="USERS.ADD_USER_MODAL.LABELS.BASIC_INFO">
                <div className={`${requiredHeader} ${formField}`}>
                  <Translate id="USERS.USER_FORM_FIELDS.LABELS.ALL_FIELDS_ARE_REQUIRED" />
                </div>
                {isProxyUser && (
                  <div className={subHeaderDetail}>
                    <Translate id="USERS.USER_FORM_FIELDS.VALIDATION.CANT_EDIT_SERVICE_PROVIDER" />
                  </div>
                )}
                {isProxyUser
                  ? consts.proxyFormInputs.map(object => (
                      <VerticalFormFieldLayout
                        key={object.id}
                        label={object.label}
                      >
                        <Field
                          key={object.id}
                          component={FieldInput}
                          disabled
                          id={object.id}
                          name={object.name}
                          validate={object.validate}
                        />
                      </VerticalFormFieldLayout>
                    ))
                  : consts.userFormInputs.map(object => (
                      <VerticalFormFieldLayout
                        key={object.id}
                        label={object.label}
                      >
                        <Field
                          className={formField}
                          component={FieldInput}
                          disabled={
                            object.calcDisabled &&
                            object.calcDisabled({ isEditing })
                          }
                          id={object.id}
                          name={object.name}
                          normalize={object.normalize}
                          validate={object.validate}
                        />
                      </VerticalFormFieldLayout>
                    ))}
                {!isProxyUser && (
                  <VerticalFormFieldLayout label="USERS.USER_FORM_FIELDS.LABELS.TIMEZONE">
                    <Field
                      component={TimeZoneSelect}
                      disabled={isProxyUser}
                      name={consts.fieldTimezone}
                    />
                  </VerticalFormFieldLayout>
                )}
                {renderIf(
                  currentOrganizationType !== tenantTypeStrings.CloudOwner,
                )(
                  <VerticalFormFieldLayout label="USERS.USER_FORM_FIELDS.LABELS.ROLE">
                    <Field
                      className={formField}
                      component={FieldSelect}
                      disabled={
                        isProxyUser || isPrimaryAdmin || !canAccessUserRoles
                      }
                      id={consts.idRoleType}
                      label={
                        <Translate id="USERS.USER_FORM_FIELDS.LABELS.ROLE" />
                      }
                      name={consts.fieldRole}
                      translate
                      validate={validation.required}
                    >
                      {userRoles}
                    </Field>
                  </VerticalFormFieldLayout>,
                )}

                {isEditing && (
                  <>
                    <label className={formLabel}>
                      {<Translate id="COMMON.STATUS" />}
                    </label>
                    <div className={modalFormGroup}>
                      <Field
                        className={formInput}
                        component={FieldInput}
                        disabled
                        name={consts.fieldStatus}
                        translate
                      />
                    </div>
                  </>
                )}
                {renderIf(role !== ROLES.Viewer && !isProxyUser)(
                  <>
                    <div className={formRadioGroup}>
                      <label className={formRadioLabel}>
                        <Field
                          component={fieldProps => (
                            <RadioGroup
                              labelTranslateId="USERS.USER_FORM_FIELDS.LABELS.EMAIL_NOTIFICATIONS"
                              name={consts.fieldEmailNotify}
                              onChange={e => {
                                const changedValue = normalizeBoolean(
                                  e.target.value,
                                );
                                fieldProps.input.onChange(changedValue);
                              }}
                              options={consts.USER_EMAIL_NOTIFICATION}
                              value={fieldProps.input.value}
                              verticalLayout={false}
                            />
                          )}
                          id={consts.idEmailNotification}
                          name={consts.fieldEmailNotify}
                        />
                      </label>
                    </div>
                  </>,
                )}
                {renderIf(!isProxyUser && showSites)(
                  <>
                    <div className={formGroup}>
                      <Field
                        component={fieldProps => (
                          <RadioGroup
                            disabled={isProxyUser || isPrimaryAdmin}
                            id={consts.idRadioAutoAccessToFutureSites}
                            labelTranslateId="USERS.USER_FORM_FIELDS.LABELS.ACCESS_TO_FUTURE_SITES"
                            onChange={e => {
                              const changedValue = normalizeBoolean(
                                e.target.value,
                              );
                              fieldProps.input.onChange(changedValue);
                            }}
                            options={consts.USER_ACCESS_TO_FUTURE_SITES}
                            value={fieldProps.input.value}
                            verticalLayout={false}
                          />
                        )}
                        id={consts.idRadioAutoAccessToFutureSites}
                        name={consts.fieldNewSiteAccess}
                      />
                    </div>
                  </>,
                )}
                <GroupLayout
                  horizontalPositioning="left"
                  verticalSpacing="large"
                >
                  <ManageUser disabled={isProxyUser} {...props} />
                  <Button
                    key="userFormButtonRevert"
                    buttonType="primary"
                    disabled={submittingForm || pristine}
                    id="userFormButtonRevert"
                    inputType="button"
                    onClick={reset}
                    text={<Translate id="BUTTONS.REVERT" />}
                  />
                  <Button
                    key="userFormButtonSave"
                    buttonType="primary"
                    disabled={submittingForm || pristine || !valid}
                    id="userFormButtonSave"
                    inputType="button"
                    onClick={handleSubmit}
                    text={<Translate id="BUTTONS.SAVE" />}
                  />
                </GroupLayout>
              </LabeledBox>
            </fieldset>
            {renderIf(showSites)(
              <>
                <div style={{ padding: '16px' }} />
                <div className={siteAccessWrapper}>
                  <LabeledBox labelId="USERS.ADD_USER_MODAL.ACCESS_NOTIFICATION_TITLE">
                    <UserAccessTable
                      canAccessUserRoles={canAccessUserRoles}
                      canEditProxySiteAccess={canEditProxySiteAccess}
                      change={change}
                      isPrimaryAdmin={isPrimaryAdmin}
                      isProxyUser={isProxyUser}
                      locations={locations}
                      policies={policies}
                      role={role}
                      schedules={schedules}
                    />
                  </LabeledBox>
                </div>
              </>,
            )}
          </div>
        </form>
      </div>
    </>
  );
};

UserForm.defaultProps = {
  hasRemoteUsers: false,
  initialValues: {},
  locations: [],
  policies: [],
  role: ROLES.Viewer,
  schedules: [],
  showSites: true,
};

UserForm.propTypes = {
  canAccessUserRoles: PropTypes.bool.isRequired,
  canEditProxySiteAccess: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired, // from redux form
  currentOrganizationType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasRemoteUsers: PropTypes.bool,
  initialValues: PropTypes.shape({
    FirstName: PropTypes.string,
    IsPrimaryAdmin: PropTypes.bool,
    LastName: PropTypes.string,
    Type: PropTypes.string,
    UserType: PropTypes.string,
  }),
  isEditing: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  policies: PropTypes.arrayOf(PropTypes.object),
  pristine: PropTypes.bool.isRequired,
  resendInvite: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired, // from redux form
  resetPassword: PropTypes.func.isRequired,
  role: PropTypes.oneOf(Object.keys(ROLES)),
  schedules: PropTypes.arrayOf(PropTypes.shape({})),
  showSites: PropTypes.bool,
  submittingForm: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const selector = formValueSelector('addUserForm');
  const isPrimaryAdmin = selector(state, 'IsPrimaryAdmin');
  const policies = selector(state, 'Policies');
  const role = selector(state, consts.fieldRole);
  const selectedGroupIds = policies
    ? policies.map(policy => policy.GroupId)
    : [];
  const hasRemoteUsers = selector(state, 'HasRemoteUsers');
  return {
    currentOrganizationType: state.user.currentOrganization.Type,
    hasRemoteUsers,
    isPrimaryAdmin,
    policies,
    role,
    selectedGroupIds,
  };
}

UserForm = reduxForm({
  enableReinitialize: true,
  form: 'addUserForm',
  validate: formLevelValidation,
})(UserForm);

export default connect(mapStateToProps)(withLocalize(UserForm));
