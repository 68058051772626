// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

// Components
import { Button, Icon, ListView } from 'lib';

// Utilities
import { PATH_CAMERAS } from 'constants/urlPaths';
import { getCameraActualStatus } from 'util/getCameraActualStatus';

// Styles
import * as CameraTypes from 'constants/cameraTypes';
import { IC_SETTINGS, IC_TRASH } from 'constants/iconNames';
import * as styles from './styles.css';

// Consts
import * as consts from './constants';
import CellConnectionState from './CellConnectionState';

function ConnectedCamerasTable(props) {
  const {
    activeCameras,
    disableCamera,
    disconnectCamera,
    enableCamera,
    loginCamera,
    resetFDSPassword,
    translate,
  } = props;

  const toggleCameraStatus = (enabled, rowIndex, menuProps) => {
    if (enabled) {
      enableCamera(rowIndex, menuProps);
    } else {
      disableCamera(rowIndex, menuProps);
    }
  };

  const getActionButton = (menuProps, rowIndex) => {
    const { realState } = menuProps.cameraStatusObj;

    switch (realState) {
      case CameraTypes.CAMERA_AUTH_FAILED: {
        return (
          <Button
            compact
            id={`${consts.idBtnLogin}-${rowIndex}`}
            inputType="button"
            onClick={() => loginCamera(rowIndex, menuProps)}
            size="small"
            text={translate('BUTTONS.LOGIN')}
          />
        );
      }

      case CameraTypes.CAMERA_CONNECTED: {
        return (
          <Button
            compact
            id={`${consts.idBtnDisable}-${rowIndex}`}
            inputType="button"
            onClick={() => toggleCameraStatus(false, rowIndex, menuProps)}
            size="small"
            text={translate('BUTTONS.DISABLE')}
          />
        );
      }

      case CameraTypes.CAMERA_DISABLED_BY_USER: {
        return (
          <Button
            compact
            id={`${consts.idBtnEnable}-${rowIndex}`}
            inputType="button"
            onClick={() => toggleCameraStatus(true, rowIndex, menuProps)}
            size="small"
            text={translate('BUTTONS.ENABLE')}
          />
        );
      }

      case CameraTypes.CAMERA_FACTORY_DEFAULT: {
        return (
          <Button
            compact
            id={`${consts.idBtnSetCredentials}-${rowIndex}`}
            inputType="button"
            onClick={() => resetFDSPassword(menuProps)}
            size="small"
            text={translate('BUTTONS.INITIALIZE')}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  // This function returns the actual rowIndex of the camera in the activeCameras array irrespective of sorting or filtering actions.
  const getActiveCameraIndex = camera => {
    return activeCameras.findIndex(cam => cam.Id === camera.Id);
  };

  const cameraLinkPath = camId => {
    return `${PATH_CAMERAS}/${camId}`;
  };

  const CUSTOM_CELLS = {
    ConnectionButton: rowData => {
      const rowIndex = getActiveCameraIndex(rowData);
      const menuProps = {
        buttonDisabled: false,
        cameraData: activeCameras,
        cameraStatusObj: getCameraActualStatus(rowData),
        cell: 'connectionStatus',
        rowIndex,
        table: 'CONNECTED',
      };
      return getActionButton(menuProps, rowIndex);
    },
    ConnectionState: rowData => {
      const menuProps = {
        buttonDisabled: false,
        cameraData: activeCameras,
        cameraStatusObj: getCameraActualStatus(rowData),
        cell: 'connectionStatus',
        table: 'CONNECTED',
      };
      return (
        <CellConnectionState
          {...props}
          className={styles.connectStatusRow}
          data={activeCameras}
          field="ConnectionState"
          id={rowData.Id}
          menuProps={menuProps}
        />
      );
    },
  };

  const ROW_ACTIONS = [
    ({rowData}) => {
      const { location } = props;
      return rowData.ConnectionState === CameraTypes.CAMERA_CONNECTED ? (
        <div className={styles.settingsLinkButton}>
          <Link
            to={{
              pathname: `${cameraLinkPath(rowData.Id)}`,
              state: { prevPath: location.pathname },
            }}
          >
            <Icon
              iconClass={styles.settingsIcon}
              id={IC_SETTINGS}
              title="DEVICE_DETAILS.CAMERAS_TAB.SETTINGS"
            />
          </Link>
        </div>
      ) : (
        ''
      );
    },
    ({rowData, rowIex, isDetails}) => (
      <Button
        icon
        inputType="button"
        onClick={() => {
          disconnectCamera(rowData);
        }}
      >
        <Icon
          iconClass={styles.iconCenter}
          id={IC_TRASH}
          title="DEVICE_DETAILS.CAMERAS_TAB.REMOVE_CAMERA_LABEL"
        />
      </Button>
    ),
  ];

  const getTableTitle = () =>
    translate('DEVICE_DETAILS.CAMERAS_TAB.CONNECTED_CAMERAS_HEADER', {
      count: activeCameras.length,
    });

  return (
    <ListView
      bodyHeight={150}
      cardFieldOrder={consts.CONNECTED_CAMERAS_CARD_FIELD_ORDER}
      cellAlignments={consts.CELL_ALIGNMENTS}
      cellWidths={consts.CELL_WIDTHS}
      customCells={CUSTOM_CELLS}
      data={activeCameras}
      defaultSortField="Name"
      fieldOrder={consts.CONNECTED_CAMERAS_FIELD_ORDER}
      headerTranslationIds={consts.CONNECTED_CAMERAS_HEADER_TRANSLATION_IDS}
      resizable
      rowActions={ROW_ACTIONS}
      sortType="local"
      title={getTableTitle()}
      titleClass={styles.camerasTableTitle}
      truncatedFields={['Name', 'Model', 'Manufacturer', 'PhysicalAddress']}
    />
  );
}

ConnectedCamerasTable.defaultProps = {
  location: null,
  server: null,
};

ConnectedCamerasTable.propTypes = {
  activeCameras: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  disableCamera: PropTypes.func.isRequired,
  disconnectCamera: PropTypes.func.isRequired,
  enableCamera: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  loginCamera: PropTypes.func.isRequired,
  resetFDSPassword: PropTypes.func.isRequired,
  server: PropTypes.shape({ Name: PropTypes.string }),
  translate: PropTypes.func.isRequired,
};

export default withLocalize(ConnectedCamerasTable);
