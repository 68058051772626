import React from 'react';
import { Translate } from 'react-localize-redux';

const getVersionInfo = () => {
  return process.env.BUILD_VERSION ? (
    `v ${process.env.BUILD_VERSION}`
  ) : (
    <Translate id="FOOTER.VERSION_NOT_AVAILABLE" />
  );
};

export default getVersionInfo;
