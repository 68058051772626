import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import { modalContentConfirm } from './styles.css';

const AddCameraGroupSuccessModal = ({ redirectTo, hideModal }) => (
  <div className={modalContentConfirm}>
    <div>
      <Translate id="CAMERA_GROUP.ADD_GROUP_SUCCESS_MESSAGE" />
      <Link
        to={`/devices/${redirectTo}`}
        title="RedirectToEditedGrp"
        onClick={hideModal}
      >
        <button type="button">
          <Translate id="BUTTONS.OK" />
        </button>
      </Link>
    </div>
  </div>
);

AddCameraGroupSuccessModal.defaultProps = {
  redirectTo() {},
  hideModal() {},
};

AddCameraGroupSuccessModal.propTypes = {
  redirectTo: PropTypes.func,
  hideModal: PropTypes.func,
};

export default AddCameraGroupSuccessModal;
