import React from 'react';

import Progress from 'react-circular-progressbar';

import { circularProgressBarContainer } from './styles.css';

const CircularProgressBar = props => {
  const { className: propclassName } = props;

  return (
    <Progress
      {...props}
      className={`${circularProgressBarContainer} ${propclassName}`}
    />
  );
};

CircularProgressBar.defaultProps = {
  className: '',
  percentage: 0,
  strokeWidth: 15,
};

export default CircularProgressBar;
