// Constants
import * as PasswordRules from 'constants/PasswordRules';
import { tenantTypeStrings } from 'util/tenantTypes';

// Validators
export const required = value => {
  const requiredVal = 'SIGNUP.REQUIRED_VALUE_MESSAGE';
  if (value) {
    if (Array.isArray(value)) {
      return value.length === 0 ? requiredVal : '';
    }
    return value.trim().length === 0 ? requiredVal : '';
  }
  return requiredVal;
};

export const tenDigitsRequired = value => {
  if (value && value.length > 10) {
    return 'VALIDATION_RULES.INVALID_FORMAT';
  }
  if (!value) {
    return 'SIGNUP.REQUIRED_VALUE_MESSAGE';
  }
  return undefined;
};

export const activationcodereq = content => {
  if (!content) {
    return ' ';
  }
  if (content.length < 11) {
    return ' ';
  }
  if (content.length > 11) {
    return ' ';
  }

  if (
    content.length === 11 &&
    !/^([A-Z0-9]{3})+-([A-Z0-9]{3})+-([A-Z0-9]{3})$/i.test(content)
  ) {
    return ' ';
  }
  return undefined;
};

export const email = (content, _formValues, { users = [] }) => {
  if (content && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(content)) {
    return 'VALIDATION_RULES.INVALID_FORMAT';
  }
  if (users.find(user => content === user.EmailAddress)) {
    return 'VALIDATION_RULES.EXISTING_USER';
  }
  return undefined;
};

export const zipCode = content => {
  return content && (content.length !== 5 || Number.isNaN(content))
    ? 'VALIDATION_RULES.INVALID_ZIP_CODE'
    : undefined;
};

export const phone = content => {
  return content &&
    content !== '911' &&
    !/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/i.test(content)
    ? 'VALIDATION_RULES.INVALID_FORMAT'
    : undefined;
};

// Validate Password component
export const validatePasswordInput = (
  password,
  showLast3PasswordsRule = false,
  passLast3PasswordsRule = PasswordRules.PENDING,
) => {
  const errors = [];
  const passwordKeyword = 'PASSWORD';

  if (password !== null) {
    errors.push({
      errorMessage:
        'SIGNUP.PASSWORD_RULES.MIN_LENGTH_8_MAX_LENGTH_50_CHARACTERS',
      testPassed:
        password.length >= 8 && password.length <= 50
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.ONE_LOWERCASE_CHARACTER',
      testPassed:
        password.search(/[a-z]/) >= 0
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.ONE_UPPERCASE_CHARACTER',
      testPassed:
        password.search(/[A-Z]/) >= 0
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.ONE_NUMERIC_CHARACTER',
      testPassed:
        password.search(/[0-9]/) >= 0
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.ONE_SPECIAL_CHARACTER',
      testPassed:
        password.search(/[-!$%^&*()_+|'~=`{}[\]:;<>?.@\\/#\\]/g) >= 0
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.INVALID_PASSWORD_CHARACTER',
      testPassed:
        password.search(/,/g) <= 0
          ? PasswordRules.PASSED
          : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_NOT_HAVE,
    });
    errors.push({
      errorMessage: 'SIGNUP.PASSWORD_RULES.NO_PASSWORD_KEYWORD',
      testPassed: !password
        .trim()
        .toUpperCase()
        .includes(passwordKeyword)
        ? PasswordRules.PASSED
        : PasswordRules.FAILED,
      type: PasswordRules.PASSWORD_MUST_NOT_HAVE,
    });
    if (showLast3PasswordsRule) {
      errors.push({
        errorMessage: 'SIGNUP.PASSWORD_RULES.NO_REUSED_PASSWORDS',
        testPassed: passLast3PasswordsRule,
        type: PasswordRules.PASSWORD_MUST_NOT_HAVE,
      });
    }
  } else {
    return undefined;
  }

  return errors;
};

export const allPasswordChecksPass = errors => {
  return (
    errors
      .map(error => error.testPassed)
      .filter(testPassed => testPassed !== PasswordRules.PASSED).length === 0
  );
};

export const validatePassword = (password, isNotLast3 = true) => {
  return !password ||
    !allPasswordChecksPass(validatePasswordInput(password)) ||
    !isNotLast3
    ? 'PROFILE.LABEL_ERROR_FORMATTING'
    : undefined;
};

export const validateConfirmPassword = (confirmPassword, password) => {
  return confirmPassword === password
    ? undefined
    : 'PROFILE.LABEL_ERROR_NO_MATCH';
};

export const organizationBillingNumber = (number, { Type }) => {
  const firstFourDigits = number && number.toString().slice(0, 4);
  if (
    (Type === tenantTypeStrings.Dealer && firstFourDigits === '0001') ||
    (Type === tenantTypeStrings.Subscriber && firstFourDigits === '0007')
  ) {
    return undefined;
  }
  return 'VALIDATION_RULES.INVALID_FORMAT';
};

export const organizationBillingNumberOrZeros = (number, formValues) => {
  if (number.toString() === '0000000000') {
    return undefined;
  }
  return organizationBillingNumber(number, formValues);
};

export const dealerBillingNumber = number => {
  const firstFourDigits = number && number.toString().slice(0, 4);
  if (firstFourDigits === '0008') {
    return undefined;
  }
  return 'VALIDATION_RULES.INVALID_FORMAT';
};

export const dealerBillingNumberOrZeros = number => {
  if (number.toString() === '0000000000') {
    return undefined;
  }
  return dealerBillingNumber(number);
};

export const dealerOrderNumber = number => {
  const firstThreeDigits = number && number.toString().slice(0, 3);
  if (firstThreeDigits === '003') {
    return undefined;
  }
  return 'VALIDATION_RULES.INVALID_FORMAT';
};

export const dealerOrderNumberOrZeros = number => {
  if (number.toString() === '0000000000') {
    return undefined;
  }
  return dealerOrderNumber(number);
};

// Normalizers

export const trim = content => content && content.trim();

export const tenDigits = content => {
  if (content.lenth <= 10) {
    return content;
  }
  return content.slice(0, 10);
};

export const formatPhone = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length < 3) {
    return `(${onlyNums})`;
  }
  if (onlyNums.length < 6) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10,
  )}`;
};

export const getSubscriptionState = subscription => {
  if (!subscription) {
    return {};
  }

  const expired = subscription.State.indexOf('Expired') >= 0;
  const suspended = subscription.State.indexOf('Suspended') >= 0;

  return {
    isExpired: expired,
    isSuspended: suspended,
    isValid: !expired && !suspended,
  };
};

export const tenDigitsLeftZeroPadding = val =>
  val ? val.toString().padStart(10, '0') : '';

export const authTokenLength = token => {
  if (!token) {
    return undefined;
  }
  if (token.length < 6) {
    return 'VALIDATION_RULES.TOKEN_TOO_SHORT';
  }
  return undefined;
};

export const isSelfServiceableSite = (site, servicePackages) => {
  if (
    site &&
    site.PackageSubscription &&
    servicePackages &&
    servicePackages.some(
      sp => sp.ServiceFamily === site.PackageSubscription.ServiceFamily,
    )
  )
    return true;
  return false;
};
