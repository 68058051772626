import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import renderIf from 'render-if';
import * as groupStyle from './groupLayout.css';

const GroupLayout = ({
  additionalClasses,
  children,
  dropshadow,
  horizontalPositioning,
  separatorWidth,
  verticalCenter,
  verticalSpacing,
}) => {
  const capitaliseFirstLetter = str => {
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);
    return newStr;
  };

  const classes = () => {
    const flexJustifyContent = `groupLayout${capitaliseFirstLetter(
      horizontalPositioning,
    )}`;
    const groupVerticalSpacing = `groupLayoutSpacing${capitaliseFirstLetter(
      verticalSpacing,
    )}`;
    const groupVerticalCenter = verticalCenter
      ? `bottomMargin${capitaliseFirstLetter(verticalSpacing)}`
      : '';
    const dropshadowClass = dropshadow ? groupStyle.dropshadow : '';
    return [
      groupStyle.groupLayout,
      groupStyle[flexJustifyContent],
      groupStyle[groupVerticalSpacing],
      groupStyle[groupVerticalCenter],
      dropshadowClass,
      additionalClasses,
    ].join(' ');
  };
  return (
    <div className={classes()}>
      {Array.isArray(children)
        ? children.map((child, index) => (
            <Fragment key={index}>
              {child}
              {renderIf(index !== children.length - 1)(
                <div
                  style={{
                    width:
                      typeof separatorWidth === 'number'
                        ? `${separatorWidth}px`
                        : separatorWidth,
                  }}
                />,
              )}
            </Fragment>
          ))
        : children}
    </div>
  );
};

GroupLayout.propTypes = {
  additionalClasses: PropTypes.string,
  children: PropTypes.node,
  dropshadow: PropTypes.bool,
  horizontalPositioning: PropTypes.string,
  separatorWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  verticalCenter: PropTypes.bool,
  verticalSpacing: PropTypes.string,
};

GroupLayout.defaultProps = {
  additionalClasses: '', // pass in any extra classes you need - this component
  // is simple and decoupled - if the thing you're passing in is only used in
  // button groups then consider adding it directly to the component
  children: null,
  dropshadow: false,
  horizontalPositioning: 'left', // center || split || right
  separatorWidth: '8px',
  verticalCenter: false,
  verticalSpacing: 'none', // default is none options are
  // none || medium || large
};

export default GroupLayout;
