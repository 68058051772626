import imgMaximize from 'images/btn-fullscreen-grid@2x.png';
import imgMinimize from 'images/img-btn-return-size@2x.png';
import { ACTIVE_COLOR_DEFAULT } from 'constants/app';

export const idSaveViewsButton = 'saveViewsButton';
export const idDeleteViewsButton = 'deleteViewsButton';
export const idViewsDropdown = 'viewsDropdown';
export const idCreateViewsButton = 'createViewsButton';
export const idSyncToggle = 'syncVideoToggle';
export const idViewsMinButton = 'minViewsButton';
export const idViewsMaxButton = 'maxViewsButton';
export const idInactivityBanner = 'inactivityBanner';
export const idInactivityWarning = 'inactivityWarning';
export const idMoreActionsDropdown = 'moreActionsDropdown';

// ViewSaveForm
export const MAX_VIEW_NAME_CHAR = 128;
export const idViewSaveButton = 'viewSaveButton';
export const idViewCancelButton = 'viewCancelButton';

// ViewEditor
export const createNewView = 'CREATE_NEW_VIEW';
export const layoutIconFillColor = '#000';
export const layoutIconFillColorSelected = '#fff';
export const fullScreenToggleIcons = { imgMaximize, imgMinimize };
export const VIEW_EDITOR_ICON_SIZE = '16';

export const optionsLayout = [
  {
    label: 'Layout',
    options: [
      {
        label: '1x1',
        value: 0,
      },
      {
        label: '2x1',
        value: 1,
      },
      {
        label: '2x2',
        value: 2,
      },
      {
        label: '3x1',
        value: 4,
      },
      {
        label: '3x2',
        value: 5,
      },
      {
        label: '3x3',
        value: 3,
      },
    ],
  },
];

export const IconSaveInBuiltTrueFill = '#999';
export const IconSaveInBuiltFalseFill = ACTIVE_COLOR_DEFAULT;
export const idPageHeaderView = 'viewsHeader';
export const idDropDownLayout = 'dropDownLayout';

export const MAX_ACC_CAMERAS = 9;
