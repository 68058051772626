
import * as types from 'constants/ActionTypes';

export function toggleAccordion(accordionType, accordionId, isExpanded) {
  const accordion = {
    accordionType,
    accordionId,
    isExpanded,
  };

  return {
    type: types.TOGGLE_ACCORDION,
    accordion,
  };
}
