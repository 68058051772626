import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  filter,
  filterTrigger,
  filterTriggerIcon,
  filterDropdown,
  filterSectionItem,
  filterSectionClose,
  disabled,
  selectable,
  selected,
  multiSelect,
} from './styles.css';

class IconFilter extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      selectedArr: value,
      open: false,
    };
  }

  toggleDropdown = value => {
    const { open } = this.state;
    this.setState({ open: value === null ? !open : value }, () => {
      open || this.onClose();
    });
  };

  handleOptionClick = value => {
    const { selectedArr } = this.state;
    const valueIndex = selectedArr.indexOf(value);
    const newArr =
      valueIndex >= 0
        ? selectedArr
            .slice(0, valueIndex)
            .concat(selectedArr.slice(valueIndex + 1))
        : selectedArr.concat([value]);
    this.setState({ selectedArr: newArr });
  };

  onClose = () => {
    const { onChange } = this.props;
    const { selectedArr } = this.state;
    const newValues = selectedArr;
    onChange(newValues);
  };

  render() {
    const {
      anchor,
      disabled: disabledProp,
      triggerClassName,
      multiple,
      closeText,
      options,
    } = this.props;
    const { open, selectedArr } = this.state;
    const _style = {};
    if (anchor) {
      _style[anchor] = 0;
    }
    return (
      <div className={`${filter} ${disabledProp && disabled}`}>
        <div className={filterTrigger}>
          <button
            className={`${filterTriggerIcon} ${triggerClassName}`}
            onClick={() => this.toggleDropdown(null)}
            type="button"
          >
            {icon}
          </button>
        </div>
        {open && (
          <a
            href="#"
            className={`${filterDropdown} ${multiple && multiSelect}`}
            style={_style}
            onBlur={() => this.toggleDropdown(false)}
          >
            {options.map(opt => {
              const isSelected = selectedArr.includes(opt.value);
              return (
                <div
                  key={opt.value}
                  onClick={() => this.handleOptionClick(opt.value)}
                  className={`${filterSectionItem} ${selectable} ${isSelected &&
                    selected}`}
                  onKeyPress={() => this.handleOptionClick(opt.value)}
                  role="button"
                  tabIndex="0"
                >
                  {opt.text}
                </div>
              );
            })}
            <div
              onClick={() => this.toggleDropdown(false)}
              className={filterSectionClose}
              onKeyPress={() => this.toggleDropdown(false)}
              role="button"
              tabIndex="0"
            >
              {closeText}
            </div>
          </a>
        )}
      </div>
    );
  }
}

IconFilter.defaultProps = {
  triggerClassName: '',
  closeText: 'Close Menu',
  multiple: false,
  anchor: null,
  value: [],
  onChange: () => {},
};

IconFilter.propTypes = {
  icon: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  closeText: PropTypes.string,
  triggerClassName: PropTypes.string,
  multiple: PropTypes.bool,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default IconFilter;
