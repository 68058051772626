export const messageStyleStrings = {
  info: 'info',
  error: 'error',
  success: 'success',
  loading: 'loading',
};

export const keyErrorMessageHidden = 'errorBoxHidden';
export const keyErrorMessageVisible = 'errorBoxVisible';
export const keySuccessMessageHidden = 'successBoxHidden';
export const keySuccessMessageVisible = 'successBox';
