import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconFullscreen = props => {
  return (
    <svg
      id={props.id}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 18 18"
      fill={props.fill}
    >
      <g
        id="03---Alarms-&gt;-List-View-(Modal)-Clip"
        stroke="none"
        strokeWidth="1"
        fill={props.fill}
        fillRule="evenodd"
      >
        <g
          id="Alarms-List-Modal-Comment"
          transform="translate(-1292.000000, -648.000000)"
          fill={props.fill}
        >
          <g id="(Modal)" transform="translate(91.000000, 190.000000)">
            <g id="Group-6" transform="translate(30.000000, 77.000000)">
              <g id="arrows" transform="translate(1171.000000, 381.000000)">
                <g
                  id="Rectangle-12"
                  transform="translate(9.000000, 14.000000) rotate(180.000000) translate(-9.000000, -14.000000) translate(0.000000, 10.000000)"
                >
                  <path
                    d="M16,3.48528137 L16,5.99707067 C16,6.56211865 16.4477153,7 17,7 C17.5561352,7 18,6.55097324 18,5.99707067 L18,1.00292933 C18,1.00244092 17.9999997,1.0019526 17.999999,1.00146438 C17.9999997,1.00097669 18,1.00048838 18,1 C18,0.722422234 17.8881384,0.47281326 17.7072073,0.292403731 C17.5252783,0.109092319 17.2756652,0 17,0 C16.9995116,0 16.9990233,3.46298506e-07 16.9985351,1.03866243e-06 C16.9980474,3.45068384e-07 16.9975591,6.13818048e-17 16.9970707,6.14117097e-17 L12.0029293,3.6721467e-16 C11.4378814,4.0181388e-16 11,0.44771525 11,1 C11,1.55613518 11.4490268,2 12.0029293,2 L14.6568542,2 L10.7050068,5.95184748 C10.320345,6.3365092 10.3165825,6.97343674 10.7071068,7.36396103 C11.1003537,7.75720799 11.7298558,7.75542553 12.1192203,7.36606104 L16,3.48528137 Z"
                    id="Combined-Shape"
                  />
                  <path
                    d="M5.58463831,3.48528137 L5.58463831,5.99707067 C5.58463831,6.56211865 6.03235356,7 6.58463831,7 C7.14077349,7 7.58463831,6.55097324 7.58463831,5.99707067 L7.58463831,1.00292933 C7.58463831,1.00244092 7.58463798,1.0019526 7.58463731,1.00146438 C7.58463797,1.00097669 7.58463831,1.00048838 7.58463831,1 C7.58463831,0.722422234 7.47277676,0.47281326 7.29184559,0.292403731 C7.10991658,0.109092319 6.8603035,0 6.58463831,0 C6.58414992,0 6.58366161,3.46298506e-07 6.58317339,1.03866243e-06 C6.58268567,3.45068384e-07 6.58219737,6.13818048e-17 6.58170898,6.14117097e-17 L1.58756764,3.6721467e-16 C1.02251966,4.0181388e-16 0.584638314,0.44771525 0.584638314,1 C0.584638314,1.55613518 1.03366507,2 1.58756764,2 L4.24149256,2 L0.289645087,5.95184748 C-0.0950166399,6.3365092 -0.0987791961,6.97343674 0.291745096,7.36396103 C0.684992051,7.75720799 1.31449416,7.75542553 1.70385865,7.36606104 L5.58463831,3.48528137 Z"
                    id="Combined-Shape"
                    transform="translate(3.792319, 3.829245) scale(-1, 1) translate(-3.792319, -3.829245) "
                  />
                </g>
                <g id="Rectangle-12">
                  <path
                    d="M16,3.48528137 L16,5.99707067 C16,6.56211865 16.4477153,7 17,7 C17.5561352,7 18,6.55097324 18,5.99707067 L18,1.00292933 C18,1.00244092 17.9999997,1.0019526 17.999999,1.00146438 C17.9999997,1.00097669 18,1.00048838 18,1 C18,0.722422234 17.8881384,0.47281326 17.7072073,0.292403731 C17.5252783,0.109092319 17.2756652,0 17,0 C16.9995116,0 16.9990233,3.46298506e-07 16.9985351,1.03866243e-06 C16.9980474,3.45068384e-07 16.9975591,-1.606628e-16 16.9970707,-1.60632895e-16 L12.0029293,1.45170065e-16 C11.4378814,1.79769275e-16 11,0.44771525 11,1 C11,1.55613518 11.4490268,2 12.0029293,2 L14.6568542,2 L10.7050068,5.95184748 C10.320345,6.3365092 10.3165825,6.97343674 10.7071068,7.36396103 C11.1003537,7.75720799 11.7298558,7.75542553 12.1192203,7.36606104 L16,3.48528137 Z"
                    id="Combined-Shape"
                  />
                  <path
                    d="M5.58463831,3.48528137 L5.58463831,5.99707067 C5.58463831,6.56211865 6.03235356,7 6.58463831,7 C7.14077349,7 7.58463831,6.55097324 7.58463831,5.99707067 L7.58463831,1.00292933 C7.58463831,1.00244092 7.58463798,1.0019526 7.58463731,1.00146438 C7.58463797,1.00097669 7.58463831,1.00048838 7.58463831,1 C7.58463831,0.722422234 7.47277676,0.47281326 7.29184559,0.292403731 C7.10991658,0.109092319 6.8603035,0 6.58463831,0 C6.58414992,0 6.58366161,3.46298506e-07 6.58317339,1.03866243e-06 C6.58268567,3.45068384e-07 6.58219737,-1.606628e-16 6.58170898,-1.60632895e-16 L1.58756764,1.45170065e-16 C1.02251966,1.79769275e-16 0.584638314,0.44771525 0.584638314,1 C0.584638314,1.55613518 1.03366507,2 1.58756764,2 L4.24149256,2 L0.289645087,5.95184748 C-0.0950166399,6.3365092 -0.0987791961,6.97343674 0.291745096,7.36396103 C0.684992051,7.75720799 1.31449416,7.75542553 1.70385865,7.36606104 L5.58463831,3.48528137 Z"
                    id="Combined-Shape"
                    transform="translate(3.792319, 3.829245) scale(-1, 1) translate(-3.792319, -3.829245) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconFullscreen.defaultProps = {
  className: null,
  width: '18px',
  height: '18px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconFullscreen;
