import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconToggleDragToZoomMode = props => {
  return (
    <div className={props.className} title={props.translate(props.title)}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="PTZ-icons"
            transform="translate(-209.000000, -22.000000)"
            fill="#FFFFFF"
          >
            <g id="ic_tours" transform="translate(207.000000, 20.000000)">
              <g transform="translate(3.250000, 3.125000)" id="Path">
                <polygon points="14 0 14 4 15 4 15 13 17 13 17 4 18 4 18 0" />
                <polygon points="14 15 11 15 11 13 6 16.1 11 19 11 17 14 17 14 18 18 18 18 14 14 14" />
                <polygon points="13 1 4 1 4 0 0 0 0 4 4 4 4 3 13 3" />
                <polygon points="3 5 1 5 1 14 0 14 0 18 4 18 4 14 3 14" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconToggleDragToZoomMode.defaultProps = {
  title: 'PTZ.BUTTONS.PRESETS_AND_TOURS',
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
};

export default withLocalize(IconToggleDragToZoomMode);
