import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// COMPONENTS
import { Button } from 'lib';

// Actions
import { hideModal, showModal } from 'actions/modal';
import * as DeviceActions from 'actions/devices';

// STYLES

// ICONS
import {
  IconCancelPtzMode,
  IconCheck,
  IconChevron,
  IconClear,
  IconEdit,
  IconPlayTour,
  IconUpArrow,
} from 'icons';
import * as modalTypes from 'constants/ModalTypes';
import { ACTIVE_COLOR_DEFAULT } from 'constants/app';
import {
  activeIcons,
  boxItem,
  buttonHolder,
  center,
  checkbox,
  clickable,
  editorBody,
  editorField,
  editorTitle,
  emptyListHolder,
  iconHolder,
  iconsHolder,
  itemName,
  label,
  leftArrow,
  lineEditor,
  listHolder,
  listItem,
  listTitle,
  playIcon,
  presetTourBox,
  right,
  setHome,
  titleHolder,
  tourButtons,
  tourTitle,
} from './styles.css';

// CONSTANTS
const presetTour = {
  BOX_BAR_FOOT: 'BoxBarFoot',
  BOX_BAR_HEAD: 'BoxBarHead',
  CREATE: 'Create',
  DELETE: 'Delete',
  ENTER_KEY: 13,
  ESCAPE_KEY: 27,
  LIST_MIN_HEIGHT: 100,
  LIST_PRESETS: 'ListPresets',
  LIST_TOURS: 'ListTours',
  RESERVED_HEIGHT: 200,
};

function LineEditor(props) {
  const [inputValue, setInputValue] = useState(
    props.editType === presetTour.DELETE
      ? this.props.listItems[props.deletingItemIndex].name
      : '',
  );

  const [isHome, setIsHome] = useState(false);

  const titleId =
    props.editType === presetTour.DELETE
      ? 'PTZ.PRESETS.EDIT'
      : 'PTZ.PRESETS.CREATE';

  return (
    <div className={lineEditor}>
      <div className={editorTitle}>
        <Translate id={titleId} />
      </div>
      <div className={editorBody}>
        <input
          autoFocus
          className={editorField}
          maxLength="40"
          onChange={event => {
            setInputValue(event.target.value);
          }}
          onKeyUp={event => {
            event.keyCode === presetTour.ENTER_KEY
              ? props.saveFunction(inputValue, props.deletingItemIndex, isHome)
              : event.keyCode === presetTour.ESCAPE_KEY
              ? props.cancelEdit()
              : null;
          }}
          value={inputValue}
        />
        <div className={`${iconsHolder} ${clickable}`}>
          <div
            className={iconHolder}
            onClick={event => {
              props.saveFunction(inputValue, props.deletingItemIndex, isHome);
            }}
          >
            <IconCheck />
          </div>
          <div
            className={iconHolder}
            onClick={props.cancelEdit}
            style={{ marginLeft: '10px' }}
          >
            <IconCancelPtzMode />
          </div>
        </div>
      </div>
      <div className={setHome}>
        <input
          className={checkbox}
          id="isHome"
          onChange={() => setIsHome(!isHome)}
          type="checkbox"
        />
        <label htmlFor="isHome" />
        <span>
          <Translate id="PTZ.PRESETS.SET_HOME" />
        </span>
      </div>
    </div>
  );
}

LineEditor.defaultProps = {
  cancelEdit: () => {},
  deletingItemIndex: null,
  editType: presetTour.CREATE,
  itemType: presetTour.LIST_PRESETS,
  listItems: [],
  saveFunction: () => {},
};

LineEditor.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.object),
};

const BoxFooter = ({ clickAction, displayNameId, isCreating, listType }) => {
  const isPreset = listType === presetTour.LIST_PRESETS;
  const doClick = isPreset && !isCreating ? clickAction : null;
  const selectedClass =
    doClick !== null
      ? `${boxItem} ${titleHolder} ${clickable}`
      : `${boxItem} ${titleHolder}`;
  return (
    <div className={selectedClass} onClick={() => doClick(false)}>
      <div className={`${listTitle}`}>
        <Translate id={displayNameId} />
      </div>
      {doClick !== null ? (
        <div className={clickable}>
          <IconChevron className={right} />
        </div>
      ) : null}
    </div>
  );
};

const BoxHeader = ({ back, close, displayNameId, itemCount, listType }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className={`${boxItem} ${titleHolder}`}>
          {listType === presetTour.LIST_TOURS ? (
            <div className={`${clickable} ${leftArrow}`} onClick={back}>
              <IconUpArrow
                height="16px"
                title={translate('PTZ.TOURS.BACK_BUTTON')}
                width="16px"
              />
            </div>
          ) : null}
          <div className={`${listTitle}`}>
            <Translate id={displayNameId} />
            {` (${itemCount})`}
          </div>
          <div className={clickable} onClick={close}>
            <IconCancelPtzMode title={translate('BUTTONS.CLOSE')} />
          </div>
        </div>
      )}
    </Translate>
  );
};

const displayPresets = (
  presets,
  deleteFunction,
  deletingItemIndex,
  selectFunction,
  saveFunction,
  cancelEdit,
  canEdit,
) => {
  const elements = [];
  presets.forEach((item, index) => {
    elements.push(
      <div
        key={index}
        className={`${boxItem} ${listItem}`}
        onClick={e => {
          selectFunction(e, item.id);
        }}
      >
        <div className={itemName}>{`${item.name} `}</div>
        {canEdit && (
          <div className={activeIcons} onClick={() => deleteFunction(index)}>
            <Translate>
              {({ translate }) => (
                <IconClear
                  iconId={`clear_${index}`}
                  title={translate('BUTTONS.DELETE')}
                />
              )}
            </Translate>
          </div>
        )}
      </div>,
    );
  });
  return elements;
};

const displayTours = (tours, selectFunction, editPtzTour) => {
  const elements = [];

  tours.forEach((item, index) => {
    elements.push(
      <div
        key={index}
        className={`${boxItem} ${listItem}`}
        onClick={e => {
          selectFunction(e, item.id);
        }}
      >
        <div
          className={playIcon}
          onClick={e => {
            selectFunction(e, item.id);
          }}
        >
          <IconPlayTour iconId={`play_${index}`} />
        </div>

        <div className={itemName}>{`${item.name}`}</div>
        <div
          className={activeIcons}
          onClick={e => {
            e.stopPropagation();
            editPtzTour(index);
          }}
        >
          <Translate>
            {({ translate }) => (
              <IconEdit
                iconId={`edit_${index}`}
                title={translate('PTZ.TOURS.EDIT_TOUR_LABEL')}
              />
            )}
          </Translate>
        </div>
      </div>,
    );
  });
  return elements;
};

const getCreateButton = (maxId, buttonTextId, createFunction, stopPtzTour) => {
  return (
    <div key={maxId + 1}>
      {buttonTextId === 'GENERIC_MESSAGES.CREATE_NEW_PRESET' ? (
        <div className={`${boxItem} ${center}`}>
          <Button
            buttonType="primary"
            inputType="button"
            onClick={createFunction}
          >
            <Translate id={buttonTextId} />
          </Button>
        </div>
      ) : (
        <div className={`${center} ${tourButtons}`}>
          <Button
            buttonType="primary"
            inputType="button"
            onClick={createFunction}
          >
            <Translate id={buttonTextId} />
          </Button>

          <Button
            buttonType="secondary"
            inputType="button"
            onClick={stopPtzTour}
          >
            <Translate id="GENERIC_MESSAGES.STOP_TOUR" />
          </Button>
        </div>
      )}
    </div>
  );
};

const BoxList = ({
  canEdit,
  cancelEdit,
  createFunction,
  deleteFunction,
  deletingItemIndex,
  editPtzTour,
  isCreating,
  listItems,
  listType,
  maxHeight,
  saveFunction,
  selectFunction,
  stopPtzTour,
}) => {
  const listStyle = { maxHeight };
  const listElements = [];
  let buttonTextId = '';

  buttonTextId =
    listType === presetTour.LIST_PRESETS
      ? 'GENERIC_MESSAGES.CREATE_NEW_PRESET'
      : 'GENERIC_MESSAGES.CREATE_NEW_TOUR';

  const creationArea = isCreating ? (
    <div key={listItems.maxId}>
      <LineEditor
        cancelEdit={cancelEdit}
        editType={presetTour.CREATE}
        iconColor={ACTIVE_COLOR_DEFAULT}
        itemType={presetTour.LIST_PRESETS}
        saveFunction={saveFunction}
      />
    </div>
  ) : (
    getCreateButton(listItems.maxId, buttonTextId, createFunction, stopPtzTour)
  );

  listType === presetTour.LIST_PRESETS
    ? listElements.push(
        displayPresets(
          listItems,
          deleteFunction,
          deletingItemIndex,
          selectFunction,
          saveFunction,
          cancelEdit,
          canEdit,
        ),
      )
    : listElements.push(displayTours(listItems, selectFunction, editPtzTour));

  return (
    <div>
      <div
        className={`${listHolder} ${
          listType === presetTour.LIST_PRESETS && listItems.length === 0
            ? emptyListHolder
            : null
        }`}
        style={listStyle}
      >
        {listType === presetTour.LIST_TOURS ? (
          <div className={tourTitle}>
            <Translate id="PTZ.TOURS.RUN_TOUR_TITLE" />
          </div>
        ) : (
          ''
        )}
        {listElements}
      </div>
      {canEdit ? <div className={buttonHolder}>{creationArea}</div> : null}
    </div>
  );
};

class PresetTourBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingItemIndex: null,
      isCreating: false,
      isDeleting: false,
      isSelected: false,
    };
  }

  componentDidMount() {
    this.props.actions.getPtzTours(
      this.props.webRtcHelper,
      this.props.camera.Id,
      this.props.camera.RemoteId,
    );
  }

  deleteItem = itemIndex => {
    this.savePreset(null, itemIndex);
  };

  editPtzTour = itemIndex => {
    let tour;
    if (this.props.ptzTours && this.props.camera.Id) {
      tour = this.props.ptzTours[this.props.camera.Id].tours[itemIndex] || {};
    }
    if (tour.name) {
      this.props.handleEditPtzTour(tour);
    }
  };

  getNewPresetId = () => {
    const ptzPresets = this.props.ptzPresets[this.props.camera.Id];
    const { presets } = ptzPresets;
    const { minId } = ptzPresets;
    const { maxId } = ptzPresets;

    if (presets.length === 0) {
      return minId;
    }

    const existingIds = presets.map(p => p.id);

    for (let i = minId; i <= maxId; i++) {
      if (!existingIds.includes(i)) return i;
    }

    return null;
  };

  savePreset = (presetName, deletingItemIndex, isHome) => {
    const cameraId = this.props.camera.Id;
    const cameraRemoteId = this.props.camera.RemoteId;
    const { presets } = this.props.ptzPresets[cameraId];

    if (deletingItemIndex !== null) {
      const presetId = presets[deletingItemIndex].id;
      this.props.actions.deletePtzPreset(
        this.props.webRtcHelper,
        cameraId,
        cameraRemoteId,
        presetId,
      );
      this.closeEditor();
      return;
    }

    if (!presetName.trim()) {
      this.closeEditor();
      return;
    }

    const newPresetId = this.getNewPresetId();

    if (newPresetId === null) {
      /* TODO: Inform user if all the 249 slots for presets have been used */
      avoLogError(
        'User has used all of their preset slots and cannot create more',
      );
    } else {
      this.props.actions.createPtzPreset(
        this.props.webRtcHelper,
        cameraId,
        cameraRemoteId,
        newPresetId,
        presetName,
        isHome,
      );
    }
    this.closeEditor();
  };

  closeEditor = () => {
    this.setState({
      deletingItemIndex: null,
      isCreating: false,
      isDeleting: false,
    });
  };

  cancelEdit = () => {
    this.closeEditor();
  };

  createNewPreset = () => {
    this.setState({
      isCreating: true,
    });
  };

  togglePTZModal = () => {
    this.props.isPtzTourModalOpen
      ? this.props.actions.hideModal()
      : this.props.actions.showModal(modalTypes.PTZ_TOUR, {
          modalKey: this.props.videoId,
        });
  };

  selectPreset = (e, presetId) => {
    e.preventDefault();
    const cameraId = this.props.camera.Id;
    const cameraRemoteId = this.props.camera.RemoteId;

    this.setState({
      isSelected: true,
    });

    this.props.actions.goToPtzPreset(
      this.props.webRtcHelper,
      cameraId,
      cameraRemoteId,
      presetId,
    );
  };

  selectTour = (e, tourId) => {
    e.preventDefault();
    const cameraId = this.props.camera.Id;
    const cameraRemoteId = this.props.camera.RemoteId;

    this.props.actions.startPtzTour(
      this.props.webRtcHelper,
      cameraId,
      cameraRemoteId,
      tourId,
    );
  };

  stopPtzTour = () => {
    const { deviceId } = this.props;
    const cameraId = this.props.camera.Id;
    const cameraRemoteId = this.props.camera.RemoteId;
    this.props.actions.stopTour(
      this.props.webRtcHelper,
      deviceId,
      cameraId,
      cameraRemoteId,
    );
  };

  render() {
    const cameraId = this.props.camera.Id;

    const presets =
      (this.props.ptzPresets &&
        this.props.ptzPresets[cameraId] &&
        this.props.ptzPresets[cameraId].presets) ||
      [];

    const tours =
      (this.props.ptzTours &&
        this.props.ptzTours[cameraId] &&
        this.props.ptzTours[cameraId].tours) ||
      [];

    const currentListNameId =
      this.props.activeList === presetTour.LIST_PRESETS
        ? 'VIDEO_PLAYER.PRESETS'
        : 'VIDEO_PLAYER.TOURS';

    const alternateListNameId = 'VIDEO_PLAYER.TOURS';

    const displayList =
      this.props.activeList === presetTour.LIST_PRESETS ? presets : tours;

    const createFunction =
      this.props.activeList === presetTour.LIST_PRESETS
        ? this.createNewPreset
        : this.togglePTZModal;

    const selectFunction =
      this.props.activeList === presetTour.LIST_PRESETS
        ? this.selectPreset
        : this.selectTour;

    const itemCount =
      this.props.activeList === presetTour.LIST_PRESETS
        ? presets.length
        : tours.length;

    let listMaxHeight = this.props.maxHeight - presetTour.RESERVED_HEIGHT;
    listMaxHeight =
      listMaxHeight < presetTour.LIST_MIN_HEIGHT
        ? presetTour.LIST_MIN_HEIGHT
        : listMaxHeight;

    return (
      <div
        className={presetTourBox}
        style={{
          background: this.props.background,
          border: this.props.border,
          maxHeight: `${this.props.maxHeight}px`,
          width: `${this.props.maxWidth}px`,
        }}
      >
        <BoxHeader
          back={this.props.switchList}
          close={this.props.close}
          displayNameId={currentListNameId}
          itemCount={itemCount}
          listType={this.props.activeList}
        />
        <BoxList
          cancelEdit={this.cancelEdit}
          canEdit={this.props.canEditPtzPresetsAndTours}
          createFunction={createFunction}
          deleteFunction={this.deleteItem}
          deletingItemIndex={this.state.deletingItemIndex}
          editPtzTour={this.editPtzTour}
          isCreating={this.state.isCreating}
          listItems={displayList}
          listType={this.props.activeList}
          maxHeight={listMaxHeight}
          saveFunction={this.savePreset}
          selectFunction={selectFunction}
          stopPtzTour={this.stopPtzTour}
        />
        {this.props.activeList === presetTour.LIST_PRESETS ? (
          <BoxFooter
            clickAction={this.props.switchList}
            displayNameId={alternateListNameId}
            isCreating={this.state.isCreating}
            listType={this.props.activeList}
          />
        ) : null}
      </div>
    );
  }
}

PresetTourBox.propTypes = {
  background: PropTypes.string,
  activeList: PropTypes.string,
  border: PropTypes.string,
  bottomMargin: PropTypes.number,
  listFooterId: PropTypes.string,
  listItems: PropTypes.array,
  listTitleId: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  onClose: PropTypes.func,
  switchList: PropTypes.func,
};

PresetTourBox.defaultProps = {
  background: 'rgba(0, 0, 0, 0.8)',
  border: '',
  bottomMargin: 0,
  listFooterId: 'VIDEO_PLAYER.TOURS',
  listTitleId: 'VIDEO_PLAYER.PRESETS',
  maxHeight: 400,
  maxWidth: 300,
  onClose: () => {},
  ptzPresets: {},
  ptzTours: {},
};

function mapDispatchToProps(dispatch) {
  const deviceActions = DeviceActions;

  return {
    actions: bindActionCreators(
      { ...deviceActions, hideModal, showModal },
      dispatch,
    ),
  };
}

function mapStateToProps(state) {
  return {
    canEditPtzPresetsAndTours:
      state.user.permissions.CAN_EDIT_PTZ_PRESETS_AND_TOURS,
    isPtzTourModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.PTZ_TOUR,
    ptzPresets: state.devices.ptzPresets,
    ptzTours: state.devices.ptzTours,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresetTourBox);
