exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".serverImage___1f1LR {\r\n  width: 100%;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.serverImageContainer___1sAhx {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  -ms-flex-pack: start;\r\n      justify-content: flex-start;\r\n  -ms-flex-align: start;\r\n      align-items: start;\r\n  width: 100%;\r\n  height: 100%;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/ServerImage/ServerImage.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,cAAc;CACf;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;EAC3B,qBAAqB;MACjB,4BAA4B;EAChC,sBAAsB;MAClB,mBAAmB;EACvB,YAAY;EACZ,aAAa;CACd","file":"ServerImage.styles.css","sourcesContent":[".serverImage {\r\n  width: 100%;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.serverImageContainer {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  -ms-flex-pack: start;\r\n      justify-content: flex-start;\r\n  -ms-flex-align: start;\r\n      align-items: start;\r\n  width: 100%;\r\n  height: 100%;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"serverImage": "serverImage___1f1LR",
	"serverImageContainer": "serverImageContainer___1sAhx"
};