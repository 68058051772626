import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';

// Components
import { InputFilter } from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { PageMessage } from 'containers';

// Actions
import * as SubscriptionActions from 'actions/subscriptions';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';

// Utilities
import * as permissions from 'util/permissions';
import FilterableContainer from 'util/FilterableContainer';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import {
  ACTION_IMAGES,
  BULK_ACTION_TABLES,
  FILTER_GROUPS,
  unlimitedCameras,
} from 'constants/app';
import AccountNavMenu from '../AccountNavMenu';
import ServicePlansTable from './ServicePlansTable/ServicePlansTable';

// Style
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';
import { filterWrapper } from '../styles.css';

import {
  CAMERA_RESOURCE_TYPE,
  filters,
  idSearchInput,
  idServicePlanNavHeader,
  SEARCH_PLACEHOLDER,
  servicePlansTextInputFields as textInputFields,
} from '../constants';

class ServicePlansContainer extends FilterableContainer {
  // eslint-disable-next-line react/static-property-placement
  static contextType = BulkActionContext;

  constructor(props) {
    super(props);
    this.state = {
      queryOptions: {},
      submittingForm: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      isFetchingServicePackages,
      orgId,
      selectedDealerId,
    } = this.props;
    if (!isFetchingServicePackages && selectedDealerId) {
      actions.getServicePackages(selectedDealerId);
    } else if (!isFetchingServicePackages && orgId) {
      actions.getServicePackages(orgId);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get selectedServicePlanIds() {
    return (
      this.context.bulkActionIdsFor(BULK_ACTION_TABLES.SERVICE_PLANS) || []
    );
  }

  onClickRow = servicePlanData => {
    this.props.history.push(`/accounts/serviceplans/${servicePlanData.Id}`);
  };

  handleFilter = (field, values) => {
    const { handleFilter } = this.props;
    handleFilter(field, values, false, 'contains');
  };

  // Override the behavior of NavigationWrapper to navigate to a route instead of opening a modal
  handleAddClick = () => {
    this.props.history.push(`/accounts/serviceplans/new`);
  };

  convertServicePackages = (servicePackages, translate) => {
    const emptyGrant = {};
    const servicePackagesWithCode = servicePackages.filter(
      servicePackage => servicePackage.ClaimCode,
    );
    const servicePlans = servicePackagesWithCode.map(servicePackage => {
      const grant =
        servicePackage.Grants.find(
          x => x.ResourceType === CAMERA_RESOURCE_TYPE,
        ) || emptyGrant;
      const channels =
        grant.Quantity === unlimitedCameras
          ? translate('CAMERA.CAMERAS_QUANTITY_UNLIMITED')
          : grant.Quantity;

      const servicePlanTermLengths = servicePackage.TermLengths.map(
        termLength => {
          let term = 'N/a';
          if (termLength.NumberOfMonths) {
            term = translate(
              `${
                termLength.NumberOfMonths > 1
                  ? 'SUBSCRIPTIONS.SUBSCRIPTION_TERM_LENGTH_PLURAL'
                  : 'SUBSCRIPTIONS.SUBSCRIPTION_TERM_LENGTH'
              }`,
              // eslint-disable-next-line camelcase
              { month_qty: termLength.NumberOfMonths },
            );
          }

          return {
            AutoRenew: servicePackage.IsAutoRenewable
              ? translate('COMMON.YES')
              : translate('COMMON.NO'),
            Channels: channels,
            Code: servicePackage.ClaimCode,
            Id: servicePackage.Id,
            ServicePlan: servicePackage.Name,
            Term: term,
          };
        },
      );

      return servicePlanTermLengths || [];
    });

    return servicePlans.reduce((accu, elem) => {
      return accu === [] ? elem : accu.concat(elem);
    }, []);
  };

  render() {
    const {
      canChangeCustomer,
      filteredItems,
      handleEditClick,
      isFetchingServicePackages,
      noNavigation,
      noPageTitle,
      profile,
      translate,
    } = this.props;

    const convertedFilteredItems = this.convertServicePackages(
      filteredItems,
      translate,
    );
    return (
      <>
        <ListNav
          actionImage={ACTION_IMAGES.SERVICE_PLAN}
          bulkActionsTable={BULK_ACTION_TABLES.SERVICE_PLANS}
          canAdd
          canChangeCustomer={canChangeCustomer}
          canDelete
          deleteActive={this.selectedServicePlanIds.length > 0}
          id={idServicePlanNavHeader}
          navigationTabs={
            noNavigation ? null : <AccountNavMenu profile={profile} />
          }
          onAddClick={this.handleAddClick}
          onViewClick={handleEditClick}
          pageTitle={
            noPageTitle ? null : (
              <PageTitle titleId="SERVICE_PLANS_TABLE.SERVICE_PLANS_TITLE" />
            )
          }
          viewActive={this.selectedServicePlanIds.length > 0}
        >
          <div className={filterWrapper}>
            <InputFilter
              field={textInputFields}
              id={idSearchInput}
              onChange={this.handleFilter}
              overrideValue
              placeholder={SEARCH_PLACEHOLDER}
              value={this.getFilterValue(
                filters.servicePlans.servicePlan.field,
              )}
            />
          </div>
        </ListNav>
        <MainContentWrapper>
          <PageMessage
            messageType={[
              messageTypes.SERVICE_PLAN_ERROR,
              messageTypes.SERVICE_PLAN_SUCCESS,
            ]}
          />
          <EmptyPlaceholder
            isFetching={isFetchingServicePackages}
            items={convertedFilteredItems}
            string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
          >
            <ServicePlansTable
              onRowClicked={this.onClickRow}
              rowsSelected={this.context.bulkActionIds.servicePlans}
              servicePlans={convertedFilteredItems}
              string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
            />
          </EmptyPlaceholder>
        </MainContentWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    filterGroup: FILTER_GROUPS.SERVICE_PLANS,
    filterStatus: state.filters.filterStatus,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    items: state.locations.dealerServicePackages,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_EDIT_SERVICE_PLAN,
    orgId,
    profile: state.user.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...SubscriptionActions,
        ...FilterActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default withLocalize(
  NavigationWrapper(
    ServicePlansContainer,
    mapStateToProps,
    mapDispatchToProps,
    modalTypes.ADD_EDIT_SERVICE_PLAN,
    { textInputFields },
  ),
);
