import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import {
  cardBody,
  cardHeader,
  cardKey,
  cardKeyLabel,
  cardKeySwatch,
  cardSubheader,
  dashboardCard,
} from './dashboard.styles.css';

const LargeCard = ({
  chartKeys,
  children,
  headerTextKey,
  id,
  subheaderKey,
}) => (
  <div className={dashboardCard} id={id}>
    <h3 className={cardHeader}>
      <Translate id={headerTextKey} />
    </h3>
    {chartKeys && (
      <div className={cardKey}>
        {chartKeys.map(k => (
          <span key={k.labelTranslateKey}>
            <div
              className={cardKeySwatch}
              style={{ backgroundColor: k.color }}
            />
            <div className={cardKeyLabel}>
              <Translate id={k.labelTranslateKey} />
            </div>
          </span>
        ))}
      </div>
    )}
    {subheaderKey && (
      <h4 className={cardSubheader}>
        <Translate id={subheaderKey} />
      </h4>
    )}
    <div className={cardBody}>{children}</div>
  </div>
);

LargeCard.propTypes = {
  chartKeys: PropTypes.arrayOf(PropTypes.any).isRequired,
  children: PropTypes.node.isRequired,
  headerTextKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  subheaderKey: PropTypes.string.isRequired,
};

export default LargeCard;
