import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { contentTop } from './ContentWrapper.css';

const TopContentWrapper = ({ children, contentTopHeader, contentTopNav }) => {
  return (
    <div className={`${contentTopNav || contentTopHeader} ${contentTop}`}>
      {children}
    </div>
  );
};

TopContentWrapper.defaultProps = {
  children: null,
  contentTopHeader: '',
  contentTopNav: '',
};

TopContentWrapper.propTypes = {
  children: PropTypes.node,
  contentTopHeader: PropTypes.string,
  contentTopNav: PropTypes.string,
};

export default TopContentWrapper;
