/* Pure action creators for manipulating data for rules and boundaries on the client.
/* Actions to get or update this data via Gateway / WEP can be found in cameraSettings actions
/* this file deals only with redux manipulation, not API calls (for now).
*/

import * as types from 'constants/ActionTypes';

export const resetRule = (id, ruleId) => ({
  type: types.RESET_RULE,
  id,
  ruleId,
});

export const resetAllRules = id => ({
  type: types.RESET_ALL_RULES,
  id,
});

export const setRule = (id, ruleId, ruleData) => ({
  type: types.SET_RULE,
  id,
  ruleId,
  ruleData,
});

export const deleteRule = (id, ruleId) => ({
  type: types.DELETE_RULE,
  id,
  ruleId,
});

export const addRule = (id, ruleId) => ({
  type: types.ADD_RULE,
  id,
  ruleId,
});

export const setRuleCanvasVisibility = (id, playerId, show = true) => ({
  id,
  playerId,
  show,
  type: types.SET_RULE_CANVAS_VISIBILITY,
});

export const resetAllPrivacyZones = id => ({
  type: types.RESET_ALL_ZONES,
  id,
});

export const setPrivacyZone = (id, zoneId, data = null) => ({
  type: types.SET_ZONE,
  id,
  zoneId,
  data, // null data will delete the zone in redux
});

export const addPrivacyZone = (id, selected = false) => ({
  id,
  selected,
  type: types.ADD_ZONE,
});
