
import urlBuilder from 'queryBuilder/url';
import { BAD_CONNECTION_STRING, MODEL_STATE } from 'constants/app';
import * as types from 'constants/ActionTypes';
import { sendPostRequest } from './fetchHelpers';

export const validateConnectionString = async (
  IntegrationType,
  ConnectionString,
) => {
  const url = urlBuilder(types.VALIDATE_CONNECTION_STRING);
  try {
    const response = await sendPostRequest(url, {
      IntegrationType,
      ConnectionString,
    });
    if (response.status === 200) {
      return undefined;
    }
    if (response.status === 400) {
      const json = await response.json();
      return json[MODEL_STATE][BAD_CONNECTION_STRING][0];
    }
  } catch (err) {
    avoLogError('Error validating Connection String', err);
  }
  return 'INTEGRATIONS.CONFIGS.VALIDATION_ERROR';
};
