/* global avoLogError */
import urlBuilder from 'queryBuilder/url';
import {
  GET_GATEWAY_SPEAKER_SETTINGS,
  IS_COMMITTING_SPEAKER_SETTINGS,
  IS_FETCHING_SPEAKER_SETTINGS,
  RECEIVE_SPEAKER_SETTINGS,
} from 'constants/ActionTypes';
import { findCamera } from 'util/normalizeCamera';
import store from 'store/configureStore';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  sendGetRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';
import { showMessage } from '../pageMessage';
import { isFetching } from '../common';

export function receiveSpeakerSettings(cameraId, json) {
  return {
    cameraId,
    data: json,
    type: RECEIVE_SPEAKER_SETTINGS,
  };
}

export function getSpeakerSettings({ cameraId, deviceId, remoteId }) {
  return dispatch => {
    const url = urlBuilder(GET_GATEWAY_SPEAKER_SETTINGS, deviceId, remoteId);
    dispatch(isFetching(IS_FETCHING_SPEAKER_SETTINGS));
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(isFetching(IS_FETCHING_SPEAKER_SETTINGS, false));
        dispatch(receiveSpeakerSettings(cameraId, json));
      })
      .catch(error => {
        dispatch(isFetching(IS_FETCHING_SPEAKER_SETTINGS, false));
        avoLogError('Error getting speaker settings', error);
      });
  };
}

export function setSpeakerSettings({ cameraId, data, deviceId, remoteId }) {
  return dispatch => {
    const url = urlBuilder(GET_GATEWAY_SPEAKER_SETTINGS, deviceId, remoteId);
    let settings;
    findCamera(store.getState(), cameraId, camera => {
      settings = {
        speaker: {
          audioOutEnable: {
            id: camera.speakers.activatedId,
            val: data.activated,
          },
          audioOutVolume: {
            id: camera.speakers.volumeId,
            val: data.volume,
          },
        },
      };
    });
    dispatch(isFetching(IS_COMMITTING_SPEAKER_SETTINGS));
    sendPutRequestReturningJSON(url, {
      id: remoteId,
      settings,
    })
      .then(json => {
        if (json.accepted && json.accepted.length > 0) {
          dispatch(isFetching(IS_COMMITTING_SPEAKER_SETTINGS, false));
          dispatch(receiveSpeakerSettings(cameraId, settings));
          dispatch(
            showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_SETTINGS_SUCCESS',
            }),
          );
        } else {
          throw new Error('GATEWAY_UPDATE_SETTINGS_ERROR');
        }
      })
      .catch(error => {
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_SETTINGS_ERROR',
          }),
        );
        avoLogError('Error setting speaker settings', error);
      });
  };
}
