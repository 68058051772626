import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR_DEFAULT, SVG_XMLNS } from '../constants/app';

const IconPhone = props => {
  const { fill, height, id, title, width } = props;

  return (
    <svg
      height={height}
      id={id}
      version="1.1"
      viewBox="0 0 30 30"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.4372466,15.2191915 L16.4881049,13.417046 C16.2875822,13.2945302 16.0575937,13.2297054 15.8228751,13.2297054 C15.4404397,13.2297054 15.0814993,13.3987462 14.8381736,13.6937146 L14.4361977,14.1808313 L13.6953648,15.0786861 C12.7075617,14.5391512 11.7870647,13.8791942 10.9582995,13.1157191 C10.9326332,13.0920689 10.9078199,13.0673331 10.8843248,13.0417444 C10.1210823,12.2130567 9.46120284,11.2929475 8.92159048,10.3047567 L9.79897428,9.58075027 L10.3064844,9.1619478 C10.7935235,8.75997188 10.9124724,8.05069838 10.583153,7.51209406 L8.78092992,4.5629524 C8.5688535,4.21579842 8.1842469,4 7.77738585,4 C7.69689762,4 7.61609922,4.00845204 7.53716181,4.02489087 L6.65349714,4.20920738 C6.64481248,4.21099084 6.63636044,4.21339463 6.62767577,4.21533317 C5.86769004,4.38166001 5.22192316,4.86823388 4.85367785,5.55455505 L4.53963416,6.13968436 C3.91751748,7.29893206 3.82896491,8.6691706 4.29654108,9.89905875 C5.1346888,12.1029476 6.35310078,14.1147658 7.91804641,15.8787608 C7.98147548,15.9501767 8.04986722,16.018646 8.12151571,16.0821526 C9.88535568,17.6470982 11.8971738,18.8654327 14.1011402,19.7035804 C14.6183431,19.9003035 15.1610571,19.9999446 15.7142392,19.9999446 C16.4605776,19.9999446 17.2028839,19.8133794 17.8605146,19.4604873 L18.4455664,19.1464436 C19.1397193,18.7739335 19.6300927,18.1179311 19.7909916,17.3467018 L19.7911467,17.3459264 L19.9753081,16.4629596 C20.0765775,15.9776264 19.8603138,15.4777929 19.4372466,15.2191915"
          fill={fill}
        />
      </g>
    </svg>
  );
};

IconPhone.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
};

IconPhone.defaultProps = {
  fill: ACTIVE_COLOR_DEFAULT,
  height: '30px',
  id: 'iconPhone',
  title: '',
  width: '30px',
};

export default IconPhone;
