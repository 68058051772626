import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResizableAndDraggable from 'react-rnd';
import { Translate } from 'react-localize-redux';
import { selectedPrivacyZone, notSelectedPrivacyZone } from './styles.css';

class PrivacyRectangle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTransforming: false,
      lastDraggedPrivacyZone: null,
    };
  }

  handleSelect = () => {
    const { onObjectSelected, zoneId } = this.props;
    onObjectSelected(zoneId);
  };

  handleResizeStart = () => {
    this.setState({ isTransforming: true }, this.handleSelect);
  };

  handleResizeStop = (event, direction, refToElement, delta) => {
    // Note that since this uses a delta rather than an absolute size,
    // it is dependent on props.rectangle being up to date
    const { rectangle, onZoneUpdated, zoneId } = this.props;
    const { x, y, rectangleHeight, rectangleWidth } = rectangle;
    const newRectangle = {
      rectangleHeight: rectangleHeight + delta.height,
      rectangleWidth: rectangleWidth + delta.width,
      x,
      y,
    };
    switch (direction) {
      case 'top':
        newRectangle.y = y - delta.height;
        break;
      case 'topLeft':
        newRectangle.y = y - delta.height;
        newRectangle.x = x - delta.width;
        break;
      case 'left':
        newRectangle.x = x - delta.width;
        break;
      default:
        break;
    }
    this.setState({ isTransforming: false }, () =>
      onZoneUpdated(newRectangle, zoneId),
    );
  };

  handleDragStart = (node, point) => {
    if (point.x && point.y) {
      this.setState({ isTransforming: true }, this.handleSelect);
    }
  };

  handleDragStop = (e, data) => {
    this.setState({ isTransforming: false });
    const { rectangle, onZoneUpdated, zoneId } = this.props;
    let { x, y } = data;
    if (x < 1) {
      x = 1;
    }
    if (y < 1) {
      y = 1;
    }
    onZoneUpdated({ ...rectangle, x, y }, zoneId);
  };

  render() {
    const {
      rectangle,
      rectMinHeight,
      rectMinWidth,
      isSelected,
      zoneId: id,
    } = this.props;
    const { rectangleWidth, rectangleHeight, x, y } = {
      rectangleWidth: rectMinWidth,
      rectangleHeight: rectMinHeight,
      ...rectangle,
    };
    return (
      <ResizableAndDraggable
        ref={c => {
          this[`rnd-${id}`] = c;
        }}
        default={{
          x,
          y,
          width: `${rectangleWidth}px`,
          height: `${rectangleHeight}px`,
        }}
        z={0}
        minWidth={rectMinWidth}
        minHeight={rectMinHeight}
        bounds="parent"
        key={id}
        onResizeStart={this.handleResizeStart}
        onResizeStop={this.handleResizeStop}
        onDragStart={this.handleDragStart}
        onDragStop={this.handleDragStop}
        extendsProps={id}
      >
        <div
          id={id}
          className={isSelected ? selectedPrivacyZone : notSelectedPrivacyZone}
          key={id}
          style={{
            height: '100%',
            width: '100%',
            margin: 0,
          }}
        >
          {
            <Translate
              data={{ number: parseInt(id, 10) + 1 }}
              id="CAMERA.SETTINGS.PRIVACY_ZONE_LABEL"
            />
          }
        </div>
      </ResizableAndDraggable>
    );
  }
}

PrivacyRectangle.defaultProps = {
  rectDefaultHeight: 100,
  rectDefaultWidth: 100,
  rectMinWidth: 10,
  rectMinHeight: 10,
  onZoneUpdated: () => {},
  name: '',
  isSelected: false,
  onObjectSelected: () => {},
};

PrivacyRectangle.propTypes = {
  rectangle: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }).isRequired,
  zoneId: PropTypes.number.isRequired,
  rectDefaultHeight: PropTypes.number,
  rectDefaultWidth: PropTypes.number,
  rectMinHeight: PropTypes.number,
  rectMinWidth: PropTypes.number,
  onZoneUpdated: PropTypes.func,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  onObjectSelected: PropTypes.func,
};

export default PrivacyRectangle;
