import React from 'react';
import { PropTypes } from 'prop-types';
import renderIf from 'render-if';
import { IC_FAVORITE_DEFAULT_ON, IC_FAVORITE_ON } from 'constants/iconNames';
import { IconCloud } from 'icons';
import { Icon } from 'lib';
import {
  defaultIconWrap,
  dropDownItemText,
  optionStyle,
  optionStyleSelected,
  truncatedSelectText,
  viewEditorIcons,
} from './styles.css';

import { VIEW_EDITOR_ICON_SIZE } from './constants';

const ViewEditorDropdownOption = ({
  children,
  isFocused,
  isSelected,
  onFocus,
  onSelect,
  option,
}) => {
  const handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  };

  const handleMouseEnter = event => {
    onFocus(option, event);
  };

  const handleMouseMove = event => {
    if (isFocused) return;
    onFocus(option, event);
  };

  return (
    <div
      className={isSelected ? optionStyleSelected : optionStyle}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      role="button"
      tabIndex="0"
    >
      <span className={`${truncatedSelectText} ${dropDownItemText}`}>
        {children}
      </span>
      <div className={viewEditorIcons}>
        {renderIf(option && option.isDefault)(
          <Icon
            iconClass={defaultIconWrap}
            id={IC_FAVORITE_DEFAULT_ON}
            size={VIEW_EDITOR_ICON_SIZE}
          />,
        )}
        {renderIf(option && !option.isDefault && option.isFavorite)(
          <Icon id={IC_FAVORITE_ON} size={VIEW_EDITOR_ICON_SIZE} />,
        )}
        {renderIf(option && !option.isAccView)(
          <IconCloud
            fill="#aaa"
            height={VIEW_EDITOR_ICON_SIZE}
            width={VIEW_EDITOR_ICON_SIZE}
          />,
        )}
      </div>
    </div>
  );
};

ViewEditorDropdownOption.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.string.isRequired,
};

ViewEditorDropdownOption.defaultProps = {
  children: null,
  isFocused: false,
  isSelected: false,
  onFocus() {},
  onSelect() {},
};

export default ViewEditorDropdownOption;
