exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".contentMain___1AFFG {\r\n  box-sizing: border-box;\r\n  -ms-flex: 1;\r\n      flex: 1;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n}\r\n\r\n.contentTop___2HEz3 {\r\n  background-color: #ffffff;\r\n}\r\n\r\n.contentMainBody____TsT3 {\r\n  padding: 0px 16px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/ContentWrapper/ContentWrapper.css"],"names":[],"mappings":"AAAA;EAEE,uBAAuB;EACvB,YAAY;MACR,QAAQ;EACZ,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;CAC5B;;AAED;EACE,0BAA0B;CAC3B;;AAED;EACE,kBAAkB;CACnB","file":"ContentWrapper.css","sourcesContent":[".contentMain {\r\n  composes: backgroundContent from '../../sharedStyles/brushes.css';\r\n  box-sizing: border-box;\r\n  -ms-flex: 1;\r\n      flex: 1;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n}\r\n\r\n.contentTop {\r\n  background-color: #ffffff;\r\n}\r\n\r\n.contentMainBody {\r\n  padding: 0px 16px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"contentMain": "contentMain___1AFFG " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["backgroundContent"] + "",
	"contentTop": "contentTop___2HEz3",
	"contentMainBody": "contentMainBody____TsT3"
};