import { createSelector } from 'reselect';
import { decorateCamera } from 'util/aftHelper';
import { findServerCategory, DeviceSource } from '../util/deviceCategory';

const camerasSelector = state => state.devices.cameras;
const devicesSelector = state => state.devices.devices;
const locationsSelector = state => state.locations.locations;
const aftSelector = state => state.aft;

const indexedLocationsSelector = createSelector(
  locationsSelector,
  locations =>
    locations.reduce(
      (acc, location) => ({
        ...acc,
        [location.Id]: location,
      }),
      {},
    ),
);

function getConnectionState(camera) {
  if (!camera.available) {
    return 'DISCONNECTED';
  }

  return camera.connectionStatus.state;
}

const cameraSelectionSelector = createSelector(
  camerasSelector,
  devicesSelector,
  indexedLocationsSelector,
  aftSelector,
  (cameras, devices, locations, aft) => {
    const cameraArr = [];

    const addCamera = (locationId, camera) => {
      const itemIndex = cameraArr.findIndex(
        item => item.locationId === locationId,
      );
      if (itemIndex >= 0) {
        cameraArr[itemIndex].cameras.push(camera);
      } else {
        const location = locations[locationId] || {};
        cameraArr.push({
          locationId,
          locationName: location.Name,
          cameras: [camera],
        });
      }
    };
    /* Add all valid Blue cameras */
    cameras.forEach(camera => {
      const device = devices.find(x => x.Id === camera.DeviceId);
      if (findServerCategory(device) !== DeviceSource.BLUE) {
        return;
      }
      addCamera(camera.LocationId, camera);
    });
    /* Add all AFT cameras */
    Object.entries(aft).forEach(([locationId, clusters]) => {
      Object.entries(clusters).forEach(([clusterId, cameras]) => {
        cameras.map(decorateCamera).forEach(camera =>
          camera.apiType !== 'LOCAL_DIGITAL_IO'
            ? addCamera(locationId, {
                Id: camera.id,
                RemoteId: camera.id,
                DeviceId: camera.serverId,
                Name: camera.name || '',
                Manufacturer: camera.manufacturer,
                Model: camera.model,
                ConnectionState: getConnectionState(camera),
                RecordedData: camera.available,
                Active: camera.active,
                getRevokableSnapshotURL: camera.getRevokableSnapshotURL,
              })
            : null,
        );
      });
    });

    /* Sort the array by location name */
    cameraArr.sort((a, b) => {
      const aName = a.locationName ? a.locationName.toUpperCase() : '';
      const bName = b.locationName ? b.locationName.toUpperCase() : '';
      if (aName > bName) {
        return 1;
      }
      if (aName < bName) {
        return -1;
      }
      return 0;
    });
    return cameraArr;
  },
);

export default cameraSelectionSelector;
