/*

IMPORTANT: It is important that CardSet's prop signature and OGTable's prop signature stay aligned.
It is okay to not have exactly the same props. But the names should mean and do about the same things.

It is better to have the features as similar as possible.

- Andre

*/
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { withLocalize } from 'react-localize-redux';
import * as styles from './CardSet.css';

function CardSet(props) {
  const { bulkActionIdsFor, setBulkActionIds } = useContext(BulkActionContext);
  const {
    bodyHeight,
    bulkActionsTable,
    cardClickCallback,
    clickable,
    customCells,
    data,
    featuredField,
    fieldOrder,
    rowActions,
    titleField,
    translate,
  } = props;
  if (data.length === 0) {
    return (
      <div className={styles.noResults}>
        {translate('FILTER.NO_RESULTS_FOUND')}
      </div>
    );
  }

  function onClickCardBody(cardData, cardIndex) {
    if (clickable) {
      cardClickCallback(cardData, cardIndex);
    }
  }

  function onSelectCard(cardData, checked) {
    const { bulkActionsSingle } = props;
    let newList;
    const currentIds = bulkActionIdsFor(bulkActionsTable) || [];
    if (checked) {
      if (bulkActionsSingle) {
        newList = [cardData.Id];
      } else {
        newList = [...currentIds, cardData.Id];
      }
    } else {
      newList = currentIds.filter(actionId => actionId !== cardData.Id);
    }
    setBulkActionIds(bulkActionsTable, newList);
  }

  return (
    <div className={styles.cardSet}>
      <div
        className={styles.cardItems}
        style={{
          maxHeight:
            typeof bodyHeight === 'number' ? `${bodyHeight}px` : bodyHeight,
        }}
      >
        {data.map((item, rowIdx) => {
          const selected = bulkActionIdsFor(bulkActionsTable).includes(item.Id);
          return (
            <div
              key={`card-${rowIdx}`}
              className={`${styles.cardItem} ${
                clickable ? styles.clickable : ''
              } ${selected ? styles.selected : ''}`}
              onClick={() => {
                return onClickCardBody(item, rowIdx);
              }}
            >
              {featuredField ? (
                <div key="featuredField" className={styles.featuredField}>
                  {customCells[featuredField]
                    ? customCells[featuredField](item, rowIdx)
                    : item[featuredField]}
                </div>
              ) : (
                ''
              )}
              <div key="selection-checkbox" className={styles.cardCheckbox}>
                <input
                  checked={selected}
                  onChange={e => {
                    e.stopPropagation();
                    const { target } = e;
                    onSelectCard(item, target.checked);
                  }}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  type="checkbox"
                />
              </div>
              <div key="actions" className={styles.actionsContainer}>
                {rowActions && item
                  ? rowActions.map(actionItem => {
                      return actionItem(item, rowIdx);
                    })
                  : null}
              </div>
              <div key="data" className={styles.cardData}>
                {titleField ? (
                  <div
                    key="titleField"
                    className={`${styles.fieldValue} ${styles.titleField}
                    }`}
                  >
                    {customCells[titleField]
                      ? customCells[titleField](item, rowIdx)
                      : item[titleField]}
                  </div>
                ) : (
                  ''
                )}
                {fieldOrder
                  .filter(
                    fieldName =>
                      fieldName !== 'Actions' &&
                      fieldName !== featuredField &&
                      fieldName !== titleField,
                  )
                  .map(fieldName => {
                    return (
                      <div
                        key={fieldName}
                        className={`${styles.fieldValue} ${
                          titleField === fieldName ? styles.titleField : ''
                        }`}
                      >
                        {customCells[fieldName]
                          ? customCells[fieldName](item, rowIdx)
                          : item[fieldName]}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

CardSet.propTypes = {
  bodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bulkActions: PropTypes.bool,
  bulkActionsSingle: PropTypes.bool,
  bulkActionsTable: PropTypes.string,
  cardClickCallback: PropTypes.func,
  clickable: PropTypes.bool,
  customCells: PropTypes.objectOf(PropTypes.func),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  featuredField: PropTypes.string,
  fieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  rowActions: PropTypes.arrayOf(PropTypes.func),
  titleField: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

CardSet.defaultProps = {
  bodyHeight: null,
  bulkActions: false,
  bulkActionsSingle: false,
  bulkActionsTable: null,
  cardClickCallback: (rowData, rowIndex) => {
    avoLog('Clicked CardSet Card', { rowData, rowIndex });
  },
  clickable: false,
  customCells: {},
  featuredField: null,
  rowActions: null,
  titleField: 'Name',
};

export default withLocalize(CardSet);
