import React from 'react';

const BulkActionContext = React.createContext({
  bulkActionIds: {},
  bulkActionIdsFor: () => {},
  clearBulkActionIdsFor: () => {},
  setBulkActionIds: () => {},
});

export default BulkActionContext;
