import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function pageMessageReducer(
  state = initialState().pageMessage,
  action = {},
) {
  switch (action.type) {
    case types.SHOW_MESSAGE:
      return {
        messageType: action.messageType,
        header: action.header,
        body: action.body || action.props.body,
        props: action.props,
      };

    case types.HIDE_MESSAGE:
      return initialState().pageMessage;

    default:
      return state;
  }
}
