/* FIXME this implementation is too simplistic to be useful for future
 * AFT use cases. It'll serve the immediate needs until we can discuss
 * how to proceed.
 */

import get from 'lodash.get';
import {
  AFT as AFT_ACTION_TYPE,
  REMOVE_CLUSTER,
  RESET_USER_CONTEXT,
} from 'constants/ActionTypes';
import concatUpsert from 'util/concatUpsert';

import initialState from 'store/initialState';

export default function aftReducer(state, action) {
  const { cameras, clusterId, locationId } = action;
  switch (action.type) {
    case AFT_ACTION_TYPE.RECEIVE_CAMERA_LIST: {
      const clusters = state[locationId] || {};
      return Object.assign({}, state, {
        [locationId]: {
          ...clusters,
          [clusterId]: cameras.map(camera => ({ ...camera, clusterId })),
        },
      });
    }
    case AFT_ACTION_TYPE.UPSERT_CAMERA_LIST: {
      const clusters = state[locationId] || {};
      const camerasWithClusterId = cameras.map(camera => ({
        ...camera,
        clusterId,
      }));
      const stateCameras = get(state, `[${locationId}][${clusterId}]`, []);
      const newCameras = concatUpsert(stateCameras, camerasWithClusterId, 'id');
      return {
        ...state,
        [locationId]: {
          ...clusters,
          [clusterId]: newCameras,
        },
      };
    }
    case AFT_ACTION_TYPE.REMOVE_CAMERAS: {
      if (!state[locationId] || !state[locationId][clusterId]) return state;
      const { cameraIds } = action;
      const oldCameras = state[locationId][clusterId];
      const newCameras = oldCameras.filter(c => !cameraIds.includes(c.id));
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          [clusterId]: newCameras,
        },
      };
    }
    case REMOVE_CLUSTER: {
      if (locationId) {
        const newLocationClusters = Object.assign({}, state[locationId]);
        delete newLocationClusters[clusterId];
        return Object.assign({}, state, { [locationId]: newLocationClusters });
      }
      return state;
    }
    case RESET_USER_CONTEXT: {
      return initialState().aft;
    }
    default: {
      return state || initialState().aft;
    }
  }
}
