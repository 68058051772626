// Libs
import React from 'react';
import { Translate } from 'react-localize-redux';

// Container
import {
  DocLinkContainer,
  LanguageSelect,
  UserVoiceLinkContainer,
} from 'containers';

// Styles
import {
  companyInfo,
  copyright,
  footerSelectWrapper,
  languageDropdownContainer,
  languageText,
  SelectDropUp,
  separator,
  versionInfo,
} from 'sharedStyles/styles.css';
import { footerBody } from 'sharedStyles/global.css';

// Constants
import { LANGUAGES } from 'constants/app';

// NOTE: This function is overwriting the constant VERSION_INFO @ app/constants/strings.js so it could be translated for this case.
import getVersionInfo from 'util/getVersionInfo';
import * as loggedInConsts from './LoggedInFrameFooter.constants';

const LoggedInFrameFooter = () => {
  function clickVersion(e) {
    if (e.shiftKey && e.altKey && window.localStorage) {
      localStorage.skeletonKey = true;
      window.location.reload();
    }
  }

  function clickVersionTouch() {
    localStorage.skeletonKey = true;
    window.location.reload();
  }

  return (
    <div className={footerBody}>
      <div className={companyInfo}>
        <div className={copyright}>
          <span>© </span>
          {`${new Date().getFullYear()} ${loggedInConsts.companyName}`}
        </div>
        <DocLinkContainer
          id={loggedInConsts.idEulaDocLink}
          linkKey={loggedInConsts.eulaDocKey}
          linkText={<Translate id="FOOTER.EULA_DOC_TEXT" />}
        />
        <span className={separator} />
        <DocLinkContainer
          id={loggedInConsts.idTosDocLink}
          linkKey={loggedInConsts.tosDocKey}
          linkText={<Translate id="FOOTER.TOS_DOC_TEXT" />}
        />
        <span className={separator} />
        <DocLinkContainer
          id={loggedInConsts.idPrivacyDocLink}
          linkKey={loggedInConsts.privacyDocKey}
          linkText={<Translate id="FOOTER.PRIVACY_DOC_TEXT" />}
        />
        <UserVoiceLinkContainer />
      </div>
      <div className={versionInfo}>
        {LANGUAGES.length > 1 && (
          <div
            className={`${languageDropdownContainer} ${footerSelectWrapper}`}
          >
            <div className={languageText}>
              <Translate id="PROFILE.LABEL_LANGUAGES" />
            </div>
            <LanguageSelect applyOnChange className={SelectDropUp} />
          </div>
        )}
        <span className={separator} />
        <div onClick={clickVersion} onTouchEnd={clickVersionTouch}>
          {getVersionInfo()}
        </div>
      </div>
    </div>
  );
};
export default LoggedInFrameFooter;
