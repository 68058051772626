// signalType
export const ALARM = 'Alarm';
export const CREDENTIALS_REVOKED = 'CredentialsRevoked';
export const USER_LOGOUT = 'UserLogout';
export const LOCATIONS = 'Locations';
export const SCHEDULES = 'Schedules';
export const USERS = 'Users';
export const PROFILE = 'Profile';
export const SWITCH_USER = 'SwitchUser';
export const DEVICES = 'Devices';
export const CLUSTERS = 'Clusters';
export const CUSTOMERS = 'Customers';
export const CONTACTS = 'Contacts';
export const CAMERAS = 'Cameras';
export const CAMERA_SNAPSHOT = 'CameraSnapshot';
export const REGISTERED_CAMERAS = 'RegisteredCameras';
export const DISCOVERED_CAMERAS_READY = 'DiscoveredCamera';
export const ANALYTIC_EVENT_SNAPSHOT = 'AnalyticEventSnapshot';
export const ANALYTIC_EVENT_HIGH_QUALITY_VIDEO =
  'AnalyticEventHighQualityVideo';
export const ANALYTIC_EVENT_LOW_QUALITY_VIDEO = 'AnalyticEventLowQualityVideo';
export const ANALYTIC_EVENT_CLIP_VIDEO = 'AnalyticEventClipVideo';
export const VIEWS = 'Views';
export const HELIOS_VERSION = 'HeliosVersion';
export const EXPORT_VIDEO = 'ExportedVideo';
export const EXPORT_LOG = 'ExportedLog';
export const FIRMWARE = 'Firmware';
export const INTEGRATION_CONFIGURATIONS = 'IntegrationConfigurations';
export const PROVIDER_INTEGRATION_CONFIGURATIONS =
  'ProviderIntegrationConfigurations';
export const SUBSCRIBER_INTEGRATION_CONFIGURATIONS =
  'SubscriberIntegrationConfigurations';
export const SUBSCRIPTION_CHANGE_REQUEST = 'SubscriptionChangeRequest';
export const REBOOT = 'Reboot';
// Update SignalType (Steve M or Howard)
export const BANNER_NOTICE = 'BannerNotice';

// Verbs & status strings
export const UPLOAD_COMPLETE = 'UploadComplete';
export const DOWNLOADING = 'Downloading';
export const DOWNLOADING_COMPLETE = 'DownloadingComplete';
export const REBOOTING_DEVICE = 'Rebooting';
