import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

// Constants
import { canvasObjectTypes } from 'constants/app';

const classNames = {
  beam: 'dragHandleBeam',
};
const ptzArrowHeadColor = '#FFFFFF';

class Line extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDragDisabled: false,
      isResizing: false,
      dragPosition: this.calculateInitialPosition(props.points),
      points: this.calculateInitialPoints(props.points),
    };
  }

  calculateInitialPosition(initialPoints) {
    // In relation to the draggable object
    let positionLeft;
    let positionTop;
    if (initialPoints && initialPoints.length === 2) {
      positionLeft = initialPoints[0].x;
      positionTop = initialPoints[0].y;
    } else {
      positionLeft = 10;
      positionTop = 10;
    }
    return {
      x: positionLeft,
      y: positionTop,
    };
  }

  calculateInitialPoints(initialPoints) {
    // In relation to the draggable object
    if (initialPoints && initialPoints.length === 2) {
      return [
        {
          x: 0,
          y: 0,
        },
        {
          x: initialPoints[1].x - initialPoints[0].x,
          y: initialPoints[1].y - initialPoints[0].y,
        },
      ];
    }
    return [
      {
        x: 0,
        y: 0,
      },
      {
        x: 20,
        y: 20,
      },
    ];
  }

  _parallelLineSegment(x1, x2, y1, y2, length, position) {
    const intersectX = x2 * position + x1 * (1 - position);
    const intersectY = y2 * position + y1 * (1 - position);
    const angle = Math.atan2(y2 - y1, x2 - x1);
    return [
      { x: intersectX, y: intersectY },
      {
        x: -Math.sin(angle) * length + intersectX,
        y: Math.cos(angle) * length + intersectY,
      },
      {
        x: Math.sin(angle) * length + intersectX,
        y: -Math.cos(angle) * length + intersectY,
      },
    ];
  }

  getDirectionArrow(points) {
    const length = 50;
    const arrowLength = 7;
    const tipPos = 6 / 8;
    const arrowPos = [1 / 4, 2 / 4, 3 / 4];
    const arrows = [];
    arrowPos.forEach((pos, i) => {
      const arrowLine = this._parallelLineSegment(
        points[0].x,
        points[1].x,
        points[0].y,
        points[1].y,
        length,
        pos,
      );
      const arrowPoint = this._parallelLineSegment(
        arrowLine[0].x,
        arrowLine[1].x,
        arrowLine[0].y,
        arrowLine[1].y,
        arrowLength,
        tipPos,
      );
      const arrowPointBack = this._parallelLineSegment(
        arrowLine[0].x,
        arrowLine[2].x,
        arrowLine[0].y,
        arrowLine[2].y,
        arrowLength,
        tipPos,
      );
      let arrowPointStr = '';
      [
        { x: arrowLine[1].x, y: arrowLine[1].y },
        { x: arrowPoint[1].x, y: arrowPoint[1].y },
        { x: arrowPoint[2].x, y: arrowPoint[2].y },
      ].forEach(pt => {
        arrowPointStr = arrowPointStr.concat(`${pt.x},${pt.y} `);
      });
      let arrowPointBackStr = '';
      [
        { x: arrowLine[2].x, y: arrowLine[2].y },
        { x: arrowPointBack[1].x, y: arrowPointBack[1].y },
        { x: arrowPointBack[2].x, y: arrowPointBack[2].y },
      ].forEach(pt => {
        arrowPointBackStr = arrowPointBackStr.concat(`${pt.x},${pt.y} `);
      });
      arrows.push(
        <line
          key={`line_${i}`}
          x1={arrowLine[1].x}
          x2={arrowLine[2].x}
          y1={arrowLine[1].y}
          y2={arrowLine[2].y}
        />,
      );
      arrows.push(
        <polygon key={`poly_${i}`} points={arrowPointStr} fill="yellowGreen" />,
      );
      if (this.props.directional === false) {
        arrows.push(
          <polygon
            key={`poly_b_${i}`}
            points={arrowPointBackStr}
            fill="yellowGreen"
          />,
        );
      }
    });
    return arrows;
  }

  getDragControls(points, type) {
    const propPoints =
      this.props.type === canvasObjectTypes.loi
        ? points
        : this.props.type === canvasObjectTypes.ptzArrow
        ? [points[0]]
        : [];

    if (this.props.type === canvasObjectTypes.loi) {
      return propPoints.map((point, ix) => {
        const size = 8;
        const _style = this.props.isSelected
          ? { zIndex: 1 }
          : { display: 'none' };
        const styleOuter = this.props.isSelected
          ? { zIndex: 2, fill: 'transparent' }
          : { display: 'none' };

        return [
          <circle
            key={`inner_${ix}`}
            cx={point.x}
            cy={point.y}
            r={size}
            style={_style}
          />,
          <circle
            key={`outer_${ix}`}
            className="controlPoint"
            cx={point.x}
            cy={point.y}
            r={size * 5}
            style={styleOuter}
            onMouseDown={this.startResize.bind(this, ix)}
          />,
        ];
      });
    }
    if (this.props.type === canvasObjectTypes.ptzArrow) {
      return propPoints.map((point, ix) => {
        const styleOuter = this.props.isSelected
          ? {
              zIndex: 1,
              fill: 'transparent',
            }
          : { display: 'none' };
        const styleNames = this.state.isResizing
          ? `${'controlPoint'} ${'ptzControlPointHide'}`
          : `${'controlPoint'} ${'ptzControlPointVisible'}`;
        return [
          <defs>
            <marker
              id="startarrow"
              key="startarrow"
              markerWidth="10"
              markerHeight="7"
              refX="5"
              refY="3.5"
              orient="auto"
            >
              {this.state.isResizing ? (
                <polygon
                  points="10 0, 10 7, 0 3.5"
                  fill={ptzArrowHeadColor}
                  fillOpacity={1}
                />
              ) : (
                ''
              )}
            </marker>
          </defs>,
          <circle
            key={`outer_${ix}`}
            className={styleNames}
            cx={point.x}
            cy={point.y}
            r={24}
            style={styleOuter}
            onMouseDown={this.startResize.bind(this, ix)}
            onMouseLeave={() => {
              if (!this.state.isResizing) {
                this.props.onMouseLeave(false);
              }
            }}
          />,
        ];
      });
    }
  }

  select = () => {
    this.props.onObjectSelected(this.props.id);
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ dragPosition: { x, y }, isResizing: true });
  };

  onControlledDragStop = (e, position) => {
    const { x, y } = position;
    let newDragPosition = { x, y };
    const { right, left, bottom, top } = this.props.canvasDocumentCoords;
    const { clientX, clientY } = e;
    const pointsAdjusted = this.export();
    if (
      pointsAdjusted.point1.x < 0 ||
      pointsAdjusted.point2.x < 0 ||
      pointsAdjusted.point1.y < 0 ||
      pointsAdjusted.point2.y < 0 ||
      pointsAdjusted.point1.x > this.props.parentWidth ||
      pointsAdjusted.point1.y > this.props.parentHeight ||
      pointsAdjusted.point2.x > this.props.parentWidth ||
      pointsAdjusted.point2.y > this.props.parentHeight ||
      clientX >= right ||
      clientX <= left ||
      clientY >= bottom ||
      clientY <= top
    ) {
      newDragPosition = this.calculateInitialPosition(this.props.points);
    }
    this.setState(
      {
        dragPosition: newDragPosition,
        isResizing: false,
      },
      () => {
        this.props.onObjectUpdated(this.export(), this.props.id);
      },
    );
  };

  startResize = ix => {
    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.stopMouseActions, false);
    this.setState(
      {
        isResizing: true,
        isDragDisabled: true,
        selectedIndex: ix,
      },
      () => this.props.onMouseDown(this.state.dragPosition),
    );
  };

  onResize = e => {
    const ix = this.state.selectedIndex;
    if (this.state.isResizing) {
      const { bottom, left, right, top } = this.props.canvasDocumentCoords;
      const { clientX, clientY } = e;
      if (
        clientX >= right ||
        clientX <= left ||
        clientY >= bottom ||
        clientY <= top
      ) {
        return;
      }
      this.setState(
        state => {
          const { points } = state;
          const { dragPosition } = state;
          const deltaX = e.offsetX - dragPosition.x;
          const deltaY = e.offsetY - dragPosition.y;
          if (ix === 0) {
            // Point remains 0,0 but drag position changes
            dragPosition.x = e.offsetX;
            dragPosition.y = e.offsetY;
            const point = points[1];
            point.x -= deltaX;
            point.y -= deltaY;
            return { dragPosition, points };
          }
          if (ix === 1) {
            const point = points[1];
            point.x = e.offsetX - dragPosition.x;
            point.y = e.offsetY - dragPosition.y;
            return { dragPosition, points };
          }
        },
        () => this.props.onMouseDrag(this.state.dragPosition),
      );
    }
  };

  stopMouseActions = e => {
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.stopMouseActions, false);
    this.setState({ isResizing: false, isDragDisabled: false }, () => {
      this.props.onMouseUp(this.state.dragPosition);
      this.props.onObjectUpdated(this.export(), this.props.id);
    });
  };

  export() {
    // account for displacement
    const pointsAdjusted = this.state.points.map(point => {
      const x = this.state.dragPosition.x + point.x;
      const y = this.state.dragPosition.y + point.y;
      const normalized = { x, y };
      return normalized;
    });

    return {
      point1: {
        x: pointsAdjusted[0].x,
        y: pointsAdjusted[0].y,
      },
      point2: {
        x: pointsAdjusted[1].x,
        y: pointsAdjusted[1].y,
      },
    };
  }

  getLine = points => {
    return (
      <line
        key={`line_with_marker_${this.props.type}`}
        id={`line_with_marker_${this.props.type}`}
        x1={points[0].x}
        x2={points[1].x}
        y1={points[0].y}
        y2={points[1].y}
        markerStart="url(#startarrow)"
      />
    );
  };

  render() {
    const _className = classNames[this.props.type];
    const _styleSelected = this.props.isSelected
      ? this.props.type === canvasObjectTypes.ptzArrow
        ? 'ptzLineSelected'
        : 'selected'
      : '';
    const { points } = this.state;
    const line = this.getLine(points);
    const dragControls = this.getDragControls(points, this.props.type);
    const directionArrow =
      this.props.type === canvasObjectTypes.loi
        ? this.getDirectionArrow(points)
        : '';
    return (
      <Draggable
        cancel=".controlPoint"
        disabled={false}
        position={{
          x: this.state.dragPosition.x,
          y: this.state.dragPosition.y,
        }}
        onMouseDown={this.select}
        oneMouseUp={this.select}
        onDrag={this.onControlledDrag}
        onStop={this.onControlledDragStop}
        key={`draggable_${this.props.type}`}
      >
        <g
          ref="controls"
          key={this.props.id}
          className={`${_className} ${_styleSelected}`}
        >
          {line}
          {directionArrow}
          {dragControls}
        </g>
      </Draggable>
    );
  }
}

Line.propTypes = {
  type: PropTypes.oneOf([canvasObjectTypes.loi, canvasObjectTypes.ptzArrow]),
  canvasDocumentCoords: PropTypes.object.isRequired,
};

Line.defaultProps = {
  id: 'lineOfInterest',
  onMouseDown: () => {},
  onMouseDrag: () => {},
  onMouseUp: () => {},
  onMouseLeave: () => {},
};

export default Line;
