import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, reset } from 'redux-form';

// Components
import Spinner from 'react-md-spinner';
import Moment from 'moment-timezone-all';
import { FieldInput, ServerImage } from 'components';
import {
  Button,
  CircularProgressBar,
  GroupLayout,
  MainContentWrapper,
} from 'lib';
import { TimeZoneSelect } from 'containers';
import { DATABASE_DATETIME_EXTRACT_FORMAT } from 'constants/app';
// Util
import {
  convertCameraTimeTo,
  convertToLongDateTimeFormat,
  getFormatForActiveLocale,
  TIME_TYPES,
} from 'util/convertTimeTo';

import { types as timeZoneSelectTypes } from 'containers/TimeZoneSelect/constants';

// Styles
import {
  tabContent,
  tabContentVerticalSeperator,
} from 'sharedStyles/global.css';

import { formField } from 'sharedStyles/modalForms.css';

import { Translate } from 'react-localize-redux';
import {
  DOWNLOADING,
  DOWNLOADING_COMPLETE,
  REBOOTING_DEVICE,
} from 'constants/SignalTypes';
import {
  buttonRow,
  generalInfoContent,
  latestFirmwareVersionLabel,
  panelWrapper,
  progressBar,
  progressMessage,
  progressText,
  serverInputRow,
  serverLabel,
  tabPanel,
  tabPanelManage,
  tabPanelUpgrade,
  tabSubheader,
} from './styles.css';

// App Constants
import * as serverGeneralConstants from './constants';

class ServerDetailGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { initialValues } = this.props;
    return {
      disableButton: true,
      name: initialValues.Name || '',
      timeZone: initialValues.TimeZone || '',
    };
  };

  // eslint-disable-next-line class-methods-use-this
  get formFieldContents() {
    return [
      {
        id: serverGeneralConstants.idIpAddress,
        name: serverGeneralConstants.fieldIpAddress,
        translateLabel: 'DEVICE_DETAILS.GENERAL_TAB.IP_ADDRESS_LABEL',
      },
      {
        id: serverGeneralConstants.idHostName,
        name: serverGeneralConstants.fieldHostName,
        translateLabel: 'DEVICE_DETAILS.GENERAL_TAB.HOST_NAME_LABEL',
      },
      {
        id: serverGeneralConstants.idMacAddress,
        name: serverGeneralConstants.fieldMacAddress,
        translateLabel: 'DEVICE_DETAILS.GENERAL_TAB.MAC_ADDRESS_LABEL',
      },
      {
        id: serverGeneralConstants.idFirmwareVersion,
        name: serverGeneralConstants.fieldFirmwareVersion,
        translateLabel: 'DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_VERSION_LABEL',
      },
      {
        id: serverGeneralConstants.idSerialNumber,
        name: serverGeneralConstants.fieldSerialNumber,
        translateLabel: 'DEVICE_DETAILS.GENERAL_TAB.SERIAL_NUMBER_LABEL',
      },
    ];
  }

  onUpgradeButtonMouseDown = () => {
    const { secondaryButtonMouseDown } = this.props;
    return secondaryButtonMouseDown('btnUpgradeFirmware');
  };

  onRestartApplianceButtonMouseDown = () => {
    const { secondaryButtonMouseDown } = this.props;
    return secondaryButtonMouseDown('btnRestartAppliance');
  };

  get getUpgradeMessage() {
    const { firmwareImage, initialValues, upgrade } = this.props;
    if (upgrade.status) {
      const upgradeProgress = () => {
        switch (upgrade.status) {
          case DOWNLOADING:
            return (
              <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_DOWNLOADING" />
            );
          case DOWNLOADING_COMPLETE:
            return (
              <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_DOWNLOADING_COMPLETE" />
            );
          case REBOOTING_DEVICE:
            return (
              <Translate id="DEVICE_DETAILS.GENERAL_TAB.REBOOTING_DEVICE" />
            );
          default:
            return (
              <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_DEFAULT" />
            );
        }
      };

      let upgradeSpeedText = null;
      if (upgrade.speed) {
        upgradeSpeedText = (
          <Translate
            data={{ upgradeSpeed: upgrade.speed }}
            id="DEVICE_DETAILS.GENERAL_TAB.UPGRADE_SPEED"
          />
        );
      }

      return (
        <div className={progressMessage}>
          <div className={progressText}>
            <span>{upgradeProgress()}</span>
            <span>{upgradeSpeedText}</span>
          </div>
          <div className={progressBar}>
            {upgrade.status === DOWNLOADING ? (
              <CircularProgressBar percentage={upgrade.progress} />
            ) : (
              <Spinner duration={2000} singleColor="#5DB6FF" size="45" />
            )}
          </div>
        </div>
      );
    }
    if (
      firmwareImage &&
      (!initialValues ||
        firmwareImage.FirmwareVersion !== initialValues.FirmwareVersion)
    ) {
      return (
        <label className={latestFirmwareVersionLabel}>
          <Translate
            data={{ newVersion: firmwareImage.FirmwareVersion }}
            id="DEVICE_DETAILS.GENERAL_TAB.NEW_DEVICE_FIRMWARE_AVAILABLE"
          />
        </label>
      );
    }
    return (
      <label className={latestFirmwareVersionLabel}>
        <Translate id="DEVICE_DETAILS.GENERAL_TAB.FIRMWARE_UPDATED" />
      </label>
    );
  }

  get firmwareUpgradeTime() {
    const { initialValues, serverLocation } = this.props;
    if (initialValues.FirmwareStatusUpdateTime) {
      const formatForLocale = getFormatForActiveLocale();
      return convertCameraTimeTo(
        Moment(
          initialValues.FirmwareStatusUpdateTime,
          DATABASE_DATETIME_EXTRACT_FORMAT,
        ),
        TIME_TYPES.LOCAL,
        initialValues,
        serverLocation,
        formatForLocale,
      );
    }
    return <Translate id="DEVICE_DETAILS.GENERAL_TAB.UNKNOWN_TIME" />;
  }

  get firmwareUpgradeStatus() {
    const { initialValues } = this.props;
    if (!initialValues.FirmwareStatus) {
      return null;
    }
    return (
      <Translate
        id={`DEVICE_DETAILS.FIRMWARE_UPGRADE_STATUS.${initialValues.FirmwareStatus &&
          initialValues.FirmwareStatus.toUpperCase()}`}
      />
    );
  }

  updateServerTimeZone = newTimeZone => {
    const { timeZone } = this.state;
    if (timeZone !== newTimeZone) {
      this.setState({
        disableButton: false,
        timeZone: newTimeZone,
      });
    }
  };

  get disableButtonsAndInputs() {
    const { disableRestartServer, initialValues, upgrade } = this.props;
    const { serverDisconnectedStatus } = serverGeneralConstants;
    return (
      initialValues.ConnectionState === serverDisconnectedStatus ||
      upgrade.status ||
      disableRestartServer
    );
  }

  disableDownloadDeviceLogsButton = () => {
    const { initialValues, logs } = this.props;
    return (
      initialValues.ConnectionState ===
        serverGeneralConstants.serverDisconnectedStatus || logs.isFetching
    );
  };

  submitChanges = () => {
    const { handleSubmit } = this.props;
    handleSubmit();
  };

  updateServerName = event => {
    const targetValue = event.target.value;
    const { name } = this.state;
    if (name !== targetValue) {
      this.setState({
        disableButton: false,
        name: targetValue,
      });
    }
  };

  undoChanges = () => {
    const { dispatch } = this.props;
    dispatch(reset(serverGeneralConstants.formName));
    this.setState(this.getInitialState());
  };

  render() {
    const {
      canGetLogs,
      handleSubmit,
      initialValues,
      logs,
      onRequestDeviceLogs,
      onRestartApplianceClick,
      onUpgradeFirmwareClick,
      preferredLongDateFormat,
      preferredTimeFormat,
    } = this.props;

    const { disableButton } = this.state;

    return (
      <form onSubmit={handleSubmit(this.submitChanges)}>
        <MainContentWrapper>
          <div className={generalInfoContent}>
            <div className={panelWrapper}>
              <div className={tabPanel}>
                <div className={tabSubheader}>
                  <Translate id="DEVICE_DETAILS.GENERAL_TAB.INFORMATION_HEADER" />
                </div>
                <div className={serverInputRow}>
                  <label className={serverLabel}>
                    <Translate id="DEVICE_DETAILS.GENERAL_TAB.SERVER_NAME_LABEL" />
                  </label>
                  <Field
                    className={formField}
                    component={FieldInput}
                    disabled={this.disableButtonsAndInputs}
                    id={serverGeneralConstants.idServerName}
                    name={serverGeneralConstants.fieldServerName}
                    onChange={this.updateServerName}
                  />
                </div>
                <div className={serverInputRow}>
                  <label className={serverLabel}>
                    <Translate id="DEVICE_DETAILS.GENERAL_TAB.DEVICE_TIMEZONE_LABEL" />
                  </label>
                  <Field
                    changeCallback={this.updateServerTimeZone}
                    className={formField}
                    component={TimeZoneSelect}
                    disabled={this.disableButtonsAndInputs}
                    id={serverGeneralConstants.idServerTimezone}
                    name={serverGeneralConstants.fieldTimeZone}
                    translate
                    type={timeZoneSelectTypes.SERVER}
                  />
                </div>
                {this.formFieldContents.map(item => (
                  <div key={item.name} className={serverInputRow}>
                    <label className={serverLabel}>
                      <Translate id={item.translateLabel} />
                    </label>
                    <Field
                      className={formField}
                      component={FieldInput}
                      disabled
                      id={item.id}
                      name={item.name}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={tabContentVerticalSeperator}>
              <ServerImage serverModel={initialValues.Model} useLargeImages />
            </div>
            <div className={tabContent}>
              <div className={tabPanelUpgrade}>
                <div className={tabSubheader}>
                  <Translate id="DEVICE_DETAILS.GENERAL_TAB.UPGRADE_HEADER" />
                </div>
                {this.firmwareUpgradeStatus === null ? null : (
                  <div className={serverInputRow}>
                    <Translate
                      data={{
                        lastStatus: this.firmwareUpgradeStatus,
                        lastTime: convertToLongDateTimeFormat(
                          this.firmwareUpgradeTime,
                          preferredLongDateFormat,
                          preferredTimeFormat,
                        ),
                      }}
                      id="DEVICE_DETAILS.GENERAL_TAB.LAST_FIRMWARE_UPGRADE_TEXT"
                    />
                  </div>
                )}
                <div className={serverInputRow}>
                  <Button
                    buttonType="secondary"
                    disabled={this.disableButtonsAndInputs}
                    id={serverGeneralConstants.idUpgrade}
                    inputType="button"
                    onClick={onUpgradeFirmwareClick}
                    onMouseDown={this.onUpgradeButtonMouseDown}
                  >
                    <Translate id="BUTTONS.UPGRADE" />
                  </Button>
                  {this.getUpgradeMessage}
                </div>
              </div>
              <div className={tabPanelManage}>
                <div className={tabSubheader}>
                  <Translate id="DEVICE_DETAILS.GENERAL_TAB.MANAGE_HEADER" />
                </div>
                <div className={`${serverInputRow} ${buttonRow}`}>
                  {canGetLogs && (
                    <Button
                      buttonType="secondary"
                      disabled={this.disableDownloadDeviceLogsButton()}
                      id={serverGeneralConstants.idLogDownload}
                      inputType="button"
                      onClick={onRequestDeviceLogs}
                      text={
                        logs.isFetching ? (
                          <Translate id="DEVICE_DETAILS.GENERAL_TAB.GETTING_LOGS" />
                        ) : (
                          <Translate id="DEVICE_DETAILS.GENERAL_TAB.GET_LOGS" />
                        )
                      }
                    />
                  )}
                  <Button
                    buttonType="secondary"
                    disabled={this.disableButtonsAndInputs}
                    id={serverGeneralConstants.idBtnRestart}
                    inputType="button"
                    onClick={onRestartApplianceClick}
                    onMouseDown={this.onRestartApplianceButtonMouseDown}
                    text={
                      <Translate id="DEVICE_DETAILS.GENERAL_TAB.RESTART_APPLIANCE_TEXT" />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <GroupLayout horizontalPositioning="center">
            <Button
              key="serverDetailGeneralCancelButton"
              buttonType="primary"
              disabled={disableButton}
              id="serverDetailGeneralCancelButton"
              inputType="button"
              onClick={this.undoChanges}
              text={<Translate id="BUTTONS.CANCEL" />}
            />
            <Button
              key="serverDetailGeneralSaveButton"
              buttonType="primary"
              disabled={disableButton}
              id="serverDetailGeneralSaveButton"
              inputType="submit"
              text={<Translate id="BUTTONS.SAVE" />}
            />
          </GroupLayout>
        </MainContentWrapper>
      </form>
    );
  }
}

ServerDetailGeneral.propTypes = {
  canGetLogs: PropTypes.bool,
  disableRestartServer: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  firmwareImage: PropTypes.objectOf(PropTypes.any),
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  logs: PropTypes.objectOf(PropTypes.any),
  onRequestDeviceLogs: PropTypes.func.isRequired,
  onRestartApplianceClick: PropTypes.func.isRequired,
  onUpgradeFirmwareClick: PropTypes.func.isRequired,
  preferredLongDateFormat: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
  secondaryButtonMouseDown: PropTypes.func.isRequired,
  serverLocation: PropTypes.objectOf(PropTypes.any),
  upgrade: PropTypes.objectOf(PropTypes.any),
};

ServerDetailGeneral.defaultProps = {
  canGetLogs: false,
  firmwareImage: {},
  initialValues: {},
  logs: {},
  preferredLongDateFormat: '',
  preferredTimeFormat: '',
  serverLocation: {},
  upgrade: {},
};

export default reduxForm({
  enableReinitialize: true,
  form: serverGeneralConstants.formName,
})(ServerDetailGeneral);
