// Libs
import React from 'react';
import { PropTypes } from 'prop-types';

// Components
import { ProgressBar } from 'lib';

// Utils
import { Translate } from 'react-localize-redux';

// Constant
import * as poeBarConstants from './constants';

// Style
import * as barStyles from './styles.css';

const ServerDetailPoEBar = props => {
  // get all totals in single iteration
  const totalValue = () => {
    const { poe } = props;
    const total = {
      budget: 0,
      budgetMax: 0,
      consumption: 0,
    };
    if (poe && poe.ports) {
      total.budgetMax = poe.budgetmax;
      poe.ports.forEach(poeElement => {
        if (
          poeElement &&
          poeElement.status !== poeBarConstants.cameraPoEStatus.DISCONNECTED &&
          poeElement.status !== poeBarConstants.cameraPoEStatus.UNPOWERED
        ) {
          total.consumption += poeElement[poeBarConstants.fieldUsage]
            ? poeElement[poeBarConstants.fieldUsage]
            : 0;

          if (
            poeElement[poeBarConstants.fieldBudget] &&
            poeElement[poeBarConstants.fieldBudget] !== 0
          ) {
            total.budget += poeElement[poeBarConstants.fieldBudget];
          } else if (poeElement[poeBarConstants.fieldClassBudget]) {
            total.budget += poeElement[poeBarConstants.fieldClassBudget];
          }
        }
      });
    }
    return total;
  };

  const totalObject = totalValue();

  return (
    <div className={`${barStyles.poeBarColumn}`}>
      <div className={barStyles.tabSubheader}>
        <Translate id="DEVICE_DETAILS.POE_MANAGEMENT_TAB.POE_MANAGEMENT_HEADER" />
      </div>
      <ProgressBar
        label={
          <Translate
            data={{ budget: totalObject.budgetMax, usage: totalObject.budget }}
            id="SERVER.POE.DATA.CONSUMPTION_MESSAGE"
          />
        }
        leftValue={totalObject.budget}
        name={poeBarConstants.budget}
        totalValue={totalObject.budgetMax}
      />
      <ProgressBar
        label={
          <Translate
            data={{
              budget: totalObject.budgetMax,
              usage: totalObject.consumption,
            }}
            id="SERVER.POE.DATA.CONSUMPTION_MESSAGE"
          />
        }
        leftValue={totalObject.consumption}
        name={poeBarConstants.consumption}
        totalValue={totalObject.budgetMax}
      />
    </div>
  );
};

ServerDetailPoEBar.propTypes = {
  poe: PropTypes.shape({}).isRequired,
};

export default ServerDetailPoEBar;
