import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconArrowBent = props => {
  // Used to indicate 'copy text to clipboard'
  return (
    <svg
      width={props.width}
      height={props.height}
      className={props.className}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect
            stroke={props.bgStroke}
            strokeWidth="1"
            fill={props.bgFill}
            fillRule="evenodd"
            x="0.5"
            y="0.5"
            width="29"
            height="29"
            rx="4"
          />
          <path
            d="M19.2607346,10 C19.2607346,10 4.78380057,10 5.12033903,24 C5.12033903,24 4.45304227,15.1826923 19.2607346,15.1826923 L19.2607346,10 Z"
            fill={props.arrowStroke}
            fillRule="nonzero"
          />
          <polygon
            fill={props.arrowStroke}
            fillRule="evenodd"
            transform="translate(21.500000, 12.500000) rotate(90.000000) translate(-21.500000, -12.500000) "
            points="21.5 9 27 16 16 16"
          />
        </g>
      </g>
    </svg>
  );
};

IconArrowBent.defaultProps = {
  className: null,
  width: '30px',
  height: '30px',
  bgFill: '#EFF8FF',
  bgStroke: '#5DB6FF',
  arrowStroke: '#006e99',
};

export default IconArrowBent;
