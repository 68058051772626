/* global avoLogError */
import * as types from 'constants/ActionTypes';
import urlBuilder from 'queryBuilder/url';
import * as messageTypes from 'constants/MessageTypes';
import {
  runGetActionReturningJSON,
  sendDeleteRequestReturningJSON,
  sendPostRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { isFetching } from './common';
import { showMessage } from './pageMessage';
import { revertToggle } from './toggle';

// Redux-only action creators

function receiveViews(views) {
  return {
    type: types.RECEIVE_VIEWS,
    views,
  };
}

export function setCurrentPanelId(id) {
  return {
    id,
    type: types.SET_CURRENT_PANEL_ID,
  };
}

export function setCurrentView(view) {
  return {
    type: types.RECEIVE_VIEW,
    view,
  };
}

export function setCurrentViewCamera(params) {
  return {
    params,
    type: types.SET_CURRENT_VIEW_CAMERA,
  };
}

export function editCurrentView(view) {
  return {
    type: types.EDIT_CURRENT_VIEW,
    view,
  };
}

export function setElementTurnStatus(position, status) {
  return {
    position,
    type: types.SET_ELEMENT_TURN_STATUS,
    status,
  };
}

export function setResumeStreamStartTime(position, startTime) {
  return {
    position,
    type: types.SET_RESUME_STREAM_START_TIME,
    startTime,
  };
}

export function setRecordedVideoStartDate(date) {
  return {
    date,
    type: types.RECORDED_VIDEO_DATE,
  };
}

export function toggleSyncVideo() {
  return {
    type: types.TOGGLE_SYNC_VIDEO,
  };
}

export function setSyncedStartTime(startTime) {
  return {
    startTime,
    type: types.SET_SYNCED_START_TIME,
  };
}

export function clearSyncedStartTime() {
  return {
    type: types.CLEAR_SYNCED_START_TIME,
  };
}

export function setRecordedVideoPosition(position) {
  return {
    position,
    type: types.RECORDED_VIDEO_REQUESTED,
  };
}

function deleteCurrentView() {
  return {
    type: types.DELETE_VIEW,
  };
}

export function maximizeCurrentView(isMaximized) {
  return {
    isMaximized,
    type: types.MAXIMIZE_VIEW,
  };
}

// Action creators calling FETCH

export function getViews() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_VIEWS));
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_VIEWS,
      url: urlBuilder(types.GET_VIEWS),
      onError: err => {
        avoLogError('Error fetching views:', err);
      },
      onSuccess: json => {
        dispatch(receiveViews(json));
      },
    });
  };
}

export function getViewDetails(viewId) {
  return dispatch => {
    const url = urlBuilder(types.GET_VIEW, viewId);
    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_VIEW,
      url,
      onError: err => {
        avoLogError(`Error fetching view details for view ${viewId}:`, err);
      },
      onSuccess: json => {
        dispatch(setCurrentPanelId(null));
        dispatch(setCurrentView(json));
      },
    });
  };
}

export function addView(data) {
  // extract placeholder Id to remove it from post
  const { Id, ...formData } = data;
  return dispatch => {
    dispatch(isFetching(true));
    const url = urlBuilder(types.CREATE_VIEW);
    return sendPostRequestReturningJSON(url, formData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.VIEW_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'VIEWS.SAVE_VIEW_SUCCESS',
            translateBodyData: {
              viewName: data.Name,
            },
          }),
        );
        dispatch(getViews());
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.VIEW_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERAL_MESSAGES.EDIT_ERROR',
          }),
        );
        avoLogError('Error adding a view', ex);
      })
      .then(() => dispatch(isFetching(false)));
  };
}

export function editView(formData, rollbackAction) {
  return dispatch => {
    const viewId = formData.Id;
    const url = urlBuilder(types.EDIT_VIEW, formData.Id);
    return sendPutRequestReturningJSON(url, formData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.VIEW_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'VIEWS.EDIT_VIEW_SUCCESS',
            translateBodyData: {
              viewName: formData.Name,
            },
          }),
        );
        dispatch(getViewDetails(viewId));
        dispatch(getViews());
      })
      .catch(ex => {
        if (rollbackAction) {
          dispatch(revertToggle(rollbackAction));
        }
        dispatch(
          showMessage(messageTypes.VIEW_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERAL_MESSAGES.EDIT_ERROR',
          }),
        );
        avoLogError('Error editing view', ex);
      });
  };
}

export function deleteView(viewId, viewName) {
  return dispatch => {
    dispatch(isFetching(true));
    const url = urlBuilder(types.DELETE_VIEW, viewId);
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.VIEW_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'VIEWS.DELETE_VIEW_SUCCESS',
            translateBodyData: {
              viewName,
            },
          }),
        );
        dispatch(deleteCurrentView());
        dispatch(getViews());
      })
      .catch(ex => {
        avoLogError('Error deleting a view', ex);
        dispatch(
          showMessage(messageTypes.VIEW_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'VIEWS.DELETE_VIEW_UNSUCCESS',
            translateBodyData: {
              viewName,
            },
          }),
        );
      })
      .then(() => dispatch(isFetching(false)));
  };
}

export function setTurnStreamsPaused(turnStreamsPaused) {
  return {
    turnStreamsPaused,
    type: types.SET_TURN_STREAMS_PAUSED,
  };
}
