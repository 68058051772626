import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSubscriptionState } from 'util/validation';

// Actions
import * as LocationActions from 'actions/locations';
import * as DeviceActions from 'actions/devices';
import { hideModal } from 'actions/modal';

// Components
import { BusyIndicator, InternalClaimDeviceForm } from 'components';

// Styles
import { internalClaimContainer } from './styles.css';

class InternalClaimingContainer extends Component {
  componentDidMount() {
    const { actions, locations } = this.props;
    if (locations.length === 0) {
      actions.getLocations();
    }
  }

  getActivationCode = (locationId, deviceFamily) => {
    const { actions } = this.props;
    actions.getActivationCode(locationId, deviceFamily);
  };

  onFormSubmit = formData => {
    const { actions, handleCancel, orgId } = this.props;
    if (formData) {
      const data = {
        ClaimingKey: formData.claimingKey,
        OrganizationId: orgId,
        ParentId: formData.location,
      };
      actions.claimDevice(data);
      // hide modal
      actions.hideModal();
      if (handleCancel) handleCancel();
    }
  };

  getValidLocations = locations => {
    return locations.filter(
      loc => (getSubscriptionState(loc.PackageSubscription).isValid && loc.UserHasAccess),
    );
  };

  render() {
    const { actions, activationCode, isFetching, locations } = this.props;
    return (
      <div className={internalClaimContainer}>
        {isFetching ? (
          <BusyIndicator />
        ) : (
          <div>
            <InternalClaimDeviceForm
              activationCode={activationCode}
              getActivationCode={this.getActivationCode}
              locations={this.getValidLocations(locations)}
              onClose={actions.hideModal}
              onSubmit={this.onFormSubmit}
            />
          </div>
        )}
      </div>
    );
  }
}

InternalClaimingContainer.propTypes = {
  actions: PropTypes.shape({
    claimDevice: PropTypes.func.isRequired,
    getActivationCode: PropTypes.func.isRequired,
    getLocations: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
  }).isRequired,
  activationCode: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object),
  orgId: PropTypes.string,
};

InternalClaimingContainer.defaultProps = {
  activationCode: undefined,
  isFetching: null,
  locations: [],
  orgId: undefined,
};

function mapStateToProps(state) {
  let { locations } = state.locations;
  const { profile, users } = state.user;
  let policies = profile.Policies;
  const validLocationPolicies = [];

  const userObject = users.find(user => {
    return user.TenantProxyId
      ? user.TenantProxyId === profile.TenantId
      : user.Id === profile.Id;
  });
  if (userObject) {
    policies = userObject.Policies;
  }

  policies.forEach(policy => {
    const validLocation = locations.find(
      location => location.Id === policy.GroupId,
    );
    if (validLocation) validLocationPolicies.push(validLocation);
  });

  locations = validLocationPolicies;

  return {
    activationCode: state.devices.deviceActivationCode,
    locations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...DeviceActions, ...LocationActions, hideModal },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InternalClaimingContainer);
