import urlBuilder from 'queryBuilder/url';
import {
  sendGetRequestReturningJSON,
  sendPostRequestReturningJSON,
} from 'util/fetchHelpers';
import {
  ERROR_GETTING_RECORDING_SETTINGS_MESSAGE,
  VIDEO_RETENTION_MESSAGE,
} from 'constants/MessageTypes';

// Constants
import * as types from 'constants/ActionTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { showMessage, showPersistentTranslatedError } from '../pageMessage';
import { isFetchingData } from '../common';
import { getCompressionSettings } from './cameraSettings';

export function getAllCamerasCompressionSettings(cameras, serverId) {
  return dispatch => {
    const fetchOptions = { fetchScope: serverId };
    dispatch(
      isFetchingData(
        types.GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS,
        true,
        fetchOptions,
      ),
    );
    return Promise.all(
      cameras.map(camera =>
        dispatch(
          getCompressionSettings(camera.DeviceId, camera.RemoteId, camera.Id),
        ),
      ),
    ).then(() =>
      dispatch(
        isFetchingData(
          types.GET_CONNECTED_CAMERAS_COMPRESSION_SETTINGS,
          false,
          fetchOptions,
        ),
      ),
    );
  };
}

export function getRecordingSettings(serverId) {
  return (dispatch, getState) => {
    dispatch(
      isFetchingData(types.GET_RECORDING_SETTINGS, true, {
        fetchScope: serverId,
      }),
    );
    const url = urlBuilder(types.GET_RECORDING_SETTINGS, serverId);
    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch({
          type: types.RECEIVE_RECORDING_SETTINGS,
          blueCameras: getState().devices.cameras,
          recordingSettings: response.retentionSettings,
          usage: response.usage,
          volumeInfo: response.volumeInfo,
          serverId,
        });
      })
      .catch(error => {
        avoLogError('Error getting recording settings', error);
        dispatch(
          showPersistentTranslatedError(
            ERROR_GETTING_RECORDING_SETTINGS_MESSAGE,
            'DEVICE_DETAILS.VIDEO_RETENTION_TAB.ERROR_GETTING_RECORDING_SETTINGS_MESSAGE',
          ),
        );
      })
      .finally(() => {
        dispatch(
          isFetchingData(types.GET_RECORDING_SETTINGS, false, {
            fetchScope: serverId,
          }),
        );
      });
  };
}

export function saveRecordingSettings(
  serverId,
  connectedCameras,
  recordingSettings,
) {
  return dispatch => {
    const retentionValuesMap = {};
    const linkedValuesMap = {};
    connectedCameras.forEach(camera => {
      retentionValuesMap[camera.Id] = camera.retention;
      linkedValuesMap[camera.Id] = camera.linked;
    });

    // combine linked cameras
    const linkedCameras = {
      cameraIds: [],
      storage: {
        maxRetention: {
          val: undefined,
        },
        streamRetentionAmount: {
          val: undefined,
        },
        camerasLinked: {
          val: true,
        },
      },
    };
    const unlinkedCameras = [];

    recordingSettings.forEach(setting => {
      if (linkedValuesMap[setting.blueId]) {
        linkedCameras.cameraIds.push(setting.accId);
        if (linkedCameras.storage.maxRetention.val === undefined) {
          linkedCameras.storage.maxRetention.val = parseInt(
            retentionValuesMap[setting.blueId],
            10,
          );
          linkedCameras.storage.streamRetentionAmount.val =
            setting.streamRetentionAmount.val;
        }
      } else {
        const retentionValue =
          retentionValuesMap[setting.blueId] ||
          (setting.maxRetention && setting.maxRetention.val);
        if (retentionValue === null) {
          return;
        }
        unlinkedCameras.push({
          retentionSettings: {
            cameraIds: [setting.accId],
            storage: {
              maxRetention: {
                val: parseInt(retentionValue, 10),
              },
              streamRetentionAmount: {
                val: setting.streamRetentionAmount.val,
              },
              camerasLinked: {
                val: false,
              },
            },
          },
        });
      }
    });

    dispatch(isFetchingData(types.SAVE_RECORDING_SETTINGS));
    const url = urlBuilder(types.SAVE_RECORDING_SETTINGS, serverId);
    const linkedList = [];
    if (linkedCameras.storage.maxRetention.val !== undefined) {
      linkedList.push({ retentionSettings: linkedCameras });
    }
    const body = JSON.stringify([...linkedList, ...unlinkedCameras]);
    return sendPostRequestReturningJSON(url, body, null, null, true)
      .then(() => {
        dispatch(
          showMessage(VIDEO_RETENTION_MESSAGE, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'DEVICE_DETAILS.UPDATE_RETENTION_SETTINGS_SUCCESS',
          }),
        );
        dispatch(getRecordingSettings(serverId));
      })
      .catch(error => {
        avoLogError('Error saving recording settings', error);
        dispatch(
          showMessage(VIDEO_RETENTION_MESSAGE, error, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'DEVICE_DETAILS.UPDATE_RETENTION_SETTINGS_ERROR',
          }),
        );
        return error;
      })
      .finally(() => {
        dispatch(isFetchingData(types.SAVE_RECORDING_SETTINGS, false));
      });
  };
}
