exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".modalMessage___1J4gB {\r\n    color: #575757;\r\n    margin: 5px 0px 10px 0px;\r\n  }", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/Bookmarks/DeleteBookmarks/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;GAC1B","file":"styles.css","sourcesContent":[".modalMessage {\r\n    color: #575757;\r\n    margin: 5px 0px 10px 0px;\r\n  }"],"sourceRoot":""}]);

// exports
exports.locals = {
	"modalMessage": "modalMessage___1J4gB"
};