import * as validation from 'util/validation';

export const idLocationDropdown = 'locationDropdown';
export const idSecurityScheduleDropdown = 'securityScheduleDropdown';
export const idHealthScheduleDropdown = 'healthScheduleDropdown';
export const idLocScheFormBox = 'locScheFormBox';
export const idFirstName = 'FirstName';
export const idLastName = 'LastName';
export const idEmail = 'Email';
export const idPhone = 'Phone';
export const idRoleType = 'RoleType';
export const idStatus = 'UserStatus';
export const idAddSite = 'addSite';
export const idRemoveSite = 'removeSite';
export const scheduleAlways = 'Always';
export const scheduleNever = 'Never';
export const idRadioAutoAccessToFutureSites = 'radioAutoAccessToFutureSites';
export const idEmailNotification = 'emailNotification';

const fieldName = 'FirstName';
const fieldLastName = 'LastName';
const fieldEmailAddress = 'EmailAddress';
const fieldPhoneNumber = 'PhoneNumber';

export const fieldSummary = 'summary';
export const fieldStatus = 'Status';
export const fieldStatusType = 'StatusType';
export const fieldTimezone = 'TimeZone';
export const fieldRole = 'Role';
export const fieldPolicies = 'Policies';
export const fieldEmailNotify = 'EmailNotification';
export const fieldNewSiteAccess = 'NewSiteAccess';

export const statusInvited = 'Invited';
export const statusEnabled = 'Enabled'; // Corresponds to database value
export const proxy = 'Proxy';

export const userFormInputs = [
  {
    id: idFirstName,
    label: 'USERS.USER_FORM_FIELDS.LABELS.FIRST_NAME',
    name: fieldName,
    validate: validation.required,
  },
  {
    id: idLastName,
    label: 'USERS.USER_FORM_FIELDS.LABELS.LAST_NAME',
    name: fieldLastName,
    validate: validation.required,
  },
  {
    calcDisabled: ({ isEditing }) => isEditing,
    id: idEmail,
    label: 'COMMON.EMAIL',
    name: fieldEmailAddress,
    normalize: validation.trim,
    validate: [validation.required, validation.email],
  },
  {
    id: idPhone,
    label: 'USERS.USER_FORM_FIELDS.LABELS.PHONE',
    name: fieldPhoneNumber,
  },
];

export const proxyFormInputs = [
  {
    id: idFirstName,
    label: 'COMMON.NAME',
    name: fieldName,
    validate: validation.required,
  },
];

export const ACCESS_TABLE = {
  fieldOrder: [
    'SiteAccess',
    'SecurityMonitoringSchedule',
    'HealthMonitoringSchedule',
    'Actions',
  ],
  headerTranslationIds: {
    Actions: 'USERS.ADD_USER_MODAL.LABELS.ACTIONS',
    HealthNotification:
      'USERS.ADD_USER_MODAL.LABELS.HEALTH_MONITORING_SCHEDULE',
    Name: 'USERS.ADD_USER_MODAL.LABELS.SITE_ACCESS',
    SecurityNotification:
      'USERS.ADD_USER_MODAL.LABELS.SECURITY_MONITORING_SCHEDULE',
  },
};

export const ACCESS_TABLE_TITLE_HEIGHT = 24;
export const ACCESS_TABLE_MIN_HEIGHT = 48;

export const CELL_WIDTHS = {
  Actions: 20,
  HealthMonitoringSchedule: 40,
  LocationSelector: 10,
  SecurityMonitoringSchedule: 50,
  SiteAccess: 50,
};
export const CELL_ALIGNMENTS = {
  Actions: 'center',
};

export const SCHEDULES = {
  ALWAYS: 'Always',
  EXCLUDED_DATES: 'Excluded Dates',
  MY_SCHEDULE: 'My Schedule',
  NEVER: 'Never',
  NON_BUSINESS_HOURS: 'Non-Business Hours',
};

export const USER_EMAIL_NOTIFICATION = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
];

export const USER_ACCESS_TO_FUTURE_SITES = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
];
