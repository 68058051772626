import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import * as d3TimeFormat from 'd3-time-format';
import Moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import get from 'lodash.get';

// Constants
import * as localizedStrings from 'localization/languageFiles';
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE,
} from 'constants/app';
import { PREFERRED_START_OF_WEEK } from 'constants/dateTimeNumberFormats';
import getUserLocale from './getUserLocale';

// For Prop helpers
export const MISSING_TRANSLATE_FUNCTION = 'MISSING_TRANSLATE_FUNCTION';

// options
export const defaultToInput = ({ translationId }) => translationId;

let setActiveLanguage = () => null;
const getBrowserLanugage = () => {
  const language = getUserLocale();
  if (language) {
    const browserLanguage = LANGUAGES.find(
      supportedLang => supportedLang.code === language.substring(0, 2),
    );
    return get(browserLanguage, 'fullCode') || DEFAULT_LANGUAGE;
  }
  return DEFAULT_LANGUAGE;
};
let activeLanguage =
  localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || getBrowserLanugage();

export const getActiveLanguage = () => activeLanguage;
export const getActiveLanguageCode = () => {
  const actualLanguage =
    LANGUAGES.find(
      language =>
        language.code === activeLanguage ||
        language.fullCode === activeLanguage,
    ) || LANGUAGES.find(language => language.fullCode === DEFAULT_LANGUAGE);
  return actualLanguage.code;
};

export const getActiveLanguageFullCode = () => {
  const actualLanguage =
    LANGUAGES.find(
      language =>
        language.code === activeLanguage ||
        language.fullCode === activeLanguage,
    ) || LANGUAGES.find(language => language.fullCode === DEFAULT_LANGUAGE);
  return actualLanguage.fullCode;
};

export const changeAppLanguage = async newActiveLanguage => {
  // We check if the newActiveLanguage is supported by Blue, in another words, if it's inside the LANGUAGES array
  const actualLanguage =
    LANGUAGES.find(
      language =>
        language.code === newActiveLanguage ||
        language.fullCode === newActiveLanguage,
    ) || LANGUAGES.find(language => language.fullCode === DEFAULT_LANGUAGE);
  localStorage.setItem(LOCAL_STORAGE_LANGUAGE, actualLanguage.fullCode);
  activeLanguage = actualLanguage.fullCode;
  setActiveLanguage(actualLanguage.code);
  Moment.locale(actualLanguage.code);
  const fullCode = get(actualLanguage, 'fullCode');
  if (fullCode) {
    try {
      // eslint-disable-next-line import/no-dynamic-require
      const timeFormatLocale = await require(`d3-time-format/locale/${fullCode}.json`);
      d3TimeFormat.timeFormatDefaultLocale(timeFormatLocale);
    } catch (e) {
      const timeFormatLocale = await require('d3-time-format/locale/en-US.json');
      d3TimeFormat.timeFormatDefaultLocale(timeFormatLocale);
    }
  }
};

const addDefaultTranslations = (defaultLocalizations, localizations) => {
  if (!localizations) return defaultLocalizations;
  if (typeof localizations === 'string')
    return typeof defaultLocalizations === 'string'
      ? localizations
      : defaultLocalizations;
  const result = {};
  Object.keys(defaultLocalizations).forEach(key => {
    result[key] = addDefaultTranslations(
      defaultLocalizations[key],
      localizations[key],
    );
  });
  return result;
};

const initializeLocalizedStrings = () => {
  const defaultLanguage = LANGUAGES.find(
    supportedLang => supportedLang.fullCode === DEFAULT_LANGUAGE,
  );
  const processedLocalizedStrings = {
    [defaultLanguage.code]: localizedStrings[defaultLanguage.code],
  };
  LANGUAGES.forEach(language => {
    if (language.code !== defaultLanguage.code) {
      processedLocalizedStrings[language.code] = addDefaultTranslations(
        localizedStrings[defaultLanguage.code],
        localizedStrings[language.code],
      );
    }
  });
  return processedLocalizedStrings;
};

export const initializeLanguages = async (
  initializeFunc = () => null,
  setActiveLanguageFunc = () => null,
  addTranslationForLanguageFunc = () => null,
  userLanguage,
) => {
  const existingLanguage = LANGUAGES.find(
    supportedLang =>
      supportedLang.fullCode === userLanguage ||
      supportedLang.code === userLanguage,
  );
  const selectedLanguage = get(existingLanguage, 'fullCode') || activeLanguage;
  initializeFunc({
    languages: LANGUAGES,
    options: { renderToStaticMarkup },
  });
  const processedLocalizedStrings = initializeLocalizedStrings();
  LANGUAGES.forEach(language => {
    addTranslationForLanguageFunc(
      processedLocalizedStrings[language.code],
      language.code,
    );
  });
  setActiveLanguage = setActiveLanguageFunc;
  await changeAppLanguage(selectedLanguage);
};

export const getStartOfWeek = localizationPreference => {
  const weekStart =
    localizationPreference && localizationPreference.PreferredWeekStartDay
      ? PREFERRED_START_OF_WEEK[localizationPreference.PreferredWeekStartDay]
      : null;
  const localeStartOfWeek = Moment.localeData(getUserLocale()).firstDayOfWeek();

  return weekStart || weekStart === 0 ? weekStart : localeStartOfWeek;
};

export const initializeMomentSettings = localizationPreference => {
  Moment.updateLocale(getActiveLanguageCode(), {
    week: {
      dow: getStartOfWeek(localizationPreference),
    },
  });
};

initializeLanguages.propTypes = {
  addTranslationForLanguage: PropTypes.func,
  initialize: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any),
  setActiveLanguage: PropTypes.func,
};

export default initializeLanguages;
