import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { contentMain, contentMainBody } from './ContentWrapper.css';

const MainContentWrapper = ({ children, contentClass }) => {
  return (
    <div className={`${contentClass || contentMainBody} ${contentMain}`}>
      {children}
    </div>
  );
};

MainContentWrapper.defaultProps = {
  children: null,
  contentClass: '',
};

MainContentWrapper.propTypes = {
  children: PropTypes.node,
  contentClass: PropTypes.string,
};

export default MainContentWrapper;
