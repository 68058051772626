import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

// Components
import {
  PageMessage,
  SelectCustomerContainer,
  SelectLocationContainer,
} from 'containers';

import { BusyIndicator } from 'components';

import { Button } from 'lib';

// Styles
import {
  wizardButtons,
  wizardFormBody,
  wizardHeader,
  wizardHeaderLeft,
  wizardHeaderRight,
} from 'sharedStyles/styles.css';

import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as messageTypes from 'constants/MessageTypes';
import { container, inputContainer, label } from './styles.css';

// Constants

export class ClaimDeviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // value of CustomerId is set and retrieved from redux store, both in parent and in SelectLocation
      ParentId: null,
    };
  }

  get canSubmit() {
    const { ParentId } = this.state;
    return ParentId !== null;
  }

  selectedLocationChanged = id => {
    this.setState({ ParentId: id });
  };

  onSubmit = event => {
    const { handleSubmit } = this.props;
    const { ParentId } = this.state;
    handleSubmit({
      ParentId,
    });
  };

  render() {
    const { isBusy } = this.props;
    const busyIndicator = isBusy ? <BusyIndicator /> : undefined;
    return (
      <div className={container}>
        <div className={wizardHeader}>
          <div className={wizardHeaderLeft}>
            <Translate id="DEVICES.DEVICE_CLAIM.HEADER_TEXT" />
          </div>
          <div className={wizardHeaderRight}>
            <Translate id="DEVICES.DEVICE_CLAIM.STEP_INDICATOR" />
          </div>
        </div>
        <div className={wizardFormBody}>
          <PageMessage
            messageStyle={messageStyleStrings.error}
            messageType={messageTypes.CLAIM_ERROR}
          />
          <div className={inputContainer}>
            <div className={label}>
              <Translate id="DEVICES.DEVICE_CLAIM.SUBSCRIBER_LABEL" />
            </div>
            <SelectCustomerContainer
              isAuthenticated
              selectedItem={this.selectedOrganizationChanged}
            />
          </div>
          <div className={inputContainer}>
            <div className={label}>
              <Translate id="COMMON.SITE" />
            </div>
            <SelectLocationContainer
              selectedLocation={this.selectedLocationChanged}
            />
          </div>
          <div className={wizardButtons}>
            <Button
              disabled={!this.canSubmit}
              inputType="button"
              onClick={this.onSubmit}
              text={
                <Translate id="DEVICES.DEVICE_CLAIM.ACTIVATE_BUTTON_LABEL" />
              }
            />
          </div>
        </div>
        {busyIndicator}
      </div>
    );
  }
}

ClaimDeviceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
};

export default ClaimDeviceForm;
