import * as validation from 'util/validation';

export const idName = 'Name';
export const idSAP = 'sapId';
export const idPhone = 'Phone';
export const idCustomerType = 'CustomerType';
export const idPartner = 'Dealer';
export const idProvider = 'Provider';
export const idTimezone = 'Timezone';
export const idFirstName = 'adminFirstName';
export const idLastName = 'adminLastName';
export const idPrimaryAdminId = 'adminId';
export const idEmail = 'Email';
export const idStatus = 'tenantStatus';
export const idEntitlements = 'Entitlements';
export const idContactEmail = 'ContactEmail';
export const idContactPhone = 'Phone';
export const idCustomerSubType = 'CustomerSubType';

export const idAddSiteButton = 'addSiteButton';
export const idAddOrgButton = 'addOrgButton';
export const idSubmitButton = 'submitFormButton';
export const idRevertButton = 'clearFormButton';

// Error constants
export const requiredAdminFields = [
  'Name',
  'Type',
  'AdministratorEmail',
  'AdministratorFirstName',
];

export const requiredFields = [
  'Name',
  'Type',
  'SubType',
  'BillingNumber',
  'AdministratorEmail',
  'AdministratorFirstName',
  'AdministratorLastName',
  'TimeZone',
  'ContactPhone',
  'ContactEmail',
];

export const formFields = {
  address1: {
    id: 'address1',
    name: 'Address',
    translatedLabel: 'ADDRESS.STREET_ONE',
  },
  address2: {
    id: 'address2',
    name: 'Address2',
    translatedLabel: 'ADDRESS.STREET_TWO',
  },
  city: {
    id: 'city',
    name: 'City',
    translatedLabel: 'ADDRESS.CITY',
  },
  contactEmail: {
    id: 'contactEmail',
    name: 'Email',
    translatedLabel: 'COMMON.EMAIL',
    validate: validation.email,
  },
  country: {
    id: 'country',
    name: 'Country',
    translatedLabel: 'ADDRESS.COUNTRY',
  },
  email: {
    id: idEmail,
    name: 'AdministratorEmail',
    normalize: validation.trim,
    translatedLabel: 'COMMON.EMAIL',
    translatedPlaceholder: 'USERS.USER_FORM_FIELDS.PLACEHOLDERS.EMAIL',
    validate: [validation.required, validation.email],
  },
  firstName: {
    id: idFirstName,
    name: 'AdministratorFirstName',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.FIRST_NAME',
    validate: validation.required,
  },
  lastName: {
    id: idLastName,
    name: 'AdministratorLastName',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.LAST_NAME',
  },
  name: {
    id: idName,
    name: 'Name',
    validate: validation.required,
  },
  parent: {
    id: idPartner,
    name: 'DealerId',
    translatedLabel: 'TENANTS.PROVIDER',
    validate: validation.required,
  },
  phone: {
    id: idPhone,
    name: 'Phone',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.PHONE',
  },
  postalCode: {
    id: 'postalCode',
    name: 'PostalCode',
    translatedLabel: 'ADDRESS.ZIP',
  },
  primaryAdminId: {
    id: idPrimaryAdminId,
    name: 'AdminUserId',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.ID',
  },
  region: {
    id: 'region',
    name: 'Region',
    translatedLabel: 'ADDRESS.STATE',
  },
  sapId: {
    id: idSAP,
    name: 'BillingNumber',
    normalize: validation.tenDigits,
    normalizeOnBlur: validation.tenDigitsLeftZeroPadding,
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.ORGANIZATION_ID',
  },
  status: {
    id: idStatus,
    name: 'Status',
    translatedLabel: 'COMMON.STATUS',
  },
  subType: {
    id: idCustomerSubType,
    name: 'SubType',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.PROVIDER_SUBTYPE',
    validate: validation.required,
  },
  tenantEntitlements: {
    id: idEntitlements,
    name: 'Entitlements',
    translatedLabel:
      'USERS.USER_FORM_FIELDS.LABELS.HEALTH_MONITOR_SYSTEM_ENABLED',
  },
  type: {
    id: idCustomerType,
    name: 'Type',
    translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.TYPE',
  },
};

export const ORGANIZATION_HEALTH_MONITOR_ACCESS = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
];
