export const UPDATE_CAMERA_FOCUS_RELATIVE = 'update-camera-focus-relative';
export const UPDATE_CAMERA_FOCUS_CONTINUOUS = 'update-camera-focus-continuous';
export const UPDATE_CAMERA_FOCUS_AUTO = 'update-camera-auto-focus';
export const UPDATE_CAMERA_PAN_TILT_ZOOM = 'update-camera-pan-tilt-zoom';

export const GET_PTZ_PRESETS = 'read-camera-ptz-preset';
export const CREATE_PTZ_PRESET = 'create-camera-ptz-preset';
export const UPDATE_PTZ_PRESET = 'update-camera-ptz-preset';
export const DELETE_PTZ_PRESET = 'delete-camera-ptz-preset';
export const GO_TO_PTZ_PRESET = 'update-camera-preset';
export const GO_TO_HOME_PTZ_PRESET = 'update-camera-preset-home';

export const GET_PTZ_TOURS = 'read-camera-ptz-tour';
export const CREATE_PTZ_TOUR = 'create-camera-ptz-tour';
export const UPDATE_PTZ_TOUR = 'update-camera-ptz-tour';
export const DELETE_PTZ_TOUR = 'delete-camera-ptz-tour';
export const START_PTZ_TOUR = 'update-camera-tour';
export const STOP_PTZ_TOUR = 'update-camera-tour-stop';

export const DELETE_BOOKMARK = 'delete-bookmark';
export const CREATE_BOOKMARK = 'create-bookmark';
