// Libs
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withLocalize } from 'react-localize-redux';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { translatedConnect } from 'util/translatedConnect';
import { bindActionCreators } from 'redux';
import onClickOutside from 'react-onclickoutside';
import { itemCountToLayout, layoutItemCount } from 'util/layoutItemCount';
import Modal from 'react-modal';
import { ThemeProvider } from 'react-jss';
import { Buttons } from '@avo-svtr/savitar';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Components
import { SelectCamera } from 'cameraSelection';
import { InactivityPrompt, Timer, ViewEditor, ViewSaveForm } from 'components';
import { Button, Callout, EmptyPlaceholder, ListNav } from 'lib';
import { ModalContainer, PageMessage, ViewContainer } from 'containers';

// Actions
import * as DeviceActions from 'actions/devices';
import * as ViewActions from 'actions/views';
import * as ClustersActions from 'actions/clusters';
import * as AftClientActions from 'actions/aftClient';
import * as LocationActions from 'actions/locations';
import * as MessageActions from 'actions/pageMessage';
import { hideModal, showModal } from 'actions/modal';

// Selectors
import cameraSelectionSelector from 'selectors/cameraSelection';
// Utils
import { generateSort } from 'util/generateSort';
import { appToggles } from 'util/featureFlags';
import { getActiveLanguageFullCode } from 'util/languagesUtils';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import { NEW_VIEW_ID, newView } from 'store/viewDefaults';
import { cameraOrigins } from 'constants/cameraTypes';
import * as ViewsConsts from 'components/Views/constants';
import * as ToggleTypes from 'constants/ToggleTypes';

// Styles
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';
import {
  layoutDisplayFlex,
  layoutFlex1,
  layoutHeight100Percent,
} from 'sharedStyles/layout.css';
import { contentContainer } from 'sharedStyles/global.css';
import { STREAM_PAUSED, STREAM_RESUMED } from './constants';
import {
  centerContent,
  selectCameraClose,
  selectCameraCloseWrapper,
  selectCameraContainer,
  selectCameraContainerMaximized,
  titleWrap,
  viewContainer,
  viewContentContainer,
  viewsContainer,
  viewsContainerMaximized,
  wrapper,
} from './styles.css';
import { messageStyleStrings } from '../PageMessage/constants';
import SiteViewContainer from '../SiteView';

const customTheme = {};

// Class
class ViewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCallout: false,
      enabledPtzFeed: null,
      inactiveTime: 0,
      isMaximized: false,
      isVisibleAddCameraDropdown: false,
      noSitesAcknowledged: false,
      pausedCount: 0,
      resumedCount: 0,
    };
    this.panel = React.createRef();
  }

  componentDidMount() {
    const {
      actions,
      isFetchingAllCameras,
      isFetchingClusters,
      isFetchingDeviceData,
      isFetchingLocations,
      isFetchingViews,
    } = this.props;
    if (isFetchingViews === null) actions.getViews();
    if (!isFetchingClusters) actions.getClusters();
    if (isFetchingLocations === null) actions.getLocations();
    if (isFetchingDeviceData === null) actions.getAllDevices();
    if (isFetchingAllCameras === null) actions.getAllCameras();
    this.getAccCamerasIfNeeded();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // This block of code handles an edge case relating to maximising screen
    // and resizing the ViewContainer component(s).  We use react-dimensions to
    // do this however we discovered a failure in that package that was never
    // updated and the package is now deprecated.
    // DO NOT DELETE this code until we delete react-dimensions, which is likely
    // only going to happen when EVO is removed entirely.
    const { currentPanelId, showFrame } = this.props;
    const {
      displayCallout,
      enabledPtzFeed,
      inactiveTime,
      isMaximized,
      isVisibleAddCameraDropdown,
      noSitesAcknowledged,
      viewContainerShouldResize,
    } = this.state;
    if (
      nextProps.showFrame !== showFrame ||
      nextState.isVisibleAddCameraDropdown !== isVisibleAddCameraDropdown ||
      nextProps.currentPanelId !== currentPanelId
    ) {
      this.setState({ viewContainerShouldResize: true });
    } else if (
      !nextState.viewContainerShouldResize &&
      nextState.viewContainerShouldResize !== viewContainerShouldResize
    ) {
      this.setState({ viewContainerShouldResize: false });
    }
    return (
      nextState.inactiveTime !== inactiveTime ||
      nextState.displayCallout !== displayCallout ||
      nextState.enabledPtzFeed !== enabledPtzFeed ||
      nextState.isMaximized !== isMaximized ||
      nextState.isVisibleAddCameraDropdown !== isVisibleAddCameraDropdown ||
      nextState.viewContainerShouldResize !== viewContainerShouldResize ||
      nextState.noSitesAcknowledged !== noSitesAcknowledged ||
      // This is ugly but prevents this component to be rendered again every second
      JSON.stringify(this.props) !== JSON.stringify(nextProps)
    );
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      clusters,
      currentView,
      isFetchingClusters,
      isFetchingViewDetails,
      isFetchingViews,
      views,
    } = this.props;
    const hasViewChanged = prevProps.currentView.Id !== currentView.Id;
    const tooManyCams =
      currentView &&
      currentView.Elements &&
      currentView.Elements.length > ViewsConsts.MAX_ACC_CAMERAS;
    if (hasViewChanged && tooManyCams) {
      // TODO: use view change function for this
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        displayCallout: true,
      });
    }
    if (currentView && currentView.Id === NEW_VIEW_ID) {
      if (currentView.isStale) {
        // if current view is new view just added to db, replace state data with persisted data from db
        const viewPersisted = views.find(
          view => view.Name === currentView.Name,
        );
        if (viewPersisted) {
          const viewId = viewPersisted.Id;
          actions.getViewDetails(viewId);
        }
      }
    } else if (!currentView.Id) {
      // need to set current vw after page mounts or current vw deleted
      const viewDefault =
        views.find(view => view.Default) ||
        views
          .sort((a, b) => (a.Name > b.Name ? 1 : -1))
          .find(view => view.Favorite) ||
        views[0];
      if (viewDefault && !isFetchingViewDetails) {
        actions.getViewDetails(viewDefault.Id);
      } else if (isFetchingViews === false && views.length === 0) {
        // If no default view, open on a new view
        this.createNewView();
      }
    }
    if (
      !isFetchingClusters &&
      (prevProps.clusters.length !== clusters.length ||
        prevProps.isFetchingClusters)
    ) {
      actions.getAllAccSavedViewLists(clusters);
      // Check if any cluster has never fetched camera data
      this.getAccCamerasIfNeeded();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.setTurnStreamsPaused(false);
  }

  get sitesWithAccess() {
    const { locations } = this.props;
    return locations.filter(l => l.UserHasAccess);
  }

  get cameraSelectorProps() {
    const { isVisibleAddCameraDropdown } = this.state;
    return {
      activate: this.showCameraSelector,
      isCameraSelectorShowing: isVisibleAddCameraDropdown,
    };
  }

  getAccCamerasIfNeeded = () => {
    const { accCamerasFetched, actions, clusters } = this.props;
    clusters.forEach(cluster => {
      if (
        accCamerasFetched[cluster.Id] === null ||
        accCamerasFetched[cluster.Id] === undefined
      ) {
        actions.refreshCameraList(cluster.LocationId, cluster);
      }
    });
  };

  selectLayoutItemCount = objSelected => {
    const { actions, currentView } = this.props;
    const view = {
      ...currentView,
      Layout: objSelected.value,
      layoutIsDirty: true,
    };
    actions.editCurrentView(view);
  };

  getDeviceId = (locationId, cameraId) => {
    const { selectionList } = this.props;
    const camera = selectionList
      .find(location => location.locationId === locationId)
      .cameras.filter(cam => cam !== undefined)
      .find(cam => cam.Id === cameraId);

    return camera ? camera.DeviceId : 'undefined'; // http://bitbucket.york.lan/projects/MVAAS/repos/server/pull-requests/4585/overview?commentId=69726
  };

  getAccSavedViewDetails = viewId => {
    const { accViews, actions } = this.props;
    const activeLocation = accViews.locations.find(location =>
      location.views.find(view => viewId === view.Id),
    );

    const activeView = activeLocation.views.find(view => view.Id === viewId);

    const cameras = activeView.cameraIds.map((remoteId, index) => {
      return {
        CameraId: remoteId,
        CameraRemoteId: remoteId,
        DeviceId: this.getDeviceId(activeLocation.locationId, remoteId),
        Position: index,
      };
    });

    const newCurrentView = {
      Default: false,
      Elements: cameras,
      Favorite: false,
      Id: viewId,
      Layout: itemCountToLayout(cameras.length),
      Name: activeView.name,
      isAccView: true,
    };
    actions.setCurrentView(newCurrentView);
  };

  onSelectedViewChanged = (viewId, isAccView) => {
    const { actions } = this.props;
    if (isAccView) {
      this.getAccSavedViewDetails(viewId);
    } else {
      actions.getViewDetails(viewId);
    }
  };

  createNewView = () => {
    const { actions } = this.props;
    const view = newView;
    actions.setCurrentView(view);
  };

  setDefaultView = () => {
    this.toggleViewPropertyValue(view => {
      view.Default = !view.Default;
    }, ToggleTypes.TOGGLE_VIEW_DEFAULT);
  };

  toggleSyncVideo = () => {
    const { actions } = this.props;
    actions.toggleSyncVideo();
  };

  setFavorite = () => {
    this.toggleViewPropertyValue(view => {
      view.Favorite = !view.Favorite;
    }, ToggleTypes.TOGGLE_VIEW_FAVORITE);
  };

  toggleViewPropertyValue = (toggle, rollbackAction) => {
    const { actions, currentView } = this.props;
    if (currentView.Id === NEW_VIEW_ID) {
      const view = { ...currentView };
      toggle(view);
      actions.setCurrentView(view);
    } else {
      const view = { ...this.mapCurrentView() };
      toggle(view);
      actions.editView(view, rollbackAction);
    }
  };

  showSaveModal = () => {
    const { actions } = this.props;
    actions.showModal(modalTypes.SAVE_VIEW);
  };

  saveChanges = saveData => {
    const { actions, currentView } = this.props;
    actions.hideModal();
    let view = this.mapCurrentView();

    if (currentView.Id === NEW_VIEW_ID) {
      // New view
      if (saveData.name && this.validateName(saveData.name, true)) {
        view.Name = saveData.name;
        actions.addView(view);
        // add flag to indicate that this obj is stale and should be replaced
        // in componentWillReceiveProps with obj from db
        view.isStale = true;
        actions.setCurrentView(view);
      }
    } else if (saveData.saveACopy) {
      // Existing view, save a copy
      const name =
        saveData.name === view.Name ? `${view.Name}_Copy` : saveData.name;
      if (saveData.name && this.validateName(name, true)) {
        view = Object.assign(view, {
          Id: NEW_VIEW_ID,
          Name: name,
          isStale: true,
        });
        actions.setCurrentView(view);
        actions.addView(view);
      }
    } else if (saveData.name) {
      // Old view, overwrite
      view.Name = saveData.name;
      actions.editView(view);
    }
    if (view.layoutIsDirty && this.validateName(saveData.name, false)) {
      view.layoutIsDirty = false;
      actions.editCurrentView(view);
    }
  };

  validateName = (name, showMessage) => {
    const { actions, views } = this.props;
    const nameIsNotUnique = views.some(item => item.Name === name);
    if (nameIsNotUnique) {
      if (showMessage) {
        actions.showMessage(messageTypes.VIEW_ERROR, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'VIEWS.ADD_VIEW_DUPLICATED_ERROR',
          translateBodyData: {
            viewName: name,
          },
        });
      }
      return false;
    }
    return true;
  };

  handleDelete = () => {
    const { actions, currentView, translate, views } = this.props;
    if (currentView) {
      const id = currentView.Id;
      const selectedView = views.find(view => view.Id === id);
      if (selectedView) {
        const onOkClick = () => {
          actions.deleteView(selectedView.Id, selectedView.Name);
          actions.hideModal();
        };
        const modalProps = {
          handleCancel: () => {
            actions.hideModal();
          },
          message: translate('VIEWS.DELETE_MODAL_TEXT'),
          onOkClick,
          title: translate('VIEWS.DELETE_MODAL_HEADER'),
        };
        actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
      }
    }
  };

  mapCurrentView = () => {
    const { currentView } = this.props;
    const { Elements, ...formData } = currentView;
    const count = layoutItemCount(formData.Layout);
    const elements = this.mapElements(Elements, count);
    return { ...formData, Elements: elements };
  };

  mapElements = (items, count) => {
    const elements = items
      .filter(element => element && element.Position < count) // If layout changed, remove elements in invalid positions
      .map(this.mapElement);
    return elements;
  };

  mapElement = element => {
    const mappedElement = {
      CameraId: element.CameraId,
      Position: element.Position,
    };
    if (element.CameraId === element.RemoteId) {
      mappedElement.CameraOrigin = cameraOrigins.CAMERA_REMOTE;
      mappedElement.DeviceId = element.DeviceId;
    } else {
      mappedElement.CameraOrigin = cameraOrigins.CAMERA_CLOUD;
    }
    return mappedElement;
  };

  maximizeCurrentView = () => {
    const { actions } = this.props;
    this.setState({ isMaximized: true });
    actions.maximizeCurrentView(true);
  };

  minimizeCurrentView = () => {
    const { actions } = this.props;
    this.setState({ isMaximized: false });
    actions.maximizeCurrentView(false);
  };

  handleSelectDate = date => {
    const { actions } = this.props;
    actions.setRecordedVideoStartDate(date);
  };

  showCameraSelector = show => {
    this.setState({
      isVisibleAddCameraDropdown: show,
    });
  };

  onSelectCamera = camera => {
    const { actions } = this.props;
    const { DeviceId, Id: CameraId, RemoteId } = camera;
    actions.setCurrentViewCamera({
      CameraId,
      DeviceId,
      RemoteId,
    });
  };

  onRemoveCamera = () => {
    const { actions } = this.props;
    actions.setCurrentViewCamera({});
  };

  currentCameraId = () => {
    const { currentPanelId, currentView } = this.props;
    let index = null;
    const numberElementsInView = get(this, 'props.currentView.Elements', [])
      .length;

    if (numberElementsInView > 0) {
      index = currentView.Elements.findIndex(element => {
        if (element !== undefined) {
          return element.Position === currentPanelId;
        }
        return null;
      });
      if (currentView.Elements[index] && currentView.Elements[index].CameraId) {
        return currentView.Elements[index].CameraId;
      }
    }
    return null;
  };

  handleClickOutside = e => {
    const { actions } = this.props;
    if (this.panel.current.contains(e.target)) {
      return;
    }
    actions.setCurrentPanelId(null);
  };

  hideTooltip = () => {
    this.setState({
      displayCallout: false,
    });
  };

  handlePtzEnabledInFeed = element => {
    this.setState({ enabledPtzFeed: element });
  };

  currentViewIds = () => {
    const { currentView } = this.props;
    const numberElementsInView = get(this, 'props.currentView.Elements', [])
      .length;

    if (numberElementsInView > 0) {
      return currentView.Elements.reduce((result, cam) => {
        if (cam !== undefined) {
          result.push(cam.CameraId);
        }
        return result;
      }, []);
    }
    return [];
  };

  getTooltipPos = () => {
    const { isMaximized, isVisibleAddCameraDropdown } = this.state;
    return {
      left: isVisibleAddCameraDropdown ? -260 : 55,
      top: isMaximized ? -10 : -30,
    };
  };

  getButtons = () => (
    <Buttons.IconXButton
      buttonClasses={selectCameraClose}
      onClick={() => this.showCameraSelector(false)}
    />
  );

  onTick = time => {
    const {
      actions,
      currentViewTurnStatus,
      inactivityPromptIsOpen,
      timeOutSeconds,
      turnStreamsPaused,
    } = this.props;
    const { pausedCount } = this.state;
    const turnStatuses = Object.values(currentViewTurnStatus);
    const turnStreamsPresent = turnStatuses.find(isTurnStream => isTurnStream);
    const allStreamsTurn =
      turnStatuses.length &&
      turnStatuses.reduce((acc, currIsTurn) => currIsTurn && acc);
    // show after 15 minutes of inactivity
    if (time >= timeOutSeconds) {
      if (!turnStreamsPaused && turnStreamsPresent)
        actions.setTurnStreamsPaused(true);
      if (!inactivityPromptIsOpen && allStreamsTurn) {
        const newPausedCount = pausedCount + 1;
        this.trackAppinsight(STREAM_PAUSED, pausedCount);
        this.setState({ pausedCount: newPausedCount });
        actions.showModal(modalTypes.INACTIVITY_PROMPT);
      }
    }
    this.setState({ inactiveTime: time });
  };

  trackAppinsight = (name, count) => {
    const { currentOrganization } = this.props;
    ai.appInsights.trackEvent({
      name,
      properties: {
        count,
        dealerId: currentOrganization.DealerId,
        dealerName: currentOrganization.DealerName,
        subscriberId: currentOrganization.Id,
        subscriberName: currentOrganization.Name,
      },
    });
  };

  resumeStreaming = () => {
    const { actions, inactivityPromptIsOpen } = this.props;
    const { resumedCount } = this.state;
    if (inactivityPromptIsOpen) {
      const newResumedCount = resumedCount + 1;
      this.trackAppinsight(STREAM_RESUMED, resumedCount);
      this.setState({ resumedCount: newResumedCount });
      actions.hideModal();
      actions.setTurnStreamsPaused(false);
    }
  };

  render() {
    const {
      accViews,
      actions,
      cameras,
      canChangeCustomer,
      currentView: currentViewProp,
      currentViewTurnStatus,
      devices,
      inactivityPromptIsOpen,
      isFetchingLocations,
      isFetchingViewDetails,
      isMaximized,
      locations,
      modalIsOpen,
      selectionList: selectionListProp,
      showFrame,
      syncVideo,
      timeOutSeconds,
      translate,
      turnStreamsPaused,
      views,
    } = this.props;
    const {
      displayCallout,
      enabledPtzFeed,
      inactiveTime,
      isVisibleAddCameraDropdown,
      noSitesAcknowledged,
      viewContainerShouldResize,
    } = this.state;
    const currentView = currentViewProp || {};
    const numStreams = Object.keys(currentViewTurnStatus).length;
    const numTurnStreams = Object.values(currentViewTurnStatus).reduce(
      (acc, currIsTurn) => (currIsTurn ? acc + 1 : acc),
      0,
    );
    const secondsToPause = timeOutSeconds - inactiveTime;
    const enableSiteView = appToggles.featureIsEnabled('site-view');
    const enableSiteViewTree = appToggles.featureIsEnabled('site-view-tree');
    const selectionList =
      enableSiteView || enableSiteViewTree ? (
        <>
          <SiteViewContainer
            languageCode={getActiveLanguageFullCode()}
            removeCamera={this.onRemoveCamera}
            selectCamera={this.onSelectCamera}
            selectedCameraId={this.currentCameraId()}
            siteViewTreeEnabled={enableSiteViewTree}
          />
          <div className={selectCameraCloseWrapper}>
            {Buttons ? this.getButtons() : null}
          </div>
        </>
      ) : (
        <SelectCamera
          devices={devices}
          isShowing={isVisibleAddCameraDropdown}
          removeCamera={this.onRemoveCamera}
          selectCamera={this.onSelectCamera}
          selectedCameraId={this.currentCameraId()}
          selectionList={selectionListProp}
          showDropdown={this.showCameraSelector}
          visibleList={this.currentViewIds()}
        />
      );

    return (
      <ThemeProvider theme={customTheme}>
        <>
          {canChangeCustomer && !isMaximized ? (
            <ListNav canChangeCustomer={canChangeCustomer} />
          ) : null}
          <div
            ref={this.panel}
            className={showFrame ? viewsContainer : viewsContainerMaximized}
          >
            <ViewEditor
              accViews={accViews}
              activateApanelOnCreate={actions.setCurrentPanelId}
              cameraSelector={this.cameraSelectorProps}
              createNewView={this.createNewView}
              currentView={currentView}
              deleteView={this.handleDelete}
              isDirty={currentView.layoutIsDirty}
              isMaximized={!showFrame}
              isSynchronized={syncVideo}
              locations={locations}
              maximizeView={this.maximizeCurrentView}
              minimizeView={this.minimizeCurrentView}
              onSelectView={this.onSelectedViewChanged}
              saveView={this.showSaveModal}
              selectLayout={this.selectLayoutItemCount}
              setDefault={this.setDefaultView}
              setFavorite={this.setFavorite}
              // camera selector
              toggleSyncVideo={this.toggleSyncVideo}
              views={views}
            />
            <div className={`${layoutDisplayFlex} ${layoutHeight100Percent}`}>
              {isVisibleAddCameraDropdown && (
                <div
                  className={
                    showFrame
                      ? selectCameraContainer
                      : selectCameraContainerMaximized
                  }
                >
                  {selectionList}
                </div>
              )}
              <div className={layoutFlex1}>
                <div>
                  <PageMessage
                    messageType={[
                      messageTypes.VIEW_ERROR,
                      messageTypes.VIEW_SUCCESS,
                      messageTypes.BOOKMARKS_MESSAGE,
                    ]}
                  />
                  {turnStreamsPaused ? (
                    <PageMessage
                      id={ViewsConsts.idInactivityBanner}
                      keepOpen
                      rightBody={
                        <Button
                          buttonType="action"
                          onClick={this.resumeStreaming}
                          text={translate('VIEWS.RESUME_ALL')}
                        />
                      }
                      translateBody={
                        numTurnStreams === 1
                          ? 'VIEWS.INACTIVITY_INFO_BANNER_SINGULAR'
                          : 'VIEWS.INACTIVITY_INFO_BANNER_PLURAL'
                      }
                      translateBodyData={{ numStreams: numTurnStreams }}
                      visible={
                        numTurnStreams &&
                        numTurnStreams < numStreams &&
                        !inactivityPromptIsOpen
                      }
                    />
                  ) : (
                    <PageMessage
                      id={ViewsConsts.idInactivityWarning}
                      keepOpen
                      translateBody="VIEWS.PAUSE_STREAM_WARNING"
                      translateBodyData={{ secondsToPause }}
                      visible={
                        secondsToPause <= 60 &&
                        secondsToPause > 0 &&
                        numTurnStreams
                      }
                    />
                  )}
                </div>
                <div className={viewContainer}>
                  <div className={viewContentContainer}>
                    <EmptyPlaceholder
                      isFetching={isFetchingViewDetails}
                      items={[1]}
                    >
                      <ViewContainer
                        cameras={cameras}
                        enabledPtzFeed={enabledPtzFeed}
                        handlePtzEnabledInFeed={this.handlePtzEnabledInFeed}
                        resizeToggle={viewContainerShouldResize}
                        syncVideo={syncVideo}
                        toggleSyncVideo={this.toggleSyncVideo}
                        view={currentView}
                      />
                    </EmptyPlaceholder>
                  </div>

                  {displayCallout ? (
                    <Callout
                      handleClick={this.hideTooltip}
                      left={this.getTooltipPos().left}
                      top={this.getTooltipPos().top}
                      translateId="VIEWS.ACC_VIEW_MAX_CAMERA_NOTICE"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Modal
            className={modalContentContainer}
            isOpen={
              (!this.sitesWithAccess || this.sitesWithAccess.length === 0) &&
              !noSitesAcknowledged &&
              isFetchingLocations === false
            }
            overlayClassName={modalOverlay}
            shouldCloseOnOverlayClick
          >
            <div className={wrapper}>
              <div className={`${centerContent} ${titleWrap}`}>
                {translate('USERS.ADD_USER_MODAL.NO_SITE_ACCESS')}
              </div>
              <div className={centerContent}>
                <Button
                  inputType="button"
                  onClick={() => this.setState({ noSitesAcknowledged: true })}
                  text={translate('BUTTONS.CLOSE')}
                />
              </div>
            </div>
          </Modal>
          <Modal
            className={modalContentContainer}
            isOpen={modalIsOpen}
            onRequestClose={actions.hideModal}
            overlayClassName={modalOverlay}
            shouldCloseOnOverlayClick={false}
          >
            <ModalContainer
              handleCancel={actions.hideModal}
              modalTitle={translate('VIEWS.SAVE_MODAL_HEADER')}
            >
              <ViewSaveForm
                currentView={currentViewProp}
                onCancel={actions.hideModal}
                onSave={this.saveChanges}
              />
            </ModalContainer>
          </Modal>
          <Modal
            className={modalContentContainer}
            isOpen={inactivityPromptIsOpen}
            onRequestClose={this.resumeStreaming}
            overlayClassName={modalOverlay}
            shouldCloseOnOverlayClick
          >
            <InactivityPrompt onResumeStreaming={this.resumeStreaming} />
          </Modal>
          <Timer onTick={this.onTick} />
        </>
      </ThemeProvider>
    );
  }
}

ViewsContainer.propTypes = {
  accCamerasFetched: PropTypes.objectOf(PropTypes.bool),
  accViews: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.object).isRequired,
  canChangeCustomer: PropTypes.bool,
  clusters: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentOrganization: PropTypes.objectOf(PropTypes.any),
  currentPanelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentView: PropTypes.objectOf(PropTypes.any).isRequired,
  currentViewTurnStatus: PropTypes.objectOf(PropTypes.bool).isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  inactivityPromptIsOpen: PropTypes.bool,
  isFetchingAllCameras: PropTypes.bool,
  isFetchingClusters: PropTypes.bool,
  isFetchingDeviceData: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  isFetchingViewDetails: PropTypes.bool,
  isFetchingViews: PropTypes.bool,
  isMaximized: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  selectionList: PropTypes.arrayOf(PropTypes.object),
  showFrame: PropTypes.bool.isRequired,
  syncVideo: PropTypes.bool.isRequired,
  timeOutSeconds: PropTypes.number,
  translate: PropTypes.func.isRequired,
  turnStreamsPaused: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.object),
};

ViewsContainer.defaultProps = {
  accCamerasFetched: {},
  accViews: {},
  canChangeCustomer: false,
  currentOrganization: {},
  currentPanelId: null,
  inactivityPromptIsOpen: false,
  isFetchingAllCameras: null,
  isFetchingClusters: null,
  isFetchingDeviceData: null,
  isFetchingLocations: null,
  isFetchingViewDetails: null,
  isFetchingViews: null,
  isMaximized: false,
  selectionList: [],
  timeOutSeconds: 900,
  views: [],
};

ViewsContainer.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStateToProps(state) {
  const { cameras } = state.devices;
  cameras.sort(generateSort(item => item.Name.toLowerCase()));
  const { EmailAddress: userEmail, Id: userId } = state.user.profile;
  const { currentOrganization } = state.user;
  const timeOutSeconds =
    currentOrganization && currentOrganization.StreamIdleTimeoutInMinutes * 60;
  return {
    accCamerasFetched: state.isFetching.getAftCameraList, // TODO
    accViews: state.views.accSavedViews,
    cameras,
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    clusters: state.clusters.clusters,
    currentOrganization,
    currentPanelId: state.views.panelId,
    currentView: state.views.currentView,
    currentViewTurnStatus: state.views.currentViewTurnStatus,
    devices: state.devices.devices,
    inactivityPromptIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.INACTIVITY_PROMPT,
    isFetchingAllCameras: state.isFetching.getAllCameras,
    isFetchingClusters: state.isFetching.getClusters,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingViewDetails: state.isFetching.getView,
    isFetchingViews: state.isFetching.getViews,
    isMaximized: state.views.isCurrentViewMaximized,
    locations: state.locations.locations,
    modalIsOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.SAVE_VIEW,
    selectedOrganizationId: state.user.selectedOrganization
      ? state.user.selectedOrganization.Id
      : null,
    selectionList: cameraSelectionSelector(state),
    showFrame: !state.views.isCurrentViewMaximized,
    syncVideo: state.views.syncVideo,
    timeOutSeconds,
    turnStreamsPaused: state.views.turnStreamsPaused,
    userEmail,
    userId,
    views: state.views.views,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...ViewActions,
        ...MessageActions,
        ...LocationActions,
        ...AftClientActions,
        ...ClustersActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default translatedConnect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withRouter(
    withLocalize(
      withAITracking(
        ai.reactPlugin,
        onClickOutside(ViewsContainer),
        'ViewsContainer',
        contentContainer,
      ),
    ),
  ),
);
