export const searchTypes = {
  ipAddress: 'ipAddress',
  ipRange: 'ipRange',
  rtsp: 'rtsp',
};

export const getInitialValues = (cameraType, searchType) => {
  return {
    cameraType: cameraType || 'AVIGILON',
    port: cameraType === 'ONVIF' || cameraType === 'AXIS' ? 80 : 55080,
    searchType: searchType || 'ipAddress', // Looks like we need to add port number supporte in datamodel of supportedCameras Get REQUEST
  };
};

export const required =
  'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.REQUIRED_ERROR';

export const field = {
  LABEL: {
    cameraType:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.CAMERA_TYPE_LABEL',
    confirmPassword:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.CONFIRM_PASSWORD_LABEL',
    controlPort:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.CONTROL_PORT_LABEL',
    ipOrHost:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.IP_ADDRESS_HOSTNAME_LABEL',
    password:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.PASSWORD_LABEL',
    rangeEnd:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.RANGE_END_LABEL',
    rangeStart:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.RANGE_START_LABEL',
    searchType:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.SEARCH_TYPE_LABEL',
    searchTypeSelect: {
      ipAddress:
        'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.IP_ADDRESS_OPTION',
      ipRange:
        'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.IP_RANGE_OPTION',
      rtsp: 'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.RTSP_OPTION',
    },
    streamURL:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.STREAM_URL_LABEL',
    username:
      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.USERNAME_LABEL',
  },
  VALUE: {
    address: 'address',
    confirmPassword: 'confirmPassword',
    endIpAddress: 'endIpAddress',
    password: 'password',
    port: 'port',
    searchType: 'searchType',
    searchTypeSelect: {
      ipAddress: 'ipAddress',
      ipRange: 'ipRange',
      rtsp: 'rtsp',
    },
    startIpAddress: 'startIpAddress',
    urlStream: 'urlStream',
    username: 'username',
  },
};
