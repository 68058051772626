import React, { Component } from 'react';
import * as d3Shape from 'd3-shape';

const LineChartItem = ({
  id,
  scaledDataPoints,
  onClick,
  onCircleClick,
  onCircleHover,
  onCircleLeave,
  isSelected,
  color,
  highlightColor,
  radius,
}) => {
  const circleStyle = isSelected
    ? { fill: highlightColor }
    : { fill: color, cursor: 'pointer' };
  const lineStyle = isSelected
    ? { stroke: highlightColor }
    : { stroke: color, cursor: 'pointer' };
  const itemStyle = isSelected
    ? {
        strokeLinecap: 'butt',
        fill: 'none',
        stroke: 'none',
        strokeWidth: '3px',
        opacity: 1,
      }
    : {
        strokeLinecap: 'butt',
        fill: 'none',
        stroke: 'none',
        strokeWidth: '3px',
        opacity: 0.6,
      };

  const graphLine = () =>
    d3Shape
      .line()
      .x(datum => datum.x)
      .y(datum => datum.y);

  const graphCircles = (
    scaledData,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    radius,
  ) =>
    scaledData.map(point => {
      return (
        <circle
          cx={point.x}
          cy={point.y}
          r={radius}
          key={`circle_${point.x}${point.y}`}
          style={style}
          onClick={onClick}
          onMouseEnter={onMouseEnter.bind(this, point.x + point.y)}
          onMouseLeave={onMouseLeave.bind(this, point.x + point.y)}
        />
      );
    });

  return (
    <g key={id} id={id} style={itemStyle}>
      <path
        d={graphLine()(scaledDataPoints)}
        onClick={onClick}
        style={lineStyle}
      />
      {graphCircles(
        scaledDataPoints,
        circleStyle,
        onCircleClick,
        onCircleHover,
        onCircleLeave,
        radius,
      )}
    </g>
  );
};

export default LineChartItem;
