import React, { useState } from 'react';

// Components
import { DropdownItem } from 'components';
import { DocLinkContainer, UserVoiceLinkContainer } from 'containers';
import { Dropdown } from 'lib';
import { Translate, withLocalize } from 'react-localize-redux';
import IconHelp from 'icons/IconHelp';

// Consts
import * as LoginConsts from './LoginStatus.constants';

// Styles
import { helpDropdown } from './HelpDropdown.css';

const HelpDropdown = () => {
  const [showItems, setShowItems] = useState(false);
  const renderDropdown = () => (
    <Translate>
      {({ translate }) => (
        <Dropdown
          id={LoginConsts.idHelpDropdown}
          toggleDropdown={() => {
            setShowItems(!showItems);
          }}
        >
          <DocLinkContainer
            compactMode
            linkKey={LoginConsts.helpDocKey}
            linkText={translate('MASTER_HEADER.HELP')}
          >
            <DropdownItem
              label={translate('MASTER_HEADER.HELP')}
              onClick={() => {}}
            />
          </DocLinkContainer>
          <UserVoiceLinkContainer showAsDropdown />
        </Dropdown>
      )}
    </Translate>
  );
  return (
    <div className={helpDropdown} onClick={() => setShowItems(!showItems)}>
      <IconHelp />
      {showItems && renderDropdown()}
    </div>
  );
};

export default HelpDropdown;
