// Libs
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import renderIf from 'render-if';

// Components
import { ListNav, PageTitle } from 'lib';
import { tenantTypeStrings } from 'util/tenantTypes';

// Utilities
import * as permissions from 'util/permissions';

// Actions
import * as UserActions from 'actions/user';
import * as LocationActions from 'actions/locations';
import * as SubscriptionActions from 'actions/subscriptions';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';

// Constants
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_INFO,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_SERVICE_PLANS,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';

// Styles
import { listNavWrapper } from 'sharedStyles/styles.css';
import { horizontalDividerSolid } from 'sharedStyles/modalForms.css';
import DealerNavMenu from './DealerNavMenu';
import CustomerFormContainer from '../../Customers/CustomerFormContainer';
import ServicePlansContainer from '../serviceplans/ServicePlansContainer';
import SubscriberDetailsContainer from '../sites/SubscriberDetailsContainer';

function DealerDetailsContainer(props) {
  const {
    actions,
    canAddOrg,
    canChangeCustomer,
    canEditOrg,
    customers,
    fullPath,
    handleAddClick,
    history,
    isAdding,
    items,
    match,
    profile,
    translate,
    userTenantType,
  } = props;

  const selectedDealerId = match.params.dealerId;
  const selectedSubscriberId = match.params.subscriberId;
  const addNewDealer = {
    Name: `${translate('BUTTONS.ADD_ORGANIZATION', {
      tenantType: tenantTypeStrings.ProviderFull,
    })}`,
    Type: tenantTypeStrings.Provider,
  };
  const dealerDetailsCustomer = customers.find(p => p.Id === selectedDealerId);
  const subscriberDetailCustomer =
    items && items.find(item => item.Id === selectedSubscriberId);
  const onBackClick = () => {
    return history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}`);
  };
  const onSusbcriberBackClick = () => {
    return history.push(
      `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${selectedDealerId}${PATH_SEGMENT_SUBSCRIBERS}`,
    );
  };

  useEffect(() => {
    const { dealerId } = match.params;
    if (!customers.some(c => c.TenantId === dealerId || c.Id === dealerId)) {
      actions.getCustomerOrganization(dealerId);
    }
  }, [actions, customers, match]);

  const renderTitle = () => {
    const dealerObj = isAdding ? addNewDealer : dealerDetailsCustomer;
    return (
      <div className={listNavWrapper}>
        <PageTitle
          onBackClick={onBackClick}
          showBackButton
          title={dealerObj && dealerObj.Name}
        />
      </div>
    );
  };

  return (
    <>
      {!fullPath.includes(PATH_SEGMENT_SUBSCRIBERS) ? (
        <>
          <ListNav
            canChangeCustomer={canChangeCustomer}
            navigationTabs={
              !isAdding && <DealerNavMenu currentOrgId={selectedDealerId} />
            }
            onAddClick={handleAddClick}
            pageTitle={renderTitle()}
            path={fullPath}
            profile={profile}
          />
          {renderIf(isAdding)(<div className={horizontalDividerSolid} />)}
        </>
      ) : null}

      <Switch>
        <Route
          path={[
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${selectedDealerId}${PATH_SEGMENT_INFO}`,
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}${PATH_SEGMENT_NEW}`,
          ]}
          render={() => {
            return (
              <CustomerFormContainer
                canAdd={canAddOrg}
                canEdit={canEditOrg} // If viewing customers for a Provider
                isAdding={isAdding}
                navigateBack={onBackClick}
                pageTenantType={tenantTypeStrings.Provider}
                selectedCustomer={dealerDetailsCustomer}
                selectedDealerId={selectedDealerId}
                userTenantType={userTenantType}
              />
            );
          }}
        />
        <Route
          path={[
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${selectedDealerId}${PATH_SEGMENT_SERVICE_PLANS}`,
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}${PATH_SEGMENT_NEW}`,
          ]}
          render={() => {
            return (
              <ServicePlansContainer
                canAdd={canAddOrg}
                canEdit={canEditOrg} // If viewing customers for a dealer
                isAdding={isAdding}
                navigateBack={onBackClick}
                pageTenantType={tenantTypeStrings.Dealer}
                selectedCustomer={dealerDetailsCustomer}
                selectedDealerId={selectedDealerId}
                userTenantType={userTenantType}
                noNavigation
                noPageTitle
              />
            );
          }}
        />
        <Route
          path={[
            `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${selectedDealerId}${PATH_SEGMENT_SUBSCRIBERS}/${selectedSubscriberId}`,
            `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}${PATH_SEGMENT_NEW}`,
          ]}
          render={() => {
            return (
              <SubscriberDetailsContainer
                canAddOrg={canAddOrg}
                canChangeCustomer={canChangeCustomer}
                customer={subscriberDetailCustomer}
                history={history}
                isAdding={isAdding}
                items={items}
                onBackClick={onSusbcriberBackClick}
                pageTenantType={tenantTypeStrings.Subscriber}
                path={fullPath}
                profile={profile}
                selectedSubscriberId={selectedSubscriberId}
                userTenantType={userTenantType}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

DealerDetailsContainer.defaultProps = {
  customers: [],
  handleAddClick: () => {},
  isAdding: false,
  isFetchingCustomerOrganizations: null,
  items: [],
};

DealerDetailsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  canAddOrg: PropTypes.bool.isRequired,
  canChangeCustomer: PropTypes.bool.isRequired,
  canEditOrg: PropTypes.bool.isRequired,
  customers: PropTypes.arrayOf(PropTypes.any),
  fullPath: PropTypes.string.isRequired,
  handleAddClick: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isAdding: PropTypes.bool,
  isFetchingCustomerOrganizations: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
  userTenantType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    actionMenu: state.actionMenu,
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canAddOrg: state.user.permissions.CAN_CREATE_ORG,
    canEditOrg: state.user.permissions.CAN_EDIT_ORG,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    canViewCustomerType: state.user.permissions.CAN_HAVE_DEALER_CUSTOMERS,
    canViewOrgControls: state.user.permissions.CAN_VIEW_ORG_CONTROLS,
    customers: state.user.customerOrganizations,
    isFetchingCustomerOrganizations: state.user.isFetchingCustomerOrganizations,
    items: state.user.customerOrganizations,
    orgId,
    profile: state.user.profile,
    userTenantType:
      state.user.profile.CustomerTenantType || state.user.profile.TenantType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...FilterActions,
        ...LocationActions,
        ...UserActions,
        ...SubscriptionActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default withLocalize(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withRouter(DealerDetailsContainer)),
);
