/* eslint-disable react/jsx-props-no-spreading */
// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

// Components
import { FieldPassword, ResponsiveFieldInput } from 'components';
import { LanguageSelect, PageMessage } from 'containers';
import { Button } from 'lib';

// Constants
import { SUBMIT_BUTTON_ID } from 'constants/ElementId';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as messageTypes from 'constants/MessageTypes';
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE,
} from 'constants/app';

// Validation
import {
  allPasswordChecksPass,
  required,
  validatePasswordInput,
} from 'util/validation';

// Styles
import { formWrapper } from 'sharedStyles/modalForms.css';
import {
  formField,
  formGroup,
  formLabel,
} from 'components/VerticalForm/styles.css';
import { buttonContainerRegister, inputError, pageError } from './styles.css';

const formLevelValidation = values => {
  const errors = {};
  if (values) {
    if (
      !values.FirstName ||
      (values.FirstName && values.FirstName.length === 0)
    ) {
      errors.FirstName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.LastName || (values.LastName && values.LastName.length === 0)) {
      errors.LastName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.Password) {
      errors.Password = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.ConfirmPassword) {
      errors.ConfirmPassword = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      values.Password &&
      values.ConfirmPassword &&
      values.Password !== values.ConfirmPassword
    ) {
      errors.ConfirmPassword = <Translate id="PROFILE.LABEL_ERROR_NO_MATCH" />;
    }
    const passwordFormatErrors = validatePasswordInput(values.Password || '');
    if (values.Password && !allPasswordChecksPass(passwordFormatErrors)) {
      errors.Password = <Translate id="PROFILE.LABEL_ERROR_FORMATTING" />;
    }
  }
  return errors;
};

const ResponsiveFieldLanguageSelect = props => {
  const {
    className,
    input: inputProps,
    labelTranslationId,
    meta: { error, touched },
  } = props;
  return (
    <div className={className}>
      <label className={formLabel}>
        <Translate id={labelTranslationId} />
      </label>
      <LanguageSelect {...inputProps} className={formGroup} />
      <span className={inputError}>
        {touched && error && <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />}
      </span>
    </div>
  );
};

ResponsiveFieldLanguageSelect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  labelTranslationId: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
};

ResponsiveFieldLanguageSelect.defaultProps = {
  className: '',
  input: {},
  labelTranslationId: '',
};

const PasswordFieldInput = props => {
  const {
    className,
    id,
    labelTranslationId,
    meta: { error, pristine },
  } = props;
  return (
    <div className={className}>
      <label className={formLabel}>
        <Translate id={labelTranslationId} />
      </label>
      <FieldPassword hideErrorLabel inputId={id} {...props} />
      <div className={inputError}>{!pristine && error && error}</div>
    </div>
  );
};

PasswordFieldInput.defaultProps = {
  className: '',
  id: '',
  labelTranslationId: null,
  meta: null,
  type: null,
};

PasswordFieldInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  labelTranslationId: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

// Classs
class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      activeLanguage:
        localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || DEFAULT_LANGUAGE,
      initialLanguage:
        localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || DEFAULT_LANGUAGE,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.initialValues &&
      prevState.initialLanguage !==
        nextProps.initialValues.CommunicationLanguage
    ) {
      return {
        activeLanguage: nextProps.initialValues.CommunicationLanguage,
        initialLanguage: nextProps.initialValues.CommunicationLanguage,
      };
    }
    return null;
  }

  componentDidUpdate() {
    const { change } = this.props;
    const { activeLanguage } = this.state;
    change('CommunicationLanguage', activeLanguage);
  }

  setTheValue = (field, value) => {
    const { change } = this.props;
    change(field, value);
  };

  render() {
    const { handleSubmit, invalid } = this.props;
    const { activeLanguage } = this.state;
    return (
      <div className={formWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={pageError}>
            <PageMessage
              messageStyle={messageStyleStrings.error}
              messageType={messageTypes.SIGNUP_ERROR}
            />
          </div>
          <Field
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="SIGNUP.FIRST_NAME_LABEL"
            name="FirstName"
            type="text"
            validate={required}
          />
          <Field
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="SIGNUP.LAST_NAME_LABEL"
            name="LastName"
            type="text"
            validate={required}
          />
          <Field
            className={formGroup}
            component={PasswordFieldInput}
            id="password"
            labelTranslationId="SIGNUP.PASSWORD_LABEL"
            name="Password"
            newUser
            showValidCheckmark={false}
            type="password"
            validate={required}
          />
          <Field
            className={formGroup}
            component={PasswordFieldInput}
            id="confirmPassword"
            isConfirmation
            labelTranslationId="SIGNUP.CONFIRM_PASSWORD_LABEL"
            name="ConfirmPassword"
            newUser
            showValidCheckmark={false}
            type="password"
            validate={required}
            validationWindowVisible={false}
          />

          {LANGUAGES.length > 1 && (
            <Field
              className={formGroup}
              component={ResponsiveFieldLanguageSelect}
              labelTranslationId="PROFILE.LABEL_COMMUNICATION_LANGUAGE"
              name="CommunicationLanguage"
              userLanguage={activeLanguage}
              validate={required}
            />
          )}
          <div className={buttonContainerRegister}>
            <Button
              disabled={invalid}
              id={SUBMIT_BUTTON_ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
        </form>
      </div>
    );
  }
}

SignupForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ CommunicationLanguage: PropTypes.string })
    .isRequired,
  invalid: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default reduxForm({
  enableReinitialize: true,
  form: 'signupForm',
  validate: formLevelValidation,
})(withRouter(withLocalize(SignupForm)));
