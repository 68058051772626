import * as types from 'constants/ActionTypes';
import * as webRtcRoutes from 'constants/WebRtcRoutes';
import * as tourConsts from 'components/PtzTourForm/constants';
import { COMMAND_FAIL_RETRIES } from 'util/WebRtcHelper';
import { cloneDeep } from 'util/cameraSettingLinks';

const PtzReducers = {};

PtzReducers[types.SENT_WEBRTC_REQUEST] = (state, action) => {
  const webRtcRequests = Object.assign({}, state.webRtcRequests);
  const webRtcRequestsEntry = {
    serverId: action.serverId,
    cameraId: action.cameraId,
    route: action.route,
    query: action.query,
    remainingTries: COMMAND_FAIL_RETRIES,
  };
  webRtcRequests[action.commandId] = webRtcRequestsEntry;
  const _state = Object.assign({}, state, { webRtcRequests });
  return _state;
};

PtzReducers[types.RECEIVED_WEBRTC_RESPONSE] = (state, action) => {
  const webRtcRequests = Object.assign({}, state.webRtcRequests);
  const request = webRtcRequests[action.response.commandId];

  if (!request) {
    return state;
  }

  const { cameraId } = request;
  delete webRtcRequests[action.response.commandId];

  let _state = Object.assign({}, state, { webRtcRequests });

  if (action.response.status === 'error') {
    avoLogError('Received error from WebRTC', {
      response: action.response,
      request,
    });
    return _state;
  }

  switch (action.response.route) {
    case webRtcRoutes.GET_PTZ_PRESETS: {
      const ptzPresets = Object.assign({}, _state.ptzPresets);
      ptzPresets[cameraId] = action.response.payload;
      _state = Object.assign({}, _state, { ptzPresets });
      break;
    }

    case webRtcRoutes.CREATE_PTZ_PRESET: {
      const ptzPresets = cloneDeep(_state.ptzPresets);
      const currentPresets = ptzPresets[cameraId].presets;
      currentPresets.push({
        id: request.query.presetId,
        name: request.query.name,
      });
      _state = Object.assign({}, _state, { ptzPresets });
      break;
    }

    case webRtcRoutes.DELETE_PTZ_PRESET: {
      const ptzPresets = cloneDeep(_state.ptzPresets);
      const currentPresets = ptzPresets[cameraId].presets;
      const deletedPreset = currentPresets.find(
        p => p.id === request.query.presetId,
      );
      if (deletedPreset) {
        const index = currentPresets.indexOf(deletedPreset);
        currentPresets.splice(index, 1);
      }
      _state = Object.assign({}, _state, { ptzPresets });
      break;
    }

    case webRtcRoutes.GET_PTZ_TOURS: {
      const ptzTours = Object.assign({}, _state.ptzTours);
      const { defaultTour, defaultIdleTimeoutMs } = action.response.payload;
      const tours = action.response.payload.tours
        .filter(tour => tour.name)
        .map(tour => {
          return {
            ...tour,
            isDefault: tour.id === defaultTour,
            idleStartTime:
              defaultTour !== undefined
                ? defaultIdleTimeoutMs
                : tourConsts.DEFAULT_IDLE_START_TIME,
          };
        });
      ptzTours[cameraId] = { ...action.response.payload, tours };
      _state = Object.assign({}, _state, { ptzTours });
      break;
    }

    case webRtcRoutes.CREATE_PTZ_TOUR: {
      const ptzTours = cloneDeep(_state.ptzTours);
      const currentTours = ptzTours[cameraId];
      const requestTour = request.query.tour;

      if (requestTour.isDefault) {
        currentTours.defaultTour = requestTour.id;
        currentTours.defaultIdleTimeoutMs = requestTour.idleStartTime;
      }

      currentTours.tours.push(request.query.tour);

      currentTours.tours = currentTours.tours.map(tour => {
        tour.idleStartTime =
          currentTours.defaultTour !== undefined
            ? currentTours.defaultIdleTimeoutMs
            : tourConsts.DEFAULT_IDLE_START_TIME;
        tour.isDefault = tour.id === currentTours.defaultTour;
        return tour;
      });

      _state = Object.assign({}, _state, { ptzTours });
      break;
    }

    case webRtcRoutes.UPDATE_PTZ_TOUR: {
      const ptzTours = cloneDeep(_state.ptzTours);
      const currentTours = ptzTours[cameraId];
      const defaultTourId = currentTours.defaultTour;
      const requestTour = request.query.tour;
      const updatedTour = currentTours.tours.find(
        tour => tour.id === requestTour.id,
      );
      if (!updatedTour) break;

      if (requestTour.isDefault) {
        currentTours.defaultTour = requestTour.id;
        currentTours.defaultIdleTimeoutMs = requestTour.idleStartTime;
        updatedTour.isDefault = true;
      } else if (defaultTourId === requestTour.id && !requestTour.isDefault) {
        delete currentTours.defaultTour;
        currentTours.defaultIdleTimeoutMs = 0;
        updatedTour.isDefault = false;
      }

      updatedTour.name = requestTour.name;
      updatedTour.mode = requestTour.mode;
      updatedTour.presets = requestTour.presets;
      updatedTour.pauseDurationMs = requestTour.pauseDurationMs;

      currentTours.tours = currentTours.tours.map(tour => {
        tour.idleStartTime =
          currentTours.defaultTour !== undefined
            ? currentTours.defaultIdleTimeoutMs
            : tourConsts.DEFAULT_IDLE_START_TIME;
        tour.isDefault = tour.id === currentTours.defaultTour;
        return tour;
      });

      _state = Object.assign({}, _state, { ptzTours });
      break;
    }
  }

  return _state;
};

PtzReducers[types.RETRY_WEBRTC_REQUEST] = (state, action) => {
  const webRtcRequests = Object.assign({}, state.webRtcRequests);
  const failedRequest = webRtcRequests[action.previousCommandId];
  webRtcRequests[action.commandId] = Object.assign({}, failedRequest);
  const retryRequest = webRtcRequests[action.commandId];
  retryRequest.remainingTries = failedRequest.remainingTries - 1;
  delete webRtcRequests[action.previousCommandId];
  return Object.assign({}, state, { webRtcRequests });
};

PtzReducers[types.REMOVE_FAILED_WEBRTC_REQUEST] = (state, action) => {
  const webRtcRequests = Object.assign({}, state.webRtcRequests);
  delete webRtcRequests[action.failedCommandId];
  return Object.assign({}, state, { webRtcRequests });
};

export default PtzReducers;
