import * as types from 'constants/ActionTypes';
import * as permissionTypes from 'constants/PermissionTypes';
// TODO: replace with helper function
const generatePermissionsObject = () => {
  const permissions = {};
  Object.keys(permissionTypes).forEach(key => {
    permissions[key] = false;
  });
  return permissions;
};

// Please ensure new keys added to the initialState are in alphabetical order
export default function initialState() {
  return {
    accServerLists: {},
    accordions: {
      types: {},
    },
    actionMenu: {
      isVisible: false,
    },
    aft: {},
    aftSiteView: {},
    alarms: {
      alarmModal: types.ALARM_MODAL_HIDE,
      alarmsCount: 0,
      alarmsGraphStats: [],
      alarmsNextLink: null,
      alarmsPrevLink: null,
      alarmsSummaryStats: {},
      allAlarms: [],
      clips: {},
      healthAlarms: [],
      healthAlarmsNextLink: null,
      healthAlarmsPrevLink: null,
      isFetchingAlarmData: null,
      isFetchingAlarmsStats: null,
      isFetchingHealthAlarmData: null,
      isFetchingSecurityAlarmData: null,
      isFetchingSelectedAlarm: {},
      securityAlarms: [],
      securityAlarmsNextLink: null,
      securityAlarmsPrevLink: null,
      selectedAlarm: {},
      visibleAlarmIds: [],
      widgetVisibility: {},
    },
    bookmarks: {
      bookmarks: {},
      bookmarksQueryOptions: {},
    },
    clusters: {
      clusters: [],
    },
    contacts: {
      contacts: [],
    },
    dashboard: {
      dealerSubscriberStats: {},
      dealerSubscriberUpgradeSites: [],
      dealerSubscribersNearingRenewal: [],
      dealerSubscriptionLevels: [],
      isFetchingDealerSubscriberStats: null,
      isFetchingDealerSubscriberUpgradeSites: null,
      isFetchingDealerSubscribersNearingRenewal: null,
      isFetchingDealerSubscriptionLevels: null,
      isFetchingSubscriptionRenewalPeriods: null,
      subscriptionRenewalPeriods: [],
    },
    devices: {
      activePtzFunction: null,
      cameras: [],
      claimError: null,
      claimKeys: {},
      claimedDeviceId: null,
      deviceActivationCode: null,
      devices: [],
      discoveredCameras: [],
      discoveredCamerasCredentials: [],
      // deviceId => [collection of entities],
      entities: {},
      // entityId => settings
      entitySettings: {},
      events: {},
      firmwareImage: {},
      // deviceId => {status: string, progress: string}
      firmwareUpgrade: {},
      isFetchingAcquisitionData: null,
      isFetchingCameraSettings: {
        error: false,
        isFetching: null,
      },
      isFetchingCompressionData: null,
      isFetchingDeviceData: null,
      // deviceId => bool
      isFetchingDeviceEntities: {},
      // Need an entry for each device
      isFetchingDeviceSnapshots: {},
      isFetchingDiscoveredCameras: null,
      // entityId => bool
      isFetchingEntitySettings: {},
      isFetchingFirmwareImage: null,
      // {locationId1: bool, locationId2: bool, ...}
      isFetchingLocationDeviceData: {},
      isFetchingPrivacyZones: null,
      isFetchingPtzPresets: null,
      isFetchingRuleData: null,
      isFetchingServerCapabilities: null,
      isFetchingSitesWithUpgrades: false,
      // TODO: Eliminate this
      isFetchingSnapshots: false,
      isFetchingSupportedCameras: null,
      // deviceId => { uri: uri, isFetching: bool }
      logs: {},
      mediaParams: [],
      poe: [],
      ptzPresets: {},
      ptzTours: {},
      rules: {},
      selectedCamera: null,
      selectedServer: null,
      serversAndCameras: [],
      settings: {},
      sitesWithUpgrades: [],
      // cameraId => {uri: string, stale: optional bool}
      snapshotSource: {},
      snapshots: {},
      supportedCameras: [],
      // cameraId => {recording: array, motion: array, isFetching: bool }
      timelines: {},
      users: [],
      videoExports: [],
      webRtcRequests: {},
    },
    filters: {
      alarms: {
        assignedTo: [],
        cameraName: [],
        dateRangeEnd: undefined,
        dateRangeStart: undefined,
        location: [],
        rule: [],
        status: [],
        subscriber: [],
      },
      devices: {
        location: [],
        model: [],
        status: [],
        timezones: [],
      },
      filterStatus: {},
      infiniteWithoutOData: [],
      locations: {
        city: [],
        country: [],
        region: [],
      },
      users: {
        location: [],
        role: [],
      },
    },
    isFetching: {
      getAftCameraList: {},
      getAftServerList: {},
      getAftSiteviewTree: {},
      getAiKey: null,
      getAllCameras: null,
      getAllServersAndCameras: null,
      getBannerNotices: null,
      getBookmarks: {},
      getCameras: {},
      getClusters: null,
      getContacts: null,
      getDealerServicePackageFamilies: null,
      getDevicePoe: null,
      getIntegrationTypes: null,
      getLinks: null,
      getLocations: null,
      getLocationsScoped: null,
      getOrgSubscriptions: null,
      getRecordingSettings: null,
      getRegionalDeployments: null,
      getRules: null,
      getSchedules: null,
      getServerTimeZoneList: null,
      getServicePackage: {},
      getServicePackageByCode: null,
      getServicePackages: null,
      getSubscriberServicePackages: null,
      getTemplates: null,
      getTimezones: null,
      getUserProfile: null,
      getUsers: null,
      getUsersPublicInfo: {},
      getView: null,
      getViews: null,
    },
    isSaving: {
      location: null,
    },
    locale: {},
    locations: {
      dealerServiceFamilies: [],
      dealerServicePackages: [],
      isFetchingPendingSiteRequests: null,
      isUpdatingDealerHealthMonitoring: null,
      lastFetchedOrgId: null,
      locations: [],
      locationsScoped: {},
      modalIsOpen: false,
      orgSubscriptions: [],
      pendingSiteRequests: [],
      servicePackages: [],
      subscriptions: [],
    },
    microFrontend: {
      hms: {
        redirectToHMSRoot: false,
      },
    },
    modal: {
      isOpen: false,
      modalProps: {},
      modalType: null,
    },
    motion: {
      active: false,
      currentX: null,
      currentY: null,
      initialX: null,
      initialY: null,
    },
    notifications: {
      connectionId: null,
      connectionTries: 0,
      hasConnectionSubscription: false,
      // true if subscribe or unsubscribe is in flight
      isUpdatingConnectionSubscription: false,
      subscribedTenant: null,
    },
    organizations: {
      // configId, canDelete, array of sites using the configuration (optional), error message (optional)
      canDeleteConfiguration: {},
      integrationConfigTest: {},
      // [integrationConfig.IntegrationConfigurationId]: response value (null, true, false)
      integrationConfigs: [],
      // site data by subscriber
      integrationSubscriberSites: [],
      integrationSubscriberSummary: [],
      integrationTypes: [],
      isFetchingIntegrationConfigs: null,
      isFetchingIntegrationSubscriberSummary: null,
    },
    pageMessage: {
      body: null,
      header: null,
      messageType: null,
      props: {},
    },
    persistentFilters: {
      // Stores persistent filters from the ListViews
    },
    ptzTour: {
      isFetchingPtzTours: {},
    },
    recordingData: {
      isDirty: false,
      recordingSchedules: [],
      selectedTemplate: null,
      templates: [],
      usingRealTemplateIds: true,
    },
    settings: {
      hideIpInput: true,
      showVideo: true,
    },
    signalr: {
      log: [],
    },
    siteView: {
      collapsedSites: [],
      isCompressed: false,
      topScrolledDevice: null,
    },
    streamOverlays: {},
    toggle: {
      actions: [],
    },
    user: {
      bannerNotices: [],
      currentOrganization: null,
      currentPermissionsFor: null,
      customerOrganizations: [],
      customers: [],
      customersNextPageLink: '',
      disableAutoComplete: false,
      hasAcceptedEula: null,
      isAuthenticated: null,
      isFetchingCurrentOrganization: null,
      isFetchingCustomerData: null,
      isFetchingCustomerOrganizations: null,
      isLoggingOut: false,
      isNotLast3Passwords: {},
      isPasswordChangeSuccessful: false,
      organizations: [],
      partners: [],
      permissions: generatePermissionsObject(),
      profile: {},
      providers: [],
      redirectToOnSuccess: null,
      roles: [],
      schedules: [],
      selectedOrganization: null,
      userName: '',
      users: [],
      usersPublicInfo: {}, // Key is tenantId, value contains an array, either of complete user objects or the limited user objects returned by GET /UsersPublicInfo
    },
    utilities: {
      aiKey: null,
      isWorldwideCentral: null, // core Blue deployment = true, regional deployment = false
      links: {},
      regionalDeployments: [],
      timeZoneLists: {},
      timezones: [],
    },
    videoRetention: {},
    views: {
      accSavedViews: { locations: [] },
      currentView: {},
      currentViewTurnStatus: {}, // position (string) => turn stream (bool)
      isCurrentViewMaximized: false,
      isFetchingViews: null,
      recordedVideo: {},
      resumeStreamStartTimes: {}, // position (string) => startTime (number)
      syncVideo: false,
      syncVideoStartTime: null,
      turnStreamsPaused: false,
      views: [],
    },
  };
}
