import PropTypes from 'prop-types';
import React from 'react';
import { useRecoilState } from 'recoil';

//Atoms
import { showFilters as showFiltersAtom } from 'atoms';

//Components
import ListNav from '../ListNav/ListNav';

function AvoListNav(props) {
  const {} = props;
  const [showFilters, setShowFilters] = useRecoilState(showFiltersAtom);

  return (
    <ListNav
      {...props}
      showFilters={showFilters}
      setShowFilters={setShowFilters}
    />
  );
}

export default AvoListNav;
