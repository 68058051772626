import PropTypes from 'prop-types';
import React from 'react';
import ReactSlider from 'react-slider';
import { withLocalize } from 'react-localize-redux';
import {
  playerSegmentedButton,
  btnContent,
  verticalButtonGroup,
} from './styles.css';

const VideoButtons = props => {
  return (
    <div style={props.show ? {} : { visibility: 'hidden' }}>
      <div
        className={verticalButtonGroup}
        style={props.showDigitalZoomControls ? {} : { visibility: 'hidden' }}
      >
        <button
          className={playerSegmentedButton}
          onClick={props.onZoomIn}
          alt={props.translate('VIDEO_PLAYER.ZOOM_IN_LABEL')}
          title={props.translate('VIDEO_PLAYER.ZOOM_IN_LABEL')}
        >
          <span className={btnContent}>+</span>
        </button>
        <ReactSlider
          className="verticalSlider"
          step={0.1}
          min={1}
          defaultValue={1}
          value={props.zoomLevel}
          max={props.maxZoomLevel}
          orientation="vertical"
          onChange={props.onSliderChange}
          withBars
          invert
        />
        <button
          className={playerSegmentedButton}
          onClick={props.onZoomOut}
          alt={props.translate('VIDEO_PLAYER.ZOOM_OUT_LABEL')}
          title={props.translate('VIDEO_PLAYER.ZOOM_OUT_LABEL')}
        >
          <span className={btnContent}>-</span>
        </button>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

VideoButtons.defaultProps = {
  onZoomIn: PropTypes.func,
  onZoomOut: PropTypes.func,
  zoomLevel: 1,
  maxZoomLevel: 8,
  show: true,
};

export default withLocalize(VideoButtons);
