// Libs
import PropTypes from 'prop-types';
import React from 'react';

// Components
import { Icon, ListView } from 'lib';

import { IC_VIDEO } from 'constants/iconNames';
import {
  cameraIcon,
  cameraName,
  cameraNameCol,
  cameraRow,
  scheduleTable,
} from './styles.css';

// Constants
import * as consts from './constants';

// Icons

const CamerasTable = props => {
  const { cameraIconWidth, camerasArray } = props;

  const CELL_WIDTHS = {
    Name: '15%',
  };
  const CUSTOM_CELLS = {
    Name: rowData => {
      return (
        <div className={cameraNameCol}>
          <div className={cameraIcon}>
            <Icon id={IC_VIDEO} size={cameraIconWidth} />
          </div>
          <div className={cameraName}>{rowData.Name}</div>
        </div>
      );
    },
  };
  return (
    <div className={scheduleTable}>
      <ListView
        cellWidths={CELL_WIDTHS}
        customBodyRowClass={cameraRow}
        customCells={CUSTOM_CELLS}
        data={camerasArray}
        fieldOrder={consts.FIELD_ORDER}
        headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
        hideFilter
        rowHeight="40"
      />
    </div>
  );
};

CamerasTable.defaultProps = {
  cameraIconFill: '#575757',
  cameraIconHeight: 10,
  cameraIconWidth: 15,
};

CamerasTable.propTypes = {
  cameraIconFill: PropTypes.string,
  cameraIconHeight: PropTypes.number,
  cameraIconWidth: PropTypes.number,
  camerasArray: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};

export default CamerasTable;
