import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconFocusIn = props => {
  return (
    <div className={props.className} title={props.title}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path
            d="M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
            id="ptz_icon_focus_weak_a"
          />
          <path id="ptz_icon_focus_weak_d" d="M0 0h24v24H0z" />
          <filter
            x="-29.2%"
            y="-20.8%"
            width="158.3%"
            height="158.3%"
            filterUnits="objectBoundingBox"
            id="ptz_icon_focus_weak_c"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g transform="translate(-2 -1)" fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <mask id="ptz_icon_focus_weak_b" fill="#fff">
            <use xlinkHref="#ptz_icon_focus_weak_a" />
          </mask>
          <g mask="url(#ptz_icon_focus_weak_b)">
            <use
              fill="#000"
              filter="url(#ptz_icon_focus_weak_c)"
              xlinkHref="#ptz_icon_focus_weak_d"
            />
            <use fill="#FFF" xlinkHref="#ptz_icon_focus_weak_d" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconFocusIn.defaultProps = {
  className: null,
  width: '48px',
  height: '48px',
  fill: 'white',
  title: 'Focus in',
};

export default IconFocusIn;
