import jsonParseRobust from './jsonParseRobust';

/* Helper method for creating reduce functions. Adds a key to an object
 * and sets it to a default value
 * Example:
 *   reducer = addKeyToObjectAs(0);
 *   obj = {}
 *   result = reducer(obj, 'foo');
 *   console.log(result);
 *   > { foo: 0 }
 *   console.log(obj);
 *   > { foo: 0 }
 */
function addKeyToObjectAs(defaultValue) {
  return (memo, key) => {
    memo[key] = defaultValue;
    return memo;
  };
}

/* Returns an object containing empty child objects named based on the
 * supplied input keys.
 * Example:
 *     objectify(['foo', 'bar'])
 *     > { foo: {}, bar: {} }
 */
function objectify(keys) {
  const initialValue = {};
  const asObjects = addKeyToObjectAs({});
  return keys.reduce(asObjects, initialValue);
}

/* Returns an object containing boolean `true` values named based on the
 * supplied input keys.
 * Example:
 *     objectify(['foo', 'bar'])
 *     > { foo: true, bar: true }
 */
function booleanify(keys) {
  const initialValue = {};
  const asBooleans = addKeyToObjectAs(true);
  return keys.reduce(asBooleans, initialValue);
}

function transformObjectValues(object, transform) {
  const reducer = (memo, key) => {
    memo[key] = transform(object[key]);
    return memo;
  };
  return Object.keys(object).reduce(reducer, {});
}

// TODO: there are probably optional properties that are missing here
const CapabilitiesKeys = [
  'acquisition',
  'audio',
  'compression',
  'digitalIo',
  'exposure',
  'general',
  'h264',
  'mjpeg',
  'motion',
  'network',
  'profileRecording',
  'ptz',
  'speaker',
  'streamRecording',
];

/* Return a normalized version of the camera object that allows downstream
 * code to consume the data model with less verbosity and error checking, by
 * guaranteeing that certain assumptions can be made about the returned
 * object.
 *
 * Original Example:
 *   let hasSpeaker = false;
 *   if (camera.Capabilities) {
 *     let capabilities = JSON.parse(camera.Capabilities); // assumes valid JSON
 *     hasSpeaker = capabilities.speaker &&
 *       capabilities.speaker.includes('SPEAKER_OUTPUT');
 *   }
 *
 * Normalized EXample:
 *   let camera = normalizedCamera(camera);
 *   let hasSpeaker = camera.Capabilities.speaker.SPEAKER_OUTPUT;
 */
export default function normalizeCamera(camera, state) {
  if (!camera) {
    // whatever non-truthy value we received, pass through unchanged
    return camera;
  }

  // start with a copy of the original object
  const normalized = Object.assign({}, camera);

  // TODO normalize other parts of the structure, i.e. Links,
  // FirmwareUpgradeStatus, etc..

  /* start with a default "sane" state that contains empty objects for
   * everything we expect to be present
   */
  normalized.Capabilities = objectify(CapabilitiesKeys);

  /* merge in anything we parse from the camera metadata */
  try {
    normalized.Capabilities = Object.assign(
      normalized.Capabilities,
      transformObjectValues(jsonParseRobust(camera.Capabilities), value =>
        booleanify(value),
      ),
    );
  } catch (e) {
    /* Either the property didn't contain valid JSON, or the JSON didn't
     * represent an object with properties that were arrays of strings.  In
     * either case, it's appropriate to allow the initial state created by
     * objectify() above to fall through here.
     */
  }

  // Stitch in any related values from state, if found
  if (state && state.devices) {
    const { devices } = state;
    const id = normalized.Id;
    normalized.speakers = (devices.speakers && devices.speakers[id]) || {};
  }

  return normalized;
}

export function findCamera(state, id, callbackIfFound) {
  let camera;
  if (state && id) {
    const sourceCamera = state.devices.cameras.find(c => c.Id === id);
    camera = normalizeCamera(sourceCamera, state);
    if (camera && callbackIfFound) {
      callbackIfFound(camera);
    }
  }
  return camera;
}
