export const IC_FILTER_OFF = 'ic_filter_sm_off';
export const IC_FILTER = 'ic_filter_sm';
export const IC_TRASH = 'ic_trash';

export const tenantTypeStrings = {
  CloudOwner: 'Avigilon',
  Dealer: 'Provider',
  Provider: 'Provider',
  Subscriber: 'Subscriber',
};
export const ACTION_IMAGES = {
  CONTACT: 'Contact',
  DEALER: 'Dealer',
  DEALER_SELECTED: 'DealerSubscriber',
  LOCATION: 'Location',
  NOTIFICATION: 'Notification',
  PROVIDER: 'Provider',
  SCHEDULE: 'Schedule',
  SITE_SUBSCRIPTIONS: 'Site Subscriptions',
  SUBSCRIBER: 'Subscriber',
  USER: 'User',
};

export const idAddUserBtn = 'addUserBtn';
export const idAddLocationBtn = 'addLocationBtn';
export const idAddScheduleBtn = 'addScheduleBtn';
export const idViewBtn = 'viewBtn';
export const idDeleteBtn = 'deleteBtn';
export const idAddContactBtn = 'addContactBtn';
export const idSuspendBtn = 'suspendBtn';
export const idUnsuspendBtn = 'unsuspendBtn';
export const idAddOrgBtn = 'addOrgBtn';
export const idEditCustomer = 'editCustomer';
export const idChangeProviderBtn = 'changeProviderBtn';

export const getClickHandler = (active, activeFunction) =>
  active ? activeFunction : () => null;
export const idClearNotificationFilterButton = 'ClearNotificationFilterButton';

export const ICON_HEIGHT = '24px';
