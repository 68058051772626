import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';
import { ADD_ICON_ID } from './constants';

const IconAdd = props => {
  return (
    <div title={props.title} className={props.className} id={ADD_ICON_ID}>
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 21 24"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="icon-add" fill={props.fill}>
            <g id="btn-add">
              <path d="M17,14 L20,14 L20,16 L17,16 L17,19 L15,19 L15,16 L12,16 L12,14 L15,14 L15,11 L17,11 L17,14 Z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconAdd.defaultProps = {
  title: 'Add',
  className: null,
  width: '21px',
  height: '24px',
  fill: ACTIVE_COLOR_DEFAULT,
};

export default IconAdd;
