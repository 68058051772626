import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash.get';

// Utilities
import * as permissions from 'util/permissions';
import ComparableFirmwareVersion from 'util/ComparableFirmwareVersion';
import { tenantSubtypeStrings } from 'util/tenantTypes';

// Actions
import * as SubscriptionActions from 'actions/subscriptions';
import * as LocationActions from 'actions/locations';
import { showMessage } from 'actions/pageMessage';
import { getLocationDevices } from 'actions/devices';

// Components
import { PageMessage } from 'containers';
import { MainContentWrapper } from 'lib';

// Constants
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { dashes, CHANGE_PROVIDER_MINIMUM_ACC_VERSION } from 'constants/app';
import * as messageTypes from 'constants/MessageTypes';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
  PATH_SUBSCRIPTIONS,
} from 'constants/urlPaths';
import ChangeProviderForm from './ProviderForm/ChangeProviderForm';

function ChangeProviderFormContainer({
  actions,
  changeFieldValue,
  devices,
  history,
  isFetchingLocationDeviceData,
  isFetchingLocations,
  isFetchingServicePackageByCode,
  locations,
  match,
  messageType,
  servicePackages,
  translate
}) {
  const { params } = match;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [claimCode, setClaimCode] = useState('');

  useEffect(() => {
    if (isFetchingLocations === null) {
      actions.getLocations();
    } else if (
      !isFetchingLocations &&
      params.subscriberId &&
      locations.length === 0
    ) {
      /* This redirect to sites is for the scenario when you are logged in as a cloud owner and navigate
      to the Change Provider form by clicking on a customer org and hit refresh.
      locations don't get loaded in the redux state by the time the form loads causing the title of the form to appear without a site name.  */
      history.push(
        `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${params.subscriberId}${PATH_SEGMENT_SITES}`,
      );
    }
    if(isFetchingLocationDeviceData === null) {
      actions.getLocationDevices(params.siteId);
    }
  }, [actions, history, isFetchingLocations, isFetchingLocationDeviceData, locations, params.subscriberId]);

  useEffect(() => {
    if (messageType === messageTypes.CHANGE_REQUEST_SUCCESS) {
      backPath();
    }
  });

  function getCurrentSite() {
    const currentLocation =
      locations.find(loc => loc.Id === params.siteId) || {};
    return currentLocation;
  }

  const backPath = () => {
    return params.subscriberId
      ? history.push(
        `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${params.subscriberId}${PATH_SEGMENT_SITES}/${params.siteId}${PATH_SUBSCRIPTIONS}`,
      )
      : history.push(
        `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}/${params.siteId}${PATH_SUBSCRIPTIONS}`,
      );
  };

  const validateCode = code => {
    if (code) {
      setClaimCode(code);
      actions.getServicePackageByCode(code);
    }
  };

  const servicePackageForCode = code => {
    let servicePackage = '';
    const currentLocation = getCurrentSite(params.siteId);
    const packageSubscription = get(currentLocation, 'PackageSubscription');
    const servicePackageId = get(packageSubscription, 'ServicePackageId');
    const packageCode = claimCode || code;
    if (packageCode !== undefined) {
      const servicePackageByCode = servicePackages.find(
        p => p.ClaimCode === packageCode,
      );
      if (
        servicePackageByCode &&
        servicePackageByCode.Id &&
        currentLocation &&
        packageSubscription &&
        servicePackageId !== servicePackageByCode.Id
      ) {
        servicePackage = servicePackageByCode;
      }
    }
    return servicePackage;
  };

  const clearServicePackageByCode = () => {
    actions.clearServicePackageByCode();
  };

  const initialValues = () => {
    const servicePackage = servicePackageForCode();

    if (servicePackage) {
      return {
        ClaimCode: servicePackage.ClaimCode,
        FixedTermLength: translate(
          'SUBSCRIPTIONS.SUBSCRIPTION_TERM_LENGTH_PLURAL',
          { month_qty: servicePackage.FixedTermLength },
        ),
        Id: servicePackage.Id,
        IsAutoRenewable: servicePackage.IsAutoRenewable
          ? translate('COMMON.YES')
          : translate('COMMON.NO'),
        Name: servicePackage.Name,
        ProviderName: servicePackage.ProviderName,
      };
    }
    if (isFetchingServicePackageByCode === null || !servicePackage) {
      return {
        ClaimCode: claimCode,
        FixedTermLength: dashes,
        IsAutoRenewable: dashes,
        Name: dashes,
        ProviderName: dashes,
      };
    }
  };

  function onCancel() {
    actions.clearServicePackageByCode();
    backPath();
  }

  const isWithMinimumACCVersion = () => {

    const servicePackage = servicePackageForCode();

    if (devices.length > 0 && servicePackage.TenantSubType === tenantSubtypeStrings.publicPrivatePartner ) {
      const currentSite = getCurrentSite();

      const devicesForSite = devices.filter(device => {
        return device.SiteId === currentSite.Id;
      });

      const minimumChangeProviderVersion = new ComparableFirmwareVersion(
        CHANGE_PROVIDER_MINIMUM_ACC_VERSION,
      );

      const devicesWithoutMinimumACCVersion = devicesForSite.filter(device => {
        if (device.AccVersion === null) {
          return device;
        } else {
          const deviceAccVersion = new ComparableFirmwareVersion(
            device.AccVersion,
          );
          if (minimumChangeProviderVersion.compare(deviceAccVersion) === 1) {
            return device;
          }
        }
      });

      return !(devicesWithoutMinimumACCVersion.length > 0);
    }
    return true;
  };

  function submitForm(formData) {
    setIsSubmitting(true);

    if (isWithMinimumACCVersion()) {
      if (formData.Id)
       actions.createChangeProviderRequest(params.siteId, formData.ClaimCode);
    } else {
      actions.showMessage(messageTypes.CHANGE_REQUEST_ERROR, null, null, {
        messageStyle: messageStyleStrings.error,
        translateBody:
          'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_PROVIDER_REQUIRED_MINIMUM_ACC_VERSION',
      });
    }
  }

  return (
    <MainContentWrapper>
      <PageMessage messageType={[messageTypes.CHANGE_REQUEST_ERROR]} />
      <ChangeProviderForm
        backPath={backPath}
        changeFieldValue={changeFieldValue}
        clearServicePackageByCode={clearServicePackageByCode}
        currentLocation={getCurrentSite()}
        initialValues={initialValues()}
        isFetchingServicePackageByCode={isFetchingServicePackageByCode}
        onCancel={onCancel}
        onSubmit={submitForm}
        servicePackageForCode={servicePackageForCode}
        servicePackages={servicePackages}
        submittingForm={isSubmitting}
        validateCode={validateCode}
      />
    </MainContentWrapper>
  );
}

ChangeProviderFormContainer.defaultProps = {
  history: [],
  isFetchingLocations: null,
  isFetchingServicePackageByCode: null,
  isFetchingLocationDeviceData: null,
  locations: [],
  match: {},
  messageType: '',
  servicePackages: [],
};

ChangeProviderFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.any),
  isFetchingLocations: PropTypes.bool,
  isFetchingLocationDeviceData: PropTypes.bool,
  isFetchingServicePackageByCode: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.objectOf(PropTypes.any),
  messageType: PropTypes.string,
  servicePackages: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const orgId = permissions.getOrgIdFromStore(state);

  return {
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingLocationDeviceData:
      state.devices.isFetchingLocationDeviceData[ownProps.match.params.siteId],
    isFetchingServicePackageByCode: state.isFetching.getServicePackageByCode,
    devices: state.devices.devices,
    locations: state.locations.locations,
    messageType: state.pageMessage.messageType,
    orgId,
    servicePackages: state.locations.servicePackages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...SubscriptionActions,
        ...LocationActions,
        getLocationDevices,
        showMessage
      },
      dispatch,
    ),
    changeFieldValue: (field, value) => {
      dispatch(change('changeProviderForm', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withLocalize(ChangeProviderFormContainer)));
