/* Many tables join together data from more than one API call. This
seems like as good a place as any to keep those functions. Perhaps they
should move into the table components later. */

import Moment from 'moment';
import { unlimitedCameras } from 'constants/app';
import { getSubscriberDatesFormat } from 'util/convertTimeTo';
import { getSubscriptionState } from './validation';

const CAMERA_RESOURCE_TYPE = 1;

export const locationTableData = (locations = [], servicePackages = [], translate) => {
  /* inject into the locations array, data from the subscriptions and
   * servicePackages array
   */
  const emptySub = { BillingCycleId: 'N/a', ServicePackageId: null };
  const emptyPackage = {
    Grants: [],
    Name: translate('LOCATIONS.EMPTY_SUBSCRIPTION_LOCATIONS'),
  };
  const emptyGrant = {};
  return locations.map(location => {
    const sub = location.PackageSubscription || emptySub;
    const pkg =
      servicePackages.find(x => x.Id === sub.ServicePackageId) || emptyPackage;
    const grant =
      pkg.Grants.find(x => x.ResourceType === CAMERA_RESOURCE_TYPE) ||
      emptyGrant;
    let term = 'N/a';
    if (sub.TermLength) {
      term = translate(
        `SUBSCRIPTIONS.SUBSCRIPTION_TERM_LENGTH${
          sub.TermLength > 1 ? '_PLURAL' : ''
        }`,
        { month_qty: sub.TermLength },
      );
    }

    const translatedCameras = () => {
      if (translate && grant.Quantity && grant.Quantity !== unlimitedCameras) {
        return translate('CAMERA.CAMERAS_QUANTITY', {
          camerasQnty: location.CameraCount,
          camerasTotalQnty: grant.Quantity,
        });
      }

      return (
        location.CameraCount +
        (grant.Quantity && grant.Quantity !== unlimitedCameras
          ? ` of ${grant.Quantity}`
          : '')
      );
    };

    return {
      ...location,
      /* Flattened from subscription */
      ActivationDate: sub.TermStartDate
        ? Moment(sub.TermStartDate).format(getSubscriberDatesFormat())
        : '',
      BillingCycleId: sub.BillingCycleId,
      Cameras: translatedCameras(),
      DealerId: sub.DealerId,
      DealerMonitoringEnabled: sub.RemoteHealthMonitoringEnabled,
      OrgId: sub.TenantId,
      RenewalDate: sub.TermEndDate
        ? Moment(sub.TermEndDate).format(getSubscriberDatesFormat())
        : '',
      ServicePackageId: sub.ServicePackageId,
      /* injected from service pkg */
      Subscription: pkg.Name,
      SubscriptionTermLength: `${sub.TermLength || ''}`, // Cast to string
      Term: term,
      subscriptionState: getSubscriptionState(location.PackageSubscription),
    };
  });
};

export const extractItemCountFromMap = map => {
  if (map) {
    return Object.keys(map).reduce((total, key) => total + map[key].length, 0);
  }
  return 0;
};
