// enum for # of items in a layout
export const layoutItemCount = enumValue => {
  switch (enumValue) {
    case 0:
      return 1;
    case 1:
      return 2;
    case 2:
      return 4;
    case 3:
      return 9;
    case 4:
      return 3;
    case 5:
      return 6;
    default:
      return 1;
  }
};

/**
 * Returns View Layout number based on number of screens.
 * @param {number} itemCount
 */
export const itemCountToLayout = itemCount => {
  switch (itemCount) {
    case 1:
      return 0;
    case 2:
      return 1;
    case 3:
      return 4;
    case 4:
      return 2;
    case 5:
    case 6:
      return 5;
    default:
      return 3;
  }
};
