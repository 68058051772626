import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Translate } from 'react-localize-redux';

// Styles
import {
  disabled,
  filterTextInput,
  inputFilter,
  searchFilterInput,
} from './styles.css';

class InputFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  onChangeDebounced = debounce(value => {
    const { field, isInput, onChange } = this.props;
    let valueArr;
    if (!value || value.length === 0 || value === '') {
      if (isInput) {
        valueArr = null;
      } else {
        valueArr = 'All';
      }
    } else {
      valueArr = [value];
    }
    onChange(field, valueArr);
  }, 360);

  handleFilterChanged = e => {
    // Normalization
    // return an array of values
    // or the string 'All' when the filter is cleared.
    const { value } = e.target;
    this.setState({ value });
    this.onChangeDebounced(value);
  };

  render() {
    const { className, disabled: isDisabled, id, placeholder } = this.props;
    const { value } = this.state;
    return (
      <div className={className !== '' ? className : searchFilterInput}>
        <div className={filterTextInput + (isDisabled ? ` ${disabled}` : '')}>
          <Translate>
            {({ translate }) => (
              <input
                id={id}
                onChange={isDisabled ? () => null : this.handleFilterChanged}
                placeholder={placeholder ? translate(placeholder) : ''}
                type="text"
                value={value}
              />
            )}
          </Translate>
        </div>
      </div>
    );
  }
}

InputFilter.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  id: PropTypes.string,
  isInput: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMount: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

InputFilter.defaultProps = {
  className: '',
  disabled: false,
  id: 'idInputFilter',
  isInput: false,
  onMount: null,
  placeholder: '',
  value: '',
};

export default InputFilter;
