import normalizeCamera from 'util/normalizeCamera';

export const isAnalyticCamera = camera => {
  if (!camera) return null;
  const normalizedCamera = normalizeCamera(camera);
  return (
    normalizedCamera.Capabilities.analytic &&
    normalizedCamera.Capabilities.analytic.RULE_OBJECT_PRESENT
  );
};
