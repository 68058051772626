import React from 'react';
import PropTypes from 'prop-types';
import { Button, GroupLayout } from 'lib';
import { ModalContainer } from 'containers';
import { withLocalize } from 'react-localize-redux';
import { OK_BUTTON_ID } from 'constants/ElementId';
import {
  emphasizedTextWrap,
  modalContentConfirm,
  textWrap,
} from './styles.css';

// Constants

const PromptModal = ({
  bottomText,
  buttonText,
  emphasizedText,
  hideModal,
  title,
  topText,
  translate,
}) => (
  <ModalContainer handleCancel={hideModal} modalTitle={translate(title)}>
    <div className={modalContentConfirm}>
      <div className={textWrap}>
        <div>{translate(topText)}</div>
        <div className={emphasizedTextWrap}>{emphasizedText}</div>
        <div>{translate(bottomText)}</div>
      </div>
      <GroupLayout horizontalPositioning="center" verticalSpacing="large">
        <Button
          key="okButton"
          id={OK_BUTTON_ID}
          onClick={hideModal}
          text={translate(buttonText)}
        />
      </GroupLayout>
    </div>
  </ModalContainer>
);

PromptModal.propTypes = {
  bottomText: PropTypes.string,
  buttonText: PropTypes.string,
  emphasizedText: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  topText: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

PromptModal.defaultProps = {
  bottomText: '',
  buttonText: 'BUTTONS.CLOSE',
  emphasizedText: '',
  title: '',
  topText: '',
};

export default withLocalize(PromptModal);
