import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function modalReducer(
  state = initialState().modal,
  action = {},
) {
  switch (action.type) {
    case types.ADD_CAMERA_GROUP_SUCCESS:
    case types.SHOW_MODAL:
      return {
        isOpen: true,
        modalProps: action.modalProps,
        modalType: action.modalType,
      };

    case types.CLOSE_MODAL:
      return initialState().modal;

    default:
      return state;
  }
}
