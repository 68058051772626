/* global avoLogError */

// Libs
import urlBuilder from 'queryBuilder/url';
import * as types from 'constants/ActionTypes';
// Consts
import { sendGetRequestReturningJSON } from '../util/fetchHelpers';
import { isFetching } from './common';

// Redux action creators

export function receiveDealerBillingCycles(dealerBillingCycles) {
  return dispatch => {
    dispatch({
      type: types.RECEIVE_DEALER_BILLING_CYCLES,
      dealerBillingCycles,
    });
  };
}

export function setCurrentBillingCycle(cycleId) {
  return dispatch => {
    dispatch({
      type: types.SET_CURRENT_BILLING_CYCLE,
      cycleId,
    });
  };
}

export function receiveDealerSubscriberStats(dealerSubscriberStats) {
  return {
    type: types.RECEIVE_DEALER_SUBSCRIBER_STATS,
    dealerSubscriberStats,
  };
}

export function receiveDealerSubscriptionLevels(subscriptionLevels) {
  return {
    type: types.RECEIVE_DEALER_SUBSCRIPTION_LEVELS,
    subscriptionLevels,
  };
}

export function receiveDealerSubscriptionRenewalPeriods(subscriptionLevels) {
  return {
    type: types.RECEIVE_SUBSCRIPTION_RENEWAL_PERIODS,
    subscriptionLevels,
  };
}

export function receiveDealerSubscriberUpgradeSites(
  dealerSubscriberUpgradeSites,
) {
  return {
    type: types.RECEIVE_DEALER_SUBSCRIBER_UPGRADE_SITES,
    dealerSubscriberUpgradeSites,
  };
}

export function receiveDealerSubscribersNearingRenewal(
  dealerSubscribersNearingRenewal,
) {
  return {
    type: types.RECEIVE_DEALER_SUBSCRIBERS_NEARING_RENEWAL,
    dealerSubscribersNearingRenewal,
  };
}

// Async action creators

export function getDealerBillingCycles() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEALER_BILLING_CYCLES));
    const url = urlBuilder(types.GET_DEALER_BILLING_CYCLES);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerBillingCycles(json));
      })
      .catch(e => {
        dispatch(isFetching(types.IS_FETCHING_DEALER_BILLING_CYCLES, false));
        avoLogError('Error getting dealer billing cycles', e);
      });
  };
}

export function getDealerSubscriberStats(billingCycleId = null) {
  // TODO: Align hard coded with real data, then point this at an actual endpoint.
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEALER_SUBSCRIBER_STATS));
    let url;
    if (billingCycleId) {
      url = urlBuilder(types.GET_DEALER_SUBSCRIBER_STATS, null, null, {
        other: [{ key: 'billingCycleId', value: billingCycleId }],
      });
    } else {
      url = urlBuilder(types.GET_DEALER_SUBSCRIBER_STATS);
    }

    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerSubscriberStats(json));
      })
      .catch(e => {
        dispatch(isFetching(types.IS_FETCHING_DEALER_SUBSCRIBER_STATS, false));
        avoLogError('Error getting dealer subscriber stats', e);
      });
  };
}

export function getDealerSubscriptionLevels(billingCycleId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEALER_SUBSCRIPTION_LEVELS));
    let url;
    if (billingCycleId) {
      url = urlBuilder(types.GET_DEALER_SUBSCRIPTION_LEVELS, null, null, {
        other: [{ key: 'billingCycleId', value: billingCycleId }],
      });
    } else {
      url = urlBuilder(types.GET_DEALER_SUBSCRIPTION_LEVELS);
    }
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerSubscriptionLevels(json));
      })
      .catch(e => {
        dispatch(
          isFetching(types.IS_FETCHING_DEALER_SUBSCRIPTION_LEVELS, false),
        );
        avoLogError('Error getting dealer subscription levels', e);
      });
  };
}

export function getDealerSubscriptionRenewalPeriods(billingCycleId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_SUBSCRIPTION_RENEWAL_PERIODS));
    let url;
    if (billingCycleId) {
      url = urlBuilder(types.GET_SUBSCRIPTION_RENEWAL_PERIODS, null, null, {
        other: [{ key: 'billingCycleId', value: billingCycleId }],
      });
    } else {
      url = urlBuilder(types.GET_SUBSCRIPTION_RENEWAL_PERIODS);
    }
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerSubscriptionRenewalPeriods(json));
      })
      .catch(e => {
        dispatch(
          isFetching(types.IS_FETCHING_SUBSCRIPTION_RENEWAL_PERIODS, false),
        );
        avoLogError('Error getting dealer subscription renewal preiods', e);
      });
  };
}

export function getDealerSubscriberUpgradeSites() {
  return dispatch => {
    // TODO: Align hard coded with real data, then point this at an actual endpoint.
    dispatch(isFetching(types.IS_FETCHING_DEALER_SUBSCRIBER_UPGRADE_SITES));
    // const url = formatUrl();
    const url = urlBuilder(types.GET_DEALER_SUBSCRIBER_UPGRADE_SITES);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerSubscriberUpgradeSites(json));
      })
      .catch(e => {
        dispatch(
          isFetching(types.IS_FETCHING_DEALER_SUBSCRIBER_UPGRADE_SITES, false),
        );
        avoLogError('Error getting dealer subscriber upgrade sites', e);
      });
  };
}

export function getDealerSubscribersNearingRenewal() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEALER_SUBSCRIBERS_NEARING_RENEWAL));
    const url = urlBuilder(types.GET_DEALER_SUBSCRIBERS_NEARING_RENEWAL);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveDealerSubscribersNearingRenewal(json));
      })
      .catch(e => {
        dispatch(
          isFetching(
            types.IS_FETCHING_DEALER_SUBSCRIBERS_NEARING_RENEWAL,
            false,
          ),
        );
        avoLogError('Error getting dealer subscribers nearing renewal', e);
      });
  };
}
