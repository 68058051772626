import { DEVICE_SETTINGS } from 'constants/deviceSettings';
import {
  PATH_SEGMENT_AUDIO,
  PATH_SEGMENT_DIGITAL_IO,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_PRIVACY,
  PATH_SEGMENT_RULES,
  PATH_SEGMENT_TAMPERING,
  PATH_SEGMENT_VIDEO,
} from 'constants/urlPaths';

export const AUDIO_CONTENT_ID = 'AudioContent';
export const TAMPERING_CONTENT_ID = 'TamperingContent';
export const TAMPERING_KEYS = {
  buttonGroup: 'tampering-panel-buttonGroup',
  buttons: 'tampering-panel-buttons',
  settings: 'tampering-panel-settings',
  statusMessage: 'tampering-panel-status-message',
  tamperingSettings: 'tampering-camera-settings',
};

export const TAMPERING_BUTTONS = {
  restore: {
    id: 'tamperingSettingsButtonRestore',
    key: 'tamperingSettingsButtonRestore',
  },

  revert: {
    id: 'tamperingSettingsButtonRevert',
    key: 'tamperingSettingsButtonRevert',
  },
  save: {
    id: 'tamperingSettingsButtonSave',
    key: 'tamperingSettingsButtonSave',
  },
};

export const TAMPERING_DEFAULT_VALUE = {
  delay: 8,
  sensitivity: 8,
};

const TABS = DEVICE_SETTINGS.GENERIC.LABELS;

export const allTabs = [
  {
    id: TABS.GENERAL,
    label: 'CAMERA.SETTINGS.TABS.GENERAL_LABEL',
    path: PATH_SEGMENT_GENERAL,
  },
  {
    id: TABS.RULES,
    label: 'CAMERA.SETTINGS.TABS.RULES_LABEL',
    path: PATH_SEGMENT_RULES,
  },
  {
    id: TABS.PRIVACY,
    label: 'CAMERA.SETTINGS.TABS.PRIVACY_LABEL',
    path: PATH_SEGMENT_PRIVACY,
  },
  {
    id: TABS.TAMPERING,
    label: 'CAMERA.SETTINGS.TABS.TAMPERING_LABEL',
    path: PATH_SEGMENT_TAMPERING,
  },
  {
    id: TABS.DIGITAL_OUT,
    label: 'CAMERA.SETTINGS.TABS.DIGITAL_OUT_LABEL',
    path: PATH_SEGMENT_DIGITAL_IO,
  },
  {
    id: TABS.AUDIO,
    label: 'CAMERA.SETTINGS.TABS.AUDIO_LABEL',
    path: PATH_SEGMENT_AUDIO,
  },
  {
    id: TABS.VIDEO,
    label: 'CAMERA.SETTINGS.TABS.VIDEO_LABEL',
    path: PATH_SEGMENT_VIDEO,
  },
];
