// Libs
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';

// Actions
import * as UserActions from 'actions/user';
import * as LocationActions from 'actions/locations';

// Components
import { ROLES_KEYS } from 'constants/app';
import { PATH_ACCOUNTS, PATH_SEGMENT_USERS } from 'constants/urlPaths';
import UserForm from './UserForm';

// Constants
import * as consts from './constants';

// Helper functions
const getEditUserProps = (selectedUser, translate) => {
  const getTranslatedStatus = role => {
    return translate(`STATUS.${role.toUpperCase()}`);
  };
  return {
    ...selectedUser,
    Role: selectedUser.Role,
    Status: getTranslatedStatus(selectedUser.Status),
  };
};

const getPolicyDefault = (user, schedules) => {
  const scheduleValues = schedules;
  const schedule = scheduleValues.find(sched => {
    if (user.UserType === consts.proxy) {
      return sched.Name === consts.scheduleNever;
    }
    return sched.Name === consts.scheduleAlways;
  });

  return [
    {
      HealthScheduleId: schedule ? schedule.Id : null,
      SecurityScheduleId: schedule ? schedule.Id : null,
    },
  ];
};

const UserFormContainer = props => {
  const {
    actions,
    canAccessUserRoles,
    canEditProxySiteAccess,
    hasRemoteUsers,
    history,
    isFetchingLocations,
    isFetchingSchedules,
    isFetchingUsers,
    locations,
    queryOptions,
    schedules,
    showSites,
    translate,
    userId,
    users,
  } = props;

  useEffect(() => {
    if (isFetchingUsers === null) {
      actions.getUsers();
    }
    if (isFetchingLocations === null) {
      actions.getLocations();
    }
    if (isFetchingSchedules === null) {
      actions.getSchedules();
    }
  }, [actions, isFetchingUsers, isFetchingLocations, isFetchingSchedules]);

  const initialValues = useMemo(() => {
    let selectedUser = userId && users.find(u => u.Id === userId);

    if (selectedUser) {
      selectedUser = getEditUserProps(selectedUser, translate);
    }

    return {
      EmailNotification: false,
      NewSiteAccess: false,
      Policies: getPolicyDefault({}, schedules),
      Role: ROLES_KEYS.Administrator.value,
      ...selectedUser,
    };
  }, [schedules, translate, userId, users]);

  const [submittingForm, setSubmittingForm] = useState(false);

  function repairPolicy(policy) {
    const resultPolicy = [];
    if (policy) {
      policy.forEach(element => {
        if (
          element.GroupId &&
          element.SecurityScheduleId &&
          element.HealthScheduleId
        ) {
          resultPolicy.push(element);
        }
      }, this);
    }
    return resultPolicy;
  }

  function submitForm(values) {
    setSubmittingForm(true);
    const userData = {
      CanManageCustomerAccounts: true,
      EmailAddress: values.EmailAddress,
      LastName: null,
      ...values,
    };
    userData.Policies = repairPolicy(userData.Policies);
    if (values.Id) {
      actions.editUser(userData, queryOptions);
    } else {
      actions.addUser(userData, queryOptions);
      history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_USERS}`);
    }
    setSubmittingForm(false);
  }

  function resendInvite() {
    actions.resendInvite(userId);
    actions.hideModal();
  }

  function resetPassword() {
    actions.resetPassword(userId);
    actions.hideModal();
  }

  return (
    <UserForm
      canAccessUserRoles={canAccessUserRoles}
      canEditProxySiteAccess={canEditProxySiteAccess}
      hasRemoteUsers={hasRemoteUsers}
      initialValues={initialValues}
      isEditing={userId !== 'new'}
      locations={locations}
      onSubmit={submitForm}
      resendInvite={resendInvite}
      resetPassword={resetPassword}
      schedules={schedules}
      showSites={showSites}
      submittingForm={submittingForm}
    />
  );
};

UserFormContainer.defaultProps = {
  actions: {},
  canEditProxySiteAccess: false,
  hasRemoteUsers: false,
  initialValues: {},
  isFetchingLocations: null,
  isFetchingSchedules: null,
  isFetchingUsers: null,
  locations: [],
  queryOptions: {},
  schedules: [],
  showSites: true,
  users: [],
};

UserFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  canAccessUserRoles: PropTypes.bool.isRequired,
  canEditProxySiteAccess: PropTypes.bool,
  hasRemoteUsers: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  initialValues: PropTypes.shape({}),
  isFetchingLocations: PropTypes.bool,
  isFetchingSchedules: PropTypes.bool,
  isFetchingUsers: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  queryOptions: PropTypes.shape({}),
  schedules: PropTypes.arrayOf(PropTypes.shape({})),
  showSites: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
};

function mapStateToProps(state) {
  return {
    canAccessUserRoles: state.user.permissions.CAN_HAVE_NON_ADMIN_USERS,
    canEditProxySiteAccess: state.user.permissions.CAN_EDIT_PROXY_SITE_ACCESS,
    canEditSiteAccess: state.user.permissions.CAN_EDIT_SITE_ACCESS,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingSchedules: state.isFetching.getSchedules,
    isFetchingUsers: state.isFetching.getUsers,
    locations: state.locations.locations,
    schedules: state.user.schedules,
    showSchedules: state.user.permissions.CAN_VIEW_SCHEDULES_COLUMNS,
    showSites: state.user.permissions.CAN_VIEW_SITES,
    users: state.user.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...LocationActions, ...UserActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(UserFormContainer));
