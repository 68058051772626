// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

// Styles
import { pendingRequestColoredDot } from 'sharedStyles/styles.css';
import { navMenu, navMenuItem, navMenuItemActive } from './NavTabs.css';

// Consts
const navTabsKey= 'NavTabs';
const navLinkKey = 'NavLink_';
const navClue= 'navHasPendingClue_';

const NavTabs = ({ className, tabs, translate, translateLabels }) => (
  <div key={navTabsKey} className={`${className} ${navMenu}`}>
    {tabs.map(({ exact, hasPendingClue, id, key, label, path }) => (
      <div key={`${navLinkKey}${key || id || label}`}>
        <NavLink
          key={key || id || label}
          activeClassName={navMenuItemActive}
          className={navMenuItem}
          exact={exact}
          id={id}
          title={translateLabels ? translate(label) : label}
          to={path}
        >
          {translateLabels ? translate(label) : label}
          {hasPendingClue ? <div  key={`${navClue}${key || id || label}`} className={pendingRequestColoredDot} /> : null}
        </NavLink>
      </div>
    ))}
  </div>
);

NavTabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      exact: PropTypes.bool,
      id: PropTypes.string,
      key: PropTypes.string,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  ).isRequired,
  translate: PropTypes.func.isRequired,
  translateLabels: PropTypes.bool,
};

NavTabs.defaultProps = {
  className: '',
  translateLabels: false,
};

export default withLocalize(NavTabs);
