import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { Translate } from 'react-localize-redux';

// Constant
import * as barConstants from './constants';

// Style
import * as styles from './styles.css';

const ProgressBar = props => {
  const { label, leftValue, name, showPercentage, totalValue } = props;
  const labelClassName = `${styles.barColumn} ${styles.barText}`;
  const width = (leftValue * 100) / totalValue;
  const percentageStyles =
    width > 8 ? styles.barPercent : styles.barPercentOutside;
  return (
    <div className={styles.barRow} id={barConstants.idRow + name}>
      <div className={labelClassName} id={barConstants.idLabel + name}>
        <Translate
          id={`PROGRESS_BAR.PROGRESS_BAR_LABEL.${name.toUpperCase()}`}
        />
      </div>
      <div
        className={`${styles.barColumn} ${styles.bar}`}
        id={barConstants.idPoEBar + name}
      >
        <div
          className={`${styles.barProgress} ${styles[name.toLowerCase()]}`}
          style={{
            maxWidth: '100%',
            position: 'relative',
            width: totalValue === 0 ? totalValue : `${width}%`,
          }}
        >
          {showPercentage && (
            <span className={percentageStyles}>{`${width.toFixed(2)}%`}</span>
          )}
        </div>
      </div>
      <div
        className={`${labelClassName} ${styles.barTextSummary}`}
        id={barConstants.idSummary + name}
      >
        {label}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  label: PropTypes.element.isRequired,
  leftValue: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  showPercentage: PropTypes.bool,
  totalValue: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  showPercentage: false,
};

export default ProgressBar;
