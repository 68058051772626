// Libs
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useRecoilState } from 'recoil';
import { flags as flagsAtom, showFilters as showFiltersAtom } from 'atoms';

// Actions
import * as PersistentFilterActions from 'actions/persistentFilters';

// Components
import ListView from '../ListView/ListView';

function AvoListView(props) {
  const {
    actions,
    clickableRowPrompt,
    headerLabels,
    headerTranslationIds,
    persistFilterAs,
    persistentFilters,
    translate,
  } = props;

  const [flags] = useRecoilState(flagsAtom);
  const [showFilters] = useRecoilState(showFiltersAtom);

  const derrivedHeaderLabels = headerLabels;
  let defaultFilterText = '';
  if (
    persistFilterAs &&
    persistentFilters &&
    persistentFilters[persistFilterAs]
  ) {
    defaultFilterText = persistentFilters[persistFilterAs];
  }
  if (headerTranslationIds) {
    Object.keys(headerTranslationIds).forEach(fieldName => {
      derrivedHeaderLabels[fieldName] = translate(
        headerTranslationIds[fieldName],
      );
    });
  }

  function persistFilter(filterName, filterText) {
    const { actions } = props;
    actions.persistFilter(filterName, filterText);
  }

  return (
    <ListView
      persistentFilterCallback={persistFilter}
      {...props}
      clickableRowPrompt={clickableRowPrompt}
      defaultFilterText={defaultFilterText}
      headerLabels={derrivedHeaderLabels}
      inlineDetails={flags.inlineDetailsOn || false}
      resizable={flags.resizable || false}
      infiniteScrollLoadingText={translate('GENERAL_MESSAGES.LOADING_MORE')}
      mobileTouchCards={flags.mobileTouchCards || false}
      noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
      showFilters={showFilters}
      switchListViewType={flags.switchListViewType || false}
      xray={flags.xray || false}
    />
  );
}

AvoListView.defaultProps = {
  clickableRowPrompt: '',
  headerLabels: {},
  headerTranslationIds: {},
  persistFilterAs: null,
};

AvoListView.propTypes = {
  clickableRowPrompt: PropTypes.string,
  headerLabels: PropTypes.objectOf(PropTypes.string),
  headerTranslationIds: PropTypes.objectOf(PropTypes.string),
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    persistentFilters: state.persistentFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...PersistentFilterActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(AvoListView));
