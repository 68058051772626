import Moment from 'moment';

import store from 'store/configureStore'; // Not worth fixing; this file is only used to support the deprecated EVO player.

import * as types from 'constants/ActionTypes';

export const COMMAND_FAIL_RETRIES = 3;
const RESPONSE_COMMAND_STRING = 'response';
const REQUEST_COMMAND_STRING = 'request';
const COMMAND_TYPE_STRING = 'command';
const STATUS_ERROR_STRING = 'error';
const BASE64_ENCODING_STRING = 'base64';
const COMMAND_CHANNEL_TYPE_STRING = 'emsCommandChannel';
const RESPONSE_TIMEOUT_MILLISECONDS = 200;

const isErrorResponse = argument => {
  return argument.status === STATUS_ERROR_STRING;
};

const extractResponseFromData = data => {
  if (
    !data ||
    !data.argument ||
    !data.command ||
    data.command !== RESPONSE_COMMAND_STRING
  ) {
    return null;
  }

  const argument = data.base64
    ? JSON.parse(atob(data.argument))
    : data.argument;

  const commandId = argument.id;

  if (isErrorResponse(argument)) {
    delete argument.id;
    const updatedArgument = Object.assign({}, argument, { commandId });
    return updatedArgument;
  }

  const { route } = argument;

  const payload =
    argument.data.contentEncoding === BASE64_ENCODING_STRING
      ? JSON.parse(atob(argument.data.payload))
      : argument.data.payload;

  return { commandId, route, payload };
};

export const webRtcResponseHandler = data => {
  const response = extractResponseFromData(data);
  store.dispatch({ type: types.RECEIVED_WEBRTC_RESPONSE, response });
};

const buildCommandArgument = (route, query) => {
  const commandId = Moment().valueOf();

  const argument = {
    id: commandId,
    route,
    query,
  };

  return argument;
};

class WebRtcHelper {
  constructor(player) {
    this.player = player;
  }

  sendDirectlyToAll = argument => {
    if (this.player && this.player.webrtc) {
      this.player.webrtc.sendDirectlyToAll(
        COMMAND_CHANNEL_TYPE_STRING,
        COMMAND_TYPE_STRING,
        {
          command: REQUEST_COMMAND_STRING,
          argument,
          base64: 0,
        },
      );
    }
  };

  sendCommand = ({
    dispatch: initialDispatch,
    route,
    query,
    serverId,
    cameraId,
  }) => {
    if (!route || !query) {
      avoLogError(
        'route and query are required parameters for sendWebRtcCommand',
      );
      return;
    }
    const { dipatch: storeDispatch } = store;
    const dispatch = initialDispatch || storeDispatch;
    const argument = buildCommandArgument(route, query);
    const commandId = argument.id;
    this.sendDirectlyToAll(argument);

    dispatch({
      type: types.SENT_WEBRTC_REQUEST,
      serverId,
      cameraId,
      commandId,
      route,
      query,
    });

    setTimeout(() => {
      const previousCommandId = commandId;
      const failedRequest = store.getState().devices.webRtcRequests[
        previousCommandId
      ];
      if (failedRequest) {
        this.retryCommand({ dispatch, route, query, previousCommandId });
      }
    }, RESPONSE_TIMEOUT_MILLISECONDS);
  };

  retryCommand = ({
    dispatch: initialDispatch,
    route,
    query,
    previousCommandId,
  }) => {
    const { dipatch: storeDispatch } = store;
    const dispatch = initialDispatch || storeDispatch;

    const argument = buildCommandArgument(route, query);
    const commandId = argument.id;
    this.sendDirectlyToAll(argument);

    dispatch({
      type: types.RETRY_WEBRTC_REQUEST,
      route,
      query,
      commandId,
      previousCommandId,
    });

    setTimeout(() => {
      const failedRequest = store.getState().devices.webRtcRequests[commandId];
      if (failedRequest) {
        if (failedRequest.remainingTries <= 0) {
          dispatch({
            type: types.REMOVE_FAILED_WEBRTC_REQUEST,
            failedCommandId: commandId,
          });
        } else {
          this.retryCommand({ dispatch, route, query, commandId });
        }
      }
    }, RESPONSE_TIMEOUT_MILLISECONDS);
  };
}

export default WebRtcHelper;
