import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig, INSTRUMENTATION_KEY) {
    try {
      this.appInsights = new ApplicationInsights({
        config: {
          disableFetchTracking: false,
          extensionConfig: {
            [this.reactPlugin.identifier]: reactPluginConfig,
          },
          extensions: [this.reactPlugin],
          instrumentationKey: INSTRUMENTATION_KEY,
        },
      });
      this.appInsights.loadAppInsights();
    } catch (e) {
      this.appInsights = {
        trackEvent: () => {},
        trackPageView: () => {},
        trackTrace: () => {},
      };
    }
  }
}

export const ai = new TelemetryService();
