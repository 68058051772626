/* Add this fragment to a redux form only if that form will use the action addUserIfNeeded
All fields referenced by addUserIfNeeded will be generated by this fragment
However, the parent form must handle updating the selectedId prop which determines
whether static data or input fields are displayed; and if the dropdown is to be
prepopulated, the parent form must have an AdminUserId in its initialValues object */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Field } from 'redux-form';

import { FieldInput } from 'components/FormElements';
import { FieldSelect, FormGroup } from 'lib';
import * as validation from 'util/validation';
// Containers
import TimeZoneSelect from 'containers/TimeZoneSelect/TimeZoneSelectContainer';

// Actions
import { getUsers, getUsersForTenant } from 'actions/user';

// Utilities
import { Translate } from 'react-localize-redux';
import { parseUserRoles, ROLES, STATUS_KEYS } from 'util/userRoles';

// Styles
import {
  formReadOnlyText,
  horizontalDivider,
} from 'sharedStyles/modalForms.css';
import { formField } from './styles.css';

// Constants
import * as consts from './constants';

const { USER_FORM_FIELDS } = consts;

const ReadOnlyData = props => {
  const { text } = props;
  return <div className={formReadOnlyText}>{text}</div>;
};

ReadOnlyData.defaultProps = {
  text: '',
};

ReadOnlyData.propTypes = {
  text: PropTypes.string,
};

const UserDisplay = props => {
  const { selectedId } = props;
  return selectedId === consts.adminUserNew ? (
    <UserForm {...props} />
  ) : (
    <UserReadOnly {...props} />
  );
};

UserDisplay.propTypes = {
  selectedId: PropTypes.string.isRequired,
};

const UserReadOnly = props => {
  const { data } = props;
  return (
    <div>
      {Object.keys(USER_FORM_FIELDS)
        .filter(
          key =>
            USER_FORM_FIELDS[key] &&
            USER_FORM_FIELDS[key].userObjectField &&
            data[USER_FORM_FIELDS[key].userObjectField],
        )
        .map(key => (
          <FormGroup
            key={USER_FORM_FIELDS[key].key}
            label={<Translate id={USER_FORM_FIELDS[key].label} />}
          >
            <ReadOnlyData text={data[USER_FORM_FIELDS[key].userObjectField]} />
          </FormGroup>
        ))}
    </div>
  );
};

UserReadOnly.defaultProps = {
  data: {},
};
UserReadOnly.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

const UserForm = props => {
  const { excludeFields, translate, users } = props;
  return (
    <div>
      {Object.keys(USER_FORM_FIELDS)
        .filter(
          key =>
            USER_FORM_FIELDS[key] &&
            !USER_FORM_FIELDS[key].select && // Skip dropdown inputs
            !excludeFields.includes(USER_FORM_FIELDS[key].name), // Skip any requested exclusions
        )
        .map(key => (
          <FormGroup
            key={USER_FORM_FIELDS[key].key}
            keyName={USER_FORM_FIELDS[key].key}
            label={translate(USER_FORM_FIELDS[key].label)}
          >
            <Field
              className={formField}
              component={FieldInput}
              name={USER_FORM_FIELDS[key].name}
              normalize={
                USER_FORM_FIELDS[key].normalize &&
                USER_FORM_FIELDS[key].normalize
              }
              placeholder={
                USER_FORM_FIELDS[key].placeholder &&
                translate(USER_FORM_FIELDS[key].placeholder)
              }
              translate
              users={users}
              validate={USER_FORM_FIELDS[key].validate}
            />
          </FormGroup>
        ))}
      {!excludeFields.includes(USER_FORM_FIELDS.timezone.name) && (
        <FormGroup
          key={USER_FORM_FIELDS.timezone.key}
          keyName={USER_FORM_FIELDS.timezone.key}
          label={<Translate id={USER_FORM_FIELDS.timezone.label} />}
        >
          <Field
            className={formField}
            component={TimeZoneSelect}
            isNewVersion
            name={USER_FORM_FIELDS.timezone.name}
            translate
            validate={validation.required}
          />
        </FormGroup>
      )}
    </div>
  );
};

UserForm.defaultProps = {
  excludeFields: [],
  users: [],
};

UserForm.propTypes = {
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  translate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
};

class AddCreateUser extends Component {
  componentDidMount() {
    const { actions, initialValues, isFetchingUsers, tenantId } = this.props;
    if (!isFetchingUsers && tenantId) {
      actions.getUsersForTenant(tenantId);
    } else if (isFetchingUsers === null) {
      actions.getUsers();
    }
    if (!initialValues[USER_FORM_FIELDS.id.name]) {
      // Warn if no initial AdminUserId field is provided
      avoLogError(consts.missingAdminIdField, {});
    }
  }

  currentAdmin = () => {
    const { selectedId, users } = this.props;
    if (selectedId === consts.adminUserNew) {
      return {};
    }
    const user = users.find(u => u.Id === selectedId);
    return user && user.Id ? user : {};
  };

  filterUser = user => {
    const { showUserRoles } = this.props;
    return (
      user.UserType !== 'Proxy' &&
      (user.publicDataSubset === true ||
        user.Status !== STATUS_KEYS.Disabled.value) &&
      showUserRoles.includes(parseUserRoles(user.Role))
    );
  };

  render() {
    const {
      allowCreate,
      disabled,
      excludeFields,
      idUser,
      label,
      selectedId,
      translatedLabel,
      users,
      validate,
    } = this.props;
    const displayData = this.currentAdmin();
    return (
      <div>
        <FormGroup
          id={idUser}
          label={label || <Translate id={translatedLabel} />}
        >
          <Field
            className={formField}
            component={FieldSelect}
            disabled={disabled}
            isNewVersion
            name={USER_FORM_FIELDS.id.name}
            translate
            validate={validate}
          >
            {allowCreate && (
              <Translate>
                {({ translate }) => (
                  <option key={-1} value={consts.adminUserNew}>
                    {translate('USERS.USERS_ACTIONS.ADD_NEW_USER')}
                  </option>
                )}
              </Translate>
            )}
            {users.filter(this.filterUser).map(u => (
              <option key={u.Id} value={u.Id}>
                {u.LastName}
                {','} {u.FirstName}
              </option>
            ))}
          </Field>
        </FormGroup>
        <div className={horizontalDivider} />
        <Translate>
          {({ translate }) => (
            <UserDisplay
              data={displayData}
              excludeFields={excludeFields}
              selectedId={selectedId}
              translate={translate}
              validate={validate}
            />
          )}
        </Translate>
      </div>
    );
  }
}

AddCreateUser.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  allowCreate: PropTypes.bool,
  disabled: PropTypes.bool,
  excludeFields: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(USER_FORM_FIELDS).map(field => field.name)),
  ),
  idUser: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.any),
  isFetchingUsers: PropTypes.bool,
  label: PropTypes.string,
  selectedId: PropTypes.string,
  showUserRoles: PropTypes.arrayOf(PropTypes.string),
  tenantId: PropTypes.string,
  translatedLabel: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  validate: PropTypes.arrayOf(PropTypes.func),
};

AddCreateUser.defaultProps = {
  allowCreate: true,
  disabled: false,
  excludeFields: [],
  idUser: 'userId',
  initialValues: {},
  isFetchingUsers: null,
  label: '',
  selectedId: consts.adminUserNew,
  showUserRoles: Object.values(ROLES), // Array of user roles to be displayed
  tenantId: null,
  translatedLabel: 'USERS.USER_FORM_FIELDS.LABELS.ID',
  users: [],
  validate: [],
};

function mapStateToProps(state, ownProps) {
  const { tenantId } = ownProps;
  let { users } = state.user;
  let isFetchingUsers = state.isFetching.getUsers;
  if (ownProps.tenantId) {
    users = state.user.usersPublicInfo[tenantId] || [];
    isFetchingUsers = state.isFetching.getUsersPublicInfo[tenantId];
  }
  return {
    isFetchingUsers,
    users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getUsers, getUsersForTenant }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCreateUser);
