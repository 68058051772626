import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-plus';
import { withLocalize } from 'react-localize-redux';
import { ai } from 'util/telemetryService';

import { IconFilter } from 'components';
import { Icon } from 'lib';
import { SELECT_STYLE } from 'constants/app';
import {
  disabledClassName,
  filter,
  filterArrow,
  filterInput,
  filterMultiInput,
  labelOverlay,
  selectBoxClear,
  selectBoxOption,
  selectBoxOptionSubtext,
} from './styles.css';

class FilterTranslated extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      rawOptions: this.optionsToArr(props.options),
      value,
    };
  }

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    this.setState({
      rawOptions: this.optionsToArr(nextProps.options),
    });
    // If new values are passed in, override
    if (nextProps.value || value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  optionsToArr = options => {
    const { translate, translateOptions } = this.props;
    const translateOption = option =>
      translateOptions ? translate(option.subText) : option.subText;
    return options
      .filter(
        option => (option.value && option.text) || typeof option === 'string',
      )
      .map(option => ({
        label: translateOptions
          ? translate(option.text || option)
          : option.text || option,
        subText: option.subText ? translateOption(option) : '',
        value: option.value || option,
      }));
  };

  setValue = value => {
    const { multi } = this.props;
    if (!value || value.length === 0 || !multi) {
      this.setState({ value }, this.handleFilterChanged);
    } else {
      // If one value in a multiselect has been changed, wait until
      // filter closes to update container
      this.setState({ value });
    }
  };

  clearValue = () => {
    this.setState({ value: null }, this.handleFilterChanged);
  };

  handleFilterChanged = () => {
    const { field, isInput, onChange } = this.props;
    // Normalization
    // Whether multi- or single-select, return an array of values
    // or the string 'All' when the filter is cleared.
    const { value } = this.state;
    let valueArr;
    if (!value || value.length === 0) {
      if (isInput) {
        valueArr = null;
      } else {
        valueArr = 'All';
      }
    } else if (typeof value === 'string' || !value.length) {
      valueArr = [value.value || value];
    } else {
      valueArr = value.map(v => v.value || v);
    }
    onChange(field, valueArr);
    ai.appInsights.trackEvent({ name: 'userUsedDropdownFilter' });
  };

  countValues = () => {
    const { rawOptions, value } = this.state;
    const {
      allVisiblePlaceholder,
      isInput,
      isStatusFilter,
      multi,
      translate,
    } = this.props;
    let countValues;
    if (multi && value && value.length > 0) {
      const values = value.slice();
      countValues = translate(
        `FILTER.COUNTER.SELECTED_COUNT${values.length > 1 ? '_PLURAL' : ''}`,
        { count: values.length },
      );
    } else if (!multi && value) {
      const selectedOption = rawOptions.find(
        opt => opt.value === value.value || opt.value === value,
      );
      if (selectedOption) { 
        countValues = translate('FILTER.COUNTER.SELECTED', {
          option: selectedOption.label || selectedOption,
        });
      }
    } else if (allVisiblePlaceholder) {
      countValues = translate(allVisiblePlaceholder);
    } else if (multi) {
      if (isInput) {
        countValues = translate('FILTER.COUNTER.NONE');
      } else {
        const translationId = isStatusFilter
          ? 'FILTER.COUNTER.ALL_STATUS'
          : 'FILTER.COUNTER.ALL_DEVICES';
        countValues = translate(translationId);
      }
    } else {
      countValues = translate('FILTER.COUNTER.ANY');
    }
    return (
      <span>
        <b>{this.getLabel()}</b>
        {countValues}
      </span>
    );
  };

  renderClear = () => <Icon iconClass={selectBoxClear} id="ic_remove" />;

  renderOption = option => (
    <div className={selectBoxOption}>
      {option.label}
      {option.subText && (
        <div className={selectBoxOptionSubtext}> {option.subText} </div>
      )}
    </div>
  );

  valueComponent = () => (
    <div className={labelOverlay}>{this.countValues()}</div>
  );

  getLabel = () => {
    const { label, translate, translateLabel } = this.props;
    return translate('LABEL', {
      label: translateLabel ? translate(label) : label,
    });
  };

  renderArrow = () => {
    const { value } = this.state;
    if (!(value && value.length !== 0)) {
      return <span className={`${'Select-arrow'} ${filterArrow}`} />;
    }
    return null;
  };

  render() {
    const {
      disabled,
      icon,
      id,
      isIcon,
      isInput,
      isSearchable,
      multi,
      translate,
      value,
    } = this.props;
    const { rawOptions, value: stateValue } = this.state;
    if (isIcon) {
      return (
        <IconFilter
          anchor="left"
          closeText={translate('BUTTONS.CLOSE_MENU')}
          icon={icon}
          id={id}
          multiple={multi}
          onChange={disabled ? () => null : this.handleFilterChanged}
          options={rawOptions}
          value={value}
        />
      );
    }
    return (
      <div className={`${filter} ${disabled && disabledClassName}`} id={id}>
        <Select
          arrowRenderer={this.renderArrow}
          className={
            multi
              ? `${filterMultiInput} msi-select`
              : `${filterInput} msi-select`
          }
          clearRenderer={this.renderClear}
          clearValueText={translate('COMMON.CLEAR')}
          closeOnSelect={!multi}
          disabled={disabled}
          label={this.getLabel()}
          multi={multi}
          noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
          onChange={this.setValue}
          onClear={this.clearValue}
          onClose={this.handleFilterChanged}
          optionClassName="msi-select-option"
          optionRenderer={this.renderOption}
          options={rawOptions}
          placeholder={this.countValues()}
          removeSelected={false}
          searchable={!isInput && isSearchable}
          style={SELECT_STYLE}
          value={stateValue}
          valueComponent={this.valueComponent}
        />
      </div>
    );
  }
}

FilterTranslated.propTypes = {
  allVisiblePlaceholder: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  icon: PropTypes.node,
  id: PropTypes.string,
  isIcon: PropTypes.bool,
  isInput: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isStatusFilter: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multi: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMount: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  translate: PropTypes.func.isRequired,
  translateLabel: PropTypes.bool,
  translateOptions: PropTypes.bool,
  value: PropTypes.string,
};

FilterTranslated.defaultProps = {
  allVisiblePlaceholder: null,
  disabled: false,
  icon: '',
  id: 'idFilter',
  isIcon: false,
  isInput: false,
  isSearchable: true,
  isStatusFilter: false,
  multi: false,
  onMount: null,
  translateLabel: false,
  translateOptions: false,
  value: '',
};

export default withLocalize(FilterTranslated);
