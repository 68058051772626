exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/styles.css"), undefined);
exports.i(require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".placeholderWrapper___3XFz8 {\r\n  border: 1px solid #dddddd;\r\n}\r\n\r\n.placeHolderSpinner___1qaz-{\r\n  -ms-flex-align: center;\r\n      align-items: center; \r\n  display: -ms-flexbox; \r\n  display: flex; \r\n  height: 100%; \r\n  -ms-flex-pack: center; \r\n      justify-content: center;\r\n}\r\n\r\n.placeholder___1X3ji {\r\n  padding: 10px;\r\n}\r\n\r\n.placeholder___1X3ji {\r\n  color: #000000 !important;\r\n}\r\n\r\n@keyframes ellipsis___1FTta {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/EmptyPlaceholder/styles.css"],"names":[],"mappings":"AAAA;EAEE,0BAA0B;CAC3B;;AAED;EACE,uBAAuB;MACnB,oBAAoB;EACxB,qBAAqB;EACrB,cAAc;EACd,aAAa;EACb,sBAAsB;MAClB,wBAAwB;CAC7B;;AAED;EAEE,cAAc;CACf;;AAED;EACE,0BAA0B;CAC3B;;AAED;EACE;IACE,cAAc;GACf;CACF","file":"styles.css","sourcesContent":[".placeholderWrapper {\r\n  composes: contentWrapper from '../../sharedStyles/styles.css';\r\n  border: 1px solid #dddddd;\r\n}\r\n\r\n.placeHolderSpinner{\r\n  -ms-flex-align: center;\r\n      align-items: center; \r\n  display: -ms-flexbox; \r\n  display: flex; \r\n  height: 100%; \r\n  -ms-flex-pack: center; \r\n      justify-content: center;\r\n}\r\n\r\n.placeholder {\r\n  composes: tableHeaderTextColor from '../../sharedStyles/brushes.css';\r\n  padding: 10px;\r\n}\r\n\r\n.placeholder {\r\n  color: #000000 !important;\r\n}\r\n\r\n@keyframes ellipsis {\r\n  to {\r\n    width: 1.25em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"placeholderWrapper": "placeholderWrapper___3XFz8 " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/styles.css").locals["contentWrapper"] + "",
	"placeHolderSpinner": "placeHolderSpinner___1qaz-",
	"placeholder": "placeholder___1X3ji " + require("-!../../../node_modules/css-loader/index.js??ref--6-1!../../sharedStyles/brushes.css").locals["tableHeaderTextColor"] + "",
	"ellipsis": "ellipsis___1FTta"
};