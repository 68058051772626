import React, { PureComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
/*
Note from Andre: In this component we are copying incoming props into state.
This is to facilitate a complex interaction.
In general this practice is frowned upon, and it's presence should not be taken as a blessing.
This should be used case by case. If you think your case merits this pattern, make sure to talk it over with dev leads.
*/
// Components
import {
  Checkbox,
  DurationInput,
  FieldInput,
  MinuteDurationInput,
} from 'components';

import { Button, FieldSelect, FormGroup, GroupLayout, ListView } from 'lib';

// Icons
import { IconClear, IconDownArrow, IconUpArrow } from 'icons';

// Styles
import {
  formField,
  headerText,
  horizontalDivider,
  subHeaderDetail,
} from 'sharedStyles/modalForms.css';

import { DELETE_ICON_ID, DOWN_ICON_ID, UP_ICON_ID } from 'constants/ElementId';
import {
  actionsButtons,
  fieldWrapper,
  formWrapper,
  tableWrapper,
} from './PtzTourForm.styles.css';

// Constants
import * as consts from './constants';

const FieldPauseDuration = props => {
  return (
    <MinuteDurationInput
      initialDuration={
        props.input.value === ''
          ? consts.DEFAULT_PAUSE_DURATION_MS
          : props.input.value
      }
      isMilliseconds
      maxDuration={props.constraints.maxPauseTimeMs}
      minDuration={props.constraints.minPauseTimeMs}
      onChange={props.input.onChange}
    />
  );
};

const FieldStartTime = props => {
  return (
    <MinuteDurationInput
      disabled={!props.isDefaultValue}
      initialDuration={
        props.input.value === ''
          ? consts.DEFAULT_IDLE_START_TIME
          : props.input.value
      }
      isMilliseconds
      maxDuration={props.constraints.maxIdleStartTimeMs}
      minDuration={props.constraints.minIdleStartTimeMs}
      onChange={props.input.onChange}
    />
  );
};

const FieldDefaultTour = props => {
  return (
    <Checkbox
      checked={props.input.value}
      label={<Translate id="PTZ_TOUR.SET_DEFAULT_TOUR" />}
      onChange={props.input.onChange}
    />
  );
};

const tourPresetTemplate = () => {
  return {
    id: null,
    moveSpeed: consts.DEFAULT_MOVE_SPEED,
    viewTimeMs: consts.DEFAULT_VIEW_TIME_MS,
  };
};

class PtzTourForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tourPresets: (props.currentTour && props.currentTour.presets) || [],
    };
  }

  get presetData() {
    // This is for the preset dropdown
    const { presets } = this.props;
    const data =
      (presets &&
        presets.map(preset => {
          return {
            label: preset.name,
            value: preset.id,
          };
        })) ||
      [];
    return data;
  }

  addPreset = () => {
    const existing = this.state.tourPresets || [];
    const newTourPreset = tourPresetTemplate();
    const updatedPresets = [...existing, newTourPreset];
    this.setState({ tourPresets: updatedPresets }, () => {
      this.props.change('presets', this.state.tourPresets);
    });
  };

  deletePreset = (rowData, rowIdx) => {
    const withoutDeleted = [...this.state.tourPresets].filter((preset, idx) => {
      return idx !== rowIdx;
    });
    this.setState({ tourPresets: withoutDeleted }, () => {
      this.props.change('presets', this.state.tourPresets);
    });
  };

  movePresetUp = (rowData, rowIdx) => {
    let newOrder;
    const tourPresets = [...this.state.tourPresets];
    if (rowIdx > 0) {
      newOrder = tourPresets.filter((preset, idx) => {
        return idx !== rowIdx;
      });
      newOrder.splice(rowIdx - 1, 0, tourPresets[rowIdx]);
      this.setState({ tourPresets: newOrder }, () => {
        this.props.change('presets', this.state.tourPresets);
      });
    }
  };

  movePresetDown = (rowData, rowIdx) => {
    let newOrder = [];
    const tourPresets = [...this.state.tourPresets];
    if (rowIdx < tourPresets.length - 1) {
      newOrder = tourPresets.filter((preset, idx) => {
        return idx !== rowIdx;
      });
      newOrder.splice(rowIdx + 1, 0, tourPresets[rowIdx]);
      this.setState({ tourPresets: newOrder }, () => {
        this.props.change('presets', this.state.tourPresets);
      });
    }
  };

  setPreset = (rowData, rowIdx, e) => {
    const newValue = e.target.value;
    const updatedPresets = [...this.state.tourPresets].map((preset, idx) => {
      preset = { ...preset };
      if (idx === rowIdx) {
        preset.id = parseInt(newValue, 10);
      }
      return preset;
    });
    this.setState({ tourPresets: updatedPresets }, () => {
      this.props.change('presets', this.state.tourPresets);
    });
  };

  setMoveSpeed = (rowData, rowIdx, e) => {
    const newValue = e.target.value;
    const updatedPresets = [...this.state.tourPresets].map((preset, idx) => {
      preset = { ...preset };
      if (idx === rowIdx) {
        preset.moveSpeed = newValue / 100;
        if (preset.moveSpeed < consts.MIN_MOVE_SPEED) {
          preset.moveSpeed = consts.MIN_MOVE_SPEED;
        } else if (preset.moveSpeed > consts.MAX_MOVE_SPEED) {
          preset.moveSpeed = consts.MAX_MOVE_SPEED;
        }
      }
      return preset;
    });
    this.setState({ tourPresets: updatedPresets }, () => {
      this.props.change('presets', this.state.tourPresets);
    });
  };

  setViewTimeMs = (rowData, rowIdx, newValue) => {
    const updatedPresets = [...this.state.tourPresets].map((preset, idx) => {
      preset = { ...preset };
      if (idx === rowIdx) {
        if (newValue < 0) newValue = 0;
        preset.viewTimeMs = newValue;
      }
      return preset;
    });
    this.setState({ tourPresets: updatedPresets }, () => {
      this.props.change('presets', this.state.tourPresets);
    });
  };

  render() {
    const {
      constraints,
      handleCancel,
      handleSubmit,
      invalid,
      isDefaultValue,
      pristine,
    } = this.props;
    const CustomCells = {
      Actions: (rowData, rowIdx) => (
        <div className={actionsButtons}>
          <Button
            icon
            id={UP_ICON_ID}
            inputType="button"
            onClick={this.movePresetUp.bind(this, rowData, rowIdx)}
          >
            <IconUpArrow fillColor="#999" iconId={`moveUp${rowIdx}`} />
          </Button>
          <Button
            icon
            id={DOWN_ICON_ID}
            inputType="button"
            onClick={this.movePresetDown.bind(this, rowData, rowIdx)}
          >
            <IconDownArrow fillColor="#999" iconId={`moveDown${rowIdx}`} />
          </Button>
        </div>
      ),
      Delete: (rowData, rowIdx) => (
        <Button
          icon
          id={DELETE_ICON_ID}
          inputType="button"
          onClick={this.deletePreset.bind(this, rowData, rowIdx)}
        >
          <IconClear fillColor="#999" iconId={`clear${rowIdx}`} />
        </Button>
      ),

      id: (rowData, rowIdx) => (
        <select
          key={rowData.id}
          onChange={this.setPreset.bind(this, rowData, rowIdx)}
          style={{ width: '100%' }}
          value={rowData.id}
        >
          <Translate>
            {({ translate }) => (
              <option key="none">
                {translate('PTZ.TOURS.PRESET_SELECT_DEFAULT')}
              </option>
            )}
          </Translate>
          {this.presetData.map(preset => (
            <option key={preset.value} value={preset.value}>
              {preset.label}
            </option>
          ))}
        </select>
      ),
      moveSpeed: (rowData, rowIdx) => (
        <input
          max={100}
          min={1}
          onChange={this.setMoveSpeed.bind(this, rowData, rowIdx)}
          type="number"
          value={rowData.moveSpeed * 100}
        />
      ),
      viewTimeMs: (rowData, rowIdx) => (
        <DurationInput
          initialDuration={rowData.viewTimeMs}
          isMilliseconds
          max={100}
          min={1}
          onChange={this.setViewTimeMs.bind(this, rowData, rowIdx)}
        />
      ),
    };
    CustomCells.displayName = 'CustomCells';

    return (
      <Translate>
        {({ translate }) => (
          <div className={formWrapper}>
            <form onSubmit={handleSubmit}>
              <FormGroup id="TourName" label={translate('PTZ_TOUR.TOUR_NAME')}>
                <Field
                  className={`${formField} ${fieldWrapper}`}
                  component={FieldInput}
                  name="name"
                  type="text"
                />
              </FormGroup>
              <FormGroup
                id="PauseDuration"
                label={translate('PTZ_TOUR.PAUSE_DURATION')}
              >
                <Field
                  component={FieldPauseDuration}
                  constraints={constraints}
                  name="pauseDurationMs"
                  type="number"
                />
              </FormGroup>
              <FormGroup id="TourMode" label={translate('PTZ_TOUR.TOUR_MODE')}>
                <Field
                  className={`${formField} ${fieldWrapper}`}
                  component={FieldSelect}
                  name="mode"
                  type="select"
                >
                  <option key="sequential" value="sequential">
                    {translate('PTZ_TOUR.SEQUENTIAL')}
                  </option>
                  <option key="random" value="random">
                    {translate('PTZ_TOUR.RANDOM')}
                  </option>
                </Field>
              </FormGroup>
              <FormGroup id="IsDefault" label="">
                <Field component={FieldDefaultTour} name="isDefault" />
              </FormGroup>
              <FormGroup
                id="StartTime"
                label={translate('PTZ_TOUR.START_TIME')}
              >
                <Field
                  component={FieldStartTime}
                  constraints={constraints}
                  isDefaultValue={isDefaultValue}
                  name="idleStartTime"
                  type="number"
                />
              </FormGroup>
              <div className={horizontalDivider} />
              <div className={headerText}>
                <Translate id="PTZ.TOURS.TOUR_BUILDER_HEADING" />
              </div>
              <div className={subHeaderDetail}>
                <Translate id="PTZ.TOURS.TOUR_BUILDER_SUBHEADING" />
              </div>
              <ListView
                cellAlignments={consts.CELL_ALIGNMENTS}
                cellWidths={consts.CELL_WIDTHS}
                className={tableWrapper}
                customCells={CustomCells}
                data={this.state.tourPresets}
                fieldOrder={consts.FIELD_ORDER}
                headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
                hideFilter
              />
              <Field component={FieldInput} name="presets" type="hidden" />
              <Button
                inputType="button"
                onClick={this.addPreset}
                text="Add another preset"
              />
              <GroupLayout verticalCenter verticalSpacing="medium">
                <Button
                  key="userFormButtonCancel"
                  buttonType="primary"
                  id="userFormButtonCancel"
                  inputType="button"
                  onClick={handleCancel}
                  text={<Translate id="BUTTONS.CANCEL" />}
                />
                <Button
                  key="userFormButtonSave"
                  buttonType="primary"
                  disabled={invalid || pristine}
                  id="userFormButtonSave"
                  onClick={handleSubmit}
                  text={<Translate id="BUTTONS.SAVE" />}
                />
              </GroupLayout>
            </form>
          </div>
        )}
      </Translate>
    );
  }
}

const validate = values => {
  const errors = {};
  if (values) {
    if (!values.name || (values.name && values.name.length === 0)) {
      errors.name = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
  }
  return errors;
};

PtzTourForm.defaultProps = {
  presets: [],
};

const selector = formValueSelector('PtzTourForm');
const PtzTourConnectForm = connect(state => {
  return {
    isDefaultValue: selector(state, 'isDefault'),
  };
})(PtzTourForm);

export default reduxForm({
  enableReinitialize: false,
  fields: [
    'name',
    'pauseDurationMs',
    'idleStartTime',
    'mode',
    'isDefault',
    'presets',
  ],
  form: 'PtzTourForm',
  validate,
})(PtzTourConnectForm);
