import FetchError from './FetchError';

export default function handleErrors(response) {
  const contentType = response.headers.get('content-type');
  const isJsonResponse =
    contentType && contentType.indexOf('application/json') !== -1;
  if (response.status >= 200 && response.status < 300) {
    return isJsonResponse ? response.json() : { status: 'OK' };
  }
  if (isJsonResponse) {
    // Is JSON, is error
    return response.json().then(json => {
      if (json.ReasonCode === 'EulaAcceptanceRequired') {
        throw new FetchError(json, response.status);
      } else {
        // Some errors return content type json but response as string
        const message = typeof json === 'string' ? json : null;
        throw new FetchError(
          json.ExceptionMessage || json.Message || message,
          json.Code || response.status,
          json.ExceptionMessage || json.Message || message,
        );
      }
    });
  }
  if (response.status === 401) {
    return response.text().then(text => {
      if (text && text !== '') {
        throw new FetchError(response.statusText, response.status, text);
      } else {
        throw new FetchError(response.statusText, response.status);
      }
    });
  }
  if (response.status === 504) {
    throw new Error('No response from the device');
  } else if (response.statusText) {
    throw Error(response.statusText);
  } else if (response.errorMessage) {
    throw new Error(response.errorMessage);
  } else {
    throw new Error(response.status);
  }
}
