
export function getCookie(name) {
  // borrowed from https://stackoverflow.com/a/22852843/66780
  let cookieValue = ` ${document.cookie}`;
  let cookieStart = cookieValue.indexOf(` ${name}=`);
  if (cookieStart === -1) {
    cookieValue = null;
  } else {
    cookieStart = cookieValue.indexOf('=', cookieStart) + 1;
    let cookieEnd = cookieValue.indexOf(';', cookieStart);
    if (cookieEnd === -1) {
      cookieEnd = cookieValue.length;
    }
    cookieValue = unescape(cookieValue.substring(cookieStart, cookieEnd));
  }
  return cookieValue;
}

export function setCookie(name, value, expireInDays) {
  const d = new Date();
  d.setTime(d.getTime() + expireInDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires} ; path=/`;
}
