import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const highlightColor = '#FFFFFF';

const IconAlarmUnReviewed = props => {
  const { title, translate, width, height, highlight, className } = props;

  return (
    <div
      title={translate(title)}
      height={height}
      width={width}
      style={{ display: 'flex' }}
    >
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 33 33"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="01-notifications-list"
          stroke="none"
          strokeWidth="1"
          fill={highlight ? highlightColor : ''}
          fillRule="evenodd"
        >
          <g id="Alarms-List" transform="translate(-127.000000, -239.000000)">
            <g
              id="new-icon-alarm-02"
              transform="translate(127.000000, 239.000000)"
            >
              <g id="Page-1">
                <g id="Group-3" transform="translate(0.000000, 0.259459)">
                  <path
                    d="M16.4781222,4.10719817 C9.64284444,4.10719817 4.10159444,9.59761101 4.10159444,16.3701798 C4.10159444,23.1427486 9.64284444,28.6331615 16.4781222,28.6331615 C23.3137056,28.6331615 28.85465,23.1427486 28.85465,16.3701798 C28.85465,9.59761101 23.3137056,4.10719817 16.4781222,4.10719817 M32.9564278,16.3701798 C32.9564278,25.3873541 25.5787889,32.6973083 16.4781222,32.6973083 C7.37745556,32.6973083 0.000122222222,25.3873541 0.000122222222,16.3701798 C0.000122222222,7.3530055 7.37745556,0.0433541284 16.4781222,0.0433541284 C25.5787889,0.0433541284 32.9564278,7.3530055 32.9564278,16.3701798"
                    id="Fill-1"
                    fill={highlight ? highlightColor : '#D0D2D3'}
                  />
                </g>
                <path
                  d="M23.5673778,20.8169752 L9.25485,20.8169752 L8.05065556,23.2102321 L13.5292667,23.2102321 C13.8085444,24.5753422 15.0222111,25.6031862 16.4781833,25.6031862 C17.9341556,25.6031862 19.1484333,24.5753422 19.4271,23.2102321 L24.9057111,23.2102321 L23.7030444,20.8200028 L23.5673778,20.8169752 Z M23.0748222,19.4833514 L22.4979333,18.4243239 L22.4979333,13.6387183 C22.4979333,10.3350853 19.8023222,7.65633303 16.4781833,7.65633303 C13.1540444,7.65633303 10.4587389,10.3350853 10.4587389,13.6387183 L10.4587389,18.4243239 L9.92554444,19.4839569 L23.0748222,19.4833514 Z"
                  id="Fill-4"
                  fill={highlight ? highlightColor : '#70B1E0'}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconAlarmUnReviewed.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.bool,
  translate: PropTypes.func,
  className: PropTypes.string,
};

IconAlarmUnReviewed.defaultProps = {
  title: 'ALARMS.STATUS.UNREVIEWED_LABEL',
  className: null,
  width: '25px',
  height: '25px',
  highlight: false,
  translate: () => {},
};

export default withLocalize(IconAlarmUnReviewed);
