import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SettingsButton } from '@avo-svtr/savitar';
import SettingOptionsGroup from './SettingsOptionsGroup';
import * as classes from './savitarPlayer.styles.css';

const Settings = props => {
  const [showSettings, setShowSetting] = useState(false);
  const settingsRef = useRef();

  const toggleSettings = useCallback(() => setShowSetting(old => !old), [setShowSetting]);

  return (
    <div ref={settingsRef} className={classes.settings}>
    {showSettings ?
        <SettingOptionsGroup
          { ...props }
          settingsRef={settingsRef}
          toggleSettings={toggleSettings}
        />
      : null}
    <SettingsButton onClick={toggleSettings} />
  </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
