import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_CAMERAS } from 'constants/urlPaths';
import { DEVICE_SETTINGS } from 'constants/deviceSettings';
import { allTabs } from './constants';

const CameraSettingsNavMenu = props => {
  const TABS = DEVICE_SETTINGS.GENERIC.LABELS;
  const { camera, translate } = props;

  function getTabsToBeDisplayed() {
    let tabs = allTabs;
    tabs = tabs.slice();
    if (camera.Capabilities !== undefined) {
      const cameraCapabilities = JSON.parse(camera.Capabilities);
      if (
        !cameraCapabilities.general ||
        !cameraCapabilities.general.includes('PRIVACY_ZONES')
      ) {
        tabs = tabs.filter(tab => tab.id !== TABS.PRIVACY);
      }
      if (
        !cameraCapabilities.digitalIo ||
        (cameraCapabilities.digitalIo.length === 1 &&
          cameraCapabilities.digitalIo[0] === 'OUTPUT_ACTIVATE')
      ) {
        tabs = tabs.filter(tab => tab.id !== TABS.DIGITAL_OUT);
      }
      if (!cameraCapabilities.speaker) {
        tabs = tabs.filter(tab => tab.id !== TABS.AUDIO);
      }
    }
    return tabs;
  }

  const visibleTabs = getTabsToBeDisplayed();
  return (
    <NavTabs
      tabs={visibleTabs.map(tab => ({
        id: tab.id,
        key: tab.id,
        label: translate(tab.label),
        path: `${PATH_CAMERAS}/${camera.Id}${tab.path}`,
      }))}
    />
  );
};

CameraSettingsNavMenu.propTypes = {
  camera: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(CameraSettingsNavMenu);
