// Libs
import React from 'react';
import { PropTypes } from 'prop-types';

// Component
import { ListView } from 'lib';

// Styles
import * as ContactConsts from './constants';

// Class
const NotificationsContactsTable = ({ contacts, hideColumns }) => {
  const { fieldName, fieldPhoneNo } = ContactConsts.columnDataFields;

  const data = contacts.map(row => ({
    Id: row.contact.Id,
    name: row.contact[fieldName],
    phone: row.contact[fieldPhoneNo],
  }));

  const CUSTOM_CELLS = {
    name: rowData => {
      return <span>{rowData.name}</span>;
    },
    phone: rowData => {
      return (
        <span>
          <a href={`tel:${rowData.phone}`}>{rowData.phone}</a>
        </span>
      );
    },
  };

  const fieldOrder = ContactConsts.FIELD_ORDER.filter(
    field => !hideColumns.includes(field),
  );

  const translationIds = {
    name: 'COMMON.NAME',
    phone: 'GENERAL_TABLE.HEADER.PHONE_LABEL',
  };

  return (
    <ListView
      cellWidths={{ Actions: 50 }}
      customCells={CUSTOM_CELLS}
      data={data}
      fieldOrder={fieldOrder}
      fillContainerHeight
      headerTranslationIds={translationIds}
      hideFilter
      inlineDetails={false}
      resizable={false}
      sortType="local"
      tableBodyStyle={null}
      truncatedFields={fieldOrder}
    />
  );
};

NotificationsContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.any),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
};

NotificationsContactsTable.defaultProps = {
  contacts: [],
  hideColumns: [],
};

export default NotificationsContactsTable;
