import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DeviceActions from 'actions/devices';

import SnapshotUnavailable from 'components/SnapshotUnavailable/SnapshotUnavailable';
import { cameraSnapshot, cameraSnapshotImg } from './styles.css';

class CameraSnapshot extends Component {
  state = {
    snapshotURL: undefined,
    snapshotError: 0,
    revokeSnapshotURL: () => undefined,
  };

  componentDidMount() {
    const {
      snapshotURL,
      refreshSnapshot,
      getRevokableSnapshotURL,
      actions,
      deviceId,
      cameraId,
    } = this.props;
    if ((!snapshotURL || refreshSnapshot) && !getRevokableSnapshotURL) {
      actions.getSnapshotURL(deviceId, cameraId);
    }
    this.fetchAftSnapshot();
  }

  componentDidUpdate() {
    const {
      refreshSnapshot,
      getRevokableSnapshotURL,
      actions,
      deviceId,
      cameraId,
    } = this.props;
    if (refreshSnapshot && !getRevokableSnapshotURL) {
      actions.getSnapshotURL(deviceId, cameraId);
    }
  }

  componentWillUnmount() {
    const { revokeSnapshotURL } = this.state;
    revokeSnapshotURL();
  }

  handleImageError = () => {
    const { snapshotError } = this.state;
    const { actions, deviceId, cameraId } = this.props;
    if (snapshotError === 0) {
      // Only retry once
      this.setState({ snapshotError: snapshotError + 1 }, () =>
        actions.getSnapshotURL(deviceId, cameraId),
      );
    }
  };

  async fetchAftSnapshot() {
    const { snapshotURL: stateURL } = this.state;
    const { getRevokableSnapshotURL } = this.props;
    if (stateURL || !getRevokableSnapshotURL) {
      return;
    }

    const {
      url: snapshotURL,
      revoke: revokeSnapshotURL,
    } = await getRevokableSnapshotURL();
    this.setState(state => {
      state.revokeSnapshotURL();
      return { snapshotURL, revokeSnapshotURL };
    });
  }

  render() {
    const { cameraName, snapshotURL: propURL } = this.props;
    const { snapshotURL: stateURL } = this.state;
    const snapshotURL = propURL || stateURL;

    return (
      <div className={cameraSnapshot}>
        {!snapshotURL ? (
          <SnapshotUnavailable />
        ) : (
          <img
            className={cameraSnapshotImg}
            src={snapshotURL}
            onError={this.handleImageError}
            alt={cameraName}
          />
        )}
      </div>
    );
  }
}

CameraSnapshot.propTypes = {
  cameraId: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  cameraName: PropTypes.string,
  snapshotURL: PropTypes.string,
  refreshSnapshot: PropTypes.bool,
  getRevokableSnapshotURL: PropTypes.func,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

CameraSnapshot.defaultProps = {
  cameraName: '',
  refreshSnapshot: false,
  getRevokableSnapshotURL: undefined,
  snapshotURL: '',
};

function mapStateToProps(state, ownProps) {
  let snapshotURL = '';
  let refreshSnapshot = false;
  const snapshot = state.devices.snapshots[ownProps.cameraId];
  if (snapshot) {
    snapshotURL = snapshot.uri;
    refreshSnapshot = snapshot.stale;
  }
  return {
    snapshotURL,
    refreshSnapshot,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(DeviceActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CameraSnapshot);
