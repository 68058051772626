export const PROTOCOL = 'https://';

export const HOST =
  window.location.hostname +
  (window.location.port ? `:${window.location.port}/` : '/');
export const HOST_LOCAL = 'localhost:8081/';
// for development with a local copy of the HeliosAPI
export const HOST_MOCK = 'localhost:8082/';
// mock server runs on 8082 so as not to conflict with a local Azure deployment
export const HOST_DEV = 'ci.develophelios.com/';
// for development with the remote development environment
// DO NOT EDIT HOST_DEV. Use Oasis.
export const HOST_TEST = 'testHost/';
// for jest action tests
export const URL_BASE = 'api/';
export const URL_BASE_ODATA = 'odata/';
export const URL_VERSION_1 = 'v1/';
export const URL_VERSION_2 = 'v2/';
export const URL_VERSION_3 = 'v3/';
export const URL_VERSION_4 = 'v4/';
export const MOCK_URL_BASE = '';

// Most API requests will have /Organizations/:orgId inserted
export const URL_INCLUDE_ORG_ID = 'Organizations/';

export const RESOURCE_DELETE_ORGANIZATION = 'Organizations/:id';

// Accounts
export const RESOURCE_LOGIN = 'Accounts/Login';
export const RESOURCE_LOGOUT = 'Accounts/Logout';
export const REGISTER = 'Accounts/RegisterUser';
export const REGISTER_UNPROVISIONED = 'Organizations/CreateSelfProvisioned';
export const RESOURCE_ACCOUNTS = 'Accounts';
export const REQUEST_RESET_PASSWORD = `${RESOURCE_ACCOUNTS}/ForgotPassword`; // send an email to allow password reset
export const CHANGE_PASSWORD = `${RESOURCE_ACCOUNTS}/ResetPassword`; // reset by user from random GUID to pw of their choice
export const VALIDATE_PASSWORD = `${RESOURCE_ACCOUNTS}/ValidatePassword`; // validate the password
export const RESOURCE_USER_PROFILE = `${RESOURCE_ACCOUNTS}/Profile`;
export const RESOURCE_CUSTOMERS = `${RESOURCE_ACCOUNTS}/Customers/`;
export const RESOURCE_ACCOUNT_ORGANIZATIONS = `${RESOURCE_ACCOUNTS}/Users/`;

// Sites
export const RESOURCE_LOCATIONS = 'Locations/';
export const RESOURCE_CLUSTERS = 'Clusters/';
export const DELETE_LOCATION = `${RESOURCE_LOCATIONS}:id`; // hack so DELETE uses the v1 api
export const GET_CONTACT_BY_LOCATION = `${RESOURCE_LOCATIONS}:id/Contacts`;
export const RESOURCE_SERVICE_PACKAGES = 'ServicePackages/';
export const GET_SERVICE_PACKAGES = `${RESOURCE_SERVICE_PACKAGES}Dealer/:id`;
export const GET_SERVICE_PACKAGE = `${RESOURCE_SERVICE_PACKAGES}:id`;
export const GET_SERVICE_PACKAGE_BY_CODE = `${RESOURCE_SERVICE_PACKAGES}ByCode/:id`;
export const SUSPEND_LOCATIONS = `${RESOURCE_LOCATIONS}Suspend`;
export const UNSUSPEND_LOCATIONS = `${RESOURCE_LOCATIONS}Unsuspend`;
export const SUBSCRIBER_SERVICE_PACKAGES = 'ServicePackages';

// Subscriptions
export const RESOURCE_SERVICE_PACKAGE_FAMILIES = 'ServiceFamilies/';
export const GET_DEALER_SERVICE_PACKAGE_FAMILIES = `${RESOURCE_SERVICE_PACKAGE_FAMILIES}Dealer/:id`;
export const RESOURCE_SUBSCRIPTIONS = 'Subscriptions/';
export const RESOURCE_SUBSCRIPTION = 'Subscription/';
export const GET_ORG_SUBSCRIPTIONS = RESOURCE_SUBSCRIPTIONS; // So the overridden ORG _will_ be included.
export const UPGRADE_SERVICE_PACKAGE = `${RESOURCE_SUBSCRIPTIONS}:id/Upgrade`;
export const DOWNGRADE_SERVICE_PACKAGE = `${RESOURCE_SUBSCRIPTIONS}:id/Downgrade`;
export const EDIT_SUBSCRIPTION_TERM_LENGTH = `${RESOURCE_SUBSCRIPTIONS}:id/UpdateTerm`;
export const ENABLE_MONITORING = `${RESOURCE_SUBSCRIPTIONS}:id/DealerMonitoring`;
export const DISABLE_MONITORING = `${RESOURCE_SUBSCRIPTIONS}:id/DealerMonitoring`;
export const DEALER_BILLING_CYCLES = `${RESOURCE_SUBSCRIPTIONS}/DealerBillingCycles`;
export const GET_PENDING_SITE_REQUESTS = `${RESOURCE_SUBSCRIPTIONS}ChangeProviderRequests`;
export const CHANGE_PROVIDER = `${RESOURCE_LOCATIONS}:id/${RESOURCE_SUBSCRIPTIONS}ChangeProviderRequest/`;
export const PENDING_SITE_REQUESTS_CREATE = `${CHANGE_PROVIDER}Create`;
export const PENDING_SITE_REQUESTS_ACCEPT = `${CHANGE_PROVIDER}Accept`;
export const PENDING_SITE_REQUESTS_DECLINE = `${CHANGE_PROVIDER}Reject`;

// Dealer Dashboard
export const DEALER_SUBSCRIBERS_STATS = 'DealerSubscribersStats';
export const DEALER_SUBSCRIBERS_UPGRADE_SITES = 'DealerSubscribersUpgradeSites';
export const DEALER_SUBSCRIBERS_SUBSCRIPTION_LEVELS =
  'DealerSubscribersSubscriptionLevels';
export const DEALER_SUBSCRIBERS_RENEWAL_PERIOD =
  'DealerSubscribersRenewalPeriod';
export const DEALER_SUBSCRIBERS_NEARING_RENEWAL =
  'DealerSubscribersNearingRenewal';
// Dealer Dashboard

// Devices
export const RESOURCE_DEVICE = 'Devices/';
export const RESOURCE_SERVERS = 'Servers/';
export const RESOURCE_ALL_DEVICES = 'Devices';
export const RESOURCE_CAMERAGROUP = 'CameraGroups/';
export const RESOURCE_ALL_SERVERS_AND_CAMERAS = RESOURCE_DEVICE;
export const RESOURCE_GATEWAY = `${RESOURCE_ALL_DEVICES}/:id/Gateway`;
export const RESOURCE_CAMERAGROUP_GROUPS = 'CameraGroups/:id/Groups';
export const RESOURCE_EVENTS = 'Alarms';
export const RESOURCE_GATEWAYS = 'Gateways/';
export const RESOURCE_EVENT_CLIP_URL = 'Clips/:id';
export const RESOURCE_DEVICE_LOGS = `${RESOURCE_DEVICE}:id/Logs`;
export const GET_DEVICE_LOGS = `${RESOURCE_DEVICE_LOGS}/:logId`;
export const GATEWAY_DEVICE_ENTITIES = `${RESOURCE_GATEWAY}/entities`;
export const GATEWAY_ENTITY_SETTINGS = `${RESOURCE_GATEWAY}/entity-settings`;
export const DELETE_DEVICE = `${RESOURCE_DEVICE}:id`;
// Devices - claiming
export const CREATE_CLAIM_KEY = 'DeviceClaimKeys/:id';
// id is the locationId
export const GET_ACTIVATION_CODE = `${RESOURCE_LOCATIONS}:id/${RESOURCE_GATEWAYS}GetActivationCode`;
export const CLAIM_DEVICE_BY_ID = `${RESOURCE_LOCATIONS}:locationId/${RESOURCE_DEVICE}ClaimById/:id`;
export const CLAIM_DEVICE = `${RESOURCE_LOCATIONS}:locationId/${RESOURCE_DEVICE}ClaimByKey/:claimKey`;

export const RESOURCE_CAMERAS = 'Devices/:id/Cameras/';
export const RESOURCE_CAMERA_SNAPSHOT = `${RESOURCE_CAMERAS}:cameraId/Snapshot`;
export const CONNECT_CAMERA = `${RESOURCE_CAMERAS}:cameraId/connect`; // POST to connect, DELETE to disconnect
export const MANUALLY_DISCOVER_CAMERA = `${RESOURCE_CAMERAS}FindCamera`;
export const MANUALLY_DISCOVER_RTSP_CAMERA = `${RESOURCE_CAMERAS}FindRtspCamera`;
export const MANUALLY_DISCOVER_CAMERAS_BY_RANGE = `${RESOURCE_CAMERAS}FindCameras`;
export const DELETE_CAMERA = 'Cameras/:cameraId';
export const UNMANAGE_CAMERA = `${RESOURCE_CAMERAS}:cameraId`;
export const CAMERA_VIDEO_EXPORT = `${RESOURCE_CAMERAS}:cameraId/Export`;
export const CAMERA_VIDEO_GET_STREAM = `${CAMERA_VIDEO_EXPORT}/:exportId`;
export const RESOURCE_DEVICES = 'Devices';
export const RESOURCE_UPGRADE_DEVICE_FIRMWARE = 'FirmwareUpgrade/Trigger/';
export const RESOURCE_GET_LATEST_FIRMWARE = 'FirmwareUpgrade/:id/:platform';
export const ENABLE_CAMERA = 'Cameras/:id/Activate';
export const DISABLE_CAMERA = 'Cameras/:id/Deactivate';
export const ENABLE_TAMPER_EVENTS = 'Cameras/:id/EnableTamper';
export const DISABLE_TAMPER_EVENTS = 'Cameras/:id/DisableTamper';
export const ARM_CAMERA = 'Cameras/:id/Arm';
export const DISARM_CAMERA = 'Cameras/:id/Disarm';
export const ENABLE_CAMERA_FEATURE = 'Cameras/:id/EnableFeature';
export const DISABLE_CAMERA_FEATURE = 'Cameras/:id/DisableFeature';

// Get all cameras
export const RESOURCE_ALL_CAMERAS = 'Cameras';
export const GATEWAY_DEVICE_POE = `${RESOURCE_GATEWAY}/poe`;
export const GATEWAY_DEVICE_POE_INFO = `${RESOURCE_GATEWAY}/poe-info`;

// Get specific camera
export const GET_CAMERA = `${RESOURCE_ALL_CAMERAS}/`;

export const RESOURCE_MEDIA_PARAMS = `${RESOURCE_GATEWAY}/p2p-media`;
export const RESOURCE_GATEWAY_CAMERA_SETTINGS = `${RESOURCE_GATEWAY}/camera-settings`;
export const GATEWAY_CAMERA_ZOOM = `${RESOURCE_GATEWAY}/camera-zoom`;
export const GATEWAY_CAMERA_FOCUS = `${RESOURCE_GATEWAY}/camera-focus`;
export const GATEWAY_CAMERA_AUTOFOCUS = `${RESOURCE_GATEWAY}/camera-auto-focus`;
export const GATEWAY_CAMERA_FOCUS_RELATIVE = `${RESOURCE_GATEWAY}/camera-focus-relative`;
export const GATEWAY_CAMERA_FOCUS_CONTINUOUS = `${RESOURCE_GATEWAY}/camera-focus-continuous`;
export const GATEWAY_CAMERA_PAN_TILT_ZOOM = `${RESOURCE_GATEWAY}/camera-pan-tilt-zoom`;
export const RESOURCE_RESTART_DEVICE = `${RESOURCE_GATEWAY}/Reboot`;
export const RESOURCE_RESTART_CAMERA = `${RESOURCE_GATEWAY}/camera-reboot`;
export const GATEWAY_ANALYTICS = `${RESOURCE_GATEWAY}/analytics`;
export const GATEWAY_TIMELINE = `${RESOURCE_GATEWAY}/timeline`;
export const GATEWAY_UPDATE_SITE = `${RESOURCE_GATEWAY}/site`;
export const GATEWAY_UPDATE_TIME_ZONE = `${RESOURCE_GATEWAY}/date-time`;
export const GATEWAY_TRIGGER_DIGITAL_OUT = `${RESOURCE_GATEWAY}/camera-trigger-digital-output`;
export const GATEWAY_DIGITAL_OUT_CAMERA_LINKS = `${RESOURCE_GATEWAY}/camera-link`;
export const GATEWAY_PTZ_PRESET = `${RESOURCE_GATEWAY}/camera-ptz-preset`;
export const GATEWAY_UPDATE_PTZ_PRESET = `${RESOURCE_GATEWAY}/camera-preset`;
export const GATEWAY_CAMERA_PTZ_TOUR = `${RESOURCE_GATEWAY}/camera-ptz-tour`;
export const GATEWAY_CAMERA_PTZ_PRESET_HOME = `${RESOURCE_GATEWAY}/camera-preset-home`;
export const GATEWAY_START_PTZ_TOUR = `${RESOURCE_GATEWAY}/camera-tour`;
export const GATEWAY_STOP_PTZ_TOUR = `${RESOURCE_GATEWAY}/camera-tour-stop`;
export const RESOURCE_ALL_BOOKMARKS = 'Bookmarks';

// Servers
export const GET_DISCOVERED_CAMERAS = 'Devices/:id/DiscoveredCameras/';
export const GET_DISCOVERED_CAMERAS_RESULT =
  'Devices/:cameraId/DiscoveredCamerasResult/:id';
export const GET_CONNECTED_CAMERAS = 'Devices/:id/ConnectedCameras/';
export const GET_CONNECT_CAMERA_DRIVERS =
  'Devices/:id/Cameras/ConnectCameraDrivers/';
export const GATEWAY_GET_CAPABILITIES = `${RESOURCE_GATEWAY}/capabilities`;
export const GET_SERVER_TIME_ZONE_LIST = 'Servers/:id/TimeZoneList';
// Servers

// Users
export const RESOURCE_USERS = 'Users/';
export const DELETE_USER = `${RESOURCE_USERS}:id`; // slight hack to keep DELETE on the v1 api
export const RESOURCE_USERS_PUBLIC_INFO = 'UsersPublicInfo';
export const RESEND_INVITE = `${RESOURCE_USERS}:id/ResendInvite`;
export const RESET_PASSWORD = `${RESOURCE_USERS}:id/ResetPassword`; // reset by admin to random GUID
export const RESOURCE_SCHEDULES = 'Schedules';
export const RESOURCE_SCHEDULE = `${RESOURCE_SCHEDULES}/:id`;
export const RESOURCE_NOTICES = 'Notices';
export const GET_BANNER_NOTICES = `${RESOURCE_NOTICES}/Banner`;
// Alarms
export const RESOURCE_ALARMS = 'Alarms/';
export const ALARMS_ODATA = 'Alarms(:orgId)';
export const EDIT_ALARM = 'Alarms/:id';
export const ALARMS_STATS = `${RESOURCE_ALARMS}Stats`;

// Customers
export const RESOURCE_CURRENT_ORGANIZATION = 'Organizations/:id'; // The currently inhabited org
export const RESOURCE_ORGANIZATIONS = 'Subscribers/'; // Subscribers of the currently inhabited org
export const RESOURCE_ORGANIZATION = `${URL_INCLUDE_ORG_ID}/`;
export const CREATE_ORGANIZATION = 'Create';
export const EDIT_ORGANIZATION = '';
export const RESEND_CUSTOMER_INVITE = 'ResendInvite';
export const GET_CUSTOMER_ORGANIZATION = `${RESOURCE_ORGANIZATION}:id`;

// Integrations (SureView/Monitoring)
export const RESOURCE_INTEGRATIONS = 'IntegrationConfigurations';
export const EDIT_INTEGRATION_CONFIG = `${RESOURCE_INTEGRATIONS}/:id`;
export const CREATE_INTEGRATION_CONFIG = `${RESOURCE_INTEGRATIONS}/Create`;
export const CAN_DELETE_INTEGRATION_CONFIG = `${RESOURCE_INTEGRATIONS}/:id/CanDelete`;
export const DELETE_INTEGRATION_CONFIG = `${RESOURCE_INTEGRATIONS}/:id/Delete`;
export const RESOURCE_INTEGRATION_SUBSCRIBER_SUMMARY = `${RESOURCE_INTEGRATIONS}/SubscriberSummary`;
export const RESOURCE_INTEGRATION_SUBSCRIBER_SITES = `${RESOURCE_INTEGRATIONS}/Subscribers/:id`;
export const GET_CONNECTION_TEST_RESULT = `${RESOURCE_INTEGRATIONS}/:id/TestConnectivity`;
export const ENABLE_INTEGRATION_CONFIG = `${RESOURCE_INTEGRATIONS}/:id/Enable`;
export const GET_INTEGRATION_TYPES = `${RESOURCE_INTEGRATIONS}/Types`;
export const VALIDATE_CONNECTION_STRING = `${RESOURCE_INTEGRATIONS}/ValidateConnectionString`;

// Recording Settings
export const RESOURCE_RECORDING_SETTINGS = `${RESOURCE_DEVICE}:id/RecordingSettings`;

// Utilities
export const RESOURCE_UTILITIES = 'Utilities';
export const GET_REGIONAL_DEPLOYMENTS = `${RESOURCE_UTILITIES}/Regions`;
export const GET_TIMEZONES = `${RESOURCE_UTILITIES}/Timezones`;
export const RESOURCE_LINKS = `${RESOURCE_UTILITIES}/Links`;
export const GET_AI_KEY = `${RESOURCE_UTILITIES}/AppInsightsKey`;
// Utilities

// signalr hub
export const NOTIFICATION_CALLBACK = 'Callback/';

// views
export const RESOURCE_VIEWS = 'Views/';

// Contacts
export const RESOURCE_CONTACTS = 'Contacts/';

// All v2 paths
export const PATHS_V2 = [
  GET_ACTIVATION_CODE,
  GET_BANNER_NOTICES,
  GET_DEALER_SERVICE_PACKAGE_FAMILIES,
  GET_REGIONAL_DEPLOYMENTS,
  GET_SERVER_TIME_ZONE_LIST,
  GET_SERVICE_PACKAGE,
  GET_SERVICE_PACKAGES,
  GET_SERVICE_PACKAGE_BY_CODE,
  PENDING_SITE_REQUESTS_CREATE,
  RESOURCE_ALL_BOOKMARKS,
  RESOURCE_CLUSTERS,
  RESOURCE_ORGANIZATION,
  RESOURCE_SERVICE_PACKAGE_FAMILIES,
  SUBSCRIBER_SERVICE_PACKAGES,
  SUSPEND_LOCATIONS,
  UNSUSPEND_LOCATIONS,
  VALIDATE_PASSWORD,
  RESOURCE_CURRENT_ORGANIZATION,
  RESOURCE_SUBSCRIPTION,
];

// All v3 paths
export const PATHS_V3 = [
  CAN_DELETE_INTEGRATION_CONFIG,
  CREATE_INTEGRATION_CONFIG,
  DELETE_INTEGRATION_CONFIG,
  EDIT_INTEGRATION_CONFIG,
  ENABLE_INTEGRATION_CONFIG,
  GET_CONNECTION_TEST_RESULT,
  GET_INTEGRATION_TYPES,
  REGISTER_UNPROVISIONED,
  RESOURCE_ALL_SERVERS_AND_CAMERAS,
  RESOURCE_CUSTOMERS,
  RESOURCE_INTEGRATIONS,
  RESOURCE_INTEGRATION_SUBSCRIBER_SITES,
  RESOURCE_INTEGRATION_SUBSCRIBER_SUMMARY,
  RESOURCE_ORGANIZATIONS,
  RESOURCE_SERVERS,
  RESOURCE_USERS,
  VALIDATE_CONNECTION_STRING,
];

// All v4 paths
export const PATHS_V4 = [RESOURCE_LOCATIONS, RESOURCE_USER_PROFILE];

// All paths which require a /odata base path
export const PATHS_ODATA = [ALARMS_ODATA];

// Health Monitor
export const PATH_HEALTH_MONITOR = '/healthMonitor';
export const PATH_HMS_CONFIG = 'HealthMonitor/Config';
export const PATH_HMS_JWT = 'HealthMonitor/JWT';

// Reports
export const PATH_REPORTS = '/reports';

// All paths which do not require an organization id to be added before sending
export const PATHS_ORG_EXEMPT = [
  CHANGE_PASSWORD,
  CREATE_CLAIM_KEY,
  GET_AI_KEY,
  GET_BANNER_NOTICES,
  GET_CUSTOMER_ORGANIZATION,
  GET_INTEGRATION_TYPES,
  GET_REGIONAL_DEPLOYMENTS,
  GET_SERVICE_PACKAGE,
  GET_SERVICE_PACKAGES,
  GET_SERVICE_PACKAGE_BY_CODE,
  GET_TIMEZONES,
  PATH_HMS_CONFIG,
  PATH_HMS_JWT,
  REGISTER,
  REGISTER_UNPROVISIONED,
  REQUEST_RESET_PASSWORD,
  RESOURCE_ACCOUNTS,
  RESOURCE_ACCOUNT_ORGANIZATIONS,
  RESOURCE_CUSTOMERS,
  RESOURCE_DELETE_ORGANIZATION,
  RESOURCE_LINKS,
  RESOURCE_LOGIN,
  RESOURCE_LOGOUT,
  RESOURCE_SERVICE_PACKAGES,
  RESOURCE_SERVICE_PACKAGE_FAMILIES,
  RESOURCE_USER_PROFILE,
  VALIDATE_PASSWORD,
].concat(PATHS_ODATA);

// external links
export const PATH_INFO_REGIONS = 'https://connect.blue.avigilon.com/region/';

// top level routes
// when adding a NEW top-level navigation route, please
// also add it to authenticatedPaths or unauthenticatedPaths (below)
export const PATH_DEFAULT = '';
export const PATH_LOGIN = '/signin';
export const PATH_REGISTER = '/register';
export const PATH_SIGNUP_SELF_PROVISIONED = '/selfsignup';
export const PATH_GUEST = '/guest';
export const PATH_SIGNUP = '/signup';
export const PATH_REQUEST_RESET = '/requestReset';
export const PATH_RESET = '/reset';
export const PATH_CLAIM = '/claim';
export const PATH_ACTIVATION_SUCCESS = '/activation';
export const PATH_ACCOUNTS = '/accounts';
export const PATH_ALARMS = '/notifications';
export const PATH_DEVICES = '/devices';
export const PATH_DASHBOARD = '/subscriptioncenter'; // Dashboard is shown as Subscription Center
export const PATH_VIEWS = '/views';
export const PATH_PROFILE = '/profile';
export const PATH_FULLSCREEN_VIDEO = '/fullscreen';
export const PATH_BOOKMARKS = '/bookmarks';
// if PATH_INVALID_TOKEN is ever changed, a corresponding change MUST be made in the server code.
// the corresponding server code is in AccountsController.SignUpOrSignIn.
export const PATH_INVALID_TOKEN = '/invalidToken';

export const PATH_SERVERS = `${PATH_DEVICES}/servers`;
export const PATH_CAMERAS = `${PATH_DEVICES}/cameras`;

// route segments
export const PATH_SEGMENT_CAMERAS = '/cameras';
export const PATH_SEGMENT_GENERAL = '/general';
export const PATH_SEGMENT_SUBSCRIBERS = '/subscribers';
export const PATH_SEGMENT_SERVICE_PLANS = '/serviceplans';
export const PATH_SEGMENT_INFO = '/info';
export const PATH_SEGMENT_DEALERS = '/dealers';
export const PATH_SEGMENT_MONITORING = '/monitoring';
export const PATH_SEGMENT_SITES = '/sites';
export const PATH_SEGMENT_USERS = '/users';
export const PATH_SEGMENT_CONTACTS = '/contacts';
export const PATH_SEGMENT_SCHEDULES = '/schedules';
export const PATH_SEGMENT_MONITORING_CENTERS = '/centralstations';
export const PATH_SEGMENT_SITE_CONFIGURATION = '/siteconfigurations';
export const PATH_SEGMENT_DIGITAL_IO = '/digitalio';
export const PATH_SEGMENT_VIDEO_RETENTION = '/videoretention';
export const PATH_SEGMENT_RECORDING_SCHEDULE = '/recordingschedule';
export const PATH_SEGMENT_POE = '/poemanagement';
export const PATH_SEGMENT_VIDEO = '/video';
export const PATH_SEGMENT_AUDIO = '/audio';
export const PATH_SEGMENT_RULES = '/rules';
export const PATH_SEGMENT_PRIVACY = '/privacy';
export const PATH_SEGMENT_TAMPERING = '/tampering';
export const PATH_SEGMENT_ALL = '/all';
export const PATH_SEGMENT_HEALTH = '/health';
export const PATH_SEGMENT_SECURITY = '/security';
export const PATH_SEGMENT_LIST = '/list';
export const PATH_SEGMENT_SUMMARY = '/summary';
export const PATH_SEGMENT_PERSONAL_INFO = '/personalinfo';
export const PATH_SEGMENT_PASSWORD = '/password';
export const PATH_SEGMENT_FORMATS = '/formats';
export const PATH_SEGMENT_NEW = '/new';
export const PATH_CHANGE_PROVIDER = '/changeprovider';
export const PATH_SUBSCRIPTIONS = '/subscriptions';

export const authenticatedPaths = [
  // Must be logged in to view, will be shown with LoggedinFrame
  PATH_ACCOUNTS,
  PATH_ALARMS,
  PATH_BOOKMARKS,
  PATH_DASHBOARD,
  PATH_DEVICES,
  PATH_HEALTH_MONITOR,
  PATH_PROFILE,
  PATH_REPORTS,
  PATH_VIEWS,
];

export const unauthenticatedPaths = [
  // Can be viewed without logging in, no masthead/navigation/account info shown
  PATH_ACTIVATION_SUCCESS,
  PATH_CLAIM,
  PATH_DEFAULT,
  PATH_FULLSCREEN_VIDEO,
  PATH_GUEST,
  PATH_INVALID_TOKEN,
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_REQUEST_RESET,
  PATH_RESET,
  PATH_SIGNUP,
  PATH_SIGNUP_SELF_PROVISIONED,
];
