exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".panelSettings___m9YzN {\r\n  padding-bottom: 8px;\r\n  margin-bottom: 10px;\r\n  border-bottom: 2px dotted #e7e7e7;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/Panel/panelSettings.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,kCAAkC;CACnC","file":"panelSettings.css","sourcesContent":[".panelSettings {\r\n  padding-bottom: 8px;\r\n  margin-bottom: 10px;\r\n  border-bottom: 2px dotted #e7e7e7;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"panelSettings": "panelSettings___m9YzN"
};