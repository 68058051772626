import React from 'react';

// Constants
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Components
import { ModalContainer, PageMessage } from 'containers';
import { Translate } from 'react-localize-redux';
import { Button } from 'lib';

// Styles
import { buttonGroup, buttonGroupCenter } from 'sharedStyles/modalForms.css';
import { PropTypes } from 'prop-types';
import { modalContentError } from './styles.css';

const ErrorMessage = ({
  message,
  messageEmphasize,
  messageRegular,
  translatedMessage,
}) => {
  if (message) {
    return (
      <PageMessage
        messageStyle={messageStyleStrings.error}
        visible
        header={<Translate id="GENERAL_MESSAGES.ERROR_DEFAULT_TITLE" />}
        body={message}
      />
    );
  }
  if (messageEmphasize && messageRegular) {
    return (
      <PageMessage
        messageStyle={messageStyleStrings.error}
        visible
        header={messageEmphasize}
        body={messageRegular}
      />
    );
  }
  if (translatedMessage) {
    return (
      <PageMessage
        messageStyle={messageStyleStrings.error}
        visible
        translateBody={translatedMessage}
      />
    );
  }
};

const ErrorModal = ({
  hideModal,
  message,
  translatedMessage,
  messageEmphasize,
  messageRegular,
  reason,
  ERROR_REASON_PROMPT,
}) => (
  <ModalContainer
    modalTitle={<Translate id="GENERAL_MESSAGES.ERROR_DEFAULT_TITLE" />}
    handleCancel={hideModal}
  >
    <div className={modalContentError}>
      <ErrorMessage
        message={message}
        translatedMessage={translatedMessage}
        messageEmphasize={messageEmphasize}
        messageRegular={messageRegular}
      />
      <div>
        {ERROR_REASON_PROMPT ? (
          <Translate id="GENERIC_MESSAGES.ERROR_REASON" data={{ reason }} />
        ) : (
          ''
        )}
      </div>
      <div className={buttonGroup}>
        <div className={buttonGroupCenter}>
          <Button inputType="button" onClick={hideModal} text={<Translate id="BUTTONS.OK" />} />
        </div>
      </div>
    </div>
  </ModalContainer>
);

ErrorModal.defaultProps = {
  translatedMessage: '',
  hideModal() {},
  message: '',
  messageEmphasize: '',
  messageRegular: '',
  reason: '',
  ERROR_REASON_PROMPT: '',
};

ErrorModal.propTypes = {
  translatedMessage: PropTypes.string,
  hideModal: PropTypes.func,
  message: PropTypes.string,
  messageEmphasize: PropTypes.string,
  messageRegular: PropTypes.string,
  reason: PropTypes.string,
  ERROR_REASON_PROMPT: PropTypes.string,
};

export default ErrorModal;
