import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { ModalCancelIcon, TextInput } from 'components';
import { Button, GroupLayout } from 'lib';
import { CONFIRM_NAME_INPUT_ID } from 'constants/ElementId';
import { bindActionCreators } from 'redux';
import { hideModal } from 'actions/modal';
import { getLocationsScoped } from 'actions/locations';
import { ORG_STATUS_KEYS } from 'util/userRoles';
import {
  formHeader,
  buttonGroup,
  buttonGroupCenter,
  headerText,
  modalContent,
} from 'sharedStyles/modalForms.css';
import {
  marginBottom,
  marginBottom3x,
  modalContentConfirm,
  modalText,
  sitesToSuspendList,
  truncate,
} from './styles.css';

const DeleteSubscriberModal = ({
  actions,
  onOkClick,
  orgId,
  orgName,
  store: { isFetchingGetLocations, locations },
  title,
}) => {
  useEffect(() => {
    if (
      !isFetchingGetLocations ||
      typeof isFetchingGetLocations[orgId] === 'undefined'
    ) {
      actions.getLocations(orgId, null, true);
    }
  }, [actions, isFetchingGetLocations, orgId]);
  const [writtenOrgName, setWrittenOrgName] = useState('');
  const memoizedLocations = useMemo(() => {
    return locations.filter(location => location.TenantId === orgId);
  }, [orgId, locations]);
  const hasLocations = !!(
    isFetchingGetLocations &&
    isFetchingGetLocations[orgId] === false &&
    memoizedLocations.length > 0
  );
  const filterLocations = location => {
    const statuses = location.PackageSubscription.State.split(',').map(state =>
      state.trim(),
    );
    const lastStatus = statuses[statuses.length - 1];
    return (
      lastStatus === ORG_STATUS_KEYS.Activated.value ||
      lastStatus === ORG_STATUS_KEYS.Active.value
    );
  };
  const sitesAreActive =
    hasLocations && memoizedLocations.filter(filterLocations);
  let sitesName;
  if (sitesAreActive.length > 0) {
    sitesName = sitesAreActive.map(site => site.Name);
  }
  const suspendFirst = () =>
    sitesName.length === 1 ? (
      <>
        <p className={marginBottom3x}>
          <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_MESSAGE_SINGULAR" />
        </p>
        <h5 className={truncate}>{sitesName}</h5>
        <GroupLayout verticalSpacing="large">
          <Button
            buttonType="primary"
            id="deleteSubscriberCancelButton"
            inputType="button"
            onClick={actions.hideModal}
            text={<Translate id="BUTTONS.CLOSE" />}
          />
        </GroupLayout>
      </>
    ) : (
      <>
        <p className={marginBottom3x}>
          <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_MESSAGE_PLURAL" />
        </p>
        <ul className={sitesToSuspendList}>
          {sitesName.map(siteName => (
            <li key={siteName} className={truncate}>
              {siteName}
            </li>
          ))}
        </ul>
        <GroupLayout verticalSpacing="large">
          <Button
            buttonType="primary"
            id="deleteSubscriberCancelButton"
            inputType="button"
            onClick={actions.hideModal}
            text={<Translate id="BUTTONS.CLOSE" />}
          />
        </GroupLayout>
      </>
    );
  const terminateOrg = () => (
    <>
      <p className={marginBottom3x}>
        <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_MESSAGE_2" />
      </p>
      <h5 className={`${marginBottom3x} ${truncate}`}>{orgName}</h5>
      <p className={marginBottom3x}>
        <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_MESSAGE_3" />
      </p>
      <p className={marginBottom}>
        <Translate id="ORGANIZATIONS.TERMINATE_SUBSCRIBER_MODAL.TERMINATE_SUBSCRIBER_CTA" />
      </p>
      <TextInput
        id={CONFIRM_NAME_INPUT_ID}
        onChange={event => setWrittenOrgName(event.target.value)}
        value={writtenOrgName}
      />
      <div className={buttonGroup}>
        <GroupLayout additionalClasses={buttonGroupCenter}>
          <Button
            buttonType="primary"
            id="deleteSubscriberCancelButton"
            inputType="button"
            onClick={actions.hideModal}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
          <Button
            buttonType="primary"
            disabled={writtenOrgName !== orgName}
            id="deleteSubscriberCancelButton"
            inputType="button"
            onClick={onOkClick}
            text={<Translate id="BUTTONS.TERMINATE" />}
          />
        </GroupLayout>
      </div>
    </>
  );
  const renderModal = () => (sitesName ? suspendFirst() : terminateOrg());
  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className={modalContent}>
        <div className={formHeader}>
          <div className={headerText}>{title}</div>
          <ModalCancelIcon handleCancel={actions.hideModal} />
        </div>
        <div className={`${modalContentConfirm} ${modalText}`}>
          {!isFetchingGetLocations || isFetchingGetLocations[orgId] ? (
            <div>
              <Translate id="GENERAL_MESSAGES.LOADING_HEADER" />
            </div>
          ) : (
            renderModal()
          )}
        </div>
      </div>
    </div>
  );
};

DeleteSubscriberModal.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  onOkClick: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

DeleteSubscriberModal.defaultProps = {
  orgId: '',
  orgName: '',
};

const mapStateToProps = (
  {
    isFetching: { getLocationsScoped: getLocationsFetcher },
    locations: { locations },
  },
  ownProps,
) => ({
  store: {
    isFetchingGetLocations: getLocationsFetcher,
    locations,
  },
  ...ownProps,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getLocations: getLocationsScoped,
      hideModal,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteSubscriberModal);
