import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconViewDropdown3x3 = props => {
  const { className, fill, height, translate, width } = props;

  return (
    <svg
      className={className}
      height={height}
      version="1.1"
      viewBox="0 0 17 15"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{translate('VIEWS.LAYOUT_TOOLTIP')}</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-1045.000000, -57.000000)">
          <g transform="translate(110.000000, 20.000000)">
            <path d="M947,47 L952,47 L952,51 L947,51 L947,47 Z M947,42 L952,42 L952,46 L947,46 L947,42 Z M947,37 L952,37 L952,41 L947,41 L947,37 Z M941,47 L946,47 L946,51 L941,51 L941,47 Z M941,42 L946,42 L946,46 L941,46 L941,42 Z M941,37 L946,37 L946,41 L941,41 L941,37 Z M935,47 L940,47 L940,51 L935,51 L935,47 Z M935,42 L940,42 L940,46 L935,46 L935,42 Z M935,37 L940,37 L940,41 L935,41 L935,37 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconViewDropdown3x3.defaultProps = {
  className: null,
  fill: '#000000',
  height: '15px',
  width: '17px',
};

IconViewDropdown3x3.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconViewDropdown3x3);
