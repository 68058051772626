// Information in the Utilities reducer is not organization or user specific and
// should only need to be fetched once per app load.

import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import { generateSort } from '../util/generateSort';

export default function utilitiesReducer(state, action = {}) {
  switch (action.type) {
    case types.RECEIVE_REGIONAL_DEPLOYMENTS: {
      if (!action.RegionalDeployments) {
        return state;
      }
      return Object.assign({}, state, {
        isWorldwideCentral: action.IsWorldwideCentral || false,
        regionalDeployments: action.RegionalDeployments.sort(
          generateSort(region => region.Name.toUpperCase()),
        ),
      });
    }

    case types.RECEIVE_TIMEZONES: {
      const timezones = action.timezones.sort((zone1, zone2) => {
        const v1 = parseInt(zone1.Value, 10);
        const v2 = parseInt(zone2.Value, 10);
        if (v1 > v2) {
          return 1;
        }
        if (v2 > v1) {
          return -1;
        }
        const k1 = zone1.Key.toLowerCase();
        const k2 = zone2.Key.toLowerCase();
        if (k1 > k2) {
          return 1;
        }
        if (k2 > k1) {
          return -1;
        }
        return 0;
      });
      return Object.assign({}, state, { timezones });
    }

    case types.RECEIVE_SERVER_TIME_ZONE_LIST: {
      return Object.assign({}, state, {
        timeZoneLists: {
          ...state.timeZoneLists,
          [action.serverId]: action.timeZoneList,
        },
      });
    }

    case types.RECEIVE_AI_KEY: {
      if (action.key) {
        return Object.assign({}, state, { aiKey: action.key });
      }
      return state;
    }

    case types.RECEIVE_LINKS: {
      const links = {};
      action.links.forEach(element => {
        links[element.Key] = element.Value;
      });
      return Object.assign({}, state, {
        links,
      });
    }

    default:
      return state || initialState().utilities;
  }
}
