import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'lib';
import * as oasisStyles from './Oasis.css';

function CustomerModule({ profile, proxyLocked, resetProxy, saveProxy }) {
  if (profile.CustomerTenantId === undefined) {
    return '';
  }
  return (
    <>
      <div className={oasisStyles.proxy}>
        <div>Proxied to: {profile.CustomerTenantName || 'Not Proxied'}</div>
        <div className={oasisStyles.buttonSet}>
          {localStorage.proxyid === undefined &&
          profile.CustomerTenantId &&
          !proxyLocked ? (
            <Button
              buttonClass={oasisStyles.clickable}
              inputType="button"
              onClick={saveProxy}
              text="Lock"
            />
          ) : (
            <Button
              buttonClass={oasisStyles.clickable}
              inputType="button"
              onClick={resetProxy}
              text="Unlock"
            />
          )}
        </div>
      </div>
    </>
  );
}

CustomerModule.defaultProps = {
  proxyLocked: false,
};

CustomerModule.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  proxyLocked: PropTypes.bool,
  resetProxy: PropTypes.func.isRequired,
  saveProxy: PropTypes.func.isRequired,
};

export default CustomerModule;
