import React from 'react';
import moment from 'moment';
import { Translate } from 'react-localize-redux';

export const ALARM_STATUS = [
  { text: 'ALARMS.STATUS.UNREVIEWED_LABEL', value: 'Unreviewed' },
  { text: 'ALARMS.STATUS.IN_REVIEW_LABEL', value: 'In-Review' },
  { text: 'ALARMS.STATUS.REVIEWED_LABEL', value: 'Reviewed' },
];

export const chartHeaderText = (appliedFilter, dataLength) => {
  let timeSpan;
  switch (dataLength + 1) {
    case 0:
    case 1: {
      timeSpan = <Translate id="ALARMS.HEADER.TIMESPAN_24_HOURS" />;
      break;
    }
    case 3: {
      timeSpan = <Translate id="ALARMS.HEADER.TIMESPAN_3_DAYS" />;
      break;
    }
    case 7: {
      timeSpan = <Translate id="ALARMS.HEADER.TIMESPAN_7_DAYS" />;
      break;
    }
    case 30: {
      timeSpan = <Translate id="ALARMS.HEADER.TIMESPAN_30_DAYS" />;
      break;
    }
    default: {
      timeSpan = (
        <Translate
          id="ALARMS.HEADER.TIMESPAN_N_DAYS"
          data={{ numDays: dataLength }}
        />
      );
    }
  }
  return appliedFilter ? (
    <Translate
      id="ALARMS.HEADER.SHOWING_WITH_FILTER"
      data={{
        filter: appliedFilter,
        timeSpan,
      }}
    />
  ) : (
    <Translate id="ALARMS.HEADER.SHOWING_LABEL" data={{ timeSpan }} />
  );
};

const getActivityTimeValue = momentValue => {
  return (
    <Translate
      id="ALARMS.DATA_ROWS.MOMENT_AGO"
      data={{ moment: moment.duration(momentValue).humanize() }}
    />
  );
};

export const tableDataRows = {
  UnreviewedCount: {
    label: <Translate id="ALARMS.STATUS.UNREVIEWED_LABEL" />,
    field: 'Status',
    getValue: v => v,
    getFilterValues: () => ['Unreviewed'], // Database enum, not a user-readable string. Do not translate.
  },
  InReviewCount: {
    label: <Translate id="ALARMS.STATUS.IN_REVIEW_LABEL" />,
    field: 'Status',
    getValue: v => v,
    getFilterValues: () => ['In-Review'], // Database enum, not a user-readable string. Do not translate.
  },
  OldestUnreviewedActivityTime: {
    label: <Translate id="ALARMS.DATA_ROWS.OLDEST_UNREVIEWED_NOTIFICATION" />,
    field: 'Id',
    typeOfValue: 'number',
    getValue: v => getActivityTimeValue(v),
    getFilterValues: rawTableData => [rawTableData.OldestUnreviewedActivity],
  },
  SitesWithUnreviewedActivities: {
    label: <Translate id="ALARMS.DATA_ROWS.SITES_REQUIRING_ATTENTION" />,
    field: 'Status',
    getValue: v => v.length,
    getFilterValues: () => ['Unreviewed'], // Database enum, not a user-readable string. Do not translate.
  },
  CamerasWithUnreviewedActivities: {
    label: <Translate id="ALARMS.DATA_ROWS.CAMERAS_REQUIRING_ATTENTION" />,
    field: 'Status',
    getValue: v => v.length,
    getFilterValues: () => ['Unreviewed'], // Database enum, not a user-readable string. Do not translate.
  },
};
