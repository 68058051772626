export const CAMERA_TYPES = {
  JSON_ERROR_CONNECTION_STATUS: 'Invalid connection status json',
  CAMERA_AUTH_REQUIRED: 'AUTHENTICATE',
  ADDING_CAMERA: 'ADDING CAMERA',
  CAMERA_CHECKING_SUBSCRIPTION: 'CHECKING SUBSCRIPTION',
  CAMERA_OVER_SUBSCRIPTION: 'DISABLED (Over Subscription)',
  CAMERA_DEFAULT_ERROR: 'CONNECTION FAILURE',
  DISABLED_BY_USER: 'DISABLED',
  ENABLE_CAMERA: 'ENABLE',
};

// Managed status
export const MANAGED_CAMERA = 'MANAGED';
export const UNMANAGED_CAMERA = 'UNMANAGED';

// Connection Status
export const CAMERA_CONNECTED = 'CONNECTED';
export const CAMERA_DISCONNECTED = 'DISCONNECTED';
export const CAMERA_AUTH_FAILED = 'AUTH_FAILED';
export const CAMERA_UNKNOWN_ERROR = 'UNKNOWN_ERROR';
export const CAMERA_ADDING_CAMERA = 'ADDING_CAMERA';
export const CAMERA_DISABLED = 'CAMERA_DISABLED';
export const CAMERA_DISABLED_PENDING_SUBSCRIPTION_CHECK =
  'DisabledPendingSubscriptionCheck';
export const CAMERA_DISABLED_DUE_TO_SUBSCRIPTION_LIMIT =
  'DisabledDueToSubscriptionLimit';
export const CAMERA_DISABLED_BY_USER = 'DisabledByUser';
export const CAMERA_FACTORY_DEFAULT = 'FACTORY_DEFAULT';

// Camera Status Text
export const CAMERA_IS_CONNECTED = CAMERA_CONNECTED;
export const CAMERA_IS_DISCONNECTED = CAMERA_DISCONNECTED;
export const {
  JSON_ERROR_CONNECTION_STATUS,
  CAMERA_AUTH_REQUIRED,
  ADDING_CAMERA,
  CAMERA_CHECKING_SUBSCRIPTION,
  CAMERA_OVER_SUBSCRIPTION,
  CAMERA_DEFAULT_ERROR,
  DISABLED_BY_USER,
  ENABLE_CAMERA,
} = {
  ...CAMERA_TYPES,
};

// Camera Origin
export const cameraOrigins = {
  CAMERA_CLOUD: 'Cloud',
  CAMERA_REMOTE: 'Remote',
};

// Camera features
export const AUTO_TBE = {
  name: 'TBE',
  label: 'TAMPERING.AUTO_TBE_LABEL_HEADER',
};
