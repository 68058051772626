import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Translate } from 'react-localize-redux';
/* Styles */
import { PropTypes } from 'prop-types';
import { callout } from './styles.css';

class Callout extends Component {
  handleClickOutside = () => {
    const { handleClick } = this.props;

    handleClick();
  };

  render() {
    const { handleClick, left, position, top, translateId } = this.props;

    const wrapperStyle = {
      left,
      position,
      top,
    };
    return (
      <div style={wrapperStyle}>
        <div
          className={callout}
          onClick={() => {
            handleClick();
          }}
          onKeyPress={() => {
            handleClick();
          }}
          role="button"
          tabIndex={0}
        >
          <Translate id={translateId} />
        </div>
      </div>
    );
  }
}

Callout.defaultProps = {
  handleClick: () => {},
  left: 0,
  position: 'absolute',
  top: 0,
  translateId: '',
};

Callout.propTypes = {
  handleClick: PropTypes.func,
  left: PropTypes.number,
  position: PropTypes.string,
  top: PropTypes.number,
  translateId: PropTypes.string,
};

export default onClickOutside(Callout);
