export const columnDataFields = {
  fieldName: 'Name',
  fieldPhoneNo: 'PhoneNumber',
  fieldSite: 'Sites',
};

export const FIELD_NAME = 'name';
export const FIELD_PHONE = 'phone';
export const FIELD_SITE = 'site';

export const iconColors = {
  BLACK: 'black',
  WHITE: 'white',
};

export const FIELD_ORDER = [FIELD_NAME, FIELD_SITE, FIELD_PHONE];
