import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Styles
import { activeItem, menuItem } from './DropdownItem.style.css';

const DropdownItem = ({ children, id, onClick }) => {
  const [active, setActive] = useState(false);
  function handleClick(ev) {
    if (onClick) {
      onClick(ev);
    }
  }

  const mouseEnter = () => {
    setActive(true);
  };

  const mouseLeave = () => {
    setActive(false);
  };

  return (
    <div
      className={active ? `${menuItem} ${activeItem}` : menuItem}
      id={id}
      onClick={handleClick}
      onKeyPress={handleClick}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      role="button"
      tabIndex="0"
    >
      {children}
    </div>
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

DropdownItem.defaultProps = {
  children: null,
  id: '',
  onClick: () => {},
};

export default DropdownItem;
