import React from 'react';
import PropTypes from 'prop-types';
import { Button, GroupLayout, OGTable } from 'lib';
import { Translate } from 'react-localize-redux';
import OasisModule from './OasisModule';

function EnvironmentModule(props) {
  const { defaultEnvs, updateModuleState } = props;
  const setEnvironment = newEnvironment => {
    // OGTable row click
    if (typeof newEnvironment === 'object') {
      const { url } = newEnvironment;
      return props.setEnvironment(url);
    }
    // requestNewEnvironment
    if (typeof newEnvironment === 'string') {
      return props.setEnvironment(newEnvironment);
    }
    /* global avoLogError */
    avoLogError('newEnvironment has no url for setEnvironment', newEnvironment);
  };

  const removeEnvironment = environmentToRemove => {
    props.removeEnvironment(environmentToRemove);
  };

  const requestNewEnvironment = () => {
    let newEnvironment = prompt('Environment to Add');
    if (!/\/$/.test(newEnvironment)) {
      newEnvironment += '/';
    }
    setEnvironment(newEnvironment);
  };

  const { history } = props;
  const HISTORY_DATA =
    (history &&
      history.map(historyItem => {
        return { url: historyItem };
      })) ||
    [];
  const FIELD_ORDER = ['url'];
  const HEADER_LABELS = {
    url: 'Dev Environment History',
  };
  return (
    <OasisModule
      moduleId="oasis-environment"
      theme="magenta"
      title="Environment"
      updateModuleState={updateModuleState}
    >
      <OGTable
        bodyHeight={125}
        data={HISTORY_DATA}
        fieldOrder={FIELD_ORDER}
        headerLabels={HEADER_LABELS}
        resizable
        resizableFrom="top"
        rowActions={[
          ({rowData}) => {
            return (
              <Button
                compact
                onClick={() => {
                  setEnvironment(rowData.url);
                }}
                size="small"
                text="Activate"
              />
            );
          },
          ({rowData}) => {
            if (defaultEnvs.includes(rowData.url)) {
              return '';
            }
            return (
              <Button
                compact
                onClick={() => {
                  removeEnvironment(rowData.url);
                }}
                size="small"
                text="Delete"
              />
            );
          },
        ]}
        sortType="local"
      />
      <GroupLayout dropshadow verticalCenter verticalSpacing="none">
        <Button
          inputType="button"
          onClick={requestNewEnvironment}
          text="Add Environment"
        />
      </GroupLayout>
    </OasisModule>
  );
}

EnvironmentModule.defaultProps = {
  history: [],
};

EnvironmentModule.propTypes = {
  history: PropTypes.arrayOf(PropTypes.string),
  removeEnvironment: PropTypes.func.isRequired,
  setEnvironment: PropTypes.func.isRequired,
};

export default EnvironmentModule;
