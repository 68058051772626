import { FORM_DEFAULTS } from 'constants/app';
import * as validation from 'util/validation';

export const USER_FORM_FIELDS = {
  email: {
    key: 'AdminEmailAddress',
    label: 'COMMON.EMAIL',
    name: 'AdminEmailAddress',
    placeholder: 'USERS.USER_FORM_FIELDS.PLACEHOLDERS.EMAIL',
    userObjectField: 'EmailAddress',
    validate: [validation.required, validation.email],
  },
  firstName: {
    key: 'AdminFirstName', // Corresponds to name looked for in addUserIfNeeded
    label: 'USERS.USER_FORM_FIELDS.LABELS.FIRST_NAME',
    name: 'AdminFirstName', // Corresponds to name of field in User object returned by API
    userObjectField: 'FirstName',
    validate: validation.required,
  },
  id: {
    key: 'AdminUserId',
    label: 'USERS.USER_FORM_FIELDS.LABELS.ID',
    // userObjectField: 'Id',
    name: 'AdminUserId',
    select: true,
  },
  lastName: {
    key: 'AdminLastName',
    label: 'USERS.USER_FORM_FIELDS.LABELS.LAST_NAME',
    name: 'AdminLastName',
    userObjectField: 'LastName',
    validate: validation.required,
  },
  phone: {
    key: 'AdminPhoneNumber',
    label: 'USERS.USER_FORM_FIELDS.LABELS.PHONE',
    name: 'AdminPhoneNumber',
    userObjectField: 'PhoneNumber',
  },
  timezone: {
    key: 'AdminTimeZone',
    label: 'USERS.USER_FORM_FIELDS.LABELS.TIMEZONE',
    name: 'AdminTimeZone',
    select: true,
    userObjectField: 'TimeZone',
    validate: validation.required,
  },
};

export const adminUserNew = FORM_DEFAULTS.new;
export const missingAdminIdField = `Warning: AddCreateUser form was initialized without ${
  USER_FORM_FIELDS.id.name
} data. Selected user id will populate a new ${
  USER_FORM_FIELDS.id.name
} field.`;
