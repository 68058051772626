// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import { DashboardContainer } from 'containers';

// Actions
import * as UserActions from 'actions/user';

// Utilities
import { getFullPath } from 'util/getPathName';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Styles
import { contentContainer } from 'sharedStyles/global.css';

function CustomersContainer(props) {
  return (
    <>
      <DashboardContainer {...props} />
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...state,
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    fullPath: getFullPath(ownProps.location.pathname),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...UserActions }, dispatch),
  };
}

CustomersContainer.propTypes = {
  canChangeCustomer: PropTypes.bool,
  children: PropTypes.node,
  fullPath: PropTypes.string.isRequired,
};

CustomersContainer.defaultProps = {
  canChangeCustomer: false,
  children: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withAITracking(
    ai.reactPlugin,
    CustomersContainer,
    'CustomersContainer',
    contentContainer,
  ),
);
