import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import * as OrganizationActions from 'actions/organizations';

import { Translate } from 'react-localize-redux';

class MonitoringConnectionTestContainer extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.testValue === undefined &&
      this.props.testValue !== null &&
      this.props.testValue !== undefined
    ) {
      setInterval(this.resetConnectionTest, 3000);
    }
  }

  handleTestConnection = () => {
    this.props.actions.testIntegrationConnection(this.props.configurationId);
  };

  resetConnectionTest = () => {
    this.props.actions.receiveTestConnectionResult(
      this.props.configurationId,
      null,
    );
    // Removes corresponding test data from the reducer
  };

  render() {
    return (
      <div onClick={this.handleTestConnection}>
        <Translate id="INTEGRATIONS.TEST_CONNECTION_LINK" />
        {this.props.testValue !== undefined && this.props.testValue}
      </div>
    );
  }
}

MonitoringConnectionTestContainer.propTypes = {
  configurationId: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  const configurationId = { ownProps };
  const testValue = state.organizations.integrationConfigTest[configurationId];
  return {
    testValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, OrganizationActions),
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonitoringConnectionTestContainer);
