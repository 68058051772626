import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { SubscriptionStateNotice } from 'components';
import { Button, Icon, ListView } from 'lib';
import { truncatedText } from 'sharedStyles/tables.css';
import { pendingRequestColoredDot } from 'sharedStyles/styles.css';
import PropTypes from 'prop-types';
import { BULK_ACTION_TABLES, SERVICE_FAMILIES } from 'constants/app';
import {
  IC_SITE_SUSPEND,
  IC_SITE_UNSUSPEND,
  IC_TRASH,
} from 'constants/iconNames';
import { convertToShortFormat } from 'util/convertTimeTo';
import { isSelfServiceableSite } from 'util/validation';
import ActivationDateContainer from './ActivationDateContainer';
import { SITE_TABLE_FIELDS } from './constants';
import {
  inlineSubscriptionState,
  nameWrapper,
  siteName,
  statusDiv,
} from './LocationsTable.css';

class LocationsTable extends Component {
  handleRowClick = (data, index) => {
    const { onRowClicked } = this.props;
    onRowClicked(data, index);
  };

  getDisplayFields = tableFields => {
    const { access } = this.props;
    const {
      ACTIVATION_DATE,
      BILLING_ID,
      CAMERAS,
      DEVICE_COUNT,
      NAME,
      PURCHASE_ORDER_NUMBER,
    } = tableFields;
    let fieldOrder = [
      NAME,
      BILLING_ID,
      PURCHASE_ORDER_NUMBER,
      DEVICE_COUNT,
      CAMERAS,
      ACTIVATION_DATE,
    ];
    if (!access) {
      const hideColumns = [BILLING_ID, PURCHASE_ORDER_NUMBER];
      fieldOrder = fieldOrder.filter(field => !hideColumns.includes(field));
    }
    return fieldOrder;
  };

  render() {
    const {
      canBulkDeleteLocations,
      canDeleteLocation,
      canSuspendLocations,
      handleDeleteClick,
      handleSuspendClick,
      handleUnsuspendClick,
      locations,
      preferredShortDateFormat,
      servicePackages,
      translate,
    } = this.props;
    const {
      BILLING_ID,
      CAMERAS,
      DEVICE_COUNT,
      NAME,
      PURCHASE_ORDER_NUMBER,
      RENEWAL_DATE,
      SUBSCRIPTION,
      TERM,
    } = SITE_TABLE_FIELDS;

    const translationIds = {
      ActivationDate: 'GENERAL_TABLE.HEADER.ACTIVATION_DATE_LABEL',
      BillingNumber: 'GENERAL_TABLE.HEADER.BILLING_ID_LABEL',
      Cameras: 'COMMON.CAMERAS',
      DeviceCount: 'GENERAL_TABLE.HEADER.SERVERS_LABEL',
      IsAutoRenewable: 'GENERAL_TABLE.HEADER.AUTO_RENEWAL',
      Name: 'COMMON.SITE',
      PurchaseOrderNumber: 'GENERAL_TABLE.HEADER.ORDER_ID_LABEL',
      RenewalDate: 'GENERAL_TABLE.HEADER.RENEWAL_DATE_LABEL',
      Subscription: 'GENERAL_TABLE.HEADER.SUBSCRIPTION_LABEL',
      Term: 'GENERAL_TABLE.HEADER.TERM_LABEL',
    };
    const renderSubscription = row => {
      const statusCells = [];
      statusCells.push(
        <SubscriptionStateNotice
          key={row.Id}
          className={inlineSubscriptionState}
          shorthand
          subscriptionState={row.subscriptionState}
        />,
      );
      return (
        <div>
          <div
            key={SUBSCRIPTION}
            alt={row[SUBSCRIPTION]}
            className={truncatedText}
          >
            <span>{row[SUBSCRIPTION]}</span>
          </div>
          <div key="statusTag" className={truncatedText}>
            {statusCells}
          </div>
        </div>
      );
    };

    const renderName = rowData => {
      const statusCells = [];
      statusCells.push(
        <SubscriptionStateNotice
          key={rowData.Id}
          className={inlineSubscriptionState}
          shorthand
          subscriptionState={rowData.subscriptionState}
        />,
      );

      return (
        <span>
          {rowData.PackageSubscription &&
          rowData.PackageSubscription.ChangeRequestGrantDate ? (
            <div
              className={`${pendingRequestColoredDot} ${statusDiv}`}
              title={translate('ACCOUNTS.LOCATIONS.PENDING_SUBSCRIPTION')}
            />
          ) : (
            ''
          )}
          <div className={nameWrapper}>
            <span className={siteName}>{rowData[NAME]}</span>
            <span key="statusTag" className={truncatedText}>
              {statusCells}
            </span>
          </div>
        </span>
      );
    };

    const customHeaderClass = {
      Name: siteName,
    };

    const customCells = {
      ActivationDate: (rowData, idx, expanded, selected) => (
        <ActivationDateContainer selected={selected} site={rowData} />
      ),
      BillingNumber: rowData => <span>{rowData[BILLING_ID]}</span>,
      Cameras: rowData => <span>{rowData[CAMERAS]}</span>,
      DeviceCount: rowData => <span>{rowData[DEVICE_COUNT]}</span>,
      IsAutoRenewable: rowData => (
        <span>
          {rowData.PackageSubscription &&
          rowData.PackageSubscription.IsAutoRenewable
            ? translate('COMMON.YES')
            : translate('COMMON.NO')}
        </span>
      ),
      Name: rowData => renderName(rowData),
      PurchaseOrderNumber: rowData => (
        <span>{rowData[PURCHASE_ORDER_NUMBER]}</span>
      ),
      RenewalDate: rowData => (
        <span>
          {rowData[RENEWAL_DATE]
            ? convertToShortFormat(
                rowData[RENEWAL_DATE],
                preferredShortDateFormat,
              )
            : translate('GENERAL_TABLE.BODY.NOT_APPLICABLE_LABEL')}
        </span>
      ),
      Subscription: rowData => renderSubscription(rowData),
      Term: rowData => <span>{rowData[TERM]}</span>,
    };
    const ROW_ACTIONS = [
      ({ rowData, selected }) =>
        canSuspendLocations ? (
          <Button
            icon
            inputType="button"
            onClick={
              rowData.subscriptionState &&
              !rowData.subscriptionState.isSuspended
                ? () => {
                    handleSuspendClick(rowData);
                  }
                : () => {
                    handleUnsuspendClick(rowData);
                  }
            }
          >
            <Icon
              color={selected ? 'white' : 'black'}
              id={
                rowData.subscriptionState &&
                !rowData.subscriptionState.isSuspended
                  ? IC_SITE_SUSPEND
                  : IC_SITE_UNSUSPEND
              }
              title={
                !rowData.subscriptionState.isSuspended
                  ? 'BUTTONS.SUSPEND'
                  : 'BUTTONS.UNSUSPEND'
              }
            />
          </Button>
        ) : (
          ''
        ),
      ({ rowData, selected }) =>
        canDeleteLocation && isSelfServiceableSite(rowData, servicePackages) ? (
          <Button
            icon
            inputType="button"
            onClick={e => {
              e.stopPropagation();
              handleDeleteClick(
                [rowData.Id],
                rowData.PackageSubscription.ServiceFamily ===
                  SERVICE_FAMILIES.AccCloudServices,
              );
            }}
          >
            <Icon
              color={selected ? 'white' : 'black'}
              id={IC_TRASH}
              title="BUTTONS.DELETE"
            />
          </Button>
        ) : (
          ''
        ),
    ];
    return (
      <ListView
        bulkActions={canBulkDeleteLocations}
        bulkActionsTable={BULK_ACTION_TABLES.LOCATIONS}
        cellWidths={{ Actions: 50 }}
        clickableRows
        customCells={customCells}
        customHeaderClass={customHeaderClass}
        data={locations}
        fieldOrder={this.getDisplayFields(SITE_TABLE_FIELDS)}
        fillContainerHeight
        headerTranslationIds={translationIds}
        persistFilterAs="locations"
        resizable={false}
        rowActions={ROW_ACTIONS}
        rowClickCallback={this.handleRowClick}
        sortType="local"
      />
    );
  }
}

LocationsTable.propTypes = {
  access: PropTypes.bool,
  canBulkDeleteLocations: PropTypes.bool,
  canDeleteLocation: PropTypes.bool,
  canSuspendLocations: PropTypes.bool,
  handleDeleteClick: PropTypes.func.isRequired,
  handleSuspendClick: PropTypes.func.isRequired,
  handleUnsuspendClick: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.any),
  onRowClicked: PropTypes.func.isRequired,
  preferredShortDateFormat: PropTypes.string,
  servicePackages: PropTypes.arrayOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

LocationsTable.defaultProps = {
  access: false,
  canBulkDeleteLocations: false,
  canDeleteLocation: false,
  canSuspendLocations: false,
  locations: [],
  preferredShortDateFormat: '',
  servicePackages: [],
};

export default withLocalize(LocationsTable);
