// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

// Components
import { PlayerHost } from 'components';

// Constants
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import { cameraSupportsPtz } from 'util/cameraSettingPermission';

// Styles
import {
  videoPlayer,
  emptyVideoContainer,
  offlineErrorContainer,
  offlineErrorMessage,
} from './styles.css';

class NotificationStreamVideo extends Component {
  // TODO: General : Remove unused
  componentDidMount() {}

  renderError = errorString => (
    <div className={offlineErrorContainer}>
      <span className={offlineErrorMessage}>{errorString}</span>
    </div>
  );

  render() {
    const mediaPlayerProperties = {
      width: this.props.playerWidth,
      height: this.props.playerHeight,
    };
    return (
      <div className={videoPlayer}>
        {this.props.camera ? (
          <div style={mediaPlayerProperties}>
            <PlayerHost
              camera={this.props.camera}
              id={this.props.camera.Id}
              key={this.props.camera.Id}
              width={this.props.playerWidth}
              height={this.props.playerHeight}
              startTime={this.props.eventStartTime}
              deviceId={this.props.camera.DeviceId}
              cameraId={this.props.camera.Id}
              cameraRemoteId={this.props.camera.RemoteId}
              isDisconnected={
                this.props.camera.ConnectionState !== CAMERA_CONNECTED
              }
              getClipVideo={this.props.getClipVideo}
              showRulesOverlay={this.props.showRulesOverlay}
              setRulesOverlayVisibility={this.props.setRulesOverlayVisibility}
              autofocus={this.props.autofocus}
              deviceName={this.props.deviceName}
              cameraName={this.props.cameraName}
              siteName={this.props.siteName}
              showCanvas={this.props.showRulesOverlay}
              canEditROI={false}
              cameraResWidth={this.props.camera.DefaultWidth}
              cameraResHeight={this.props.camera.DefaultHeight}
              canvasItems={
                this.props.rules ? Object.values(this.props.rules) : []
              }
              tenantId={this.props.camera.TenantId}
              hideOverlayToggle={this.props.cameraSupportsPtz}
            />
          </div>
        ) : (
          <div
            className={emptyVideoContainer}
            style={{
              width: `${this.props.playerWidth}px`,
              height: `${this.props.playerHeight}px`,
            }}
            /* Already calculated pixel width and height for player, might as well use them */
          >
            {this.props.camera &&
              this.props.camera.ConnectionState !== CAMERA_CONNECTED &&
              this.renderError(
                <Translate id="ALARMS.VIDEO.CAMERA_OFFLINE_ERROR" />,
              )}
          </div>
        )}
      </div>
    );
  }
}

function mapWrapperStateToProps(state, ownProps) {
  const alarm = state.alarms.selectedAlarm;
  let cameraId = '';
  let deviceId = '';
  let camera = {};
  let cameraDevice = {};
  let doesCameraSupportPtz = false;
  if (alarm) {
    cameraId = alarm.CameraId;
    deviceId = alarm.DeviceId;
    camera =
      ownProps.camera ||
      state.devices.cameras.find(c => c.Id === cameraId) ||
      camera;
    cameraDevice = state.devices.devices.find(d => d.Id === camera.DeviceId);
    // Avoid passing a null camera object to GetMediaParams
  }
  doesCameraSupportPtz = cameraSupportsPtz(camera, cameraDevice);
  return {
    camera,
    cameraId,
    deviceId,
    mediaParamsAll: state.devices.mediaParams,
    timelines: state.devices.timelines,
    id: ownProps.id,
    cameraSupportsPtz: doesCameraSupportPtz,
  };
}

export default connect(mapWrapperStateToProps)(NotificationStreamVideo);
