import React from 'react';
import PropTypes from 'prop-types';

import { Translate, withLocalize } from 'react-localize-redux';

import { contentCard } from 'sharedStyles/global.css';
import {
  details,
  header,
  pageNotFoundText,
  pageNotFoundWrapper,
} from './NotFound.css';

const NotFound = ({ translate }) => (
  <div className={`${contentCard} ${pageNotFoundWrapper}`}>
    <h1 className={`${pageNotFoundText} ${header}`}>
      <Translate id="PAGE_NOT_FOUND.HEADER" />
    </h1>
    <h2 className={`${pageNotFoundText} ${details}`}>
      {translate('PAGE_NOT_FOUND.DETAILS', null, {
        renderInnerHtml: true,
      })}
    </h2>
  </div>
);

NotFound.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withLocalize(NotFound);
