import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-plus';

import { IconFilter } from 'components';
import { Icon } from 'lib';
import { SELECT_STYLE } from 'constants/app';
import {
  disabled,
  filter,
  filterArrow,
  filterInput,
  filterMultiInput,
  labelOverlay,
  selectBoxClear,
  selectBoxOption,
  selectBoxOptionSubtext,
} from './styles.css';

const closeText = 'Close Menu';

class Filter extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = {
      rawOptions: this.optionsToArr(props.options),
      value,
    };
  }

  componentDidMount() {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  optionsToElements = options =>
    options
      .filter(
        option => (option.value && option.text) || typeof option === 'string',
      )
      .map((option, i) => (
        <option key={i} value={option.value || option}>
          {option.text || option}
        </option>
      ));

  optionsToArr = options =>
    options
      .filter(
        option => (option.value && option.text) || typeof option === 'string',
      )
      .map((option, i) => ({
        label: option.text || option,
        subText: option.subText || '',
        value: option.value || option,
      }));

  setValue = value => {
    const { multi } = this.props;
    if (!value || value.length === 0 || !multi) {
      this.setState({ value }, this.handleFilterChanged);
    } else {
      // If one value in a multiselect has been changed, wait until
      // filter closes to update container
      this.setState({ value });
    }
  };

  clearValue = () => {
    this.setState({ value: null }, this.handleFilterChanged);
  };

  handleFilterChanged = () => {
    // Normalization
    // Whether multi- or single-select, return an array of values
    // or the string 'All' when the filter is cleared.
    const { field, isInput, onChange } = this.props;
    const { value } = this.state;
    let valueArr;
    if (!value || value.length === 0) {
      if (isInput) {
        valueArr = null;
      } else {
        valueArr = 'All';
      }
    } else if (typeof value === 'string' || !value.length) {
      valueArr = [value.value || value];
    } else {
      valueArr = value.map(v => v.value || v);
    }
    onChange(field, valueArr);
  };

  countValues = () => {
    const { isInput, label, multi } = this.props;
    const { rawOptions, value } = this.state;
    if (multi && value && value.length > 0) {
      const values = value.slice();
      return `${label}: ${values.length} item${
        values.length > 1 ? 's' : ''
      } selected`;
    }
    if (!multi && value) {
      const selectedOption = rawOptions.find(
        opt => opt.value === value.value || opt.value === value,
      );
      return `${label}: ${selectedOption.label || selectedOption}`;
    }
    if (multi) {
      if (isInput) {
        return `${label}: None`;
      }
      return `${label}: All`;
    }
    return `${label}: Any`;
  };

  renderClear = () => <Icon iconClass={selectBoxClear} id="ic_remove" />;

  renderOption = option => (
    <div className={selectBoxOption}>
      {option.label}
      <div className={selectBoxOptionSubtext}> {option.subText} </div>
    </div>
  );

  valueComponent = () => (
    <div className={labelOverlay}>{this.countValues()}</div>
  );

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    this.setState({
      rawOptions: this.optionsToArr(nextProps.options),
    });
    // If new values are passed in, override
    if (nextProps.value || value !== nextProps.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  renderArrow = () => {
    const { value } = this.state;
    if (!(value && value.length !== 0)) {
      return <span className={`${'Select-arrow'} ${filterArrow}`} />;
    }
    return null;
  };

  render() {
    const {
      disabled: disbledProp,
      icon,
      id,
      isIcon,
      isInput,
      label,
      multi,
    } = this.props;
    const { rawOptions, value } = this.state;
    if (isIcon) {
      return (
        <IconFilter
          anchor="left"
          closeText={closeText}
          icon={icon}
          id={id}
          multiple={multi}
          onChange={disbledProp ? () => null : this.handleFilterChanged}
          options={rawOptions}
          value={value}
        />
      );
    }
    return (
      <div className={filter + (disbledProp ? ` ${disabled}` : '')} id={id}>
        <Select
          arrowRenderer={this.renderArrow}
          className={multi ? filterMultiInput : filterInput}
          clearRenderer={this.renderClear}
          closeOnSelect={!multi}
          label={label}
          multi={multi}
          onChange={this.setValue}
          onClear={this.clearValue}
          onClose={this.handleFilterChanged}
          optionRenderer={this.renderOption}
          options={rawOptions}
          placeholder={this.countValues()}
          removeSelected={false}
          searchable={!isInput}
          style={SELECT_STYLE}
          value={value}
          valueComponent={this.valueComponent}
        />
      </div>
    );
  }
}

Filter.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  id: PropTypes.string,
  isInput: PropTypes.bool,
  label: PropTypes.string.isRequired,
  multi: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMount: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Filter.defaultProps = {
  disabled: false,
  id: 'idFilter',
  isInput: false,
  multi: false,
  onMount: null,
};

export default Filter;
