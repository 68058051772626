// Libs
import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Icons
import { IconSearch } from 'icons';

// Styles
import {
  filterTextInput,
  searchbarIcon,
  textFilterWrapper,
} from './styles.css';

const TextFilter = props => {
  const [filter, setFilter] = useState('');

  const handleChange = e => {
    setFilter(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <div className={`${filterTextInput} ${textFilterWrapper}`}>
      <div className={searchbarIcon}>
        <IconSearch height="25px" showBorder={false} width="28px" />
      </div>
      <Translate>
        {({ translate }) => (
          <input
            id="textFilter"
            onChange={handleChange}
            placeholder={translate('BUTTONS.SEARCH')}
            type="text"
            value={filter}
          />
        )}
      </Translate>
    </div>
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

TextFilter.defaultProps = {};

export default TextFilter;
