import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from '../constants/app';

const LastLinkingLine = ({ title, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 6"
    version="1.1"
    xmlns={SVG_XMLNS}
  >
    <title>{title}</title>
    <g
      id="uiServerSettings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="uiServerSettings-VideoRetention"
        transform="translate(-112.000000, -391.000000)"
        stroke="#000000"
      >
        <g id="Row-of-Cameras" transform="translate(102.000000, 271.000000)">
          <g id="linked-ui" transform="translate(10.000000, 41.801938)">
            <path
              d="M1.46153846,78.1980616 L1.46153846,84.1980616 L1.46153846,78.1980616 Z M1.46153846,78.6980616 L7,78.6980616 L1.46153846,78.6980616 Z"
              id="Combined-Shape"
              transform="translate(4.000000, 81.198062) scale(1, -1) translate(-4.000000, -81.198062) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

LastLinkingLine.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LastLinkingLine.defaultProps = {
  title: '',
  width: '7px',
  height: '6px',
};

export default LastLinkingLine;
