import React from 'react';
import PropTypes from 'prop-types';
import { formField, formGroup, formLabel } from '../CameraSettings/styles.css';

const TextInput = ({
  className,
  type,
  label,
  value,
  max,
  min,
  id,
  disabled,
  onChange,
  onBlur,
}) => {
  return (
    <div className={className.class}>
      <label className={className.label}>{label}</label>
      <div className={className.input}>
        <input
          type={type}
          value={value || ''}
          max={type === 'text' ? null : max}
          min={type === 'text' ? null : min}
          id={id}
          disabled={disabled}
          onChange={disabled ? () => null : onChange}
          onBlur={disabled ? () => null : onBlur}
        />
      </div>
    </div>
  );
};

TextInput.propTypes = {
  className: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

TextInput.defaultProps = {
  className: {
    class: formGroup,
    label: formLabel,
    input: formField,
  },
  type: 'text',
  label: '',
  value: '',
  max: '',
  min: '',
  id: `textId${Math.random()}`,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
};

export default TextInput;
