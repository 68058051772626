exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".sourceList___2JGcl {\r\n  border: 1px solid #dddddd;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.sourceListItem___19p2t {\r\n  line-height: 3em;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: row;\r\n      flex-direction: row;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: justify;\r\n      justify-content: space-between;\r\n}\r\n\r\n.sourceListItem___19p2t:hover {\r\n  background: #5db6ff;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.selected___3rRAV {\r\n}\r\n\r\n.sourceListItem___19p2t.selected___3rRAV {\r\n  background: #5db6ff;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/SourceList/styles.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,mBAAmB;CACpB;;AAED;EACE,iBAAiB;EACjB,qBAAqB;EACrB,cAAc;EACd,wBAAwB;MACpB,oBAAoB;EACxB,uBAAuB;MACnB,oBAAoB;EACxB,uBAAuB;MACnB,+BAA+B;CACpC;;AAED;EACE,oBAAoB;EACpB,aAAa;EACb,gBAAgB;CACjB;;AAED;CACC;;AAED;EACE,oBAAoB;EACpB,aAAa;EACb,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".sourceList {\r\n  border: 1px solid #dddddd;\r\n  overflow-y: auto;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.sourceListItem {\r\n  line-height: 3em;\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: row;\r\n      flex-direction: row;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: justify;\r\n      justify-content: space-between;\r\n}\r\n\r\n.sourceListItem:hover {\r\n  background: #5db6ff;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.selected {\r\n}\r\n\r\n.sourceListItem.selected {\r\n  background: #5db6ff;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"sourceList": "sourceList___2JGcl",
	"sourceListItem": "sourceListItem___19p2t",
	"selected": "selected___3rRAV"
};