exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".snippetContainer___3Atgo {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n}\r\n\r\n.errorParent___1jGhp {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-flow: column;\r\n      flex-flow: column;\r\n  margin: 0px 30px 0px 30px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/Profile/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;CAC5B;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,sBAAsB;MAClB,kBAAkB;EACtB,0BAA0B;CAC3B","file":"styles.css","sourcesContent":[".snippetContainer {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n}\r\n\r\n.errorParent {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-flow: column;\r\n      flex-flow: column;\r\n  margin: 0px 30px 0px 30px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"snippetContainer": "snippetContainer___3Atgo",
	"errorParent": "errorParent___1jGhp"
};