// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Utils
import { validateConfirmPassword, validatePassword } from 'util/validation';

// Constants
import * as PasswordRules from 'constants/PasswordRules';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Styles
import { formWrapper } from 'sharedStyles/modalForms.css';
import { buttonContainerRegister, pageError } from './styles.css';

// Containers
import PageMessage from '../../containers/PageMessage/PageMessage';

// Components
import VerticalFormFieldStack from '../VerticalForm/VerticalFormFieldStack';
import { Button } from 'lib';

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        body={error}
        messageStyle={messageStyleStrings.error}
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.defaultProps = {
  meta: null,
};

errorAggregator.propTypes = {
  meta: PropTypes.objectOf(PropTypes.any),
};

class Form extends Component {
  get passwordFieldProps() {
    const { code } = this.props;
    return [
      {
        code,
        inputId: 'password1',
        label: 'RESET_PASSWORD.ENTER_PASSWORD_LABEL',
        name: 'password1',
        type: 'password',
      },
      {
        code,
        inputId: 'password2',
        isConfirmation: true,
        label: 'RESET_PASSWORD.CONFIRM_PASSWORD_LABEL',
        name: 'password2',
        type: 'password',
        validationWindowVisible: false,
      },
    ];
  }

  validatePasswordWithLast3 = password => {
    const { isNotLast3Passwords } = this.props;
    return validatePassword(
      password,
      isNotLast3Passwords.password1 !== PasswordRules.FAILED,
    );
  };

  passNotLast3PasswordsRule = () => {
    const { isNotLast3Passwords } = this.props;
    const result = !(
      isNotLast3Passwords.password1 === PasswordRules.FAILED ||
      isNotLast3Passwords.password1 === PasswordRules.PENDING ||
      isNotLast3Passwords.password2 === PasswordRules.FAILED ||
      isNotLast3Passwords.password2 === PasswordRules.PENDING
    );
    return result;
  };

  render() {
    const { handleSubmit, invalid, pristine, submitting } = this.props;
    return (
      <div className={formWrapper}>
        <form onSubmit={handleSubmit}>
          <Field component={errorAggregator} name="summary" />
          <div className={pageError}>
            <PageMessage
              messageStyle={messageStyleStrings.error}
              messageType={messageTypes.RESET_PASSWORD_ERROR}
            />
          </div>
          <VerticalFormFieldStack fieldPropList={this.passwordFieldProps} />
          <div className={buttonContainerRegister}>
            <Button
              disabled={
                invalid ||
                pristine ||
                submitting ||
                !this.passNotLast3PasswordsRule()
              }
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  code: PropTypes.string,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  isNotLast3Passwords: PropTypes.shape({
    password1: PropTypes.string,
    password2: PropTypes.string,
  }).isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

Form.defaultProps = {
  code: '',
  handleSubmit: '',
  invalid: true,
  pristine: true,
  submitting: false,
};

const validate = (values, props) => {
  const { isNotLast3Passwords } = props;
  const errors = {};
  const passwordError = validatePassword(
    values.password1,
    isNotLast3Passwords.password1 !== PasswordRules.FAILED,
  );
  const confirmPasswordError = validateConfirmPassword(
    values.password2,
    values.password1,
  );
  if (passwordError) errors.password1 = <Translate id={passwordError} />;
  if (confirmPasswordError)
    errors.password2 = <Translate id={confirmPasswordError} />;
  return errors;
};

const shouldValidate = ({ initialRender, nextProps, props, structure }) => {
  if (initialRender) return false;
  const passwordAsyncValidated =
    nextProps.isNotLast3Passwords.password1 !== PasswordRules.PENDING &&
    props.isNotLast3Passwords.password1 === PasswordRules.PENDING;
  const valuesChanged = !structure.deepEqual(
    props.formValues,
    nextProps.formValues,
  );
  return passwordAsyncValidated || valuesChanged;
};

const selector = formValueSelector('resetPasswordForm');

const ReduxForm = reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: 'resetPasswordForm',
  shouldValidate,
  touchOnChange: true,
  validate,
})(Form);

const ResetPasswordForm = connect(state => ({
  formValues: selector(state, 'password1', 'password2'),
}))(ReduxForm);

export default ResetPasswordForm;
