import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const highlightColor = '#FFFFFF';
const activeColor1 = '#D0D2D3';
const activeColor4 = '#D54039';
const IconDeviceHealth = props => {
  const { className, height, highlight, title, translate, width } = props;

  return (
    <div
      height={height}
      style={{ display: 'flex' }}
      title={translate(title)}
      width={width}
    >
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 42 42"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-13.000000, -552.000000)">
            <g transform="translate(0.000000, 446.000000)">
              <g transform="translate(0.000000, 92.000000)">
                <g transform="translate(13.000000, 14.000000)">
                  <g transform="translate(0.000000, 0.314495)">
                    <mask fill="white" id="mask-3-2">
                      <polygon points="2.43081139e-15 39.6328073 2.43081139e-15 0.0526238532 39.947037 0.0526238532 39.947037 39.6328073" />
                    </mask>
                    <path
                      d="M19.9735185,4.97849541 C11.6883333,4.97849541 4.97166667,11.6331743 4.97166667,19.8427156 C4.97166667,28.0518899 11.6883333,34.7069358 19.9735185,34.7069358 C28.2587037,34.7069358 34.9753704,28.0518899 34.9753704,19.8427156 C34.9753704,11.6331743 28.2587037,4.97849541 19.9735185,4.97849541 M39.9472222,19.8427156 C39.9472222,30.7726239 31.0046296,39.6328073 19.9735185,39.6328073 C8.94240741,39.6328073 -0.000185185185,30.7726239 -0.000185185185,19.8427156 C-0.000185185185,8.91280734 8.94240741,0.0526238532 19.9735185,0.0526238532 C31.0046296,0.0526238532 39.9472222,8.91280734 39.9472222,19.8427156"
                      fill={highlight ? highlightColor : activeColor1}
                      mask="url(#mask-3-2)"
                    />
                  </g>
                  <mask fill="white" id="mask-3-4">
                    <polygon points="0 39.9475229 39.947037 39.9475229 39.947037 0.366972477 0 0.366972477" />
                  </mask>
                  <path
                    d="M19.1248148,27.3053211 L21.5385185,27.3053211 L21.5385185,24.8099083 L19.1248148,24.8099083 L19.1248148,27.3053211 Z M21.922963,15.7684404 L18.6592593,15.7684404 L19.1248148,23.7497248 L21.5385185,23.7497248 L21.922963,15.7684404 Z M20.2907407,14.3111927 C23.0048148,9.30311927 30.9681481,11.3522936 30.9681481,16.453945 C30.9681481,21.3787156 25.6296296,26.3034862 20.2907407,31.2278899 C14.9522222,26.3034862 9.6137037,21.3787156 9.6137037,16.453945 C9.6137037,11.3526606 17.5766667,9.30311927 20.2907407,14.3111927 L20.2907407,14.3111927 Z"
                    fill={highlight ? highlightColor : activeColor4}
                    mask="url(#mask-3-4)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconDeviceHealth.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.bool,
  title: PropTypes.string,
  translate: PropTypes.func,
  width: PropTypes.string,
};

IconDeviceHealth.defaultProps = {
  className: null,
  height: '25px',
  highlight: false,
  title: 'ALARMS.STATUS.DEVICE_HEALTH_LABEL',
  translate: () => {},
  width: '25px',
};

export default withLocalize(IconDeviceHealth);
