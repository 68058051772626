import { DEVICE_SETTINGS } from 'constants/deviceSettings';
import {
  PATH_SEGMENT_CAMERAS,
  PATH_SEGMENT_DIGITAL_IO,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_POE,
  PATH_SEGMENT_RECORDING_SCHEDULE,
  PATH_SEGMENT_VIDEO_RETENTION,
} from 'constants/urlPaths';

const TABS = DEVICE_SETTINGS.GENERIC.LABELS;

export const TABS_CONNECTED = [
  {
    id: TABS.CAMERAS,
    label: 'COMMON.CAMERAS',
    path: PATH_SEGMENT_CAMERAS,
  },
  {
    id: TABS.GENERAL,
    label: 'DEVICE_DETAILS.GENERAL_TAB_LABEL',
    path: PATH_SEGMENT_GENERAL,
  },
  {
    id: TABS.IO,
    label: 'DEVICE_DETAILS.INPUT_OUTPUT_TAB_LABEL',
    path: PATH_SEGMENT_DIGITAL_IO,
  },
  {
    id: TABS.POE,
    label: 'DEVICE_DETAILS.POE_MANAGEMENT_TAB_LABEL',
    path: PATH_SEGMENT_POE,
  },
  {
    id: TABS.RECORDING_SCHEDULE,
    label: 'DEVICE_DETAILS.RECORDING_SCHEDULE',
    path: PATH_SEGMENT_RECORDING_SCHEDULE,
  },
  {
    id: TABS.RETENTION,
    label: 'DEVICE_DETAILS.VIDEO_RETENTION_TAB_LABEL',
    path: PATH_SEGMENT_VIDEO_RETENTION,
  },
];

export const TABS_DISCONNECTED = [
  {
    id: TABS.CAMERAS,
    label: 'COMMON.CAMERAS',
    path: PATH_SEGMENT_CAMERAS,
  },
  {
    id: TABS.GENERAL,
    label: 'DEVICE_DETAILS.GENERAL_TAB_LABEL',
    path: PATH_SEGMENT_GENERAL,
  },
];
