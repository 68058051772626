import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconCamera = props => {
  return (
    <svg
      id={props.id}
      width={props.width}
      height={props.height}
      viewBox="0 0 15 11"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="01a-notifications-list-subscribers"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <title>{props.title}</title>
        <g
          id="dealer-dashboard"
          transform="translate(-769.000000, -796.000000)"
          fill={props.fill}
        >
          <g id="Base" transform="translate(-1.000000, -108.000000)">
            <g id="Group-12">
              <g id="Group-9" transform="translate(1.000000, 0.000000)">
                <g
                  id="Table-Grid"
                  transform="translate(106.000000, 284.000000)"
                >
                  <g
                    id="Data-Table"
                    transform="translate(0.000000, 273.000000)"
                  >
                    <g id="DEVICES" transform="translate(661.000000, 9.000000)">
                      <path
                        d="M13.25,344 L13.25,347.75 C13.25,348.056812 12.8275,348.5 12.5,348.5 L2.75,348.5 C2.4225,348.5 2,348.056812 2,347.75 L2,338.75 C2,338.443625 2.4225,338 2.75,338 L12.5,338 C12.8275,338 13.25,338.443625 13.25,338.75 L13.25,342.5 L13.25,344 Z M17,339.5 L17,347 L13.25,343.25 L17,339.5 Z"
                        id="Camera"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconCamera.defaultProps = {
  id: 'iconCamera',
  className: null,
  width: '25px',
  height: '16px',
  fill: ACTIVE_COLOR_DEFAULT,
  title: 'Camera',
};

export default IconCamera;
