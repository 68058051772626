import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';

import { ListNav } from 'lib';
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import * as LocationActions from 'actions/locations';
import * as SubscriptionActions from 'actions/subscriptions';
import { hideModal, showModal } from 'actions/modal';

import { ACTION_IMAGES, BULK_ACTION_TABLES } from 'constants/app';
import * as consts from '../constants';
import handleSuspension from './SuspendLogic';

function SitesListNav(props) {
  const {
    actions,
    canAddLocation,
    canBulkDeleteLocations,
    canEdit,
    canEditSubscription,
    canSuspendLocations,
    currentOrg,
    forceFilterToggle,
    items,
    navigationTabs,
    onAddClick,
    onDeleteClick,
    showDeleteIcon,
  } = props;
  const { bulkActionIdsFor } = useContext(BulkActionContext);

  function handleSuspendUnsuspend(type) {
    const selectedLocationIds = bulkActionIdsFor(BULK_ACTION_TABLES.LOCATIONS);
    const selectedLocations = items.filter(item =>
      selectedLocationIds.includes(item.Id),
    );
    handleSuspension(
      type,
      currentOrg,
      selectedLocations,
      canEditSubscription,
      actions,
    );
  }
  const selectedLocationIds = bulkActionIdsFor(BULK_ACTION_TABLES.LOCATIONS);
  return (
    <ListNav
      {...props}
      actionImage={ACTION_IMAGES.LOCATION}
      bulkActionsTable={BULK_ACTION_TABLES.LOCATIONS}
      canAdd={canAddLocation}
      canDelete={canBulkDeleteLocations}
      canEdit={canEdit}
      canSuspendLocations={canSuspendLocations}
      currentOrg={currentOrg}
      deleteActive={selectedLocationIds.length > 0}
      forceFilterToggle={forceFilterToggle}
      handleSuspendSite={() => {
        handleSuspendUnsuspend(consts.SUSPEND);
      }}
      handleUnsuspendSite={() => {
        handleSuspendUnsuspend(consts.UNSUSPEND);
      }}
      id={consts.idLocNavHeader}
      isSuspendActive={selectedLocationIds.length > 0}
      isUnsuspendActive={selectedLocationIds.length > 0}
      navigationTabs={navigationTabs}
      onAddClick={onAddClick}
      onDeleteClick={onDeleteClick}
      showDeleteIcon={showDeleteIcon}
      showFilters={false}
    />
  );
}

SitesListNav.defaultProps = {
  actions: {},
  bulkActionsContext: {},
  canEdit: false,
  children: [],
  currentOrg: {},
  filters: {},
  forceFilterToggle: false,
  handleSuspendSite: () => {},
  handleUnsuspendSite: () => {},
  items: [],
  navigationTabs: null,
  onAddClick: () => {},
  onDeleteClick: () => {},
  selectedIds: [],
  showDeleteIcon: true,
  title: '',
  translate: () => {},
};

SitesListNav.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  bulkActionsContext: PropTypes.objectOf(PropTypes.any),
  canAddLocation: PropTypes.bool.isRequired,
  canBulkDeleteLocations: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
  canEditSubscription: PropTypes.bool.isRequired,
  canSuspendLocations: PropTypes.bool.isRequired,
  children: PropTypes.node,
  currentOrg: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any),
  forceFilterToggle: PropTypes.bool,
  handleSuspendSite: PropTypes.func,
  handleUnsuspendSite: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  navigationTabs: PropTypes.node,
  onAddClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  showDeleteIcon: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  translate: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canBulkDeleteLocations: state.user.permissions.CAN_BULK_DELETE_SITES,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canSuspendLocations: state.user.permissions.CAN_SUSPEND_SITE,
    items: state.locations.locations,
    profile: state.user.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...SubscriptionActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(SitesListNav),
);
