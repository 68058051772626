// Libs
import PropTypes from 'prop-types';
import React from 'react';

// Styles
import * as styles from './OGTable.css';

// Icons
import { IconCaretUp, IconCaretDown } from 'icons';

const SortIndicator = ({ field, sortingDirection, sortingField }) => {
  let content;
  if (field === sortingField) {
    if (sortingDirection === 'ASC') {
      content = <IconCaretDown className={styles.caretDown} />;
    } else if (sortingDirection === 'DESC') {
      content = <IconCaretUp className={styles.caretUp} />;
    }
  }
  return <div className={styles.sortIndicator}>{content}</div>;
};

SortIndicator.defaultProps = {
  sortingField: null,
};

SortIndicator.propTypes = {
  field: PropTypes.string.isRequired,
  sortingDirection: PropTypes.string.isRequired,
  sortingField: PropTypes.string,
};

export default SortIndicator;
