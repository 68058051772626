// Dashboard
export const dashboardIds = {
  CamerasWithUnreviewedActivities: 'summaryCameras',
  InReviewCount: 'summaryInReview',
  OldestUnreviewedActivityTime: 'summaryOldest',
  SitesWithUnreviewedActivities: 'summarySites',
  UnreviewedCount: 'summaryUnreviewed',
  idCompareCheckbox: 'compareCheckbox',
  idLineGraph: 'notificationsLinegraph',
};

// For the summary table
export const ROW_HEIGHT = 44;
export const notificationsTableHeight = `calc(100vh - 320px)`;

// Colors of chart lines; more will be generated algorithmically if necessary
export const COLOR_ARRAY = [
  '#006e99',
  '#24A6CE',
  '#D5CD88',
  '#F1AF29',
  '#CD441B',
  '#F8C285',
  '#659D69',
  '#224D5D',
];
// Corresponds to name of the array
// of chart values in the data structure returned by the API
export const valKey = 'Coordinates';

// These consts are being used for moment.js calculation
export const unitsOfTime = { days: 'Days', hours: 'Hours' };

// AlarmTable
export const SELECTED_ROW_COLOR = '#FFFFFF';
export const UNSELECTED_ROW_COLOR = '#006e99';
export const DEVICE_ICON_SIZE = 16;
