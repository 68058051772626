import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Toggle } from 'components';
import { EmptyPlaceholder, Icon, ListView, NoStyleButton } from 'lib';
import { MonitoringConnectionTestContainer } from 'containers';
import { IC_EDIT, IC_TRASH } from 'constants/iconNames';
import * as PATH from 'constants/urlPaths';
import * as consts from './constants';
import { linkText } from './styles.css';

class MonitoringConfigurationsTable extends Component {
  get integrationTypeDisplays() {
    const { integrationTypes } = this.props;
    const types = {};
    integrationTypes.forEach(type => {
      types[type.Enum] = type.Display;
    });
    return types;
  }

  onToggleConnection = rowData => {
    const { handleToggleConnection } = this.props;
    handleToggleConnection(rowData);
  };

  onClickEdit = rowData => {
    const { history } = this.props;
    history.push(
      `${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_MONITORING}${PATH.PATH_SEGMENT_MONITORING_CENTERS}/${rowData.IntegrationConfigurationId}`,
    );
  };

  onClickDelete = rowData => {
    const { handleDelete } = this.props;
    handleDelete(rowData);
  };

  render() {
    const { translate } = this.props;
    const CUSTOM_CELLS = {
      Actions: rowData => {
        return (
          <div className={linkText}>
            <div
              id={consts.idTestConnection}
              title={translate('INTEGRATIONS.TEST_CONNECTION_TOOLTIP')}
            >
              <MonitoringConnectionTestContainer
                configurationId={rowData.IntegrationConfigurationId}
              />
            </div>
            <NoStyleButton
              id={consts.idEditConnection}
              onClick={() => this.onClickEdit(rowData)}
            >
              <Icon id={IC_EDIT} size={22} title="INTEGRATIONS.EDIT_LINK" />
            </NoStyleButton>
            <NoStyleButton
              id={consts.idDeleteConnection}
              onClick={() => this.onClickDelete(rowData)}
            >
              <Icon id={IC_TRASH} size={22} title="INTEGRATIONS.DELETE_LINK" />
            </NoStyleButton>
          </div>
        );
      },
      ConnectionStatus: rowData => {
        return (
          <div>
            <Toggle
              checked={rowData.Enabled}
              id={`connectionToggle_${rowData.IntegrationConfigurationId}`}
              onChange={() => this.onToggleConnection(rowData)}
            />
          </div>
        );
      },
      IntegrationType: rowData =>
        this.integrationTypeDisplays[rowData.IntegrationType],
    };
    const cellWidths = {
      ConnectionStatus: '60px',
    };
    const { integrationConfigs, isFetchingSummary } = this.props;
    return (
      <EmptyPlaceholder
        isFetching={isFetchingSummary}
        items={integrationConfigs}
        translateKey="INTEGRATIONS.NO_CONFIGURATIONS_FOUND"
      >
        <ListView
          bodyHeight={420}
          cellAlignments={consts.CELL_ALIGNMENTS}
          cellWidths={cellWidths}
          customCells={CUSTOM_CELLS}
          data={integrationConfigs}
          fieldOrder={consts.FIELD_ORDER}
          headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
        />
      </EmptyPlaceholder>
    );
  }
}

MonitoringConfigurationsTable.propTypes = {
  handleDelete: PropTypes.func,
  handleToggleConnection: PropTypes.func,
  history: PropTypes.objectOf(PropTypes.any),
  integrationConfigs: PropTypes.arrayOf(PropTypes.shape({})),
  integrationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Display: PropTypes.string,
      Enum: PropTypes.string,
    }),
  ),
  isFetchingSummary: PropTypes.bool,
  translate: PropTypes.func,
};

MonitoringConfigurationsTable.defaultProps = {
  handleDelete: () => {},
  handleToggleConnection: () => {},
  history: {},
  integrationConfigs: {},
  integrationTypes: [],
  isFetchingSummary: false,
  translate: () => {},
};

export default withRouter(MonitoringConfigurationsTable);
