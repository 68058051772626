import moment from 'moment';

export const generateSort = extractSortValue => {
  // Array.prototype.sort will expect a function that takes two objects and returns 1, -1, or 0.
  // This function generates that function.
  // extractSortValues takes in each comparable object, and returns a sortable value, usually a field value.

  // Making this an arrow breaks sorting.
  return (obj1, obj2) => {
    let result = 0;
    if (obj1 && obj2 && typeof obj1 === typeof obj2) {
      const [value1, value2] = [obj1, obj2].map(extractSortValue);
      if ((value1 || value1 === 0) && (value2 || value2 === 0)) {
        if (value1 > value2) {
          result = 1;
        } else if (value1 < value2) {
          result = -1;
        }
      }
    }
    return result;
  };
};

export const sortFirstAndLastName = extractSortValue => {
  // Array.prototype.sort will expect a function that takes two objects and returns 1, -1, or 0.
  // This function generates that function.
  // Adding to above sort, this sort function then also reorder the items based on A A, A a, a A, a a order
  // here first item is last name and second item in last name.
  // When we have same first and last name couple of times, we have to faciliate the order such that it becomes
  // visible organized and easy to find for user.
  // sortFirstAndLastName takes in each comparable object, and returns a sortable value, usually a field value.

  return (obj1, obj2) => {
    let result = 0;
    if (obj1 && obj2 && typeof obj1 === typeof obj2) {
      const [value1, value2] = [obj1, obj2].map(extractSortValue);
      if (value1 && value2) {
        if (
          value1.toString().toLowerCase() === value2.toString().toLowerCase()
        ) {
          const value1Last = value1.split(' ')[0];
          const value2Last = value2.split(' ')[0];
          const value1First = value1.split(' ')[1];
          const value2First = value2.split(' ')[1];

          if (value1Last > value2Last) {
            result = 1;
          } else if (value1First > value2First) {
            result = 1;
          } else {
            result = -1;
          }
        } else if (
          value1 &&
          value2 &&
          value1.toString().toLowerCase() > value2.toString().toLowerCase()
        ) {
          result = 1;
        } else {
          result = -1;
        }
      }
    }
    return result;
  };
};

// sortByCreationDate sorts the data by date from latest to oldest date.
// Provide the date time format used in the data to compare the values.
export const sortByDate = (extractSortValue, dateTimeFormat) => {
  return (obj1, obj2) => {
    let result = 0;
    if (obj1 && obj2 && typeof obj1 === typeof obj2) {
      const [value1, value2] = [obj1, obj2].map(extractSortValue);
      if ((value1 || value1 === 0) && (value2 || value2 === 0)) {
        if (
          moment(value1, dateTimeFormat).isAfter(
            moment(value2, dateTimeFormat),
            'minute',
          )
        ) {
          result = -1;
        } else if (
          moment(value1, dateTimeFormat).isBefore(
            moment(value2, dateTimeFormat),
            'minute',
          )
        ) {
          result = 1;
        }
      }
    }
    return result;
  };
};
