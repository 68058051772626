import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  buttonClass,
  // Accepts any sort of styling needed but ideally we shouldn't ever be altering this
  buttonType,
  // One of Primary, Secondary, Action or Icon
  // See https://cobalt.motorolasolutions.com/components/button for examples
  children,
  // Can be used instead of text where we need more than a simple string
  compact,
  // Reduces surrounding padding of the outer button styling
  // See https://cobalt.motorolasolutions.com/components/button for examples
  disabled,
  // Disables button and provides a hover cursor change
  icon,
  // Boolean; must provide <Icon/> component between it
  // TO DO: Allow user to pass in icon name here instead of requiring explicit <Icon/> inclusion
  id,
  // Button ID
  inputType,
  // One of 'submit', 'reset', 'button'
  // 95% of the time button is the correct option, submit/reset should be used for forms where applicable
  onClick,
  // Accepts a function for handling the click action
  onMouseEnter,
  // Accepts a function for handling changes based on mouse interaction
  onMouseLeave,
  // Accepts a function for handling changes based on mouse interaction
  size,
  // One of small or large
  // See https://cobalt.motorolasolutions.com/components/button for examples
  text,
  // Accepts string or translation
  // WILL NOT TRANSLATE AUTOMATICALLY (duh)
}) => {
  const btnType = () => {
    if (buttonType !== 'primary') {
      return `msi-btn msi-btn-${buttonType}`;
    }

    return `msi-btn`;
  };
  const btnSize = () => {
    if (size !== '') {
      return `msi-btn-${size}`;
    }
    return '';
  };

  return (
    <button
      key={id}
      className={`${btnType()} ${btnSize()} ${
        compact ? 'msi-btn-compact' : ''
      } ${icon ? 'msi-btn-icon' : ''} ${buttonClass}`}
      disabled={disabled}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={inputType}
    >
      {children || text}
    </button>
  );
};

Button.defaultProps = {
  buttonClass: '',
  buttonType: 'primary',
  children: undefined,
  compact: false,
  disabled: false,
  icon: false,
  id: 'btn',
  inputType: 'button',
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  size: '',
  text: '',
};

Button.propTypes = {
  buttonClass: PropTypes.string,
  buttonType: PropTypes.oneOf(['action', 'icon', 'primary', 'secondary']),
  children: PropTypes.node,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  id: PropTypes.string,
  inputType: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  size: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default Button;
