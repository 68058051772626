/* global avoLogError */

import urlBuilder from 'queryBuilder/url';
import {
  runGetActionReturningJSON,
  sendPostRequestReturningJSON,
  sendGetRequestReturningJSON,
  sendPutRequestReturningJSON,
  sendDeleteRequestReturningJSON,
} from 'util/fetchHelpers';
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { showMessage } from './pageMessage';

// Redux action creators

export function receiveContacts(contacts) {
  return {
    type: types.RECEIVE_CONTACTS,
    contacts,
  };
}

export function receiveContact(contact) {
  return {
    type: types.RECEIVE_CONTACT,
    contact,
  };
}

// async action creators

export function getContacts(queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.GET_CONTACTS, 0, 0, queryOptions);
    runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_CONTACTS,
      onSuccess: json => {
        dispatch(receiveContacts(json));
      },
      onError: err => {
        dispatch(
          showMessage(messageTypes.CONTACT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CONTACTS.GET_CONTACT_ERROR',
          }),
        );
        dispatch(receiveContacts([]));
        avoLogError('Error getting contacts', err);
      },
    });
  };
}

export function getContact(id) {
  return dispatch => {
    const url = urlBuilder(types.GET_CONTACT, id);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveContact(json)))
      .catch(ex => {
        avoLogError('Error getting contact', { id, ex });
      });
  };
}

export function addContact(contactData, queryOptions) {
  return dispatch => {
    const body = { ...contactData };
    const url = urlBuilder(types.ADD_CONTACT);
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(getContacts(queryOptions));
        dispatch(
          showMessage(messageTypes.CONTACT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CONTACTS.ADD_CONTACT_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error adding a contact', ex);
        dispatch(
          showMessage(messageTypes.CONTACT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERAL_MESSAGES.ADD_ERROR',
          }),
        );
      });
  };
}

export function editContact(contactData, queryOptions) {
  return dispatch => {
    const body = { ...contactData };
    const url = urlBuilder(types.EDIT_CONTACT, contactData.Id);
    return sendPutRequestReturningJSON(url, body)
      .then(() => {
        dispatch(getContacts(queryOptions));
        dispatch(
          showMessage(messageTypes.CONTACT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CONTACTS.EDIT_CONTACT_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error editing contact', ex);
        dispatch(
          showMessage(messageTypes.CONTACT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERAL_MESSAGES.EDIT_ERROR',
          }),
        );
      });
  };
}

export function deleteContact(id, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.DELETE_CONTACT, id);
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CONTACT_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CONTACTS.DELETE_CONTACT_SUCCESS',
          }),
        );
        dispatch(getContacts(queryOptions));
      })
      .catch(ex => {
        avoLogError('Error deleting contact', ex);
        dispatch(
          showMessage(messageTypes.CONTACT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CONTACTS.DELETE_CONTACT_ERROR',
          }),
        );
      });
  };
}

export function deleteContacts(ids, queryOptions) {
  return dispatch => {
    ids.forEach(id => dispatch(deleteContact(id, queryOptions)));
  };
}

// Fetch contacts by location
export function getContactsByLocation(queryOptions) {
  return dispatch => {
    const { locationId } = queryOptions;
    const url = urlBuilder(
      types.GET_CONTACT_BY_LOCATION,
      locationId,
      0,
      queryOptions,
    );
    runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_CONTACTS,
      onSuccess: json => {
        dispatch(receiveContacts(json));
      },
      onError: err => {
        dispatch(
          showMessage(messageTypes.CONTACT_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CONTACTS.GET_CONTACT_ERROR',
          }),
        );
        dispatch(receiveContacts([]));
        avoLogError('Error getting contacts by location', err);
      },
    });
  };
}
