import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';

// Utilities
import * as permissions from 'util/permissions';
import { ROLES } from 'util/userRoles';
import * as tenantUtils from 'util/tenantTypes';
import normalizeBoolean from 'util/normalizeBoolean';

// Constants
import * as ENTITLEMENTS from 'constants/EntitlementTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { countries } from 'constants/dropdownContent';

// Actions
import { showModal } from 'actions/modal';

// Components
import { FieldInput, FieldInputWithOnBlur } from 'components';
import { Button, FieldSelect, GroupLayout } from 'lib';
import RadioGroup from 'components/Inputs/RadioGroup';

// Styles
import {
  formField,
  modalFormGroup,
  pageError,
} from 'sharedStyles/modalForms.css';
import { AddCreateUser, PageMessage } from 'containers';
import {
  formGroup,
  formLabel,
  sectionHeader,
} from 'components/VerticalForm/styles.css';
import {
  buttonGroup,
  formColumn,
  formRadioGroup,
  formRadioLabel,
  formWrapper,
} from './styles.css';
import * as consts from './constants';

const formLevelValidation = (values, props) => {
  const { allowEmptyValue } = props;
  const errors = {};
  const requiredFields = allowEmptyValue
    ? consts.requiredAdminFields
    : consts.requiredFields;
  const emptyFields = requiredFields.filter(
    field => !values[field] || values[field] === '',
  );
  if (emptyFields.length > 0) {
    errors.summary = 'PROFILE.FILL_REQUIRED_FIELDS_ERROR';
  }
  return errors;
};

const ErrorAggregator = ({ meta: { error, touched } }) => {
  if (touched && error) {
    return (
      <div className={pageError}>
        <PageMessage
          messageStyle={messageStyleStrings.error}
          translateBody={error}
          translateHeader="GENERAL_MESSAGES.MISSING_INFO_HEADER"
          visible={touched && error}
        />
      </div>
    );
  }

  return null;
};

ErrorAggregator.defaultProps = {
  meta: {},
};

ErrorAggregator.propTypes = {
  meta: PropTypes.objectOf(PropTypes.any),
};

const Label = props => {
  const { label } = props;
  return <label className={formLabel}>{label}</label>;
};

Label.propTypes = {
  label: PropTypes.string.isRequired,
};

const FieldDisplay = props => {
  const { normalizeOnBlur } = props;
  return normalizeOnBlur ? (
    <FieldInputWithOnBlur {...props} />
  ) : (
    <FieldInput {...props} />
  );
};

FieldDisplay.defaultProps = {
  normalizeOnBlur: undefined,
};

FieldDisplay.propTypes = {
  normalizeOnBlur: PropTypes.func,
};

const FormGroup = props => {
  const {
    allowEmptyValue,
    children,
    component,
    id,
    label,
    superUserValidate,
    validate,
  } = props;
  return (
    <div className={modalFormGroup} id={id}>
      <Label label={label} />
      <Field
        className={formField}
        component={component}
        translate
        {...props}
        validate={allowEmptyValue ? superUserValidate : validate}
      >
        {children}
      </Field>
    </div>
  );
};

FormGroup.propTypes = {
  allowEmptyValue: PropTypes.bool,
  children: PropTypes.node,
  component: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  superUserValidate: PropTypes.func,
  validate: PropTypes.func,
};

FormGroup.defaultProps = {
  allowEmptyValue: false,
  children: null,
  component: FieldDisplay,
  id: '',
  label: '',
  superUserValidate: () => {},
  validate: () => {},
};

const header = headerString => {
  return (
    <div className={sectionHeader}>
      <Translate id={headerString} />
    </div>
  );
};

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthMonitorSystemEnabled: false,
      reportsEntitlementEnabled: false,
    };
  }

  componentDidMount() {
    const { initialValues } = this.props;
    const { Entitlements } = initialValues;

    this.setState({
      healthMonitorSystemEnabled:
        Entitlements && Entitlements.includes(ENTITLEMENTS.HealthMonitorSystem),
      reportsEntitlementEnabled:
        Entitlements && Entitlements.includes(ENTITLEMENTS.SafetyMonitorSystem),
    });
  }

  get isSubmitDisabled() {
    const { dirty, pristine, readOnly, submittingForm, valid } = this.props;
    return submittingForm || pristine || readOnly || !valid || !dirty;
  }

  get primaryAdmin() {
    const {
      adminUserId,
      initialValues,
      isEditing,
      readOnly,
      submittingForm,
      tenantId,
      translate,
    } = this.props;
    return (
      <fieldset className={formColumn} disabled={readOnly || submittingForm}>
        {header('ACCOUNTS.GENERAL_INFO.PRIMARY_ADMIN')}
        {isEditing && tenantId ? (
          <AddCreateUser
            id={consts.formFields.primaryAdminId.id}
            initialValues={initialValues}
            selectedId={adminUserId}
            showUserRoles={[ROLES.Administrator]}
            tenantId={tenantId}
            translatedLabel="USERS.USER_FORM_FIELDS.LABELS.ID"
          />
        ) : (
          <div>
            <FormGroup
              {...consts.formFields.firstName}
              key={consts.formFields.firstName.name}
              disabled={isEditing}
              label={translate(consts.formFields.firstName.translatedLabel)}
            />
            <FormGroup
              {...consts.formFields.lastName}
              key={consts.formFields.lastName.name}
              disabled={isEditing}
              label={translate(consts.formFields.lastName.translatedLabel)}
            />
            <FormGroup
              {...consts.formFields.email}
              key={consts.formFields.email.name}
              label={translate(consts.formFields.email.translatedLabel)}
              placeholder={translate(
                consts.formFields.email.translatedPlaceholder,
              )}
            />
          </div>
        )}
      </fieldset>
    );
  }

  get fieldSelectSubType() {
    const fieldType = props => {
      return <FieldSelect {...props} />;
    };

    const { isEditing, selectedType, translate } = this.props;

    if (selectedType === tenantUtils.tenantTypeStrings.Provider) {
      return (
        <FormGroup
          component={fieldType}
          {...consts.formFields.subType}
          key={consts.formFields.subType.name}
          disabled={isEditing}
          label={<Translate id={consts.formFields.subType.translatedLabel} />}
        >
          <option key="provider-subtype-select" value="">
            {translate('GENERAL_MESSAGES.SELECT')}
          </option>
          <option value={tenantUtils.tenantSubtypeStrings.dealer}>
            {translate('TENANTS.PROVIDER_SUBTYPES.DEALER')}
          </option>
          <option value={tenantUtils.tenantSubtypeStrings.publicPrivatePartner}>
            {translate('TENANTS.PROVIDER_SUBTYPES.PUBLIC_PRIVATE_PARTNER')}
          </option>
        </FormGroup>
      );
    }

    return null;
  }

  get details() {
    const fieldType = props => {
      return <FieldSelect {...props} />;
    };
    const {
      allowEmptyValue,
      dealerId,
      isEditing,
      partners: propPartners,
      readOnly,
      selectedType,
      submittingForm,
      translate,
      userTenantType,
    } = this.props;

    const strDealerId = 'PreferredDealer_Id';
    const permissibleCustomerTypes = permissions.canCreateCustomerTypes(
      userTenantType,
    );
    let fieldSelectParent;
    if (
      (permissibleCustomerTypes.length > 1 &&
        selectedType === tenantUtils.tenantTypeStrings.Subscriber) ||
      (permissibleCustomerTypes.length > 2 &&
        selectedType === tenantUtils.tenantTypeStrings.Provider)
    ) {
      const partners = () => {
        const list = [];

        list.push(
          <option key="partners-select" value="">
            {translate('GENERAL_MESSAGES.SELECT')}
          </option>,
        );
        propPartners.map(org =>
          // create a list of Org Names for displaying the name of organization
          list.push(
            <option key={org.Id} value={org.Id}>
              {org.Name}
            </option>,
          ),
        );
        return list;
      };

      fieldSelectParent = (
        <FormGroup
          component={fieldType}
          {...consts.formFields.parent}
          key={strDealerId}
          disabled={isEditing || submittingForm}
          label={<Translate id={consts.formFields.parent.translatedLabel} />}
          name={isEditing ? consts.formFields.parent.name : strDealerId}
        >
          {partners()}
        </FormGroup>
      );
    }

    const nameFieldTranslationId =
      selectedType === tenantUtils.tenantTypeStrings.Provider
        ? 'USERS.USER_FORM_FIELDS.LABELS.PROVIDER_NAME'
        : 'USERS.USER_FORM_FIELDS.LABELS.CUSTOMER_NAME';

    return (
      <>
        <fieldset className={formColumn} disabled={readOnly}>
          {header('ORGANIZATIONS.ORGANIZATION_INFO_LABEL')}
          <FormGroup
            {...consts.formFields.name}
            key={consts.formFields.name.name}
            label={<Translate id={nameFieldTranslationId} />}
          />

          {this.fieldSelectSubType}

          <FormGroup
            {...consts.formFields.sapId}
            key={consts.formFields.sapId.name}
            allowEmptyValue={allowEmptyValue}
            label={<Translate id={consts.formFields.sapId.translatedLabel} />}
          />
        </fieldset>
        {selectedType === tenantUtils.tenantTypeStrings.Provider ? (
          <fieldset className={formColumn} disabled={readOnly}>
            {header('GENERAL_MESSAGES.ADDRESS_HEADER')}
            <FormGroup
              component={fieldType}
              {...consts.formFields.country}
              key={consts.formFields.country.name}
              label={
                <Translate id={consts.formFields.country.translatedLabel} />
              }
            >
              {countries.map(c => (
                <option key={c.code} value={c.dbValue}>
                  {translate(c.name)}
                </option>
              ))}
            </FormGroup>
            <FormGroup
              {...consts.formFields.address1}
              key={consts.formFields.address1.name}
              label={
                <Translate id={consts.formFields.address1.translatedLabel} />
              }
            />
            <FormGroup
              {...consts.formFields.address2}
              key={consts.formFields.address2.name}
              label={
                <Translate id={consts.formFields.address2.translatedLabel} />
              }
            />
            <FormGroup
              {...consts.formFields.city}
              key={consts.formFields.city.name}
              label={<Translate id={consts.formFields.city.translatedLabel} />}
            />
            <FormGroup
              {...consts.formFields.region}
              key={consts.formFields.region.name}
              label={
                <Translate id={consts.formFields.region.translatedLabel} />
              }
            />
            <FormGroup
              {...consts.formFields.postalCode}
              key={consts.formFields.postalCode.name}
              label={
                <Translate id={consts.formFields.postalCode.translatedLabel} />
              }
            />
          </fieldset>
        ) : null}

        <fieldset className={formColumn} disabled={readOnly}>
          {fieldSelectParent}
        </fieldset>
      </>
    );
  }

  get organizationEntitlements() {
    const { readOnly, selectedType } = this.props;

    const {
      healthMonitorSystemEnabled,
      reportsEntitlementEnabled,
    } = this.state;

    const onChangeRadioButton = (changedValue, value, entitlementEnabled) => {
      const val = value.split(', ');
      const index = val.indexOf(entitlementEnabled);
      if (changedValue) {
        if (index === -1) {
          val.push(entitlementEnabled);
        }
      } else if (index > -1) {
        val.splice(index, 1);
        if (val.length === 0) {
          val.push('None');
        }
      }
      return val.join(', ');
    };

    const onRadioButtonClick = (val, entitlementEnabled) => {
      this.setState({
        [entitlementEnabled]: val,
      });
    };

    return (
      <fieldset className={formColumn} disabled={readOnly}>
        {header('ACCOUNTS.GENERAL_INFO.ORGANIZATION_ENTITLEMENTS')}
        <div className={formRadioGroup}>
          <label className={formRadioLabel}>
            <Field
              key={consts.formFields.tenantEntitlements.id}
              component={fieldProps => (
                <RadioGroup
                  labelTranslateId={
                    consts.formFields.tenantEntitlements.translatedLabel
                  }
                  onChange={e => {
                    const changedValue = normalizeBoolean(e.target.value);
                    fieldProps.input.onChange(
                      onChangeRadioButton(
                        changedValue,
                        fieldProps.input.value,
                        ENTITLEMENTS.HealthMonitorSystem,
                      ),
                    );
                    onRadioButtonClick(
                      changedValue,
                      'healthMonitorSystemEnabled',
                    );
                  }}
                  options={consts.ORGANIZATION_HEALTH_MONITOR_ACCESS}
                  value={healthMonitorSystemEnabled}
                />
              )}
              id={consts.formFields.tenantEntitlements.id}
              name={consts.formFields.tenantEntitlements.name}
            />
          </label>
        </div>
      </fieldset>
    );
  }

  /* eslint-disable-next-line */
  get publicContactInfo() {
    const { readOnly } = this.props;

    return (
      <fieldset className={formColumn} disabled={readOnly}>
        {header('ACCOUNTS.GENERAL_INFO.PUBLIC_CONTACT_INFO_HEADER')}
        <FormGroup
          {...consts.formFields.phone}
          key={consts.formFields.phone.name}
          label={<Translate id={consts.formFields.phone.translatedLabel} />}
        />
        <FormGroup
          {...consts.formFields.contactEmail}
          key={consts.formFields.contactEmail.name}
          label={
            <Translate id={consts.formFields.contactEmail.translatedLabel} />
          }
        />
      </fieldset>
    );
  }

  resetLocally = () => {
    const { reset, resetState } = this.props;
    reset();
    resetState();
  };

  handleSubmit = () => {
    const { handleSubmit } = this.props;
    handleSubmit();
    this.resetLocally();
  };

  handleRevert = () => {
    const { initialValues, reset } = this.props;
    const { Entitlements } = initialValues;

    this.setState({
      healthMonitorSystemEnabled:
        Entitlements && Entitlements.includes(ENTITLEMENTS.HealthMonitorSystem),
      reportsEntitlementEnabled:
        Entitlements && Entitlements.includes(ENTITLEMENTS.SafetyMonitorSystem),
    });

    reset();
  };

  render() {
    const { handleSubmit, pristine, selectedType } = this.props;

    return (
      <div className={formWrapper}>
        <PageMessage
          messageType={[
            messageTypes.EDIT_CUSTOMER_SUCCESS,
            messageTypes.CUSTOMER_ERROR,
          ]}
        />
        <form onSubmit={handleSubmit}>
          <Field component={ErrorAggregator} name="summary" />
          <div className={formGroup}>
            {this.details}
            {this.primaryAdmin}
            {this.organizationEntitlements}
            {selectedType === tenantUtils.tenantTypeStrings.Provider
              ? this.publicContactInfo
              : null}
          </div>
          <div className={buttonGroup}>
            <GroupLayout>
              <Button
                key={consts.idRevertButton}
                buttonType="primary"
                disabled={pristine}
                id={consts.idRevertButton}
                inputType="button"
                onClick={this.handleRevert}
                text={<Translate id="BUTTONS.REVERT" />}
              />
              <Button
                key={consts.idSubmitButton}
                buttonType="primary"
                disabled={this.isSubmitDisabled}
                id={consts.idSubmitButton}
                onClick={this.handleSubmit}
                text={<Translate id="BUTTONS.SAVE" />}
              />
            </GroupLayout>
          </div>
        </form>
      </div>
    );
  }
}

Form.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  adminUserId: PropTypes.string.isRequired,
  allowEmptyValue: PropTypes.bool.isRequired,
  dealerId: PropTypes.string,
  delete: PropTypes.func,
  dirty: PropTypes.bool.isRequired,
  handleRevert: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditing: PropTypes.bool,
  partners: PropTypes.arrayOf(PropTypes.object),
  pristine: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  reset: PropTypes.func,
  resetState: PropTypes.func,
  selectedType: PropTypes.string.isRequired,
  submittingForm: PropTypes.bool,
  tenantId: PropTypes.string,
  timezones: PropTypes.arrayOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
  userTenantType: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  dealerId: '',
  delete: () => {},
  handleRevert: () => {},
  handleSubmit: () => {},
  isEditing: false,
  partners: [],
  readOnly: false,
  reset: () => {},
  resetState: () => {},
  submittingForm: false,
  tenantId: null,
  timezones: [],
};

const ReduxForm = reduxForm({
  enableReinitialize: true,
  form: 'addCameraGroupForm',
  validate: formLevelValidation,
})(Form);

const selector = formValueSelector('addCameraGroupForm');

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showModal,
    },
    dispatch,
  ),
});

const mapStateToProps = state => {
  const selectedType = selector(state, consts.formFields.type.name);
  const adminUserId = selector(state, consts.formFields.primaryAdminId.name);
  const dealerId = selector(state, consts.formFields.parent.name);
  return {
    adminUserId,
    allowEmptyValue: state.user.permissions.CAN_VIEW_BILLING_ORDER_ID,
    dealerId,
    selectedType,
  };
};

const OrganizationForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReduxForm);

export default withLocalize(OrganizationForm);
