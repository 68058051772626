import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import { FieldSelect, FormGroup } from 'lib';

import * as validation from 'util/validation';

import { formField } from '../../styles.css';

import * as LocationFormConsts from '../../constants';

const SubscriptionTermField = props => {
  const {
    disabled,
    initialValues,
    isNonRenewable,
    readOnly,
    subscriptionTermOptions,
  } = props;
  // Hide Renewal Period field for non-renewable subscriptions
  if (isNonRenewable) {
    return null;
  }
  return (
    <FormGroup
      key={LocationFormConsts.nameSubscriptionTermLength}
      label={<Translate id="LOCATIONS.LOCATION_FORM.SUBSCRIPTION_TERM" />}
    >
      {readOnly ? (
        initialValues.Term
      ) : (
        <Field
          className={formField}
          component={FieldSelect}
          disabled={disabled}
          isNewVersion
          name={LocationFormConsts.nameSubscriptionTermLength}
          translate
          validate={validation.required}
        >
          {subscriptionTermOptions.map(opt => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </Field>
      )}
    </FormGroup>
  );
};

SubscriptionTermField.defaultProps = {
  disabled: false,
  initialValues: [],
  readOnly: false,
};

SubscriptionTermField.propTypes = {
  disabled: PropTypes.bool,
  initialValues: PropTypes.objectOf(PropTypes.any),
  isNonRenewable: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  subscriptionTermOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SubscriptionTermField;
