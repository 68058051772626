import urlBuilder from 'queryBuilder/url';
import { GET_HMS_CONFIG, GET_HMS_JWT } from 'constants/ActionTypes';

const fetchPath = url =>
  fetch(url, {
    credentials: 'include',
  }).then(res => res.json());

export const getAuthToken = tenantId => {
  const url = urlBuilder(GET_HMS_JWT, tenantId);
  return fetchPath(url);
};

export const getHmsConfig = () => {
  const url = urlBuilder(GET_HMS_CONFIG);
  return fetchPath(url);
};

export const getFromLocalStorage = key => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : '';
};

export const getTenantInformation = (canChangeCustomer, profile) => {
  let tenantId;

  if (!canChangeCustomer) {
    ({ TenantId: tenantId } = profile);
  } else {
    ({ CustomerTenantId: tenantId } = profile);

    if (!tenantId) {
      ({ TenantId: tenantId } = profile);
    }
  }

  return { tenantId };
};
