export const FIELD_ORDER = [
  'Name',
  'IntegrationType',
  'ApiLoginUsername',
  'ConnectionString',
  'ConnectionStatus',
  'Actions',
];

export const HEADER_TRANSLATION_IDS = {
  Name: 'COMMON.NAME',
  IntegrationType: 'INTEGRATIONS.CONFIGS.INTEGRATION_TYPE',
  ApiLoginUsername: 'INTEGRATIONS.CONFIGS.API_LOGIN_USERNAME',
  ApiLoginPassword: 'INTEGRATIONS.CONFIGS.API_LOGIN_PASSWORD',
  ConnectionString: 'INTEGRATIONS.CONFIGS.CONNECTION_STRING',
  ConnectionStatus: 'INTEGRATIONS.CONFIGS.CONNECTION_STATUS',
  Actions: 'INTEGRATIONS.CONFIGS.ACTIONS',
};

export const CELL_ALIGNMENTS = {
  Actions: 'right',
};

export const idTestConnection = 'testConnection';
export const idEditConnection = 'editConnection';
export const idDeleteConnection = 'deleteConnection';
