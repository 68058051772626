// Libs
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as AlarmActions from 'actions/alarms';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';
import * as FilterActions from 'actions/filters';
import { getUsersPublicInfo } from 'actions/user';

// Components
import { NotificationsTableContainer } from 'containers';

// Utilities
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';
import getPathName, {
  getAlarmSubRoute,
  getCurrentPath,
  getFullPath,
} from 'util/getPathName';
import filterAlarmSelector from 'selectors/alarmsTableSelector';

// Styles
import { contentContainer } from 'sharedStyles/global.css';

// Constants
import * as types from 'constants/ActionTypes';
import { alarmTypes } from 'constants/alarmSettings';
import * as PATH from 'constants/urlPaths';

class NotificationsContainer extends PureComponent {
  componentDidMount() {
    const { actions, isFetchingAlarms } = this.props;
    const queryOptions = {
      filters: [],
      skip: 0,
      top: 50,
    };
    if (isFetchingAlarms === null) {
      actions.getAlarms(queryOptions, types.ALARM_FETCH_DIRECTION_NEXT);
      actions.getAlarms(
        queryOptions,
        types.ALARM_FETCH_DIRECTION_NEXT,
        alarmTypes.healthAlarms,
      );
      actions.getAlarms(
        queryOptions,
        types.ALARM_FETCH_DIRECTION_NEXT,
        alarmTypes.securityAlarms,
      );
    }
    this.pushCurrentPath();
  }

  componentDidUpdate() {
    this.pushCurrentPath();
  }

  get currentPath() {
    const { fullPath, path, profile } = this.props;
    return getCurrentPath(fullPath, path, profile, getAlarmSubRoute);
  }

  getAlarms = (queryOptions, alarmType) => {
    const { actions } = this.props;
    actions.getAlarms(
      queryOptions,
      types.ALARM_FETCH_DIRECTION_NEXT,
      alarmType,
    );
  };

  pushCurrentPath() {
    const { fullPath, history } = this.props;
    if (!fullPath.includes(this.currentPath)) {
      history.push(this.currentPath);
    }
  }

  renderChildren() {
    const {
      actions,
      alarmsNextLink,
      allAlarms,
      canChangeCustomer,
      fullPath,
      healthAlarms,
      healthAlarmsNextLink,
      isFetchingAlarms,
      isFetchingHealthAlarmData,
      isFetchingSecurityAlarmData,
      profile,
      securityAlarms,
      securityAlarmsNextLink,
      unfilteredAlarms,
    } = this.props;
    // const contHeight = Math.max(containerHeight - SECONDARY_NAV_HEIGHT, 0);

    return (
      <Switch>
        <Route
          exact
          path={PATH.PATH_ALARMS}
          render={() => <Redirect to={this.currentPath} />}
        />
        <Route
          path={PATH.PATH_ALARMS + PATH.PATH_SEGMENT_ALL}
          render={props => (
            <NotificationsTableContainer
              {...props}
              actions={actions}
              alarms={allAlarms}
              alarmsNextLink={alarmsNextLink}
              allAlarms={unfilteredAlarms}
              canChangeCustomer={canChangeCustomer}
              getAlarms={queryOptions => {
                this.getAlarms(queryOptions, undefined);
              }}
              isFetchingAlarms={isFetchingAlarms}
              path={fullPath}
              profile={profile}
              showGraphToggle
            />
          )}
        />
        <Route
          path={PATH.PATH_ALARMS + PATH.PATH_SEGMENT_HEALTH}
          render={props => (
            <NotificationsTableContainer
              {...props}
              actions={actions}
              alarms={healthAlarms}
              alarmsNextLink={healthAlarmsNextLink}
              alarmType={alarmTypes.healthAlarms}
              allAlarms={unfilteredAlarms}
              canChangeCustomer={canChangeCustomer}
              getAlarms={queryOptions => {
                this.getAlarms(queryOptions, alarmTypes.healthAlarms);
              }}
              isFetchingAlarms={isFetchingHealthAlarmData}
              path={fullPath}
              profile={profile}
            />
          )}
        />
        <Route
          path={PATH.PATH_ALARMS + PATH.PATH_SEGMENT_SECURITY}
          render={props => (
            <NotificationsTableContainer
              {...props}
              actions={actions}
              alarms={securityAlarms}
              alarmsNextLink={securityAlarmsNextLink}
              alarmType={alarmTypes.securityAlarms}
              allAlarms={unfilteredAlarms}
              canChangeCustomer={canChangeCustomer}
              getAlarms={queryOptions => {
                this.getAlarms(queryOptions, alarmTypes.securityAlarms);
              }}
              isFetchingAlarms={isFetchingSecurityAlarmData}
              path={fullPath}
              profile={profile}
            />
          )}
        />
      </Switch>
    );
  }

  render() {
    return <>{this.renderChildren()}</>;
  }
}

NotificationsContainer.defaultProps = {
  alarmsNextLink: null,
  canChangeCustomer: null,
  children: null,
  fullPath: null,
  healthAlarmsNextLink: null,
  isFetchingAlarms: null,
  isFetchingHealthAlarmData: null,
  isFetchingSecurityAlarmData: null,
  securityAlarmsNextLink: null,
  unfilteredAlarms: [],
};

NotificationsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired, 
  alarmsNextLink: PropTypes.string,
  allAlarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  canChangeCustomer: PropTypes.bool,
  children: PropTypes.node,
  fullPath: PropTypes.string,
  healthAlarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  healthAlarmsNextLink: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetchingAlarms: PropTypes.bool,
  isFetchingHealthAlarmData: PropTypes.bool,
  isFetchingSecurityAlarmData: PropTypes.bool,
  path: PropTypes.string.isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  securityAlarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  securityAlarmsNextLink: PropTypes.string,
  unfilteredAlarms: PropTypes.arrayOf(PropTypes.object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...AlarmActions,
        ...DeviceActions,
        ...LocationActions,
        ...FilterActions,
        getUsersPublicInfo,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state, ownProps) {
  const fullPath = getFullPath(ownProps.location.pathname);
  const path = getPathName(ownProps.location.pathname);
  return {
    alarmsNextLink: state.alarms.alarmsNextLink,
    allAlarms: filterAlarmSelector(state, alarmTypes.allAlarms),
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    fullPath,
    healthAlarms: filterAlarmSelector(state, alarmTypes.healthAlarms),
    healthAlarmsNextLink: state.alarms.healthAlarmsNextLink,
    isFetchingAlarms: state.alarms.isFetchingAlarmData,
    isFetchingHealthAlarmData: state.alarms.isFetchingHealthAlarmData,
    isFetchingSecurityAlarmData: state.alarms.isFetchingSecurityAlarmData,
    path,
    profile: state.user.profile,
    securityAlarms: filterAlarmSelector(state, alarmTypes.securityAlarms),
    securityAlarmsNextLink: state.alarms.securityAlarmsNextLink,
    unfilteredAlarms: filterAlarmSelector(
      { alarms: state.alarms, filters: { filterStatus: { alarms: [] } } },
      alarmTypes.allAlarms,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withAITracking(
    ai.reactPlugin,
    NotificationsContainer,
    'NotificationsContainer',
    contentContainer,
  ),
);
