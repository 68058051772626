import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconPause = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 12 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="iPhone"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="03-alarms-review"
          transform="translate(-13.000000, -347.000000)"
          fill={props.fill}
        >
          <g id="Video" transform="translate(-1.000000, 64.000000)">
            <g
              id="Player-Controls-Clip"
              transform="translate(0.000000, 272.000000)"
            >
              <g id="ic_pause" transform="translate(13.000000, 11.000000)">
                <path
                  d="M5,12.9230769 C5,13.6520741 4.70177778,14 4,14 L2,14 C1.29822222,14 1,13.6520741 1,12.9230769 L1,1.07692308 C1,0.347925926 1.29822222,0 2,0 L4,0 C4.70177778,0 5,0.347925926 5,1.07692308 L5,12.9230769 Z M13,12.9230769 C13,13.6520741 12.7017778,14 12,14 L10,14 C9.29822222,14 9,13.6520741 9,12.9230769 L9,1.07692308 C9,0.347925926 9.29822222,0 10,0 L12,0 C12.7017778,0 13,0.347925926 13,1.07692308 L13,12.9230769 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconPause.defaultProps = {
  id: 'idPause',
  className: null,
  width: '17px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconPause;
