import React, { Component } from 'react';
import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';
import Moment from 'moment';

// Components
import { Canvas, PresetTourBox } from 'components';
import { NoStyleButton } from 'lib';
// Icons
import {
  IconToggleZoomMode,
  IconToggleToursMode,
  IconGoToHomePreset,
  IconPtzArrow,
  IconFocusAuto,
  IconZoomInFull,
  IconZoomIn,
  IconZoomOut,
  IconZoomOutFull,
} from 'icons';

// Styles
import {
  ptzFunctionSelectionButton,
  ptzFunctionSelectionButtonActive,
  ptzActiveFunctionIconsContainer,
  ptzActiveFunctionIconsSideSpacing,
  ptzActiveFunctionIconsColumn,
  ptzActiveFunctionIcon,
  ptzActiveFunctionIconMouseDown,
  ptzFunctionMenuIconsRow,
  ptzBar,
  flexRow,
  ptzArrowActive,
  ptzModeEnabled,
  ptzMenuButtonWrapper,
  zoomIn,
} from './styles.css';

// Constants
import { PTZ_FUNCTION_NAMES } from './constants';

const ptzFunctions = {
  MIN_ZOOM_SIZE: 5,
  PLAYER_BTN_HEIGHT: 60,
  PTZ_BTN_HEIGHT: 42,
  VALID_PTZ_TARGETS: ['dragToZoomArea', 'ptzModeSelection', 'activeLabel'],
  PTZ_AREA: 'ptzModeSelection',
  iconArrowSize: 46,
  zoomValue: 0,
  partitions: 4,
  noValue: 0,
  insideAxisRegion: 15,
  arrowKey: 'ptzPanTiltIcon',
};

const zoomTypes = {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_IN_FULL: 'ZOOM_IN_FULL',
  ZOOM_OUT: 'ZOOM_OUT',
  ZOOM_OUT_FULL: 'ZOOM_OUT_FULL',
};

const keyCodes = {
  W: 87,
  A: 65,
  S: 83,
  D: 68,
  Z: 90,
  UP: 38,
  LEFT: 37,
  DOWN: 40,
  RIGHT: 39,
  NUM_UP: 104,
  NUM_LEFT: 97,
  NUM_DOWN: 98,
  NUM_RIGHT: 102,
  ADD: 107,
  SUBTRACT: 109,
  PLUS: 187,
  MINUS: 189,
};

const basePanTiltAmount = 0.1;
const baseZoomAmount = 0.05;
const dragDeadzoneRadius = 24;
const dragRequestThrottleMsGateway = 1000;
const dragRequestThrottleMsWebRtc = 1000 / 20;

class PtzFunctionMenu extends Component {
  constructor(props) {
    super(props);
    this.ptzFunctionMenuDiv = React.createRef();
    this.state = {
      activePtzFunction: null,
      lastPtzFunction: null,
      idOfElementWithMouseDown: null,
      keyMap: {},
      lastOptionsObject: null,
      rectStyle: {},
      pos: {},
      roi: {},
      isDragging: false,
      drawArea: null,
      ptzButtonHover: false,
      lastPtzTiltCallTime: Moment(),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (
      this.props.useDataChannel === true &&
      nextProps.useDataChannel === false &&
      this.state.isProcessingKeyInput &&
      this.state.lastOptionsObject
    ) {
      this.sendCameraPanTiltZoomCommand(this.state.lastOptionsObject, false);
    }

    if (
      this.props.isPtzModeEnabled === true &&
      nextProps.isPtzModeEnabled === false
    ) {
      this.clearActivePtzFunction();
    } else if (
      nextProps.isPtzModeEnabled === true &&
      this.props.activePtzFunction !== PTZ_FUNCTION_NAMES.TOURS &&
      this.ptzFunctionMenuDiv.current
    ) {
      this.ptzFunctionMenuDiv.current.focus();
    }

    if (!this.props.isVideoPlaying && nextProps.isVideoPlaying) {
      this.props.actions.getPtzPresets(
        this.props.webRtcHelper,
        this.props.camera.Id,
        this.props.camera.RemoteId,
      );
    }
  }

  clickToCenter = (width, height, e) => {
    const options = {
      fieldOfView: {
        imagePanelSize: {
          width,
          height,
        },
        ROI: {
          x: e.nativeEvent.offsetX - width / 2,
          y: e.nativeEvent.offsetY - height / 2,
          width,
          height,
        },
      },
    };
    this.sendCameraPanTiltZoomCommand(options, false);
  };

  updatePosition = evt => {
    const { pos } = this.state;
    const el = this.state.drawArea;
    const h = pos.startY - evt.pageY + el.y;
    const w = pos.startX - evt.pageX + el.x;
    const height = Math.abs(h);
    const width = Math.abs(w);
    const y = h > 0 ? evt.pageY - el.y : pos.startY;
    const x = w > 0 ? evt.pageX - el.x : pos.startX;

    const rectStyle = {
      position: 'absolute',
      border: this.props.selectionBorder,
      background: this.props.selectionBackground,
      width: `${width}px`,
      height: `${height}px`,
      left: `${x}px`,
      top: `${y}px`,
    };

    this.setState({
      rectStyle,
      roi: {
        x,
        y,
        height,
        width,
      },
    });
  };

  reset = () => {
    this.setState({
      isDragging: false,
      roi: {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      },
      rectStyle: {
        width: '0px',
        height: '0px',
      },
    });
  };

  initDrag = evt => {
    const isCorrectTarget = ptzFunctions.VALID_PTZ_TARGETS.includes(
      evt.target.id,
    );
    if (!isCorrectTarget) {
      return;
    }
    const targetEl = evt.target.getBoundingClientRect();
    const x = evt.pageX - targetEl.left;
    const y = evt.pageY - targetEl.top;
    this.setState({
      isDragging: true,
      pos: {
        startX: x,
        startY: y,
      },
      drawArea: targetEl,
    });
  };

  moveDrag = evt => {
    if (this.state.isDragging) {
      this.addZoomIndicator(evt);
      this.updatePosition(evt);
    }
  };

  addZoomIndicator = evt => evt.target.classList.add(zoomIn);

  removeZoomIndicator = evt => {
    evt.target.classList.remove(zoomIn);
    evt.target.parentElement.classList.remove(zoomIn);
  };

  endDrag = evt => {
    const isCorrectTarget = ptzFunctions.VALID_PTZ_TARGETS.includes(
      evt.target.id,
    );
    this.removeZoomIndicator(evt);
    const isValidDrag =
      this.state.roi.height > ptzFunctions.MIN_ZOOM_SIZE &&
      this.state.roi.width > ptzFunctions.MIN_ZOOM_SIZE;

    if (isCorrectTarget && isValidDrag) {
      const options = {
        fieldOfView: {
          imagePanelSize: {
            width: this.props.frameWidth,
            height: this.props.frameHeight,
          },
          ROI: {
            x: this.state.pos.startX,
            y: this.state.pos.startY,
            width: this.state.roi.width,
            height: this.state.roi.height,
          },
        },
      };
      this.sendCameraPanTiltZoomCommand(options, false);
    }

    if (isCorrectTarget && !isValidDrag) {
      this.clickToCenter(this.props.frameWidth, this.props.frameHeight, evt);
    }

    this.reset();
  };

  sendWebRtcCommand = (route, query) => {
    const argument = {
      id: new Date().getTime(),
      route,
      query,
    };

    this.props.evoPlayer.webrtc.sendDirectlyToAll(
      'emsCommandChannel',
      'command',
      { command: 'request', argument, base64: 0 },
    );
  };

  sendCameraPanTiltZoomCommand = (options, useDataChannel) => {
    if (!options) {
      return avoLogError(
        "Invalid value provided for argument 'options' in ptz zoom",
        options,
      );
    }

    if (typeof useDataChannel !== 'boolean') {
      useDataChannel = this.props.useDataChannel;
    }

    if (useDataChannel) {
      this.props.actions.cameraPanTiltZoom(
        this.props.webRtcHelper,
        this.props.deviceId,
        this.props.camera.Id,
        this.props.camera.RemoteId,
        options,
      );
    } else {
      this.props.actions.cameraPanTiltZoomGateway(
        this.props.deviceId,
        this.props.camera.RemoteId,
        options,
      );
    }
  };

  togglePanTiltMode = value => {
    // Only care about value if it's a bool
    if (typeof value !== 'boolean') {
      value = null;
    }
    this.setState(prevState => {
      if (
        value === false ||
        (value === null &&
          this.props.activePtzFunction === PTZ_FUNCTION_NAMES.PAN_TILT)
      ) {
        if (prevState.lastPtzFunction === PTZ_FUNCTION_NAMES.PAN_TILT) {
          this.props.actions.setActivePtzFunction(null);
        } else {
          this.props.actions.setActivePtzFunction(this.state.lastPtzFunction);
        }
      } else {
        this.props.actions.setActivePtzFunction(PTZ_FUNCTION_NAMES.PAN_TILT);
        return {
          lastPtzFunction: this.props.activePtzFunction,
        };
      }
    });
  };

  focusAuto = () => {
    this.props.actions.cameraAutofocus(
      this.props.webRtcHelper,
      this.props.camera.Id,
      this.props.camera.RemoteId,
    );

    this.clearActivePtzFunction();
  };

  goToHomePreset = () => {
    this.props.actions.goToHomePtzPreset(
      this.props.webRtcHelper,
      this.props.camera.Id,
      this.props.camera.RemoteId,
    );

    this.clearActivePtzFunction();
  };

  toggleToursMode = () => {
    if (this.props.activePtzFunction === PTZ_FUNCTION_NAMES.TOURS) {
      this.props.actions.setActivePtzFunction(null);
    } else {
      this.props.actions.setActivePtzFunction(PTZ_FUNCTION_NAMES.TOURS);
    }
  };

  toggleZoomMode = () => {
    if (this.props.activePtzFunction === PTZ_FUNCTION_NAMES.ZOOM) {
      this.props.actions.setActivePtzFunction(null);
    } else {
      this.props.actions.setActivePtzFunction(PTZ_FUNCTION_NAMES.ZOOM);
    }
  };

  cancelPtzMode = () => {
    this.setState({ activePtzFunction: null });
    this.props.exitPtzMode();
  };

  isKeyUpDir = keyMap => {
    return keyMap[keyCodes.UP] || keyMap[keyCodes.W] || keyMap[keyCodes.NUM_UP];
  };

  isKeyDownDir = keyMap => {
    return (
      keyMap[keyCodes.DOWN] || keyMap[keyCodes.S] || keyMap[keyCodes.NUM_DOWN]
    );
  };

  isKeyLeftDir = keyMap => {
    return (
      keyMap[keyCodes.LEFT] || keyMap[keyCodes.A] || keyMap[keyCodes.NUM_LEFT]
    );
  };

  isKeyRightDir = keyMap => {
    return (
      keyMap[keyCodes.RIGHT] || keyMap[keyCodes.D] || keyMap[keyCodes.NUM_RIGHT]
    );
  };

  isKeyZoomIn = keyMap => {
    return keyMap[keyCodes.PLUS] || keyMap[keyCodes.ADD];
  };

  isKeyZoomOut = keyMap => {
    return keyMap[keyCodes.MINUS] || keyMap[keyCodes.SUBTRACT];
  };

  populatePanTiltZoomOptionsFromKeyMap = (options, keyMap) => {
    if (!options.continuous) options.continuous = {};

    const up = this.isKeyUpDir(keyMap);
    const left = this.isKeyLeftDir(keyMap);
    const down = this.isKeyDownDir(keyMap);
    const right = this.isKeyRightDir(keyMap);
    const zoomIn = this.isKeyZoomIn(keyMap);
    const zoomOut = this.isKeyZoomOut(keyMap);

    if (up) {
      options.continuous.tiltAmount = (-basePanTiltAmount).toString();
    }
    if (down) {
      options.continuous.tiltAmount = basePanTiltAmount.toString();
    }
    if (left) {
      options.continuous.panAmount = (-basePanTiltAmount).toString();
    }
    if (right) {
      options.continuous.panAmount = basePanTiltAmount.toString();
    }
    if (zoomIn) {
      options.continuous.zoomAmount = baseZoomAmount.toString();
    }
    if (zoomOut) {
      options.continuous.zoomAmount = (-baseZoomAmount).toString();
    }
  };

  handleKeyDown = e => {
    if (
      this.props.activePtzFunction !== PTZ_FUNCTION_NAMES.TOURS &&
      Object.values(keyCodes).includes(e.keyCode)
    ) {
      this.startKeyboardPTZ(e);
    }
  };

  handleKeyUp = e => {
    if (
      this.props.activePtzFunction !== PTZ_FUNCTION_NAMES.TOURS &&
      Object.values(keyCodes).includes(e.keyCode)
    ) {
      this.stopKeyboardPTZ(e);
    }
  };

  handleMouseWheel = e => {
    if (e.target.id !== ptzFunctions.PTZ_AREA) {
      return;
    }
    if (e.nativeEvent.wheelDelta > 0) {
      this.zoomInOut(zoomTypes.ZOOM_IN, 'START', true);
    } else if (e.nativeEvent.wheelDelta < 0) {
      this.zoomInOut(zoomTypes.ZOOM_OUT, 'START', true);
    }
  };

  startKeyboardPTZ = e => {
    if (!this.props.showMenu) return;
    e = e || event; // to deal with IE

    const newKeyMap = Object.assign({}, this.state.keyMap);
    newKeyMap[e.keyCode] = e.type === 'keydown';

    if (deepEqual(this.state.keyMap, newKeyMap)) {
      return;
    }

    this.setState({ keyMap: newKeyMap, isProcessingKeyInput: true });

    const options = {
      continuous: {
        panAmount: '0.0',
        tiltAmount: '0.0',
        zoomAmount: '0.0',
        action: 'START',
      },
    };

    this.populatePanTiltZoomOptionsFromKeyMap(options, newKeyMap);

    this.sendCameraPanTiltZoomCommand(options);
  };

  stopKeyboardPTZ = e => {
    if (this.state.isProcessingKeyInput) {
      e = e || event; // to deal with IE

      const newKeyMap = Object.assign({}, this.state.keyMap);
      newKeyMap[e.keyCode] = false;
      const isAtLeastOneKeyDown = Object.values(newKeyMap).includes(true);

      const options = {
        continuous: {
          panAmount: '0.0',
          action: isAtLeastOneKeyDown ? 'START' : 'STOP',
        },
      };

      this.populatePanTiltZoomOptionsFromKeyMap(options, newKeyMap);

      this.setState({
        keyMap: newKeyMap,
        isProcessingKeyInput: isAtLeastOneKeyDown,
      });

      this.sendCameraPanTiltZoomCommand(options);
    }
  };

  setActivePtzFunction = functionName => {
    this.props.actions.setActivePtzFunction(functionName);
  };

  clearActivePtzFunction = () => {
    this.props.actions.setActivePtzFunction(null);
  };

  ptzFunctionButtons = [
    {
      componentName: IconFocusAuto,
      functionName: PTZ_FUNCTION_NAMES.AUTO_FOCUS,
      onMouseDown: () => {
        this.setActivePtzFunction(PTZ_FUNCTION_NAMES.AUTO_FOCUS);
      },
      onMouseUp: this.focusAuto,
      onMouseLeave: () => {
        if (this.props.activePtzFunction === PTZ_FUNCTION_NAMES.AUTO_FOCUS) {
          this.clearActivePtzFunction();
        }
      },
    },
    {
      componentName: IconGoToHomePreset,
      functionName: PTZ_FUNCTION_NAMES.GO_TO_HOME,
      onMouseDown: () => {
        this.setActivePtzFunction(PTZ_FUNCTION_NAMES.GO_TO_HOME);
      },
      onMouseUp: this.goToHomePreset,
      onMouseLeave: () => {
        if (this.props.activePtzFunction === PTZ_FUNCTION_NAMES.GO_TO_HOME) {
          this.clearActivePtzFunction();
        }
      },
    },
    {
      componentName: IconToggleToursMode,
      functionName: PTZ_FUNCTION_NAMES.TOURS,
      onClick: this.toggleToursMode,
    },
    {
      componentName: IconToggleZoomMode,
      functionName: PTZ_FUNCTION_NAMES.ZOOM,
      onClick: this.toggleZoomMode,
    },
  ];

  // ZOOM ACTIONS
  zoomInOut = (zoomType, action, isStep = false) => {
    let zoomAmount = '0.0';
    switch (zoomType) {
      case zoomTypes.ZOOM_IN: {
        zoomAmount = baseZoomAmount.toString();
        break;
      }
      case zoomTypes.ZOOM_OUT: {
        zoomAmount = (-baseZoomAmount).toString();
        break;
      }
      case zoomTypes.ZOOM_IN_FULL: {
        zoomAmount = '1.0';
        break;
      }
      case zoomTypes.ZOOM_OUT_FULL: {
        zoomAmount = '-1.0';
        break;
      }
    }

    this.sendCameraPanTiltZoomCommand({
      continuous: { zoomAmount, action },
    });

    if (
      action === 'START' &&
      isStep &&
      (zoomType === zoomTypes.ZOOM_IN || zoomType === zoomTypes.ZOOM_OUT)
    ) {
      setTimeout(() => {
        this.sendCameraPanTiltZoomCommand({
          continuous: { zoomAmount: 0, action: 'STOP' },
        });
      }, 200);
    }
  };

  zoomActiveButtons = [
    {
      id: 'zoomOutFull',
      onMouseUp: () => this.zoomInOut(zoomTypes.ZOOM_OUT_FULL, 'START'),
      onMouseDown: () => {},
      IconName: IconZoomOutFull,
    },
    {
      id: 'zoomOut',
      onMouseDown: () => this.zoomInOut(zoomTypes.ZOOM_OUT, 'START'),
      onMouseUp: () => this.zoomInOut(zoomTypes.ZOOM_OUT, 'STOP'),
      IconName: IconZoomOut,
    },
    {
      id: 'zoomIn',
      onMouseDown: () => this.zoomInOut(zoomTypes.ZOOM_IN, 'START'),
      onMouseUp: () => this.zoomInOut(zoomTypes.ZOOM_IN, 'STOP'),
      IconName: IconZoomIn,
    },
    {
      id: 'zoomInFull',
      onMouseUp: () => this.zoomInOut(zoomTypes.ZOOM_IN_FULL, 'START'),
      onMouseDown: () => {},
      IconName: IconZoomInFull,
    },
  ];

  // MOUSE STATE FUNCTIONS
  handleFunctionIconMouseDown = id => {
    this.setState({ idOfElementWithMouseDown: id });
  };

  clearMouseDownElement = () => {
    if (this.state.idOfElementWithMouseDown !== null) {
      this.setState({ idOfElementWithMouseDown: null });
    }
  };

  // ELEMENT GENERATION FUNCTIONS
  buildPtzFunctionButton = (
    ComponentName,
    functionName,
    onClick,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  ) => {
    const className =
      this.props.activePtzFunction === functionName
        ? ptzFunctionSelectionButtonActive
        : ptzFunctionSelectionButton;

    return (
      <NoStyleButton
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        key={functionName}
        className={ptzMenuButtonWrapper}
      >
        <ComponentName className={className} width="24px" height="24px" />
      </NoStyleButton>
    );
  };

  buildPtzFunctionButtons = () => {
    return this.ptzFunctionButtons.map(element => {
      return this.buildPtzFunctionButton(
        element.componentName,
        element.functionName,
        element.onClick,
        element.onMouseDown,
        element.onMouseUp,
        element.onMouseLeave,
      );
    });
  };

  buildPtzActiveButtons = activeButtons => {
    return activeButtons.map(element => {
      return this.buildClickableActiveFunctionButton(
        element.id,
        element.onMouseDown,
        element.onMouseUp,
        element.IconName,
        element.calculateShouldShow,
      );
    });
  };

  buildClickableActiveFunctionButton = (
    id,
    onMouseDown,
    onMouseUp,
    IconName,
    calculateShouldShow,
  ) => {
    return calculateShouldShow === undefined || calculateShouldShow() ? (
      <div
        id={id}
        className={
          this.state.idOfElementWithMouseDown === id
            ? ptzActiveFunctionIconMouseDown
            : ptzActiveFunctionIcon
        }
        onMouseUp={e => {
          onMouseUp();
          this.clearMouseDownElement();
        }}
        onMouseDown={e => {
          onMouseDown();
          this.handleFunctionIconMouseDown(id);
        }}
        onMouseLeave={e => {
          if (this.state.idOfElementWithMouseDown === id) {
            onMouseUp();
            this.clearMouseDownElement();
          }
        }}
        key={id}
      >
        <IconName />
      </div>
    ) : null;
  };

  getPtzBarMargin = () => {
    const allBtnHeight =
      ptzFunctions.PLAYER_BTN_HEIGHT + ptzFunctions.PTZ_BTN_HEIGHT;
    const bufferHeight = (this.props.playerHeight - this.props.frameHeight) / 2;
    return bufferHeight < ptzFunctions.PLAYER_BTN_HEIGHT ||
      bufferHeight > allBtnHeight
      ? ptzFunctions.PLAYER_BTN_HEIGHT - bufferHeight
      : 0;
  };

  closePresetTour = () => {
    this.props.actions.setActivePtzFunction(null);
  };

  getCommonElements() {
    if (
      this.props.hidePtzFunctionMenu ||
      this.state.isDragging ||
      this.props.activePtzFunction === PTZ_FUNCTION_NAMES.PAN_TILT
    ) {
      return null;
    }

    return (
      <div>
        {this.state.isDragging ? null : this.getPtzActiveIcons()}
        <div
          className={flexRow}
          style={{
            marginBottom: `${this.getPtzBarMargin()}px`,
            justifyContent: 'center',
          }}
        >
          {this.state.activePtzFunction === ptzFunctions.TOURS ? (
            <PresetTourBox
              actions={this.props.actions}
              close={this.closePresetTour}
              deviceId={this.props.deviceId}
              camera={this.props.camera}
              handleEditPtzTour={this.props.handleEditPtzTour}
              webRtcHelper={this.webRtcHelper}
              videoId={this.props.videoId}
            />
          ) : null}
          <div className={ptzFunctionMenuIconsRow}>
            {this.buildPtzFunctionButtons()}
          </div>
        </div>
      </div>
    );
  }

  canvasMouseDown = value => {
    this.togglePanTiltMode(true);
  };

  canvasMouseUp = value => {
    this.togglePanTiltMode(false);
    const options = {
      continuous: {
        panAmount: ptzFunctions.noValue.toFixed(1).toString(),
        tiltAmount: ptzFunctions.noValue.toFixed(1).toString(),
        zoomAmount: ptzFunctions.noValue.toFixed(1).toString(),
        action: 'STOP',
      },
    };
    this.sendCameraPanTiltZoomCommand(options);
    const distX = value.x - this.props.width / 2;
    const distY = value.y - this.props.height / 2;
    const cursorDistanceFromCenter = Math.sqrt(
      Math.pow(distX, 2) + Math.pow(distY, 2),
    );
    if (cursorDistanceFromCenter > dragDeadzoneRadius) {
      this.setState({ ptzButtonHover: false });
    }
  };

  getCursorDistanceFromCircle = (x, y, props) => {
    const centerX = props.width / 2;
    const centerY = props.height / 2;
    const distX = x - centerX;
    const distY = y - centerY;
    const cursorDistanceFromCenter = Math.sqrt(
      Math.pow(distX, 2) + Math.pow(distY, 2),
    );
    return cursorDistanceFromCenter;
  };

  onCanvasMouseMove = (e, props) => {
    if (
      this.getCursorDistanceFromCircle(
        e.nativeEvent.offsetX,
        e.nativeEvent.offsetY,
        props,
      ) > dragDeadzoneRadius
    ) {
      this.setState({ ptzButtonHover: false });
    }
  };

  canvasMouseMove = value => {
    const currentPtzTiltCallTime = Moment();
    if (
      currentPtzTiltCallTime - this.state.lastPtzTiltCallTime >
      (this.props.useDataChannel
        ? dragRequestThrottleMsWebRtc
        : dragRequestThrottleMsGateway)
    ) {
      let panAmount = 0.0;
      let tiltAmount = 0.0;
      const centerX = this.props.width / 2;
      const centerY = this.props.height / 2;
      const distX = value.x - centerX;
      const distY = value.y - centerY;
      if (
        this.getCursorDistanceFromCircle(value.x, value.y, this.props) >
        dragDeadzoneRadius
      ) {
        panAmount = distX / centerX;
        tiltAmount = distY / centerY;
      }

      const options = {
        continuous: {
          panAmount: panAmount.toString(),
          tiltAmount: tiltAmount.toString(),
          action: 'START',
        },
      };

      this.setState({ lastPtzTiltCallTime: currentPtzTiltCallTime }, () => {
        this.sendCameraPanTiltZoomCommand(options);
      });
    }
  };

  getPtzActiveIcons = () => {
    return (
      <div className={ptzActiveFunctionIconsContainer}>
        <div className={ptzActiveFunctionIconsSideSpacing} />
        <div className={ptzActiveFunctionIconsColumn}>
          {this.props.activePtzFunction === PTZ_FUNCTION_NAMES.ZOOM
            ? this.buildPtzActiveButtons(this.zoomActiveButtons)
            : null}
        </div>
        <div className={ptzActiveFunctionIconsSideSpacing} />
      </div>
    );
  };

  getPtzArrowElement() {
    return this.props.hidePtzArrow ? null : (
      <div
        id={ptzFunctions.arrowKey}
        key={ptzFunctions.arrowKey}
        className={ptzArrowActive}
        style={{
          position: 'absolute',
          top: `${(this.props.height - ptzFunctions.iconArrowSize) / 2}px`,
          left: `${(this.props.width - ptzFunctions.iconArrowSize) / 2}px`,
          width: `${ptzFunctions.iconArrowSize}px`,
          height: `${ptzFunctions.iconArrowSize}px`,
          // Moving colors to consts won't work so left here
          backgroundColor: this.state.ptzButtonHover
            ? '#5db6ff'
            : 'rgba(0, 0, 0, 0.5)',
        }}
        onMouseMove={e => {
          if (
            this.getCursorDistanceFromCircle(
              e.nativeEvent.offsetX,
              e.nativeEvent.offsetY,
              {
                height: ptzFunctions.iconArrowSize,
                width: ptzFunctions.iconArrowSize,
              },
            ) <= dragDeadzoneRadius
          ) {
            this.setState({ ptzButtonHover: true });
          }
        }}
        // PtzArrowElement mouse events only trigger when there is no Canvas element
        // e.g. when this.state.ptzButtonHover is false, so Canvas must handle subsequent events
      >
        <IconPtzArrow
          fillBackgroundColor=""
          width={`${ptzFunctions.iconArrowSize}px`}
          height={`${ptzFunctions.iconArrowSize}px`}
        />
      </div>
    );
  }

  render() {
    let renderElement;
    const arrowElement = this.getPtzArrowElement();
    const commonElement = this.getCommonElements();
    const {
      cameraResWidth,
      cameraResHeight,
      xOffset,
      yOffset,
      rotation,
    } = this.props;
    const streamDetails = {
      cameraResWidth,
      cameraResHeight,
      xOffset,
      yOffset,
      rotation,
    };
    if (this.state.ptzButtonHover) {
      renderElement = (
        <div>
          {arrowElement}
          <div className={ptzBar} onWheel={this.handleMouseWheel}>
            <Canvas
              key="canvas"
              ref="canvas"
              type={this.props.type}
              streamDetails={streamDetails}
              width={this.props.width}
              height={this.props.height}
              canvasList={this.props.canvasList}
              updateCanvasList={this.props.updateCanvasList}
              onObjectSelected={this.props.onObjectSelected}
              canEditROI={this.props.canEditROI}
              isPtzArrowEnabled={this.props.isPtzArrowEnabled}
              canvasMouseDown={this.canvasMouseDown}
              canvasMouseUp={this.canvasMouseUp}
              canvasMouseMove={this.canvasMouseMove}
              onCanvasMouseMove={this.onCanvasMouseMove}
              canvasElementMouseLeave={() => {
                this.setState({ ptzButtonHover: false });
              }}
              userHasEnabledPtzRules={this.props.userHasEnabledPtzRules}
              cameraSupportsPtz={this.props.cameraSupportsPtz}
            />
            {commonElement}
          </div>
        </div>
      );
    } else {
      renderElement = (
        <div>
          <div
            id={ptzFunctions.PTZ_AREA}
            className={ptzBar}
            onMouseDown={this.initDrag}
            onMouseMove={this.moveDrag}
            onMouseLeave={this.reset}
            onMouseUp={this.endDrag}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            onWheel={this.handleMouseWheel}
            tabIndex="0"
            ref={this.ptzFunctionMenuDiv}
          >
            {this.state.isDragging ? null : arrowElement}
            <div id="dragToZoomArea" style={this.state.rectStyle} />
            {commonElement}
          </div>
        </div>
      );
    }

    return (
      <div className={ptzModeEnabled}>
        {this.props.showMenu ? renderElement : null}
      </div>
    );
  }
}

PtzFunctionMenu.defaultProps = {
  selectionBorder: '1px solid green',
  selectionBackground: 'rgba(120,150,90,0.5)',
};

PtzFunctionMenu.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  exitPtzMode: PropTypes.func.isRequired,
};

export default PtzFunctionMenu;
