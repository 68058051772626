export const ROLES = {
  Administrator: 'Administrator',
  Manager: 'Manager',
  Responder: 'Responder',
  Viewer: 'Viewer',
};

export const parseUserRoles = userRolesString => {
  if (userRolesString.includes(ROLES.Administrator)) {
    return ROLES.Administrator;
  }
  if (userRolesString.includes(ROLES.Manager)) {
    return ROLES.Manager;
  }
  if (userRolesString.includes(ROLES.Responder)) {
    return ROLES.Responder;
  }
  if (userRolesString.includes(ROLES.Viewer)) {
    return ROLES.Viewer;
  }
  return null;
};

// NOTE: The code will overwrite the above when all the code is translated
export const ROLES_KEYS = {
  Administrator: { key: 'ROLES.ADMINISTRATOR', value: 'Administrator' },
  Manager: { key: 'ROLES.MANAGER', value: 'Manager' },
  Responder: { key: 'ROLES.RESPONDER', value: 'Responder' },
  Viewer: { key: 'ROLES.VIEWER', value: 'Viewer' },
};

export const STATUS_KEYS = {
  Disabled: { key: 'STATUS.DISABLED', value: 'Disabled' },
  Enabled: { key: 'STATUS.ENABLED', value: 'Enabled' },
  Invited: { key: 'STATUS.INVITED', value: 'Invited' },
  Locked: { key: 'STATUS.LOCKED', value: 'Locked' },
};

export const ORG_STATUS_KEYS = {
  Activated: {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_ACTIVE',
    value: 'Activated',
  },
  Active: { key: 'ORGANIZATION_STATUS.ORGANIZATION_ACTIVE', value: 'Active' },
  NeverActive: {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_NEVER_ACTIVE',
    value: 'NeverActive',
  },
  None: {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_STATUS_UNKNOWN',
    value: 'None',
  },
  'Not Active': {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_INACTIVE',
    value: 'Not Active',
  },
  PendingActivation: {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_PENDING_ACTIVATION',
    value: 'PendingActivation',
  },
  Suspended: {
    key: 'ORGANIZATION_STATUS.ORGANIZATION_SUSPENDED',
    value: 'Suspended',
  },
};

export const parseOrgStatusKey = statusString => {
  if (statusString.includes(ORG_STATUS_KEYS.Active.value)) {
    return ORG_STATUS_KEYS.Active.key;
  }
  if (statusString.includes(ORG_STATUS_KEYS['Not Active'].value)) {
    return ORG_STATUS_KEYS['Not Active'].key;
  }
  if (statusString.includes(ORG_STATUS_KEYS.None.value)) {
    return ORG_STATUS_KEYS.None.key;
  }
  if (statusString.includes(ORG_STATUS_KEYS.Suspended.value)) {
    return ORG_STATUS_KEYS.Suspended.key;
  }
  return null;
};

export const parseUserRolesKey = userRolesString => {
  if (userRolesString.includes(ROLES_KEYS.Administrator.value)) {
    return ROLES_KEYS.Administrator.key;
  }
  if (userRolesString.includes(ROLES_KEYS.Manager.value)) {
    return ROLES_KEYS.Manager.key;
  }
  if (userRolesString.includes(ROLES_KEYS.Responder.value)) {
    return ROLES_KEYS.Responder.key;
  }
  if (userRolesString.includes(ROLES_KEYS.Viewer.value)) {
    return ROLES_KEYS.Viewer.key;
  }
  return null;
};
