// Libs
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import MicroFrontend from 'components/MicroFrontend';
import { ListNav } from 'lib';

// Utils
import { getActiveLanguageCode } from 'util/languagesUtils';
import {
  getAuthToken,
  getFromLocalStorage,
  getHmsConfig,
  getTenantInformation,
} from './reports.helpers';

const ReportsWrapper = ({ canChangeCustomer, history, profile }) => {
  const [authToken, setAuthToken] = useState(localStorage.authToken);

  // HACK: Reports is using the same config as HMS for now
  const [reportsConfig, setReportsConfig] = useState(getFromLocalStorage('hmsConfig'));

  const { AppInsightsKey, VizAPIUrl, VizUIUrl } = reportsConfig || {};
  const languageCode = getActiveLanguageCode();
  const { LocalizationPreference } = profile;

  const config = useMemo(() => {
    if (!VizAPIUrl || !authToken) {
      return null;
    }

    return {
      apiUrl: VizAPIUrl,
      appInsightsKey: AppInsightsKey,
      authToken,
      history,
      languageCode,
      localizationPreference: LocalizationPreference,
    };
  }, [authToken, history, AppInsightsKey, VizAPIUrl, languageCode, LocalizationPreference]);

  useEffect(() => {
    if (!authToken) {
      const { tenantId } = getTenantInformation(canChangeCustomer, profile);
      getAuthToken(tenantId).then(setAuthToken);
    }
  }, [authToken, canChangeCustomer, profile]);

  // HACK: Same as above, using HMS config for now
  useEffect(() => {
    if (!reportsConfig) {
      getHmsConfig().then(setReportsConfig);
    }
  }, [reportsConfig]);

  return (
    <>
      <ListNav canChangeCustomer={canChangeCustomer} title={null} />
      {config && VizUIUrl && (
        <MicroFrontend config={config} host={VizUIUrl} name="Reports" />
      )}
    </>
  );
};

export default withRouter(ReportsWrapper);

ReportsWrapper.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};
