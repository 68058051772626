/* These Constants represent the height of Global UI Elements such as Header and Footer  
 * These Values:
 *   - Must be shared across different pages rather than defining in their local scope
 *   - Must be updated when height of these elements get updated in CSS.
*/

export const LISTNAV_HEIGHT = 80;
export const MASTHEAD_HEIGHT = 80;          // Match: .masthead min-height
export const FOOTER_HEIGHT = 50;            // Match: .scrollFooter height
export const SECONDARY_NAV_HEIGHT = 40;     // Match: .secondaryNavContainer height
export const SCROLLBAR_SIZE = 18;           // This is the thickness of a scrollbar
export const TABLE_MARGIN = 50;             // Margin around OGTable
export const SEARCH_BAR_HEIGHT = 70;        // ListNav Filter element height
export const OGTABLE_THEAD_HEIGHT = 24;     // OGTable Table Head Height
export const OGTABLE_PADDING = 16;          // Set by ListViewContainer
