import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

// Constants
import * as CameraTypes from 'constants/cameraTypes';

// Styles
import {
  coloredDot,
  disabledIcon,
  offlineIcon,
  onlineIcon,
} from 'sharedStyles/connectionStatus.css';
import { connectedIconDiv, connectedLabel } from './styles.css';

class CellConnectionState extends Component {
  get iconStyle() {
    const { menuProps } = this.props;
    const { cameraStatusObj } = menuProps;
    const { realState, state } = cameraStatusObj;
    if (state === CameraTypes.CAMERA_CONNECTED) {
      return `${onlineIcon} ${coloredDot}`;
    }
    if (realState === CameraTypes.CAMERA_DISABLED) {
      return `${disabledIcon} ${coloredDot}`;
    }
    return `${offlineIcon} ${coloredDot}`;
  }

  render() {
    const { className, menuProps } = this.props;
    return (
      <div className={className}>
        <div className={connectedIconDiv}>
          <div className={this.iconStyle} />
        </div>
        <div className={connectedLabel}>
          <Translate id={menuProps.cameraStatusObj.statusText} />
        </div>
      </div>
    );
  }
}

CellConnectionState.propTypes = {
  className: PropTypes.string,
  menuProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

CellConnectionState.defaultProps = {
  className: '',
};

export default CellConnectionState;
