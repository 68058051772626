import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';

import * as alarmConstants from './constants';
import {
  commentContainer,
  saveNoteButton,
  noteHeader,
  noteBody,
  formField,
  formFieldDisabled,
} from './styles.css';

function CommentComponent({ translate, handleSubmit, height }) {
  const [noteFocused, setNoteFocused] = useState(false);
  const [noteContent, setNoteContent] = useState('');

  function handleFocusNote() {
    if (!noteFocused) {
      setNoteContent('');
      setNoteFocused(true);
    }
  }

  function handleEditNote(e) {
    if (e.target.value.length <= alarmConstants.MAX_COMMENT_CHAR) {
      setNoteContent(e.target.value);
    }
  }

  function handleSaveNote() {
    setNoteFocused(false);
    handleSubmit({ ReviewComment: noteContent });
    setNoteContent('');
  }

  const style = {
    height: `${height}`,
  };
  return (
    <div className={commentContainer}>
      <div className={noteHeader}>
        <label>
          <Translate id="ALARMS.EDIT_MODAL.COMMENT_LABEL" />
        </label>
      </div>
      <div className={noteBody}>
        <div
          className={noteFocused ? formField : formFieldDisabled}
          style={style}
        >
          <textarea
            style={{ display: 'block' }}
            onFocus={handleFocusNote}
            onChange={handleEditNote}
            placeholder={translate(
              'ALARMS.EDIT_MODAL.COMMENT_FIELD_PLACEHOLDER',
            )}
            value={noteContent}
          />
          {noteFocused && (
            <div
              className={saveNoteButton}
              onClick={
                noteContent !== '' &&
                noteContent.length <= alarmConstants.MAX_COMMENT_CHAR
                  ? handleSaveNote
                  : () => null
              }
            >
              <Translate id="ALARMS.EDIT_MODAL.ADD_COMMENT_LABEL" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CommentComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
};

export default withLocalize(CommentComponent);
