/* eslint-disable react/jsx-curly-newline */
// This disable is intentional.  Prettier and jsx-curly-newline have contradicting lint rules.
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';

import { getSubscriptionState } from 'util/validation';
// Components
import Modal from 'react-modal';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  CameraLoginModal,
  DeleteDeviceForm,
  DeviceLocationWrapper,
  DevicesTable,
  FilterTranslated,
  IconSwitch,
  InputFilter,
} from 'components';
import {
  Button,
  EmptyPlaceholder,
  ListNav,
  MainContentWrapper,
  PageTitle,
} from 'lib';
import {
  DeviceBulkUpgradeConfirm,
  DeviceContainer,
  InternalClaimingContainer,
  ModalContainer,
  PageMessage,
} from 'containers';
import FilterableContainer from 'util/FilterableContainer';

// Actions
import * as AftClientActions from 'actions/aftClient';
import * as ClusterActions from 'actions/clusters';
import * as DeviceActions from 'actions/devices';
import { getLocations } from 'actions/locations';
import * as UserActions from 'actions/user';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';

import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { ai } from 'util/telemetryService';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import { PATH_SEGMENT_LIST } from 'constants/urlPaths';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Styles
import { contentContainer } from 'sharedStyles/global.css';
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';
import {
  btnAddServer,
  deviceContainer,
  deviceFilterWrapper,
  deviceList,
  deviceListItem,
  modalContainer,
  siteDeviceWrapper,
  viewSwitchWrapper,
} from './styles.css';

import * as DeviceConsts from './constants';

import NavigationWrapper from '../NavigationWrapper/NavigationWrapper';

class DevicesContainer extends FilterableContainer {
  constructor(props) {
    super(props);
    this.state = {
      canceledUpgradeSiteIds: [],
      displayTabularView: false,
      expandedBulkUpgradeConfirm: [],
      expandedSites: [],
      fetchingDevices: false,
      fetchingNextPage: false,
      selectedCameraId: null,
      selectedDeviceId: null,
      selectedLocationId: null,
    };
  }

  componentDidMount() {
    const {
      actions,
      isFetchingAllServersAndCameras,
      isFetchingClusters,
      isFetchingLocations,
      isFetchingSiteIdsWithUpgrades,
      isFetchingUsers,
      locations,
      orgId,
    } = this.props;
    if (isFetchingAllServersAndCameras === null)
      actions.getAllServersAndCameras(orgId);
    if (isFetchingClusters === null) actions.getClusters();
    if (isFetchingLocations === null || locations.length === 0)
      actions.getLocations();
    if (isFetchingSiteIdsWithUpgrades === null) actions.getSitesWithUpgrades();
    if (isFetchingUsers === null) actions.getUsers();
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      clusters,
      isFetchingAccServers,
      isFetchingClusters,
      orgId,
    } = this.props;

    if (prevProps.orgId !== orgId) {
      actions.getLocations();
    }

    if (
      (prevProps.clusters.length !== clusters.length && !isFetchingClusters) ||
      (prevProps.isFetchingClusters && !isFetchingClusters)
    ) {
      actions.refreshAllCameraLists(clusters);
      actions.getAllAccServerLists(clusters);
    } else if (
      clusters.length > 0 &&
      Object.keys(isFetchingAccServers).length === 0
    ) {
      actions.getAllAccServerLists(clusters);
    }
  }

  handleConfirmDeleteClick = () => {
    const { actions, selectedDevice } = this.props;
    this.toggleDeleteModal();
    actions.deleteDevice(selectedDevice.Id);
  };

  changeVisibleTab(bDisplay) {
    this.setState({
      displayTabularView: bDisplay,
    });
  }

  toggleModal = (modalType, show, cameraId, deviceId) => {
    const { actions } = this.props;
    switch (modalType) {
      case modalTypes.CAMERA_LOGIN:
      case modalTypes.FDS_CAMERA_RESET: {
        this.setState({
          selectedCameraId: cameraId,
          selectedDeviceId: deviceId,
        });
        break;
      }
      case modalTypes.ADD_DEVICE: {
        break;
      }
      // no default
    }
    if (show) {
      actions.showModal(modalType, {});
    } else {
      actions.hideModal();
    }
  };

  toggleDeleteModal = device => {
    const { actions, deleteModalIsOpen } = this.props;
    if (deleteModalIsOpen) {
      actions.hideModal(modalTypes.DELETE_DEVICE);
    } else {
      actions.showModal(modalTypes.DELETE_DEVICE, { device });
    }
  };

  connectConnectedCamera = values => {
    const { actions, cameras, devices } = this.props;
    const { selectedCameraId, selectedDeviceId } = this.state;
    let cameraId;
    let cameraName;
    let cameraUserName;
    let cameraPassword;
    const selectedCamera = cameras.find(
      cam => cam.Id === selectedCameraId && cam.DeviceId === selectedDeviceId,
    );
    const selectedDevice = devices.find(dev => dev.Id === selectedDeviceId);
    if (selectedCamera) {
      cameraId = selectedCamera.RemoteId;
      cameraName = selectedCamera.name || selectedCamera.Name;
      cameraUserName = values.cameraUserName.trim();
      cameraPassword = values.cameraPassword.trim();

      actions.connectCamera(
        selectedDevice.Id,
        cameraId,
        cameraUserName,
        cameraPassword,
        selectedCamera.Model,
        {
          cameraName,
          deviceName: selectedDevice.Name,
          physicalAddress:
            selectedCamera.PhysicalAddress || selectedCamera.physicalAddress,
          uri: selectedCamera.uri || selectedCamera.Uri,
        },
      );
      actions.hideModal();
    } else {
      actions.showModal(modalTypes.SHOW_ERROR, null, null, {
        messageStyle: messageStyleStrings.error,
        translateBody: 'CAMERA.NO_CAMERA_SELECTED',
      });
    }
  };

  getConnectedCamInitialValues = () => {
    const { cameras } = this.props;
    const { selectedCameraId, selectedDeviceId } = this.state;
    const selectedCamera = cameras.find(
      x => x.Id === selectedCameraId && x.DeviceId === selectedDeviceId,
    );
    if (selectedCamera) {
      return {
        IpAddress: selectedCamera.IpAddress,
        cameraPassword: '',
        cameraType: selectedCamera.Manufacturer,
        cameraUserName: ' ',
        streamURL: selectedCamera.Uri,
      };
    }
    return {};
  };

  hideCameraLoginModal = () => {
    this.toggleModal(modalTypes.CAMERA_LOGIN, false);
  };

  hideCameraFDSModal = () => {
    this.toggleModal(modalTypes.FDS_CAMERA_RESET, false);
  };

  toggleAddDeviceModal = () => {
    const { modalIsOpen } = this.props;
    this.toggleModal(modalTypes.ADD_DEVICE, !modalIsOpen);
  };

  updateDeviceData = locationId => {
    const { actions } = this.props;
    actions.getLocationDevices(locationId);
  };

  retrieveSiteDevicesAndCameras = location => {
    const locationId = location.Id;
    this.setState(
      prevState => ({
        expandedSites: [...prevState.expandedSites, locationId],
      }),
      () => {
        this.updateDeviceData(locationId);
      },
    );
  };

  clearSiteDevicesAndCameras = locationId => {
    const { actions } = this.props;
    this.setState(
      prevState => ({
        expandedSites: prevState.expandedSites.filter(es => es !== locationId),
      }),
      () => {
        if (this.state.expandedSites.length > 0) {
          this.updateDeviceData();
        } else {
          actions.clearLocationDevicesAndDeviceCameras();
        }
      },
    );
  };

  toggleBulkUpgradeConfirm = (key, show) => {
    this.setState(oldState => {
      const expandedBulkUpgradeConfirm = [
        ...oldState.expandedBulkUpgradeConfirm,
      ];
      const index = expandedBulkUpgradeConfirm.findIndex(x => x === key);
      const showInternal = show === undefined ? index < 0 : show;
      if (showInternal && index < 0) {
        expandedBulkUpgradeConfirm.push(key);
      } else if (!showInternal && index >= 0) {
        expandedBulkUpgradeConfirm.splice(index, 1);
      }
      return { expandedBulkUpgradeConfirm };
    });
  };

  hasUpgradeableDevices = siteId => {
    const { sitesWithUpgrades } = this.props;
    const { canceledUpgradeSiteIds } = this.state;
    if (
      canceledUpgradeSiteIds.findIndex(canceledSiteId => {
        return canceledSiteId === siteId;
      }) >= 0
    ) {
      return false;
    }
    return (
      sitesWithUpgrades.findIndex(site => {
        return site.SiteId === siteId;
      }) >= 0
    );
  };

  upgradeMessageCancel = siteId => {
    this.setState(oldState => {
      const canceledUpgradeSiteIds = [...oldState.canceledUpgradeSiteIds];
      canceledUpgradeSiteIds.push(siteId);
      return { canceledUpgradeSiteIds };
    });
  };

  handleFilter = (field, values) => {
    const { handleFilter } = this.props;
    handleFilter(field, values);
  };

  upgradeAllSites = upgradableDeviceIds => {
    const { actions, devices } = this.props;
    devices.forEach(device => {
      if (upgradableDeviceIds.includes(device.Id)) {
        actions.firmwareUpgrade(device.Id, {
          deviceName: device.Name,
        });
      }
    });
  };

  isAnyUpgradeInProgress = () => {
    const { devices, siteUpgradeStatus } = this.props;
    const locationIds = [...new Set(devices.map(device => device.LocationId))];
    return locationIds.some(id => siteUpgradeStatus[id] === true);
  };

  get navButtons() {
    const addButton = (
      <div className={btnAddServer}>
        <Button
          inputType="button"
          onClick={this.toggleAddDeviceModal}
          text={<Translate id="BUTTONS.ADD" />}
        />
      </div>
    );
    return addButton;
  }

  render() {
    const {
      accCameras,
      accServers,
      actions,
      canChangeCustomer,
      canDeleteSuspendedDevice,
      deleteModalIsOpen,
      devices,
      filteredItems,
      firmwareUpgradeStatus,
      hasActiveDeviceFilters,
      history,
      isCameraLoginModalOpen,
      isFetchingAftData,
      isFetchingLocationDeviceData,
      isFetchingLocations,
      locations,
      match: { path: subroute },
      modalIsOpen,
      orgId,
      selectedDevice,
      siteUpgradeStatus,
      sitesWithUpgrades,
      translate,
    } = this.props;
    const { displayTabularView } = this.state;
    return (
      <>
        <Switch>
          <Route
            path={`${subroute}${PATH_SEGMENT_LIST}`}
            render={() => (
              <>
                <ListNav
                  canChangeCustomer={canChangeCustomer}
                  customButtons={this.navButtons}
                  isTable={displayTabularView}
                  onTableClick={() => this.changeVisibleTab(true)}
                  onTileClick={() => this.changeVisibleTab(false)}
                  pageTitle={
                    <PageTitle
                      title={<Translate id="NAV_MENU.TITLES.DEVICES" />}
                    />
                  }
                >
                  <div className={deviceFilterWrapper}>
                    <InputFilter
                      key="searchInput"
                      field={DeviceConsts.fieldsToSearch}
                      id="searchInput"
                      onChange={this.handleFilter}
                      overrideValue
                      placeholder="LIST_VIEWS.FILTER"
                      value={this.getFilterValue('searchInput')}
                    />
                  </div>
                  {DeviceConsts.filters.map(filter => (
                    <div
                      key={`otherFilters-${filter.field}`}
                      className={deviceFilterWrapper}
                    >
                      <FilterTranslated
                        field={filter.field}
                        isSearchable={false}
                        label={filter.label}
                        onChange={this.handleFilter}
                        options={filter.options}
                        translateLabel
                        translateOptions={filter.translateOptions}
                        value={this.getFilterValue(filter.field)}
                      />
                    </div>
                  ))}
                </ListNav>
              </>
            )}
          />
          <Route
            render={() => {
              return (
                <ListNav
                  canChangeCustomer={canChangeCustomer}
                  customButtons={this.navButtons}
                  isTable={displayTabularView}
                  onTableClick={() => this.changeVisibleTab(true)}
                  onTileClick={() => this.changeVisibleTab(false)}
                  pageTitle={
                    <PageTitle
                      title={<Translate id="NAV_MENU.TITLES.DEVICES" />}
                    />
                  }
                />
              );
            }}
          />
        </Switch>
        <MainContentWrapper>
          <IconSwitch
            className={viewSwitchWrapper}
            iconButtons={DeviceConsts.VIEW_SWITCH_PROPS}
          />
          <PageMessage
            messageType={[
              messageTypes.CLAIM_ERROR,
              messageTypes.CLAIM_SUCCESS,
              messageTypes.DEVICE_ERROR,
              messageTypes.DEVICE_SUCCESS,
              messageTypes.CAMERA_ARM_SUCCESS,
              messageTypes.CAMERA_ARM_ERROR,
              messageTypes.CAMERA_DISARM_SUCCESS,
              messageTypes.CAMERA_DISARM_ERROR,
            ]}
          />
          <EmptyPlaceholder
            isFetching={isFetchingLocations || isFetchingAftData}
            items={locations}
            translateKey="FILTER.NO_RESULTS_FOUND"
          >
            <Switch>
              <Route
                exact
                path={`${subroute}/`}
                render={() => (
                  <>
                    {locations.map((location, index) => {
                      return (
                        <DeviceLocationWrapper
                          key={location.Id}
                          accCameras={accCameras}
                          accServers={accServers}
                          index={index}
                          location={location}
                          onUpgradeClick={() =>
                            this.toggleBulkUpgradeConfirm(index)
                          }
                          retrieveSiteDevicesAndCameras={
                            this.retrieveSiteDevicesAndCameras
                          }
                          siteIdsWithUpgradableDevices={sitesWithUpgrades}
                          subscriptionState={getSubscriptionState(
                            location.PackageSubscription,
                          )}
                          translate={translate}
                          upgradingFirmware={siteUpgradeStatus[location.Id]}
                        >
                          <div className={siteDeviceWrapper}>
                            <div className={deviceContainer}>
                              {this.hasUpgradeableDevices(location.Id) &&
                                siteUpgradeStatus[location.Id] === false && (
                                  <DeviceBulkUpgradeConfirm
                                    locationId={location.Id}
                                    onCancel={() =>
                                      this.upgradeMessageCancel(location.Id)
                                    }
                                    onUpgrade={upgradeableDeviceIds => {
                                      this.upgradeAllSites(
                                        upgradeableDeviceIds,
                                      );
                                      this.toggleBulkUpgradeConfirm(index);
                                    }}
                                  />
                                )}
                              <EmptyPlaceholder
                                isFetching={false}
                                items={devices.filter(
                                  device => device.SiteId === location.Id,
                                )}
                                string={
                                  isFetchingLocationDeviceData[location.Id] ===
                                    false && (
                                    <Translate
                                      id={
                                        hasActiveDeviceFilters
                                          ? 'FILTER.NO_RESULTS_FOUND'
                                          : 'DEVICES.MESSAGE.NO_DEVICE_ACCESS_FOR_SITE'
                                      }
                                    />
                                  )
                                } // TODO: Record isFetching by location for devices
                              >
                                <ul className={deviceList}>
                                  {devices
                                    .filter(
                                      device => device.SiteId === location.Id,
                                    )
                                    .map(device => {
                                      return (
                                        <li
                                          key={device.Id}
                                          className={deviceListItem}
                                        >
                                          <DeviceContainer
                                            canDeleteSuspendedDevice={
                                              canDeleteSuspendedDevice
                                            }
                                            device={device}
                                            handleDeleteClick={() =>
                                              this.toggleDeleteModal(device)
                                            }
                                            hasValidSubscription={
                                              getSubscriptionState(
                                                location.PackageSubscription,
                                              ).isValid
                                            }
                                            onAuthRequired={(
                                              cameraId,
                                              deviceId,
                                            ) => {
                                              this.toggleModal(
                                                modalTypes.CAMERA_LOGIN,
                                                true,
                                                cameraId,
                                                deviceId,
                                              );
                                            }}
                                            orgId={orgId}
                                          />
                                        </li>
                                      );
                                    })}
                                </ul>
                              </EmptyPlaceholder>
                            </div>
                          </div>
                        </DeviceLocationWrapper>
                      );
                    })}
                  </>
                )}
              />
              <Route
                path={`${subroute}${PATH_SEGMENT_LIST}`}
                render={() => (
                  <DevicesTable
                    actions={actions}
                    canDeleteSuspendedDevice={canDeleteSuspendedDevice}
                    deviceTableData={filteredItems}
                    firmwareUpgradeStatus={firmwareUpgradeStatus}
                    handleDeleteClick={this.toggleDeleteModal}
                    history={history}
                    locations={locations}
                    onAuthRequired={this.toggleModal}
                  />
                )}
              />
            </Switch>
          </EmptyPlaceholder>
        </MainContentWrapper>
        <Modal
          key={translate('BUTTONS.ADD')}
          className={`${modalContentContainer} ${modalContainer}`}
          contentLabel={translate('NAV_MENU.TITLES.DEVICES')}
          isOpen={modalIsOpen}
          onRequestClose={this.toggleAddDeviceModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          <ModalContainer
            handleCancel={this.toggleAddDeviceModal}
            modalTitle={translate('BUTTONS.ADD')}
          >
            <InternalClaimingContainer orgId={orgId} />
          </ModalContainer>
        </Modal>
        <Modal
          key={translate('DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_TITLE')}
          className={modalContentContainer}
          contentLabel={translate(
            'DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_TITLE',
          )}
          isOpen={deleteModalIsOpen}
          onRequestClose={this.toggleDeleteModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick
        >
          <ModalContainer
            handleCancel={this.toggleDeleteModal}
            modalTitle={translate(
              'DEVICES.DEVICE_REMOVE.DELETE_DEVICE_MODAL_TITLE',
            )}
            modalTitleIcon={{
              color: 'red',
              iconId: 'ic_error',
            }}
          >
            <DeleteDeviceForm
              device={selectedDevice}
              handleCancel={this.toggleDeleteModal}
              handleConfirm={this.handleConfirmDeleteClick}
            />
          </ModalContainer>
        </Modal>
        <Modal
          key={translate('DEVICES.DEVICE_LOGIN.CONNECT_CAMERA_MODAL_TITLE')}
          className={modalContentContainer}
          contentLabel={translate(
            'DEVICES.DEVICE_LOGIN.CONNECT_CAMERA_MODAL_TITLE',
          )}
          isOpen={isCameraLoginModalOpen}
          onRequestClose={this.hideCameraLoginModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          <ModalContainer
            handleCancel={this.hideCameraLoginModal}
            modalTitle={translate(
              'DEVICES.DEVICE_LOGIN.CONNECT_CAMERA_MODAL_TITLE',
            )}
          >
            <CameraLoginModal
              handleCancel={this.hideCameraLoginModal}
              initialValue={this.getConnectedCamInitialValues}
              onSubmit={this.connectConnectedCamera}
            />
          </ModalContainer>
        </Modal>
      </>
    );
  }
}

DevicesContainer.propTypes = {
  translate: PropTypes.func.isRequired,
};

DevicesContainer.defaultProps = {
  clusters: [],
  devices: [],
  isFetchingDeviceData: null,
  isFetchingLocationDeviceData: null,
  isFetchingLocations: null,
  isFetchingSiteIdsWithUpgrades: null,
  isFetchingUsers: null,
  locations: [],
};

function mapStateToProps(state, ownProps) {
  const orgId = ownProps.match.params.id;
  const { profile, users } = state.user;
  const FILTER_GROUP_NAME = 'DEVICES';
  const activeDeviceFilters = state.filters.filterStatus[FILTER_GROUP_NAME];
  const hasActiveDeviceFilters =
    activeDeviceFilters && Object.keys(activeDeviceFilters).length > 0;
  const { locations } = state.locations;

  const upgradingDeviceIds = Object.keys(state.devices.firmwareUpgrade).filter(
    deviceId => {
      const status = state.devices.firmwareUpgrade[deviceId];
      return status && status.status && status.status.length > 0;
    },
  );

  const upgradingDevices = state.devices.devices.filter(device =>
    upgradingDeviceIds.includes(device.Id),
  );

  const upgradingDevicesSiteIds = upgradingDevices.map(
    device => device.LocationId,
  );

  const siteUpgradeStatus = locations.reduce((hash, location) => {
    hash[location.Id] = upgradingDevicesSiteIds.includes(location.Id);
    return hash;
  }, {});

  let sitesWithUpgrades = state.devices.sitesWithUpgrades || [];

  locations.forEach(locationExpired => {
    if (!getSubscriptionState(locationExpired.PackageSubscription).isValid) {
      sitesWithUpgrades = sitesWithUpgrades.filter(location => {
        return !location.SiteId.includes(locationExpired.Id);
      });
    }
  });

  const nonDeletedDevices = state.devices.devices.filter(
    d => d.ClusterName !== 'Deleted',
  );

  // AFT-related data and state

  const accCameras = state.aft;
  const accServers = state.accServerLists;

  const isFetchingClusters = state.isFetching.getClusters;

  // const aftCameraFetch = state.isFetching.getAftCameraList;
  // const isFetchingAftCameras = Object.keys(aftCameraFetch).some(
  //   clusterId => aftCameraFetch[clusterId] === true,
  // );
  // Commenting out so we can watch the numbers go up while aft cameras load

  const aftServerFetch = state.isFetching.getAftServerList;
  const isFetchingAftServers = Object.keys(aftServerFetch).some(
    clusterId => aftServerFetch[clusterId] === true,
  );

  const isFetchingAftData = isFetchingClusters || isFetchingAftServers;

  return {
    accCameras,
    accServers,
    allServersAndCameras: state.devices.serversAndCameras,
    cameras: state.devices.cameras,
    canDeleteSuspendedDevice:
      state.user.permissions.CAN_DELETE_SUSPENDED_DEVICE,
    clusters: state.clusters.clusters,
    deleteModalIsOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.DELETE_DEVICE,
    devices: nonDeletedDevices,
    filterGroup: FILTER_GROUP_NAME,
    filterStatus: state.filters.filterStatus,
    filterValues: state.filters.devices,
    firmwareUpgradeStatus: state.devices.firmwareUpgrade,
    hasActiveDeviceFilters,
    isCameraLoginModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.CAMERA_LOGIN,
    isFetchingAccServers: state.isFetching.getAftServerList,
    isFetchingAftData,
    isFetchingAllServersAndCameras: state.isFetching.getAllServersAndCameras,
    isFetchingClusters,
    isFetchingLocationDeviceData: state.devices.isFetchingLocationDeviceData,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingSiteIdsWithUpgrades: state.isFetching.getUpgradeableDevices,
    isFetchingUsers: state.isFetching.getUsers,
    items: state.devices.serversAndCameras,
    locations,
    modalIsOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.ADD_DEVICE,
    orgId,
    selectedDevice: state.modal.modalProps.device,
    serversAndCameras: state.devices.serversAndCameras,
    siteUpgradeStatus,
    sitesWithUpgrades,
    upgradingDevicesSiteIds,
  };
}

function mapDispatchToProps(dispatch) {
  const deviceActions = DeviceActions;
  return {
    actions: bindActionCreators(
      {
        ...AftClientActions,
        ...ClusterActions,
        ...FilterActions,
        ...UserActions,
        ...deviceActions,
        getLocations,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  withAITracking(
    ai.reactPlugin,
    withLocalize(DevicesContainer),
    'DevicesContainer',
    contentContainer,
  ),
  mapStateToProps,
  mapDispatchToProps,
  null,
  { textInputFields: DeviceConsts.fieldsToSearch },
);
