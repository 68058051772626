import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

// actions
import * as DeviceActions from 'actions/devices';

// components
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Button, EmptyPlaceholder, GroupLayout } from 'lib';

// Utils
import { translatedConnect } from 'util/translatedConnect';
import { sortCameras, sortDevices } from 'util/sortDevicesAndCameras';

// styles
import { PATH_CAMERAS, PATH_SERVERS } from 'constants/urlPaths';
import {
  confirmHeader,
  confirmNotAvailableElement,
  confirmNotAvailableElementSeparator,
  confirmNotAvailablePlaceholder,
  confirmNotAvailableWrapper,
  confirmUpgradeDeviceTruncates,
  confirmUpgradeInfo,
  confirmUpgradeSummary,
  confirmUpgradeWrapper,
  flexRow,
} from './styles.css';

// constants
import { idBtnCancel, idBtnUpgrade, linkStyle } from './constants';

class DeviceBulkUpgradeConfirm extends React.Component {
  componentDidMount() {
    const { actions, isFetching, locationId } = this.props;
    if (locationId && isFetching === null) {
      actions.getLocationDevices(locationId);
    } else if (!locationId) {
      // In an 'upgrade all' situation, fetch all devices
      actions.getAllDevices();
    }
  }

  get deviceCount() {
    const { devices } = this.props;
    return devices.length;
  }

  get activeCameras() {
    const { cameras } = this.props;
    return cameras.filter(c => c.Active === true);
  }

  renderUnavailableDevice = (d, i) => (
    <span key={`d_${i}`} className={confirmNotAvailableElement}>
      <div>
        <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.SERVER" />
      </div>
      <div className={confirmUpgradeDeviceTruncates}>
        <Link style={linkStyle} to={`${PATH_SERVERS}/${d.Id}`}>
          {d.Name}
        </Link>
      </div>
      <div className={confirmNotAvailableElementSeparator} />
      <div>
        <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.REASON" />
      </div>
      <Translate>
        {({ translate }) => (
          <div
            className={confirmUpgradeDeviceTruncates}
            title={translate(d.cannotUpgradeReason)}
          >
            {translate(d.cannotUpgradeReason)}
          </div>
        )}
      </Translate>
    </span>
  );

  renderUnavailableCamera = (c, i) => {
    return (
      <span key={`c_${i}`} className={confirmNotAvailableElement}>
        <div>
          <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.CAMERA" />
        </div>
        <div className={confirmUpgradeDeviceTruncates}>
          {c.enableCameraDetailsLink ? (
            <Link style={linkStyle} to={`${PATH_CAMERAS}/${c.Id}`}>
              {c.Name}
            </Link>
          ) : (
            c.Name
          )}
        </div>
        <div className={confirmNotAvailableElementSeparator} />
        <div>
          <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.REASON" />
        </div>
        <Translate>
          {({ translate }) => (
            <div
              className={confirmUpgradeDeviceTruncates}
              title={translate(c.cannotUpgradeReason)}
            >
              {translate(c.cannotUpgradeReason)}
            </div>
          )}
        </Translate>
      </span>
    );
  };

  handleUpgrade = () => {
    const { devices, locationId, onUpgrade } = this.props;
    const upgradeableDeviceIds = [];
    sortDevices(devices).devicesCanUpgrade.forEach(device => {
      if (!locationId || device.SiteId === locationId) {
        upgradeableDeviceIds.push(device.Id);
      }
    });
    onUpgrade(upgradeableDeviceIds, locationId);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { actions, devices, isFetching } = this.props;
    if (isFetching === true && nextProps.isFetching === false) {
      // When a new crop of devices is fetched, get camera data for any new devices
      nextProps.devices.forEach(newDevice => {
        if (
          devices.length === 0 ||
          !devices.find(device => device.Id === newDevice.Id)
        ) {
          actions.getCameras({
            field: 'DeviceId',
            value: newDevice.Id,
          });
        }
      });
    }
  }

  render() {
    const { devices, isFetching, onCancel } = this.props;
    const { devicesCanUpgrade, devicesCannotUpgrade } = sortDevices(devices);
    const { camerasCannotUpgrade } = sortCameras(
      this.activeCameras,
      devicesCannotUpgrade,
    );
    return (
      <div className={confirmUpgradeWrapper}>
        <div className={flexRow}>
          <div className={confirmUpgradeSummary}>
            <div className={confirmHeader}>
              <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.UPGRADE_INFO_LABEL" />
            </div>
            <div>
              <Translate
                data={{
                  toUpgrade: devicesCanUpgrade.length,
                  total: this.deviceCount,
                }}
                id="DEVICES.DEVICE_UPGRADE_CONFIRM.SERVERS_TO_UPGRADE"
              />
            </div>
          </div>
          <div className={confirmUpgradeInfo}>
            <div className={confirmHeader}>
              <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.UPGRADE_SUMMARY_LABEL" />
            </div>
            <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.UPGRADE_SUMMARY_CONTENT" />
          </div>
        </div>
        <div className={flexRow}>
          <div className={confirmNotAvailableWrapper}>
            <div className={flexRow}>
              <div className={confirmHeader}>
                <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.UPGRADE_UNAVAILABLE_LABEL" />
              </div>
            </div>
            <EmptyPlaceholder
              className={confirmNotAvailablePlaceholder}
              isFetching={isFetching}
              items={devicesCannotUpgrade.concat(camerasCannotUpgrade)}
            >
              {devicesCannotUpgrade.map((d, i) =>
                this.renderUnavailableDevice(d, i),
              )}
              {camerasCannotUpgrade.map((c, i) =>
                this.renderUnavailableCamera(c, i),
              )}
            </EmptyPlaceholder>
          </div>
        </div>
        <div className={flexRow}>
          <GroupLayout horizontalPositioning="right" verticalSpacing="medium">
            <Button
              key={idBtnCancel}
              buttonType="primary"
              id={idBtnCancel}
              inputType="button"
              onClick={onCancel}
              text={<Translate id="BUTTONS.CANCEL" />}
            />
            <Button
              inputType="button"
              key={idBtnUpgrade}
              id={idBtnUpgrade}
              onClick={this.handleUpgrade}
            >
              <Translate id="DEVICES.DEVICE_UPGRADE_CONFIRM.UPGRADE_BUTTON_LABEL" />
            </Button>
          </GroupLayout>
        </div>
      </div>
    );
  }
}

DeviceBulkUpgradeConfirm.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.shape({})),
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  isFetching: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpgrade: PropTypes.func,
};

DeviceBulkUpgradeConfirm.defaultProps = {
  cameras: [],
  devices: [],
  isFetching: false,
  onCancel: () => {},
  onUpgrade: () => {},
};

function mapStateToProps(state, ownProps) {
  let devices;
  let cameras;
  let isFetching;
  if (ownProps.locationId) {
    isFetching =
      state.devices.isFetchingLocationDeviceData[ownProps.locationId];
    devices = state.devices.devices.filter(
      device => device.LocationId === ownProps.locationId,
    );
    cameras = state.devices.cameras.filter(
      camera => camera.LocationId === ownProps.locationId,
    );
  } else {
    isFetching = state.devices.isFetchingDeviceData;
    devices = state.devices.devices;
    cameras = state.devices.cameras;
  }
  return {
    cameras,
    devices,
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(DeviceActions, dispatch),
  };
}

export default translatedConnect(mapStateToProps, mapDispatchToProps)(
  DeviceBulkUpgradeConfirm,
);
