// Libs
import React, { Component } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import { withLocalize } from 'react-localize-redux';
import { getActiveLanguageCode } from 'util/languagesUtils';

// Components
import { Icon } from 'lib';

// Constants
import { convertCameraTimeTo, TIME_TYPES } from 'util/convertTimeTo';
import {
  DATE_FORMAT,
  DATE_FORMAT_EVOPLAYER,
  DATE_FORMAT_EVOPLAYER_WITHOUT_ZONE,
  STREAM_TYPES,
  TIME_FORMAT,
} from 'constants/app';
import { IC_CALENDAR } from 'constants/iconNames';

// Styles
import {
  tickerDateText,
  tickerTime,
  tickerTimeIcon,
  tickerTimeText,
  timepickerDay,
  videoTimePicker,
  videoTimeTicker,
} from './styles.css';

const dateUnit = 'seconds';

class OpenCloseTracker extends Component {
  // Component used to track when its parent mounts/dismounts
  componentDidMount() {
    this.props.onOpen();
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  render() {
    return '';
  }
}

OpenCloseTracker.defaultProps = {
  onClose: () => {},
  onOpen: () => {},
};

// Class
class DateTimeTicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      pickerTime: null,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (
        !this.props.isPlayerPaused ||
        this.props.streamType === STREAM_TYPES.live
      ) {
        // Live should continue counting when paused, recorded should not
        this.setState(state => ({ counter: state.counter + 1 }));
      }
    }, 1000);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.startDateTime !== this.props.startDateTime) {
      this.setState({ counter: 0 });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (!this.props.isPlayerPaused &&
        this.state.counter !== nextState.counter) ||
      nextProps.startDateTime !== this.props.startDateTime ||
      nextProps.activeLanguage.code !== this.props.activeLanguage.code
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleDateTimeChange = dateTime => {
    // Deals with the peculiarities of react-datepicker
    this.setState(currentState => {
      if (currentState.pickerTime === null) {
        return { pickerTime: dateTime };
      }
      const pickerTime = currentState.pickerTime.clone();
      if (
        dateTime.hours() === pickerTime.hours() &&
        dateTime.minutes() === pickerTime.minutes()
      ) {
        pickerTime
          .year(dateTime.year())
          .month(dateTime.month())
          .date(dateTime.date());
      } else {
        pickerTime
          .hour(dateTime.hour())
          .minute(dateTime.minute())
          .second(0);
      }
      return { pickerTime };
    });
  };

  handlePickerClose = () => {
    if (Moment.isMoment(this.state.pickerTime)) {
      // react-datepicker refuses to return a non-local moment object, even when utcOffset is defined
      // so pickerTime is provided to convertCameraTimeTo as a string with no timezone information
      // and the time returned is the selected time in the camera timezone.
      const dateTime = this.state.pickerTime.clone();
      const convertedTime = convertCameraTimeTo(
        dateTime.format(DATE_FORMAT_EVOPLAYER_WITHOUT_ZONE),
        TIME_TYPES.LOCAL,
        this.props.cameraDevice,
        this.props.cameraLocation,
        DATE_FORMAT_EVOPLAYER,
      );
      const convertedMoment = Moment(convertedTime, DATE_FORMAT_EVOPLAYER);
      this.props.onDateTimeChange(convertedMoment);
      this.setState({ pickerTime: null });
    }
  };

  getMoment() {
    return Moment(this.props.startDateTime, DATE_FORMAT_EVOPLAYER).add(
      this.state.counter,
      dateUnit,
    );
  }

  getDateTime = format =>
    `${convertCameraTimeTo(
      this.getMoment(),
      TIME_TYPES.LOCAL,
      this.props.cameraDevice,
      this.props.cameraLocation,
      format,
    )}`;

  render() {
    // See note in onDateTimeChange
    const currentMoment = Moment(
      this.getDateTime(DATE_FORMAT_EVOPLAYER_WITHOUT_ZONE),
      DATE_FORMAT_EVOPLAYER_WITHOUT_ZONE,
    );
    if (this.props.startDateTime) {
      return (
        <div className={videoTimeTicker}>
          <DatePicker
            calendarClassName={videoTimePicker}
            className={tickerTime}
            customInput={
              this.props.customInput ? (
                this.props.customInput
              ) : (
                <div className={tickerTime}>
                  <div className={tickerTimeText}>
                    {this.getDateTime(TIME_FORMAT)}
                  </div>
                  <Icon
                    iconClass={tickerTimeIcon}
                    id={IC_CALENDAR}
                    title="TIME_SELECT.PLACEHOLDERS.DATE"
                  />
                </div>
              )
            }
            dayClassName={() => timepickerDay}
            locale={getActiveLanguageCode() || 'en'}
            onChange={this.handleDateTimeChange}
            popperPlacement="top-start"
            selected={this.state.pickerTime || currentMoment}
            shouldCloseOnSelect={false}
            showTimeSelect
            timeCaption={this.props.translate('TIME_SELECT.PLACEHOLDERS.TIME')}
            timeFormat="HH:mm"
            timeIntervals={5}
          >
            <OpenCloseTracker onClose={this.handlePickerClose} />
          </DatePicker>
          {!this.props.customInput ? (
            <div className={tickerDateText}>
              {this.getDateTime(DATE_FORMAT)}
            </div>
          ) : null}
        </div>
      );
    }
    return <div />;
  }
}

DateTimeTicker.defaultProps = {
  customInput: false,
  onDateTimeChange: () => {},
};

export default withLocalize(DateTimeTicker);
