import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import renderIf from 'render-if';

import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';

import { Button, CopyableInput, EmptyPlaceholder, Icon } from 'lib';

import { convertToShortFormat } from 'util/convertTimeTo';
import { getSubscriptionState } from 'util/validation';


import { serviceFamilyMap, SITE_STATUS } from 'constants/app';
import { IC_REFRESH } from 'constants/iconNames';
import { SITE_TABLE_FIELDS } from './constants';

import {
  locationsCopyContainer,
  locationsCopyInput,
} from './LocationsTable.css';

const ActivationDateContainer = ({
  actions,
  isFetching,
  isFetchingActivationCode,
  preferredShortDateFormat,
  selected,
  site,
  translate,
}) => {
  useEffect(() => {
    // When the post to request a new code is done, refresh location data
    if (isFetchingActivationCode === false) {
      actions.getUpdatedLocation(site.Id);
    }
  }, [actions, isFetchingActivationCode, site.Id]);

  const getNewCode = useCallback(() => {
    const { ServiceFamily } = site.PackageSubscription;
    const serviceFamily = serviceFamilyMap[ServiceFamily];
    actions.getActivationCode(site.Id, serviceFamily);
  }, [actions, site.Id, site.PackageSubscription]);

  const siteHasValidSubscription = useMemo(
    () => getSubscriptionState(site.PackageSubscription).isValid,
    [site],
  );

  const renderSiteActivationDate = useMemo(() => {
    const { ACTIVATION_DATE } = SITE_TABLE_FIELDS;
    return (
      <span>
        {site[ACTIVATION_DATE]
          ? convertToShortFormat(
              site[ACTIVATION_DATE],
              preferredShortDateFormat,
            )
          : translate('GENERAL_TABLE.BODY.NOT_APPLICABLE_LABEL')}
      </span>
    );
  }, [preferredShortDateFormat, site, translate]);

  const renderCodeExpired = useMemo(() => {
    return (
      <span>
        <span>
          {renderIf(site.SiteStatus === SITE_STATUS.EXPIRED)(
            translate('ACCOUNTS.LOCATIONS.CODE_EXPIRED'),
          )}
          {renderIf(site.SiteStatus === SITE_STATUS.DISCONNECTED)(
            translate('ACCOUNTS.LOCATIONS.SITE_DISCONNECTED'),
          )}
        </span>
        {renderIf(site.UserHasAccess)(
          <Button
            icon
            inputType="button"
            onClick={e => {
              e.stopPropagation();
              getNewCode();
            }}
          >
            <Icon
              color={selected ? 'white' : 'black'}
              id={IC_REFRESH}
              title="ACCOUNTS.LOCATIONS.GET_NEW_CODE"
            />
          </Button>,
        )}
      </span>
    );
  }, [getNewCode, selected, site.SiteStatus, site.UserHasAccess, translate]);

  const renderActivationDateContent = () => {
    if (
      site.SiteStatus === SITE_STATUS.ACTIVE ||
      site.SiteStatus === SITE_STATUS.NONE ||
      !siteHasValidSubscription
    ) {
      return renderSiteActivationDate;
    }
    if (site.SiteStatus === SITE_STATUS.CLAIMING) {
      return (
        <CopyableInput
          inputClassName={locationsCopyContainer}
          inputValueClassName={locationsCopyInput}
          value={site.ClaimingCode}
        />
      );
    }
    return renderCodeExpired;
  };

  return (
    <EmptyPlaceholder isFetching={isFetching} items={[1]} size={20}>
      {renderActivationDateContent()}
    </EmptyPlaceholder>
  );
};

ActivationDateContainer.defaultProps = {
  isFetching: null,
  isFetchingActivationCode: null,
  preferredShortDateFormat: '',
  selected: false,
};

ActivationDateContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isFetching: PropTypes.bool,
  isFetchingActivationCode: PropTypes.bool,
  preferredShortDateFormat: PropTypes.string,
  selected: PropTypes.bool,
  site: PropTypes.shape({
    ClaimingCode: PropTypes.string,
    Id: PropTypes.string,
    PackageSubscription: PropTypes.object,
    SiteStatus: PropTypes.string,
    UserHasAccess: PropTypes.bool,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { site } = ownProps;
  let isFetchingLocationData = null;
  let isFetchingActivationCode = null;
  if (state.isFetching.getLocationsScoped) {
    isFetchingLocationData = state.isFetching.getLocationsScoped[site.Id];
  }
  if (state.isFetching.getActivationCode) {
    isFetchingActivationCode = state.isFetching.getActivationCode[site.Id];
  }
  return {
    isFetching: isFetchingActivationCode || isFetchingLocationData,
    isFetchingActivationCode,
    preferredShortDateFormat:
      state.user.profile.LocalizationPreference.PreferredShortDateFormat,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...LocationActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ActivationDateContainer));
