import React from 'react';
import PropTypes from 'prop-types';

import { withLocalize } from 'react-localize-redux';

import { badgeItems, appStoreBadge } from './badges.styles.css';

const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.avigilon.helios.android&hl=en_US';

const PlayStoreBadge = props => {
  const { activeLanguage, translate } = props;
  let imgSrc;
  try {
    imgSrc = require(`../../images/storesBadges/playStore/${
      activeLanguage.code
    }.png`);
  } catch (error) {
    imgSrc = require('../../images/storesBadges/playStore/en.png');
  }
  return (
    <div className={badgeItems}>
      <a href={ANDROID_STORE_URL} target="_blank">
        <img
          className={appStoreBadge}
          src={imgSrc}
          alt={translate('LOGIN.ANDROID_BADGE_TITLE')}
          title={translate('LOGIN.ANDROID_BADGE_TITLE')}
        />
      </a>
    </div>
  );
};

PlayStoreBadge.propTypes = {
  activeLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(PlayStoreBadge);
