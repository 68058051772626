import React from 'react';
import PropTypes from 'prop-types';

import UsaCity from './UsaCity';
import UsaStateDropdown from './UsaStateDropdown';
import UsaZipCode from './UsaZipCode';

import { horizontalWrap } from '../styles.css';

const UsaAddressFields = props => {
  const { disabled } = props;
  return (
    <div className={horizontalWrap}>
      <UsaCity disabled={disabled} />
      <UsaStateDropdown disabled={disabled} />
      <UsaZipCode disabled={disabled} />
    </div>
  );
};

UsaAddressFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default UsaAddressFields;
