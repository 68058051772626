import PropTypes from 'prop-types';
import React from 'react';
import { selectedListItem, unselectedListItem } from './Buttons.styles.css';

// button that has no visual appearance, just displays the child content
const ToggleButton = ({
  isActive,
  classNameSelected,
  classNameUnselected,
  onClick,
  onMouseDown,
  id,
  children,
}) => (
  <button
    type="button"
    className={isActive ? classNameSelected : classNameUnselected}
    onClick={() => (!isActive ? onClick() : null)}
    onMouseDown={() => (!isActive ? onMouseDown() : null)}
    id={id}
  >
    {children}
  </button>
);

ToggleButton.defaultProps = {
  onClick() {},
  onMouseDown() {},
  id: 'btnToggle',
  classNameSelected: selectedListItem,
  classNameUnselected: unselectedListItem,
  isActive: false,
  children: null,
};

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  classNameSelected: PropTypes.string,
  classNameUnselected: PropTypes.string,
  children: PropTypes.node,
};

export default ToggleButton;
