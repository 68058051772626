import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import { Translate } from 'react-localize-redux';

// Actions
import * as DeviceActions from 'actions/devices';
import * as ViewActions from 'actions/views';

// Constants
import { MEDIA_QUALITY, STREAM_TYPES } from 'constants/app';

const MEDIA_FORMAT = 'video';

// wrapper for obtaining mediaparams for evo player
// The ONLY WAY this app should EVER get media params
// is by wrapping a component in this and calling the
// getMedia function that is passed down
// to the consumer

function GetMediaParams(Consumer, mapStateToProps, actions) {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        startTime: this.props.startTime,
        newTimeline: false,
        mediaQuality: MEDIA_QUALITY.low,
        cameraChanged: false,
        mediaParams: null,
        videoSearch: false,
      };
      this.mediaId =
        props.mediaParamsId ||
        Math.floor((1 + Math.random()) * 0x10000).toString(16);
      // A randomly generated ID to distinguish the mediaParams for different EVO instances
      // Can be overridden by a passed-in ID
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const media = nextProps.mediaParamsAll[this.mediaId];
      const timeline = nextProps.timelines[nextProps.camera.RemoteId] || {};

      // If this is a clip, there is no need to start a stream
      if (nextProps.streamType === STREAM_TYPES.recorded) {
        return;
      }

      // If this is a recording, check if an error message must be set
      if (
        media &&
        this.state.startTime &&
        !this.state.videoSearch &&
        timeline.isFetching === false
      ) {
        const startTime = Moment(this.state.startTime);
        media.errorMessage = (
          <Translate id="VIDEO_PLAYER.RECORDED_VIDEO_ERROR" />
        );
        if (!timeline || !timeline.record) {
          // Don't error out if only timelines are broken
          media.errorMessage = null;
        } else {
          const recordingPeriod = this.getRecordingPeriod(timeline, startTime);
          if (recordingPeriod) {
            media.errorMessage = null;
            media.params = Object.assign({}, media.params || {}, {
              recordingEndTime: recordingPeriod.end,
            });
          }
        }
      }

      // Set media params to pass to wrapped component
      // If this is a recorded video & search is disabled, wait until timeline is returned
      if (
        media &&
        media.isFetching === false &&
        (!this.state.startTime ||
          this.state.videoSearch ||
          !timeline.record ||
          timeline.isFetching === false)
      ) {
        if (!media.errorMessage) {
          this.setState({
            mediaParams: media.params,
          });
        } else {
          // In the case of a null response or error, clear media params
          this.setState({
            mediaParams: media || {},
          });
        }
      }

      // Reset start time if necessary
      if (
        this.state.startTime &&
        this.props.camera.RemoteId !== nextProps.camera.RemoteId
      ) {
        this.setState({ startTime: null });
      }
    }

    getStreamType = () => {
      if (this.props.streamType === STREAM_TYPES.recorded) {
        return STREAM_TYPES.recorded;
      }
      if (this.state.startTime) {
        return STREAM_TYPES.playback;
      }
      return STREAM_TYPES.live;
    };

    getRecordingPeriod = (timeline, startTime) => {
      // Find recording block in timeline that this belongs to
      if (timeline && timeline.record) {
        return timeline.record.find(
          recording =>
            Moment(recording.start).isBefore(startTime) &&
            Moment(recording.end).isAfter(startTime),
        );
      }
    };

    requestTimeline = (startTime, increment) => {
      // Increment is the time in seconds to add or subtract from startTime
      increment = increment || 1;
      const utcMoment = Moment(startTime).utc();
      const scope = scope; // TODO: set based on increment
      const startRange = `${utcMoment
        .clone()
        .subtract(increment, 's')
        .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
      const endRange = `${utcMoment
        .clone()
        .add(increment, 's')
        .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
      this.props.actions.getRecordingTimeline(
        this.props.camera.DeviceId,
        this.props.camera.RemoteId,
        startRange,
        endRange,
        scope,
      );
    };

    startRecordedVideo = (startTime, search = false) => {
      const timeline = this.props.timelines[this.props.camera.RemoteId] || {};
      if (!this.getRecordingPeriod(timeline, startTime)) {
        // Check that recording exists for this time
        this.requestTimeline(startTime);
      }
      // Only do this if the action is available (on the views page)
      this.props.actions.setSyncedStartTime &&
        this.props.actions.setSyncedStartTime(startTime);
      const utcMoment = Moment(startTime).utc();
      const startTimeFormatted = `${utcMoment
        .clone()
        .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
      this.setState(
        {
          startTime: startTimeFormatted,
          videoSearch: search,
        },
        () => {
          this.requestMediaParams(this.props.camera);
        },
      );
    };

    returnToLiveVideo = () => {
      this.setState(
        {
          startTime: null,
          videoSearch: false,
        },
        () => {
          // Only if action is available
          this.props.actions.clearSyncedStartTime &&
            this.props.actions.clearSyncedStartTime();
          this.requestMediaParams(this.props.camera);
        },
      );
    };

    setQuality = mediaQuality => {
      this.setState(
        {
          mediaQuality,
        },
        () => {
          this.requestMediaParams(this.props.camera);
        },
      );
    };

    getMedia = (camera, startTime = null, search = false) => {
      const media = this.props.mediaParamsAll[this.mediaId];
      if (!media || media.isFetching === false) {
        // Don't fetch the same stream multiple times
        if (startTime) {
          this.startRecordedVideo(startTime, search);
        } else {
          this.returnToLiveVideo();
        }
      }
    };

    requestMediaParams = camera => {
      let startTime = null;
      if (this.state.startTime) {
        // gateway/media requires a nonstandard ISO-ish format that should be used nowhere else
        startTime = `${Moment(this.state.startTime)
          .utc()
          .format('YYYYMMDDTHHmmss.SSS')}Z`;
      }
      if (camera && camera.DeviceId && camera.RemoteId) {
        this.props.actions.getMediaParams(
          camera.DeviceId,
          camera.RemoteId,
          MEDIA_FORMAT,
          this.state.mediaQuality,
          startTime,
          this.mediaId,
          this.state.videoSearch,
          camera.TenantId,
        );
      }
    };

    render() {
      return (
        <div
          style={{
            width: '100%',
          }}
        >
          <Consumer
            {...this.props}
            {...this.state}
            mediaParamsId={this.mediaId}
            startRecordedVideo={this.startRecordedVideo}
            returnToLiveVideo={this.returnToLiveVideo}
            getMedia={this.getMedia}
            setQuality={this.setQuality}
            streamType={this.getStreamType()}
          />
        </div>
      );
    }
  }

  Wrapper.defaultProps = {
    camera: {},
    mediaParamsAll: {},
    timelines: {},
    startTime: null,
  };

  Wrapper.displayName = 'GetMediaParams';

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...DeviceActions,
          ...ViewActions,
          ...actions,
        },
        dispatch,
      ),
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
}

export default GetMediaParams;
