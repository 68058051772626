import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldInput } from 'components/FormElements';
import { FormGroup } from 'lib';

import * as LocationFormConsts from '../../constants';

import { formField } from '../../styles.css';

const NonUsaCity = props => {
  const { disabled } = props;
  return (
    <FormGroup
      id={LocationFormConsts.idCity}
      keyName="nonUsaCity"
      label={<Translate id="ADDRESS.CITY" />}
    >
      <Field
        className={formField}
        component={FieldInput}
        disabled={disabled}
        isNewVersion
        name={LocationFormConsts.nameCity}
        translate
        type="text"
        validate={validation.required}
      />
    </FormGroup>
  );
};

NonUsaCity.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default NonUsaCity;
