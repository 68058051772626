import PropTypes from 'prop-types';
import React from 'react';
import { formLabel, modalFormGroup } from './styles.css';

const FormGroup = ({ children, id, keyName, label }) => {
  return (
    <div key={keyName} className={modalFormGroup} id={id}>
      <label className={formLabel}>{label}</label>
      {children}
    </div>
  );
};
FormGroup.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  keyName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

FormGroup.defaultProps = {
  children: null,
  id: '',
  keyName: '',
};

export default FormGroup;
