
import React from 'react';
import { PropTypes } from 'prop-types';

import { Footer } from 'components';
//import { IconLogo } from 'icons';
import IconLogo from 'icons/IconAvigilonLogo';
import * as LoginConsts from './constants';
import { LOGO_FONT_COLOR } from 'constants/app';

import {
  loginBody,
  loginLogo,
  loginBox,
  loginHeader,
  loginHeaderRight,
  loginHeaderBackground,
  loginContainer,
  loginContentWrapper,
} from './styles.css';

const LoggedOutContentWrapper = ({
  boxClassName,
  children,
  footer,
  footerClassName,
  header,
  rightheader,
}) => {
  return (
    <div className={loginContainer}>
      <div className={loginBody}>
        <div className={loginContentWrapper}>
          <div>
            <IconLogo
              className={loginLogo}
              width="225px"
              height="24.26px"
              fontColor={LOGO_FONT_COLOR}
              id={LoginConsts.idHomeAppLogo}
            />
          </div>
          <div className={`${loginBox} ${boxClassName}`}>
            <div className={loginHeaderBackground}>
              <div className={loginHeader}>{header}</div>
              <div className={loginHeaderRight}>{rightheader}</div>
            </div>
            <div>{children}</div>
          </div>
          <Footer className={footerClassName} />
          {footer}
        </div>
      </div>
    </div>
  );
};

LoggedOutContentWrapper.defaultProps = {
  header: null,
  boxClassName: '',
  footerClassName: '',
  rightheader: null,
  footer: null,
  children: null,
};

LoggedOutContentWrapper.propTypes = {
  header: PropTypes.node,
  boxClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  rightheader: PropTypes.node,
  footer: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

export default LoggedOutContentWrapper;
