import {
  AFT as AFT_ACTION_TYPE,
  RESET_USER_CONTEXT,
} from 'constants/ActionTypes';

import { getMissingCameras } from 'util/siteViewHelper';

import initialState from 'store/initialState';

const getTreeItemsWithCamerasRemoved = (treeItems = [], cameraIds = []) => {
  if (treeItems.length === 0 || cameraIds.length === 0) return treeItems;
  const newTreeItems = [];
  treeItems.forEach(treeItem => {
    if (treeItem.type === 'CAMERA' && !cameraIds.includes(treeItem.id)) {
      newTreeItems.push(treeItem);
    } else if (treeItem.type === 'GROUP') {
      const children = getTreeItemsWithCamerasRemoved(
        treeItem.children,
        cameraIds,
      );
      newTreeItems.push({ ...treeItem, children });
    }
  });
  return newTreeItems;
};

const mapSiteViewToClusterId = (children, clusterId) =>
  children.map(child => {
    if (child.type !== 'GROUP') {
      return { ...child, clusterId };
    }

    if (child.children) {
      return {
        ...child,
        clusterId,
        children: mapSiteViewToClusterId(child.children, clusterId),
      };
    }

    // Preserve backward compatability with WEP versions
    // that have entries property instead of children.
    return {
      ...child,
      entries: mapSiteViewToClusterId(child.entries, clusterId),
    };
  });

export default function aftReducer(state, action) {
  const { siteView, clusterId, locationId } = action;

  switch (action.type) {
    case AFT_ACTION_TYPE.RECEIVE_SITEVIEW_TREE: {
      const clusters = state[locationId] || {};
      return Object.assign({}, state, {
        [locationId]: {
          ...clusters,
          [clusterId]: mapSiteViewToClusterId(siteView, clusterId),
        },
      });
    }
    case AFT_ACTION_TYPE.RECEIVE_CAMERA_LIST:
    case AFT_ACTION_TYPE.UPSERT_CAMERA_LIST: {
      const { cameras } = action;
      const clusters = state[locationId] || {};
      const currentSiteView = clusters[clusterId] || [];
      const camerasWithClusterId = cameras.map(camera => ({
        ...camera,
        clusterId,
      }));
      const missingCameras = getMissingCameras(
        currentSiteView,
        camerasWithClusterId,
      );
      if (missingCameras.length === 0) {
        return state;
      }
      return {
        ...state,
        [locationId]: {
          ...clusters,
          [clusterId]: [...currentSiteView, ...missingCameras],
        },
      };
    }
    case AFT_ACTION_TYPE.REMOVE_CAMERAS: {
      const { cameraIds } = action;
      if (!state[locationId] || !state[locationId][clusterId]) return state;
      const oldSiteView = state[locationId][clusterId];
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          [clusterId]: getTreeItemsWithCamerasRemoved(oldSiteView, cameraIds),
        },
      };
    }
    case RESET_USER_CONTEXT: {
      return initialState().aftSiteView;
    }
    default: {
      return state || initialState().aftSiteView;
    }
  }
}
