import * as types from 'constants/ActionTypes';

// Utils
import get from 'lodash.get';

const AudioReducers = {};

AudioReducers[types.IS_COMMITTING_SPEAKER_SETTINGS] = (state, action) => {
  return Object.assign({}, state, {
    isCommittingSpeakerSettings: action.value,
  });
};

AudioReducers[types.IS_FETCHING_SPEAKER_SETTINGS] = (state, action) => {
  return Object.assign({}, state, { isFetchingSpeakerSettings: action.value });
};

AudioReducers[types.RECEIVE_SPEAKER_SETTINGS] = (state, action) => {
  // Normalize volume to a percentage
  let { val: volume } = get(action, 'data.speaker.audioOutVolume', null);
  const { max, min } = get(action, 'data.speaker.audioOutVolume', null);
  if (max && max !== 100 && volume) {
    volume = (volume / 100) * (max - min);
  }
  // Normalize speaker data
  const data = {
    activated: get(action, 'data.speaker.audioOutEnable.val', null),
    activatedId: get(action, 'data.speaker.audioOutEnable.id', null),
    volume,
    volumeId: get(action, 'data.speaker.audioOutVolume.id', null),
  };
  const speakers = state.speakers || {};
  speakers[action.cameraId] = data;
  return { ...state, isFetchingSpeakerSettings: false, speakers };
};

export default AudioReducers;
