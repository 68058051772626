import React, { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { translatedConnect } from 'util/translatedConnect';

// Actions
import { setRuleCanvasVisibility } from 'actions/streamOverlays';

// Components
import { ToggleSwitch } from 'components';

// Styles
import {
  settingButtons,
  settingLabel,
  settingOptionContainer,
} from './savitarPlayer.styles.css';

const RuleOverlayButton = ({
  actions,
  areRulesVisible,
  cameraId,
  hostId,
  translate,
}) => {
  const handleClick = useCallback(() => {
    actions.setRuleCanvasVisibility(cameraId, hostId, !areRulesVisible);
  }, [actions, areRulesVisible, cameraId, hostId]);

  return (
    <div className={settingOptionContainer}>
      <div className={settingLabel}>
        {translate('VIDEO_PLAYER.OVERLAY_LABEL')}
      </div>
      <div className={settingButtons}>
        <ToggleSwitch
          id="toggleOverlaysButton"
          isActive={areRulesVisible}
          labelFalse={translate('VIDEO_PLAYER.TOGGLE_HIDE_LABEL')}
          labelTrue={translate('VIDEO_PLAYER.TOGGLE_SHOW_LABEL')}
          onToggle={handleClick}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  let areRulesVisible = false;
  const { cameraId, hostId } = ownProps;
  const showRuleCanvas =
    state.streamOverlays[cameraId] &&
    state.streamOverlays[cameraId].showRuleCanvas;
  if (showRuleCanvas) {
    areRulesVisible =
      showRuleCanvas.includes(hostId) ||
      showRuleCanvas.includes(toString(hostId));
  }
  return {
    areRulesVisible,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setRuleCanvasVisibility,
      },
      dispatch,
    ),
  };
}

RuleOverlayButton.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  areRulesVisible: PropTypes.bool,
  cameraId: PropTypes.string.isRequired,
  hostId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

RuleOverlayButton.defaultProps = {
  areRulesVisible: false,
};

export default translatedConnect(mapStateToProps, mapDispatchToProps)(
  RuleOverlayButton,
);
