import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function settingsReducer(state, action = {}) {
  switch (action.type) {
    case types.HIDE_IP_INPUT: {
      return { state, hideIpInput: true };
    }

    case types.SHOW_IP_INPUT: {
      return { state, hideIpInput: false };
    }

    case types.SELECT_CAMERA: {
      return { state, selectedCamera: action.camera };
    }
    // clear cache when user logs out
    case types.UNSET_USER: {
      return initialState().settings;
    }

    default: {
      return state || initialState().settings;
    }
  }
}
