import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

// Components
import { Button, Icon } from 'lib';
import { IC_COPY } from 'constants/iconNames';

// Styles
import {
  copyInputBtn,
  copyInputContainer,
  copyInputSuccessContainer,
  copyInputSuccessText,
  copyInputValue,
  copyInputValueCopied,
  messageHidden,
} from './styles.css';

// Constants
const idInput = 'copyableValueInput';
const animationLength = 200;
const displaySuccessMessageLength = 2500;

class CopyableInput extends Component {
  constructor(props) {
    super(props);
    this.valueRef = null;

    this.setValueRef = x => (this.valueRef = x);
    this.state = {
      copySuccess: false, // For determining whether to include button or success message content
      showButton: true, // For animating the success message
      showSuccessMessage: false, // For animating the button
    };
  }

  handleCopyBtnClick = e => {
    e.stopPropagation();
    // Select text
    this.valueRef.select();
    // Copy selected text
    document.designMode = 'on';
    if (document.execCommand('copy')) {
      this.setState(
        { showButton: false }, // Animate button leaving
        () => {
          setTimeout(() => {
            this.setState({
              copySuccess: true, // Remove button content, add message content
              showSuccessMessage: true, // Animate message appearing
            });
          }, animationLength);
          setTimeout(() => {
            this.setState({ showSuccessMessage: false }); // Animate message disappearing
          }, displaySuccessMessageLength);
          setTimeout(() => {
            this.setState({
              copySuccess: false, // Remove message content, add button content
              showButton: true, // Animate button appearing
            });
          }, displaySuccessMessageLength + animationLength);
        },
      );
    }
    document.designMode = 'off';
    // Unselect text
    if ('selectionStart' in this.valueRef) {
      this.valueRef.selectionEnd = this.valueRef.selectionStart;
    }
    this.valueRef.blur();
  };

  renderBtn = () => {
    const { btnComponent } = this.props;

    if (btnComponent) {
      return btnComponent;
    }
    return (
      <Button icon inputType="button" onClick={() => {}}>
        <Icon id={IC_COPY} title="COPYABLE_INPUT.BUTTON_TOOLTIP" />
      </Button>
    );
  };

  render() {
    const {
      animateButton,
      btnClassName,
      inputClassName,
      inputValueClassName,
      successClassName,
      successText,
      translate,
      value,
    } = this.props;

    const { copySuccess, showButton, showSuccessMessage } = this.state;

    return (
      <div className={`${copyInputContainer} ${inputClassName}`}>
        <div className={`${copyInputValue} ${inputValueClassName}`}>
          <input
            ref={this.setValueRef}
            className={showSuccessMessage ? copyInputValueCopied : ''}
            id={idInput}
            readOnly
            type="text"
            value={value}
          />
        </div>
        <div
          className={`${copyInputBtn} ${btnClassName} ${!showButton &&
            animateButton &&
            messageHidden}`}
          onClick={this.handleCopyBtnClick}
          onKeyPress={this.handleCopyBtnClick}
          role="button"
          tabIndex={0}
          title={translate('COPYABLE_INPUT.BUTTON_TOOLTIP')}
        >
          {copySuccess && animateButton ? null : this.renderBtn()}
        </div>
        <div
          className={`${copyInputSuccessContainer} ${successClassName} ${!showSuccessMessage &&
            messageHidden}`}
        >
          {copySuccess ? (
            <div className={copyInputSuccessText}>
              {successText || translate('COPYABLE_INPUT.SUCCESS')}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CopyableInput.propTypes = {
  animateButton: PropTypes.bool,
  btnClassName: PropTypes.string,
  btnComponent: PropTypes.func, // Enable to have button disappear on click
  inputClassName: PropTypes.string,
  inputValueClassName: PropTypes.string,
  successClassName: PropTypes.string,
  successText: PropTypes.string,
  translate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

CopyableInput.defaultProps = {
  animateButton: false,
  btnClassName: null,
  btnComponent: undefined,
  inputClassName: null,
  inputValueClassName: null,
  successClassName: null,
  successText: null,
};

export default withLocalize(CopyableInput);
