import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconMicrophone = props => {
  return (
    <svg
      id={props.id}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 15 23"
      fill={props.fill}
    >
      <defs>
        <rect
          id="path-1"
          x="5.41666667"
          y="14.6363636"
          width="2.16666667"
          height="7.31818182"
        />
        <rect
          id="path-2"
          x="5.41666667"
          y="17.7727273"
          width="2.16666667"
          height="7.31818182"
        />
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <title>{props.title}</title>
        <g id="Desktop" transform="translate(-537.000000, -493.000000)">
          <g id="Group" transform="translate(537.000000, 492.000000)">
            <g id="Page-1">
              <g id="Group-6" transform="translate(0.000000, 0.356000)">
                <g
                  id="icon-microphone"
                  transform="translate(1.000000, 0.644000)"
                >
                  <path
                    d="M0.63688151,7.53564453 C0.63688151,7.53564453 -0.379801432,15.4071822 6.49153646,15.4071822 C13.3628743,15.4071822 12.4361165,8.84858842 12.4361165,7.53564453"
                    id="Path-2"
                    stroke={props.fill}
                    strokeWidth="2"
                  />
                  <rect
                    id="Rectangle-12"
                    stroke={props.fill}
                    strokeWidth="2"
                    x="3.16666667"
                    y="1"
                    width="6.66666667"
                    height="11.5909091"
                    rx="3.33333333"
                  />
                  <g id="Rectangle-13">
                    <use fill="#999999" fillRule="evenodd" />
                    <rect
                      stroke={props.fill}
                      strokeWidth="1"
                      x="5.91666667"
                      y="15.1363636"
                      width="1.16666667"
                      height="6.31818182"
                    />
                  </g>
                  <g
                    id="Rectangle-13"
                    transform="translate(6.500000, 21.431818) rotate(90.000000) translate(-6.500000, -21.431818) "
                  >
                    <use fill={props.fill} fillRule="evenodd" />
                    <rect
                      stroke={props.fill}
                      strokeWidth="1"
                      x="5.91666667"
                      y="18.2727273"
                      width="1.16666667"
                      height="6.31818182"
                    />
                  </g>
                  <path
                    d="M9.80183919,4.60040838 L3.59723186,4.60040838"
                    id="Path-4"
                    stroke={props.fill}
                    strokeWidth="1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconMicrophone.defaultProps = {
  className: null,
  width: '13px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
  title: '',
};

export default IconMicrophone;
