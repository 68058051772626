import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';

const IconView = props => {
  return (
    <div
      title={props.title}
      style={{ width: props.width, height: props.height }}
    >
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 21 16"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs />
        <g
          id="dealer-dashboard"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="translate(-569.000000, -141.000000)"
        >
          <g
            id="Base"
            transform="translate(-1.000000, -108.000000)"
            fill={props.strokeFill}
          >
            <g id="Group-12">
              <g id="Nav-Bar" transform="translate(570.000000, 241.000000)">
                <path
                  d="M3.06997589,15.3521688 C3.6726334,14.5965907 4.72439459,13.3241519 5.7046074,12.3687426 C6.02767586,12.0533333 6.10472796,12.1208439 5.9280585,12.5329283 C5.684794,13.1016371 5.54665059,13.7238143 5.54665059,14.3800169 C5.54665059,17.0636962 7.76465038,19.2407764 10.5,19.2407764 C13.2353496,19.2407764 15.4533494,17.0636962 15.4533494,14.3800169 C15.4533494,13.6924895 15.3047489,13.0406076 15.0405703,12.4492152 C14.8578467,12.0392911 14.9310462,11.9734008 15.2563162,12.2871899 C16.2921166,13.286346 17.3444281,14.5868692 17.9404812,15.3559494 C18.2162176,15.7124051 18.2657511,16.3302616 18.0296415,16.7131814 C17.0708932,18.2659241 14.4379128,21.8385823 10.5,21.8385823 C6.56263759,21.8385823 3.92910682,18.2578228 2.9709089,16.7018397 C2.73534962,16.3189198 2.78873572,15.7043038 3.06997589,15.3521688 Z M10.4565206,15.9667848 C10.7823409,16.2794937 10.8324248,16.7380253 10.5104571,17.0545148 L9.94577524,17.6091814 C9.62380753,17.9256709 9.04536639,17.9899409 8.69753119,17.7004557 C8.07285879,17.1808945 7.55826082,16.5425148 7.16804697,15.8279831 C6.95285145,15.4337215 7.08273928,14.8369283 7.40525736,14.5204388 L7.99305483,13.9436287 C8.31557291,13.6271392 8.74596394,13.6914093 8.98317434,14.073789 C9.32220359,14.6203544 9.84725862,15.3813333 10.4565206,15.9667848 Z M0,16.00027 C0,17.5697553 3.9731366,24 10.5,24 C17.0274138,24 21,17.5697553 21,16.00027 C21,14.4307848 17.0785984,8 10.5,8 C3.92140161,8 0,14.4307848 0,16.00027 Z"
                  id="icon-view-21x16"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconView.defaultProps = {
  title: '',
  className: '',
  width: '21px',
  height: '16px',
  strokeFill: ACTIVE_COLOR_DEFAULT,
};

export default IconView;
