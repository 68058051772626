import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconViewDropdown3x1 = props => {
  const { className, fill, height, translate, width } = props;
  return (
    <svg
      className={className}
      height={height}
      version="1.1"
      viewBox="0 0 17 15"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{translate('VIEWS.LAYOUT_TOOLTIP')}</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1088.000000, -56.000000)">
          <g transform="translate(110.000000, 20.000000)">
            <g transform="translate(978.000000, 36.000000)">
              <path
                d="M12,6 L17,6 L17,10 L12,10 L12,6 Z M6,6 L11,6 L11,10 L6,10 L6,6 Z M0,6 L5,6 L5,10 L0,10 L0,6 Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconViewDropdown3x1.defaultProps = {
  className: null,
  fill: '#000000',
  height: '15px',
  width: '17px',
};

IconViewDropdown3x1.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconViewDropdown3x1);
