import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';
import { tabs } from './constants';

const SubscriberNavMenu = props => {
  const { currentOrg, path } = props;

  const pathPrefix = path.includes('dealer') || path.includes('provider')
    ? `${PATH_ACCOUNTS}${PATH_SEGMENT_DEALERS}/${currentOrg.DealerId}${PATH_SEGMENT_SUBSCRIBERS}/${currentOrg.Id}`
    : `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${currentOrg.Id}`;

  return (
    <NavTabs
      tabs={tabs.map(({ label, pathSegment }) => ({
        label,
        path: `${pathPrefix}${pathSegment}`,
      }))}
      translateLabels
    />
  );
};

SubscriberNavMenu.propTypes = {
  currentOrg: PropTypes.objectOf(PropTypes.any).isRequired,
  path: PropTypes.string.isRequired,
};

export default withLocalize(SubscriberNavMenu);
