import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// Com ponents
import { Translate } from 'react-localize-redux';

// Constants
import * as inputConsts from './constants';

// Styles
import {
  durationInputWrapper,
  durationInputFormGroup,
  durationInputLabel,
} from './styles.css';

class DurationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: props.isMilliseconds
        ? props.initialDuration / 1000
        : props.initialDuration,
      error: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialDuration !== this.props.initialDuration) {
      this.setState({
        duration: this.props.isMilliseconds
          ? this.props.initialDuration / 1000
          : this.props.initialDuration,
      });
    }
  }

  /*
   * debounce() waits for events to bubble. After a timeout
   * if no new events is received, it performs the action.
   */

  get outputDuration() {
    return this.props.isMilliseconds
      ? this.state.duration * 1000
      : this.state.duration;
  }

  handleChange = debounce(() => {
    const { duration } = this.state;
    const { maxDuration, minDuration } = this.props;
    if (maxDuration && maxDuration < duration) {
      this.setState(
        { duration: maxDuration, error: this.props.errorString },
        () => this.props.onChange(this.outputDuration),
      );
    } else if ((minDuration || minDuration === 0) && minDuration > duration) {
      this.setState(
        { duration: minDuration, error: this.props.errorString },
        () => this.props.onChange(this.outputDuration),
      );
    } else {
      this.props.onChange(this.outputDuration);
    }
  }, 300);

  handleChangeMinutes = e => {
    const newMinutes = parseInt(e.target.value) || 0;
    const currentSeconds = this.state.duration % 60;
    const duration = newMinutes * 60 + currentSeconds;
    this.setState({ duration }, this.handleChange);
  };

  handleChangeSeconds = e => {
    const newSeconds = parseInt(e.target.value) || 0;
    const currentSeconds = this.state.duration % 60;
    const duration = newSeconds + this.state.duration - currentSeconds;
    const { maxDuration, minDuration } = this.props;
    if (duration > maxDuration || duration < minDuration) {
      return;
    }
    this.setState({ duration }, this.handleChange);
  };

  render() {
    const minuteMax = this.props.maxDuration / 60;
    const minuteMin = Math.floor(this.props.minDuration / 60);
    let minuteVal = Math.floor(this.state.duration / 60);
    let secondVal = this.state.duration % 60;
    if (minuteVal > 0) {
      minuteVal = minuteVal.toString().replace(/^0+/, '');
    }
    if (secondVal > 0) {
      secondVal = secondVal.toString().replace(/^0+/, '');
    }
    return (
      <form noValidate>
        <div className={durationInputWrapper}>
          <div className={durationInputFormGroup}>
            <input
              id={`${inputConsts.idMinInput}_${this.props.id}`}
              type="number"
              value={minuteVal}
              onChange={this.handleChangeMinutes}
              onFocus={this.handleFocus}
              min={minuteMin}
              max={minuteMax} // If over an hour is nescessary in the future, an hours input will be added to the component
            />
            <div className={durationInputLabel}>
              <Translate id="UNITS.MINUTES_ABBREV" />
            </div>
          </div>
          <div className={durationInputFormGroup}>
            <input
              id={`${inputConsts.idSecInput}_${this.props.id}`}
              type="number"
              value={secondVal}
              onChange={this.handleChangeSeconds}
            />
            <div className={durationInputLabel}>
              <Translate id="UNITS.SECONDS_ABBREV" />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

DurationInput.defaultProps = {
  id: '',
  initialDuration: 0,
  maxDuration: undefined,
  minDuration: undefined,
  isMilliseconds: false,
  errorString: '',
};

DurationInput.propTypes = {
  id: PropTypes.string,
  initialDuration: PropTypes.number,
  maxDuration: PropTypes.number,
  minDuration: PropTypes.number,
  errorString: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMilliseconds: PropTypes.bool,
};

export default DurationInput;
