import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, ListView } from 'lib';
import OasisModule from './OasisModule';

function IsFetchingModule({
  failFetches,
  isFetching,
  resetIsFetchingStatus,
  setFailFetch,
  updateModuleState,
}) {
  function statusDisplay(fetchStatus) {
    switch (fetchStatus) {
      case true: {
        return 'Active';
      }
      case false: {
        return 'Complete';
      }
      default: {
        return 'Dormant';
      }
    }
  }
  const fetchData = Object.keys(isFetching).map(fetchType => {
    return {
      fetchStatus: statusDisplay(isFetching[fetchType]),
      fetchType,
    };
  });
  const HEADER_TRANSLATION_IDS = {
    fetchStatus: 'DEV_DO_NOT_TRANSLATE.FETCH_STATUS',
    fetchType: 'DEV_DO_NOT_TRANSLATE.FETCH_TYPE',
  };
  const HEADER_LABELS = {
    fetchStatus: 'Status',
    fetchType: 'Type',
  };
  const CELL_WIDTHS = {
    fetchStatus: 80,
  };
  const ROW_ACTIONS = [
    ({rowData}) => {
      return rowData.fetchStatus !== 'Dormant' ? (
        <Button
          compact
          inputType="button"
          onClick={() => {
            avoLog('Clicked reset button!');
            resetIsFetchingStatus(rowData.fetchType);
          }}
          size="small"
          text="Reset"
        />
      ) : (
        ''
      );
    },
  ];
  return (
    <OasisModule
      moduleId="oasis-fetches"
      theme="green"
      title="Fetches"
      updateModuleState={updateModuleState}
    >
      <ListView
        bodyHeight={125}
        canChangeView={false}
        cellWidths={CELL_WIDTHS}
        data={fetchData}
        defaultSortField="fetchType"
        fieldOrder={['fetchType', 'fetchStatus']}
        headerLabels={HEADER_LABELS}
        resizable
        resizableFrom="top"
        rowActions={ROW_ACTIONS}
        sortType="local"
        updateModuleState={updateModuleState}
      />
      <div style={{ margin: 8 }}>
        <input checked={failFetches} onChange={setFailFetch} type="checkbox" />{' '}
        <span>Fail API Calls</span>
      </div>
    </OasisModule>
  );
}

IsFetchingModule.defaultProps = {
  failFetches: false,
};

IsFetchingModule.propTypes = {
  failFetches: PropTypes.bool,
  isFetching: PropTypes.shape({}).isRequired,
  resetIsFetchingStatus: PropTypes.func.isRequired,
  setFailFetch: PropTypes.func.isRequired,
  updateModuleState: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
  };
}

export default connect(mapStateToProps)(IsFetchingModule);
