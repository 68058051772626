// Libs
import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

// Actions
import * as OrganizationActions from 'actions/organizations';
import { hideModal, showModal } from 'actions/modal';
import * as FilterActions from 'actions/filters';
import { getPendingSiteRequests } from 'actions/locations';

// Containers
import { ModalContainer, PageMessage } from 'containers';
import FilterableContainer from 'util/FilterableContainer';

// Components
import Modal from 'react-modal';
import { InputFilter, MonitoringConfigurationsTable } from 'components';
import {
  Button,
  EmptyPlaceholder,
  ListNav,
  MainContentWrapper,
  PageTitle,
} from 'lib';

// Constants
import * as PATH from 'constants/urlPaths';
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import { FILTER_GROUPS, INTEGRATION_TYPES } from 'constants/app';

// Utils
import * as permissions from 'util/permissions';

// Styles
import {
  modalContentContainer,
  modalOverlay,
  sectionTitle,
} from 'sharedStyles/styles.css';
import PendingRequestTable from './PendingRequestTable';
import MonitoringNavMenu from './MonitoringNavMenu';
import { idSearchInput, monitoringTextInputFields } from '../constants';
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';
import DeleteCentralStationForm from './DeleteCentralStationForm';
import MonitoringConfigAccordion from './MonitoringConfigAccordion/MonitoringConfigAccordion';
import {
  addCentralStationContainer,
  centralStationLabel,
  disableModal,
  filterGroup,
  filterWrapper,
  modalContainer,
  panelContent,
  prompt,
  subscriberWrapper,
  subTabPanel,
} from '../styles.css';

class MonitoringContainer extends FilterableContainer {
  constructor(props) {
    super(props);
    this.state = {
      currentSubScreen: 'sites', // configs or sites
      integrationConfigurationId: '',
      rowSelectedId: null,
      submittingForm: false,
    };
  }

  componentDidUpdate() {
    const {
      actions,
      isFetchingConfig,
      isFetchingIntegrationTypes,
      isFetchingPendingRequests,
      isFetchingSummary,
    } = this.props;

    if (isFetchingConfig === null) {
      actions.getIntegrationConfigs();
    }

    if (isFetchingSummary === null) {
      actions.getIntegrationSubscriberSummary();
    }

    if (isFetchingIntegrationTypes === null) {
      actions.getIntegrationTypes();
    }

    if (isFetchingPendingRequests === null) {
      actions.getPendingSiteRequests();
    }
  }

  getListNav = tab => {
    const { canChangeCustomer, profile } = this.props;
    let titleLabel = '';
    if (tab === 'SITE_CONFIGURATIONS') {
      titleLabel = 'INTEGRATIONS.MONITORING_SITE_CONFIGURATIONS_TITLE';
    } else if (tab === 'CENTRAL_STATIONS') {
      titleLabel = 'INTEGRATIONS.MONITORING_CENTRAL_STATIONS_TITLE';
    }
    return (
      <ListNav
        canChangeCustomer={canChangeCustomer}
        navigationTabs={<MonitoringNavMenu profile={profile} />}
        pageTitle={
          <PageTitle
            backPath={`${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_SUBSCRIBERS}`}
            showBackButton
            titleId={titleLabel}
          />
        }
      />
    );
  };

  toggleConfig = configEnabled => {
    const { actions, integrationConfig } = this.props;
    actions.editIntegrationConfiguration(
      integrationConfig.IntegrationConfigurationId,
      { Enabled: configEnabled },
    );
  };

  onDeleteClick = rowData => {
    const { actions } = this.props;
    this.setState({ rowSelectedId: rowData.IntegrationConfigurationId }, () =>
      actions.showModal(modalTypes.DELETE_CENTRAL_STATION),
    );
  };

  hideDelete = () => {
    const { actions } = this.props;
    this.setState({ rowSelectedId: null });
    actions.hideModal();
  };

  onToggleConnection = rowData => {
    const { actions } = this.props;
    if (!rowData.Enabled) {
      actions.enableIntegrationConfigs(
        rowData.IntegrationConfigurationId,
        true,
      );
    } else {
      actions.getIntegrationConfigs(); // Fetch the actual state of the connection toggle
      const onOkClick = () => {
        actions.enableIntegrationConfigs(
          rowData.IntegrationConfigurationId,
          false,
        );
        actions.hideModal();
      };

      const handleCancel = () => {
        actions.getIntegrationConfigs();
        actions.hideModal();
      };

      const message = (
        <div className={disableModal}>
          <p>
            <Translate
              data={{ stationName: <strong>{rowData.Name}</strong> }}
              id="INTEGRATIONS.DISABLE_CONNECTION.DISABLE_WARNING"
            />
          </p>
          <p>
            <Translate id="INTEGRATIONS.DISABLE_CONNECTION.DISABLE_MESSAGE" />
          </p>
        </div>
      );
      const title = (
        <Translate id="INTEGRATIONS.DISABLE_CONNECTION.DISABLE_TITLE" />
      );
      const modalProps = {
        handleCancel,
        message,
        onOkClick,
        textConfirm: 'BUTTONS.OK',
        title,
      };
      actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
    }
  };

  getSubscriberSites = integrationData => {
    const { actions } = this.props;
    actions.getIntegrationSubscriberSites(integrationData.SubscriberId);
  };

  updateSubscriberSites = subscriberId => {
    const {
      actions,
      integrationConfigs,
      subscriberSites: propSubscriberSites,
    } = this.props;
    const optionsById = {};
    integrationConfigs.forEach(config => {
      optionsById[config.IntegrationConfigurationId] = config.IntegrationType;
    });
    const subscriberSites = propSubscriberSites.filter(site => {
      const {
        ConfigurationString,
        IntegrationConfigurationId,
        TenantId,
      } = site;
      // Save sites in the current subscriber org that either have no config set, or do, and also have the configuration string set
      return (
        TenantId === subscriberId &&
        (!IntegrationConfigurationId ||
          optionsById[IntegrationConfigurationId] ===
            INTEGRATION_TYPES.WEBHOOKS ||
          (IntegrationConfigurationId && ConfigurationString))
      );
    });
    actions.updateIntegrationSubscriberSites(subscriberId, subscriberSites);
  };

  toggleModal = () => {
    const { actions, modalIsOpen } = this.props;
    return modalIsOpen
      ? actions.hideModal()
      : actions.showModal(modalTypes.ADD_CENTRAL_STATION);
  };

  handleCentralStationBtnClick = () => {
    this.props.history.push(
      `${PATH.PATH_ACCOUNTS}${PATH.PATH_SEGMENT_MONITORING}${PATH.PATH_SEGMENT_MONITORING_CENTERS}${PATH.PATH_SEGMENT_NEW}`,
    );
  };

  handleDeleteConfirm = configId => {
    const { actions } = this.props;
    this.hideDelete();
    actions.deleteIntegrationConfig(configId);
  };

  render() {
    const {
      deleteModalIsOpen,
      filteredItems: items,
      handleFilter,
      integrationConfigs,
      integrationTypes,
      isFetchingPendingRequests,
      isFetchingSummary,
      pendingRequestData,
      profile,
      subscriberSites,
      translate,
    } = this.props;
    const { rowSelectedId } = this.state;

    return (
      <>
        <PageMessage messageType={messageTypes.INTEGRATION_CONFIG} />
        <Switch>
          <Route
            exact
            path={
              PATH.PATH_ACCOUNTS +
              PATH.PATH_SEGMENT_MONITORING +
              PATH.PATH_SEGMENT_MONITORING_CENTERS
            }
            render={() => {
              return (
                <>
                  {this.getListNav('CENTRAL_STATIONS')}
                  <MainContentWrapper>
                    <div className={subTabPanel}>
                      <div className={addCentralStationContainer}>
                        <div className={prompt}>
                          <Translate id="INTEGRATIONS.MONITORING_CONNECTION_INFORMATION_PROMPT" />
                        </div>
                        <Button
                          inputType="button"
                          onClick={this.handleCentralStationBtnClick}
                          text={<Translate id="BUTTONS.CENTRAL_STATION" />}
                        >
                          <div className={centralStationLabel}>
                            <Translate id="BUTTONS.ADD_CENTRAL_STATION" />
                          </div>
                        </Button>
                      </div>
                      <div className={panelContent}>
                        <MonitoringConfigurationsTable
                          handleDelete={this.onDeleteClick}
                          handleToggleConnection={this.onToggleConnection}
                          integrationConfigs={integrationConfigs}
                          integrationTypes={integrationTypes}
                          isFetchingSummary={isFetchingSummary}
                        />
                        <Modal
                          className={`${modalContentContainer} ${modalContainer}`}
                          isOpen={deleteModalIsOpen}
                          onRequestClose={this.hideDelete}
                          overlayClassName={modalOverlay}
                          shouldCloseOnOverlayClick={false}
                        >
                          <ModalContainer
                            handleCancel={this.hideDelete}
                            modalTitle={`${translate(
                              'INTEGRATIONS.DELETE_INTEGRATION.DELETE_MODAL_HEADER',
                            )}`}
                          >
                            <DeleteCentralStationForm
                              configId={rowSelectedId}
                              handleCancel={this.hideDelete}
                              onDeleteConfirm={
                                configId => this.handleDeleteConfirm(configId)
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            />
                          </ModalContainer>
                        </Modal>
                      </div>
                    </div>
                  </MainContentWrapper>
                </>
              );
            }}
          />
          <Route
            exact
            path={
              PATH.PATH_ACCOUNTS +
              PATH.PATH_SEGMENT_MONITORING +
              PATH.PATH_SEGMENT_SITE_CONFIGURATION
            }
            render={() => {
              return (
                <>
                  {this.getListNav('SITE_CONFIGURATIONS')}
                  <MainContentWrapper>
                    <div className={subTabPanel}>
                      <div className={subscriberWrapper}>
                        <PendingRequestTable
                          isFetchingPendingRequests={isFetchingPendingRequests}
                          pendingRequestData={pendingRequestData}
                          profile={profile}
                        />
                        <div className={sectionTitle}>
                          <Translate id="INTEGRATIONS.MONITORING_SUBSCRIBER_SITES" />
                        </div>
                        <div className={prompt}>
                          <Translate id="INTEGRATIONS.MONITORING_SITE_INFORMATION_PROMPT" />
                        </div>
                        <div className={filterGroup}>
                          <div className={filterWrapper}>
                            <InputFilter
                              field={monitoringTextInputFields}
                              id={idSearchInput}
                              onChange={handleFilter}
                              overrideValue
                              placeholder="BUTTONS.SEARCH"
                              value={this.getFilterValue('SubscriberName')}
                            />
                          </div>
                        </div>
                        <EmptyPlaceholder
                          isFetching={isFetchingSummary && items.length === 0}
                          items={items}
                          string={
                            <Translate id="INTEGRATIONS.SUBSCRIBER_STATS.EMPTY_INTEGRATION_SUBSCRIBER_SEARCH" />
                          }
                        >
                          <MonitoringConfigAccordion
                            getSubscriberSites={this.getSubscriberSites}
                            isFetchingSummary={isFetchingSummary}
                            isPendingRequestExists={
                              pendingRequestData.length > 0
                            }
                            subscriberSites={subscriberSites}
                            subscriberSummary={items}
                            updateSubscriberSites={this.updateSubscriberSites}
                          />
                        </EmptyPlaceholder>
                      </div>
                    </div>
                  </MainContentWrapper>
                </>
              );
            }}
          />
          <Route
            exact
            path={PATH.PATH_ACCOUNTS + PATH.PATH_SEGMENT_MONITORING}
            render={() => (
              <Redirect
                to={
                  PATH.PATH_ACCOUNTS +
                  PATH.PATH_SEGMENT_MONITORING +
                  PATH.PATH_SEGMENT_SITE_CONFIGURATION
                }
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

MonitoringContainer.propTypes = {
  onFormSubmit: PropTypes.func,
  pendingRequestData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subscriberSites: PropTypes.arrayOf(PropTypes.shape({})),
};

MonitoringContainer.defaultProps = {
  onFormSubmit: () => {},
  subscriberSites: [],
};

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  const { integrationConfigs } = state.organizations;
  return {
    deleteModalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.DELETE_CENTRAL_STATION,
    filterGroup: FILTER_GROUPS.INTEGRATION_SUBSCRIBERS,
    filterStatus: state.filters.filterStatus,
    integrationConfig: (integrationConfigs && integrationConfigs[0]) || {},
    integrationConfigs,
    integrationSubscriberSummary:
      state.organizations.integrationSubscriberSummary,
    integrationTypes: state.organizations.integrationTypes,
    isFetchingConfig: state.organizations.isFetchingIntegrationConfigs,
    isFetchingIntegrationTypes: state.isFetching.getIntegrationTypes,
    isFetchingPendingRequests: state.locations.isFetchingPendingSiteRequests,
    isFetchingSummary:
      state.organizations.isFetchingIntegrationSubscriberSummary,
    items: state.organizations.integrationSubscriberSummary,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_CENTRAL_STATION,
    orgId,
    pendingRequestData: state.locations.pendingSiteRequests,
    profile: state.user.profile,
    subscriberSites: state.organizations.integrationSubscriberSites,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...OrganizationActions,
        ...FilterActions,
        getPendingSiteRequests,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  withLocalize(MonitoringContainer),
  mapStateToProps,
  mapDispatchToProps,
  { monitoringTextInputFields },
);
