import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Components
import { BusyIndicator, LoggedOutContentWrapper } from 'components';

// Styles
import { PATH_LOGIN } from 'constants/urlPaths';
import {
  regionDefaultFocus,
  regionSelectToolTip,
  widerFooter,
  widerLoginBox,
} from './styles.css';

// Constants
import * as loginConsts from './constants';

const SelectRegionContainer = props => {
  const {
    isFetchingRegionalDeployments,
    regionalDeployments,
    translate,
  } = props;

  const handleClick = useCallback(destination => {
    window.open(destination, '_self');
  }, []);

  const getRegionElement = useCallback(
    region => {
      const regionUrl = `${region.URL}${PATH_LOGIN}`;
      const regionNameKey = region.Name.toUpperCase().replace(' ', '_');
      const regionNameTranslation = `REGION_SELECT.REGIONS.${regionNameKey}`;

      return (
        <msi-item-native
          key={loginConsts.idCanLinkBtn}
          class={`${region.Name === 'Default' ? regionDefaultFocus : ''}`}
          id={loginConsts.idCanLinkBtn}
          onClick={() => handleClick(regionUrl)}
        >
          <Translate id={regionNameTranslation} />
        </msi-item-native>
      );
    },
    [handleClick],
  );

  const regionElements = useMemo(() => {
    const defaultSort = (a, b) => {
      // eslint-disable-next-line no-param-reassign
      a = a.Name;
      // eslint-disable-next-line no-param-reassign
      b = b.Name;

      if (a === 'Default') return -1;
      if (b === 'Default') return 1;

      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    };
    return regionalDeployments
      .sort(defaultSort)
      .map(region => getRegionElement(region));
  }, [getRegionElement, regionalDeployments]);

  if (isFetchingRegionalDeployments) return <BusyIndicator />;

  return (
    <LoggedOutContentWrapper
      boxClassName={widerLoginBox}
      footerClassName={widerFooter}
      header={translate('REGION_SELECT.HEADER')}
    >
      <msi-treeview-native>{regionElements}</msi-treeview-native>
      <div className={regionSelectToolTip}>
        * {translate('REGION_SELECT.TOOLTIP')}
      </div>
    </LoggedOutContentWrapper>
  );
};

SelectRegionContainer.propTypes = {
  isFetchingRegionalDeployments: PropTypes.bool,
  regionalDeployments: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func.isRequired,
};

SelectRegionContainer.defaultProps = {
  isFetchingRegionalDeployments: null,
  regionalDeployments: [],
};

function mapStateToProps(state) {
  return {
    isFetchingRegionalDeployments: state.isFetching.getRegionalDeployments,
    regionalDeployments: state.utilities.regionalDeployments,
  };
}

export default connect(mapStateToProps)(
  withLocalize(
    withAITracking(
      ai.reactPlugin,
      SelectRegionContainer,
      'SelectRegionContainer',
    ),
  ),
);
