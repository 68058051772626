
import React from 'react';
import { Message } from 'components';
import { PropTypes } from 'prop-types';
import * as cookieUtils from 'util/cookies';
import * as cookieNames from 'constants/cookieNames';

const MessageContainer = ({ data }) => {
  const renderNotices = notices => {
    return notices.map(notice => {
      const noticeCookieName = `${cookieNames.DISMISS_BANNER_COOKIE}_${
        notice.Id
      }`;
      const showingMessage = cookieUtils.getCookie(noticeCookieName) ? null : (
        <Message
          severity={notice.NoticeTypeData.Severity}
          message={notice.NoticeTypeData.Message}
          start={notice.EffectiveDate}
          end={notice.ExpirationDate}
          key={notice.Id}
          id={notice.Id}
        />
      );

      return showingMessage;
    });
  };

  return <div>{renderNotices(data)}</div>;
};

MessageContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default MessageContainer;
