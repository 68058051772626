import React, { useState } from 'react';
import * as styles from './styles.css';
const SourceListItem = ({ children, selected, onClick }) => {
  return (
    <div
      className={`${styles.sourceListItem} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default SourceListItem;
