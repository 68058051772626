// Utils
import jsonParseRobust from './jsonParseRobust';

export const cloneDeep = obj => {
  return jsonParseRobust(JSON.stringify(obj));
};

export const setCamerasLinkFlag = (cameras, linkedCameras) => {
  return cameras.map(camera => {
    const link = linkedCameras.find(
      linkedCamera => linkedCamera.RemoteId === camera.RemoteId,
    );
    if (link) {
      camera.isLinked = true;
    }
    return camera;
  });
};

export const listCamerasFromSameServer = (selectedCamera, cameras) => {
  // only active cameras from the same server
  return cameras.filter(
    camera => camera.Active && camera.ServerId === selectedCamera.ServerId,
  );
};

export const getDigitalOutLinksFromCamera = (cameras, entities, camera) => {
  const entity =
    entities &&
    entities.find(
      e => e.serial === camera.Serial && e.type === 'DIGITAL_OUTPUT',
    );
  if (!entity) {
    return [];
  }
  return cameras.filter(cam => {
    const links = (cam && cam.Links && jsonParseRobust(cam.Links)) || [];
    const filteredLinks = links.filter(
      link => link.type === 'DIGITAL_OUTPUT' && link.source === entity.id,
    );
    return filteredLinks.length > 0 ? cam : null;
  });
};
