import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';

// Actions
import * as DeviceActions from 'actions/devices';
import * as UserActions from 'actions/user';

// Utils

// Components
import Logo from 'containers/Login/Logo';
import { BusyIndicator, Footer } from 'components';
import ClaimDeviceForm from 'components/ClaimDeviceForm/ClaimDeviceForm';

// Constants
import { PATH_REGISTER, PATH_ACTIVATION_SUCCESS } from 'constants/urlPaths';
import { LOGO_FONT_COLOR } from 'constants/app';
import {
  appContainer,
  wizardScreen,
  wizardContainer,
  wizardForm,
  wizardFooter,
  wizardFormBody,
  wizardLogo,
} from 'sharedStyles/styles.css';

// Styles
import getPathName, { getFullPath } from 'util/getPathName';

import { wizardFormOverride } from './styles.css';

class ClaimDeviceFormContainer extends Component {
  constructor(props) {
    super(props);
    // use flag so that request isnt submitted every time props change
    this.state = {
      hasSubmittedClaimKeyRequest: false,
      deviceClaimedError: false,
    };
  }

  // for the unauth user, redirect to login, then re-mount this component with isAuth = T
  UNSAFE_componentWillMount() {
    if (this.props.isAuthenticated && !this.state.hasSubmittedClaimKeyRequest) {
      this.props.actions.getAllDevices();
    } else if (!this.props.isAuthenticated && !this.props.isBusy) {
      this.props.actions.getUserProfile();
    }
  }

  // for the auth user, isAuth = T when App root fetches getProfile
  UNSAFE_componentWillReceiveProps(nextProps) {
    const deviceAlreadyClaimed =
      nextProps.devices &&
      nextProps.devices.find(
        device => device.Id === this.props.match.params.id,
      );
    if (!nextProps.isAuthenticated) {
      this.props.actions.redirectAfterLogin(this.props.fullPath);
      // need /register rather than /login so that LoginContainer can add link for user that does not have account
      this.props.history.push(PATH_REGISTER);
    } else if (nextProps.isFetchingDeviceData === null) {
      this.props.actions.getAllDevices();
    } else if (nextProps.claimedDeviceId) {
      // If the claimedDeviceId is in the store, redirect to the activation page.
      this.props.history.push(
        `${PATH_ACTIVATION_SUCCESS}/${nextProps.claimedDeviceId}`,
      );
    } else if (deviceAlreadyClaimed) {
      const deviceClaimedError = {
        message: <Translate id="DEVICES.DEVICE_CLAIM.ERROR_ALREADY_CLAIMED" />,
      };
      this.setState({ hasSubmittedClaimKeyRequest: true, deviceClaimedError });
    } else if (
      nextProps.isAuthenticated &&
      !this.state.hasSubmittedClaimKeyRequest &&
      !deviceAlreadyClaimed
    ) {
      this.props.actions.createClaimKey(this.props.match.params.id);
      this.setState({ hasSubmittedClaimKeyRequest: true });
    }
  }

  onSubmit(formData) {
    if (!formData.ParentId) {
      return;
    }
    const data = {
      OrganizationId: this.props.organization.Id, // customer
      ParentId: formData.ParentId, // location
      ServerId: this.props.match.params.id, // device
    };
    this.props.actions.claimDeviceById(data);
  }

  logout() {
    this.props.actions.logout();
  }

  changeOrganization = userId => {
    this.props.actions.setOrganizationContext(userId, this.props.connectionId);
  };

  render() {
    let content;
    const error = this.props.error || this.state.deviceClaimedError;
    if (error) {
      content = (
        <div className={wizardFormBody}>
          {error.status && (
            <Translate
              id="DEVICES.DEVICE_CLAIM.RESPONSE_CODE_LABEL"
              data={{
                errorCode: error.status,
              }}
            />
          )}
          {error.message && (
            <Translate
              id="DEVICES.DEVICE_CLAIM.ERROR_LABEL"
              data={{
                errorMessage: error.message,
              }}
            />
          )}
          <Translate id="DEVICES.DEVICE_CLAIM.KEY_ERROR_TEXT" />
        </div>
      );
    } else if (this.props.claimingKey) {
      content = (
        <ClaimDeviceForm
          handleSubmit={this.onSubmit.bind(this)}
          locations={this.props.locations}
          subscriber={this.props.organization}
          isBusy={this.props.isBusy}
          isDone={this.props.claimedDeviceId}
        />
      );
    } else {
      // BusyIndicator should display instead of form while claimKey is being generated/retrieved
      content = <BusyIndicator />;
    }
    return (
      <div className={appContainer}>
        <div className={wizardScreen}>
          <div className={wizardContainer}>
            <div className={wizardLogo}>
              <Logo fontColor={LOGO_FONT_COLOR} />
            </div>
            <div className={`${wizardFormOverride} ${wizardForm}`}>
              {content}
            </div>
            <Footer className={wizardFooter} />
          </div>
        </div>
      </div>
    );
  }
}

ClaimDeviceFormContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  parentId: PropTypes.string,
};

ClaimDeviceFormContainer.contextTypes = {
  router: PropTypes.object.isRequired,
};

ClaimDeviceFormContainer.defaultProps = {
  claimingKey: undefined,
  organizationId: undefined,
};

function mapStateToProps(state, ownProps) {
  const serverId = ownProps.match.params.id;
  const obj = state.devices.claimKeys[serverId.toLowerCase()];
  let claimingKey;
  if (obj) {
    claimingKey = obj.claimKey;
  }
  let role;
  let currentOrganization;
  let isAuthenticated;
  if (state.user.profile) {
    currentOrganization = state.user.profile.TenantName;
    role = state.user.profile.Role;
    isAuthenticated = !!state.user.profile.TenantId;
  }
  return {
    organization: state.user.selectedOrganization,
    userName: state.user.userName,
    connectionId: state.notifications.connectionId,
    role,
    isAuthenticated,
    pathname: getPathName(ownProps.location.pathname),
    fullPath: getFullPath(ownProps.location.pathname),
    claimingKey,
    error: state.devices.claimError,
    claimedDeviceId: state.devices.claimedDeviceId,
    // overlay on top of form to disable selects while fetching location data
    isBusy: state.isFetching.getUserProfile || state.isFetching.getLocations,
    currentOrganization,
    organizations: state.user.organizations,
    locations: state.locations.locations,
    canChangeOrganization: !state.devices.isFetchingSnapshots,
    devices: state.devices.devices,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, DeviceActions, UserActions),
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimDeviceFormContainer);
