// libs
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Components
import { FieldInput } from 'components';

import { Button, FieldSelect, GroupLayout } from 'lib';

// Constants
import { SERVICE_FAMILIES, serviceFamilyMap } from 'constants/app';
import { activationcodereq, required } from 'util/validation';
import * as ClaimConsts from './constants';

// Styles
import {
  accLimitLabel,
  activationCodeExpirationWarning,
  anchorStyledDiv,
  buttonGroupClaimForm,
  buttonSubmit,
  container,
  formStack,
  haveNeedRow,
  selectPathHeader,
  selectPathItem,
} from './styles.css';

import { buttonGroupCenter, formField } from 'sharedStyles/modalForms.css';

const accCloudSite = SERVICE_FAMILIES.AccCloudServices;

const userCodeOptions = {
  HAS: 'have',
  NEED: 'need',
};

const HasCodeForm = () => {
  return (
    <div>
      <div className={selectPathItem}>
        <div className={formField} id={ClaimConsts.idActivationCodeInput}>
          <Translate>
            {({ translate }) => (
              <Field
                className={formField}
                component={FieldInput}
                name="claimingKey"
                placeholder={translate(
                  'DEVICES.DEVICE_CLAIM.ACTIVATION_CODE_PLACEHOLDER',
                )}
                validate={activationcodereq}
              />
            )}
          </Translate>
        </div>
      </div>
    </div>
  );
};

const NeedsCodeForm = props => {
  const { activationCode } = props;
  return (
    <div className={selectPathItem}>
      <div id={activationCode}>
        <div id={ClaimConsts.idActivationCodeText}>{activationCode}</div>
        <div className={activationCodeExpirationWarning}>
          <Translate id="DEVICES.DEVICE_CLAIM.ACTIVATION_EXPIRATION_NOTICE" />
        </div>
      </div>
    </div>
  );
};

NeedsCodeForm.propTypes = {
  activationCode: PropTypes.string,
};

NeedsCodeForm.defaultProps = {
  activationCode: null,
};

class InternalClaimDeviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasCode: false,
      locationId: null,
      needsCode: false,
    };
  }

  recordLocationChange = e => {
    this.setState({ locationId: e.target.value }, () => {
      if (!this.getSelectedLocation()) {
        this.setState({ hasCode: false, needsCode: false });
      } else {
        const { hasCode, needsCode } = this.state;
        let currentSelectedOption;
        if (hasCode) {
          currentSelectedOption = userCodeOptions.HAS;
        } else if (needsCode) {
          currentSelectedOption = userCodeOptions.NEED;
        } else {
          currentSelectedOption = false;
        }
        if (currentSelectedOption) {
          this.getACodeClick(currentSelectedOption);
        }
      }
    });
  };

  getACodeClick = option => {
    const { getActivationCode } = this.props;
    const selectedLocation = this.getSelectedLocation();
    const { ServiceFamily: serviceFamily } = selectedLocation.PackageSubscription;

    let userHasCode = false;
    let userNeedsCode = false;

    if (serviceFamily) {
      if (option === userCodeOptions.HAS) {
        userHasCode = true;
      } else if (option === userCodeOptions.NEED) {
        userNeedsCode = true;
      }

      // Server will automatically reject anything which is not in available options.
      const deviceFamily = serviceFamilyMap[serviceFamily] || null;

      this.setState(
        {
          hasCode: userHasCode,
          needsCode: userNeedsCode,
        },
        () => {
          if (userNeedsCode) {
            getActivationCode(selectedLocation.Id, deviceFamily);
          }
        },
      );
    } else {
      this.setState({
        hasCode: userHasCode,
        needsCode: userNeedsCode,
      });
    }
  };

  getACode = option => {
    return (
      <span
        className={`${anchorStyledDiv}`}
        onClick={() => {
          this.getACodeClick(option);
        }}
        onKeyPress={() => {}}
        role="link"
        tabIndex="-1"
      >
        <Translate
          id={`DEVICES.DEVICE_CLAIM.${
            option === userCodeOptions.HAS ? 'HAVE' : 'GET'
          }_CODE_LABEL`}
        />
      </span>
    );
  };

  getSelectedLocation = () => {
    const { locations } = this.props;
    const { locationId } = this.state;
    return locations.find(l => l.Id === locationId);
  };

  cancelChanges = () => {
    this.setState({
      hasCode: false,
      needsCode: false,
    });
  };

  render() {
    const {
      activationCode,
      handleSubmit,
      locations,
      onClose,
      onSubmit,
      pristine,
      submitting,
      valid,
    } = this.props;
    const { hasCode, needsCode } = this.state;
    const shouldDisplayAccLimitText = locations.some(
      location =>
        location.ActiveClusterCount > 0 &&
        location.PackageSubscription.ServiceFamily === accCloudSite,
    );

    return (
      <form className={container} onSubmit={handleSubmit(onSubmit)}>
        <div className={formStack}>
          {shouldDisplayAccLimitText && (
            <div className={accLimitLabel}>
              <Translate id="DEVICES.DEVICE_CLAIM.ACC_LIMIT" />
            </div>
          )}
          <label className={selectPathHeader}>
            <Translate id="COMMON.SITE" />
          </label>
          <div
            className={`${selectPathItem} ${formField}`}
            id={ClaimConsts.idServerSiteDropdown}
          >
            <Field
              className={formField}
              component={FieldSelect}
              name="location"
              onChange={this.recordLocationChange}
              validate={required}
            >
              <Translate>
                {({ translate }) => (
                  <option key="notACustomer" value={null}>
                    {translate('DEVICES.DEVICE_CLAIM.CHOOSE_A_SITE_OPTION')}
                  </option>
                )}
              </Translate>
              {locations.map(location => (
                <Translate key={location.Id}>
                  {({ translate }) => (
                    <option
                      key={location.Id}
                      disabled={
                        location.ActiveClusterCount > 0 &&
                        location.PackageSubscription.ServiceFamily === accCloudSite
                      }
                      value={location.Id}
                    >
                      {`${location.Name} ${
                        location.ActiveClusterCount > 0 &&
                        location.PackageSubscription.ServiceFamily === accCloudSite
                          ? ` (${translate(
                              'DEVICES.DEVICE_CLAIM.ACC_CAPACITY_REACHED',
                            )})`
                          : ''
                      }`}
                    </option>
                  )}
                </Translate>
              ))}
            </Field>
          </div>
          {this.getSelectedLocation() && (
            <div>
              <label className={selectPathHeader}>
                <Translate id="DEVICES.DEVICE_CLAIM.ACTIVATION_CODE_LABEL" />
              </label>
              {!hasCode && !needsCode && (
                <div className={`${haveNeedRow}`}>
                  {this.getACode(userCodeOptions.HAS)}
                  <div style={{ padding: '0px 5px' }}>
                    <Translate id="DEVICES.DEVICE_CLAIM.LOCATION_OR_LABEL" />
                  </div>
                  {this.getACode(userCodeOptions.NEED)}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={formStack}>
          {hasCode && (
            <HasCodeForm
              props={pristine}
              submitting={submitting}
              valid={valid}
            />
          )}

          {needsCode && <NeedsCodeForm activationCode={activationCode} />}
        </div>
        {(hasCode || needsCode) && (
          <div className={buttonGroupClaimForm}>
            <GroupLayout additionalClasses={buttonGroupCenter}>
              <Button
                key="claimFormButtonCancel"
                buttonType={needsCode ? 'primary' : 'action'}
                id="claimFormButtonCancel"
                inputType="button"
                onClick={needsCode ? onClose : this.cancelChanges}
                text={
                  needsCode ? (
                    <Translate id="BUTTONS.CLOSE" />
                  ) : (
                    <Translate id="BUTTONS.CANCEL" />
                  )
                }
              />
              {hasCode && (
                <Button
                  className={buttonSubmit}
                  disabled={pristine || submitting || !valid}
                  inputType="submit"
                  text={
                    <Translate id="DEVICES.DEVICE_CLAIM.ACTIVATION_SUBMIT_BUTTON_TEXT" />
                  }
                />
              )}
            </GroupLayout>
          </div>
        )}
      </form>
    );
  }
}

InternalClaimDeviceForm.defaultProps = {
  activationCode: null,
  pristine: true,
  submitting: false,
  valid: false,
};

InternalClaimDeviceForm.propTypes = {
  activationCode: PropTypes.string,
  getActivationCode: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
};
export default reduxForm({
  form: 'internalClaimDevice',
})(InternalClaimDeviceForm);
