// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

// Components
import {
  DigitalOutConfigForm,
  GenericMessage,
} from 'components';
import { EmptyPlaceholder } from 'lib';

// lib
import { AccordionItem } from 'lib';

// Constants
import { DIGITAL_OUT_CONTENT_ID } from './constants';

// Styles
import 'react-input-range/lib/css/index.css';
import { cameraSettingsBasic, rightSettings } from './styles.css';
import { settingsPanelHeader } from '../DigitalOutConfigForm/styles.css';

// Class
class CameraSettingsDigitalOut extends Component {
  componentDidMount() {
    const { camera, fetchEntities, isFetchingCameraEntities } = this.props;
    if (isFetchingCameraEntities === undefined) {
      fetchEntities(camera.DeviceId);
    }
  }

  get digitalOutAvailable() {
    const { digitalOutput } = this.props;
    return digitalOutput && digitalOutput !== {};
  }

  get outputName() {
    const { digitalOutput } = this.props;
    if (digitalOutput && digitalOutput.digitalOutputName) {
      return digitalOutput.digitalOutputName.val;
    }
    return null;
  }

  render() {
    const {
      camera,
      digitalOutput,
      digitalOutputAvailableCameras,
      isFetchingCameraEntities,
      isSaving,
      links,
      onSave,
    } = this.props;
    if (!this.digitalOutAvailable) {
      return (
        <GenericMessage>
          <Translate id="CAMERA.DIGITAL_OUT.NOT_AVAILABLE" />
        </GenericMessage>
      );
    }
    return (
      <div
        className={`${rightSettings} ${cameraSettingsBasic}`}
        id={DIGITAL_OUT_CONTENT_ID}
      >
        <div className={settingsPanelHeader}>
          <Translate id="CAMERA.DIGITAL_OUT.HEADER" />
        </div>
        <EmptyPlaceholder
          isFetching={isFetchingCameraEntities}
          items={digitalOutputAvailableCameras}
        >
          <AccordionItem
            disabled={false}
            expanded={false}
            title={this.outputName}
          >
            <DigitalOutConfigForm
              key={camera.Id}
              digitalOutData={digitalOutput}
              digitalOutLinks={links}
              digitalOutputAvailableCameras={digitalOutputAvailableCameras}
              isSaving={isSaving}
              onSubmit={onSave}
              serverId={camera.ServerId}
              serverName={camera.ServerName}
            />
          </AccordionItem>
        </EmptyPlaceholder>
      </div>
    );
  }
}

CameraSettingsDigitalOut.propTypes = {
  camera: PropTypes.shape({
    DeviceId: PropTypes.string.isRequired,
    Id: PropTypes.string.isRequired,
    ServerId: PropTypes.string.isRequired,
    ServerName: PropTypes.string.isRequired,
  }).isRequired,
  digitalOutput: PropTypes.shape({
    digitalOutputName: PropTypes.shape({ val: PropTypes.string }),
  }).isRequired,
  digitalOutputAvailableCameras: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  fetchEntities: PropTypes.func.isRequired,
  isFetchingCameraEntities: PropTypes.node.isRequired,
  isSaving: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape({}).isRequired,
};

CameraSettingsDigitalOut.defaultProps = {
  isSaving: false,
};

export default CameraSettingsDigitalOut;
