
/* A simple utility which can take either a string or a JSON object
/* and returns a json object without fail.
/* For input which isn't valid or parsable json, an empty object is returned */

const jsonParseRobust = inputItem => {
  if (!inputItem && inputItem !== false) {
    avoLog('jsonParseRobust called on an empty input', inputItem, 'warn');
    return {};
  }
  try {
    if (typeof inputItem === 'string') {
      return JSON.parse(inputItem);
    }
    return inputItem;
  } catch (e) {
    avoLogError('jsonParseRobust called on an invalid string', inputItem);
    return {};
  }
};

export default jsonParseRobust;
