import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconFastForward = props => {
  return (
    <svg
      id={props.id}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 23 19"
      fill={props.fill}
    >
      <path
        d="M11.2321401,17.3997963 C11.0204068,17.5631296 10.7000509,17.4778981 10.7000509,17.2108611 L10.7000509,9.46160185 L0.531991911,17.3997963 C0.320258677,17.5631296 -9.72740741e-05,17.4778981 -9.72740741e-05,17.2108611 L-9.72740741e-05,0.272157407 C-9.72740741e-05,0.00447222222 0.320258677,-0.0801111111 0.531991911,0.0832222222 L10.7000509,8.01299074 L10.7000509,0.272157407 C10.7000509,0.00447222222 11.0204068,-0.0801111111 11.2321401,0.0832222222 L21.9643887,8.45243519 C22.1764461,8.61576852 22.114515,8.8834537 21.9037545,9.04678704 L11.2321401,17.3997963 Z"
        fill={props.fill}
      />
    </svg>
  );
};

IconFastForward.defaultProps = {
  className: null,
  width: '23px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconFastForward;
