import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconNoSnapshot = props => {
  return (
    <div
      title={props.translate(props.title)}
      style={{ height: props.height, width: props.width }}
    >
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 32 23"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <title>{props.translate(props.title)}</title>
        <g
          id="2018-0501-02-wc-devices-card-view"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="2018-0612-devices-new-button-and-clusters"
            transform="translate(-420.000000, -228.000000)"
            fill="#8CCBFF"
            fillRule="nonzero"
          >
            <g id="Group-3" transform="translate(94.000000, 206.000000)">
              <g
                id="img-snapshot-new"
                transform="translate(294.000000, 0.000000)"
              >
                <g id="Group-5" transform="translate(16.000000, 22.000000)">
                  <g id="photo-icon" transform="translate(16.000000, 0.000000)">
                    <path
                      d="M2.66666667,2.09090909 C2.3721148,2.09090909 2.13333333,2.32494206 2.13333333,2.61363636 L2.13333333,20.3863636 C2.13333333,20.6750579 2.3721148,20.9090909 2.66666667,20.9090909 L29.3333333,20.9090909 C29.6278852,20.9090909 29.8666667,20.6750579 29.8666667,20.3863636 L29.8666667,2.61363636 C29.8666667,2.32494206 29.6278852,2.09090909 29.3333333,2.09090909 L2.66666667,2.09090909 Z M2.66666667,0 L29.3333333,0 C30.8060927,0 32,1.17016486 32,2.61363636 L32,20.3863636 C32,21.8298351 30.8060927,23 29.3333333,23 L2.66666667,23 C1.19390733,23 0,21.8298351 0,20.3863636 L0,2.61363636 C0,1.17016486 1.19390733,0 2.66666667,0 Z M4,15.05 L11.4666667,9 L18.9333333,15.05 L23.7333333,12.025 L28,15.05 L28,20 L4,20 L4,15.05 Z M20,11 C18.3431458,11 17,9.65685425 17,8 C17,6.34314575 18.3431458,5 20,5 C21.6568542,5 23,6.34314575 23,8 C23,9.65685425 21.6568542,11 20,11 Z"
                      id="Combined-Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconNoSnapshot.defaultProps = {
  className: null,
  width: '32px',
  height: '23px',
  on: true,
  title: 'CAMERA.SNAPSHOT_UNAVAILABLE',
};

export default withLocalize(IconNoSnapshot);
