exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".body___2VWb3 {\r\n  text-align: left;\r\n}\r\n\r\n.box___D02uN {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 16px 16px 50px 16px; /* TODO: Use one of the padding helper classes instead of this */\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/InvalidToken/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;CAClB;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;EAC3B,6BAA6B,CAAC,iEAAiE;CAChG","file":"styles.css","sourcesContent":[".body {\r\n  text-align: left;\r\n}\r\n\r\n.box {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 16px 16px 50px 16px; /* TODO: Use one of the padding helper classes instead of this */\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"body": "body___2VWb3",
	"box": "box___D02uN"
};