exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".deviceNameWrapper___3H_TV {\r\n  margin: 5px 15px 15px 0;\r\n}\r\n\r\n.deviceName___3w4SU {\r\n  font-size: 14px;\r\n  font-weight: bold;\r\n  margin-left: 6px;\r\n}\r\n\r\n.bulletPointsOfDoom___12M4L {\r\n  list-style-type: disc;\r\n  padding-left: 16px;\r\n  max-width: 520px;\r\n}\r\n\r\n.instructions___3-SL2 {\r\n  margin: 20px 0;\r\n}\r\n\r\n.confirmationInput___30Cty {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  /* margin-left: 36px; */\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n\r\n.confirmationInput___30Cty input {\r\n  margin-left: 10px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/DeleteDeviceForm/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;CACzB;;AAED;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;CAClB;;AAED;EACE,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;CAClB;;AAED;EACE,eAAe;CAChB;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,wBAAwB;EACxB,uBAAuB;MACnB,oBAAoB;CACzB;;AAED;EACE,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".deviceNameWrapper {\r\n  margin: 5px 15px 15px 0;\r\n}\r\n\r\n.deviceName {\r\n  font-size: 14px;\r\n  font-weight: bold;\r\n  margin-left: 6px;\r\n}\r\n\r\n.bulletPointsOfDoom {\r\n  list-style-type: disc;\r\n  padding-left: 16px;\r\n  max-width: 520px;\r\n}\r\n\r\n.instructions {\r\n  margin: 20px 0;\r\n}\r\n\r\n.confirmationInput {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  /* margin-left: 36px; */\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}\r\n\r\n.confirmationInput input {\r\n  margin-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"deviceNameWrapper": "deviceNameWrapper___3H_TV",
	"deviceName": "deviceName___3w4SU",
	"bulletPointsOfDoom": "bulletPointsOfDoom___12M4L",
	"instructions": "instructions___3-SL2",
	"confirmationInput": "confirmationInput___30Cty"
};