// Libs
import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Components
import { SelectableGrid } from 'components';
import { ScheduleHeaderContainer } from 'containers';

// Constants
import { continuous, motionAndAnalytics } from './constants';

// styles
import {
  alarmRow,
  alarmTextLast,
  alarmTextTop,
  bottomContent,
  centerContent,
  contentWrapper,
  gridWrapper,
  header,
  headerNarrow,
  leftBottomContent,
  leftTopContent,
  tableHeader,
  topContent,
} from './styles.css';

const RecordingTemplateSelectable = props => {
  const { groupId, selectedRecordingTemplate, updateRecordingTemplate } = props;

  const transformTemplate = template => {
    const grid = [[], []];
    if (template) {
      const { templateIntervals } = template;
      for (let col = 0; col < templateIntervals.length; col += 1) {
        const { eventToRecordOn } = templateIntervals[col];
        if (templateIntervals[col].recordingMode === 'CONTINUOUS') {
          grid[0][col] = continuous;
          grid[1][col] = motionAndAnalytics;
        } else if (templateIntervals[col].recordingMode === 'ON_EVENT') {
          grid[0][col] = false;
          grid[1][col] = false;
          if (eventToRecordOn) {
            eventToRecordOn.forEach(event => {
              switch (event) {
                case 'ALARM_TRIGGER':
                case 'MOTION': {
                  grid[1][col] = motionAndAnalytics;
                  break;
                }
                default: {
                  break;
                }
              }
            });
          }
        }
      }
    }
    return grid;
  };

  const updateGrid = grid => {
    const { id, tempId } = selectedRecordingTemplate;
    updateRecordingTemplate(grid, id || tempId, groupId);
  };

  return (
    <div className={contentWrapper}>
      <div className={topContent}>
        <div className={leftTopContent}>
          <span className={headerNarrow} />
          <Translate>
            {({ translate }) => (
              <div
                className={tableHeader}
                title={translate(
                  'DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.TABLE_HEADER',
                )}
              >
                <Translate id="DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.TABLE_HEADER" />
              </div>
            )}
          </Translate>
        </div>
        <div className={centerContent}>
          <span className={header}>
            <ScheduleHeaderContainer />
          </span>
        </div>
      </div>
      <div className={bottomContent}>
        <div className={leftBottomContent}>
          <Translate>
            {({ translate }) => (
              <div className={alarmRow}>
                <div
                  className={alarmTextTop}
                  title={translate(
                    'DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.CONTINUOUS_RECORDING',
                  )}
                >
                  {translate(
                    'DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.CONTINUOUS_RECORDING',
                  )}
                </div>
              </div>
            )}
          </Translate>
          <Translate>
            {({ translate }) => (
              <div className={alarmRow}>
                <div
                  className={alarmTextLast}
                  title={translate(
                    'DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.MOTION_AND_ANALYTICS_RECORDING',
                  )}
                >
                  {translate(
                    'DEVICE_DETAILS.RECORDING_SCHEDULE_TAB.RECORDING_TABLE.MOTION_AND_ANALYTICS_RECORDING',
                  )}
                </div>
              </div>
            )}
          </Translate>
        </div>
        <div className={centerContent}>
          <SelectableGrid
            auxData={transformTemplate(selectedRecordingTemplate)}
            gridMatrix={transformTemplate(selectedRecordingTemplate)}
            numColumns={48}
            numrows={2}
            onGridUpdate={updateGrid}
            outerClass={gridWrapper}
            rowClass={alarmRow}
          />
        </div>
      </div>
    </div>
  );
};

RecordingTemplateSelectable.propTypes = {
  groupId: PropTypes.string.isRequired,
  selectedRecordingTemplate: PropTypes.shape({
    id: PropTypes.any,
    tempId: PropTypes.any,
  }),
  updateRecordingTemplate: PropTypes.func.isRequired,
};

RecordingTemplateSelectable.defaultProps = {
  selectedRecordingTemplate: null,
};

export default RecordingTemplateSelectable;
