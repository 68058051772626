// Libs
import Dimensions from 'react-dimensions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';

// Styles
import * as styles from './dashboard.styles.css';

class GraphBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRowAxis = packageChartDataMaximum => {
    const rows = [];
    let i = packageChartDataMaximum;
    while (i >= 0) {
      rows.push(
        <div
          key={`axis_row_${i}`}
          id={`axis_row_${i}`}
          className={styles.graphBarAxisContent}
        >
          <div className={styles.graphBarAxisContentItem}>
            <div className={styles.graphBarAxisContentItemNo}>{i}</div>
            <div
              className={styles.graphBarAxisContentItemAxis}
              style={{
                borderTop: `2px ${
                  i === 0 ? 'solid' : 'dotted'
                } rgb(238, 238, 238)`,
              }}
            />
          </div>
        </div>,
      );
      i -= 1;
    }
    return rows;
  };

  render() {
    const {
      cardNonBarItemHeight,
      cardSymbolsHeight,
      containerHeight,
      maximum,
      packageChartData,
      translate,
    } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div
          className={`${styles.graphContent} ${styles.horizontal}`}
          style={{ height: `${containerHeight}px` }}
        >
          <div
            className={styles.graphXAxis}
            style={{ height: `${containerHeight}px` }}
          >
            {packageChartData.map(data => {
              return (
                <div
                  key={`${data.labelTranslateKey}_${data.color}`}
                  className={styles.graphXAxisItemContainer}
                >
                  <div className={styles.graphRow}>
                    <div
                      className={styles.graphBarContainer}
                      style={{
                        height: `${(data.count / maximum) *
                          (containerHeight - cardNonBarItemHeight)}px`,
                      }}
                    >
                      <div
                        className={styles.graphBar}
                        style={{
                          backgroundColor: data.color,
                        }}
                      />
                    </div>
                    <div
                      className={styles.cardSymbols}
                      style={{
                        height: `${cardSymbolsHeight}px`,
                      }}
                    >
                      <div className={styles.flex}>
                        <div className={styles.flex}>
                          <span className={styles.cardNumber}>
                            {data.count}
                          </span>
                        </div>
                        <div>
                          <span
                            key="up"
                            className={`${styles.cardSymbol} ${styles.green} ${
                              data.numberUp === 0 ? styles.gray : ''
                            }`}
                          >
                            {data.numberUp}
                          </span>
                          <div className={styles.cardHorizontalDividerLine} />
                          <span
                            key="down"
                            className={`${styles.cardSymbol} ${styles.red} ${
                              data.numberDown === 0 ? styles.gray : ''
                            }`}
                          >
                            {data.numberDown}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.graphBarLabel}>
                    {translate(data.labelTranslateKey)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          key="graphBar_axis_wrapper"
          id="graphBar_axis_wrapper"
          className={`${styles.graphXAxis} ${styles.graphBarAxisContainer}`}
        >
          <div className={styles.graphBarAxis}>{this.getRowAxis(maximum)}</div>
        </div>
      </div>
    );
  }
}
GraphBar.defaultProps = {
  cardSymbolsHeight: 40,
  cardNonBarItemHeight: 60,
  containerHeight: 40,
  maximum: 40,
  packageChartData: null,
};
GraphBar.propTypes = {
  cardSymbolsHeight: PropTypes.number,
  cardNonBarItemHeight: PropTypes.number,
  containerHeight: PropTypes.number,
  maximum: PropTypes.number,
  packageChartData: PropTypes.arrayOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

const style = {
  height: '100%',
  width: '100%',
};

export default Dimensions({ elementResize: true, containerStyle: style })(
  withLocalize(GraphBar),
);
