exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/brushes.css"), undefined);

// module
exports.push([module.id, ".userVoiceLink___1KA53 {\r\n  margin: 8px;\r\n  text-decoration: none;\r\n}\r\n\r\n.userVoiceLinkText___1WBDG {\r\n  font-size: 12px;\r\n  font-weight: normal;\r\n}\r\n\r\n.feedbackLink___MbAmT {\r\n  text-decoration: none;\r\n  color: #333333;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/common/UserVoiceLink/UserVoiceLink.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,sBAAsB;CACvB;;AAED;EACE,gBAAgB;EAChB,oBAAoB;CAErB;;AAED;EACE,sBAAsB;EACtB,eAAe;CAChB","file":"UserVoiceLink.css","sourcesContent":[".userVoiceLink {\r\n  margin: 8px;\r\n  text-decoration: none;\r\n}\r\n\r\n.userVoiceLinkText {\r\n  font-size: 12px;\r\n  font-weight: normal;\r\n  composes: avigilonBlueTextColor from '../../../sharedStyles/brushes.css';\r\n}\r\n\r\n.feedbackLink {\r\n  text-decoration: none;\r\n  color: #333333;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"userVoiceLink": "userVoiceLink___1KA53",
	"userVoiceLinkText": "userVoiceLinkText___1WBDG " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/brushes.css").locals["avigilonBlueTextColor"] + "",
	"feedbackLink": "feedbackLink___MbAmT"
};