// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import { getStartOfWeek } from 'util/languagesUtils';

// Actions
import * as UserActions from 'actions/user';
import { hideModal, showModal } from 'actions/modal';

// Components
import { PageMessage } from 'containers';
import { ExceptionSchedule, ScheduleSelectable } from 'components';
import AccountNavMenu from './AccountNavMenu';
import {
  Button,
  GroupLayout,
  Icon,
  ListNav,
  MainContentWrapper,
  PageTitle,
} from 'lib';
import { IC_TRASH } from 'constants/iconNames';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { ALWAYS_SCHEDULE, NEVER_SCHEDULE } from 'util/localizeSeededSchedules';

// Styles
import { navWrapper } from 'lib/ListNav/ListNav.css';
import * as permissions from 'util/permissions';

import {
  btnCreateView,
  dashedLine,
  editorContent,
  inputLabelLeft,
  scheduleContainerHeight,
  scheduleGrid,
  scheduleInput,
  schedulesList,
  schedulesSelect,
  separator,
} from './styles.css';
import * as ScheduleConsts from './constants';

const emptyGrid = (cols, rows) => {
  const grid = [];
  let row = [];
  for (let i = 0; i <= rows * cols; i += 1) {
    if (i !== 0 && i % cols === 0) {
      grid.push(row);
      row = [];
    }
    row.push(true);
  }
  return grid;
};

const emptySchedule = {
  activeGrid: emptyGrid(48, 7),
  activeScheduleId: null,
  activeScheduleName: '',
  error: [],
  exceptionDates: [],
  exceptionGrid: [],
};

class SchedulesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...emptySchedule,
    };
  }

  componentDidMount() {
    const { actions, isFetchingSchedules, schedules } = this.props;
    if (isFetchingSchedules === null) {
      actions.getSchedules();
    }
    if (schedules.length > 0) {
      const firstSchedule = schedules[0];
      this.setState({
        activeGrid: firstSchedule.Weeks,
        activeScheduleId: firstSchedule.Id,
        activeScheduleName: firstSchedule.Name,
        error: [],
        exceptionDates: firstSchedule.Holidays,
        exceptionGrid: firstSchedule.Holidaytimes,
      });
    }
  }

  // eslint-disable-next-line camelcase
  componentDidUpdate(prevProps) {
    const { actions, isFetchingSchedules, orgId, schedules } = this.props;
    const { activeScheduleName } = this.state;
    if (isFetchingSchedules === null || orgId !== prevProps.orgId) {
      actions.getSchedules();
    }
    if (
      (!schedules || schedules.length === 0) &&
      schedules &&
      schedules.length > 0
    ) {
      const firstSchedule = schedules[0];
      this.setState({
        activeGrid: firstSchedule.Weeks,
        activeScheduleId: firstSchedule.Id,
        activeScheduleName: firstSchedule.Name,
        error: [],
        exceptionDates: firstSchedule.Holidays,
        exceptionGrid: firstSchedule.Holidaytimes,
      });
    }
    if (
      prevProps.schedules &&
      schedules &&
      prevProps.schedules.length === schedules.length - 1
    ) {
      // If a new schedule has been added from the current UI, set it as the active schedule
      // but ignore schedules pushed from notification
      const addedSchedule =
        schedules.find(
          newSchedule => newSchedule.Name === activeScheduleName,
        ) || {};
      this.setState({
        activeGrid: addedSchedule.Weeks,
        activeScheduleId: addedSchedule.Id,
        activeScheduleName: addedSchedule.Name,
        error: [],
        exceptionDates: addedSchedule.Holidays,
        exceptionGrid: addedSchedule.Holidaytimes,
      });
    }
  }

  handleScheduleChange = value => {
    const { schedules } = this.props;
    if (!value || value === 'NEW') {
      this.setState(emptySchedule);
    } else {
      const activeSchedule = schedules.find(s => s.Id === value) || {};
      this.setState({
        activeGrid: activeSchedule.Weeks,
        activeScheduleId: activeSchedule.Id,
        activeScheduleName: activeSchedule.Name,
        error: [],
        exceptionDates: activeSchedule.Holidays,
        exceptionGrid: activeSchedule.Holidaytimes,
      });
    }
  };

  handleCancel = () => {
    const { activeScheduleId } = this.state;
    this.handleScheduleChange(activeScheduleId);
  };

  cleanExceptionDayTimes = (exceptionDates, exceptionTimes) => {
    const cleanExceptionGrid = [...exceptionTimes];
    exceptionTimes.forEach((gridItem, index) => {
      if (!gridItem.find(x => x === true) && !exceptionDates[index]) {
        cleanExceptionGrid.splice(index, 1);
      }
    });
    return {
      cleanedHolidayTimes: cleanExceptionGrid,
      cleanedHolidays: exceptionDates,
    };
  };

  handleSave = () => {
    const {
      activeGrid,
      activeScheduleId,
      activeScheduleName,
      exceptionDates,
      exceptionGrid: exceptionGridState,
    } = this.state;
    const { actions } = this.props;
    const exceptionSchedule = this.cleanExceptionDayTimes(
      exceptionDates,
      exceptionGridState,
    );
    const error = [];
    if (!activeScheduleName) {
      error.push('SCHEDULES.FEEDBACK.SAVE_SCHEDULE_NO_NAME_ERROR');
    }
    if (
      !activeGrid ||
      activeGrid.length === 0 ||
      exceptionSchedule.cleanedHolidays.length !==
        exceptionSchedule.cleanedHolidayTimes.length
    ) {
      error.push('SCHEDULES.FEEDBACK.SAVE_SCHEDULE_NO_TIMES_ERROR');
    }
    if (error.length > 0) {
      this.setState({ error });
    } else {
      const scheduleData = {
        Holidays: exceptionSchedule.cleanedHolidays,
        Holidaytimes: exceptionSchedule.cleanedHolidayTimes,
        Name: activeScheduleName,
        Weeks: activeGrid,
      };
      if (activeScheduleId) {
        scheduleData.Id = activeScheduleId;
        actions.editSchedule(scheduleData);
      } else {
        actions.addSchedule(scheduleData);
      }
    }
  };

  handleAdd = () => {
    this.handleScheduleChange('NEW');
  };

  handleDelete = () => {
    const { actions } = this.props;
    const { activeScheduleId, activeScheduleName } = this.state;
    const onOkClick = () => {
      actions.deleteSchedule(activeScheduleId);
      this.setState(emptySchedule);
      actions.hideModal();
    };
    const modalProps = {
      handleCancel: () => {
        actions.hideModal();
      },
      message: (
        <Translate
          data={{ name: activeScheduleName }}
          id="SCHEDULES.DELETE_SCHEDULE_CONFIRM"
        />
      ),
      onOkClick,
      title: <Translate id="SCHEDULES.DELETE_SCHEDULE_MODAL_TITLE" />,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  handleScheduleDropdown = e => {
    this.handleScheduleChange(e.target.value);
  };

  toggleModal = () => {
    const { actions, modalIsOpen } = this.props;
    if (modalIsOpen) {
      actions.hideModal();
      return;
    }
    actions.showModal(modalTypes.SUBSCRIBERS, {});
  };

  processSubscribers = subscribers => {
    return subscribers.map(sub => {
      const fullName = `${sub.firstName} ${sub.lastName}`;
      return { ...sub, fullName };
    });
  };

  handleNameChange = e => {
    this.setState({ activeScheduleName: e.target.value });
  };

  updateActiveGrid = activeGrid => {
    const { startOfWeek } = this.props;
    this.setState({
      activeGrid: ScheduleConsts.changeGridOrderBack(activeGrid, startOfWeek),
    });
  };

  updateExceptionDates = exceptionDates => {
    this.setState({ exceptionDates });
  };

  updateExceptionGrid = newExceptionGrid => {
    this.setState({ exceptionGrid: newExceptionGrid });
  };

  cleanError = error => {
    const { error: errorArray } = this.state;
    this.setState(previousState => ({
      ...previousState,
      error: errorArray.filter(currentError => error !== currentError),
    }));
  };

  get isSystemSchedule() {
    const { activeScheduleName } = this.state;
    return (
      activeScheduleName === ALWAYS_SCHEDULE ||
      activeScheduleName === NEVER_SCHEDULE
    );
  }

  isSaveDisabled = () => {
    const {
      activeGrid,
      exceptionDates,
      exceptionGrid: exceptionGridState,
    } = this.state;
    // Expand the conditions as needed
    /* Condition#1
     * When week's day is set for the entire schedule.
     */
    const isAnyDaySelected = week => {
      return week.find(day => day === true);
    };

    // When business days are not empty and have atleast one selected day in the schedule
    return (
      this.isSystemSchedule ||
      (activeGrid.length > 0 && !activeGrid.some(isAnyDaySelected)) ||
      // exception dates related exception times items exists
      exceptionDates.length !== exceptionGridState.length ||
      // exception times are not empty with dates and atleast one selected day in the schedule
      (exceptionGridState.length > 0 &&
        !exceptionGridState.some(isAnyDaySelected))
    );
  };

  render() {
    const { canChangeCustomer, profile, schedules, startOfWeek } = this.props;
    const {
      activeGrid,
      activeScheduleId,
      activeScheduleName,
      error,
      exceptionDates,
      exceptionGrid: exceptionGridState,
      selectionMode,
    } = this.state;
    return (
      <Translate>
        {({ translate }) => (
          <div className={scheduleContainerHeight}>
            <div className={navWrapper}>
              <ListNav
                canChangeCustomer={canChangeCustomer}
                navigationTabs={<AccountNavMenu profile={profile} />}
                pageTitle={
                  <PageTitle
                    title={<Translate id="SCHEDULES.SCHEDULES_HEADER" />}
                  />
                }
              />
              <div className={editorContent}>
                <div className={schedulesList}>
                  <select
                    className={schedulesSelect}
                    disabled={schedules.length === 0}
                    id={ScheduleConsts.idSchedulesList}
                    onChange={this.handleScheduleDropdown}
                    value={activeScheduleId || 'NEW'}
                  >
                    <option hidden value="NEW">
                      {translate('SCHEDULES.SCHEDULE_NOT_AVAILABLE')}
                    </option>
                    {/*
                        MVAAS-8117 Always, Never and Non-Business Hours schedules
                        are seeded in the database, we're hiding them in this dropdown
                      */}
                    {schedules.map(s => (
                      <option key={s.Id} value={s.Id}>
                        {s.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={btnCreateView}>
                  <Button
                    buttonType="secondary"
                    inputType="button"
                    onClick={this.handleAdd}
                    text={translate('SCHEDULES.CREATE_SCHEDULE_BUTTON')}
                  />
                </div>
                <div className={separator} />
                <div className={inputLabelLeft}>
                  <Translate id="SCHEDULES.LABELS.NAME" />
                </div>
                <input
                  className={scheduleInput}
                  disabled={this.isSystemSchedule}
                  id={ScheduleConsts.idScheduleName}
                  onChange={this.handleNameChange}
                  placeholder={translate('SCHEDULES.SCHEDULE_NAME_PLACEHOLDER')}
                  type="text"
                  value={activeScheduleName}
                />
                <div className={separator} />
                <Button
                  disabled={!activeScheduleId || this.isSystemSchedule}
                  icon
                  id="scheduleDeleteButton"
                  onClick={activeScheduleId ? this.handleDelete : () => null}
                >
                  <Icon id={IC_TRASH} title="BUTTONS.DELETE" />
                </Button>
              </div>
            </div>
            <MainContentWrapper>
              {error.length >= 1 &&
                error.map(errorMessage => (
                  <PageMessage
                    key={errorMessage}
                    hideMessage={() => this.cleanError(errorMessage)}
                    messageStyle={messageStyleStrings.errorMessage}
                    translateBody={errorMessage}
                    visible
                  />
                ))}

              <PageMessage
                messageType={[
                  messageTypes.SCHEDULE_ERROR,
                  messageTypes.SCHEDULE_SUCCESS,
                ]}
              />
              <div>
                <Translate id="SCHEDULES.INSTRUCTIONS_TEXT" />
              </div>
              <div className={scheduleGrid}>
                <ScheduleSelectable
                  gridMatrix={activeGrid}
                  onGridUpdate={this.updateActiveGrid}
                  setSelected={selectionMode}
                  startOfWeek={startOfWeek}
                />
                <div className={dashedLine} />
              </div>
              <div>
                <Translate id="SCHEDULES.EXCLUDE_TIMES_TEXT" />
              </div>
              <div className={exceptionGridState}>
                <ExceptionSchedule
                  exceptionDates={exceptionDates || []}
                  gridMatrix={exceptionGridState}
                  onDatesUpdate={this.updateExceptionDates}
                  onGridUpdate={this.updateExceptionGrid}
                  timezone="UTC"
                />
              </div>
              <GroupLayout
                horizontalPositioning="right"
                verticalSpacing="large"
              >
                <Button
                  key="scheduleCancelButton"
                  buttonType="primary"
                  id="scheduleCancelButton"
                  inputType="button"
                  onClick={this.handleAdd}
                  text={<Translate id="BUTTONS.CANCEL" />}
                />
                <Button
                  key="scheduleSaveButton"
                  buttonType="primary"
                  disabled={this.isSaveDisabled()}
                  id="scheduleSaveButton"
                  inputType="button"
                  onClick={this.handleSave}
                  text={<Translate id="BUTTONS.SAVE" />}
                />
              </GroupLayout>
            </MainContentWrapper>
          </div>
        )}
      </Translate>
    );
  }
}

SchedulesContainer.defaultProps = {
  isFetchingSchedules: null,
  schedules: [],
  startOfWeek: 0,
};

SchedulesContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canChangeCustomer: PropTypes.bool.isRequired,
  isFetchingSchedules: PropTypes.bool,
  modalIsOpen: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object),
  startOfWeek: PropTypes.number,
};

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    isFetchingSchedules: state.isFetching.getSchedules,
    modalIsOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.SUBSCRIBERS,
    orgId,
    schedules: state.user.schedules,
    startOfWeek: getStartOfWeek(state.user.profile.LocalizationPreference),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...UserActions, hideModal, showModal },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesContainer);
