import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

import { ListNav, PageTitle } from 'lib';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';
import { ACTION_IMAGES } from 'lib/ListNav/constants';
import { BLUE_SELF_MONITORING, navTabs } from './constants';

const SiteDetailListNavContainer = ({
  canAddLocation,
  canChangeCustomer,
  canChangeProvider,
  currentSite,
  goBack,
  isAddingLocation,
  isFetchingLocations,
  isSubscriptionTab,
  locationId,
  locations,
  onChangeProviderClick,
  subscriberId,
  translate,
}) => {
  if (isAddingLocation && !canAddLocation) {
    goBack();
  }

  useEffect(() => {
    if (isFetchingLocations === false) {
      const currentLocation = locations.find(loc => locationId === loc.Id);
      if (!currentLocation && !isAddingLocation) {
        goBack();
      }
    }
  }, [locationId, locations, isFetchingLocations, isAddingLocation, goBack]);

  const path = key =>
    subscriberId
      ? `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${subscriberId}${PATH_SEGMENT_SITES}/${currentSite.Id}${navTabs[key].path}`
      : `${PATH_ACCOUNTS}${PATH_SEGMENT_SITES}/${currentSite.Id}${navTabs[key].path}`;

  const tabKeys = Object.keys(navTabs);
  const navigationTabs = (
    <NavTabs
      tabs={tabKeys.map(key => ({
        id: navTabs[key].id,
        key,
        label: translate(`SITE_DETAILS_NAV.TABS.${key.toUpperCase()}.LABEL`),
        path: path(key),
      }))}
    />
  );

  const pkg = currentSite.PackageSubscription;
  const isPendingChanges = pkg && !!pkg.ChangeRequestGrantDate;
  const isBlueSelfMonitoring =
    pkg && pkg.ServiceFamily === BLUE_SELF_MONITORING;

  return (
    <>
      <ListNav
        actionImage={
          !isAddingLocation ? ACTION_IMAGES.SITE_SUBSCRIPTIONS : null
        }
        canAdd={isSubscriptionTab && canChangeProvider && !isBlueSelfMonitoring}
        canChangeCustomer={canChangeCustomer}
        isDisabled={isPendingChanges}
        navigationTabs={!isAddingLocation ? navigationTabs : null}
        onAddClick={() => onChangeProviderClick()} // TODO: Add functionality to add subscription on a location
        pageTitle={
          <PageTitle
            onBackClick={() => goBack()}
            showBackButton
            title={
              !isAddingLocation
                ? translate('LOCATIONS.EDIT_SITE_LABEL', {
                    siteName: currentSite.Name,
                  })
                : translate('LOCATIONS.ADD_SITE_LABEL')
            }
          />
        }
      />
    </>
  );
};

SiteDetailListNavContainer.defaultProps = {
  canAddLocation: false,
  canChangeCustomer: false,
  canChangeProvider: false,
  currentSite: {},
  goBack: () => {},
  isAddingLocation: false,
  isFetchingLocations: null,
  isSubscriptionTab: false,
  locationId: null,
  locations: {},
  onChangeProviderClick: null,
  subscriberId: null,
  translate: null,
};

SiteDetailListNavContainer.propTypes = {
  canAddLocation: PropTypes.bool,
  canChangeCustomer: PropTypes.bool,
  canChangeProvider: PropTypes.bool,
  currentSite: PropTypes.shape({
    Id: PropTypes.string,
    Name: PropTypes.string,
    PackageSubscription: PropTypes.object,
  }),
  goBack: PropTypes.func,
  isAddingLocation: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  isSubscriptionTab: PropTypes.bool,
  locationId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  onChangeProviderClick: PropTypes.func,
  subscriberId: PropTypes.string,
  translate: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  const { locationId } = ownProps;
  const isAddingLocation = locationId === 'new';
  const currentSite =
    locationId && state.locations.locations.find(l => l.Id === locationId);
  return {
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    canChangeProvider: state.user.permissions.CAN_CHANGE_PROVIDER,
    currentSite,
    isAddingLocation,
    isFetchingLocations: state.isFetching.getLocations,
    locations: state.locations.locations,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(withLocalize(SiteDetailListNavContainer)),
);
