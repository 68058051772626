import moment from 'moment';
import aft, { Client as AftClient } from '@avo-aft/aft-client';
import { fetchAlarms } from 'actions/alarms';
import * as urlConstants from 'constants/urlPaths';
import { APP_NAME } from 'constants/app';
import { throttle } from 'lodash';

const NODE_TYPE_CAMERA = 'CAMERA';
const NODE_TYPE_GROUP = 'GROUP';
const NODE_TYPE_MESSAGE_EXTERNAL = 'MESSAGE_EXTERNAL';
const NODE_TYPE_MESSAGE_NO_CAMERAS = 'MESSAGE_NO_CAMERAS';
export const NODE_TYPE_MESSAGE_LOADING_CAMERAS = 'MESSAGE_LOADING_CAMERAS';

let decoratedCameraCache = new WeakMap();
const aftClientCache = {};

const SNAPSHOT_FUZZ_FACTOR = 10000; // ms

// @todo [TG] Should not be required soon...
// Returns a date in a ISO 8601 string format compatible with ACC
export function toAccDateStr(date) {
  return date.replace(/:/g, '').replace(/-/g, '');
}

/**
 * fetch the blue clip data and format the response
 * @param {object} request contains start and end time in JS millisecond format represending the beginning
 *  and the end of the time range to request
 * @param {string} cameraId the unique id of a specific camera being requested for
 *
 * @returns {object} contains various type of events
 * with shape { start: YYYY-MM-DDTHH:MM:SSZ, end: YYYY-MM-DDTHH:MM:SSZ }
 *
 * type of events to be included: analytics, bookmarks
 */
async function readAdditionalTimeline(request, cameraId) {
  const { start, end } = request;
  const FETCH_INTERVAL = 19000; // same constant is used in Savitar as well
  const response = [];
  /**
   * prevent fetching too often when the timeline duration is short
   */
  if (end - start < FETCH_INTERVAL) return response;

  const queryOptions = {
    filters: [
      {
        field: 'EventStartTime',
        typeOfValue: 'number',
        values: [
          moment(start)
            .utc()
            .format(),
        ],
        operator: 'ge',
      },
      {
        field: 'EventEndTime',
        typeOfValue: 'number',
        values: [
          moment(end)
            .utc()
            .format(),
        ],
        operator: 'le',
      },
      {
        field: 'CameraId',
        typeOfValue: 'guid',
        values: [cameraId],
        operator: 'eq',
      },
    ],
  };

  const { value } = await fetchAlarms(queryOptions);

  if (value && value.length > 0) {
    value.forEach(event => {
      /**
       * for blue analytics events, they are being represented with a circle that indicates
       * the start time of the event, so the end time is intentionally set to undefined.
       *
       * the response needs to be in intermediate format which has start, end, and type as properties
       */
      response.push({
        start: new Date(event.EventStartTime).getTime(),
        end: undefined,
        type: 'blue-analytics',
        name: event.EventName,
      });
    });
  }

  return response;
}

function createFuzzyLiveSnapshotRequest(cameraId) {
  return {
    cameraId,
    format: 'jpeg',
    media: 'video',
    size: '206,114',
    t: toAccDateStr(new Date(Date.now() - SNAPSHOT_FUZZ_FACTOR).toISOString()),
  };
}

export function cameraSupportsAFT(camera) {
  const { clusterId, GatewayCapabilities } = camera;
  if (clusterId) {
    /* The clusterId property should only be present if this camera object
     * came from AFT, which means it's an AFT-supported camera which is
     * probably coming from an ACC cluster.
     */
    return true;
  }

  if (GatewayCapabilities && GatewayCapabilities.includes('AFT')) {
    /* Cameras which come from the Blue REST API have GatewayCapabilities
     * injected. If the AFT capability is included, this camera supports AFT.
     */
    return true;
  }

  return false;
}

export function getAftClient(camera) {
  const clusterId = camera.clusterId || camera.GroupId;
  const cachedAftClient = aftClientCache[clusterId];
  if (!cachedAftClient) {
    aftClientCache[clusterId] = new AftClient({ clusterId });
    return aftClientCache[clusterId];
  }

  const newAftClient = new AftClient({ clusterId });
  return cachedAftClient.connection === newAftClient.connection
    ? cachedAftClient
    : newAftClient;
}

/**
 * Decorate a camera object as found in the Redux aft slice with aft-client for
 * use with Savitar, thumbnails in the camera selection list, etc... This
 * object is formatted according to ACC/WEP output as received via AFT, but it
 * has the Blue `clusterId` value injected into it by the reducer when stored
 * in Redux. To reduce unnecessary rerenders, we cache the decorated versions
 * of a camera so this function will always return referentially-equal outputs
 * given referentially-equal inputs.
 */
export function decorateCamera(camera) {
  let decoratedCamera = decoratedCameraCache.get(camera);
  if (decoratedCamera) {
    return decoratedCamera;
  }

  if (!cameraSupportsAFT(camera)) {
    /* We can't decorate this camera. Once all in-the-field Blue Connect
     * devices are upgraded to AFT-capable firmware this shouldn't happen,
     * and this should become an error condition at that time. In the meantime
     * just return the undecorated input version
     */
    return camera;
  }

  /* No camera was found in the cache, so we should generate a new
   * decorated object, cache it, and return it.
   */

  /* the supplied camera object may have come from an AFT read-cameras
   * request, or a Blue REST API. The data shapes are different, so we need
   * to select consistently-named properties and values regardless which
   * input shape was provided.
   * const <identifier> = <value-from-AFT> || <value-from-Blue>;
   */
  const clusterId = camera.clusterId || camera.GroupId;
  const cameraId = camera.id || camera.RemoteId;

  const client = getAftClient(camera);
  const getRevokableSnapshotURL = async () => {
    const request = createFuzzyLiveSnapshotRequest(cameraId);
    const response = await client.readMedia(request);
    const blob = new Blob(response.payload, {
      type: response.headers['content-type'],
    });
    const url = window.URL.createObjectURL(blob);

    return {
      url: window.URL.createObjectURL(blob),
      revoke: () => window.URL.revokeObjectURL(url),
    };
  };

  const { GatewayCapabilities } = camera;
  if (
    GatewayCapabilities &&
    GatewayCapabilities.includes('ANALYTIC_EVENTS_SYNC')
  ) {
    camera.readAdditionalTimeline = throttle(
      async request => readAdditionalTimeline(request, camera.Id),
      3000,
    );
    camera.isBlueConnectCamera = true;
  }

  decoratedCamera = {
    aftClient: client,
    getRevokableSnapshotURL,
    /* Savitar's SimplePlayer expects a cameraId property for its own internal
     * bindings with aft-client
     */
    cameraId,
    clusterId,
    ...camera,
  };

  decoratedCameraCache.set(camera, decoratedCamera);
  return decoratedCamera;
}

export function isAftCatalogSync(gatewayCapabilities = []) {
  return (
    Array.isArray(gatewayCapabilities) &&
    gatewayCapabilities.includes('AFT') &&
    !gatewayCapabilities.includes('AUTH_CATALOG_SYNC')
  );
}

export function isCameraNotificationEnabled(gatewayCapabilities = []) {
  if (!Array.isArray(gatewayCapabilities)) return false;
  if (!gatewayCapabilities.includes('WEBRTC_NOTIFICATIONS')) return false;
  // TESTING NOTE: Capability included in WEP from integ/ACC-v7.6 branch 2020/04/14 or later
  let cameraNotificationEnabled = false;
  gatewayCapabilities.forEach(capability => {
    if (capability.includes('CAMERA_LIGHT_NOTIFICATIONS')) {
      cameraNotificationEnabled = true;
    }
  });
  return cameraNotificationEnabled;
}

export function setAftTenantId(tenantId) {
  const {
    PROTOCOL,
    HOST,
    HOST_LOCAL,
    HOST_MOCK,
    HOST_TEST,
    HOST_DEV,
  } = urlConstants;
  const { API_ENV } = process.env;
  const { localStorage } = window;

  let protocol = PROTOCOL;
  let baseUrl = HOST;
  if (API_ENV === 'local') {
    baseUrl = HOST_LOCAL;
    protocol = 'http://';
  } else if (API_ENV === 'dev') {
    baseUrl = (localStorage && localStorage.devHost) || HOST_DEV;
  } else if (API_ENV === 'mock') {
    baseUrl = HOST_MOCK;
    protocol = 'http://';
  } else if (API_ENV === 'test') {
    baseUrl = HOST_TEST;
    protocol = 'http://';
  }

  const environment = protocol + baseUrl;
  aft.config({
    tenantId,
    environment,
    clientName: APP_NAME,
  });

  decoratedCameraCache = new WeakMap();
}

/**
 * Loop through each child, filter for Cameras
 * and Groups, and populate each camera.
 */
const decorateSiteView = (children, cameras) => {
  // Expects cameras to be an OBJECT of { cameraId: camera }
  const newChildren = [];
  children.forEach(child => {
    if (child.type === NODE_TYPE_CAMERA && cameras) {
      // Always bring in camera object rather than relying on site view object
      const cameraListCamera = cameras[child.id];
      if (cameraListCamera) {
        // CameraList cameras are already decorated, etc.
        newChildren.push({ ...child, ...cameraListCamera });
      }
    } else if (child.type === NODE_TYPE_GROUP) {
      newChildren.push({
        ...child,
        children: decorateSiteView(child.children || child.entries, cameras),
        state: { expanded: true },
      });
    }
  });
  return newChildren;
};

function siteIsLoading(site) {
  return site.children.some(
    child => child.type === NODE_TYPE_MESSAGE_LOADING_CAMERAS,
  );
}

export function getSiteChildren(site, cameraHash) {
  const { messages = [], cameras = [], siteView = [] } = site;
  const newChildren = [];

  if (!siteIsLoading(site) && cameras.length === 0 && siteView.length === 0) {
    newChildren.push({
      id: `message_${site.locationId}`,
      messages: [],
      type: NODE_TYPE_MESSAGE_NO_CAMERAS,
    });
    return newChildren;
  }

  if (messages.length !== 0) {
    newChildren.push({
      id: `messages_${site.locationId}`,
      messages,
      type: NODE_TYPE_MESSAGE_EXTERNAL,
    });
    return newChildren;
  }

  if (siteView.length > 0) {
    newChildren.push(...decorateSiteView(siteView, cameraHash));
  } else if (cameras.length > 0) {
    // No siteview but presence of cameras most likely indicates a Blue site
    cameras.forEach(camera => {
      newChildren.push(camera);
    });
  }

  return newChildren;
}
