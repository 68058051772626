import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconSmallWrong = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>Icon-small-x</title>
      <g
        id="06-Org-Mgmt-Form-Register"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-Org-Mgmt-General"
          transform="translate(-675.000000, -337.000000)"
        >
          <g id="All-Content" transform="translate(262.000000, 182.000000)">
            <g id="Content" transform="translate(17.000000, 104.000000)">
              <g id="Group-2" transform="translate(371.000000, 10.000000)">
                <g
                  id="icon-small-x"
                  transform="translate(25.000000, 41.000000)"
                >
                  <circle id="Oval" fill="#FF0000" cx="6" cy="6" r="6" />
                  <path
                    d="M7.53287497,6 L8.7418619,4.79101307 C9.13238619,4.40048878 9.13238619,3.7673238 8.7418619,3.37679951 L8.7418619,3.37679951 L8.62320049,3.2581381 L8.62320049,3.2581381 C8.2326762,2.86761381 7.59951122,2.86761381 7.20898693,3.2581381 L7.20898693,3.2581381 L6,4.46712503 L4.79101307,3.2581381 L4.79101307,3.2581381 C4.40048878,2.86761381 3.7673238,2.86761381 3.37679951,3.2581381 L3.37679951,3.2581381 L3.2581381,3.37679951 L3.2581381,3.37679951 C2.86761381,3.7673238 2.86761381,4.40048878 3.2581381,4.79101307 L4.46712503,6 L3.2581381,7.20898693 C2.86761381,7.59951122 2.86761381,8.2326762 3.2581381,8.62320049 L3.37679951,8.7418619 C3.7673238,9.13238619 4.40048878,9.13238619 4.79101307,8.7418619 L6,7.53287497 L7.20898693,8.7418619 C7.59951122,9.13238619 8.2326762,9.13238619 8.62320049,8.7418619 L8.7418619,8.62320049 C9.13238619,8.2326762 9.13238619,7.59951122 8.7418619,7.20898693 L7.53287497,6 Z"
                    id="Combined-Shape"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSmallWrong.defaultProps = {
  className: '',
  width: '12px',
  height: '12px',
};

export default IconSmallWrong;
