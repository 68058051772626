import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from '../constants/app';

const IconClip = props => {
  return (
    <svg
      id={props.id}
      width={props.width}
      height={props.height}
      viewBox="0 0 25 16"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <title>{props.title}</title>
        <g id="video-icon-02" fill={props.fill}>
          <rect id="Rectangle-6" x="0" y="0" width="25" height="1" />
          <rect id="Rectangle-6" x="0" y="2.05023373" width="25" height="1" />
          <rect
            id="Rectangle-6"
            transform="translate(3.500000, 7.500000) rotate(90.000000) translate(-3.500000, -7.500000) "
            x="-4"
            y="6"
            width="15"
            height="3"
          />
          <rect
            id="Rectangle-6"
            transform="translate(0.500000, 1.500000) rotate(90.000000) translate(-0.500000, -1.500000) "
            x="-1"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(24.500000, 1.500000) rotate(90.000000) translate(-24.500000, -1.500000) "
            x="23"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(6.500000, 1.500000) rotate(90.000000) translate(-6.500000, -1.500000) "
            x="5"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(8.500000, 1.500000) rotate(90.000000) translate(-8.500000, -1.500000) "
            x="7"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(10.500000, 1.500000) rotate(90.000000) translate(-10.500000, -1.500000) "
            x="9"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(12.500000, 1.500000) rotate(90.000000) translate(-12.500000, -1.500000) "
            x="11"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(14.500000, 1.500000) rotate(90.000000) translate(-14.500000, -1.500000) "
            x="13"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(16.500000, 1.500000) rotate(90.000000) translate(-16.500000, -1.500000) "
            x="15"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(18.500000, 1.500000) rotate(90.000000) translate(-18.500000, -1.500000) "
            x="17"
            y="1"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(0.500000, 14.500000) rotate(90.000000) translate(-0.500000, -14.500000) "
            x="-1"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(24.500000, 14.500000) rotate(90.000000) translate(-24.500000, -14.500000) "
            x="23"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(6.500000, 14.500000) rotate(90.000000) translate(-6.500000, -14.500000) "
            x="5"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(8.500000, 14.500000) rotate(90.000000) translate(-8.500000, -14.500000) "
            x="7"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(10.500000, 14.500000) rotate(90.000000) translate(-10.500000, -14.500000) "
            x="9"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(12.500000, 14.500000) rotate(90.000000) translate(-12.500000, -14.500000) "
            x="11"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(14.500000, 14.500000) rotate(90.000000) translate(-14.500000, -14.500000) "
            x="13"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(16.500000, 14.500000) rotate(90.000000) translate(-16.500000, -14.500000) "
            x="15"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(18.500000, 14.500000) rotate(90.000000) translate(-18.500000, -14.500000) "
            x="17"
            y="14"
            width="3"
            height="1"
          />
          <rect
            id="Rectangle-6"
            transform="translate(21.500000, 7.500000) rotate(90.000000) translate(-21.500000, -7.500000) "
            x="14"
            y="6"
            width="15"
            height="3"
          />
          <rect id="Rectangle-6" x="0" y="15" width="25" height="1" />
          <rect id="Rectangle-6" x="0" y="13" width="25" height="1" />
          <polygon
            id="Triangle"
            transform="translate(12.857143, 8.000000) rotate(90.000000) translate(-12.857143, -8.000000) "
            points="12.8571429 5 16.7142857 11 9 11"
          />
        </g>
      </g>
    </svg>
  );
};

IconClip.defaultProps = {
  id: 'iconClip',
  className: null,
  width: '25px',
  height: '16px',
  fill: ACTIVE_COLOR_DEFAULT,
  title: 'Recorded Video',
};

export default IconClip;
