import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';

// actions
import * as OrganizationActions from 'actions/organizations';

// components
import { Spinner } from 'components';
import { Button, GroupLayout } from 'lib';

// Constants
import * as idConsts from 'constants/ElementId';

// styles
import {
  bold,
  confirmationInput,
  deleteModalContent,
  deleteModalList,
} from '../styles.css';

class DeleteCentralStationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredCentralStationName: '',
    };
  }

  UNSAFE_componentWillMount() {
    // On mount, check if this integration can be deleted
    this.props.actions.canDeleteIntegrationConfig(this.props.configId);
  }

  handleDeleteConfirm = () => {
    this.props.onDeleteConfirm(this.props.configId);
  };

  handleInputChange = event => {
    this.setState({ enteredCentralStationName: event.target.value });
  };

  render() {
    const {
      canDeleteConfiguration,
      centralStationName,
      configId,
      handleCancel,
    } = this.props;
    if (!canDeleteConfiguration || canDeleteConfiguration.id !== configId) {
      return (
        <div className={deleteModalContent}>
          <div style={{ margin: 'auto' }}>
            <Spinner />
          </div>
        </div>
      );
    }
    if (canDeleteConfiguration.canDelete) {
      const disabled =
        this.state.enteredCentralStationName !== centralStationName;
      return (
        <div className={deleteModalContent}>
          <p>
            <Translate id="INTEGRATIONS.DELETE_INTEGRATION.DELETE_MODAL_WARNING" />
            <span
              key="siteName"
              className={bold}
            >{` ${centralStationName}`}</span>
          </p>
          <p>
            <Translate id="INTEGRATIONS.DELETE_INTEGRATION.DELETE_MODAL_ENTER_NAME" />
          </p>
          <input
            id={idConsts.CONFIRM_NAME_INPUT}
            onChange={this.handleInputChange}
            size="50"
            value={this.state.enteredCentralStationName}
          />
          <GroupLayout>
            <Button
              key="cancelButton"
              buttonType="primary"
              className={confirmationInput}
              id={idConsts.CANCEL_BUTTON_ID}
              inputType="button"
              onClick={handleCancel}
            >
              <Translate id="BUTTONS.CANCEL" />
            </Button>
            <Button
              key="deleteButton"
              buttonType="primary"
              disabled={disabled}
              id={idConsts.DELETE_BUTTON_ID}
              inputType="button"
              onClick={this.handleDeleteConfirm}
            >
              <Translate id="BUTTONS.DELETE" />
            </Button>
          </GroupLayout>
        </div>
      );
    }
    if (canDeleteConfiguration.canDelete === false) {
      if (canDeleteConfiguration.sites.length === 0) {
        return (
          <div className={deleteModalContent}>
            <p>
              <Translate id="INTEGRATIONS.DELETE_INTEGRATION.DELETE_ERROR" />
            </p>
            <GroupLayout>
              <Button
                key="okButton"
                id={idConsts.OK_BUTTON_ID}
                inputType="button"
                onClick={handleCancel}
              >
                <Translate id="BUTTONS.OK" />
              </Button>
            </GroupLayout>
          </div>
        );
      }
      return (
        <div className={deleteModalContent}>
          <p>
            <Translate
              data={{ centralStationName }}
              id="INTEGRATIONS.DELETE_INTEGRATION.DELETE_MODAL_SUBSCRIBERS"
            />
          </p>
          {canDeleteConfiguration.sites ? (
            <ul className={deleteModalList}>
              {this.props.impactedSubscribers.map(item => (
                <li key={item.SubscriberName}>{item.SubscriberName}</li>
              ))}
            </ul>
          ) : null}
          <p>
            <Translate id="INTEGRATIONS.DELETE_INTEGRATION.DELETE_MODAL_CANNOT_DELETE" />
          </p>
          <GroupLayout>
            <Button
              key="okButton"
              id={idConsts.OK_BUTTON_ID}
              inputType="button"
              onClick={handleCancel}
            >
              <Translate id="BUTTONS.OK" />
            </Button>
          </GroupLayout>
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  // All we do here is consult the store for whether this config
  // has any related sites
  const { configId } = ownProps;
  const { integrationConfigs } = state.organizations;
  const integrationConfig =
    integrationConfigs &&
    integrationConfigs.find(
      config => config.IntegrationConfigurationId === configId,
    );
  const centralStationName = integrationConfig ? integrationConfig.Name : '';
  const { canDeleteConfiguration } = state.organizations;
  const subscribers = state.organizations.integrationSubscriberSummary;
  const impactedSubscribers = [];

  if (canDeleteConfiguration && canDeleteConfiguration.sites && subscribers) {
    canDeleteConfiguration.sites.forEach(site => {
      subscribers.forEach(subscriber => {
        if (subscriber.SubscriberId === site.TenantId) {
          const subscriberObject = {
            SubscriberName: subscriber.SubscriberName,
          };
          if (
            !impactedSubscribers.find(
              x => x.SubscriberName === subscriberObject.SubscriberName,
            )
          ) {
            impactedSubscribers.push(subscriberObject);
          }
        }
      });
    });
  }

  return {
    canDeleteConfiguration,
    centralStationName,
    configId,
    impactedSubscribers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...OrganizationActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteCentralStationForm);
