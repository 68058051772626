import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';

// Actions
import * as UserActions from 'actions/user';

// Components
import {
  BusyIndicator,
  LoggedOutContentWrapper,
  RequestResetPasswordForm,
} from 'components';

// Style
import { registerText } from './styles.css';

// this container used for requesting a password reset
function RequestResetContainer(props) {
  const { isFetching, translate } = props;

  function changePassword(formData) {
    const { actions } = props;
    actions.requestResetPassword(formData.email);
  }

  return (
    <LoggedOutContentWrapper header={translate('RESET_PASSWORD.HEADER')}>
      <div className={registerText}>
        {translate('RESET_PASSWORD.REGISTER_TEXT')}
      </div>
      <RequestResetPasswordForm onSubmit={changePassword} />
      {isFetching.getUserData && <BusyIndicator />}
    </LoggedOutContentWrapper>
  );
}

RequestResetContainer.defaultProps = {
  actions: {},
  isFetching: {},
};

RequestResetContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  isFetching: PropTypes.objectOf(PropTypes.bool),
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = UserActions;
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(RequestResetContainer),
);
