/* Libraries */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Dimensions from 'react-dimensions';
import { getShortD3FormatForActiveLocale } from 'util/convertTimeTo';
import * as d3Array from 'd3-array';
import * as d3Scale from 'd3-scale';
import * as d3TimeFormat from 'd3-time-format';
/* Note - d3-axis will manipulate the dom directly. Use with caution! */
import { select as d3Select } from 'd3-selection';
import * as d3Axis from 'd3-axis';
/* Components */
import { PREFERRED_LONG_DATE_D3_FORMAT } from 'constants/dateTimeNumberFormats';
import GridLines from './GridLines';
import LineChartItem from './LineChartItem';
import Tooltip from './Tooltip';
/* Styles */
import {
  chartContainer,
  chartKey,
  chartKeyItem,
  chartKeyLabel,
  chartKeySwatch,
  lineChartContent,
} from './styles.css';

class LineChartComponent extends Component {
  constructor(props) {
    super(props);
    const {
      containerHeight,
      containerWidth,
      legendHeight,
      margin,
    } = this.props;
    this.state = {
      chartHeight: containerHeight - margin * 2 - legendHeight,
      chartWidth: containerWidth - margin * 2,
      circleSelected: 0,
      keepCircle: false,
      lineSelected: 0,
    };
  }

  componentDidMount() {
    const xNode = d3Select(this.xAxis);
    this.drawXAxis(xNode);
    const yNode = d3Select(this.yAxis);
    this.drawYAxis(yNode);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { containerHeight, containerWidth, locale } = this.props;
    if (
      nextProps.containerHeight !== containerHeight ||
      nextProps.containerWidth !== containerWidth ||
      nextProps.locale !== locale
    ) {
      this.setState(
        {
          chartHeight:
            nextProps.containerHeight -
            nextProps.margin * 2 -
            nextProps.legendHeight,
          chartWidth: nextProps.containerWidth - nextProps.margin * 2,
        },
        () => {
          const xNode = d3Select(this.xAxis);
          this.drawXAxis(xNode);
          const yNode = d3Select(this.yAxis);
          this.drawYAxis(yNode);
        },
      );
    }
  }

  selectLine = lineId => {
    const { onSelectLine } = this.props;
    this.setState({ lineSelected: lineId }, () => onSelectLine(lineId));
  };

  selectCircle = circleKey =>
    this.setState(prevState => {
      if (!prevState.keepCircle) {
        return { circleSelected: circleKey };
      }
      return null;
    });

  keepCircle = () =>
    this.setState(prevState => {
      if (prevState.circleSelected !== 0) {
        return { keepCircle: true };
      }
      return null;
    });

  clearCircle = () => this.setState({ circleSelected: 0, keepCircle: false });

  handleTooltipClick = (key, dataPoint, lineData, lineIndex) => {
    const { onTooltipClick } = this.props;
    onTooltipClick(key, dataPoint, lineData, lineIndex);
  };

  xScale = extent => {
    const { margin } = this.props;
    const { chartWidth } = this.state;
    return d3Scale
      .scaleTime()
      .domain(extent)
      .range([margin, chartWidth + margin]);
  };

  yScale = extent => {
    const { margin, yTicks } = this.props;
    const { chartHeight } = this.state;
    return d3Scale
      .scaleLinear()
      .domain(extent)
      .range([chartHeight + margin, margin])
      .nice([yTicks]);
  };

  getExtent = () => {
    const { chartRange, data, getVal, getX, getY } = this.props;
    let scaleData = [];
    data.forEach(dataObj => {
      scaleData = scaleData.concat(getVal(dataObj));
    });
    let xMin = d3Array.min(scaleData, getX);
    let xMax = d3Array.max(scaleData, getX);
    let xExtent = [
      xMin.setDate(xMin.getDate() - 0.5),
      xMax.setDate(xMax.getDate() + 0.5),
    ];
    const yMin = 0;
    const yMax = d3Array.max(scaleData, getY);
    const yExtent = [yMin, yMax];
    if (chartRange) {
      xMin = d3Array.min(chartRange);
      xMax = d3Array.max(chartRange);
      xExtent = [xMin, xMax];
    }
    return { xExtent, yExtent };
  };

  getScaledArray = (data, extent) => {
    const { getX, getY, xString, yString } = this.props;
    return data.map(datum => ({
      x: this.xScale(extent.xExtent)(getX(datum)),
      xString: xString ? xString(datum) : getX(datum),
      y: this.yScale(extent.yExtent)(getY(datum)),
      yString: yString ? yString(datum) : getY(datum),
    }));
  };

  drawXAxis = xNode => {
    const { margin, profileLongDateFormat, xTicks } = this.props;
    const { chartHeight } = this.state;
    const { xExtent } = this.getExtent();
    const xAxis = d3Axis
      .axisBottom()
      .scale(this.xScale(xExtent))
      .ticks(xTicks)
      .tickFormat(
        d3TimeFormat.timeFormat(
          PREFERRED_LONG_DATE_D3_FORMAT[profileLongDateFormat] ||
            getShortD3FormatForActiveLocale(),
        ),
      )
      .tickSize(0)
      .tickPadding([6]);
    xNode.attr('transform', `translate(0,${chartHeight + margin})`).call(xAxis);
    xNode.select('.domain').remove();
  };

  drawYAxis = yNode => {
    const { margin, yTicks } = this.props;
    const { yExtent } = this.getExtent();
    const yAxis = d3Axis
      .axisLeft()
      .scale(this.yScale(yExtent))
      .ticks(yTicks)
      .tickSize(0)
      .tickPadding([6]);
    yNode.attr('transform', `translate(${margin}, 0)`).call(yAxis);
    yNode.select('.domain').remove();
  };

  render() {
    const {
      circleRadius,
      color,
      containerHeight,
      containerWidth,
      data,
      getFilterName,
      getFilterRange,
      getFilterType,
      getId,
      getVal,
      highlightColor,
      legendHeight,
      margin,
      selectedLegendClassName,
      tooltipClass,
      xTicks,
      yTicks,
    } = this.props;

    const {
      chartHeight,
      chartWidth,
      circleSelected,
      keepCircle,
      lineSelected,
    } = this.state;
    /* Pre-process the data into an array of scaled points, [{x, y}, {x, y}, etc.], 
    which will be used to draw shapes */
    const extent = this.getExtent();
    const processedData = data.map(dataObj =>
      this.getScaledArray(getVal(dataObj), extent),
    );
    const highlightColorItem = highlightColor || color;
    return (
      <div className={chartContainer}>
        <div
          style={{
            boxSizing: 'border-box',
            height: `${containerHeight}px`,
            margin: 'auto',
            padding: `0px ${margin}px`,
            position: 'relative',
            width: `${containerWidth}px`,
          }}
        >
          {Array.isArray(color) && (
            <div className={chartKey}>
              {color.map((colorItem, index) => {
                const isSelected = lineSelected === `line_${index}`;
                if (data[index]) {
                  const label = `${getFilterName(
                    data[index],
                  )} (${getFilterRange(data[index])
                    .map(date => date.format('D MMM'))
                    .join(' - ')})`;
                  return (
                    <div
                      key={`key_${index}`}
                      className={`${chartKeyItem} ${
                        isSelected ? selectedLegendClassName : ''
                      }`}
                      title={label}
                    >
                      <div
                        className={chartKeySwatch}
                        style={{ backgroundColor: colorItem }}
                      />
                      <div className={chartKeyLabel}>{label}</div>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <svg
            className={lineChartContent}
            height={containerHeight - legendHeight}
            style={{
              left: '0px',
              position: 'absolute',
              top: `${legendHeight}px`,
            }}
            viewBox={`0 0 ${containerWidth - 25} 200`}
            width={containerWidth}
          >
            <GridLines
              coloredBackground
              height={chartHeight}
              width={chartWidth}
              xScale={this.xScale(extent.xExtent)}
              xStart={margin}
              xTicks={xTicks}
              yScale={this.yScale(extent.yExtent)}
              yStart={margin}
              yTicks={yTicks}
            />
            <g ref={node => (this.xAxis = node)} className="xAxis" />
            <g ref={node => (this.yAxis = node)} className="yAxis" />
            <g>
              {processedData.map((datum, index) => {
                const lineId = getId ? getId(data[index]) : index;
                return (
                  <LineChartItem
                    key={`line_${index}`}
                    color={
                      Array.isArray(color) ? color[index % color.length] : color
                    }
                    highlightColor={
                      Array.isArray(highlightColorItem)
                        ? highlightColorItem[index % highlightColorItem.length]
                        : highlightColorItem
                    }
                    id={`line_${lineId}`}
                    isSelected={lineSelected === `line_${lineId}`}
                    onCircleClick={() => {
                      this.selectLine(`line_${lineId}`);
                      this.keepCircle();
                    }}
                    onCircleHover={this.selectCircle}
                    onCircleLeave={this.selectCircle.bind(this, 0)}
                    onClick={this.selectLine.bind(this, `line_${lineId}`)}
                    radius={circleRadius}
                    scaledDataPoints={datum}
                  />
                );
              })}
            </g>
          </svg>
          <div
            style={{
              left: '0px',
              position: 'absolute',
              top: `${legendHeight}px`,
            }}
          >
            {processedData.map((datum, index) => {
              const filterName = getFilterName(data[index]);
              const filterType = getFilterType(data[index]);
              return datum.map((dataPoint, i) => (
                <Tooltip
                  key={i}
                  chartWidth={chartWidth}
                  className={tooltipClass}
                  dataPoint={dataPoint}
                  filterName={filterName}
                  filterType={filterType}
                  onClickout={keepCircle ? this.clearCircle : () => {}}
                  ownKey={dataPoint.x + dataPoint.y}
                  radius={0}
                  selectedKey={circleSelected}
                />
              ));
            })}
          </div>
        </div>
      </div>
    );
  }
}

LineChartComponent.defaultProps = {
  chartRange: null,
  circleRadius: 8,
  color: '#000000',
  getFilterName: () => '',
  getFilterRange: () => [],
  getFilterType: () => '',
  getId: null,
  getVal: val => val,
  header: '',
  headerClassName: '',
  highlightColor: null,
  legendHeight: 20,
  locale: '',
  margin: 25,
  onSelectLine: () => '',
  onTooltipClick: () => '',
  profileLongDateFormat: '',
  selectedLegendClassName: '',
  showTooltips: true,
  tooltipClass: '',
  xString: null,
  xTicks: 6,
  yString: null,
  yTicks: 2,
};

LineChartComponent.propTypes = {
  chartRange: PropTypes.arrayOf(PropTypes.any), // an array of objects
  circleRadius: PropTypes.number, // returns the x value from the data array
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // returns a string corresponding to the x value
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired, // returns the y value from the data array
  getFilterName: PropTypes.func, // returns a string corresponding to the y value
  getFilterRange: PropTypes.func, // returns the array of x and y values from a data object.
  // If getVal is not specified, getX and getY should work on each object in data directly
  getFilterType: PropTypes.func, // if not provided, a line will be identified by its index in data
  getId: PropTypes.func, // manually set the start and end values of the x-axis
  // For now, array should contain javascript date objects
  // If chartRange is set, dates without corresponding data will have a y-value of 0
  getVal: PropTypes.func, // returns a string describing the type of filter from a data object
  getX: PropTypes.func.isRequired, // returns a string naming the filter from a data object
  // getFilterType and getFilterName can be used if data objects are the result of specific filters
  // applied to the data, and the data has information on those filters
  getY: PropTypes.func.isRequired, // returns an array containing two moments, [beginTime, endTime]
  header: PropTypes.string, // Additional action to take when a line is clicked
  headerClassName: PropTypes.string, // Action to take when a tooltip is clicked
  highlightColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // width of the left and bottom margin in which axis are placed
  legendHeight: PropTypes.number, // height of the portion of the chart containing the key/legend
  locale: PropTypes.string, // header of chart component
  margin: PropTypes.number, // class of chart component
  onSelectLine: PropTypes.func,
  // Color or array of colors to draw lines in
  onTooltipClick: PropTypes.func,
  // Color or array of colors to highlight lines in on click
  // If not provided, the selected line will not highlight
  profileLongDateFormat: PropTypes.string, // class applied to legend key when line is selected
  selectedLegendClassName: PropTypes.string, // radius of the circles at data points
  showTooltips: PropTypes.bool, // Number of labelled tick marks on the x-axis
  tooltipClass: PropTypes.string, // Number of labelled tick marks on the y-axis
  xString: PropTypes.func, // whether or not to show info on hover
  xTicks: PropTypes.number, // className for hover-over info
  yString: PropTypes.func, // selected locale to re render graph
  yTicks: PropTypes.number,
};

export default Dimensions({ elementResize: true })(LineChartComponent);
