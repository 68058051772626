import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import { ServerDetailRetention } from 'components';
import { EmptyPlaceholder, MainContentWrapper } from 'lib';
import { cloneDeep } from 'util/cameraSettingLinks';
// Actions
import {
  getRecordingSettings,
  saveRecordingSettings,
} from 'actions/devices/videoRetention';
import { hideModal, showModal } from 'actions/modal';
import { showPersistentTranslatedError } from 'actions/pageMessage';
import calculateStorageTime from 'util/calculateStorageTime';
// Constants
import {
  ERROR_GETTING_RECORDING_SETTINGS_MESSAGE,
  VIDEO_RETENTION_MESSAGE,
} from 'constants/MessageTypes';
import * as CameraTypes from 'constants/cameraTypes';
import { getCameraActualStatus } from 'util/getCameraActualStatus';

import { PageMessage } from 'containers';

const TERABYTES = 1000000000000;

const ServerDetailRetentionContainer = props => {
  const {
    actions,
    isFetchingRecordingSettings,
    isFetchingUsageData,
    isMessageInactive,
    recordingSettings,
    storageData,
    store,
  } = props;
  const { cameras, selectedServer } = store;
  // Storage info
  const volumeInfo = storageData && storageData.volumeInfo;
  const capacity =
    volumeInfo &&
    Number(Number(volumeInfo.activeUsageBytes / TERABYTES).toPrecision(3));
  const used =
    volumeInfo &&
    Number(Number(volumeInfo.internalUsageBytes / TERABYTES).toPrecision(3));

  const availableStorage = storageData
    ? storageData.volumeInfo.activeUsageBytes
    : 0;

  const camerasInitialState = cloneDeep(cameras).filter(camera => {
    const cameraRealStatus = getCameraActualStatus(camera);
    return (
      camera.ServerId === selectedServer &&
      (cameraRealStatus.realState !== CameraTypes.CAMERA_DISCONNECTED ||
        camera.RecordedData)
    );
  });
  const [connectedCameras, setConnectedCameras] = useState(camerasInitialState);
  const [isDirty, setIsDirty] = useState(false);

  const [
    initialMaxRecordingAndLinkedStatus,
    setInitialMaxRecordingAndLinkedStatus,
  ] = useState(false);

  const [currentLinkedMaxRetention, setCurrentLinkedMaxRetention] = useState(
    false,
  );

  const retentions = useMemo(() => {
    return calculateStorageTime(
      connectedCameras,
      recordingSettings,
      availableStorage,
    );
  }, [connectedCameras, recordingSettings, availableStorage]);

  const selectedServerObj = store.devices.find(
    device => device.Id === selectedServer,
  );

  if (
    typeof currentLinkedMaxRetention === 'boolean' &&
    !currentLinkedMaxRetention
  ) {
    const linkedCameras = connectedCameras.filter(
      camera => camera.linked && camera.retention >= 0,
    );
    if (linkedCameras.length > 0) {
      setCurrentLinkedMaxRetention(linkedCameras[0].retention);
    }
  }

  if (
    typeof currentLinkedMaxRetention === 'number' &&
    currentLinkedMaxRetention >= 0
  ) {
    const unlinkedCameras = connectedCameras.filter(camera => !camera.linked);
    if (unlinkedCameras.length === connectedCameras.length) {
      setCurrentLinkedMaxRetention(false);
    }
  }

  useEffect(() => {
    if (
      isFetchingRecordingSettings === null ||
      isFetchingRecordingSettings[selectedServer] === undefined
    ) {
      actions.getRecordingSettings(selectedServer);
    }
  }, [selectedServer, isFetchingRecordingSettings, actions]);

  if (recordingSettings && !initialMaxRecordingAndLinkedStatus) {
    let shouldSetCurrentLinkedMaxStatus = false;
    const changedCameras = connectedCameras.map(connectedCamera => {
      let cameraRetention = {};
      const record =
        recordingSettings &&
        recordingSettings.find(
          recordSetting => recordSetting.accId === connectedCamera.RemoteId,
        );
      if (record) {
        if (!shouldSetCurrentLinkedMaxStatus) {
          shouldSetCurrentLinkedMaxStatus =
            record.camerasLinked.val && !currentLinkedMaxRetention
              ? record.maxRetention.val
              : false;
        }
        cameraRetention = {
          ...connectedCamera,
          linked: record.camerasLinked.val,
          retention: record.maxRetention.val,
        };
      } else {
        cameraRetention = {
          ...connectedCamera,
          linked: false,
          retention: 0,
        };
      }
      return cameraRetention;
    });
    if (typeof shouldSetCurrentLinkedMaxStatus === 'number') {
      setCurrentLinkedMaxRetention(shouldSetCurrentLinkedMaxStatus);
    }
    setConnectedCameras(changedCameras);
    setInitialMaxRecordingAndLinkedStatus(changedCameras);
  }

  const handleRetentionChange = (cameraId, retentionValue) => {
    const changedRetentionCameras = connectedCameras.map(camera =>
      camera.Id === cameraId
        ? {
            ...camera,
            retention:
              retentionValue !== 'MAX' ? parseInt(retentionValue, 10) : 0,
          }
        : camera,
    );
    setConnectedCameras(changedRetentionCameras);
    setIsDirty(true);
  };

  const handleLinkedStatusChange = (cameraId, newLinkedStatus) => {
    const changedCameras = connectedCameras.map(camera => {
      if (
        camera.Id === cameraId &&
        typeof currentLinkedMaxRetention === 'number'
      ) {
        return {
          ...camera,
          linked: newLinkedStatus,
          retention: currentLinkedMaxRetention,
        };
      }
      if (camera.Id === cameraId) {
        return {
          ...camera,
          linked: newLinkedStatus,
        };
      }
      return { ...camera };
    });
    setConnectedCameras(changedCameras);
    setIsDirty(true);
  };

  const handleOnRevert = () => {
    setConnectedCameras(initialMaxRecordingAndLinkedStatus);
    setIsDirty(false);
  };

  const handleOnSave = () => {
    actions.saveRecordingSettings(
      selectedServer,
      connectedCameras,
      recordingSettings,
    );
    setInitialMaxRecordingAndLinkedStatus(connectedCameras);
    setIsDirty(false);
  };

  const handleLinkedRetentionChange = (linkedCameras, retentionValue) => {
    const changedCameras = connectedCameras.map(camera => {
      return { ...camera };
    });
    linkedCameras.forEach(cameraId => {
      changedCameras.find(
        camera => camera.Id === cameraId,
      ).retention = retentionValue;
    });
    setCurrentLinkedMaxRetention(
      retentionValue !== 'MAX' ? parseInt(retentionValue, 10) : 0,
    );
    setConnectedCameras(changedCameras);
    setIsDirty(true);
  };

  const isFetchingForServer =
    isFetchingRecordingSettings !== null &&
    typeof isFetchingRecordingSettings === 'object' &&
    isFetchingRecordingSettings[selectedServer];

  const showErrorMessage =
    !recordingSettings &&
    isFetchingRecordingSettings !== null &&
    typeof isFetchingRecordingSettings === 'object' &&
    isFetchingRecordingSettings[selectedServer] === false &&
    isMessageInactive;

  if (showErrorMessage) {
    actions.showPersistentTranslatedError(
      ERROR_GETTING_RECORDING_SETTINGS_MESSAGE,
      'DEVICE_DETAILS.VIDEO_RETENTION_TAB.ERROR_GETTING_RECORDING_SETTINGS_MESSAGE',
    );
  }

  return (
    <MainContentWrapper>
      <EmptyPlaceholder
        isFetching={
          isFetchingUsageData ||
          isFetchingForServer ||
          isFetchingRecordingSettings
        }
        items={initialMaxRecordingAndLinkedStatus}
        string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
      >
        <>
          <PageMessage
            messageType={[
              VIDEO_RETENTION_MESSAGE,
              ERROR_GETTING_RECORDING_SETTINGS_MESSAGE,
            ]}
          />
          {recordingSettings && (
            <ServerDetailRetention
              cameras={connectedCameras}
              estimatedRetention={retentions}
              handleLinkedRetentionChange={handleLinkedRetentionChange}
              handleRetentionChange={handleRetentionChange}
              isDirty={isDirty}
              modalActions={{
                hide: actions.hideModal,
                show: actions.showModal,
              }}
              onLinkedStatusChange={handleLinkedStatusChange}
              onRevert={handleOnRevert}
              onSave={handleOnSave}
              recordingSettings={recordingSettings}
              selectedServerId={selectedServer}
              server={selectedServerObj}
              storageCapacity={capacity}
              storageUsed={used}
              volumeInfo={volumeInfo}
            />
          )}
        </>
      </EmptyPlaceholder>
    </MainContentWrapper>
  );
};

const mapStateToProps = state => {
  const {
    devices,
    isFetching: {
      getRecordingSettings: getRecordingSettingsStore,
      getServerDataUsageInformation,
    },
    pageMessage,
    videoRetention,
  } = state;
  const { cameras, devices: devicesArr, selectedServer, settings } = devices;
  const isMessageInactive =
    pageMessage.body === null && pageMessage.messageType === null;
  return {
    isFetchingRecordingSettings: getRecordingSettingsStore,
    isFetchingUsageData: getServerDataUsageInformation,
    isMessageInactive,
    recordingSettings: videoRetention[selectedServer]
      ? videoRetention[selectedServer].recordingSettings
      : undefined,
    storageData: videoRetention[selectedServer]
      ? videoRetention[selectedServer].storageData
      : undefined,
    store: {
      cameras,
      devices: devicesArr,
      selectedServer,
      settings,
    },
  };
};

const mapOwnProps = (stateProps, actionProps, ownProps) => ({
  ...stateProps,
  ...actionProps,
  ...ownProps,
});

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getRecordingSettings,
        hideModal,
        saveRecordingSettings,
        showModal,
        showPersistentTranslatedError,
      },
      dispatch,
    ),
  };
};

ServerDetailRetentionContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetchingRecordingSettings: PropTypes.bool.isRequired,
  isFetchingUsageData: PropTypes.bool.isRequired,
  isMessageInactive: PropTypes.bool.isRequired,
  recordingSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
  storageData: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mapOwnProps,
)(ServerDetailRetentionContainer);
