import React from 'react';
import { SVG_XMLNS } from 'constants/app';

const IconPin = props => {
  return (
    <div title="Pin">
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 10 19"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="pin">
            <g id="Page-1">
              <g id="Group-3" transform="translate(1.324786, 12.256275)">
                <path
                  d="M2.96154274,0.600989352 C2.40085897,0.470980093 1.84560256,0.278297222 1.32385043,0.036575 L0.0377393162,6.72919306 L2.96154274,0.600989352 Z"
                  fill={props.fill}
                />
              </g>
              <g transform="translate(0.000000, 6.758590)" fill={props.fill}>
                <path d="M5.84968376,5.85462569 L5.84994017,5.85462569 C6.76917094,5.85462569 7.99451282,5.62169977 8.61318803,4.51187106 C8.95464103,3.89929699 8.94805983,3.26957014 8.59387179,2.64015116 C8.40562393,2.30558403 8.12173504,1.98333171 7.76998291,1.68500532 L7.46459829,3.41396134 C7.45105128,3.49123681 7.39780342,3.55474606 7.32553846,3.58012338 C6.68964103,3.80306551 6.06797436,3.91618588 5.47810256,3.91618588 L5.47780342,3.91618588 C2.80331624,3.91605394 1.58190598,1.66996366 1.53126496,1.57434792 C1.49951282,1.51470903 1.49703419,1.44315116 1.52442735,1.38131319 L2.12305983,0.0249243056 C1.39677778,0.139188194 0.57442735,0.450665046 0.187119658,1.24347523 C-0.104974359,1.84149144 -0.0548888889,2.47557245 0.335880342,3.12799375 C1.22113675,4.60590347 3.74617949,5.85453773 5.84968376,5.85462569" />
              </g>
              <path
                d="M1.95309829,8.21474676 C2.20339744,8.61700139 3.34724359,10.2419412 5.47784188,10.2420731 L5.47809829,10.2420731 C5.98617521,10.2420731 6.52211538,10.1501519 7.07326923,9.96872824 L7.8959188,5.31214491 C7.76630342,5.32375602 7.63344017,5.33083704 7.4959188,5.33083704 L7.49536325,5.33083704 C6.24741453,5.33061713 4.81557692,4.83925602 3.75762821,4.12614028 L1.95309829,8.21474676 Z"
                fill={props.fill}
              />
              <g transform="translate(2.606838, 0.029424)">
                <path
                  d="M4.88854274,4.86867083 L4.88909829,4.86867083 C5.67636325,4.86867083 6.71281624,4.66186991 7.18174786,3.67708056 C7.43837179,3.13795556 7.40320085,2.59174954 7.07704701,2.05368009 C6.3842265,0.910205556 4.47713248,0.0145226852 2.73533761,0.0145226852 C1.97136325,0.0145226852 0.612559829,0.200080556 0.0943547009,1.44242546 C-0.0644059829,1.82317315 -0.0020982906,2.23906204 0.279269231,2.678525 C1.03914103,3.86545324 3.15003846,4.86845093 4.88854274,4.86867083"
                  fill={props.fill}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconPin.defaultProps = {
  className: null,
  width: '10px',
  height: '19px',
  fill: '#FFFFFF',
};

export default IconPin;
