import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import colorGen from 'color-generator';
import { CUSTOM_SATURATION_BRIGHTNESS } from 'constants/app';
import * as consts from '../components/ServerDetail/ServerDetailRecordingSchedule/constants';

export default function recordingDataReducer(state, action = {}) {
  switch (action.type) {
    case types.RECEIVE_TEMPLATES: {
      const colorTemplates = action.templates.map(template => ({
        ...template,
        backgroundColor: colorGen(
          CUSTOM_SATURATION_BRIGHTNESS.saturation,
          CUSTOM_SATURATION_BRIGHTNESS.brightness,
        ).hexString(),
      }));
      const templates = {
        ...state.templates,
        [action.groupId]: colorTemplates,
      };
      return { ...state, isDirty: false, templates };
    }

    case types.CREATE_TEMPLATE: {
      const tempId = Math.random().toString();
      const template = {
        ...action.templateData,
        backgroundColor: colorGen(
          CUSTOM_SATURATION_BRIGHTNESS.saturation,
          CUSTOM_SATURATION_BRIGHTNESS.brightness,
        ).hexString(),
        tempId,
      };
      const templates = [...state.templates[action.groupId], template];
      return {
        ...state,
        isDirty: true,
        selectedTemplate: tempId,
        templates: { [action.groupId]: templates },
      };
    }

    case types.SAVE_TEMPLATE: {
      const templates = [
        ...state.templates[action.groupId].map(template =>
          template.name === action.templateData.name
            ? { ...template, id: action.templateId }
            : template,
        ),
      ];
      return { ...state, templates: { [action.groupId]: templates } };
    }

    case types.DELETE_TEMPLATE: {
      const { groupId, templateId } = action;
      const newTemplates = state.templates[groupId].filter(
        t => (t.id || t.tempId) !== templateId,
      );
      return {
        ...state,
        isDirty: true,
        templates: { [groupId]: newTemplates },
      };
    }

    case types.SET_SELECTED_TEMPLATE: {
      const { templateId } = action;
      return { ...state, selectedTemplate: templateId };
    }

    case types.UPDATE_RECORDING_TEMPLATE: {
      const firstRow = action.grid[0];
      const secondRow = action.grid[1];
      const selectedTemplate = state.templates[action.groupId].find(template =>
        template.id
          ? template.id === action.templateId
          : template.tempId === action.templateId,
      );
      let intervals = selectedTemplate.templateIntervals;

      for (let col = 0; col < firstRow.length; col += 1) {
        const firstCell = firstRow[col];
        const secondCell = secondRow[col];
        const interval = intervals[col];
        let updatedTemplate = {};
        if (!firstCell && !secondCell) {
          updatedTemplate = {
            ...interval,
            eventToRecordOn: [],
            recordingMode: consts.ON_EVENT,
          };
        } else if (firstCell && interval.recordingMode === consts.ON_EVENT) {
          updatedTemplate = {
            ...interval,
            eventToRecordOn: consts.EVENT_TO_RECORD_ON_CONTINUOUS,
            recordingMode: consts.CONTINUOUS,
          };
        } else if (!firstCell && secondCell) {
          updatedTemplate = {
            ...interval,
            eventToRecordOn: consts.EVENT_TO_RECORD_ON_ON_EVENT,
            recordingMode: consts.ON_EVENT,
          };
        } else if (
          firstCell &&
          !secondCell &&
          interval.recordingMode === consts.ON_EVENT
        ) {
          updatedTemplate = {
            ...interval,
            eventToRecordOn: consts.EVENT_TO_RECORD_ON_CONTINUOUS,
            recordingMode: consts.CONTINUOUS,
          };
        } else if (
          firstCell &&
          !secondCell &&
          interval.recordingMode === consts.CONTINUOUS
        ) {
          updatedTemplate = {
            ...interval,
            eventToRecordOn: [],
            recordingMode: consts.ON_EVENT,
          };
        }
        intervals = [
          ...intervals.slice(0, col),
          { ...interval, ...updatedTemplate },
          ...intervals.slice(col + 1),
        ];
      }

      const updatedTemplate = {
        ...selectedTemplate,
        isDirty: true,
        templateIntervals: intervals,
      };
      return {
        ...state,
        isDirty: true,
        templates: {
          [action.groupId]: state.templates[action.groupId].map(template =>
            (template.id
            ? template.id === action.templateId
            : template.tempId === action.templateId)
              ? { ...template, ...updatedTemplate }
              : template,
          ),
        },
      };
    }

    case types.EDIT_TEMPLATE_NAME: {
      const templates = [
        ...state.templates[action.groupId].map(template =>
          (template.id
          ? template.id === action.templateId
          : template.tempId === action.templateId)
            ? { ...template, isDirty: true, name: action.name }
            : template,
        ),
      ];
      return {
        ...state,
        isDirty: true,
        templates: { [action.groupId]: templates },
      };
    }

    case types.RECEIVE_RECORDING_SCHEDULES: {
      const recordingSchedules = {
        ...state.recordingSchedules,
        [action.groupId]: action.recordingSchedules,
      };
      return { ...state, isDirty: false, recordingSchedules };
    }

    case types.UPDATE_RECORDING_SCHEDULE: {
      const schedulesMap = new Map();

      // shift schedule by preferred/locale start day of week
      const dayOffset = {
        0: 0,
        1: 1,
        6: -1,
      };

      for (let i = 0; i < action.selectedKeys.length; i += 1) {
        const key = action.selectedKeys[i];

        const scheduleId = action.recordingSchedules[Math.floor(key / 7)].id;
        const dayKey =
          (key + dayOffset[action.startOfWeek]) % 7 >= 0
            ? (key + dayOffset[action.startOfWeek]) % 7
            : 6;
        const day = consts.days[dayKey];
        if (!schedulesMap.has(scheduleId)) {
          schedulesMap.set(scheduleId, { [day]: action.templateId });
        } else {
          schedulesMap.set(scheduleId, {
            ...schedulesMap.get(scheduleId),
            ...{ [day]: action.templateId },
          });
        }
      }

      const hasTempId = state.templates[action.groupId].some(
        template => template.tempId,
      );

      return {
        ...state,
        isDirty: true,
        recordingSchedules: {
          [action.groupId]: state.recordingSchedules[
            action.groupId
          ].map(schedule =>
            schedulesMap.has(schedule.id)
              ? { ...schedule, ...schedulesMap.get(schedule.id) }
              : schedule,
          ),
        },
        usingRealTemplateIds: !hasTempId,
      };
    }

    case types.UPDATE_RECORDING_SCHEDULE_ID: {
      return {
        ...state,
        recordingSchedules: {
          [action.groupId]: state.recordingSchedules[action.groupId].map(
            schedule => {
              const tempId = action.idMap.keys().next().value;
              let newSchedule = schedule;
              if (Object.values(schedule).includes(tempId)) {
                Object.entries(schedule).forEach(([key, value]) => {
                  if (value === tempId) {
                    newSchedule = {
                      ...schedule,
                      [key]: action.idMap.get(tempId),
                    };
                  }
                });
              }
              return newSchedule;
            },
          ),
        },
        usingRealTemplateIds: true,
      };
    }

    default:
      return state || initialState().recordingData;
  }
}
