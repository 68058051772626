export const DIGITAL_OUT_CONTENT_ID = 'DigitalOutContent';

export const DIGITAL_OUT_FIELDS = [
  'digitalOutputName',
  'circuitStateNormallyClosed',
  'outputModeIsHold',
  'pulseDurationMs',
  'linkedCameras',
];

export const DIGITAL_OUT_BUTTON_IDS = {
  REVERT: 'digitalOutSettingsButtonRevert',
  SAVE: 'digitalOutSettingsButtonSave',
};

export const idDigitalOutName = 'DigitalOutName';
export const idCircuitOpen = 'CircuitOpenRadio';
export const idCircuitClosed = 'CircuitClosedRadio';
export const idHold = 'HoldRadio';
export const idPulse = 'PulseRadio';

export const DIGITAL_OUT_FIELD_CONFIG = {
  circuitStateNormallyClosed: {
    name: 'circuitStateNormallyClosed',
  },
  digitalOutputName: {
    name: 'digitalOutputName',
  },
  outputModeIsHold: {
    name: 'outputModeIsHold',
  },
  pulseDurationMs: {
    name: 'pulseDurationMs',
  },
};

export const DIGITAL_OUT_FORM_TRANSLATED = {
  FIELDS: {
    circuitStateNormallyClosed: {
      displayTranslatedName:
        'DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_FIELDS.CIRCUIT_STATE_NORMALLY_CLOSED_LABEL',
    },
    digitalOutputName: {
      displayTranslatedName: 'COMMON.NAME',
    },
    linkedCameras: {
      displayTranslatedName:
        'DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_FIELDS.LINKED_CAMERAS_LABEL',
    },
    outputModeIsHold: {
      displayTranslatedName:
        'DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_FIELDS.OUTPUT_MODE_IS_HOLD_LABEL',
    },
    pulseDurationMs: {
      displayTranslatedName:
        'DEVICE_DETAILS.DIGITAL_IO_TAB.DIGITAL_CHANNEL_FORM_FIELDS.PULSE_DURATION_LABEL',
    },
  },
};
