// Libs
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

// Components
import { SourceList, SourceListItem, Spinner } from 'components';
import { Button, Icon, LineEditor } from 'lib';

// ICONS
import { IconDelete, IconEdit } from 'icons';

// Utils
import { Translate } from 'react-localize-redux';
import colorGen from 'color-generator';
import { generateSort } from 'util/generateSort';

// Constants
import {
  ACTIVE_COLOR_DEFAULT,
  CUSTOM_SATURATION_BRIGHTNESS,
  ICON_COLOR_DEFAULT,
} from 'constants/app';
import { IC_EDIT } from 'constants/iconNames';
import { EDIT_ICON_ID } from 'constants/ElementId';
import { templateData, templateNameLength } from './constants';

// Styles
import {
  activeIcons,
  addTemplateButton,
  addTemplateWrapper,
  editorField,
  iconHolder,
  iconTrash,
  itemName,
  selected,
  templateItem,
  templateSquare,
  templateWrap,
} from './styles.css';

const defaultTemplateId = 'yEeV93oAQpW8eGwigNNG_w';
const TemplateItem = ({
  deleteTemplate,
  editTemplateName,
  groupId,
  index,
  isCreatingTemplate,
  item,
  onClick,
  onEditingTemplate,
  selectedTemplateId,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const selectedTemplateRef = useRef(null);

  useEffect(() => {
    if (isCreatingTemplate) {
      setIsEditing(false);
    }

    if (selectedTemplateRef && selectedTemplateRef.current) {
      selectedTemplateRef.current.scrollIntoView();
    }
  }, [isCreatingTemplate, selectedTemplateId]);

  const backgroundColor = useMemo(
    () =>
      colorGen(
        CUSTOM_SATURATION_BRIGHTNESS.saturation,
        CUSTOM_SATURATION_BRIGHTNESS.brightness,
      ).hexString(),
    [],
  );

  const handleDeleteClick = () => {
    const itemId = item.id || item.tempId;
    deleteTemplate(itemId);
  };
  const handleEditClick = () => {
    // switch to edit mode
    setIsEditing(true);
    onEditingTemplate();
  };
  const handleSaveClick = newName => {
    editTemplateName(newName, item.id || item.tempId, groupId);
    // switch from editing mode
    setIsEditing(false);
  };
  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const itemId = item.id || item.tempId;

  const isSelected = selectedTemplateId ? selectedTemplateId === itemId : false;

  return (
    <SourceListItem
      key={item.id || index}
      onClick={onClick}
      selected={isSelected}
    >
      <div className={templateItem}>
        <div
          className={templateSquare}
          style={{
            backgroundColor: item.backgroundColor || backgroundColor,
          }}
        />
        {isEditing && item.id !== defaultTemplateId ? (
          <LineEditor
            cancelEdit={handleCancelClick}
            className={editorField}
            createFunction={handleSaveClick}
            iconColor={ICON_COLOR_DEFAULT}
            initialValue={item.name}
          />
        ) : (
          <>
            <div
              className={`${itemName} ${isSelected ? selected : ''}`}
              ref={itemId === selectedTemplateId ? selectedTemplateRef : null}
            >
              {item.name}
            </div>
            {item.id !== defaultTemplateId ? (
              <Translate>
                {({ translate }) => (
                  <div className={activeIcons}>
                    <div className={iconHolder}>
                      <Button
                        icon
                        id={EDIT_ICON_ID}
                        inputType="button"
                        onClick={handleEditClick}
                      >
                        <Icon
                          color="white"
                          id={IC_EDIT}
                          size="16"
                          title="DEVICE_DETAILS.EDIT_RECORDING_SCHEDULE_LABEL"
                        />
                      </Button>
                    </div>
                    <div className={iconTrash}>
                      <Button
                        icon
                        inputType="button"
                        onClick={handleDeleteClick}
                      >
                        <IconDelete
                          fill="#fff"
                          height="16px"
                          iconId={`delete_${index}`}
                          width="16px"
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </Translate>
            ) : null}
          </>
        )}
      </div>
    </SourceListItem>
  );
};

TemplateItem.defaultProps = {
  onClick: () => {},
  selectedTemplateId: null,
};

TemplateItem.propTypes = {
  deleteTemplate: PropTypes.func.isRequired,
  editTemplateName: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isCreatingTemplate: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  onEditingTemplate: PropTypes.func.isRequired,
  selectedTemplateId: PropTypes.string,
};

const TemplateList = React.memo(function TemplateList(props) {
  const {
    createTemplate,
    deleteTemplate,
    editTemplateName,
    groupId,
    isFetchingTemplates,
    selectedTemplateId,
    setSelectedTemplateId,
    templates,
  } = props;
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);

  const onSelectTemplate = useCallback(
    templateId => setSelectedTemplateId(templateId),
    [setSelectedTemplateId],
  );

  const addTemplate = () => {
    if (isFetchingTemplates) {
      return;
    }
    setIsCreatingTemplate(true);
  };

  const closeEditor = () => {
    setIsCreatingTemplate(false);
  };

  const handleCreateTemplate = name => {
    const template = { ...templateData, name };
    createTemplate(template);
    closeEditor();
  };

  const creationArea = isCreatingTemplate ? (
    <div className={addTemplateWrapper}>
      <LineEditor
        cancelEdit={closeEditor}
        createFunction={handleCreateTemplate}
        iconColor={ACTIVE_COLOR_DEFAULT}
        length={templateNameLength}
        title="DEVICE_DETAILS.CREATE_TEMPLATE_TITLE"
      />
    </div>
  ) : (
    <Button
      buttonClass={addTemplateButton}
      buttonType="primary"
      inputType="button"
      onClick={() => addTemplate(setIsCreatingTemplate)}
      size="large"
      text={<Translate id="BUTTONS.ADD" />}
    />
  );

  const sortTemplates = useMemo(() => {
    if (templates) {
      let sortedTemplates = Object.assign([], templates);
      sortedTemplates.sort(
        generateSort(template => template.name && template.name.toLowerCase()),
      );
      const defaultTemplate = sortedTemplates.find(
        template => template.id === defaultTemplateId,
      );
      sortedTemplates = sortedTemplates.filter(
        template => template.id !== defaultTemplateId,
      );
      sortedTemplates = [defaultTemplate, ...sortedTemplates];
      if (!selectedTemplateId) {
        onSelectTemplate(sortedTemplates[0].id);
      }
      return sortedTemplates;
    }
    return undefined;
  }, [onSelectTemplate, selectedTemplateId, templates]);

  const handleEditingTemplate = () => {
    setIsCreatingTemplate(false);
  };

  return (
    <div className={templateWrap}>
      {isFetchingTemplates ? (
        <Spinner size={20} />
      ) : (
        <SourceList>
          {templates
            ? sortTemplates.map((template, templateIndex) => {
                const templateId = template.id || template.tempId;
                return (
                  <TemplateItem
                    key={templateId || templateIndex}
                    deleteTemplate={deleteTemplate}
                    editTemplateName={editTemplateName}
                    groupId={groupId}
                    index={templateIndex}
                    isCreatingTemplate={isCreatingTemplate}
                    item={template}
                    onClick={() => onSelectTemplate(templateId)}
                    onEditingTemplate={handleEditingTemplate}
                    selectedTemplateId={selectedTemplateId}
                  />
                );
              })
            : null}
        </SourceList>
      )}
      <div>{creationArea}</div>
    </div>
  );
});

TemplateList.propTypes = {
  createTemplate: PropTypes.func.isRequired,
  isFetchingTemplates: PropTypes.bool,
  templates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

TemplateList.defaultProps = {
  isFetchingTemplates: true,
};

export default TemplateList;
