/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

// TODO Andre: Make this generic.
import { FieldInput, FieldPassword, VerticalFormFieldLayout } from 'components';
import { FieldSelect, FieldTextArea } from 'lib';

const formBodies = {
  password: FieldPassword,
  select: FieldSelect,
  text: FieldInput,
  textarea: FieldTextArea,
};

const VerticalFormField = props => {
  const { hidden, label, tooltipProps, type } = props;
  const VerticalFormFieldBody = formBodies[type];
  return (
    <>
      {renderIf(!hidden)(
        <VerticalFormFieldLayout label={label} tooltipProps={tooltipProps}>
          <VerticalFormFieldBody {...props} />
        </VerticalFormFieldLayout>,
      )}
    </>
  );
};

VerticalFormField.propTypes = {
  hidden: PropTypes.bool,
  label: PropTypes.string,
  tooltipProps: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string.isRequired,
};

VerticalFormField.defaultProps = {
  hidden: false,
  label: '',
  tooltipProps: undefined,
};

export default VerticalFormField;
