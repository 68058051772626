import React from 'react';
import { PropTypes } from 'prop-types';
import { IconSmallCheck } from 'icons';

import { successContainer, successText } from './styles.css';

const FormSuccess = ({ className, message }) => (
  <div className={className}>
    <IconSmallCheck width={15} height={15} />
    <div className={successText}>{message}</div>
  </div>
);

FormSuccess.defaultProps = {
  className: successContainer,
  message: 'Success',
};

FormSuccess.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default FormSuccess;
