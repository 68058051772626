export const idServerName = 'ServerName';
export const idServerTimezone = 'Timezone';
export const idHostName = 'HostName';
export const idIpAddress = 'IpAddress';
export const idMacAddress = 'EthernetMACAddress';
export const idFirmwareVersion = 'FirmwareVersion';
export const idSerialNumber = 'SerialNumber';
export const idUpgrade = 'Upgrade';
export const idLogDownload = 'btnDownloadLogs';
export const idBtnRestart = 'btnRestartAppliance';
export const idBtnEnable = 'btnEnableCamera';
export const idBtnDisable = 'btnDisableCamera';
export const idBtnLogin = 'btnCameraLogin';
export const idBtnSetCredentials = 'btnCameraSetCredentials';
export const idToggle = 'toggleCameraEnabled';
export const idToggleFilterFDSCam = 'toggleFilterFDSCam';
export const fieldServerName = 'Name';
export const fieldHostName = 'HostName';
export const fieldFirmwareVersion = 'FirmwareVersion';
export const fieldSerialNumber = 'SerialNumber';
export const fieldTimeZone = 'TimeZone';
export const fieldMacAddress = 'MacAddress';
export const fieldIpAddress = 'IPAddress';
export const serverDisconnectedStatus = 'DISCONNECTED';
export const formName = 'serverDetailGeneralForm';
export const idBtnFindCamera = 'btnFindCamera';
export const idDiscoveredCameraFound = 'divDiscoveredCameraFound';
export const idSearchingDiscoveredCamera = 'divSearchingDiscoveredCamera';
export const idMessageComp = 'messageComponent';

// POE
export const fieldBudget = 'budget';
export const fieldUsage = 'usage';
export const fieldClassBudget = 'classbudget';
export const idRow = 'idRow';
export const idLabel = 'idLabel';
export const idPoEBar = 'idPoEBar';
export const idSummary = 'idSummary';
export const budget = 'Budget';
export const consumption = 'Consumption';
export const cameraPoEStatus = {
  DISCONNECTED: 'disconnected',
  DOWN: 'down',
  HIGHPOWERED: 'high-powered',
  POWERED: 'powered',
  UNPOWERED: 'unpowered',
  UP: 'up',
};

export const colors = {
  DOWN_COLOR: '#9E9E9E',
  UP_COLOR: '#75C64B',
};

export const cameraIconConstants = {
  HEIGHT: 10,
  WIDTH: 15,
  fillColors: '#575757',
};

export const DAYS_FIELD_ORDER = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DAYS_HEADER_TRANSLATION_IDS = {
  Friday: 'SCHEDULES.TABLE.WEEK_DAYS.FRIDAY_LABEL',
  Monday: 'SCHEDULES.TABLE.WEEK_DAYS.MONDAY_LABEL',
  Saturday: 'SCHEDULES.TABLE.WEEK_DAYS.SATURDAY_LABEL',
  Sunday: 'SCHEDULES.TABLE.WEEK_DAYS.SUNDAY_LABEL',
  Thursday: 'SCHEDULES.TABLE.WEEK_DAYS.THURSDAY_LABEL',
  Tuesday: 'SCHEDULES.TABLE.WEEK_DAYS.TUESDAY_LABEL',
  Wednesday: 'SCHEDULES.TABLE.WEEK_DAYS.WEDNESDAY_LABEL',
};

export const DAYS_DROPDOWN_TRANSLATION_IDS = {
  1: 'ONE_DAY_OPTION',
  10: 'TEN_DAYS_OPTION',
  14: 'FOURTEEN_DAYS_OPTION',
  2: 'TWO_DAYS_OPTION',
  30: 'THIRTY_DAYS_OPTION',
  5: 'FIVE_DAYS_OPTION',
  60: 'SIXTY_DAYS_OPTION',
  7: 'SEVEN_DAYS_OPTION',
  90: 'NINETY_DAYS_OPTION',
  MAX: 'MAX_DAYS_OPTION',
};

export const FIELD_ORDER = [
  'name',
  'ipAddress',
  'mfr',
  'model',
  'physicalAddress',
];

export const SUMMARY_FIELD_ORDER = [
  'name',
  'ipAddress',
  'model',
  'physicalAddress',
];

export const HEADER_TRANSLATION_IDS = {
  ipAddress: 'DEVICE_DETAILS.CAMERAS_TAB.IP_ADDRESS_HEADER_TABLE',
  mfr: 'DEVICE_DETAILS.CAMERAS_TAB.MANUFACTURER_HEADER_TABLE',
  model: 'DEVICE_DETAILS.CAMERAS_TAB.MODEL_HEADER_TABLE',
  name: 'DEVICE_DETAILS.CAMERAS_TAB.CAMERAS_TABLE_HEADER',
  physicalAddress: 'DEVICE_DETAILS.CAMERAS_TAB.MAC_ADDRESS_HEADER_TABLE',
};

export const CELL_WIDTHS = {
  // Actions: 80,
  // PhysicalAddress: 140,
  // IpAddress: 100,
  // Model: 132,
  ConnectionState: 120,
  // Name: 185,
};

export const POE_CELL_WIDTHS = { link: 50, port: 40, power: 360 };

export const CELL_ALIGNMENTS = {
  Actions: 'right',
};

export const CONNECTED_CAMERAS_FIELD_ORDER = [
  'Name',
  'ConnectionState',
  'ConnectionButton',
  'IpAddress',
  'Manufacturer',
  'Model',
  'PhysicalAddress',
];

export const CONNECTED_CAMERAS_SUMMARY_FIELD_ORDER = [
  'Name',
  'ConnectionState',
  'IpAddress',
  'PhysicalAddress',
];

export const CONNECTED_CAMERAS_CARD_FIELD_ORDER = [
  'Name',
  'ConnectionState',
  'ConnectionButton',
  'IpAddress',
  'Manufacturer',
  'Model',
  'PhysicalAddress',
];

export const CONNECTED_CAMERAS_HEADER_TRANSLATION_IDS = {
  Actions: 'DEV_DO_NOT_TRANSLATE.ACTIONS',
  ConnectionButton: 'DEV_DO_NOT_TRANSLATE.ACTIONS',
  ConnectionState: 'COMMON.STATUS',
  IpAddress: 'DEVICE_DETAILS.CAMERAS_TAB.IP_ADDRESS_HEADER_TABLE',
  Manufacturer: 'DEVICE_DETAILS.CAMERAS_TAB.MANUFACTURER_HEADER_TABLE',
  Model: 'DEVICE_DETAILS.CAMERAS_TAB.MODEL_HEADER_TABLE',
  Name: 'DEVICE_DETAILS.CAMERAS_TAB.CAMERAS_TABLE_HEADER',
  PhysicalAddress: 'DEVICE_DETAILS.CAMERAS_TAB.MAC_ADDRESS_HEADER_TABLE',
};

export const sendTypes = {
  ALL: 'ALL',
  NAME: 'NAME',
  NONE: 'NONE',
  TIME_ZONE: 'TIME_ZONE',
};

export const POWER_TOGGLE_RADIO_OPTIONS = [
  {
    translateId: 'SERVER.POE.DATA.POWER_OFF',
    value: 'off',
  },
  {
    translateId: 'SERVER.POE.DATA.POWER_AUTO',
    value: 'auto',
  },
  {
    translateId: 'SERVER.POE.DATA.POWER_MANUAL',
    value: 'manual',
  },
];

export const CAMERA_LOGIN_TYPES = {
  CAMERA_LOGIN: 'CAMERA_LOGIN',
  FDS_CAMERA_LOGIN: 'FDS_CAMERA_LOGIN',
  FDS_CAMERA_PASSWORD_RESET: 'FDS_CAMERA_PASSWORD_RESET',
};

export const regex = `(^[a-zA-Z]{4}\:\/{2}[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3})(.*)`;
export const feedbackSpinnerColor = '#006e99';
