/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UserActions from 'actions/user';
import { appContainer } from 'sharedStyles/styles.css';
import Masthead from 'containers/Login/Masthead';

const FullscreenVideoContainer = ({
  actions,
  children,
  isAuthenticated,
  userName,
}) => {
  const logout = () => {
    actions.logout();
  };

  return (
    <div className={appContainer}>
      <Masthead
        isAuthenticated={isAuthenticated}
        logout={logout}
        userName={userName}
      />
      <div style={{ height: '100vh' }}>{children}</div>
    </div>
  );
};

FullscreenVideoContainer.propTypes = {
  actions: PropTypes.shape({
    logout: PropTypes.func,
  }).isRequired,
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

FullscreenVideoContainer.defaultProps = {
  children: null,
  userName: '',
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    profile: state.user.profile,
    userName: state.user.userName,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullscreenVideoContainer);
