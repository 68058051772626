// Boolean flags
export const CAN_PROXY_AS_CUSTOMERS = 'CAN_PROXY_AS_CUSTOMERS';
export const CAN_VIEW_CUSTOMERS = 'CAN_VIEW_CUSTOMERS';
export const CAN_HAVE_DEALER_CUSTOMERS = 'CAN_HAVE_DEALER_CUSTOMERS';
export const CAN_CREATE_ORG = 'CAN_CREATE_ORG';
export const CAN_EDIT_ORG = 'CAN_EDIT_ORG';
export const CAN_EDIT_GENERAL_INFO = 'CAN_EDIT_GENERAL_INFO';
export const CAN_DELETE_ORG = 'CAN_DELETE_ORG';
export const CAN_VIEW_ORG_CONTROLS = 'CAN_VIEW_ORG_CONTROLS';
export const CAN_DELETE_SUSPENDED_DEVICE = 'CAN_DELETE_SUSPENDED_DEVICE';
export const CAN_VIEW_CONTACTS = 'CAN_VIEW_CONTACTS';
export const CAN_VIEW_SCHEDULES = 'CAN_VIEW_SCHEDULES';
export const CAN_VIEW_SITES = 'CAN_VIEW_SITES';
export const CAN_CREATE_SITE = 'CAN_CREATE_SITE';
export const CAN_EDIT_SITE = 'CAN_EDIT_SITE';
export const CAN_BULK_DELETE_SITES = 'CAN_BULK_DELETE_SITES';
export const CAN_SUSPEND_SITE = 'CAN_SUSPEND_SITE';
export const CAN_EDIT_SITE_BILLING_INFO = 'CAN_EDIT_SITE_BILLING_INFO';
export const CAN_DELETE_SITE = 'CAN_DELETE_SITE';
export const CAN_EDIT_SITE_ACCESS = 'CAN_EDIT_SITE_ACCESS';
export const CAN_EDIT_PROXY_SITE_ACCESS = 'CAN_EDIT_PROXY_SITE_ACCESS';
export const CAN_ENABLE_HEALTH_MONITORING = 'CAN_ENABLE_HEALTH_MONITORING';
export const CAN_EDIT_SUBSCRIPTION = 'CAN_EDIT_SUBSCRIPTION'; // User can assign/change a subscription for a site, but may be limited in choice of subscriptions
export const CAN_CHANGE_PROVIDER = 'CAN_CHANGE_PROVIDER';
export const CAN_GET_DEVICE_LOGS = 'CAN_GET_DEVICE_LOGS';
export const CAN_HAVE_NON_ADMIN_USERS = 'CAN_HAVE_NON_ADMIN_USERS';
export const CAN_VIEW_BILLING_ORDER_ID = 'CAN_VIEW_BILLING_ORDER_ID'; // Cloud Owner only can view Billing/Order ID in sites tables
export const CAN_EDIT_PTZ_PRESETS_AND_TOURS = 'CAN_EDIT_PTZ_PRESETS_AND_TOURS';
export const CAN_EDIT_SERVER_TIME_ZONE = 'CAN_EDIT_SERVER_TIME_ZONE';
export const CAN_VIEW_SCHEDULES_COLUMNS = 'CAN_VIEW_SCHEDULES_COLUMNS'; // Subscriber and Dealer should be able to view schedules in tables
export const CAN_EDIT_EMAIL_NOTIFICATION_PREFERENCES =
  'CAN_EDIT_EMAIL_NOTIFICATION_PREFERENCES';

// Enum values
export const SELF_SERVICE = 'SELF_SERVICE'; // 1, 2, 4
