import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

// Components
import { BackButtonWithTitle } from 'lib';

// Styles
import { customerDetailsTitle } from './dashboard.styles.css';

class CustomerDetailsTitle extends Component {
  pageTitle = () => {
    const { customer } = this.props;
    return (
      <Translate
        data={{
          name: customer.Name,
          tenantType: customer.Type,
        }}
        id="CUSTOMERS.CUSTOMERS_DETAIL.ORGANIZATION_HEADER_LABEL"
      />
    );
  };

  render() {
    const { onBackClick } = this.props;
    return (
      <BackButtonWithTitle
        className={customerDetailsTitle}
        onBackClick={onBackClick}
        title={this.pageTitle()}
      />
    );
  }
}

CustomerDetailsTitle.propTypes = {
  customer: PropTypes.objectOf(PropTypes.any),
  onBackClick: PropTypes.func,
};

CustomerDetailsTitle.defaultProps = {
  customer: {},
  onBackClick() {},
};

export default withLocalize(CustomerDetailsTitle);
