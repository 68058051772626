import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { APP_NAME } from 'constants/app';
import { textDescription } from './styles.css';

const RegisterText = ({isACC}) => (
  <>
    {isACC ? (
      <>
        <p key="signup1" className={textDescription}>
          <Translate id="SIGNUP.SIGNUP_ACC_TEXT" />
        </p>
        <p key="signup2" className={textDescription}>
          <Translate id="SIGNUP.SIGNUP_ACC_TEXT_2" />
        </p>
      </>
    ) : (
    <p className={textDescription}>
      <Translate id="SIGNUP.REGISTER_TEXT" data={{ appName: APP_NAME }} />
    </p>
    )}
  </>
);

RegisterText.defaultProps = {
  isACC: false,
};

RegisterText.propTypes = {
  isACC: PropTypes.bool,
};

export default RegisterText;
