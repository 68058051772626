/* global avoLogError */

import urlBuilder from 'queryBuilder/url';
import {
  CONNECTION_SUSBSCRIPTION_STATUS_CHANGE,
  IS_UPDATING_CONNECTION_SUBSCRIPTION,
  NOTIFICATION_CALLBACK,
  TRY_NOTIFICATION_CONNECTION,
} from 'constants/ActionTypes';
import {
  sendDeleteRequestReturningJSON,
  sendPostRequestReturningJSON,
} from 'util/fetchHelpers';

export function setConnectionSubscription(data) {
  const { id, isEstablished, orgId } = data;
  return {
    id,
    isEstablished,
    orgId,
    type: CONNECTION_SUSBSCRIPTION_STATUS_CHANGE,
  };
}

export function triedNotificationConnection(retryCount) {
  return {
    retryCount,
    type: TRY_NOTIFICATION_CONNECTION,
  };
}

export function isUpdatingConnectionSubscription(value) {
  return {
    type: IS_UPDATING_CONNECTION_SUBSCRIPTION,
    value,
  };
}

export function subscribeToNotifications(params) {
  return dispatch => {
    dispatch(isUpdatingConnectionSubscription(true));
    const url = urlBuilder(NOTIFICATION_CALLBACK);
    return sendPostRequestReturningJSON(url, params)
      .then(() => {
        dispatch(
          setConnectionSubscription({
            isEstablished: true,
            orgId: params.orgId,
          }),
        );
      })
      .catch(ex => {
        avoLogError('Error subscribing to notifications', ex);
      })
      .then(() => {
        dispatch(triedNotificationConnection());
        dispatch(isUpdatingConnectionSubscription(false));
      });
  };
}

export function unsubscribeToNotifications(connectionId, orgId) {
  return dispatch => {
    const url = urlBuilder(NOTIFICATION_CALLBACK, connectionId, null, null, {
      orgId,
    });
    dispatch(isUpdatingConnectionSubscription(true));
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(triedNotificationConnection(0));
      })
      .catch(ex => {
        avoLogError('Error unsubscribing from notifications', ex);
        dispatch(triedNotificationConnection());
      })
      .then(() => {
        dispatch(
          setConnectionSubscription({ isEstablished: false, orgId: false }),
        );
        dispatch(isUpdatingConnectionSubscription(false));
      });
  };
}
