import PropTypes from 'prop-types';
import React from 'react';

import { ObjectDetailTable } from 'components';
import OasisModule from './OasisModule';

const TenantModule = ({ profile, updateModuleState }) => {
  return (
    <>
      {profile.TenantId !== null ? (
        <OasisModule
          moduleId="oasis-tenant"
          theme="orange"
          title={`Logged in as: ${profile.TenantName}`}
          updateModuleState={updateModuleState}
        >
          <ObjectDetailTable
            bodyHeight={125}
            item={profile}
            resizable
            resizableFrom="top"
            sortType="local"
          />
        </OasisModule>
      ) : (
        ''
      )}
    </>
  );
};

TenantModule.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default TenantModule;
