import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';

// Actions
import * as deviceActions from 'actions/devices';

// Util
import {
  IconArrowIn,
  IconArrowOut,
  IconDigitalOutOff,
  IconDigitalOutOn,
} from 'icons';
import { generateSort } from 'util/generateSort';
import jsonParseRobust from 'util/jsonParseRobust';

// Icons
import { translatedConnect } from 'util/translatedConnect';

// Style
import {
  digitalOutBoxContainer,
  digitalOutBoxContainerHidden,
  digitalOutBox,
  digitalOutRow,
  digitalOutBoxText,
  // digitalOutToggleTextContainer,
  // digitalOutToggleContainer,
  digitalIO,
  digitalToggleWrapper,
} from './styles.css';

// Constants
import * as digitalOutConst from './constants';

class PlayerDigitalOutBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandDigitalToggle: true,
      digitalOffColor: digitalOutConst.OffDefaultColor,
      toggleFillColor: digitalOutConst.toggleOffDefaultColor,
      iconOnOrOff: digitalOutConst.toggleIcon.OFF,
      mouseInOrOut: digitalOutConst.mouseInOrOut.OUT,
      selectedEntityId: null,
    };
  }

  componentDidMount() {
    const { isFetchingEntity, deviceId, actions, cameraTenantId } = this.props;
    if (
      (isFetchingEntity === null || isFetchingEntity === undefined) &&
      deviceId
    ) {
      actions.getEntities(deviceId, cameraTenantId);
    }
  }

  handleTriggerClick = entityId => {
    this.props.actions.triggerDigitalOut(this.props.deviceId, entityId);
  };

  render() {
    const digitalOutToggles = [];
    this.props.entities.forEach(entity => {
      digitalOutToggles.push(
        <div
          title={entity.name}
          className={digitalToggleWrapper}
          key={entity.id}
          id={entity.id}
          onMouseEnter={() => {
            this.setState(
              {
                selectedEntityId: entity.id,
              },
              () => {
                if (
                  this.state.toggleFillColor !==
                  digitalOutConst.toggleOffMouseOverColor
                ) {
                  this.setState({
                    mouseInOrOut: digitalOutConst.mouseInOrOut.IN,
                    digitalOffColor: digitalOutConst.toggleOffMouseOverColor,
                  });
                }
              },
            );
          }}
          onMouseLeave={() => {
            this.setState({
              mouseInOrOut: digitalOutConst.mouseInOrOut.OUT,
              digitalOffColor: digitalOutConst.OffDefaultColor,
              selectedEntityId: null,
            });
          }}
          onMouseDown={() => {
            this.setState({
              digitalOffColor: digitalOutConst.OffDefaultColor,
              toggleFillColor: digitalOutConst.toggleOffDefaultColor,
              iconOnOrOff: digitalOutConst.toggleIcon.ON,
            });
          }}
          onMouseUp={() => {
            this.setState(
              {
                digitalOffColor:
                  this.state.mouseInOrOut === digitalOutConst.mouseInOrOut.IN
                    ? digitalOutConst.toggleOffMouseOverColor
                    : digitalOutConst.OffDefaultColor,
                toggleFillColor: digitalOutConst.toggleOffDefaultColor,
                iconOnOrOff: digitalOutConst.toggleIcon.OFF,
              },
              () => {
                this.props.disabled
                  ? () => {}
                  : this.handleTriggerClick(entity.id);
              },
            );
          }}
        >
          {this.state.iconOnOrOff === digitalOutConst.toggleIcon.OFF ||
          (this.state.iconOnOrOff === digitalOutConst.toggleIcon.ON &&
            this.state.selectedEntityId !== entity.id) ? (
            <IconDigitalOutOff
              fillColor={
                this.state.selectedEntityId === entity.id
                  ? this.state.digitalOffColor
                  : digitalOutConst.OffDefaultColor
              }
              toggleFillColor={
                this.state.selectedEntityId === entity.id
                  ? this.state.toggleFillColor
                  : digitalOutConst.toggleOffDefaultColor
              }
            />
          ) : this.state.iconOnOrOff === digitalOutConst.toggleIcon.ON &&
            this.state.selectedEntityId === entity.id ? (
            <IconDigitalOutOn />
          ) : null}
        </div>,
      );
    });

    return (
      <div>
        {this.props.entities.length > 0 ? (
          <div
            className={`${digitalOutBoxContainer} 
            ${
              this.props.showDigitalOutBox ? '' : digitalOutBoxContainerHidden
            }`}
          >
            <div className={digitalOutBox}>
              {this.props.showDigitalOutBox ? (
                <div className={`${digitalOutRow}`}>
                  <div className={`${digitalOutBoxText} ${digitalIO}`}>
                    <Translate id="SERVER.DIGITAL_OUT.HEADERS.DIGITAL_OUTPUT_CAMEL" />
                  </div>
                  {this.state.expandDigitalToggle
                    ? this.props.entities.length === 1
                      ? digitalOutToggles[0]
                      : ''
                    : digitalOutToggles}
                  {this.props.entities.length > 1 ? (
                    <div
                      className={digitalOutBoxText}
                      onClick={() => {
                        this.setState({
                          expandDigitalToggle: !this.state.expandDigitalToggle,
                        });
                      }}
                    >
                      {this.state.expandDigitalToggle ? (
                        <IconArrowOut />
                      ) : (
                        <IconArrowIn />
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

PlayerDigitalOutBox.propTypes = {
  showDigitalOutBox: PropTypes.bool,
  deviceId: PropTypes.string,
  cameraId: PropTypes.string,
  cameraRemoteId: PropTypes.string,
  cameraTenantId: PropTypes.string,
  isFetchingEntity: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
};

PlayerDigitalOutBox.defaultProps = {
  disabled: false,
};

function mapStateToProps(state, ownProps) {
  const entities = [];
  let isFetchingEntity;
  if (ownProps.deviceId && ownProps.cameraId && ownProps.cameraRemoteId) {
    const camera = state.devices.cameras.find(
      camera => camera.Id === ownProps.cameraId,
    );
    if (
      camera &&
      camera.Links &&
      state.devices.entities &&
      state.devices.entities[ownProps.deviceId]
    ) {
      jsonParseRobust(camera.Links).forEach(link => {
        if (link.type === digitalOutConst.cameraLinkTypes.DIGITAL_OUTPUT) {
          const entityForLink = state.devices.entities[ownProps.deviceId].find(
            entity => entity.id === link.source,
          );
          if (entityForLink) {
            entities.push(entityForLink);
          }
        }
      });

      // Sort entities by name so we can present toggles in meaningful order
      entities.sort(generateSort(item => item.name && item.name.toLowerCase()));

      isFetchingEntity =
        state.devices.isFetchingDeviceEntities[ownProps.deviceId];
    }
  }
  return {
    entities,
    isFetchingEntity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, deviceActions), dispatch),
  };
}

export default translatedConnect(mapStateToProps, mapDispatchToProps)(
  PlayerDigitalOutBox,
);

// TODO : MVAAS-9324 Clean if there are no chances of real toggle coming back
// digitalOut.forEach(element => {
//   digitalOutToggles.push(
//     <div
//       className={digitalToggleWrapper}
//       style={{ transform: 'rotate(90deg)' }}
//       key={element}
//     >
//       <Toggle
//         offText={''}
//         onText={''}
//         label={''}
//         labelClass={{}}
//         toggleTextContainerWidth={'23px'}
//         toggleTextContainerHeight={'16px'}
//         toggleContainerWidth={'23px'}
//         toggleContainerHeight={'16px'}
//         toggleTextContainerClass={digitalOutToggleTextContainer}
//         toggleContainerClass={digitalOutToggleContainer}
//         toggleCheckedLeft={'11px'}
//         toggleInputLabelHeight={'9px'}
//         toggleInputLabelWidth={'10px'}
//         toggleWraperbottomMargin={'5px'}
//         toggleUnCheckedLeft={'3px'}
//         resetAfterSet={true}
//         toggleTextMargin={'0px'}
//         onChange={() => {
//           this.setState({
//             testChecked: !this.state.testChecked,
//           });
//         }}
//         checked={this.state.testChecked}
//       />
//     </div>,
//   );
// });
