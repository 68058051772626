/* eslint-disable no-nested-ternary */
import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import { ActivityClass, alarmTypes } from 'constants/alarmSettings';
import itemUpsert from '../util/itemUpsert';
import alarmUrlParser from '../util/alarmUrlParser';

export default function alarmReducer(state, action = {}) {
  switch (action.type) {
    case types.IS_FETCHING_ALARM_CLIP_URL: {
      const clips = {
        ...state.clips,
        [action.data.Id]: { isFetching: action.value },
      };
      return { ...state, clips };
    }

    case types.RECEIVE_ALARM_CLIP_URL: {
      const clips = {
        ...state.clips,
        [action.data.Id]: { isFetching: false, uri: action.data.Uri },
      };
      return { ...state, clips };
    }

    case types.RECEIVE_ALARMS: {
      const alarmURLs = alarmUrlParser(action.nextLink);
      const isSecurityAlarm =
        action.alarmType === alarmTypes.securityAlarms || false;
      const isHealthAlarm =
        action.alarmType === alarmTypes.healthAlarms || false;

      // populate alarms based on alarmType provided
      const alarms = action.alarmType
        ? action.alarmType === alarmTypes.healthAlarms
          ? state.healthAlarms
          : state.securityAlarms
        : state.allAlarms;

      let newTotalAlarms = [];
      // If not introducing any new alarm, return state.allAlarms
      if (
        action.alarms.length > 0 &&
        action.alarms.filter(x => !alarms.includes(x)).length === 0
      ) {
        newTotalAlarms = alarms;
      } else if (
        action.alarmFetchDirection === types.ALARM_FETCH_DIRECTION_NEXT
      ) {
        newTotalAlarms = [...alarms, ...action.alarms];
      } else if (
        action.alarmFetchDirection === types.ALARM_FETCH_DIRECTION_PREV
      ) {
        newTotalAlarms = [...action.alarms, ...alarms];
      }
      return {
        ...state,
        alarmsCount: action.count,
        alarmsNextLink:
          !isHealthAlarm && !isSecurityAlarm
            ? alarmURLs.NextLink
            : state.alarmsNextLink,
        alarmsPrevLink:
          !isHealthAlarm && !isSecurityAlarm
            ? alarmURLs.PrevLink
            : state.alarmsPrevLink,
        allAlarms:
          !isHealthAlarm && !isSecurityAlarm ? newTotalAlarms : state.allAlarms,
        healthAlarms: isHealthAlarm ? newTotalAlarms : state.healthAlarms,
        healthAlarmsNextLink: isHealthAlarm
          ? alarmURLs.NextLink
          : state.healthAlarmsNextLink,
        healthAlarmsPrevLink: isHealthAlarm
          ? alarmURLs.PrevLink
          : state.healthAlarmsPrevLink,
        isFetchingAlarmData:
          !isHealthAlarm && !isSecurityAlarm
            ? false
            : state.isFetchingAlarmData,
        isFetchingHealthAlarmData: isHealthAlarm
          ? false
          : state.isFetchingHealthAlarmData,
        isFetchingSecurityAlarmData: isSecurityAlarm
          ? false
          : state.isFetchingSecurityAlarmData,
        securityAlarms: isSecurityAlarm ? newTotalAlarms : state.securityAlarms,
        securityAlarmsNextLink: isSecurityAlarm
          ? alarmURLs.NextLink
          : state.securityAlarmsNextLink,
        securityAlarmsPrevLink: isSecurityAlarm
          ? alarmURLs.PrevLink
          : state.securityAlarmsPrevLink,
      };
    }

    case types.IS_FETCHING_ALARMS_STATS: {
      return { ...state, isFetchingAlarmsStats: true };
    }

    case types.DONE_FETCHING_ALARMS_STATS: {
      return { ...state, isFetchingAlarmsStats: false };
    }

    case types.RECEIVE_ALARMS_STATS: {
      if (action.stats) {
        return {
          ...state,
          alarmsGraphStats: action.stats.Items ? action.stats.Items : [],
          alarmsSummaryStats: action.stats.Summary ? action.stats.Summary : {},
          isFetchingAlarmsStats: false,
        };
      }
      return state;
    }

    case types.ALARM_SELECTED: {
      return {
        ...state,
        isFetchingSelectedAlarm: false,
        selectedAlarm: { ...action.selectedAlarm },
      };
    }

    case types.EDIT_ALARM: {
      if (state.selectedAlarm && action.id === state.selectedAlarm.Id) {
        const selectedAlarm = {
          ...state.selectedAlarm,
          ...action.newAlarm,
        };
        return {
          ...state,
          selectedAlarm,
        };
      }
      return { ...state };
    }
    // update from push notification triggers fetch of new alarm entity
    // which needs to be added or merged w/ existing alarm
    case types.ALARM_UPDATE: {
      if (action.alarm && action.alarm.Id) {
        const newSelectedAlarm =
          state.selectedAlarm && action.alarm.Id === state.selectedAlarm.Id
            ? action.alarm
            : {};
        const selectedAlarm = {
          ...state.selectedAlarm,
          ...newSelectedAlarm,
        };

        let { allAlarms, healthAlarms, securityAlarms } = state;
        if (!allAlarms.find(x => x.ProxiedEntity_Id === action.alarm.Id)) {
          allAlarms = itemUpsert(allAlarms, action.alarm);
        }

        if (
          action.alarm.ActivityClass === ActivityClass.VideoReviewActivity ||
          action.alarm.ActivityClass === ActivityClass.DeviceTamperingActivity
        ) {
          if (
            !securityAlarms.find(x => x.ProxiedEntity_Id === action.alarm.Id)
          ) {
            securityAlarms = itemUpsert(securityAlarms, action.alarm);
          }
        } else if (
          !healthAlarms.find(x => x.ProxiedEntity_Id === action.alarm.Id)
        ) {
          healthAlarms = itemUpsert(healthAlarms, action.alarm);
        }

        return {
          ...state,
          allAlarms,
          healthAlarms,
          securityAlarms,
          selectedAlarm,
        };
      }
      return state;
    }

    case types.ALARM_MODAL_HIDE:
    case types.ALARM_MODAL_SHOW: {
      return { ...state, alarmModal: action.type };
    }

    case types.IS_FETCHING_ALARM_DATA: {
      return { ...state, isFetchingAlarmData: action.value };
    }

    case types.IS_FETCHING_HEALTH_ALARM_DATA: {
      return { ...state, isFetchingHealthAlarmData: action.value };
    }

    case types.IS_FETCHING_SECURITY_ALARM_DATA: {
      return { ...state, isFetchingSecurityAlarmData: action.value };
    }

    case types.COMPLETED_FETCHING_ALARM_DATA: {
      return { ...state, isFetchingAlarmData: false };
    }

    case types.IS_FETCHING_SELECTED_ALARM: {
      return { ...state, isFetchingSelectedAlarm: true };
    }
    case types.COMPLETED_FETCHING_SELECTED_ALARM: {
      return { ...state, isFetchingSelectedAlarm: true };
    }
    // clear cache when user logs out or changes org/customer
    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().alarms;
    }
    case types.CLEAR_ALARMS: {
      return {
        ...state,
        alarmsCount: 0,
        alarmsNextLink: null,
        alarmsPrevLink: null,
        allAlarms: !action.alarmType ? [] : state.allAlarms,
        healthAlarms:
          action.alarmType === alarmTypes.healthAlarms
            ? []
            : state.healthAlarms,
        securityAlarms:
          action.alarmType === alarmTypes.securityAlarms
            ? []
            : state.securityAlarms,
      };
    }
    case types.CLEAR_SELECTED_ALARM: {
      return { ...state, selectedAlarm: {} };
    }

    case types.SET_WIDGET_VISIBILITY: {
      return {
        ...state,
        widgetVisibility: {
          ...state.widgetVisibility,
          ...action.widgetVisibility,
        },
      };
    }

    default:
      return state || initialState().alarms;
  }
}
