exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".imgBusy___2rZoj {\r\n  margin: auto;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/BusyIndicator/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;CACd","file":"styles.css","sourcesContent":[".imgBusy {\r\n  margin: auto;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"imgBusy": "imgBusy___2rZoj"
};