import React from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';
import { AccordionItem, Button } from 'lib';
import OGRowActions from './OGRowActions';

import * as styles from '../OGTable.css';

const OGExpandedRow = ({
  cellWidths,
  content,
  idx,
  disabled,
  rowActions,
  rowData,
  setExpandedRow,
  theme,
  title,
  totalColumns,
}) => (
  <td colSpan={totalColumns}>
    <div className={styles.inlineDetails}>
      <AccordionItem
        canToggle={false}
        isExpanded
        leftItems={renderIf(rowActions)(
          <div className={styles.detailActions}>
            <OGRowActions
              cellWidths={cellWidths}
              disabled={disabled}
              expanded
              idx={idx}
              rowActions={rowActions}
              rowData={rowData}
            />
          </div>,
        )}
        rightItems={
          <Button
            buttonClass={styles.headerButton}
            compact
            inputType="button"
            onClick={() => {
              setExpandedRow(null);
            }}
          >
            <div className={styles.detailClose} />
          </Button>
        }
        theme={theme}
        title={title}
      >
        <div
          className={styles.inlineContentWrapper}
          style={{ backgroundColor: 'white' }}
        >
          {content}
        </div>
      </AccordionItem>
    </div>
  </td>
);

OGExpandedRow.defaultProps = {
  cellWidths: null,
  isRowDisabled: false,
  rowActions: [],
  title: '',
};

OGExpandedRow.propTypes = {
  cellWidths: PropTypes.objectOf(PropTypes.any),
  content: PropTypes.node.isRequired,
  idx: PropTypes.number.isRequired,
  isRowDisabled: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.any),
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  setExpandedRow: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['blue', 'detail']).isRequired,
  title: PropTypes.string,
  totalColumns: PropTypes.number.isRequired,
};

export default OGExpandedRow;
