// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

// Components
import { VerticalFormFieldLayout } from 'components';

// Utils
import {
  convertTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';

// Styles
import { sectionHeader } from 'components/VerticalForm/styles.css';

// Constants
import { BOOKMARK_DETAILS_FORM_FIELDS } from './constants';

const BookmarkDetailsForm = props => {
  const { initialValues, profile } = props;
  return (
    <div>
      <div className={`${sectionHeader}`}>
        {<Translate id="BOOKMARKS.BOOKMARK_CREATED.DETAILS" />}
      </div>
      <div>
        {BOOKMARK_DETAILS_FORM_FIELDS.map(fieldConfig => (
          <VerticalFormFieldLayout
            key={fieldConfig.name}
            label={fieldConfig.label}
            readOnly
          >
            {fieldConfig.type === 'time'
              ? convertTimeTo(
                  initialValues[fieldConfig.name],
                  TIME_TYPES.LOCAL,
                  profile.TimeZone,
                  getPreferredLongDateTimeFormat(
                    profile.LocalizationPreference.PreferredLongDateFormat,
                    profile.LocalizationPreference.PreferredTimeFormat,
                  ) || getFormatForActiveLocale(),
                )
              : initialValues[fieldConfig.name]}
          </VerticalFormFieldLayout>
        ))}
      </div>
    </div>
  );
};

BookmarkDetailsForm.defaultProps = {
  profile: { LocalizationPreference: {}, TimeZone: '' },
};

BookmarkDetailsForm.propTypes = {
  initialValues: PropTypes.shape({
    creationTime: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    isProtected: PropTypes.bool.isRequired,
    locationName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
  }).isRequired,
  profile: PropTypes.shape({
    LocalizationPreference: PropTypes.object,
    TimeZone: PropTypes.string,
  }),
};

export default BookmarkDetailsForm;
