import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import { Button } from 'lib';
import { ModalContainer } from 'containers';
import { PATH_LOGIN } from 'constants/urlPaths';
import { buttonContainer, modalContentConfirm, modalText } from './styles.css';

const registrationSuccessModal = ({
  header,
  hideModal,
  modalText: modalTextProp,
}) => (
  <ModalContainer
    cancelVisible={false}
    handleCancel={hideModal}
    modalTitle={<Translate id={header} />}
  >
    <div className={modalContentConfirm}>
      <div className={modalText}>
        <Translate id={modalTextProp} />
      </div>
      <div className={buttonContainer}>
        <Link onClick={hideModal} to={PATH_LOGIN}>
          <Button
            onClick={() => {}}
            text={<Translate id="BUTTONS.SIGN_IN" />}
          />
        </Link>
      </div>
    </div>
  </ModalContainer>
);

registrationSuccessModal.defaultProps = {
  header: 'SIGNUP.REGISTRATION_SUCCESS_HEADER',
  hideModal() {},
  modalText: 'SIGNUP.REGISTRATION_SUCCESS_TEXT',
};

registrationSuccessModal.propTypes = {
  header: PropTypes.string,
  hideModal: PropTypes.func,
  modalText: PropTypes.string,
};

export default registrationSuccessModal;
