// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dimensions from 'react-dimensions';
import get from 'lodash.get';
import { withLocalize } from 'react-localize-redux';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Utils
import { calculateVideoDimensions } from 'util/calculateVideoDimensions';

// Actions
import * as SettingsActions from 'actions/settings';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';
import * as OverlayActions from 'actions/streamOverlays';
import { hideModal, showModal } from 'actions/modal';

// Components
import {
  CameraSettingsAudio,
  CameraSettingsTampering,
  PageMessage,
} from 'containers';
import {
  CameraSettingsDigitalOut,
  CameraSettingsGeneral,
  CameraSettingsPrivacy,
  CameraSettingsRules,
  CameraSettingsVideo,
  PlayerHost,
} from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';

// Constants
import {
  canvasObjectTypes,
  LIST_NAV_TITLE_HEIGHT,
  PROXY_ORGANIZATION_DROPDOWN_HEIGHT,
  SECONDRY_NAV_TABS_HEIGHT,
} from 'constants/app';
import {
  PATH_CAMERAS,
  PATH_DEVICES,
  PATH_SEGMENT_AUDIO,
  PATH_SEGMENT_DIGITAL_IO,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_PRIVACY,
  PATH_SEGMENT_RULES,
  PATH_SEGMENT_TAMPERING,
  PATH_SEGMENT_VIDEO,
} from 'constants/urlPaths';
import * as messageTypes from 'constants/MessageTypes';
import * as modalTypes from 'constants/ModalTypes';
import { DEVICE_SETTINGS } from 'constants/deviceSettings';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import { calcRuleId, newPlaceholder } from 'constants/cameraSettings';
import { activityOptions } from 'components/CameraSettings/CameraRuleConstants';
import { cameraSupportsPtz } from 'util/cameraSettingPermission';
import { analyticsReduxToWep } from 'util/streamOverlaysHelper';

// Styles
import {
  tabContent,
  tabContentVerticalSeperator,
} from 'sharedStyles/global.css';

// Utils
import { CAMERA_PTZ_OBJECT } from 'util/cameraSettingValues';
import { findCamera } from 'util/normalizeCamera';
import { isAnalyticCamera } from 'util/isAnalyticCamera';

import {
  cloneDeep,
  getDigitalOutLinksFromCamera,
  listCamerasFromSameServer,
  setCamerasLinkFlag,
} from 'util/cameraSettingLinks';
import CameraSettingsNavMenu from './CameraSettingsNavMenu';
import { mediaPlayer, settingsContent } from './styles.css';
import CameraMonitoredAlert from './CameraMonitoredAlert';

const TABS = DEVICE_SETTINGS.GENERIC.LABELS;

const TABS_VISIBLE = [
  {
    id: TABS.VIDEO,
    pathSegment: PATH_SEGMENT_VIDEO,
    translationId: 'CAMERA.SETTINGS.TABS.VIDEO_LABEL',
  },
  {
    id: TABS.AUDIO,
    pathSegment: PATH_SEGMENT_AUDIO,
    translationId: 'CAMERA.SETTINGS.TABS.AUDIO_LABEL',
  },
  {
    id: TABS.DIGITAL_OUT,
    pathSegment: PATH_SEGMENT_DIGITAL_IO,
    translationId: 'CAMERA.SETTINGS.TABS.DIGITAL_OUT_LABEL',
  },
  {
    id: TABS.RULES,
    pathSegment: PATH_SEGMENT_RULES,
    translationId: 'CAMERA.SETTINGS.TABS.RULES_LABEL',
  },
  {
    id: TABS.PRIVACY,
    pathSegment: PATH_SEGMENT_PRIVACY,
    translationId: 'CAMERA.SETTINGS.TABS.PRIVACY_LABEL',
  },
  {
    id: TABS.TAMPERING,
    pathSegment: PATH_SEGMENT_TAMPERING,
    translationId: 'CAMERA.SETTINGS.TABS.TAMPERING_LABEL',
  },
  {
    id: TABS.GENERAL,
    pathSegment: PATH_SEGMENT_GENERAL,
    translationId: 'CAMERA.SETTINGS.TABS.GENERAL_LABEL',
  },
];

class CameraSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acquisition: null,
      isBusy: false,
      ptzArrow: CAMERA_PTZ_OBJECT,
    };
    this.mediaParamsId = `cs_${Math.floor(
      (1 + Math.random()) * 0x10000,
    ).toString(16)}`;
  }

  componentDidMount() {
    const { actions, isFetchingLocations, match } = this.props;
    actions.getAllDevices();
    if (isFetchingLocations === null) {
      actions.getLocations();
    }
    const cameraId = match.params.cid;
    actions.setSelectedCamera(cameraId);
  }

  componentDidUpdate() {
    const {
      actions,
      camera,
      cameraId,
      containerHeight,
      history,
      isFetchingAllCameras,
      isFetchingCameraSettings,
      isFetchingCameraSettingsHasError,
      isFetchingImageSettings,
      isFetchingPrivacyZones,
      isFetchingRules,
      selectedCamera,
      settings,
      updateDimensions,
    } = this.props;
    const { acquisition } = this.state;
    if (containerHeight === 0) {
      updateDimensions();
    }

    if (
      cameraId &&
      isFetchingAllCameras === null &&
      (!camera || !camera.Name)
    ) {
      actions.getAllCameras();
    }

    if (camera && camera.DeviceId && camera.Id) {
      const { DeviceId, Id, RemoteId } = camera;
      if (isFetchingCameraSettings === null) {
        actions.getAllCameraSettings(DeviceId, RemoteId, Id);
      } else if (
        isFetchingCameraSettingsHasError &&
        isFetchingCameraSettingsHasError !== undefined
      ) {
        // Fall back on individual gets if the 'ALL' param for camera-settings fails
        if (isFetchingPrivacyZones === null) {
          actions.getGatewayGeneralSettings(DeviceId, RemoteId, Id);
        }

        if (isFetchingRules === null) {
          actions.getRules(DeviceId, RemoteId, Id);
        }
        if (isFetchingImageSettings === null) {
          actions.getCompressionSettings(DeviceId, RemoteId, Id);
          actions.getAcquisitionSettings(DeviceId, RemoteId, Id);
        }
      }
    }

    if (settings && settings.acquisition && !acquisition) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ acquisition: settings.acquisition });
    }

    if (
      !selectedCamera ||
      (isFetchingAllCameras === false && (!camera || !camera.Name))
    ) {
      history.push(PATH_DEVICES);
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.setSelectedCamera(null);
    actions.unSetServerBreadcrumb();
  }

  get analyticsEnabled() {
    const { camera, settings } = this.props;
    return settings.remoteAnalyticsEnabled || isAnalyticCamera(camera);
  }

  get selectedTab() {
    const { location } = this.props;
    const pathArray = location.pathname.split('/');
    if (pathArray.length === 0) return '';
    const pathSegment = `/${pathArray[pathArray.length - 1]}`;
    const selectedTab = TABS_VISIBLE.find(
      tab => tab.pathSegment === pathSegment,
    );
    return selectedTab ? selectedTab.id : TABS.GENERAL;
  }

  restoreDefaultVideoSettings = () => {
    if (this.refs.canvas) {
      this.refs.canvas.reset();
    }
  };

  onCancel = selectedTab => {
    const { actions, camera } = this.props;
    switch (selectedTab) {
      case TABS.RULES: {
        actions.resetAllRules(camera.Id);
        break;
      }
      case TABS.PRIVACY: {
        actions.resetAllPrivacyZones(camera.Id);
        break;
      }
      default:
    }
  };

  /**
   * Canvas availability decider:
   *
   * Be careful about changing the criterias here and make sure changes are deep tested.
   * PRIVACY: Does not depend on analytics rules. Canvas must be available for all
   * RULES: Depend on analyticsEnabled
   * OTHER: must be able to display canvas if PTZ is enabled
   *
   * Returns: Bool value. If passed in the criteria, we will say yes/no about canvasAvailable in
   *          to the drawing container or not. Drawing Container aka <Canvas/> will then show/hide
   *          div id === 'DrawingContainer' if showCanvas === true.
   *
   * TODO: MVAAS-17812 will use canvasType to make show/hide decisions within PlayerHost
   */
  isCanvasAvailable = () => {
    const { doesCameraSupportPtz } = this.props;
    const { selectedTab } = this;
    switch (selectedTab) {
      case TABS.PRIVACY: {
        return true;
      }
      case TABS.RULES: {
        return this.analyticsEnabled;
      }
      default: {
        if (!doesCameraSupportPtz) return false;
        return true;
      }
    }
  };

  getCameraProps = (playerWidth, playerHeight) => {
    const {
      camera,
      cameraDeviceLocation,
      deviceName,
      doesCameraSupportPtz,
      rules,
    } = this.props;
    const { ptzArrow } = this.state;
    let canvasItems = ptzArrow;
    if (this.selectedTab === TABS.RULES && rules) {
      canvasItems = Object.values(rules).concat(ptzArrow);
    }
    return {
      autofocus: this.autofocus,
      camera,
      cameraId: camera.Id,
      cameraName: camera.Name,
      cameraRemoteId: camera.RemoteId,
      cameraResHeight: camera.DefaultHeight,
      cameraResWidth: camera.DefaultWidth,
      cameraSupportsPtz: doesCameraSupportPtz,
      canEditROI: true,
      canvasItems,
      canvasType: this.selectedTab,
      controlsDisabled:
        this.selectedTab === TABS.PRIVACY ||
        (this.selectedTab === TABS.RULES && this.selectedRule()),
      deviceId: camera.DeviceId,
      deviceName,
      height: playerHeight,
      hideFocusButton: true,
      hideOverlayToggle: true,
      hidePtzArrow: this.selectedTab === TABS.PRIVACY,
      hidePtzFunctionMenu: this.selectedTab === TABS.PRIVACY,
      id: 'cameraSettings',
      isDisconnected: camera.ConnectionState !== CAMERA_CONNECTED,
      mediaParamsId: this.mediaParamsId,
      restoreDefaults: this.restoreDefaultVideoSettings,
      selectCanvasItem:
        this.selectedTab === TABS.RULES
          ? this.selectRule
          : this.selectPrivacyZone,
      showCanvas: this.isCanvasAvailable(),
      siteName: cameraDeviceLocation,
      updateCanvasItems:
        this.selectedTab === TABS.RULES
          ? this.updateRuleBoundary
          : this.updatePrivacyList,
      width: playerWidth,
    };
  };

  // PRIVACY

  selectedPrivacyZone = () => {
    const { privacyZones } = this.props;
    if (privacyZones) {
      const selectedZone = privacyZones.find(zone => zone.isSelected === true);
      if (selectedZone) return selectedZone;
    }
    return {};
  };

  selectPrivacyZone = id => {
    const { actions, camera, privacyZones } = this.props;
    if (!privacyZones) {
      return;
    }
    const oldSelectedZone = this.selectedPrivacyZone();
    const deselectedZone = { ...oldSelectedZone, isSelected: false };
    const newSelectedZone = privacyZones.find(item => item.id === id);
    const selectedZone = { ...newSelectedZone, isSelected: true };
    actions.setPrivacyZone(camera.Id, selectedZone.id, selectedZone);
    actions.setPrivacyZone(camera.Id, deselectedZone.id, deselectedZone);
  };

  addPrivacyZone = () => {
    const { actions, camera } = this.props;
    const oldSelectedZone = this.selectedPrivacyZone();
    const deselectedZone = { ...oldSelectedZone, isSelected: false };
    actions.setPrivacyZone(camera.Id, deselectedZone.id, deselectedZone);
    actions.addPrivacyZone(camera.Id, true);
  };

  deletePrivacyZone = zoneId => {
    const { actions, camera } = this.props;
    actions.setPrivacyZone(camera.Id, zoneId);
  };

  updatePrivacyZones = () => {
    const { actions, camera, privacyZoneId, privacyZones } = this.props;
    const zones = [];
    if (privacyZones) {
      privacyZones.forEach(element => {
        const elementRect = {
          rect: {
            bottom: element.privacyZoneDimensions.bottom,
            left: element.privacyZoneDimensions.left,
            right: element.privacyZoneDimensions.right,
            top: element.privacyZoneDimensions.top,
          },
        };
        zones.push(elementRect);
      }, this);
    }

    const formData = {
      id: camera.RemoteId,
      settings: {
        general: {
          privacyZones: {
            id: privacyZoneId,
            val: {
              privacyZone: zones,
            },
          },
        },
      },
    };

    actions.editGatewayGeneralSettings(
      camera.DeviceId,
      camera.RemoteId,
      camera.Id,
      formData,
    );
  };

  // RULES AND ROIS

  selectedRule = () => {
    const { rules } = this.props;
    if (rules) {
      const selectedRuleIndex = Object.values(rules).findIndex(
        r => r.isSelected === true,
      );
      const selectedRule = {
        ...Object.values(rules)[selectedRuleIndex],
        id: parseInt(Object.keys(rules)[selectedRuleIndex]),
      };
      if (selectedRuleIndex > -1) return selectedRule;
    }
    return null;
  };

  selectRule = (id, enablingDisabling) => {
    // TODO (tech debt): Make select rule a redux action to dry this out
    const { actions, camera, rules } = this.props;
    if (!rules) return;
    const oldSelectedRule = this.selectedRule();
    if (oldSelectedRule) {
      if (oldSelectedRule.id === id) return;
      const deselectedRule = { ...oldSelectedRule, isSelected: false };
      actions.setRule(camera.Id, deselectedRule.id, deselectedRule);
    }
    const newSelectedRule = rules[id];
    if (newSelectedRule) {
      const selectedRule = {
        ...newSelectedRule,
        isSelected: !enablingDisabling,
      };
      actions.setRule(camera.Id, id, selectedRule);
    }
  };

  toggleRuleVisibility = id => {
    const { actions, camera, rules } = this.props;
    if (!rules) return;
    const visibleRule = rules[id];
    if (visibleRule) {
      actions.setRule(camera.Id, id, {
        ...visibleRule,
        isVisible: !visibleRule.isVisible,
      });
    }
  };

  onDeleteRule = id => {
    const { actions, rules, translate } = this.props;
    const title = translate('CAMERA.SETTINGS.RULES.DELETE_RULE');
    const activeRule = rules[id];
    const ruleName = activeRule.event ? activeRule.event.name : '';
    const message =
      ruleName === ''
        ? translate('CAMERA.SETTINGS.RULES.DELETE_RULE_UNNAMED_CONFIRM')
        : translate('CAMERA.SETTINGS.RULES.DELETE_RULE_CONFIRM', {
            ruleName,
          });
    const onOkClick = () => {
      this.deleteRule(id);
      actions.hideModal();
    };
    const modalProps = {
      handleCancel: () => {
        actions.hideModal();
      },
      message,
      onOkClick,
      textCancel: 'BUTTONS.CANCEL',
      textConfirm: 'BUTTONS.CONFIRM',
      title,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  deleteRule = deleteId => {
    const { actions, camera, rulesFromServer } = this.props;
    const selectedRule = this.selectedRule();
    const id = deleteId || selectedRule.id;
    if (id) {
      actions.deleteRule(camera.Id, id);
      const isRuleOnServer = rulesFromServer[id];
      if (isRuleOnServer) {
        const newRules = {};
        Object.keys(rulesFromServer).forEach(key => {
          if (key !== `${id}`) {
            newRules[key] = rulesFromServer[key];
          }
        });
        this.saveRules(newRules);
      }
    }
  };

  addRule = () => {
    const { actions, camera, rules } = this.props;
    const ruleId = calcRuleId(rules);
    actions.addRule(camera.Id, ruleId);
    this.selectRule(ruleId);
  };

  handleChangeActivityType = type => {
    const { actions, camera } = this.props;
    const rule = this.selectedRule();
    if (!rule) return;
    let nextRule;
    const loiActivities = activityOptions
      .filter(op => op.boundary === canvasObjectTypes.loi)
      .map(op => op.value);
    if (loiActivities.includes(type) && rule.loi === null) {
      nextRule = {
        ...rule,
        event: { ...rule.event, activityType: type },
        loi: newPlaceholder,
        roi: null,
      };
    } else if (rule.roi === null) {
      nextRule = {
        ...rule,
        event: { ...rule.event, activityType: type },
        loi: null,
        roi: newPlaceholder,
      };
    } else {
      nextRule = {
        ...rule,
        event: {
          ...rule.event,
          activityType: type,
        },
      };
    }
    if (nextRule) {
      actions.setRule(camera.Id, rule.id, nextRule);
    }
  };

  handleChangeDirectionality = () => {
    const rule = this.selectedRule();
    if (!rule || !rule.loi) return;
    const newLoi = { ...rule.loi };
    newLoi.directional = !newLoi.directional;
    newLoi.type = canvasObjectTypes.loi;
    this.updateRuleBoundary([newLoi]);
  };

  handleProhibitedDirection = newDirectionAngle => {
    const rule = this.selectedRule();
    if (!rule || !rule.roi) return;
    const newRoi = { ...rule.roi };
    newRoi.directionAngle = newDirectionAngle * 360;
    newRoi.type = canvasObjectTypes.roi;
    this.updateRuleBoundary([newRoi]);
  };

  updateRuleBoundary = canvasItems => {
    // canvasItems look like rule.loi or rule.roi but have an additional 'type' field
    const { actions, camera } = this.props;
    const rule = this.selectedRule();
    const { id } = rule;
    const updatedTarget = canvasItems.find(t => t.id === id);
    if (rule && updatedTarget) {
      const nextRule = {
        ...rule,
        loi: null,
        roi: null,
        [updatedTarget.type]: updatedTarget,
      };
      actions.setRule(camera.Id, rule.id, nextRule);
    }
  };

  updateEvent = (id, ruleData) => {
    const { actions, camera } = this.props;
    actions.setRule(camera.Id, id, ruleData);
  };

  saveRules = newRules => {
    const {
      actions,
      beamCount,
      beamId,
      camera,
      eventId,
      roiCount,
      roiId,
      rules: propRules,
    } = this.props;
    const rules = newRules || propRules; // newRules arg is only necessary for delete
    const data = {
      id: camera.RemoteId,
      settings: analyticsReduxToWep({
        beamId,
        eventId,
        roiId,
        rules,
      }),
    };
    const rois = get(data, 'settings.analytics.rois.val', []);
    const lois = get(data, 'settings.analytics.beams.val.lois', []);
    const ruleAdd = roiCount < rois.length || beamCount < lois.length;
    actions.updateCameraSettings(
      camera.DeviceId,
      camera.RemoteId,
      camera.Id,
      data,
      ruleAdd,
    );
  };

  updateCameraDigitalOutSettings = (digitalOut, links) => {
    const { actions, camera, digitalOutput, entities } = this.props;
    let settings;
    // if settings hasn't change and only links, send false to action
    // to skip update api request
    if (digitalOutput !== digitalOut) {
      settings = {
        id: camera.RemoteId,
        settings: {
          digital_output: digitalOut,
        },
      };
    }
    const linkedCamerasToUpdate = links.filter(
      cam => cam.isLinked !== undefined,
    );
    const entity = entities.find(
      ent => ent.serial === camera.Serial && ent.type === 'DIGITAL_OUTPUT',
    );
    actions.updateCameraSettingsAndLinks(
      camera,
      settings,
      linkedCamerasToUpdate,
      entity.id,
    );
  };

  updateCameraGeneralSettings = generalUpdate => {
    const { actions, camera, general } = this.props;
    const gKeys = Object.keys(generalUpdate);
    const generalNew = {};
    let newItem;
    Object.keys(general).forEach(key => {
      newItem = general[key];
      if (gKeys.includes(key)) {
        newItem.val = generalUpdate[key];
        generalNew[key] = newItem;
      }
    });

    const data = {
      id: camera.RemoteId,
      settings: {
        general: generalNew,
      },
    };

    actions.updateCameraSettings(
      camera.DeviceId,
      camera.RemoteId,
      camera.Id,
      data,
    );
  };

  updateCameraVideoSettings = compression => {
    const { actions, camera, settings } = this.props;
    const { acquisition } = this.state;
    const cKeys = Object.keys(compression);
    const compressionNew = {};
    let newItem;
    if (settings.compression) {
      Object.keys(settings.compression).forEach(key => {
        newItem = settings.compression[key];
        if (cKeys.includes(key)) {
          if (!this.hasInvalidKeys(key, compression)) {
            newItem.val = compression[key].val;
            compressionNew[key] = newItem;
          }
        }
      });
    }

    const data = {
      id: camera.RemoteId,
      settings: {
        acquisition,
        compression: compressionNew,
      },
    };

    actions.updateCameraSettings(
      camera.DeviceId,
      camera.RemoteId,
      camera.Id,
      data,
    );
  };

  autofocus = () => {
    const { actions, camera } = this.props;
    actions.cameraAutofocusGateway(camera.DeviceId, camera.RemoteId);
  };

  updateAcquisitionField = (val, field) => {
    const { acquisition } = this.state;
    const newAcquisition = {
      ...acquisition,
      [field]: { ...acquisition[field], val },
    };
    this.setState({ acquisition: newAcquisition });
  };

  cameraSettingsVideo = () => {
    const { actions, camera, cameraData, settings } = this.props;
    const { acquisition } = this.state;
    return (
      <CameraSettingsVideo
        acquisition={acquisition}
        camera={camera}
        cameraData={cameraData}
        onAutofocus={this.autofocus}
        onFocusUpdate={step => {
          actions.cameraFocus(camera.DeviceId, camera.RemoteId, step);
        }}
        onSave={this.updateCameraVideoSettings}
        onZoomUpdate={step => {
          actions.cameraZoom(camera.DeviceId, camera.RemoteId, step);
        }}
        settings={settings}
        updateAcquisitionField={this.updateAcquisitionField}
      />
    );
  };

  hasInvalidKeys(currentKey, compression) {
    return (
      (currentKey === 'actualFrameRate' ||
        currentKey === 'customRecordingProfileActualFrameRate') &&
      compression[currentKey].val === 0
    );
    /* actualFrameRate with value as zero always gets rejected and throws an error
     * while updating camera video settings and especially in case of changes in
     * image rotation.Hence avoiding it to be added. Web endpoint changes required.
     */
  }

  render() {
    const {
      actions,
      camera,
      cameraDeviceLocation,
      canChangeCustomer,
      clusterId,
      containerHeight,
      containerWidth,
      digitalOutput,
      digitalOutputAvailableCameras,
      doesCameraSupportPtz,
      entities,
      eventId,
      general,
      isFetchingAllCameras,
      isFetchingCameraEntities,
      isFetchingCameraSettings,
      isFetchingDeviceEntities,
      isFetchingImageSettings,
      isFetchingPrivacyZones,
      isFetchingRules,
      isSavingCameraSettings,
      links,
      location,
      privacyZoneId,
      privacyZones,
      rules,
      settings,
    } = this.props;
    const { isBusy } = this.state;
    const { playerHeight, playerWidth, tabWidth } = calculateVideoDimensions(
      containerHeight,
      containerWidth,
      canChangeCustomer
        ? PROXY_ORGANIZATION_DROPDOWN_HEIGHT +
            LIST_NAV_TITLE_HEIGHT +
            SECONDRY_NAV_TABS_HEIGHT
        : LIST_NAV_TITLE_HEIGHT + SECONDRY_NAV_TABS_HEIGHT,
    );
    const mediaPlayerProperties = {
      height: playerHeight,
      width: playerWidth,
    };

    const backPath =
      location.state && location.state.prevPath
        ? location.state.prevPath
        : PATH_DEVICES;

    const titleObj = {
      backPath,
      showBackButton: true,
      titleData: { cameraName: camera.Name },
      titleId: 'CAMERA.CAMERA_TITLE',
    };

    return (
      <>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          navigationTabs={<CameraSettingsNavMenu camera={camera} />}
          pageTitle={<PageTitle {...titleObj} />}
        />
        <MainContentWrapper>
          <PageMessage
            messageType={[
              messageTypes.GATEWAY_ERROR,
              messageTypes.GATEWAY_SUCCESS,
              messageTypes.CAMERA_ENABLE_FEATURE_ERROR,
              messageTypes.CAMERA_DISABLE_FEATURE_ERROR,
              messageTypes.CAMERA_ENABLE_FEATURE_SUCCESS,
              messageTypes.CAMERA_DISABLE_FEATURE_SUCCESS,
            ]}
          />
          <div
            className={settingsContent}
            style={{
              height:
                playerHeight > 0 ? `${playerHeight}px` : `calc(100vh - 233px)`,
            }}
          >
            <div
              className={tabContent}
              style={{
                height: `${playerHeight}px`,
                width: `${tabWidth}px`,
              }}
            >
              <CameraMonitoredAlert camera={camera} tabWidth={tabWidth} />
              <Switch>
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_VIDEO}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={
                        isFetchingImageSettings || isFetchingCameraSettings
                      }
                      items={
                        settings &&
                        (settings.compression || settings.acquisition)
                          ? [1]
                          : []
                      }
                      showDataWhileFetching
                      translateKey="ERROR_MESSAGES.GATEWAY_CONNECT_ERROR"
                    >
                      {this.cameraSettingsVideo()}
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_PRIVACY}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={
                        isFetchingPrivacyZones || isFetchingCameraSettings
                      }
                      items={privacyZoneId ? [1] : []}
                      translateKey={
                        settings && settings.general
                          ? 'ERROR_MESSAGES.PRIVACY_RETURN_ERROR'
                          : 'ERROR_MESSAGES.GATEWAY_CONNECT_ERROR'
                      }
                    >
                      <CameraSettingsPrivacy
                        addPrivacyZone={this.addPrivacyZone}
                        cameraSupportsPtz={doesCameraSupportPtz}
                        deletePrivacyZone={this.deletePrivacyZone}
                        handleCancel={this.onCancel.bind(this, TABS.PRIVACY)}
                        isFetchingPrivacyZones={
                          isFetchingPrivacyZones || isFetchingCameraSettings
                        }
                        privacyZones={privacyZones}
                        selectPrivacyZone={this.selectPrivacyZone}
                        updatePrivacyZones={this.updatePrivacyZones}
                      />
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_DIGITAL_IO}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={
                        isFetchingAllCameras ||
                        isFetchingCameraSettings ||
                        isFetchingRules ||
                        isFetchingDeviceEntities
                      }
                      items={camera ? [1] : []}
                      translateKey="ERROR_MESSAGES.GATEWAY_CONNECT_ERROR"
                    >
                      <CameraSettingsDigitalOut
                        camera={camera}
                        cameraDeviceLocation={cameraDeviceLocation}
                        capabilities={camera && camera.Capabilities}
                        digitalOutput={digitalOutput}
                        digitalOutputAvailableCameras={
                          digitalOutputAvailableCameras
                        }
                        entities={entities}
                        fetchEntities={actions.getEntities}
                        general={general}
                        isFetchingCameraEntities={isFetchingCameraEntities}
                        isSaving={isSavingCameraSettings}
                        links={links}
                        onSave={this.updateCameraDigitalOutSettings}
                      />
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_RULES}`}
                  render={() => (
                    <CameraSettingsRules
                      key={rules && rules.eventId}
                      addRule={this.addRule}
                      camera={camera}
                      cameraSupportsPtz={doesCameraSupportPtz}
                      clusterId={clusterId}
                      deleteRule={this.onDeleteRule}
                      handleCancel={this.onCancel.bind(this, TABS.RULES)}
                      handleProhibitedDirectionChange={
                        this.handleChangeProhibitedDirection
                      }
                      isFetching={isFetchingRules || isBusy}
                      onChangeActivityType={this.handleChangeActivityType}
                      onChangeDirectionality={this.handleChangeDirectionality}
                      onChangeProhibitedDirection={
                        this.handleProhibitedDirection
                      }
                      refreshRuleData={actions.getRules}
                      ruleId={eventId}
                      rules={rules}
                      saveRule={this.saveRules}
                      selectRule={this.selectRule}
                      settings={settings}
                      toggleRule={this.toggleRuleVisibility}
                      updateRule={this.updateEvent}
                    />
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_TAMPERING}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={
                        isFetchingAllCameras ||
                        isFetchingCameraSettings ||
                        isFetchingRules
                      }
                      items={camera ? [1] : []}
                      translateKey="ERROR_MESSAGES.GATEWAY_CONNECT_ERROR"
                    >
                      <CameraSettingsTampering
                        camera={camera}
                        settings={settings}
                      />
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_GENERAL}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={isFetchingImageSettings}
                      items={settings && general ? [1] : []}
                      translateKey="ERROR_MESSAGES.GATEWAY_CONNECT_ERROR"
                    >
                      <CameraSettingsGeneral
                        camera={camera}
                        cameraDeviceLocation={cameraDeviceLocation}
                        capabilities={
                          camera && camera.Capabilities && camera.Capabilities
                        }
                        general={general}
                        onRefreshSnapshot={() => {
                          actions.refreshSnapshot(
                            camera.DeviceId,
                            camera.Id,
                            camera.Name,
                          );
                        }}
                        onRestartCamera={() => {
                          actions.cameraRestart(
                            camera.DeviceId,
                            camera.RemoteId,
                            camera.Name,
                          );
                        }}
                        onSave={this.updateCameraGeneralSettings}
                      />
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  path={`${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_AUDIO}`}
                  render={() => (
                    <EmptyPlaceholder
                      isFetching={isFetchingAllCameras}
                      items={camera ? [1] : []}
                      translateKey="ERROR_MESSAGES.GATEWAY_CONNECT_ERROR"
                    >
                      <CameraSettingsAudio />
                    </EmptyPlaceholder>
                  )}
                />
                <Route
                  exact
                  path={`${PATH_CAMERAS}/${camera.Id}`}
                  render={() => (
                    <Redirect
                      to={{
                        pathname: `${PATH_CAMERAS}/${camera.Id}${PATH_SEGMENT_GENERAL}`,
                        state: location.state,
                      }}
                    />
                  )}
                />
              </Switch>
            </div>
            <div className={tabContentVerticalSeperator} />
            {playerHeight > 0 && playerWidth > 0 ? (
              <div className={mediaPlayer} style={mediaPlayerProperties}>
                {/* eslint-disable react/jsx-props-no-spreading */}
                <PlayerHost
                  {...this.getCameraProps(playerWidth, playerHeight)}
                  showCanvas={this.isCanvasAvailable()}
                />
                {/* eslint-enable react/jsx-props-no-spreading */}
              </div>
            ) : (
              <div style={{ width: `${playerWidth}px` }} />
            )}
          </div>
        </MainContentWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  let acquisition;
  let beamCount;
  let beamId;
  let cameraData = {};
  let cameraDeviceLocation;
  let clusterId;
  let compression;
  let deviceName;
  let digitalOutput;
  let digitalOutputAvailableCameras;
  let doesCameraSupportPtz = false;
  let entities = [];
  let eventCount;
  let eventId;
  let general;
  let isFetchingCameraEntities;
  let links = [];
  let privacyZoneId;
  let privacyZones;
  let roiCount;
  let roiId;
  let rules = null;
  let rulesFromServer;
  let settings = {};

  const camera = state.devices.cameras.find(
    cam => cam.Id === state.devices.selectedCamera,
  );
  let cameraDevice = null;

  if (camera) {
    // if we have all the necessary data in state, populate the
    // acquisition var with data from the model
    findCamera(state, state.devices.selectedCamera, cam => {
      cameraData = {
        DeviceId: cam.DeviceId,
        RemoteId: cam.RemoteId,
        ...cam.acquisition,
        hasAutoFocus: !!cam.Capabilities.acquisition.FOCUS_ONE_SHOT,
        hasFocus: !!cam.Capabilities.acquisition.FOCUS_MANUAL,
      };
    });
    const overlayData = state.streamOverlays[camera.Id];
    if (overlayData) {
      const rulesUnfiltered = {
        ...overlayData.rules,
        ...overlayData.ruleEdits,
      };
      rules = {};
      Object.keys(rulesUnfiltered).forEach(key => {
        if (rulesUnfiltered[key] !== null) {
          rules[key] = rulesUnfiltered[key];
        }
      });
      rulesFromServer = overlayData.rules;
      eventCount = overlayData.eventCount;
      eventId = overlayData.eventId;
      beamCount = overlayData.beamCount;
      beamId = overlayData.beamId;
      roiCount = overlayData.roiCount;
      roiId = overlayData.roiId;
    }
    settings = state.devices.settings[camera.Id];
    if (settings && settings.general) {
      ({ general } = settings);
    }

    if (settings && settings.digital_output) {
      digitalOutput = settings.digital_output;
    }

    entities = state.devices.entities[camera.DeviceId];
    isFetchingCameraEntities =
      state.devices.isFetchingDeviceEntities[camera.DeviceId];
    if (entities && entities.length > 0) {
      const cameras = cloneDeep(state.devices.cameras);
      const filteredCameras = listCamerasFromSameServer(camera, cameras);
      links = getDigitalOutLinksFromCamera(filteredCameras, entities, camera);
      digitalOutputAvailableCameras = setCamerasLinkFlag(
        filteredCameras,
        links,
      );
    }

    const currentStreamData = state.streamOverlays[camera.Id];
    if (currentStreamData) {
      const privacyZonesObject = {
        ...currentStreamData.privacyZones,
        ...currentStreamData.privacyZoneEdits,
      };
      privacyZones = Object.entries(privacyZonesObject)
        .filter(item => item[1] !== null)
        .map(item => ({ ...item[1], id: item[0] }));
      privacyZoneId = currentStreamData.privacyZoneId;
    }

    if (settings && settings.compression && settings.acquisition) {
      ({ compression } = settings);
      ({ acquisition } = settings);
    }
    if (state.devices && state.devices.devices) {
      cameraDevice = state.devices.devices.find(
        device => device.Id === camera.DeviceId,
      );
      if (cameraDevice) {
        deviceName = cameraDevice.Name;
        const deviceLocation = state.locations.locations.find(
          loc => loc.Id === cameraDevice.SiteId,
        );
        if (deviceLocation) {
          cameraDeviceLocation = deviceLocation.Name;
          clusterId = camera.GroupId;
        }
      }
    }

    doesCameraSupportPtz = cameraSupportsPtz(camera, cameraDevice);
  }

  return {
    acquisition,
    beamCount,
    beamId,
    camera,
    cameraData,
    cameraDevice,
    cameraDeviceLocation,
    cameraId: state.devices.selectedCamera,
    clusterId,
    compression,
    deviceName,
    digitalOutput,
    digitalOutputAvailableCameras,
    doesCameraSupportPtz,
    entities,
    eventCount,
    eventId,
    general,
    isFetchingAllCameras: state.isFetching.getAllCameras,
    isFetchingCameraEntities,
    isFetchingCameraSettings: state.devices.isFetchingCameraSettings.isFetching,
    isFetchingCameraSettingsHasError:
      state.devices.isFetchingCameraSettings.error,
    isFetchingImageSettings:
      state.devices.isFetchingCompressionData ||
      state.devices.isFetchingAcquisitionData,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingPrivacyZones: state.devices.isFetchingPrivacyZones,
    isFetchingRules: state.isFetching.getRules,
    isHidden: state.settings.hideIpInput,
    isSavingCameraSettings: state.devices.isSavingCameraSettings,
    links,
    mediaParamsAll: state.devices.mediaParams,
    privacyZoneId,
    privacyZones,
    profile: state.user.profile,
    roiCount,
    roiId,
    rules,
    rulesFromServer,
    selectedCamera: state.devices.selectedCamera,
    serverBreadcrumb: state.devices.serverBreadcrumb,
    settings,
  };
}

CameraSettingsContainer.defaultProps = {
  camera: {},
  canChangeCustomer: false,
  doesCameraSupportPtz: false,
  eventId: null,
  isFetchingAllCameras: false,
  isFetchingCameraSettings: null,
  isFetchingCameraSettingsHasError: false,
  isFetchingDeviceEntities: false,
  isFetchingImageSettings: false,
  isFetchingPrivacyZones: false,
  isFetchingRules: false,
  isSavingCameraSettings: false,
  match: {},
  privacyZoneId: null,
  privacyZones: {},
  rules: {},
  selectedCamera: null,
  settings: {},
};

CameraSettingsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  camera: PropTypes.objectOf(PropTypes.any),
  cameraData: PropTypes.objectOf(PropTypes.any).isRequired,
  cameraDeviceLocation: PropTypes.string.isRequired,
  cameraId: PropTypes.string.isRequired,
  canChangeCustomer: PropTypes.bool,
  clusterId: PropTypes.string.isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  deviceName: PropTypes.string.isRequired,
  digitalOutput: PropTypes.shape({}).isRequired,
  digitalOutputAvailableCameras: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  doesCameraSupportPtz: PropTypes.bool,
  entities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  eventId: PropTypes.string,
  general: PropTypes.shape({
    deviceName: PropTypes.string.isRequired,
    locationString: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetchingAllCameras: PropTypes.bool,
  isFetchingCameraEntities: PropTypes.shape({}).isRequired,
  isFetchingCameraSettings: PropTypes.bool,
  isFetchingCameraSettingsHasError: PropTypes.bool,
  isFetchingDeviceEntities: PropTypes.bool,
  isFetchingImageSettings: PropTypes.bool,
  isFetchingPrivacyZones: PropTypes.bool,
  isFetchingRules: PropTypes.bool,
  isSavingCameraSettings: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  privacyZoneId: PropTypes.string,
  privacyZones: PropTypes.objectOf(PropTypes.any),
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  router: PropTypes.shape({}).isRequired,
  rules: PropTypes.shape({
    beamCount: PropTypes.number,
    beamId: PropTypes.string,
    eventId: PropTypes.string,
    roiCount: PropTypes.number,
    roiId: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedCamera: PropTypes.string,
  settings: PropTypes.shape({
    acquisition: PropTypes.shape({}),
    compression: PropTypes.shape({
      resolution: PropTypes.shape({
        val: PropTypes.string,
      }),
    }),
    general: PropTypes.shape({}),
    remoteAnalyticsEnabled: PropTypes.bool.isRequired,
  }),
  translate: PropTypes.func.isRequired,
  updateDimensions: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...SettingsActions,
        ...DeviceActions,
        ...LocationActions,
        ...OverlayActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  Dimensions()(
    withLocalize(
      withAITracking(
        ai.reactPlugin,
        CameraSettingsContainer,
        'CameraSettingsContainer',
      ),
    ),
  ),
);
