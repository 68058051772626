const alarmUrlParser = url => {
  const alarmURLObj = {};
  alarmURLObj.NextLink = null;
  alarmURLObj.PrevLink = null;

  if (url) {
    if (url.indexOf('/next/') > -1 && url.indexOf('/prev/') > -1) {
      const [nextLink, prevLink] = url.split('/next/');
      alarmURLObj.NextLink = nextLink;
      alarmURLObj.PrevLink = prevLink.replace('/prev/', '');
    } else if (url.indexOf('/next/') > -1 && url.indexOf('/prev/') < 0) {
      alarmURLObj.NextLink = url.replace('/next/', '');
    } else if (url.indexOf('/prev/') > -1 && url.indexOf('/next/') < 0) {
      alarmURLObj.PrevLink = url.replace('/prev/', '');
    }
  }
  return alarmURLObj;
};

export default alarmUrlParser;
