import PropTypes from 'prop-types';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';

// Components
import { Icon, NoStyleButton } from 'lib';

// Styles
import {
  backButton,
  backWrapper,
  listNavTitle,
  titleWrapper,
} from './styles.css';

// Constants

const PageTitle = props => {
  const {
    backPath,
    className,
    onBackClick,
    showBackButton,
    title,
    titleData,
    titleId,
  } = props;
  const BackButtonWrapper = backPath ? Link : 'div';
  return (
    <div className={className}>
      {showBackButton ? (
        <BackButtonWrapper className={backWrapper} to={backPath}>
          <NoStyleButton
            className={backButton}
            id="backBtn"
            isBackButton
            onClick={onBackClick}
          >
            <Icon id="ic_back" size="26" />
          </NoStyleButton>
        </BackButtonWrapper>
      ) : null}
      <span className={listNavTitle}>
        {titleId ? <Translate data={titleData} id={titleId} /> : title}
      </span>
    </div>
  );
};

PageTitle.propTypes = {
  backPath: PropTypes.string,
  className: PropTypes.string,
  onBackClick: PropTypes.func,
  showBackButton: PropTypes.bool,
  title: PropTypes.node,
  titleData: PropTypes.objectOf(PropTypes.any),
  titleId: PropTypes.string,
};

PageTitle.defaultProps = {
  backPath: '',
  className: titleWrapper,
  onBackClick: () => {},
  showBackButton: false,
  title: '',
  titleData: null,
  titleId: null,
};

export default PageTitle;
