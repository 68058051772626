export const DEVICE_SETTINGS = {
  GENERIC: {
    LABELS: {
      AUDIO: 'Audio',
      PRIVACY: 'Privacy',
      RULES: 'Rules',
      GENERAL: 'General',
      ADVANCED: 'Advanced',
      VIDEO: 'Video',
      CAMERAS: 'Cameras',
      IO: 'Digital I/O',
      RETENTION: 'Video Retention',
      TAMPERING: 'Tampering',
      DIGITAL_OUT: 'Digital I/O',
      POE: 'PoE Management',
      RECORDING_SCHEDULE: 'Recording Schedule',
    },
  },
};
