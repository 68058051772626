export const columnHeader = {
  FirstName: 'USERS.FORM_COLUMN_HEADERS.FIRST_NAME',
  LastName: 'USERS.FORM_COLUMN_HEADERS.LAST_NAME',
  EmailAddress: 'USERS.FORM_COLUMN_HEADERS.EMAIL_ADDRESS',
  Role: 'USERS.FORM_COLUMN_HEADERS.ROLE',
  location: 'COMMON.SITE',
  securitySchedule: 'USERS.FORM_COLUMN_HEADERS.SECURITY_MONITORING_SCHEDULE',
  healthSchedule: 'USERS.FORM_COLUMN_HEADERS.HEALTH_MONITORING_SCHEDULE',
  Status: 'COMMON.STATUS',
  Schedule: 'USERS.FORM_COLUMN_HEADERS.SCHEDULE',
};

export const columnDataFields = {
  fieldFirstName: 'FirstName',
  fieldLastName: 'LastName',
  fieldEmailAddr: 'EmailAddress',
  fieldRole: 'Role',
  fieldPolicy: 'Policies',
  fieldGroupId: 'GroupId',
  fieldSecuritySchedule: 'SecurityScheduleId',
  fieldHealthSchedule: 'HealthScheduleId',
  fieldStatus: 'Status',
  fieldType: 'UserType',
};

export const iconColors = {
  black: 'black',
  white: 'white',
};

export const FLUID_COLUMNS = 60;

export const userTableHeight = `calc(100vh - 350px)`;
