import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconTogglePtzLock = props => {
  return (
    <div
      className={props.className}
      title={props.translate('VIDEO_PLAYER.LOCK_PTZ')}
    >
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="PTZ-icons" transform="translate(-256.000000, -22.000000)">
            <g id="ic_lock_open" transform="translate(254.000000, 20.000000)">
              <g transform="translate(4.000000, 1.000000)">
                <mask id="mask-2" fill="white">
                  <path
                    d="M8,16 C9.1,16 10,15.1 10,14 C10,12.9 9.1,12 8,12 C6.9,12 5.99999999,12.9 5.99999999,14 C5.99999999,15.1 6.9,16 8,16 Z M14,6.99999998 L13,6.99999998 L13,4.99999998 C13,2.23999996 10.76,-4.5833076e-08 8,-4.5833076e-08 C5.23999999,-4.5833076e-08 2.99999998,2.23999996 2.99999998,4.99999998 L4.89999999,4.99999998 C4.89999999,3.28999997 6.28999999,1.89999996 8,1.89999996 C9.71000001,1.89999996 11.1,3.28999997 11.1,4.99999998 L11.1,6.99999998 L1.99999998,6.99999998 C0.89999997,6.99999998 -3.33329808e-08,7.89999999 -3.33329808e-08,8.99999999 L-3.33329808e-08,19 C-3.33329808e-08,20.1 0.89999997,21 1.99999998,21 L14,21 C15.1,21 16,20.1 16,19 L16,8.99999999 C16,7.89999999 15.1,6.99999998 14,6.99999998 Z M14,19 L1.99999998,19 L1.99999998,8.99999999 L14,8.99999999 L14,19 Z"
                    id="path-1"
                  />
                </mask>
                <path
                  id="Icon"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  d="M8,16 C9.1,16 10,15.1 10,14 C10,12.9 9.1,12 8,12 C6.9,12 5.99999999,12.9 5.99999999,14 C5.99999999,15.1 6.9,16 8,16 Z M14,6.99999998 L13,6.99999998 L13,4.99999998 C13,2.23999996 10.76,-4.5833076e-08 8,-4.5833076e-08 C5.23999999,-4.5833076e-08 2.99999998,2.23999996 2.99999998,4.99999998 L4.89999999,4.99999998 C4.89999999,3.28999997 6.28999999,1.89999996 8,1.89999996 C9.71000001,1.89999996 11.1,3.28999997 11.1,4.99999998 L11.1,6.99999998 L1.99999998,6.99999998 C0.89999997,6.99999998 -3.33329808e-08,7.89999999 -3.33329808e-08,8.99999999 L-3.33329808e-08,19 C-3.33329808e-08,20.1 0.89999997,21 1.99999998,21 L14,21 C15.1,21 16,20.1 16,19 L16,8.99999999 C16,7.89999999 15.1,6.99999998 14,6.99999998 Z M14,19 L1.99999998,19 L1.99999998,8.99999999 L14,8.99999999 L14,19 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconTogglePtzLock.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
};

export default withLocalize(IconTogglePtzLock);
