exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".noStyleButton___38orD {\r\n  background: transparent;\r\n  border: none;\r\n  outline: none;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  padding: 0px 0px;\r\n}\r\n\r\n.noStyleButtonPointer___j9sg7 {\r\n  background: transparent;\r\n  border: none;\r\n  outline: none;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  cursor: pointer;\r\n  padding: 0px 0px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/Button/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,cAAc;EACd,4BAA4B;EAC5B,qBAAqB;EACrB,iBAAiB;CAClB;;AAED;EACE,wBAAwB;EACxB,aAAa;EACb,cAAc;EACd,4BAA4B;EAC5B,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".noStyleButton {\r\n  background: transparent;\r\n  border: none;\r\n  outline: none;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  padding: 0px 0px;\r\n}\r\n\r\n.noStyleButtonPointer {\r\n  background: transparent;\r\n  border: none;\r\n  outline: none;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  cursor: pointer;\r\n  padding: 0px 0px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"noStyleButton": "noStyleButton___38orD",
	"noStyleButtonPointer": "noStyleButtonPointer___j9sg7"
};