// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// Components
import { PageMessage } from 'containers';
import { DateSelect, DurationInput, TimeSelect } from 'components';
import { Button, GroupLayout } from 'lib';

// Style
import { DATABASE_DATETIME_EXTRACT_FORMAT } from 'constants/app';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import {
  buttonGroupHelper,
  cameraInputRow,
  cameraLabel,
  cameraVideoDownloadForm,
  dateTimeSeperator,
  modalInfo,
  modalText,
  videoDownloadPickerContainer,
} from './styles.css';

// Const
import * as VideoDownloadConst from './constants';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 1,
      errors: {}, // time in seconds
      startTime: props.initialValues.initialDateTime
        ? moment(
            props.initialValues.initialDateTime,
            DATABASE_DATETIME_EXTRACT_FORMAT,
          )
        : moment(),
    };
  }

  componentDidMount() {
    const { change } = this.props;
    const { duration, startTime } = this.state;
    change(
      VideoDownloadConst.VIDEO_DOWNLOAD_FORM.FORM_PROPS.START_TIME,
      startTime,
    );
    change(
      VideoDownloadConst.VIDEO_DOWNLOAD_FORM.FORM_PROPS.DURATION,
      duration,
    );
  }

  onDateChange = startDate => {
    const { change } = this.props;
    this.clearError();
    this.setState({ startTime: startDate });
    change(
      VideoDownloadConst.VIDEO_DOWNLOAD_FORM.FORM_PROPS.START_TIME,
      startDate,
    );
  };

  onTimeChange = startTime => {
    const { change } = this.props;
    const { startTime: stateStartTime } = this.state;
    this.clearError();
    const now = moment();
    const date = stateStartTime || moment();
    let newStartTime = startTime;

    if (date.isSame(now, 'day') && newStartTime.isAfter(now)) {
      // Cannot pick a future time
      newStartTime = now;
    }
    this.setState({ startTime }, () => {
      change(
        VideoDownloadConst.VIDEO_DOWNLOAD_FORM.FORM_PROPS.START_TIME,
        newStartTime,
      );
    });
  };

  onDurationChange = duration => {
    const { change } = this.props;
    this.clearError();
    this.setState({ duration }, () => {
      change(
        VideoDownloadConst.VIDEO_DOWNLOAD_FORM.FORM_PROPS.DURATION,
        duration,
      );
    });
  };

  formLevelValidation = values => {
    const { translate } = this.props;
    let error = {};
    const now = moment();
    if (values.startTime && values.duration) {
      const startTime = values.startTime || moment();
      const endTime = startTime.clone().add(values.duration, 's');
      if (endTime.isAfter(now)) {
        error.summary = translate('CAMERA.MESSAGES.ERRORS.FUTURE_VIDEO_DENIED');
      } else if (values.duration && values.duration <= 0) {
        error.summary = translate('CAMERA.MESSAGES.ERRORS.ZERO_DURATION');
      }
    } else {
      error = {};
    }
    return error;
  };

  hasError = () => {
    const { errors } = this.state;
    return Object.keys(errors).length > 0;
  };

  clearError = () => {
    this.setState({ errors: {} });
  };

  submit = values => {
    const { handleSubmit } = this.props;
    const { errors } = this.state;
    this.setState({ errors: this.formLevelValidation(values) }, () => {
      if (Object.keys(errors).length === 0) {
        handleSubmit();
      }
    });
  };

  render() {
    const {
      cameraDevice,
      cameraLocation,
      handleCancel,
      handleSubmit,
    } = this.props;
    const { duration, errors, startTime } = this.state;
    const today = moment();
    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <div className={cameraVideoDownloadForm}>
          <div className={modalInfo}>
            <span className={modalText}>
              <Translate id="CAMERA.MESSAGES.INFO_TEXT" />
            </span>
          </div>
          <PageMessage
            body={errors.summary}
            messageStyle={messageStyleStrings.error}
          />
          <div className={cameraInputRow}>
            <label className={cameraLabel}>
              <Translate
                data={{
                  startingTime: moment
                    .tz(cameraDevice.TimeZone || cameraLocation.TimeZone)
                    .zoneAbbr(),
                }}
                id="CAMERA.MESSAGES.LABELS.START_AT"
              />
            </label>
            <div className={videoDownloadPickerContainer}>
              <DateSelect
                initialValue={startTime || null}
                onChange={this.onDateChange}
                timezone={cameraDevice.TimeZone || cameraLocation.TimeZone}
              />
              <div className={dateTimeSeperator} />
              <TimeSelect
                disabledHours={() => {
                  const hours = [];
                  if (startTime.isSame(today, 'day')) {
                    const currentHour = today.hours();
                    for (let i = currentHour; i <= 23; i += 1) {
                      hours.push(i);
                    }
                  }
                  return hours;
                }}
                disabledMinutes={() => {
                  const minutes = [];
                  if (startTime && startTime.isSame(today, 'hour')) {
                    const currentMinutes = today.minutes() + 1;
                    for (let i = currentMinutes; i <= 59; i += 1) {
                      minutes.push(i);
                    }
                  }
                  return minutes;
                }}
                disabledSeconds={() => {
                  const seconds = [];
                  if (startTime && startTime.isSame(today, 'minute')) {
                    const currentSeconds = today.seconds();
                    for (let i = currentSeconds; i <= 59; i += 1) {
                      seconds.push(i);
                    }
                  }
                  return seconds;
                }}
                initialDay={startTime || null}
                onChange={this.onTimeChange}
                timezone={cameraDevice.TimeZone || cameraLocation.TimeZone}
                use12Hours={false}
                value={startTime}
              />
            </div>
          </div>
          <div className={cameraInputRow}>
            <label className={cameraLabel}>
              <Translate id="CAMERA.MESSAGES.LABELS.DURATION" />
            </label>
            <div className={videoDownloadPickerContainer}>
              <DurationInput
                initialDuration={duration}
                maxDuration={VideoDownloadConst.MAX_DOWNLOAD_DURATION}
                minDuration={1}
                onChange={this.onDurationChange}
              />
            </div>
          </div>
          <GroupLayout additionalClasses={buttonGroupHelper}>
            <Button
              key={VideoDownloadConst.VIDEO_DOWNLOAD_FORM.BUTTONS.CANCEL.KEY}
              buttonType="primary"
              disabled={false}
              id={VideoDownloadConst.VIDEO_DOWNLOAD_FORM.BUTTONS.CANCEL.ID}
              inputType="button"
              onClick={handleCancel}
              text={<Translate id="BUTTONS.CANCEL" />}
            />
            <Button
              key={VideoDownloadConst.VIDEO_DOWNLOAD_FORM.BUTTONS.SAVE.KEY}
              buttonType="primary"
              disabled={false}
              id={VideoDownloadConst.VIDEO_DOWNLOAD_FORM.BUTTONS.SAVE.ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </GroupLayout>
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  cameraDevice: {},
  cameraLocation: {},
  change() {},
  handleCancel() {},
  handleSubmit() {},
  initialValues: {},
  translate() {},
};

Form.propTypes = {
  cameraDevice: PropTypes.objectOf(PropTypes.any),
  cameraLocation: PropTypes.objectOf(PropTypes.any),
  change: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any),
  translate: PropTypes.func,
};

const ReduxForm = reduxForm({
  enableReinitialize: false,
  forceUnregisterOnUnmount: true,
  form: 'CameraVideoDownloadForm',
})(Form);

const CameraVideoDownloadModal = connect()(ReduxForm);

export default withLocalize(CameraVideoDownloadModal);
