// Libs
import React, { Component } from 'react';
import Select from 'react-select-plus';
import { Translate, withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

// Components
import { SELECT_STYLE } from 'constants/app';
import { ToggleCameraSelection } from 'cameraSelection';
import { Button, Dropdown, Icon, NoStyleButton } from 'lib';

// Icons
import { IconUnsync } from 'icons';

// Constants
import { NEW_VIEW_ID } from 'store/viewDefaults';
import * as ToggleTypes from 'constants/ToggleTypes';
import {
  IC_ADD,
  IC_CHEVRON_DOWN,
  IC_FAVORITE_DEFAULT_OFF,
  IC_FAVORITE_DEFAULT_ON,
  IC_FAVORITE_OFF,
  IC_FAVORITE_ON,
  IC_MORE_HORIZONTAL,
  IC_REFRESH,
  IC_SAVE,
  IC_TRASH,
  IC_VIDEO,
  IC_VIEW_COLLAPSE,
  IC_VIEW_EXPAND,
} from 'constants/iconNames';
import { SERVICE_FAMILIES } from 'constants/app';

// Styles
import {
  layoutPositionFixedTop,
  layoutWidth100Percent,
  layoutZIndexOnTop,
} from 'sharedStyles/layout.css';
import * as ViewEditorConsts from './constants';
import {
  ToggleIconButton,
  ViewEditorDropdownOption,
  ViewEditorDropdownValue,
  ViewLayoutOption,
  ViewLayoutValue,
} from '..';
import 'react-select-plus/dist/react-select-plus.css';

import {
  accItemAlign,
  addIcon,
  alarmButton,
  buttonDisabled,
  buttonEnabled,
  createViewButton,
  dropDownItemLabelWrap,
  editorContent,
  editorContentLeft,
  editorContentRight,
  editorWrapper,
  failedToLoad,
  fullScreenWrap,
  iconButtonWrap,
  imgIcon,
  imgSaveIcon,
  layoutDropdown,
  moreActionsActive,
  moreActionsDropdownFullscreenWrap,
  moreActionsDropdownWrap,
  moreActionsIconWrap,
  moreActionsItem,
  narrowWrapper,
  selectView,
  separator,
  toggleButtonIcon,
  tooManyCameras,
  videoIcon,
  viewEditorDropdown,
  viewStarToggle,
} from './styles.css';

class ViewEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraSelectorActive: false,
      fullScreen: false,
      selectedLayout: 2,
      selectedOption: null,
      showMoreActions: false,
    };
  }

  componentDidMount() {
    const { currentView } = this.props;
    if (currentView) {
      this.setCurrentView(currentView);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { cameraSelector, currentView } = nextProps;
    const { Layout } = currentView;
    const { isCameraSelectorShowing } = cameraSelector;
    if (nextProps.currentView) {
      this.setCurrentView(currentView);
      this.setState({
        cameraSelectorActive: isCameraSelectorShowing,
        selectedLayout: Layout,
      });
    }
  }

  get editorWrapperClasses() {
    const { fullScreen } = this.state;

    let position = '';
    if (fullScreen) {
      position = `${layoutPositionFixedTop} ${layoutWidth100Percent}  ${narrowWrapper}
        ${layoutZIndexOnTop}`;
    }

    return `${editorWrapper} ${position}`;
  }

  getAccNoViewItem = locationName => ({
    isAccView: true,
    label: locationName,
    options: [
      {
        cameraIds: [],
        className: `${accItemAlign} ${failedToLoad}`,
        disabled: true,
        isAccView: true,
        label: <Translate id="VIEWS.ACC_SAVED_NO_VIEWS" />,
      },
    ],
  });

  getAccViewItem = view => ({
    cameraIds: view.cameraIds,
    className:
      view.cameraIds.length > ViewEditorConsts.MAX_ACC_CAMERAS
        ? tooManyCameras
        : accItemAlign,
    isAccView: true,
    label: this.getTruncatedName(view.name, 28),
    value: view.Id,
  });

  getAccViews = viewData => {
    const { locations } = this.props;
    const claimedAccLocations =
      locations
        .filter(location => {
          return location.PackageSubscription.ServiceFamily === SERVICE_FAMILIES.AccCloudServices;
        })
        .filter(accLocation => {
          if (viewData && viewData.locations) {
            const location = viewData.locations.find(
              loc => loc.locationId === accLocation.Id,
            );
            if (!location) return false;
          }
          return true;
        }) || [];

    return claimedAccLocations
      .map(claimedLocation => {
        const location = viewData.locations.find(
          loc => loc.locationId === claimedLocation.Id,
        );

        return {
          label: this.getLocationName(location.locationId),
          options: location.views
            .map(view => this.getAccViewItem(view))
            .sort(this.sortAccViews),
        };
      })
      .filter(loc => loc !== undefined)
      .sort(this.sortAccViews);
  };

  getDefault = views => {
    return views.filter(view => view.Default);
  };

  getFavourite = views => {
    return views
      .filter(view => view.Favorite && !view.Default)
      .sort(this.sortViews);
  };

  getNonFavourite = views => {
    return views
      .filter(view => !view.Favorite && !view.Default)
      .sort(this.sortViews);
  };

  getOptionDefault = defaultView => {
    return defaultView.map(view => this.mapViewToOption(view));
  };

  getOptionFavourites = favorites => {
    return favorites.map(view => this.mapViewToOption(view));
  };

  getOptions = (defaultView, fav, nonFav, accViews) => {
    return [...defaultView, ...fav, ...nonFav, ...accViews];
  };

  getOptionsNonFavorites = nonFavorites => {
    return nonFavorites.map(view => this.mapViewToOption(view));
  };

  getLocationName = locationId => {
    const { locations } = this.props;
    const location = locations.find(loc => loc.Id === locationId);
    return location.Name;
  };

  getTruncatedName = (fullName, desiredLength) => {
    return fullName.length > desiredLength
      ? `${fullName.substring(0, desiredLength)}...`
      : fullName;
  };

  mapViewToOption = view => ({
    isDefault: view.Default,
    isFavorite: view.Favorite,
    label: view.Name,
    value: view.Id,
  });

  onViewSelect = option => {
    const { onSelectView } = this.props;
    if (option && option.value) {
      const id = option.value;
      this.setState({
        selectedOption: id,
      });
      onSelectView(id, option.isAccView);
    }
  };

  onCreateNew = () => {
    const { activateApanelOnCreate, createNewView } = this.props;
    const { cameraSelectorActive } = this.state;
    const isCamSelectorOpen = cameraSelectorActive;
    createNewView();
    if (!isCamSelectorOpen) {
      this.toggleCameraSelector();
      activateApanelOnCreate(0);
    }
  };

  renderButtonDelete = () => {
    const { currentView, deleteView } = this.props;

    const canDelete =
      !currentView.isBuiltinView && currentView.Id !== NEW_VIEW_ID;

    return (
      <div
        className={moreActionsItem}
        onClick={canDelete ? deleteView : () => null}
        onKeyPress={canDelete ? deleteView : () => null}
        role="button"
        tabIndex="0"
      >
        <NoStyleButton
          className={canDelete ? buttonEnabled : buttonDisabled}
          id={ViewEditorConsts.idDeleteViewsButton}
          onClick={canDelete ? deleteView : () => null}
        >
          <Icon iconClass={imgIcon} id={IC_TRASH} />
        </NoStyleButton>
        <span className={dropDownItemLabelWrap}>
          <Translate id="BUTTONS.DELETE" />
        </span>
      </div>
    );
  };

  renderButtonSave = () => {
    const { isDirty, saveView } = this.props;
    return (
      <div
        className={alarmButton}
        onClick={saveView}
        onKeyPress={saveView}
        role="button"
        tabIndex="0"
      >
        <NoStyleButton
          className={isDirty ? buttonEnabled : buttonDisabled}
          disabled={!isDirty}
          id={ViewEditorConsts.idSaveViewsButton}
          onClick={saveView}
        >
          <Icon iconClass={imgSaveIcon} id={IC_SAVE} title="BUTTONS.SAVE" />
        </NoStyleButton>
      </div>
    );
  };

  setCurrentView = view => {
    this.setState({
      selectedOption: view.Id,
    });
  };

  toggleCameraSelector = () => {
    const { cameraSelector } = this.props;
    const { cameraSelectorActive } = this.state;

    cameraSelector.activate(!cameraSelectorActive);
    this.setState({ cameraSelectorActive: !cameraSelectorActive });
  };

  toggleFullScreen = fullScreenMode => {
    const { maximizeView, minimizeView } = this.props;
    if (fullScreenMode) {
      maximizeView();
    } else {
      minimizeView();
    }
    this.setState({
      fullScreen: fullScreenMode,
    });
  };

  toggleMoreActions = () => {
    this.setState(state => ({ showMoreActions: !state.showMoreActions }));
  };

  renderMoreActionsDropdown = () => {
    const { currentView, setDefault, setFavorite } = this.props;
    const { fullScreen } = this.state;
    return (
      <Translate>
        {({ translate }) => (
          <Dropdown
            containerClasses={
              fullScreen
                ? moreActionsDropdownFullscreenWrap
                : moreActionsDropdownWrap
            }
            id={ViewEditorConsts.idMoreActionsDropdown}
            toggleDropdown={this.toggleMoreActions}
          >
            <div
              className={moreActionsItem}
              onClick={setDefault}
              onKeyPress={setDefault}
              role="button"
              tabIndex="0"
            >
              <ToggleIconButton
                disabled={currentView.isAccView}
                iconActive={
                  <Icon
                    iconClass={toggleButtonIcon}
                    id={IC_FAVORITE_DEFAULT_ON}
                  />
                }
                iconInactive={
                  <Icon
                    iconClass={toggleButtonIcon}
                    id={IC_FAVORITE_DEFAULT_OFF}
                  />
                }
                id="resolved_"
                isActive={currentView.Default}
                onClick={setDefault}
                revertOn={ToggleTypes.TOGGLE_VIEW_DEFAULT}
                styleClass={viewStarToggle}
              />
              <span className={dropDownItemLabelWrap}>
                {translate(
                  currentView.Default
                    ? 'VIEWS.REMOVE_DEFAULT_VIEW_LABEL'
                    : 'VIEWS.ADD_DEFAULT_VIEW_LABEL',
                )}
              </span>
            </div>
            <div
              className={moreActionsItem}
              onClick={setFavorite}
              onKeyPress={setFavorite}
              role="button"
              tabIndex="0"
            >
              <ToggleIconButton
                disabled={currentView.isBuiltinView || currentView.isAccView}
                iconActive={
                  <Icon iconClass={toggleButtonIcon} id={IC_FAVORITE_ON} />
                }
                iconInactive={
                  <Icon iconClass={toggleButtonIcon} id={IC_FAVORITE_OFF} />
                }
                id="cboxFavorite"
                isActive={currentView.Favorite}
                onClick={setFavorite}
                styleClass={viewStarToggle}
              />
              <span className={dropDownItemLabelWrap}>
                {translate(
                  currentView.Favorite
                    ? 'VIEWS.REMOVE_FAVORITE_VIEW_LABEL'
                    : 'VIEWS.ADD_FAVORITE_VIEW_LABEL',
                )}
              </span>
            </div>
            {this.renderButtonDelete()}
          </Dropdown>
        )}
      </Translate>
    );
  };

  sortViews(a, b) {
    if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
      return -1;
    }
    if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  sortAccViews(a, b) {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  render() {
    const {
      accViews,
      cameraSelector,
      isMaximized,
      isSynchronized,
      selectLayout,
      toggleSyncVideo,
      translate,
      views,
    } = this.props;

    const {
      cameraSelectorActive,
      fullScreen,
      selectedLayout,
      selectedOption,
      showMoreActions,
    } = this.state;

    const arrDefault = this.getDefault(views);
    const arrFavorites = this.getFavourite(views);
    const arrNonFavorites = this.getNonFavourite(views);

    const optionDefault = this.getOptionDefault(arrDefault);
    const optionsACCViews = this.getAccViews(accViews);
    const optionsFavorites = this.getOptionFavourites(arrFavorites);
    const optionsNonFavorites = this.getOptionsNonFavorites(arrNonFavorites);

    return (
      <div className={this.editorWrapperClasses}>
        <div className={`${editorContent} ${fullScreen && fullScreenWrap}`}>
          <div className={editorContentLeft}>
            <ToggleCameraSelection
              icon={
                <>
                  <Icon
                    iconClass={addIcon}
                    id={IC_ADD}
                    title="VIEWS.CAMERA_LIST_TOOLTIP"
                  />
                  <Icon
                    iconClass={videoIcon}
                    id={IC_VIDEO}
                    title="VIEWS.CAMERA_LIST_TOOLTIP"
                  />
                </>
              }
              isActive={cameraSelectorActive}
              onClick={this.toggleCameraSelector}
            />
            <div className={separator} />
            <div id={ViewEditorConsts.idViewsDropdown}>
              <Select
                arrowRenderer={() => <Icon id={IC_CHEVRON_DOWN} />}
                className={`${selectView} ${viewEditorDropdown}`}
                clearable={false}
                noResultsText={<Translate id="FILTER.NO_RESULTS_FOUND" />}
                onChange={this.onViewSelect}
                optionClassName="msi-select-option"
                optionComponent={ViewEditorDropdownOption}
                options={this.getOptions(
                  optionDefault,
                  optionsFavorites,
                  optionsNonFavorites,
                  optionsACCViews,
                )}
                placeholder={<Translate id="VIEWS.SWITCH_TO_VIEW" />}
                searchable
                style={SELECT_STYLE}
                tabIndex="-1"
                trimFilter
                value={selectedOption}
                valueComponent={ViewEditorDropdownValue}
              />
            </div>
          </div>
          <div className={editorContentRight}>
            {this.renderButtonSave()}
            <div className={separator} />
            <div className={alarmButton}>
              <NoStyleButton
                onClick={toggleSyncVideo}
                title={translate(
                  isSynchronized ? '' : 'VIEWS.UNSYNC_ICON_TITLE',
                )}
              >
                {isSynchronized ? (
                  <Icon id={IC_REFRESH} title="VIEWS.SYNC_ICON_TITLE" />
                ) : (
                  <IconUnsync title="VIEWS.UNSYNC_ICON_TITLE" />
                )}
              </NoStyleButton>
            </div>
            <div className={separator} />
            <div
              className={iconButtonWrap}
              id={ViewEditorConsts.idDropDownLayout}
            >
              <Select
                className={layoutDropdown}
                clearable={false}
                noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
                onChange={selectLayout}
                optionClassName="msi-select-option"
                optionComponent={ViewLayoutOption}
                options={ViewEditorConsts.optionsLayout}
                searchable={false}
                tabIndex="-1"
                trimFilter
                value={selectedLayout}
                valueComponent={ViewLayoutValue}
                width={75}
              />
            </div>
            <div className={separator} />
            {isMaximized ? (
              <div
                className={iconButtonWrap}
                onClick={() => this.toggleFullScreen(false)}
                onKeyPress={() => this.toggleFullScreen(false)}
                role="button"
                tabIndex="0"
              >
                <Icon id={IC_VIEW_COLLAPSE} title="VIEWS.MINIMIZE_TOOLTIP" />
              </div>
            ) : (
              <div
                className={iconButtonWrap}
                onClick={() => this.toggleFullScreen(true)}
                onKeyPress={() => this.toggleFullScreen(true)}
                role="button"
                tabIndex="0"
              >
                <Icon
                  id={IC_VIEW_EXPAND}
                  onClick={() => this.toggleFullScreen(true)}
                  title="VIEWS.MAXIMIZE_TOOLTIP"
                />
              </div>
            )}
            <div className={separator} />
            <div
              className={iconButtonWrap}
              onClick={() => this.toggleMoreActions()}
              onKeyPress={() => this.toggleMoreActions()}
              role="button"
              tabIndex="0"
            >
              <div
                className={
                  showMoreActions
                    ? `${moreActionsIconWrap} ${moreActionsActive}`
                    : moreActionsIconWrap
                }
              >
                <Icon id={IC_MORE_HORIZONTAL} title="VIEWS.MORE_TOOLTIP" />
              </div>
            </div>
            {renderIf(showMoreActions)(this.renderMoreActionsDropdown())}
            <div className={separator} />
            <div className={createViewButton}>
              <Button
                id={ViewEditorConsts.idCreateViewsButton}
                inputType="button"
                onClick={this.onCreateNew}
                text={<Translate id="BUTTONS.NEW_VIEW" />}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewEditor.defaultProps = {
  accViews: { locations: [] },
  currentView: {},
  views: [],
};

ViewEditor.propTypes = {
  accViews: PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        locationId: PropTypes.string,
        views: PropTypes.arrayOf(
          PropTypes.shape({
            Id: PropTypes.string,
            cameraIds: PropTypes.arrayOf(PropTypes.string),
            name: PropTypes.string,
          }),
        ),
      }),
    ),
  }),
  activateApanelOnCreate: PropTypes.func.isRequired,
  cameraSelector: PropTypes.shape({
    activate: PropTypes.bool,
    isCameraSelectorShowing: PropTypes.bool,
  }).isRequired,
  createNewView: PropTypes.func.isRequired,
  currentView: PropTypes.shape({
    Default: PropTypes.bool,
    Favorite: PropTypes.bool,
    Id: PropTypes.string,
    Layout: PropTypes.func,
    isAccView: PropTypes.bool,
    isBuiltinView: PropTypes.bool,
  }),
  deleteView: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isMaximized: PropTypes.bool.isRequired,
  isSynchronized: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maximizeView: PropTypes.func.isRequired,
  minimizeView: PropTypes.func.isRequired,
  onSelectView: PropTypes.func.isRequired,
  saveView: PropTypes.func.isRequired,
  selectLayout: PropTypes.func.isRequired,
  setDefault: PropTypes.func.isRequired,
  setFavorite: PropTypes.func.isRequired,
  toggleSyncVideo: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  views: PropTypes.arrayOf(PropTypes.shape({})),
};

export default withLocalize(ViewEditor);
