export const SITE_TABLE_FIELDS = {
  AUTO_RENEWAL: 'IsAutoRenewable',
  BILLING_ID: 'BillingNumber',
  NAME: 'Name',
  PURCHASE_ORDER_NUMBER: 'PurchaseOrderNumber',
  SUBSCRIPTION: 'Subscription',
  TERM: 'Term',
  DEVICE_COUNT: 'DeviceCount',
  CAMERAS: 'Cameras',
  ACTIVATION_DATE: 'ActivationDate',
  RENEWAL_DATE: 'RenewalDate',
};
export const SITE_TABLE_LABEL = {
  NAME: {
    TRANSLATED_HEADER: 'COMMON.SITE',
    FIELD: 'Name',
  },
  BILLING_ID: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.BILLING_ID_LABEL',
    FIELD: 'BillingNumber',
  },
  PURCHASE_ORDER_NUMBER: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.ORDER_ID_LABEL',
    FIELD: 'PurchaseOrderNumber',
  },
  SUBSCRIPTION: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.SUBSCRIPTION_LABEL',
    FIELD: 'Subscription',
  },
  TERM: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.TERM_LABEL',
    FIELD: 'Term',
  },
  GATEWAYS: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.SERVERS_LABEL',
    FIELD: 'DeviceCount',
  },
  CAMERAS: {
    TRANSLATED_HEADER: 'COMMON.CAMERAS',
    FIELD: 'Cameras',
  },
  ACTIVATION_DATE: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.ACTIVATION_DATE_LABEL',
    FIELD: 'ActivationDate',
  },
  RENEWAL_DATE: {
    TRANSLATED_HEADER: 'GENERAL_TABLE.HEADER.RENEWAL_DATE_LABEL',
    FIELD: 'RenewalDate',
  },
};
