import React from 'react';
import renderIf from 'render-if';
import PropTypes from 'prop-types';
import {
  toggleContainer,
  toggleSelection,
  toggleText,
  toggleWrapper,
} from './ButtonToggle.css';

const ButtonToggle = props => {
  const {
    onChange,
    leftId,
    rightId,
    name,
    className,
    label,
    leftValue,
    leftChecked,
    leftLabel,
    rightValue,
    rightChecked,
    rightLabel,
  } = props;

  return (
    <div className={className}>
      <div className={toggleWrapper}>
        {renderIf(label)(<div className={toggleText}>{label}</div>)}
        <div className={toggleContainer}>
          <span
            className={toggleSelection}
            style={{ left: leftChecked ? 0 : 84 }}
          />
          <input
            id={leftId || `${name}${leftValue}`}
            type="radio"
            value={leftValue}
            checked={leftChecked}
            onChange={onChange}
            name={name}
          />
          <label htmlFor={leftId || `${name}${leftValue}`}>{leftLabel}</label>

          <input
            id={rightId || `${name}${rightValue}`}
            type="radio"
            value={rightValue}
            checked={rightChecked || !leftChecked}
            onChange={onChange}
            name={name}
          />
          <label htmlFor={rightId || `${name}${rightValue}`}>
            {rightLabel}
          </label>
        </div>
      </div>
    </div>
  );
};

ButtonToggle.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  leftLabel: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  leftChecked: PropTypes.bool.isRequired,
  leftId: PropTypes.string,
  rightLabel: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  rightChecked: PropTypes.bool,
  rightId: PropTypes.string,
};

ButtonToggle.defaultProps = {
  className: '',
  leftId: '',
  rightId: '',
  label: null,
  onChange: () => {},
  rightChecked: null,
};

export default ButtonToggle;
