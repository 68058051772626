// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

// Actions
import * as DeviceActions from 'actions/devices';
import * as types from 'constants/ActionTypes';

// Components
import ModalContainer from 'containers/Modal/ModalContainer';
import FindCameraForm from 'components/FindCameraForm/FindCameraForm';

// Styles
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';

// Constants
import { FIND_CAMERA_FORM_CONTAINER_ID } from './constants';

// Class
class FindCameraFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isToBeDiscoveredCameraExist = formData => {
    const { actions, discoveredCameras } = this.props;
    const {
      showMessageOnDiscoveredCameraManualSearch,
      showMessageOnDiscoveredCameraResults,
    } = actions;
    let isExist = false;
    // We can't gurantee on range search so we will let
    // the server to search in database and find for us.
    if (formData.searchType === 'ipAddress') {
      const findResult = discoveredCameras.find(
        discCamera => discCamera.ipAddress === formData.address,
      );
      if (findResult) {
        isExist = true;
      }
    } else if (formData.searchType === 'rtsp') {
      const findResult = discoveredCameras.find(
        discCamera => discCamera.uri && discCamera.uri === formData.urlStream,
      );
      if (findResult) {
        isExist = true;
      }
    }

    if (isExist) {
      showMessageOnDiscoveredCameraManualSearch();
      // For best user experience we will execute this after couple of seconds to show process progress.
      setTimeout(() => {
        showMessageOnDiscoveredCameraResults();
      }, 2500);
    }
    return isExist;
  };

  discover = data => {
    if (!this.isToBeDiscoveredCameraExist(data)) {
      const { actions, id } = this.props;
      const { manuallyDiscoverCamera } = actions;

      let actionType;
      const formData = {
        password: data.password,
        username: data.username,
      };

      if (data.confirmPassword) {
        formData.confirmPassword = data.confirmPassword;
        formData.initialize = true;
      }

      if (data.searchType === 'ipRange') {
        actionType = types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE;
        formData.startIpAddress = data.startIpAddress;
        formData.endIpAddress = data.endIpAddress;
        formData.port = data.port;
        if (data.cameraType) {
          formData.connectionType = data.cameraType;
        }
      } else if (data.searchType === 'ipAddress') {
        actionType = types.MANUALLY_DISCOVER_CAMERA;
        formData.address = data.address;
        formData.port = parseInt(data.port, 10);
        if (data.cameraType) {
          formData.connectionType = data.cameraType;
        }
      } else if (data.searchType === 'rtsp') {
        actionType = types.MANUALLY_DISCOVER_RTSP_CAMERA;
        delete formData.initialize;
        delete formData.confirmPassword;
        formData.uri = data.urlStream;
      }
      manuallyDiscoverCamera(id, formData, actionType);
    }
    this.hideCameraLoginModal(data);
  };

  hideCameraLoginModal = (formData = null) => {
    const { toggleFindCameraModal } = this.props;
    toggleFindCameraModal(formData);
  };

  render() {
    const { isFormVisible, supportedCameras } = this.props;
    return (
      <div id={FIND_CAMERA_FORM_CONTAINER_ID}>
        <Modal
          className={modalContentContainer}
          contentLabel="FindCameraModal"
          isOpen={isFormVisible}
          onRequestClose={this.hideCameraLoginModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          <ModalContainer
            handleCancel={this.hideCameraLoginModal}
            modalTitle={<Translate id="BUTTONS.FIND_UNDISCOVERED" />}
          >
            <FindCameraForm
              onCancel={this.hideCameraLoginModal}
              onSubmit={this.discover}
              supportedCameras={supportedCameras}
              visible={isFormVisible}
            />
          </ModalContainer>
        </Modal>
      </div>
    );
  }
}

FindCameraFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  discoveredCameras: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  isFormVisible: PropTypes.bool.isRequired,
  supportedCameras: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleFindCameraModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(DeviceActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(FindCameraFormContainer);
