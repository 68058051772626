import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function PersistentFilter(state, action) {
    switch (action.type) {
        case types.PERSIST_FILTER: {
          const { filterName, filterValue } = action;
          let newFilters = {...state.persistentFilters};
          newFilters[filterName] = filterValue;
          return newFilters;
        }

        default:
            return state || initialState().persistentFilters;
    }
}