import React, { useMemo } from 'react';
import { withLocalize } from 'react-localize-redux';
import { ListView } from 'lib';
import PropTypes from 'prop-types';

// Consts
import { flexContainer } from 'sharedStyles/global.css';
import { FIELD_ORDER, getCustomCells, TRANSLATION_IDS } from './constants';

// Styles
import { changeIndicator, siteName } from './styles.css';

const ChangePendingIndicator = ({ isPending, titleId, translate }) =>
  isPending ? (
    <span className={changeIndicator} title={translate(titleId)} />
  ) : null;

ChangePendingIndicator.propTypes = {
  isPending: PropTypes.bool.isRequired,
  titleId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

const SiteSubscriptionsTable = props => {
  const handleRowClick = (data, index) => {
    const { onRowClicked } = props;
    onRowClicked(data, index);
  };

  const { preferredShortDateFormat, subscriptions, translate } = props;

  const isSubscriptionPending = () =>
    subscriptions.filter(s => s.IsChangePending).length > 0;

  const customHeaderClass = {
    Subscriptions: isSubscriptionPending() ? siteName : null,
  };

  const ROW_ACTIONS = [];

  const customCells = useMemo(
    () =>
      getCustomCells(
        translate,
        preferredShortDateFormat,
        { flexContainer },
        ChangePendingIndicator,
      ),
    [preferredShortDateFormat, translate],
  );

  return (
    <ListView
      customCells={customCells}
      customHeaderClass={customHeaderClass}
      data={subscriptions}
      fieldOrder={FIELD_ORDER}
      fillContainerHeight
      headerTranslationIds={TRANSLATION_IDS}
      resizable={false}
      rowActions={ROW_ACTIONS}
      rowClickCallback={handleRowClick}
      sortType="local"
    />
  );
};

SiteSubscriptionsTable.propTypes = {
  onRowClicked: PropTypes.func,
  preferredShortDateFormat: PropTypes.string,
  subscriptions: PropTypes.arrayOf(PropTypes.any),
  translate: PropTypes.func.isRequired,
};

SiteSubscriptionsTable.defaultProps = {
  onRowClicked: () => {},
  preferredShortDateFormat: '',
  subscriptions: [],
};

export default withLocalize(SiteSubscriptionsTable);
