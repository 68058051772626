import { canvasObjectTypes } from 'constants/app';
import get from 'lodash.get';

/* For handling the interpretation and display of graphic data,
   especially as generated by WEP
*/

// TODO: Fold in AdjustPrivacyZone functions

export const analyticsWepToRedux = data => {
  /* Where data is the 'analytics' object returned by the WEP camera-settings 'read'
     endpoint and the return data has the following shape:
     {
        ruleSensorId1: {
          event: {},
          roi: {},
          loi: null
        },
        ruleSensorId2: {
          event: {},
          roi: null,
          loi: {},
        },
      },
  */
  const { beams, events, rois } = data;
  const rules = {};
  if (events.val) {
    events.val.forEach(event => {
      const { sensorId } = event;
      const roi = rois.val ? rois.val.find(r => r.id === sensorId) : null;
      const loi =
        beams.val && beams.val.lois
          ? beams.val.lois.find(b => b.id === sensorId)
          : null;
      rules[sensorId] = {
        event,
        loi: loi || null,
        roi: roi || null, // want null, not undefined, if not found
      };
    });
  }
  return rules;
};

export const analyticsReduxToWep = streamOverlayObject => {
  /* Where data has the shape of an object stored in the streamOverlaysReducer
    and the return data is a shape that will be accepted by a POST
    to the WEP camera-settings endpoint
  */
  const { beamId, eventId, roiId, rules } = streamOverlayObject;
  const events = [];
  const rois = [];
  const lois = [];
  Object.keys(rules).forEach(key => {
    if (rules[key].event.name && rules[key].event.name.length > 0) {
      events.push(rules[key].event);
      if (rules[key].roi) rois.push(rules[key].roi);
      if (rules[key].loi) lois.push(rules[key].loi);
    }
  });
  const data = {
    analytics: {
      beams: {
        id: beamId,
        val: {
          lois: lois.map(l => {
            // Filter out type, etc.
            const { directional, id, point1, point2 } = l;
            return {
              directional,
              id,
              point1,
              point2,
            };
          }),
        },
      },
      events: {
        id: eventId,
        val: events,
      },
      rois: {
        id: roiId,
        val: rois.map(r => {
          // Filter out type, add hasDirectionality.
          const { directionAngle, id, region, rod } = r;
          if (!directionAngle) {
            return r;
          }
          // Server expect angle in -179 to 180 range
          // We're converting here before send
          const convertedAngle =
            directionAngle < 180
              ? 180 - directionAngle
              : (directionAngle - 180) * -1;
          return {
            directionAngle: convertedAngle,
            hasDirectionality: true,
            id,
            region,
            rod,
          };
        }),
      },
    },
  };
  return data;
};

export const generalWepToRedux = data => {
  /* Where data is the 'general' object returned by the WEP camera-settings 'read'
    endpoint and the return data has the following shape:
    {
      pId1:  {
          isSelected: bool,
          privacyZoneDimensions: { left, right, top, bottom },
          ...other metaData
        },
      pId2:  {
          isSelected: bool,
          privacyZoneDimensions: { left, right, top, bottom },
          ...other metadata
        },
    },
    */

  const rectArray = get(data, 'privacyZones.val.privacyZone') || [];
  const privacyZones = {};

  rectArray.forEach((r, i) => {
    privacyZones[i] = {
      id: i,
      isSelected: i === 0,
      privacyZoneDimensions: r.rect,
      type: canvasObjectTypes.privacy,
    };
  });
  return privacyZones;
};

export const generalReduxToWep = () => {
  /* Where data has the shape of the 'privacyZones' object stored in the streamOverlaysReducer
    and the return data is a shape that will be accepted by a POST
    to the WEP camera-settings endpoint
  */
};

export const currentRules = (rules, ruleEdits) => ({ ...rules, ...ruleEdits });

export const currentPrivacyZones = (privacyZones, privacyZoneEdits) => {
  const privacyZonesArray = [];
  const mergedPrivacyZones = { ...privacyZones, ...privacyZoneEdits };
  Object.keys(mergedPrivacyZones)
    .sort()
    .forEach(key => {
      if (mergedPrivacyZones[key] !== null) {
        privacyZonesArray.push(mergedPrivacyZones[key]);
      }
    });
  return privacyZonesArray;
};
