import { cloneDeep } from 'util/cameraSettingLinks';
// These are Back End values, not UI strings
export const ALWAYS_SCHEDULE = 'Always';
export const NEVER_SCHEDULE = 'Never';
export const NON_BUSINESS_HOURS_SCHEDULE = 'Non-Business Hours';

export const localizeScheduleObject = (schedule, translate) => {
  if (schedule.Name === ALWAYS_SCHEDULE && schedule.Type === ALWAYS_SCHEDULE) {
    return {
      ...schedule,
      Name: translate('SCHEDULES.SELECT_ALWAYS_OPTION'),
    };
  }
  if (schedule.Name === NEVER_SCHEDULE && schedule.Type === NEVER_SCHEDULE) {
    return {
      ...schedule,
      Name: translate('SCHEDULES.SELECT_NEVER_OPTION'),
    };
  }
  if (schedule.Name === NON_BUSINESS_HOURS_SCHEDULE) {
    return {
      ...schedule,
      Name: translate('SCHEDULES.SELECT_NON_BUSINESS_HOURS_OPTION'),
    };
  }
  return schedule;
};

export const localizeSchedules = (schedules, translate) =>
  schedules.map(schedule => localizeScheduleObject(schedule, translate));

export const localizeLocationSchedules = (data, translate) =>
  cloneDeep(data).map(item => {
    return {
      ...item,
      SecuritySchedule: localizeScheduleObject(
        item.SecuritySchedule,
        translate,
      ),
      HealthSchedule: localizeScheduleObject(item.HealthSchedule, translate),
    };
  });
