import React from 'react';
import { SVG_XMLNS, ACTIVE_COLOR_DEFAULT } from 'constants/app';

const IconAlarmStatus = props => {
  return (
    <div title={props.title} style={{ display: 'flex' }}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 17 19"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g fill={props.fillColor} transform="translate(-50, -7)">
          <path
            d="M63.5880392,17.9738827 L63.5880392,13.0968634 C63.5880392,9.7302115 60.8660779,7 57.509444,7 C54.1528101,7 51.4314381,9.7302115 51.4314381,13.0968634 L51.4314381,17.9738827 L49,22.850902 L54.5317279,22.850902 C54.8134117,24.2422314 56.0391488,25.2900009 57.509444,25.2900009 C58.9797392,25.2900009 60.2054763,24.2422314 60.4871601,22.850902 L66.018888,22.850902 L63.5880392,17.9738827 Z"
            id="Path"
          />
        </g>
      </svg>
    </div>
  );
};

IconAlarmStatus.defaultProps = {
  fillColor: ACTIVE_COLOR_DEFAULT,
  height: '19px',
  width: '17px',
  title: 'Alarm status',
};

export default IconAlarmStatus;
