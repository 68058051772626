// Libs
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { convertToLongDateFormat } from 'util/convertTimeTo';

// Components
import { cellText, highlight, tableCell } from 'sharedStyles/tables.css';

// Consts
import { DATE_FORMAT_MMM_D_Y } from 'constants/app';

const processText = (
  data,
  rowIndex,
  field,
  dateFormat,
  preferredLongDateFormat,
) => {
  const dateValue = data[rowIndex.idx][field];
  const defaultValue = Moment(dateValue).format(dateFormat);
  return convertToLongDateFormat(defaultValue, preferredLongDateFormat);
};
const DateCell = ({
  cellClassName,
  className,
  data,
  dateFormat,
  field,
  preferredLongDateFormat,
  rowIndex,
  rowsSelected,
  ...props
}) => (
  <div
    className={`${tableCell} ${rowsSelected.includes(rowIndex) ? highlight : ''}
        ${cellClassName || ''}$`}
    {...props}
  >
    <div className={className || cellText}>
      {processText(data, rowIndex, field, dateFormat, preferredLongDateFormat)}
    </div>
  </div>
);

DateCell.propTypes = {
  dateFormat: PropTypes.string,
  field: PropTypes.string.isRequired,
  preferredLongDateFormat: PropTypes.string,
  rowsSelected: PropTypes.arrayOf(PropTypes.any),
};

DateCell.defaultProps = {
  dateFormat: DATE_FORMAT_MMM_D_Y,
  rowsSelected: [],
  preferredLongDateFormat: ''
};

export default DateCell;
