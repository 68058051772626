// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import IconUpArrow from 'icons/IconUpArrow';

// Components
import { RadioGroup } from 'components';
import { Button, GroupLayout, Icon, ListView } from 'lib';

import { cloneDeep } from 'util/cameraSettingLinks';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import { IC_ARROW } from 'constants/iconNames';

// Utils
import * as poeTableConsts from './constants';
import * as poeTableStyles from './styles.css';

export default class PoETable extends React.Component {
  constructor(props) {
    super(props);
    const initialPowerButtons = this.getInitialPowerButtons();
    this.state = {
      hasChanges: false,
      powerButtons: initialPowerButtons,
      revertData: initialPowerButtons,
    };
  }

  getInitialPowerButtons = () => {
    const { ports } = this.props;
    const powerButtons = [];
    ports.forEach(port => {
      const isOffSelected = !port.power;
      const isAutoSelected = port.power && port.budget === 0;
      const isManualSelected = port.power && port.budget > 0;

      const buttonSet = [
        {
          budget: 0,
          power: false,
          selected: isOffSelected,
          translateLabel: 'SERVER.POE.DATA.POWER_OFF',
          value: 'off',
        },
        {
          budget: 0,
          classbudget: port.classbudget,
          power: true,
          selected: isAutoSelected,
          translateLabel: 'SERVER.POE.DATA.POWER_AUTO',
          value: 'auto',
        },
        {
          budget: port.budget || port.classbudget || 0,
          power: true,
          selected: isManualSelected,
          translateLabel: 'SERVER.POE.DATA.POWER_MANUAL',
          value: 'manual',
        },
      ];

      powerButtons.push(buttonSet);
    });
    return powerButtons;
  };

  renderConsumption = port => {
    const { classbudget: budget, usage } = port;
    if (usage !== undefined) {
      return (
        <Translate
          data={{ budget, usage }}
          id="SERVER.POE.DATA.CONSUMPTION_MESSAGE"
        />
      );
    }
    return <span />;
  };

  renderStatus = port => {
    const isPowered =
      port.powerStatus === poeTableConsts.cameraPoEStatus.POWERED;
    const isHighPowered =
      port.powerStatus === poeTableConsts.cameraPoEStatus.HIGHPOWERED;
    const isUnpowered =
      port.powerStatus === poeTableConsts.cameraPoEStatus.UNPOWERED;
    const isGreen = isPowered || isHighPowered;

    let translation = 'SERVER.POE.DATA.DISCONNECTED';
    if (isUnpowered) translation = 'SERVER.POE.DATA.UNPOWERED';
    if (isHighPowered) translation = 'SERVER.POE.DATA.HIGH_POWERED';
    if (isPowered) translation = 'SERVER.POE.DATA.POWERED';

    return (
      <span className={`${isGreen ? poeTableStyles.green : ''}`}>
        <Translate id={translation} />
      </span>
    );
  };

  renderArrowIcon = port => {
    const isCameraUp = port.status === poeTableConsts.cameraPoEStatus.UP;

    return (
      <Icon
        color={
          isCameraUp
            ? poeTableConsts.colors.UP_COLOR
            : poeTableConsts.colors.DOWN_COLOR
        }
        iconClass={
          !isCameraUp ? poeTableStyles.downArrow : poeTableStyles.upArrow
        }
        id={IC_ARROW}
      />
    );
  };

  handlePowerChange = (evt, row) => {
    const { showBudgetExceedMessage } = this.props;
    const { powerButtons } = this.state;
    const clickedButton = evt.target.value;
    const powerButtonsRow = powerButtons[row];
    const powerButtonsSelected = powerButtonsRow.find(e => e.selected === true);
    const noChanges = clickedButton === powerButtonsSelected.value;
    if (noChanges) {
      return;
    }

    const newState = cloneDeep(powerButtons).map((buttonSet, index) => {
      if (index !== row) {
        return buttonSet;
      }

      return buttonSet.map(button => {
        if (clickedButton === button.value) {
          button.selected = true;
          button.budget = clickedButton === 'manual' ? button.budget || 1 : 0;
        } else {
          button.selected = false;
        }
        return button;
      });
    });

    const newButton = newState[row].find(item => item.value === clickedButton);

    if (!this.isWithinBudget(row, newButton.budget)) {
      showBudgetExceedMessage();
    }

    this.setState({
      hasChanges: true,
      powerButtons: [...newState],
    });
  };

  handleWattsChange = (row, evt) => {
    const { showBudgetExceedMessage } = this.props;
    const MANUAL_BTN = 2;
    const MAX_WATTS = 128;
    const MIN_WATTS = 1;
    let newValue = +evt.target.value;

    if (newValue > MAX_WATTS) {
      newValue = MAX_WATTS;
    } else if (newValue < MIN_WATTS) {
      newValue = MIN_WATTS;
    }

    if (!this.isWithinBudget(row, newValue)) {
      showBudgetExceedMessage();
    }

    this.setState(oldState => {
      const newState = cloneDeep(oldState.powerButtons);
      newState[row][MANUAL_BTN].budget = newValue;
      return {
        hasChanges: true,
        powerButtons: newState,
      };
    });
  };

  isWithinBudget = (row, newBudget) => {
    const poeData = this.getPoEDataFromState();
    const MAX_TOTAL_BUDGET = 128;
    const totalBudget = poeData.reduce((sum, item, index) => {
      if (index === row) {
        return sum + newBudget;
      }
      if (item.power) {
        return sum + item.budgetWatch;
      }
      return sum;
    }, 0);

    return totalBudget <= MAX_TOTAL_BUDGET;
  };

  getManualInputs = rowNumber => {
    const { powerButtons } = this.state;
    const currentButtons = powerButtons[rowNumber];
    const currentBtn = currentButtons.find(btn => btn.selected);
    const showManualInput =
      currentBtn.selected && currentBtn.value === 'manual';
    const { budget } = currentBtn;
    if (!showManualInput) {
      return undefined;
    }

    return (
      <div className={poeTableStyles.show}>
        <input
          className={poeTableStyles.manualWattsInput}
          max="128"
          min="1"
          onChange={this.handleWattsChange.bind(this, rowNumber)}
          onKeyPress={this.handleWattsChange.bind(this, rowNumber)}
          required
          type="number"
          value={budget || 1}
        />
        <div className={poeTableStyles.manualWattsLabel}>
          <Translate id="SERVER.POE.DATA.CONSUMPTION_UNIT" />
        </div>
      </div>
    );
  };

  renderToggleButtons = portIndex => {
    const { powerButtons } = this.state;
    const powerButtonsRow = powerButtons[portIndex];
    const powerButtonsSelected = powerButtonsRow.find(e => e.selected);
    return (
      <div className={`${poeTableStyles.show}`}>
        <RadioGroup
          containerClassName={poeTableStyles.alignCenter}
          onChange={evt => {
            this.handlePowerChange(evt, portIndex);
          }}
          options={poeTableConsts.POWER_TOGGLE_RADIO_OPTIONS}
          value={powerButtonsSelected.value}
        />
        {this.getManualInputs(portIndex)}
      </div>
    );
  };

  getPoEDataFromState = () => {
    const { powerButtons } = this.state;
    const poeData = [];
    powerButtons.forEach(buttonSet => {
      const selectedButtons = buttonSet.filter(button => button.selected);
      selectedButtons.forEach(button => {
        poeData.push({
          budget: +button.budget,
          budgetWatch: +button.classbudget || +button.budget || 0,
          power: button.power,
        });
      });
    });
    return poeData;
  };

  renderSpeed = port => {
    const isLinkUp = port.status === poeTableConsts.cameraPoEStatus.UP;
    if (isLinkUp) {
      return (
        <Translate
          data={{ speed: port.speed || '-' }}
          id="SERVER.POE.DATA.SPEED_MESSAGE"
        />
      );
    }
    return <span />;
  };

  onCancel = () => {
    const { revertData } = this.state;
    this.setState({
      hasChanges: false,
      powerButtons: revertData,
    });
  };

  onSave = () => {
    const { hideModal, showModal, updatePoE } = this.props;
    const { powerButtons } = this.state;
    const modalProps = {
      handleCancel: () => {
        hideModal();
      },
      message: <Translate id="SERVER.POE.CONFIRM.MESSAGE" />,
      onOkClick: () => {
        const poeData = this.getPoEDataFromState();
        poeData.forEach(item => delete item.budgetWatch);
        updatePoE(poeData);
        modalProps.hideModal = true;
        hideModal();
        this.setState({
          hasChanges: false,
          revertData: [...powerButtons],
        });
      },
      textCancel: 'BUTTONS.CANCEL',
      textConfirm: 'BUTTONS.OK',
      title: <Translate id="SERVER.POE.CONFIRM.TITLE" />,
    };

    showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  render() {
    const { ports } = this.props;
    const { hasChanges } = this.state;
    const translateHeader = {
      cameraName: 'SERVER.POE.TABLE_HEAD.CAMERA_NAME',
      consumption: 'SERVER.POE.TABLE_HEAD.CONSUMPTION',
      link: 'SERVER.POE.TABLE_HEAD.LINK',
      port: 'SERVER.POE.TABLE_HEAD.PORT',
      power: 'SERVER.POE.TABLE_HEAD.POWER',
      speed: 'SERVER.POE.TABLE_HEAD.SPEED',
      status: 'COMMON.STATUS',
    };
    const fieldOrder = [
      'port',
      'cameraName',
      'power',
      'consumption',
      'speed',
      'link',
      'status',
    ];
    const data = ports
      ? ports.map((port, index) => ({
          cameraName: port.cameraName || port.hwaddr,
          consumption: this.renderConsumption(port),
          link: this.renderArrowIcon(port),
          port: parseInt(port.port, 10) + 1,
          power: this.renderToggleButtons(index),
          speed: this.renderSpeed(port),
          status: this.renderStatus(port),
        }))
      : [];
    return (
      <div>
        <ListView
          cellWidths={poeTableConsts.POE_CELL_WIDTHS}
          data={data}
          fieldOrder={fieldOrder}
          headerTranslationIds={translateHeader}
          hideFilter
        />
        <GroupLayout horizontalPositioning="center" verticalSpacing="large">
          <Button
            key="generalSettingsButtonRevert"
            buttonType="primary"
            disabled={!hasChanges}
            id="generalSettingsButtonRevert"
            inputType="button"
            onClick={this.onCancel}
            text={<Translate id="BUTTONS.REVERT" />}
          />
          <Button
            key="generalSettingsButtonSave"
            buttonType="primary"
            disabled={!hasChanges}
            id="generalSettingsButtonSave"
            inputType="button"
            onClick={this.onSave}
            text={<Translate id="BUTTONS.SAVE" />}
          />
        </GroupLayout>
      </div>
    );
  }
}

PoETable.propTypes = {
  hideModal: PropTypes.func.isRequired,
  ports: PropTypes.arrayOf(PropTypes.object).isRequired,
  showBudgetExceedMessage: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updatePoE: PropTypes.func.isRequired,
};
