import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconFocusAuto = props => {
  return (
    <div className={props.className} title={props.translate(props.title)}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path
            transform="translate(1 1)"
            d="M5.31 11h-.944l-.375-.976H2.274L1.92 11H1l1.673-4.295h.917zM3.713 9.3L3.12 7.708l-.58 1.594zm2.05-2.595h.868v2.326c0 .37.01.609.032.718a.696.696 0 0 0 .265.423c.14.107.33.16.573.16.246 0 .432-.05.557-.15.125-.101.2-.225.225-.371.026-.147.038-.39.038-.73V6.705h.867v2.256c0 .516-.023.88-.07 1.093a1.21 1.21 0 0 1-.26.539 1.31 1.31 0 0 1-.504.35c-.211.087-.487.13-.827.13-.41 0-.72-.047-.933-.142a1.334 1.334 0 0 1-.502-.369 1.183 1.183 0 0 1-.243-.476c-.057-.246-.085-.61-.085-1.09zM11.07 11V7.432H9.795v-.727h3.413v.727h-1.271V11zm2.414-2.121c0-.438.066-.805.197-1.102.097-.218.23-.415.4-.589.169-.173.354-.302.555-.386.267-.114.576-.17.926-.17.632 0 1.139.196 1.519.589.38.392.57.938.57 1.637 0 .694-.19 1.236-.566 1.628-.377.391-.88.587-1.512.587-.638 0-1.146-.195-1.523-.584-.377-.39-.566-.926-.566-1.61zm.894-.03c0 .487.112.856.337 1.107.224.25.51.376.855.376.346 0 .63-.124.851-.374.222-.249.333-.622.333-1.12 0-.492-.108-.86-.324-1.102-.216-.242-.502-.363-.86-.363-.357 0-.645.123-.864.368-.219.245-.328.614-.328 1.109zM2 12H0v4c0 1.1.9 2 2 2h4v-2H2v-4zM2 2h4V0H2C.9 0 0 .9 0 2v4h2V2zm14-2h-4v2h4v4h2V2c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4z"
            id="a_ptz_focus_auto"
          />
        </defs>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#a_ptz_focus_auto" />
      </svg>
    </div>
  );
};

IconFocusAuto.defaultProps = {
  className: null,
  width: '48px',
  height: '48px',
  fill: 'white',
  title: 'PTZ.BUTTONS.AUTO_FOCUS',
};

export default withLocalize(IconFocusAuto);
