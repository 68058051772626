import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Icon } from 'lib';

// Styles
import { IC_CHEVRON_DOWN, IC_CHEVRON_UP } from './constants';
import * as styles from './AccordionItem.css';

// Constants

// Helper Components
const ExpansionIndicator = ({ expanded, onClick }) => {
  return (
    <div
      className={`${styles.toggleIndicator} ${
        expanded
          ? styles.expandedToggleIndicator
          : styles.collapsedToggleIndicator
      } `}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      {expanded ? (
        <Icon iconClass={styles.arrowIcon} id={IC_CHEVRON_UP} />
      ) : (
        <Icon iconClass={styles.arrowIcon} id={IC_CHEVRON_DOWN} />
      )}
    </div>
  );
};

ExpansionIndicator.propTypes = {
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

ExpansionIndicator.defaultProps = {
  expanded: false,
  onClick: () => {},
};

export default ExpansionIndicator;
