import PropTypes from 'prop-types';
import React from 'react';
import * as CameraTypes from 'constants/cameraTypes';
import get from 'lodash.get';

import {
  coloredDot,
  offlineIcon,
  onlineIcon,
} from 'sharedStyles/connectionStatus.css';
import {
  cameraErrorTextStyle,
  connectedCameraError,
  connectedIcon,
  connectedLabel,
  connectedLabelIcon,
} from './styles.css';

import * as consts from './constants';

const ConnectedStatus = ({
  connected,
  statusText,
  data = {},
  errorClass,
  onSubmit = {},
}) => {
  let additionalStyle = '';
  if (get(data, 'cameraStatus.state')) {
    additionalStyle =
      data.cameraStatus.realState === CameraTypes.CAMERA_AUTH_FAILED
        ? cameraErrorTextStyle
        : '';
  }

  const cameraErrorstyle = () => {
    if (errorClass === null) {
      return connectedCameraError;
    }
    if (errorClass !== null) {
      return `${connectedCameraError} ${errorClass}`;
    }
    return '';
  };
  const icon = (
    <div
      className={
        connected
          ? `${coloredDot} ${onlineIcon}`
          : `${coloredDot} ${offlineIcon}`
      }
    />
  );

  const labelledIcon = (
    <div
      className={connectedLabelIcon}
      id={
        connected
          ? consts.deviceConnectedString
          : consts.deviceDisconnectedString
      }
    >
      <div className={connectedIcon}>{icon}</div>
      <div className={`${connectedLabel} ${additionalStyle}`}>{statusText}</div>
    </div>
  );

  let footerContent = labelledIcon;

  const camState = get(data, 'cameraStatus.state');
  if (camState) {
    const camRealState = get(data, 'cameraStatus.realState');
    if (
      camState === CameraTypes.CAMERA_DISCONNECTED &&
      camRealState === CameraTypes.CAMERA_AUTH_FAILED
    ) {
      footerContent = (
        <button
          className={
            data.cameraStatus.realState === CameraTypes.CAMERA_AUTH_FAILED
              ? cameraErrorstyle()
              : ''
          }
          onClick={() => {
            onSubmit(data.cameraId, data.deviceId);
          }}
          type="button"
        >
          {labelledIcon}
        </button>
      );
    }
  }
  return <>{footerContent}</>;
};

ConnectedStatus.propTypes = {
  connected: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any),
  errorClass: PropTypes.string,
  onSubmit: PropTypes.func,
  statusText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ConnectedStatus.defaultProps = {
  connected: false,
  data: null,
  errorClass: null,
  onSubmit: null,
  statusText: null,
};

export default ConnectedStatus;
