// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Iframe from 'react-iframe';
import { withLocalize } from 'react-localize-redux';

// Components
import { Checkbox } from 'components';
import { LanguageSelect } from 'containers';
import { Button, GroupLayout } from 'lib';

// Constants
import { templateLanguageToken } from 'constants/links';

// Styles
import {
  cameraLoginFormWrapper,
  cameraLoginTopRow,
  normalLabel,
  tabButtonGroup,
  // eulaDocStyle,
  checkboxAcceptedAgreement,
  eulaLanguageSelect,
} from './styles.css';

const EulaModal = props => {
  const {
    EulaModalProps,
    enableAcceptButton,
    handleCancel,
    handleSubmit,
    language,
    onEnableEulaAccept,
    onSubmit,
    translate,
  } = props;

  const eulaUrl =
    EulaModalProps.EulaResponseObject &&
    EulaModalProps.EulaResponseObject.EulaUrl.replace(
      templateLanguageToken,
      language.code,
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cameraLoginFormWrapper}>
        <div className={cameraLoginTopRow}>
          <label className={normalLabel}>
            <Iframe
              height="400px"
              position="absolute"
              styles={{ fontFamily: 'Helvetica', fontSize: '12px' }}
              url={eulaUrl}
              width="620px"
            />
          </label>
        </div>
        <div className={checkboxAcceptedAgreement}>
          <Checkbox
            alignBaseline
            checked={enableAcceptButton}
            id="idAcceptEulaCheckbox"
            label={translate('EULA.ACCEPTANCE')}
            onChange={onEnableEulaAccept}
          />
        </div>
        <div className={tabButtonGroup}>
          <GroupLayout horizontalPositioning="center">
            <Button
              key="btnEulaDecline"
              buttonType="primary"
              disabled={false}
              id="btnEulaDecline"
              inputType="button"
              onClick={handleCancel}
              text={translate('BUTTONS.DECLINE')}
            />
            <Button
              key="btnEulaAccept"
              buttonType="primary"
              disabled={!enableAcceptButton}
              id="btnEulaAccept"
              inputType="submit"
              text={translate('BUTTONS.ACCEPT')}
            />
          </GroupLayout>
          <LanguageSelect applyOnChange className={eulaLanguageSelect} />
        </div>
      </div>
    </form>
  );
};

EulaModal.defaultProps = {
  EulaModalProps: {},
  enableAcceptButton: false,
  handleCancel() {},
  handleSubmit() {},
  onEnableEulaAccept() {},
  onSubmit() {},
  translate() {},
};

EulaModal.propTypes = {
  EulaModalProps: PropTypes.objectOf(PropTypes.any),
  enableAcceptButton: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  language: PropTypes.shape({
    active: PropTypes.bool,
    code: PropTypes.string,
    fullCode: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onEnableEulaAccept: PropTypes.func,
  onSubmit: PropTypes.func,
  translate: PropTypes.func,
};

const ReduxForm = reduxForm({
  enableReinitialize: true,
  form: 'EULA_FORM',
})(EulaModal);

const EULAFORM = connect()(ReduxForm);

export default withLocalize(EULAFORM);
