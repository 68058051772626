import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Translate } from 'react-localize-redux';
import { Button, GroupLayout, ListView } from 'lib';
import { barButton } from './Oasis.css';
import OasisModule from './OasisModule';

function SignalRModule({ clearLog, data, updateModuleState }) {
  function dumpSignal(rowData) {
    console.info(JSON.stringify(rowData), rowData);
  }

  const CUSTOM_CELLS = {
    timestamp: rowData => {
      return moment(rowData.timestamp).calendar();
    },
  };
  return (
    <OasisModule
      actionButtons={[
        data.length > 0 ? (
          <Button
            key="clear-signals"
            buttonClass={barButton}
            inputType="button"
            onClick={e => {
              e.stopPropagation();
              clearLog();
            }}
            size="small"
            text="Clear"
          />
        ) : (
          ''
        ),
      ]}
      indicatorValue={data.length}
      moduleId="oasis-signals"
      theme="lightBlue"
      title="Signals"
      updateModuleState={updateModuleState}
    >
      <ListView
        bodyHeight={125}
        clickableRows
        customCells={CUSTOM_CELLS}
        data={data}
        defaultDisplay="table"
        defaultSortField="timestamp"
        fieldOrder={['signalType', 'timestamp']}
        headerLabels={{
          signalType: 'Type',
          signalVerb: 'Verb',
          timestamp: 'Time',
        }}
        inlineDetailLabel={rowData => (
          <Translate
            data={{ name: rowData.signalType }}
            id="DEV_DO_NOT_TRANSLATE.SIGNAL_DETAIL_LABEL"
          />
        )}
        resizable
        resizableFrom="top"
        rowClickCallback={dumpSignal}
        sortType="local"
      />
      <GroupLayout dropshadow verticalCenter verticalSpacing="none">
        <Button inputType="button" onClick={clearLog} text="Clear Log" />
      </GroupLayout>
    </OasisModule>
  );
}
SignalRModule.propTypes = {
  clearLog: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateModuleState: PropTypes.func.isRequired,
};
export default SignalRModule;
