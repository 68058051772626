import urlBuilder from 'queryBuilder/url';
import handleJsonErrors from 'util/handleJsonErrors';
import {
  runGetActionReturningJSON,
  sendDeleteRequestReturningJSON,
  sendGetRequestReturningJSON,
  sendMultiPutRequestReturningJSON,
  sendPostRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { showMessage } from './pageMessage';
import { isFetching, isFetchingData } from './common';
import { removeDeletedCustomer, updateCustomerOrganizations } from './user';

// Pure Redux action creators

export function receiveIntegrationConfigs(integrationConfigs) {
  return {
    integrationConfigs,
    type: types.RECEIVE_INTEGRATION_CONFIGS,
  };
}

export function receiveTestConnectionResult(configurationId, value) {
  return {
    configurationId,
    type: types.SET_CONNECTION_TEST_RESULT,
    value,
  };
}

const receiveCanDeleteIntegrationConfig = (id, canDelete, sites) => {
  return {
    canDelete,
    id,
    sites,
    type: types.CAN_DELETE_INTEGRATION_CONFIG,
    // Probably one extra field here for other possible errors
  };
};

export function receiveIntegrationSubscriberSummary(
  integrationSubscriberSummary,
) {
  return {
    integrationSubscriberSummary,
    type: types.RECEIVE_INTEGRATION_SUBSCRIBER_SUMMARY,
  };
}

export function receiveIntegrationSubscriberSites(integrationSubscriberSites) {
  return {
    integrationSubscriberSites,
    type: types.RECEIVE_INTEGRATION_SUBSCRIBER_SITES,
  };
}

export function receiveIntegrationTypes(integrationTypes) {
  return {
    integrationTypes,
    type: types.RECEIVE_INTEGRATION_TYPES,
  };
}

export function setMonitoringCenter(siteId, updatedMonitoringCenter) {
  return {
    siteId,
    type: types.SET_MONITORING_CENTER,
    updatedMonitoringCenter,
  };
}
export function setSecurityMonitoring(siteId, updatedStatus) {
  return {
    siteId,
    type: types.SET_SECURITY_MONITORING,
    updatedStatus,
  };
}

export function setSiteConfigurationString(siteId, configurationString) {
  return {
    configurationString,
    siteId,
    type: types.SET_SITE_CONFIGURATION_STRING,
  };
}

// Async action creators

export function getIntegrationSubscriberSummary() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY));
    const url = urlBuilder(types.GET_INTEGRATION_SUBSCRIBER_SUMMARY);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(
          isFetching(types.IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY, false),
        );
        dispatch(receiveIntegrationSubscriberSummary(json));
      })
      .catch(error => {
        dispatch(
          isFetching(types.IS_FETCHING_INTEGRATION_SUBSCRIBER_SUMMARY, false),
        );
        avoLogError('Error getting monitoring subscriber summary', error);
      });
  };
}

export function getIntegrationSubscriberSites(subscriberId) {
  return dispatch => {
    const url = urlBuilder(
      types.GET_INTEGRATION_SUBSCRIBER_SITES,
      subscriberId,
    );
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveIntegrationSubscriberSites(json));
      })
      .catch(err => {
        avoLogError('Error getting integration subscriber sites', err);
      });
  };
}

export function updateIntegrationSubscriberSites(
  subscriberId,
  subscriberSites,
) {
  return dispatch => {
    const url = urlBuilder(
      types.GET_INTEGRATION_SUBSCRIBER_SITES,
      subscriberId,
    );
    sendMultiPutRequestReturningJSON(url, subscriberSites)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(
              messageTypes.UPDATE_INTEGRATION_SUBSCRIBER_SITES_SUCCESS,
              null,
              null,
              {
                messageStyle: messageStyleStrings.success,
                translateBody:
                  'ORGANIZATIONS.UPDATE_INTEGRATION_SUBSCRIBER_SITES_SUCCESS_MESSAGE',
              },
            ),
          );
        }
      })
      .catch(err => {
        dispatch(
          showMessage(
            messageTypes.UPDATE_INTEGRATION_SUBSCRIBER_SITES_ERROR,
            null,
            null,
            {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'ORGANIZATIONS.UPDATE_INTEGRATION_SUBSCRIBER_SITES_ERROR_MESSAGE',
              translateBodyData: {
                error: err,
              },
            },
          ),
        );
        avoLogError('Error updating subscriber sites', err);
      });
  };
}

export function getIntegrationConfigs() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_INTEGRATION_CONFIGS));
    const url = urlBuilder(types.GET_INTEGRATION_CONFIGS);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveIntegrationConfigs(json.Items));
      })
      .catch(error => {
        avoLogError('Error getting integration configs', error);
      })
      .then(() => {
        dispatch(
          isFetching(types.IS_FETCHING_DEALER_INTEGRATION_CONFIGS, false),
        );
      });
  };
}

export function enableIntegrationConfigs(id, enable) {
  return dispatch => {
    const url = urlBuilder(types.ENABLE_INTEGRATION_CONFIG, id);
    sendPostRequestReturningJSON(url, enable)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: enable
                ? 'INTEGRATIONS.DISABLE_CONNECTION.ENABLE_SUCCESS'
                : 'INTEGRATIONS.DISABLE_CONNECTION.DISABLE_SUCCESS',
              translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
            }),
          );
        }
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, err.message, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
        dispatch(getIntegrationConfigs());
        avoLogError('Error editing integration config', err);
      });
  };
}

export function editIntegrationConfiguration(
  id,
  integrationConfig,
  successTranslateBody, // Custom success string var for enable/disable
) {
  return dispatch => {
    const url = urlBuilder(types.EDIT_INTEGRATION_CONFIG, id);
    sendPutRequestReturningJSON(url, integrationConfig)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                successTranslateBody ||
                'INTEGRATIONS.EDIT_INTEGRATION_CONFIG_SUCCESS',
              translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
            }),
          );
          dispatch(getIntegrationSubscriberSummary());
        }
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, err.message, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
        // If there was an error, make sure enabled status is correct with the server
        dispatch(getIntegrationConfigs());
        avoLogError('Error editing integration config', err);
      });
  };
}

export function createIntegrationConfiguration(params) {
  return dispatch => {
    const url = urlBuilder(types.CREATE_INTEGRATION_CONFIG);
    sendPostRequestReturningJSON(url, params)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: 'INTEGRATIONS.CREATE_INTEGRATION_CONFIG_SUCCESS',
              translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
            }),
          );
        }
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, err.message, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
        avoLogError('Error creating integration config', err);
      });
  };
}

export function testIntegrationConnection(configurationId) {
  return dispatch => {
    const url = urlBuilder(types.GET_CONNECTION_TEST_RESULT, configurationId);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: 'INTEGRATIONS.TEST_CONNECTION_SUCCESS',
              translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
            }),
          );
          dispatch(receiveTestConnectionResult(configurationId, json));
        }
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'INTEGRATIONS.TEST_CONNECTION_ERROR',
            translateHeader: 'GENERAL_MESSAGES.ERROR_HEADER',
          }),
        );
        avoLogError('Error testing integration config', ex);
      });
  };
}

export function canDeleteIntegrationConfig(integrationConfigurationId) {
  return dispatch => {
    const url = urlBuilder(
      types.CAN_DELETE_INTEGRATION_CONFIG,
      integrationConfigurationId,
    );
    sendPostRequestReturningJSON(url)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else if (
          json.response.ResponseCode === 'IntegrationConfigurationInUse'
        ) {
          dispatch(
            receiveCanDeleteIntegrationConfig(
              integrationConfigurationId,
              false,
              json.response.LinkedSiteIntegrationConfigurations,
            ),
          );
        } else {
          dispatch(
            receiveCanDeleteIntegrationConfig(integrationConfigurationId, true),
          );
        }
      })
      .catch(err => {
        dispatch(
          receiveCanDeleteIntegrationConfig(
            integrationConfigurationId,
            false,
            [],
          ),
        );
        avoLogError(
          'Error determining if integration config can be deleted',
          err,
        );
      });
  };
}

export function deleteIntegrationConfig(integrationConfigurationId) {
  return dispatch => {
    const url = urlBuilder(
      types.DELETE_INTEGRATION_CONFIG,
      integrationConfigurationId,
    );
    return sendPostRequestReturningJSON(url)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        } else {
          dispatch(
            showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: 'INTEGRATIONS.DELETE_INTEGRATION.DELETE_SUCCESS',
              translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
            }),
          );
          dispatch(getIntegrationConfigs());
        }
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, err.message, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'INTEGRATIONS.DELETE_INTEGRATION.DELETE_ERROR',
            translateHeader: 'GENERAL_MESSAGES.ERROR_HEADER',
          }),
        );
        avoLogError('Error deleting integration config', err);
      });
  };
}

export function getIntegrationTypes() {
  return dispatch =>
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_INTEGRATION_TYPES,
      onError: err => {
        avoLogError('Error fetching integration types', err);
      },
      onSuccess: json => {
        dispatch(receiveIntegrationTypes(json.Items));
      },
      url: urlBuilder(types.GET_INTEGRATION_TYPES),
    });
}

export function deleteOrganization(orgId, filterId, queryOption) {
  return dispatch => {
    dispatch(
      isFetchingData(types.DELETE_ORGANIZATION, true, { fetchScope: orgId }),
    );
    const url = urlBuilder(types.DELETE_ORGANIZATION, orgId);
    sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.DELETE_ORGANIZATION, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'ORGANIZATIONS.DELETE_ORGANIZATION_SUCCESS_MESSAGE',
            translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
          }),
        );
        dispatch(updateCustomerOrganizations(queryOption, filterId, orgId));
        dispatch(removeDeletedCustomer(orgId));
      })
      .catch(error => {
        dispatch(
          showMessage(messageTypes.DELETE_ORGANIZATION, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'ORGANIZATIONS.DELETE_ORGANIZATION_ERROR_MESSAGE',
            translateBodyData: {
              error,
            },
            translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
          }),
        );
      })
      .finally(() => {
        dispatch(
          isFetchingData(types.DELETE_ORGANIZATION, false, {
            fetchScope: orgId,
          }),
        );
      });
  };
}
