// Libs
import React, { useEffect, useState } from 'react';
import renderIf from 'render-if';
import PropTypes from 'prop-types';

// Utils
import { Translate } from 'react-localize-redux';

// Containers
import { PageMessage } from 'containers';

// Constants
import { TEMPLATES_MESSAGE } from 'constants/MessageTypes';
import {
  CamerasTable,
  RecordingScheduleSelectable,
  RecordingTemplateSelectable,
  TemplateList,
} from 'components';
import { Button, EmptyPlaceholder, GroupLayout } from 'lib';

// Styles
import {
  poeBarColumn,
  scheduleGrid,
  tabSubheader,
  templateGrid,
  topContent,
} from '../styles.css';
import { scheduleDivider } from './styles.css';

// Constants
import * as consts from '../constants';

const emptyGrid = (cols, rows) => {
  const grid = [];
  let row = [];
  for (let i = 0; i <= rows * cols; i += 1) {
    if (i !== 0 && i % cols === 0) {
      grid.push(row);
      row = [];
    }
    row.push(false);
  }
  return grid;
};

const ServerDetailRecordingSchedule = props => {
  const {
    actions,
    cameras,
    deleteTemplate,
    groupId,
    initialState,
    isDirty,
    isFetchingTemplates,
    recordingSchedules,
    recordingSchedulesGrid,
    saveTemplate,
    selectedRecordingTemplate,
    selectedTemplateId,
    setSelectedTemplateId,
    startOfWeek,
    templates,
    usingRealTemplateIds,
  } = props;
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (saving && usingRealTemplateIds) {
      actions.saveRecordingSchedules(recordingSchedules, groupId);
      setSaving(false);
    }
  }, [actions, groupId, recordingSchedules, saving, usingRealTemplateIds]);

  const onCancel = () => {
    actions.getTemplates(groupId);
    actions.getRecordingSchedules(groupId);
  };

  const getDeletedTemplates = () => {
    const deletedTemp = [];
    const currentTemplate = [];
    templates.forEach(
      temp =>
        currentTemplate.push(temp.id) || currentTemplate.push(temp.tempId),
    );
    initialState.current.forEach(temp => {
      if (!currentTemplate.includes(temp.id)) {
        deletedTemp.push(temp.id);
      }
    });
    return deletedTemp;
  };

  const onSave = () => {
    saveTemplate(
      templates,
      groupId,
      getDeletedTemplates(),
      recordingSchedules,
    ).then(() => {
      setSaving(true);
    });
  };

  const onCreateTemplate = template => {
    actions.createTemplate(template, groupId);
  };

  const scheduleContent = (
    <>
      <PageMessage messageType={TEMPLATES_MESSAGE} />
      <div className={tabSubheader}>
        <Translate id="DEVICE_DETAILS.RECORDING_SCHEDULE" />
      </div>
      <Translate id="DEVICE_DETAILS.RECORDING_SCHEDULE_INSTRUCTIONS" />
      <div className={topContent}>
        <TemplateList
          createTemplate={onCreateTemplate}
          deleteTemplate={deleteTemplate}
          editTemplateName={actions.editTemplateName}
          groupId={groupId}
          isFetchingTemplates={isFetchingTemplates}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          templates={templates}
        />
        <div className={templateGrid}>
          <RecordingTemplateSelectable
            groupId={groupId}
            selectedRecordingTemplate={selectedRecordingTemplate}
            updateRecordingTemplate={actions.updateRecordingTemplate}
          />
        </div>
      </div>
      <div className={scheduleDivider}>
        <span />
      </div>
      {renderIf(cameras.length > 0)(
        <div className={topContent}>
          <CamerasTable
            cameraIconFill={consts.cameraIconConstants.fillColors}
            cameraIconHeight={consts.cameraIconConstants.HEIGHT}
            cameraIconWidth={consts.cameraIconConstants.WIDTH}
            camerasArray={cameras}
          />

          <div className={scheduleGrid}>
            <RecordingScheduleSelectable
              camera={cameras}
              camerasArrayLength={cameras.length}
              gridMatrix={emptyGrid(7, cameras.length)}
              groupId={groupId}
              recordingSchedulesGrid={recordingSchedulesGrid}
              selectedRecordingTemplate={selectedRecordingTemplate}
              startOfWeek={startOfWeek}
              updateRecordingSchedule={actions.updateRecordingSchedule}
            />
          </div>
        </div>,
      )}
    </>
  );

  return (
    <div>
      <div className={poeBarColumn}>
        {isFetchingTemplates !== false ? (
          <EmptyPlaceholder isFetching />
        ) : (
          <>
            {scheduleContent}
            <GroupLayout horizontalPositioning="center" verticalSpacing="large">
              <Button
                key="generalSettingsButtonRevert"
                buttonType="primary"
                disabled={!isDirty}
                id="generalSettingsButtonRevert"
                inputType="button"
                onClick={onCancel}
                text={<Translate id="BUTTONS.REVERT" />}
              />
              <Button
                key="generalSettingsButtonSave"
                buttonType="primary"
                disabled={!isDirty}
                id="generalSettingsButtonSave"
                inputType="button"
                onClick={onSave}
                text={<Translate id="BUTTONS.SAVE" />}
              />
            </GroupLayout>
          </>
        )}
      </div>
    </div>
  );
};

ServerDetailRecordingSchedule.defaultProps = {
  recordingSchedules: undefined,
  selectedRecordingTemplate: null,
  selectedTemplateId: null,
  startOfWeek: 0,
  templates: undefined,
};

ServerDetailRecordingSchedule.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  initialState: PropTypes.shape({}).isRequired,
  isDirty: PropTypes.bool.isRequired,
  isFetchingTemplates: PropTypes.bool.isRequired,
  recordingSchedules: PropTypes.arrayOf(PropTypes.shape({})),
  recordingSchedulesGrid: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  saveTemplate: PropTypes.func.isRequired,
  selectedRecordingTemplate: PropTypes.shape({}),
  selectedTemplateId: PropTypes.string,
  setSelectedTemplateId: PropTypes.func.isRequired,
  startOfWeek: PropTypes.number,
  templates: PropTypes.arrayOf(PropTypes.shape({})),
  usingRealTemplateIds: PropTypes.bool.isRequired,
};

export default ServerDetailRecordingSchedule;
