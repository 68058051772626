import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR_DEFAULT } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { SUSPEND_ICON_ID } from './constants';

const IconSuspendLocation = ({
  className,
  fill,
  height,
  title,
  translate,
  width,
}) => {
  return (
    <div
      className={className}
      id={SUSPEND_ICON_ID}
      title={title && translate(title)}
    >
      <svg
        fill={fill}
        height={height}
        viewBox="0 0 24 24"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 Z M13.8316505,5.00381584 L5.00381584,13.8316505 C6.00769357,14.4912707 7.2090344,14.875 8.5,14.875 C12.0208153,14.875 14.875,12.0208153 14.875,8.5 C14.875,7.2090344 14.4912707,6.00769357 13.8316505,5.00381584 Z M8.5,2.125 C4.97918472,2.125 2.125,4.97918472 2.125,8.5 C2.125,9.96249955 2.61747828,11.3099746 3.44564557,12.385636 L12.385636,3.44564557 C11.3099746,2.61747828 9.96249955,2.125 8.5,2.125 Z" />
      </svg>
    </div>
  );
};

IconSuspendLocation.defaultProps = {
  className: null,
  fill: ACTIVE_COLOR_DEFAULT,
  height: '24px',
  title: 'GENERIC_MESSAGES.SUSPEND_SITE',
  width: '24px',
};

IconSuspendLocation.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconSuspendLocation);
