// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

// Components
import { Icon, ListView } from 'lib';
import * as NotificationsContainerConsts from 'containers/Notifications/constants';

// Constants
import { IC_CAMERA_MOBILE, IC_SERVER } from 'constants/iconNames';
import {
  convertTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';
import getNotificationsEventsTypeKey from 'util/getNotificationEventTypeKey';
import * as consts from './constants';

// Styles
import { deviceIconCol, deviceRow, truncateDeviceName } from './styles.css';
import StatusCell from './StatusCell';

// Class
class NotificationsTable extends Component {
  renderLocation = alarm => {
    const { locations } = this.props;
    let cameraDeviceLocationName = (
      <Translate id="ALARMS.SITE_NOT_FOUND_LABEL" />
    );
    if (alarm.LocationName) {
      cameraDeviceLocationName = alarm.LocationName;
    } else if (alarm.LocationId) {
      const cameraDeviceLocation = locations.find(
        locObj => locObj.Id === alarm.LocationId,
      );
      if (cameraDeviceLocation) {
        cameraDeviceLocationName = cameraDeviceLocation.Name;
      }
    }
    return cameraDeviceLocationName;
  };

  renderEventDescription = eventName => {
    const event = getNotificationsEventsTypeKey(eventName);
    return event.translate ? <Translate id={event.keyName} /> : event.name;
  };

  renderDate = date => {
    const {
      profileLongDateFormat,
      profileTimeFormat,
      profileTimeZone,
    } = this.props;
    return date ? (
      convertTimeTo(
        date,
        TIME_TYPES.LOCAL,
        profileTimeZone,
        getPreferredLongDateTimeFormat(
          profileLongDateFormat,
          profileTimeFormat,
        ) || getFormatForActiveLocale(),
      )
    ) : (
      <Translate id="ALARMS.STATUS.UNAVAILABLE_LABEL" />
    );
  };

  handleInfiniteScroll = () => {
    const {
      alarmType,
      getNextPage,
      nextPageURL,
      nextPagefetchType,
      tableFilterId,
    } = this.props;
    if (tableFilterId) {
      getNextPage(nextPageURL, tableFilterId, alarmType);
    } else {
      getNextPage(nextPageURL, nextPagefetchType, alarmType);
    }
  };

  handleRowClick = data => {
    const { onClickedRow } = this.props;
    onClickedRow(data);
  };

  render() {
    const { alarms, isFetchingNextPage, rowsSelected } = this.props;
    const customCells = {
      Created: alarm => {
        return this.renderDate(alarm.Created);
      },
      EventName: alarm => {
        return this.renderEventDescription(alarm.EventName);
      },
      Modified: alarm => {
        return this.renderDate(alarm.Modified);
      },
      Status: (alarm, { selected }) => {
        return (
          <StatusCell
            activityClass={alarm.ActivityClass}
            isSelected={!!selected}
            status={alarm.Status}
          />
        );
      },

      device: alarm => {
        let deviceName = <Translate id="ALARMS.DEVICE_NOT_FOUND_LABEL" />;

        if (
          alarm.ActivityClass !==
          NotificationsContainerConsts.ActivityClass.DeviceHealthActivity
        ) {
          if (alarm.CameraName) {
            deviceName = alarm.CameraName;
          }
        } else if (alarm.DeviceName) {
          deviceName = alarm.DeviceName;
        }

        let icon = null;
        if (
          alarm.ActivityClass !==
          NotificationsContainerConsts.ActivityClass.DeviceHealthActivity
        )
          icon = <Icon id={IC_CAMERA_MOBILE} size={consts.DEVICE_ICON_SIZE} />;
        else {
          icon = <Icon id={IC_SERVER} size={consts.DEVICE_ICON_SIZE} />;
        }

        return (
          <div className={deviceRow}>
            <div className={deviceIconCol}>{icon}</div>
            <div className={truncateDeviceName}>{deviceName}</div>
          </div>
        );
      },

      site: alarm => {
        const { locations } = this.props;
        let cameraDeviceLocationName = (
          <Translate id="ALARMS.SITE_NOT_FOUND_LABEL" />
        );
        if (alarm.LocationName) {
          cameraDeviceLocationName = alarm.LocationName;
        } else if (alarm.LocationId) {
          const cameraDeviceLocation = locations.find(
            locObj => locObj.Id === alarm.LocationId,
          );
          if (cameraDeviceLocation) {
            cameraDeviceLocationName = cameraDeviceLocation.Name;
          }
        }
        return cameraDeviceLocationName;
      },
    };
    const translationIds = {
      Created: 'ALARMS.TABLE.EVENT_DATE_TIME_HEADER_LABEL',
      EventName: 'ALARMS.TABLE.EVENT_DESCRIPTION_HEADER_LABEL',
      Modified: 'ALARMS.TABLE.EVENT_LAST_UPDATED_DATE',
      ModifiedByName: 'ALARMS.TABLE.EVENT_LAST_VIEWED_BY_LABEL',
      Status: 'COMMON.STATUS',
      device: 'ALARMS.TABLE.EVENT_DEVICE_HEADER_LABEL',
      site: 'COMMON.SITE',
    };
    return (
      <>
        <ListView
          bodyHeight={consts.notificationsTableHeight}
          cellAlignments={{ status: 'center' }}
          cellWidths={{ Status: 50 }}
          clickableRows
          customCells={customCells}
          data={alarms}
          featuredField="Status"
          fieldOrder={[
            'Status',
            'Created',
            'EventName',
            'site',
            'device',
            'ModifiedByName',
            'Modified',
          ]}
          headerTranslationIds={translationIds}
          hideFilter
          infiniteScroll
          inlineDetailLabel={rowData => `${rowData.Description}`}
          isFetchingMoreData={isFetchingNextPage}
          onInfiniteScroll={this.handleInfiniteScroll}
          rowClickCallback={this.handleRowClick}
          rowsSelected={rowsSelected}
          titleField="Created"
          truncatedFields={[
            'Created',
            'EventName',
            'site',
            'device',
            'ModifiedByName',
            'Modified',
          ]}
        />
      </>
    );
  }
}

NotificationsTable.propTypes = {
  alarmType: PropTypes.string,
  alarms: PropTypes.arrayOf(PropTypes.object).isRequired,
  getNextPage: PropTypes.func.isRequired,
  isFetchingNextPage: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.any),
  nextPageURL: PropTypes.string,
  nextPagefetchType: PropTypes.string,
  onClickedRow: PropTypes.func.isRequired,
  profileLongDateFormat: PropTypes.string,
  profileTimeFormat: PropTypes.string,
  profileTimeZone: PropTypes.string,
  rowsSelected: PropTypes.arrayOf(PropTypes.number),
  tableFilterId: PropTypes.string,
};

NotificationsTable.defaultProps = {
  alarmType: undefined,
  isFetchingNextPage: false,
  locations: [],
  nextPageURL: '',
  nextPagefetchType: '',
  profileLongDateFormat: '',
  profileTimeFormat: '',
  profileTimeZone: '',
  rowsSelected: [],
  tableFilterId: '',
};

export default NotificationsTable;
