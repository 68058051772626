import React from 'react';
import PropTypes from 'prop-types';
import { Button, GroupLayout } from 'lib';
import { ModalContainer } from 'containers';
import { Translate } from 'react-localize-redux';
import { OK_BUTTON_ID } from 'constants/ElementId';
import { modalContentConfirm, modalText } from './styles.css';

// Constants

const InfoModal = ({
  hideModal,
  message,
  okButtonTranslateId,
  onOkClick,
  title,
}) => (
  <ModalContainer handleCancel={hideModal} modalTitle={title}>
    <div className={modalContentConfirm}>
      <div className={modalText}>{message}</div>
      <GroupLayout horizontalPositioning="right" verticalSpacing="large">
        <Button key="okButton" id={OK_BUTTON_ID} onClick={onOkClick}>
          <Translate id={okButtonTranslateId} />
        </Button>
      </GroupLayout>
    </div>
  </ModalContainer>
);

InfoModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  okButtonTranslateId: PropTypes.string,
  onOkClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

InfoModal.defaultProps = {
  okButtonTranslateId: 'BUTTONS.OK',
  onOkClick: () => {},
  title: '',
};

export default InfoModal;
