import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { HELP_ICON_ID } from '../constants/ElementId';

const IconHelp = ({ className, fill, height, title, translate, width }) => {
  return (
    <div className={className} id={HELP_ICON_ID} title={translate(title)}>
      <svg
        height={height}
        version="1.1"
        viewBox="0 0 24 21"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g fill="none" fillRule="evenodd" opacity="1" strokeWidth="1">
          <path
            d="M13,14.94H11c0-.3,0-.48,0-.55a3.71,3.71,0,0,1,.22-1.65,5.12,5.12,0,0,1,1.33-1.46,9.28,9.28,0,0,0,1.19-1.06,1.39,1.39,0,0,0,.3-.87,1.47,1.47,0,0,0-.53-1.14,2,2,0,0,0-1.43-.48,2.16,2.16,0,0,0-1.45.5,2.58,2.58,0,0,0-.8,1.51L7.74,9.48A3.5,3.5,0,0,1,9,7a4.42,4.42,0,0,1,3-1A4.54,4.54,0,0,1,15.1,7a3.09,3.09,0,0,1,1.16,2.39,2.67,2.67,0,0,1-.42,1.43A9.4,9.4,0,0,1,14,12.67a3.64,3.64,0,0,0-.9,1A3.4,3.4,0,0,0,13,14.94ZM11,18V16h2v2Z"
            fill={fill}
          />
          <path
            d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18.25A8.25,8.25,0,1,1,20.25,12,8.25,8.25,0,0,1,12,20.25Z"
            fill={fill}
          />
        </g>
      </svg>
    </div>
  );
};

IconHelp.defaultProps = {
  className: '',
  fill: '#ffffff',
  height: '28px',
  title: 'MASTER_HEADER.HELP',
  width: '21px',
};

IconHelp.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconHelp);
