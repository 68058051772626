import * as types from 'constants/ActionTypes';

export function addFilter(filterGroup, newFilters) {
  return {
    type: types.ADD_FILTER_VALUES,
    filterGroup,
    newFilters,
  };
}

export function clearFilter(filterGroup) {
  return {
    type: types.CLEAR_FILTER_VALUES,
    filterGroup,
    newFilters: [],
  };
}
