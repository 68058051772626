import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconSelectList = props => {
  return (
    <div
      title={props.title}
      style={{ height: props.height, width: props.width }}
    >
      <svg
        width={props.width}
        height={props.height}
        className={props.className}
        viewBox="0 0 23 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <title>{props.title}</title>
        <defs>
          <filter
            x="-2.2%"
            y="-2.6%"
            width="104.3%"
            height="110.5%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feOffset
              dx="0"
              dy="1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feComposite
              in="shadowOffsetOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            />
          </filter>
          <filter
            x="-3.8%"
            y="-5.0%"
            width="107.7%"
            height="120.0%"
            filterUnits="objectBoundingBox"
            id="filter-4"
          >
            <feOffset
              dx="0"
              dy="1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            />
          </filter>
        </defs>
        <g
          id="view-live"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Rectangle-15">
            <rect
              x="0"
              y="0"
              width="23"
              height="19"
              rx="2"
              fill={props.on ? 'black' : 'none'}
              fillOpacity="1"
              filter="url(#filter-2)"
            />
            <rect
              fill={props.on ? '#FBFBFB' : 'none'}
              fillRule="evenodd"
              x="0"
              y="0"
              width="23"
              height="19"
              rx="2"
            />
            <rect
              stroke={props.on ? '#CBCBCB' : 'none'}
              strokeWidth="1"
              x="0.5"
              y="0.5"
              width="22"
              height="18"
              rx="2"
            />
          </g>
          <g id="Combined-Shape">
            <path
              d="M5,4 L18,4 L18,6 L5,6 L5,4 Z M5,8 L18,8 L18,10 L5,10 L5,8 Z M5,12 L18,12 L18,14 L5,14 L5,12 Z"
              fill="black"
              fillOpacity="1"
              filter="url(#filter-4)"
            />
            <path
              fill="#7C7C7C"
              fillRule="evenodd"
              d="M5,4 L18,4 L18,6 L5,6 L5,4 Z M5,8 L18,8 L18,10 L5,10 L5,8 Z M5,12 L18,12 L18,14 L5,14 L5,12 Z"
              id="path-3"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconSelectList.defaultProps = {
  className: null,
  width: '23px',
  height: '20px',
  on: true,
  title: 'Select list view',
};

export default IconSelectList;
