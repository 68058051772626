// Utils
import urlBuilder from 'queryBuilder/url';
import {
  sendGetRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';

// Constants
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Actions
import { updateDigitalOutLinks } from './links';
import { showMessage } from '../pageMessage';
import { isFetching } from '../common';

/* digital in / digital out for a server */

// Pure redux action creators

export function receiveEntitySettings(entityId, settings) {
  return {
    type: types.RECEIVE_ENTITY_SETTINGS,
    entityId,
    settings,
  };
}

export function receiveEntities(deviceId, entities) {
  return {
    type: types.RECEIVE_DEVICE_ENTITIES,
    deviceId,
    entities,
  };
}

// Async action creators

export function triggerDigitalOut(
  deviceId,
  entityId,
  cameraRemoteId,
  isToggle = true,
  orgId,
) {
  return () => {
    // If both entityId and cameraId are given, cameraId takes precedence
    const url = urlBuilder(types.TRIGGER_DIGITAL_OUT, deviceId, null, null, {
      orgId,
    });
    const body = {
      entityId,
      isToggle,
    };
    if (cameraRemoteId) {
      body.id = cameraRemoteId;
    }
    return sendPutRequestReturningJSON(url, body);
  };
}

export function getEntities(deviceId, orgId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_ENTITIES, true, { deviceId }));
    const url = urlBuilder(types.GET_DEVICE_ENTITIES, deviceId, null, null, {
      orgId,
    });
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json) {
          dispatch(receiveEntities(deviceId, json));
        }
      })
      .catch(ex => {
        avoLogError('Error getting entities', { deviceId, orgId, ex });
      })
      .then(() =>
        dispatch(
          isFetching(types.IS_FETCHING_DEVICE_ENTITIES, false, { deviceId }),
        ),
      );
  };
}

export function getEntitySettings(deviceId, entityId, orgId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_ENTITY_SETTINGS, true, { entityId }));
    const url = urlBuilder(types.GET_ENTITY_SETTINGS, deviceId, null, null, {
      orgId,
      entityId,
    });
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json) {
          dispatch(receiveEntitySettings(entityId, json));
        }
      })
      .catch(ex => {
        avoLogError('Errror getting entity settings', ex);
      })
      .then(() =>
        dispatch(
          isFetching(types.IS_FETCHING_ENTITY_SETTINGS, false, { entityId }),
        ),
      );
  };
}

export function updateEntitySettings(
  entityData,
  entityId,
  deviceId,
  orgId,
  shouldSkipAlertMessages,
) {
  return dispatch => {
    const url = urlBuilder(types.UPDATE_ENTITY_SETTINGS, deviceId, null, null, {
      orgId,
    });
    return sendPutRequestReturningJSON(url, entityData)
      .then(response => {
        if (response.errorMessage) {
          throw new Error(response.errorMessage);
        } else if (!shouldSkipAlertMessages) {
          dispatch(
            showMessage(messageTypes.DEVICE_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: 'GENERIC_MESSAGES.EDIT_SUCCESS_MESSAGE',
            }),
          );
          dispatch(getEntitySettings(deviceId, entityId, orgId)); // No signalR update signals are currently provided for entity updates
          dispatch(getEntities(deviceId, orgId)); // No signalR update signals are currently provided for entity updates
        }
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.DEVICE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERIC_MESSAGES.EDIT_ERROR_MESSAGE',
          }),
        );
        avoLogError('Error updating entity settings', ex);
        return Promise.error(
          'An error occurred.  Changes to the data were not saved.',
        );
      });
  };
}

export function updateEntitySettingsAndLinks(
  entityData,
  entityId,
  deviceId,
  linkedCameras,
  orgId,
) {
  return dispatch => {
    dispatch(
      updateEntitySettings(
        entityData,
        entityId,
        deviceId,
        false,
        linkedCameras.length > 0,
      ),
    ).then(() => {
      // Update camera link
      dispatch(updateDigitalOutLinks(deviceId, linkedCameras, entityId))
        .then(arrayOfValuesOrErrors => {
          const errors = arrayOfValuesOrErrors.filter(obj => obj.error);
          if (errors.length > 0) {
            throw new Error(errors[0].errorMessage);
          } else {
            dispatch(
              showMessage(messageTypes.DEVICE_SUCCESS, null, null, {
                messageStyle: messageStyleStrings.success,
                translateBody: 'GENERIC_MESSAGES.EDIT_SUCCESS_MESSAGE',
              }),
            );
            dispatch(getEntitySettings(deviceId, entityId, orgId)); // No signalR update signals are currently provided for entity updates
            dispatch(getEntities(deviceId, orgId)); // No signalR update signals are currently provided for entity updates
          }
        })
        .catch(() => {
          dispatch(
            showMessage(messageTypes.DEVICE_ERROR, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody: 'GENERIC_MESSAGES.EDIT_ERROR_MESSAGE',
            }),
          );
        });
    });
  };
}
