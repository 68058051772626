import { camelCase } from 'lodash';
import urlBuilder from 'queryBuilder/url';
import { sendPostRequestReturningJSON } from 'util/fetchHelpers';
import { ROLES } from 'util/userRoles';
import * as types from 'constants/ActionTypes';
import { FORM_DEFAULTS } from 'constants/app';

export function isFetching(type, value = true, data = {}) {
  // To reduce boilerplate, value represents an optional bool to set isFetching to.
  // As we update actions, we should replace DONE_FETCHING action types with dispatches of
  // isFetching with value = false

  /* TODO: code duplication - NOTE: Ensure any changes made to this copy of
   * isFetching() are also made in actions/devices.js.
   * This method was originally extracted from actions/devices.js, however
   * it was *also* referenced by the components listed below. Due to the
   * unexpected difficulty with what was expected to be a trivial refactoring,
   * I've had to abort attempting to refactor the components.  Therefore they
   * currently *still* reference the original version of this method in
   * devices.js, and therefore devices.js has not been adjusted to reference
   * this file.
   *
   * Still needs refactoring:
   *  - app/components/EditAlarmModal/EditHealthAlarmModal.js
   *  - app/components/EditAlarmModal/EditAlarmModal.js
   *  - app/containers/CameraSettings/CameraSettingsContainer.js
   *  - app/actions/devices.js
   *  - app/actions/views.js
   */
  return { data, type, value };
}

export function isFetchingData(fetchType, value = true, options = {}) {
  const { fetchScope } = options;
  return {
    fetchScope,
    fetchType: camelCase(fetchType),
    type: types.IS_FETCHING,
    value,
  };
}

export function hasNeverFetched(fetchStatus) {
  return fetchStatus === null;
}

export function fetchNotRunning(fetchStatus) {
  return !fetchStatus;
}

export function isSavingData(objectType, value = true) {
  return {
    objectType: camelCase(objectType),
    type: types.IS_SAVING,
    value,
  };
}

export function addUserIfNeeded(params, orgId) {
  const {
    AdminEmailAddress: EmailAddress,
    AdminFirstName: FirstName,
    AdminLastName: LastName,
    AdminPhoneNumber: PhoneNumber,
    AdminTimeZone: TimeZone,
    AdminUserId,
    AdministratorId,
  } = params;
  const Role = ROLES.Administrator;
  const hasNewFields = FirstName && LastName && EmailAddress;
  const newUserParams = {
    EmailAddress,
    FirstName,
    LastName,
    PhoneNumber,
    Policies: [],
    Role,
    TimeZone,
  };

  const adminId = AdminUserId || AdministratorId;

  if (adminId === FORM_DEFAULTS.new && hasNewFields) {
    const url = urlBuilder(types.ADD_USER, orgId);
    return sendPostRequestReturningJSON(url, newUserParams);
  }
  // Always return a promise.
  return new Promise(resolve => {
    resolve({ id: adminId });
  });
}

export function extractUserIdFromResponse(json) {
  if (typeof json === 'string') {
    return json;
  }
  if (typeof json === 'object' && (json.id || json.Id)) {
    return json.id || json.Id;
  }
  return avoLogError(
    'User creation creation response format not recognized:',
    json,
  );
}
