import React from 'react';
import PropTypes from 'prop-types';
import { SelectableGrid } from 'components';
import { Translate } from 'react-localize-redux';

import { ScheduleHeaderContainer } from 'containers';
import { DAY_ORDER } from 'constants/dateTimeNumberFormats';

import {
  centerContent,
  contentWrapper,
  dayRow,
  dayText,
  gridWrapper,
  header,
  headerNarrow,
  headerText,
  leftContent,
  spacer,
} from './styles.css';
import { changeGridOrder } from '../../containers/AccountManagement/constants';

const ScheduleSelectable = ({
  gridMatrix,
  onGridUpdate,
  setSelected,
  startOfWeek,
}) => {
  return (
    <div className={contentWrapper}>
      <div className={leftContent}>
        <span className={headerNarrow} />
        <span className={header}>
          <div className={headerText}>
            <Translate id="SCHEDULES.TABLE.HEADERS.WEEKDAY_LABEL" />
          </div>
        </span>
        <div className={spacer} />
        {DAY_ORDER[startOfWeek].map(day => (
          <div key={day} className={dayRow}>
            <div key={day} className={dayText}>
              <Translate id={`SCHEDULES.TABLE.WEEK_DAYS.${day}_LABEL`} />
            </div>
          </div>
        ))}
      </div>
      <div className={centerContent}>
        <ScheduleHeaderContainer />
        <SelectableGrid
          gridMatrix={changeGridOrder(gridMatrix, startOfWeek)}
          numColumns={48}
          numRows={7}
          onGridUpdate={onGridUpdate}
          outerClass={gridWrapper}
          rowClass={dayRow}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
};

ScheduleSelectable.propTypes = {
  gridMatrix: PropTypes.arrayOf(PropTypes.any),
  onGridUpdate: PropTypes.func,
  setSelected: PropTypes.func,
  startOfWeek: PropTypes.number,
};

ScheduleSelectable.defaultProps = {
  gridMatrix: null,
  onGridUpdate: () => {},
  setSelected: null,
  startOfWeek: 0,
};

export default ScheduleSelectable;
