import * as types from 'constants/ActionTypes';

export function showModal(modalType, modalProps) {
  return {
    type: types.SHOW_MODAL,
    modalType,
    modalProps,
  };
}

export function hideModal() {
  return {
    type: types.CLOSE_MODAL,
  };
}
