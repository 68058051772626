import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function accordionsReducer(
  state = initialState().accordions,
  action = {},
) {
  switch (action.type) {
    case types.TOGGLE_ACCORDION: {
      const { accordionType } = action.accordion;
      // check if accordion type exists
      if (state.types[accordionType]) {
        // check if accordion should be updated
        const isUpdating = state.types[accordionType].accordions.some(
          acc => acc.accordionId === action.accordion.accordionId,
        );

        if (isUpdating) {
          // update
          return {
            ...state,
            types: {
              ...state.types,
              ...{
                [accordionType]: {
                  accordions: [
                    ...state.types[accordionType].accordions.map(accordion =>
                      accordion.accordionId === action.accordion.accordionId
                        ? { ...accordion, ...action.accordion }
                        : accordion,
                    ),
                  ],
                },
              },
            },
          };
        }
        // add new accordion to type
        return {
          ...state,
          types: {
            ...state.types,
            ...{
              [accordionType]: {
                accordions: [
                  ...state.types[accordionType].accordions.concat(
                    action.accordion,
                  ),
                ],
              },
            },
          },
        };
      }
      // add new type and add accordion
      return {
        ...state,
        types: {
          ...state.types,
          ...{ [accordionType]: { accordions: [action.accordion] } },
        },
      };
    }

    default:
      return state;
  }
}
