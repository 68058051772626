/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Controls,
  MouseDetect,
  PanTiltZoom,
  PanTiltZoomContextProvider,
  Show,
  StyledPlayer,
  TimelineContextProvider,
  VideoStatusOverlay,
  Viewport,
  ZoomControl,
} from '@avo-svtr/savitar';

import SavitarPlayerControls from './SavitarPlayerControls';

const SimplePlayer = ({ ...props }) => {
  const controlsShouldShow = ({ ui, uiReady }) =>
    uiReady && ui.hovering && (ui.controlsHovering || !ui.idle);
  const zoomShouldShow = ({ ui, uiReady }) =>
    uiReady && (ui.hovering && !ui.idle);

  return (
    <TimelineContextProvider>
      <StyledPlayer>
        <MouseDetect idleKey="idle">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <PanTiltZoomContextProvider {...props}>
            <Show when={context => context.uiReady}>
              <Viewport />
              <Show when="hovering">
                <PanTiltZoom />
              </Show>
            </Show>
            <VideoStatusOverlay />
            <Controls
              bottom={
                <MouseDetect hoverKey="controlsHovering">
                  <Show when={controlsShouldShow}>
                    <SavitarPlayerControls {...props} />
                  </Show>
                </MouseDetect>
              }
              right={() => (
                <Show when={zoomShouldShow}>
                  <ZoomControl />
                </Show>
              )}
            />
          </PanTiltZoomContextProvider>
        </MouseDetect>
      </StyledPlayer>
    </TimelineContextProvider>
  );
};

/**
 * SimplePlayer composes a collection of components to create an user experience
 * similar to the "EVO Player" that was the sole video player in Blue up until
 * the 2.16 release.
 *
 * The SimplePlayer component itself is mostly responsible for composing a
 * particular presentation. Video playback behaviour is mostly managed by
 * PlayerPresentationProvider and other components imported from the
 * Savitar component library.
 */

export default SimplePlayer;
