import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import create from 'lodash.create';
import { cloneDeep } from 'util/cameraSettingLinks';
import renderIf from 'render-if';
import { Route } from 'react-router-dom';
import get from 'lodash.get';

// Components
import moment from 'moment-timezone-all';
import Modal from 'react-modal';
import { DateTimeFilter, FilterTranslated, Toggle } from 'components';
import {
  Button,
  EmptyPlaceholder,
  ListNav,
  MainContentWrapper,
  PageTitle,
} from 'lib';
import * as AlarmActions from 'actions/alarms';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';
import * as FilterActions from 'actions/filters';
import { getUsers } from 'actions/user';
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';
// Actions

// Utilities
import * as permissions from 'util/permissions';
import {
  alarmFilterFieldsMap,
  appliedAlarmFilter,
  chartFilterPrecedenceArray,
  processFilters,
} from 'util/filterUtils';
import {
  convertTimeTo,
  getDateFilterFormatForActiveLocale,
  getPreferredLongDateFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';
import events from 'constants/notificationsEventsType';
import { DATETIME_FORMAT_WITH_ZONE } from 'constants/app';
import * as types from 'constants/ActionTypes';
import { ALARM_STATUS } from './NotificationsTable/utils';

// Constants
import EditHealthNotificationModal from './EditNotification/EditHealthNotificationModal';
import EditNotificationModal from './EditNotification/EditNotificationModal';
import NotificationsTable from './NotificationsTable/NotificationsTable';
import NotificationsDashboard from './NotificationsTable/NotificationsDashboard';
import NotificationsNavMenu from './NotificationsNavMenu';
import * as AlarmConsts from './constants';

// Styles
import {
  alarmFilterWrapper,
  alarmFilterWrapperWithMargin,
  alarmModalContainer,
  alarmsFilters,
  clearButton,
  healthAlarmModalContainer,
  listNavContent,
} from './styles.css';

const fetchNextPageWhenAlarmLeft = 35;
const defaultStartTime = moment()
  .utc()
  .subtract(3, 'days');
const defaultEndTime = moment().utc();
const defaultUnits = 'Hours';
const createdField = 'Created';
const startDateOperator = 'ge';
const endDateOperator = 'le';

const defaultState = {
  chartFilters: [
    {
      field: 'beginRange',
      values: [defaultStartTime.format()],
    },
    {
      field: 'endRange',
      values: [defaultEndTime.format()],
    },
    {
      field: 'units',
      values: [defaultUnits],
    },
  ],
  currentEndTime: defaultEndTime,
  currentSelectedOrgId: null,
  currentStartTime: defaultStartTime,
  firstTimeLoad: true,
  isLoading: false,
  queryOptions: {
    filters: [],
    skip: 0,
    top: 50,
  },
  resetFilters: false,
  selectedIndices: [],
  shouldRefreshLiveVideo: true,
};

// class
class NotificationsTableContainer extends Component {
  constructor(props) {
    super(props);
    const filters =
      (props.filterStatus && props.filterStatus[props.filterGroup]) || [];
    this.state = {
      ...defaultState,
      queryOptions: {
        filters,
        top: defaultState.queryOptions.top,
      },
    };
  }

  componentDidMount() {
    const {
      actions,
      filters,
      isFetchingAlarmsStats,
      isFetchingAllCameras,
      isFetchingDeviceData,
      isFetchingLocations,
      isFetchingUsers,
      selectedOrganization,
    } = this.props;
    if (selectedOrganization) {
      if (isFetchingUsers === null) {
        actions.getUsers(); // For last viewed by, activity log in modal
      }
      if (isFetchingAllCameras === null) {
        actions.getAllCameras(); // For device filter, header in modal
      }
      if (!filters.location || filters.location.length === 0) {
        actions.getCameraGroups(); // For locations filter
      }
      if (isFetchingAlarmsStats === null) {
        this.getAlarmsStats(); // For chart
      }
      if (isFetchingDeviceData === null) {
        actions.getAllDevices();
      } // For device column, header in modal
      // Devices in reducer may contain incomplete list, so refresh it
      if (isFetchingLocations === null) {
        actions.getLocations();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      actions,
      alarms,
      filters,
      profile,
      selectedOrganization,
    } = this.props;
    const {
      currentEndTime,
      currentStartTime,
      firstTimeLoad,
      queryOptions,
    } = this.state;
    if (
      nextProps.selectedOrganization !== selectedOrganization &&
      !firstTimeLoad
    ) {
      this.getPageData();
    }
    // To fetch chart stats after locations are loaded
    // or on organization / customer change
    if (!nextProps.isFetchingAlarmsStats && !firstTimeLoad) {
      if (
        nextProps.filters.location.length > 0 &&
        (nextProps.isFetchingAlarmsStats === null ||
          nextProps.filters.location !== filters.location)
      ) {
        actions.getAlarmsStats({
          statFilters: this.getChartFilters(
            queryOptions.filters,
            nextProps.filters.location,
          ),
        });
      }
    }

    if (
      alarms &&
      alarms.length > 0 &&
      nextProps.alarms &&
      nextProps.alarms.length > 0 &&
      alarms !== nextProps.alarms
    ) {
      if (!firstTimeLoad) {
        this.setState({
          isLoading: false,
          resetFilters: false,
        });
      }
    }

    if (firstTimeLoad) {
      const startTime = convertTimeTo(
        currentStartTime,
        TIME_TYPES.LOCAL,
        profile.TimeZone,
      );
      const endTime = convertTimeTo(
        currentEndTime,
        TIME_TYPES.LOCAL,
        profile.TimeZone,
      );
      this.setState({
        currentEndTime:
          this.getDateRangeFilterValue(endDateOperator) ||
          moment(endTime, DATETIME_FORMAT_WITH_ZONE),
        currentStartTime:
          this.getDateRangeFilterValue(startDateOperator) ||
          moment(startTime, DATETIME_FORMAT_WITH_ZONE),
        firstTimeLoad: false,
      });
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    const { isLoading } = this.state;
    if (!isLoading && nextState.isLoading) {
      return true;
    }
    return !nextState.isLoading;
  }

  get sumNotifications() {
    const { tableData } = this.props;
    return tableData.UnreviewedCount + tableData.InReviewCount || null;
  }

  get dateRange() {
    const { translate } = this.props;
    const { currentEndTime, currentStartTime } = this.state;
    const days = currentEndTime.diff(currentStartTime, 'days');
    return days <= 1
      ? translate('ALARMS.CONTENT_HEADER.TIMESPAN_24_HOURS')
      : translate('ALARMS.CONTENT_HEADER.TIMESPAN_N_DAYS', { numDays: days });
  }

  appliedFilters = () => {
    const { filterGroup, filterStatus } = this.props;
    return filterStatus && filterStatus[filterGroup];
  };

  getPageData = () => {
    // Information that should be retrieved/refreshed when org changes
    const { actions, getAlarms } = this.props;
    const { queryOptions } = this.state;
    getAlarms(queryOptions);
    actions.getUsers(); // For last viewed by, activity log in modal
    actions.getAllDevices(); // For device column, header in modal
    actions.getAllCameras(); // For device filter, header in modal
    actions.getLocations(); // For site column, header in modal
    actions.getCameraGroups(); // For filter
  };

  getAlarmsStats = () => {
    const { actions, cameras, filters } = this.props;
    const { queryOptions } = this.state;
    if (
      filters.location &&
      filters.location.length > 0 &&
      cameras &&
      cameras.length > 0
    ) {
      actions.getAlarmsStats({
        statFilters: this.getChartFilters(queryOptions.filters),
      });
    }
  };

  getChartFilters = (queryOptionFilters, locs) => {
    // Returns chart filters corresponding to current queryOption filters
    const { cameras, filters } = this.props;
    const {
      chartFilters: stateChartFilters,
      currentEndTime,
      currentStartTime,
    } = this.state;
    const chartFilters = [];
    let addAllFiltersFor = null;
    const locations = locs || filters.location;

    // Initial pass. Map field names, format values, create an object to determine if any fields need to be split
    queryOptionFilters.forEach(qfilter => {
      let { field } = qfilter;
      let { values } = qfilter;
      const filterMapRow = alarmFilterFieldsMap.find(
        row => row.listFilterField === field,
      );
      if (
        filterMapRow &&
        filterMapRow.chartFilterField &&
        filterMapRow.stateFilterKey
      ) {
        // format
        field = filterMapRow.chartFilterField;
        if (field === 'descriptions') {
          const filterMap = filters[filterMapRow.stateFilterKey];
          values = values.map(v => {
            return filterMap.find(filterObj => filterObj.value === v).text;
          });
        }
        // Add to the filter array
        if (values.length > 0) {
          chartFilters.push({ field, values });
        }
      }
    });

    // Determine whether the lowest filter in the heirarchy needs be split
    const precedenceArray = chartFilterPrecedenceArray;
    precedenceArray.forEach(fieldName => {
      const filterMapRow = alarmFilterFieldsMap.find(
        row => row.chartFilterField === fieldName,
      );
      const qFilter = chartFilters.find(filter => filter.field === fieldName);
      if (qFilter) {
        if (
          qFilter.values.length === 1 &&
          filterMapRow.splitSingleSelectionInto
        ) {
          addAllFiltersFor = {
            field: filterMapRow.splitSingleSelectionInto,
            filterBy: qFilter.values[0],
          };
        } else {
          addAllFiltersFor = null;
        }
      }
    });

    // Split a filter, if necessary
    // This should be replaced with a more generalized function in the future
    // but for now, hard-code rules
    if (addAllFiltersFor) {
      const { field, filterBy } = addAllFiltersFor;
      if (field === 'cameraIds') {
        const values = cameras
          .filter(cam => cam.LocationId === filterBy)
          .map(cam => cam.Id);
        if (
          values.length > 0 &&
          values.length <= AlarmConsts.MAX_LINES_DEFAULT
        ) {
          chartFilters.push({ field, values });
        }
      }
    }

    // If no filters given, filter by location
    if (chartFilters.length === 0) {
      const field = 'locationIds';
      const values = locations.map(loc => loc.value);
      if (values.length > 0 && values.length <= AlarmConsts.MAX_LINES_DEFAULT) {
        chartFilters.push({ field, values });
      }
    }
    // Begin and end range may be out of sync with list filters
    // Add from currentStartTime and currentEndTime, which only update
    // when the date dropdown is accessed specifically
    const startTime = currentStartTime.clone().utc();
    const endTime = currentEndTime.clone().utc();
    chartFilters.push(
      { field: 'beginRange', values: [startTime.format()] },
      { field: 'endRange', values: [endTime.format()] },
    );
    // Must have units - calculate based on begin and end range
    const numDays = Math.abs(startTime.diff(endTime, 'days'));
    // These are being used to calculate dates
    const unitsOfTime = numDays > 3 ? 'Days' : 'Hours';
    chartFilters.push({
      field: 'units',
      values: [unitsOfTime],
    });
    // Is there a previous period to compare to?
    const currentPriorBeginRange = stateChartFilters.find(
      f => f.field === AlarmConsts.priorBeginRange,
    );
    if (currentPriorBeginRange) {
      const periodLength = endTime.diff(startTime, unitsOfTime) + 1;
      const priorBeginRangeValue = startTime
        .subtract(periodLength, unitsOfTime)
        .format();
      chartFilters.push({
        field: currentPriorBeginRange.field,
        values: [priorBeginRangeValue],
      });
    }
    return chartFilters;
  };

  handleMultiFilterChange = (newFilterArray, updateGraph) => {
    // New filter array contains objects {field, value, typeOfValue, operator}
    const { actions, filterGroup, getAlarms } = this.props;
    const { queryOptions } = this.state;
    let appliedFilters = queryOptions.filters;
    let filtersChanged;
    let filtersChangedHere;
    newFilterArray.forEach(newFilter => {
      const processedFilters = processFilters(appliedFilters, newFilter);
      appliedFilters = processedFilters.newFilters;
      filtersChangedHere = processedFilters.filtersChanged;
      filtersChanged = filtersChanged || filtersChangedHere;
    });
    const newState = {
      queryOptions: {
        ...queryOptions,
        filters: appliedFilters,
        skip: 0,
      },
      resetFilters: false,
    };
    if (updateGraph) {
      newState.chartFilters = this.getChartFilters(appliedFilters);
    }
    if (filtersChanged) {
      // Only update if a field was changed or added
      this.setState(newState, () => {
        getAlarms(newState.queryOptions);
        if (updateGraph) {
          actions.getAlarmsStats({
            selectedId: null,
            selectedIndices: [],
            statFilters: newState.chartFilters,
          });
        }
      });
      actions.addFilter(filterGroup, appliedFilters);
    }
  };

  getFilterValue = field => {
    const filters = this.appliedFilters();
    const fieldFilter =
      filters && filters.length > 0 && filters.find(f => f.field === field);
    return (fieldFilter && fieldFilter.values) || [];
  };

  getDateRangeFilterValue = operator => {
    const { profile } = this.props;
    const filters = this.appliedFilters();
    const fieldFilter =
      filters &&
      filters.length > 0 &&
      filters.find(f => f.field === createdField && f.operator === operator);

    let time = null;
    if (fieldFilter && fieldFilter.values) {
      time = convertTimeTo(
        fieldFilter.values[0],
        TIME_TYPES.LOCAL,
        profile.TimeZone,
      );
      time = moment(time, DATETIME_FORMAT_WITH_ZONE);
    }
    return time;
  };

  handleFilterChange = (field, values, typeOfValue, operator) => {
    // Values is an array
    // Run when a single dropdown filter is changed
    const { chartFilters, queryOptions } = this.state;
    const { actions, filterGroup, getAlarms } = this.props;
    const { filters } = queryOptions;
    const { filtersChanged, newFilters } = processFilters(filters, {
      field,
      operator,
      typeOfValue,
      values,
    });
    const newChartFilters = this.getChartFilters(newFilters);
    const newQueryOptions = {
      ...queryOptions,
      filters: newFilters,
      skip: 0,
      tab: 'testHealth',
    };
    if (filtersChanged) {
      // Only update if a field was changed or added
      this.setState(
        {
          chartFilters: newChartFilters,
          queryOptions: newQueryOptions,
          resetFilters: false,
          selectedIndices: [],
        },
        () => {
          const { queryOptions: newQueryOptionsState } = this.state;
          actions.addFilter(filterGroup, newFilters);
          getAlarms(newQueryOptionsState);
          actions.getAlarmsStats({
            statFilters: chartFilters,
          });
        },
      );
    }
  };

  onSetSort = event => {
    const { actions } = this.props;
    actions.sortAlarms(event.target.value);
  };

  setDateRange = dateObject => {
    const { currentEndTime, currentStartTime } = this.state;
    const typeOfValue = 'number';
    let { endDate, startDate } = dateObject;
    if (!dateObject || (!dateObject.startDate && !dateObject.endDate)) {
      this.handleFilterChange(createdField, 'All');
    } else {
      endDate = endDate || currentEndTime;
      startDate = startDate || currentStartTime;
      this.setState(
        {
          currentEndTime: endDate,
          currentStartTime: startDate,
        },
        () =>
          this.handleMultiFilterChange(
            [
              {
                field: createdField,
                operator: startDateOperator,
                typeOfValue,
                values: [startDate.format()],
              },
              {
                field: createdField,
                operator: endDateOperator,
                typeOfValue,
                values: [endDate.format()],
              },
            ],
            true,
          ),
      );
    }
  };

  setComparePeriods = priorBeginRange => {
    const { chartFilters: stateChartFilters, queryOptions } = this.state;
    const { actions } = this.props;
    const chartFilters = [].concat(stateChartFilters);
    if (priorBeginRange === null) {
      const priorBeginIndex = chartFilters.findIndex(
        f => f.field === AlarmConsts.priorBeginRange,
      );
      if (priorBeginIndex >= 0) {
        chartFilters.splice(priorBeginIndex, 1);
      }
    } else {
      const priorBeginRangeFilter = {
        field: AlarmConsts.priorBeginRange,
        values: [priorBeginRange],
      };
      chartFilters.push(priorBeginRangeFilter);
    }
    this.setState({ chartFilters }, () =>
      actions.getAlarmsStats({
        statFilters: this.getChartFilters(queryOptions.filters),
      }),
    );
  };

  handleEventReviewClick = alarm => {
    const { history, match } = this.props;
    history.push(`${match.url}/${alarm.Id}`);
  };

  updateAlarmStore = (storeData, alarmId) => {
    const { actions, alarms } = this.props;
    const index = alarms.findIndex(
      alarm => alarm.Id === alarmId || alarm.ProxiedEntity_Id === alarmId,
    );
    if (index >= 0 && alarms[index]) {
      actions.editAlarmStore(storeData, alarmId);
    }
  };

  getPreviousAlarm = selectedId => {
    const { alarms, history } = this.props;
    const currentAlarmIndex = alarms.findIndex(
      alarm => alarm.Id === selectedId,
    );
    const previousAlarmIndex = currentAlarmIndex - 1;

    if (previousAlarmIndex > -1) {
      this.setState({
        shouldRefreshLiveVideo:
          alarms[previousAlarmIndex].CameraId !==
          alarms[currentAlarmIndex].CameraId,
      });
      history.push(alarms[previousAlarmIndex].Id);
    } else {
      // TODO: ask eric to disable icon and provide grey next button when no more previous records available
    }
  };

  getNextAlarm = selectedId => {
    const { alarms, history } = this.props;
    const currentAlarmIndex = alarms.findIndex(
      alarm => alarm.Id === selectedId,
    );
    const nextAlarmIndex = currentAlarmIndex + 1;

    if (alarms[nextAlarmIndex]) {
      this.setState({
        shouldRefreshLiveVideo:
          alarms[nextAlarmIndex].CameraId !==
          alarms[currentAlarmIndex].CameraId,
      });
      this.handleOnScrollEnd(nextAlarmIndex);
      history.push(alarms[nextAlarmIndex].Id);
    } else {
      // TODO: ask eric to disable icon and provide grey next button when no more next records available
    }
  };

  handleExit = () => {
    const { actions, history, match } = this.props;
    actions.clearSelectedAlarm();
    history.push(match.url);
  };

  handleOnScrollEnd = currentRowIndex => {
    const {
      actions,
      alarmType,
      alarms,
      alarmsNextLink,
      isFetchingAlarms,
    } = this.props;
    if (alarms.length - currentRowIndex < fetchNextPageWhenAlarmLeft) {
      if (alarmsNextLink && !isFetchingAlarms) {
        actions.getAlarmsNextPage(
          alarmsNextLink,
          types.ALARM_FETCH_DIRECTION_NEXT,
          alarmType,
        );
      }
    }
  };

  onSelectedRowsChanged = (selectedIndices, selectedIndex) => {
    const { alarms } = this.props;
    let id = alarms[0].Id;
    let locationId = alarms[0].LocationId;
    if (selectedIndex) {
      id = alarms[selectedIndex].Id;
      locationId = alarms[selectedIndex].LocationId;
    }
    if (id) {
      this.setState({
        selectedAlarmLocationId: locationId,
        selectedId: id,
        selectedIndices,
      });
    }
  };

  handleResetFilterClick = () => {
    // First set state to default, then reset chartFilters, which are derived from the state
    // Note: should probably update this pattern so chart filters are always derived from
    // the state when needed, not cached.
    const { actions, filterGroup, getAlarms } = this.props;
    actions.clearFilter(filterGroup);
    this.setState(defaultState, () => {
      this.setState(
        {
          chartFilters: this.getChartFilters(defaultState.queryOptions.filters),
        },
        () => {
          const { chartFilters } = this.state;
          getAlarms(
            defaultState.queryOptions,
            types.ALARM_FETCH_DIRECTION_NEXT,
          );
          actions.getAlarmsStats({
            statFilters: chartFilters,
          });
        },
      );
    });
  };

  getAlarmTenantId = alarmId => {
    const { alarms } = this.props;
    const selectedAlarm = alarms.find(alarm => alarm.Id === alarmId);
    return selectedAlarm ? selectedAlarm.ProxiedTenant_Id : null;
  };

  getSourceAlarmId = alarmId => {
    const { alarms } = this.props;
    const selectedAlarm = alarms.find(alarm => alarm.Id === alarmId);
    const proxiedEntityId = get(selectedAlarm, 'ProxiedEntity_Id');
    return proxiedEntityId || alarmId;
  };

  getEventNames = eventNames => {
    let eventArray = [];
    const eventsType = create(Object, events);
    if (eventNames) {
      let translatedItem;
      eventArray = cloneDeep(eventNames).map(item => {
        translatedItem = item;
        if (Object.keys(eventsType).includes(item.text)) {
          translatedItem.text = <Translate id={eventsType[item.text]} />;
        }
        return translatedItem;
      });
    }
    return eventArray;
  };

  onChangeVisibility = (checked, _label, id) => {
    const { actions } = this.props;
    actions.setWidgetVisibility({
      [id]: checked,
    });
  };

  render() {
    const {
      actions,
      alarmType,
      alarms,
      alarmsNextLink,
      allAlarms,
      canChangeCustomer,
      chartData,
      count,
      filters,
      hasCustomers,
      isFetchingAlarms,
      isFetchingAlarmsStats,
      match,
      profile,
      showGraphToggle,
      tableData,
      translate,
      users,
      widgetVisibility,
    } = this.props;
    const {
      chartFilters,
      currentEndTime,
      currentStartTime,
      isLoading,
      queryOptions,
      resetFilters,
      selectedAlarmLocationId,
      selectedIndices,
      shouldRefreshLiveVideo,
    } = this.state;
    return (
      <>
        <ListNav
          actionImage={AlarmConsts.navBarActionTitle}
          canChangeCustomer={canChangeCustomer}
          id={AlarmConsts.idAlarmNavHeader}
          navigationTabs={<NotificationsNavMenu profile={profile} />}
          pageTitle={
            <PageTitle titleId="ALARMS.NOTIFICATION_CENTER_BAR_TITLE" />
          }
        >
          <div className={listNavContent}>
            <div className={alarmsFilters}>
              <div className={alarmFilterWrapper}>
                <FilterTranslated
                  disabled={isFetchingAlarms}
                  field="Status"
                  forceReset={resetFilters}
                  id={AlarmConsts.idAlarmAlertsDropdown}
                  isIcon={false}
                  label="COMMON.STATUS"
                  multi
                  onChange={this.handleFilterChange}
                  options={ALARM_STATUS}
                  translateLabel
                  translateOptions
                  value={this.getFilterValue('Status')}
                />
              </div>
              {/* Hidden pending server support
                  {this.props.hasCustomers && <div className={alarmFilterWrapper}>
                    <FilterTranslated
                      id={AlarmConsts.idAlarmSubscribersDropdown}
                      label={translate('ALARMS.FILTERS.SUBSCRIBERS_LABEL')}
                      field={'ProxiedTenant_Id'}
                      options={this.props.subscribers}
                      onChange={this.handleFilterChange}
                      multi={true}
                      disabled={this.props.isFetching}
                      forceReset={this.state.resetFilters}
                    />
                </div>}
              */}
              <div className={alarmFilterWrapper}>
                <FilterTranslated
                  disabled={isFetchingAlarms}
                  field="LocationId"
                  forceReset={resetFilters}
                  id={AlarmConsts.idAlarmLocationsDropdown}
                  label={translate('ALARMS.FILTERS.SITES_LABEL')}
                  multi
                  onChange={this.handleFilterChange}
                  options={filters.location}
                  value={this.getFilterValue('LocationId')}
                />
              </div>
              <div className={alarmFilterWrapper}>
                <FilterTranslated
                  disabled={isFetchingAlarms}
                  field="CameraId"
                  forceReset={resetFilters}
                  id={AlarmConsts.idAlarmCamerasDropdown}
                  label={translate('ALARMS.FILTERS.DEVICES_LABEL')}
                  multi
                  onChange={this.handleFilterChange}
                  options={filters.cameraName}
                  value={this.getFilterValue('CameraId')}
                />
              </div>
              <div className={alarmFilterWrapper}>
                <FilterTranslated
                  disabled={isFetchingAlarms}
                  field="Description"
                  forceReset={resetFilters}
                  id={AlarmConsts.idAlarmRulesDropdown} // This function returns an array of event names with and without translation
                  label={translate('ALARMS.FILTERS.EVENTS_LABEL')}
                  multi
                  onChange={this.handleFilterChange}
                  options={this.getEventNames(filters.rule)}
                  value={this.getFilterValue('Description')}
                />
              </div>
              <div className={alarmFilterWrapperWithMargin}>
                <DateTimeFilter
                  dayRange={[1, 3, 7, 30]}
                  disabled={isFetchingAlarms}
                  handleSelectDate={this.setDateRange}
                  id={AlarmConsts.idAlarmDatesDropdown}
                  initialEndTime={currentEndTime}
                  initialStartTime={currentStartTime}
                  showCustomDateSelector={false}
                  timeFormat={getPreferredLongDateFormat(
                    profile.LocalizationPreference.PreferredLongDateFormat,
                    profile.LocalizationPreference.PreferredTimeFormat,
                  )} // TODO: MVAAS-5506
                  timezone={profile.TimeZone}
                />
              </div>
              <Button
                buttonType="action"
                className={clearButton}
                disabled={queryOptions.filters.length === 0}
                inputType="button"
                onClick={this.handleResetFilterClick}
              >
                <span>{translate('COMMON.CLEAR')}</span>
              </Button>
            </div>
            {renderIf(showGraphToggle)(
              <Toggle
                checked={!!widgetVisibility[AlarmConsts.WIDGETS.VOLUME_CHART]}
                id={AlarmConsts.WIDGETS.VOLUME_CHART}
                label={translate(
                  'ALARMS.CONTENT_HEADER.WIDGET_VISIBILITY.DISPLAY_VOLUME_CHART',
                )}
                onChange={this.onChangeVisibility}
              />,
            )}
          </div>
        </ListNav>
        <MainContentWrapper>
          {renderIf(showGraphToggle)(
            <NotificationsDashboard
              appliedChartFilter={appliedAlarmFilter(queryOptions.filters)}
              changeFilters={this.handleMultiFilterChange}
              chartData={chartData}
              chartRange={[currentStartTime, currentEndTime]}
              chartUnits={chartFilters.find(f => f.field === 'units').values[0]}
              isFetching={isFetchingAlarmsStats}
              maximumLines={AlarmConsts.MAX_LINES_DEFAULT}
              onComparePeriods={this.setComparePeriods}
              profileLongDateFormat={
                profile.LocalizationPreference.PreferredLongDateFormat
              }
              profileShortDateFormat={
                profile.LocalizationPreference.PreferredShortDateFormat
              }
              profileTimeFormat={
                profile.LocalizationPreference.PreferredTimeFormat
              }
              profileTimeZone={profile.TimeZone}
              stateFilters={filters}
              tableData={tableData}
              widgetVisibility={widgetVisibility}
            />,
          )}
          <EmptyPlaceholder
            isFetching={isFetchingAlarms && alarms.length === 0}
            items={alarms}
            string={<Translate id="ALARMS.EMPTY_ALARMS_LABEL" />}
          >
            <NotificationsTable
              alarms={alarms}
              alarmType={alarmType}
              getNextPage={actions.getAlarmsNextPage}
              isCustomerDealer={hasCustomers}
              isFetchingNextPage={isFetchingAlarms}
              nextPagefetchType={types.ALARM_FETCH_DIRECTION_NEXT}
              nextPageURL={alarmsNextLink}
              onClickedRow={this.handleEventReviewClick}
              onSelectedRowsChanged={this.onSelectedRowsChanged}
              prevPagefetchType={types.ALARM_FETCH_DIRECTION_PREV}
              profileLongDateFormat={
                profile.LocalizationPreference.PreferredLongDateFormat
              }
              profileTimeFormat={
                profile.LocalizationPreference.PreferredTimeFormat
              }
              profileTimeZone={profile.TimeZone}
              rowsSelected={selectedIndices}
              totalRowCount={count}
            />
          </EmptyPlaceholder>
        </MainContentWrapper>
        <Route
          exact
          path={`${match.url}/:alarmId`}
          render={props => {
            const {
              match: {
                params: { alarmId: selectedId },
              },
            } = props;
            const selectedAlarm = allAlarms.find(x => x.Id === selectedId);
            let modalClass = `${modalContentContainer} ${alarmModalContainer}`;
            let modalToShow = '';
            if (selectedAlarm) {
              if (
                selectedAlarm.ActivityClass ===
                AlarmConsts.ActivityClass.VideoReviewActivity
              ) {
                modalClass = `${modalContentContainer} ${alarmModalContainer}`;
              } else {
                modalClass = `${modalContentContainer} ${healthAlarmModalContainer}`;
              }
              if (
                selectedAlarm.ActivityClass ===
                  AlarmConsts.ActivityClass.VideoReviewActivity ||
                selectedAlarm.ActivityClass ===
                  AlarmConsts.ActivityClass.DeviceTamperingActivity
              ) {
                modalToShow = AlarmConsts.AlarmReviewModalTypes.AnalyticsModal;
              } else if (
                selectedAlarm.ActivityClass ===
                  AlarmConsts.ActivityClass.CameraHealthActivity ||
                selectedAlarm.ActivityClass ===
                  AlarmConsts.ActivityClass.DeviceHealthActivity
              ) {
                modalToShow = AlarmConsts.AlarmReviewModalTypes.HealthModal;
              }
            }
            return (
              <Modal
                className={modalClass}
                contentLabel="EditAlarmModal"
                isOpen
                onRequestClose={this.handleExit}
                overlayClassName={modalOverlay}
                shouldCloseOnOverlayClick={false}
              >
                {renderIf(
                  selectedAlarm &&
                    modalToShow ===
                      AlarmConsts.AlarmReviewModalTypes.AnalyticsModal,
                )(
                  <EditNotificationModal
                    alarmId={this.getSourceAlarmId(selectedId)}
                    alarms={alarms}
                    alarmTenantId={this.getAlarmTenantId(selectedId)}
                    handleCancel={this.handleExit}
                    handleNext={() => this.getNextAlarm(selectedId)}
                    handlePrevious={() => this.getPreviousAlarm(selectedId)}
                    isBusy={isLoading}
                    locationId={selectedAlarmLocationId}
                    locations={filters.location}
                    profileTimeZone={profile.TimeZone}
                    shouldRefreshLiveVideo={shouldRefreshLiveVideo}
                    show
                    updateAlarmStore={this.updateAlarmStore}
                    userId={profile.Id}
                    users={users}
                  />,
                )}
                {renderIf(
                  selectedAlarm &&
                    modalToShow ===
                      AlarmConsts.AlarmReviewModalTypes.HealthModal,
                )(
                  <EditHealthNotificationModal
                    alarmId={this.getSourceAlarmId(selectedId)}
                    alarms={alarms}
                    alarmTenantId={this.getAlarmTenantId(selectedId)}
                    handleCancel={this.handleExit}
                    handleNext={() => this.getNextAlarm(selectedId)}
                    handlePrevious={() => this.getPreviousAlarm(selectedId)}
                    isBusy={isLoading}
                    locations={filters.location}
                    profileTimeZone={profile.TimeZone}
                    shouldRefreshLiveVideo={shouldRefreshLiveVideo}
                    show
                    updateAlarmStore={this.updateAlarmStore}
                    userId={profile.Id}
                    users={users}
                  />,
                )}
              </Modal>
            );
          }}
        />
      </>
    );
  }
}

NotificationsTableContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  activeLanguage: PropTypes.shape({
    active: PropTypes.bool,
    code: PropTypes.string,
    fullCode: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  alarmType: PropTypes.string,
  alarms: PropTypes.arrayOf(PropTypes.object),
  alarmsNextLink: PropTypes.string,
  allAlarms: PropTypes.arrayOf(PropTypes.object),
  cameras: PropTypes.arrayOf(PropTypes.object).isRequired,
  canChangeCustomer: PropTypes.bool.isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  count: PropTypes.number,
  filterGroup: PropTypes.string.isRequired,
  filterStatus: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    cameraName: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.arrayOf(PropTypes.object),
    rule: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  getAlarms: PropTypes.func,
  hasCustomers: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isFetchingAlarms: PropTypes.bool,
  isFetchingAlarmsStats: PropTypes.bool,
  isFetchingAllCameras: PropTypes.bool,
  isFetchingDeviceData: PropTypes.bool,
  isFetchingLocations: PropTypes.bool,
  isFetchingUsers: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.shape({
    Id: PropTypes.string,
    LocalizationPreference: PropTypes.object,
    TimeZone: PropTypes.string.isRequired,
  }),
  selectedOrganization: PropTypes.string.isRequired,
  showGraphToggle: PropTypes.bool,
  tableData: PropTypes.shape({
    InReviewCount: PropTypes.number,
    UnreviewedCount: PropTypes.number,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  widgetVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
};

NotificationsTableContainer.defaultProps = {
  alarmType: null,
  alarms: [],
  alarmsNextLink: null,
  allAlarms: [],
  count: 0,
  getAlarms: () => {},
  isFetchingAlarms: null,
  isFetchingAlarmsStats: null,
  isFetchingAllCameras: null,
  isFetchingDeviceData: null,
  isFetchingLocations: null,
  isFetchingUsers: null,
  profile: { LocalizationPreference: {} },
  showGraphToggle: false,
};

function mapStateToProps(state) {
  const selectedOrganization = permissions.getOrgIdFromStore(state);
  return {
    cameras: state.devices.cameras,
    chartData: state.alarms.alarmsGraphStats,
    count: state.alarms.alarmsCount,
    devices: state.devices.devices,
    filterGroup: AlarmConsts.filterGroup,
    filterStatus: state.filters.filterStatus,
    filters: state.filters.alarms,
    hasCustomers: state.user.permissions.CAN_VIEW_CUSTOMERS,
    isFetchingAlarmsStats: state.alarms.isFetchingAlarmsStats,
    isFetchingAllCameras: state.isFetching.getAllCameras,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
    isFetchingDevices: state.devices.isFetchingDeviceData,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingUsers: state.isFetching.getUsers,
    locations: state.locations.locations,
    profile: state.user.profile,
    selectedOrganization,
    tableData: state.alarms.alarmsSummaryStats,
    users: state.user.users,
    widgetVisibility: state.alarms.widgetVisibility,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...AlarmActions,
        ...DeviceActions,
        ...LocationActions,
        ...FilterActions,
        getUsers,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(NotificationsTableContainer));
