import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { ai } from 'util/telemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

// Components
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { EmptyPlaceholder, MainContentWrapper, PageTitle } from 'lib';
import { PageMessage } from 'containers';

// Actions
import * as LocationActions from 'actions/locations';
import * as UserActions from 'actions/user';
import * as FilterActions from 'actions/filters';
import * as SubscriptionActions from 'actions/subscriptions';
import { hideModal, showModal } from 'actions/modal';
// import * as MenuActions from 'actions/actionMenu';

// Utilities
import { renderLocationDeleteErrors } from 'util/deleteHelper';
import * as tableUtils from 'util/tableData';
import FilterableContainer from 'util/FilterableContainer';
import { generateSort } from 'util/generateSort';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
} from 'constants/urlPaths';

// Styles
import { tableWrapper } from 'sharedStyles/tables.css';

// Constants
import { BULK_ACTION_TABLES, FILTER_GROUPS } from 'constants/app';
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import { defaultSiteDetailsTab } from './constants';
import * as customerConsts from '../constants';

// Components
import LocationsTable from './LocationsTable/LocationsTable';
import SubscriberNavMenu from './SubscriberNavMenu';
import SitesListNav from './SitesListNav';
import handleSuspension from './SuspendLogic';
import NavigationWrapper from '../../NavigationWrapper/NavigationWrapper';

class SubscriberSitesContainer extends FilterableContainer {
  componentDidMount() {
    const { actions, isFetchingLocations, orgId } = this.props;
    if (orgId) {
      this.getServicePackages(this.props);
    }
    if (isFetchingLocations === null && orgId) {
      actions.getLocations(orgId);
    }
  }

  componentDidUpdate(prevProps) {
    const { orgId: prevOrgId } = prevProps;
    const { actions, orgId, lastFetchedOrgId } = this.props;
    if (orgId && !prevOrgId) {
      this.getServicePackages(this.props);
    }
    if (orgId && orgId !== lastFetchedOrgId) {
      actions.getLocations(orgId);
    }
  }

  componentWillUnmount() {
    const { handleClearFilters, history } = this.props;
    handleClearFilters();
    this.context.clearBulkActionIdsFor(BULK_ACTION_TABLES.LOCATIONS);
    if (!history.location) {
      history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}`);
    }
  }

  onClickRow = locationData => {
    const { customer, history } = this.props;
    history.push({
      pathname: `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_SITES}/${locationData.Id}/${defaultSiteDetailsTab}`,
      state: { customerId: customer.Id, siteId: locationData.Id },
    });
  };

  onAddSiteClick = () => {
    const { customer, history } = this.props;
    history.push({
      pathname: `${PATH_ACCOUNTS}${PATH_SEGMENT_SUBSCRIBERS}/${customer.Id}${PATH_SEGMENT_SITES}${PATH_SEGMENT_NEW}`,
      state: { customerId: customer.Id },
    });
  };

  getServicePackages = props => {
    const { actions, dealerId, orgId } = props;
    actions.getServicePackages(dealerId);
    actions.getSubscriberServicePackages(orgId);
  };

  handleMonitoringClick = isChecked => {
    const { actions, orgId, selectedId } = this.props;
    actions.setDealerMonitoring(orgId, selectedId, isChecked);
  };

  handleSuspendClick = location => {
    const { actions, canEditSubscription, customer } = this.props;
    handleSuspension(
      customerConsts.SUSPEND,
      customer,
      [location],
      canEditSubscription,
      actions,
    );
  };

  handleUnsuspendClick = location => {
    const { actions, canEditSubscription, customer } = this.props;
    handleSuspension(
      customerConsts.UNSUSPEND,
      customer,
      [location],
      canEditSubscription,
      actions,
    );
  };

  handleDeleteClick = (idsToDelete, isACC) => {
    const {
      actions,
      canBulkDeleteLocations,
      customer,
      locations,
      profile,
      translate,
    } = this.props;
    const { clearBulkActionIdsFor } = this.context;
    if (!canBulkDeleteLocations && !isACC) {
      return;
    }
    const selectedLocations = idsToDelete.map(id =>
      locations.find(location => location.Id === id),
    );
    // Can these locations be deleted?
    const errorMessage = renderLocationDeleteErrors(selectedLocations, profile);

    if (errorMessage !== null) {
      const onOkClick = () => {
        actions.hideModal();
      };
      const modalProps = {
        handleCancel: () => {
          actions.hideModal();
        },
        message: errorMessage,
        onOkClick,
        title: translate('LOCATIONS.LOCATIONS_MODAL.DELETE_MODAL_HEADER'),
      };
      actions.showModal(modalTypes.INFO_MODAL, modalProps);
    } else {
      const message = (
        <div key="deleteLocationConfirm">
          <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_LOCATIONS_CONFIRM_1" />
          <br />
          <br />
          {selectedLocations.map(l => (
            <li key={l.Id}>{l.Name}</li>
          ))}
          <br />
          <Translate id="LOCATIONS.LOCATIONS_MODAL.DELETE_LOCATIONS_CONFIRM_2" />
        </div>
      );

      const onOkClick = () => {
        clearBulkActionIdsFor(BULK_ACTION_TABLES.LOCATIONS);
        actions.deleteLocations(idsToDelete, customer.Id);
        actions.hideModal();
      };
      const modalProps = {
        handleCancel: () => {
          actions.hideModal();
        },
        message,
        onOkClick,
        title: translate('LOCATIONS.LOCATIONS_MODAL.DELETE_MODAL_HEADER'),
      };
      actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
    }
  };

  render() {
    const {
      access,
      canBulkDeleteLocations,
      canChangeCustomer,
      canDeleteLocation,
      canSuspendLocations,
      containerHeight,
      customer,
      dealerServicePackages,
      isFetchingLocations,
      locations,
      orgId,
      path,
      profile,
      subscriberServicePackages,
      title,
      translate,
    } = this.props;

    return (
      <>
        <SitesListNav
          bulkActionsContext={this.context}
          canBulkDeleteLocations={canBulkDeleteLocations}
          canChangeCustomer={canChangeCustomer}
          currentOrg={customer}
          forceFilterToggle
          navigationTabs={
            <SubscriberNavMenu currentOrg={customer} path={path} />
          }
          onAddClick={() => this.onAddSiteClick()}
          onDeleteClick={this.handleDeleteClick}
          pageTitle={title}
          profile={profile}
        />
        <MainContentWrapper>
          <div className={tableWrapper}>
            <PageMessage
              messageType={[
                messageTypes.CUSTOMER_ERROR,
                messageTypes.LOCATION_ERROR,
                messageTypes.EDIT_CUSTOMER_SUCCESS,
                messageTypes.LOCATION_SUCCESS,
              ]}
            />
            <EmptyPlaceholder
              isFetching={isFetchingLocations}
              items={locations}
              translateKey="FILTER.NO_RESULTS_FOUND"
            >
              <LocationsTable
                access={access}
                canBulkDeleteLocations={canBulkDeleteLocations}
                canDeleteLocation={canDeleteLocation}
                canSuspendLocations={canSuspendLocations}
                handleDeleteClick={this.handleDeleteClick}
                handleSuspendClick={this.handleSuspendClick}
                handleUnsuspendClick={this.handleUnsuspendClick}
                locations={tableUtils.locationTableData(
                  locations,
                  dealerServicePackages,
                  translate,
                )}
                maxHeight={containerHeight}
                onRowClicked={this.onClickRow}
                orgId={orgId}
                servicePackages={subscriberServicePackages}
              />
            </EmptyPlaceholder>
          </div>
        </MainContentWrapper>
      </>
    );
  }
}

SubscriberSitesContainer.contextType = BulkActionContext;

SubscriberSitesContainer.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  customer: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  onBackClick: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any),
  selectedIndex: PropTypes.string,
  selectedIndices: PropTypes.arrayOf(PropTypes.any),
};

SubscriberSitesContainer.defaultProps = {
  customer: {},
  onBackClick: () => null,
  selectedIds: [],
  selectedIndices: [],
  servicePackages: [],
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...FilterActions,
        ...LocationActions,
        ...UserActions,
        ...SubscriptionActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

function mapStateToProps(state, ownProps) {
  const hasTableData = !!(
    state.locations.locations &&
    state.locations.locations.length > 0 &&
    state.locations.servicePackages
  );
  let orgId;
  let newCustomer = {};
  if (ownProps.customer && ownProps.customer.Id) {
    orgId = ownProps.customer.Id;
    newCustomer = ownProps.customer;
  }
  let users = {};
  const userData = state.user.users || [];
  userData.forEach(user => {
    users[user.Id] = user;
  });
  if (state.user.users.length > 0) {
    users = state.user.users.sort(
      generateSort(user => `${user.FirstName} ${user.LastName}`),
    );
  }
  return {
    canAddLocation: state.user.permissions.CAN_CREATE_SITE,
    canBulkDeleteLocations: state.user.permissions.CAN_BULK_DELETE_SITES,
    canDeleteLocation: state.user.permissions.CAN_DELETE_SITE,
    canEditSubscription: state.user.permissions.CAN_EDIT_SUBSCRIPTION,
    canEnableHealthMonitoring:
      state.user.permissions.CAN_ENABLE_HEALTH_MONITORING,
    canViewBillingOrderId: state.user.permissions.CAN_VIEW_BILLING_ORDER_ID,
    customer: newCustomer,
    customerOrganizations: state.user.customerOrganizations,
    dealerServiceFamilies: state.locations.dealerServiceFamilies,
    dealerServicePackages: state.locations.dealerServicePackages,
    filterGroup: FILTER_GROUPS.SUBSCRIBER_SITES,
    filterStatus: state.filters.filterStatus,
    filters: state.filters.locations,
    hasTableData,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingServicePackages: state.isFetching.getServicePackages,
    lastFetchedOrgId: state.locations.lastFetchedOrgId,
    locations: state.locations.locations,
    modalIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.ADD_EDIT_LOCATION,
    orgId,
    profile: state.user.profile,
    selectedLocationId: state.modal.modalProps.locationId,
    selfService: state.user.permissions.SELF_SERVICE,
    subscriberServicePackages: state.user.subscriberServicePackages,
    timezones: state.utilities.timezones,
    users: users || {},
  };
}

export default withLocalize(
  NavigationWrapper(
    withRouter(
      withAITracking(
        ai.reactPlugin,
        SubscriberSitesContainer,
        'SubscriberSitesContainer',
      ),
    ),
    mapStateToProps,
    mapDispatchToProps,
    modalTypes.ADD_EDIT_LOCATION,
    {
      textInputFields: customerConsts.textInputFields,
    },
  ),
);
