import flags from '../config/featureFlags';

export function createToggleRouter() {
  // To switch a feature flag on your local machine, add it to your localStorage:
  // localStorage.featureFlags = JSON.stringify({ 'feature-name': { enabled: true }});

  const localStorageOverrides =
    localStorage && localStorage.featureFlags
      ? JSON.parse(localStorage.featureFlags)
      : {};

  const featureConfig = Object.assign({}, flags, localStorageOverrides);

  return {
    featureIsEnabled(featureName) {
      const feature = featureConfig[featureName];
      return feature && feature.enabled;
    },

    setFeature(featureName, isEnabled) {
      featureConfig[featureName].enabled = isEnabled;
    },
  };
}

export const appToggles = createToggleRouter(flags);
