/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// containers
import { PageMessage } from 'containers';

// Components
import { VideoPlayer } from 'components';
import Spinner from 'react-md-spinner';

// Constants
import { STREAM_TYPES } from 'constants/app';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as alarmConstants from './constants';

// Styles
import { emptyVideoPlayer } from './styles.css';
import { videoPlayerWrap } from '../../../components/PlayerHost/styles.css';

class NotificationClipVideo extends Component {
  shouldComponentUpdate(nextProps) {
    const { clipDisposition, clipId, playerHeight, playerWidth } = this.props;
    if (
      clipId !== nextProps.clipId ||
      clipDisposition !== nextProps.clipDisposition ||
      playerWidth !== nextProps.playerWidth ||
      playerHeight !== nextProps.playerHeight
    ) {
      // Because a new URI is generated every time the alarm data is re-fetched
      // and because the alarm data is frequently re-fetched in response to signalR updates
      // respond only to changes in the immutable clipId
      return true;
    }
    return false;
  }

  render() {
    const {
      cameraId,
      cameraRemoteId,
      clipDisposition,
      clipId,
      clipUri,
      deviceId,
      handleGetFullEvent,
      isFetching,
      playerHeight,
      playerWidth,
    } = this.props;
    const clipUnavailable = clipDisposition !== alarmConstants.uploadSucceeded;
    const connectionError = clipId === null || clipUnavailable;
    return (
      <>
        {isFetching ? (
          <div className={emptyVideoPlayer}>
            <Spinner />
          </div>
        ) : connectionError ? (
          <div className={videoPlayerWrap}>
            <PageMessage
              fixedPosition={false}
              messageStyle={messageStyleStrings.error}
              translateBody="GENERAL_MESSAGES.CONNECTION_CLIP_ERROR"
              visible
            />
          </div>
        ) : (
          <VideoPlayer
            cameraId={cameraId}
            cameraRemoteId={cameraRemoteId}
            cameraResHeight={playerHeight}
            cameraResWidth={playerWidth}
            clipUri={clipUri}
            deviceId={deviceId}
            getFullEventVideo={handleGetFullEvent}
            height={playerHeight}
            hideStatusBox
            id={alarmConstants.clipVideo}
            streamType={STREAM_TYPES.recorded}
            width={playerWidth}
          />
        )}
      </>
    );
  }
}

NotificationClipVideo.propTypes = {
  cameraId: PropTypes.string.isRequired,
  cameraRemoteId: PropTypes.string.isRequired,
  clipDisposition: PropTypes.string.isRequired,
  clipId: PropTypes.string.isRequired,
  clipUri: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
  handleGetFullEvent: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  playerHeight: PropTypes.number.isRequired,
  playerWidth: PropTypes.number.isRequired,
};

NotificationClipVideo.defaultProps = {
  isFetching: null,
};

export default NotificationClipVideo;
