// Libs
import React from 'react';
import { Translate } from 'react-localize-redux';

// Components
import { LoggedOutContentWrapper } from 'components';

// Styles
import { body, box } from './styles.css';

const InvalidToken = () => (
  <LoggedOutContentWrapper header={<Translate id="INVALID_TOKEN.HEADER" />}>
    <div className={box}>
      <div className={body}>
        <Translate id="INVALID_TOKEN.BODY" />
      </div>
    </div>
  </LoggedOutContentWrapper>
);

export default InvalidToken;
