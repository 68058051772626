import { HIDE_MESSAGE, SHOW_MESSAGE } from 'constants/ActionTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';

export function showMessage(messageType, body, header, props) {
  return {
    body,
    header,
    messageType,
    props,
    type: SHOW_MESSAGE,
  };
}

export function showPersistentMessage(messageType, body, props = {}) {
  return {
    body,
    messageType,
    props: {
      ...props,
      keepOpen: true,
    },
    type: SHOW_MESSAGE,
  };
}

export function showTranslatedError(
  messageType,
  translateBody,
  props = { keepOpen: false },
) {
  return {
    body: null,
    header: null,
    messageType,
    props: {
      ...props,
      messageStyle: messageStyleStrings.error,
      translateBody,
    },
    type: SHOW_MESSAGE,
  };
}

export function showPersistentTranslatedError(
  messageType,
  translateBody,
  props = {},
) {
  return {
    body: null,
    header: null,
    messageType,
    props: {
      ...props,
      keepOpen: true,
      messageStyle: messageStyleStrings.error,
      translateBody,
    },
    type: SHOW_MESSAGE,
  };
}

export function hideMessage() {
  return {
    type: HIDE_MESSAGE,
  };
}
