// Libs
import PropTypes from 'prop-types';

import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { Translate } from 'react-localize-redux';

// Components
import { ToggleSwitch } from 'components';
import { NoStyleButton } from 'lib';

// Style
import {
  container,
  popupShow,
  popupHidden,
  popupLabel,
  menuItem,
  settingsLabel,
} from './styles.css';

// Constants
import * as settingsConsts from './constants';

class SettingsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  handleClickOutside = () => {
    this.setState({ showMenu: false });
  };

  toggleMenu = () => {
    if (this.props.isEnabled) {
      this.setState(prevState => ({
        showMenu: !prevState.showMenu,
      }));
    }
  };

  onVideoQualityChanged = value => {
    const quality = value ? settingsConsts.HIGH : settingsConsts.LOW;
    this.props.setVideoQuality(quality);
    // this.setState({ showMenu: false });
  };

  onRulesOverlayChanged = value => {
    // F/T toggle buttons are in reverse position
    this.props.setRulesOverlayVisibility(!value);
  };

  render() {
    return (
      <div className={container}>
        <div className={this.state.showMenu ? popupShow : popupHidden}>
          {this.props.enableCalibration && !this.props.hideFocusButton ? (
            <div className={menuItem}>
              <span className={popupLabel}>
                <Translate id="VIDEO_PLAYER.FOCUS_LABEL" />
              </span>
              <NoStyleButton onMouseDown={this.props.autofocus}>
                <div>
                  <span className={settingsLabel}>
                    <Translate id="VIDEO_PLAYER.AUTO_LABEL" />
                  </span>
                </div>
              </NoStyleButton>
            </div>
          ) : null}
          {!this.props.hideOverlayToggle ? (
            <div className={menuItem}>
              <span className={popupLabel}>
                <Translate id="VIDEO_PLAYER.OVERLAY_LABEL" />
              </span>
              <ToggleSwitch
                id={settingsConsts.ID_BUTTON_TOGGLE_OVERLAYS}
                isActive={!this.props.showRulesOverlay}
                labelFalse={<Translate id="VIDEO_PLAYER.TOGGLE_SHOW_LABEL" />}
                labelTrue={<Translate id="VIDEO_PLAYER.TOGGLE_HIDE_LABEL" />}
                onToggle={this.onRulesOverlayChanged}
              />
            </div>
          ) : null}
          <div className={menuItem}>
            <span className={popupLabel}>
              <Translate id="VIDEO_PLAYER.QUALITY_LABEL" />
            </span>
            <ToggleSwitch
              id={settingsConsts.ID_BUTTON_TOGGLE_VIDEO_QUALITY}
              isActive={this.props.videoQuality === settingsConsts.HIGH}
              labelFalse={
                <Translate id="VIDEO_PLAYER.VIDEO_QUALITY_LOW_LABEL" />
              }
              labelTrue={<Translate id="VIDEO_PLAYER.VIDEO_QUALITY_HI_LABEL" />}
              onToggle={this.onVideoQualityChanged}
            />
          </div>
        </div>
        <NoStyleButton
          id={settingsConsts.ID_MENU}
          className={this.props.buttonStyle}
          onClick={this.toggleMenu}
        >
          {this.props.children}
        </NoStyleButton>
      </div>
    );
  }
}

SettingsPopup.defaultProps = {
  isEnabled: true,
  items: [],
  hideOverlayToggle: false,
  hideFocusButton: false,
  setRulesOverlayVisibility: () => {},
};

SettingsPopup.propTypes = {
  setRulesOverlayVisibility: PropTypes.func,
};

export default onClickOutside(SettingsPopup);
