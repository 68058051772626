/* eslint-disable prefer-destructuring */
import { FORM_DEFAULTS } from 'constants/app';

/* eslint-disable sort-keys-fix/sort-keys-fix */
// This disable rule is necessary to maintain order of tabs.
import {
  PATH_SEGMENT_CONTACTS,
  PATH_SEGMENT_DEALERS,
  PATH_SEGMENT_GENERAL,
  PATH_SEGMENT_MONITORING,
  PATH_SEGMENT_SCHEDULES,
  PATH_SEGMENT_SERVICE_PLANS,
  PATH_SEGMENT_SITES,
  PATH_SEGMENT_SUBSCRIBERS,
  PATH_SEGMENT_USERS,
} from 'constants/urlPaths';

// UserContainer
export const idRoleDropdown = 'roleDropdown';
export const idLocationsDropdown = 'locationsDropdown';
export const idUsersNavHeader = 'usersHeader';
export const avigilon = 'Avigilon';
export const proxy = 'Proxy';
export const idDeleteMessageConfirmOne = 'delete_message_one';
export const idDeleteMessageConfirmTwo = 'delete_message_two';

// LocationContainer
export const idCityDropdown = 'cityDropdown';
export const idRegionDropdown = 'regionDropdown';
export const idCountryDropDown = 'countryDropDown';
export const idLocNavHeader = 'locationsHeader';
export const defaultTermLength = '3';

// Account
export const idGeneralHeader = 'generalHeader';

// Customers
export const idTypeDropdown = 'typeDropdown';
export const idSearchInput = 'searchInput';
export const idTextInput = 'textInput';

export const textInputFields = [
  'Name',
  'Address',
  'BillingNumber',
  'FirstName',
  'LastName',
  'EmailAddress',
];

export const userTextInputFields = ['FirstName', 'LastName', 'EmailAddress'];

export const orgtextInputFields = [
  'Name',
  'Address',
  'BillingNumber',
  'Status',
];

export const contactsTextInputFields = ['Name'];

export const servicePlansTextInputFields = ['Name'];

// Schedule
export const idSchNavHeader = 'schedulesHeader';
export const idSchedulesList = 'schedulesList';
export const idScheduleName = 'scheduleName';
export const scheduleAlways = 'Always';
export const scheduleNever = 'Never';
export const idTimezonesList = 'timeZones';

// Contacts
export const idContNavHeader = 'contactsHeader';
export const idContactLocationFilter = 'contactLocationFilter';

// Service plans
export const idServicePlanNavHeader = 'servicePlansHeader';
export const idServicePlanFilter = 'servicePlanFilter';
export const CAMERA_RESOURCE_TYPE = 1;

// Monitoring
export const monitoringTextInputFields = ['SubscriberName'];

// All
export const adminUserNew = FORM_DEFAULTS.new;
export const SEARCH_PLACEHOLDER = 'LIST_VIEWS.FILTER';

// Table Actions
export const SUSPEND = 'SUSPEND';
export const UNSUSPEND = 'UNSUSPEND';

// Filters
export const filters = {
  contacts: {
    name: {
      field: 'Name',
    },
    site: {
      field: 'locationId',
      label: 'COMMON.SITE',
    },
  },
  orgs: {
    name: {
      field: 'Name',
    },
    status: {
      field: 'Status',
      label: 'Account Status',
    },
    type: {
      field: 'Type',
      label: 'FILTER.ORGS.TYPE',
      type: 'array',
    },
  },
  servicePlans: {
    servicePlan: {
      field: 'ServicePlan',
    },
  },
  users: {
    name: {
      field: 'LastName',
    },
    role: {
      field: 'Role',
      label: 'USERS.FILTER.ROLE_LABEL',
    },
    site: {
      field: 'locationId',
      label: 'COMMON.SITE',
    },
  },
};

export const USERS = {
  ROLE: {
    ORGANIZATION: {
      PLURAL: 'Organizations',
      SINGULAR: 'Organization',
    },
    SUBSCRIBER: {
      PLURAL: 'Subscribers',
      SINGULAR: 'Subscriber',
    },
  },
};

export const LIST_NAV_LABEL = {
  SITE: {
    CITY: {
      ALL_VISIBLE_PLACEHOLDER: 'ACCOUNTS.LOCATIONS.ALL_CITIES_VISIBLE',
      ID: 'cityDropdown',
      LABEL: 'ACCOUNTS.LOCATIONS.CITY',
      NAME: 'City',
    },
    COUNTRY: {
      ALL_VISIBLE_PLACEHOLDER: 'ACCOUNTS.LOCATIONS.ALL_COUNTRIES_VISIBLE',
      ID: 'countryDropDown',
      LABEL: 'ACCOUNTS.LOCATIONS.COUNTRY',
      NAME: 'Country',
    },
    REGION: {
      ALL_VISIBLE_PLACEHOLDER: 'ACCOUNTS.LOCATIONS.ALL_REGIONS_VISIBLE',
      ID: 'regionDropdown',
      LABEL: 'ACCOUNTS.LOCATIONS.REGION',
      NAME: 'Region',
    },
  },
};

/* Shift start day of week used by exceptions schedule
   Default sent by server is Monday(1)
*/
export const changeGridOrder = (grid, startOfWeek) => {
  let newGrid = [];

  switch (startOfWeek) {
    case 0:
      newGrid[0] = grid[6];
      newGrid[1] = grid[0];
      newGrid[2] = grid[1];
      newGrid[3] = grid[2];
      newGrid[4] = grid[3];
      newGrid[5] = grid[4];
      newGrid[6] = grid[5];
      break;
    case 1:
      newGrid = grid;
      break;
    case 6:
      newGrid[0] = grid[5];
      newGrid[1] = grid[6];
      newGrid[2] = grid[0];
      newGrid[3] = grid[1];
      newGrid[4] = grid[2];
      newGrid[5] = grid[3];
      newGrid[6] = grid[4];
      break;
    default:
      newGrid = grid;
      break;
  }

  return newGrid;
};

// Shift back start day of week to Monday(1) to send back to server
export const changeGridOrderBack = (grid, startOfWeek) => {
  let newGrid = [];

  switch (startOfWeek) {
    case 0:
      newGrid[6] = grid[0];
      newGrid[0] = grid[1];
      newGrid[1] = grid[2];
      newGrid[2] = grid[3];
      newGrid[3] = grid[4];
      newGrid[4] = grid[5];
      newGrid[5] = grid[6];
      break;
    case 1:
      newGrid = grid;
      break;
    case 6:
      newGrid[5] = grid[0];
      newGrid[6] = grid[1];
      newGrid[0] = grid[2];
      newGrid[1] = grid[3];
      newGrid[2] = grid[4];
      newGrid[3] = grid[5];
      newGrid[4] = grid[6];
      break;
    default:
      newGrid = grid;
      break;
  }

  return newGrid;
};
export const idGeneralLink = 'infoLink';
export const idCustomersLink = 'customersLink';
export const idDealersLink = 'dealersLink';
export const idOrgInfoLink = 'orgInfoLink';
export const idLocationsLink = 'locationsLink';
export const idUsersLink = 'usersLink';
export const idSchedulesLink = 'schedulesLink';
export const idContactsLink = 'contactsLink';
export const idServicePlansLink = 'servicePlansLink';
export const idMonitoringLink = 'monitoringLink';
export const idDashboardLink = 'idDashboardLink';

export const tabs = {
  subscribers: {
    id: idCustomersLink,
    path: PATH_SEGMENT_SUBSCRIBERS,
    hasPendingClue: false,
  },
  dealers: {
    id: idDealersLink,
    path: PATH_SEGMENT_DEALERS,
    hasPendingClue: false,
  },
  monitoring: {
    id: idMonitoringLink,
    path: PATH_SEGMENT_MONITORING,
    hasPendingClue: false,
  },
  sites: {
    id: idLocationsLink,
    path: PATH_SEGMENT_SITES,
    hasPendingClue: false,
  },
  servicePlans: {
    id: idServicePlansLink,
    path: PATH_SEGMENT_SERVICE_PLANS,
    hasPendingClue: false,
  },
  users: { id: idUsersLink, path: PATH_SEGMENT_USERS, hasPendingClue: false },
  contacts: {
    id: idContactsLink,
    path: PATH_SEGMENT_CONTACTS,
    hasPendingClue: false,
  },
  schedules: {
    id: idSchedulesLink,
    path: PATH_SEGMENT_SCHEDULES,
    hasPendingClue: false,
  },
  general: {
    id: idGeneralLink,
    path: PATH_SEGMENT_GENERAL,
    hasPendingClue: false,
  },
};

export const USA_USA_USA = 'United States';
