export const states = [
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Lousiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

export const countries = [
  { name: 'COUNTRIES.AFGHANISTAN', dbValue: 'Afghanistan', code: 'AF' },
  { name: 'COUNTRIES.ALAND', dbValue: 'Åland Islands', code: 'AX' },
  { name: 'COUNTRIES.ALBANIA', dbValue: 'Albania', code: 'AL' },
  { name: 'COUNTRIES.ALGERIA', dbValue: 'Algeria', code: 'DZ' },
  { name: 'COUNTRIES.AMERICAN', dbValue: 'American Samoa', code: 'AS' },
  { name: 'COUNTRIES.ANDORRA', dbValue: 'AndorrA', code: 'AD' },
  { name: 'COUNTRIES.ANGOLA', dbValue: 'Angola', code: 'AO' },
  { name: 'COUNTRIES.ANGUILLA', dbValue: 'Anguilla', code: 'AI' },
  { name: 'COUNTRIES.ANTARCTICA', dbValue: 'Antarctica', code: 'AQ' },
  { name: 'COUNTRIES.ANTIGUA', dbValue: 'Antigua and Barbuda', code: 'AG' },
  { name: 'COUNTRIES.ARGENTINA', dbValue: 'Argentina', code: 'AR' },
  { name: 'COUNTRIES.ARMENIA', dbValue: 'Armenia', code: 'AM' },
  { name: 'COUNTRIES.ARUBA', dbValue: 'Aruba', code: 'AW' },
  { name: 'COUNTRIES.AUSTRALIA', dbValue: 'Australia', code: 'AU' },
  { name: 'COUNTRIES.AUSTRIA', dbValue: 'Austria', code: 'AT' },
  { name: 'COUNTRIES.AZERBAIJAN', dbValue: 'Azerbaijan', code: 'AZ' },
  { name: 'COUNTRIES.BAHAMAS', dbValue: 'Bahamas', code: 'BS' },
  { name: 'COUNTRIES.BAHRAIN', dbValue: 'Bahrain', code: 'BH' },
  { name: 'COUNTRIES.BANGLADESH', dbValue: 'Bangladesh', code: 'BD' },
  { name: 'COUNTRIES.BARBADOS', dbValue: 'Barbados', code: 'BB' },
  { name: 'COUNTRIES.BELARUS', dbValue: 'Belarus', code: 'BY' },
  { name: 'COUNTRIES.BELGIUM', dbValue: 'Belgium', code: 'BE' },
  { name: 'COUNTRIES.BELIZE', dbValue: 'Belize', code: 'BZ' },
  { name: 'COUNTRIES.BENIN', dbValue: 'Benin', code: 'BJ' },
  { name: 'COUNTRIES.BERMUDA', dbValue: 'Bermuda', code: 'BM' },
  { name: 'COUNTRIES.BHUTAN', dbValue: 'Bhutan', code: 'BT' },
  { name: 'COUNTRIES.BOLIVIA', dbValue: 'Bolivia', code: 'BO' },
  { name: 'COUNTRIES.BOSNIA', dbValue: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'COUNTRIES.BOTSWANA', dbValue: 'Botswana', code: 'BW' },
  { name: 'COUNTRIES.BOUVET', dbValue: 'Bouvet Island', code: 'BV' },
  { name: 'COUNTRIES.BRAZIL', dbValue: 'Brazil', code: 'BR' },
  {
    name: 'COUNTRIES.BRITISH',
    dbValue: 'British Indian Ocean Territory',
    code: 'IO',
  },
  { name: 'COUNTRIES.BRUNEI', dbValue: 'Brunei Darussalam', code: 'BN' },
  { name: 'COUNTRIES.BULGARIA', dbValue: 'Bulgaria', code: 'BG' },
  { name: 'COUNTRIES.BURKINA', dbValue: 'Burkina Faso', code: 'BF' },
  { name: 'COUNTRIES.BURUNDI', dbValue: 'Burundi', code: 'BI' },
  { name: 'COUNTRIES.CAMBODIA', dbValue: 'Cambodia', code: 'KH' },
  { name: 'COUNTRIES.CAMEROON', dbValue: 'Cameroon', code: 'CM' },
  { name: 'COUNTRIES.CANADA', dbValue: 'Canada', code: 'CA' },
  { name: 'COUNTRIES.CAPE', dbValue: 'Cape Verde', code: 'CV' },
  { name: 'COUNTRIES.CAYMAN', dbValue: 'Cayman Islands', code: 'KY' },
  {
    name: 'COUNTRIES.CENTRAL',
    dbValue: 'Central African Republic',
    code: 'CF',
  },
  { name: 'COUNTRIES.CHAD', dbValue: 'Chad', code: 'TD' },
  { name: 'COUNTRIES.CHILE', dbValue: 'Chile', code: 'CL' },
  { name: 'COUNTRIES.CHINA', dbValue: 'China', code: 'CN' },
  { name: 'COUNTRIES.CHRISTMAS', dbValue: 'Christmas Island', code: 'CX' },
  { name: 'COUNTRIES.COCOS', dbValue: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'COUNTRIES.COLOMBIA', dbValue: 'Colombia', code: 'CO' },
  { name: 'COUNTRIES.COMOROS', dbValue: 'Comoros', code: 'KM' },
  { name: 'COUNTRIES.CONGO', dbValue: 'Congo', code: 'CG' },
  {
    name: 'COUNTRIES.CONGO_REP',
    dbValue: 'Congo, The Democratic Republic of the',
    code: 'CD',
  },
  { name: 'COUNTRIES.COOK', dbValue: 'Cook Islands', code: 'CK' },
  { name: 'COUNTRIES.COSTA', dbValue: 'Costa Rica', code: 'CR' },
  { name: 'COUNTRIES.COTE', dbValue: "Cote D'Ivoire", code: 'CI' },
  { name: 'COUNTRIES.CROATIA', dbValue: 'Croatia', code: 'HR' },
  { name: 'COUNTRIES.CUBA', dbValue: 'Cuba', code: 'CU' },
  { name: 'COUNTRIES.CYPRUS', dbValue: 'Cyprus', code: 'CY' },
  { name: 'COUNTRIES.CZECH', dbValue: 'Czech Republic', code: 'CZ' },
  { name: 'COUNTRIES.DENMARK', dbValue: 'Denmark', code: 'DK' },
  { name: 'COUNTRIES.DJIBOUTI', dbValue: 'Djibouti', code: 'DJ' },
  { name: 'COUNTRIES.DOMINICA', dbValue: 'Dominica', code: 'DM' },
  { name: 'COUNTRIES.DOMINICAN', dbValue: 'Dominican Republic', code: 'DO' },
  { name: 'COUNTRIES.ECUADOR', dbValue: 'Ecuador', code: 'EC' },
  { name: 'COUNTRIES.EGYPT', dbValue: 'Egypt', code: 'EG' },
  { name: 'COUNTRIES.EL', dbValue: 'El Salvador', code: 'SV' },
  { name: 'COUNTRIES.EQUATORIAL', dbValue: 'Equatorial Guinea', code: 'GQ' },
  { name: 'COUNTRIES.ERITREA', dbValue: 'Eritrea', code: 'ER' },
  { name: 'COUNTRIES.ESTONIA', dbValue: 'Estonia', code: 'EE' },
  { name: 'COUNTRIES.ETHIOPIA', dbValue: 'Ethiopia', code: 'ET' },
  {
    name: 'COUNTRIES.FALKLAND',
    dbValue: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  { name: 'COUNTRIES.FAROE', dbValue: 'Faroe Islands', code: 'FO' },
  { name: 'COUNTRIES.FIJI', dbValue: 'Fiji', code: 'FJ' },
  { name: 'COUNTRIES.FINLAND', dbValue: 'Finland', code: 'FI' },
  { name: 'COUNTRIES.FRANCE', dbValue: 'France', code: 'FR' },
  { name: 'COUNTRIES.FRENCH_GUI', dbValue: 'French Guiana', code: 'GF' },
  { name: 'COUNTRIES.FRENCH_POL', dbValue: 'French Polynesia', code: 'PF' },
  {
    name: 'COUNTRIES.FRENCH_SOU',
    dbValue: 'French Southern Territories',
    code: 'TF',
  },
  { name: 'COUNTRIES.GABON', dbValue: 'Gabon', code: 'GA' },
  { name: 'COUNTRIES.GAMBIA', dbValue: 'Gambia', code: 'GM' },
  { name: 'COUNTRIES.GEORGIA', dbValue: 'Georgia', code: 'GE' },
  { name: 'COUNTRIES.GERMANY', dbValue: 'Germany', code: 'DE' },
  { name: 'COUNTRIES.GHANA', dbValue: 'Ghana', code: 'GH' },
  { name: 'COUNTRIES.GIBRALTAR', dbValue: 'Gibraltar', code: 'GI' },
  { name: 'COUNTRIES.GREECE', dbValue: 'Greece', code: 'GR' },
  { name: 'COUNTRIES.GREENLAND', dbValue: 'Greenland', code: 'GL' },
  { name: 'COUNTRIES.GRENADA', dbValue: 'Grenada', code: 'GD' },
  { name: 'COUNTRIES.GUADELOUPE', dbValue: 'Guadeloupe', code: 'GP' },
  { name: 'COUNTRIES.GUAM', dbValue: 'Guam', code: 'GU' },
  { name: 'COUNTRIES.GUATEMALA', dbValue: 'Guatemala', code: 'GT' },
  { name: 'COUNTRIES.GUERNSEY', dbValue: 'Guernsey', code: 'GG' },
  { name: 'COUNTRIES.GUINEA', dbValue: 'Guinea', code: 'GN' },
  { name: 'COUNTRIES.GUINEA_BIS', dbValue: 'Guinea-Bissau', code: 'GW' },
  { name: 'COUNTRIES.GUYANA', dbValue: 'Guyana', code: 'GY' },
  { name: 'COUNTRIES.HAITI', dbValue: 'Haiti', code: 'HT' },
  {
    name: 'COUNTRIES.HEARD',
    dbValue: 'Heard Island and Mcdonald Islands',
    code: 'HM',
  },
  {
    name: 'COUNTRIES.HOLY',
    dbValue: 'Holy See (Vatican City State)',
    code: 'VA',
  },
  { name: 'COUNTRIES.HONDURAS', dbValue: 'Honduras', code: 'HN' },
  { name: 'COUNTRIES.HONG', dbValue: 'Hong Kong', code: 'HK' },
  { name: 'COUNTRIES.HUNGARY', dbValue: 'Hungary', code: 'HU' },
  { name: 'COUNTRIES.ICELAND', dbValue: 'Iceland', code: 'IS' },
  { name: 'COUNTRIES.INDIA', dbValue: 'India', code: 'IN' },
  { name: 'COUNTRIES.INDONESIA', dbValue: 'Indonesia', code: 'ID' },
  { name: 'COUNTRIES.IRAN', dbValue: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'COUNTRIES.IRAQ', dbValue: 'Iraq', code: 'IQ' },
  { name: 'COUNTRIES.IRELAND', dbValue: 'Ireland', code: 'IE' },
  { name: 'COUNTRIES.ISLE', dbValue: 'Isle of Man', code: 'IM' },
  { name: 'COUNTRIES.ISRAEL', dbValue: 'Israel', code: 'IL' },
  { name: 'COUNTRIES.ITALY', dbValue: 'Italy', code: 'IT' },
  { name: 'COUNTRIES.JAMAICA', dbValue: 'Jamaica', code: 'JM' },
  { name: 'COUNTRIES.JAPAN', dbValue: 'Japan', code: 'JP' },
  { name: 'COUNTRIES.JERSEY', dbValue: 'Jersey', code: 'JE' },
  { name: 'COUNTRIES.JORDAN', dbValue: 'Jordan', code: 'JO' },
  { name: 'COUNTRIES.KAZAKHSTAN', dbValue: 'Kazakhstan', code: 'KZ' },
  { name: 'COUNTRIES.KENYA', dbValue: 'Kenya', code: 'KE' },
  { name: 'COUNTRIES.KIRIBATI', dbValue: 'Kiribati', code: 'KI' },
  {
    name: 'COUNTRIES.KOREA_DEM',
    dbValue: "Korea, Democratic People'S Republic of",
    code: 'KP',
  },
  { name: 'COUNTRIES.KOREA', dbValue: 'Korea, Republic of', code: 'KR' },
  { name: 'COUNTRIES.KUWAIT', dbValue: 'Kuwait', code: 'KW' },
  { name: 'COUNTRIES.KYRGYZSTAN', dbValue: 'Kyrgyzstan', code: 'KG' },
  {
    name: 'COUNTRIES.LAO',
    dbValue: "Lao People'S Democratic Republic",
    code: 'LA',
  },
  { name: 'COUNTRIES.LATVIA', dbValue: 'Latvia', code: 'LV' },
  { name: 'COUNTRIES.LEBANON', dbValue: 'Lebanon', code: 'LB' },
  { name: 'COUNTRIES.LESOTHO', dbValue: 'Lesotho', code: 'LS' },
  { name: 'COUNTRIES.LIBERIA', dbValue: 'Liberia', code: 'LR' },
  { name: 'COUNTRIES.LIBYAN', dbValue: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'COUNTRIES.LIECHTENSTEIN', dbValue: 'Liechtenstein', code: 'LI' },
  { name: 'COUNTRIES.LITHUANIA', dbValue: 'Lithuania', code: 'LT' },
  { name: 'COUNTRIES.LUXEMBOURG', dbValue: 'Luxembourg', code: 'LU' },
  { name: 'COUNTRIES.MACAO', dbValue: 'Macao', code: 'MO' },
  {
    name: 'COUNTRIES.MACEDONIA',
    dbValue: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
  },
  { name: 'COUNTRIES.MADAGASCAR', dbValue: 'Madagascar', code: 'MG' },
  { name: 'COUNTRIES.MALAWI', dbValue: 'Malawi', code: 'MW' },
  { name: 'COUNTRIES.MALAYSIA', dbValue: 'Malaysia', code: 'MY' },
  { name: 'COUNTRIES.MALDIVES', dbValue: 'Maldives', code: 'MV' },
  { name: 'COUNTRIES.MALI', dbValue: 'Mali', code: 'ML' },
  { name: 'COUNTRIES.MALTA', dbValue: 'Malta', code: 'MT' },
  {
    name: 'COUNTRIES.MARSHALL',
    dbValue: 'Marshall Islands',
    code: 'MH',
  },
  { name: 'COUNTRIES.MARTINIQUE', dbValue: 'Martinique', code: 'MQ' },
  { name: 'COUNTRIES.MAURITANIA', dbValue: 'Mauritania', code: 'MR' },
  { name: 'COUNTRIES.MAURITIUS', dbValue: 'Mauritius', code: 'MU' },
  { name: 'COUNTRIES.MAYOTTE', dbValue: 'Mayotte', code: 'YT' },
  { name: 'COUNTRIES.MEXICO', dbValue: 'Mexico', code: 'MX' },
  {
    name: 'COUNTRIES.MICRONESIA',
    dbValue: 'Micronesia, Federated States of',
    code: 'FM',
  },
  { name: 'COUNTRIES.MOLDOVA', dbValue: 'Moldova, Republic of', code: 'MD' },
  { name: 'COUNTRIES.MONACO', dbValue: 'Monaco', code: 'MC' },
  { name: 'COUNTRIES.MONGOLIA', dbValue: 'Mongolia', code: 'MN' },
  { name: 'COUNTRIES.MONTENEGRO', dbValue: 'Montenegro', code: 'ME' },
  { name: 'COUNTRIES.MONTSERRAT', dbValue: 'Montserrat', code: 'MS' },
  { name: 'COUNTRIES.MOROCCO', dbValue: 'Morocco', code: 'MA' },
  { name: 'COUNTRIES.MOZAMBIQUE', dbValue: 'Mozambique', code: 'MZ' },
  { name: 'COUNTRIES.MYANMAR', dbValue: 'Myanmar', code: 'MM' },
  { name: 'COUNTRIES.NAMIBIA', dbValue: 'Namibia', code: 'NA' },
  { name: 'COUNTRIES.NAURU', dbValue: 'Nauru', code: 'NR' },
  { name: 'COUNTRIES.NEPAL', dbValue: 'Nepal', code: 'NP' },
  { name: 'COUNTRIES.NETHERLANDS', dbValue: 'Netherlands', code: 'NL' },
  {
    name: 'COUNTRIES.NETHERLANDS_ANT',
    dbValue: 'Netherlands Antilles',
    code: 'AN',
  },
  { name: 'COUNTRIES.NEW_CALEDONIA', dbValue: 'New Caledonia', code: 'NC' },
  { name: 'COUNTRIES.NEW_ZEALAND', dbValue: 'New Zealand', code: 'NZ' },
  { name: 'COUNTRIES.NICARAGUA', dbValue: 'Nicaragua', code: 'NI' },
  { name: 'COUNTRIES.NIGER', dbValue: 'Niger', code: 'NE' },
  { name: 'COUNTRIES.NIGERIA', dbValue: 'Nigeria', code: 'NG' },
  { name: 'COUNTRIES.NIUE', dbValue: 'Niue', code: 'NU' },
  { name: 'COUNTRIES.NORFOLK', dbValue: 'Norfolk Island', code: 'NF' },
  {
    name: 'COUNTRIES.NORTHERN',
    dbValue: 'Northern Mariana Islands',
    code: 'MP',
  },
  { name: 'COUNTRIES.NORWAY', dbValue: 'Norway', code: 'NO' },
  { name: 'COUNTRIES.OMAN', dbValue: 'Oman', code: 'OM' },
  { name: 'COUNTRIES.PAKISTAN', dbValue: 'Pakistan', code: 'PK' },
  { name: 'COUNTRIES.PALAU', dbValue: 'Palau', code: 'PW' },
  {
    name: 'COUNTRIES.PALESTINIAN',
    dbValue: 'Palestinian Territory, Occupied',
    code: 'PS',
  },
  { name: 'COUNTRIES.PANAMA', dbValue: 'Panama', code: 'PA' },
  { name: 'COUNTRIES.PAPUA', dbValue: 'Papua New Guinea', code: 'PG' },
  { name: 'COUNTRIES.PARAGUAY', dbValue: 'Paraguay', code: 'PY' },
  { name: 'COUNTRIES.PERU', dbValue: 'Peru', code: 'PE' },
  { name: 'COUNTRIES.PHILIPPINES', dbValue: 'Philippines', code: 'PH' },
  { name: 'COUNTRIES.PITCAIRN', dbValue: 'Pitcairn', code: 'PN' },
  { name: 'COUNTRIES.POLAND', dbValue: 'Poland', code: 'PL' },
  { name: 'COUNTRIES.PORTUGAL', dbValue: 'Portugal', code: 'PT' },
  { name: 'COUNTRIES.PUERTO', dbValue: 'Puerto Rico', code: 'PR' },
  { name: 'COUNTRIES.QATAR', dbValue: 'Qatar', code: 'QA' },
  { name: 'COUNTRIES.REUNION', dbValue: 'Reunion', code: 'RE' },
  { name: 'COUNTRIES.ROMANIA', dbValue: 'Romania', code: 'RO' },
  { name: 'COUNTRIES.RUSSIAN', dbValue: 'Russian Federation', code: 'RU' },
  { name: 'COUNTRIES.RWANDA', dbValue: 'RWANDA', code: 'RW' },
  { name: 'COUNTRIES.SAINT_HELENA', dbValue: 'Saint Helena', code: 'SH' },
  {
    name: 'COUNTRIES.SAINT_KITTS',
    dbValue: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  { name: 'COUNTRIES.SAINT_LUCIA', dbValue: 'Saint Lucia', code: 'LC' },
  {
    name: 'COUNTRIES.SAINT_PIERRE',
    dbValue: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    name: 'COUNTRIES.SAINT_VINCENT',
    dbValue: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  { name: 'COUNTRIES.SAMOA', dbValue: 'Samoa', code: 'WS' },
  { name: 'COUNTRIES.SAN', dbValue: 'San Marino', code: 'SM' },
  { name: 'COUNTRIES.SAO', dbValue: 'Sao Tome and Principe', code: 'ST' },
  { name: 'COUNTRIES.SAUDI', dbValue: 'Saudi Arabia', code: 'SA' },
  { name: 'COUNTRIES.SENEGAL', dbValue: 'Senegal', code: 'SN' },
  { name: 'COUNTRIES.SERBIA', dbValue: 'Serbia', code: 'RS' },
  { name: 'COUNTRIES.SEYCHELLES', dbValue: 'Seychelles', code: 'SC' },
  { name: 'COUNTRIES.SIERRA', dbValue: 'Sierra Leone', code: 'SL' },
  { name: 'COUNTRIES.SINGAPORE', dbValue: 'Singapore', code: 'SG' },
  { name: 'COUNTRIES.SLOVAKIA', dbValue: 'Slovakia', code: 'SK' },
  { name: 'COUNTRIES.SLOVENIA', dbValue: 'Slovenia', code: 'SI' },
  { name: 'COUNTRIES.SOLOMON', dbValue: 'Solomon Islands', code: 'SB' },
  { name: 'COUNTRIES.SOMALIA', dbValue: 'Somalia', code: 'SO' },
  { name: 'COUNTRIES.SOUTH_AFRICA', dbValue: 'South Africa', code: 'ZA' },
  {
    name: 'COUNTRIES.SOUTH_GEORGIA',
    dbValue: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  { name: 'COUNTRIES.SPAIN', dbValue: 'Spain', code: 'ES' },
  { name: 'COUNTRIES.SRI', dbValue: 'Sri Lanka', code: 'LK' },
  { name: 'COUNTRIES.SUDAN', dbValue: 'Sudan', code: 'SD' },
  { name: 'COUNTRIES.SOUTH_SUDAN', dbValue: 'South Sudan', code: 'SS' },
  { name: 'COUNTRIES.SURINAME', dbValue: 'Suriname', code: 'SR' },
  { name: 'COUNTRIES.SVALBARD', dbValue: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'COUNTRIES.SWAZILAND', dbValue: 'Swaziland', code: 'SZ' },
  { name: 'COUNTRIES.SWEDEN', dbValue: 'Sweden', code: 'SE' },
  { name: 'COUNTRIES.SWITZERLAND', dbValue: 'Switzerland', code: 'CH' },
  { name: 'COUNTRIES.SYRIAN', dbValue: 'Syrian Arab Republic', code: 'SY' },
  {
    name: 'COUNTRIES.TAIWAN',
    dbValue: 'Taiwan, Province of China',
    code: 'TW',
  },
  { name: 'COUNTRIES.TAJIKISTAN', dbValue: 'Tajikistan', code: 'TJ' },
  {
    name: 'COUNTRIES.TANZANIA',
    dbValue: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  { name: 'COUNTRIES.THAILAND', dbValue: 'Thailand', code: 'TH' },
  { name: 'COUNTRIES.TIMOR', dbValue: 'Timor-Leste', code: 'TL' },
  { name: 'COUNTRIES.TOGO', dbValue: 'Togo', code: 'TG' },
  { name: 'COUNTRIES.TOKELAU', dbValue: 'Tokelau', code: 'TK' },
  { name: 'COUNTRIES.TONGA', dbValue: 'Tonga', code: 'TO' },
  { name: 'COUNTRIES.TRINIDAD', dbValue: 'Trinidad and Tobago', code: 'TT' },
  { name: 'COUNTRIES.TUNISIA', dbValue: 'Tunisia', code: 'TN' },
  { name: 'COUNTRIES.TURKEY', dbValue: 'Turkey', code: 'TR' },
  { name: 'COUNTRIES.TURKMENISTAN', dbValue: 'Turkmenistan', code: 'TM' },
  { name: 'COUNTRIES.TURKS', dbValue: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'COUNTRIES.TUVALU', dbValue: 'Tuvalu', code: 'TV' },
  { name: 'COUNTRIES.UGANDA', dbValue: 'Uganda', code: 'UG' },
  { name: 'COUNTRIES.UKRAINE', dbValue: 'Ukraine', code: 'UA' },
  {
    name: 'COUNTRIES.UNITED_ARAB',
    dbValue: 'United Arab Emirates',
    code: 'AE',
  },
  { name: 'COUNTRIES.UNITED_KINGDOM', dbValue: 'United Kingdom', code: 'GB' },
  { name: 'COUNTRIES.UNITED_STATES', dbValue: 'United States', code: 'US' },
  {
    name: 'COUNTRIES.UNITED_STATES_MIN',
    dbValue: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  { name: 'COUNTRIES.URUGUAY', dbValue: 'Uruguay', code: 'UY' },
  { name: 'COUNTRIES.UZBEKISTAN', dbValue: 'Uzbekistan', code: 'UZ' },
  { name: 'COUNTRIES.VANUATU', dbValue: 'Vanuatu', code: 'VU' },
  { name: 'COUNTRIES.VENEZUELA', dbValue: 'Venezuela', code: 'VE' },
  { name: 'COUNTRIES.VIET', dbValue: 'Viet Nam', code: 'VN' },
  {
    name: 'COUNTRIES.VIRGIN_ISLANDS_BRI',
    dbValue: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    name: 'COUNTRIES.VIRGIN_ISLANDS_USA',
    dbValue: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  { name: 'COUNTRIES.WALLIS', dbValue: 'Wallis and Futuna', code: 'WF' },
  { name: 'COUNTRIES.WESTERN', dbValue: 'Western Sahara', code: 'EH' },
  { name: 'COUNTRIES.YEMEN', dbValue: 'Yemen', code: 'YE' },
  { name: 'COUNTRIES.ZAMBIA', dbValue: 'Zambia', code: 'ZM' },
  { name: 'COUNTRIES.ZIMBABWE', dbValue: 'Zimbabwe', code: 'ZW' },
];
