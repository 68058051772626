import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Draggable from 'react-draggable';

// Constants
import { canvasObjectTypes } from 'constants/app';
import { directionAngleArrowStyle } from './styles.css';

const MIN_POLYGON_POINT_COUNT = 3;
const classNames = {
  roi: 'dragHandleRoi',
  privacy: 'dragHandlePrivacyZone',
  classified: 'dragHandleClassified',
  pixel: 'dragHandlePixel',
};

class Polygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTransforming: false,
      selectedIndex: null,
      dragPosition: {
        x: this.calculateInitialPosition(props.points).x,
        y: this.calculateInitialPosition(props.points).y,
      },
      points: this.getInitialPointsFromDimensions(props.points),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isTransforming } = this.state;
    if (!isTransforming) {
      this.setState({
        dragPosition: {
          x: this.calculateInitialPosition(nextProps.points).x,
          y: this.calculateInitialPosition(nextProps.points).y,
        },
        points: this.getInitialPointsFromDimensions(nextProps.points),
      });
    }
  }

  getInitialPointsFromDimensions(initialPoints) {
    const { initialHeight, initialWidth } = this.props;
    if (initialPoints && initialPoints.length > 0) {
      return initialPoints;
    }
    const height = initialHeight;
    const width = initialWidth;
    const points = [
      { x: 0, y: 0 },
      { x: width, y: 0 },
      { x: width, y: height },
      { x: 0, y: height },
    ];
    return points;
  }

  startResize = ix => {
    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.stopMouseActions, false);
    this.setState({
      isTransforming: true,
      selectedIndex: ix,
    });
  };

  stopMouseActions = () => {
    const { selectedIndex, points } = this.state;
    const { onObjectUpdated, id } = this.props;
    const ix = selectedIndex;
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.stopMouseActions, false);

    if (this.shouldRemovePoint()) {
      const newPoints = [...points];
      newPoints.splice(selectedIndex, 1);
      this.setState({ points: newPoints });
    }

    const intersectPoint = this.isOverlapping(points[ix]);
    if (intersectPoint) {
      this.setState(state => {
        const points = [...state.points];
        const point = { ...points[ix] };
        point.x = intersectPoint.x;
        point.y = intersectPoint.y;
        points[ix] = point;
        return { points };
      });
    }

    this.setState({
      isTransforming: false,
      selectedIndex: null,
    });

    onObjectUpdated(this.export(), id);
  };

  shouldRemovePoint = () => {
    const { points } = this.state;
    const { selectedIndex } = this.state;
    const point = points[selectedIndex];
    const leftPoint = points[selectedIndex - 1] || points[points.length - 1];
    const rightPoint = points[selectedIndex + 1] || points[0];

    if (!point) {
      return false;
    }

    // horizontal line check
    if (
      Math.abs(point.x - leftPoint.x) <= 5 &&
      Math.abs(point.x - rightPoint.x) <= 5
    ) {
      return true;
    }

    // vertical line check
    if (
      Math.abs(point.y - leftPoint.y) <= 5 &&
      Math.abs(point.y - rightPoint.y) <= 5
    ) {
      return true;
    }

    // diagonal line check
    if (
      +Math.abs(
        (point.y - leftPoint.y) / (point.x - leftPoint.x) -
          (rightPoint.y - leftPoint.y) / (rightPoint.x - leftPoint.x),
      ).toFixed(2) < 0.03
    ) {
      return true;
    }

    return false;
  };

  onResize = event => {
    const { selectedIndex, isTransforming } = this.state;
    const { canvasDocumentCoords } = this.props;
    const ix = selectedIndex;
    if (isTransforming) {
      const { bottom, left, right, top } = canvasDocumentCoords;
      const { clientX, clientY } = event;
      if (
        clientX <= right &&
        clientX > left &&
        clientY <= bottom &&
        clientY > top
      ) {
        this.setState(state => {
          const points = [...state.points];
          const point = { ...points[ix] };
          const newX = event.offsetX - state.dragPosition.x;
          const newY = event.offsetY - state.dragPosition.y;
          if (newX > 0 && newY > 0 && point) {
            point.x = newX;
            point.y = newY;
            point.isDropped = true;
          }
          points[ix] = point;
          return { points };
        });
      }
    }
  };

  // check if there's overlap between selected point and all other points
  isOverlapping = point => {
    const { points, selectedIndex } = this.state;
    const leftIndex =
      selectedIndex === 0 ? points.length - 1 : selectedIndex - 1;
    const rightIndex =
      selectedIndex === points.length - 1 ? 0 : selectedIndex + 1;

    for (let i = 0; i < points.length; i += 1) {
      const leftPoint = points[i];
      const rightPoint = points[i + 1] || points[0];

      const intersectionPointLeft = this.intersects(
        point.x,
        point.y,
        points[leftIndex].x,
        points[leftIndex].y,
        leftPoint.x,
        leftPoint.y,
        rightPoint.x,
        rightPoint.y,
      );

      const intersectionPointRight = this.intersects(
        point.x,
        point.y,
        points[rightIndex].x,
        points[rightIndex].y,
        leftPoint.x,
        leftPoint.y,
        rightPoint.x,
        rightPoint.y,
      );

      const intersectionPoint = intersectionPointLeft || intersectionPointRight;

      if (intersectionPoint) {
        return intersectionPoint;
      }
    }

    return false;
  };

  // check if two lines intersect
  intersects = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    let det;
    let gamma;
    let lambda;
    det = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);
    if (det === 0) {
      return false;
    }
    lambda = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / det;
    gamma = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / det;

    const x = Math.round(x1 + lambda * (x2 - x1));
    const y = Math.round(y1 + lambda * (y2 - y1));

    if (lambda > 0 && lambda < 1 && (gamma > 0 && gamma < 1)) {
      return { x, y };
    }
    return false;
  };

  getMidPoint = (p1, p2) => {
    const midPoint = {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
    return midPoint;
  };

  pointIsInLine = (p1, p2, p3) => {
    const a1 = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
    const a2 = (Math.atan2(p3.y - p2.y, p3.x - p2.x) * 180) / Math.PI;
    if (Math.abs(a1 - a2) < 10) {
      return true;
    }
    // if angles are similar but negative of one another and lines are short
    const isInLine = Math.abs(a1) - Math.abs(a2) < 10;
    const isTiny = Math.abs(p2.y - p1.y) < 10 && Math.abs(p2.x - p1.x) < 10;
    return isInLine && isTiny;
  };

  onControlledDragStart = (e, position) => {
    const { x, y } = position;
    this.setState({ dragPosition: { x, y }, isTransforming: true });
  };

  onControlledDragStop = (e, position) => {
    // TODO: prevent user from dragging outside bounds of parent canvas
    let { x, y } = position;
    const { id, parentHeight, parentWidth, onObjectUpdated } = this.props;
    const pointsAdjusted = this.export();
    pointsAdjusted.forEach(pointObj => {
      if (pointObj.x < 0 || pointObj.x > parentWidth) {
        x = 0;
      }
      if (pointObj.y < 0 || pointObj.y > parentHeight) {
        y = 0;
      }
    });

    this.setState({
      dragPosition: { x, y },
      isTransforming: false,
    });
    onObjectUpdated(this.export(), id);
  };

  addNewPoint = (ix, point) => {
    const newPoints = [...this.state.points];
    newPoints.splice(ix, 0, point);
    this.setState({ points: newPoints }, () => {
      this.startResize(ix);
    });
  };

  getDragControls = () => {
    const { points } = this.state;
    const { isSelected } = this.props;
    return points.map((point, ix) => {
      const key = ix;
      const width = 15;
      const height = 15;
      const size = 8;
      const nextIx = points[ix + 1] ? ix + 1 : 0;
      const nextPoint = points[nextIx];
      const style = isSelected ? { zIndex: 1 } : { display: 'none' };
      const styleOuter = isSelected
        ? { zIndex: 2, fill: 'transparent' }
        : { display: 'none' };
      return [
        <rect
          className="controlPointVisible"
          key={`inner_${key}`}
          x={point.x - 5}
          y={point.y - 5}
          width={width}
          height={height}
          style={style}
        />,
        <rect
          key={`outer_${key}`}
          className="controlPoint"
          x={point.x - 35}
          y={point.y - 35}
          width={width * 5}
          height={height * 5}
          style={styleOuter}
          onMouseDown={this.startResize.bind(this, ix)}
        />,
        <circle
          className="controlPointVisible"
          key={`inner_circle_${key}`}
          cx={(point.x + nextPoint.x) / 2}
          cy={(point.y + nextPoint.y) / 2}
          r={size}
          style={style}
        />,
        <circle
          key={`outer_circle_${key}`}
          className="controlPoint"
          cx={(point.x + nextPoint.x) / 2}
          cy={(point.y + nextPoint.y) / 2}
          r={size * 5}
          style={styleOuter}
          onMouseDown={() =>
            this.addNewPoint(nextIx, {
              x: (point.x + nextPoint.x) / 2,
              y: (point.y + nextPoint.y) / 2,
            })
          }
        />,
      ];
    });
  };

  select = () => {
    const { onObjectSelected, id } = this.props;
    onObjectSelected(id);
  };

  calculateInitialPosition(initialPoints) {
    const {
      left,
      parentWidth,
      parentHeight,
      initialHeight,
      initialWidth,
      top,
    } = this.props;
    let positionTop = 0;
    let positionLeft = 0;
    if (initialPoints) {
      return {
        x: positionLeft,
        y: positionTop,
      };
    }

    positionLeft = left || (parentWidth - initialWidth) / 2;
    positionTop = top || (parentHeight - initialHeight) / 2;

    return {
      x: positionLeft,
      y: positionTop,
    };
  }

  addMidPoints() {
    const { points: statePoints } = this.state;
    const points = statePoints.slice();
    const ix = points.findIndex(point => point.isDropped);
    if (ix < 0) return;
    const point = points[ix];
    delete point.isDropped;
    let pointPrevious;
    let pointNext;
    let pointInsertAfter;
    let pointInsertBefore;
    if (ix === points.length - 1) {
      pointPrevious = points[ix - 1];
      pointNext = points[0];
      if (this.pointIsInLine(pointPrevious, point, pointNext)) {
        if (points.length > MIN_POLYGON_POINT_COUNT) {
          points.splice(ix, 1);
        }
      } else {
        pointInsertBefore = this.getMidPoint(pointPrevious, point);
        pointInsertAfter = this.getMidPoint(point, pointNext);
        points.splice(ix + 1, 0, pointInsertAfter);
        points.splice(ix, 0, pointInsertBefore);
      }
    } else if (ix === 0) {
      pointPrevious = points[points.length - 1];
      pointNext = points[1];
      if (this.pointIsInLine(pointPrevious, point, pointNext)) {
        if (points.length > MIN_POLYGON_POINT_COUNT) {
          points.splice(ix, 1);
        }
      } else {
        pointInsertBefore = this.getMidPoint(pointPrevious, point);
        pointInsertAfter = this.getMidPoint(point, pointNext);
        points.splice(points.length, 0, pointInsertBefore);
        points.splice(1, 0, pointInsertAfter);
      }
    } else {
      pointPrevious = points[ix - 1];
      pointNext = points[ix + 1];
      if (this.pointIsInLine(pointPrevious, point, pointNext)) {
        if (points.length > MIN_POLYGON_POINT_COUNT) {
          points.splice(ix, 1);
        }
      } else {
        pointInsertBefore = this.getMidPoint(pointPrevious, point);
        pointInsertAfter = this.getMidPoint(point, pointNext);
        points.splice(ix + 1, 0, pointInsertAfter);
        points.splice(ix, 0, pointInsertBefore);
      }
    }
    this.setState({
      points,
    });
  }

  export() {
    const { dragPosition, points } = this.state;
    // need to combine x,y coordinates of polygon with position of polygon
    const pointsAdjusted = points.map(point => {
      const x = dragPosition.x + point.x;
      const y = dragPosition.y + point.y;
      const normalized = { x, y };
      return normalized;
    });
    return pointsAdjusted;
  }

  render() {
    const {
      isProhibitedDirectionRule,
      directionAngle,
      id,
      type,
      isSelected,
    } = this.props;
    const { points: statePoints, dragPosition } = this.state;
    const className = classNames[type];
    const styleSelected = isSelected ? 'selected' : '';
    const controls = this.getDragControls(statePoints);
    const points = statePoints
      .reduce((sum, next) => {
        sum += `${next.x},${next.y} `;
        return sum;
      }, '')
      .trim();
    const arrowId = isProhibitedDirectionRule ? `arrows${directionAngle}` : id;
    return (
      <Draggable
        cancel=".controlPoint"
        disabled={false}
        position={{
          x: dragPosition.x,
          y: dragPosition.y,
        }}
        onDrag={this.onControlledDragStart}
        onStop={this.onControlledDragStop}
      >
        {isProhibitedDirectionRule ? (
          <g
            ref="controls"
            key={id}
            className={`${className} ${styleSelected}`}
          >
            <defs>
              <pattern
                id={arrowId}
                viewBox="0,0,24,24"
                width="8%"
                height="28%"
                className={directionAngleArrowStyle}
                style={{
                  transform: `rotate(${directionAngle || 90}deg)`,
                }}
              >
                <path d="M12 0l8 9h-6v15h-4v-15h-6z" fill="yellowgreen" />
              </pattern>
            </defs>
            <polygon key="polygon" points={points} onMouseDown={this.select} />
            <polygon
              key="arrow-polygon"
              points={points}
              style={{ fill: `url(#${arrowId})`, fillOpacity: 1 }}
            />
            {controls}
          </g>
        ) : (
            <g
              ref="controls"
              key={id}
              className={`${className} ${styleSelected}`}
            >
              <polygon key="polygon" points={points} onMouseDown={this.select} />
              {controls}
            </g>
          )}
      </Draggable>
    );
  }
}

Polygon.propTypes = {
  type: PropTypes.oneOf(Object.values(canvasObjectTypes)).isRequired,
  canvasDocumentCoords: PropTypes.objectOf(PropTypes.number).isRequired,
  isProhibitedDirectionRule: PropTypes.bool,
  directionAngle: PropTypes.number,
  isSelected: PropTypes.bool,
  onObjectUpdated: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  points: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)).isRequired,
  parentWidth: PropTypes.number.isRequired,
  parentHeight: PropTypes.number.isRequired,
  onObjectSelected: PropTypes.func.isRequired,
  initialHeight: PropTypes.number.isRequired,
  initialWidth: PropTypes.number.isRequired,
  left: PropTypes.number,
  top: PropTypes.number,
};

Polygon.defaultProps = {
  isProhibitedDirectionRule: false,
  directionAngle: 0,
  isSelected: false,
  left: null,
  top: null,
};

export default Polygon;
