import { getFullPath } from 'util/getPathName';
import { PATH_ALARMS, PATH_CAMERAS, PATH_SERVERS } from '../constants/urlPaths';

export const ID_TYPE = {
  CAMERA: 'CAMERA',
  NOTIFICATION: 'NOTIFICATION',
  SERVER: 'SERVER',
};

const TYPE_TO_PATH = {
  [ID_TYPE.CAMERA]: PATH_CAMERAS,
  [ID_TYPE.NOTIFICATION]: `${PATH_ALARMS}/\\w+?`,
  [ID_TYPE.SERVER]: PATH_SERVERS,
};

const idPattern = '[a-f0-9-]{36}';

const getIdFromPath = type => {
  let id = null;
  const path = getFullPath(window.location.pathname);
  const pathTarget = TYPE_TO_PATH[type];

  const foundId = path.match(new RegExp(`${pathTarget}/(${idPattern})`));
  if (foundId) {
    [, id] = foundId;
  }

  return id;
};

export default getIdFromPath;
