exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css"), undefined);

// module
exports.push([module.id, ".coloredDot___24tXz {\r\n  height: 6px;\r\n  width: 6px;\r\n  border-radius: 50%;\r\n  margin-bottom: 1px;\r\n  margin-right: 4px;\r\n}\r\n\r\n.onlineIcon___1ziik {\r\n  background-color: " + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["connectedGreenColor"] + ";\r\n}\r\n\r\n.offlineIcon___oG3ip {\r\n  background-color: " + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["errorRed"] + ";\r\n}\r\n\r\n.disabledIcon___37XNr {\r\n  background-color: " + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["primaryDisabledGrey"] + ";\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/sharedStyles/connectionStatus.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;CACnB;;AAED;EACE,8CAAsC;CACvC;;AAED;EACE,8CAA2B;CAC5B;;AAED;EACE,8CAAsC;CACvC","file":"connectionStatus.css","sourcesContent":["@value (connectedGreenColor, errorRed, primaryDisabledGrey) from './color_palette.css';\r\n\r\n.coloredDot {\r\n  height: 6px;\r\n  width: 6px;\r\n  border-radius: 50%;\r\n  margin-bottom: 1px;\r\n  margin-right: 4px;\r\n}\r\n\r\n.onlineIcon {\r\n  background-color: connectedGreenColor;\r\n}\r\n\r\n.offlineIcon {\r\n  background-color: errorRed;\r\n}\r\n\r\n.disabledIcon {\r\n  background-color: primaryDisabledGrey;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"connectedGreenColor": "" + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["connectedGreenColor"] + "",
	"errorRed": "" + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["errorRed"] + "",
	"primaryDisabledGrey": "" + require("-!../../node_modules/css-loader/index.js??ref--6-1!./color_palette.css").locals["primaryDisabledGrey"] + "",
	"coloredDot": "coloredDot___24tXz",
	"onlineIcon": "onlineIcon___1ziik",
	"offlineIcon": "offlineIcon___oG3ip",
	"disabledIcon": "disabledIcon___37XNr"
};