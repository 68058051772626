import PropTypes from 'prop-types';
import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconNavViews = ({ className, fill, height, width }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <g>
        <g>
          <g>
            <polygon points="24 6.19 18 9 18 16 24 18.97 24 6.19" />
          </g>
          <g>
            <path d="M3,7V17a1.8,1.8,0,0,0,2,2H17V5H5A1.8,1.8,0,0,0,3,7Z" />
          </g>
          <g>
            <path d="M1,6v5H2V6Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconNavViews.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

IconNavViews.defaultProps = {
  className: null,
  fill: '#000',
  height: '27px',
  width: '38px',
};

export default IconNavViews;
