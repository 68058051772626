import React from 'react';
import Modal from 'react-modal';
import * as ModalActions from 'actions/modal';
import { bindActionCreators } from 'redux';
import * as types from 'constants/ModalTypes';
import { connect } from 'react-redux';
import {
  modalContent,
  modalContentContainer,
  modalOverlay,
} from 'sharedStyles/styles.css';
import ConfirmationModal from './ConfirmationModal';
import AddCameraGroupSuccessModal from './AddCameraGroupSuccessModal';
import ErrorModal from './errorModal';
import SuccessModal from './successModal';
import registrationSuccessModal from './registrationSuccessModal';
import unprovisionedRegistrationSuccessModal from './unprovisionedRegistrationSuccessModal';
import LogDownloadModal from './LogDownloadModal';

import infoModal from './infoModal';
import DeleteSubscriberModal from './DeleteSubscriberModal';
import PromptModal from './PromptModal';

const MODAL_COMPONENTS = {
  [types.ADD_CAMERA_GROUP_SUCCESS]: AddCameraGroupSuccessModal,
  [types.REGISTRATION_SUCCESS]: registrationSuccessModal,
  [types.UNPROVISIONED_REGISTRATION_SUCCESS]: unprovisionedRegistrationSuccessModal,
  [types.SHOW_CONFIRM]: ConfirmationModal,
  [types.SHOW_SUCCESS]: SuccessModal,
  [types.SHOW_ERROR]: ErrorModal,
  [types.DOWNLOAD_LOGS]: LogDownloadModal,
  [types.INFO_MODAL]: infoModal,
  [types.NEW_SITE_ACTIVATION_CODE]: PromptModal,
  [types.DELETE_SUBSCRIBER_MODAL]: DeleteSubscriberModal,
};

const RootModal = ({ actions, isOpen, modalProps, modalType }) => {
  if (!modalType || !MODAL_COMPONENTS[modalType]) {
    return <span />;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return (
    <Modal
      className={modalContentContainer}
      closeTimeoutMS={150}
      contentLabel="modal"
      isOpen={isOpen}
      onRequestClose={actions.hideModal}
      overlayClassName={modalOverlay}
      shouldCloseOnOverlayClick={false}
    >
      <SpecificModal
        className={modalContent}
        hideModal={actions.hideModal}
        {...modalProps}
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  return { ...state.modal };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...ModalActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RootModal);
