import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { Translate } from 'react-localize-redux';
import { sectionHeader } from 'components/VerticalForm/styles.css';

import {
  MonitoringField,
  ServicePackageField,
  SubscriptionTermField,
  TrialTermField,
} from './SubscriptionFields';

// Styles
import { formColumn, subscriptionFieldWrap } from '../styles.css';

// Constants
import * as LocationFormConsts from '../constants';
import { SERVICE_FAMILIES } from 'app/constants/app';

class SubscriptionFieldSet extends Component {
  getServicePackage(servicePackageSkuId) {
    const { servicePackages } = this.props;
    return servicePackages.find(sp => sp.SkuId === servicePackageSkuId);
  }

  getServicePackagesByFamily(servicePackageFamily) {
    const familyType = servicePackageFamily || SERVICE_FAMILIES.AccCloudServices;
    const { servicePackages } = this.props;
    return servicePackages.filter(
      type => String(type.ServiceFamily) === String(familyType),
    );
  }

  getAvailableSubscriptionTermOptions(servicePackageSkuId) {
    const { servicePackageFamily, subscriptionTermOptions } = this.props;
    const familyType =
      servicePackageFamily || SERVICE_FAMILIES.AccCloudServices;
    const servicePackage = this.getServicePackage(servicePackageSkuId);
    let fixedTermLength = 0;
    let termLengths = [];
    if (servicePackage) {
      ({
        FixedTermLength: fixedTermLength,
        TermLengths: termLengths,
      } = servicePackage);
    }

    const termLengthValues = termLengths.map(
      termLength => termLength.NumberOfMonths,
    );
    return subscriptionTermOptions.filter(
      opt =>
        (fixedTermLength
          ? String(opt.value) === String(fixedTermLength)
          : termLengthValues.includes(opt.value)) &&
        String(opt.family) === String(familyType),
    );
  }

  handleFamilyChange = evt => {
    const { change } = this.props;
    const filteredServicePackages = this.getServicePackagesByFamily(
      evt.target.value,
    );
    change(
      'ServicePackageSkuId',
      filteredServicePackages.length && filteredServicePackages[0].SkuId,
    );
  };

  handlePackageChange = e => {
    // Not all packages support the same term lengths.
    // If the user selects a different package and the currently selected term length
    // is not supported by the new package, set the term length to a valid value.

    const { change } = this.props;

    const demoSkuId = LocationFormConsts.demoSubscriptionSkuId;

    if (e.target.value === demoSkuId) {
      change('SubscriptionTermLength', '12');
    }

    const availableSubscriptionTermOptions = this.getAvailableSubscriptionTermOptions(
      e.target.value,
    );

    if (availableSubscriptionTermOptions.length) {
      change(
        'SubscriptionTermLength',
        String(availableSubscriptionTermOptions[0].value),
      );
    }
  };

  isSiteExtendable = () => {
    const {
      canEditSubscription,
      initialValues,
      servicePackageSkuId,
    } = this.props;

    const servicePackage = this.getServicePackage(servicePackageSkuId);

    return (
      canEditSubscription &&
      initialValues &&
      initialValues.PackageSubscription &&
      LocationFormConsts.extendableTrialSiteStates.includes(
        initialValues.PackageSubscription.State,
      ) &&
      servicePackage &&
      servicePackage.IsTrial
    );
  };

  render() {
    const {
      canEditSubscription,
      canEnableHealthMonitoring,
      change,
      initialValues,
      readOnly,
      selfService,
      servicePackageFamilies,
      servicePackageFamily,
      servicePackageSkuId,
      servicePackages,
      showHealthMonitoring,
      timezone,
    } = this.props;

    const servicePackage = servicePackageSkuId
      ? this.getServicePackage(servicePackageSkuId)
      : servicePackages.find(
          p => p.ServiceFamily === SERVICE_FAMILIES.AccCloudServices,
        );

    const selectedSkuId = servicePackageSkuId || servicePackage.SkuId;

    return (
      <fieldset className={formColumn}>
        <div className={sectionHeader}>
          <Translate id="LOCATIONS.LOCATION_FORM.SUBSCRIPTION_TYPE" />
        </div>
        <div className={subscriptionFieldWrap}>
          <ServicePackageField
            disabled={!canEditSubscription}
            handleFamilyChange={this.handleFamilyChange}
            handlePackageChange={this.handlePackageChange}
            initialValues={initialValues}
            readOnly={readOnly}
            selfService={selfService}
            servicePackage={servicePackage}
            servicePackageFamilies={servicePackageFamilies}
            servicePackages={this.getServicePackagesByFamily(
              servicePackageFamily,
            )}
          />
          <SubscriptionTermField
            disabled={
              !canEditSubscription ||
              (servicePackage && servicePackage.FixedTermLength)
            }
            initialValues={initialValues}
            isNonRenewable={LocationFormConsts.nonRenewableSubscriptionSkuIds.includes(
              selectedSkuId,
            )}
            readOnly={readOnly}
            subscriptionTermOptions={this.getAvailableSubscriptionTermOptions(
              selectedSkuId,
            )}
          />
          <TrialTermField
            initialValue={
              initialValues.PackageSubscription &&
              initialValues.PackageSubscription.TermEndDate
            }
            isExtendable={this.isSiteExtendable()}
            onChange={change}
            timezone={timezone}
          />
        </div>
      </fieldset>
    );
  }
}

SubscriptionFieldSet.defaultProps = {
  initialValues: [],
  readOnly: false,
  servicePackageFamilies: [],
  servicePackageFamily: undefined,
  servicePackageSkuId: '',
  servicePackages: [],
  timezone: undefined,
};

SubscriptionFieldSet.propTypes = {
  canEditSubscription: PropTypes.bool.isRequired,
  canEnableHealthMonitoring: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  readOnly: PropTypes.bool,
  selfService: PropTypes.number.isRequired,
  servicePackageFamilies: PropTypes.arrayOf(PropTypes.shape({})),
  servicePackageFamily: PropTypes.number,
  servicePackageSkuId: PropTypes.string,
  servicePackages: PropTypes.arrayOf(PropTypes.shape({})),
  showHealthMonitoring: PropTypes.bool.isRequired,
  subscriptionTermOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timezone: PropTypes.string,
};

export default SubscriptionFieldSet;
