// Libs
import React from 'react';
import { Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone-all';

// Utils
import { sortByDate } from 'util/generateSort';
import FilterableContainer from 'util/FilterableContainer';

// Components
import { DeleteBookmarksContainer } from 'containers';
import { DateSelect, InputFilter } from 'components';
import { EmptyPlaceholder, Icon, ListView, NoStyleButton } from 'lib';

// Actions
import * as FilterActions from 'actions/filters';
import * as BookmarksActions from 'actions/bookmarks';
import { hideModal, showModal } from 'actions/modal';

// Constants
import {
  DATABASE_DATETIME_EXTRACT_FORMAT,
  DATABASE_DATETIME_FORMAT,
} from 'constants/app';
import { IC_EDIT, IC_TRASH } from 'constants/iconNames';
import * as modalTypes from 'constants/ModalTypes';

// Styles
import { truncatedCellText } from 'sharedStyles/tables.css';
import {
  convertTimeTo,
  getFormatForActiveLocale,
  getPreferredLongDateTimeFormat,
  TIME_TYPES,
} from 'util/convertTimeTo';
import {
  bookmarkName,
  bookmarksTable,
  dateFilterWrapper,
  deleteButton,
  filterGroup,
  filterLabelWrapper,
  filterWrapper,
  linksWrapper,
  linkText,
  protectedStatus,
  protectedStatusYes,
  queryPicker,
  searchBox,
  siteSearchFilter,
} from './styles.css';

// Utils
import NavigationWrapper from '../NavigationWrapper/NavigationWrapper';

import * as consts from './constants';

class SiteBookmarksContainer extends FilterableContainer {
  componentDidMount() {
    // Fetch the bookmarks for a site if they are not already fetched.
    const { actions, clusterIds, clustersForSite } = this.props;
    let { queryOptions } = this.props;
    if (clusterIds.length > 0) {
      clusterIds.forEach(clusterId => {
        actions.getBookmarks(clusterId, queryOptions);
      });
    }
  }

  refreshBookmarks = newQueryOptions => {
    const { actions, clusterIds } = this.props;
    let { queryOptions } = this.props;
    queryOptions = { ...queryOptions, ...newQueryOptions };
    if (clusterIds.length > 0) {
      clusterIds.forEach(clusterId => {
        actions.getBookmarks(clusterId, queryOptions);
      });
    }
  };

  handleChangeFrom = time => {
    const { queryOptions } = this.props;
    const formattedTime = time.format(DATABASE_DATETIME_EXTRACT_FORMAT);
    if (time.isAfter(moment(queryOptions.to))) {
      const toTime = time
        .hours(23)
        .minutes(59)
        .seconds(59);
      this.refreshBookmarks({
        from: formattedTime,
        to: toTime.format(DATABASE_DATETIME_EXTRACT_FORMAT),
      });
    } else {
      this.refreshBookmarks({ from: formattedTime });
    }
  };

  handleChangeTo = time => {
    this.refreshBookmarks({
      to: time.format(DATABASE_DATETIME_EXTRACT_FORMAT),
    });
  };

  editBookmark(id, bookmarksPerCluster) {
    const { actions, siteId } = this.props;
    const clusterId = this.getClusterIdForBookmark(id, bookmarksPerCluster);
    actions.showModal(modalTypes.EDIT_BOOKMARK, {
      bookmarkId: id,
      clusterId,
      siteId,
    });
  }

  cancelEditBookmark() {
    const { actions } = this.props;
    actions.hideModal();
  }

  getClusterIdForBookmark = (bId, bookmarksPerCluster) => {
    let clusterId;
    if (bookmarksPerCluster !== undefined) {
      Object.entries(bookmarksPerCluster).forEach(([cId, bookmarksList]) => {
        if (bookmarksList.findIndex(item => item.id === bId) >= 0) {
          clusterId = cId;
        }
      });
    }
    return clusterId;
  };

  render() {
    const {
      bookmarksPerCluster,
      clusterIds,
      filteredItems,
      handleFilter,
      isFetchingBookmarks,
      profile,
      queryOptions,
      siteId,
    } = this.props;
    const items = filteredItems;
    let CUSTOM_CELLS;

    if (clusterIds && clusterIds.length > 0) {
      CUSTOM_CELLS = {
        Actions: rowData => {
          return (
            <Translate>
              {({ translate }) => (
                <div className={linksWrapper}>
                  <NoStyleButton
                    id={consts.idEditBookmark}
                    onClick={() => {
                      this.editBookmark(rowData.id, bookmarksPerCluster);
                    }}
                  >
                    <Icon
                      className=""
                      fontSize="22px"
                      height="22px"
                      id={IC_EDIT}
                      title="BOOKMARKS.EDIT_BOOKMARK_LINK"
                      width="22px"
                    />
                  </NoStyleButton>
                  <div className={deleteButton}>
                    <DeleteBookmarksContainer
                      bookmarkIds={[rowData.id]}
                      bookmarkNames={[rowData.name]}
                      bookmarksPerCluster={bookmarksPerCluster}
                      /** TODO: DHP/MVAAS-17027 Investigate how to resolve this usage here */

                      getBookmarkCluster={this.getClusterIdForBookmark}
                    >
                      <NoStyleButton id={consts.idDeleteBookmark}>
                        <Icon
                          id={IC_TRASH}
                          size={22}
                          title="BOOKMARKS.DELETE_BOOKMARK_TOOLTIP"
                        />
                      </NoStyleButton>
                    </DeleteBookmarksContainer>
                  </div>
                </div>
              )}
            </Translate>
          );
        },

        creationTime: rowData => {
          return convertTimeTo(
            rowData.creationTime,
            TIME_TYPES.LOCAL,
            profile.TimeZone,
            getPreferredLongDateTimeFormat(
              profile.LocalizationPreference.PreferredLongDateFormat,
              profile.LocalizationPreference.PreferredTimeFormat,
            ) || getFormatForActiveLocale(),
          );
        },
        description: rowData => {
          return (
            <div className={truncatedCellText} title={rowData.description}>
              {rowData.description}
            </div>
          );
        },
        isProtected: rowData => {
          return (
            <span
              className={
                rowData.isProtected ? protectedStatusYes : protectedStatus
              }
            >
              {rowData.isProtected ? (
                <Translate id="COMMON.YES" />
              ) : (
                <Translate id="COMMON.NO" />
              )}
            </span>
          );
        },
        name: rowData => {
          return (
            <Link
              className={`${bookmarkName} ${linkText}`}
              to={`/bookmarks/${siteId}/${rowData.clusterId}/${rowData.id}`}
            >
              {rowData.name}
            </Link>
          );
        },

        startTime: rowData => {
          return convertTimeTo(
            rowData.startTime,
            TIME_TYPES.LOCAL,
            profile.TimeZone,
            getPreferredLongDateTimeFormat(
              profile.LocalizationPreference.PreferredLongDateFormat,
              profile.LocalizationPreference.PreferredTimeFormat,
            ) || getFormatForActiveLocale(),
          );
        },
      };
    }

    return (
      <>
        <div className={searchBox}>
          <div className={filterGroup}>
            <div className={filterWrapper}>
              <InputFilter
                className={siteSearchFilter}
                field={consts.textInputFields}
                id={consts.idBookmarksSearchInput}
                onChange={handleFilter}
                overrideValue
                placeholder={consts.BOOKMARK_SEARCH_PLACEHOLDER}
                value={this.getFilterValue('name')}
              />
            </div>
            <div className={`${filterWrapper} ${dateFilterWrapper}`}>
              <DateSelect
                className={queryPicker}
                initialTime={moment()
                  .hours(0)
                  .minutes(0)
                  .seconds(0)}
                initialValue={
                  queryOptions.from
                    ? moment(queryOptions.from)
                    : moment().subtract(14, 'days')
                }
                onChange={this.handleChangeFrom}
                popperPlacement="bottom-end"
                showCalendarIcon
                timezone={profile.TimeZone}
              />
            </div>
            <div className={`${filterWrapper} ${filterLabelWrapper}`}>
              <Translate id="BOOKMARKS.SEARCH_BOOKMARKS.FILTER_TO" />
            </div>
            <div className={`${filterWrapper} ${dateFilterWrapper}`}>
              <DateSelect
                className={queryPicker}
                filterDate={date => {
                  return moment(queryOptions.from).isBefore(
                    moment(date).format(),
                  );
                }}
                initialTime={moment()
                  .hours(23)
                  .minutes(59)
                  .seconds(59)}
                initialValue={
                  queryOptions.to ? moment(queryOptions.to) : moment()
                }
                onChange={this.handleChangeTo}
                popperPlacement="bottom-end"
                showCalendarIcon
                timezone={profile.TimeZone}
              />
            </div>
          </div>
        </div>
        <EmptyPlaceholder
          isFetching={isFetchingBookmarks}
          items={items}
          string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
        >
          <ListView
            bodyHeight={250}
            cellAlignments={consts.CELL_ALIGNMENTS}
            cellWidths={consts.CELL_WIDTHS}
            className={bookmarksTable}
            customCells={CUSTOM_CELLS}
            data={items}
            fieldOrder={consts.FIELD_ORDER}
            headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
            hideFilter
          />
        </EmptyPlaceholder>
      </>
    );
  }
}

SiteBookmarksContainer.defaultProps = {
  bookmarks: {},
  subscriptionState: false,
};

function mapStateToProps(state, ownProps) {
  let bookmarksData = [];
  let bookmarksPerCluster;
  let bookmarksDataPerCluster = [];
  const clusterIds = [];
  let isFetchingBookmarks;
  let queryOptions = {};

  state.clusters.clusters.forEach(cluster => {
    if (cluster.LocationId === ownProps.siteId) {
      clusterIds.push(cluster.Id);
    }
  });

  if (clusterIds.length > 0) {
    clusterIds.forEach(clusterId => {
      isFetchingBookmarks = state.isFetching.getBookmarks[clusterId];

      if (state.bookmarks.bookmarks[clusterId]) {
        bookmarksDataPerCluster = state.bookmarks.bookmarks[
          clusterId
        ].map(bk => ({ ...bk, clusterId }));
        queryOptions = state.bookmarks.bookmarksQueryOptions[clusterId] || {};
        bookmarksData = [...bookmarksData, ...bookmarksDataPerCluster]; // Appending data for a site with one or more clusters
      }
    });
    // Sorting the bookmarks data by start time
    bookmarksData = bookmarksData.sort(
      sortByDate(
        item => item.startTime && item.startTime.toLowerCase(),
        DATABASE_DATETIME_FORMAT,
      ),
    );

    bookmarksPerCluster = state.bookmarks.bookmarks;
  }

  return {
    bookmarksData,
    bookmarksPerCluster,
    clusterIds,
    filterGroup: ownProps.siteId,
    filterStatus: state.filters.filterStatus,
    isFetchingBookmarks: isFetchingBookmarks || false,
    items: bookmarksData,
    profile: state.user.profile,
    queryOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...BookmarksActions, ...FilterActions, hideModal, showModal },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  SiteBookmarksContainer,
  mapStateToProps,
  mapDispatchToProps,
  null,
  { textInputFields: consts.textInputFields },
);
