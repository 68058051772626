// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { SVG_XMLNS } from '../constants/app';

const IconInfo = ({ width, height, title }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns={SVG_XMLNS}
      title={title}
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <circle fill="#0052CC" cx="8" cy="8" r="8" />
        <g transform="translate(6 3)" fill="#FFF">
          <rect x="1" y="5" width="2" height="6" rx="1" />
          <rect x=".5" width="3" height="3" rx="1.5" />
        </g>
      </g>
    </svg>
  );
};

IconInfo.defaultProps = {
  width: '14px',
  height: '14px',
  title: '',
};

IconInfo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
};

export default IconInfo;
