// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

// Constants
import { SVG_XMLNS } from '../constants/app';

const highlightColor = '#FFFFFF';

const IconAlarmFalse = props => {
  const { title, translate, width, height, highlight } = props;

  return (
    <div title={translate(title)} style={{ display: 'flex' }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 33 33"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="01-notifications-list"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Alarms-List" transform="translate(-124.000000, -475.000000)">
            <g id="false" transform="translate(124.000000, 475.000000)">
              <g id="Page-1">
                <g id="Group-3" transform="translate(0.000000, 0.259459)">
                  <path
                    d="M16.4781222,4.10728899 C9.64284444,4.10728899 4.10159444,9.59739908 4.10159444,16.3702706 C4.10159444,23.1428394 9.64284444,28.6332523 16.4781222,28.6332523 C23.3137056,28.6332523 28.85465,23.1428394 28.85465,16.3702706 C28.85465,9.59739908 23.3137056,4.10728899 16.4781222,4.10728899 M32.9564278,16.3702706 C32.9564278,25.387445 25.5787889,32.6970963 16.4781222,32.6970963 C7.37745556,32.6970963 0.000122222222,25.387445 0.000122222222,16.3702706 C0.000122222222,7.35309633 7.37745556,0.0431422018 16.4781222,0.0431422018 C25.5787889,0.0431422018 32.9564278,7.35309633 32.9564278,16.3702706"
                    id="Fill-1"
                    fill={highlight ? highlightColor : '#D0D2D3'}
                  />
                </g>
                <path
                  d="M17.9400833,22.1893817 L19.8626389,20.4158587 L9.25466667,20.4158587 L8.05077778,22.8091156 L13.5290833,22.8091156 C13.8086667,24.1742257 15.0220278,25.201767 16.478,25.201767 C16.478,25.201767 18.7681389,25.3549596 19.3816944,23.6752899 L17.9400833,22.1893817 Z M20.3973611,15.6423633 L22.49775,17.7361982 L22.49775,13.2376018 C22.49775,9.93427156 19.8021389,7.25521651 16.478,7.25521651 C13.1541667,7.25521651 10.4585556,9.93427156 10.4585556,13.2376018 L10.4585556,18.0232073 L9.92566667,19.0825376 L19.0730833,19.0985835 L18.0100556,18.0453083 L20.3973611,15.6423633 Z"
                  id="Fill-4"
                  fill={highlight ? highlightColor : '#70B1E0'}
                />
                <polygon
                  id="Fill-6"
                  fill={highlight ? highlightColor : '#D54039'}
                  points="20.4258694 23.632178 25.8351194 18.2722514 24.4839528 16.9334807 19.0747028 22.2931046"
                />
                <polygon
                  id="Fill-8"
                  fill={highlight ? highlightColor : '#D54039'}
                  points="25.9035028 22.2017945 20.3576694 16.7068404 19.0061972 18.0453083 24.5523361 23.5408679"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconAlarmFalse.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  highlight: PropTypes.bool,
  translate: PropTypes.func,
};

IconAlarmFalse.defaultProps = {
  title: 'ALARMS.STATUS.FALSE_ALARM_LABEL',
  width: '25px',
  height: '25px',
  highlight: false,
  translate: () => {},
};

export default withLocalize(IconAlarmFalse);
