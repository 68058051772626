export const IC_NOTIFICATION = 'ic_notification';
export const IC_NOTIFICATION_OFF = 'ic_notification_off';
export const IC_CALENDAR = 'ic_calendar';
export const IC_COPY = 'ic_copy';
export const IC_MENU = 'ic_menu';
export const IC_ALL = 'ic_all';
export const IC_ADD = 'ic_add';
export const IC_VIDEO = 'ic_video';
export const IC_ADD_BOOKMARK = 'ic_capture_add_mark';
export const IC_SAVE = 'ic_save';
export const IC_FAVORITE_ON = 'ic_favorite_on';
export const IC_FAVORITE_OFF = 'ic_favorite_off';
export const IC_VIEW_EXPAND = 'ic_view_expand';
export const IC_VIEW_COLLAPSE = 'ic_view_collapse';
export const IC_CAMERA_MOBILE = 'ic_camera_mobile';
export const IC_SERVER = 'ic_server';
export const IC_INFO = 'ic_information';
export const IC_CHEVRON_UP = 'ic_chevron_up';
export const IC_CHEVRON_DOWN = 'ic_chevron_down';
export const IC_AGENCY_BUILDING = 'ic_agency_building';
export const IC_EDIT = 'ic_edit';
export const IC_MORE_HORIZONTAL = 'ic_more_horizontal';
export const IC_TRASH = 'ic_trash';
export const IC_REFRESH = 'ic_refresh';
export const IC_FILTER = 'ic_filter_sm';
export const IC_ARROW = 'ic_panel_sort';
export const IC_REMOVE = 'ic_remove';
export const IC_CLOUD_SYNC = 'ic_cloud_sync';
export const IC_FAVORITE_DEFAULT_ON = 'ic_favorite_default_on';
export const IC_FAVORITE_DEFAULT_OFF = 'ic_favorite_default_off';
export const IC_SETTINGS = 'ic_settings';
export const IC_SITE_SUSPEND = 'ic_site_suspend';
export const IC_SITE_UNSUSPEND = 'ic_site_unsuspend';
export const IC_FILTER_OFF = 'ic_filter_sm_off';
export const IC_ARROW_DOWN = 'ic_arrow_down';
export const IC_ARROW_UP = 'ic_arrow_up';
export const IC_DATA_GRAPH = 'ic_data_graph';
