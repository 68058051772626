import React from 'react';
import { PropTypes } from 'prop-types';
import Icon from './Icon';
import { layoutIconFillColor } from './constants';
import { iconContainer } from './styles.css';

const ViewLayoutValue = ({ value, option, children }) => {
  return (
    <div className="Select-value">
      <span className="Select-value-label">
        <div className={iconContainer}>
          <Icon params={value || option} fillColor={layoutIconFillColor} />
        </div>
        {children}
      </span>
    </div>
  );
};

ViewLayoutValue.propTypes = {
  children: PropTypes.node,
  option: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

ViewLayoutValue.defaultProps = {
  children: null,
};

export default ViewLayoutValue;
