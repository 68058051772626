import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { PropTypes } from 'prop-types';

// Utils
import { accountTabAccess as tabAccess } from 'util/permissions';

// Components
import NavTabs from 'components/NavTabs/NavTabs';

// Constants
import { PATH_ALARMS } from 'constants/urlPaths';
import { tabs } from './constants';

const NotificationsNavMenu = props => {
  const { profile, translate } = props;
  const tabAccessPass = tabAccess(profile);
  const tabKeys = Object.keys(tabs);

  return (
    <NavTabs
      tabs={tabKeys
        .filter(key => tabAccessPass[key])
        .map(key => ({
          id: tabs[key].id,
          key,
          label: translate(`SECONDARY_NAV.TABS.${key.toUpperCase()}.LABEL`),
          path: `${PATH_ALARMS}${tabs[key].path}`,
        }))}
    />
  );
};

NotificationsNavMenu.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(NotificationsNavMenu);
