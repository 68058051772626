import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';
import { PlayerSyncContextProvider } from '@avo-svtr/savitar';
import { FeedContainer } from 'containers';
import { layoutItemCount } from 'util/layoutItemCount';
import {
  tile1,
  tile2,
  tile3,
  tile4,
  tile6,
  tile9,
  tileContainerBorder,
} from './styles.css';

const generateRowColCount = count => {
  const countObj = { countColums: 0, countRows: 0 };
  switch (count) {
    case 1:
      countObj.countColumns = 1;
      countObj.countRows = 1;
    case 2:
      countObj.countColumns = 2;
      countObj.countRows = 1;
      break;
    case 3:
      countObj.countColumns = 3;
      countObj.countRows = 1;
      break;
    case 4:
      countObj.countColumns = 2;
      countObj.countRows = 2;
      break;
    case 6:
      countObj.countColumns = 3;
      countObj.countRows = 2;
      break;
    case 9:
      countObj.countColumns = 3;
      countObj.countRows = 3;
      break;
    default:
      break;
  }
  return countObj;
};

class ViewContainer extends Component {
  componentDidMount() {
    document.addEventListener('fullscreenchange', this.handleFullScreenChange);
  }

  shouldComponentUpdate(nextProps) {
    // This block of code handles an edge case relating to maximising screen
    // and resizing the ViewContainer component(s).  We use react-dimensions to
    // do this however we discovered a failure in that package that was never
    // updated and the package is now deprecated.
    // DO NOT DELETE this code until we delete react-dimensions, which is likely
    // only going to happen when EVO is removed entirely.

    // force react-dimensions to recalculate on maximise, which it wasn't doing
    // 100% of the time before.
    if (nextProps.resizeToggle && nextProps.containerWidth) {
      const { updateDimensions } = this.props;
      requestAnimationFrame(() => updateDimensions(), 0);
    }
    return true;
  }

  componentWillUnmount() {
    document.removeEventListener(
      'fullscreenchange',
      this.handleFullScreenChange,
    );
  }

  getClassname = count => {
    switch (count) {
      case 1:
        return tile1;
      case 2:
        return tile2;
      case 3:
        return tile3;
      case 4:
        return tile4;
      case 6:
        return tile6;
      case 9:
        return tile9;
      default:
        return tile9;
    }
  };

  handleFullScreenChange = () => {
    const { syncVideo, toggleSyncVideo } = this.props;
    if (!document.fullscreenElement) return;

    if (!syncVideo) return;

    if (document.fullscreenElement.className.includes('svtrPlayer')) {
      /**
       * only toggles off syncVideo when one of the players are changed to fullscreen
       */
      toggleSyncVideo();
    }
  };

  formatStream = (stream, index) => {
    const { cameras } = this.props;
    // cameras have RemoteId, view Elements have CameraRemoteId, we need RemoteId
    if (!stream.RemoteId && stream.CameraRemoteId) {
      stream.RemoteId = stream.CameraRemoteId;
    }
    const viewCamera = cameras.find(camera => camera.Id === stream.CameraId);
    if (viewCamera) {
      stream.cameraName = viewCamera.Name;
      stream.Id = viewCamera.Id;
      stream.LocationId = viewCamera.LocationId;
      stream.isDisconnected = viewCamera.IsDisconnected;
      stream.ConnectionState = viewCamera.ConnectionState;
      stream.GroupId = viewCamera.GroupId;
      stream.GatewayCapabilities = viewCamera.GatewayCapabilities || [];
      stream.recordedData = viewCamera.RecordedData;
      stream.active = viewCamera.Active;
    }
    if (viewCamera && viewCamera.DefaultHeight && viewCamera.DefaultWidth) {
      stream.defaultWidth = viewCamera.DefaultWidth;
      stream.defaultHeight = viewCamera.DefaultHeight;
    }
    stream.key = `${stream.CameraId}_${index}`;
    return stream;
  };

  renderFeedContainer = (stream, index) => {
    let defaultHeight = 0;
    let defaultWidth = 0;
    let key = index;
    let cameraStream;
    if (stream) {
      // stream = false is a placeholder for an empty feed container
      cameraStream = stream;
      defaultHeight = stream.defaultHeight;
      defaultWidth = stream.defaultWidth;
      key = stream.key;
    }
    const {
      cameras,
      containerHeight,
      containerWidth,
      enabledPtzFeed,
      handlePtzEnabledInFeed,
      view,
    } = this.props;
    const enumLayout = view.Layout;
    const count = enumLayout !== null ? layoutItemCount(enumLayout) : 1;
    const { countColumns, countRows } = generateRowColCount(count);
    // exclude L and R border thickness, account for rounding errors
    const columnWidth = Math.floor(containerWidth / countColumns) - 2;
    const height = Math.floor(containerHeight / countRows);
    const className = this.getClassname(count);
    return (
      <div key={index} className={className}>
        <FeedContainer
          key={key}
          camera={cameraStream}
          cameras={cameras}
          containerColumns={countColumns}
          containerRows={countRows}
          defaultHeight={defaultHeight}
          defaultWidth={defaultWidth}
          enabledPtzFeed={enabledPtzFeed}
          handlePtzEnabledInFeed={handlePtzEnabledInFeed}
          height={height}
          id={index}
          videoKey={key}
          width={columnWidth}
        />
      </div>
    );
  };

  render() {
    const { view } = this.props;
    const enumLayout = view.Layout;
    const count = enumLayout ? layoutItemCount(enumLayout) : 1;
    let countArray = new Array(count);
    countArray = [...countArray];
    const streamArray = countArray.map((item, index) => {
      let stream = false;
      if (view && view.Elements && view.Elements.length > 0) {
        stream = view.Elements.find(
          element => element && element.Position === index,
        );
        if (stream) {
          return this.formatStream(stream, index);
        }
      }
      return stream;
    });
    return (
      <PlayerSyncContextProvider>
        <div key={view.Id} className={tileContainerBorder}>
          {streamArray.map((stream, index) =>
            this.renderFeedContainer(stream, index),
          )}
        </div>
      </PlayerSyncContextProvider>
    );
  }
}

ViewContainer.propTypes = {
  cameras: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  enabledPtzFeed: PropTypes.bool,
  handlePtzEnabledInFeed: PropTypes.func,
  resizeToggle: PropTypes.bool,
  syncVideo: PropTypes.bool,
  toggleSyncVideo: PropTypes.func,
  updateDimensions: PropTypes.func,
  view: PropTypes.shape({
    Elements: PropTypes.arrayOf(PropTypes.object),
    Id: PropTypes.string,
    Layout: PropTypes.any,
  }),
};

ViewContainer.defaultProps = {
  enabledPtzFeed: false,
  handlePtzEnabledInFeed: () => null,
  resizeToggle: false,
  syncVideo: false,
  toggleSyncVideo: () => null,
  updateDimensions: () => null,
  view: [{}],
};

const style = {
  border: 0,
  height: '100%',
  margin: 0,
  padding: 0,
  width: '100%',
};

export default Dimensions({ containerStyle: style })(ViewContainer);
