import React from 'react';
import PropTypes from 'prop-types';
import { Button, GroupLayout } from 'lib';
import { ModalContainer } from 'containers';
import { Translate } from 'react-localize-redux';
import {
  confirmCancelGroup,
  modalContentConfirm,
  modalText,
} from './styles.css';

const ConfirmationModal = ({
  disabled,
  handleCancel,
  hideModal: propHideModal,
  message,
  okButtonChildren,
  onOkClick,
  textConfirm,
  title,
}) => {
  const hideModal = handleCancel || propHideModal;
  return (
    <ModalContainer handleCancel={propHideModal} modalTitle={title || ''}>
      <div className={modalContentConfirm}>
        <div className={modalText}>{message}</div>
        <GroupLayout
          additionalClasses={confirmCancelGroup}
          verticalSpacing="medium"
        >
          <Button
            key="confirmCancelButton"
            buttonType="primary"
            id="confirmCancelButton"
            inputType="button"
            onClick={hideModal}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
          <Button
            key="confirmDeleteButton"
            buttonType="primary"
            disabled={disabled}
            id="confirmDeleteButton"
            inputType="button"
            onClick={onOkClick}
            text={<Translate id={textConfirm} />}
          >
            {okButtonChildren}
          </Button>
        </GroupLayout>
      </div>
    </ModalContainer>
  );
};

ConfirmationModal.defaultProps = {
  disabled: false,
  handleCancel() {},
  hideModal() {},
  message: '',
  okButtonChildren: null,
  onOkClick() {},
  textConfirm: 'BUTTONS.DELETE',
  title: 'BUTTONS.CANCEL',
};

ConfirmationModal.propTypes = {
  disabled: PropTypes.bool,
  handleCancel: PropTypes.func,
  hideModal: PropTypes.func,
  message: PropTypes.node,
  okButtonChildren: PropTypes.node,
  onOkClick: PropTypes.func,
  textConfirm: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ConfirmationModal;
