import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PATH_ACCOUNTS, PATH_SEGMENT_USERS } from 'constants/urlPaths';

import UserFormContainer from './AddUserForm/UserFormContainer';
import UserListNavContainer from './UserListNavContainer';

function UserDetailContainer({ history, isFetchingUsers, match, users }) {
  const { params } = match;
  const { userid } = params;

  useEffect(() => {
    // If there is no such user, redirect back to the users table
    if (isFetchingUsers === false) {
      const currentUser = users.find(u => userid === u.Id);
      if (!currentUser && userid !== 'new') {
        // redirect
        history.push(`${PATH_ACCOUNTS}${PATH_SEGMENT_USERS}`);
      }
    }
  }, [userid, users, isFetchingUsers, history]);

  return (
    <>
      <UserListNavContainer userId={userid} />
      <UserFormContainer history={history} userId={userid} />
    </>
  );
}

UserDetailContainer.defaultProps = {
  isFetchingUsers: null,
  match: { params: { userid: null } },
  users: [],
};

UserDetailContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isFetchingUsers: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userid: PropTypes.string,
    }),
  }),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.string,
    }),
  ),
};

const mapStateToProps = state => {
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    isFetchingUsers: state.isFetching.getUsers,
    users: state.user.users,
  };
};

export default withRouter(connect(mapStateToProps, null)(UserDetailContainer));
