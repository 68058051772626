exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".disabledIcon___23Ytu {\r\n  color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.iconWrapper___29YU- {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/Icon/Icon.styles.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;CAC3B;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,uBAAuB;MACnB,oBAAoB;CACzB","file":"Icon.styles.css","sourcesContent":[".disabledIcon {\r\n  color: rgba(0, 0, 0, 0.5);\r\n}\r\n\r\n.iconWrapper {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"disabledIcon": "disabledIcon___23Ytu",
	"iconWrapper": "iconWrapper___29YU-"
};