import React from 'react';
import PropTypes from 'prop-types';

import NonUsaCity from './NonUsaCity';
import NonUsaPostalCode from './NonUsaPostalCode';
import NonUsaRegion from './NonUsaRegion';

import { horizontalWrap } from '../styles.css';

const NonUsaAddressFields = props => {
  const { disabled } = props;
  return (
    <div className={horizontalWrap}>
      <NonUsaCity disabled={disabled} />
      <NonUsaRegion disabled={disabled} />
      <NonUsaPostalCode disabled={disabled} />
    </div>
  );
};

NonUsaAddressFields.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default NonUsaAddressFields;
