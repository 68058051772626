// Button elements
export const ADD_BUTTON_ID = 'addButton';
export const CANCEL_BUTTON_ID = 'cancelButton';
export const DELETE_BUTTON_ID = 'deleteButton';
export const EDIT_BUTTON_ID = 'editButton';
export const NO_BUTTON_ID = 'noButton';
export const OK_BUTTON_ID = 'okButton';
export const SAVE_BUTTON_ID = 'saveButton';
export const SUBMIT_BUTTON_ID = 'submitButton';
export const YES_BUTTON_ID = 'yesButton';

// Icon Elements
export const CANCEL_ICON_ID = 'cancelIcon';
export const CHECK_ICON_ID = 'checkIcon';
export const DELETE_ICON_ID = 'deleteIcon';
export const EDIT_ICON_ID = 'editIcon';
export const DOWN_ICON_ID = 'downIcon';
export const UP_ICON_ID = 'upIcon';
export const GRID_ICON_ID = 'gridIcon';
export const LIST_ICON_ID = 'listIcon';
export const GEAR_ICON_ID = 'gearIcon';
export const HELP_ICON_ID = 'helpIcon';
export const LOGO_ICON_ID = 'logoIcon';

// Input Elements
export const CONFIRM_NAME_INPUT_ID = 'confirmName';
export const CONFIRM_PASSWORD_INPUT_ID = 'confirmPassword';
export const DESCRIPTION_INPUT_ID = 'description';
export const EMAIL_INPUT_ID = 'email';
export const NAME_INPUT_ID = 'name';
export const PASSWORD_INPUT_ID = 'password';
export const USERNAME_INPUT_ID = 'username';
export const CENTRAL_STATION_DROP_DOWN_ID = 'centralStationDropdown';
export const SECURITY_MONITORING_CHECKBOX = 'securityMonitoringCheckbox';
export const SERVER_ID_INPUT = 'serverIDInput';

// Text link elements
export const FORGOT_PASSWORD_LINK_ID = 'forgotPasswordLink';
export const RESET_PASSWORD_LINK_ID = 'resetPasswordLink';
export const RESEND_INVITE_LINK_ID = 'resendInviteLink';
export const SIGN_UP_ACC_LINK_ID = 'signupAccLink';

// Monitoring Page
export const CONFIRM_NAME_INPUT = 'confirmName';
export const TEST_CONNECTION_BUTTON_ID = 'testConnectionButton';

// Toggle Elements
export const ANALYTICS_TOGGLE_ID = 'analyticsToggle';
export const TAMPER_TOGGLE_ID = 'tamperToggle';
export const AUTOTBE_TOGGLE_ID = 'autoTBEToggle';