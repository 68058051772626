export const FIELD_ORDER = [
  'SiteName',
  'MonitoringCenter',
  'SiteId',
  'ConfigurationString',
  'copyCameraInfoButton',
  'SecurityEventDealerMonitoringEnabled',
];

export const HEADER_TRANSLATION_IDS = {
  ConfigurationString: 'INTEGRATIONS.SITE_HEADER_LABELS.SERVER_ID',
  MonitoringCenter: 'INTEGRATIONS.SITE_HEADER_LABELS.MONITORING_CENTER',
  SecurityEventDealerMonitoringEnabled:
    'INTEGRATIONS.SITE_HEADER_LABELS.SECURITY_EVENT_DEALER_MONITORING_ENABLED',
  SiteId: 'INTEGRATIONS.SITE_HEADER_LABELS.CONFIGURATION_DETAILS',
  SiteName: 'COMMON.SITE',
  copyCameraInfoButton:
    'INTEGRATIONS.SITE_HEADER_LABELS.COPY_CAMERA_INFO_BUTTON',
};

export const CELL_WIDTHS = {
  ConfigurationString: '150',
  MonitoringCenter: '200',
  SecurityEventDealerMonitoringEnabled: '100',
  SiteId: '270',
  copyCameraInfoButton: '48',
};

export const CELL_ALIGNMENTS = {
  ConfigurationString: 'center',
  SecurityEventDealerMonitoringEnabled: 'center',
  copyCameraInfoButton: 'center',
};
