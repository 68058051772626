import { Client as AftClient } from '@avo-aft/aft-client';

// Constants
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { showMessage } from './pageMessage';
import { isFetchingData } from './common';

export function getTemplates(groupId) {
  return async dispatch => {
    const fetchOptions = { fetchScope: groupId };
    dispatch(isFetchingData(types.GET_TEMPLATES, true, fetchOptions));
    try {
      const client = new AftClient({ clusterId: groupId });
      const response = await client.readRecordingTemplates();
      const templates = response.payload.recordingTemplates;
      dispatch(receiveTemplatesList(templates, groupId));
    } catch (ex) {
      avoLogError('Error getting templates', { ex, groupId });
    } finally {
      dispatch(isFetchingData(types.GET_TEMPLATES, false, fetchOptions));
    }
  };
}

export function getRecordingSchedules(groupId) {
  return async dispatch => {
    const fetchOptions = { fetchScope: groupId };
    dispatch(isFetchingData(types.GET_RECORDING_SCHEDULES, true, fetchOptions));
    try {
      const client = new AftClient({ clusterId: groupId });
      const response = await client.readRecordingSchedules();
      const { recordingSchedules } = response.payload;
      dispatch(receiveRecordingSchedulesList(recordingSchedules, groupId));
    } catch (ex) {
      avoLogError('Error getting recording schedules', { ex, groupId });
    } finally {
      dispatch(
        isFetchingData(types.GET_RECORDING_SCHEDULES, false, fetchOptions),
      );
    }
  };
}

function receiveRecordingSchedulesList(recordingSchedules, groupId) {
  return {
    groupId,
    recordingSchedules,
    type: types.RECEIVE_RECORDING_SCHEDULES,
  };
}

function receiveTemplatesList(templates, groupId) {
  return {
    groupId,
    templates,
    type: types.RECEIVE_TEMPLATES,
  };
}

export function saveTemplate(templates, groupId, deleteTemplates) {
  return async dispatch => {
    const client = new AftClient({ clusterId: groupId });
    templates.map(async templateData => {
      const shouldUpdateTemplate = templateData.isDirty;
      const idToreplace = new Map();
      const { tempId } = templateData;
      delete templateData.backgroundColor;
      delete templateData.tempId;
      delete templateData.isDirty;
      if (!templateData.id) {
        // creating new template
        try {
          const response = await client.createRecordingTemplate({
            recordingTemplate: templateData,
          });
          idToreplace.set(tempId, response.payload.id);
          // replace tempIds with real IDs in recording schedule
          dispatch(updateRecordingScheduleId(groupId, idToreplace));
          dispatch(
            receiveSaveTemplate(templateData, response.payload.id, groupId),
          );
          dispatch(
            showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody: 'DEVICE_DETAILS.CREATE_TEMPLATE_SUCCESS',
            }),
          );
        } catch (error) {
          avoLogError('Error creating template', {
            error,
            groupId,
            templateData,
          });
          dispatch(
            showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody: 'DEVICE_DETAILS.CREATE_TEMPLATE_ERROR',
            }),
          );
        }
      } else {
        // updating
        if (shouldUpdateTemplate) {
          try {
            await client.updateRecordingTemplate({
              recordingTemplate: templateData,
            });
            dispatch(
              showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
                messageStyle: messageStyleStrings.success,
                translateBody: 'DEVICE_DETAILS.UPDATE_TEMPLATE_SUCCESS',
              }),
            );
          } catch (error) {
            avoLogError('Error creating template', {
              error,
              groupId,
              templateData,
            });
            dispatch(
              showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody: 'DEVICE_DETAILS.UPDATE_TEMPLATE_ERROR',
              }),
            );
          }
        }
        if (deleteTemplates) {
          // delete templates
          deleteTemplates.forEach(async delTemp => {
            try {
              await client.deleteRecordingTemplates({
                ids: delTemp,
              });
            } catch (error) {
              avoLogError('Error deleting template', {
                delTemp,
                error,
                groupId,
                templateData,
              });
            }
          });
        }
      }
    });
    dispatch(receiveTemplatesList(templates, groupId));
  };
}

export function editTemplateName(name, templateId, groupId) {
  return {
    groupId,
    name,
    templateId,
    type: types.EDIT_TEMPLATE_NAME,
  };
}

export function createTemplate(templateData, groupId) {
  return async dispatch => {
    dispatch(receiveCreateTemplate(templateData, groupId));
  };
}

export function updateRecordingTemplate(grid, templateId, groupId) {
  return {
    grid,
    groupId,
    templateId,
    type: types.UPDATE_RECORDING_TEMPLATE,
  };
}

function receiveCreateTemplate(templateData, groupId) {
  return {
    groupId,
    templateData,
    type: types.CREATE_TEMPLATE,
  };
}

function receiveSaveTemplate(templateData, templateId, groupId) {
  return {
    groupId,
    templateData,
    templateId,
    type: types.SAVE_TEMPLATE,
  };
}

export function setSelectedTemplate(templateId) {
  return {
    templateId,
    type: types.SET_SELECTED_TEMPLATE,
  };
}

export function deleteTemplate(templateId, groupId) {
  return {
    groupId,
    templateId,
    type: types.DELETE_TEMPLATE,
  };
}

export function updateRecordingSchedule(
  groupId,
  templateId,
  recordingSchedules,
  selectedKeys,
  startOfWeek,
) {
  return {
    groupId,
    recordingSchedules,
    selectedKeys,
    templateId,
    type: types.UPDATE_RECORDING_SCHEDULE,
    startOfWeek,
  };
}

export function saveRecordingSchedules(recordingSchedulesData, groupId) {
  return async dispatch => {
    const client = new AftClient({ clusterId: groupId });
    recordingSchedulesData.map(async schedule => {
      try {
        await client.updateRecordingSchedule({
          recordingSchedules: [schedule],
        });
        dispatch(
          showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'DEVICE_DETAILS.UPDATE_RECORDING_SCHEDULE_SUCCESS',
          }),
        );
      } catch (error) {
        avoLogError('Error updating recording schedule', {
          error,
          groupId,
          schedule,
        });
        dispatch(
          showMessage(messageTypes.TEMPLATES_MESSAGE, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'DEVICE_DETAILS.UPDATE_RECORDING_SCHEDULE_ERROR',
          }),
        );
      }
    });
  };
}

function updateRecordingScheduleId(groupId, idMap) {
  return {
    groupId,
    idMap,
    type: types.UPDATE_RECORDING_SCHEDULE_ID,
  };
}
