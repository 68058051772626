import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';

// Components
import { CSSTransitionGroup } from 'react-transition-group';
import { Toast } from 'lib';

// Actions
import * as PageMessageActions from 'actions/pageMessage';

class PageMessage extends Component {
  /* A generic page message component, in the style of ButtonGeneric. This should be used preferentially to PageSuccess and PageError */

  componentWillUnmount() {
    const { actions } = this.props;
    actions.hideMessage();
  }

  render() {
    const {
      actions,
      body,
      fixedPosition,
      hideMessage,
      keepOpen,
      messageStyle: m,
      messageType,
      translateBody,
      translateBodyData,
      visible,
    } = this.props;
    let content = <div key={`${m}BoxHidden`} />;
    if (visible) {
      messageType && !keepOpen && setTimeout(actions.hideMessage, 5000);
      hideMessage && setTimeout(hideMessage, 5000);
      content = (
        <Toast
          close={actions.hideMessage}
          fixedPosition={fixedPosition}
          keepOpen={keepOpen}
          message={
            translateBody ? (
              <Translate data={translateBodyData} id={translateBody} />
            ) : (
              body.message || body
            )
          }
          messageType={m}
        />
      );
    }
    return (
      <CSSTransitionGroup
        transitionEnterTimeout={600}
        transitionLeaveTimeout={600}
        transitionName="pageMessageContainer"
      >
        {content}
      </CSSTransitionGroup>
    );
  }
}

PageMessage.defaultProps = {
  body: '',
  fixedPosition: true,
  hideMessage: () => {},
  keepOpen: false,
  messageStyle: 'warning',
  messageType: '',
  translateBody: false,
  translateBodyData: {},
  visible: false,
};

PageMessage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fixedPosition: PropTypes.bool,
  hideMessage: PropTypes.func,
  keepOpen: PropTypes.bool,
  messageStyle: PropTypes.oneOf(['warning', 'success', 'error']),
  messageType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  translateBody: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  translateBodyData: PropTypes.objectOf(PropTypes.any),
  visible: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  // props passed in directly, e.g. visible, header, body, take precedence
  // over props in the redux store
  const { pageMessage } = state;
  const pageMessageProps = pageMessage.props || {};
  const translateHeader =
    ownProps.translateHeader || pageMessageProps.translateHeader;
  const translateHeaderData =
    ownProps.translateHeaderData || pageMessageProps.translateHeaderData;
  const translateBody =
    ownProps.translateBody || pageMessageProps.translateBody;
  const translateBodyData =
    ownProps.translateBodyData || pageMessageProps.translateBodyData;
  const messageStyle = ownProps.messageStyle || pageMessageProps.messageStyle;
  const visible = Array.isArray(ownProps.messageType)
    ? ownProps.messageType.indexOf(pageMessage.messageType) > -1
    : pageMessage.messageType === ownProps.messageType;

  return {
    body: ownProps.body || pageMessage.body,
    header: ownProps.header || pageMessage.header,
    keepOpen:
      ownProps.keepOpen ||
      (pageMessage.props ? pageMessage.props.keepOpen : null) ||
      false,
    messageStyle,
    messageType: pageMessage.messageType || null,
    props: ownProps.props || pageMessage.props || {},
    translateBody,
    translateBodyData,
    translateHeader,
    translateHeaderData,
    visible: ownProps.visible || visible,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...PageMessageActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageMessage);
