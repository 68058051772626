import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconFilterReset = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 24 17"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="dealer-dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-654.000000, -141.000000)"
      >
        <title>{props.translate('ALARMS.FILTERS.CLEAR_FILTER')}</title>
        <g
          id="Base"
          transform="translate(-1.000000, -108.000000)"
          fill={props.isIconActive ? '#006e99' : '#999999'}
        >
          <g id="Group-12">
            <g id="Nav-Bar" transform="translate(570.000000, 241.000000)">
              <path
                d="M85,8 L105.048606,8 L97.5953538,15.870717 L97.5953538,25 L93.318378,20.8013494 L93.318378,15.3310052 L85,8 Z M108.927459,15.3882535 L106.451723,17.8628397 L108.926583,20.3366957 C109.023011,20.434248 109.023011,20.5932816 108.926583,20.6896656 L107.881791,21.7339716 C107.78507,21.8306477 107.625379,21.8306477 107.528658,21.7339716 L105.053798,19.2602617 L102.577478,21.735286 C102.481195,21.8315239 102.32165,21.8315239 102.224344,21.735286 L101.179991,20.691272 C101.083709,20.5943039 101.083709,20.4351242 101.179991,20.3387402 L103.656604,17.8632778 L101.179115,15.3873773 C101.081955,15.2893869 101.081955,15.1306454 101.179115,15.0344074 L102.223468,13.9901013 C102.320189,13.8932792 102.479004,13.8932792 102.576309,13.9901013 L105.054091,16.46644 L107.530411,13.9914157 C107.62757,13.8945936 107.786385,13.8945936 107.883106,13.9914157 L108.927459,15.0352836 C109.02418,15.1319597 109.02418,15.2911393 108.927459,15.3882535 Z"
                id="icon-filter-24x17"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconFilterReset.defaultProps = {
  className: null,
  width: '24px',
  height: '17px',
};

export default withLocalize(IconFilterReset);
