exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".badgeItems___2b_Db {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 10px 5px;\r\n}\r\n\r\n.appStoreBadge___3Vfbc {\r\n  width: 140px;\r\n  height: 41.48px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/StoreBadges/badges.styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;EAC3B,kBAAkB;CACnB;;AAED;EACE,aAAa;EACb,gBAAgB;CACjB","file":"badges.styles.css","sourcesContent":[".badgeItems {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  padding: 10px 5px;\r\n}\r\n\r\n.appStoreBadge {\r\n  width: 140px;\r\n  height: 41.48px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"badgeItems": "badgeItems___2b_Db",
	"appStoreBadge": "appStoreBadge___3Vfbc"
};