export const ActivityClass = {
  CameraHealthActivity: 'CameraHealthActivity',
  DeviceHealthActivity: 'DeviceHealthActivity',
  DeviceTamperingActivity: 'DeviceTamperingActivity',
  ServerHealthActivity: 'ServerHealthActivity',
  ServerTamperingActivity: 'ServerTamperingActivity', // May replace DeviceTamperingActivity
  VideoReviewActivity: 'VideoReviewActivity', // May replace DeviceHealthActivity
};

export const alarmFilters = {
  healthAlarms: {
    field: 'ActivityClass',
    operator: 'eq',
    typeOfValue: 'string',
    values: [
      ActivityClass.CameraHealthActivity,
      ActivityClass.DeviceHealthActivity,
    ],
  },
  securityAlarms: {
    field: 'ActivityClass',
    operator: 'eq',
    typeOfValue: 'string',
    values: [
      ActivityClass.VideoReviewActivity,
      ActivityClass.DeviceTamperingActivity,
    ],
  },
};

export const alarmTypes = {
  allAlarms: 'allAlarms',
  healthAlarms: 'healthAlarms',
  securityAlarms: 'securityAlarms',
};
