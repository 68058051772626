export const arrRatesClip = [
  {
    text: '1/4',
    value: 0.25,
  },
  {
    text: '1/2',
    value: 0.5,
  },
  {
    text: '1',
    value: 1,
  },
];
export const arrRatesFullEvent = arrRatesClip.concat([
  {
    text: '2',
    value: 2,
  },
  {
    text: '4',
    value: 4,
  },
  {
    text: '8',
    value: 8,
  },
]);
export const eventTypes = {
  joinedroom: 'joinedRoom',
  createdpeer: 'createdPeer',
  checkingpeer: 'checkingPeer',
  chosencandtype: 'chosenCandType',
  connectedpeer: 'connectedPeer',
  disconnectedpeer: 'disconnectedPeer',
  connectfailedpeer: 'connectFailedPeer',
  closepeer: 'closePeer',
  playerstarted: 'playerStarted',
  channelopen: 'channelOpen',
  playererror: 'playerError',
  playerstopped: 'playerStopped',
  streamrequestfail: 'streamRequestFail',
  'rep-estthroughput': 'throughput',
  'rep-frameloss': 'frameloss',
};

// Ids
export const idBtnFwd = 'idBtnFwd10';
export const idBtnRwd = 'idBtnRewind10';
export const ID_BUTTON_SNAPSHOT = 'idBtnSnapshot';
export const ID_BUTTON_RETURN_TO_LIVE = 'idBtnReturnLive';
export const ID_BUTTON_TOGGLE_OVERLAYS = 'btnToggleOverlays';
export const ID_BUTTON_TOGGLE_VIDEO_QUALITY = 'btnToggleVideoQuality';
export const ID_MENU = 'idBtnMenu';
export const idButtonError = 'btnConnectionError';
export const PLAYBACK_CONTROLS = 'PlaybackControls';
export const SCRUBBER_CONTROLS = 'ScrubberControls';

// Player status box
export const VIDEO_PADDING = 205;
export const idBtnCloseDetails = 'btnCloseDetails';
export const idToggleEvents = 'iconToggleEvents';
export const idBtnPushpin = 'btnPushpin';
export const idShowPlayerStatus = 'showPlayerStatus';

// Other
export const SKIP_SECONDS_RECORDED = -10;
export const SKIP_SECONDS = -30;
export const LOW = 'low';
export const HIGH = 'high';
export const EVO_INTERVAL_FAILURE_THRESHOLD = 15;

// PlayerDigitalOutBox
export const OffDefaultColor = '#F7F7F7';
export const toggleOffDefaultColor = '#D8D8D8';
export const toggleOffMouseOverColor = '#006e99';
export const toggleIcon = { ON: 'ON', OFF: 'OFF' };
export const mouseInOrOut = { IN: 'IN', OUT: 'OUT' };
export const cameraLinkTypes = {
  DIGITAL_OUTPUT: 'DIGITAL_OUTPUT',
};

// PTZ Functions

export const PTZ_FUNCTION_NAMES = {
  PAN_TILT: 'Pan & Tilt',
  AUTO_FOCUS: 'Auto Focus',
  GO_TO_HOME: 'Go to Home Preset',
  TOURS: 'Presets & Tours',
  LOCK: 'Lock',
  ZOOM: 'Camera Zoom',
};
