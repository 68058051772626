// Libs
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component
import { Button } from 'lib';

// Constant
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { EMAIL_INPUT_ID, SUBMIT_BUTTON_ID } from 'constants/ElementId';

// Containers
import { PageMessage } from 'containers';

// Styles
import { formWrapper } from 'sharedStyles/modalForms.css';
import { formField, formGroup, formLabel } from '../VerticalForm/styles.css';
import { buttonContainerRegister, pageError, returnLink } from './styles.css';

const RequestResetPasswordForm = ({
  handleSubmit,
  invalid,
  pristine,
  submitting,
}) => {
  return (
    <div className={formWrapper}>
      <form onSubmit={handleSubmit}>
        <div className={pageError}>
          <PageMessage
            messageStyle={messageStyleStrings.error}
            messageType={messageTypes.REQUEST_RESET_PASSWORD_ERROR}
          />
          <PageMessage
            messageStyle={messageStyleStrings.success}
            messageType={messageTypes.REQUEST_RESET_PASSWORD_SUCCESS}
          />
        </div>
        <div className={formGroup}>
          <div className={formLabel}>
            <Translate id="COMMON.EMAIL" />
          </div>
          <Field
            className={formField}
            component="input"
            id={EMAIL_INPUT_ID}
            name="email"
          />
        </div>
        <div className={formGroup}>
          <div className={buttonContainerRegister}>
            <Button
              disabled={invalid || pristine || submitting}
              id={SUBMIT_BUTTON_ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
        </div>
        <div className={returnLink}>
          <Link to="/signin">
            <Translate id="RESET_PASSWORD.RETURN_TO_LOGIN_SCREEN" />
          </Link>
        </div>
      </form>
    </div>
  );
};

RequestResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
};

RequestResetPasswordForm.defaultProps = {
  handleSubmit: () => {},
  invalid: true,
  pristine: true,
  submitting: false,
};

export default reduxForm({
  form: 'resetPasswordForm',
})(RequestResetPasswordForm);
