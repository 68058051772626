import * as types from 'constants/ActionTypes';
import urlBuilder from 'queryBuilder/url';
import { runGetActionReturningJSON } from 'util/fetchHelpers';

// Redux actions

function receiveRegionalDeployments(data) {
  return {
    type: types.RECEIVE_REGIONAL_DEPLOYMENTS,
    ...data,
  };
}

export function receiveTimezones(timezones) {
  return {
    type: types.RECEIVE_TIMEZONES,
    timezones,
  };
}

export function receiveTimeZoneList(serverId, timeZoneList) {
  return {
    type: types.RECEIVE_SERVER_TIME_ZONE_LIST,
    serverId,
    timeZoneList,
  };
}

function receiveAiKey(data) {
  return {
    type: types.RECEIVE_AI_KEY,
    key: data,
  };
}

export function receiveLinks(links) {
  return {
    type: types.RECEIVE_LINKS,
    links,
  };
}

export function resetIsFetching(fetchType) {
  return {
    type: types.RESET_IS_FETCHING,
    fetchType,
  };
}

// Asynchronous actions

export function getRegionalDeployments() {
  // Use to determine if a user must select a region
  return dispatch => {
    const url = urlBuilder(types.GET_REGIONAL_DEPLOYMENTS);
    runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_REGIONAL_DEPLOYMENTS,
      onSuccess: json => {
        dispatch(receiveRegionalDeployments(json));
      },
      onError: ex => {
        avoLogError('Request failed getting regional deployments ', ex.message);
        dispatch(receiveRegionalDeployments({}));
      },
    });
  };
}

export function getTimezones() {
  return dispatch => {
    runGetActionReturningJSON({
      dispatch,
      url: urlBuilder(types.GET_TIMEZONES),
      fetchType: types.GET_TIMEZONES,
      onSuccess: json => {
        dispatch(receiveTimezones(json));
      },
      onError: err => {
        avoLogError('Error fetching notices:', err);
        dispatch(receiveTimezones([]));
      },
    });
  };
}

export function getTimeZoneList(serverId) {
  return dispatch => {
    const url = urlBuilder(types.GET_SERVER_TIME_ZONE_LIST, serverId);
    return runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_SERVER_TIME_ZONE_LIST,
      onSuccess: timeZoneList => {
        dispatch(receiveTimeZoneList(serverId, timeZoneList));
      },
      onError: ex => {
        avoLogError('Error getting Server time zone list', { serverId, ex });
      },
    });
  };
}

export function getAiKey() {
  // Use to determine if a user must select a region
  return dispatch => {
    const url = urlBuilder(types.GET_AI_KEY);
    runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_AI_KEY,
      onSuccess: json => {
        dispatch(receiveAiKey(json));
      },
      onError: ex => {
        avoLogError('Request failed getting app insights key ', ex.message);
      },
    });
  };
}

export function getLinks() {
  return dispatch => {
    const url = urlBuilder(types.GET_LINKS);
    runGetActionReturningJSON({
      dispatch,
      url,
      fetchType: types.GET_LINKS,
      onSuccess: json => {
        dispatch(receiveLinks(json));
      },
      onError: ex => {
        avoLogError('Error getting links', ex);
      },
    });
  };
}
