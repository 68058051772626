export const POPUP_HEADER_HEIGHT = 18;
export const imgBuilding = require('../../images/icon-building-sm.png');

export const cancelIconConstants = {
  COLOR: '#7C7C7C',
  LINE_HEIGHT: '12px',
  SIZE: 10,
};
export const clipOnlyIconConstants = {
  fillColors: {
    NORMAL: '#006e99',
    SELECTED: '#FFFFFF',
  },
  HEIGHT: 10,
  WIDTH: 15,
};
export const cameraIconConstants = {
  fillColors: {
    CONNECTED: '#6ECA3D',
    DISCONNECTED: '#E11818',
    SELECTED: '#FFFFFF',
  },
  HEIGHT: 10,
  WIDTH: 15,
};

export const bookmarkCameraIconConstants = {
  fillColors: {
    UNSELECTED: '#808080',
    SELECTED: '#FFFFFF',
  },
};
