import create from 'lodash.create';
import get from 'lodash.get';
import events from 'constants/notificationsEventsType';

const eventsType = create(Object, events);

const getKey = (eventName = '') => {
  const cleanedEventName = eventName.endsWith('.')
    ? eventName.substr(0, eventName.length - 1)
    : eventName;
  const key = get(eventsType, cleanedEventName);
  return key
    ? { keyName: key, translate: true }
    : { name: cleanedEventName, translate: false };
};

export default getKey;
