import PropTypes from 'prop-types';
import React from 'react';
import { cameraListToggleIcon, active } from './SelectCamera.styles.css';
import { Button } from 'lib';

// button that has no visual appearance, just displays the child content
const ToggleCameraSelection = props => (
  <Button
    id={props.id}
    inputType="button"
    disabled={props.disabled}
    buttonClass={componentClassNames(props.isActive)}
    onClick={ev => (!props.disabled ? props.onClick(ev) : null)}
    onMouseDown={() => (!props.isActive ? props.onMouseDown() : null)}
    buttonType="secondary"
  >
    {props.icon}
  </Button>
);

const componentClassNames = isActive => {
  if (isActive) {
    return `${cameraListToggleIcon} ${active}`;
  }
  return `${cameraListToggleIcon}`;
};

ToggleCameraSelection.defaultProps = {
  onClick() {},
  onMouseDown() {},
  id: 'btnToggleCameraSelection',
  disabled: false,
};

ToggleCameraSelection.propTypes = {
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  icon: PropTypes.object.isRequired,
};

export default ToggleCameraSelection;
