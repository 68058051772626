// Libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';

// Components
import { PersistentAccordionItem } from 'components';
import { Button, EmptyPlaceholder, GroupLayout, ListView } from 'lib';
import { IntegrationSiteItemContainer, PageMessage } from 'containers';

// Utitlity
import { generateSort } from 'util/generateSort';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import * as accordionTypes from 'constants/AccordionTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as consts from './constants';

// Styles
import { monitoringConfigAccordion, siteTable } from './styles.css';

class MonitoringConfigAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: false,
    };
  }

  componentWillUnmount() {
    this.hasValueChanged();
  }

  handleSave = subscriberId => {
    const { updateSubscriberSites } = this.props;
    this.setState({ isChanged: false });
    updateSubscriberSites(subscriberId);
  };

  hasValidSites = item => {
    // Action Button is only clickable when there are valid site entries.
    // This means monitoring is ON and a configuration string is set
    const { subscriberSites } = this.props;
    const validSubscriberSites = subscriberSites.filter(site => {
      return (
        site.TenantId === item.SubscriberId &&
        ((site.SecurityEventDealerMonitoringEnabled &&
          site.ConfigurationString &&
          site.ConfigurationString.length > 0) ||
          !site.SecurityEventDealerMonitoringEnabled)
      );
    });
    return validSubscriberSites.length > 0;
  };

  hasValueChanged = isChanged => {
    this.setState({ isChanged });
  };

  render() {
    const {
      getSubscriberSites,
      isFetchingSummary,
      isPendingRequestExists,
      subscriberSites,
      subscriberSummary,
    } = this.props;
    const { isChanged } = this.state;
    const CUSTOM_CELLS = {
      ConfigurationString: rowData => (
        <IntegrationSiteItemContainer
          fieldName="ConfigurationString"
          handleChange={this.hasValueChanged}
          site={rowData}
        />
      ),
      MonitoringCenter: rowData => (
        <IntegrationSiteItemContainer
          fieldName="MonitoringCenter"
          handleChange={this.hasValueChanged}
          site={rowData}
        />
      ),
      SecurityEventDealerMonitoringEnabled: rowData => (
        <IntegrationSiteItemContainer
          fieldName="SecurityEventDealerMonitoringEnabled"
          handleChange={this.hasValueChanged}
          site={rowData}
        />
      ),
      SiteId: rowData => (
        <IntegrationSiteItemContainer
          fieldName="SiteId"
          handleChange={this.hasValueChanged}
          site={rowData}
        />
      ),
      copyCameraInfoButton: rowData => (
        <IntegrationSiteItemContainer
          fieldName="copyCameraInfoButton"
          handleChange={() => {}}
          site={rowData}
        />
      ),
      readyStatus: rowData => (
        <IntegrationSiteItemContainer
          fieldName="readyStatus"
          handleChange={this.hasValueChanged}
          site={rowData}
        />
      ),
    };
    return (
      <div
        className={monitoringConfigAccordion}
        style={{
          maxHeight: isPendingRequestExists
            ? 'calc(50vh - 388px)'
            : 'calc(100vh - 388px)',
        }}
      >
        <PageMessage
          messageStyle={messageStyleStrings.error}
          messageType={messageTypes.UPDATE_INTEGRATION_SUBSCRIBER_SITES_ERROR}
        />
        <PageMessage
          messageStyle={messageStyleStrings.success}
          messageType={messageTypes.UPDATE_INTEGRATION_SUBSCRIBER_SITES_SUCCESS}
        />
        <EmptyPlaceholder
          isFetching={isFetchingSummary}
          items={subscriberSummary}
          string={
            <Translate id="INTEGRATIONS.SUBSCRIBER_STATS.EMPTY_INTEGRATION_SUBSCRIBERS" />
          }
        >
          {subscriberSummary
            .sort(generateSort(item => item.SubscriberName.toLowerCase()))
            .map((subscriber, index) => {
              const subscriberSitesForThisSubscriber = subscriberSites
                .sort(generateSort(item => item.SiteName.toLowerCase()))
                .filter(site => site.TenantId === subscriber.SubscriberId);

              return (
                <PersistentAccordionItem
                  key={subscriber.SubscriberId}
                  accordionType={accordionTypes.MONITORING}
                  item={subscriber}
                  loadExpanded={isPendingRequestExists ? false : index === 0}
                  nameField="SubscriberName"
                  retrieveContainedData={getSubscriberSites}
                >
                  <ListView
                    bodyHeight={250}
                    cellAlignments={consts.CELL_ALIGNMENTS}
                    cellWidths={consts.CELL_WIDTHS}
                    className={siteTable}
                    customCells={CUSTOM_CELLS}
                    data={subscriberSitesForThisSubscriber}
                    fieldOrder={consts.FIELD_ORDER}
                    headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
                    hideFilter
                  />
                  <GroupLayout horizontalPositioning="center">
                    <Button
                      disabled={!isChanged}
                      inputType="button"
                      onClick={() => {
                        this.handleSave(subscriber.SubscriberId);
                      }}
                      text={<Translate id="BUTTONS.SAVE" />}
                    />
                  </GroupLayout>
                </PersistentAccordionItem>
              );
            })}
        </EmptyPlaceholder>
      </div>
    );
  }
}

MonitoringConfigAccordion.propTypes = {
  getSubscriberSites: PropTypes.func,
  isFetchingSummary: PropTypes.bool,
  isPendingRequestExists: PropTypes.bool.isRequired,
  subscriberSites: PropTypes.arrayOf(PropTypes.any),
  subscriberSummary: PropTypes.arrayOf(PropTypes.any),
  updateSubscriberSites: PropTypes.func,
};

MonitoringConfigAccordion.defaultProps = {
  getSubscriberSites: () => {},
  isFetchingSummary: false,
  subscriberSites: [],
  subscriberSummary: [],
  updateSubscriberSites: () => {},
};

export default MonitoringConfigAccordion;
