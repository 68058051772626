// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'util/cameraSettingLinks';

// Actions
import * as UserActions from 'actions/user';
import * as SignalActions from 'actions/signals';
import * as UtilityActions from 'actions/utilities';

// Components
import { Button, Icon } from 'lib';
import Signals from './Signals';
import Fetches from './Fetches';
import Customer from './Customer';
import DevFlags from './DevFlags';
import Tenant from './Tenant';
import Debug from './Debug';
import Settings from './Settings';
import EnvironmentSwitch from './EnvironmentSwitch';

// Styles
import * as styles from './Oasis.css';

// Constants
import * as consts from './constants';
import { IC_SETTINGS } from 'constants/iconNames';

const DefaultEnvs = [
  'ci.develophelios.com/',
  'bluenextnext.develophelios.com/',
  'blue-termina-api.azurewebsites.net/',
  'blue-hyrule-api.azurewebsites.net/',
  'bluenext.develophelios.com/',
  'qa.develophelios.com/',
  'preview.develophelios.com/',
];

class Oasis extends Component {
  constructor(props) {
    super(props);
    const envHistory =
      (window.localStorage && localStorage.devEnvHistory) || '';
    const historyList =
      (envHistory.length && envHistory.split(',')) || DefaultEnvs;
    const moduleState =
      window.localStorage && localStorage.oasisModuleState !== undefined
        ? JSON.parse(localStorage.oasisModuleState)
        : {};
    this.state = {
      devHost:
        (window.localStorage && localStorage.devHost) ||
        'ci.develophelios.com/',
      expanded:
        (window.localStorage && localStorage.oasisExpanded === 'true') || false,
      failFetches:
        (window.localStorage &&
          localStorage.failFetches &&
          localStorage.failFetches !== 'false') ||
        false,
      history: historyList,
      moduleState,
      settingsVisible: false,
      visible:
        (window.localStorage &&
          localStorage.skeletonKey !== undefined &&
          localStorage.skeletonKey) ||
        false,
    };
  }

  componentDidUpdate() {
    const { actions, customers, profile } = this.props;
    if (
      window.localStorage &&
      localStorage.proxyid &&
      customers &&
      customers.length > 0 &&
      profile.CustomerTenantId === undefined
    ) {
      actions.setCustomerContext(localStorage.proxyid);
    }
  }

  toggleOasis = () => {
    const { expanded } = this.state;
    const newExpanded = !expanded;
    this.setState({ expanded: newExpanded }, () => {
      if (window.localStorage) {
        localStorage.oasisExpanded = newExpanded;
      }
    });
  };

  toggleSettings = e => {
    e.stopPropagation();
    const { settingsVisible } = this.state;
    this.setState({
      settingsVisible: !settingsVisible,
    });
  };

  setEnvironment = newEnvironment => {
    let nextEnvironment;
    if (typeof newEnvironment === 'object') {
      nextEnvironment = newEnvironment.url;
    } else {
      nextEnvironment = newEnvironment;
    }
    const { history } = this.state;
    let newHistory = [newEnvironment, ...history];
    newHistory = new Set(newHistory);
    newHistory = [...newHistory];
    this.setState(
      {
        devHost: nextEnvironment,
        history: newHistory,
      },
      () => {
        if (window.localStorage) {
          localStorage.devHost = newEnvironment;
          localStorage.devEnvHistory = newHistory;
          this.resetProxy();
          window.location.reload();
        }
      },
    );
  };

  removeEnvironment = environmentToRemove => {
    const { devHost, history } = this.state;
    let newDevHost;
    if (environmentToRemove === devHost) {
      newDevHost = 'ci.develophelios.com/';
    } else {
      newDevHost = devHost;
    }
    const newHistory = history.filter(url => url !== environmentToRemove);
    this.setState(
      {
        devHost: newDevHost,
        history: newHistory,
      },
      () => {
        if (window.localStorage) {
          localStorage.devHost = newDevHost;
          localStorage.devEnvHistory = newHistory;
          this.resetProxy();
          window.location.reload();
        }
      },
    );
  };

  saveProxy = () => {
    const { profile } = this.props;
    if (window.localStorage) {
      localStorage.proxyid = profile.CustomerTenantId;
      this.setState({ proxyLocked: true });
    }
  };

  restoreProxy = () => {
    const { setCustomerContext } = this.props;
    if (window.localStorage) {
      setCustomerContext(localStorage.proxyid);
    }
  };

  resetProxy = () => {
    if (window.localStorage) {
      localStorage.removeItem('proxyid');
      window.location.reload();
    }
  };

  clearSignalLog = () => {
    const { actions } = this.props;
    actions.clearSignalRLog();
  };

  updateModuleState = (moduleId, propName, newValue) => {
    const { moduleState } = this.state;
    const newModuleState = cloneDeep(moduleState);
    newModuleState[moduleId] = newModuleState[moduleId] || {};
    newModuleState[moduleId][propName] = newValue;
    this.setState({ moduleState: newModuleState });
    localStorage.oasisModuleState = JSON.stringify(newModuleState);
  };

  disableOasis = () => {
    localStorage.removeItem('skeletonKey');
    localStorage.removeItem('flags');
    window.location.reload();
  };

  setFailFetch = () => {
    const { failFetches } = this.state;
    this.setState({ failFetches: !failFetches });
    localStorage.failFetches = !failFetches;
  };

  resetIsFetchingStatus = fetchName => {
    const { actions } = this.props;
    actions.resetIsFetching(fetchName);
  };

  render() {
    const { isFetching, profile, signals } = this.props;
    const {
      devHost,
      expanded,
      failFetches,
      history,
      moduleState,
      proxyLocked,
      settingsVisible,
      visible,
    } = this.state;
    if (visible) {
      return (
        <div className={`${styles.oasis} ${expanded ? styles.expanded : ''}`}>
          <div className={styles.oasisHeader}>
            <div className={styles.oasisLabel}>Oasis</div>
            {expanded ? (
              <div className={styles.headerItems}>
                <a
                  href={`//${devHost}`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {devHost}
                </a>
                <span
                  dangerouslySetInnerHTML={{ __html: '&nbsp;&bull;&nbsp;' }}
                />
                <a
                  href={`//${devHost}/swagger`}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {'Swagger'}
                </a>
                <Button
                  buttonClass={styles.oasisSettingsButton}
                  icon
                  inputType="button"
                  onClick={this.toggleSettings}
                >
                  <Icon color="white" height="16" id={IC_SETTINGS} width="16" />
                </Button>
                <Button
                  buttonClass={styles.headerButton}
                  inputType="button"
                  onClick={this.toggleOasis}
                  text="-"
                />
                <Button
                  buttonClass={styles.headerButton}
                  inputType="button"
                  onClick={this.disableOasis}
                  text="x"
                />
              </div>
            ) : (
              <Button
                buttonClass={styles.headerButton}
                inputType="button"
                onClick={this.toggleOasis}
                text="+"
              >
                +
              </Button>
            )}
          </div>
          <div />
          {expanded ? (
            <>
              {settingsVisible ? (
                <Settings
                  moduleState={moduleState}
                  updateModuleState={this.updateModuleState}
                />
              ) : null}
              <EnvironmentSwitch
                defaultEnvs={DefaultEnvs}
                history={history}
                moduleState={moduleState}
                removeEnvironment={this.removeEnvironment}
                setEnvironment={this.setEnvironment}
                updateModuleState={this.updateModuleState}
              />
              <Tenant
                moduleState={moduleState}
                profile={profile}
                updateModuleState={this.updateModuleState}
              />
              <Fetches
                failFetches={failFetches}
                isFetching={isFetching}
                moduleState={moduleState}
                resetIsFetchingStatus={this.resetIsFetchingStatus}
                setFailFetch={this.setFailFetch}
                updateModuleState={this.updateModuleState}
              />
              <DevFlags
                moduleState={moduleState}
                updateModuleState={this.updateModuleState}
              />
              <Signals
                clearLog={this.clearSignalLog}
                data={signals}
                moduleState={moduleState}
                updateModuleState={this.updateModuleState}
              />
              <Debug
                moduleState={moduleState}
                updateModuleState={this.updateModuleState}
              />
              <Customer
                moduleState={moduleState}
                profile={profile}
                proxyLocked={proxyLocked}
                resetProxy={this.resetProxy}
                saveProxy={this.saveProxy}
                updateModuleState={this.updateModuleState}
              />
            </>
          ) : (
            ''
          )}
        </div>
      );
    }
    return '';
  }
}

Oasis.defaultProps = {
  setCustomerContext: () => {},
};

Oasis.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  customers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.objectOf(PropTypes.any).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  setCustomerContext: PropTypes.func,
  signals: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = state => {
  let currentOrganization;
  if (state.user.profile) {
    currentOrganization = state.user.profile.TenantName;
  }
  return {
    currentOrganization,
    customers: state.user.customers,
    isFetching: state.isFetching,
    organizations: state.user.organizations,
    profile: state.user.profile,
    signals: state.signalr.log,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { ...UserActions, ...SignalActions, ...UtilityActions },
      dispatch,
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Oasis);
