import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

import { getFullPath } from 'util/getPathName';

import Reports from './Reports';

const ReportsContainer = ({
  canChangeCustomer,
  fullPath,
  history,
  profile,
}) => (
  <Reports
    canChangeCustomer={canChangeCustomer}
    fullPath={fullPath}
    history={history}
    profile={profile}
  />
);

ReportsContainer.propTypes = {
  canChangeCustomer: PropTypes.bool.isRequired,
  fullPath: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const fullPath = getFullPath(ownProps.location.pathname);
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    fullPath,
    profile: state.user.profile,
  };
};

export default connect(
  mapStateToProps,
  null,
)(withAITracking(ai.reactPlugin, ReportsContainer, 'ReportsContainer'));
