import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store/configureStore';
import { LocalizeProvider } from 'react-localize-redux';
import routes from './config/routes';
// import Localizations from './localization/initializeLanguages';
// Define log functions as no-ops
window.avoLog = () => {};
window.avoLogError = () => {};
// Localizations();
ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider store={store}>{routes(store)}</LocalizeProvider>
  </Provider>,
  document.getElementById('app'),
);
