import * as types from 'constants/ActionTypes';
import * as urlConstants from 'constants/urlPaths';
// import fieldNames from './fieldNameFormatter';
import formatUrl from './urlFormatter';

const GATEWAY_SETTING_TYPE_GENERAL = 'GENERAL';
const GATEWAY_SETTING_TYPE_SPEAKER = 'SPEAKER';
const GATEWAY_SETTINGS_TYPE_ALL = 'ALL';

// THE DIFFERENCE BTWN 'PATH' AND 'TEMPLATE' IS THAT TEMPLATE NEEDS AN ID THAT
// CAN'T BE APPENDED
// SO PATH CAN BE /API/FOO + /123 BUT TEMPLATE IS /API/FOO/:ID/BAR
export default function urlBuilder(
  type,
  id,
  cameraId,
  queryOptions,
  idSegments,
) {
  // If failFecth is enabled in Oasis, return invalid URL to fail the request
  if (
    window.localStorage &&
    localStorage.failFetches &&
    localStorage.failFetches !== 'false'
  ) {
    return 'nonexist-url';
  }

  // queryOptions is an object that may contain any of: filter, sort, skip, top
  // idSegments contains additional ids or other identifying information, for when id and cameraId are insufficient to construct an entire url
  switch (type) {
    case types.LOGIN: {
      return formatUrl({ path: urlConstants.RESOURCE_LOGIN });
    }

    case types.LOGOUT: {
      return formatUrl({ path: urlConstants.RESOURCE_LOGOUT });
    }

    case types.GET_DEVICES: {
      return formatUrl({
        path: urlConstants.RESOURCE_DEVICES,
        queryOptions: { ...queryOptions },
      });
    }

    case types.GET_ALL_DEVICES: {
      return formatUrl({ path: urlConstants.RESOURCE_ALL_DEVICES });
    }

    case types.GET_ALL_SERVERS_AND_CAMERAS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ALL_SERVERS_AND_CAMERAS,
        queryOptions: {
          sort: { col: 'LocationId, Type', direction: 'desc' },
        },
      });
    }

    case types.GET_LOCATION_DEVICES: {
      return formatUrl({
        path: urlConstants.RESOURCE_DEVICES,
        queryOptions: { filters: queryOptions.queryOptions.filters },
      });
    }

    case types.GET_DEVICE_ENTITIES: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_DEVICE_ENTITIES,
        ...idSegments,
      });
    }

    case types.UPDATE_LINKED_CAMERAS: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_DIGITAL_OUT_CAMERA_LINKS,
        ...idSegments,
      });
    }

    case types.UPGRADE_DEVICE_FIRMWARE: {
      return formatUrl({
        id,
        path: urlConstants.RESOURCE_UPGRADE_DEVICE_FIRMWARE,
      });
    }

    case types.GET_LATEST_FIRMWARE: {
      return formatUrl({
        ids: { id, platform: idSegments.platform },
        template: urlConstants.RESOURCE_GET_LATEST_FIRMWARE,
      });
    }

    case types.REQUEST_DEVICE_LOGS: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_DEVICE_LOGS,
      });
    }

    case types.GET_DEVICE_LOGS: {
      return formatUrl({
        ids: {
          id,
          logId: idSegments.logId,
        },
        template: urlConstants.GET_DEVICE_LOGS,
      });
    }

    case types.RESTART_APPLIANCE: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_RESTART_DEVICE,
      });
    }

    case types.GET_DEVICE: {
      return formatUrl({
        path: urlConstants.RESOURCE_SERVERS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.DELETE_DEVICE: {
      return formatUrl({ id, template: urlConstants.DELETE_DEVICE });
    }

    case types.GET_SNAPSHOT_URL: {
      return formatUrl({
        ids: { cameraId, id },
        template: urlConstants.RESOURCE_CAMERA_SNAPSHOT,
      });
    }

    case types.GET_SNAPSHOT_SOURCE: {
      let params = {
        cameraId,
        format: queryOptions.format || 'jpeg',
        frames: queryOptions.frames || 'all',
        media: queryOptions.media || 'video',
        quality: queryOptions.quality || 'high',
        size: queryOptions.size || '100,100',
      };
      if (queryOptions.startTime) {
        params = Object.assign(params, { t: queryOptions.startTime || 'live' });
      }
      return formatUrl({
        id,
        queryOptions: {
          params,
        },
        template: urlConstants.RESOURCE_MEDIA_PARAMS,
      });
    }

    case types.GET_MEDIA_PARAMS: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_MEDIA_PARAMS,
        ...idSegments,
      });
    }

    case types.ADD_DEVICE: {
      return formatUrl({ path: urlConstants.RESOURCE_DEVICE });
    }

    case types.GET_EVENTS: {
      return formatUrl({
        path: urlConstants.RESOURCE_EVENTS,
        queryOptions: {
          filter: { field: 'Device_Id', value: id },
          ...queryOptions,
        },
      });
    }

    case types.GET_CAMERAGROUPS: {
      return formatUrl({
        path: urlConstants.RESOURCE_CAMERAGROUP,
      });
    }

    case types.GET_CAMERAGROUP: {
      return formatUrl({ id, path: urlConstants.RESOURCE_CAMERAGROUP });
    }

    case types.EDIT_DEVICE: {
      return formatUrl({ id, path: urlConstants.RESOURCE_DEVICE });
    }

    case types.GET_ALARM_CLIP_URL: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_EVENT_CLIP_URL,
        ...idSegments,
      });
    }

    case types.GET_ALARMS: {
      return formatUrl({
        queryOptions: {
          ...queryOptions,
        },
        template: urlConstants.ALARMS_ODATA,
      });
    }

    case types.GET_ALARM: {
      return formatUrl({
        id,
        template: urlConstants.EDIT_ALARM,
        ...idSegments,
      });
    }

    case types.GET_ALARMS_STATS: {
      return formatUrl({
        path: urlConstants.ALARMS_STATS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.CLAIM_DEVICE: {
      return formatUrl({
        ids: { ...idSegments },
        template: urlConstants.CLAIM_DEVICE,
      });
    }

    case types.CLAIM_DEVICE_BY_ID: {
      return formatUrl({
        ids: { id, locationId: idSegments.locationId },
        template: urlConstants.CLAIM_DEVICE_BY_ID,
      });
    }

    case types.GET_CAMERAS: {
      return formatUrl({
        id,
        queryOptions: { ...queryOptions },
        template: urlConstants.RESOURCE_ALL_CAMERAS,
        ...idSegments,
      });
    }

    case types.GET_ALL_CAMERAS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ALL_CAMERAS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.GATEWAY_DEVICE_POE: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_DEVICE_POE,
      });
    }

    case types.GATEWAY_DEVICE_POE_INFO: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_DEVICE_POE_INFO,
      });
    }

    case types.GET_CAMERA: {
      return formatUrl({ id, path: urlConstants.GET_CAMERA });
    }

    case types.GET_RULES: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        queryOptions: {
          params: {
            id: cameraId,
            type: 'ANALYTICS',
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.UPDATE_CAMERA_SETTINGS: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.UPDATE_CAMERA_ZOOM: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_ZOOM,
      });
    }

    case types.UPDATE_CAMERA_FOCUS: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_FOCUS,
      });
    }

    case types.UPDATE_CAMERA_FOCUS_RELATIVE: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_FOCUS_RELATIVE,
      });
    }

    case types.UPDATE_CAMERA_FOCUS_CONTINUOUS: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_FOCUS_CONTINUOUS,
      });
    }

    case types.UPDATE_CAMERA_AUTOFOCUS: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_AUTOFOCUS,
      });
    }

    case types.UPDATE_CAMERA_PAN_TILT_ZOOM: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_PAN_TILT_ZOOM,
      });
    }

    case types.TRIGGER_DIGITAL_OUT: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_TRIGGER_DIGITAL_OUT,
        ...idSegments,
      });
    }

    case types.UPDATE_SERVER_NAME: {
      /* Helios uses ACC's Site Name as the Server Name. This API
          has SITE as in the url because it is updating a site
          but Helios displays server name instead, hence the url type
          here is called UpdateServerName but actual implementation
          uses SITE */
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_UPDATE_SITE,
      });
    }

    case types.UPDATE_SERVER_TIME_ZONE: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_UPDATE_TIME_ZONE,
      });
    }

    case types.RESTART_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_RESTART_CAMERA,
      });
    }

    case types.REFRESH_SNAPSHOT: {
      return formatUrl({
        ids: { cameraId, id },
        template: urlConstants.RESOURCE_CAMERA_SNAPSHOT,
      });
    }

    case types.GET_ANALYTICS_STATUS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            cameraId,
          },
        },
        template: urlConstants.GATEWAY_ANALYTICS,
      });
    }

    case types.UPDATE_ANALYTICS_STATUS: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_ANALYTICS,
      });
    }

    case types.GET_ENTITY_SETTINGS: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        queryOptions: {
          params: {
            id: idSegments.entityId,
            type: GATEWAY_SETTINGS_TYPE_ALL,
          },
        },
        template: urlConstants.GATEWAY_ENTITY_SETTINGS,
      });
    }

    case types.UPDATE_ENTITY_SETTINGS: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.GATEWAY_ENTITY_SETTINGS,
      });
    }

    case types.GET_CAMERA_SETTINGS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
            type: GATEWAY_SETTINGS_TYPE_ALL,
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.GET_ACQUISITION_SETTINGS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
            type: 'ACQUISITION',
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.GET_COMPRESSION_SETTINGS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
            type: 'COMPRESSION',
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.GET_RECORDING_TIMELINE: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            cameraIds: cameraId,
            end: '',
            scope: '10_SECONDS',
            start: '',
            ...queryOptions,
          },
        },
        template: urlConstants.GATEWAY_TIMELINE,
        ...idSegments,
      });
    }

    case types.GET_USERS: {
      return formatUrl({
        path: urlConstants.RESOURCE_USERS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.GET_USER: {
      return formatUrl({
        id,
        path: urlConstants.RESOURCE_USERS,
      });
    }

    case types.GET_USERS_PUBLIC_INFO: {
      // Only override orgId if it's in the queryOptions
      let options = {
        path: urlConstants.RESOURCE_USERS_PUBLIC_INFO,
        queryOptions: { ...queryOptions },
      };
      if (queryOptions && queryOptions.orgId) {
        options = { ...options, orgId: queryOptions.orgId };
      }
      return formatUrl(options);
    }

    case types.GET_BANNER_NOTICES: {
      const options = {
        path: urlConstants.GET_BANNER_NOTICES,
      };
      const url = formatUrl(options);
      return url;
    }

    case types.GET_UPGRADEABLE_DEVICES: {
      return formatUrl({
        path: urlConstants.RESOURCE_SERVERS,
        // Some day these query options and the sorting conditions in DeviceBulkUpgradeConfirm/constants
        // should somehow become shared code.
        queryOptions: {
          filters: [
            { field: 'CanBeUpgraded', typeOfValue: 'boolean', values: [true] },
            { field: 'ConnectionState', values: ['CONNECTED'] },
            // TODO: Re-enable when firmware status bug is resolved.
            // {
            //   field: 'FirmwareStatus',
            //   typeOfValue: 'number', // This is a dirty lie to force the query params to be properly formatted
            //   values: ["'Success'", null],
            // },
          ],
          select: { typeOfValue: 'guid', value: 'SiteId' },
        },
      });
    }

    case types.EDIT_USER: {
      return formatUrl({ id, path: urlConstants.RESOURCE_USERS });
    }

    case types.DELETE_USER: {
      return formatUrl({ id, template: urlConstants.DELETE_USER });
    }

    case types.RESEND_INVITE: {
      return formatUrl({ id, template: urlConstants.RESEND_INVITE });
    }

    case types.REQUEST_RESET_PASSWORD: {
      return formatUrl({
        path: urlConstants.REQUEST_RESET_PASSWORD,
        queryOptions,
      });
    }

    case types.RESET_PASSWORD: {
      return formatUrl({ id, template: urlConstants.RESET_PASSWORD });
    }

    case types.GET_LOCATIONS: {
      return formatUrl({
        path: urlConstants.RESOURCE_LOCATIONS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.SUSPEND_LOCATION: {
      return formatUrl({
        id,
        template: urlConstants.SUSPEND_LOCATIONS,
        ...idSegments,
      });
    }

    case types.UNSUSPEND_LOCATION: {
      return formatUrl({
        id,
        template: urlConstants.UNSUSPEND_LOCATIONS,
        ...idSegments,
      });
    }

    case types.GET_CLUSTERS: {
      return formatUrl({
        path: urlConstants.RESOURCE_CLUSTERS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.GET_CLUSTER: {
      return formatUrl({
        path: urlConstants.RESOURCE_CLUSTERS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.GET_SERVICE_PACKAGES: {
      return formatUrl({
        id,
        template: urlConstants.GET_SERVICE_PACKAGES,
      });
    }

    case types.GET_SERVICE_PACKAGE: {
      return formatUrl({
        id,
        template: urlConstants.GET_SERVICE_PACKAGE,
      });
    }

    case types.GET_SERVICE_PACKAGE_BY_CODE: {
      return formatUrl({
        id,
        template: urlConstants.GET_SERVICE_PACKAGE_BY_CODE,
      });
    }

    case types.GET_LOCATION: {
      return formatUrl({
        path: urlConstants.RESOURCE_LOCATIONS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.ADD_LOCATION: {
      return formatUrl({
        path: urlConstants.RESOURCE_LOCATIONS,
        ...idSegments,
      });
    }

    case types.EDIT_LOCATION: {
      return formatUrl({
        id,
        path: urlConstants.RESOURCE_LOCATIONS,
        ...idSegments,
      });
    }

    case types.DELETE_LOCATION: {
      return formatUrl({
        id,
        template: urlConstants.DELETE_LOCATION,
        ...idSegments,
      });
    }

    case types.GET_SERVICE_PACKAGE_FAMILIES: {
      return formatUrl({
        path: urlConstants.RESOURCE_SERVICE_PACKAGE_FAMILIES,
        ...idSegments,
      });
    }

    case types.GET_DEALER_SERVICE_PACKAGE_FAMILIES: {
      return formatUrl({
        id,
        template: urlConstants.GET_DEALER_SERVICE_PACKAGE_FAMILIES,
        ...idSegments,
      });
    }

    case types.GET_SUBSCRIPTIONS: {
      return formatUrl({
        path: urlConstants.RESOURCE_SUBSCRIPTIONS,
      });
    }

    case types.GET_ORG_SUBSCRIPTIONS: {
      const url = formatUrl({
        orgId: id,
        path: urlConstants.GET_ORG_SUBSCRIPTIONS,
      });
      return url;
    }

    case types.GET_ALL_SERVICE_PACKAGES: {
      return formatUrl({
        path: urlConstants.RESOURCE_SERVICE_PACKAGES,
      });
    }

    case types.GET_DEALER_BILLING_CYCLES: {
      return formatUrl({
        path: urlConstants.DEALER_BILLING_CYCLES,
      });
    }

    case types.GET_DEALER_SUBSCRIBER_STATS: {
      return formatUrl({
        path: urlConstants.DEALER_SUBSCRIBERS_STATS,
        queryOptions,
      });
    }

    case types.GET_DEALER_SUBSCRIPTION_LEVELS: {
      return formatUrl({
        path: urlConstants.DEALER_SUBSCRIBERS_SUBSCRIPTION_LEVELS,
        queryOptions,
      });
    }

    case types.GET_SUBSCRIPTION_RENEWAL_PERIODS: {
      return formatUrl({
        path: urlConstants.DEALER_SUBSCRIBERS_RENEWAL_PERIOD,
        queryOptions,
      });
    }

    case types.GET_DEALER_SUBSCRIBER_UPGRADE_SITES: {
      return formatUrl({
        path: urlConstants.DEALER_SUBSCRIBERS_UPGRADE_SITES,
      });
    }

    case types.GET_SUBSCRIBER_SERVICE_PACKAGES: {
      return formatUrl({
        orgId: id,
        path: urlConstants.SUBSCRIBER_SERVICE_PACKAGES,
      });
    }

    case types.GET_DEALER_SUBSCRIBERS_NEARING_RENEWAL: {
      return formatUrl({
        path: urlConstants.DEALER_SUBSCRIBERS_NEARING_RENEWAL,
      });
    }

    case types.EDIT_ALARM: {
      return formatUrl({
        id,
        template: urlConstants.EDIT_ALARM,
        ...idSegments,
      });
    }

    case types.ADD_USER: {
      return formatUrl({ orgId: id, path: urlConstants.RESOURCE_USERS });
    }

    case types.CREATE_CLAIM_KEY: {
      return formatUrl({ id, template: urlConstants.CREATE_CLAIM_KEY });
    }

    case types.GET_ACTIVATION_CODE: {
      return formatUrl({
        id,
        queryOptions,
        template: urlConstants.GET_ACTIVATION_CODE,
        version: urlConstants.URL_VERSION_2,
      });
    }

    case types.GET_TIMEZONES: {
      return formatUrl({ path: urlConstants.GET_TIMEZONES });
    }

    case types.GET_REGIONAL_DEPLOYMENTS: {
      return formatUrl({ path: urlConstants.GET_REGIONAL_DEPLOYMENTS });
    }

    case types.GET_DISCOVERED_CAMERAS: {
      return formatUrl({
        id,
        template: urlConstants.GET_DISCOVERED_CAMERAS,
      });
    }

    case types.GET_DISCOVERED_CAMERAS_RESULT: {
      const url = formatUrl({
        ids: { cameraId, id },
        template: urlConstants.GET_DISCOVERED_CAMERAS_RESULT,
      });
      return url;
    }

    case types.GET_CONNECTED_CAMERAS: {
      return formatUrl({
        id,
        template: urlConstants.GET_CONNECTED_CAMERAS,
      });
    }

    case types.GET_SUPPORTED_CAMERAS: {
      return formatUrl({
        id,
        template: urlConstants.GET_CONNECT_CAMERA_DRIVERS,
      });
    }

    case types.GET_SERVER_CAPABILITIES: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_GET_CAPABILITIES,
      });
    }

    case types.CONNECT_CAMERA: {
      return formatUrl({
        ids: { cameraId, id },
        template: urlConstants.CONNECT_CAMERA,
      });
    }

    case types.UNMANAGE_CAMERA: {
      return formatUrl({
        ids: { cameraId, id },
        template: urlConstants.UNMANAGE_CAMERA,
      });
    }

    case types.DELETE_CAMERA: {
      return formatUrl({
        ids: { cameraId, id },
        template: urlConstants.DELETE_CAMERA,
      });
    }

    case types.REGISTER: {
      return formatUrl({ path: urlConstants.REGISTER });
    }

    case types.REGISTER_UNPROVISIONED: {
      return formatUrl({
        path: urlConstants.REGISTER_UNPROVISIONED,
      });
    }

    case types.MANUALLY_DISCOVER_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.MANUALLY_DISCOVER_CAMERA,
      });
    }

    case types.MANUALLY_DISCOVER_RTSP_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.MANUALLY_DISCOVER_RTSP_CAMERA,
      });
    }

    case types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE: {
      return formatUrl({
        id,
        template: urlConstants.MANUALLY_DISCOVER_CAMERAS_BY_RANGE,
      });
    }

    case types.ENABLE_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.ENABLE_CAMERA,
      });
    }

    case types.DISABLE_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.DISABLE_CAMERA,
      });
    }

    case types.ARM_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.ARM_CAMERA,
      });
    }

    case types.DISARM_CAMERA: {
      return formatUrl({
        id,
        template: urlConstants.DISARM_CAMERA,
      });
    }

    case types.ENABLE_CAMERA_FEATURE: {
      return formatUrl({
        id,
        template: urlConstants.ENABLE_CAMERA_FEATURE,
      });
    }

    case types.DISABLE_CAMERA_FEATURE: {
      return formatUrl({
        id,
        template: urlConstants.DISABLE_CAMERA_FEATURE,
      });
    }

    case types.ADD_CUSTOMER: {
      return formatUrl({
        path: urlConstants.CREATE_ORGANIZATION,
        ...idSegments,
      });
    }

    case types.EDIT_CUSTOMER: {
      return formatUrl({
        template: urlConstants.EDIT_ORGANIZATION,
        ...idSegments,
      });
    }

    case types.RESEND_CUSTOMER_INVITE: {
      return formatUrl({
        id,
        template: urlConstants.RESEND_CUSTOMER_INVITE,
      });
    }

    case types.GET_CURRENT_ORGANIZATION: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_CURRENT_ORGANIZATION,
      });
    }

    case types.EDIT_SUBSCRIPTION_TERM_LENGTH: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.EDIT_SUBSCRIPTION_TERM_LENGTH,
      });
    }

    case types.PENDING_SITE_REQUESTS_CREATE: {
      return formatUrl({
        id,
        template: urlConstants.PENDING_SITE_REQUESTS_CREATE,
      });
    }

    case types.UPGRADE_SERVICE_PACKAGE: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.UPGRADE_SERVICE_PACKAGE,
      });
    }

    case types.DOWNGRADE_SERVICE_PACKAGE: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.DOWNGRADE_SERVICE_PACKAGE,
      });
    }

    case types.ENABLE_MONITORING: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.ENABLE_MONITORING,
      });
    }

    case types.DISABLE_MONITORING: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.ENABLE_MONITORING,
      });
    }

    case types.GET_CUSTOMERS: {
      return formatUrl({
        path: urlConstants.RESOURCE_CUSTOMERS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.DELETE_CUSTOMER: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_CURRENT_ORGANIZATION,
      });
    }

    case types.GET_ORGANIZATIONS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ACCOUNT_ORGANIZATIONS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.ENABLE_INTEGRATION_CONFIG: {
      return formatUrl({
        id,
        template: urlConstants.ENABLE_INTEGRATION_CONFIG,
      });
    }

    case types.GET_INTEGRATION_CONFIGS: {
      return formatUrl({
        path: urlConstants.RESOURCE_INTEGRATIONS,
      });
    }

    case types.GET_INTEGRATION_TYPES: {
      return formatUrl({
        path: urlConstants.GET_INTEGRATION_TYPES,
      });
    }

    case types.GET_CONNECTION_TEST_RESULT: {
      return formatUrl({
        id,
        template: urlConstants.GET_CONNECTION_TEST_RESULT,
      });
    }

    case types.CREATE_INTEGRATION_CONFIG: {
      return formatUrl({
        path: urlConstants.CREATE_INTEGRATION_CONFIG,
      });
    }

    case types.EDIT_INTEGRATION_CONFIG: {
      return formatUrl({
        id,
        template: urlConstants.EDIT_INTEGRATION_CONFIG,
      });
    }

    case types.CAN_DELETE_INTEGRATION_CONFIG: {
      return formatUrl({
        id,
        template: urlConstants.CAN_DELETE_INTEGRATION_CONFIG,
      });
    }

    case types.DELETE_INTEGRATION_CONFIG: {
      return formatUrl({
        id,
        template: urlConstants.DELETE_INTEGRATION_CONFIG,
      });
    }

    case types.GET_INTEGRATION_SUBSCRIBER_SUMMARY: {
      return formatUrl({
        path: urlConstants.RESOURCE_INTEGRATION_SUBSCRIBER_SUMMARY,
      });
    }

    case types.GET_INTEGRATION_SUBSCRIBER_SITES: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_INTEGRATION_SUBSCRIBER_SITES,
      });
    }

    case types.GET_SCHEDULES: {
      return formatUrl({
        path: urlConstants.RESOURCE_SCHEDULES,
        queryOptions: { ...queryOptions },
      });
    }

    case types.ADD_SCHEDULE: {
      return formatUrl({
        path: urlConstants.RESOURCE_SCHEDULES,
      });
    }

    case types.EDIT_SCHEDULE: {
      return formatUrl({ id, template: urlConstants.RESOURCE_SCHEDULE });
    }

    case types.DELETE_SCHEDULE: {
      return formatUrl({ id, template: urlConstants.RESOURCE_SCHEDULE });
    }

    case types.GET_CUSTOMER_ORGANIZATIONS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ORGANIZATIONS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.GET_CUSTOMER_ORGANIZATION: {
      return formatUrl({
        id,
        template: urlConstants.GET_CUSTOMER_ORGANIZATION,
        version: urlConstants.URL_VERSION_2,
      });
    }

    case types.GET_PARTNERS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ORGANIZATIONS,
        queryOptions: {
          filter: { field: 'Type', operator: 'eq', value: "'Provider'" },
          ...queryOptions,
        },
      });
    }

    case types.GET_PROVIDERS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ORGANIZATIONS,
        queryOptions: {
          filter: { field: 'Type', operator: 'eq', value: "'Provider'" },
          ...queryOptions,
        },
      });
    }

    case types.CHANGE_ORGANIZATION_CONTEXT: {
      return formatUrl({
        id,
        path: urlConstants.RESOURCE_ACCOUNT_ORGANIZATIONS,
        queryOptions,
      });
    }

    case types.GET_USER_PROFILE: {
      return formatUrl({ path: urlConstants.RESOURCE_USER_PROFILE });
    }

    case types.EDIT_USER_PROFILE: {
      return formatUrl({ path: urlConstants.RESOURCE_USER_PROFILE });
    }

    case types.CHANGE_PASSWORD: {
      return formatUrl({ path: urlConstants.CHANGE_PASSWORD });
    }

    case types.VALIDATE_PASSWORD: {
      return formatUrl({ path: urlConstants.VALIDATE_PASSWORD });
    }

    case types.GET_LINKS: {
      return formatUrl({ path: urlConstants.RESOURCE_LINKS });
    }

    case types.GET_AI_KEY: {
      return formatUrl({ path: urlConstants.GET_AI_KEY });
    }

    case types.NOTIFICATION_CALLBACK: {
      return formatUrl({
        id,
        path: urlConstants.NOTIFICATION_CALLBACK,
        ...idSegments,
      });
    }

    case types.GET_VIEWS:
    case types.CREATE_VIEW: {
      return formatUrl({ path: urlConstants.RESOURCE_VIEWS });
    }

    case types.GET_VIEW:
    case types.EDIT_VIEW:
    case types.DELETE_VIEW: {
      return formatUrl({ id, path: urlConstants.RESOURCE_VIEWS });
    }

    case types.GET_CONTACTS: {
      return formatUrl({
        path: urlConstants.RESOURCE_CONTACTS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.GET_CONTACT: {
      return formatUrl({
        id,
        path: urlConstants.RESOURCE_CONTACTS,
        queryOptions: { ...queryOptions },
      });
    }

    case types.ADD_CONTACT: {
      return formatUrl({ path: urlConstants.RESOURCE_CONTACTS });
    }

    case types.EDIT_CONTACT:
    case types.DELETE_CONTACT: {
      return formatUrl({ id, path: urlConstants.RESOURCE_CONTACTS });
    }

    case types.GET_GATEWAY_GENERAL_SETTINGS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
            type: GATEWAY_SETTING_TYPE_GENERAL,
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.UPDATE_GATEWAY_SPEAKER_SETTINGS:
    case types.GET_GATEWAY_SPEAKER_SETTINGS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
            type: GATEWAY_SETTING_TYPE_SPEAKER,
          },
        },
        template: urlConstants.RESOURCE_GATEWAY_CAMERA_SETTINGS,
      });
    }

    case types.GET_CONTACT_BY_LOCATION: {
      return formatUrl({
        id,
        queryOptions: { ...queryOptions },
        template: urlConstants.GET_CONTACT_BY_LOCATION,
      });
    }

    case types.CAMERA_VIDEO_EXPORT: {
      return formatUrl({
        ids: {
          cameraId,
          id,
        },
        template: urlConstants.CAMERA_VIDEO_EXPORT,
        ...idSegments,
      });
    }
    case types.CAMERA_VIDEO_GET_STREAM: {
      return formatUrl({
        ids: {
          cameraId,
          exportId: queryOptions.exportId,
          id,
        },
        template: urlConstants.CAMERA_VIDEO_GET_STREAM,
        ...idSegments,
      });
    }

    case types.ENABLE_TAMPER_EVENTS: {
      return formatUrl({
        ids: { id },
        template: urlConstants.ENABLE_TAMPER_EVENTS,
      });
    }

    case types.DISABLE_TAMPER_EVENTS: {
      return formatUrl({
        ids: { id },
        template: urlConstants.DISABLE_TAMPER_EVENTS,
      });
    }

    case types.PTZ_DRAG_TO_ZOOM: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_PAN_TILT_ZOOM,
      });
    }

    case types.CREATE_PTZ_PRESET:
    case types.UPDATE_PTZ_PRESET:
    case types.DELETE_PTZ_PRESET: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_PTZ_PRESET,
      });
    }

    case types.SELECT_PTZ_PRESET: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_UPDATE_PTZ_PRESET,
      });
    }

    case types.GET_PTZ_PRESETS: {
      return formatUrl({
        id,
        queryOptions,
        template: urlConstants.GATEWAY_PTZ_PRESET,
      });
    }

    case types.GO_TO_HOME_PTZ_PRESET: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_PTZ_PRESET_HOME,
      });
    }

    case types.ADD_PTZ_TOUR:
    case types.UPDATE_PTZ_TOUR: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_CAMERA_PTZ_TOUR,
      });
    }

    case types.GET_PTZ_TOURS: {
      return formatUrl({
        id,
        queryOptions: {
          params: {
            id: cameraId,
          },
        },
        template: urlConstants.GATEWAY_CAMERA_PTZ_TOUR,
      });
    }

    case types.START_PTZ_TOUR: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_START_PTZ_TOUR,
      });
    }

    case types.STOP_PTZ_TOUR: {
      return formatUrl({
        id,
        template: urlConstants.GATEWAY_STOP_PTZ_TOUR,
      });
    }

    case types.GET_BOOKMARKS: {
      return formatUrl({
        path: urlConstants.RESOURCE_ALL_BOOKMARKS,
        queryOptions: { ...queryOptions },
        ...idSegments,
      });
    }

    case types.GET_RECORDING_SETTINGS:
    case types.SAVE_RECORDING_SETTINGS: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_RECORDING_SETTINGS,
      });
    }

    case types.GET_SERVER_TIME_ZONE_LIST: {
      return formatUrl({
        id,
        template: urlConstants.GET_SERVER_TIME_ZONE_LIST,
      });
    }

    case types.VALIDATE_CONNECTION_STRING: {
      return formatUrl({
        path: urlConstants.VALIDATE_CONNECTION_STRING,
      });
    }

    case types.DELETE_ORGANIZATION: {
      return formatUrl({
        id,
        template: urlConstants.RESOURCE_DELETE_ORGANIZATION,
        version: urlConstants.URL_VERSION_1,
      });
    }

    case types.GET_HMS_CONFIG: {
      return formatUrl({ path: urlConstants.PATH_HMS_CONFIG });
    }

    case types.GET_HMS_JWT: {
      return formatUrl({
        orgId: id,
        template: urlConstants.PATH_HMS_JWT,
      });
    }

    case types.GET_PENDING_SITE_REQUESTS: {
      return formatUrl({
        template: urlConstants.GET_PENDING_SITE_REQUESTS,
        version: urlConstants.URL_VERSION_2,
      });
    }

    case types.PENDING_SITE_REQUESTS_ACCEPT: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.PENDING_SITE_REQUESTS_ACCEPT,
        version: urlConstants.URL_VERSION_2,
      });
    }

    case types.PENDING_SITE_REQUESTS_DECLINE: {
      return formatUrl({
        id,
        orgId: idSegments.orgId,
        template: urlConstants.PENDING_SITE_REQUESTS_DECLINE,
        version: urlConstants.URL_VERSION_2,
      });
    }

    default:
      return null;
  }
}
