import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Icon from './Icon';
import { layoutIconFillColor } from './constants';
import { optionStyle, optionStyleSelected } from './styles.css';

class ViewLayoutOption extends Component {
  handleMouseDown = event => {
    const { onSelect, option } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  };

  handleMouseEnter = event => {
    const { onFocus, option } = this.props;
    onFocus(option, event);
  };

  handleMouseMove = event => {
    const { isFocused, onFocus, option } = this.props;
    if (isFocused) return;
    onFocus(option, event);
  };

  render() {
    const { handleMouseDown, handleMouseEnter, handleMouseMove, props } = this;
    return (
      <div
        className={props.isSelected ? optionStyleSelected : optionStyle}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        role="button"
        tabIndex="0"
      >
        <Icon
          params={props.value || props.option}
        />
        {props.children}
      </div>
    );
  }
}

ViewLayoutOption.propTypes = {
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.string.isRequired,
};

ViewLayoutOption.defaultProps = {
  isFocused: false,
  onFocus() {},
  onSelect() {},
};

export default ViewLayoutOption;
