import initialState from 'store/initialState';
import * as types from 'constants/ActionTypes';

export default function microFrontendReducer(
  state = initialState().microFrontend,
  action = {},
) {
  switch (action.type) {
    case types.REDIRECT_TO_HMS_ROOT: {
      return {
        ...state,
        hms: {
          ...state.hms,
          redirectToHMSRoot: true,
        },
      };
    }
    case types.STOP_REDIRECTING_TO_HMS_ROOT: {
      return {
        ...state,
        hms: {
          ...state.hms,
          redirectToHMSRoot: false,
        },
      };
    }
    default:
      return state || initialState().microFrontend;
  }
}
