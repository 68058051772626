import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconLiveVideo = props => {
  return (
    <svg
      id={props.id}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 25 20"
      fill={props.fill}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(1.000000, 1.000000)">
            <path
              d="M18.9530398,6.98707708 L18.9255586,6.13085937 L18.9255586,2 C18.9255586,0.8954305 18.0301281,-1.53517607e-15 16.9255586,-1.33226763e-15 L16.9255586,-6.66188025e-16 L2,-6.66188025e-16 L2,-4.4408921e-16 C0.8954305,-2.41180773e-16 -1.3527075e-16,0.8954305 0,2 L-6.66133815e-16,16 C1.43124652e-15,17.1045695 0.8954305,18 2,18 L2,18 L16.9255586,18 C18.0301281,18 18.9255586,17.1045695 18.9255586,16 L18.9255586,11.1689453 L18.9540565,10.1281441 L23,13.3630677 L23,3.75134053 L18.9530398,6.98707708 Z"
              stroke={props.fill}
              strokeWidth="2"
            />
            <path
              d="M0.888,6.288 L0.888,12 L4.928,12 L4.928,10.944 L2.144,10.944 L2.144,6.288 L0.888,6.288 Z M5.632,6.288 L5.632,12 L6.888,12 L6.888,6.288 L5.632,6.288 Z M10.648,12 L12.544,6.288 L11.248,6.288 L9.96,10.304 L9.944,10.304 L8.672,6.288 L7.384,6.288 L9.232,12 L10.648,12 Z M13.032,6.288 L13.032,12 L17.368,12 L17.368,10.944 L14.288,10.944 L14.288,9.544 L17.056,9.544 L17.056,8.568 L14.288,8.568 L14.288,7.344 L17.304,7.344 L17.304,6.288 L13.032,6.288 Z"
              fill={props.fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconLiveVideo.defaultProps = {
  className: null,
  id: 'IconLiveVideo',
  width: '30px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
  title: 'Live video',
};

export default IconLiveVideo;
