import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomFocusInMore = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1196.000000, -447.000000)"
      >
        <path
          d="M1196.41459,454 C1196.36274,453.700769 1196.45236,453.38182 1196.68342,453.150758 L1202.15076,447.683418 C1202.5282,447.305976 1203.14015,447.305976 1203.51759,447.683418 C1203.89503,448.060859 1203.89503,448.672811 1203.51759,449.050253 L1198.56785,454 L1203.51759,458.949747 C1203.89503,459.327189 1203.89503,459.939141 1203.51759,460.316582 C1203.14015,460.694024 1202.5282,460.694024 1202.15076,460.316582 L1196.68342,454.849242 C1196.45236,454.61818 1196.36274,454.299231 1196.41459,454 Z M1202.21358,454 C1202.16173,453.700769 1202.25135,453.38182 1202.48241,453.150758 L1207.94975,447.683418 C1208.32719,447.305976 1208.93914,447.305976 1209.31658,447.683418 C1209.69402,448.060859 1209.69402,448.672811 1209.31658,449.050253 L1204.36684,454 L1209.31658,458.949747 C1209.69402,459.327189 1209.69402,459.939141 1209.31658,460.316582 C1208.93914,460.694024 1208.32719,460.694024 1207.94975,460.316582 L1202.48241,454.849242 C1202.25135,454.61818 1202.16173,454.299231 1202.21358,454 Z"
          id="icon-btn-zoomfocus-in-more-v2"
          fill={props.fill}
          transform="translate(1203.000000, 454.000000) rotate(180.000000) translate(-1203.000000, -454.000000) "
        />
      </g>
    </svg>
  );
};

IconBtnZoomFocusInMore.defaultProps = {
  className: null,
  width: '14px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomFocusInMore;
