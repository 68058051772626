import React, { Component } from 'react';
import { AllHtmlEntities } from 'html-entities';
import moment from 'moment-timezone-all';
import { DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE } from 'constants/app.js';
import PropTypes from 'prop-types';
import * as messageClass from './Message.css';
import { IconX } from 'icons';

// Constants
import * as messageConstants from './constants';

// Utils
import * as cookieUtils from 'util/cookies';
import * as cookieNames from 'constants/cookieNames';

class Message extends Component {
  constructor() {
    super();

    this.closeMessage = this.closeMessage.bind(this);
    this.state = {
      isDismissed: false,
    };
  }

  closeMessage = () => {
    const { id } = this.props;
    this.setState({
      isDismissed: true,
    });
    cookieUtils.setCookie(
      `${cookieNames.DISMISS_BANNER_COOKIE}_${id}`,
      'true',
      messageConstants.NOTICE_COOKIE_EXPIRE_IN_DAYS,
    );
  };

  noticeTimeWindow = dates => {
    const startNotice = moment(
      dates.start,
      DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
    );
    const endNotice = moment(
      dates.end,
      DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE,
    );
    const today = moment(
      moment(new Date()).format(DATETIME_FORMAT_ALARM_DASHBOARD_WITH_ZONE),
    );
    if (today <= startNotice && today >= endNotice) {
      this.setState({
        isDismissed: true,
      });
    }
  };

  buildClassName = severity => {
    switch (severity) {
      case 'Informational':
        return 'messageNormal';
      case 'Warning':
        return 'messageCaution';
      case 'Critical':
        return 'messageNegative';
      default:
        return null;
    }
  };

  render() {
    const { message: messageProp, severity } = this.props;
    const { isDismissed } = this.state;
    const entities = new AllHtmlEntities();
    const message = entities.decode(messageProp);
    return (
      <div
        className={`${messageClass.message} ${
          messageClass[`${this.buildClassName(severity)}`]
        } ${isDismissed ? messageClass.messageDisabled : ''}`}
      >
        <div>
          <span dangerouslySetInnerHTML={{ __html: `${message}` }} />
          <div
            className={messageClass.messageClose}
            onClick={this.closeMessage}
            onKeyPress={this.closeMessage}
            role="button"
            tabIndex="0"
          >
            <IconX height="20px" />
          </div>
        </div>
      </div>
    );
  }
}

Message.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Message;
