exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formFieldReadOnly___3Zqlo {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  height: 30px;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.formFieldReadOnly___3Zqlo > input:disabled {\r\n  background-color: transparent !important;\r\n  border: none;\r\n  font-weight: normal;\r\n  word-break: break-word;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  color: #575757;\r\n  margin-left: 3px;\r\n  padding-bottom: 5px;\r\n}\r\n\r\n.subHeader___1R945 {\r\n  padding-left: 16px;\r\n  font-weight: 400;\r\n}\r\n\r\n.codeStatus___1a2Bg {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.validationIcons___1NwRy {\r\n  padding-left: 5px;\r\n  margin-top: 24px;\r\n}\r\n.customFormGroup___35F7M {\r\n  width: 50%;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/AccountManagement/sites/ProviderForm/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,aAAa;EACb,qBAAqB;CACtB;;AAED;EACE,yCAAyC;EACzC,aAAa;EACb,oBAAoB;EACpB,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,oBAAoB;CACrB;;AAED;EACE,mBAAmB;EACnB,iBAAiB;CAClB;;AAED;EACE,qBAAqB;EACrB,cAAc;CACf;;AAED;EACE,kBAAkB;EAClB,iBAAiB;CAClB;AACD;EACE,WAAW;CACZ","file":"styles.css","sourcesContent":[".formFieldReadOnly {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  height: 30px;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n.formFieldReadOnly > input:disabled {\r\n  background-color: transparent !important;\r\n  border: none;\r\n  font-weight: normal;\r\n  word-break: break-word;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  color: #575757;\r\n  margin-left: 3px;\r\n  padding-bottom: 5px;\r\n}\r\n\r\n.subHeader {\r\n  padding-left: 16px;\r\n  font-weight: 400;\r\n}\r\n\r\n.codeStatus {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.validationIcons {\r\n  padding-left: 5px;\r\n  margin-top: 24px;\r\n}\r\n.customFormGroup {\r\n  width: 50%;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formFieldReadOnly": "formFieldReadOnly___3Zqlo",
	"subHeader": "subHeader___1R945",
	"codeStatus": "codeStatus___1a2Bg",
	"validationIcons": "validationIcons___1NwRy",
	"customFormGroup": "customFormGroup___35F7M"
};