import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconGotoVideo = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 25 20"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop" transform="translate(-567.000000, -495.000000)">
          <g id="btn-goto-video" transform="translate(567.000000, 495.000000)">
            <g transform="translate(1.000000, 1.000000)">
              <circle
                id="Oval-2"
                stroke={props.fill}
                strokeWidth="2"
                cx="7.5"
                cy="7.5"
                r="3.5"
              />
              <path
                d="M2,-6.66188025e-16 L16,-6.66188025e-16 L16,-8.8817842e-16 C17.1045695,-1.09108686e-15 18,0.8954305 18,2 L18,6.13085937 L18.0708008,8.45019531 L18,11.1689453 L18,16 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 L2,18 C0.8954305,18 1.3527075e-16,17.1045695 0,16 L0,2 L0,2 C-1.3527075e-16,0.8954305 0.8954305,-2.41180773e-16 2,-4.4408921e-16 Z"
                id="Rectangle-5"
                stroke={props.fill}
                strokeWidth="2"
              />
              <polyline
                id="Path-3"
                stroke={props.fill}
                strokeWidth="2"
                points="18.0531637 7.70657071 23 3.75134053 23 13.3630677 18.0112892 9.37435694"
              />
              <rect
                id="Rectangle-4"
                fill={props.fill}
                transform="translate(11.828427, 11.828427) rotate(135.000000) translate(-11.828427, -11.828427) "
                x="10.8284271"
                y="8.82842712"
                width="2"
                height="6"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconGotoVideo.defaultProps = {
  className: null,
  width: '25px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconGotoVideo;
