import React from 'react';
import PropTypes from 'prop-types';

import { withLocalize } from 'react-localize-redux';

import { badgeItems, appStoreBadge } from './badges.styles.css';

const APPLE_STORE_URL =
  'https://itunes.apple.com/us/app/avigilon-blue/id1228673827?mt=8';

const AppStoreBadge = props => {
  const { activeLanguage, translate } = props;
  let imgSrc;
  try {
    imgSrc = require(`../../images/storesBadges/appStore/${
      activeLanguage.code
    }.png`);
  } catch (error) {
    imgSrc = require('../../images/storesBadges/appStore/en.png');
  }
  return (
    <div className={badgeItems}>
      <a href={APPLE_STORE_URL} target="_blank">
        <img
          className={appStoreBadge}
          src={imgSrc}
          alt={translate('LOGIN.APPLE_BADGE_TITLE')}
          title={translate('LOGIN.APPLE_BADGE_TITLE')}
        />
      </a>
    </div>
  );
};

AppStoreBadge.propTypes = {
  activeLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(AppStoreBadge);
