import PropTypes from 'prop-types';
import React from 'react';
import { AccordionItem } from 'lib';
import * as oasisStyles from './Oasis.css';

function OasisModule({
  actionButtons,
  children,
  indicatorValue,
  moduleId,
  theme,
  title,
  updateModuleState,
}) {
  const moduleStates =
    (window.localStorage &&
      localStorage.oasisModuleState &&
      JSON.parse(localStorage.oasisModuleState)) ||
    {};
  const moduleState = moduleStates[moduleId] || {};

  function persistModuleState(propName, newValue) {
    updateModuleState(moduleId, propName, newValue);
  }
  function updateExpansionState(newState) {
    avoLog(
      `Updating Expansion state of ${moduleId} to ${
        newState ? 'Expanded' : 'Collapsed'
      }`,
    );
    persistModuleState('expanded', newState);
  }
  return moduleState.active ? (
    <AccordionItem
      expansionCallback={updateExpansionState}
      isExpanded={moduleState.expanded || false}
      rightItems={[
        actionButtons.map(button => {
          return button;
        }),
        indicatorValue ? (
          <div key="indicator" className={oasisStyles.indicator}>
            {indicatorValue}
          </div>
        ) : null,
      ]}
      theme={theme}
      title={title}
    >
      <div className={oasisStyles.oasisList}>{children}</div>
    </AccordionItem>
  ) : null;
}
OasisModule.defaultProps = {
  actionButtons: [],
  indicatorValue: null,
  theme: 'gray',
  title: 'Oasis Module',
};
OasisModule.propTypes = {
  actionButtons: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.node.isRequired,
  indicatorValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  moduleId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  title: PropTypes.string,
  updateModuleState: PropTypes.func.isRequired,
};
export default OasisModule;
