export const fieldSummary = 'summary';
export const formName = 'contactForm';
export const requiredFields = ['Name', 'PhoneNumber', 'Sites'];
export const fieldId = 'Id';
export const fieldName = 'Name';
export const fieldPhoneNumber = 'PhoneNumber';
export const fieldSites = 'Sites';
export const idBtnContactSubmit = 'ContactSubmitBtn';
export const idSiteDropdown = 'siteDropdown';
export const idContactName = 'ContactName';
export const idPhone = 'Phone';
export const idSite = 'Site';
