import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from 'components';
import { Button, GroupLayout } from 'lib';
import moment from 'moment-timezone-all';
import onClickOutside from 'react-onclickoutside';

import { Translate } from 'react-localize-redux';

// Constants
import ARM_DISARM_RADIO_OPTIONS from './constants';

// Styles
import { alarmRadioWrap, titleWrap } from './ArmDisarmCamera.css';
import { logHeader } from '../../containers/Notifications/EditNotification/styles.css';

const ArmDisarmCameraMenu = props => {
  const { disarmed, onCancel, onSave } = props;
  // select first radio button - Thirty Minutes
  const [duration, setDuration] = useState(ARM_DISARM_RADIO_OPTIONS[0].value);

  const onDisarmChange = e => {
    setDuration(e.target.value);
  };

  const handleSave = () => {
    // don't pass duration if Indefinitely is selected
    const rearmDateTime =
      duration !== ARM_DISARM_RADIO_OPTIONS[3].value
        ? moment()
            .add(parseFloat(duration), 'hours')
            .format()
        : '';
    onSave(rearmDateTime);
  };

  ArmDisarmCameraMenu.handleClickOutside = () => onCancel();

  return (
    <div>
      {disarmed ? (
        <>
          <div className={`${logHeader} ${titleWrap}`}>
            <label>
              <Translate id="ALARMS.ARM_DISARM_CAMERA.ARM_ICON_TITLE" />
            </label>
          </div>
          <div>
            <Translate id="ALARMS.ARM_DISARM_CAMERA.REARM_LABEL" />
          </div>
        </>
      ) : (
        <div>
          <div className={alarmRadioWrap}>
            <RadioGroup
              labelTranslateId="ALARMS.ARM_DISARM_CAMERA.DISARM_ICON_TITLE"
              onChange={onDisarmChange}
              options={ARM_DISARM_RADIO_OPTIONS}
              value={duration}
              verticalLayout
            />
          </div>
        </div>
      )}
      <GroupLayout verticalSpacing="none">
        <Button
          key="generalSettingsButtonRevert"
          buttonType="primary"
          id="generalSettingsButtonRevert"
          inputType="button"
          onClick={onCancel}
          text={<Translate id="BUTTONS.CANCEL" />}
        />
        <Button
          key="generalSettingsButtonSave"
          buttonType="primary"
          id="generalSettingsButtonSave"
          inputType="button"
          onClick={handleSave}
          text={
            disarmed ? (
              <Translate id="ALARMS.ARM_DISARM_CAMERA.REARM" />
            ) : (
              <Translate id="BUTTONS.SAVE" />
            )
          }
        />
      </GroupLayout>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => ArmDisarmCameraMenu.handleClickOutside,
};

ArmDisarmCameraMenu.propTypes = {
  disarmed: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default onClickOutside(ArmDisarmCameraMenu, clickOutsideConfig);
