import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const commonSize = 46;
const IconPtzArrow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 92 92"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <circle id="path-1" cx={commonSize} cy={commonSize} r={commonSize} />
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="objectBoundingBox"
          x="0"
          y="0"
          width={commonSize}
          height={commonSize}
          fill={props.fillColor}
        >
          <use xlinkHref="#path-1" />
        </mask>
        <filter
          x="-20.0%"
          y="-20.0%"
          width="140.0%"
          height="140.0%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        id="PTZ-UI-FINAL"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-PTZ---Preset-Selected"
          transform="translate(-381.000000, -185.000000)"
        >
          <g
            id="Drag-to-Pan-Symbol"
            transform="translate(381.000000, 185.000000)"
          >
            <use
              id="Oval"
              stroke={props.fillBackgroundColor}
              mask="url(#mask-2)"
              strokeWidth="4"
              fillOpacity="0.305281929"
              fill={props.fillBackgroundColor}
              strokeDasharray="6,2"
              xlinkHref="#path-1"
            />
            <g
              id="Drag-to-pan"
              filter="url(#filter-3)"
              opacity="0.685360863"
              strokeWidth="1"
              transform="translate(16.000000, 16.000000)"
              fill={props.fillColor}
            >
              <polygon
                id="Path"
                points="18.5454545 16.3636364 30 0 41.4545455 16.3636364"
              />
              <polygon
                id="Path"
                points="41.4545455 43.6363636 30 60 18.5454545 43.6363636"
              />
              <polygon
                id="Path"
                points="16.3636364 41.4545455 0 30 16.3636364 18.5454545"
              />
              <polygon
                id="Path"
                points="43.6363636 18.5454545 60 30 43.6363636 41.4545455"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconPtzArrow.defaultProps = {
  className: null,
  width: '46px',
  height: '46px',
  fillColor: '#FFFFFF',
  fillBackgroundColor: '#000000',
};

export default IconPtzArrow;
