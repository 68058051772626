import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { Button, Icon } from 'lib';

// Constants
import { ACTION_IMAGES } from 'constants/app';
import * as IconConsts from 'lib/ListNav/constants';
import { IC_TRASH } from 'constants/iconNames';

// Styles
import { actionButton, centerButton } from './NavControls.css';

function DeleteIcon(props) {
  const { actionImage, bulkActionsTable, deleteActive, onDeleteClick } = props;
  const { bulkActionIdsFor } = useContext(BulkActionContext);
  const idsToDelete = bulkActionIdsFor(bulkActionsTable);
  let resultComponent = null;
  switch (actionImage) {
    case ACTION_IMAGES.LOCATION:
    case ACTION_IMAGES.USER:
    case ACTION_IMAGES.CONTACT:
    case ACTION_IMAGES.SCHEDULE:
    case ACTION_IMAGES.SUBSCRIBER:
    case ACTION_IMAGES.DEALER:
      resultComponent = (
        <>
          <Button
            className={`${actionButton} ${centerButton}`}
            disabled={!deleteActive}
            icon
            id={IconConsts.idDeleteBtn}
            onClick={() => {
              IconConsts.getClickHandler(
                idsToDelete.length > 0,
                onDeleteClick,
              )(idsToDelete);
            }}
          >
            <Icon id={IC_TRASH} title="BUTTONS.DELETE" />
          </Button>
        </>
      );
      break;
    default:
      resultComponent = '';
  }
  return resultComponent;
}

DeleteIcon.defaultProps = {
  actionImage: '',
  deleteActive: null,
  onDeleteClick() {},
};

DeleteIcon.propTypes = {
  actionImage: PropTypes.string,
  deleteActive: PropTypes.bool,
  onDeleteClick: PropTypes.func,
};

export default DeleteIcon;
