import React from 'react';
import { PropTypes } from 'prop-types';
import { Translate } from 'react-localize-redux';

import { modalContentSuccess, success, buttonContainer } from './styles.css';

const SuccessModal = ({ message, reason, hideModal }) => (
  <div className={modalContentSuccess}>
    <div className={success}>{message}</div>
    <div>
      {reason && (
        <Translate
          id="GENERIC_MESSAGES.SUCCESS_MODAL_REASON_MESSAGE"
          data={{ reason }}
        />
      )}
    </div>
    <div className={buttonContainer}>
      <button onClick={hideModal} type="button">
        <Translate id="BUTTONS.OK" />
      </button>
    </div>
  </div>
);

SuccessModal.defaultProps = {
  hideModal() {},
  reason: '',
  message: '',
};

SuccessModal.propTypes = {
  hideModal: PropTypes.func,
  reason: PropTypes.string,
  message: PropTypes.string,
};

export default SuccessModal;
