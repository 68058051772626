// perms for navigation
import { NAVIGATION_TABS } from 'constants/app';
import { getEntitlementValues } from 'util/getEntitlementValues';
import {
  PATH_ACCOUNTS,
  PATH_ALARMS,
  PATH_BOOKMARKS,
  PATH_DASHBOARD,
  PATH_DEVICES,
  PATH_HEALTH_MONITOR,
  PATH_REPORTS,
  PATH_VIEWS,
} from 'constants/urlPaths';
import * as PERMISSIONS from 'constants/PermissionTypes';
import {
  tenantSubtypeStrings,
  tenantTranslatedLabelsId,
  tenantTypeStrings,
} from './tenantTypes';
import { parseUserRoles, ROLES as userRoleStrings } from './userRoles';

// Used only by the user reducer to calculate permissions in store
// or by other functions in this file
export const hasPermission = (permissionType, profile, currentOrganization) => {
  const profileTenantType =
    profile && (profile.CustomerTenantType || profile.TenantType);
  if (!profileTenantType) return false;
  const tenantType = profileTenantType.toLowerCase(); // Currently proxied as
  const rootTenantType = profile.TenantType && profile.TenantType.toLowerCase(); // Base user is
  const { CloudOwner, Provider, Subscriber } = tenantTypeStrings;
  const subscriber = Subscriber.toLowerCase();
  const cloudOwner = CloudOwner.toLowerCase();
  const provider = Provider.toLowerCase();
  // User role
  const userRole = parseUserRoles(profile.Role);
  const { Administrator, Manager, Responder } = userRoleStrings;
  const entitlementValues = getEntitlementValues(currentOrganization);

  switch (permissionType) {
    case PERMISSIONS.CAN_PROXY_AS_CUSTOMERS: {
      return rootTenantType !== subscriber;
    }
    case PERMISSIONS.CAN_VIEW_CUSTOMERS: {
      return tenantType !== subscriber;
    }
    case PERMISSIONS.CAN_HAVE_DEALER_CUSTOMERS: {
      return tenantType === provider || tenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_VIEW_CONTACTS: {
      return tenantType === subscriber && entitlementValues.BLUE;
    }
    case PERMISSIONS.CAN_VIEW_SCHEDULES: {
      return tenantType === subscriber && entitlementValues.BLUE;
    }
    case PERMISSIONS.CAN_VIEW_SITES: {
      return tenantType === subscriber;
    }
    case PERMISSIONS.CAN_CREATE_ORG:
    case PERMISSIONS.CAN_EDIT_ORG:
    case PERMISSIONS.CAN_DELETE_ORG:
    case PERMISSIONS.CAN_VIEW_ORG_CONTROLS: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_EDIT_GENERAL_INFO: {
      return tenantType === subscriber && entitlementValues.SELF_PROVISIONED;
    }
    case PERMISSIONS.CAN_SUSPEND_SITE: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_BULK_DELETE_SITES: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_EDIT_SITE_BILLING_INFO: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_EDIT_PROXY_SITE_ACCESS: {
      return rootTenantType === subscriber;
    }
    case PERMISSIONS.CAN_EDIT_SITE_ACCESS: {
      return tenantType === subscriber;
    }
    case PERMISSIONS.CAN_ENABLE_HEALTH_MONITORING: {
      return rootTenantType === provider;
    }
    case PERMISSIONS.CAN_EDIT_SUBSCRIPTION: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_CHANGE_PROVIDER: {
      return rootTenantType === cloudOwner || rootTenantType === subscriber;
    }
    case PERMISSIONS.CAN_GET_DEVICE_LOGS: {
      return (
        rootTenantType === cloudOwner &&
        (userRole === Manager || userRole === Administrator)
      );
    }
    case PERMISSIONS.CAN_HAVE_NON_ADMIN_USERS: {
      return tenantType !== cloudOwner;
    }
    case PERMISSIONS.CAN_VIEW_SCHEDULES_COLUMNS: {
      return tenantType !== cloudOwner && tenantType !== provider;
    }
    case PERMISSIONS.SELF_SERVICE: {
      if (rootTenantType === provider) {
        return 2;
      }
      if (rootTenantType === cloudOwner) {
        return 1;
      }
      return 4;
    }
    case PERMISSIONS.CAN_EDIT_EMAIL_NOTIFICATION_PREFERENCES: {
      return [Manager, Administrator, Responder].includes(userRole);
    }
    // case for displaying billing/order ID columns in sites (location) table
    case PERMISSIONS.CAN_VIEW_BILLING_ORDER_ID: {
      return rootTenantType === cloudOwner;
    }
    case PERMISSIONS.CAN_EDIT_PTZ_PRESETS_AND_TOURS:
    case PERMISSIONS.CAN_EDIT_SERVER_TIME_ZONE: {
      return userRole === Manager || userRole === Administrator;
    }
    case PERMISSIONS.CAN_DELETE_SUSPENDED_DEVICE: {
      return (
        tenantType !== subscriber ||
        rootTenantType === cloudOwner ||
        rootTenantType === provider
      );
    }

    default: {
      return false;
    }
  }
};

export const canViewTabs = (profile, currentOrganization) => {
  // This MUST AGREE with getUserHomepage; if a user has a homepage they are not allowed to see,
  // an infinite redirect loop results
  let tabs = [];
  const tenantType = profile.CustomerTenantType || profile.TenantType;
  const profileTenantType = profile.TenantType;
  const tenantEntitlementValues = getEntitlementValues(currentOrganization);
  const role =
    profile.Role && profile.Role.length > 0 && profile.Role.toLowerCase();
  const { Administrator, Manager, Responder, Viewer } = userRoleStrings;
  const viewer = Viewer.toLowerCase();
  const manager = Manager.toLowerCase();
  const administrator = Administrator.toLowerCase();
  const responder = Responder.toLowerCase();

  if (role) {
    // Tabs are added based on role
    if (role.includes(viewer)) {
      tabs = tabs.concat([NAVIGATION_TABS.VIEWS, NAVIGATION_TABS.BOOKMARKS]);
    }
    if (role.includes(responder)) {
      tabs = tabs.concat([NAVIGATION_TABS.ALARMS]);
    }
    if (role.includes(manager)) {
      tabs = tabs.concat([
        NAVIGATION_TABS.DEVICES,
        NAVIGATION_TABS.HEALTH_MONITOR,
        NAVIGATION_TABS.REPORTS,
      ]);
    }
    if (role.includes(administrator)) {
      tabs = tabs.concat([NAVIGATION_TABS.DASHBOARD, NAVIGATION_TABS.ACCOUNTS]);
    }
  }

  if (tenantType) {
    if (tenantType.includes(tenantTypeStrings.CloudOwner)) {
      // Role is ignored for cloud owners
      tabs = [NAVIGATION_TABS.ACCOUNTS];
    } else if (
      tenantType.includes(tenantTypeStrings.Dealer) ||
      tenantType.includes(tenantTypeStrings.Provider)
    ) {
      // Tabs are filtered based on org type
      const dealerTabs = [
        NAVIGATION_TABS.ACCOUNTS,
        NAVIGATION_TABS.DASHBOARD,
        NAVIGATION_TABS.ALARMS,
        NAVIGATION_TABS.HEALTH_MONITOR,
      ];
      tabs = tabs.filter(tab => dealerTabs.includes(tab));
    } else {
      // Andre Says: For now, only dealers see dashboard tab.
      tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.DASHBOARD);
    }
  }

  // MVAAS-22003 - Dealer Dashboard tab should not be accessible nor visible for Service Provider of type PPP
  if (
    currentOrganization &&
    currentOrganization.SubType === tenantSubtypeStrings.publicPrivatePartner
  ) {
    tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.DASHBOARD);
  }

  if (profileTenantType) {
    if (
      profileTenantType.includes(tenantTypeStrings.Dealer) ||
      profileTenantType.includes(tenantTypeStrings.Provider)
    ) {
      // Reports tab should not be visible for Dealers or Providers
      tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.REPORTS);
    }
  }

  if (!tenantEntitlementValues.HMS) {
    tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.HEALTH_MONITOR);
  }
  if (!tenantEntitlementValues.REPORTS) {
    tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.REPORTS);
  }
  // As a work around for MVAAS-23171 always enable notifications tab for provider
  if (
    tenantType &&
    !tenantType.includes(tenantTypeStrings.Provider) &&
    !tenantEntitlementValues.BLUE
  ) {
    tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.ALARMS);
  }

  if (!tenantEntitlementValues.BLUE) {
    tabs = tabs.filter(tab => tab !== NAVIGATION_TABS.DEVICES);
  }

  return tabs.length > 0 ? tabs : [NAVIGATION_TABS.VIEWS];
};

// Account management tabs
export const accountTabAccess = profile => {
  let tabAccess = {
    all: true,
    centralStations: false,
    contacts: true,
    dealers: false,
    formats: true,
    general: true,
    health: true,
    monitoring: false,
    password: true,
    personalInfo: true,
    schedules: true,
    security: true,
    servicePlans: false,
    siteConfigurations: false,
    sites: true,
    subscribers: false,
    summary: true,
    users: true,
  };
  const tenantType = profile.CustomerTenantType
    ? profile.CustomerTenantType
    : profile.TenantType;

  const isProvider =
    tenantType &&
    (tenantType.includes(tenantTypeStrings.Dealer) ||
      tenantType.includes(tenantTypeStrings.Provider));

  if (tenantType && tenantType !== tenantTypeStrings.Subscriber) {
    if (tenantType.includes(tenantTypeStrings.CloudOwner)) {
      tabAccess = {
        ...tabAccess,
        contacts: false,
        dealers: true,
        general: false,
        schedules: false,
        sites: false,
        subscribers: true,
      };
    }
    if (isProvider) {
      tabAccess = {
        ...tabAccess,
        all: null,
        centralStations: isProvider,
        contacts: false,
        dealers: false,
        health: false,
        monitoring: isProvider,
        schedules: false,
        security: false,
        servicePlans: true,
        siteConfigurations: isProvider,
        sites: false,
        subscribers: true,
      };
    }
  }
  return tabAccess;
};

export const getUserHomepage = (
  profile,
  locations = [],
  canViewDevices = false,
  currentOrganization,
) => {
  // The homepage is determined based on visible tabs
  const tabPriorities = [
    // Ascending order of priority for ease of iteration
    NAVIGATION_TABS.VIEWS,
    NAVIGATION_TABS.BOOKMARKS,
    NAVIGATION_TABS.ALARMS,
    NAVIGATION_TABS.DEVICES,
  ];
  if (hasPermission(PERMISSIONS.CAN_PROXY_AS_CUSTOMERS, profile)) {
    tabPriorities.push(NAVIGATION_TABS.ACCOUNTS);
  }
  const tabsToPaths = {
    [NAVIGATION_TABS.ACCOUNTS]: PATH_ACCOUNTS,
    [NAVIGATION_TABS.DEVICES]: PATH_DEVICES,
    [NAVIGATION_TABS.ALARMS]: PATH_ALARMS,
    [NAVIGATION_TABS.VIEWS]: PATH_VIEWS,
    [NAVIGATION_TABS.DASHBOARD]: PATH_DASHBOARD,
    [NAVIGATION_TABS.HEALTH_MONITOR]: PATH_HEALTH_MONITOR,
    [NAVIGATION_TABS.BOOKMARKS]: PATH_BOOKMARKS,
    [NAVIGATION_TABS.REPORTS]: PATH_REPORTS,
  };
  const tabList = canViewTabs(profile, currentOrganization);
  let tab = '';
  tabPriorities.forEach(t => {
    if (tabList.includes(t)) {
      tab = t;
    }
  });
  if (
    tabList.includes(NAVIGATION_TABS.ACCOUNTS) &&
    ((tab === NAVIGATION_TABS.DEVICES && locations && locations.length === 0) ||
      !canViewDevices)
  ) {
    tab = NAVIGATION_TABS.ACCOUNTS;
  }

  return tabsToPaths[tab] || PATH_VIEWS;
};

export const canCreateCustomerTypes = tenantType => {
  // disable ability to create customer of provider type
  // const objProvider = 'Provider';
  const objPartner = {
    label: tenantTranslatedLabelsId.Provider,
    type: tenantTypeStrings.Provider,
  };
  const objCustomer = {
    label: tenantTranslatedLabelsId.Subscriber,
    type: tenantTypeStrings.Subscriber,
  };
  switch (tenantType) {
    case tenantTypeStrings.CloudOwner: {
      return [objPartner, objCustomer];
    }
    case tenantTypeStrings.Provider: {
      return [objPartner, objCustomer];
    }
    case tenantTypeStrings.Partner: {
      return [objCustomer];
    }
    default: {
      return [];
    }
  }
};

export const getOrgIdFromStore = store => {
  // STORE IS READ-ONLY
  // Should be refined and stored in permissions util
  const orgId =
    store.user.profile.CustomerTenantId || store.user.profile.TenantId;
  return orgId;
};
