// Libs
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, withLocalize } from 'react-localize-redux';
import Spinner from 'react-md-spinner';

// Components
import { Toggle } from 'components';
import { Button, Icon, ListView } from 'lib';
import { PageMessage } from 'containers';

// Utils
import { getCameraActualStatus } from 'util/getCameraActualStatus';
import ComparableFirmwareVersion from 'util/ComparableFirmwareVersion';

// Styles
import { IC_ADD } from 'constants/iconNames';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as styles from './styles.css';

// Constants
import * as consts from './constants';
import * as messageTypes from 'constants/MessageTypes';

function DiscoveredCamerasTable(props) {
  const {
    connectDiscoveredCamera,
    discoveredCameras,
    findCameraFormData,
    findUndiscoveredCamerasCallback,
    isFetching,
    isServerDisconnected,
    pageMessageType,
    resetFDSPassword,
    translate,
  } = props;

  const [filterFDSCamera, setFilterFDSCamera] = useState(false);

  const getConnectIconFill = (rowData, isDetails) => {
    if (rowData.ConnectionState !== undefined) {
      return '#DDDDDD';
    }
    if (isDetails) {
      return 'white';
    }
    return 'rgba(0, 0, 0, 0.7)';
  };

  const ROW_ACTIONS = [
    ({rowData, rowIndex }) => {
      return (
        <Button
          icon
          inputType="button"
          onClick={() => {
            if (rowData.factoryDefault) {
              const menuProps = {
                buttonDisabled: false,
                cameraData: discoveredCameras,
                cameraStatusObj: getCameraActualStatus(rowData),
                cell: 'connectionStatus',
                discoveryId: rowData.id,
                rowIndex,
                table: 'DISCOVERED',
              };
              resetFDSPassword(menuProps);
            } else {
              connectDiscoveredCamera(rowData);
            }
          }}
        >
          <Icon
            iconClass={styles.iconCenter}
            id={IC_ADD}
            title="DEVICE_DETAILS.CAMERAS_TAB.ADD_CAMERA_ICON_LABEL"
          />
        </Button>
      );
    },
  ];

  const renderFindUndiscoveredCamerasButton = () => {
    return (
      <Button
        buttonType="secondary"
        disabled={
          isServerDisconnected ||
          pageMessageType === messageTypes.FETCHING_DISCOVERED_CAMERA_MANUALLY
        }
        id={consts.idBtnFindCamera}
        inputType="button"
        onClick={() => findUndiscoveredCamerasCallback()}
        styleClass={`${styles.secondaryButton} ${styles.secondaryButtonRoundedText}`}
        text={translate('BUTTONS.FIND_UNDISCOVERED')}
      />
    );
  };

  const getFoundDiscoveredCameraCount = () => {
    if (!findCameraFormData) return 0;

    if (findCameraFormData.address) {
      const requestedDiscoveredCameras = discoveredCameras.filter(
        camera => camera.ipAddress === findCameraFormData.address,
      );
      return requestedDiscoveredCameras.length;
    }
    if (findCameraFormData.startIpAddress && findCameraFormData.endIpAddress) {
      const startAddress = new ComparableFirmwareVersion(
        findCameraFormData.startIpAddress,
      );
      const endAddress = new ComparableFirmwareVersion(
        findCameraFormData.endIpAddress,
      );

      const discoveredCamerasInsideRange = discoveredCameras.filter(camera => {
        const discCameraAddress = new ComparableFirmwareVersion(
          camera.ipAddress,
        );
        if (
          startAddress <= discCameraAddress <= endAddress ||
          startAddress >= discCameraAddress >= endAddress
        ) {
          return camera;
        }
      });
      return discoveredCamerasInsideRange.count;
    }
    if (findCameraFormData.urlStream) {
      const requestedDiscoveredCameras = discoveredCameras.filter(camera => {
        if (camera.uri && camera.uri.length > 0) {
          const portLessCameraURI = camera.uri.replace(
            new RegExp(consts.regex, 'g'),
            '$1',
          );
          const findCameraURI = findCameraFormData.urlStream.replace(
            new RegExp(consts.regex, 'g'),
            '$1',
          );
          return portLessCameraURI === findCameraURI;
        }
        return false;
      });
      return requestedDiscoveredCameras.length;
    }

    return 0;
  };

  const getFoundDiscoveredCameraIndex = () => {
    if (
      !findCameraFormData ||
      pageMessageType !== messageTypes.FETCHING_DISCOVERED_CAMERA_RESULT
    )
      return -1;

    if (findCameraFormData.address) {
      const requestedDiscoveredCamerasIndex = discoveredCameras.findIndex(
        camera => camera.ipAddress === findCameraFormData.address,
      );
      return requestedDiscoveredCamerasIndex;
    }
    if (findCameraFormData.startIpAddress && findCameraFormData.endIpAddress) {
      const startAddress = new ComparableFirmwareVersion(
        findCameraFormData.startIpAddress,
      );
      const endAddress = new ComparableFirmwareVersion(
        findCameraFormData.endIpAddress,
      );

      const discoveredCamerasInsideRange = discoveredCameras.filter(camera => {
        const discCameraAddress = new ComparableFirmwareVersion(
          camera.ipAddress,
        );
        if (
          startAddress <= discCameraAddress <= endAddress ||
          startAddress >= discCameraAddress >= endAddress
        ) {
          return camera;
        }
      });

      const rangeCameraIndexes = discoveredCamerasInsideRange.forEach(
        insideRangeCamera => {
          const requestedDiscoveredCamerasIndex = discoveredCameras.findIndex(
            rangeCamera => rangeCamera.ipAddress === insideRangeCamera.address,
          );
          return requestedDiscoveredCamerasIndex;
        },
      );

      return rangeCameraIndexes;
    }
    if (findCameraFormData.urlStream) {
      const requestedDiscoveredCamerasIndex = discoveredCameras.findIndex(
        camera => {
          if (camera.uri && camera.uri.length > 0) {
            const portLessCameraURI = camera.uri.replace(
              new RegExp(consts.regex, 'g'),
              '$1',
            );
            const findCameraURI = findCameraFormData.urlStream.replace(
              new RegExp(consts.regex, 'g'),
              '$1',
            );
            return portLessCameraURI === findCameraURI;
          }
          return false;
        },
      );

      return requestedDiscoveredCamerasIndex;
    }

    return -1;
  };

  const getTableTitle = () =>
    translate('DEVICE_DETAILS.CAMERAS_TAB.DISCOVERED_CAMERAS_HEADER', {
      count: discoveredCameras.length,
    });

  const changeFilterFDSCamera = () => {
    setFilterFDSCamera(!filterFDSCamera);
  };

  const renderFilterFDSCameras = () => (
    <div className={styles.filterFDSCameras}>
      <Toggle
        checked={filterFDSCamera}
        id={consts.idToggleFilterFDSCam}
        onChange={changeFilterFDSCamera}
      />
      <span>
        <Translate id="DEVICE_DETAILS.CAMERAS_TAB.DISCOVERED_CAMERAS_FILTER_FDS" />
      </span>
    </div>
  );

  const cameras = filterFDSCamera
    ? discoveredCameras.filter(camera => camera.factoryDefault)
    : discoveredCameras;

  const messageComp =
    pageMessageType === null ? null : (
      <PageMessage
        body={
          pageMessageType === messageTypes.FETCHING_DISCOVERED_CAMERA_RESULT ? (
            <div id={consts.idDiscoveredCameraFound}>
              <Translate>
                {({ translate }) => (
                  <>
                    {translate(
                      'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERA_SEARCH_COMPLETED',
                      { count: getFoundDiscoveredCameraCount() },
                      { renderInnerHtml: true },
                    )}
                  </>
                )}
              </Translate>
            </div>
          ) : (
            <div
              className={styles.discoveredCameraFoundWrapper}
              id={consts.idSearchingDiscoveredCamera}
            >
              <div className={styles.spinnerWrapper}>
                <Spinner
                  color1={consts.feedbackSpinnerColor}
                  color2={consts.feedbackSpinnerColor}
                  color3={consts.feedbackSpinnerColor}
                  color4={consts.feedbackSpinnerColor}
                  size={16}
                />
              </div>
              <div className={styles.discoveredCameraSearchProgressText}>
                {translate(
                  'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERA_SEARCH_IN_PROGRESS',
                )}
              </div>
            </div>
          )
        }
        keepOpen
        messageStyle={messageStyleStrings.success}
        messageType={[
          messageTypes.FETCHING_DISCOVERED_CAMERA_MANUALLY,
          messageTypes.FETCHING_DISCOVERED_CAMERA_RESULT,
        ]}
      />
    );

  const targetRowIndex = getFoundDiscoveredCameraIndex();
  return (
    <div>
      <ListView
        bodyHeight={180}
        cellAlignments={consts.CELL_ALIGNMENTS}
        cellWidths={consts.CELL_WIDTHS}
        data={cameras}
        defaultSortField="name"
        fieldOrder={consts.FIELD_ORDER}
        headerTranslationIds={consts.HEADER_TRANSLATION_IDS}
        highlightRowNo={targetRowIndex}
        isFetching={isFetching}
        leftContent={renderFilterFDSCameras()}
        messageComponent={messageComp}
        resizable
        rightContent={renderFindUndiscoveredCamerasButton()}
        rowActions={ROW_ACTIONS}
        rowHeight="50"
        scrollToPos={targetRowIndex}
        sortType="local"
        title={getTableTitle()}
        titleClass={styles.camerasTableTitle}
        truncatedFields={['name', 'mfr', 'model', 'physicalAddress']}
      />
    </div>
  );
}

DiscoveredCamerasTable.defaultProps = {
  findCameraFormData: null,
  findUndiscoveredCamerasCallback: () => {},
  isFetching: false,
  isServerDisconnected: false,
  pageMessageType: null,
};

DiscoveredCamerasTable.propTypes = {
  connectDiscoveredCamera: PropTypes.func.isRequired,
  discoveredCameras: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  findCameraFormData: PropTypes.shape({
    address: PropTypes.string,
    endIpAddress: PropTypes.string,
    startIpAddress: PropTypes.string,
    urlStream: PropTypes.string,
  }),
  findUndiscoveredCamerasCallback: PropTypes.func,
  isFetching: PropTypes.bool,
  isServerDisconnected: PropTypes.bool,
  pageMessageType: PropTypes.string,
  resetFDSPassword: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(DiscoveredCamerasTable);
