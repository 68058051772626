// Actions
import * as types from 'constants/ActionTypes';
import { getUsersPublicInfo } from 'actions/user';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import { LOCATION } from 'constants/ObjectTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as modalTypes from 'constants/ModalTypes';

// Utils
import urlBuilder from 'queryBuilder/url';
import {
  addUserIfNeeded,
  extractUserIdFromResponse,
  isFetching,
  isFetchingData,
  isSavingData,
} from './common';
import {
  runGetActionReturningJSON,
  sendDeleteRequestReturningJSON,
  sendGetRequestReturningJSON,
  sendMultiPutRequestReturningJSON,
  sendPostRequestReturningJSON,
  sendPutRequestReturningJSON,
} from '../util/fetchHelpers';

// Components
import { showMessage } from './pageMessage';
import { showModal } from './modal';

export function receiveLocations(locations, orgId) {
  return {
    locations,
    orgId,
    type: types.RECEIVE_LOCATIONS,
  };
}

export function clearLocations() {
  return dispatch => {
    dispatch(receiveLocations([]));
  };
}

/**
 * Retrieve the sites by Organzation
 *
 * @param {String} orgId Used to know which sites are going to be retrieved
 * @param {Object} queryOptions Options to be sent on the Request, if needed
 */
export function getLocations(orgId, queryOptions) {
  return dispatch => {
    const idSegments = orgId ? { orgId } : {};
    const url = urlBuilder(
      types.GET_LOCATIONS,
      null,
      null,
      queryOptions,
      idSegments,
    );

    const onSuccess = json => {
      dispatch(receiveLocations(json.Items, orgId));
      return Promise.resolve();
    };

    const onError = ex => {
      dispatch(
        showMessage(messageTypes.LOCATION_ERROR, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'LOCATIONS.ACTIONS.GET_LOCATION_ERROR',
        }),
      );
      avoLogError('Error Getting Locations:', ex);
      dispatch(receiveLocations([]));
    };

    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_LOCATIONS,
      onError,
      onSuccess,
      url,
    });
  };
}

export function getLocationsScoped(orgId) {
  return dispatch => {
    const url = urlBuilder(types.GET_LOCATIONS, null, null, null, { orgId });

    dispatch(
      isFetchingData(types.GET_LOCATIONS_SCOPED, true, { fetchScope: orgId }),
    );
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveLocations(json.Items));
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.LOCATION_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'LOCATIONS.ACTIONS.GET_LOCATION_ERROR',
          }),
        );
        avoLogError('Error Getting Scoped Locations:', ex);
        dispatch(receiveLocations([]));
      })
      .finally(() =>
        dispatch(
          isFetchingData(types.GET_LOCATIONS_SCOPED, false, {
            fetchScope: orgId,
          }),
        ),
      );
  };
}

export function receiveOrgSubscriptions(subscriptions) {
  return {
    subscriptions,
    type: types.RECEIVE_ORG_SUBSCRIPTIONS,
  };
}

export function getOrgSubscriptions(tenantId) {
  return dispatch => {
    const url = urlBuilder(types.GET_ORG_SUBSCRIPTIONS, tenantId);
    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_ORG_SUBSCRIPTIONS,
      onError: err => {
        avoLogError('Error loading organization subscriptions', {
          err,
          tenantId,
        });
      },
      onSuccess: json => {
        dispatch(receiveOrgSubscriptions(json));
      },
      url,
    });
  };
}

function receiveDealerServicePackageFamilies(data) {
  return {
    data,
    type: types.RECEIVE_DEALER_SERVICE_PACKAGE_FAMILIES,
  };
}

export function getDealerServicePackageFamilies(dealerId, orgId) {
  return dispatch => {
    let url;
    if (dealerId) {
      url = urlBuilder(
        types.GET_DEALER_SERVICE_PACKAGE_FAMILIES,
        dealerId,
        null,
        {},
        { orgId },
      );
    } else {
      url = urlBuilder(types.GET_SERVICE_PACKAGE_FAMILIES);
    }
    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_DEALER_SERVICE_PACKAGE_FAMILIES,
      onError: error => {
        avoLogError('Error getting service package families', error);
      },
      onSuccess: json => {
        dispatch(receiveDealerServicePackageFamilies(json));
      },
      url,
    });
  };
}

function receiveCameraGroups(cameragroups) {
  return {
    cameragroups,
    type: types.RECEIVE_CAMERAGROUPS,
  };
}

// retrieves camera groups belonging to an organization
// Not currently in use, calls deprecated API
export function getCameraGroups(orgId) {
  return dispatch => {
    const url = urlBuilder(types.GET_CAMERAGROUPS, orgId);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveCameraGroups(json)))
      .catch(ex => {
        avoLogError(
          'Error getting camera groups. But why is this deprecated action being called?',
          ex,
        );
      });
  };
}

export function addLocation(locationData, orgId, queryOptions) {
  return dispatch => {
    dispatch(isSavingData(LOCATION));
    addUserIfNeeded(locationData, orgId)
      .then(json => {
        return {
          ...locationData,
          AdminUserId: extractUserIdFromResponse(json),
        };
      })
      .then(locationDataResponse => {
        dispatch(getUsersPublicInfo(orgId));
        const url = urlBuilder(types.ADD_LOCATION, null, null, null, {
          orgId,
        });

        sendPostRequestReturningJSON(url, locationDataResponse)
          .then(json => {
            if (json.ClaimingCode) {
              dispatch(
                showModal(modalTypes.NEW_SITE_ACTIVATION_CODE, {
                  bottomText: 'DEVICES.DEVICE_CLAIM.EXPIRE_CODE_LABEL',
                  emphasizedText: json.ClaimingCode,
                  title: 'DEVICES.DEVICE_CLAIM.ACTIVATION_CODE_LABEL',
                  topText: 'DEVICES.DEVICE_CLAIM.ENTER_CODE_LABEL',
                }),
              );
            }
            dispatch(
              showMessage(messageTypes.LOCATION_SUCCESS, null, null, {
                messageStyle: messageStyleStrings.success,
                translateBody:
                  'LOCATIONS.LOCATIONS_ACTIONS.ADD_LOCATION_SUCCESS',
              }),
            );
            dispatch(getOrgSubscriptions(orgId));
            dispatch(getLocations(orgId, queryOptions));
            dispatch(getCameraGroups(orgId));
          })
          .catch(err => {
            dispatch(
              showMessage(
                messageTypes.ADD_EDIT_LOCATION_ERROR,
                err.message,
                null,
                {
                  body: (err && err.message) || err,
                  messageStyle: messageStyleStrings.error,
                  translateBody:
                    !err && 'USERS.USERS_ACTIONS.ADD_LOCATION_ERROR',
                  translateHeader:
                    !((err && err.message) || err) &&
                    'GENERAL_MESSAGES.ADD_ERROR',
                },
              ),
            );
          });
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.ADD_EDIT_LOCATION_ERROR, err.message, null, {
            body: (err && err.message) || err,
            messageStyle: messageStyleStrings.error,
            translateBody:
              !((err && err.message) || err) &&
              'USERS.USERS_ACTIONS.ADD_LOCATION_ERROR',
            translateHeader:
              !((err && err.message) || err) && 'GENERAL_MESSAGES.ADD_ERROR',
          }),
        );
      })
      .finally(() => {
        dispatch(isSavingData(LOCATION, false));
      });
  };
}

export function editLocation(locationData, orgId, queryOptions) {
  return dispatch => {
    dispatch(isSavingData(LOCATION));
    addUserIfNeeded(locationData, orgId)
      .then(json => {
        return {
          ...locationData,
          AdminUserId: extractUserIdFromResponse(json),
        };
      })
      .then(locationDataResponse => {
        dispatch(getUsersPublicInfo(orgId));
        const url = urlBuilder(
          types.EDIT_LOCATION,
          locationDataResponse.Id,
          null,
          null,
          {
            orgId,
          },
        );

        sendPutRequestReturningJSON(url, locationDataResponse)
          .then(() => {
            dispatch(
              showMessage(messageTypes.LOCATION_SUCCESS, null, null, {
                messageStyle: messageStyleStrings.success,
                translateBody:
                  'LOCATIONS.LOCATIONS_ACTIONS.EDIT_LOCATION_SUCCESS',
              }),
            );
            dispatch(getOrgSubscriptions(orgId));
            dispatch(getLocations(orgId, queryOptions));
            dispatch(getCameraGroups(orgId));
          })
          .catch(err => {
            dispatch(
              showMessage(
                messageTypes.ADD_EDIT_LOCATION_ERROR,
                err.message,
                null,
                {
                  messageStyle: messageStyleStrings.error,
                  translateBody: !err.message
                    ? 'LOCATIONS.LOCATIONS_ACTIONS.EDIT_LOCATION_ERROR'
                    : '',
                  translateHeader: !err ? 'GENERAL_MESSAGES.EDIT_ERROR' : '',
                },
              ),
            );
          });
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.ADD_EDIT_LOCATION_ERROR, null, null, {
            body: (err && err.message) || err,
            messageStyle: messageStyleStrings.error,
            translateBody:
              !((err && err.message) || err) &&
              'USERS.USERS_ACTIONS.ADD_USER_ERROR',
            translateHeader:
              !((err && err.message) || err) && 'GENERAL_MESSAGES.EDIT_ERROR',
          }),
        );
      })
      .finally(() => {
        dispatch(isSavingData(LOCATION, false));
      });
  };
}

export function deleteLocation(id, orgId, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.DELETE_LOCATION, id, null, queryOptions, {
      orgId,
    });
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.LOCATION_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'LOCATIONS.LOCATIONS_ACTIONS.DELETE_LOCATION_SUCCESS',
          }),
        );
        dispatch(getLocations(orgId, queryOptions));
        dispatch(getCameraGroups(orgId));
      })
      .catch(err => {
        dispatch(
          showMessage(messageTypes.LOCATION_ERROR, null, null, {
            body: err,
            messageStyle: messageStyleStrings.error,
            translateBody:
              !err && 'LOCATIONS.LOCATIONS_ACTIONS.DELETE_LOCATION_ERROR',
            translateHeader: !err && 'GENERAL_MESSAGES.ERROR_HEADER',
          }),
        );
      });
  };
}

export function deleteLocations(ids, orgId, queryOptions) {
  return dispatch =>
    Promise.all([
      ...ids.map(id => dispatch(deleteLocation(id, orgId, queryOptions))),
    ]);
}

export function suspendUnsuspendLocations(orgId, locationIds, queryOptions) {
  const endpoint =
    queryOptions === 'SUSPEND'
      ? types.SUSPEND_LOCATION
      : types.UNSUSPEND_LOCATION;

  return dispatch => {
    const url = urlBuilder(endpoint, null, null, null, { orgId });
    return sendMultiPutRequestReturningJSON(url, locationIds)
      .then(() => {
        dispatch(
          showMessage(messageTypes.LOCATION_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'LOCATIONS.LOCATIONS_ACTIONS.EDIT_LOCATION_SUCCESS',
            translateHeader: 'GENERAL_MESSAGES.SUCCESS_HEADER',
          }),
        );
        dispatch(getLocations(orgId));
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.LOCATION_ERROR, null, null, {
            body: ex,
            messageStyle: messageStyleStrings.error,
            translateBody:
              !ex && 'LOCATIONS.LOCATIONS_ACTIONS.EDIT_LOCATION_ERROR',
            translateHeader: !ex && 'GENERAL_MESSAGES.ERROR_HEADER',
          }),
        );
        avoLogError('Error suspending or unsuspending a location', ex);
      });
  };
}

function updateLocationList(location) {
  return {
    location,
    type: types.LOCATION_UPDATE,
  };
}

export function getUpdatedLocation(locationId, orgId) {
  return dispatch => {
    dispatch(
      isFetchingData(types.GET_LOCATIONS_SCOPED, true, {
        fetchScope: locationId,
      }),
    );
    const url = urlBuilder(
      types.GET_LOCATION,
      locationId,
      null,
      { filter: { field: 'Id', operator: 'eq', value: locationId } },
      {
        orgId,
      },
    );
    return sendGetRequestReturningJSON(url)
      .then(json => {
        if (json.Items) {
          dispatch(updateLocationList(json.Items.length ? json.Items[0] : {}));
        } else {
          dispatch(updateLocationList(json.length ? json[0] : {}));
        }
      })
      .catch(ex => {
        avoLogError('Error getting location', { ex, locationId, orgId });
      })
      .finally(() => {
        dispatch(
          isFetchingData(types.GET_LOCATIONS_SCOPED, false, {
            fetchScope: locationId,
          }),
        );
      });
  };
}

export function removeLocation(locationId) {
  // Action used to remove a location from the list in response
  // to a SignalR signal indicating a location has been deleted
  return {
    locationId,
    type: types.REMOVE_LOCATION,
  };
}

/** Pending Subscription Requests for Sites */

export function receivePendingSiteRequests(pendingSiteRequests) {
  // Action used to remove a location from the list in response
  // to a SignalR signal indicating a location has been deleted
  return {
    pendingSiteRequests,
    type: types.RECEIVE_PENDING_SITE_REQUESTS,
  };
}

export function getPendingSiteRequests() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_PENDING_SITE_REQUESTS, true));
    const url = urlBuilder(
      types.GET_PENDING_SITE_REQUESTS,
      null,
      null,
      {},
      null,
    );

    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receivePendingSiteRequests(json));
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERAL_MESSAGES.GET_REQUEST_ERROR',
          }),
        );
        avoLogError('Error Getting Pending Site Requests:', ex);
        dispatch(receivePendingSiteRequests([]));
      })
      .then(() =>
        dispatch(isFetching(types.IS_FETCHING_PENDING_SITE_REQUESTS, false)),
      );
  };
}
export function updatePendingSiteRequests(
  dealerId,
  orgId,
  siteData,
  isRequestAccepted,
) {
  return dispatch => {
    const id = siteData.SiteId;
    const urlType = isRequestAccepted
      ? types.PENDING_SITE_REQUESTS_ACCEPT
      : types.PENDING_SITE_REQUESTS_DECLINE;
    const url = urlBuilder(urlType, id, null, {}, { orgId });
    return sendPostRequestReturningJSON(url, {})
      .then(() => {
        const msgTranslateBody = isRequestAccepted
          ? 'LOCATIONS.LOCATIONS_ACTIONS.PENDING_SITE_REQUEST_ACCEPTED_SUCCESS'
          : 'LOCATIONS.LOCATIONS_ACTIONS.PENDING_SITE_REQUEST_DECLINED_SUCCESS';
        dispatch(
          showMessage(messageTypes.INTEGRATION_CONFIG, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: msgTranslateBody,
            translateBodyData: {
              siteName: siteData.SiteName,
            },
          }),
        );
        dispatch(getPendingSiteRequests());
      })
      .catch(err => {
        dispatch(
          showMessage(
            messageTypes.INTEGRATION_CONFIG,
            'Unknown API Error',
            null,
            {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'LOCATIONS.LOCATIONS_ACTIONS.PENDING_SITE_REQUEST_ERROR',
              translateBodyData: {
                siteName: siteData.SiteName,
              },
              translateHeader: !err ? 'GENERAL_MESSAGES.EDIT_ERROR' : '',
            },
          ),
        );
      });
  };
}
/** Pending Subscription Requests for Sites */
