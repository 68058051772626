import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconPlay = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="iPhone"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="03-alarms-review-full"
          transform="translate(-44.000000, -347.000000)"
          fill={props.fill}
        >
          <g id="Video" transform="translate(0.000000, 90.000000)">
            <g id="Bar-/-Menu" transform="translate(0.000000, 246.000000)">
              <g id="Player-Controls-Full">
                <g id="ic_play" transform="translate(43.000000, 11.000000)">
                  <path
                    d="M1.56983623,13.9161909 C1.25642631,14.1053983 1,13.9632728 1,13.6002586 L1,0.399741443 C1,0.0367272227 1.25642631,-0.105398345 1.56983623,0.083809067 L12.4501468,6.65590652 C12.7635568,6.84511393 12.7635568,7.15488607 12.4501468,7.34409348 L1.56983623,13.9161909 Z"
                    id="Fill-1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconPlay.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconPlay;
