exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".logoLink___2gP8X {\r\n  text-decoration: none;\r\n}\r\n\r\n.logo___2Og4x {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  cursor: pointer;\r\n  padding: 8px;\r\n}\r\n\r\n.logoText___2a280 {\r\n  color: #fff;\r\n  font-size: 14px;\r\n  margin-left: 8px;\r\n}\r\n\r\n@media only screen and (max-width: 767px) and (orientation: portrait) {\r\n  .logoImg___3vwqM {\r\n    margin: 0 auto auto 8px;\r\n  }\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/Login/logo.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;CACvB;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,uBAAuB;MACnB,oBAAoB;EACxB,gBAAgB;EAChB,aAAa;CACd;;AAED;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE;IACE,wBAAwB;GACzB;CACF","file":"logo.css","sourcesContent":[".logoLink {\r\n  text-decoration: none;\r\n}\r\n\r\n.logo {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  cursor: pointer;\r\n  padding: 8px;\r\n}\r\n\r\n.logoText {\r\n  color: #fff;\r\n  font-size: 14px;\r\n  margin-left: 8px;\r\n}\r\n\r\n@media only screen and (max-width: 767px) and (orientation: portrait) {\r\n  .logoImg {\r\n    margin: 0 auto auto 8px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"logoLink": "logoLink___2gP8X",
	"logo": "logo___2Og4x",
	"logoText": "logoText___2a280",
	"logoImg": "logoImg___3vwqM"
};