// Constants
import * as CameraTypes from 'constants/cameraTypes';

import { DeviceSource, findServerCategory } from './deviceCategory';
import { getCameraActualStatus } from './getCameraActualStatus';

export const sortDevices = devices => {
  const devicesCannotUpgrade = [];
  const devicesCanUpgrade = [];
  devices.forEach(d => {
    if (d.ConnectionState !== CameraTypes.CAMERA_CONNECTED) {
      devicesCannotUpgrade.push({
        ...d,
        cannotUpgradeReason:
          'DEVICES.DEVICE_UPGRADE_CONFIRM.DEVICE_IS_NOT_CONNECTED',
      });
      // TODO: Re-enable when firmware status bug is resolved.
      // } else if (d.FirmwareStatus && d.FirmwareStatus !== 'Success') {
      //   devicesCannotUpgrade.push({
      //     ...d,
      //     cannotUpgradeReason: 'Device is upgrading or failed last upgrade',
      //   });
    } else if (!d.CanBeUpgraded) {
      devicesCannotUpgrade.push({
        ...d,
        cannotUpgradeReason:
          'DEVICES.DEVICE_UPGRADE_CONFIRM.DEVICE_IS_UP_TO_DATE',
      });
    } else {
      devicesCanUpgrade.push(d);
    }
  });
  return { devicesCanUpgrade, devicesCannotUpgrade };
};

export const sortCameras = (cameras, devicesCannotUpgrade = []) => {
  const camerasCannotUpgrade = [];
  const camerasCanUpgrade = [];
  cameras.forEach(c => {
    // Assumes disabled cameras will not be upgraded
    const status = getCameraActualStatus(c);
    const deviceObject = devicesCannotUpgrade.find(d => c.DeviceId === d.Id);
    if (status.realState !== CameraTypes.CAMERA_CONNECTED) {
      camerasCannotUpgrade.push({
        ...c,
        cannotUpgradeReason: status.statusText,
        enableCameraDetailsLink: false,
      });
    } else if (deviceObject) {
      camerasCannotUpgrade.push({
        ...c,
        cannotUpgradeReason:
          'DEVICES.DEVICE_UPGRADE_CONFIRM.DEVICE_IS_UP_TO_DATE',
        enableCameraDetailsLink:
          findServerCategory(deviceObject) === DeviceSource.BLUE,
      });
    } else {
      camerasCanUpgrade.push(c);
    }
  });
  return { camerasCanUpgrade, camerasCannotUpgrade };
};

export const sortObjectsArrayByPropertyValue = (array, propertyValue) => {
  return array.sort((a, b) => a[propertyValue].localeCompare(b[propertyValue]));
};
