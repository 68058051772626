import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconDownload = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 20 16"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="03---Alarms-&gt;-List-View-(Modal)-Clip"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Alarms-List-Modal-Comment"
          transform="translate(-1255.000000, -650.000000)"
          fill={props.fill}
        >
          <g id="(Modal)" transform="translate(91.000000, 190.000000)">
            <g id="Group-6" transform="translate(30.000000, 77.000000)">
              <g
                id="img-icon-download-off"
                transform="translate(1132.000000, 380.000000)"
              >
                <g
                  id="arrow-for-full"
                  transform="translate(11.000000, 7.500000) rotate(-225.000000) translate(-11.000000, -7.500000) translate(6.000000, 2.000000)"
                >
                  <rect
                    id="Rectangle-12"
                    x="8"
                    y="0"
                    width="2"
                    height="7"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-12"
                    transform="translate(6.500000, 1.000000) rotate(90.000000) translate(-6.500000, -1.000000) "
                    x="5.5"
                    y="-2.5"
                    width="2"
                    height="7"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-12"
                    transform="translate(4.535534, 5.535534) rotate(45.000000) translate(-4.535534, -5.535534) "
                    x="3.53553391"
                    y="1.53553391"
                    width="2"
                    height="8"
                    rx="1"
                  />
                </g>
                <rect
                  id="Rectangle-14"
                  transform="translate(12.000000, 18.000000) rotate(90.000000) translate(-12.000000, -18.000000) "
                  x="11"
                  y="8"
                  width="2"
                  height="20"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconDownload.defaultProps = {
  className: null,
  width: '25px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconDownload;
