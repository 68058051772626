import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translatedConnect } from 'util/translatedConnect';
import { bindActionCreators } from 'redux';
import { VideoButtons } from 'components';
import { PageMessage } from 'containers';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Actions
import * as SettingsActions from 'actions/settings';
import * as DeviceActions from 'actions/devices';
import * as LocationActions from 'actions/locations';
import { showModal, hideModal } from 'actions/modal';
import GetMediaParams from 'containers/GetMediaParams/GetMediaParams';
import { videoStream, videoPlayerError } from './styles.css';
import PlayerManagerHOC from './PlayerManagerHOC';

const MAX_SCALE_LEVEL = 8;
const ORIGIN_DEFAULT = '50% 50% 0';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseDown: false,
      scale: 1,
      dragStartX: null, // mouseDown coordinates
      dragStartY: null,
      currentTranslateX: null, // dragging from current dragging
      currentTranslateY: null,
      translateX: 0,
      translateY: 0,
      origin: ORIGIN_DEFAULT,
      css: {},
      maxDragHorizontal: 0,
      maxDragVertical: 0,
      canPan: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.camera &&
      nextProps.camera.Name !== this.props.serverBreadcrumb
    ) {
      this.props.actions.setServerBreadcrumb(nextProps.camera.Name);
      if (nextProps.isFetchingMediaParams === null) {
        this.refreshMediaParams();
      }
    }
  }

  zoomIn = () => {
    if (this.state.scale >= MAX_SCALE_LEVEL) {
      return;
    }
    const _scale = this.state.scale + 0.1;
    this.changeZoomLevel(_scale);
  };

  zoomOut = () => {
    const _scale = this.state.scale - 0.1;
    if (_scale <= 1) {
      this.setState(
        {
          scale: 1,
          translateX: 0,
          translateY: 0,
          origin: ORIGIN_DEFAULT,
        },
        function() {
          this.setCss();
          this.setPanLimits();
          this.setCanPan();
        },
      );
    } else {
      this.setState(
        {
          scale: _scale,
          origin: ORIGIN_DEFAULT,
        },
        function() {
          this.setPanLimits(this.setCss);
          this.setCanPan();
        },
      );
    }
  };

  onSliderChange = value => {
    this.setState(
      {
        origin: ORIGIN_DEFAULT,
      },
      () => {
        this.changeZoomLevel(value);
      },
    );
  };

  onMouseWheelChange = ev => {
    // dont handle mousewheel scrolling unless the mouse is over the video stream,
    // with no elements overlayed
    if (ev.target.id === this.props.videoId) {
      const bounds = this.container.getBoundingClientRect();
      const mousePositionLeft = ev.pageX || ev.clientX;
      const mousePositionTop = ev.pageY || ev.clientY;
      const originLeft = mousePositionLeft - bounds.left;
      const originTop = mousePositionTop - bounds.top;
      const scaleChange = ev.deltaY <= 0 ? 0.1 : -0.1;
      const scale = this.state.scale + scaleChange;
      if (scale >= 1 && scale <= MAX_SCALE_LEVEL) {
        this.setState(
          {
            origin: `${originLeft}px ${originTop}px 0`,
          },
          () => {
            this.changeZoomLevel(scale);
          },
        );
      }
    }
  };

  changeZoomLevel = zoomLevel => {
    this.setState(
      {
        scale: zoomLevel,
      },
      function() {
        this.setCss();
        this.setPanLimits();
        this.setCanPan();
      },
    );
  };

  setPanLimits(callback) {
    const _width = this.props.width;
    const _height = this.props.height;
    const _maxDragHorizontal = (_width * this.state.scale - _width) / 2;
    const _maxDragVertical = (_height * this.state.scale - _height) / 2;
    let x = this.state.translateX;
    let y = this.state.translateY;
    if (x > 0) {
      if (x > _maxDragHorizontal) {
        x = _maxDragHorizontal;
      }
    } else if (x < _maxDragHorizontal) {
      x = _maxDragHorizontal;
    }

    if (y > 0) {
      if (y > _maxDragVertical) {
        y = _maxDragVertical;
      }
    } else if (y < _maxDragVertical) {
      y = _maxDragVertical;
    }
    this.setState(
      {
        maxDragHorizontal: _maxDragHorizontal,
        maxDragVertical: _maxDragVertical,
        translateX: x,
        translateY: y,
      },
      callback,
    );
  }

  startDrag = ev => {
    // Prevent drag when over other elements
    if (!this.state.canPan || ev.target.id !== this.props.videoId) return;
    ev.persist();
    this.setState({
      isMouseDown: true,
      dragStartX: ev.screenX,
      dragStartY: ev.screenY,
    });
  };

  stopDrag = ev => {
    ev.persist();
    this.setState(prevState => {
      const x = prevState.translateX + prevState.currentTranslateX;
      const y = prevState.translateY + prevState.currentTranslateY;
      return {
        isMouseDown: false,
        dragStartX: null,
        dragStartY: null,
        translateX: x,
        translateY: y,
        currentTranslateX: null,
        currentTranslateY: null,
      };
    });
  };

  setCanPan() {
    const canPan = this.state.scale > 1;
    this.setState({ canPan });
  }

  pan = ev => {
    ev.persist();
    this.setState(
      prevState => {
        if (prevState.isMouseDown) {
          if (prevState.scale <= 1) return;
          const xNew = ev.screenX;
          const yNew = ev.screenY;
          let xDelta = xNew - prevState.dragStartX;
          let yDelta = yNew - prevState.dragStartY;

          // dont let user drag edge of video beyond edge of parent container
          xDelta = this.validateMaxDrag(
            xDelta,
            prevState.translateX,
            prevState.maxDragHorizontal,
          );
          yDelta = this.validateMaxDrag(
            yDelta,
            prevState.translateY,
            prevState.maxDragVertical,
          );
          return {
            currentTranslateX: xDelta,
            currentTranslateY: yDelta,
          };
        }
      },
      () => this.setCss(),
    );
  };

  validateMaxDrag(current, previous, max) {
    const deltaTotal = current + previous;
    if (current >= 0) {
      current = deltaTotal >= max ? max - previous : current;
    } else {
      const _max = -1 * max;
      current = deltaTotal < _max ? _max - previous : current;
    }
    return current;
  }

  setCss() {
    const translate = this.setTranslateCss();
    const scale = this.setScaleCss();
    this.setState({
      css: {
        transform: `${translate} ${scale}`,
        transformOrigin: this.state.origin,
      },
    });
  }

  setScaleCss() {
    const _scale = `scale(${this.state.scale})`;
    return _scale;
  }

  setTranslateCss() {
    const x = this.state.translateX + (this.state.currentTranslateX || 0);
    const y = this.state.translateY + (this.state.currentTranslateY || 0);
    const _translate = `translate(${x}px, ${y}px)`;
    return _translate;
  }

  renderError = connectionError => {
    if (connectionError.errorComponent) {
      return connectionError.errorComponent;
    }
    return (
      <div className={videoPlayerError}>
        {connectionError.errorMessageKey ? (
          <PageMessage
            messageStyle={messageStyleStrings.error}
            translateBody={connectionError.errorMessageKey}
            visible
          />
        ) : (
          <PageMessage
            messageStyle={messageStyleStrings.error}
            body={connectionError.errorMessage}
            visible
          />
        )}
      </div>
    );
  };

  refreshMediaParams = (startTime = null, search = false) => {
    this.props.getMedia(this.props.camera, startTime, search);
  };

  render() {
    // merge buttons from parent HOC
    let videoButtons;
    videoButtons = (
      <VideoButtons
        onZoomIn={this.zoomIn}
        onZoomOut={this.zoomOut}
        maxZoomLevel={MAX_SCALE_LEVEL}
        zoomLevel={this.state.scale}
        onSliderChange={this.onSliderChange}
        show={this.props.showControls}
        showDigitalZoomControls={this.props.showDigitalZoomControls}
      >
        {this.props.buttons}
      </VideoButtons>
    );

    const { height } = this.props;
    const { width } = this.props;

    const _size = {
      height: `${height}px`,
      width: `${width}px`,
    };
    const _style = Object.assign({}, _size, this.state.css, {
      cursor: this.state.canPan ? 'move' : '-webkit-zoom-in',
      // TODO: Beware, this can break down rotation of video. This helps in centering the video div before applying rotation
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    });

    if (
      this.props.connectionError &&
      this.props.connectionError.hasConnectionError
    ) {
      return (
        <div
          ref={component => (this.container = component)}
          className={videoStream}
          style={_size}
        >
          <div style={_style}>
            {this.renderError(this.props.connectionError)}
          </div>
          <div
            style={{
              width: `${width}px`,
            }}
          >
            {this.props.connectionError.showButtons === false
              ? null
              : videoButtons}
          </div>
        </div>
      );
    }
    return (
      <div
        ref={component => (this.container = component)}
        className={videoStream}
        style={_size}
        onMouseDown={this.startDrag}
        onMouseUp={this.stopDrag}
        onMouseLeave={this.stopDrag}
        onMouseMove={this.pan}
        onWheel={this.onMouseWheelChange}
      >
        <div style={_style}>{this.props.children}</div>
        <div
          style={{
            width: `${width}px`,
          }}
        >
          {videoButtons}
        </div>
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  isDisconnected: true,
};

VideoPlayer.propTypes = {
  buttons: PropTypes.element,
};

function mapStateToProps(state, ownProps) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...SettingsActions,
        ...DeviceActions,
        ...LocationActions,
        showModal,
        hideModal,
      },
      dispatch,
    ),
  };
}

function mapWrapperStateToProps(state, ownProps) {
  const camera = state.devices.cameras.find(
    camera => camera.Id === ownProps.cameraId,
  );
  return {
    camera,
    mediaParamsAll: state.devices.mediaParams,
    timelines: state.devices.timelines,
    privacyZones: state.privacyZone || null,
    handlePtzEnabledInFeed: ownProps.handlePtzEnabledInFeed,
    enabledPtzFeed: ownProps.enabledPtzFeed,
    videoId: ownProps.id,
  };
}

export default GetMediaParams(
  PlayerManagerHOC(
    translatedConnect(mapStateToProps, mapDispatchToProps)(VideoPlayer),
  ),
  mapWrapperStateToProps,
);
