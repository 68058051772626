import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomFocusOut = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1088.000000, -446.000000)"
      >
        <path
          d="M1091.33375,453.757359 L1096.96691,459.390524 L1095.60008,460.757359 L1088.7659,453.923184 L1088.93173,453.757359 L1088.7659,453.591534 L1095.60008,446.757359 L1096.96691,448.124194 L1091.33375,453.757359 Z M1097.13274,453.757359 L1102.7659,459.390524 L1101.39907,460.757359 L1094.56489,453.923184 L1094.73072,453.757359 L1094.56489,453.591534 L1101.39907,446.757359 L1102.7659,448.124194 L1097.13274,453.757359 Z"
          id="icon-btn-zoomfocus-out-more-v2"
          fill={props.fill}
        />
      </g>
    </svg>
  );
};

IconBtnZoomFocusOut.defaultProps = {
  className: null,
  width: '14px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomFocusOut;
