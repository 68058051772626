import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldSelect, FormGroup } from 'lib';

import { states } from 'constants/dropdownContent';
import * as LocationFormConsts from '../../constants';

import { formField } from '../../styles.css';

const UsaStateDropdown = props => {
  const { disabled } = props;
  return (
    <FormGroup
      id={LocationFormConsts.idRegionDropdown}
      keyName="UsaAddressMetaData"
      label={<Translate id="ADDRESS.STATE" />}
    >
      <Field
        className={formField}
        component={FieldSelect}
        disabled={disabled}
        isNewVersion
        name={LocationFormConsts.nameRegion}
        translate
        validate={validation.required}
      >
        {states.map(s => (
          <option key={s.abbreviation} value={s.abbreviation}>
            {s.abbreviation}
          </option>
        ))}
      </Field>
    </FormGroup>
  );
};

UsaStateDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default UsaStateDropdown;
