/* global avoLogError */

// Utils
import urlBuilder from 'queryBuilder/url';

// Constants
import {
  SET_ACTIVE_PTZ_FUNCTION,
  UPDATE_CAMERA_PAN_TILT_ZOOM,
  GO_TO_HOME_PTZ_PRESET,
} from 'constants/ActionTypes';

import * as webRtcRoutes from 'constants/WebRtcRoutes';

import { showMessage } from 'actions/pageMessage';
import * as messageTypes from 'constants/MessageTypes';
import { sendPutRequestReturningJSON } from 'util/fetchHelpers';
import { messageStyleStrings } from 'containers/PageMessage/constants';

export function updateActivePtzFunction(name) {
  return {
    type: SET_ACTIVE_PTZ_FUNCTION,
    name,
  };
}

export function setActivePtzFunction(name) {
  return dispatch => {
    dispatch(updateActivePtzFunction(name));
  };
}

export function cameraAutofocus(webRtcHelper, cameraId, cameraRemoteId) {
  return dispatch => {
    const route = webRtcRoutes.UPDATE_CAMERA_FOCUS_AUTO;
    const query = { id: cameraRemoteId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function cameraPanTiltZoomGateway(serverId, cameraRemoteId, options) {
  return () => {
    const url = urlBuilder(UPDATE_CAMERA_PAN_TILT_ZOOM, serverId);
    const body = Object.assign({ id: cameraRemoteId }, options);

    return sendPutRequestReturningJSON(url, body).catch(ex => {
      avoLogError('Error updating camera PTZ', ex);
    });
  };
}

export function cameraPanTiltZoom(
  webRtcHelper,
  serverId,
  cameraId,
  cameraRemoteId,
  options,
) {
  return dispatch => {
    const route = webRtcRoutes.UPDATE_CAMERA_PAN_TILT_ZOOM;
    const query = { id: cameraRemoteId };
    if (options.continuous) {
      query.continuous = options.continuous;
    } else if (options.fieldOfView) {
      query.fieldOfView = options.fieldOfView;
    }
    webRtcHelper.sendCommand({ dispatch, route, query, serverId, cameraId });
  };
}

export function getPtzPresets(webRtcHelper, cameraId, cameraRemoteId) {
  return dispatch => {
    const route = webRtcRoutes.GET_PTZ_PRESETS;
    const query = { id: cameraRemoteId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function createPtzPreset(
  webRtcHelper,
  cameraId,
  cameraRemoteId,
  presetId,
  name,
  isHome,
) {
  return dispatch => {
    const route = webRtcRoutes.CREATE_PTZ_PRESET;
    const query = { id: cameraRemoteId, presetId, name, isHome };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function goToPtzPreset(
  webRtcHelper,
  cameraId,
  cameraRemoteId,
  presetId,
) {
  return dispatch => {
    const route = webRtcRoutes.GO_TO_PTZ_PRESET;
    const query = { id: cameraRemoteId, presetId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function goToHomePtzPresetGateway(serverId, cameraRemoteId) {
  return dispatch => {
    const url = urlBuilder(GO_TO_HOME_PTZ_PRESET, serverId);
    const body = Object.assign({ id: cameraRemoteId });

    return sendPutRequestReturningJSON(url, body).catch(ex => {
      dispatch(
        showMessage(messageTypes.PTZ_GO_TO_HOME_ERROR, null, null, {
          messageStyle: messageStyleStrings.error,
          translateBody: 'PTZ.PRESETS.GO_TO_HOME_PRESET_ERROR',
        }),
      );
      avoLogError('Error sending ptz to home', ex);
    });
  };
}

export function goToHomePtzPreset(webRtcHelper, cameraId, cameraRemoteId) {
  return dispatch => {
    const route = webRtcRoutes.GO_TO_HOME_PTZ_PRESET;
    const query = { id: cameraRemoteId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function deletePtzPreset(
  webRtcHelper,
  cameraId,
  cameraRemoteId,
  presetId,
) {
  return dispatch => {
    const route = webRtcRoutes.DELETE_PTZ_PRESET;
    const query = { id: cameraRemoteId, presetId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function addPtzTour(webRtcHelper, cameraId, cameraRemoteId, tourData) {
  return dispatch => {
    const route = webRtcRoutes.CREATE_PTZ_TOUR;
    const query = { id: cameraRemoteId, tour: tourData };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function updatePtzTour(
  webRtcHelper,
  cameraId,
  cameraRemoteId,
  tourData,
) {
  return dispatch => {
    const route = webRtcRoutes.UPDATE_PTZ_TOUR;
    const query = { id: cameraRemoteId, tour: tourData };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function getPtzTours(webRtcHelper, cameraId, cameraRemoteId) {
  return dispatch => {
    const route = webRtcRoutes.GET_PTZ_TOURS;
    const query = { id: cameraRemoteId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function startPtzTour(webRtcHelper, cameraId, cameraRemoteId, tourId) {
  return dispatch => {
    const route = webRtcRoutes.START_PTZ_TOUR;
    const query = { id: cameraRemoteId, tourId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}

export function stopTour(webRtcHelper, deviceId, cameraId, cameraRemoteId) {
  return dispatch => {
    const route = webRtcRoutes.STOP_PTZ_TOUR;
    const query = { id: cameraRemoteId };
    webRtcHelper.sendCommand({ dispatch, route, query, cameraId });
  };
}
