exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".pendingRequestTableStyle___34lDy {\r\n  padding: 16px 16px 0;\r\n}\r\n\r\n.pendingRequestTableWrapper___3Tk5l{\r\n  margin-bottom: 28px;\r\n  border-bottom: 2px solid #F8F8F8;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/AccountManagement/monitoring/MonitoringStyles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;CACtB;;AAED;EACE,oBAAoB;EACpB,iCAAiC;CAClC","file":"MonitoringStyles.css","sourcesContent":[".pendingRequestTableStyle {\r\n  padding: 16px 16px 0;\r\n}\r\n\r\n.pendingRequestTableWrapper{\r\n  margin-bottom: 28px;\r\n  border-bottom: 2px solid #F8F8F8;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pendingRequestTableStyle": "pendingRequestTableStyle___34lDy",
	"pendingRequestTableWrapper": "pendingRequestTableWrapper___3Tk5l"
};