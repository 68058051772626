/* global avoLogError */

import aft from '@avo-aft/aft-client';
// Actions

// Utils
import { ai } from 'util/telemetryService';
import * as PasswordRules from 'constants/PasswordRules';
import {
  runGetActionReturningJSON,
  sendDeleteRequestReturningJSON,
  sendGetRequestReturningJSON,
  sendPostRequest,
  sendPostRequestReturningJSON,
  sendPutRequestReturningJSON,
} from '../util/fetchHelpers';
import * as cookieUtils from '../util/cookies';
import { isHMSPath, windowRedirectToHMSRoot } from '../util/hmsHelpers';
import * as urlConstants from '../constants/urlPaths';

// Consts
import * as types from '../constants/ActionTypes';
import * as modalTypes from '../constants/ModalTypes';
import * as messageTypes from '../constants/MessageTypes';
import { APP_NAME } from '../constants/app';
import * as cookieNames from '../constants/cookieNames';
import { messageStyleStrings } from '../containers/PageMessage/constants';
import {
  addUserIfNeeded,
  extractUserIdFromResponse,
  isFetching,
  isFetchingData,
} from './common';
import urlBuilder from '../queryBuilder/url';
import { showMessage, showPersistentMessage } from './pageMessage';
import { hideModal, showModal } from './modal';
import { clearFilter } from './filters';
import {
  beginRedirectingToHMSRoot,
  stopRedirectingToHMSRoot,
} from './microFrontend';

function buildLogoutBody(propagateToOtherInstancesThisSession) {
  let body = {};
  if (propagateToOtherInstancesThisSession) {
    // pass up the sessionCookie value only if we need to
    // propogate the logout to other instances of this
    // cookieVal-th session
    const cookieVal = cookieUtils.getCookie(cookieNames.SESSION_COOKIE);
    body = {
      sessionId: cookieVal,
    };
  }
  return body;
}

export function receiveBannerNotices(bannerNotices) {
  return dispatch => {
    dispatch({
      bannerNotices,
      type: types.RECEIVE_BANNER_NOTICES,
    });
  };
}

export function getBannerNotices() {
  return dispatch => {
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_BANNER_NOTICES,
      onError: err => {
        avoLogError('Error fetching notices:', err);
      },
      onSuccess: json => {
        dispatch(receiveBannerNotices(json));
      },
      url: urlBuilder(types.GET_BANNER_NOTICES),
    });
  };
}

export function busy() {
  return {
    type: types.BUSY,
  };
}

export function notBusy() {
  return {
    type: types.NOT_BUSY,
  };
}

export function setUser() {
  return {
    type: types.SET_USER,
  };
}

export function loginFailure(msgTranslationId) {
  return {
    errorMessage: msgTranslationId,
    type: types.LOGIN_FAILURE,
  };
}

export function loginFailureMessage(msg) {
  return {
    errorFinalMessage: msg,
    errorMessage: null,
    type: types.LOGIN_FAILURE,
  };
}

export function HandleEULARequest() {
  return {
    type: types.EULA_ACCEPTANCE_REQUIRED,
  };
}

export function login(credentials) {
  return dispatch => {
    dispatch(busy());
    const url = urlBuilder(types.LOGIN);
    const body = {
      Email: credentials.email,
      Password: credentials.password,
      SecurityToken: credentials.code,
    };
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(setUser());
      })
      .catch(ex => {
        avoLogError('Request failed logging in: ', ex);
        ai.appInsights.trackEvent({ name: 'Login failed' });
        if (ex.status >= 400) {
          if (ex.status === 400) {
            dispatch(
              showPersistentMessage(messageTypes.LOGIN_ERROR, null, {
                messageStyle: messageStyleStrings.error,
                translateBody: 'LOGIN.WRONG_EMAIL_ERROR',
              }),
            );
          } else if (ex.status === 401) {
            dispatch(
              showPersistentMessage(
                messageTypes.LOGIN_ERROR,
                loginFailureMessage(ex.serverMessage).errorFinalMessage,
                {
                  messageStyle: messageStyleStrings.error,
                },
              ),
              loginFailure(ex.serverMessage),
            );
          } else if (ex.status === 403) {
            dispatch(HandleEULARequest());
            dispatch(
              showModal(modalTypes.EULA, {
                EulaResponseObject: ex.message.EulaApprovalRequest,
                credentials,
              }),
            );
          } else {
            dispatch(loginFailure('VIEWS.UNKNOWN_ERROR'));
          }
        } else {
          dispatch(loginFailure('VIEWS.UNKNOWN_ERROR'));
        }
      })
      .then(() => {
        dispatch(notBusy());
      });
  };
}

export function acceptEula(modalProps) {
  return dispatch => {
    dispatch(busy());
    const url = modalProps.EulaResponseObject.AcceptanceUrl;
    const body = {
      SecurityToken: modalProps.EulaResponseObject.SecurityToken,
    };
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(setUser());
      })
      .catch(ex => {
        avoLogError('Request failed Accepting EULA', ex);
        dispatch(hideModal());

        if (ex.status >= 400) {
          let errorMessage = 'EULA.EULA_GENERAL_ERROR';

          if (
            ex.Message &&
            ex.Message.toLowerCase().includes('token') &&
            ex.Message.toLowerCase().includes('older')
          ) {
            errorMessage = 'EULA.EULA_TOKEN_EXPIRED_ERROR';
          }
          dispatch(loginFailure(errorMessage));
        } else {
          dispatch(loginFailure(ex.message));
        }
      })
      .then(() => {
        dispatch(notBusy());
      });
  };
}
export function logout(propagateToOtherInstancesThisSession = true) {
  return dispatch => {
    dispatch(busy());
    const url = urlBuilder(types.LOGOUT);
    const body = buildLogoutBody(propagateToOtherInstancesThisSession);
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        // force browser to reload app.  this should clear cache and hopefully prevent
        // new session cookie from being set by another callback
        const { location } = window;
        const port = location.port ? `:${location.port}` : '';
        const tempUri = `${location.protocol}//${location.hostname}${port}${urlConstants.PATH_LOGIN}`;
        window.location.assign(tempUri);
        window.sessionStorage.clear();
      })
      .catch(() => {
        window.location.reload();
      });
  };
}

export function invalidateUserSession(
  propagateToOtherInstancesThisSession = true,
) {
  return () => {
    const url = urlBuilder(types.LOGOUT);
    const body = buildLogoutBody(propagateToOtherInstancesThisSession);
    return sendPostRequestReturningJSON(url, body).catch(e =>
      avoLogError('Error logging out', e),
    );
  };
}

export function receiveUsers(users) {
  return {
    type: types.RECEIVE_USERS,
    users,
  };
}

export function receiveUsersPublicInfo(users, orgId, publicDataSubset = true) {
  return {
    publicDataSubset, // flags data is from GET /UsersPublicInfo and many fields will be missing
    scope: orgId,
    type: types.RECEIVE_USERS_PUBLIC_INFO,
    users,
  };
}

export function receiveUser(user) {
  return {
    type: types.RECEIVE_USER,
    user,
  };
}

export function getUsers(queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.GET_USERS, 0, 0, queryOptions);
    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_USERS,
      onError: ex => {
        dispatch(
          showMessage(messageTypes.USER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'USERS.USERS_ACTIONS.GET_USER_ERROR',
          }),
        );
        dispatch(receiveUsers([]));
        avoLogError('Request failed getting Users: ', ex);
      },
      onSuccess: json => dispatch(receiveUsers(json.Items)),
      url,
    });
  };
}

export function getUsersPublicInfo(orgId) {
  return dispatch => {
    const url = urlBuilder(types.GET_USERS_PUBLIC_INFO, 0, 0, { orgId });
    return runGetActionReturningJSON({
      dispatch,
      fetchScope: orgId,
      fetchType: types.GET_USERS_PUBLIC_INFO,
      onError: ex => {
        dispatch(
          showMessage(messageTypes.USER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'USERS.USERS_ACTIONS.GET_USER_ERROR',
          }),
        );
        avoLogError('Request failed getting users public info ', ex);
      },
      onSuccess: json => dispatch(receiveUsersPublicInfo(json, orgId)),
      url,
    });
  };
}

export function getUsersForTenant(orgId) {
  // This action fetches users for a tenant the user is not currently logged in as.
  // If the user is not authorized for the full user list, fall back on getUsersPublicInfo
  return dispatch => {
    const url = urlBuilder(types.GET_USERS, null, null, null, { orgId });
    return runGetActionReturningJSON({
      dispatch,
      fetchScope: orgId,
      fetchType: types.GET_USERS_PUBLIC_INFO,
      onError: () => {
        dispatch(getUsersPublicInfo(orgId));
      },
      onSuccess: json => {
        dispatch(receiveUsersPublicInfo(json.Items, orgId, false));
      },
      url,
    });
  };
}

export function getUser(id) {
  return dispatch => {
    const url = urlBuilder(types.GET_USER, id);
    return sendGetRequestReturningJSON(url)
      .then(json => dispatch(receiveUser(json)))
      .catch(ex => {
        avoLogError('Request failed getting User ', ex);
      });
  };
}

export function removeUser(id) {
  return {
    id,
    type: types.REMOVE_USER,
  };
}

export function resendInvite(userId) {
  // pass id to completion callback
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.RESEND_INVITE, userId);
    return sendPostRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.USER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'USER_ACTIONS.RESEND_INVITE_OK',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.USER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'USER_ACTIONS.RESEND_INVITE_ERROR',
          }),
        );
        avoLogError('Error resending invite', ex);
      });
  };
}

export function clearError() {
  return {
    error: null,
    type: types.CLEAR_ERROR,
  };
}

export function requestResetPassword(email) {
  // pass id to completion callback
  return dispatch => {
    dispatch(clearError());
    const url = urlBuilder(types.REQUEST_RESET_PASSWORD, 0, 0, {
      other: [
        {
          key: 'email',
          value: email,
        },
      ],
    });
    const body = {
      email,
    };
    return sendPostRequestReturningJSON(url, body)
      .then(() => {
        dispatch(
          showModal(modalTypes.REGISTRATION_SUCCESS, {
            header: 'RESET_PASSWORD.PASSWORD_HEADER',
            modalText: 'RESET_PASSWORD.PASSWORD_OK',
          }),
        );
      })
      .catch(ex => {
        const errorMessage = ex.message;
        if (errorMessage.includes('locked')) {
          dispatch(
            showPersistentMessage(
              messageTypes.REQUEST_RESET_PASSWORD_ERROR,
              null,
              {
                messageStyle: messageStyleStrings.error,
                translateBody:
                  'RESET_PASSWORD.RESET_PASSWORD_ACCOUNT_LOCKED_ERROR',
              },
            ),
          );
        } else if (errorMessage.includes('not enabled')) {
          dispatch(
            showPersistentMessage(
              messageTypes.REQUEST_RESET_PASSWORD_ERROR,
              null,
              {
                messageStyle: messageStyleStrings.error,
                translateBody:
                  'RESET_PASSWORD.RESET_PASSWORD_ACCOUNT_NOT_ENABLED_ERROR',
              },
            ),
          );
        } else {
          dispatch(
            showPersistentMessage(
              messageTypes.REQUEST_RESET_PASSWORD_ERROR,
              null,
              {
                messageStyle: messageStyleStrings.error,
                translateBody: 'RESET_PASSWORD.WRONG_EMAIL_ERROR',
              },
            ),
          );
        }
      });
  };
}

export function resetPassword(userId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.RESET_PASSWORD, userId);
    return sendPostRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.USER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'RESET_PASSWORD.RESET_PASSWORD_EMAIL_SENT',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.USER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'RESET_PASSWORD.RESET_PASSWORD_ERROR',
          }),
        );
        avoLogError('Error resetting password', ex);
      });
  };
}

export function passwordChangeSuccess() {
  return {
    type: types.PASSWORD_CHANGE_SUCCESS,
  };
}

export function changePassword(formData) {
  return dispatch => {
    dispatch(busy());
    const url = urlBuilder(types.CHANGE_PASSWORD);
    const body = formData;

    return sendPutRequestReturningJSON(url, body)
      .then(() => {
        dispatch(passwordChangeSuccess());
        dispatch(
          showModal(modalTypes.REGISTRATION_SUCCESS, {
            header: 'RESET_PASSWORD.PASSWORD_RESETTED_HEADER',
            modalText: 'RESET_PASSWORD.PASSWORD_RESETTED_TEXT',
          }),
        );
      })
      .catch(ex => {
        if (ex.status >= 400) {
          if (ex.status === 401) {
            dispatch(
              showMessage(messageTypes.RESET_PASSWORD_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody: 'VIEWS.UNKNOWN_ERROR',
              }),
            );
          } else if (ex.status === 410) {
            dispatch(
              showMessage(
                messageTypes.RESET_PASSWORD_LINK_EXPIRED_ERROR,
                null,
                null,
                {
                  keepOpen: true,
                  messageStyle: messageStyleStrings.error,
                },
              ),
            );
          } else {
            dispatch(
              showMessage(messageTypes.RESET_PASSWORD_ERROR, ex.message, null, {
                messageStyle: messageStyleStrings.error,
              }),
            );
          }
        } else {
          dispatch(
            showMessage(messageTypes.RESET_PASSWORD_ERROR, null, null, {
              messageStyle: messageStyleStrings.error,
              translateBody: 'VIEWS.UNKNOWN_ERROR',
            }),
          );
        }

        avoLogError('Error changing password', ex);
      })
      .then(() => {
        dispatch(notBusy());
      });
  };
}

export function deleteUser(userId, queryOptions) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    const url = urlBuilder(types.DELETE_USER, userId);
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.USER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'USERS.FEEDBACK_MESSAGES.DELETE_USER_SUCCESS',
          }),
        );
        dispatch(getUsers(queryOptions));
      })
      .catch(ex => {
        avoLogError('Delete user failed', ex);
        dispatch(
          showMessage(messageTypes.USER_ERROR, null, ex.message, {
            messageStyle: messageStyleStrings.error,
          }),
        );
      });
  };
}

export function deleteUsers(ids, queryOptions) {
  return dispatch => {
    ids.forEach(id => dispatch(deleteUser(id, queryOptions)));
  };
}

export function addUser(userData, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.ADD_USER, queryOptions.orgId);
    return sendPostRequestReturningJSON(url, userData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.USER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'USERS.FEEDBACK_MESSAGES.ADD_USER_SUCCESS',
          }),
        );
        dispatch(getUsers(queryOptions));
      })
      .catch(ex => {
        avoLogError('Add user failed: ', ex);
        dispatch(
          showMessage(messageTypes.USER_ERROR, ex.serverMessage, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
      });
  };
}

export function unsetUser() {
  return {
    type: types.UNSET_USER,
  };
}

export function editUser(userData, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.EDIT_USER, userData.Id);
    return sendPutRequestReturningJSON(url, userData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.USER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'GENERIC_ACTION_MESSAGES.EDIT_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        avoLogError('Edit user failed', ex.serverMessage);
        if (ex.status >= 400) {
          return dispatch(
            showMessage(
              messageTypes.USER_ERROR,
              ex.serverMessage || null,
              null,
              {
                body: ex.ExceptionMessage || ex.Message,
                messageStyle: messageStyleStrings.error,
              },
            ),
          );
        }
        return dispatch(
          showMessage(messageTypes.USER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'GENERIC_ACTION_MESSAGES.EDIT_ERROR',
          }),
        );
      });
  };
}

export function register(credentials) {
  return dispatch => {
    dispatch(busy());
    const url = urlBuilder(types.REGISTER);
    return sendPostRequestReturningJSON(url, credentials)
      .then(() => {
        dispatch(showModal(modalTypes.REGISTRATION_SUCCESS, {}));
        dispatch(unsetUser());
      })
      .catch(ex => {
        if (ex.status === 410) {
          dispatch(
            showPersistentMessage(messageTypes.SIGNUP_ERROR, null, {
              messageStyle: messageStyleStrings.error,
              translateBody: 'SIGNUP.EXPIRED_ERROR',
            }),
          );
        } else {
          avoLogError('Error registering: ', ex);
          dispatch(loginFailure('GENERIC_ACTION_MESSAGES.GENERIC_ERROR'));
        }
      })
      .then(() => {
        dispatch(notBusy());
      });
  };
}

export function registerSelfProvisioned(registrationData) {
  return dispatch => {
    dispatch(busy());
    const url = urlBuilder(types.REGISTER_UNPROVISIONED);
    return sendPostRequestReturningJSON(url, registrationData)
      .then(() => {
        dispatch(showModal(modalTypes.UNPROVISIONED_REGISTRATION_SUCCESS, {}));
        dispatch(unsetUser());
      })
      .catch(ex => {
        if (ex.status === 410) {
          dispatch(
            showPersistentMessage(messageTypes.SIGNUP_ERROR, null, {
              messageStyle: messageStyleStrings.error,
              translateBody: 'SIGNUP.EXPIRED_ERROR',
            }),
          );
        } else {
          avoLogError('Error registering: ', ex);
          dispatch(loginFailure('GENERIC_ACTION_MESSAGES.GENERIC_ERROR'));
        }
      })
      .then(() => {
        dispatch(notBusy());
      });
  };
}

export function receiveCurrentOrganization(currentOrg) {
  return {
    organization: currentOrg,
    type: types.RECEIVE_CURRENT_ORGANIZATION,
  };
}

export function getCurrentOrganization(orgId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CURRENT_ORGANIZATION));
    const url = urlBuilder(types.GET_CURRENT_ORGANIZATION, orgId);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveCurrentOrganization(json));
      })
      .catch(err => {
        avoLogError('Error fetching current organization', err);
      });
  };
}

export function receiveOrganizations(organizations) {
  return {
    organizations,
    type: types.RECEIVE_ORGANIZATIONS,
  };
}

// other orgs that the user belongs to
export function getOrganizations() {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));
    const url = urlBuilder(types.GET_ORGANIZATIONS);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveOrganizations(json));
      })
      .catch(ex => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA, false));
        avoLogError('Error getting organizations: ', ex);
      });
  };
}

export function receiveCustomers(customers, nextPageLink, preserveOldData) {
  return {
    customers,
    nextPageLink,
    preserveOldData,
    type: types.RECEIVE_CUSTOMERS,
  };
}

// LIST OF CUSTOMERS FOR DROPDOWN
export function getCustomers(requestQueryOptions) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));

    const url = urlBuilder(
      types.GET_CUSTOMERS,
      null,
      null,
      requestQueryOptions,
      null,
    );

    return sendGetRequestReturningJSON(url)
      .then(json => {
        const jsonItems = json.Items || json;
        dispatch(receiveCustomers(jsonItems, json.NextPageLink, false));
      })
      .catch(ex => {
        avoLogError('Error getting customers:', ex);
      })
      .then(() => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA, false));
      });
  };
}

export function getCustomersNextPage(nextPageLink) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));

    return sendGetRequestReturningJSON(nextPageLink)
      .then(json => {
        const jsonItems = json.Items || json;
        dispatch(receiveCustomers(jsonItems, json.NextPageLink, true));
      })
      .catch(ex => {
        avoLogError('Error getting customers:', ex);
      })
      .then(() => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA, false));
      });
  };
}

export function receiveCustomerOrganizations(customerOrganizations) {
  return {
    customerOrganizations,
    type: types.RECEIVE_CUSTOMER_ORGANIZATIONS,
  };
}

export function updateInfiniteData(type, queryOptions) {
  return {
    queryOptions,
    tableType: type,
    type: types.UPDATE_CUSTOMER_ORGANIZATIONS_INFINITE_FILTER,
  };
}

export function receivePartners(partners) {
  return {
    partners,
    type: types.RECEIVE_PARTNERS,
  };
}

export function getPartners() {
  return dispatch => {
    const url = urlBuilder(types.GET_PARTNERS);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receivePartners(json));
      })
      .catch(ex => {
        avoLogError('Error getting partners', ex);
      });
  };
}

// LIST OF CUSTOMERS FOR TABLE IN ACCOUNTS/CUSTOMERS
export function getCustomerOrganizations(
  queryOptions,
  tableFilterId = 'orgTable',
) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_ORGANIZATIONS));
    const url = urlBuilder(
      types.GET_CUSTOMER_ORGANIZATIONS,
      0,
      0,
      queryOptions,
    );
    return sendGetRequestReturningJSON(url)
      .then(json => {
        const jsonItems = json.Items || json; // Account for v2 API
        dispatch(
          updateInfiniteData(
            tableFilterId,
            jsonItems.length > 0 ? queryOptions : null,
          ),
        );
        dispatch(receiveCustomerOrganizations(json));
        dispatch(getPartners());
      })
      .catch(ex => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_ORGANIZATIONS, false));
        avoLogError('Error getting customer organizations', ex);
      });
  };
}

function clearCustomerOrganizations(toDeleteOrgId) {
  return {
    toDeleteOrgId,
    type: types.CLEAR_CUSTOMER_ORGANIZATIONS,
  };
}

export function updateCustomerOrganizations(
  currentFilter,
  currentFilterId,
  toDeleteOrgId,
) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_ORGANIZATIONS));
    const url = urlBuilder(
      types.GET_CUSTOMER_ORGANIZATIONS,
      null,
      null,
      currentFilter,
    );
    dispatch(clearFilter(currentFilterId));
    sendGetRequestReturningJSON(url)
      .then(response => {
        const items = response.Items || response;
        if (items.length >= 0) {
          dispatch(clearCustomerOrganizations(toDeleteOrgId));
          dispatch(updateInfiniteData(currentFilterId, currentFilter));
          dispatch(receiveCustomerOrganizations(items));
        }
      })
      .finally(() => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_ORGANIZATIONS, false));
      });
  };
}

export function receiveCustomerOrganization(customerOrganization) {
  return {
    customerOrganization,
    type: types.RECEIVE_CUSTOMER_ORGANIZATION,
  };
}

// SINGLE ENTITY FOR TABLE IN ACCOUNTS/CUSTOMERS
export function getCustomerOrganization(id) {
  return dispatch => {
    const url = urlBuilder(types.GET_CUSTOMER_ORGANIZATION, id);
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveCustomerOrganization(json));
      })
      .catch(ex => {
        avoLogError('Error getting customer org', { ex, id });
      });
  };
}

// Customer Actions
export function addCustomer(formData, orgId, queryOptions, tableFilterId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_DEVICE_DATA));
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));
    const url = urlBuilder(types.ADD_CUSTOMER, null, null, null, {
      orgId,
    });
    return sendPostRequestReturningJSON(url, formData)
      .then(json => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'CUSTOMERS.CUSTOMERS_ACTIONS.ADD_CUSTOMER_SUCCESS',
          }),
        );
        // Fetch freshly minted Customer(Dealer/Subscriber) on the successul request
        dispatch(getUsersPublicInfo(json.id));
        dispatch(getCustomerOrganizations(queryOptions, tableFilterId)); // Refresh table
        dispatch(getCustomers({})); // Refresh dropdown
        dispatch(getOrganizations());
        return json;
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CUSTOMERS.CUSTOMERS_ACTIONS.ADD_CUSTOMER_ERROR',
          }),
        );
        dispatch(isFetching(types.CUSTOMER_DATA_FETCH_COMPLETED));
        avoLogError('Error adding customer', ex);
      });
  };
}

export function updateCustomerOrganization(data) {
  return {
    data,
    type: types.UPDATE_CUSTOMER_ORGANIZATION,
  };
}

export function editCustomer(formData, orgId) {
  return dispatch => {
    addUserIfNeeded(formData, orgId)
      .then(json => {
        return {
          ...formData,
          AdministratorId: extractUserIdFromResponse(json),
        };
      })
      .then(receivedFormData => {
        dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));
        const url = urlBuilder(types.EDIT_CUSTOMER, null, null, null, {
          orgId,
        });
        sendPutRequestReturningJSON(url, receivedFormData)
          .then(() => {
            dispatch(
              showMessage(messageTypes.EDIT_CUSTOMER_SUCCESS, null, null, {
                messageStyle: messageStyleStrings.success,
                translateBody:
                  'CUSTOMERS.CUSTOMERS_ACTIONS.EDIT_CUSTOMER_SUCCESS',
              }),
            );
            dispatch(updateCustomerOrganization(receivedFormData));
            dispatch(getUsersForTenant(orgId));
            dispatch(getUserProfile());
            dispatch(resetUserContext());
          })
          .catch(ex => {
            dispatch(
              showMessage(messageTypes.CUSTOMER_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody:
                  'CUSTOMERS.CUSTOMERS_ACTIONS.EDIT_CUSTOMER_ERROR',
              }),
            );
            dispatch(isFetching(types.CUSTOMER_DATA_FETCH_COMPLETED));
            avoLogError('Error editing customer', ex);
          });
      })
      .catch(err => {
        avoLogError('Edit customer failed: ', err);
        dispatch(
          showMessage(messageTypes.CUSTOMER_ERROR, err.serverMessage, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
      });
  };
}

export function deleteCustomer(customerId, queryOptions) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CUSTOMER_DATA));
    const url = urlBuilder(types.DELETE_CUSTOMER, customerId);
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CUSTOMERS.CUSTOMERS_ACTIONS.DELETE_CUSTOMER_SUCCESS',
          }),
        );
        dispatch(getCustomerOrganizations(queryOptions));
      })
      .catch(() => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'CUSTOMERS.CUSTOMERS_ACTIONS.DELETE_CUSTOMER_ERROR',
          }),
        );
        dispatch(isFetching(types.CUSTOMER_DATA_FETCH_COMPLETED));
      });
  };
}

export function removeDeletedCustomer(deletedOrganizationId) {
  return {
    deletedOrganizationId,
    type: types.REMOVE_DELETED_CUSTOMER,
  };
}

export function deleteCustomers(ids, queryOptions) {
  return dispatch => {
    ids.forEach(id => dispatch(deleteCustomer(id, queryOptions)));
  };
}
// Customer Actions

export function receiveUserProfile(profile) {
  return {
    profile,
    type: types.RECEIVE_USER_PROFILE,
  };
}

export function getUserProfile() {
  return dispatch => {
    const url = urlBuilder(types.GET_USER_PROFILE);
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_USER_PROFILE,
      onError: err => {
        avoLogError('Error fetching user profile:', err);
        dispatch(loginFailure());
      },
      onSuccess: json => {
        const environment = new URL(url).origin;
        aft.config({
          clientName: APP_NAME,
          environment,
          tenantId: json.TenantId,
        });
        dispatch(receiveUserProfile(json));
      },
      url,
    });
  };
}

export function editUserProfile(formData) {
  return dispatch => {
    const url = urlBuilder(types.EDIT_USER_PROFILE);
    return sendPutRequestReturningJSON(url, formData)
      .then(() => {
        dispatch(getUserProfile());
        dispatch(
          showMessage(messageTypes.PROFILE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'PROFILE.EDIT_PROFILE_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.PROFILE_ERROR, ex.message, null, {
            messageStyle: messageStyleStrings.error,
          }),
        );
        avoLogError('Error editing profile', ex);
      });
  };
}

export function resendCustomerInvite(customerId) {
  return dispatch => {
    const url = urlBuilder(types.RESEND_CUSTOMER_INVITE, customerId);
    return sendPostRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'CUSTOMERS.CUSTOMERS_ACTIONS.RESEND_CUSTOMER_INVITE_SUCCESS',
          }),
        );
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.CUSTOMER_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CUSTOMERS.CUSTOMERS_ACTIONS.RESEND_CUSTOMER_INVITE_ERROR',
          }),
        );
        avoLogError('Error resending customer invite', ex);
      });
  };
}

export function updateUserTenantId(id) {
  return {
    id,
    type: types.CHANGE_CUSTOMER_CONTEXT,
  };
}

export function resetUserContext() {
  return {
    type: types.RESET_USER_CONTEXT,
  };
}

export function setCustomerContext(id) {
  return dispatch => {
    // Using the left hand menu, proxy into a customer organization.
    // update aft.config to use the aft channel THEN
    // update tenant information in redux with CHANGE_CUSTOMER_CONTEXT
    // clear out other org-specific data with resetUserContext and
    // repopulate with a call to getCurrentOrganization
    let environment = '';
    if (process.env.API_ENV === 'dev') {
      if (localStorage.devHost) {
        environment =
          urlConstants.PROTOCOL + localStorage.devHost.replace('/', '');
      } else {
        environment =
          urlConstants.PROTOCOL + urlConstants.HOST_DEV.replace('/', '');
      }
    } else {
      environment = urlConstants.PROTOCOL + urlConstants.HOST.replace('/', '');
    }
    aft.config({
      clientName: APP_NAME,
      environment,
      tenantId: id,
    });

    const runDispatches = () => {
      dispatch(updateUserTenantId(id));
      dispatch(resetUserContext());
      dispatch(getCurrentOrganization(id));
    };
    if (isHMSPath()) {
      dispatch(beginRedirectingToHMSRoot());
      setTimeout(() => {
        runDispatches();
        dispatch(stopRedirectingToHMSRoot());
      }, 100);
    } else {
      runDispatches();
    }
  };
}

export function setOrganizationContext(id) {
  const cookieVal = cookieUtils.getCookie(cookieNames.SESSION_COOKIE);
  // Pass the cookie as a correlation id to indicate which browser session
  // originated the setOrganizationContext request
  return dispatch => {
    const url = urlBuilder(types.CHANGE_ORGANIZATION_CONTEXT, id);
    dispatch(isFetchingData(types.GET_USER_PROFILE, true));
    // Set isFetchingProfile to disable the user switch selector
    // TODO: Consider using a specialized isFetching flag rather than overloading
    // isFetchingProfile
    sendPutRequestReturningJSON(url, {}, {}, { 'correlation-id': cookieVal })
      .then(() => {
        if (isHMSPath()) {
          windowRedirectToHMSRoot();
        }
        window.location.reload();
      })
      .catch(ex => {
        avoLogError('Error setting organization context', ex);
      });
  };
}

export function setUserContext() {
  // The server signals that you have changed to a new user account.
  // clear out the redux store with resetUserContext THEN
  // call get profile to re-populate user information THEN
  // resubscribe to signalR
  return dispatch => {
    dispatch(resetUserContext());
    dispatch(getUserProfile());
  };
}

export function receiveSchedules(schedules) {
  return {
    schedules,
    type: types.RECEIVE_SCHEDULES,
  };
}

export function getSchedules(queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.GET_SCHEDULES, 0, 0, queryOptions);
    runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_SCHEDULES,
      onError: err => {
        avoLogError('Error getting schedules', err);
      },
      onSuccess: json => {
        dispatch(receiveSchedules(json));
      },
      url,
    });
  };
}

export function addSchedule(scheduleData, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.ADD_SCHEDULE);
    return sendPostRequestReturningJSON(url, scheduleData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.SCHEDULE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'SCHEDULES.FEEDBACK.ADD_SCHEDULE_SUCCESS',
          }),
        );
        dispatch(getSchedules(queryOptions));
      })
      .catch(ex => {
        dispatch(
          ex.message
            ? showMessage(messageTypes.SCHEDULE_ERROR, null, ex.message, {
                messageStyle: messageStyleStrings.error,
              })
            : showMessage(messageTypes.SCHEDULE_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                messageType: messageTypes.SCHEDULE_ERROR,
                translateBody: 'SCHEDULES.FEEDBACK.ADD_SCHEDULE_ERROR',
              }),
        );
        avoLogError('Error adding a schedule', ex);
      });
  };
}

export function editSchedule(scheduleData, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.EDIT_SCHEDULE, scheduleData.Id);
    return sendPutRequestReturningJSON(url, scheduleData)
      .then(() => {
        dispatch(
          showMessage(messageTypes.SCHEDULE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'SCHEDULES.FEEDBACK.EDIT_SCHEDULE_SUCCESS',
          }),
        );
        dispatch(getSchedules(queryOptions));
      })
      .catch(ex => {
        avoLogError('Error editing schedule', ex);
        dispatch(
          ex.message
            ? showMessage(messageTypes.SCHEDULE_ERROR, null, ex.message, {
                messageStyle: messageStyleStrings.error,
              })
            : showMessage(messageTypes.SCHEDULE_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody: 'SCHEDULES.FEEDBACK.EDIT_SCHEDULE_ERROR',
              }),
        );
      });
  };
}

export function deleteSchedule(scheduleId, queryOptions) {
  return dispatch => {
    const url = urlBuilder(types.DELETE_SCHEDULE, scheduleId);
    return sendDeleteRequestReturningJSON(url)
      .then(() => {
        dispatch(
          showMessage(messageTypes.SCHEDULE_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'SCHEDULES.FEEDBACK.DELETE_SCHEDULE_SUCCESS',
          }),
        );
        dispatch(getSchedules(queryOptions));
      })
      .catch(ex => {
        avoLogError('Error deleting schedule', ex);
        dispatch(
          ex.status === 409
            ? showMessage(messageTypes.SCHEDULE_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody:
                  'SCHEDULES.FEEDBACK.DELETE_SCHEDULE_IN_USE_ERROR',
              })
            : showMessage(messageTypes.SCHEDULE_ERROR, null, null, {
                messageStyle: messageStyleStrings.error,
                translateBody: 'SCHEDULES.FEEDBACK.DELETE_SCHEDULE_ERROR',
              }),
        );
      });
  };
}

export function receiveProviders(providers) {
  return {
    providers,
    type: types.RECEIVE_PROVIDERS,
  };
}

export function redirectAfterLogin(redirectToOnSuccess) {
  return {
    redirectToOnSuccess,
    type: types.REDIRECT_AFTER_LOGIN,
  };
}

export function updateNot3OldPasswordsStatus(passwordFieldName, status) {
  return {
    passwordFieldName,
    status,
    type: types.UPDATE_NOT_3_OLD_PASSWORD_STATUS,
  };
}

export function requestValidateNot3OldPasswordsStatus(
  passwordFieldName,
  formData,
) {
  return dispatch => {
    dispatch(
      updateNot3OldPasswordsStatus(passwordFieldName, PasswordRules.PENDING),
    );
    const url = urlBuilder(types.VALIDATE_PASSWORD);
    sendPostRequest(url, formData)
      .then(response => {
        if (response.status === 200) {
          dispatch(
            updateNot3OldPasswordsStatus(
              passwordFieldName,
              PasswordRules.PASSED,
            ),
          );
        } else {
          dispatch(
            updateNot3OldPasswordsStatus(
              passwordFieldName,
              PasswordRules.FAILED,
            ),
          );
        }
      })
      .catch(err => {
        updateNot3OldPasswordsStatus(passwordFieldName, PasswordRules.FAILED);
        avoLogError('Error validating password', err);
      });
  };
}

export function receiveSubscriberServicePackages(subscriberServicePackages) {
  return {
    subscriberServicePackages,
    type: types.RECEIVE_SUBSCRIBER_SERVICE_PACKAGES,
  };
}

export function getSubscriberServicePackages(orgId) {
  return dispatch => {
    dispatch(isFetchingData(types.GET_SUBSCRIBER_SERVICE_PACKAGES, true, {}));
    const url = urlBuilder(types.GET_SUBSCRIBER_SERVICE_PACKAGES, orgId);
    sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveSubscriberServicePackages(json.Items));
        dispatch(
          isFetchingData(types.GET_SUBSCRIBER_SERVICE_PACKAGES, false, {}),
        );
      })
      .catch(e => {
        dispatch(
          isFetchingData(types.GET_SUBSCRIBER_SERVICE_PACKAGES, null, {}),
        );
        avoLogError('Error getting subscriber service packages', e);
      });
  };
}

export function setPermissions() {
  return {
    type: types.SET_PERMISSIONS,
  };
}
