exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".changeIndicator___3obdi {\r\n  height: 12px;\r\n  width: 12px;\r\n  border-radius: 50%;\r\n  margin-right: 8px;\r\n  background-color: #005daa;\r\n  -ms-flex-item-align: center;\r\n      -ms-grid-row-align: center;\r\n      align-self: center;\r\n}\r\n\r\n.siteName___24PvJ {\r\n  margin-left: 16px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/AccountManagement/sites/SiteSubscriptions/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,4BAA4B;MACxB,2BAA2B;MAC3B,mBAAmB;CACxB;;AAED;EACE,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".changeIndicator {\r\n  height: 12px;\r\n  width: 12px;\r\n  border-radius: 50%;\r\n  margin-right: 8px;\r\n  background-color: #005daa;\r\n  -ms-flex-item-align: center;\r\n      -ms-grid-row-align: center;\r\n      align-self: center;\r\n}\r\n\r\n.siteName {\r\n  margin-left: 16px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"changeIndicator": "changeIndicator___3obdi",
	"siteName": "siteName___24PvJ"
};