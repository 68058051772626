import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconViewDropdown2x2 = props => {
  const { className, fill, height, translate, width } = props;
  return (
    <svg
      className={className}
      height={height}
      version="1.1"
      viewBox="0 0 17 15"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{translate('VIEWS.LAYOUT_TOOLTIP')}</title>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={fill} transform="translate(-1045.000000, -38.000000)">
          <g transform="translate(110.000000, 20.000000)">
            <path d="M935,18 L943.052632,18 L943.052632,25 L935,25 L935,18 Z M943.947368,18 L952,18 L952,25 L943.947368,25 L943.947368,18 Z M935,26 L943.052632,26 L943.052632,33 L935,33 L935,26 Z M943.947368,26 L952,26 L952,33 L943.947368,33 L943.947368,26 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconViewDropdown2x2.defaultProps = {
  className: null,
  fill: '#000000',
  height: '15px',
  width: '17px',
};

IconViewDropdown2x2.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconViewDropdown2x2);
