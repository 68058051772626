export function calculateFramePeriod(interval, rate) {
  let period = 0;
  if (
    interval &&
    interval.val &&
    interval.val > 0 &&
    rate &&
    rate.val &&
    rate.val > 0
  ) {
    period = (interval.val / rate.val).toFixed(2);
  }
  return period;
}
