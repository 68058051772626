import {
  PTZ_BASE_CAPABILITY,
  PTZ_MINIMUM_SERVER_FIRMWARE_VERSION,
} from 'constants/app';
import jsonParseRobust from './jsonParseRobust';
import ComparableFirmwareVersion from './ComparableFirmwareVersion';

export const CAMERA_PERMISSIONS = {
  CAN_REBOOT_CAMERA: 'CAN_REBOOT_CAMERA',
  SUPPORTS_PTZ: 'SUPPORTS_PTZ',
  CAN_AUTO_FOCUS: 'CAN_AUTO_FOCUS',
  CAN_MANUAL_FOCUS: 'CAN_MANUAL_FOCUS',
};

export const CAMERA_CAPABILITY = {
  REBOOT: 'REBOOT',
  CONTROL_LEDS: 'CONTROL_LEDS',
  PRIVACY_ZONES: 'PRIVACY_ZONES',
  PRIVACY_ZONE_POSITION: 'PRIVACY_ZONE_POSITION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  DISABLE_CONTROLS: 'DISABLE_CONTROLS',
  AUTO_IP_CONF: 'AUTO_IP_CONF',
  MANUAL_IP_CONF: 'MANUAL_IP_CONF',
  HTTP_PORT: 'HTTP_PORT',
  MULTICAST: 'MULTICAST',
  MJPEG: 'MJPEG',
  H264: 'H264',
  MJPEG_DEC: 'MJPEG_DEC',
  H264_DEC: 'H264_DEC',
  RESOLUTION: 'RESOLUTION',
  VARIABLE_BIT_RATE: 'VARIABLE_BIT_RATE',
  VBR_IMAGE_RATE: 'VBR_IMAGE_RATE',
  VBR_IMAGE_QUALITY: 'VBR_IMAGE_QUALITY',
  MULTI_STREAMING: 'MULTI_STREAMING',
  VBR_MAX_BIT_RATE: 'VBR_MAX_BIT_RATE',
  VBR_KEY_FRAME_INTERVAL_FRAMES: 'VBR_KEY_FRAME_INTERVAL_FRAMES',
  BACKLIGHT_COMP_LEVEL: 'BACKLIGHT_COMP_LEVEL',
  COLOUR_SATURATION: 'COLOUR_SATURATION',
  SHARPNESS: 'SHARPNESS',
  IR_CUT_FILTER: 'IR_CUT_FILTER',
  WHITE_BALANCE_AUTO: 'WHITE_BALANCE_AUTO',
  WHITE_BALANCE_MANUAL: 'WHITE_BALANCE_MANUAL',
  FLICKER_CONTROL: 'FLICKER_CONTROL',
  ADATIVE_IR: 'ADATIVE_IR',
  FOCUS_AUTO: 'FOCUS_AUTO',
  FOCUS_MANUAL: 'FOCUS_MANUAL',
  FOCUS_ONE_SHOT: 'FOCUS_ONE_SHOT',
  FOCUS_ADJ_IR_ILLUM_TYPE: 'FOCUS_ADJ_IR_ILLUM_TYPE',
  IMAGE_ROTATION: 'IMAGE_ROTATION',
  RESTORE_DEFAULTS: 'RESTORE_DEFAULTS',
  EXPOSURE_AUTO: 'EXPOSURE_AUTO',
  EXPOSURE_AUTO_PRIORITY: 'EXPOSURE_AUTO_PRIORITY',
  EXPOSURE_AUTO_MAX_VALUE: 'EXPOSURE_AUTO_MAX_VALUE',
  EXPOSURE_MANUAL: 'EXPOSURE_MANUAL',
  GAIN_AUTO_MAX_VALUE: 'GAIN_AUTO_MAX_VALUE',
  IRIS_AUTO: 'IRIS_AUTO',
  IRIS_MANUAL: 'IRIS_MANUAL',
  MASK: 'MASK',
  SENSITIVITY: 'SENSITIVITY',
  THRESHOLD: 'THRESHOLD',
  INPUT_DEFAULT_CIRCUIT_STATE: 'INPUT_DEFAULT_CIRCUIT_STATE',
  OUTPUT_DEFAULT_CIRCUIT_STATE: 'OUTPUT_DEFAULT_CIRCUIT_STATE',
  OUTPUT_ACTIVATE: 'OUTPUT_ACTIVATE',
  OUTPUT_MODE: 'OUTPUT_MODE',
  MICROPHONE_GAIN: 'MICROPHONE_GAIN',
  SPEAKER_OUTPUT: 'SPEAKER_OUTPUT',
  RECORDING_PROFILES: 'RECORDING_PROFILES',
};

export const getCameraPermission = (capabilities, permissionType) => {
  if (capabilities && permissionType) {
    const cameraCapabilities = jsonParseRobust(capabilities);
    switch (permissionType) {
      case CAMERA_PERMISSIONS.CAN_REBOOT_CAMERA: {
        return cameraCapabilities.general
          ? cameraCapabilities.general.includes(CAMERA_CAPABILITY.REBOOT)
          : false;
      }
      case CAMERA_PERMISSIONS.CAN_AUTO_FOCUS: {
        return cameraCapabilities.acquisition
          ? cameraCapabilities.acquisition.includes(
              CAMERA_CAPABILITY.FOCUS_AUTO,
            )
          : false;
      }
      case CAMERA_PERMISSIONS.CAN_MANUAL_FOCUS: {
        return cameraCapabilities.acquisition
          ? cameraCapabilities.acquisition.includes(
              CAMERA_CAPABILITY.FOCUS_MANUAL,
            )
          : false;
      }
      default: {
        return false;
      }
    }
  } else {
    return false;
  }
};

export const minimumFirmwareVersion = new ComparableFirmwareVersion(
  PTZ_MINIMUM_SERVER_FIRMWARE_VERSION,
);

export const cameraSupportsPtz = (camera, server) => {
  if (!camera || !server) return false;

  const ptzInfo = jsonParseRobust(camera.PTZInfo);

  const hasPtzBaseCapability =
    ptzInfo &&
    ptzInfo.capabilities &&
    ptzInfo.capabilities.includes(PTZ_BASE_CAPABILITY);

  if (!hasPtzBaseCapability) {
    return false;
  }

  const serverVersion = new ComparableFirmwareVersion(server.FirmwareVersion);
  const serverSupportsRtc = serverVersion.compare(minimumFirmwareVersion) >= 0;

  return serverSupportsRtc;
};
