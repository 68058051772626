import React from 'react';

import { PropTypes } from 'prop-types';
import { Tag } from 'lib';
import { hidden, locationSubscriptionExpiredOrSuspended } from './styles.css';

const SubscriptionStateNotice = ({
  className,
  shorthand,
  subscriptionState,
}) => {
  let subscriptionStateNoticeClass = locationSubscriptionExpiredOrSuspended;
  let id = '';
  if (subscriptionState.isSuspended) {
    id = shorthand
      ? 'ACCOUNTS.LOCATIONS.SUSPENDED'
      : 'DEVICES.DEVICE_LOCATION_WRAPPER.SUBSCRIPTION_SUSPENSION_NOTICE';
  } else if (subscriptionState.isExpired) {
    id = shorthand
      ? 'ACCOUNTS.LOCATIONS.EXPIRED'
      : 'DEVICES.DEVICE_LOCATION_WRAPPER.SUBSCRIPTION_EXPIRATION_NOTICE';
  } else {
    subscriptionStateNoticeClass = hidden;
  }

  return (
    <Tag
      className={`${subscriptionStateNoticeClass} ${className}`}
      label={id}
    />
  );
};

SubscriptionStateNotice.defaultProps = {
  className: '',
  shorthand: false,
};

SubscriptionStateNotice.propTypes = {
  className: PropTypes.string,
  shorthand: PropTypes.bool,
  subscriptionState: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubscriptionStateNotice;
