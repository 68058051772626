import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';
import { Translate } from 'react-localize-redux';

// Styles
import { sectionHeader } from 'components/VerticalForm/styles.css';
import { formColumn } from '../styles.css';

// Components
import { MonitoringField } from './MonitoringFields';

class SiteEntitlementsFieldSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerHealthMonitoring: props.initialValues.DealerMonitoringEnabled,
    };
  }

  handleMonitoringChange = evt => {
    const { change } = this.props;
    const { value } = evt.target;
    change('DealerMonitoringEnabled', value);
    this.setState(({ dealerHealthMonitoring }) => ({
      dealerHealthMonitoring: !dealerHealthMonitoring,
    }));
  };

  render() {
    const { canEnableHealthMonitoring, showHealthMonitoring } = this.props;
    const { dealerHealthMonitoring } = this.state;

    return renderIf(showHealthMonitoring)(
      <fieldset className={formColumn}>
        <div className={sectionHeader}>
          <Translate id="ACCOUNTS.GENERAL_INFO.SITE_ENTITLEMENTS" />
        </div>
        <MonitoringField
          disabled={!canEnableHealthMonitoring}
          monitoringChanged={this.handleMonitoringChange}
          value={dealerHealthMonitoring}
        />
      </fieldset>,
    );
  }
}

SiteEntitlementsFieldSet.propTypes = {
  canEnableHealthMonitoring: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ DealerMonitoringEnabled: PropTypes.bool })
    .isRequired,
  showHealthMonitoring: PropTypes.bool.isRequired,
};

export default SiteEntitlementsFieldSet;
