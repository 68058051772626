// Libs
import PropTypes from 'prop-types';
import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';

// Components
import { Tooltip } from 'lib';

// Icons
import { IconInfoCircle } from 'icons';

// Styles
import {
  formField,
  formFieldTextReadOnly,
  formGroup,
  formLabel,
} from './styles.css';

// Consts
const VerticalFormFieldLayout = ({
  children,
  customFormGroup,
  extraLabel,
  label,
  labelData,
  readOnly,
  showLabel,
  tooltipProps,
  translate,
}) => (
  <div
    className={customFormGroup ? `${formGroup}${customFormGroup}` : formGroup}
  >
    {showLabel ? (
      <label className={formLabel}>
        <Translate data={labelData} id={label} />
        {extraLabel}
        {tooltipProps.message ? (
          <Tooltip
            {...tooltipProps}
            message={translate(tooltipProps.message)}
            toggleMethod="click"
          >
            <span style={{ paddingLeft: 4 }}>
              <IconInfoCircle height={10} width={10} />
            </span>
          </Tooltip>
        ) : (
          ''
        )}
      </label>
    ) : (
      ''
    )}
    <div className={readOnly ? formFieldTextReadOnly : formField}>
      {children}
    </div>
  </div>
);

VerticalFormFieldLayout.defaultProps = {
  children: null,
  customFormGroup: '',
  extraLabel: '',
  label: '',
  labelData: null,
  readOnly: false,
  showLabel: true,
  tooltipProps: {},
};

VerticalFormFieldLayout.propTypes = {
  children: PropTypes.node,
  customFormGroup: PropTypes.string,
  extraLabel: PropTypes.string,
  label: PropTypes.string,
  labelData: PropTypes.shape({}),
  readOnly: PropTypes.bool,
  showLabel: PropTypes.bool,
  tooltipProps: PropTypes.shape({
    message: PropTypes.string,
  }),
  translate: PropTypes.func.isRequired,
};

export default withLocalize(VerticalFormFieldLayout);
