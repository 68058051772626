import { Client as AftClient } from '@avo-aft/aft-client';
import { appToggles } from '../util/featureFlags';
import * as types from '../constants/ActionTypes';
import {
  isAftCatalogSync,
  isCameraNotificationEnabled,
} from '../util/aftHelper';
import { isFetchingData } from './common';

export function receiveCameraList({ cameras, clusterId, locationId }) {
  return {
    cameras,
    clusterId,
    locationId,
    type: types.AFT.UPSERT_CAMERA_LIST,
  };
}

export function receiveSiteViewTree({ clusterId, locationId, siteView }) {
  return {
    clusterId,
    locationId,
    siteView,
    type: 'AFT_RECEIVE_SITEVIEW_TREE',
  };
}

export function removeCameras({ cameraIds, clusterId, locationId }) {
  // This action affects both acc camera and site view reducers
  return {
    cameraIds,
    clusterId,
    locationId,
    type: types.AFT.REMOVE_CAMERAS,
  };
}

export async function readCameras(aftClient, clusterId, locationId) {
  try {
    const response = await aftClient.readCameras({ verbosity: 'MEDIUM' });
    const { cameras } = response.payload;
    return cameras;
  } catch (error) {
    avoLogError('Error retrieving camera list', {
      clusterId,
      error,
      locationId,
    });
    return [];
  }
}

export async function readSiteView(aftClient, clusterId, locationId) {
  try {
    const { payload: siteViewPayload } = await aftClient.readSiteView();
    const { siteView } = siteViewPayload;
    return siteView;
  } catch (error) {
    avoLogError('Error retrieving SiteView', {
      clusterId,
      error,
      locationId,
    });
    return [];
  }
}

// Respond to the notification stream for CAMERA_OBJECTS_LIGHT or END_OF_CATALOG
export const receiveCamerasNotification = (
  notification,
  cluster,
  locationId,
  dispatch,
  notificationStream,
) => {
  if (notification.type === 'END_OF_CATALOG') {
    dispatch(
      isFetchingData(types.AFT.GET_CAMERA_LIST, false, {
        fetchScope: cluster.Id,
      }),
    );
    if (!notificationStream.destroyed) {
      notificationStream.destroy();
    }
  } else if (notification.type === 'CAMERA_OBJECTS_LIGHT') {
    try {
      const { modified: cameras, removed } = notification;
      dispatch(
        receiveCameraList({ cameras, clusterId: cluster.Id, locationId }),
      );
      if (removed.length > 0) {
        dispatch(
          removeCameras({
            cameraIds: removed,
            clusterId: cluster.Id,
            locationId,
          }),
        );
      }
    } catch (error) {
      avoLogError('Error processing CAMERA_OBJECTS_LIGHT notification', {
        clusterId: cluster.Id,
        error,
        locationId,
      });
    }
  }
};

// Respond to aft notification stream closing
const onNotificationStreamClose = () => {
  // TODO: MVAAS-20553
};

export async function readSiteTree(clusterId, locationId, aftClient) {
  const siteViewEnabled = appToggles.featureIsEnabled('site-view-tree');
  if (siteViewEnabled) {
    const siteView = await readSiteView(aftClient, clusterId, locationId);
    return siteView;
  }
  return null;
}

export function refreshCameraList(locationId, cluster) {
  return async dispatch => {
    const fetchScope = cluster.Id;
    const clusterId = cluster.Id;
    try {
      if (!isAftCatalogSync(cluster.GatewayCapabilities)) {
        throw Error(
          'Does not support AFT catalogue sync. Try accessing through the Blue REST API.',
        );
      }
      const aftClient = new AftClient({ clusterId: cluster.Id });
      dispatch(
        isFetchingData(types.AFT.GET_SITEVIEW_TREE, true, { fetchScope }),
      );
      dispatch(isFetchingData(types.AFT.GET_CAMERA_LIST, true, { fetchScope }));

      // Get site view
      const siteView = await readSiteTree(clusterId, locationId, aftClient);
      if (siteView) {
        dispatch(receiveSiteViewTree({ clusterId, locationId, siteView }));
      }
      dispatch(
        isFetchingData(types.AFT.GET_SITEVIEW_TREE, false, { fetchScope }),
      );

      if (isCameraNotificationEnabled(cluster.GatewayCapabilities)) {
        // Get cameras (multiple batches - notification stream)
        const { stream: notificationStream } = await aftClient.subNotification({
          topics: ['CAMERA_OBJECTS_LIGHT', 'END_OF_CATALOG'],
        });
        notificationStream.on('data', notification =>
          receiveCamerasNotification(
            notification,
            cluster,
            locationId,
            dispatch,
            notificationStream,
          ),
        );
        // Ensure we do not receive further notifications after all camera data is received
        notificationStream.on('end', () => {
          if (!notificationStream.destroyed) {
            notificationStream.destroy();
          }
        });
        // notificationStream.eos(notificationStream, notification => onNotificationStreamClose(notification));
        // TODO: MVAAS-20553
      } else {
        // Get cameras (single call - backwards compatible)
        const cameras = await readCameras(aftClient, clusterId, locationId);
        dispatch(receiveCameraList({ cameras, clusterId, locationId }));
        dispatch(
          isFetchingData(types.AFT.GET_CAMERA_LIST, false, { fetchScope }),
        );
      }
    } catch (error) {
      dispatch(
        isFetchingData(types.AFT.GET_CAMERA_LIST, false, { fetchScope }),
      );
      dispatch(
        isFetchingData(types.AFT.GET_SITEVIEW_TREE, false, { fetchScope }),
      );
      avoLogError('Error refreshing camera list', {
        clusterId: cluster.Id,
        error,
        locationId,
      });
    }
  };
}

export function refreshAllCameraLists(clustersData) {
  return dispatch => {
    clustersData.forEach(cluster => {
      dispatch(refreshCameraList(cluster.LocationId, cluster));
    });
  };
}

function receiveAccSavedViewList({ accSavedViews, locationId }) {
  return {
    accSavedViews,
    locationId,
    type: types.RECEIVE_ACC_SAVED_VIEWS,
  };
}

export function getAccSavedViewList(clusterId, locationId) {
  return async dispatch => {
    try {
      const client = new AftClient({ clusterId });
      const response = await client.readSavedViews();
      const accSavedViews = response.payload;
      dispatch(receiveAccSavedViewList({ accSavedViews, locationId }));
    } catch (ex) {
      avoLogError('Error getting acc saved view list', {
        clusterId,
        ex,
        locationId,
      });
    }
  };
}

export function getAllAccSavedViewLists(clustersData) {
  return dispatch => {
    clustersData.forEach(({ GatewayCapabilities, Id, LocationId }) => {
      dispatch(getAccSavedViewList(Id, LocationId, GatewayCapabilities || []));
    });
  };
}

export function receiveAccServerList({ accServerList, clusterId, locationId }) {
  return {
    accServerList,
    clusterId,
    locationId,
    type: types.AFT.RECEIVE_ACC_SERVER_LIST,
  };
}

export function getAccServerList(cluster) {
  return async dispatch => {
    const { Id: clusterId, LocationId: locationId } = cluster;

    let shouldUpdateIsFetching = true;

    try {
      if (!isAftCatalogSync(cluster.GatewayCapabilities)) {
        shouldUpdateIsFetching = false;
        throw Error(
          'Does not support AFT catalogue sync. Try accessing through the Blue REST API.',
        );
      }

      const client = new AftClient({ clusterId });

      dispatch(
        isFetchingData(types.AFT.GET_SERVER_LIST, true, {
          fetchScope: clusterId,
        }),
      );

      const response = await client.readServers();
      const accServerList = response.payload;
      dispatch(receiveAccServerList({ accServerList, clusterId, locationId }));
    } catch (ex) {
      avoLogError('Error getting ACC servers', {
        clusterId,
        ex,
        locationId,
      });
    } finally {
      if (shouldUpdateIsFetching) {
        dispatch(
          isFetchingData(types.AFT.GET_SERVER_LIST, false, {
            fetchScope: clusterId,
          }),
        );
      }
    }
  };
}

export function getAllAccServerLists(clustersData) {
  return dispatch => {
    clustersData.forEach(cluster => {
      dispatch(getAccServerList(cluster));
    });
  };
}

/* PTZ */
export function goToHomePtzPreset(clusterId, cameraId) {
  return async () => {
    const client = new AftClient({ clusterId });
    await client.updateCameraPresetHome({ id: cameraId }).catch(ex => {
      avoLogError('Error going to PTZ Home', ex);
    });
  };
}
