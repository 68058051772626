import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ListNav, PageTitle } from 'lib';
import { Translate } from 'react-localize-redux';

import { PATH_ACCOUNTS, PATH_SEGMENT_USERS } from 'constants/urlPaths';

const UserDetailListNavContainer = ({
  canChangeCustomer,
  currentUser,
  isEditing,
}) => {
  const pageTitle = useMemo(() => {
    const hasName = currentUser.FirstName || currentUser.LastName;
    let title;
    if (isEditing && hasName) {
      title = (
        <Translate
          data={{
            firstName: currentUser.FirstName,
            lastName: currentUser.LastName,
          }}
          id="USERS.ADD_USER_MODAL.EDIT_USER_TITLE"
        />
      );
    } else if (isEditing) {
      title = (
        <Translate
          data={{
            firstName: '...',
            lastName: '',
          }}
          id="USERS.ADD_USER_MODAL.EDIT_USER_TITLE"
        />
      );
    } else {
      title = <Translate id="USERS.ADD_USER_MODAL.ADD_USER_TITLE" />;
    }
    return (
      <PageTitle
        backPath={`${PATH_ACCOUNTS}${PATH_SEGMENT_USERS}`}
        showBackButton
        title={title}
      />
    );
  }, [currentUser.FirstName, currentUser.LastName, isEditing]);

  return (
    <ListNav canChangeCustomer={canChangeCustomer} pageTitle={pageTitle} />
  );
};

UserDetailListNavContainer.defaultProps = {
  canChangeCustomer: false,
  currentUser: {},
  isEditing: false,
};

UserDetailListNavContainer.propTypes = {
  canChangeCustomer: PropTypes.bool,
  currentUser: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps;
  let currentUser = {};
  let isEditing = userId !== 'new';
  if (userId) {
    currentUser = state.user.users.find(u => u.Id === userId);
  } else {
    isEditing = false;
  }
  return {
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    currentUser,
    isEditing,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(UserDetailListNavContainer),
);
