import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone-all';
import { withLocalize } from 'react-localize-redux';
import { ai } from 'util/telemetryService';

// Components
import {
  BusyIndicator,
  LoggedOutContentWrapper,
  RegisterText,
  SignupForm,
} from 'components';
import { PageMessage } from 'containers';

// Actions
import * as UserActions from 'actions/user';

// Constants
import { PATH_LOGIN, PATH_SIGNUP } from 'constants/urlPaths';
import { APP_INSIGHTS_EVENTS, DEFAULT_LANGUAGE } from 'constants/app';
import { getSecurityTokens } from 'util/getSecurityTokens';

// Icons
import { pageError, registerText } from './styles.css';

class SignupContainer extends Component {
  constructor(props) {
    super(props);
    const { activeLanguage } = this.props;
    this.state = {
      activeLanguage:
        (activeLanguage && activeLanguage.fullCode) || DEFAULT_LANGUAGE,
    };
  }

  // logout in order to invalidate cookie for another user's session
  componentDidMount() {
    const { actions, code, history } = this.props;
    if (!code) {
      history.push(PATH_LOGIN);
    }

    actions.invalidateUserSession();
  }

  register = formData => {
    const { actions, code } = this.props;

    ai.appInsights.trackEvent({
      name: APP_INSIGHTS_EVENTS.registrationSubmit,
    });

    const registration = {
      ConfirmPassword: formData.ConfirmPassword.trim(),
      DesiredCulture: formData.CommunicationLanguage.trim(),
      FirstName: formData.FirstName.trim(),
      LastName: formData.LastName.trim(),
      Password: formData.Password.trim(),
      SecurityToken: code,
    };
    actions.register(registration);
  };

  registrationHasExpired() {
    const { expirationDate } = this.props;
    const expiration = moment(expirationDate);
    const now = moment.utc();
    return now.isAfter(expiration, 'minute');
  }

  // forward user to login page after they register

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history, path } = this.props;
    const { activeLanguage } = this.state;
    if (
      path === PATH_SIGNUP &&
      nextProps.user.isAuthenticated &&
      !nextProps.user.error
    ) {
      history.push(PATH_LOGIN);
    }
    if (
      nextProps.activeLanguage &&
      nextProps.activeLanguage.fullCode !== activeLanguage
    ) {
      this.setState({
        activeLanguage: nextProps.activeLanguage.fullCode,
      });
    }
  }

  render() {
    const { firstName, isBusy, lastName, translate, user } = this.props;
    const { activeLanguage } = this.state;
    const hasExpired = this.registrationHasExpired();
    if (isBusy) {
      return <BusyIndicator />;
    }
    return hasExpired ? (
      <div className={pageError}>
        <PageMessage body={translate('SIGNUP.EXPIRED_ERROR')} visible />
      </div>
    ) : (
      <LoggedOutContentWrapper header={translate('SIGNUP.HEADER')}>
        <>
          <div className={registerText}>
            <RegisterText />
          </div>
          <SignupForm
            initialValues={{
              CommunicationLanguage: activeLanguage,
              FirstName: firstName,
              LastName: lastName,
            }}
            onSubmit={this.register}
            user={user}
          />
        </>
      </LoggedOutContentWrapper>
    );
  }
}

SignupContainer.contextTypes = {
  router: PropTypes.objectOf(PropTypes.any).isRequired,
};

SignupContainer.defaultProps = {
  user: {
    error: null,
    isAuthenticated: false,
  },
};

SignupContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  activeLanguage: PropTypes.objectOf(PropTypes.any).isRequired,
  code: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  isBusy: PropTypes.bool.isRequired,
  lastName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
};

function mapStateToProps(state, ownProps) {
  let code;
  let expirationDate;
  let firstName;
  let lastName;

  if (ownProps.location.search) {
    const params = getSecurityTokens(ownProps.location);
    ({ code } = params);
    expirationDate = params.expirydate;
    firstName = params.firstname;
    lastName = params.lastname;
  }
  return {
    activeLanguage: state.activeLanguage,
    code,
    expirationDate,
    firstName,
    isBusy: state.user.isBusy,
    lastName,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = UserActions;
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withLocalize(
  connect(mapStateToProps, mapDispatchToProps)(SignupContainer),
);
