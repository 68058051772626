import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function notificationsReducer(state, action = {}) {
  switch (action.type) {
    case types.CONNECTION_SUSBSCRIPTION_STATUS_CHANGE: {
      const { id, isEstablished, orgId } = action;
      const newStatus = {};
      if (isEstablished === true || isEstablished === false) {
        newStatus.hasConnectionSubscription = isEstablished;
      }
      if (id) {
        newStatus.subscribedTenant = null;
        newStatus.connectionId = id;
      } else {
        newStatus.subscribedTenant = orgId;
      }
      return { ...state, ...newStatus };
    }

    case types.TRY_NOTIFICATION_CONNECTION: {
      let { retryCount } = action;
      if (!retryCount && retryCount !== 0) {
        retryCount = state.connectionTries + 1;
      }
      return { ...state, connectionTries: retryCount };
    }

    case types.IS_UPDATING_CONNECTION_SUBSCRIPTION: {
      return { ...state, isUpdatingConnectionSubscription: action.value };
    }

    // flag to unsubscribe when org context changes
    case types.CHANGE_CUSTOMER_CONTEXT: {
      return { ...state, hasConnectionSubscription: false };
    }

    // clear cache when user logs out
    case types.UNSET_USER: {
      return initialState().notifications;
    }

    default:
      return state || initialState().notifications;
  }
}
