// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import renderIf from 'render-if';

// Components
import { PlayerHost } from 'components';
import { NoStyleButton } from 'lib';

// Actions
import * as ViewActions from 'actions/views';
import * as DeviceActions from 'actions/devices';

// Icons
import { IconPlay } from 'icons';

// Utils
import { CAMERA_PTZ_OBJECT } from 'util/cameraSettingValues';
import { cameraSupportsPtz as doesCameraSupportPtz } from 'util/cameraSettingPermission';

// Constants
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import * as modalTypes from 'constants/ModalTypes';
import { idFeedResumeButton } from './constants';

// Styles
import {
  emptyFeedContainer,
  feedActive,
  feedResumeButton,
  feedResumeNoStyleButton,
  outerFeedContainer,
  streamPausedOverlay,
} from './styles.css';

// Class
class FeedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounting: true,
      showRulesOverlay: false,
    };
  }

  componentDidMount() {
    const { actions, camera, deviceId, deviceName } = this.props;
    if (!deviceName && camera && camera.isBlueConnected && deviceId) {
      actions.getDevice(deviceId);
    }
    this.setResumeStreamStartTime(null);
    this.setState({ isMounting: false });
  }

  componentDidUpdate(prevProps) {
    const { cameraId: oldCameraId, deviceId: oldDeviceId } = prevProps;
    const {
      actions,
      camera,
      cameraId,
      cameraRemoteId,
      deviceId,
      deviceName,
      isFetchingAcquisitionData,
    } = this.props;
    // If you've switched cameras, confirm the device is available
    if (deviceId && deviceId !== oldDeviceId && !deviceName) {
      actions.getDevice(deviceId);
    }

    if (
      isFetchingAcquisitionData === null &&
      deviceId &&
      cameraRemoteId &&
      cameraId &&
      oldCameraId !== cameraId &&
      camera.ConnectionState === CAMERA_CONNECTED
    ) {
      actions.getAcquisitionSettings(deviceId, cameraRemoteId, cameraId);
    }
  }

  componentWillUnmount() {
    this.setElementTurnStatus({});
  }

  get adjustedWidth() {
    /*
      TODO: Get rid of this hardcoded value. This solution is
      temporary for MVAAS-13327.
    */
    const { containerColumns, width } = this.props;
    if (containerColumns === 1) {
      const extraWidth = 143;
      const expectedWidth = window.innerWidth - extraWidth;
      return expectedWidth;
    }
    return width;
  }

  get isActiveClass() {
    const { currentPanelId, id } = this.props;
    return currentPanelId === id ? feedActive : '';
  }

  get isActivePane() {
    const { currentPanelId, id } = this.props;
    return currentPanelId === id;
  }

  setRulesOverlayVisibility = show => {
    const { actions, camera, rules } = this.props;
    this.setState({
      showRulesOverlay: show,
    });
    if (camera && !rules) {
      actions.getRules(camera.DeviceId, camera.RemoteId, camera.CameraId);
    }
  };

  autofocus = () => {
    const { actions, camera } = this.props;
    actions.cameraAutofocusGateway(camera.DeviceId, camera.RemoteId);
  };

  refreshMediaParams = (startTime = null, search = false) => {
    const { camera, getMedia } = this.props;
    getMedia(camera, startTime, search);
  };

  makeActive = () => {
    const { actions, id } = this.props;
    actions.setCurrentPanelId(id);
  };

  setElementTurnStatus = ({ turn }) => {
    const { actions, id } = this.props;
    actions.setElementTurnStatus(id.toString(), turn);
  };

  setResumeStreamStartTime = startTime => {
    const { actions, id } = this.props;
    actions.setResumeStreamStartTime(id.toString(), startTime);
  };

  render() {
    const {
      actions,
      camera,
      cameraId,
      cameraRemoteId,
      cameraSupportsPtz,
      defaultHeight,
      defaultWidth,
      deviceId,
      deviceName,
      enabledPtzFeed,
      handlePtzEnabledInFeed,
      height,
      id,
      inactivityPromptIsOpen,
      mediaParams,
      rois,
      setQuality,
      siteName,
      startTime,
      streamPaused,
      translate,
      videoKey,
    } = this.props;
    const { isMounting, showRulesOverlay } = this.state;
    return (
      <div
        className={outerFeedContainer}
        onKeyPress={this.makeActive}
        onMouseDown={this.makeActive}
        onPointerDown={this.makeActive}
        role="button"
        style={{
          height: `${height}px`,
        }}
        tabIndex="0"
      >
        <div className={this.isActiveClass} />
        {/*
          {this.props.camera.DeviceId && this.props.camera.RemoteId
          ? <Link
              id={BUTTON_ID}
              className={btnFullscreen}
              target={'_blank'}
              to={`/fullscreen/${this.props.camera.DeviceId}/${this.props.camera.RemoteId}`}
            >
              <IconNewWindow />
            </Link>
          : <div />}
          */}
        {/* width should be video width, which will be narrow than width when only one stream is in layout */}
        {!camera ? (
          <div className={emptyFeedContainer} />
        ) : (
          <>
            <PlayerHost
              key={`${videoKey}${streamPaused ? '_paused' : ''}`}
              autofocus={this.autofocus}
              camera={camera}
              cameraId={cameraId}
              cameraName={camera.cameraName}
              cameraRemoteId={cameraRemoteId}
              cameraResHeight={defaultHeight}
              cameraResWidth={defaultWidth}
              canEditROI={!!enabledPtzFeed}
              canSearch
              canvasItems={enabledPtzFeed ? CAMERA_PTZ_OBJECT : rois}
              deviceId={deviceId}
              deviceName={deviceName}
              enabledPtzFeed={enabledPtzFeed}
              handlePtzEnabledInFeed={handlePtzEnabledInFeed}
              height={height}
              hideOverlayToggle={
                cameraSupportsPtz || !camera.GatewayCapabilities
              }
              id={String(id)}
              isActivePane={this.isActivePane}
              isDisconnected={camera.ConnectionState !== CAMERA_CONNECTED}
              mediaParams={mediaParams}
              onStreamConnectionChange={this.setElementTurnStatus}
              onStreamEnd={() => {}}
              paused={streamPaused}
              refreshMediaParams={this.refreshMediaParams}
              returnToLiveVideo={this.refreshMediaParams}
              setQuality={setQuality}
              setResumeTime={this.setResumeStreamStartTime}
              setRulesOverlayVisibility={this.setRulesOverlayVisibility}
              showCanvas={showRulesOverlay}
              showRulesOverlay={showRulesOverlay}
              siteName={siteName}
              startRecordedVideo={this.refreshMediaParams}
              startTime={isMounting ? undefined : startTime}
              width={this.adjustedWidth}
            />
            {renderIf(streamPaused && !inactivityPromptIsOpen)(
              <div className={streamPausedOverlay}>
                <NoStyleButton
                  className={feedResumeNoStyleButton}
                  id={`${idFeedResumeButton}${String(id)}`}
                  onClick={() => actions.setTurnStreamsPaused(false)}
                >
                  <IconPlay
                    className={feedResumeButton}
                    height="88px"
                    width="88px"
                  />
                  {translate('VIEWS.RESUME_ALL')}
                </NoStyleButton>
              </div>,
            )}
          </>
        )}
      </div>
    );
  }
}

function mapWrapperStateToProps(state, ownProps) {
  // retrieve device name from store if available
  // if device is not in store, need to save deviceId as prop and fetch it
  let deviceName = '';
  let deviceId = '';
  let cameraId = '';
  let cameraRemoteId = '';
  let cameraSnapshot = '';
  let rules = null;
  let siteName = '';
  let cameraSupportsPtz = false;
  // let settings;
  // let cameraMediaRotation = null;
  if (ownProps.camera && ownProps.camera.CameraId) {
    if (state.devices.snapshots[ownProps.camera.CameraId]) {
      cameraSnapshot = state.devices.snapshots[ownProps.camera.CameraId].uri;
    }
    const cameraLocationId = ownProps.camera.LocationId;
    if (cameraLocationId) {
      const loc = state.locations.locations.find(
        l => l.Id === cameraLocationId,
      );
      siteName = loc ? loc.Name : '';
    }
    const cameraDeviceId = ownProps.camera.DeviceId;
    const parentDevice = state.devices.devices.find(
      device => device.Id === cameraDeviceId,
    );
    if (parentDevice) {
      deviceName = parentDevice.Name;
    } else {
      deviceId = cameraDeviceId;
    }
    cameraId = ownProps.camera.CameraId;
    cameraRemoteId = ownProps.camera.RemoteId;
    deviceId = ownProps.camera.DeviceId;
    rules =
      state.devices.rules[cameraId] && state.devices.rules[cameraId].rules;

    const camera = state.devices.cameras.find(
      c => c.Id === ownProps.camera.CameraId,
    );
    const cameraDevice = state.devices.devices.find(
      device => device.Id === ownProps.camera.DeviceId,
    );
    cameraSupportsPtz = doesCameraSupportPtz(camera, cameraDevice);
  }

  const streamPaused =
    state.views.turnStreamsPaused &&
    state.views.currentViewTurnStatus[ownProps.id];

  const startTime =
    state.views.resumeStreamStartTimes[ownProps.id] || ownProps.startTime;

  return {
    cameraId,
    cameraRemoteId,
    cameraSnapshot,
    cameraSupportsPtz,
    currentPanelId: state.views.panelId,
    deviceId,
    deviceName,
    devices: state.devices.devices,
    inactivityPromptIsOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.INACTIVITY_PROMPT,
    isFetchingAcquisitionData: state.devices.isFetchingAcquisitionData,
    isFetchingDevices: state.devices.isFetchingDeviceData,
    isFetchingMediaParams: state.devices.isFetchingMediaParams,
    locations: state.locations.locations,
    mediaParamsAll: state.devices.mediaParams,
    rules,
    siteName,
    startTime,
    streamPaused,
    timelines: state.devices.timelines,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...ViewActions,
      },
      dispatch,
    ),
  };
}

FeedContainer.defaultProps = {
  camera: null,
  cameraSupportsPtz: false,
  containerColumns: 0,
  currentPanelId: undefined,
  deviceName: null,
  enabledPtzFeed: false,
  getMedia: () => {},
  handlePtzEnabledInFeed: () => {},
  inactivityPromptIsOpen: false,
  isFetchingAcquisitionData: null,
  mediaParams: {},
  rois: {},
  rules: null,
  setQuality: () => {},
  siteName: '',
  startTime: undefined,
  translate: () => MISSING_TRANSLATE_FUNCTION,
};

FeedContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  camera: PropTypes.shape({
    CameraId: PropTypes.string,
    ConnectionState: PropTypes.string,
    DeviceId: PropTypes.string,
    GatewayCapabilities: PropTypes.array,
    RemoteId: PropTypes.string,
    cameraName: PropTypes.string,
  }),
  cameraId: PropTypes.string.isRequired,
  cameraRemoteId: PropTypes.string.isRequired,
  cameraSupportsPtz: PropTypes.bool,
  containerColumns: PropTypes.number,
  currentPanelId: PropTypes.number,
  defaultHeight: PropTypes.number.isRequired,
  defaultWidth: PropTypes.number.isRequired,
  deviceId: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  enabledPtzFeed: PropTypes.bool,
  getMedia: PropTypes.func,
  handlePtzEnabledInFeed: PropTypes.func,
  height: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  inactivityPromptIsOpen: PropTypes.bool,
  isFetchingAcquisitionData: PropTypes.bool,
  mediaParams: PropTypes.objectOf(PropTypes.any),
  rois: PropTypes.objectOf(PropTypes.any),
  rules: PropTypes.objectOf(PropTypes.any),
  setQuality: PropTypes.func,
  siteName: PropTypes.string,
  startTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  streamPaused: PropTypes.bool.isRequired,
  translate: PropTypes.func,
  videoKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  width: PropTypes.number.isRequired,
};

export default withLocalize(
  connect(mapWrapperStateToProps, mapDispatchToProps)(FeedContainer),
);
