import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function accServerListsReducer(state, action) {
  switch (action.type) {
    case types.AFT.RECEIVE_ACC_SERVER_LIST: {
      if (action.accServerList) {
        const { accServerList, clusterId, locationId } = action;

        const existingLocation = state[locationId];
        if (existingLocation) {
          return {
            ...state,
            [locationId]: {
              ...existingLocation,
              [clusterId]: accServerList.servers,
            },
          };
        }

        return {
          ...state,
          [locationId]: {
            [clusterId]: accServerList.servers,
          },
        };
      }
      return state;
    }
    default:
      return state || initialState().accServerLists;
  }
}
