import { PATH_DEVICES, PATH_SEGMENT_LIST } from 'constants/urlPaths';
import { IC_ALL, IC_MENU } from 'constants/iconNames';
import * as CameraTypes from 'constants/cameraTypes';
import { DEVICE_TYPES } from 'constants/app';

export const CAMERA_TILE_ID = 'Camera';
export const all = 'ALL';

export const VIEW_SWITCH_PROPS = [
  {
    id: IC_ALL,
    path: PATH_DEVICES,
  },
  {
    id: IC_MENU,
    path: `${PATH_DEVICES}${PATH_SEGMENT_LIST}`,
    size: 16,
  },
];

export const fieldsToSearch = [
  'Name',
  'ConnectionState',
  'LocationName',
  'IPAddress',
  'Model'

];
export const filters = [
  {
    field: 'Type',
    label: 'DEVICES.DEVICE_CONTAINER.DEVICE_TYPE_TITLE',
    options: [
      {
        text:
          'DEVICE_DETAILS.VIDEO_RETENTION_TAB.CAMERA_LIST_TABLE.CAMERA_NAME_TABLE_HEADER',
        value: DEVICE_TYPES.CAMERA,
      },
      {
        text: 'DEVICES.TABLE_HEADERS.SERVER',
        value: DEVICE_TYPES.SERVER,
      },
    ],
    translateOptions: true,
  },
  {
    field: 'ConnectionState',
    label: 'COMMON.STATUS',
    options: [
      {
        text: 'CAMERA.STATUS.CONNECTED',
        value: CameraTypes.CAMERA_CONNECTED,
      },
      {
        text: 'CAMERA.STATUS.DISCONNECTED',
        value: CameraTypes.CAMERA_DISCONNECTED,
      },
    ],
    translateOptions: true,
  },
];
