import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { ACTIVE_COLOR_DEFAULT, SVG_XMLNS } from '../constants/app';

const IconEdit = ({
  className,
  fillColor,
  height,
  iconId,
  title,
  translate,
  width,
}) => {
  return (
    <svg
      className={className}
      height={height}
      title={translate(title)}
      version="1.1"
      viewBox="0 0 16 16"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          d="M2.5 14.375V17.5h3.125l9.217-9.217-3.125-3.125L2.5 14.375zm14.758-8.508a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525z"
          id={`a_${iconId}`}
        />
        <path d="M0 0h20v20H0z" id={`d_${iconId}`} />
        <filter
          filterUnits="objectBoundingBox"
          height="170%"
          id={`c_${iconId}`}
          width="170%"
          x="-35%"
          y="-25%"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
        <path d="M0 0h20v20H0z" />
        <mask fill="#fff" id={`b_${iconId}`}>
          <use xlinkHref={`#a_${iconId}`} />
        </mask>
        <g mask={`url(#b_${iconId})`}>
          <use fill="#000" filter="url(#c)" xlinkHref={`#d_${iconId}`} />
          <use fill={fillColor} xlinkHref={`#d_${iconId}`} />
        </g>
      </g>
    </svg>
  );
};

IconEdit.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
  height: PropTypes.string,
  iconId: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

IconEdit.defaultProps = {
  className: '',
  fillColor: ACTIVE_COLOR_DEFAULT,
  height: '16px',
  iconId: 'edit_0',
  title: 'BUTTONS.EDIT',
  width: '16px',
};

export default withLocalize(IconEdit);
