import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from 'lib';
import { IC_INFO } from 'constants/iconNames';
import { CELL_WIDTHS } from '../constants';
import OverflowRow from '../OverflowRow';

import * as styles from '../OGTable.css';

const OGRowOverflow = ({
  cellWidths,
  disabled,
  isLastRow,
  overflowFieldOrder,
  overflowFieldOrderField,
  overflowHeaderTranslations,
  rowData,
}) => {
  const visibleFieldOrder = overflowFieldOrderField
    ? overflowFieldOrder[rowData[overflowFieldOrderField]]
    : overflowFieldOrder;
  return (
    <td
      className={styles.ogBodyCell}
      width={cellWidths.Overflow || CELL_WIDTHS.overflow}
    >
      <div
        className={`${styles.ogBodyCellContent} ${styles.alignRight} ${
          disabled ? styles.disabledCell : ''
        }`}
      >
        {visibleFieldOrder.length > 0 ? (
          <Popover
            horizontalOffset="right"
            trigger={<Icon id={IC_INFO} renderBare />}
            verticalOffset={isLastRow ? 'bottom' : 'top'}
          >
            <OverflowRow
              data={rowData}
              fieldOrder={visibleFieldOrder}
              headerTranslationIds={overflowHeaderTranslations}
              hideFilter
            />
          </Popover>
        ) : null}
      </div>
    </td>
  );
};

OGRowOverflow.defaultProps = {
  cellWidths: null,
  disabled: false,
  isLastRow: false,
  overflowFieldOrderField: null,
};

OGRowOverflow.propTypes = {
  cellWidths: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  isLastRow: PropTypes.bool,
  overflowFieldOrder: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  overflowFieldOrderField: PropTypes.string,
  overflowHeaderTranslations: PropTypes.objectOf(PropTypes.string).isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OGRowOverflow;
