import React, { useState } from 'react';
import { ConfigurationProvider } from '@avo-svtr/savitar';
import Modal from 'react-modal';
import { ThemeProvider } from 'react-jss';
import { PropTypes } from 'prop-types';
import {
  modalContentContainer,
  modalOverlay,
  savitarModalOverlay,
} from 'sharedStyles/styles.css';
import { getSavitarLocalizationPreferenceProps } from 'util/convertTimeTo';

const customTheme = {};

const SavitarConfiguration = ({ children, localizationPreference }) => {
  const [popupContent, setPopupContent] = useState();

  return (
    <>
      <ConfigurationProvider
        locale={getSavitarLocalizationPreferenceProps(localizationPreference)}
        showOutside={setPopupContent}
      >
        {children}
      </ConfigurationProvider>
      <ThemeProvider theme={customTheme}>
        <Modal
          className={modalContentContainer}
          isOpen={popupContent}
          overlayClassName={`${modalOverlay} ${savitarModalOverlay}`}
        >
          {popupContent}
        </Modal>
      </ThemeProvider>
    </>
  );
};

SavitarConfiguration.defaultProps = {
  children: null,
  localizationPreference: null,
};

SavitarConfiguration.propTypes = {
  children: PropTypes.node,
  localizationPreference: PropTypes.objectOf(PropTypes.any),
};

export default SavitarConfiguration;
