// Libs
import React, { Component } from 'react';
import InputRange from 'react-input-range';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import {
  CircularInput,
  CircularProgress,
  CircularThumb,
  CircularTrack,
} from 'react-circular-input';

// Components
import { Checkbox as CustomCheckbox, DurationInput } from 'components';
import { Button, ExpansionIndicator, GroupLayout } from 'lib';

// Style
import 'react-input-range/lib/css/index.css';
import { ACTIVE_COLOR_DEFAULT, INACTIVE_COLOR_DEFAULT } from 'constants/app';
import { personFlag, vehicleFlag } from 'constants/cameraSettings';
import { IconView, IconX } from 'icons';
import {
  advancedBox,
  advanceOpen,
  cameraSettingsCheckbox,
  checkboxContainer,
  checkLabel,
  circularInputContainer,
  closeButton,
  formButtons,
  formContent,
  formField,
  formFieldError,
  formGroup,
  formHeader,
  formLabel,
  horizontalDivider,
  numberInput,
  openBox,
  ruleBox,
  ruleContainer,
  ruleLink,
  ruleNumberField,
  ruleTitle,
  sliderInput,
  viewButton,
} from './styles.css';

// Constants
import * as camRuleConstants from './CameraRuleConstants';
import { ADVANCED_SETTINGS_LINK_ID } from './constants';

// Icons

// Class
class CameraRule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPristine: true,
      nameError: false,
      showAdvanced: false,
    };
  }

  toggleForm = () => {
    const { isSelected, selectRule, sensorId } = this.props;
    return isSelected ? selectRule(-1) : selectRule(sensorId);
  };

  toggleVisible = () => {
    const { sensorId, toggleRule } = this.props;

    toggleRule(sensorId);
  };

  toggleAdvanced = () => {
    const { showAdvanced } = this.state;

    this.setState({ showAdvanced: !showAdvanced });
  };

  changeActivityType = (e, v) => {
    const val = e.target ? e.target.value : v;
    const { onChangeActivityType } = this.props;
    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === val,
    );
    const defaults = { activityType: val };
    if (activityOption) {
      const activityKeys = Object.keys(activityOption);
      activityKeys.forEach(key => {
        if (activityOption[key].def) {
          defaults[key] = activityOption[key].def;
        }
      });
      // For limit of one, there is no detection time
      if (this.hideThreshold(activityOption, defaults.limit)) {
        defaults.detectionTime = 0;
      }
    }
    onChangeActivityType(activityOption.value);
    this.changeFields(defaults);
  };

  changeCheckBox = fieldName => {
    const { objectTypeFlags } = this.props;
    const objectDetectionArr = objectTypeFlags.slice();
    const newArr = [];
    let inArr = false;
    objectDetectionArr.forEach(obj => {
      if (obj === fieldName) {
        inArr = true;
      } else {
        newArr.push(obj);
      }
    });
    if (!inArr) {
      newArr.push(fieldName);
    }
    this.changeField('objectTypeFlags', false, newArr);
  };

  changeLimit = e => {
    const { activityType } = this.props;
    // Limit and detectionTime are coupled, and may need to change together
    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === activityType,
    );

    let { detectionTime } = this.props;
    const limit = e.target.value;
    if (this.hideThreshold(activityOption, limit)) {
      detectionTime = 0;
    } else {
      detectionTime = activityOption.detectionTime.def;
    }
    this.changeFields({ detectionTime, limit });
  };

  changeFields = fieldObject => {
    const { event, updateRule } = this.props;
    const newEvent = { ...event, ...fieldObject };
    this.compareEventChanges(newEvent);
    updateRule(newEvent);
  };

  changeField = (fieldName, e, v) => {
    const value = e === false ? v : e.target.value;
    let { nameError } = this.state;
    const { event, updateRule } = this.props;
    const newEvent = { ...event, [fieldName]: value };
    this.compareEventChanges(newEvent);
    if (fieldName === 'name' && value !== '') {
      nameError = false;
    }
    this.setState({ nameError });
    updateRule(newEvent);
  };

  compareEventChanges = newEvent => {
    const { initialEvent } = this.props;
    if (initialEvent.event.objectTypeFlags) {
      initialEvent.event.objectTypeFlags.sort();
    }
    newEvent.objectTypeFlags.sort();
    if (!deepEqual(newEvent, initialEvent)) {
      this.setState({ isPristine: false });
    } else {
      this.setState({ isPristine: true });
    }
  };

  toggleEnabled = async () => {
    const { enabled, selectRule, sensorId } = this.props;

    await selectRule(sensorId, true);
    await this.changeField('enabled', false, !enabled);
    this.saveForm();
  };

  saveForm = () => {
    const { name, onSave } = this.props;

    if (!name || name.length === 0) {
      this.setState({ nameError: true });
    } else {
      onSave();
    }
  };

  cancelForm = () => {
    const { handleCancel } = this.props;
    this.setState({
      isPristine: true,
      nameError: false,
      showAdvanced: false,
    });
    handleCancel();
  };

  hideThreshold = (activityOption, limit) => {
    return (
      activityOption &&
      (activityOption.value === 'OBJECTS_CROSSED_BEAM' ||
        activityOption.value === 'OBJECT_ENTERS' ||
        activityOption.value === 'OBJECT_LEAVES') &&
      parseInt(limit, 10) < camRuleConstants.MINIMUM_OBJECTS_TO_SHOW_THRESHOLD
    );
  };

  renderThresholdUI = () => {
    const { activityType, detectionTime, limit } = this.props;
    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === activityType,
    );

    // For beam crossing, do not show threshold ui
    if (this.hideThreshold(activityOption, limit)) {
      return '';
    }

    return (
      <div className={formGroup}>
        <div className={formLabel}>
          <Translate id="CAMERA.SETTINGS.RULES.LABELS.THRESHOLD" />
        </div>
        <div className={formField} id={camRuleConstants.RULE_BOX.idThreshold}>
          <DurationInput
            initialDuration={detectionTime}
            maxDuration={activityOption.detectionTime.max}
            minDuration={activityOption.detectionTime.min}
            onChange={timeout =>
              this.changeField('detectionTime', false, timeout)
            }
          />
        </div>
      </div>
    );
  };

  renderTimeoutUI = () => {
    const { activityType, timeout } = this.props;
    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === activityType,
    );

    return (
      <div className={formGroup}>
        <div className={formLabel}>
          <Translate id="CAMERA.SETTINGS.RULES.LABELS.TIMEOUT" />
        </div>
        <div className={formField} id={camRuleConstants.RULE_BOX.idTimedOut}>
          <DurationInput
            initialDuration={timeout}
            maxDuration={activityOption.timeout.max}
            minDuration={activityOption.timeout.min}
            onChange={eventTimeout =>
              this.changeField('timeout', false, eventTimeout)
            }
          />
        </div>
      </div>
    );
  };

  renderLimitUI = () => {
    const { activityType, limit } = this.props;
    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === activityType,
    );
    // For Object Not Present, disable limit input
    const disabled =
      activityOption && activityOption.value === 'OBJECT_REQUIRED';
    return (
      <div className={formGroup}>
        <div className={formLabel}>
          <Translate id="CAMERA.SETTINGS.RULES.LABELS.LIMIT" />
        </div>
        <div className={`${formField} ${ruleNumberField}`}>
          <input
            className={numberInput}
            disabled={
              disabled || activityOption && activityOption.limit.max === activityOption.limit.min
            }
            id={camRuleConstants.RULE_BOX.idNumberOfObjects}
            max={activityOption && activityOption.limit.max}
            min={activityOption && activityOption.limit.min}
            onChange={this.changeLimit}
            type="number"
            value={limit}
          />
        </div>
      </div>
    );
  };

  renderSensitivityUI = () => {
    const { sensitivity } = this.props;
    return (
      <div className={formGroup}>
        <div className={formLabel}>
          <Translate id="CAMERA.SETTINGS.RULES.LABELS.SENSITIVITY" />
        </div>
        <div className={formField}>
          <div
            className={sliderInput}
            id={camRuleConstants.RULE_BOX.idSensitivitySlider}
          >
            <InputRange
              maxValue={10}
              minValue={1}
              onChange={val => this.changeField('sensitivity', false, val)}
              size={10}
              step={1}
              value={sensitivity}
            />
          </div>
        </div>
      </div>
    );
  };

  renderDirectionUI = () => {
    const {
      activityType,
      directionAngle,
      onChangeProhibitedDirection,
    } = this.props;

    const activityOption = camRuleConstants.activityOptions.find(
      opt => opt.value === activityType,
    );
    // Only show for Direction Violated rule
    if (activityOption && activityOption.value !== 'PROHIBITED_DIRECTION') {
      return null;
    }
    // CircularInput accepts value between 0 and 1
    const calculatedAngle = directionAngle ? directionAngle / 360 : 0.25;
    return (
      <div className={formGroup}>
        <div className={formLabel}>
          <Translate id="CAMERA.SETTINGS.RULES.LABELS.PROHIBITED_DIRECTION" />
        </div>
        <div
          className={circularInputContainer}
          id={camRuleConstants.RULE_BOX.idSensitivitySlider}
        >
          <CircularInput
            onChange={onChangeProhibitedDirection}
            radius={75}
            value={calculatedAngle}
          >
            <CircularTrack stroke="#eee" strokeWidth="4px" />
            <CircularProgress stroke="#3f51b5" strokeWidth="4px" />
            <CircularThumb fill="#3f51b5" r="7.5" stroke="#3f51b5" />
          </CircularInput>
        </div>
      </div>
    );
  };

  render() {
    const { isPristine, nameError, showAdvanced } = this.state;
    const {
      activityType,
      directional,
      enabled,
      isCameraOverlayPristine,
      isSelected,
      isVisible,
      name,
      objectTypeFlags,
      onChangeDirectionality,
    } = this.props;
    const isSaved = false;
    const hasName = name.length > 0;
    const isOpen = isSelected ? ` ${openBox}` : '';
    const advanceIsOpen = showAdvanced ? ` ${advanceOpen}` : '';
    const noClassifiedObject = objectTypeFlags.length === 0;
    const changedRuleValues = isCameraOverlayPristine
      ? isPristine
      : isCameraOverlayPristine;

    return (
      <div className={`${ruleBox}${isOpen}${advanceIsOpen}`}>
        {isSelected ? (
          <div>
            <div className={formHeader} onClick={this.toggleForm}>
              <ExpansionIndicator expanded />
            </div>
            <div className={formContent}>
              <div className={formGroup}>
                <div className={formLabel}>
                  <Translate id="CAMERA.SETTINGS.RULES.LABELS.NAME" />
                </div>
                <div className={`${formField} ${nameError && formFieldError}`}>
                  <input
                    id={camRuleConstants.RULE_BOX.idRuleName}
                    onChange={ev => this.changeField('name', ev)}
                    type="text"
                    value={name}
                  />
                </div>
              </div>
              <div className={formGroup}>
                <div className={formLabel}>
                  <Translate id="CAMERA.SETTINGS.RULES.LABELS.ACTIVITY" />
                </div>
                <div
                  className={formField}
                  id={camRuleConstants.RULE_BOX.idActivity}
                >
                  <select
                    onChange={this.changeActivityType}
                    value={activityType}
                  >
                    {camRuleConstants.activityOptions.map(opt => (
                      <Translate key={opt.value}>
                        {({ translate }) => (
                          <option value={opt.value}>
                            {translate(
                              `CAMERA.SETTINGS.RULES.ACTIVITY_LABELS.${opt.value}`,
                            )}
                          </option>
                        )}
                      </Translate>
                    ))}
                  </select>
                </div>
              </div>
              {directional !== null && (
                <div className={formGroup}>
                  <CustomCheckbox
                    checked={directional}
                    customClass={cameraSettingsCheckbox}
                    id={camRuleConstants.RULE_BOX.idDirectionalFlag}
                    label={
                      <Translate id="CAMERA.SETTINGS.RULES.LABELS.DIRECTIONAL" />
                    }
                    onChange={() => onChangeDirectionality()}
                    value={camRuleConstants.RULE_BOX.idDirectionalFlag}
                  />
                </div>
              )}
              <div className={formGroup}>
                <div className={formLabel}>
                  <Translate id="CAMERA.SETTINGS.RULES.LABELS.OBJECT" />
                </div>
                <Translate>
                  {({ translate }) => (
                    <div className={checkboxContainer}>
                      <CustomCheckbox
                        checked={objectTypeFlags.includes(personFlag)}
                        customClass={cameraSettingsCheckbox}
                        id={`${personFlag}_${name.replace(' ', '_')}`}
                        label={translate('CAMERA.SETTINGS.RULES.LABELS.PERSON')}
                        onChange={() => this.changeCheckBox(personFlag)}
                        value={personFlag}
                      />
                      <CustomCheckbox
                        checked={objectTypeFlags.includes(vehicleFlag)}
                        customClass={cameraSettingsCheckbox}
                        id={`${vehicleFlag}_${name.replace(' ', '_')}`}
                        label={translate(
                          'CAMERA.SETTINGS.RULES.LABELS.VEHICLE',
                        )}
                        onChange={() => this.changeCheckBox(vehicleFlag)}
                        value={vehicleFlag}
                      />
                    </div>
                  )}
                </Translate>
              </div>

              <div
                className={ruleLink}
                id={ADVANCED_SETTINGS_LINK_ID}
                onClick={this.toggleAdvanced}
              >
                <Translate id="CAMERA.SETTINGS.ADVANCED_SETTINGS" />
              </div>
              {showAdvanced && <div className={horizontalDivider} />}
              <div className={`${advancedBox} ${advanceIsOpen}`}>
                {this.renderSensitivityUI()}
                {this.renderLimitUI()}
                {this.renderThresholdUI()}
                {this.renderTimeoutUI()}
                {this.renderDirectionUI()}
              </div>
              <div className={horizontalDivider} />
            </div>
            <GroupLayout additionalClasses={formButtons}>
              <Button
                key={camRuleConstants.RULE_BOX.idBtnRuleCancel}
                buttonType="primary"
                id={camRuleConstants.RULE_BOX.idBtnRuleCancel}
                inputType="button"
                onClick={this.cancelForm}
                text={<Translate id="BUTTONS.CANCEL" />}
              />
              <Button
                key={camRuleConstants.RULE_BOX.idBtnRuleSave}
                buttonType="primary"
                disabled={
                  isSaved || !hasName || noClassifiedObject || changedRuleValues
                }
                id={camRuleConstants.RULE_BOX.idBtnRuleSave}
                inputType="button"
                onClick={this.saveForm}
                text={<Translate id="BUTTONS.SAVE" />}
              />

              <div
                className={closeButton}
                id={camRuleConstants.RULE_BOX.idBtnRuleClose}
                onClick={this.toggleForm}
              >
                <IconX height="20px" />
              </div>
            </GroupLayout>
          </div>
        ) : (
          <div className={ruleContainer}>
            <div className={viewButton} onClick={this.toggleVisible}>
              <Translate>
                {({ translate }) => (
                  <IconView
                    strokeFill={
                      isVisible ? ACTIVE_COLOR_DEFAULT : INACTIVE_COLOR_DEFAULT
                    }
                    title={translate(
                      'CAMERA.SETTINGS.RULES.TOGGLE_DETAILS_TOOLTIP',
                    )}
                  />
                )}
              </Translate>
            </div>
            <div className={ruleTitle}>
              {name && (
                <CustomCheckbox
                  checked={enabled}
                  id={`${camRuleConstants.enableCheckId}_${name.replace(
                    ' ',
                    '_',
                  )}`}
                  onChange={this.toggleEnabled}
                />
              )}
              <div
                className={`${ruleLink} ${checkLabel}`}
                onClick={this.toggleForm}
              >
                {name || (
                  <Translate id="CAMERA.SETTINGS.RULES.NEW_RULE_LABEL" />
                )}
              </div>
            </div>
            <ExpansionIndicator expanded={false} onClick={this.toggleForm} />
          </div>
        )}
      </div>
    );
  }
}

CameraRule.propTypes = {
  directionAngle: PropTypes.string,
  directional: PropTypes.bool,
  event: PropTypes.shape({
    activityType: PropTypes.string.isRequired,
    detectionTime: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    objectTypeFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  handleCancel: PropTypes.func,
  initialEvent: PropTypes.shape({ event: PropTypes.object }),
  isCameraOverlayPristine: PropTypes.bool,
  isSelected: PropTypes.bool,
  isVisible: PropTypes.bool,
  onChangeActivityType: PropTypes.func,
  onChangeDirectionality: PropTypes.func,
  onChangeProhibitedDirection: PropTypes.func,
  onSave: PropTypes.func,
  selectRule: PropTypes.func,
  sensorId: PropTypes.number.isRequired,
  toggleRule: PropTypes.func,
};

CameraRule.defaultProps = {
  directionAngle: '',
  directional: null,
  handleCancel: () => {},
  initialEvent: { event: {} },
  isCameraOverlayPristine: true,
  isSelected: false,
  isVisible: false,
  onChangeActivityType: () => {},
  onChangeDirectionality: () => {},
  onChangeProhibitedDirection: () => {},
  onSave: () => {},
  selectRule: () => {},
  toggleRule: () => {},
};

export default CameraRule;
