import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrent } from 'util/observables';
import Modal from 'react-modal';
import { Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';

// Actions
import { hideModal, showModal } from 'actions/modal';
import { toggleSyncVideo } from 'actions/views';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';

// Utils
import { decorateCamera } from 'util/aftHelper';

// Containers
import { BookmarkForm, ModalContainer, PageMessage } from 'containers';
import RuleOverlayContainer from 'containers/OverlayContainers/RuleOverlayContainer';
import PrivacyZoneOverlayContainer from 'containers/OverlayContainers/PrivacyZoneOverlayContainer';

// Components
import { ThemeProvider } from 'react-jss';
import { PlayerPresentationProvider } from '@avo-svtr/savitar';
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';
import SavitarPlayer from './SavitarPlayer';
import DisplayTimeObserver from './DisplayTimeObserver';
import RoiObserver from './RoiObserver';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

import { canvasTypes, DATABASE_DATETIME_FORMAT } from '../../constants/app';
import {
  activePane,
  emptyFeedContainer,
  playerHostSanitizer,
  videoPlayerWrap,
} from './styles.css';

const customTheme = {};

const UTC_TZ_STRINGS = [
  'Etc/GMT',
  'Etc/GMT+0',
  'Etc/GMT-0',
  'Etc/GMT0',
  'Etc/Greenwich',
  'Etc/UCT',
  'Etc/UTC',
  'Etc/Universal',
  'Etc/Zulu',
];

const closeModal = props => {
  document.body.dispatchEvent(
    new CustomEvent('savitarActiveElementChange', {
      detail: { target: null },
    }),
  );
  props.actions.hideModal();
};

const togglePTZToursModal = props => {
  avoLog('Is PTZ tour modal open?', props.isPtzTourModalOpen);
  if (props.isPtzTourModalOpen) {
    props.actions.hideModal();
  } else {
    props.actions.showModal(modalTypes.PTZ_TOUR, {
      modalKey: props.camera.id,
    });
  }
};

function getConnectionState(cam) {
  if (cam.ConnectionState) {
    return cam.ConnectionState;
  }

  if (!cam.available) {
    return 'DISCONNECTED';
  }
  return cam.connectionStatus.state;
}

function getServerConnectionState(device) {
  if (!device) {
    return 'DISCONNECTED';
  }
  return device.ConnectionState;
}

function cameraHaveRecordedData(camera) {
  if (!camera) {
    return false;
  }
  return camera.recordedData || camera.RecordedData;
}

// TODO: Refactor PlayerHost - MVAAS-15686
const PlayerHost = React.memo(function PlayerHost(props) {
  const {
    camera,
    canEditROI,
    canvasType,
    device,
    height: containerHeight,
    hideOverlayToggle,
    id: hostId,
    isActivePane,
    isDisabledBlueCamera,
    isDisconnected,
    locationId,
    locationName,
    onStreamConnectionChange,
    paused,
    setResumeTime,
    showCanvas,
    startTime,
    syncVideo,
    timezone,
    width: containerWidth,
  } = props;
  const { aftClient } = camera;
  const aftConnection = aftClient && aftClient.connection;
  const turn = aftConnection && aftConnection.turn;
  // Extract these variables to use in the deps array
  // to determine whether to run the onStreamConnectionChange useEffect hook

  const [streamParams, setStreamParams] = useState({});
  const [displayTime, setDisplayTime] = useState(null);
  const [displayTimeObserver, setDisplayTimeObserver] = useState(null);
  const [roiObserver, setRoiObserver] = useState(null);
  const [initialState, setInitialState] = useState({
    paused,
    startAt: startTime,
  });

  const { hideBookmarkButton } = props;

  const bookmarkModalRef = useRef();

  const streamDetails = useMemo(() => {
    if (!streamParams) {
      return null;
    }
    return {
      ...streamParams,
      cameraResHeight: streamParams['source-height'],
      cameraResWidth: streamParams['source-width'],
      xOffset: streamParams['origin-x'],
      yOffset: streamParams['origin-y'],
    };
  }, [streamParams]);

  const streamStartTime = useMemo(() => {
    if (!displayTime) {
      return moment()
        .utc()
        .format(DATABASE_DATETIME_FORMAT);
    }
    return moment(displayTime)
      .utc()
      .format(DATABASE_DATETIME_FORMAT);
  }, [displayTime]);

  const { height: screenSpaceHeight, width: screenSpaceWidth } = useMemo(() => {
    if (!streamParams) {
      return {};
    }
    const { rotation } = streamParams;
    let { height: streamHeight, width: streamWidth } = streamParams;
    if (roiObserver) {
      const { video } = getCurrent(roiObserver);
      streamHeight = video.height;
      streamWidth = video.width;
    }
    // fit canvas within container, given aspect ratio of each
    const aspectContainer = containerWidth / containerHeight;
    let aspectVideo = streamWidth / streamHeight;
    if (rotation === 90 || rotation === 270) {
      aspectVideo = streamHeight / streamWidth;
    }
    if (aspectContainer > aspectVideo) {
      return {
        height: containerHeight,
        width: containerHeight * aspectVideo,
      };
    }
    return {
      height: containerWidth / aspectVideo,
      width: containerWidth,
    };
  }, [containerHeight, containerWidth, roiObserver, streamParams]);

  const bookmarkInitialValues = {
    endTime: streamStartTime,
    startTime: streamStartTime,
  };

  function publishDisplayTime(actualDisplayTime) {
    setDisplayTime(actualDisplayTime);
  }
  const currentConnectionStatus = useMemo(() => getConnectionState(camera), [
    camera,
  ]);

  const canvas = useMemo(() => {
    if (
      !streamDetails ||
      !screenSpaceWidth ||
      !screenSpaceHeight ||
      !showCanvas
    ) {
      return null;
    }
    if (canvasType === canvasTypes.rules) {
      const { Id: cameraId } = camera;
      return (
        <RuleOverlayContainer
          cameraId={cameraId}
          canEditROI={canEditROI}
          screenSpaceHeight={screenSpaceHeight}
          screenSpaceWidth={screenSpaceWidth}
          streamDetails={streamDetails}
        />
      );
    }
    if (canvasType === canvasTypes.privacy) {
      const { Id: cameraId } = camera;
      return (
        <PrivacyZoneOverlayContainer
          cameraId={cameraId}
          canEditROI
          screenSpaceHeight={screenSpaceHeight}
          screenSpaceWidth={screenSpaceWidth}
          streamDetails={streamDetails}
        />
      );
    }
    // The overlays rendered by this function are only used for Savitar, and
    // Savitar handles its own PTZ controls, so rendering PTZ arrow here is never necessary
    return null;
  }, [
    camera,
    canEditROI,
    canvasType,
    screenSpaceHeight,
    screenSpaceWidth,
    showCanvas,
    streamDetails,
  ]);

  useEffect(() => {
    // If this must be run when variables other than turn in aftConnection update
    // it may be necessary to extract those variables and add them to the dependency array individually
    if (onStreamConnectionChange && (aftConnection || turn)) {
      onStreamConnectionChange(aftConnection);
    }
  }, [onStreamConnectionChange, aftConnection, turn]);

  useEffect(() => {
    if (camera.recordedData && currentConnectionStatus === 'DISCONNECTED') {
      setInitialState({
        paused: true,
        startAt: startTime || Date.now(),
      });
    }
    return () => {
      if (displayTimeObserver && setResumeTime) {
        setResumeTime(getCurrent(displayTimeObserver));
      }
    };
  }, [camera.recordedData, currentConnectionStatus, displayTimeObserver, setResumeTime, startTime]);
  /** this makes sure that the disabled cameras in the saved views won't be displayed in playerHost */
  /** note all hooks must be defined BEFORE this return statement or you can get some funky errors */
  if (!camera || isDisabledBlueCamera) {
    return <div className={emptyFeedContainer} />;
  }

  if (aftClient) {
    return (
      <>
        <div className={playerHostSanitizer}>
          <ThemeProvider theme={customTheme}>
            <PlayerPresentationProvider
              aftClient={aftClient}
              cameraId={camera.cameraId}
              initialState={initialState}
              /*
               * This is a workaround to expose `locationName` to `Savitar` because `SimplePlayer`
               * should not care about `locationName`.
               */
              locationName={locationName}
              onStreamParamsChange={setStreamParams}
              sync={syncVideo}
              timezone={timezone}
            >
              <DisplayTimeObserver
                publishDisplayTime={publishDisplayTime}
                saveDisplayTimeObserver={setDisplayTimeObserver}
              />
              <RoiObserver saveRoiObserver={setRoiObserver} />
              <SavitarPlayer
                aftClient={aftClient}
                cameraId={camera.cameraId}
                hideBookmarkButton={hideBookmarkButton} // TODO: context? reducer?
                hideOverlayToggle={hideOverlayToggle}
                hostId={hostId}
                ptzProps={{
                  togglePTZToursModal: () => togglePTZToursModal(props),
                }}
              />
            </PlayerPresentationProvider>
          </ThemeProvider>
        </div>
        {canvas}
        <div ref={bookmarkModalRef}>
          <Modal
            className={modalContentContainer}
            contentLabel="BookmarksForm"
            isOpen={props.isBookmarksModalOpen}
            onRequestClose={props.actions.hideModal}
            overlayClassName={modalOverlay}
            shouldCloseOnOverlayClick={false}
          >
            <ModalContainer
              handleCancel={props.actions.hideModal}
              modalTitle={<Translate id="BOOKMARKS.CREATE_BOOKMARK_TITLE" />}
            >
              <BookmarkForm
                clusterId={camera.clusterId ? camera.clusterId : camera.GroupId}
                handleCancel={props.actions.hideModal}
                initialValues={bookmarkInitialValues}
                selectedCameraId={camera.cameraId}
                siteId={locationId}
              />
            </ModalContainer>
          </Modal>
        </div>
        <Modal
          className={modalContentContainer}
          contentLabel="PtzTourForm"
          isOpen={props.isPtzTourModalOpen}
          onRequestClose={() => closeModal(props)}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          <ModalContainer
            handleCancel={() => closeModal(props)}
            modalTitle={<Translate id="PTZ.TOURS.CREATE_MODAL_LABEL" />}
          >
            {/* <PtzTourFormContainer
              deviceId={props.deviceId}
              cameraId={props.cameraId}
              handleCancel={() => closeModal(props)}
              // onSubmit={this.handleSubmitPtzForm}
              initialValues={
                // this.state.isEditingPtzTour ? initialEditTourData : {}
                {}
              }
            /> */}
          </ModalContainer>
        </Modal>
      </>
    );
  }
  return (
    <div
      className={
        isActivePane ? `${videoPlayerWrap}${activePane}` : videoPlayerWrap
      }
    >
      {!device ||
      (device && getServerConnectionState(device)) !== CAMERA_CONNECTED ||
      (isDisconnected && !cameraHaveRecordedData(camera) && !startTime) ? (
        <PageMessage
          fixedPosition={false}
          messageStyle={messageStyleStrings.error}
          translateBody="VIDEO_PLAYER.DEVICE_OFFLINE_MESSAGE"
          visible
        />
      ) : (
        <VideoPlayer {...props} />
      )}
    </div>
  );
});

PlayerHost.defaultProps = {
  canEditROI: true,
  canvasType: canvasTypes.general,
  device: null,
  deviceId: undefined,
  hideBookmarkButton: false,
  hideOverlayToggle: false,
  isActivePane: false,
  isDisabledBlueCamera: false,
  isDisconnected: false,
  showCanvas: false,
  startTime: undefined,
  timezone: null,
};

PlayerHost.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  camera: PropTypes.shape({
    GroupId: PropTypes.string,
    aftClient: PropTypes.shape({
      connection: PropTypes.shape({}),
    }).isRequired,
    cameraId: PropTypes.string,
    clusterId: PropTypes.string,
    readAdditionalTimeline: PropTypes.func,
    recordedData: PropTypes.bool,
  }).isRequired,
  canEditROI: PropTypes.bool,
  canvasType: PropTypes.oneOf(Object.values(canvasTypes)),
  device: PropTypes.objectOf({}),
  deviceId: PropTypes.number,
  deviceOnline: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  hideBookmarkButton: PropTypes.bool,
  hideOverlayToggle: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isActivePane: PropTypes.bool,
  isBookmarksModalOpen: PropTypes.bool.isRequired,
  isDisabledBlueCamera: PropTypes.bool,
  isDisconnected: PropTypes.bool,
  isPtzTourModalOpen: PropTypes.bool.isRequired,
  locationId: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  onStreamConnectionChange: PropTypes.func.isRequired,
  paused: PropTypes.bool.isRequired,
  setResumeTime: PropTypes.func.isRequired,
  showCanvas: PropTypes.bool,
  startTime: PropTypes.string,
  syncVideo: PropTypes.bool.isRequired,
  timezone: PropTypes.string,
  width: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
  /* TODO this is a workaround for gateway capabilities not being correctly
   * returned for all devices. This component simply accept a camera and/or
   * look up a camera by id from the cameraSelection selector, then use
   * GatewayCapabilities property to decide which player to use. It shouldn't
   * rely on internal structures of the store.
   */
  const aftCameras = Object.values(state.aft).reduce(
    (memo, clusters) =>
      memo.concat(
        Object.values(clusters).reduce(
          (cameraMemo, cameras) => cameraMemo.concat(cameras),
          [],
        ),
      ),
    [],
  );
  const aftCamera = aftCameras.find(
    camera =>
      camera.id === ownProps.cameraRemoteId || camera.id === ownProps.cameraId,
  );
  const camera = decorateCamera(aftCamera || ownProps.camera);
  const isDisabledBlueCamera = camera.Connected && camera.IsDisconnected;

  // Need locationId for add bookmark functionality
  let locationId = ownProps.camera && ownProps.camera.LocationId;
  if (!locationId) {
    state.clusters.clusters.forEach(cluster => {
      if (cluster.Id === camera.clusterId) {
        locationId = cluster.LocationId;
      }
    });
  }
  let timezone = null;
  const location = state.locations.locations.find(x => x.Id === locationId);
  const locationName = location ? location.Name : undefined;
  const deviceId = ownProps.camera && ownProps.camera.DeviceId;
  const device = state.devices.devices.find(x => x.Id === deviceId);
  if (camera.isBlueConnectCamera) {
    if (device && location) {
      const deviceTimezone = device.TimeZone || null;
      if (UTC_TZ_STRINGS.includes(deviceTimezone) || deviceTimezone == null) {
        /**
         * device timezone is either in UTC or not valid
         * use location's timezone instead
         *
         * if the location's time zone is not valid, will pass in null for the timezone to Savitar
         * and savitar would choose UTC for displaying the time.
         */
        timezone = locationId ? location.TimeZone : null;
      } else {
        /**
         * use device timezone
         */
        timezone = deviceTimezone;
      }
    }
  }
  let { canEditROI, canvasType, showCanvas } = ownProps;
  const { id: hostId } = ownProps;
  const { cameraId } = camera;
  const streamOverlays = state.streamOverlays[cameraId];
  if (streamOverlays && streamOverlays.showRuleCanvas) {
    // Canvas will be shown if either ownProps or redux store says to show it.
    const showRuleCanvas =
      streamOverlays.showRuleCanvas.includes(hostId) ||
      streamOverlays.showRuleCanvas.includes(toString(hostId));
    if (showRuleCanvas) {
      showCanvas = true;
      canvasType = canvasTypes.rules;
      canEditROI = false;
    }
  }

  return {
    camera,
    canEditROI,
    canvasType,
    device,
    isBookmarksModalOpen:
      state.modal.isOpen &&
      state.modal.modalType === modalTypes.EDIT_BOOKMARK &&
      state.modal.modalProps.modalKey === ownProps.id,
    isDisabledBlueCamera,
    isPtzTourModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.PTZ_TOUR,
    locationId,
    locationName,
    showCanvas,
    syncVideo: state.views.syncVideo,
    /**
     * `syncedStartTime` is a safekeeper for EVO player since `PlayerManagerHOC`
     * depends on this prop. Once EVO player is completed removed, this prop can
     * also be removed
     */
    syncedStartTime: state.views.syncedStartTime,
    timezone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        hideModal,
        showModal,
        toggleSyncVideo,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerHost);
