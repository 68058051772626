import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-localize-redux';
import Modal from 'react-modal';
import { Route, Switch } from 'react-router-dom';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from 'util/telemetryService';

// Components
import {
  BookmarkDetailsContainer,
  BookmarkForm,
  BookmarksListAccordion,
  ModalContainer,
  PageMessage,
} from 'containers';
import { InputFilter } from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';

// Actions
import { getLocations } from 'actions/locations';
import { getUsers } from 'actions/user';
import * as FilterActions from 'actions/filters';
import { hideModal } from 'actions/modal';
import { getClusters } from 'actions/clusters';

// Selectors
import * as BookmarksActions from 'actions/bookmarks';

// Utilities
import * as permissions from 'util/permissions';
import FilterableContainer from 'util/FilterableContainer';

// Styles
import { modalContentContainer, modalOverlay } from 'sharedStyles/styles.css';

// Constants
import { PATH_BOOKMARKS } from 'constants/urlPaths';
import { FILTER_GROUPS } from 'constants/app';
import * as modalTypes from 'constants/ModalTypes';
import { BOOKMARKS_MESSAGE } from 'constants/MessageTypes';
import {
  idSiteSearchInput,
  SITE_SEARCH_PLACEHOLDER,
  siteTextInputfield,
} from './constants';
import {
  filterGroup,
  filterWrapper,
  heading,
  siteSearchFilter,
} from './styles.css';
import NavigationWrapper from '../NavigationWrapper/NavigationWrapper';

class BookmarksContainer extends FilterableContainer {
  componentDidMount() {
    const {
      actions,
      isFetchingClusters,
      isFetchingSites,
      isFetchingUsers,
    } = this.props;
    if (isFetchingClusters === null) {
      actions.getClusters();
    }

    if (isFetchingSites === null) {
      actions.getLocations();
    }
    if (isFetchingUsers === null) actions.getUsers();
  }

  componentDidUpdate() {
    const {
      actions,
      firstLocationId,
      isFetchingBookmarks,
      isFetchingClusters,
      isFetchingSites,
      isFetchingUsers,
    } = this.props;
    const clustersForSite = this.getClustersForSite(firstLocationId);

    if (isFetchingSites === null) {
      this.props.actions.getLocations();
    }
    if (
      isFetchingSites === false &&
      isFetchingClusters === false &&
      clustersForSite.every(
        clusterId => isFetchingBookmarks[clusterId] === undefined,
      )
    ) {
      actions.getBookmarksForLocationClusters(clustersForSite);
    }
    if (isFetchingUsers === null) actions.getUsers();
  }

  onBackClick = () => {
    history.back();
  };

  closeBookmarksModal = () => {
    this.props.actions.hideModal();
  };

  getClustersForSite = siteId => {
    const { clusters } = this.props;
    return clusters.filter(c => c.LocationId === siteId).map(c => c.Id);
  };

  siteHasBookmarks = site => {
    const { bookmarksData } = this.props;
    const clustersForSite = this.getClustersForSite(site.Id);
    let siteHasBookmarks = false;
    clustersForSite.forEach(c => {
      if (bookmarksData[c.Id] && bookmarksData[c.Id].length > 0) {
        siteHasBookmarks = true;
      }
    });
    return siteHasBookmarks;
  };

  render() {
    const items = this.props.filteredItems;
    const {
      canChangeCustomer,
      handleFilter,
      history,
      isBookmarksModalOpen,
      isFetchingSites,
    } = this.props;

    return (
      <>
        <Switch>
          <Route
            path={`${PATH_BOOKMARKS}/:siteId/:clusterId/:bookmarkId`}
            render={props => {
              const { bookmarkId, clusterId, siteId } = props.match.params;
              return (
                <BookmarkDetailsContainer
                  key={bookmarkId}
                  bookmarkId={bookmarkId}
                  canChangeCustomer={canChangeCustomer}
                  clusterId={clusterId}
                  history={history}
                  onBackClick={this.onBackClick}
                  siteId={siteId}
                />
              );
            }}
          />
          <Route
            path={`${PATH_BOOKMARKS}`}
            render={() => {
              return (
                <div>
                  <ListNav
                    canChangeCustomer={this.props.canChangeCustomer}
                    pageTitle={
                      <PageTitle titleId="NAV_MENU.TITLES.BOOKMARKS" />
                    }
                  />
                  <MainContentWrapper>
                    <PageMessage messageType={BOOKMARKS_MESSAGE} />
                    <div className={heading}>
                      <Translate id="SECONDARY_NAV.TABS.SITES.LABEL" />
                    </div>
                    <div className={filterGroup}>
                      <div className={filterWrapper}>
                        <InputFilter
                          className={siteSearchFilter}
                          field={siteTextInputfield}
                          id={idSiteSearchInput}
                          onChange={handleFilter}
                          overrideValue
                          placeholder={SITE_SEARCH_PLACEHOLDER}
                          value={this.getFilterValue('Name')}
                        />
                      </div>
                    </div>
                    <EmptyPlaceholder
                      isFetching={isFetchingSites && items.length === 0}
                      items={items}
                      string={<Translate id="FILTER.NO_RESULTS_FOUND" />}
                    >
                      <BookmarksListAccordion
                        getClustersForSite={this.getClustersForSite}
                        isFetchingSites={isFetchingSites}
                        siteHasBookmarks={this.siteHasBookmarks}
                        sites={items}
                      />
                    </EmptyPlaceholder>
                  </MainContentWrapper>
                </div>
              );
            }}
          />
        </Switch>
        <Modal
          className={modalContentContainer}
          contentLabel="BookmarksForm"
          isOpen={isBookmarksModalOpen}
          onRequestClose={this.closeBookmarksModal}
          overlayClassName={modalOverlay}
          shouldCloseOnOverlayClick={false}
        >
          <ModalContainer
            handleCancel={this.closeBookmarksModal}
            modalTitle={<Translate id="BOOKMARKS.EDIT_MODAL_TITLE" />}
          >
            <BookmarkForm
              handleCancel={this.closeBookmarksModal}
              onBackClick={this.onBackClick}
            />
          </ModalContainer>
        </Modal>
      </>
    );
  }
}

BookmarksContainer.defaultProps = {
  clusters: [],
  firstLocationId: null,
  isFetchingFirstBookmarks: null,
  locations: [],
};

function mapStateToProps(state) {
  const { profile, users } = state.user;
  const { locations } = state.locations;

  const orgId = permissions.getOrgIdFromStore(state);

  let firstLocationId;
  if (locations.length > 0) {
    firstLocationId = locations[0].Id;
  }

  return {
    bookmarksData: state.bookmarks.bookmarks,
    canChangeCustomer: state.user.permissions.CAN_PROXY_AS_CUSTOMERS,
    clusters: state.clusters.clusters,
    filterGroup: FILTER_GROUPS.SITES,
    filterStatus: state.filters.filterStatus,
    firstLocationId,
    isBookmarksModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.EDIT_BOOKMARK,
    isFetchingBookmarks: state.isFetching.getBookmarks,
    isFetchingClusters: state.isFetching.getClusters,
    isFetchingSites: state.isFetching.getLocations,
    isFetchingUsers: state.isFetching.getUsers,
    items: state.locations.locations,
    locations,
    orgId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...FilterActions,
        ...BookmarksActions,
        getClusters,
        getLocations,
        getUsers,
        hideModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  withAITracking(ai.reactPlugin, BookmarksContainer, 'BookmarksContainer'),
  mapStateToProps,
  mapDispatchToProps,
  null,
  { textInputFields: siteTextInputfield },
);
