import { ERS_DOMAIN, ERS_PORT } from 'constants/app';

const initializePlayer = (tagId, imgReady, mediaProps, commandHandler) => {
  const opts = {
    ersIp: mediaProps.ersUri || mediaProps.ers || ERS_DOMAIN,
    streamName: mediaProps.streamName,
    roomName: mediaProps.room,
    videoTagId: tagId.toString(),
    useSrtp: false,
    ersOverSsl: true,
    port: mediaProps.ersPort || ERS_PORT,
    imgReady,
    commandHook: commandHandler,
  };

  const player = new EvoWrtcPlayer(opts);
  return player;
};

export default initializePlayer;
