import React, { useEffect } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { VerticalFormFieldStack } from 'components';
import { Button, GroupLayout, ListNav, PageTitle } from 'lib';

// Utils
import {
  authTokenLength,
  validateConfirmPassword,
  validatePassword,
} from 'util/validation';
import { validateConnectionString } from 'util/asyncValidation';

// Constants
import { INTEGRATION_TYPES } from 'constants/app';
import {
  formWrapper,
  horizontalDividerSolid,
} from 'sharedStyles/modalForms.css';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_MONITORING,
  PATH_SEGMENT_MONITORING_CENTERS,
} from 'constants/urlPaths';
import * as CentralStationConsts from './constants';

// Styles
import { usernamePasswordTextContainer } from './CentralStationForm.styles.css';
import { formContent } from '../../../../components/ProfileForm/styles.css';

const CentralStationForm = props => {
  const {
    asyncValidate,
    asyncValidating,
    canChangeCustomer,
    dirty,
    editingConfig,
    fieldValidations,
    handleSubmit,
    initialValues,
    input,
    integrationType,
    integrationTypes,
    invalid,
    pristine,
    reset,
    translate,
  } = props;
  const failedValidations = !Object.values(fieldValidations).reduce(
    (acc, cur) => acc && cur,
    true,
  );

  useEffect(() => {
    if (editingConfig && failedValidations) {
      asyncValidate();
    }
  }, [asyncValidate, editingConfig, failedValidations]);

  const AUTHENTICATION_TOKEN_PROPS = {
    hidden: integrationType !== INTEGRATION_TYPES.WEBHOOKS,
    inputId: 'authenticationToken',
    label: 'INTEGRATIONS.CONFIGS.AUTHENTICATION_TOKEN',
    maxLength: '256',
    name: 'AuthenticationToken',
    tooltipProps: {
      arrowPostion: 'left',
      message: 'INTEGRATIONS.TOOLTIP.AUTHENTICATION_TOKEN',
      offsetRight: -197,
      offsetTop: 25,
      width: 200,
    },
    type: 'textarea',
  };
  const SOFTWARE_PROPS = [
    {
      inputId: 'Name',
      label: 'COMMON.NAME',
      name: 'Name',
      tooltipProps: {
        arrowPostion: 'left',
        message: CentralStationConsts.TOOLTIPS.Name,
        offsetRight: -197,
        offsetTop: 25,
        width: 200,
      },
      type: 'text',
    },
    {
      inputId: 'integrationType',
      label: 'INTEGRATIONS.CONFIGS.INTEGRATION_TYPE',
      name: 'IntegrationType',
      options: integrationTypes,
      placeholder: translate(
        'INTEGRATIONS.MONITORING_CENTER_SELECTOR_PLACEHOLDER',
      ),
      type: 'select',
    },
    {
      hidden: !integrationType,
      inputId: 'connectionString',
      label: CentralStationConsts.CONNECTION_STRING_LABELS[integrationType],
      name: 'ConnectionString',
      tooltipProps: {
        arrowPostion: 'left',
        message: CentralStationConsts.TOOLTIPS[integrationType],
        offsetRight: -282,
        offsetTop: 25,
        width: 300,
      },
      type: 'text',
    },
  ];

  return (
    <>
      <ListNav
        canChangeCustomer={canChangeCustomer}
        pageTitle={
          <PageTitle
            backPath={`${PATH_ACCOUNTS}${PATH_SEGMENT_MONITORING}${PATH_SEGMENT_MONITORING_CENTERS}`}
            showBackButton
            titleData={{ configName: initialValues.Name }}
            titleId={
              initialValues.IntegrationConfigurationId
                ? 'INTEGRATIONS.CONFIGS.DETAIL_TITLE'
                : 'INTEGRATIONS.CONFIGS.NEW_DETAIL_TITLE'
            }
          />
        }
      />
      <div className={horizontalDividerSolid} />
      <div className={formWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={formContent}>
            <VerticalFormFieldStack
              fieldPropList={SOFTWARE_PROPS}
              filterPropList={
                integrationType === INTEGRATION_TYPES.WEBHOOKS
                  ? []
                  : CentralStationConsts.FILTER_SOFTWARE_PROPS
              }
              input={input}
            />
          </div>
          <div className={usernamePasswordTextContainer}>
            {translate(
              integrationType === INTEGRATION_TYPES.WEBHOOKS
                ? 'INTEGRATIONS.ADD_STATION_MODAL.CREATE_USERNAME_PASSWORD_WEBHOOK'
                : 'INTEGRATIONS.ADD_STATION_MODAL.CREATE_USERNAME_PASSWORD',
            )}
          </div>
          <div className={formContent}>
            <VerticalFormFieldStack
              fieldPropList={[
                ...CentralStationConsts.USER_PROPS,
                AUTHENTICATION_TOKEN_PROPS,
              ]}
              input={input}
              newUser
            />
          </div>
          <GroupLayout
            horizontalPositioning="left"
            verticalCenter
            verticalSpacing="medium"
          >
            <Button
              key="buttonRevert"
              buttonType="primary"
              disabled={!dirty}
              id="buttonRevert"
              inputType="button"
              onClick={reset}
              text={<Translate id="BUTTONS.REVERT" />}
            />
            <Button
              key="userFormButtonSave"
              buttonType="primary"
              disabled={
                invalid || pristine || asyncValidating || failedValidations
              }
              id="userFormButtonSave"
              inputType="button"
              onClick={handleSubmit}
              text={<Translate id="BUTTONS.SAVE" />}
            />
          </GroupLayout>
        </form>
      </div>
    </>
  );
};

CentralStationForm.propTypes = {
  asyncValidate: PropTypes.func.isRequired,
  asyncValidating: PropTypes.node.isRequired,
  canChangeCustomer: PropTypes.bool,
  dirty: PropTypes.bool,
  editingConfig: PropTypes.bool,
  fieldValidations: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  input: PropTypes.string,
  integrationType: PropTypes.string,
  integrationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      Display: PropTypes.string,
      Enum: PropTypes.string,
    }),
  ).isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

CentralStationForm.defaultProps = {
  canChangeCustomer: false,
  dirty: false,
  editingConfig: false,
  input: '',
  integrationType: '',
};

const validateWebhooks = values => {
  const errors = {};
  if (values.IntegrationType !== INTEGRATION_TYPES.WEBHOOKS) {
    return errors;
  }
  if (
    !values.AuthenticationToken ||
    (values.AuthenticationToken && values.AuthenticationToken.length === 0)
  ) {
    errors.AuthenticationToken = (
      <Translate id="INTEGRATIONS.CONFIGS.AUTHENTICATION_TOKEN_REQUIRED" />
    );
  }
  const authenticationTokenError = authTokenLength(values.AuthenticationToken);
  if (authenticationTokenError) {
    errors.AuthenticationToken = <Translate id={authenticationTokenError} />;
  }
  if (
    !values.ConnectionString ||
    (values.ConnectionString && values.ConnectionString.length === 0)
  ) {
    errors.ConnectionString = (
      <Translate id="INTEGRATIONS.CONFIGS.CONNECTION_STRING_REQUIRED" />
    );
  }
  return errors;
};

const validatePasswordFields = values => {
  const errors = {};
  const passwordError = validatePassword(values.ApiLoginPassword);
  const confirmPasswordError = validateConfirmPassword(
    values.ConfirmPassword,
    values.ApiLoginPassword,
  );
  if (passwordError) errors.ApiLoginPassword = <Translate id={passwordError} />;
  if (confirmPasswordError)
    errors.ConfirmPassword = <Translate id={confirmPasswordError} />;
  return errors;
};

const validate = (values, props) => {
  const errors = {};
  if (values) {
    if (!values.Name || (values.Name && values.Name.length === 0)) {
      errors.Name = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      !values.ApiLoginUsername ||
      (values.ApiLoginUsername && values.ApiLoginUsername.length === 0)
    ) {
      errors.ApiLoginUsername = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      !values.ConnectionString ||
      (values.ConnectionString && values.ConnectionString.length === 0)
    ) {
      errors.ConnectionString = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      !values.IntegrationType ||
      (values.IntegrationType && values.IntegrationType.length === 0)
    ) {
      errors.IntegrationType = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
  }
  return {
    ...errors,
    ...validatePasswordFields(values),
    ...validateWebhooks(values, props),
  };
};

const asyncValidate = async (values, _dispatch, props) => {
  const { setValidation } = props;
  const errors = {};
  if (values.IntegrationType && values.ConnectionString) {
    const connectionStringErrors = await validateConnectionString(
      values.IntegrationType,
      values.ConnectionString,
    );
    if (connectionStringErrors) {
      errors.ConnectionString = connectionStringErrors;
    }
  }
  if (Object.keys(errors).length > 0) {
    throw errors;
  }
  if (!errors.ConnectionString) {
    setValidation(
      CentralStationConsts.FORM_FIELDS_LIST.CONNECTION_STRING,
      true,
    );
  }
};

const onChange = (values, _dispatch, props, previousValues) => {
  const { setValidation } = props;
  if (values.ConnectionString !== previousValues.ConnectionString) {
    setValidation(
      CentralStationConsts.FORM_FIELDS_LIST.CONNECTION_STRING,
      false,
    );
  }
};

export default reduxForm({
  asyncBlurFields: [CentralStationConsts.FORM_FIELDS_LIST.CONNECTION_STRING],
  asyncChangeFields: [CentralStationConsts.FORM_FIELDS_LIST.INTEGRATION_TYPE],
  asyncValidate,
  enableReinitialize: true,
  fields: Object.values(CentralStationConsts.FORM_FIELDS_LIST),
  form: 'centralStationForm',
  onChange,
  touchOnChange: true,
  validate,
})(withLocalize(CentralStationForm));
