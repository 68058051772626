exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".textDescription___kP-Kg {\r\n  font-size: 10pt;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/RegisterText/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".textDescription {\r\n  font-size: 10pt;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"textDescription": "textDescription___kP-Kg"
};