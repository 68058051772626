import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { ArmDisarmCameraMenu } from 'components';
import { Icon } from 'lib';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import { IC_NOTIFICATION, IC_NOTIFICATION_OFF } from 'constants/iconNames';

import {
  armDisarmDropdown,
  armDisarmDropdownForNotifications,
  armDisarmIcon,
  deviceListArmDisarmDropdown,
  headerButton,
  headerButtonDisabled,
} from './ArmDisarmCamera.css';

const ArmDisarmCamera = ({
  actions,
  camera,
  device,
  dropdownPositionClass,
  iconClass,
  isDeviceCameraList,
  isLoading,
  isUpdating,
  onArmDisarmClick,
  showButtonWrap,
}) => {
  const [showArmDisarm, setShowArmDisarm] = useState(false);

  const onArmDisarmCameraClick = () => {
    onArmDisarmClick();
    if (!isUpdating || !isLoading) {
      setShowArmDisarm(!showArmDisarm);
    }
    return null;
  };

  const handleArmDisarmCamera = rearmDateTime => {
    if (camera.Disarmed) {
      actions.armCamera(camera.Id);
    } else {
      actions.disarmCamera(camera.Id, rearmDateTime);
    }
    setShowArmDisarm(false);
  };

  const handleArmDisarmCancel = () => {
    setShowArmDisarm(false);
  };

  const isDeviceConnected =
    device &&
    device.ConnectionState &&
    device.ConnectionState === CAMERA_CONNECTED;

  if (!isDeviceConnected || !camera) {
    return (
      <div
        className={
          showButtonWrap ? `${headerButton} ${headerButtonDisabled}` : ''
        }
      >
        <Icon
          iconClass={iconClass || armDisarmIcon}
          id={IC_NOTIFICATION}
          title="ALARMS.ARM_DISARM_CAMERA.ARM_ICON_TITLE"
        />
      </div>
    );
  }

  // This methods determines which css class (in ArmDisarmCamera.css) to use.
  const getWhereArmDisarmUsed = () => {
    const url = window.location.href;
    if (url.includes('/notifications/')) {
      return `${armDisarmDropdownForNotifications} ${dropdownPositionClass}`;
    }
    if (isDeviceCameraList) {
      return `${deviceListArmDisarmDropdown} ${dropdownPositionClass}`;
    }
    return `${armDisarmDropdown} ${dropdownPositionClass}`;
  };

  return (
    <>
      <div
        className={showButtonWrap ? `${headerButton} ` : ''}
        onClick={onArmDisarmCameraClick}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        {camera.Disarmed ? (
          <Icon
            iconClass={iconClass || armDisarmIcon}
            id={IC_NOTIFICATION}
            title="ALARMS.ARM_DISARM_CAMERA.ARM_ICON_TITLE"
          />
        ) : (
          <Icon
            iconClass={iconClass || armDisarmIcon}
            id={IC_NOTIFICATION_OFF}
            title="ALARMS.ARM_DISARM_CAMERA.DISARM_ICON_TITLE"
          />
        )}
      </div>
      {showArmDisarm && (
        <div className={getWhereArmDisarmUsed()}>
          <ArmDisarmCameraMenu
            disarmed={camera.Disarmed}
            onCancel={handleArmDisarmCancel}
            onSave={handleArmDisarmCamera}
          />
        </div>
      )}
    </>
  );
};

ArmDisarmCamera.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  camera: PropTypes.objectOf(PropTypes.any).isRequired,
  device: PropTypes.objectOf(PropTypes.any).isRequired,
  dropdownPositionClass: PropTypes.string,
  iconClass: PropTypes.string,
  isDeviceCameraList: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onArmDisarmClick: PropTypes.func,
  showButtonWrap: PropTypes.bool,
};

ArmDisarmCamera.defaultProps = {
  dropdownPositionClass: '',
  iconClass: null,
  isDeviceCameraList: false,
  isLoading: false,
  isUpdating: false,
  onArmDisarmClick() {},
  showButtonWrap: false,
};

export default ArmDisarmCamera;
