export const formName = 'changeProviderForm';
export const requiredFields = ['ClaimCode'];
export const fieldId = 'Id';
export const fieldCode = 'ClaimCode';
export const fieldServiceProviderName = 'ProviderName';
export const fieldSubscription = 'Name';
export const fieldTerm = 'FixedTermLength';
export const fieldAutoRenewable = 'IsAutoRenewable';
export const fieldSummary = 'summary';
export const maxCodeLength = 11;
