export const columnDataFields = {
  fieldAutoRenew: 'AutoRenew',
  fieldChannels: 'Channels',
  fieldCode: 'Code',
  fieldServicePlan: 'ServicePlan',
  fieldTerm: 'Term',
};
export const FIELD_AUTO_RENEW = 'autoRenew';
export const FIELD_SERVICE_PLAN = 'servicePlan';
export const FIELD_CHANNELS = 'channels';
export const FIELD_TERM = 'term';
export const FIELD_CODE = 'code';

export const iconColors = {
  BLACK: 'black',
  WHITE: 'white',
};

export const FIELD_ORDER = [
  FIELD_SERVICE_PLAN,
  FIELD_CHANNELS,
  FIELD_TERM,
  FIELD_AUTO_RENEW,
  FIELD_CODE,
];
