/* eslint-disable react/jsx-props-no-spreading */
// Libs
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';

// Components
import { ResponsiveFieldInput } from 'components';
import { PageMessage } from 'containers';
import { Button } from 'lib';

// Constants
import { SUBMIT_BUTTON_ID } from 'constants/ElementId';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import * as messageTypes from 'constants/MessageTypes';
import {
  DEFAULT_LANGUAGE,
  LOCAL_STORAGE_LANGUAGE,
} from 'constants/app';

// Validation
import {
  allPasswordChecksPass,
  required,
  validatePasswordInput,
} from 'util/validation';

// Styles
import { formWrapper } from 'sharedStyles/modalForms.css';
import {
  formGroup,
} from 'components/VerticalForm/styles.css';
import { buttonContainerRegister, pageError } from './styles.css';

const formLevelValidation = values => {
  const errors = {};
  if (values) {
    if (
      !values.FirstName ||
      (values.FirstName && values.FirstName.length === 0)
    ) {
      errors.FirstName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.LastName || (values.LastName && values.LastName.length === 0)) {
      errors.LastName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.Password) {
      errors.Password = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.ConfirmPassword) {
      errors.ConfirmPassword = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      values.Password &&
      values.ConfirmPassword &&
      values.Password !== values.ConfirmPassword
    ) {
      errors.ConfirmPassword = <Translate id="PROFILE.LABEL_ERROR_NO_MATCH" />;
    }
    const passwordFormatErrors = validatePasswordInput(values.Password || '');
    if (values.Password && !allPasswordChecksPass(passwordFormatErrors)) {
      errors.Password = <Translate id="PROFILE.LABEL_ERROR_FORMATTING" />;
    }
  }
  return errors;
};

// Classs
class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      activeLanguage:
        localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || DEFAULT_LANGUAGE,
      initialLanguage:
        localStorage.getItem(LOCAL_STORAGE_LANGUAGE) || DEFAULT_LANGUAGE,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.initialValues &&
      prevState.initialLanguage !==
        nextProps.initialValues.CommunicationLanguage
    ) {
      return {
        activeLanguage: nextProps.initialValues.CommunicationLanguage,
        initialLanguage: nextProps.initialValues.CommunicationLanguage,
      };
    }
    return null;
  }

  componentDidUpdate() {
    const { change } = this.props;
    const { activeLanguage } = this.state;
    change('CommunicationLanguage', activeLanguage);
  }

  setTheValue = (field, value) => {
    const { change } = this.props;
    change(field, value);
  };

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const { handleSubmit, invalid, translate } = this.props;
    return (
      <div className={formWrapper}>
        <form onSubmit={handleSubmit}>
          <div className={pageError}>
            <PageMessage
              messageStyle={messageStyleStrings.error}
              messageType={messageTypes.SIGNUP_ERROR}
            />
          </div>
          <Field
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="SIGNUP.ORGANIZATION_NAME_LABEL"
            name="Name"
            type="text"
            validate={required}
          />
          <Field
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="SIGNUP.FIRST_NAME_LABEL"
            name="PrimaryAdminUserFirstName"
            type="text"
            validate={required}
          />
          <Field
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="SIGNUP.LAST_NAME_LABEL"
            name="PrimaryAdminUserLastName"
            type="text"
            validate={required}
          />
          <Field 
            className={formGroup}
            component={ResponsiveFieldInput}
            labelTranslationId="COMMON.EMAIL"
            name="PrimaryAdminUserEmailAddress"
            type="email"
            validate={required} 
          />
          
          <div className={buttonContainerRegister}>
            <Button
              buttonType="secondary"
              inputType="submit"
              text={<Translate id="BUTTONS.CANCEL" />}
              onClick={this.goBack}
            />
            <Button
              disabled={invalid}
              id={SUBMIT_BUTTON_ID}
              inputType="submit"
              text={<Translate id="BUTTONS.SUBMIT" />}
            />
          </div>
        </form>
      </div>
    );
  }
}

SignupForm.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ CommunicationLanguage: PropTypes.string })
    .isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
  enableReinitialize: true,
  form: 'signupForm',
  validate: formLevelValidation,
})(withRouter(withLocalize(SignupForm)));
