// Libs
import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { SVG_XMLNS } from '../constants/app';

const IconHelpQuestion = ({ width, height, title }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns={SVG_XMLNS}
      title={title}
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <circle fill="#0052CC" cx="8" cy="8" r="8" />
        <path
          d="M8 11.38a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM6.749 5.736A.875.875 0 1 1 5 5.647C5.09 3.918 6.35 3 8.01 3c2.241 0 3.875 2.22 2.538 4.54-.239.412-.55.723-1.006 1.07-.048.036-.43.316-.514.384-.134.107-.143.12-.143.034v.851a.875.875 0 0 1-1.75 0v-.85c0-.588.288-.99.797-1.4.12-.096.528-.396.55-.413.287-.217.452-.381.55-.55C9.636 5.612 9 4.75 8.008 4.75c-.811 0-1.225.301-1.26.987z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

IconHelpQuestion.defaultProps = {
  width: '16px',
  height: '16px',
  title: '',
};

IconHelpQuestion.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
};

export default IconHelpQuestion;
