import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment';
import { select as d3Select } from 'd3-selection';
import { transition } from 'd3-transition';
import * as d3Scale from 'd3-scale';
import * as d3Timeline from 'd3-timelines';
import * as d3Time from 'd3-time';
import * as d3TimeFormat from 'd3-time-format';
import { debounce } from 'lodash';

// Components
import Draggable from 'react-draggable';

// Actions
import * as AlarmActions from 'actions/alarms';
import * as DeviceActions from 'actions/devices';

// Constants
import { ALARM_FETCH_DIRECTION_NEXT } from 'constants/ActionTypes';
import {
  DATABASE_DATETIME_EXTRACT_FORMAT,
  DATABASE_DATETIME_FORMAT,
  STREAM_TYPES,
} from 'constants/app';

// Styles
import {
  timelineMetaContainer,
  timelineContainer,
  scrubberHandle,
  scrubberVisible,
  zoomButton,
  timelineLabel,
} from './styles.css';
import {
  convertCameraTimeTo,
  getFormatForActiveLocale,
  TIME_TYPES,
} from 'util/convertTimeTo';

// This should go elsewhere but I am not sure where
const scopeString = zoomIncrement => {
  if (zoomIncrement <= 10) {
    return '10_SECONDS';
  }
  if (zoomIncrement <= 50) {
    return '100_SECONDS';
  }
  return '1000_SECONDS';
};

// Classes
class Scrubber extends Component {
  handleDrag = (e, d) => {
    this.props.onDrag(d.deltaX);
  };

  handleStop = (e, d) => {
    this.props.onStop(d.x);
  };

  render() {
    return (
      <Draggable
        axis="x"
        /* bounds={{ left: this.props.maxDrag, right: this.props.maxDrag }} */
        position={{ x: this.props.position, y: 0 }}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
      >
        <div className={scrubberHandle} onClick={this.onClick}>
          <div className={scrubberVisible} />
        </div>
      </Draggable>
    );
  }
}

class ZoomButton extends Component {
  handleZoom = () => {
    this.props.onZoom(this.props.zoomIncrement);
  };

  render() {
    return (
      <div className={zoomButton} onClick={this.handleZoom}>
        {this.props.icon}
      </div>
    );
  }
}

class TimelineLabel extends Component {
  render() {
    const { display, left, labelText, zIndex } = this.props;
    return (
      <div className={timelineLabel} style={{ display, left, zIndex }}>
        {labelText}
      </div>
    );
  }
}

class VideoTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: props.initialTime,
      scrubberTime: props.initialTime,
      isDraggingScrubber: false,
      zoomIncrement: 10,
      scrollValue: 0,
      eventLabel: null,
      eventMargin: 0,
      minTimelineRequested: null,
      eventsFetched: null,
    };
    this.timelineIdHash = props.hashId
      ? Math.floor((1 + Math.random()) * 0x10000).toString(16)
      : '';
    // A randomly generated hash to distinguish timelines
    // loaded on the same page
  }

  // Data requests

  requestTimeline = debounce((props = this.props) => {
    const { begin, end } = this.timelineLimits;
    const startRange = Moment(begin);
    const endRange = Moment(end);
    // TODO: Request a larger swath to decrease timeline calls
    const scope = scopeString(this.state.zoomIncrement);
    props.actions.getRecordingTimeline(
      props.cameraDeviceId,
      props.cameraRemoteId,
      startRange.utc().format(DATABASE_DATETIME_FORMAT),
      endRange.utc().format(DATABASE_DATETIME_FORMAT),
      scope,
      props.tenantId,
    );
  }, 500);

  requestEvents = (props = this.props) => {
    // Going to assume no server-side filtering in alarms, for now
    const { end } = this.timelineLimits;
    const endRange = Moment(end);
    const endEventTime = Moment(
      props.oldestEventTime,
      DATABASE_DATETIME_EXTRACT_FORMAT,
    );
    if (this.props.isFetchingEvents === null) {
      const queryOptions = {
        top: 50,
        filters: [
          {
            field: 'Created',
            typeOfValue: 'number',
            values: [endRange.format(DATABASE_DATETIME_EXTRACT_FORMAT)],
            operator: 'le',
          },
        ],
      };
      props.actions.getAlarms(queryOptions, ALARM_FETCH_DIRECTION_NEXT);
      this.setState({ eventsFetched: true });
    } else if (
      // TODO: some kind of while loop here
      endEventTime.isAfter(endRange) &&
      props.alarmsNextLink &&
      !this.props.isFetchingEvents
    ) {
      this.props.actions.getAlarmsNextPage(
        this.props.alarmsNextLink,
        ALARM_FETCH_DIRECTION_NEXT,
      );
    }
  };

  // Component lifecycle

  componentDidMount() {
    this.requestTimeline();
    this.requestEvents();
    this.mountTime = Moment();
    if (
      this.props.streamType === STREAM_TYPES.live &&
      this.mountTime.isAfter(this.props.initialTime)
    ) {
      // Account for timeline being opened after page load
      this.setState({
        currentTime: this.mountTime,
        scrubberTime: this.mountTime,
      });
    }
    d3Select(`#${this.props.timelineId}${this.timelineIdHash}`).append('svg');
    this.interval = setInterval(() => {
      if (
        !this.state.isDraggingScrubber &&
        (!this.props.isPlayerPaused ||
          this.props.streamType === STREAM_TYPES.live)
      ) {
        // Tick
        this.setState(state => ({
          currentTime: state.currentTime.clone().add(1, 'second'),
          scrubberTime: state.scrubberTime.clone().add(1, 'second'),
        }));
      }
    }, 1000 * this.props.playbackSpeed);
    this.setChart();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.initialTime &&
      !nextProps.initialTime.isSame(this.props.initialTime, 'second')
    ) {
      // Update if initialTime changes
      const isLiveStream = nextProps.streamType === STREAM_TYPES.live;
      this.setState({
        currentTime: isLiveStream ? Moment() : nextProps.initialTime,
        scrubberTime: isLiveStream ? Moment() : nextProps.initialTime,
      });
    }
    if (
      this.props.streamType !== STREAM_TYPES.live &&
      this.props.record.length === 0 &&
      nextProps.record.length > 0
    ) {
      // When viewing a timeline with no live video, start scrubber at last recorded video
      const lastRecording = nextProps.record.reduce(
        (maxT, t) => ({
          end: Moment.max(
            Moment(maxT.end, DATABASE_DATETIME_EXTRACT_FORMAT),
            Moment(t.end, DATABASE_DATETIME_EXTRACT_FORMAT),
          ),
        }),
        { end: Moment().subtract(1, 'years') },
      );
      const currentTime = lastRecording.end;
      this.setState({ currentTime, scrubberTime: currentTime });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.width !== nextProps.width ||
      this.props.record.length > nextProps.record.length ||
      this.props.motion.length > nextProps.motion.length ||
      this.props.events.length > nextProps.events.length ||
      !this.state.currentTime.isSame(nextState.currentTime, 'second') ||
      (this.state !== nextState &&
        this.state.scrubberTime === nextState.scrubberTime)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.setChart();
    if (
      this.props.initialTime !== prevProps.initialTime ||
      this.state.zoomIncrement > prevState.zoomIncrement
    ) {
      this.requestEvents();
      const { begin } = this.timelineLimits;
      const startRange = Moment(begin);
      const { minTimelineRequested } = this.state;
      if (!minTimelineRequested || minTimelineRequested.isAfter(startRange)) {
        this.requestTimeline();
        this.setState({ minTimelineRequested: startRange });
      }
    } else if (
      this.props.streamType === STREAM_TYPES.live &&
      this.state.currentTime.diff(this.mountTime, 'seconds') > 0 &&
      this.state.currentTime.diff(this.mountTime, 'seconds') % 20 === 0
    ) {
      // Update timeline data periodically while livestreaming so scrubber does not outpace
      // zone of recorded video
      this.requestTimeline();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // Chart utility

  setChart = () => {
    const timelineDiv = d3Select(
      `#${this.props.timelineId}${this.timelineIdHash}`,
    );
    timelineDiv
      .select('svg')
      .selectAll('*')
      .remove();
    timelineDiv
      .select('svg')
      .attr('width', this.props.width)
      .attr('height', this.props.height)
      .datum(this.processedData)
      .call(this.chart);
  };

  get timelineLimits() {
    const { zoomIncrement, currentTime } = this.state;
    const begin = currentTime
      .clone()
      .subtract(zoomIncrement * 30, 'minutes')
      .valueOf();
    const end = currentTime
      .clone()
      .add(zoomIncrement * 30 * 2.5, 'minutes')
      .valueOf();
    return { begin, end };
  }

  get widthToTimeRatio() {
    const width =
      this.props.width * 1.5 - (this.props.leftMargin + this.props.rightMargin); // width of timeline, not container
    const { begin, end } = this.timelineLimits;
    return width / (end - begin);
  }

  get chart() {
    const { begin, end } = this.timelineLimits;
    const { recordingColor, motionColor, eventColor } = this.props;
    const colorScale = d3Scale
      .scaleOrdinal()
      .range([recordingColor, motionColor, eventColor])
      .domain(['recording', 'motion', 'event']);
    const chart = d3Timeline
      .timelines()
      .orient('top')
      .itemMargin(-25)
      .tickFormat({
        format: d3TimeFormat.utcFormat(['%-I %p']),
        tickTime: d3Time.timeHours,
        tickInterval: 1,
        tickSize: 0,
      })
      .colors(colorScale)
      .colorProperty('color')
      .width(this.props.width * 1.5)
      .margin({
        left: this.props.leftMargin,
        right: this.props.rightMargin,
        top: this.props.topMargin,
        bottom: this.props.bottomMargin,
      })
      .hover(this.handleHover)
      .scroll(this.handleScroll)
      .click(this.handleClick)
      .beginning(begin + this.props.cameraTimezoneUnixOffset)
      .ending(end + this.props.cameraTimezoneUnixOffset); // Offset beginning and ending times only before they go into the chart rendering function
    return chart;
  }

  get processedData() {
    // For processed data, starting_time and ending_time are used for display purposes ONLY
    const { record, events } = this.props;
    const recordingTimes = record
      .filter(r => r.scope === scopeString(this.state.zoomIncrement))
      .map(t => ({
        starting_time:
          Moment(t.start, DATABASE_DATETIME_EXTRACT_FORMAT).valueOf() +
          this.props.cameraTimezoneUnixOffset,
        ending_time:
          Moment(t.end, DATABASE_DATETIME_EXTRACT_FORMAT).valueOf() +
          this.props.cameraTimezoneUnixOffset,
      }));
    // const motionTimes = motion.map(t => ({
    //   starting_time: Moment(t.start, DATABASE_DATETIME_EXTRACT_FORMAT).valueOf(),
    //   ending_time: Moment(t.end, DATABASE_DATETIME_EXTRACT_FORMAT).valueOf(),
    // }));
    // Not using motionTimes right now; may bring them back later
    const eventTimes = events.map(t => {
      const startTime = t.EventStartTime || t.StartTime || t.Created;
      const startMoment = Moment(startTime, DATABASE_DATETIME_EXTRACT_FORMAT);
      const formatForLocale = getFormatForActiveLocale();
      const startTimeFormatted = convertCameraTimeTo(
        startMoment,
        TIME_TYPES.LOCAL,
        this.props.cameraDevice,
        this.props.cameraLocation,
      );
      return {
        starting_time:
          startMoment.valueOf() + this.props.cameraTimezoneUnixOffset,
        ending_time:
          startMoment
            .clone()
            .add(this.state.zoomIncrement * 0.2, 'minutes')
            .valueOf() + this.props.cameraTimezoneUnixOffset,
        actual_start: startMoment.valueOf(), // Cache the REAL event start time so it can be used for database calls
        eventInfo: `${t.EventName}: ${startTimeFormatted}`,
        id: `event-${t.Id}`,
      };
    });
    const timeArray = [
      {
        color: 'recording',
        times: recordingTimes || [],
      },
      // { color: 'motion', times: motionTimes || [] },
      { color: 'event', times: eventTimes || [] },
    ];
    return timeArray;
  }

  getScrubberPosition = scrubberTime => {
    const { begin } = this.timelineLimits;
    const xOfCurrentTime =
      (scrubberTime.clone().valueOf() - begin) * this.widthToTimeRatio;
    return xOfCurrentTime;
  };

  renderDateLabels = () => {
    const labels = [];
    let { begin, end } = this.timelineLimits;
    const xOffset = begin + this.props.cameraTimezoneUnixOffset;
    begin = Moment(begin + this.props.cameraTimezoneUnixOffset).minutes(0);
    end = Moment(end + this.props.cameraTimezoneUnixOffset).minutes(0);
    while (begin.isBefore(end)) {
      if (begin.utc().hour() === 0) {
        const midnight = begin.clone();
        const labelText = midnight.format('MMM D[,] YYYY');
        const xOfMidnight =
          (midnight.valueOf() - xOffset) * this.widthToTimeRatio;
        labels.push(
          <TimelineLabel
            key={xOfMidnight}
            display="block"
            left={xOfMidnight}
            labelText={labelText}
          />,
        );
      }
      begin.add(1, 'hour');
    }
    return labels;
  };

  // Interactions

  handleHover = (d, i, datum, x, y, z) => {
    const eventLabel = d.eventInfo || null;
    if (this.state.eventLabel !== eventLabel) {
      const { begin } = this.timelineLimits;
      const eventMargin = (d.actual_start - begin) * this.widthToTimeRatio;
      this.setState({ eventLabel, eventMargin });
    }
  };

  handleClick = (d, i, datum) => {
    const { currentTime } = this.state;
    const newTime = d.actual_start;
    if (d.id) {
      this.setState({ eventLabel: null }, () => {
        const translateBy =
          (currentTime.valueOf() - newTime) * this.widthToTimeRatio;
        transition()
          .select(`#${this.props.timelineId}${this.timelineIdHash}`)
          .select('svg.scrollable')
          .select('.container')
          .transition()
          .style('transform', `translate(${translateBy}px, 0px)`)
          .on('end', () => {
            this.setState(
              {
                currentTime: Moment(newTime - 5000),
                scrubberTime: Moment(newTime - 5000),
              },
              () => {
                this.handleTimeChange();
              },
            );
          });
      });
    }
  };

  handleScroll = (newScrollValue, scale) => {
    const { scrollValue, zoomIncrement } = this.state;
    if (newScrollValue > scrollValue && zoomIncrement > this.props.zoomMin) {
      this.setState({
        zoomIncrement: this.state.zoomIncrement - 1,
        scrollValue: newScrollValue,
      });
    } else if (
      newScrollValue < scrollValue &&
      zoomIncrement < this.props.zoomMax
    ) {
      this.setState({
        zoomIncrement: this.state.zoomIncrement + 1,
        scrollValue: newScrollValue,
      });
    } else {
      this.setState({ scrollValue: newScrollValue });
    }
  };

  handleZoom = incrementBy => {
    const zoomIncrement = this.state.zoomIncrement + incrementBy;
    if (
      zoomIncrement < this.props.zoomMax &&
      zoomIncrement > this.props.zoomMin
    ) {
      this.setState({
        zoomIncrement,
      });
    }
  };

  handleScrubberDrag = deltaX => {
    this.setState(state => {
      const deltaTime = deltaX / this.widthToTimeRatio;
      const scrubberTime = Moment(state.scrubberTime.valueOf() + deltaTime);
      return { scrubberTime, isDraggingScrubber: true };
    }, this.handleScrubberChange);
  };

  handleScrubberStop = () => {
    // Cannot put scrubber in the future
    const scrubberTime = this.state.scrubberTime.isAfter(Moment(), 'second')
      ? Moment()
      : this.state.scrubberTime;

    this.setState({ scrubberTime, currentTime: scrubberTime }, () => {
      this.handleTimeChange();
      this.setState({ isDraggingScrubber: false });
    });
  };

  handleTimeChange = () => {
    this.props.onTimeChange(this.state.currentTime, this.state.scrubberTime);
  };

  handleScrubberChange = () => {
    this.props.onScrubberChange(this.state.scrubberTime);
  };

  render() {
    return (
      <div className={timelineMetaContainer}>
        <div>
          <ZoomButton
            onZoom={this.handleZoom}
            zoomIncrement={-1}
            icon={this.props.zoomInIcon}
          />
          <ZoomButton
            onZoom={this.handleZoom}
            zoomIncrement={1}
            icon={this.props.zoomOutIcon}
          />
        </div>
        <div className={timelineContainer}>
          {this.renderDateLabels()}
          <TimelineLabel
            key={this.state.eventMargin}
            display={this.state.eventLabel ? 'block' : 'none'}
            left={this.state.eventMargin}
            zIndex={2}
            labelText={this.state.eventLabel}
          />
          <Scrubber
            position={
              this.getScrubberPosition(this.state.scrubberTime) +
              this.props.leftMargin
            }
            onDrag={this.handleScrubberDrag}
            onStop={this.handleScrubberStop}
            maxDrag={this.props.width * 0.5}
          />
          <div
            className="d3-timeline"
            id={`${this.props.timelineId}${this.timelineIdHash}`}
          />
        </div>
      </div>
    );
  }
}

VideoTimeline.propTypes = {
  events: PropTypes.array.isRequired,
  record: PropTypes.array.isRequired,
  motion: PropTypes.array.isRequired,
};

VideoTimeline.defaultProps = {
  initialTime: Moment(),
  events: [],
  record: [],
  motion: [],
  playbackSpeed: 1, // Should match video playback speed
  backgroundColor: '#ddd',
  recordingColor: '#5DB6FF',
  motionColor: '#5DB6FF',
  eventColor: '#FF0000',
  onTimeChange: () => {},
  onScrubberChange: () => {},
  width: 700, // Width of the total component, including zoom buttons
  height: 100,
  zoomMax: 150,
  zoomMin: 1,
  zoomInIcon: '+',
  zoomOutIcon: '–',
  leftMargin: 30,
  rightMargin: 30,
  topMargin: 30,
  bottomMargin: 30,
  timelineId: 'timelineContainer',
  hashId: true, // Add random hash to id to prevent collisions with other VideoScrubber components
};

const mapStateToProps = (state, ownProps) => {
  const svgWidth = ownProps.width - 30; // Subtract width of zoom buttons
  let record;
  let motion;
  let events;
  let oldestEventTime;
  let initialTime;
  if (ownProps.cameraRemoteId && state.devices.timelines) {
    const timeline = state.devices.timelines[ownProps.cameraRemoteId];
    if (timeline) {
      record = timeline.record || [];
      motion = timeline.motion || [];
    }
  }
  if (ownProps.cameraId && state.alarms.allAlarms) {
    events = state.alarms.allAlarms.filter(
      a => a.CameraId === ownProps.cameraId,
    );

    if (state.alarms.allAlarms.length > 0) {
      oldestEventTime =
        state.alarms.allAlarms[state.alarms.allAlarms.length - 1].Created;
    }
  }
  if (ownProps.initialTime) {
    initialTime = ownProps.initialTime;
  }
  return {
    width: svgWidth,
    record,
    motion,
    events,
    isFetchingEvents: state.alarms.isFetchingAlarmData,
    alarmsNextLink: state.alarms.alarmsNextLink,
    oldestEventTime,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...AlarmActions,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoTimeline);
