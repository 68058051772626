/* Contains functionality to allow user state selections, e.g. collapsng or expanding an accordion, hiding or showing filters,
/* to be persisted as long as the same browser is used.
/* For now this uses localStorage. 
*/

import jsonParseRobust from 'util/jsonParseRobust';

export function persistShowFilters(value, scope) {
  const blueSettings = jsonParseRobust(
    window.localStorage.getItem('blueSettings'), // Will return {} if called on an empty value
  );

  if (!scope) {
    blueSettings.showFilters = value;
  } else {
    const showFilters = jsonParseRobust(blueSettings.showFilters);
    if (typeof showFilters === 'object') {
      blueSettings.showFilters[scope] = value;
    } else {
      blueSettings.showFilters = { [scope]: value };
    }
  }
  window.localStorage.setItem('blueSettings', JSON.stringify(blueSettings));
}

export function getShowFilters(scope) {
  const blueSettings = window.localStorage.getItem('blueSettings');
  if (!blueSettings) return true; // Show filters if this value has never been set

  const blueSettingsObject = jsonParseRobust(blueSettings);
  if (
    !blueSettingsObject.showFilters &&
    blueSettingsObject.showFilters !== false
  ) {
    return true; // Show filters if this value has never been set
  }
  if (!scope) return blueSettingsObject.showFilters; // If all filters have the same value
  return blueSettingsObject.showFilters[scope]; // if specific filters have unique values
}
