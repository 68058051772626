import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import Select from 'react-select-plus';
import 'react-select-plus/dist/react-select-plus.css';
import { SELECT_STYLE } from 'constants/app';
import { dashboardDropdown } from './dashboard.styles.css';

const currentCycleLabel = 'DEALER_DASHBOARD.CURRENT_BILLING_CYCLE_LABEL';
const previousCycleLabel = 'DEALER_DASHBOARD.PREVIOUS_BILLING_CYCLE_LABEL';

const BillingDropdown = props => {
  const {
    dealerBillingCycles,
    disabled,
    getIntervalDate,
    handleChangeBillingCycle,
    translate,
  } = props;
  const [selectedOption, setSelectedOption] = useState(null);

  const getIntervalText = billingCycle => {
    const { BeginDate: beginDate, EndDate: endDate } = billingCycle;
    const intervalBegin = getIntervalDate(beginDate);
    const intervalEnd = getIntervalDate(endDate);
    return `${intervalBegin} - ${intervalEnd}`;
  };

  const getOptionContent = (idx, intervalText) => {
    return idx === 0
      ? translate(currentCycleLabel, { cycle: intervalText })
      : translate(previousCycleLabel, { cycle: intervalText });
  };

  const generateOptionsList = billingCycleArray => {
    const optionArray = [];
    billingCycleArray.map((billingCycle, idx) => {
      const { Id: cycleId } = billingCycle;
      const intervalText = getIntervalText(billingCycle);
      const optionContent = getOptionContent(idx, intervalText);
      optionArray.push({
        cycleId,
        label: optionContent,
        value: optionContent,
      });
    });
    if (!selectedOption) setSelectedOption(optionArray[0]);

    return optionArray;
  };

  const onSelectChange = selectOption => {
    setSelectedOption(selectOption);
    handleChangeBillingCycle(selectOption);
  };

  const slicedBillingArray = dealerBillingCycles.slice(0, 2);

  const shouldRenderSelect =
    slicedBillingArray && slicedBillingArray.length > 0;

  if (shouldRenderSelect) {
    return (
      <Select
        className={`${dashboardDropdown} msi-select`}
        clearable={false}
        defaultValue={selectedOption}
        disabled={disabled}
        noResultsText={translate('FILTER.NO_RESULTS_FOUND')}
        onChange={onSelectChange}
        openOnFocus
        optionClassName="msi-select-option"
        options={generateOptionsList(slicedBillingArray)}
        style={SELECT_STYLE}
        tabIndex="-1"
        value={selectedOption}
      />
    );
  }
  return null;
};

BillingDropdown.defaultProps = {
  disabled: false,
};
BillingDropdown.propTypes = {
  dealerBillingCycles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  getIntervalDate: PropTypes.func.isRequired,
  handleChangeBillingCycle: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withLocalize(BillingDropdown);
