exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".navMenu___1rRM0 {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  width: 48px;\r\n}\r\n\r\n.navMenuItem___3Zf9K:first-child {\r\n}\r\n\r\n.navMenuItemImage___2m9Ix,\r\n.navMenuItemImageActive___bHPTg {\r\n  height: 40px;\r\n  width: 40px;\r\n  padding: 8px;\r\n  border-radius: 2px;\r\n  box-sizing: border-box;\r\n  vertical-align: top;\r\n  position: relative;\r\n  margin: 4px;\r\n}\r\n\r\n.navMenuItemActive___3EHt_ .navMenuItemImage___2m9Ix,\r\n.navMenuItemActive___3EHt_:hover .navMenuItemImage___2m9Ix {\r\n  display: block;\r\n  background-color: #005daa;\r\n}\r\n\r\n.navMenuItemImage___2m9Ix:hover {\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/containers/Login/NavMenu.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;MACvB,uBAAuB;EAC3B,YAAY;CACb;;AAED;CACC;;AAED;;EAEE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;CACb;;AAED;;EAEE,eAAe;EACf,0BAA0B;CAC3B;;AAED;EACE,qCAAqC;CACtC","file":"NavMenu.css","sourcesContent":[".navMenu {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-direction: column;\r\n      flex-direction: column;\r\n  width: 48px;\r\n}\r\n\r\n.navMenuItem:first-child {\r\n}\r\n\r\n.navMenuItemImage,\r\n.navMenuItemImageActive {\r\n  height: 40px;\r\n  width: 40px;\r\n  padding: 8px;\r\n  border-radius: 2px;\r\n  box-sizing: border-box;\r\n  vertical-align: top;\r\n  position: relative;\r\n  margin: 4px;\r\n}\r\n\r\n.navMenuItemActive .navMenuItemImage,\r\n.navMenuItemActive:hover .navMenuItemImage {\r\n  display: block;\r\n  background-color: #005daa;\r\n}\r\n\r\n.navMenuItemImage:hover {\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navMenu": "navMenu___1rRM0",
	"navMenuItem": "navMenuItem___3Zf9K",
	"navMenuItemImage": "navMenuItemImage___2m9Ix",
	"navMenuItemImageActive": "navMenuItemImageActive___bHPTg",
	"navMenuItemActive": "navMenuItemActive___3EHt_"
};