import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

import { PoETable, ServerDetailPoEBar } from 'components';
import { EmptyPlaceholder, MainContentWrapper } from 'lib';
import { PageMessage } from 'containers';
import { showMessage } from 'actions/pageMessage';

// Constants
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Styles
import { bindActionCreators } from 'redux';
import { tableSeparator } from 'sharedStyles/global.css';

class ServerDetailPOEContainer extends Component {
  getPortsWithCameraNames = () => {
    const { cameras, discoveredCameras, poe } = this.props;
    const moreCameras = discoveredCameras;
    const allCameras = [...cameras, ...moreCameras];
    const ports = [...poe.ports];
    allCameras.forEach(camera => {
      const cameraAddress = this.getCameraAddress(camera);
      ports.forEach(port => {
        if (
          port.hwaddr &&
          cameraAddress &&
          port.hwaddr.toUpperCase() === cameraAddress.toUpperCase()
        ) {
          const name = camera.Name || camera.name;
          port.cameraName = name;
        }
      });
    });
    return ports;
  };

  getCameraAddress = camera => {
    // The previous way may fail
    // Coz "" is a falsy value, false can't be converted to true by definition (since it's the opposite),
    // so it returns the second operand (undefined)
    if (typeof camera.PhysicalAddress !== 'undefined') {
      return camera.PhysicalAddress;
    }
    if (typeof camera.physicalAddress !== 'undefined') {
      return camera.physicalAddress;
    }
    return '';
  };

  showBudgetExceedMessage() {
    const { actions } = this.props;
    actions.showMessage(messageTypes.POE_BUDGET_WARNING, null, null, {
      messageStyle: messageStyleStrings.error,
      messageType: messageTypes.POE_BUDGET_WARNING,
      translateBody: 'POE.POE_BUDGET_EXCEED',
      translateHeader: 'GENERAL_MESSAGES.WARNING_HEADER',
    });
  }

  render() {
    const {
      hideModal,
      isFetchingDevicePoe,
      poe,
      showModal,
      updatePoE,
    } = this.props;
    const ports = this.getPortsWithCameraNames();
    return (
      <MainContentWrapper>
        <PageMessage
          messageType={[
            messageTypes.POE_GET_ERROR,
            messageTypes.POE_UPDATE_SUCCESS,
            messageTypes.POE_BUDGET_WARNING,
          ]}
        />
        <EmptyPlaceholder
          isFetching={isFetchingDevicePoe}
          items={poe}
          translateKey="POE.POE_NOT_FOUND"
        >
          <ServerDetailPoEBar poe={poe} />
          <div className={tableSeparator} />
          <PoETable
            hideModal={hideModal}
            ports={ports}
            showBudgetExceedMessage={this.showBudgetExceedMessage}
            showModal={showModal}
            updatePoE={updatePoE}
          />
        </EmptyPlaceholder>
      </MainContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ showMessage }, dispatch),
});

ServerDetailPOEContainer.defaultProps = {
  isFetchingDevicePoe: null,
};

ServerDetailPOEContainer.propTypes = {
  actions: PropTypes.shape({}).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  discoveredCameras: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hideModal: PropTypes.func.isRequired,
  isFetchingDevicePoe: PropTypes.bool,
  poe: PropTypes.shape({}).isRequired,
  showModal: PropTypes.func.isRequired,
  updatePoE: PropTypes.func.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(ServerDetailPOEContainer);
