import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dimensions from 'react-dimensions';
import moment from 'moment-timezone-all';

// Actions
import { getAllDevices, getCameras } from 'actions/devices';
import * as AftClientActions from 'actions/aftClient';

// Utils
import { sortObjectsArrayByPropertyValue } from 'util/sortDevicesAndCameras';

// Selectors
import cameraSelectionSelector from 'selectors/cameraSelection';

// Actions
import * as bookmarkActions from 'actions/bookmarks';
import { showModal } from 'actions/modal';

// Components
import { PageMessage } from 'containers';
import { BookmarkDetailsForm, PlayerHost } from 'components';
import { Button, ListNav, MainContentWrapper, PageTitle } from 'lib';
import { SelectCamera } from 'cameraSelection';

// Styles
import {
  tabContent,
  tabContentVerticalSeperator,
} from 'sharedStyles/global.css';
import { sectionHeader } from 'components/VerticalForm/styles.css';
import { horizontalDividerSolid } from 'sharedStyles/modalForms.css';

// Constants
import {
  LIST_NAV_TITLE_HEIGHT,
  PROXY_ORGANIZATION_DROPDOWN_HEIGHT,
} from 'constants/app';
import { PATH_BOOKMARKS } from 'constants/urlPaths';
import * as modalTypes from 'constants/ModalTypes';
import { CAMERA_CONNECTED } from 'constants/cameraTypes';
import { BOOKMARKS_MESSAGE } from 'constants/MessageTypes';
import { calculateVideoDimensions } from 'util/calculateVideoDimensions';
import {
  bookmarkDetailsContent,
  cameraContent,
  editBookmarkButton,
  editBookmarkPanelHeader,
  playerWrapper,
} from './styles.css';

class BookmarkDetailsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCameraId: null,
    };
  }

  componentDidMount() {
    const {
      actions,
      cluster,
      isFetchingBookmarks,
      isFetchingClusters,
    } = this.props;
    this.redirectIfUnauthorized();
    actions.getAllDevices();
    if (
      isFetchingBookmarks === null &&
      isFetchingClusters === false &&
      cluster &&
      cluster.Id
    ) {
      actions.getBookmarks(cluster.Id);
    }
  }

  componentDidUpdate() {
    const {
      actions,
      cluster,
      containerHeight,
      isFetchingBookmarks,
      isFetchingCamerasForSite,
      isFetchingClusters,
      isFetchingDeviceData,
      selectionList,
      siteId,
      updateDimensions,
    } = this.props;
    const { selectedCameraId: currentSelectedCameraId } = this.state;
    this.redirectIfUnauthorized();
    if (containerHeight === 0) {
      updateDimensions();
    }
    if (isFetchingDeviceData === null) {
      actions.getAllDevices();
    }
    if (
      isFetchingBookmarks === null &&
      isFetchingClusters === false &&
      cluster &&
      cluster.Id
    ) {
      actions.getBookmarks(cluster.Id);
    }
    if (
      isFetchingBookmarks === false &&
      isFetchingClusters === false &&
      isFetchingCamerasForSite === null &&
      cluster &&
      cluster.Id
    ) {
      actions.getCameras({
        field: 'LocationId',
        scope: cluster.Id,
        value: siteId,
      });
      actions.refreshCameraList(siteId, cluster);
    }
    if (
      currentSelectedCameraId === null &&
      selectionList[0].cameras &&
      selectionList[0].cameras.length
    ) {
      const selectedCameraId = selectionList[0].cameras[0].Id;
      this.setState({ selectedCameraId });
    }
  }

  redirectIfUnauthorized = () => {
    const {
      bookmark,
      cluster,
      history,
      isFetchingBookmarks,
      isFetchingClusters,
    } = this.props;
    if (isFetchingClusters === false && (!cluster || !cluster.Name)) {
      history.push(PATH_BOOKMARKS);
    }
    if (isFetchingBookmarks === false && (!bookmark || !bookmark.id)) {
      history.push(PATH_BOOKMARKS);
    }
    return null;
  };

  toggleBookmarksModal = () => {
    const { actions, bookmark, clusterId, siteId } = this.props;
    actions.showModal(modalTypes.EDIT_BOOKMARK, {
      bookmarkId: bookmark.id,
      clusterId,
      siteId,
    });
  };

  onSelectCamera = camera => {
    this.setState({ selectedCameraId: camera.Id });
  };

  getProtectedStatus = isProtected => {
    const { translate } = this.props;
    return isProtected
      ? translate('COMMON.YES')
      : translate('COMMON.NO');
  };

  getDuration = (startTime, endTime) => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const hours = endMoment.diff(startMoment, 'hours');
    const minutes = (endMoment.diff(startMoment, 'minutes') % 60)
      .toString()
      .padStart(2, '0');
    const seconds = (endMoment.diff(startMoment, 'seconds') % 60)
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  render() {
    const {
      bookmark,
      canChangeCustomer,
      containerHeight,
      containerWidth,
      locationName,
      onBackClick,
      profile,
      selectionList,
      translate,
    } = this.props;
    const { selectedCameraId } = this.state;
    const camera =
      selectionList[0].cameras !== undefined &&
      selectionList[0].cameras.find(c => c.Id === selectedCameraId);
    const { playerHeight, playerWidth, tabWidth } = calculateVideoDimensions(
      containerHeight,
      containerWidth,
      canChangeCustomer
        ? LIST_NAV_TITLE_HEIGHT + PROXY_ORGANIZATION_DROPDOWN_HEIGHT
        : LIST_NAV_TITLE_HEIGHT,
    );
    const mediaPlayerProperties = {
      height: playerHeight,
      position: 'relative',
      width: playerWidth,
    };

    return (
      <div>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          pageTitle={
            <PageTitle
              onBackClick={onBackClick}
              showBackButton
              titleData={{ name: bookmark.name }}
              titleId="BOOKMARKS.BOOKMARK_CREATED.HEADER_LABEL"
            />
          }
        />
        <div className={horizontalDividerSolid} />
        <MainContentWrapper>
          <PageMessage messageType={BOOKMARKS_MESSAGE} />
          <div className={bookmarkDetailsContent}>
            <div
              className={`${tabContent}`}
              style={{
                height:
                  playerHeight > 0
                    ? `${playerHeight}px`
                    : `calc(100vh - 233px)`,
                width: `${tabWidth}px`,
              }}
            >
              <div className={editBookmarkPanelHeader}>
                <div className={sectionHeader}>
                  {translate('COMMON.CAMERAS')}
                </div>
                <div className={editBookmarkButton}>
                  <Button
                    compact
                    inputType="button"
                    onClick={this.toggleBookmarksModal}
                    size="small"
                    text={translate('BOOKMARKS.EDIT_BOOKMARK_LINK')}
                  >
                    <div>{translate('BOOKMARKS.EDIT_BOOKMARK_LINK')}</div>
                  </Button>
                </div>
              </div>
              <div className={cameraContent}>
                {selectionList[0].cameras !== undefined && (
                  <SelectCamera
                    selectCamera={this.onSelectCamera}
                    selectedCameraId={selectedCameraId}
                    selectionList={selectionList}
                    showCompactLayout
                  />
                )}
              </div>
              <div>
                <BookmarkDetailsForm
                  initialValues={{
                    creationTime: bookmark.creationTime,
                    description: bookmark.description
                      ? bookmark.description
                      : translate('BOOKMARKS.BOOKMARK_CREATED.DESCRIPTION_EMPTY'),
                    duration: this.getDuration(
                      bookmark.startTime,
                      bookmark.endTime,
                    ),
                    endTime: bookmark.endTime,
                    isProtected: this.getProtectedStatus(bookmark.isProtected),
                    locationName,
                    name: bookmark.name,
                    startTime: bookmark.startTime,
                  }}
                  profile={profile}
                />
              </div>
            </div>
            <div className={`${tabContentVerticalSeperator}`} />
            <div style={mediaPlayerProperties}>
              {!camera ? (
                <div className={playerWrapper} style={mediaPlayerProperties} />
              ) : (
                <PlayerHost
                  key={camera.Id}
                  camera={camera}
                  cameraId={camera.Id}
                  cameraRemoteId={camera.RemoteId}
                  deviceId={camera.DeviceId}
                  height={playerHeight}
                  hideBookmarkButton
                  id={camera.Id}
                  isDisconnected={camera.ConnectionState !== CAMERA_CONNECTED}
                  paused
                  startTime={bookmark.startTime}
                  width={playerWidth}
                />
              )}
            </div>
          </div>
        </MainContentWrapper>
      </div>
    );
  }
}

BookmarkDetailsContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bookmark: PropTypes.objectOf(PropTypes.any).isRequired,
  bookmarkId: PropTypes.string.isRequired,
  canChangeCustomer: PropTypes.bool,
  cluster: PropTypes.objectOf(PropTypes.any).isRequired,
  clusterId: PropTypes.string.isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  history: PropTypes.func.isRequired,
  isFetchingBookmarks: PropTypes.bool,
  isFetchingCamerasForSite: PropTypes.bool,
  isFetchingClusters: PropTypes.bool,
  isFetchingDeviceData: PropTypes.bool,
  locationName: PropTypes.string,
  onBackClick: PropTypes.func,
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  selectionList: PropTypes.arrayOf(
    PropTypes.shape({
      cameras: PropTypes.array,
      locationId: PropTypes.string,
      locationName: PropTypes.string,
    }),
  ),
  siteId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  updateDimensions: PropTypes.func.isRequired,
};

BookmarkDetailsContainer.defaultProps = {
  canChangeCustomer: false,
  isFetchingBookmarks: null,
  isFetchingCamerasForSite: null,
  isFetchingClusters: null,
  isFetchingDeviceData: null,
  locationName: '',
  onBackClick() {},
  selectionList: [],
};

function mapStateToProps(state, ownProps) {
  const allCameras = cameraSelectionSelector(state);
  const cameraSelectObject =
    allCameras && allCameras.find(item => item.locationId === ownProps.siteId);

  let bookmark = {};
  const allBookmarks = state.bookmarks.bookmarks;
  if (allBookmarks && allBookmarks !== {}) {
    if (ownProps.clusterId !== undefined) {
      bookmark =
        allBookmarks[ownProps.clusterId] &&
        allBookmarks[ownProps.clusterId].find(
          b => b.id === ownProps.bookmarkId,
        );
    }
  }

  const cameraArray =
    cameraSelectObject &&
    cameraSelectObject.cameras.filter(
      cam =>
        bookmark &&
        bookmark.cameraIds &&
        bookmark.cameraIds.includes(cam.RemoteId),
    );

  const allBookmarkCameras = [{ ...cameraSelectObject, cameras: cameraArray }];
  const locationName = allBookmarkCameras && allBookmarkCameras[0].locationName; // bookmark object doesn't contain the location name and is required in bookmark details form.
  if (
    allBookmarkCameras &&
    allBookmarkCameras[0] &&
    allBookmarkCameras[0].cameras
  ) {
    sortObjectsArrayByPropertyValue(allBookmarkCameras[0].cameras, 'Name');
  }
  bookmark = bookmark || {};
  return {
    allCameras,
    bookmark,
    bookmarks: state.bookmarks.bookmarks,
    cluster: state.clusters.clusters.find(c => c.Id === ownProps.clusterId),
    isBookmarksModalOpen:
      state.modal.isOpen && state.modal.modalType === modalTypes.EDIT_BOOKMARK,
    isFetchingBookmarks: state.isFetching.getBookmarks[ownProps.clusterId],
    isFetchingCamerasForSite: state.isFetching.getCameras[ownProps.siteId],
    isFetchingClusters: state.isFetching.getClusters,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
    locationName,
    profile: state.user.profile,
    selectionList: allBookmarkCameras,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getAllDevices,
        getCameras,
        showModal,
        ...bookmarkActions,
        ...AftClientActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dimensions()(withLocalize(BookmarkDetailsContainer)));
