import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, GroupLayout, Icon, ListView } from 'lib';
import OasisModule from './OasisModule';
import { flags as flagsAtom } from 'atoms';
import { useRecoilState } from 'recoil';
import {
  IC_TRASH,
  IC_SITE_SUSPEND,
  IC_SITE_UNSUSPEND,
} from 'constants/iconNames';

function DevFlagsModule({ updateModuleState }) {
  const [flags, setFlags] = useRecoilState(flagsAtom);
  let flagData = [];
  if (flags) {
    flagData = Object.keys(flags).map(key => {
      return { flagName: key, flagState: JSON.parse(flags[key]) };
    });
  }

  function persistFlags(flags) {
    const stringFlags = JSON.stringify(flags);
    localStorage.flags = stringFlags;
  }

  function addNewFlag() {
    const newFlagName = prompt('Enter a name for your flag');
    const newFlags = { ...flags };
    newFlags[newFlagName] = false;
    setFlags(newFlags);
    persistFlags(newFlags);
  }

  function removeExistingFlag(rowData) {
    const newFlags = { ...flags };
    delete newFlags[rowData.flagName];
    setFlags(newFlags);
    persistFlags(newFlags);
  }

  function toggleFlag(rowData) {
    const newFlags = { ...flags };
    newFlags[rowData.flagName] = !flags[rowData.flagName];
    setFlags(newFlags);
    persistFlags(newFlags);
  }
  return (
    <OasisModule
      moduleId="oasis-dev-flags"
      rowClickCallback={toggleFlag}
      theme="purple"
      title="Dev Flags"
      updateModuleState={updateModuleState}
    >
      <>
        <ListView
          bodyHeight="300px"
          customCells={{
            flagState: rowData => {
              return rowData.flagState ? (
                <span style={{ color: 'green' }}>On</span>
              ) : (
                <span style={{ color: 'red' }}>Off</span>
              );
            },
          }}
          data={flagData}
          fieldOrder={['flagName', 'flagState']}
          headerLabels={{
            flagName: 'Flag Name',
            flagState: 'Active?',
          }}
          hideFilter
          resizable
          resizableFrom="top"
          rowActions={[
            ({ rowData }) => {
              return (
                <Button
                  icon
                  inputType="button"
                  onClick={evt => {
                    evt.stopPropagation();
                    toggleFlag(rowData);
                  }}
                >
                  {rowData.flagState ? (
                    <Icon id={IC_SITE_SUSPEND} />
                  ) : (
                    <Icon id={IC_SITE_UNSUSPEND} />
                  )}
                </Button>
              );
            },
            ({ rowData }) => {
              return (
                <Button
                  icon
                  inputType="button"
                  onClick={evt => {
                    evt.stopPropagation();
                    removeExistingFlag(rowData);
                  }}
                >
                  <Icon id={IC_TRASH} />
                </Button>
              );
            },
          ]}
        />
        <GroupLayout dropshadow verticalCenter verticalSpacing="none">
          <Button
            inputType="button"
            onClick={() => {
              addNewFlag();
            }}
            text="Add New Flag"
          />
        </GroupLayout>
      </>
    </OasisModule>
  );
}

DevFlagsModule.propTypes = {
  updateModuleState: PropTypes.func.isRequired,
};

export default DevFlagsModule;
