import React, { useContext, useEffect } from 'react';
import { PlayerContext } from '@avo-svtr/savitar';

function DisplayTimeObserver({ publishDisplayTime, saveDisplayTimeObserver }) {
  const { displayTime } = useContext(PlayerContext);

  useEffect(() => {
    if (displayTime) {
      saveDisplayTimeObserver(displayTime);
      displayTime.onValue(publishDisplayTime);
      return () => {
        displayTime.offValue(publishDisplayTime);
      };
    }
    return null;
  }, [displayTime, publishDisplayTime, saveDisplayTimeObserver]);

  return null;
}

export default DisplayTimeObserver;
