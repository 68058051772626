import React from 'react';
import Spinner from 'react-md-spinner';
import { modalOverlay } from 'sharedStyles/styles.css';
import { imgBusy } from './styles.css';

const BusyIndicator = () => (
  <div>
    <div className={modalOverlay}>
      <div className={imgBusy}>
        <Spinner
          size={80}
          color1="#006e99"
          color2="#3d6f99"
          color3="#0f4c7f"
          color4="#006e99"
        />
      </div>
    </div>
  </div>
);

export default BusyIndicator;
