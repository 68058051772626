// Utils
import urlBuilder from 'queryBuilder/url';
import {
  sendGetRequestReturningJSON,
  sendPutRequestReturningJSON,
} from 'util/fetchHelpers';
import { cloneDeep } from 'util/cameraSettingLinks';

// Constants
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import { messageStyleStrings } from 'containers/PageMessage/constants';

// Actions
import { updateDigitalOutLinks } from './links';
import { showMessage } from '../pageMessage';
import { receiveSpeakerSettings } from './audio';
import { isFetching, isFetchingData } from '../common';

// Pure redux action creators

export function receiveRules(rules, cameraId) {
  return {
    type: types.RECEIVE_RULES,
    rules,
    cameraId,
  };
}

export function receiveImageSettings(settings, cameraId) {
  return {
    type: types.RECEIVE_IMAGE_SETTINGS,
    settings,
    cameraId,
  };
}

export function clearRuleEdits(cameraId) {
  // Prepare to receive new rules and wipe out current client-side edits
  return {
    id: cameraId,
    type: types.CLEAR_RULE_EDITS,
  };
}

// Async action creators

/* Actions related to the camera-settings gateway command to Mavo-Torii Mavo-Torii */
export function getAllCameraSettings(deviceId, remoteId, cameraId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_CAMERA_SETTINGS));
    dispatch(isFetchingData(types.GET_RULES, true));
    const url = urlBuilder(types.GET_CAMERA_SETTINGS, deviceId, remoteId);
    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch(receiveImageSettings(response, cameraId));
        response.speaker &&
          dispatch(
            receiveSpeakerSettings(cameraId, { speaker: response.speaker }),
          );
        response.analytics && dispatch(receiveRules(response, cameraId));
        dispatch(isFetchingData(types.GET_RULES, false));
        dispatch(isFetching(types.IS_FETCHING_CAMERA_SETTINGS, false));
      })
      .catch(ex => {
        dispatch(isFetchingData(types.GET_RULES, false));
        dispatch(
          isFetching(types.IS_FETCHING_CAMERA_SETTINGS, false, {
            error: ex.message,
          }),
        );
      });
  };
}

export function updateCameraSettings(
  deviceId,
  remoteId,
  cameraId,
  data,
  shouldAddBoundary,
) {
  return dispatch => {
    dispatch(clearRuleEdits(cameraId));

    const url = urlBuilder(types.UPDATE_CAMERA_SETTINGS, deviceId);

    const cleanData = cloneDeep(data);
    const { compression } = cleanData.settings;
    const { acquisition } = cleanData.settings;
    const { analytics } = cleanData.settings;

    if (compression) {
      delete compression.actualFrameRate;
      delete compression.customRecordingProfileActualFrameRate;
      delete compression.keyFramePeriod;
      delete compression.keyframePeriod;
    }

    if (acquisition && acquisition.imageRotation) {
      cleanData.settings.acquisition = {
        imageRotation: acquisition.imageRotation,
      };
    } else if (acquisition) {
      delete cleanData.settings.acquisition;
    }

    return sendPutRequestReturningJSON(
      url,
      cleanData,
      {},
      { Accept: 'text/plain' },
    )
      .then(response => {
        if (response.errorMessage) {
          throw new Error(response.errorMessage);
        } else if (
          response.rejected &&
          response.rejected.length > 0 &&
          !shouldAddBoundary
        ) {
          // If shouldAddBoundary is true, keys might be rejected in the initial update
          // before the boundary is created
          throw new Error(
            `Some keys were rejected: ${response.rejected
              .map(i => i.key)
              .join(', ')}`,
          );
        } else {
          const settings = { digital_output: data.settings.digital_output };
          dispatch(receiveImageSettings(settings, cameraId));
          dispatch(
            showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_SETTINGS_SUCCESS',
            }),
          );
        }
      })
      .then(() => {
        if (shouldAddBoundary) {
          // function is recursive, cannot define before calling

          dispatch(addRoi(deviceId, remoteId, cameraId, cleanData));
        }
      })
      .catch(e => {
        avoLogError('Error updating camera settings', e);
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, e.message, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_SETTINGS_ERROR',
          }),
        );
        return Promise.reject(new Error('GATEWAY_UPDATE_SETTINGS_ERROR'));
      })
      .then(() => {
        dispatch(isFetching(types.DONE_UPDATING_CAMERA_SETTINGS));
        return Promise.resolve();
      });
  };
}

export function addRoi(deviceId, remoteId, cameraId, data, orgId = null) {
  // In cases where a new rule has been added, PUT twice (first ROI/LOI from updateCameraSettings, then rule from this
  // function, confusingly named 'addRoi' )
  return dispatch => {
    const boundaryData = {
      settings: {
        analytics: {
          events: data.settings.analytics.events,
        },
      },
    };
    const url = urlBuilder(types.GET_RULES, deviceId, remoteId, null, {
      orgId,
    });
    return sendGetRequestReturningJSON(url)
      .then(() => {
        dispatch(
          updateCameraSettings(deviceId, remoteId, cameraId, boundaryData),
        );
        return Promise.resolve();
      })
      .catch(ex => {
        return Promise.reject(new Error(`Request failed: ${ex.message}`));
      });
  };
}

export function updateCameraSettingsAndLinks(
  camera,
  data,
  linkedCameras,
  entityId,
) {
  return dispatch => {
    dispatch(isFetching(types.IS_UPDATING_CAMERA_SETTINGS));
    dispatch(updateDigitalOutLinks(camera.DeviceId, linkedCameras, entityId))
      .then(arrayOfValuesOrErrors => {
        const errors = arrayOfValuesOrErrors.filter(obj => obj.error);
        if (errors.length > 0) {
          throw new Error(errors[0].errorMessage);
        }
        if (data) {
          dispatch(
            updateCameraSettings(
              camera.DeviceId,
              camera.RemoteId,
              camera.Id,
              data,
              false,
            ),
          );
        } else {
          dispatch(isFetching(types.DONE_UPDATING_CAMERA_SETTINGS));
          dispatch(
            showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_SETTINGS_SUCCESS',
            }),
          );
        }
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.GATEWAY_UPDATE_DIGITALOUT_LINKS_ERROR',
          }),
        );
        avoLogError('Error upgrading camera settings and links', ex);
        dispatch(isFetching(types.DONE_UPDATING_CAMERA_SETTINGS));
      });
  };
}

export function getRules(deviceId, remoteId, cameraId, orgId = null) {
  return dispatch => {
    dispatch(isFetchingData(types.GET_RULES));
    const url = urlBuilder(types.GET_RULES, deviceId, remoteId, null, {
      orgId,
    });
    return sendGetRequestReturningJSON(url)
      .then(json => {
        dispatch(receiveRules(json, cameraId));
        dispatch(isFetchingData(types.GET_RULES, false));
      })
      .catch(ex => {
        avoLogError('Error getting rules', ex);
        dispatch(isFetchingData(types.GET_RULES, false));
      });
  };
}

export function getGatewayGeneralSettings(deviceId, remoteId, cameraId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_GATEWAY_GENERAL_SETTINGS));
    const url = urlBuilder(
      types.GET_GATEWAY_GENERAL_SETTINGS,
      deviceId,
      remoteId,
    );
    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch(receiveImageSettings(response, cameraId));
        dispatch(isFetching(types.DONE_FETCHING_GATEWAY_GENERAL_SETTINGS));
      })
      .catch(ex => {
        avoLogError('Error getting camera general settings', ex);
        dispatch(isFetching(types.DONE_FETCHING_GATEWAY_GENERAL_SETTINGS));
      });
  };
}

export function getAcquisitionSettings(deviceId, remoteId, cameraId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_ACQUISITION_DATA));
    const url = urlBuilder(types.GET_ACQUISITION_SETTINGS, deviceId, remoteId);
    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch(receiveImageSettings(response, cameraId));
        dispatch(isFetching(types.DONE_FETCHING_ACQUISITION_DATA));
      })
      .catch(ex => {
        avoLogError('Error getting acquisition settings', ex);
        dispatch(isFetching(types.DONE_FETCHING_ACQUISITION_DATA));
      });
  };
}

export function editGatewayGeneralSettings(
  deviceId,
  remoteId,
  cameraId,
  formData,
) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_GATEWAY_GENERAL_SETTINGS));
    const url = urlBuilder(
      types.GET_GATEWAY_GENERAL_SETTINGS,
      deviceId,
      remoteId,
    );
    return sendPutRequestReturningJSON(url, formData)
      .then(dispatch(isFetching(types.DONE_FETCHING_GATEWAY_GENERAL_SETTINGS)))
      .then(response => {
        if (response.status >= 400) {
          if (response.status === 504) {
            // TODO: during development only case May be we can extended it to prod when gateway unable to connect armada???
            throw new Error('No response from the device');
          } else {
            throw new Error(`Server responded with status ${response.status}`);
          }
        } else if (response.errorMessage) {
          throw new Error(response.errorMessage);
        } else if (
          response.rejected.length === 0 &&
          response.accepted.length >= 1
        ) {
          dispatch(getGatewayGeneralSettings(deviceId, remoteId, cameraId));
          dispatch(
            showMessage(messageTypes.GATEWAY_SUCCESS, null, null, {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'CAMERA.SETTINGS.ACTIONS.GATEWAY_PRIVACY_ZONES_SUCCESS',
            }),
          );
        } else {
          throw new Error('Unable to save the privacy zone changes');
        }
      })
      .catch(ex => {
        dispatch(
          showMessage(messageTypes.GATEWAY_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'CAMERA.SETTINGS.ACTIONS.GATEWAY_PRIVACY_ZONES_ERROR',
          }),
        );
        avoLogError('Error saving camera general settings', ex);
      });
  };
}

export function getCompressionSettings(deviceId, remoteId, cameraId) {
  return dispatch => {
    dispatch(isFetching(types.IS_FETCHING_COMPRESSION_DATA));
    const url = urlBuilder(types.GET_COMPRESSION_SETTINGS, deviceId, remoteId);
    return sendGetRequestReturningJSON(url)
      .then(response => {
        dispatch(receiveImageSettings(response, cameraId));
        dispatch(isFetching(types.DONE_FETCHING_COMPRESSION_DATA));
      })
      .catch(ex => {
        dispatch(isFetching(types.DONE_FETCHING_COMPRESSION_DATA));
        avoLogError('Error getting compression settings', ex);
      });
  };
}
