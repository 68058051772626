// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Components
import {
  DefaultFormButtons,
  LabeledBox,
  VerticalFormFieldStack,
} from 'components';
import { MainContentWrapper } from 'lib';

import { validateConfirmPassword, validatePassword } from 'util/validation';

// Styles
import { contentCard } from 'sharedStyles/global.css';

import * as PasswordRules from 'constants/PasswordRules';
import * as consts from './constants';

import {
  formContent,
  halfPageColumn,
  hiddenCredentials,
  profileContainer,
} from './styles.css';

function ProfilePasswordForm(props) {
  function resetForm() {
    const { reset } = props;
    reset(consts.PASSWORD_FORM_NAME);
  }

  const { dirty, handleSubmit, input, isNotLast3Passwords } = props;

  const passNotLast3passwordsRule = () => {
    const result = !(
      isNotLast3Passwords.NewPassword === PasswordRules.FAILED ||
      isNotLast3Passwords.NewPassword === PasswordRules.PENDING ||
      isNotLast3Passwords.ConfirmPassword === PasswordRules.FAILED ||
      isNotLast3Passwords.ConfirmPassword === PasswordRules.PENDING
    );

    return result;
  };
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      {/* This stops chrome from autocompleting the actual fields. The imposter fields are hidden  */}
      <div className={hiddenCredentials}>
        <input autoComplete="off" name="password" type="password" />
      </div>
      <div className={contentCard}>
        <div className={profileContainer}>
          <div className={halfPageColumn}>
            <MainContentWrapper>
              <div className={formContent}>
                <LabeledBox labelId="PROFILE.HEADER_UPDATE_PASSWORD">
                  <VerticalFormFieldStack
                    fieldPropList={consts.PASSWORD_PROPS}
                    input={dirty ? input : ''}
                  />
                </LabeledBox>
              </div>
            </MainContentWrapper>
          </div>
        </div>
        <DefaultFormButtons
          {...props}
          passwordInvalid={!passNotLast3passwordsRule()}
          resetForm={resetForm}
        />
      </div>
    </form>
  );
}

ProfilePasswordForm.propTypes = {
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  input: PropTypes.objectOf(PropTypes.any),
  isNotLast3Passwords: PropTypes.objectOf(PropTypes.string),
  reset: PropTypes.func.isRequired,
};

ProfilePasswordForm.defaultProps = {
  initialValues: {},
  input: undefined,
  isNotLast3Passwords: {},
};

const validate = (values, props) => {
  const { isNotLast3Passwords } = props;
  const errors = {};
  if (values) {
    const passwordError = validatePassword(
      values.NewPassword,
      isNotLast3Passwords.NewPassword !== PasswordRules.FAILED,
    );
    const confirmPasswordError = validateConfirmPassword(
      values.ConfirmPassword,
      values.NewPassword,
    );
    if (
      values.CurrentPassword ||
      values.NewPassword ||
      values.ConfirmPassword
    ) {
      if (passwordError) errors.NewPassword = <Translate id={passwordError} />;
      if (confirmPasswordError)
        errors.ConfirmPassword = <Translate id={confirmPasswordError} />;
    }
    if (
      !values.CurrentPassword &&
      (values.NewPassword || values.ConfirmPassword)
    ) {
      errors.CurrentPassword = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
  }
  return errors;
};

const shouldValidate = ({ initialRender, nextProps, props, structure }) => {
  if (initialRender) return false;
  const passwordAsyncValidated =
    nextProps.isNotLast3Passwords.NewPassword !== PasswordRules.PENDING &&
    props.isNotLast3Passwords.NewPassword === PasswordRules.PENDING;
  const valuesChanged = !structure.deepEqual(
    props.formValues,
    nextProps.formValues,
  );
  return passwordAsyncValidated || valuesChanged;
};

const selector = formValueSelector(consts.PASSWORD_FORM_NAME);

export default connect(state => ({
  formValues: selector(state, ...consts.PASSWORD_FORM_FIELDS_LIST),
}))(
  reduxForm({
    enableReinitialize: true,
    fields: consts.PASSWORD_FORM_FIELDS_LIST,
    form: consts.PASSWORD_FORM_NAME,
    shouldValidate,
    touchOnChange: true,
    validate,
  })(ProfilePasswordForm),
);
