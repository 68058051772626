// Libs
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

// Component
import { ListView } from 'lib';

// Consts
import { BULK_ACTION_TABLES } from 'constants/app';
import * as ServicePlanConsts from './constants';


// Class
// eslint-disable-next-line react/prefer-stateless-function
class ServicePlansTable extends Component {
  render() {
    const { bulkActions, hideColumns, servicePlans } = this.props;
    const {
      fieldAutoRenew,
      fieldChannels,
      fieldCode,
      fieldServicePlan,
      fieldTerm,
    } = ServicePlanConsts.columnDataFields;

    const data = servicePlans.map(row => ({
      Id: row.Id,
      autoRenew: row[fieldAutoRenew],
      channels: row[fieldChannels],
      code: row[fieldCode],
      servicePlan: row[fieldServicePlan],
      term: row[fieldTerm],
    }));
    const CUSTOM_CELLS = {
      autoRenew: rowData => {
        return <span>{rowData.autoRenew}</span>;
      },
      channels: rowData => {
        return <span>{rowData.channels}</span>;
      },
      code: rowData => {
        return <span>{rowData.code}</span>;
      },
      servicePlan: rowData => {
        return <span>{rowData.servicePlan}</span>;
      },
      term: rowData => {
        return <span>{rowData.term}</span>;
      },
    };
    const fieldOrder = ServicePlanConsts.FIELD_ORDER.filter(
      field => !hideColumns.includes(field),
    );
    const translationIds = {
      autoRenew: 'SERVICE_PLANS_TABLE.FORM.AUTO_RENEW_LABEL',
      channels: 'SERVICE_PLANS_TABLE.FORM.CHANNELS_LABEL',
      code: 'SERVICE_PLANS_TABLE.FORM.CODE_LABEL',
      servicePlan: 'SERVICE_PLANS_TABLE.FORM.SERVICE_PLAN_LABEL',
      term: 'SERVICE_PLANS_TABLE.FORM.TERM_LABEL',
    };
    return (
      <ListView
        bulkActions={bulkActions}
        bulkActionsTable={BULK_ACTION_TABLES.SERVICE_PLANS}
        cellWidths={{ Actions: 50 }}
        customCells={CUSTOM_CELLS}
        data={data}
        defaultSortField="servicePlan"
        fieldOrder={fieldOrder}
        fillContainerHeight
        headerTranslationIds={translationIds}
        hideFilter
        inlineDetails={false}
        nonSortingFields={['code']}
        resizable={false}
        sortType="local"
        tableBodyStyle={null}
        truncatedFields={fieldOrder}
      />
    );
  }
}

ServicePlansTable.propTypes = {
  bulkActions: PropTypes.bool,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  servicePlans: PropTypes.arrayOf(PropTypes.any),
};

ServicePlansTable.defaultProps = {
  bulkActions: false,
  hideColumns: [],
  servicePlans: [],
};

export default ServicePlansTable;
