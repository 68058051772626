// Libs
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

// Components
import { Button, GroupLayout } from 'lib';

// Styles
import { Translate } from 'react-localize-redux';
import { inputBox } from 'sharedStyles/styles.css';
import {
  cameraFDSFormWrapper,
  cameraFDSInputRow,
  cameraFDSLabel,
  cameraFDSTopRow,
  normalLabel,
} from './styles.css';

let hasValidCredentials = false;
const formLevelValidation = values => {
  const cameraUserName = values.cameraUserName.toString().trim();
  const cameraPassword = values.cameraPassword.toString().trim();
  const cameraConfirmPassword =
    (values.cameraConfirmPassword &&
      values.cameraConfirmPassword.toString().trim()) ||
    '';
  const hasUsername = cameraUserName.length > 0;
  const hasPassword = cameraPassword.length > 0;
  const hasValidConfirm = hasPassword
    ? cameraPassword === cameraConfirmPassword
    : true;

  hasValidCredentials = hasUsername && hasValidConfirm;
};

const FDSCameraPasswordReset = props => {
  const { handleCancel, handleSubmit, onSubmit } = props;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={cameraFDSFormWrapper}>
        <div className={cameraFDSTopRow}>
          <label className={normalLabel}>
            <Translate id="CAMERA.SET_CAMERA_FDS_PASSWORD_MODAL.MODAL_DESCRIPTION" />
          </label>
        </div>
        <div className={cameraFDSInputRow}>
          <label className={cameraFDSLabel}>
            {' '}
            <Translate id="CAMERA.SET_CAMERA_FDS_PASSWORD_MODAL.MODAL_USERNAME_LABEL" />{' '}
          </label>
          <Field
            autoComplete="off"
            className={inputBox}
            component="input"
            disabled={false}
            name="cameraUserName"
            spellCheck="false"
            type="text"
          />
        </div>
        <div className={cameraFDSInputRow}>
          <label className={cameraFDSLabel}>
            {' '}
            <Translate id="CAMERA.SET_CAMERA_FDS_PASSWORD_MODAL.MODAL_PASSWORD_LABEL" />{' '}
          </label>
          <Field
            autoComplete="off"
            className={inputBox}
            component="input"
            disabled={false}
            name="cameraPassword"
            spellCheck="false"
            type="password"
          />
        </div>
        <div className={cameraFDSInputRow}>
          <label className={cameraFDSLabel}>
            <Translate id="CAMERA.SET_CAMERA_FDS_PASSWORD_MODAL.MODAL_PASSWORD_CONFIRM_LABEL" />{' '}
          </label>
          <Field
            autoComplete="off"
            className={inputBox}
            component="input"
            disabled={false}
            name="cameraConfirmPassword"
            spellCheck="false"
            type="password"
          />
        </div>
        <GroupLayout horizontalPositioning="right" verticalSpacing="large">
          <Button
            key="cameraFDSCancelButton"
            buttonType="secondary"
            id="cameraFDSCancelButton"
            inputType="button"
            onClick={handleCancel}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
          <Button
            key="cameraFDSSubmitButton"
            buttonType="primary"
            disabled={!hasValidCredentials}
            id="cameraFDSSubmitButton"
            inputType="button"
            onClick={() => handleSubmit(onSubmit)}
            text={<Translate id="BUTTONS.OK" />}
          />
        </GroupLayout>
      </div>
    </form>
  );
};

FDSCameraPasswordReset.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    initialValues: {
      cameraPassword: '',
      cameraUserName: '',
    },
  };
}
const ReduxForm = reduxForm({
  enableReinitialize: true,
  form: 'FDS_CAMERA_RESET_PASSWORD_FORM',
  validate: formLevelValidation,
})(FDSCameraPasswordReset);

const FDSCameraPasswordResetForm = connect(mapStateToProps)(ReduxForm);

export default FDSCameraPasswordResetForm;
