import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconBtnZoomFocusOut = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 8 14"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g
        id="Devices-Cameras-Basic-Settings-(HD)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1125.000000, -447.000000)"
      >
        <path
          d="M1125.31856,454 C1125.27266,453.711801 1125.36078,453.406396 1125.58291,453.184259 L1131.08375,447.683418 C1131.45194,447.315228 1132.0489,447.315228 1132.41709,447.683418 C1132.78528,448.051607 1132.78528,448.648561 1132.41709,449.016751 L1127.43384,454 L1132.41709,458.983249 C1132.78528,459.351439 1132.78528,459.948393 1132.41709,460.316582 C1132.0489,460.684772 1131.45194,460.684772 1131.08375,460.316582 L1125.58291,454.815741 C1125.36078,454.593604 1125.27266,454.288199 1125.31856,454 Z"
          id="icon-btn-zoom-out-v2"
          fill={props.fill}
        />
      </g>
    </svg>
  );
};

IconBtnZoomFocusOut.defaultProps = {
  className: null,
  width: '8px',
  height: '14px',
  fill: '#006e99',
};

export default IconBtnZoomFocusOut;
