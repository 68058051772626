// Libs
import React, { Component } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

// Constants
import { sectionHeader } from 'components/VerticalForm/styles.css';
import { idSensitivitySlider, idDelaySlider } from './constants';

// Styles
import {
  formContent,
  formField,
  formGroup,
  formLabel,
  formUnitLabel,
  sliderInput,
} from './styles.css';

// Class
class CameraSettingsTamperingHelper extends Component {
  constructor(props) {
    super(props);
    const { tamperingData } = this.props;
    this.state = tamperingData;
  }

  get isDisabled() {
    const { disable } = this.props;
    return disable;
  }

  isDelaySupported = () => {
    const { tamperingData } = this.props;
    return tamperingData.delay && tamperingData.delay.val;
  };

  setDelay = delay => {
    const { tamperingData } = this.props;
    this.setStateValue({
      delay: {
        def: tamperingData.delay.def,
        max: tamperingData.delay.max,
        min: tamperingData.delay.min,
        id: tamperingData.delay.id,
        val: delay,
      },
    });
  };

  setSensitivity = sensitivity => {
    const { tamperingData } = this.props;
    this.setStateValue({
      sensitivity: {
        def: tamperingData.sensitivity.def,
        max: tamperingData.sensitivity.max,
        min: tamperingData.sensitivity.min,
        id: tamperingData.sensitivity.id,
        val: sensitivity,
      },
    });
  };

  setStateValue = value => {
    const { onChange } = this.props;
    /* notify listeners about the resulting new state (not just the
     * specific value that changed
     */
    onChange(Object.assign({}, this.state, value));
    /* update our local state, don't rely on a parent component to update
     * our props
     */
    this.setState(value);
  };

  // TODO: MVAAS-17027 find alternative

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(nextProps.tamperingData);
  }

  render() {
    const { tamperingData } = this.props;
    const { delay, sensitivity } = this.state;

    return (
      <fieldset data-for="tampering" disabled={this.isDisabled}>
        <legend className={sectionHeader}>
          <Translate id="TAMPERING.TITLE_LABEL" />
        </legend>
        <div className={formContent}>
          <div>
            <Translate id="TAMPERING.HEADER_LABEL" />
            <div className={formGroup}>
              <div className={formLabel}>
                <Translate id="TAMPERING.SENSITIVITY_LABEL" />
              </div>
              <div className={formField}>
                <div className={sliderInput} id={idSensitivitySlider}>
                  <InputRange
                    maxValue={tamperingData.sensitivity.max}
                    minValue={tamperingData.sensitivity.min}
                    step={1}
                    value={sensitivity.val}
                    onChange={this.setSensitivity}
                    disabled={this.isDisabled}
                  />
                </div>
              </div>
              <div className={formUnitLabel} />
            </div>
            {this.isDelaySupported() ? (
              <div className={formGroup}>
                <div className={formLabel}>
                  <Translate id="TAMPERING.DELAY_LABEL" />
                </div>
                <div className={formField}>
                  <div className={sliderInput} id={idDelaySlider}>
                    <InputRange
                      maxValue={tamperingData.delay.max}
                      minValue={tamperingData.delay.min}
                      step={1}
                      value={delay.val}
                      onChange={this.setDelay}
                      disabled={this.isDisabled}
                    />
                  </div>
                </div>
                <div className={formUnitLabel}>
                  <Translate id="UNITS.SECONDS" />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}

CameraSettingsTamperingHelper.defaultProps = {
  disable: false,
  onChange: () => {},
  tamperingData: {
    delay: {
      def: 5,
      id: 2,
      max: 10,
      min: 0,
      val: 0,
    },
    sensitivity: {
      def: 5,
      id: 1,
      max: 10,
      min: 0,
      val: 0,
    },
  },
};

CameraSettingsTamperingHelper.propTypes = {
  disable: PropTypes.bool,
  onChange: PropTypes.func,
  tamperingData: PropTypes.shape({
    sensitivity: PropTypes.shape({
      def: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      val: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    delay: PropTypes.shape({
      def: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired,
      min: PropTypes.number.isRequired,
      val: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
};

export default CameraSettingsTamperingHelper;
