import PropTypes from 'prop-types';
import React from 'react';
import renderIf from 'render-if';

// Components
import { LabeledBox } from 'components';
import { MainContentWrapper } from 'lib';
import { ACTION_IMAGES } from '../../constants/app';
import AccessTable from './ProfileAccessTable';
import { halfPageColumn, profileName, userInfo } from './styles.css';

const ProfileSummary = ({
  LocationData,
  canViewSites,
  emailAddress,
  phoneNumber,
  profileFullName,
  tenantType,
}) => {
  return (
    <div className={halfPageColumn}>
      <MainContentWrapper>
        <LabeledBox labelId="SECONDARY_NAV.TABS.SUMMARY.LABEL" />
        <div className={userInfo}>
          <div className={profileName}>{profileFullName}</div>
          <div>{emailAddress}</div>
          <div>{phoneNumber}</div>
        </div>
        {renderIf(tenantType === ACTION_IMAGES.SUBSCRIBER)(
          <LabeledBox labelId="PROFILE.HEADER_ACCESS">
            <AccessTable
              accessData={LocationData}
              canViewSites={canViewSites}
            />
          </LabeledBox>,
        )}
      </MainContentWrapper>
    </div>
  );
};

ProfileSummary.propTypes = {
  LocationData: PropTypes.objectOf(PropTypes.any),
  canViewSites: PropTypes.bool,
  emailAddress: PropTypes.string,
  phoneNumber: PropTypes.string,
  profileFullName: PropTypes.string,
  tenantType: PropTypes.string,
};

ProfileSummary.defaultProps = {
  LocationData: undefined,
  canViewSites: false,
  emailAddress: null,
  phoneNumber: null,
  profileFullName: null,
  tenantType: null,
};

export default React.memo(ProfileSummary);
