import * as cameraTypes from 'constants/cameraTypes';

export const DeviceSource = {
  BLUE: 'BLUE',
  ACC: 'ACC',
};
export const findCameraCategory = camera => {
  // By Default Unmanaged to cover all condition that does not make camera managed
  let camCategory = cameraTypes.UNMANAGED_CAMERA;
  if (camera) {
    try {
      if (
        (camera.Active === true && camera.Connected === true) ||
        (camera.Active === true && camera.Connected === false)
      ) {
        camCategory = cameraTypes.MANAGED_CAMERA;
      }
    } catch (e) {
      // Ignore camera with JSON errors
    }
  }
  return camCategory;
};

export const findServerCategory = server => {
  return server && server.Model !== null && server.Model !== undefined
    ? DeviceSource.BLUE
    : DeviceSource.ACC;
};
