// Notification Tabs
import {
  PATH_SEGMENT_ALL,
  PATH_SEGMENT_HEALTH,
  PATH_SEGMENT_SECURITY,
} from 'constants/urlPaths';

import { ACTION_IMAGES } from 'constants/app';

export const idAllLink = 'allLink';
export const idHealthLink = 'idHealthLink';
export const idSecurityLink = 'idSecurityLink';

export const tabs = {
  all: { id: idAllLink, path: PATH_SEGMENT_ALL },
  health: { id: idHealthLink, path: PATH_SEGMENT_HEALTH },
  security: { id: idSecurityLink, path: PATH_SEGMENT_SECURITY },
};

export const idAlarmAlertsDropdown = 'alarmAlertsDropdown';
export const idAlarmCamerasDropdown = 'alarmCamerasDropdown';
export const idAlarmRulesDropdown = 'alarmRulesDropdown';
export const idAlarmLocationsDropdown = 'alarmLocationsDropdown';
export const idAlarmDatesDropdown = 'alarmDatesDropdown';
export const idAlarmNavHeader = 'alarmNavHeader';
export const navBarActionTitle = ACTION_IMAGES.NOTIFICATION;
export const idBtnView = 'viewBtn';
export const priorBeginRange = 'priorBeginRange';
export const filterGroup = 'alarms';

export const MAX_LINES_DEFAULT = 6;

export const ActivityClass = {
  CameraHealthActivity: 'CameraHealthActivity',
  DeviceHealthActivity: 'DeviceHealthActivity',
  DeviceTamperingActivity: 'DeviceTamperingActivity',
  ServerHealthActivity: 'ServerHealthActivity',
  ServerTamperingActivity: 'ServerTamperingActivity', // May replace DeviceTamperingActivity
  VideoReviewActivity: 'VideoReviewActivity', // May replace DeviceHealthActivity
};

export const AlarmReviewModalTypes = {
  AnalyticsModal: 'AnalyticsModal',
  HealthModal: 'HealthModal',
};

export const WIDGETS = {
  SUMMARY_TABLE: 'SummaryTable',
  VOLUME_CHART: 'VolumeChart',
};
