import React from 'react';
import { bindActionCreators } from 'redux';
import { Translate, withLocalize } from 'react-localize-redux';

// Actions
import * as MenuActions from 'actions/actionMenu';
import * as UserActions from 'actions/user';
import * as LocationActions from 'actions/locations';
import * as FilterActions from 'actions/filters';
import { hideModal, showModal } from 'actions/modal';
import * as DeviceActions from 'actions/devices';
import * as UtilityActions from 'actions/utilities';

// Utilities
import { mapObjKeysToOptions } from 'util/filterUtils';
import * as permissions from 'util/permissions';
import FilterableContainer from 'util/FilterableContainer';
import { localizeSchedules } from 'util/localizeSeededSchedules';

// Components
import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';
import { FilterTranslated, InputFilter } from 'components';
import { EmptyPlaceholder, ListNav, MainContentWrapper, PageTitle } from 'lib';
import NavigationWrapper from 'containers/NavigationWrapper/NavigationWrapper';
import { PageMessage } from 'containers';

// Constants
import * as modalTypes from 'constants/ModalTypes';
import {
  ACTION_IMAGES,
  BULK_ACTION_TABLES,
  FILTER_GROUPS,
  ROLES_KEYS,
  STATUS_KEYS,
} from 'constants/app';
import * as messageTypes from 'constants/MessageTypes';
import {
  PATH_ACCOUNTS,
  PATH_SEGMENT_NEW,
  PATH_SEGMENT_USERS,
} from 'constants/urlPaths';

// Styles
import { contentContainer } from 'sharedStyles/global.css';
import AccountNavMenu from '../AccountNavMenu';
import { filterWrapper } from '../styles.css';

import * as UserConsts from '../constants';
import UserTable from './UserTable/UserTable';

const { userTextInputFields: textInputFields } = UserConsts;

class UsersTableContainer extends FilterableContainer {
  // eslint-disable-next-line react/static-property-placement
  static contextType = BulkActionContext;

  constructor(props) {
    super(props);
    const { orgId } = this.props;
    this.state = {
      queryOptions: {
        count: true,
        editedUser: null,
        filters: [],
        orgId,
        skip: 0,
        sort: { col: 'LastName', direction: 'asc' },
        tableHeight: 0,
        top: 50,
      },
      submittingForm: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      isFetchingLocations,
      isFetchingOrgSubscriptions,
      isFetchingSchedules,
      isFetchingTimezones,
      isFetchingUsers,
      orgId,
    } = this.props;
    const { queryOptions } = this.state;

    if (isFetchingTimezones === null) {
      actions.getTimezones();
    }
    if (orgId) {
      if (isFetchingUsers === null) {
        actions.getUsers(queryOptions);
      }
      if (isFetchingLocations === null) {
        actions.getLocations();
      }
      if (isFetchingSchedules === null) {
        actions.getSchedules();
      }
      if (isFetchingOrgSubscriptions === null) {
        actions.getOrgSubscriptions();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, isFetchingUsers, orgId } = this.props;
    const { queryOptions } = this.state;
    if (orgId !== prevProps.orgId || isFetchingUsers === null) {
      actions.getUsers(queryOptions);
    } else if (orgId !== prevProps.orgId) {
      actions.getLocations();
      actions.getSchedules();
      actions.getOrgSubscriptions();
    }
  }

  componentWillUnmount() {
    this.context.clearBulkActionIdsFor(BULK_ACTION_TABLES.USERS);
  }

  get selectedUserIds() {
    return this.context.bulkActionIdsFor(BULK_ACTION_TABLES.USERS) || [];
  }

  getLocalizedSchedues = () => {
    const { schedules, translate } = this.props;
    return localizeSchedules(schedules, translate);
  };

  handleAddClick = () => {
    this.props.history.push(
      `${PATH_ACCOUNTS}${PATH_SEGMENT_USERS}${PATH_SEGMENT_NEW}`,
    );
  };

  handleDeleteClick = selectedIds => {
    const { actions, items } = this.props;
    const { queryOptions } = this.state;
    const { clearBulkActionIdsFor } = this.context;
    const message = [
      <div key={UserConsts.idDeleteMessageConfirmOne}>
        <Translate id="USERS.DELETE_USER_MODAL.DELETE_USER_CONFIRM_MESSAGE_ONE" />
        <br />
        <br />
      </div>,
    ];
    const names = selectedIds
      .map(id => items.find(location => location.Id === id).FirstName)
      .map(name => <li key={name}>{name}</li>);
    message.push(names);
    message.push(
      <div key={UserConsts.idDeleteMessageConfirmTwo}>
        <br />
        <Translate id="USERS.DELETE_USER_MODAL.DELETE_USER_CONFIRM_MESSAGE_TWO" />
      </div>,
    );
    const onOkClick = () => {
      clearBulkActionIdsFor(BULK_ACTION_TABLES.USERS);
      actions.deleteUsers(selectedIds, queryOptions);
      actions.hideModal();
    };
    const modalProps = {
      handleCancel: () => {
        actions.hideModal();
      },
      message,
      onOkClick,
      title: <Translate id="USERS.DELETE_USER_MODAL.MODAL_TITLE" />,
    };
    actions.showModal(modalTypes.SHOW_CONFIRM, modalProps);
  };

  repairPolicy = policy => {
    const resultPolicy = [];
    if (policy) {
      policy.forEach(element => {
        if (
          element.GroupId &&
          element.SecurityScheduleId &&
          element.HealthScheduleId
        ) {
          resultPolicy.push(element);
        }
      }, this);
    }
    return resultPolicy;
  };

  submitForm = values => {
    const { actions } = this.props;
    const { queryOptions } = this.state;
    this.setState({ submittingForm: true });
    const userData = {
      CanManageCustomerAccounts: true,
      EmailAddress: values.EmailAddress,
      LastName: null,
      ...values,
    };
    userData.Policies = this.repairPolicy(userData.Policies);
    if (values.Id) {
      actions.editUser(userData, queryOptions);
    } else {
      actions.addUser(userData, queryOptions);
    }
    // reset rowIndex to avoid render issues
    actions.hideMenu();
    actions.hideModal();
    this.setState({ submittingForm: false });
  };

  resendInvite = () => {
    const { actions, selectedUserId } = this.props;
    actions.resendInvite(selectedUserId);
    actions.hideModal();
  };

  resetPassword = () => {
    const { actions, selectedUserId } = this.props;
    actions.resetPassword(selectedUserId);
    actions.hideModal();
  };

  changeLocationAccess = (user, location) => {
    const { schedules } = this.props;
    const locationId = location.Id;
    const schedule = schedules.find(sched => {
      if (user.UserType === UserConsts.proxy) {
        return sched.Name === UserConsts.scheduleNever;
      }
      return sched.Name === UserConsts.scheduleAlways;
    });
    const policyIndex = user.Policies.findIndex(
      policy => policy.GroupId === locationId,
    );
    if (policyIndex >= 0) {
      user.Policies.splice(policyIndex, 1);
    } else {
      user.Policies.push({
        GroupId: locationId,
        HealthScheduleId: schedule ? schedule.Id : null,
        SecurityScheduleId: schedule ? schedule.Id : null,
      });
    }
    this.setState({
      editedUser: user,
    });
  };

  handleUserFilter = (field, values) => {
    const { actions, handleFilter } = this.props;
    const newFilters = handleFilter(field, values, false, 'contains');
    if (newFilters === null) return; // No need to continue if filters haven't changed.
    const filters = [];
    const orFilters = [];
    newFilters.forEach(filter => {
      if (filter.field === 'Role') {
        filters.push(filter);
      } else if (filter.field === 'locationId') {
        // Location filters will be handled client-side temporarily
        // TODO: MVAAS-20277 handle site access filter server-side
      } else {
        orFilters.push(filter);
      }
    });
    const queryOptions = {
      filters: [
        ...filters,
        {
          filters: orFilters,
          filtersOperator: 'or',
        },
      ],
      filtersOperator: 'and',
      skip: 0,
      sort: {
        col: 'LastName,FirstName',
        direction: 'asc',
      },
      top: 50,
    };
    actions.getUsers(queryOptions);
  };

  render() {
    const {
      actions,
      canChangeCustomer,
      currentOrganization,
      filterValues,
      filteredItems,
      filters,
      handleEditClick,
      isFetchingUsers,
      locations,
      profile,
      showSchedules,
      showSites,
      translate,
    } = this.props;
    const { queryOptions } = this.state;
    const processedUsers = filteredItems.map(user => {
      let locationIds = '';
      const policies = [];
      const localizedStatus =
        user.Status && STATUS_KEYS[user.Status]
          ? translate(STATUS_KEYS[user.Status].key)
          : '';
      const localizedRole =
        user.Role && ROLES_KEYS[user.Role]
          ? translate(ROLES_KEYS[user.Role].key)
          : '';
      if (user.Policies) {
        user.Policies.forEach(policy => {
          const loc = locations.find(l => l.Id === policy.GroupId);
          locationIds += loc && `${loc.Id}, `; // For table filtering
          // Using the subscription package, determine if this is a dealer
          // proxy user and, if so, if they are providing health monitoring for this site
          if (
            loc &&
            loc.PackageSubscription &&
            user.TenantProxyId === loc.PackageSubscription.DealerId
          ) {
            policy.monitoring =
              loc.PackageSubscription.RemoteHealthMonitoringEnabled ||
              loc.PackageSubscription.RemoteSecurityMonitoringEnabled;
          }
          if (loc) {
            policies.push(policy); // Show only sites you have access to
          }
        });
      }
      const processedUser = {
        ...user,
        Policies: policies.length > 0 ? policies : [],
        Role: localizedRole,
        RoleType: user.Role,
        Status: localizedStatus,
        StatusType: user.Status,
        isCloudAdmin:
          currentOrganization &&
          currentOrganization.Type === UserConsts.avigilon,
        isProxyUser: user.UserType === UserConsts.proxy,
        locationIds,
      };
      return processedUser;
    });
    const isCloudAdmin = () =>
      currentOrganization && currentOrganization.Type === UserConsts.avigilon;

    return (
      <div className={contentContainer}>
        <PageMessage
          messageType={[messageTypes.USER_ERROR, messageTypes.USER_SUCCESS]}
        />
        <ListNav
          actionImage={ACTION_IMAGES.USER}
          bulkActionsTable={BULK_ACTION_TABLES.USERS}
          canAdd
          canChangeCustomer={canChangeCustomer}
          canDelete
          deleteActive={this.selectedUserIds.length > 0}
          id={UserConsts.idUsersNavHeader}
          navigationTabs={<AccountNavMenu profile={profile} />}
          onAddClick={this.handleAddClick}
          onDeleteClick={this.handleDeleteClick}
          onViewClick={handleEditClick}
          pageTitle={<PageTitle title={<Translate id="USERS.USERS_TITLE" />} />}
          viewActive={this.selectedUserIds.length > 0}
        >
          <div className={filterWrapper}>
            <InputFilter
              field={textInputFields}
              id={UserConsts.idSearchInput}
              onChange={this.handleUserFilter}
              overrideValue
              placeholder={UserConsts.SEARCH_PLACEHOLDER}
              value={this.getFilterValue(UserConsts.filters.users.name.field)}
            />
          </div>
          <div className={filterWrapper}>
            <FilterTranslated
              field={UserConsts.filters.users.role.field}
              forceReset={filters.length === 0}
              id={UserConsts.idRoleDropdown}
              label={UserConsts.filters.users.role.label}
              onChange={this.handleUserFilter}
              options={mapObjKeysToOptions(ROLES_KEYS)}
              translateLabel
              translateOptions
              value={this.getFilterValue(UserConsts.filters.users.role.field)}
            />
          </div>
          {showSites && (
            <div className={filterWrapper}>
              <FilterTranslated
                field={UserConsts.filters.users.site.field}
                id={UserConsts.idLocationsDropdown}
                label={UserConsts.filters.users.site.label}
                onChange={this.handleUserFilter}
                options={filterValues.location}
                translateLabel
                value={this.getFilterValue(UserConsts.filters.users.site.field)}
              />
            </div>
          )}
        </ListNav>

        <MainContentWrapper>
          <EmptyPlaceholder
            isFetching={isFetchingUsers && processedUsers.length === 0}
            items={processedUsers}
            translateKey="FILTER.NO_RESULTS_FOUND"
          >
            <UserTable
              currentOrganization={currentOrganization}
              getNextPage={actions.getUsers}
              isCloudAdmin={isCloudAdmin()}
              isFetchingNextPage={isFetchingUsers}
              locations={locations}
              nextPageURL={queryOptions}
              onDeleteClick={this.handleDeleteClick}
              profile={profile}
              rowsSelected={this.context.bulkActionIds.users}
              schedules={this.getLocalizedSchedues()}
              showSchedules={showSchedules}
              showSites={showSites}
              translate={translate}
              users={processedUsers}
            />
          </EmptyPlaceholder>
        </MainContentWrapper>
      </div>
    );
  }
}

UsersTableContainer.defaultProps = {
  isFetchingLocations: null,
  isFetchingOrgSubscriptions: null,
  isFetchingSchedules: null,
  isFetchingTimezones: null,
  locations: [],
  orgSubscriptions: [],
  schedules: [],
  selectedUserId: null,
  users: [],
};

function mapStateToProps(state) {
  const orgId = permissions.getOrgIdFromStore(state);
  return {
    canAccessUserRoles: state.user.permissions.CAN_HAVE_NON_ADMIN_USERS,
    canEditProxySiteAccess: state.user.permissions.CAN_EDIT_PROXY_SITE_ACCESS,
    canEditSiteAccess: state.user.permissions.CAN_EDIT_SITE_ACCESS,
    currentOrganization: state.user.currentOrganization,
    filterGroup: FILTER_GROUPS.ORG_USERS,
    filterStatus: state.filters.filterStatus,
    filterValues: state.filters.users,
    isFetchingLocations: state.isFetching.getLocations,
    isFetchingOrgSubscriptions: state.isFetching.getOrgSubscriptions,
    isFetchingSchedules: state.isFetching.getSchedules,
    isFetchingTimezones: state.isFetching.getTimezones,
    isFetchingUsers: state.isFetching.getUsers,
    items: state.user.users,
    locations: state.locations.locations,
    modalOperation: state.modal.modalType,
    orgId,
    orgSubscriptions: state.locations.orgSubscriptions,
    profile: state.user.profile,
    schedules: state.user.schedules,
    selectedUserId: state.modal.modalProps.userId,
    showSchedules: state.user.permissions.CAN_VIEW_SCHEDULES_COLUMNS,
    showSites: state.user.permissions.CAN_VIEW_SITES,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...LocationActions,
        ...UserActions,
        ...MenuActions,
        ...FilterActions,
        ...DeviceActions,
        ...UtilityActions,
        hideModal,
        showModal,
      },
      dispatch,
    ),
  };
}

export default NavigationWrapper(
  withLocalize(UsersTableContainer),
  mapStateToProps,
  mapDispatchToProps,
  null,
  { textInputFields },
);
