import 'rc-time-picker/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
import { withLocalize } from 'react-localize-redux';
import { getTimeFormatMilliSec } from 'util/convertTimeTo';
import { timePickerContainer } from './styles.css';

class TimeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: null,
    };
  }

  convertTimeToTZ = initialDateTime => {
    if (!initialDateTime) {
      return null;
    }
    const { timezone } = this.props;
    let convertedDate = moment(initialDateTime);
    if (timezone) {
      convertedDate = convertedDate.tz(timezone);
    }
    return convertedDate;
  };

  onTimeChange = time => {
    const { initialDay, onChange } = this.props;
    this.setState({
      selectedTime: time,
    });
    const oldDateTime = this.convertTimeToTZ(initialDay);
    let newTime;
    if (time && oldDateTime) {
      newTime = oldDateTime.clone().set({
        hour: time.get('hour'),
        millisecond: time.get('millisecond'),
        minute: time.get('minute'),
        second: time.get('second'),
      });
      onChange(newTime || time);
    }
  };

  render() {
    const {
      disabledHours,
      disabledMinutes,
      placeholder,
      preferredTimeFormat,
      showHour,
      showMinute,
      showSecond,
      translate,
      use12Hours,
      value,
    } = this.props;
    const { selectedTime } = this.state;

    return (
      <div className={timePickerContainer}>
        <TimePicker
          {...this.props}
          allowEmpty={false}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
          format={`${getTimeFormatMilliSec(preferredTimeFormat)} ${
            use12Hours ? 'a' : ''
          }`}
          hideDisabledOptions
          onChange={this.onTimeChange}
          placeholder={placeholder && translate(placeholder)}
          selected={selectedTime}
          showHour={showHour}
          showMinute={showMinute}
          showSecond={showSecond}
          use12Hours={use12Hours}
          value={this.convertTimeToTZ(value)}
        />
      </div>
    );
  }
}

TimeSelect.propTypes = {
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  initialDay: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  // Provide initialDay if the returned dateTime object should have a day/month/year
  // value which does not match the current date
  placeholder: PropTypes.string,
  preferredTimeFormat: PropTypes.string,
  showHour: PropTypes.bool,
  showMinute: PropTypes.bool,
  showSecond: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  use12Hours: PropTypes.bool,
  value: PropTypes.objectOf(PropTypes.any),
};

TimeSelect.defaultProps = {
  disabledHours: () => {
    return [];
  },
  disabledMinutes: () => {
    return [];
  },
  initialDay: null,
  onChange: () => {},
  placeholder: 'TIME_SELECT.PLACEHOLDERS.TIME',
  preferredTimeFormat: null,
  showHour: true,
  showMinute: true,
  showSecond: true,
  use12Hours: true,
  value: null,
};

function mapStateToProps(state) {
  return {
    preferredTimeFormat:
      state.user.profile.LocalizationPreference.PreferredTimeFormat,
  };
}

export default connect(mapStateToProps, null)(withLocalize(TimeSelect));
