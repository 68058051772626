import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldInput } from 'components/FormElements';
import { FormGroup } from 'lib';
import * as LocationFormConsts from '../../constants';

import { formField, postalCodeField } from '../../styles.css';

const UsaZipCode = props => {
  const { disabled } = props;
  return (
    <FormGroup
      id={LocationFormConsts.idPostalCode}
      keyName="usaZipCode"
      label={<Translate id="ADDRESS.ZIP" />}
    >
      <Field
        className={`${formField} ${postalCodeField}`}
        component={FieldInput}
        disabled={disabled}
        isNewVersion
        name={LocationFormConsts.namePostalCode}
        normalize={validation.trim}
        size={10}
        translate
        type="text"
        validate={validation.required}
      />
    </FormGroup>
  );
};

UsaZipCode.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default UsaZipCode;
