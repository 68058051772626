import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconAddPrivacyZone = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <path
          d="M17.7850466,8.49127759 C17.8813204,8.39280922 17.8813204,8.23140993 17.7850466,8.13338578 L16.7455231,7.07496182 C16.6492494,6.9767896 16.4911685,6.9767896 16.3944585,7.07496182 L13.9295894,9.58449863 L11.4632661,7.07362917 C11.3664106,6.97545694 11.2083298,6.97545694 11.112056,7.07362917 L10.0725325,8.13249734 C9.9758225,8.23007728 9.9758225,8.39103234 10.0725325,8.49038915 L12.538565,11.0008144 L10.0734051,13.5107954 C9.97756764,13.6085234 9.97756764,13.7699227 10.0734051,13.868243 L11.1129286,14.926815 C11.209784,15.024395 11.3685921,15.024395 11.4644295,14.926815 L13.9292986,12.4172782 L16.3927134,14.9254824 C16.4889871,15.0235065 16.6479405,15.0235065 16.7442143,14.9254824 L17.7841741,13.8666142 C17.8801569,13.7688862 17.8801569,13.607635 17.7841741,13.5087224 L15.3207593,11.0003702 L17.7850466,8.49127759 Z"
          id="path-1"
        />
        <filter
          x="-6.4%"
          y="-6.2%"
          width="112.7%"
          height="125.0%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <circle id="path-3" cx="3" cy="18" r="3" />
        <filter
          x="-8.3%"
          y="-8.3%"
          width="116.7%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
        <circle id="path-5" cx="24" cy="18" r="3" />
        <filter
          x="-8.3%"
          y="-8.3%"
          width="116.7%"
          height="133.3%"
          filterUnits="objectBoundingBox"
          id="filter-6"
        >
          <feOffset
            dx="0"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
            type="matrix"
            in="shadowOffsetOuter1"
          />
        </filter>
      </defs>
      <g
        id="01-notifications-list"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Alarms-List" transform="translate(-372.000000, -147.000000)">
          <g id="Privacy" transform="translate(372.000000, 147.000000)">
            <path
              d="M4,17 L6,17 L6,19 L3.5,19 L2,19 L2,16 L4,16 L4,17 Z M2,2 L5,2 L5,4 L2,4 L2,2 Z M7,2 L10,2 L10,4 L7,4 L7,2 Z M12,2 L15,2 L15,4 L12,4 L12,2 Z M17,2 L20,2 L20,4 L17,4 L17,2 Z M8,17 L11,17 L11,19 L8,19 L8,17 Z M13,17 L16,17 L16,19 L13,19 L13,17 Z M18,17 L21,17 L21,19 L18,19 L18,17 Z M22,2 L25,2 L25,4 L22,4 L22,2 Z M23,6 L25,6 L25,9 L23,9 L23,6 Z M23,11 L25,11 L25,14 L23,14 L23,11 Z M23,16 L25,16 L25,19 L23,19 L23,16 Z M2,6 L4,6 L4,9 L2,9 L2,6 Z M2,11 L4,11 L4,14 L2,14 L2,11 Z"
              id="Combined-Shape"
              fill="#006e99"
            />
            <g id="Path">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-2)"
                xlinkHref="#path-1"
              />
              <use fill="#006e99" fillRule="evenodd" xlinkHref="#path-1" />
            </g>
            <circle id="Oval-2" fill="#006e99" cx="3" cy="3" r="3" />
            <g id="Oval-2">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-4)"
                xlinkHref="#path-3"
              />
              <use fill="#006e99" fillRule="evenodd" xlinkHref="#path-3" />
            </g>
            <circle id="Oval-2" fill="#006e99" cx="24" cy="3" r="3" />
            <g id="Oval-2">
              <use
                fill="black"
                fillOpacity="1"
                filter="url(#filter-6)"
                xlinkHref="#path-5"
              />
              <use fill="#006e99" fillRule="evenodd" xlinkHref="#path-5" />
            </g>
            <path
              d="M32,13 L29,13 L29,15 L32,15 L32,18 L34,18 L34,15 L37,15 L37,13 L34,13 L34,10 L32,10 L32,13 Z"
              id="Combined-Shape-Copy-2"
              fill="#006e99"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IconAddPrivacyZone.defaultProps = {
  className: null,
  width: '45px',
  height: '30px',
};

export default IconAddPrivacyZone;
