// Libs
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import renderIf from 'render-if';

// Components
import FieldInput from 'components/FormElements/FieldInput';
import GroupLayout from 'lib/GroupLayout/GroupLayout';
import RadioGroup from 'components/Inputs/RadioGroup';
import { Button, FieldSelect } from 'lib';

// Style
import { formLabel } from 'components/VerticalForm/styles.css';
import {
  formField,
  formWrapper,
  modalFormGroup,
} from 'sharedStyles/modalForms.css';
import { hidden } from 'sharedStyles/styles.css';

// Const
import { CAMERA_LOGIN_TYPES } from 'components/ServerDetail/constants';
import * as findCameraConst from './constant';

const buttonWrapperClass = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row-reverse',
};
const okButtonWrapperClass = { paddingLeft: '10px' };

const formLevelValidation = values => {
  const errors = {};
  if (
    values.searchType === findCameraConst.searchTypes.ipAddress &&
    !values.address
  ) {
    errors.address = <Translate id={findCameraConst.required} />;
  }
  if (
    (values.searchType === findCameraConst.searchTypes.ipAddress ||
      values.searchType === findCameraConst.searchTypes.ipRange) &&
    !values.port
  ) {
    errors.port = <Translate id={findCameraConst.required} />;
  }
  if (
    values.searchType === findCameraConst.searchTypes.ipRange &&
    !values.startIpAddress
  ) {
    errors.startIpAddress = <Translate id={findCameraConst.required} />;
  }
  if (
    values.searchType === findCameraConst.searchTypes.ipRange &&
    !values.endIpAddress
  ) {
    errors.endIpAddress = <Translate id={findCameraConst.required} />;
  }
  if (
    values.searchType === findCameraConst.searchTypes.rtsp &&
    !values.urlStream
  ) {
    errors.urlStream = <Translate id={findCameraConst.required} />;
  }
  return errors;
};

const Form = ({
  handleSubmit,
  onCancel,
  searchType,
  supportedCameras,
  visible,
}) => {
  const [loginType, setLoginType] = useState(
    CAMERA_LOGIN_TYPES.FDS_CAMERA_LOGIN,
  );

  const supportedCamerasKeyValue = [];
  supportedCameras.forEach(element => {
    supportedCamerasKeyValue.push({
      key: element,
      value: element,
    });
  });
  return (
    <form className={visible ? formWrapper : hidden} onSubmit={handleSubmit}>
      <div className={modalFormGroup}>
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.searchType} />
        </label>
        <Translate>
          {({ translate }) => (
            <Field
              className={formField}
              component={FieldSelect}
              name={findCameraConst.field.VALUE.searchType}
            >
              <option
                value={findCameraConst.field.VALUE.searchTypeSelect.ipAddress}
              >
                {translate(
                  findCameraConst.field.LABEL.searchTypeSelect.ipAddress,
                )}
              </option>
              <option
                value={findCameraConst.field.VALUE.searchTypeSelect.ipRange}
              >
                {translate(
                  findCameraConst.field.LABEL.searchTypeSelect.ipRange,
                )}
              </option>
              <option value={findCameraConst.field.VALUE.searchTypeSelect.rtsp}>
                {translate(findCameraConst.field.LABEL.searchTypeSelect.rtsp)}
              </option>
            </Field>
          )}
        </Translate>
      </div>
      <div
        className={
          searchType !== findCameraConst.searchTypes.rtsp
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.cameraType} />
        </label>
        <Field className={formField} component={FieldSelect} name="cameraType">
          {supportedCamerasKeyValue.map(item => (
            <option key={item.key} value={item.value}>
              {item.value}
            </option>
          ))}
        </Field>
      </div>
      <div
        className={
          searchType === findCameraConst.searchTypes.ipAddress ? null : hidden
        }
      />
      <div
        className={
          searchType === findCameraConst.searchTypes.ipAddress
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.ipOrHost} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.address}
        />
      </div>
      <div
        className={
          searchType === findCameraConst.searchTypes.ipRange
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.rangeStart} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.startIpAddress}
        />
      </div>
      <div
        className={
          searchType === findCameraConst.searchTypes.ipRange ? null : hidden
        }
      />
      <div
        className={
          searchType === findCameraConst.searchTypes.ipRange
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.rangeEnd} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.endIpAddress}
        />
      </div>
      <div
        className={
          searchType !== findCameraConst.searchTypes.rtsp ? null : hidden
        }
      />
      <div
        className={
          searchType !== findCameraConst.searchTypes.rtsp
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.controlPort} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.port}
        />
      </div>
      <div
        className={
          searchType === findCameraConst.searchTypes.rtsp
            ? modalFormGroup
            : hidden
        }
      >
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.streamURL} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.urlStream}
        />
      </div>
      <div className={modalFormGroup} id="radioApplyFDSCredentials">
        {renderIf(searchType !== findCameraConst.searchTypes.rtsp)(
          <RadioGroup
            labelClass={formLabel}
            labelTranslateId="DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.FDS_CAMERA_CREDENTIALS_HEADER"
            onChange={() => {
              setLoginType(
                loginType === CAMERA_LOGIN_TYPES.FDS_CAMERA_LOGIN
                  ? CAMERA_LOGIN_TYPES.FDS_CAMERA_PASSWORD_RESET
                  : CAMERA_LOGIN_TYPES.FDS_CAMERA_LOGIN,
              );
            }}
            options={[
              {
                translateId: 'COMMON.YES',
                value: true,
              },
              {
                translateId: 'COMMON.NO',
                value: false,
              },
            ]}
            value={loginType === CAMERA_LOGIN_TYPES.FDS_CAMERA_PASSWORD_RESET}
            verticalLayout={false}
          />,
        )}
      </div>
      <div className={modalFormGroup}>
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.username} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.username}
        />
      </div>
      <div className={modalFormGroup}>
        <label className={formLabel}>
          <Translate id={findCameraConst.field.LABEL.password} />
        </label>
        <Field
          className={formField}
          component={FieldInput}
          name={findCameraConst.field.VALUE.password}
          type={findCameraConst.field.VALUE.password}
        />
      </div>
      {renderIf(loginType !== CAMERA_LOGIN_TYPES.FDS_CAMERA_LOGIN)(
        renderIf(searchType !== findCameraConst.searchTypes.rtsp)(
          <div className={modalFormGroup}>
            <label className={formLabel}>
              <Translate id={findCameraConst.field.LABEL.confirmPassword} />
            </label>
            <Field
              className={formField}
              component={FieldInput}
              name={findCameraConst.field.VALUE.confirmPassword}
              type={findCameraConst.field.VALUE.password}
            />
          </div>,
        ),
      )}
      <GroupLayout horizontalPositioning="right" verticalSpacing="large">
        <div style={buttonWrapperClass}>
          <div style={okButtonWrapperClass}>
            <Button
              buttonType="primary"
              inputType="submit"
              text={<Translate id="BUTTONS.OK" />}
            />
          </div>
          <Button
            buttonType="primary"
            inputType="button"
            onClick={onCancel}
            text={<Translate id="BUTTONS.CANCEL" />}
          />
        </div>
      </GroupLayout>
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  searchType: PropTypes.string,
  supportedCameras: PropTypes.arrayOf(PropTypes.string).isRequired,
  visible: PropTypes.bool,
};

Form.defaultProps = {
  searchType: null,
  visible: false,
};

function mapStateToProps(state) {
  const selector = formValueSelector('findCameraForm');
  const searchType = selector(state, 'searchType');
  const cameraType = selector(state, 'cameraType');
  const initialValues = findCameraConst.getInitialValues(
    cameraType,
    searchType,
  );
  return {
    initialValues,
    searchType,
  };
}

const FindCameraForm = reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: 'findCameraForm',
  validate: formLevelValidation,
})(Form);

export default connect(mapStateToProps)(FindCameraForm);
