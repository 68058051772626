export const FIELD_ORDER = [
  'id',
  'moveSpeed',
  'viewTimeMs',
  'Actions',
  'Delete',
];
export const HEADER_TRANSLATION_IDS = {
  id: 'PTZ.TOURS.TABLE.PRESET',
  moveSpeed: 'PTZ.TOURS.TABLE.SPEED',
  viewTimeMs: 'PTZ.TOURS.TABLE.VIEW_TIME',
  Actions: 'PTZ.TOURS.TABLE.ACTIONS',
  Delete: 'PTZ.TOURS.TABLE.DELETE',
};

export const CELL_WIDTHS = {
  moveSpeed: 60,
  viewTimeMs: 150,
  Actions: 50,
  Delete: 30,
};

export const CELL_ALIGNMENTS = {
  moveSpeed: 'center',
  viewTimeMs: 'center',
  Actions: 'center',
  Delete: 'center',
};
// Temporary until data comes in
export const DATA = [
  {
    id: 2,
    moveSpeed: 0.8,
    viewTimeMs: 3000,
    Actions: 'Actions go here',
  },
  {
    id: 5,
    moveSpeed: 0.7,
    viewTimeMs: 3000,
    Actions: 'Actions go here',
  },
];

export const DEFAULT_IDLE_START_TIME = 5 * 60 * 1000;
export const DEFAULT_MOVE_SPEED = 0.8;
export const MIN_MOVE_SPEED = 0.01;
export const MAX_MOVE_SPEED = 1;
export const DEFAULT_VIEW_TIME_MS = 10 * 1000;
export const DEFAULT_PAUSE_DURATION_MS = 0;
