exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".layoutPositionFixedTop___2ntlL {\r\n  position: fixed;\r\n  top: 0;\r\n}\r\n\r\n.layoutZIndexOnTop___IFPqj {\r\n  z-index: 1100;\r\n}\r\n\r\n.layoutZIndexJustUnderTop___iPwV_ {\r\n  z-index: 1000;\r\n}\r\n\r\n.layoutWidth100Percent___2eL7R {\r\n  width: 100%;\r\n}\r\n\r\n.layoutHeight100Percent___2Ys8Y {\r\n  height: 100%;\r\n}\r\n\r\n.layoutPositionRelative___3-5-C {\r\n  position: relative;\r\n}\r\n\r\n.layoutDisplayFlex___3vrYx {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.layoutFlex1___HYPvr {\r\n  -ms-flex: 1;\r\n      flex: 1;\r\n}\r\n\r\n.layoutFlexCenter___3DLao {\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: distribute;\r\n      justify-content: space-around;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/sharedStyles/layout.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,OAAO;CACR;;AAED;EACE,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb;;AAED;EACE,aAAa;CACd;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,qBAAqB;EACrB,cAAc;CACf;;AAED;EACE,YAAY;MACR,QAAQ;CACb;;AAED;EAEE,uBAAuB;MACnB,oBAAoB;EACxB,0BAA0B;MACtB,8BAA8B;CACnC","file":"layout.css","sourcesContent":[".layoutPositionFixedTop {\r\n  position: fixed;\r\n  top: 0;\r\n}\r\n\r\n.layoutZIndexOnTop {\r\n  z-index: 1100;\r\n}\r\n\r\n.layoutZIndexJustUnderTop {\r\n  z-index: 1000;\r\n}\r\n\r\n.layoutWidth100Percent {\r\n  width: 100%;\r\n}\r\n\r\n.layoutHeight100Percent {\r\n  height: 100%;\r\n}\r\n\r\n.layoutPositionRelative {\r\n  position: relative;\r\n}\r\n\r\n.layoutDisplayFlex {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n}\r\n\r\n.layoutFlex1 {\r\n  -ms-flex: 1;\r\n      flex: 1;\r\n}\r\n\r\n.layoutFlexCenter {\r\n  composes: layoutDisplayFlex;\r\n  -ms-flex-align: center;\r\n      align-items: center;\r\n  -ms-flex-pack: distribute;\r\n      justify-content: space-around;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"layoutPositionFixedTop": "layoutPositionFixedTop___2ntlL",
	"layoutZIndexOnTop": "layoutZIndexOnTop___IFPqj",
	"layoutZIndexJustUnderTop": "layoutZIndexJustUnderTop___iPwV_",
	"layoutWidth100Percent": "layoutWidth100Percent___2eL7R",
	"layoutHeight100Percent": "layoutHeight100Percent___2Ys8Y",
	"layoutPositionRelative": "layoutPositionRelative___3-5-C",
	"layoutDisplayFlex": "layoutDisplayFlex___3vrYx",
	"layoutFlex1": "layoutFlex1___HYPvr",
	"layoutFlexCenter": "layoutFlexCenter___3DLao layoutDisplayFlex___3vrYx"
};