import React from 'react';
import { SVG_XMLNS } from '../constants/app';
import { ICON_CLOUD } from './constants';

// eslint-disable-next-line react/prop-types
const IconCloud = ({ className, fill, height, title, width }) => {
  return (
    <div className={className} id={ICON_CLOUD} title={title}>
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 21 24"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g fill={fill} fillRule="evenodd">
          <path
            d="M20.0009,12.2194 L20.0009,11.8114 L20.0009,11.0004 C20.0009,6.7164 16.0009,4.0004 13.0009,4.0004 L12.0009,4.0004 C8.5569,4.0004 5.7139,6.5194 5.0009,10.0794 C2.6239,10.2654 0.9999,12.3774 0.9999,14.9614 L0.9999,15.1194 C0.9999,17.8264 3.0779,20.0004 5.6009,20.0004 L19.4369,20.0004 C21.3959,20.0004 22.9999,18.2984 22.9999,16.2194 L22.9999,15.9994 C23.0269,14.0154 21.7069,12.3774 20.0009,12.2194 L20.0009,12.2194 Z"
            fill={fill}
            fillOpacity=".8"
          />
        </g>
      </svg>
    </div>
  );
};

IconCloud.defaultProps = {
  className: null,
  fill: 'none',
  height: '24px',
  title: '',
  width: '24px',
};

export default IconCloud;
