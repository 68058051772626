import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import {
  contentCard,
  contentCardsContainer,
  contentData,
  contentHeader,
} from './OverflowRow.css';

const OverflowRow = ({ data, fieldOrder, headerTranslationIds }) => {
  const overflowContent = fieldOrder.map(fieldName => (
    <div key={fieldName} className={contentCard}>
      <div className={contentHeader}>
        <Translate id={headerTranslationIds[fieldName]} />
      </div>
      <div className={contentData}>{data[fieldName]}</div>
    </div>
  ));
  return <div className={contentCardsContainer}>{overflowContent}</div>;
};

OverflowRow.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldOrder: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  headerTranslationIds: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default OverflowRow;
