import React from 'react';
import { Translate } from 'react-localize-redux';

// Styles
import { PropTypes } from 'prop-types';
import BLUE_CONNECT_MODELS from 'constants/BlueConnectModels';
import { serverImage, serverImageContainer } from './ServerImage.styles.css';

// Constants
import {
  SERVER_IMAGE_HEIGHT,
  SERVER_IMAGE_HEIGHT_LARGE,
  SERVER_IMAGE_WIDTH,
  SERVER_IMAGE_WIDTH_LARGE,
} from './constants';

// Images
const blueGenericBox = require('../../images/blue-box-generic-blue-bg.png');
const blueDarkhorseBox = require('../../images/blue-box-darkhorse-blue-bg.png');
const blueWhiteBox = require('../../images/white-box-blue-bg.png');

const ServerImage = props => {
  const { serverModel, useLargeImages } = props;

  const getServerImage = () => {
    if (serverModel) {
      switch (serverModel) {
        case BLUE_CONNECT_MODELS.DARKHORSE: {
          return (
            <Translate>
              {({ translate }) => (
                <img
                  alt={translate('ACCESSIBILITY.SERVER_DARKHORSE')}
                  height={
                    useLargeImages
                      ? SERVER_IMAGE_HEIGHT_LARGE
                      : SERVER_IMAGE_HEIGHT
                  }
                  src={blueDarkhorseBox}
                  width={
                    useLargeImages
                      ? SERVER_IMAGE_WIDTH_LARGE
                      : SERVER_IMAGE_WIDTH
                  }
                />
              )}
            </Translate>
          );
        }
        default: {
          return (
            <Translate>
              {({ translate }) => (
                <img
                  alt={translate('ACCESSIBILITY.SERVER_BLUE')}
                  height={
                    useLargeImages
                      ? SERVER_IMAGE_HEIGHT_LARGE
                      : SERVER_IMAGE_HEIGHT
                  }
                  src={blueGenericBox}
                  width={
                    useLargeImages
                      ? SERVER_IMAGE_WIDTH_LARGE
                      : SERVER_IMAGE_WIDTH
                  }
                />
              )}
            </Translate>
          );
        }
      }
    }
    return (
      <Translate>
        {({ translate }) => (
          <img
            alt={translate('ACCESSIBILITY.SERVER_WHITE')}
            height={
              useLargeImages ? SERVER_IMAGE_HEIGHT_LARGE : SERVER_IMAGE_HEIGHT
            }
            src={blueWhiteBox}
            width={
              useLargeImages ? SERVER_IMAGE_WIDTH_LARGE : SERVER_IMAGE_WIDTH
            }
          />
        )}
      </Translate>
    );
  };

  return (
    <div className={serverImageContainer}>
      <div className={serverImage}>{getServerImage()}</div>
    </div>
  );
};

ServerImage.propTypes = {
  serverModel: PropTypes.string,
  useLargeImages: PropTypes.bool,
};

ServerImage.defaultProps = {
  serverModel: '',
  useLargeImages: false,
};

export default ServerImage;
