/* global avoLogError */

import urlBuilder from 'queryBuilder/url';
import {
  runGetActionReturningJSON,
  sendDeleteRequestReturningJSON,
  sendPostRequest,
  sendPostRequestReturningJSON,
  sendPutRequest,
} from 'util/fetchHelpers';
import handleJsonErrors from 'util/handleJsonErrors';
import * as types from 'constants/ActionTypes';
import * as messageTypes from 'constants/MessageTypes';
import handleErrors from 'util/handleErrors';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { getLocations } from './locations';
import { showMessage } from './pageMessage';
import { isFetching } from './common';

// Pure Redux actions

export function receiveDealerServicePackages(dealerServicePackages) {
  return {
    dealerServicePackages,
    type: types.RECEIVE_DEALER_SERVICE_PACKAGES,
  };
}

export function receiveServicePackage(servicePackage, packageId) {
  return {
    scope: packageId,
    servicePackage,
    type: types.RECEIVE_SERVICE_PACKAGE,
  };
}

export function receiveServicePackageByCode(servicePackage, code) {
  return {
    scope: code,
    servicePackage,
    type: types.RECEIVE_SERVICE_PACKAGE,
  };
}

// Asynchronous actions

// retrieves Service Packages
export function getServicePackages(orgId) {
  return dispatch => {
    const url = urlBuilder(types.GET_SERVICE_PACKAGES, orgId);
    return runGetActionReturningJSON({
      dispatch,
      fetchType: types.GET_SERVICE_PACKAGES,
      onError: err => {
        dispatch(
          showMessage(messageTypes.SERVICE_PACKAGE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'SUBSCRIPTIONS.SERVICE_PACKAGE.GET_LOCATION_ERROR_MESSAGE',
          }),
        );
        dispatch(receiveDealerServicePackages([]));
        avoLogError('Service Package Request failed: ', err);
      },
      onSuccess: json => {
        dispatch(receiveDealerServicePackages(json));
      },
      url,
    });
  };
}

export function getServicePackage(packageId) {
  return dispatch => {
    const url = urlBuilder(types.GET_SERVICE_PACKAGE, packageId);
    return runGetActionReturningJSON({
      dispatch,
      fetchScope: packageId,
      fetchType: types.GET_SERVICE_PACKAGE,
      onError: err => {
        dispatch(
          showMessage(messageTypes.SERVICE_PACKAGE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody:
              'SUBSCRIPTIONS.SERVICE_PACKAGE.GET_LOCATION_ERROR_MESSAGE',
          }),
        );
        dispatch(receiveServicePackage([], packageId));
        avoLogError('Service Package Request failed: ', err);
      },
      onSuccess: json => {
        dispatch(receiveServicePackage(json, packageId));
      },
      url,
    });
  };
}

export function getServicePackageByCode(code) {
  return dispatch => {
    const url = urlBuilder(types.GET_SERVICE_PACKAGE_BY_CODE, code);
    return runGetActionReturningJSON({
      dispatch,
      fetchScope: code,
      fetchType: types.GET_SERVICE_PACKAGE_BY_CODE,
      onError: err => {
        dispatch(
          showMessage(messageTypes.SERVICE_PACKAGE_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'SUBSCRIPTIONS.CHANGE_PROVIDER.INVALID_CODE_ERROR',
          }),
        );
        dispatch(receiveServicePackageByCode([], null));
        avoLogError('Service Package Request failed: ', err);
      },
      onSuccess: json => {
        dispatch(receiveServicePackageByCode(json, code));
      },
      url,
    });
  };
}

export function clearServicePackageByCode() {
  return dispatch => {
    dispatch(receiveServicePackageByCode([], null));
  };
}

export function createChangeProviderRequest(locationId, claimCode) {
  return dispatch => {
    const url = urlBuilder(
      types.PENDING_SITE_REQUESTS_CREATE,
      locationId,
      null,
      null,
      null,
    );
    return sendPostRequestReturningJSON(url, {
      ClaimCode: claimCode,
    })
      .then(() => {
        dispatch(
          showMessage(messageTypes.CHANGE_REQUEST_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody:
              'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_REQUEST_SUCCESS',
          }),
        );
        // dispatch getChangeProviderRequest api
      })
      .catch(ex => {
        avoLogError('Error adding a service package change request', ex);
        dispatch(
          showMessage(messageTypes.CHANGE_REQUEST_ERROR, null, null, {
            messageStyle: messageStyleStrings.error,
            translateBody: 'SUBSCRIPTIONS.CHANGE_PROVIDER.CHANGE_REQUEST_ERROR',
          }),
        );
      });
  };
}

export function upgradeServicePackage(orgId, locationId, ServicePackageId) {
  return dispatch => {
    const url = urlBuilder(
      types.UPGRADE_SERVICE_PACKAGE,
      locationId,
      null,
      null,
      { orgId },
    );
    return sendPutRequest(url, { ServicePackageId })
      .then(response => {
        if (response.status >= 400) {
          return response.json().then(json => {
            throw new Error(json);
          });
        }
        dispatch(
          showMessage(
            messageTypes.SERVICE_PACKAGE_UPGRADE_SUCCESS,
            null,
            null,
            {
              messageStyle: messageStyleStrings.success,
              translateBody: 'SUBSCRIPTIONS.SERVICE_PACKAGE.UPGRADE_SUCCESS',
            },
          ),
        );
        dispatch(getLocations(orgId, { id: locationId }));
        return response.json();
      })
      .catch(e => {
        dispatch(
          showMessage(messageTypes.SERVICE_PACKAGE_UPGRADE_ERROR, null, null, {
            header: e.ExceptionMessage,
            messageStyle: messageStyleStrings.error,
            translateBody: 'SUBSCRIPTIONS.SERVICE_PACKAGE.UPGRADE_ERROR',
          }),
        );
      });
  };
}

export function downgradeServicePackage(orgId, locationId, ServicePackageId) {
  return dispatch => {
    const url = urlBuilder(
      types.DOWNGRADE_SERVICE_PACKAGE,
      locationId,
      null,
      null,
      { orgId },
    );
    sendPutRequest(url, { ServicePackageId })
      .then(response => {
        if (response.status >= 400) {
          return response.json().then(json => {
            throw new Error(json);
          });
        }
        dispatch(
          showMessage(
            messageTypes.SERVICE_PACKAGE_DOWNGRADE_SUCCESS,
            null,
            null,
            {
              messageStyle: messageStyleStrings.success,
              translateBody: 'SUBSCRIPTIONS.SERVICE_PACKAGE.DOWNGRADE_SUCCESS',
            },
          ),
        );
        dispatch(getLocations(orgId, { id: locationId }));
        return response.json();
      })
      .catch(e => {
        dispatch(
          showMessage(
            messageTypes.SERVICE_PACKAGE_DOWNGRADE_ERROR,
            null,
            null,
            {
              header: e.ExceptionMessage,
              messageStyle: messageStyleStrings.error,
              translateBody: 'SUBSCRIPTIONS.SERVICE_PACKAGE.DOWNGRADE_ERROR',
            },
          ),
        );
      });
  };
}

export function editSubscriptionTermLength(
  orgId,
  locationId,
  SubscriptionTermLength,
) {
  return dispatch => {
    const url = urlBuilder(
      types.EDIT_SUBSCRIPTION_TERM_LENGTH,
      locationId,
      null,
      null,
      { orgId },
    );
    sendPutRequest(url, { SubscriptionTermLength })
      .then(response => {
        if (response.status >= 400) {
          return response.json().then(json => {
            throw new Error(json);
          });
        }
        dispatch(
          showMessage(messageTypes.EDIT_SUBSCRIPTION_TERM_SUCCESS, null, null, {
            messageStyle: messageStyleStrings.success,
            translateBody: 'SUBSCRIPTIONS.EDIT_SUBSCRIPTION_TERM.SUCCESS',
          }),
        );
        dispatch(getLocations(orgId, { id: locationId }));
        return response.json();
      })
      .catch(e => {
        dispatch(
          showMessage(messageTypes.EDIT_SUBSCRIPTION_TERM_ERROR, null, null, {
            header: e.ExceptionMessage,
            messageStyle: messageStyleStrings.error,
            translateBody: 'SUBSCRIPTIONS.EDIT_SUBSCRIPTION_TERM.ERROR',
          }),
        );
      });
  };
}

export function setDealerMonitoring(orgId, locationId, monitoringEnabled) {
  return dispatch => {
    let url;
    dispatch(isFetching(types.IS_UPDATING_DEALER_HEALTH_DATA));
    if (monitoringEnabled) {
      url = urlBuilder(types.ENABLE_MONITORING, locationId, null, null, {
        orgId,
      });
      // Warning: Beware before changing it to sendPostRequestReturningJSON because
      // we are handling 401 custom message here and do not want handleError 401 message.
      return sendPostRequest(url)
        .then(response => {
          if (response.status === 401) {
            const exception = {
              translateMessage:
                'SUBSCRIPTIONS.DEALER_MONITORING.MONITORING_ACCESS_RESTRICTED',
            };
            throw exception;
          } else {
            return handleErrors(response);
          }
        })
        .then(() => {
          dispatch(
            showMessage(
              messageTypes.HEALTH_MONITORING_ACTIVATION_SUCCESS,
              null,
              null,
              {
                messageStyle: messageStyleStrings.success,
                translateBody:
                  'SUBSCRIPTIONS.DEALER_MONITORING.ACTIVATION_SUCCESS',
              },
            ),
          );
        })
        .catch(e => {
          dispatch(
            showMessage(
              messageTypes.HEALTH_MONITORING_ACTIVATION_ERROR,
              null,
              null,
              {
                body: e.message,
                messageStyle: messageStyleStrings.error,
                translateBody: !e.message && e.translateMessage,
                translateHeader:
                  'SUBSCRIPTIONS.DEALER_MONITORING.ACTIVATION_ERROR',
              },
            ),
          );
        })
        .then(() => {
          dispatch(getLocations(orgId, { id: locationId }));
          dispatch(isFetching(types.IS_UPDATING_DEALER_HEALTH_DATA, false));
        });
    }
    url = urlBuilder(types.DISABLE_MONITORING, locationId, null, null, {
      orgId,
    });

    return sendDeleteRequestReturningJSON(url)
      .then(json => {
        if (json.isError) {
          throw handleJsonErrors(json);
        }
        dispatch(
          showMessage(
            messageTypes.HEALTH_MONITORING_DEACTIVATION_SUCCESS,
            null,
            null,
            {
              messageStyle: messageStyleStrings.success,
              translateBody:
                'SUBSCRIPTIONS.DEALER_MONITORING.DEACTIVATION_SUCCESS',
            },
          ),
        );
      })
      .catch(() => {
        dispatch(
          showMessage(
            messageTypes.HEALTH_MONITORING_DEACTIVATION_ERROR,
            null,
            null,
            {
              messageStyle: messageStyleStrings.error,
              translateBody:
                'SUBSCRIPTIONS.DEALER_MONITORING.DEACTIVATION_ERROR',
            },
          ),
        );
      })
      .then(() => {
        dispatch(getLocations(orgId, { id: locationId }));
        dispatch(isFetching(types.IS_UPDATING_DEALER_HEALTH_DATA, false));
      });
  };
}
