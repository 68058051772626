import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { localizeReducer } from 'react-localize-redux';
import accServerListsReducer from './accServerListsReducer';
import accordionsReducer from './accordionsReducer';
import actionMenuReducer from './actionMenuReducer';
import aft from './aftReducer';
import aftSiteViewReducer from './aftSiteViewReducer';
import alarmReducer from './alarmReducer';
import bookmarksReducer from './bookmarksReducer';
import clustersReducer from './clustersReducer';
import contactReducer from './contactReducer';
import dashboardReducer from './dashboardReducer';
import deviceReducer from './deviceReducer';
import filterReducer from './filterReducer';
import isFetchingReducer from './isFetchingReducer';
import isSavingReducer from './isSavingReducer';
import locationReducer from './locationReducer';
import modalReducer from './modalReducer';
import notificationsReducer from './notificationsReducer';
import organizationReducer from './organizationReducer';
import pageMessageReducer from './pageMessageReducer';
import persistentFilterReducer from './persistentFilterReducer';
import recordingDataReducer from './recordingDataReducer';
import settingsReducer from './settingsReducer';
import signalrReducer from './signalrReducer';
import streamOverlaysReducer from './streamOverlaysReducer';
import toggleReducer from './toggleReducer';
import userReducer from './userReducer';
import utilitiesReducer from './utilitiesReducer';
import videoRetentionReducer from './videoRetentionReducer';
import viewReducer from './viewReducer';
import microFrontendReducer from './microFrontendReducer';

const rootReducer = combineReducers({
  accServerLists: accServerListsReducer,
  accordions: accordionsReducer,
  actionMenu: actionMenuReducer,
  aft,
  aftSiteView: aftSiteViewReducer,
  alarms: alarmReducer,
  bookmarks: bookmarksReducer,
  clusters: clustersReducer,
  contacts: contactReducer,
  dashboard: dashboardReducer,
  devices: deviceReducer,
  filters: filterReducer,
  form: formReducer,
  isFetching: isFetchingReducer,
  isSaving: isSavingReducer,
  localize: localizeReducer,
  locations: locationReducer,
  microFrontend: microFrontendReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  organizations: organizationReducer,
  pageMessage: pageMessageReducer,
  persistentFilters: persistentFilterReducer,
  recordingData: recordingDataReducer,
  settings: settingsReducer,
  signalr: signalrReducer,
  streamOverlays: streamOverlaysReducer,
  toggle: toggleReducer,
  user: userReducer,
  utilities: utilitiesReducer,
  videoRetention: videoRetentionReducer,
  views: viewReducer,
});

export default rootReducer;
