import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import { SELECT_STYLE } from 'constants/app';
import { disabledBorderStyle, timezoneSelectContainer } from './styles.css';

import * as TimeZoneSelectConsts from './constants';

class TimeZoneSelect extends Component {
  // Reformat the data result to the one react-select expects
  get timezoneOptions() {
    const { timezones, type } = this.props;
    const append = type === TimeZoneSelectConsts.types.SERVER ? '' : ':00';
    return timezones.map(tzOption => {
      return {
        label: `(UTC${tzOption.Value}${append}) ${tzOption.Key}`,
        value: tzOption.Key,
      };
    });
  }

  onChangeTimezone = selection => {
    const { changeCallback, input, sendValue, timezones } = this.props;
    if (sendValue) {
      const value = timezones.find(
        timezone => timezone.Key === selection.value,
      );
      input.onChange(value);
      if (changeCallback) changeCallback(value);
    } else {
      input.onChange(selection.value);
      if (changeCallback) changeCallback(selection.value);
    }
  };

  render() {
    const { className: classNameProp, disabled, input } = this.props;
    return (
      <div
        className={`${classNameProp} ${timezoneSelectContainer}`}
        id={TimeZoneSelectConsts.selectId}
      >
        <Select
          className={disabled ? disabledBorderStyle : ''}
          defaultValue={
            this.timezoneOptions &&
            this.timezoneOptions.length &&
            this.timezoneOptions.find(item => item.value === input.value)
          }
          isDisabled={disabled}
          name={input.name}
          onChange={this.onChangeTimezone}
          options={this.timezoneOptions}
          placeholder={
            <Translate id="USERS.USER_FORM_FIELDS.PLACEHOLDERS.SELECT_ONE" />
          }
          style={SELECT_STYLE}
          value={
            input.value
              ? this.timezoneOptions.find(item => item.value === input.value)
              : ''
          }
        />
      </div>
    );
  }
}

TimeZoneSelect.propTypes = {
  changeCallback: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  sendValue: PropTypes.bool,
  timezones: PropTypes.arrayOf(
    PropTypes.shape({
      Key: PropTypes.string,
      Value: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string,
};

TimeZoneSelect.defaultProps = {
  changeCallback: null,
  className: '',
  disabled: false,
  input: {},
  sendValue: false,
  type: TimeZoneSelectConsts.types.DEFAULT,
};

export default TimeZoneSelect;
