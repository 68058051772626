import React from 'react';

import { VerticalFormField } from 'components';
import { Field } from 'redux-form';

import { formField } from './styles.css';

const VerticalFormFieldStack = ({
  fieldPropList,
  filterPropList = [],

  newUser,
}) =>
  fieldPropList
    .filter(fieldProps => !filterPropList.includes(fieldProps.name))
    .map(fieldProps => (
      <Field
        key={fieldProps.name}
        component={VerticalFormField}
        className={formField}
        {...fieldProps}
        newUser={newUser}
      />
    ));

export default VerticalFormFieldStack;
