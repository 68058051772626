const fieldTypes = {
  CameraId: 'CameraId',
  LocationId: 'LocationId',
  Created: 'Created',
  GroupId: 'GroupId',
  SourceTenantId: 'SourceTenant_Id',
};

const fieldToType = field => {
  switch (field) {
    case fieldTypes.CameraId:
    case fieldTypes.LocationId:
    case fieldTypes.Created:
    case fieldTypes.SourceTenantId:
      return 'number';
    case fieldTypes.GroupId:
      return 'guid';
    default:
      return 'string';
  }
};

export const mapObjToOptions = obj => {
  // Function to map an object of key-value pairs
  // into an array of objects usable by the Filter component as options.
  return Object.keys(obj).map(key => ({
    value: key,
    text: obj[key],
  }));
};

// TODO: MVAAS-12646
// once fully localized, this should replace the function above
export const mapObjKeysToOptions = obj => {
  // Function to map an object of key-value pairs
  // into an array of objects usable by the Filter component as options.
  return Object.keys(obj).map(key => ({
    value: obj[key].value,
    text: obj[key].key,
  }));
};

export const processFilters = (filters, newFilter) => {
  // Takes in old filters as filters
  // newFilter looks like: {field, values, typeOfValue, operator}
  // returns a new filter object and filtersChanged (true, false)
  let newFilters;
  let hasField = false;
  let fieldChanged = false;

  const { field, values } = newFilter;
  let { typeOfValue, operator } = newFilter;
  if (!field) {
    return { newFilters: filters, filtersChanged: false };
  }
  typeOfValue = typeOfValue || fieldToType(field);
  operator = operator || 'eq';

  if (values === 'All') {
    // All clears the field
    newFilters = filters.filter(f => f.field !== field);
    hasField = true;
    fieldChanged = !!filters.find(f => f.field === field);
  } else {
    newFilters = filters.map(f => {
      if (f.field === field && f.operator === operator) {
        hasField = true;
        fieldChanged =
          values.filter(v => !f.values.includes(v)).length > 0 ||
          f.values.length !== values.length;
        return { ...f, values };
      }
      return f;
    });
    if (!hasField) {
      newFilters.push({ field, values, typeOfValue, operator });
    }
  }

  return { newFilters, filtersChanged: fieldChanged || !hasField };
};

// Export so we can test
export const findValuesInItem = (item, field, values) => {
  let itemFound = false;
  if (
    values.find(
      value =>
        value === item[field] ||
        (typeof value === 'string' &&
          item[field] &&
          item[field].toLowerCase().includes(value.toLowerCase())),
    )
  ) {
    itemFound = true;
  }

  // The values are 'Connected' and 'Disconnected', so one of those always matches.
  if (field === 'ConnectionState') {
    const match = values.find(value => {
      return value === item[field];
    });

    itemFound = !!match;
  }

  // Special cases, database-specific
  if (field === 'locationId') {
    if (
      item.Policies &&
      item.Policies.find(policy => values.includes(policy.GroupId))
    ) {
      itemFound = true;
    }
    if (item.Sites && item.Sites.find(site => values.includes(site))) {
      itemFound = true;
    }
  }
  return itemFound;
};

export const applyFilters = (items, filters, orArray = []) => {
  // The optional 'orArray' indicates fields that should be combined with or rather than and
  const filteredItems = items.filter(item => {
    let keepItem = true;
    const orFilters = [];
    filters.forEach(f => {
      if (orArray.includes(f.field)) {
        orFilters.push(f);
      } else if (!findValuesInItem(item, f.field, f.values)) {
        keepItem = false;
      }
    });
    let keepInput = !orFilters.length > 0;
    orFilters.forEach(f => {
      if (findValuesInItem(item, f.field, f.values)) {
        keepInput = true;
      }
    });
    return keepItem && keepInput;
  });
  return filteredItems;
};

// Alarm filters

export const listFilterPrecedenceArray = [
  'SourceTenant_Id',
  'LocationId',
  'CameraId',
  'Description',
];

export const chartFilterPrecedenceArray = [
  'locationIds',
  'cameraIds',
  'descriptions',
];
// Object to translate between alarms filters in state, filters for the chart data,
// and filters for the notifications list data
export const alarmFilterFieldsMap = [
  {
    stateFilterKey: 'status',
    listFilterField: 'Status',
    chartFilterField: 'status',
  },
  {
    stateFilterKey: 'cameraName',
    listFilterField: 'CameraId',
    chartFilterField: 'cameraIds',
    chartLabel: 'Camera',
  },
  {
    stateFilterKey: 'rule',
    listFilterField: 'Description',
    chartFilterField: 'descriptions',
    chartLabel: 'Rule',
  },
  {
    stateFilterKey: 'location',
    listFilterField: 'LocationId',
    chartFilterField: 'locationIds',
    chartLabel: 'Site',
    splitSingleSelectionInto: 'cameraIds',
  },
  {
    stateFilterKey: 'subscriber',
    listFilterField: 'SourceTenant_Id',
    chartFilterField: 'subscriberIds',
    chartLabel: 'Subscriber',
    splitSingleSelectionInto: 'locationIds',
  },
  {
    stateFilterKey: null,
    listFilterField: 'Created',
    chartFilterField: 'startRange',
  },
  {
    stateFilterKey: null,
    listFilterField: 'Created',
    chartFilterField: 'endRange',
  },
];

export const appliedAlarmFilter = currentFilters => {
  const listFilterToLabel = {};
  alarmFilterFieldsMap.forEach(item => {
    listFilterToLabel[item.listFilterField] = item.chartLabel
      ? item.chartLabel
      : item.listFilterField;
  });
  const precedenceArray = listFilterPrecedenceArray;
  let label = 'Site';
  precedenceArray.forEach(filterField => {
    if (currentFilters.find(f => f.field === filterField)) {
      label = listFilterToLabel[filterField];
    }
  });
  return label;
};
