import PropTypes from 'prop-types';
import React, { useMemo, useCallback, memo } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

import { ThemeProvider } from 'react-jss';

import cameraSiteSelectionSelector from 'selectors/cameraSiteSelection';
import focusedCameraIdSelector from 'selectors/cameraFocus';
import { SiteView } from '@avo-svtr/savitar';

function formatSite(site, translate) {
  const cameras =
    site.Cameras === undefined && site.cameras === undefined
      ? []
      : (site.Cameras || site.cameras).filter(device => device !== undefined);

  if (site.locationId) {
    // Device is in Blue's format
    return {
      id: site.locationId,
      name: site.locationName,
      timezone: site.locationTimeZone,
      cameras,
      clusters: site.clusters,
      messages: site.messages.map(m => translate(m)),
      children: site.children,
      type: site.type,
    };
  }
  // Site is in Savitar's format
  return {
    ...site,
    cameras,
  };
}

const SiteViewContainer = memo(props => {
  const {
    focusedCamera,
    isFetchingLocations,
    languageCode,
    removeCamera,
    selectCamera,
    selectionList,
    translate,
    view,
    siteViewTreeEnabled,
  } = props;

  const cameras = useMemo(
    () => selectionList.flatMap(d => d.cameras).filter(d => d !== undefined),
    [selectionList],
  );

  const visibleList = useMemo(() => {
    if (view.Elements && view.Elements.length > 0) {
      return view.Elements.reduce((acc, curr) => {
        if (curr) {
          acc.push(curr.CameraId);
        }
        return acc;
      }, []);
    }
    return [];
  }, [view.Elements]);

  const onSelect = useCallback(
    id => {
      if (!id) {
        return;
      }

      const camera = cameras.find(d => d.id === id);
      const { id: Id, remoteId: RemoteId, deviceId: DeviceId } = camera;
      selectCamera({
        Id,
        RemoteId,
        DeviceId,
      });
    },
    [selectCamera, cameras],
  );

  const onDelete = useCallback(
    id => {
      const camera = cameras.find(d => d.id === id);
      removeCamera(camera);
    },
    [removeCamera, cameras],
  );

  const customTheme = {};

  const formattedSites = useMemo(() => {
    return selectionList.map(site => formatSite(site, translate));
  }, [selectionList, translate]);

  const SiteViewProps = {
    focusedCamera: focusedCamera ? focusedCamera.CameraId : null,
    isLoading: isFetchingLocations,
    languageCode,
    visibleCameras: visibleList,
    sites: formattedSites,
    onDelete,
    onSelect,
    type: siteViewTreeEnabled ? 'tree' : 'list',
  };

  return (
    <ThemeProvider theme={customTheme}>
      <SiteView {...SiteViewProps} />
    </ThemeProvider>
  );
});

SiteViewContainer.propTypes = {
  focusedCamera: PropTypes.shape({}),
  isFetchingLocations: PropTypes.bool,
  languageCode: PropTypes.string,
  removeCamera: PropTypes.func.isRequired,
  selectCamera: PropTypes.func.isRequired,
  selectionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  view: PropTypes.shape({}).isRequired,
  siteViewTreeEnabled: PropTypes.bool,
};

SiteViewContainer.defaultProps = {
  focusedCamera: {},
  isFetchingLocations: null,
  languageCode: 'en-US',
};

function mapStateToProps(state) {
  const selectionList = cameraSiteSelectionSelector(state);
  return {
    focusedCamera: focusedCameraIdSelector(state),
    isFetchingLocations: state.isFetching.getLocations,
    selectionList,
    view: state.views.currentView,
  };
}

export default connect(mapStateToProps)(withLocalize(SiteViewContainer));
