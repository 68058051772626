import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from '../constants/app';

const IconNavBookmarks = ({ className, fill, height, width }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <g>
        <g>
          <polygon points="6 23 12 18 18 23 18 1 6 1 6 23" />
        </g>
      </g>
    </svg>
  );
};

IconNavBookmarks.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

IconNavBookmarks.defaultProps = {
  className: null,
  fill: '#000',
  height: '32px',
  width: '38px',
};

export default IconNavBookmarks;
