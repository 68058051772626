import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function viewReducer(state, action = {}) {
  switch (action.type) {
    case types.RECEIVE_VIEWS: {
      // api/Views/id returns obj w/ ID attr, api/Views returns list w/ ViewId, so ViewId => Id
      const viewsNormalized = action.views.map(view => {
        const { ViewId, ...viewData } = view;
        return {
          Id: ViewId,
          ...viewData,
        };
      });
      return Object.assign({}, state, {
        views: viewsNormalized,
        isFetchingViews: false,
      });
    }

    case types.IS_FETCHING_VIEWS: {
      return Object.assign({}, state, {
        isFetchingViews: true,
      });
    }

    case types.RECEIVE_ACC_SAVED_VIEWS: {
      const accSavedViewsNormalized = action.accSavedViews.savedViews.map(
        view => {
          const { id, ...viewData } = view;
          return {
            Id: id,
            ...viewData,
          };
        },
      );

      const accSavedViewLocation = {
        locationId: action.locationId,
        views: accSavedViewsNormalized,
      };

      const cachedLocations = state.accSavedViews.locations
        ? state.accSavedViews.locations
        : [];
      const itemIndex = cachedLocations.findIndex(
        location => location.locationId === accSavedViewLocation.locationId,
      );
      const isExistingLocation = itemIndex >= 0;
      if (isExistingLocation) {
        cachedLocations[itemIndex] = accSavedViewLocation;
      } else {
        cachedLocations.push(accSavedViewLocation);
      }

      return Object.assign({}, state, {
        accSavedViews: {
          locations: cachedLocations,
        },
      });
    }

    case types.SET_CURRENT_PANEL_ID: {
      return Object.assign({}, state, {
        panelId: action.id,
      });
    }

    case types.RECEIVE_VIEW: {
      return Object.assign({}, state, {
        currentView: action.view,
        currentViewTurnStatus: {},
        resumeStreamStartTimes: {},
      });
    }

    case types.SET_CURRENT_VIEW_CAMERA: {
      const { panelId } = state;
      if (panelId !== null && panelId !== undefined) {
        const viewElements = state.currentView.Elements
          ? state.currentView.Elements.slice()
          : [];
        let { recordedVideo } = state;
        // Start by clearing recordedVideo if necessary
        if (recordedVideo.position === panelId) {
          recordedVideo = {};
        }
        // There is no guarantee that position in array will correspond to params.Position
        const index = viewElements.findIndex(
          element => element && element.Position === panelId,
        );
        if (!action.params.CameraId) {
          viewElements.splice(index, 1);
        } else if (index < 0) {
          viewElements.push({ ...action.params, Position: panelId });
        } else {
          viewElements[index] = { ...action.params, Position: panelId };
        }
        const current = {
          ...state.currentView,
          Elements: viewElements,
          layoutIsDirty: true,
        };
        return {
          ...state,
          currentView: current,
          recordedVideo,
        };
      }
      return state;
    }

    case types.TOGGLE_SYNC_VIDEO: {
      return Object.assign({}, state, {
        syncVideo: !state.syncVideo,
      });
    }

    case types.SET_SYNCED_START_TIME: {
      return Object.assign({}, state, {
        syncedStartTime: action.startTime,
      });
    }

    case types.CLEAR_SYNCED_START_TIME: {
      return Object.assign({}, state, {
        syncedStartTime: null,
      });
    }

    case types.EDIT_CURRENT_VIEW: {
      const current = Object.assign({}, state.currentView, action.view);
      return Object.assign({}, state, { currentView: current });
    }

    case types.DELETE_VIEW: {
      // delete matching item in list  so it wont be set as current vw
      const id = state.currentView.Id;
      const ix = state.views.findIndex(view => view.Id === id);
      const views = state.views.slice(0, ix).concat(state.views.slice(ix + 1));
      return Object.assign({}, state, {
        currentView: initialState().views.currentView,
        currentViewTurnStatus: {},
        resumeStreamStartTimes: {},
        views,
      });
    }

    case types.MAXIMIZE_VIEW: {
      return Object.assign({}, state, {
        isCurrentViewMaximized: action.isMaximized,
      });
    }
    // if there are multiple tiles open, need to track which tile opened the panel, or if the user clicked the recorded video button
    // in another tile, the recorded video should apply to that tile
    case types.RECORDED_VIDEO_REQUESTED: {
      const recordedVideo = { position: action.position };
      return Object.assign({}, state, { recordedVideo });
    }

    case types.RECORDED_VIDEO_DATE: {
      const recordedVideo = Object.assign({}, state.recordedVideo);
      recordedVideo.date = action.date;
      return Object.assign({}, state, { recordedVideo });
    }

    case types.SET_TURN_STREAMS_PAUSED: {
      return Object.assign({}, state, {
        turnStreamsPaused: action.turnStreamsPaused,
      });
    }
    case types.SET_ELEMENT_TURN_STATUS: {
      const { position, status } = action;
      const { currentViewTurnStatus: oldTurnStatus } = state;
      let currentViewTurnStatus = {};
      if (status === undefined) {
        Object.keys(oldTurnStatus).forEach(prevPosition => {
          if (prevPosition !== position.toString()) {
            currentViewTurnStatus[prevPosition] = oldTurnStatus[prevPosition];
          }
        });
      } else {
        currentViewTurnStatus = {
          ...oldTurnStatus,
          [position.toString()]: status,
        };
      }
      return Object.assign({}, state, { currentViewTurnStatus });
    }

    case types.SET_RESUME_STREAM_START_TIME: {
      const { position, startTime } = action;
      const resumeStreamStartTimes = { ...state.resumeStreamStartTimes };
      if (startTime) {
        resumeStreamStartTimes[position] = startTime;
      } else {
        delete resumeStreamStartTimes[position];
      }
      return Object.assign({}, state, { resumeStreamStartTimes });
    }

    case types.VIEW_MODAL_HIDE:
    case types.VIEW_MODAL_SHOW: {
      return Object.assign({}, state, {
        viewModal: action.type,
        viewModalOperation: action.operation,
      });
    }
    // clear cache when user logs out or changes org/customer
    case types.UNSET_USER:
    case types.RESET_USER_CONTEXT: {
      return initialState().views;
    }

    default:
      return state || initialState().views;
  }
}
