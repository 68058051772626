exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".wrapper___7Kw6s {\r\n  padding: 20px;\r\n}\r\n\r\n.inactivityInfo___1bMK4 {\r\n  margin-top: 20px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.centerContent___3Ci8r {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n}\r\n\r\n.titleWrap___2m-L9 {\r\n  font-weight: 700;\r\n  font-size: 15px;\r\n}", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/Views/InactivityPrompt.styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;;AAED;EACE,iBAAiB;EACjB,oBAAoB;CACrB;;AAED;EACE,qBAAqB;EACrB,cAAc;EACd,sBAAsB;MAClB,wBAAwB;CAC7B;;AAED;EACE,iBAAiB;EACjB,gBAAgB;CACjB","file":"InactivityPrompt.styles.css","sourcesContent":[".wrapper {\r\n  padding: 20px;\r\n}\r\n\r\n.inactivityInfo {\r\n  margin-top: 20px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.centerContent {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n}\r\n\r\n.titleWrap {\r\n  font-weight: 700;\r\n  font-size: 15px;\r\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "wrapper___7Kw6s",
	"inactivityInfo": "inactivityInfo___1bMK4",
	"centerContent": "centerContent___3Ci8r",
	"titleWrap": "titleWrap___2m-L9"
};