// Libs
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Select from 'react-select';

// Constants
import { INTEGRATION_TYPES, SELECT_STYLE } from 'constants/app';
import {
  CENTRAL_STATION_DROP_DOWN_ID,
  SECURITY_MONITORING_CHECKBOX,
  SERVER_ID_INPUT,
} from 'constants/ElementId';
import { IC_ARROW_DOWN, IC_ARROW_UP } from 'constants/iconNames';

// Components
import { PlainCheckBox } from 'components';
import { CopyableInput, Icon } from 'lib';
import { Translate } from 'react-localize-redux';
import CopyCameraInfoButtonContainer from './CopyCameraInfoButtonContainer';

// Styles
import {
  cellLabel,
  centralStation,
  configurationStringInput,
  selectIcon,
  siteIdCopyInput,
  siteIdCopyInputValue,
} from './styles.css';

class IntegrationSiteItem extends PureComponent {
  constructor(props) {
    super(props);
    this.originalSite = props.site;
  }

  componentWillUnmount() {
    const {
      handleChange,
      toggleSecurityMonitoring,
      updateMonitoringCenter,
      updateSiteConfigurationString,
    } = this.props;

    updateMonitoringCenter(
      this.siteId,
      this.originalSite.IntegrationConfigurationId,
    );
    toggleSecurityMonitoring(
      this.siteId,
      this.originalSite.SecurityEventDealerMonitoringEnabled,
    );
    updateSiteConfigurationString(
      this.siteId,
      this.originalSite.ConfigurationString,
    );
    handleChange(false);
  }

  get integrationConfigOptions() {
    const { integrationConfigs } = this.props;
    const formattedOptions = integrationConfigs.map(config => {
      return {
        label: config.Name,
        type: config.IntegrationType,
        value: config.IntegrationConfigurationId,
      };
    });

    return [
      {
        label: (
          <Translate id="INTEGRATIONS.MONITORING_CENTER_SELECTOR_PLACEHOLDER" />
        ),
        value: null,
      },
      ...formattedOptions,
    ];
  }

  get siteId() {
    const { site } = this.props;
    return site.SiteId;
  }

  get fieldValue() {
    const { fieldName, site } = this.props;
    const value = site[fieldName];
    const selectedConfig = this.integrationConfigOptions.find(
      config => config.value === site.IntegrationConfigurationId,
    );
    if (
      fieldName === 'SecurityEventDealerMonitoringEnabled' &&
      selectedConfig.type
    ) {
      return (
        <PlainCheckBox
          key={`toggle${this.siteId}`}
          checked={value}
          disabled={site.IntegrationConfigurationId === null}
          id={SECURITY_MONITORING_CHECKBOX}
          onChange={this.handleToggleChange}
        />
      );
    }
    if (
      fieldName === 'SiteId' &&
      selectedConfig.type === INTEGRATION_TYPES.SUREVIEW
    ) {
      return (
        <>
          <div className={cellLabel}>
            <Translate id="INTEGRATIONS.SITE_HEADER_LABELS.SITE_ID" />:
          </div>
          <CopyableInput
            key={`copyInput${this.siteId}`}
            disabled={!site.IntegrationConfigurationId}
            inputClassName={siteIdCopyInput}
            inputValueClassName={siteIdCopyInputValue}
            value={value}
          />
        </>
      );
    }
    if (
      fieldName === 'ConfigurationString' &&
      selectedConfig.type === INTEGRATION_TYPES.SUREVIEW
    ) {
      return (
        <>
          <div className={cellLabel}>
            <Translate id="INTEGRATIONS.SITE_HEADER_LABELS.CONFIGURATION_STRING" />
            :
          </div>
          <input
            key={`configStringinput${this.siteId}`}
            className={configurationStringInput}
            disabled={site.IntegrationConfigurationId === null}
            id={SERVER_ID_INPUT}
            onChange={this.handleSiteConfigurationStringChange}
            style={{ textAlign: 'center' }}
            value={site.ConfigurationString || ''}
          />
        </>
      );
    }
    if (fieldName === 'MonitoringCenter') {
      return (
        <Translate>
          {({ translate }) => (
            <div className={centralStation} id={CENTRAL_STATION_DROP_DOWN_ID}>
              <Select
                key={`selectedConfig${this.siteId}`}
                components={{
                  DropdownIndicator: props => (
                    <Icon
                      iconClass={selectIcon}
                      id={
                        props.selectProps.menuIsOpen
                          ? IC_ARROW_UP
                          : IC_ARROW_DOWN
                      }
                      size="13"
                    />
                  ),
                }}
                defaultValue={selectedConfig || ''}
                menuPortalTarget={document.getElementById('SelectPortal')}
                onChange={this.handleMonitoringCenterChange}
                options={this.integrationConfigOptions}
                placeholder={translate(
                  'INTEGRATIONS.MONITORING_CENTER_SELECTOR_PLACEHOLDER',
                )}
                style={SELECT_STYLE}
                styles={{
                  control: styles => ({
                    ...styles,
                    '&:active': {
                      borderColor: '#007aaa',
                    },
                    '&:hover': {
                      backgroundColor: 'var(--input-hover)',
                    },
                    boxShadow: 'none',
                  }),
                  dropdownIndicator: styles => ({
                    ...styles,
                    color: '#000',
                    marginRight: '15px',
                    paddingRight: '15px',
                  }),
                  indicatorSeparator: styles => ({
                    ...styles,
                    display: 'none',
                  }),
                  menuPortal: styles => ({ ...styles, zIndex: 1100 }),
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'var(--focus-primary)',
                    primary25: 'var(--primary-20)',
                  },
                })}
              />
            </div>
          )}
        </Translate>
      );
    }
    if (fieldName === 'copyCameraInfoButton' && selectedConfig.type) {
      return selectedConfig.type !== INTEGRATION_TYPES.WEBHOOKS ? (
        <CopyCameraInfoButtonContainer
          fieldName={fieldName}
          locationId={this.siteId}
          tenantId={site.TenantId}
        />
      ) : null;
    }
    return '';
  }

  handleToggleChange = async newStatus => {
    const { toggleSecurityMonitoring } = this.props;

    await toggleSecurityMonitoring(this.siteId, newStatus);
    this.didFieldsChange();
  };

  handleSiteConfigurationStringChange = async event => {
    const { updateSiteConfigurationString } = this.props;
    const newNumber = event.target.value;
    await updateSiteConfigurationString(this.siteId, newNumber);
    this.didFieldsChange();
  };

  handleMonitoringCenterChange = async event => {
    const newCenter = event.value;
    const { updateMonitoringCenter } = this.props;
    await updateMonitoringCenter(this.siteId, newCenter);
    this.didFieldsChange();
  };

  didFieldsChange = () => {
    const { handleChange, site } = this.props;
    const selectedConfig = this.integrationConfigOptions.find(
      config => config.value === site.IntegrationConfigurationId,
    );

    let didFieldChange = false;

    if (
      selectedConfig.type !== INTEGRATION_TYPES.WEBHOOKS &&
      selectedConfig.type !== undefined
    ) {
      didFieldChange = !site.ConfigurationString
        ? false
        : site.ConfigurationString !== this.originalSite.ConfigurationString ||
          site.SecurityEventDealerMonitoringEnabled !==
            this.originalSite.SecurityEventDealerMonitoringEnabled ||
          site.IntegrationConfigurationId !==
            this.originalSite.IntegrationConfigurationId;
    } else {
      didFieldChange =
        site.SecurityEventDealerMonitoringEnabled !==
          this.originalSite.SecurityEventDealerMonitoringEnabled ||
        site.IntegrationConfigurationId !==
          this.originalSite.IntegrationConfigurationId;
    }

    handleChange(didFieldChange);
  };

  render() {
    return this.fieldValue;
  }
}

IntegrationSiteItem.defaultProps = {
  updateMonitoringCenter() {},
};
IntegrationSiteItem.propTypes = {
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  integrationConfigs: PropTypes.arrayOf(PropTypes.any).isRequired,
  site: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleSecurityMonitoring: PropTypes.func.isRequired,
  updateMonitoringCenter: PropTypes.func,
  updateSiteConfigurationString: PropTypes.func.isRequired,
};

export default IntegrationSiteItem;
