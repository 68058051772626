import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { EmptyPlaceholder } from 'lib';
import {
  cardBody,
  cardHeader,
  cardHorizontalDividerLine,
  cardNumber,
  cardSymbol,
  cardSymbols,
  dashboardCard,
  dashboardSpinner,
  gray,
  green,
  red,
  smallCard,
} from './dashboard.styles.css';

const SmallCard = ({ data, isFetching, translateKeyLabel }) => (
  <div className={`${dashboardCard} ${smallCard}`}>
    <EmptyPlaceholder
      isFetching={isFetching}
      items={[data]}
      overrideClassName={dashboardSpinner}
      size={60}
    >
      <h3 className={cardHeader}>
        <Translate id={translateKeyLabel} />
      </h3>
      <div className={cardBody}>
        <div className={cardNumber}>{data.Count}</div>
        <div className={cardSymbols}>
          <span
            key="up"
            className={`${cardSymbol} ${green} ${
              data.NumberAdded === 0 ? gray : ''
            }`}
          >
            {data.NumberAdded}
          </span>
          <div className={cardHorizontalDividerLine} />
          <span
            key="down"
            className={`${cardSymbol} ${red} ${
              data.NumberDeleted === 0 ? gray : ''
            }`}
          >
            {data.NumberDeleted}
          </span>
        </div>
      </div>
    </EmptyPlaceholder>
  </div>
);

SmallCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  isFetching: PropTypes.bool,
  translateKeyLabel: PropTypes.string.isRequired,
};

SmallCard.defaultProps = {
  data: {},
  isFetching: false,
};

export default SmallCard;
