import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';

export default function isFetchingReducer(state, action = {}) {
  switch (action.type) {
    case types.IS_FETCHING: {
      let { value } = action;
      if (action.fetchScope) {
        value = Object.assign({}, state[action.fetchType], {
          [action.fetchScope]: action.value,
        });
      }
      return Object.assign({}, state, {
        [action.fetchType]: value,
      });
    }

    case types.RESET_IS_FETCHING: {
      return {
        ...state,
        ...{
          [action.fetchType]: null,
        },
      };
    }

    case types.CHANGE_CUSTOMER_CONTEXT:
    case types.RESET_USER_CONTEXT: {
      const persistentFields = {
        getLinks: state.getLinks,
        getUserProfile: state.getUserProfile,
        getAiKey: state.getAiKey,
        getRegionalDeployment: state.getRegionalDeployment,
      };
      return Object.assign({}, initialState().isFetching, persistentFields);
    }

    default:
      return state || initialState().isFetching;
  }
}
