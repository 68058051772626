import { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilterableContainer extends Component {
  getFilterValue(fieldName, valueType = 'string') {
    let value;
    const { filterGroup, filterStatus } = this.props;
    const activeFilterSet =
      filterStatus &&
      Reflect.has(filterStatus, filterGroup) &&
      filterStatus[filterGroup];

    if (activeFilterSet) {
      const statusForField = activeFilterSet.find(
        filter => filter.field === fieldName,
      );
      if (statusForField) {
        value = statusForField.values;
        return valueType === 'string' ? value.join('') : value;
      }
      return null;
    }
    return null;
  }
}

FilterableContainer.propTypes = {
  filterGroup: PropTypes.string.isRequired,
  filterStatus: PropTypes.objectOf(PropTypes.array).isRequired,
};
