import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconArrow = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 32 32"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <defs>
        <rect
          id="path-1"
          x="0"
          y="0"
          width={props.width}
          height={props.height}
          rx="4"
        />
      </defs>
      <g
        id="view-live-panel-redlines"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="view-live-panel" transform="translate(-354.000000, -168.000000)">
          <g id="Group-6" transform="translate(63.000000, 48.000000)">
            <g id="Group-2" transform="translate(291.000000, 120.000000)">
              <g id="Rectangle-30">
                <use fill="#D2EBFF" fillRule="evenodd" />
                <rect
                  stroke="#5DB6FF"
                  strokeWidth="1"
                  x="0.5"
                  y="0.5"
                  width="29"
                  height="29"
                  rx="4"
                />
              </g>
              <path
                d="M19.4344012,16.0216884 C19.4148465,16.022623 19.3951707,16.0230956 19.3753845,16.0230956 L7.21594404,16.0230956 C6.54439669,16.0230956 6,15.4786989 6,14.8071515 C6,14.1356042 6.54439669,13.5912075 7.21594404,13.5912075 L7.21594404,13.5912075 L19.2770386,13.5912075 L15.3222377,9.63640649 C14.847382,9.16155081 14.847382,8.39165762 15.3222377,7.91680194 L15.3222377,7.91680194 L15.3222377,7.91680194 C15.7970933,7.44194625 16.5669865,7.44194625 17.0418422,7.91680194 L17.0418422,7.91680194 L22.7738574,13.6488171 L22.7738574,13.6488171 C23.1083216,13.9832813 23.2072062,14.464116 23.0705112,14.8851293 C23.2072062,15.3061425 23.1083216,15.7869772 22.7738574,16.1214414 L22.7738574,16.1214414 L17.0418422,21.8534566 C16.5669865,22.3283123 15.7970933,22.3283123 15.3222377,21.8534566 C14.847382,21.3786009 14.847382,20.6087077 15.3222377,20.133852 L19.4344012,16.0216884 Z"
                id="Combined-Shape"
                fill="#006e99"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconArrow.defaultProps = {
  className: null,
  width: '30px',
  height: '32px',
};

export default IconArrow;
