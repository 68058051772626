import React from 'react';
import PropTypes from 'prop-types';
import { SVG_XMLNS } from '../constants/app';

const IconNavOrgMgmt = ({ className, fill, height, width }) => {
  return (
    <svg
      className={className}
      fill={fill}
      height={height}
      viewBox="0 0 24 24"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <path d="M18,5V3h1V2H5V3H6V5H2V22H9V19h6v3h7V5ZM7,17H4V15H7Zm0-4H4V11H7ZM7,9H4V7H7Zm4,8H9V15h2Zm0-4H9V11h2Zm0-4H9V7h2Zm4,8H13V15h2Zm0-4H13V11h2Zm0-4H13V7h2Zm5,8H17V15h3Zm0-4H17V11h3Zm0-4H17V7h3Z" />
    </svg>
  );
};
IconNavOrgMgmt.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
IconNavOrgMgmt.defaultProps = {
  className: null,
  fill: '#939597',
  height: '33px',
  width: '40px',
};

export default IconNavOrgMgmt;
