import React from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_COLOR_DEFAULT, SVG_XMLNS } from 'constants/app';
import { withLocalize } from 'react-localize-redux';
import { DELETE_ICON_ID } from '../constants/ElementId';

const IconDelete = ({ className, fill, height, title, translate, width }) => {
  return (
    <div className={className} id={DELETE_ICON_ID} title={translate(title)}>
      <svg
        className={className}
        height={height}
        version="1.1"
        viewBox="0 0 24 24"
        width={width}
        xmlns={SVG_XMLNS}
      >
        <g
          fill="none"
          fillRule="evenodd"
          id="Page-1"
          stroke="none"
          strokeWidth="1"
        >
          <g fill={fill} id="icon-trash-can">
            <polygon points="15 3 15 2 9 2 9 3 4 3 4 5 20 5 20 3 15 3" />
            <path d="M17.5,18h-2L16,6H13V19H11V6H8l.5,12h-2L5.9,6H4l.75,13.39A2.62,2.62,0,0,0,7.36,22h9.28a2.62,2.62,0,0,0,2.61-2.61L20,6H18.1Z" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconDelete.defaultProps = {
  className: '',
  fill: ACTIVE_COLOR_DEFAULT,
  height: '24px',
  title: 'COMMON.REMOVE',
  width: '24px',
};

IconDelete.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconDelete);
