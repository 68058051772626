import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconCaretUp = props => {
  return (
    <svg
      xmlns={SVG_XMLNS}
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
    >
      <polyline
        fill={props.fill}
        className={props.className}
        points={props.points}
        transform={props.transform}
      />
    </svg>
  );
};

IconCaretUp.defaultProps = {
  className: null,
  width: '12px',
  height: '8px',
  viewBox: '0 0 12 8',
  fill: 'none',
  points: '0 0 5 5 10 0',
  transform: 'rotate(180 4.5 2.5)',
};

export default IconCaretUp;
