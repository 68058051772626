exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/styles.css"), undefined);

// module
exports.push([module.id, ".actionButton___3svh5 {\r\n  background-color: transparent;\r\n  border: none;\r\n  box-shadow: none;\r\n  position: inherit;\r\n  cursor: pointer;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  padding: 1px 5px;\r\n}\r\n\r\n.actionButton___3svh5 .centerButton___1TSft {\r\n  margin-left: 3px;\r\n  padding: 1px 2px;\r\n}\r\n\r\n.suspendIcon___3JMXt {\r\n  padding-top: 3px !important;\r\n  padding-right: 3px !important;\r\n}\r\n\r\n.suspendIconSvg___30LLf {\r\n  height: 27px;\r\n  width: 27px;\r\n}\r\n\r\n.unsuspendIcon___1Hmmc {\r\n  padding-top: 2px !important;\r\n  padding-right: 2px !important;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/lib/ListNav/NavControls/NavControls.css"],"names":[],"mappings":"AAAA;EAEE,8BAA8B;EAC9B,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,4BAA4B;EAC5B,qBAAqB;EACrB,sBAAsB;MAClB,wBAAwB;EAC5B,iBAAiB;CAClB;;AAED;EACE,iBAAiB;EACjB,iBAAiB;CAClB;;AAED;EACE,4BAA4B;EAC5B,8BAA8B;CAC/B;;AAED;EACE,aAAa;EACb,YAAY;CACb;;AAED;EACE,4BAA4B;EAC5B,8BAA8B;CAC/B","file":"NavControls.css","sourcesContent":[".actionButton {\r\n  composes: listNavButton from '../../../sharedStyles/styles.css';\r\n  background-color: transparent;\r\n  border: none;\r\n  box-shadow: none;\r\n  position: inherit;\r\n  cursor: pointer;\r\n  display: -ms-inline-flexbox;\r\n  display: inline-flex;\r\n  -ms-flex-pack: center;\r\n      justify-content: center;\r\n  padding: 1px 5px;\r\n}\r\n\r\n.actionButton .centerButton {\r\n  margin-left: 3px;\r\n  padding: 1px 2px;\r\n}\r\n\r\n.suspendIcon {\r\n  padding-top: 3px !important;\r\n  padding-right: 3px !important;\r\n}\r\n\r\n.suspendIconSvg {\r\n  height: 27px;\r\n  width: 27px;\r\n}\r\n\r\n.unsuspendIcon {\r\n  padding-top: 2px !important;\r\n  padding-right: 2px !important;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"actionButton": "actionButton___3svh5 " + require("-!../../../../node_modules/css-loader/index.js??ref--6-1!../../../sharedStyles/styles.css").locals["listNavButton"] + "",
	"centerButton": "centerButton___1TSft",
	"suspendIcon": "suspendIcon___3JMXt",
	"suspendIconSvg": "suspendIconSvg___30LLf",
	"unsuspendIcon": "unsuspendIcon___1Hmmc"
};