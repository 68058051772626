import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

// Components
import { ListView } from 'lib';

// Utilities
import { localizeSchedules } from 'util/localizeSeededSchedules';

// Styles
import { formBox, medFormField } from 'sharedStyles/modalForms.css';

// Constants
import {
  OGTABLE_PADDING,
  OGTABLE_THEAD_HEIGHT,
  SEARCH_BAR_HEIGHT,
} from 'constants/ElementSize';
import { BULK_ACTION_TABLES } from 'constants/app';
import { ROLES } from 'util/userRoles';
import * as consts from './constants';
import {
  allCheck,
  checkmark,
  rowHasPolicy,
  semiCheck,
  someChecked,
  threeStateCheck,
} from './UserForm.styles.css';

// Custom functions
const getAccessTableHeight = containerEl => {
  let height = consts.ACCESS_TABLE_MIN_HEIGHT;
  if (containerEl) {
    height =
      containerEl.clientHeight -
      (consts.ACCESS_TABLE_TITLE_HEIGHT +
        OGTABLE_PADDING +
        OGTABLE_THEAD_HEIGHT +
        SEARCH_BAR_HEIGHT);
  }
  return height;
};

const UserAccessTable = ({
  canAccessUserRoles,
  canEditProxySiteAccess,
  change,
  isPrimaryAdmin,
  isProxyUser,
  locations: rawLocations,
  policies,
  role,
  schedules,
  translate,
}) => {
  const containerRef = useRef(document.getElementsByClassName(formBox)[0]);

  const tableHeight = useMemo(() => getAccessTableHeight(containerRef), [
    containerRef,
  ]);

  const locations = useMemo(() => {
    return rawLocations.map(loc => {
      const policyIndex =
        policies && policies.findIndex(p => p.GroupId === loc.Id);
      const hasPolicy = policyIndex >= 0;
      const location = { ...loc };
      if (hasPolicy) {
        location.Policy = {
          ...policies[policyIndex],
        };
        location.hasPolicy = true;
      } else {
        location.Policy = null;
        location.hasPolicy = false;
      }
      return {
        ...location,
        schedules,
      };
    });
  }, [policies, schedules, rawLocations]);

  const defaultSchedule = useMemo(() => {
    const schedule =
      role === ROLES.Viewer || isProxyUser
        ? schedules.find(sch => sch.Name === consts.SCHEDULES.NEVER)
        : schedules.find(sch => sch.Name === consts.SCHEDULES.ALWAYS);
    return schedule;
  }, [isProxyUser, role, schedules]);

  useEffect(() => {
    if (role === ROLES.Viewer) {
      // Reset policies to never if the user becomes a viewer
      const locationsWithPolicy = locations.filter(loc => loc.hasPolicy);
      const neverPolicy = schedules.find(
        sch => sch.Name === consts.SCHEDULES.NEVER,
      );
      const neverPolicyId = neverPolicy && neverPolicy.Id;
      if (neverPolicyId) {
        locationsWithPolicy.forEach(location => {
          const policyIndex = policies.findIndex(
            policy => policy.GroupId === location.Id,
          );
          change(
            `Policies[${policyIndex}].SecurityScheduleId`,
            neverPolicy && neverPolicy.Id,
          );
          change(
            `Policies[${policyIndex}].HealthScheduleId`,
            neverPolicy && neverPolicy.Id,
          );
        });
      }
    }
  }, [role, change, locations, schedules, policies]);

  const renderScheduleOptions = useCallback(
    (type, location, onChange) => {
      const sch = location.schedules;
      const policy = location.Policy;
      const isViewer = role === ROLES.Viewer;
      const hasAccess = location.hasPolicy;
      const policyIndex = policies.findIndex(p => p.GroupId === location.Id);
      const isReadOnly =
        policies[policyIndex] && policies[policyIndex].ReadOnly;
      const isDisabled =
        !canAccessUserRoles ||
        isProxyUser ||
        isViewer ||
        !hasAccess ||
        isReadOnly;
      const selectedPolicyID = isViewer
        ? sch.find(s => s.Name === consts.SCHEDULES.NEVER).Id
        : policy && policy[`${type}ScheduleId`];
      const options = localizeSchedules(sch, translate).map(item => (
        <option value={`${item.Id}`}>{item.Name}</option>
      ));
      const defaultOption = (
        <option>{translate('SCHEDULES.SELECT_ALWAYS_OPTION')}</option>
      );
      return (
        <select
          className={medFormField}
          disabled={isDisabled}
          name={`Policies[${policyIndex}].${type}ScheduleId`}
          onChange={event => onChange(location, event)}
          value={selectedPolicyID}
        >
          {hasAccess ? options : defaultOption}
        </select>
      );
    },
    [canAccessUserRoles, isProxyUser, policies, role, translate],
  );

  const renderLocationCheckbox = useCallback(
    (location, callback) => {
      const elName = `${location.Id}`;
      const isReadOnlyPolicy = location.Policy && location.Policy.ReadOnly;
      const isDisabled =
        isPrimaryAdmin ||
        (isProxyUser && !canEditProxySiteAccess) ||
        (isProxyUser && isReadOnlyPolicy);

      return (
        <input
          checked={location.hasPolicy}
          disabled={isDisabled}
          name={elName}
          onChange={e => callback(e, location)}
          type="checkbox"
        />
      );
    },
    [canEditProxySiteAccess, isPrimaryAdmin, isProxyUser],
  );

  const getLocationPolicy = useCallback(
    location => {
      if (location.hasPolicy) return location.Policy;
      return {
        GroupId: location.Id,
        HealthScheduleId: defaultSchedule && defaultSchedule.Id,
        SecurityScheduleId: defaultSchedule && defaultSchedule.Id,
      };
    },
    [defaultSchedule],
  );

  const setLocationCheckbox = useCallback(
    (e, location) => {
      const isSelected = e.target.checked;
      let policyIndex = policies.findIndex(p => p.GroupId === location.Id); // replace current policy
      if (policyIndex < 0) {
        policyIndex = policies.findIndex(p => p.GroupId === undefined); // if no current policy, replace empty policy
      }
      if (policyIndex < 0) {
        policyIndex = policies.length; // if no current or empty policies, add new policy
      }
      if (!isSelected) {
        change(`Policies[${policyIndex}]`, {});
      } else {
        change(`Policies[${policyIndex}]`, getLocationPolicy(location));
      }
    },
    [change, getLocationPolicy, policies],
  );

  const setMultiLocationCheckbox = useCallback(
    isChecked => {
      locations.forEach((location, locationIndex) => {
        const isReadOnly = location.Policy && location.Policy.ReadOnly;
        if (!isReadOnly) {
          if (isChecked) {
            change(`Policies[${locationIndex}]`, getLocationPolicy(location));
          } else {
            change(`Policies[${locationIndex}]`, {});
          }
        } else {
          change(`Policies[${locationIndex}]`, getLocationPolicy(location));
        }
      });
    },
    [change, getLocationPolicy, locations],
  );

  const multiCheckboxClickHandler = useMemo(() => {
    const checkboxClickHandler = e => {
      const isChecked = e.target.checked;
      setMultiLocationCheckbox(isChecked);
    };
    return checkboxClickHandler;
  }, [setMultiLocationCheckbox]);

  const customCells = useMemo(() => {
    return {
      HealthNotification: location =>
        renderScheduleOptions('Health', location, (loc, event) => {
          const policyIndex = policies.findIndex(
            policy => policy.GroupId === loc.Id,
          );
          const el = event.target;
          const selectedValue = el.children[el.selectedIndex].value;
          change(`Policies[${policyIndex}].HealthScheduleId`, selectedValue);
        }),
      LocationSelector: location =>
        renderLocationCheckbox(location, setLocationCheckbox),
      SecurityNotification: location =>
        renderScheduleOptions('Security', location, (loc, event) => {
          const el = event.target;
          const selectedValue = el.children[el.selectedIndex].value;
          const policyIndex = policies.findIndex(
            policy => policy.GroupId === loc.Id,
          );
          change(`Policies[${policyIndex}].SecurityScheduleId`, selectedValue);
        }),
    };
  }, [
    change,
    renderScheduleOptions,
    policies,
    renderLocationCheckbox,
    setLocationCheckbox,
  ]);

  const customHeader = useMemo(() => {
    const isDisabled =
      isPrimaryAdmin || (isProxyUser && !canEditProxySiteAccess);

    const locationHasPolicy = location => location.hasPolicy;
    const allHavePolicy = locations.every(locationHasPolicy);
    const someHavePolicy = locations.some(locationHasPolicy);

    let checkmarkClass;
    if (allHavePolicy) {
      checkmarkClass = allCheck;
    } else if (someHavePolicy) {
      checkmarkClass = semiCheck;
    } else {
      checkmarkClass = checkmark;
    }

    return {
      LocationSelector: (
        <label className={threeStateCheck}>
          <input
            checked={allHavePolicy}
            className={someHavePolicy ? someChecked : ''}
            disabled={isDisabled}
            onChange={multiCheckboxClickHandler}
            type="checkbox"
          />
          <span className={checkmarkClass} />
        </label>
      ),
    };
  }, [
    canEditProxySiteAccess,
    isPrimaryAdmin,
    isProxyUser,
    locations,
    multiCheckboxClickHandler,
  ]);

  return (
    <ListView
      bodyHeight={tableHeight}
      bulkActions={false}
      bulkActionsTable={BULK_ACTION_TABLES.USER_SITE_ACCESS}
      cellWidths={{ LocationSelector: 50 }}
      customCells={customCells}
      customHeader={customHeader}
      customRowClass={rowHasPolicy}
      customRowTrigger={{ hasPolicy: true }}
      data={locations}
      fieldOrder={[
        'LocationSelector',
        'Name',
        'SecurityNotification',
        'HealthNotification',
      ]}
      headerTranslationIds={consts.ACCESS_TABLE.headerTranslationIds}
      resizable={false}
      showTitle={false}
      sortType="local"
    />
  );
};

UserAccessTable.propTypes = {
  canAccessUserRoles: PropTypes.bool,
  canEditProxySiteAccess: PropTypes.bool,
  change: PropTypes.func.isRequired, // from redux form
  isPrimaryAdmin: PropTypes.bool,
  isProxyUser: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.object),
  policies: PropTypes.arrayOf(PropTypes.object),
  role: PropTypes.string.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func.isRequired,
};

UserAccessTable.defaultProps = {
  canAccessUserRoles: false,
  canEditProxySiteAccess: false,
  isPrimaryAdmin: false,
  isProxyUser: false,
  locations: [],
  policies: [],
  schedules: [],
};

export default withLocalize(UserAccessTable);
