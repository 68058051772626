const ARM_DISARM_RADIO_OPTIONS = [
  {
    translateId: 'ALARMS.ARM_DISARM_CAMERA.DISARM_OPTIONS.THIRTY_MINUTES',
    value: '.5',
  },
  {
    translateId: 'ALARMS.ARM_DISARM_CAMERA.DISARM_OPTIONS.ONE_HOUR',
    value: '1',
  },
  {
    translateId: 'ALARMS.ARM_DISARM_CAMERA.DISARM_OPTIONS.TWO_HOURS',
    value: '2',
  },
  {
    translateId: 'ALARMS.ARM_DISARM_CAMERA.DISARM_OPTIONS.INDEFINITELY',
    value: 'Indefinitely',
  },
];

export default ARM_DISARM_RADIO_OPTIONS;
