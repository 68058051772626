import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';

import {
  FieldInput,
  FilterTranslated,
  VerticalFormFieldLayout,
} from 'components';
import {
  BackButtonWithTitle,
  Button,
  GroupLayout,
  ListNav,
  PageTitle,
} from 'lib';
import { PageMessage } from 'containers';

import * as validation from 'util/validation';

import {
  formField,
  formWrapper,
  horizontalDividerSolid,
  inputError,
  modalFormGroup,
  pageError,
} from 'sharedStyles/modalForms.css';
import * as PlaceHolders from 'constants/PlaceHolders';
import { PATH_ACCOUNTS, PATH_SEGMENT_CONTACTS } from 'constants/urlPaths';
import { messageStyleStrings } from 'containers/PageMessage/constants';
import { formLabel } from 'components/VerticalForm/styles.css';
import * as ContactFormConsts from './constants';

export const fields = [
  ContactFormConsts.fieldId,
  ContactFormConsts.fieldName,
  ContactFormConsts.fieldPhoneNumber,
  ContactFormConsts.fieldSites,
];
const formLevelValidation = values => {
  const errors = {};
  const emptyFields = ContactFormConsts.requiredFields.filter(
    field =>
      !values[field] ||
      values[field].toString().trim() === '' ||
      values[field].length === 0,
  );
  if (emptyFields.length > 0) {
    errors.summary = (
      <Translate id="USERS.USER_FORM_FIELDS.VALIDATION.REQUIRED_FIELDS" />
    );
  }
  return errors;
};

const errorAggregator = ({ meta: { error, touched } }) => {
  return (
    <div className={pageError}>
      <PageMessage
        body={error}
        messageStyle={messageStyleStrings.error}
        translateHeader="GENERAL_MESSAGES.MISSING_INFO_HEADER"
        visible={touched && error}
      />
    </div>
  );
};

errorAggregator.defaultProps = {
  meta: null,
};

errorAggregator.propTypes = {
  meta: PropTypes.objectOf(PropTypes.any),
};

class Form extends Component {
  getSiteAddress(siteId) {
    const { sites } = this.props;
    const site = sites.find(siteItem => siteItem.Id === siteId);
    return site.Address.trim();
  }

  getSelectedSites() {
    const { initialValues } = this.props;
    return initialValues ? initialValues.Sites : [];
  }

  getSites() {
    const { sites } = this.props;
    return sites;
  }

  onDropdownChange = (field, value) => {
    const { change, touch } = this.props;
    touch('Sites');
    change('Sites', value);
  };

  renderSelectSite = ({ input: { value }, meta: { error, touched } }) => (
    <div className={formField}>
      {/* TODO: Rather than using the Filter component as an input, this should use a forthcoming
      Dropdown component built on react-select. Part of MVAAS-7222 . */}
      <Translate>
        {({ translate }) => (
          <>
            <FilterTranslated
              field="Sites"
              id={ContactFormConsts.idSiteDropdown}
              isInput
              label="COMMON.SITE"
              multi
              onChange={this.onDropdownChange}
              options={this.getSites().map(site => ({
                text: `${site.Name} (${this.getSiteAddress(site.Id)})`,
                value: site.Id,
              }))}
              translateLabel
              value={value || []}
            />
            <span className={inputError}>
              {touched && error && translate(error)}
            </span>
          </>
        )}
      </Translate>
    </div>
  );

  render() {
    const {
      canChangeCustomer,
      contactId,
      handleSubmit,
      initialValues,
      pristine,
      reset,
      submittingForm,
      valid,
    } = this.props;
    const isEditing = contactId !== 'new';
    const path = `${PATH_ACCOUNTS}${PATH_SEGMENT_CONTACTS}`;
    const editingTitle = (
      <Translate
        data={{ contactName: initialValues.Name }}
        id="CONTACTS_TABLE.FORM.EDIT_CONTACT_TITLE"
      />
    );
    const displayTitle = (
      <Translate id="CONTACTS_TABLE.FORM.ADD_CONTACT_TITLE" />
    );
    const title = isEditing ? editingTitle : displayTitle;

    return (
      <>
        <ListNav
          canChangeCustomer={canChangeCustomer}
          pageTitle={<PageTitle backPath={path} showBackButton title={title} />}
        />
        <div className={horizontalDividerSolid} />
        <div className={formWrapper}>
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                component={errorAggregator}
                name={ContactFormConsts.fieldSummary}
              />
            </div>
            <Field
              component={FieldInput}
              name={ContactFormConsts.fieldId}
              type="hidden"
            />
            <VerticalFormFieldLayout label="COMMON.NAME">
              <Field
                className={formField}
                component={FieldInput}
                name={ContactFormConsts.fieldName}
                translate
                type="text"
                validate={validation.required}
              />
            </VerticalFormFieldLayout>

            <VerticalFormFieldLayout label="CONTACTS_TABLE.FORM.PHONE_NUMBER_LABEL">
              <Field
                className={formField}
                component={FieldInput}
                name={ContactFormConsts.fieldPhoneNumber}
                placeholder={PlaceHolders.PhoneNumber}
                translate
                type="text"
                validate={validation.required}
              />
            </VerticalFormFieldLayout>

            <div className={modalFormGroup} id={ContactFormConsts.idSite}>
              <label className={formLabel}>
                <Translate id="COMMON.SITE" />
              </label>
              <Field
                component={this.renderSelectSite}
                name="Sites"
                translate
                type="text"
                validate={validation.required}
              />
            </div>

            <GroupLayout horizontalPositioning="left" verticalSpacing="large">
              <Button
                key="buttonRevert"
                buttonType="action"
                disabled={submittingForm || pristine}
                id="buttonRevert"
                inputType="button"
                onClick={reset}
                text={<Translate id="BUTTONS.REVERT" />}
              />
              <Button
                key="buttonSave"
                buttonType="primary"
                disabled={submittingForm || !valid || pristine}
                id="buttonSave"
                inputType="button"
                onClick={handleSubmit}
                text={
                  isEditing ? (
                    <Translate id="BUTTONS.SAVE" />
                  ) : (
                    <Translate id="BUTTONS.ADD" />
                  )
                }
              />
            </GroupLayout>
          </form>
        </div>
      </>
    );
  }
}

Form.defaultProps = {
  canChangeCustomer: false,
  change() {},
  submittingForm: false,
  touch() {},
  valid: false,
};

Form.propTypes = {
  canChangeCustomer: PropTypes.bool,
  change: PropTypes.func,
  contactId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  submittingForm: PropTypes.bool,
  touch: PropTypes.func,
  valid: PropTypes.bool,
};

const ReduxForm = reduxForm({
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
  form: ContactFormConsts.formName,
  validate: formLevelValidation,
})(Form);

const ContactForm = connect()(ReduxForm);

export default ContactForm;
