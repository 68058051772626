import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconToggleFocusMode = props => {
  return (
    <div className={props.className} title={props.title}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="PTZ-icons" transform="translate(-92.000000, -22.000000)">
            <g id="focus-strong" transform="translate(90.000000, 20.000000)">
              <g transform="translate(3.000000, 3.000000)">
                <mask id="mask-2" fill="white">
                  <path d="M9,4.99999998 C6.78999999,4.99999998 4.99999998,6.78999999 4.99999998,9 C4.99999998,11.21 6.78999999,13 9,13 C11.21,13 13,11.21 13,9 C13,6.78999999 11.21,4.99999998 9,4.99999998 Z M1.99999997,12 L-3.75002855e-08,12 L-3.75002855e-08,16 C-3.75002855e-08,17.1 0.899999966,18 1.99999997,18 L5.99999999,18 L5.99999999,16 L1.99999997,16 L1.99999997,12 Z M1.99999997,1.99999997 L5.99999999,1.99999997 L5.99999999,-3.75000582e-08 L1.99999997,-3.75000582e-08 C0.899999966,-3.75000582e-08 -3.75002855e-08,0.899999966 -3.75002855e-08,1.99999997 L-3.75002855e-08,5.99999999 L1.99999997,5.99999999 L1.99999997,1.99999997 Z M16,-3.75000582e-08 L12,-3.75000582e-08 L12,1.99999997 L16,1.99999997 L16,5.99999999 L18,5.99999999 L18,1.99999997 C18,0.899999966 17.1,-3.75000582e-08 16,-3.75000582e-08 Z M16,16 L12,16 L12,18 L16,18 C17.1,18 18,17.1 18,16 L18,12 L16,12 L16,16 Z" />
                </mask>
                <path
                  id="Icon"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  xlinkHref="#path-1"
                  d="M9,4.99999998 C6.78999999,4.99999998 4.99999998,6.78999999 4.99999998,9 C4.99999998,11.21 6.78999999,13 9,13 C11.21,13 13,11.21 13,9 C13,6.78999999 11.21,4.99999998 9,4.99999998 Z M1.99999997,12 L-3.75002855e-08,12 L-3.75002855e-08,16 C-3.75002855e-08,17.1 0.899999966,18 1.99999997,18 L5.99999999,18 L5.99999999,16 L1.99999997,16 L1.99999997,12 Z M1.99999997,1.99999997 L5.99999999,1.99999997 L5.99999999,-3.75000582e-08 L1.99999997,-3.75000582e-08 C0.899999966,-3.75000582e-08 -3.75002855e-08,0.899999966 -3.75002855e-08,1.99999997 L-3.75002855e-08,5.99999999 L1.99999997,5.99999999 L1.99999997,1.99999997 Z M16,-3.75000582e-08 L12,-3.75000582e-08 L12,1.99999997 L16,1.99999997 L16,5.99999999 L18,5.99999999 L18,1.99999997 C18,0.899999966 17.1,-3.75000582e-08 16,-3.75000582e-08 Z M16,16 L12,16 L12,18 L16,18 C17.1,18 18,17.1 18,16 L18,12 L16,12 L16,16 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

IconToggleFocusMode.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
  title: 'Focus',
};

export default IconToggleFocusMode;
