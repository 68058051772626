import React, { Component } from 'react';

// Styles
import {
  privacyBox,
  privacyZoneItem,
  privacyZoneItemSelected,
} from './styles.css';

class CameraMask extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={privacyBox}>
        <div
          className={
            this.props.isSelected ? privacyZoneItemSelected : privacyZoneItem
          }
          onClick={() => this.props.onSelectedMaskChanged(this.props.maskId)}
        >
          {this.props.maskName}
        </div>
      </div>
    );
  }
}

CameraMask.defaultProps = {};
export default CameraMask;
