import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconSnapshot = props => {
  return (
    <svg
      id={props.id}
      className={props.className}
      version="1.1"
      xmlns={SVG_XMLNS}
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 28 21"
      fill={props.fill}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop" transform="translate(-606.000000, -495.000000)">
          <g id="Group" transform="translate(537.000000, 492.000000)">
            <g id="btn-snapshot" transform="translate(69.000000, 3.000000)">
              <g>
                <polygon
                  id="Fill-4"
                  fill="#FEFEFE"
                  points="5.62689655 2.08863955 21.8848276 2.08863955 21.8848276 0.000631136045 5.62689655 0.000631136045"
                />
                <rect
                  id="Rectangle-4"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  x="1"
                  y="2.05189341"
                  width="26"
                  height="17"
                  rx="2"
                />
                <ellipse
                  id="Oval-2"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  cx="14"
                  cy="10.0448808"
                  rx="3.62068966"
                  ry="3.68162693"
                />
                <ellipse
                  id="Oval-2"
                  fill={props.fill}
                  cx="21.3103448"
                  cy="6.83730715"
                  rx="1.55172414"
                  ry="1.57784011"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSnapshot.defaultProps = {
  className: null,
  width: '28px',
  height: '21px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconSnapshot;
