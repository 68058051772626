// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import renderIf from 'render-if';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Components
import {
  DefaultFormButtons,
  LabeledBox,
  VerticalFormFieldStack,
} from 'components';
import { MainContentWrapper } from 'lib';
import LanguageSelect from 'containers/Language/LanguageSelect';

// Utils
import * as LanguageSelectConstants from 'containers/Language/constants';
import normalizeBoolean from 'util/normalizeBoolean';

// Styles
import { contentCard } from 'sharedStyles/global.css';
import { formGroup, formLabel } from 'components/VerticalForm/styles.css';

// Constants
import { LANGUAGES } from 'constants/app';
import * as consts from './constants';

import {
  formContent,
  formRadioGroup,
  formRadioLabel,
  halfPageColumn,
  languageSelector,
  profileContainer,
} from './styles.css';

function Profile(props) {
  function resetForm() {
    const { reset } = props;
    reset(consts.FORM_NAME);
  }

  const {
    canManageEmailNotifications,
    handleSubmit,
    initialValues,
    input,
  } = props;

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className={contentCard}>
        <div className={profileContainer}>
          <div className={halfPageColumn}>
            <MainContentWrapper>
              <div className={formContent}>
                <LabeledBox labelId="SECONDARY_NAV.TABS.PERSONALINFO.LABEL">
                  <div className={formGroup}>
                    <VerticalFormFieldStack
                      fieldPropList={consts.PROFILE_PROPS}
                      input={input}
                    />
                  </div>
                  {renderIf(LANGUAGES.length > 1)(
                    <div className={formGroup}>
                      <label
                        className={formLabel}
                        id={consts.PROFILE_LABELS.LABEL_COMMUNICATION_LANGUAGE}
                      >
                        <Translate
                          id={
                            consts.PROFILE_LABELS.LABEL_COMMUNICATION_LANGUAGE
                          }
                        />
                      </label>
                      <Field
                        className={languageSelector}
                        component={LanguageSelect}
                        name={LanguageSelectConstants.LanguageSelectFieldName}
                        userLanguage={initialValues.DesiredCulture}
                      />
                    </div>,
                  )}
                  {renderIf(canManageEmailNotifications)(
                    <div className={formGroup}>
                      <label className={formLabel}>
                        {
                          <Translate id="USERS.USER_FORM_FIELDS.LABELS.EMAIL_NOTIFICATIONS" />
                        }
                      </label>
                      <div className={formRadioGroup}>
                        <label className={formRadioLabel}>
                          <Field
                            component="input"
                            name={consts.EMAIL_NOTIFICATION_FORM_NAME}
                            normalize={normalizeBoolean}
                            type="radio"
                            value
                          />
                          {<Translate id="COMMON.YES" />}
                        </label>
                        <label className={formRadioLabel}>
                          <Field
                            component="input"
                            name={consts.EMAIL_NOTIFICATION_FORM_NAME}
                            normalize={normalizeBoolean}
                            type="radio"
                            value={false}
                          />
                          {<Translate id="COMMON.NO" />}
                        </label>
                      </div>
                    </div>,
                  )}
                </LabeledBox>
              </div>
            </MainContentWrapper>
          </div>
        </div>
        <DefaultFormButtons {...props} resetForm={resetForm} />
      </div>
    </form>
  );
}

Profile.propTypes = {
  canManageEmailNotifications: PropTypes.bool,
  dirty: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  input: PropTypes.objectOf(PropTypes.any),
  reset: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  canManageEmailNotifications: false,
  initialValues: {},
  input: undefined,
};

const validate = values => {
  const errors = {};
  if (values) {
    if (
      !values.FirstName ||
      (values.FirstName && values.FirstName.length === 0)
    ) {
      errors.FirstName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (!values.LastName || (values.LastName && values.LastName.length === 0)) {
      errors.LastName = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    }
    if (
      !values.EmailAddress ||
      (values.EmailAddress && values.EmailAddress.length === 0)
    ) {
      errors.EmailAddress = <Translate id="PROFILE.LABEL_ERROR_REQUIRED" />;
    } else {
      const emailRegularExpression = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegularExpression.test(values.EmailAddress)) {
        errors.EmailAddress = <Translate id="PROFILE.LABEL_ERROR_INVALID" />;
      }
    }
  }
  return errors;
};

const shouldValidate = ({ initialRender, nextProps, props, structure }) => {
  if (initialRender) return false;
  const valuesChanged = !structure.deepEqual(
    props.formValues,
    nextProps.formValues,
  );
  return valuesChanged;
};

const selector = formValueSelector(consts.FORM_NAME);

export default connect(state => ({
  formValues: selector(state, ...consts.FORM_FIELDS_LIST),
}))(
  reduxForm({
    enableReinitialize: true,
    fields: consts.FORM_FIELDS_LIST,
    form: consts.FORM_NAME,
    shouldValidate,
    touchOnChange: true,
    validate,
  })(Profile),
);
