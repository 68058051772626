import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment-timezone-all';
import onClickOutside from 'react-onclickoutside';
import { Translate } from 'react-localize-redux';

// Components
import { DateSelect, TimeSelect } from 'components';
import { Icon } from 'lib';
import { DATE_FORMAT_MMM_D_Y } from 'constants/app';
import { IC_CALENDAR } from 'constants/iconNames';
import { IconClock } from 'icons';

// Styles
import {
  dateFilter,
  dateFilterDropdown,
  dateFilterSection,
  dateFilterSectionHeader,
  dateFilterSectionItem,
  dateFilterSectionItemIcon,
  dateFilterSectionItemText,
  dateFilterTrigger,
  dateFilterTriggerIcon,
  dateFilterTriggerLabel,
  dateFilterTriggerText,
  disabled,
  filter,
  selectable,
} from './styles.css';

// Constants

const idSelectedDateRange = 'selectedDateRange';
const idDateRangeSelector = 'daySelect_';

class DateTimeFilter extends Component {
  constructor(props) {
    super(props);
    const { initialEndTime, initialStartTime } = this.props;
    this.state = {
      dateTimeOpen: false,
      endDate: initialEndTime,
      isNow: true,
      open: false,
      startDate: initialStartTime, // When endTime should be equal to the current time
    };
  }

  componentDidUpdate(prevProps) {
    const { initialEndTime, initialStartTime } = this.props;
    if (
      prevProps.initialStartTime !== initialStartTime ||
      prevProps.initialEndTime !== initialEndTime
    ) {
      this.updateState(initialStartTime, initialEndTime);
    }
  }

  updateState = (initialStartTime, initialEndTime) => {
    this.setState({
      endDate: initialEndTime,
      startDate: initialStartTime,
    });
  };

  toggleDropdown = value => {
    const { open } = this.state;
    this.setState({ open: value === null ? !open : value });
  };

  handleClickOutside = () => {
    const { dateTimeOpen, open } = this.state;
    if (open && !dateTimeOpen) {
      this.toggleDropdown(false);
    }
  };

  onSelectDate = () => {
    const { handleSelectDate } = this.props;
    const { endDate, startDate } = this.state;
    handleSelectDate({
      endDate,
      startDate,
    });
  };

  onRangeClick = days => {
    const { endDate: stateEndDate, isNow } = this.state;
    const endDate = isNow ? moment() : stateEndDate;
    const startDate = endDate.clone().subtract(days * 24, 'hours');
    // Use hours instead of days for precision wrt Daylight Savings Time
    this.setState(
      {
        endDate,
        startDate,
      },
      () => {
        this.onSelectDate();
        this.toggleDropdown(false);
      },
    );
  };

  onDateTimeClick = () => {
    this.setState({ dateTimeOpen: true });
  };

  onDateChange = date => {
    const { endDate: stateEndDate, startDate: stateStartDate } = this.state;
    let isNow = false;
    const dateInterval = stateEndDate.diff(stateStartDate, 'hours');
    const endDate = date.hour(stateEndDate.hour());
    const startDate = endDate.clone().subtract(dateInterval, 'hours');
    if (endDate.isSame(moment(), 'hour')) {
      isNow = true;
    }
    this.setState(
      {
        dateTimeOpen: false,
        endDate,
        isNow,
        startDate,
      },
      this.onSelectDate,
    );
  };

  onTimeChange = time => {
    const { endDate: stateEndDate, startDate: stateStartDate } = this.state;
    let isNow = false;
    const endDate = stateEndDate.clone().hour(time.hour());
    const startDate = stateStartDate.clone().hour(time.hour());
    // If the date is changed back to now, set the minutes to now to receive new alarms

    if (endDate.isSame(moment(), 'hour')) {
      endDate.minute(moment().minute());
      isNow = true;
    }
    this.setState({
      dateTimeOpen: false,
      endDate,
      isNow,
      startDate,
    });
    // Set state but do not update the alarms list until the timepicker is closed
  };

  getHeader = () => {
    const { endDate: stateEndDate, startDate: stateStartDate } = this.state;
    const startDate = stateStartDate.clone();
    const endDate = stateEndDate.clone();
    const days = endDate.diff(startDate, 'days');
    return days <= 1 ? (
      <Translate id="ALARMS.HEADER.TIMESPAN_24_HOURS" />
    ) : (
      <Translate data={{ numDays: days }} id="ALARMS.HEADER.TIMESPAN_N_DAYS" />
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initialEndTime, initialStartTime } = this.props;
    const newState = {};
    if (nextProps.initialEndTime !== initialEndTime) {
      newState.endDate = nextProps.initialEndTime;
    }
    if (nextProps.initialStartTime !== initialStartTime) {
      newState.startDate = nextProps.initialStartTime;
    }
    this.setState(newState);
  }

  render() {
    const {
      dayRange,
      disabled: disabledProp,
      id,
      showCustomDateSelector,
      timeFormat,
      timezone,
    } = this.props;
    const { endDate, open, startDate } = this.state;
    return (
      <div
        className={`${filter} ${dateFilter} ${disabledProp && disabled}`}
        id={id}
      >
        <div className={dateFilterTrigger}>
          <button
            className={dateFilterTriggerIcon}
            disabled={disabledProp}
            onClick={() => this.toggleDropdown(null)}
            type="button"
          >
            {/* Icon displayed at small page sizes */}
            <Icon id={IC_CALENDAR} title="TIME_SELECT.PLACEHOLDERS.DATE" />
          </button>
          <button
            className={dateFilterTriggerText}
            disabled={disabledProp}
            onClick={() => this.toggleDropdown(null)}
            type="button"
          >
            {/* Dropdown-style box displayed at large sizes */}
            <div className={dateFilterTriggerLabel}>
              <div>{`${startDate.format(timeFormat)} - ${endDate.format(
                timeFormat,
              )}`}</div>
              <Icon
                id={IC_CALENDAR}
                size={14}
                title="TIME_SELECT.PLACEHOLDERS.DATE"
              />
            </div>
          </button>
        </div>
        {open && (
          <div className={dateFilterDropdown}>
            <div className={dateFilterSection} id={idSelectedDateRange}>
              <h4 className={dateFilterSectionHeader}>{this.getHeader()}</h4>
              <p className={dateFilterSectionItem}>
                {`${startDate.format(timeFormat)} - ${endDate.format(
                  timeFormat,
                )}`}
              </p>
            </div>
            {dayRange.length > 0 && (
              <div className={dateFilterSection}>
                <h4 className={dateFilterSectionHeader}>
                  <Translate id="ALARMS.FILTERS.DATE_RANGE" />
                </h4>
                {dayRange.map((days, index) => {
                  const dayText =
                    days === 1 ? (
                      <Translate id="ALARMS.HEADER.TIMESPAN_24_HOURS" />
                    ) : (
                      <Translate
                        data={{ numDays: days }}
                        id="ALARMS.HEADER.TIMESPAN_N_DAYS"
                      />
                    );
                  return (
                    <div
                      key={index}
                      className={`${dateFilterSectionItem} ${selectable}`}
                      id={idDateRangeSelector + index}
                      onMouseDown={() => this.onRangeClick(days, index)}
                      role="button"
                      tabIndex="0"
                    >
                      {dayText}
                    </div>
                  );
                })}
              </div>
            )}
            {showCustomDateSelector && (
              <div className={dateFilterSection}>
                <h4 className={dateFilterSectionHeader}>
                  <Translate id="ALARMS.FILTERS.SELECT_CUSTOM_DATE" />
                </h4>
                <div className={`${dateFilterSectionItem} ${selectable}`}>
                  <div className={dateFilterSectionItemIcon}>
                    <Icon
                      height="20"
                      id={IC_CALENDAR}
                      title="ALARMS.FILTERS.SELECT_DATE"
                      width="20"
                    />
                  </div>
                  <div className={dateFilterSectionItemText}>
                    <DateSelect
                      initialValue={
                        endDate.isBefore(moment(), 'days') && endDate.clone()
                      }
                      onChange={this.onDateChange}
                      onFocus={this.onDateTimeClick}
                      timezone={timezone}
                    />
                  </div>
                </div>
                <div className={`${dateFilterSectionItem} ${selectable}`}>
                  <Translate>
                    {({ translate }) => (
                      <div
                        className={dateFilterSectionItemIcon}
                        title={translate('ALARMS.FILTERS.SELECT_TIME_UTC')}
                      >
                        <IconClock />
                      </div>
                    )}
                  </Translate>
                  <div className={dateFilterSectionItemText}>
                    <TimeSelect
                      onChange={this.onTimeChange}
                      onClose={this.onSelectDate}
                      onOpen={this.onDateTimeClick}
                      showMinute={false}
                      showSecond={false}
                      timezone={timezone}
                      value={
                        endDate.isBefore(moment(), 'hours') && endDate.clone()
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

DateTimeFilter.defaultProps = {
  dayRange: [],
  disabled: false,
  handleSelectDate: () => {},
  id: null,
  initialEndTime: moment().format(DATE_FORMAT_MMM_D_Y),
  initialStartTime: moment().format(DATE_FORMAT_MMM_D_Y),
  showCustomDateSelector: true,
  timeFormat: DATE_FORMAT_MMM_D_Y,
  timezone: null,
};

DateTimeFilter.propTypes = {
  dayRange: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
  handleSelectDate: PropTypes.func,
  id: PropTypes.string, // Describes the formatting of start time and end time
  initialEndTime: PropTypes.objectOf(PropTypes.any),
  initialStartTime: PropTypes.objectOf(PropTypes.any),
  showCustomDateSelector: PropTypes.bool,
  timeFormat: PropTypes.string,
  timezone: PropTypes.string,
};

export default onClickOutside(DateTimeFilter);
