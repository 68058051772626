exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".formField___3Wjtg {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  padding: 0px 0px 0px 4px;\r\n}\r\n\r\n.formField___3Wjtg > input:disabled {\r\n  background-color: transparent !important;\r\n  border: none;\r\n  font-weight: normal;\r\n  word-break: break-word;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  color: #575757;\r\n  height: -webkit-fit-content;\r\n  height: -moz-fit-content;\r\n  height: fit-content;\r\n}\r\n\r\n.titleWrapper___1k0l9 {\r\n  height: 42px;\r\n  line-height: 42px;\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  margin-right: 10px;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n  margin-left: 3px;\r\n}\r\n", "", {"version":3,"sources":["C:/Helious/ServerMaster/develop/server/client/app/components/Bookmarks/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,yBAAyB;CAC1B;;AAED;EACE,yCAAyC;EACzC,aAAa;EACb,oBAAoB;EACpB,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,4BAA4B;EAC5B,yBAAyB;EACzB,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;EACxB,oBAAoB;EACpB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".formField {\r\n  display: -ms-flexbox;\r\n  display: flex;\r\n  padding: 0px 0px 0px 4px;\r\n}\r\n\r\n.formField > input:disabled {\r\n  background-color: transparent !important;\r\n  border: none;\r\n  font-weight: normal;\r\n  word-break: break-word;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  color: #575757;\r\n  height: -webkit-fit-content;\r\n  height: -moz-fit-content;\r\n  height: fit-content;\r\n}\r\n\r\n.titleWrapper {\r\n  height: 42px;\r\n  line-height: 42px;\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  margin-right: 10px;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n  margin-left: 3px;\r\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"formField": "formField___3Wjtg",
	"titleWrapper": "titleWrapper___1k0l9"
};