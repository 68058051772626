
// Utils
import * as CameraTypes from 'constants/cameraTypes';
import jsonParseRobust from './jsonParseRobust';

// Constants

export const getCameraActualStatus = camera => {
  let cameraActualStatus = {
    state: '',
    realState: '',
    statusText: '',
  };
  let cameraConnectionStatus = {};
  try {
    cameraConnectionStatus = jsonParseRobust(camera.ConnectionStatus);
  } catch (e) {
    avoLogError('Error parsing connection status', {
      message: CameraTypes.JSON_ERROR_CONNECTION_STATUS,
      triggeringError: e,
    });
  }
  const cameraDefault = {
    state: camera.ConnectionState,
    realState: camera.ConnectionState,
    statusText: `CAMERA.STATUS.${camera.ConnectionState}`,
  };
  if (cameraConnectionStatus.state) {
    if (cameraConnectionStatus.state === CameraTypes.CAMERA_CONNECTED) {
      cameraActualStatus = {
        state: CameraTypes.CAMERA_CONNECTED,
        realState: CameraTypes.CAMERA_CONNECTED,
        statusText: 'CAMERA.STATUS.CONNECTED',
      };
    } else if (
      cameraConnectionStatus.state === CameraTypes.CAMERA_ADDING_CAMERA
    ) {
      cameraActualStatus = {
        state: CameraTypes.CAMERA_DISCONNECTED,
        realState: CameraTypes.CAMERA_ADDING_CAMERA,
        statusText: 'CAMERA.STATUS.ADDING_CAMERA',
      };
    } else if (
      cameraConnectionStatus.state === CameraTypes.CAMERA_DISCONNECTED
    ) {
      cameraActualStatus = {
        state: CameraTypes.CAMERA_DISCONNECTED,
        realState: CameraTypes.CAMERA_DISCONNECTED,
        statusText: 'CAMERA.STATUS.DISCONNECTED',
      };

      const disconnCameraErrorFlag = cameraConnectionStatus.errorFlags[0];

      if (disconnCameraErrorFlag) {
        switch (disconnCameraErrorFlag) {
          case CameraTypes.CAMERA_AUTH_FAILED: {
            cameraActualStatus.realState = CameraTypes.CAMERA_AUTH_FAILED;
            cameraActualStatus.statusText = 'CAMERA.STATUS.AUTH_REQUIRED';
            break;
          }
          case CameraTypes.CAMERA_DISABLED_PENDING_SUBSCRIPTION_CHECK: {
            cameraActualStatus.realState =
              CameraTypes.CAMERA_CHECKING_SUBSCRIPTION;
            cameraActualStatus.statusText =
              'CAMERA.STATUS.CHECKING_SUBSCRIPTION';
            break;
          }
          case CameraTypes.CAMERA_DISABLED_DUE_TO_SUBSCRIPTION_LIMIT: {
            cameraActualStatus.realState = CameraTypes.CAMERA_DISABLED;
            cameraActualStatus.statusText = 'CAMERA.STATUS.OVER_SUBSCRIPTION';
            break;
          }
          case CameraTypes.CAMERA_UNKNOWN_ERROR: {
            cameraActualStatus.realState = CameraTypes.CAMERA_UNKNOWN_ERROR;
            cameraActualStatus.statusText = 'CAMERA.STATUS.DEFAULT_ERROR';
            break;
          }
          case CameraTypes.CAMERA_DISABLED_BY_USER: {
            cameraActualStatus.realState = CameraTypes.CAMERA_DISABLED_BY_USER;
            cameraActualStatus.statusText = 'CAMERA.STATUS.DISABLED_BY_USER';
            break;
          }
          default: {
            cameraActualStatus = cameraDefault;
          }
        }
      }
    } else {
      cameraActualStatus = cameraDefault;
    }
  } else {
    cameraActualStatus = cameraDefault;
  }
  return cameraActualStatus;
};
