export const VIDEO_DOWNLOAD_FORM = {
  FORM_PROPS: {
    START_TIME: 'startTime',
    DURATION: 'duration',
  },
  BUTTONS: {
    SAVE: {
      ID: 'idBtnVideoDownloadSubmit',
      KEY: 'keyBtnVideoDownloadSubmit',
    },
    CANCEL: {
      ID: 'idBtnVideoDownloadCancel',
      KEY: 'keyBtnVideoDownloadCancel',
    },
  },
};

export const MAX_DOWNLOAD_DURATION = 3600; // In units of seconds

export const idLogDownload = 'downloadLogBtn';
