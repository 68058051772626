import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Select from 'react-select-plus/lib/Select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as LinkActions from 'actions/utilities';

// Utils
import * as LanguagesUtils from 'util/languagesUtils';

// Constants
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE,
  SELECT_STYLE,
} from 'constants/app';
import * as Constants from './constants';

import { selectBox } from './languageSelect.css';

class LanguageSelect extends Component {
  static propTypes = {
    applyOnChange: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    userLanguage: PropTypes.string,
  };

  static defaultProps = {
    applyOnChange: false,
    disabled: false,
    onChange: null,
    userLanguage: null,
  };

  static getDerivedStateFromProps = (props, state) => {
    const value = props.value || (props.input && props.input.value);
    const { initialLanguage, selectedLanguage } = state;
    if (value !== initialLanguage && value !== selectedLanguage) {
      return {
        initialLanguage: value || selectedLanguage,
        selectedLanguage: value || selectedLanguage,
      };
    }
    if (value !== selectedLanguage && initialLanguage !== selectedLanguage) {
      return {
        initialLanguage: selectedLanguage,
        selectedLanguage,
      };
    }
    return null;
  };

  constructor(props) {
    super(props);
    const userLanguage = props.input ? props.input.value : props.userLanguage;
    const activeLanguage =
      userLanguage ||
      (props.applyOnChange && localStorage.getItem(LOCAL_STORAGE_LANGUAGE)) ||
      DEFAULT_LANGUAGE;
    this.state = {
      initialLanguage: userLanguage,
      selectedLanguage: activeLanguage,
    };
  }

  componentDidMount() {
    const { selectedLanguage } = this.state;
    const { input, name, onChange, setTheValue, userLanguage } = this.props;
    this.setState({
      initialLanguage: userLanguage,
    });
    input && input.onChange(selectedLanguage);
    onChange && onChange(selectedLanguage);
    setTheValue &&
      setTheValue(name || Constants.LanguageSelectId, selectedLanguage);
  }

  componentDidUpdate() {
    const { selectedLanguage } = this.state;
    const { input, name, onChange, setTheValue } = this.props;
    input && input.onChange(selectedLanguage);
    onChange && onChange(selectedLanguage);
    setTheValue &&
      setTheValue(name || Constants.LanguageSelectId, selectedLanguage);
  }

  generateOptionsList = () => {
    const optionArr = [];
    LANGUAGES.forEach(language => {
      optionArr.push({ label: language.label, value: language.fullCode });
    });
    return optionArr;
  };

  onSelectionChanged = option => {
    const { applyOnChange, input, name, onChange, setTheValue } = this.props;
    this.setState(
      {
        selectedLanguage: option.value,
      },
      () => {
        const { value } = option;
        applyOnChange && LanguagesUtils.changeAppLanguage(value);
        applyOnChange && this.props.actions.getLinks();
        input && input.onChange(this.state.selectedLanguage);
        onChange && onChange(value);
        setTheValue && setTheValue(name || Constants.LanguageSelectId, value);
      },
    );
  };

  onSelectionBlured = () => {
    const { input } = this.props;
    input && input.onBlur(this.state.selectedLanguage);
  };

  render() {
    const { className, disabled, input, name } = this.props;
    const { selectedLanguage } = this.state;
    return (
      <Select
        className={`${className} ${selectBox} msi-select`}
        clearable={false}
        disabled={disabled}
        id={
          name || input
            ? Constants.LanguageSelectId
            : Constants.FooterLanguageSelectId
        }
        name={name || Constants.LanguageSelectId}
        onBlur={this.onSelectionBlured}
        onChange={this.onSelectionChanged}
        openOnFocus
        optionClassName="msi-select-option"
        options={this.generateOptionsList()}
        searchable
        style={SELECT_STYLE}
        value={selectedLanguage}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...LinkActions,
      },
      dispatch,
    ),
  };
};
export default connect(null, mapDispatchToProps)(LanguageSelect);
