/* eslint-disable import/no-cycle */

// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

// Component
import { CameraSettingsAudioSpeakers, PanelSettings } from 'components';
import { Button, GroupLayout } from 'lib';
import { PageMessage } from 'containers';

// Actions
import {
  getCamera,
  getSpeakerSettings,
  setSpeakerSettings,
} from 'actions/devices';

// Style
import { findCamera } from 'util/normalizeCamera';
import { cameraSettingsBasic, rightSettings } from './styles.css';

// Utils
import { AUDIO_CONTENT_ID } from './constants';

// Constant
const STRINGS = {
  FETCHING: 'GENERAL_MESSAGES.LOADING_HEADER',
  SAVING: 'GENERAL_MESSAGES.SAVE_HEADER',
};
const KEYS = {
  audioSettings: 'audio-camera-settings',
  buttons: 'audio-panel-buttons',
  settings: 'audio-panel-settings',
  statusMessage: 'audio-panel-status-message',
};

const BUTTONS = {
  revert: {
    id: 'audioSettingsButtonRevert',
    key: 'audioSettingsButtonRevert',
  },
  save: {
    id: 'audioSettingsButtonSave',
    key: 'audioSettingsButtonSave',
  },
};

// Class
class CameraSettingsAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  componentDidMount() {
    const { CameraId, DeviceId, RemoteId, actions } = this.props;
    actions.getSpeakerSettings({
      cameraId: CameraId,
      deviceId: DeviceId,
      remoteId: RemoteId,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { isSaving } = props;
    const { activated, hasChanged, hasSpeaker, volume } = state;
    const speakerState = {
      activated: Boolean(activated),
      hasSpeaker: Boolean(hasSpeaker),
      volume: volume || 50,
    };
    return isSaving || hasChanged
      ? {
          ...props,
          ...speakerState,
        }
      : props;
  }

  onRevert = () => {
    const { activated, hasSpeaker, volume } = this.props;
    const originalState = {
      activated,
      hasSpeaker,
      volume,
    };
    this.setState({
      hasChanged: false,
      ...originalState,
    });
  };

  onSave = () => {
    const { DeviceId, RemoteId, actions, selectedCameraId } = this.props;
    this.setState({
      hasChanged: false,
    });
    actions.setSpeakerSettings({
      cameraId: selectedCameraId,
      data: this.speakerState,
      deviceId: DeviceId,
      remoteId: RemoteId,
    });
  };

  onSpeakerChange = speakers => {
    this.setState({
      hasChanged: true,
      ...speakers,
    });
  };

  get buttonsDisabled() {
    const { hasChanged, isSaving } = this.state;
    return !hasChanged || isSaving;
  }

  get controlsDisabled() {
    const { isFetching, isSaving } = this.state;
    return isFetching !== false || isSaving;
  }

  get speakerState() {
    const { activated, hasSpeaker, volume } = this.state;
    return {
      activated: Boolean(activated),
      hasSpeaker: Boolean(hasSpeaker),
      volume: volume || 50,
    };
  }

  get stateMessage() {
    return (
      <PageMessage
        key={KEYS.statusMessage}
        messageStyle="loading"
        visible={this.message}
      />
    );
  }

  get message() {
    const { isFetching } = this.state;
    return isFetching ? STRINGS.ISFETCHING : undefined;
  }

  get panelSettings() {
    return (
      <PanelSettings key={KEYS.settings}>
        <CameraSettingsAudioSpeakers
          disabled={this.controlsDisabled}
          onChange={this.onSpeakerChange}
          speakers={this.speakerState}
        />
      </PanelSettings>
    );
  }

  get buttonGroup() {
    return (
      <GroupLayout key={KEYS.buttons} verticalSpacing="large">
        <Button
          key={BUTTONS.revert.key}
          buttonType="primary"
          disabled={this.buttonsDisabled}
          id={BUTTONS.revert.id}
          inputType="button"
          onClick={this.onRevert}
          text={<Translate id="BUTTONS.REVERT" />}
        />
        <Button
          key={BUTTONS.save.key}
          disabled={this.buttonsDisabled}
          id={BUTTONS.save.id}
          inputType="button"
          onClick={this.onSave}
          text={<Translate id="BUTTONS.SAVE" />}
        />
      </GroupLayout>
    );
  }

  get panelContent() {
    const { isFetching } = this.state;
    const panelContentArray = [];
    if (!isFetching) {
      // add the panel controls
      panelContentArray.push(this.panelSettings);
      // add the button groups
      panelContentArray.push(this.buttonGroup);
    }
    if (this.message) {
      // add the message
      panelContentArray.push(this.stateMessage);
    }
    return panelContentArray;
  }

  render() {
    return (
      <div
        key={KEYS.audioSettings}
        className={`${rightSettings} ${cameraSettingsBasic}`}
        id={AUDIO_CONTENT_ID}
      >
        {this.panelContent}
      </div>
    );
  }
}

CameraSettingsAudio.defaultProps = {
  activated: false,
  hasSpeaker: false,
  volume: 50,
};

CameraSettingsAudio.propTypes = {
  CameraId: PropTypes.string.isRequired,
  DeviceId: PropTypes.string.isRequired,
  RemoteId: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  activated: PropTypes.bool,
  hasSpeaker: PropTypes.bool,
  selectedCameraId: PropTypes.shape({}).isRequired,
  volume: PropTypes.number,
};

const mapStateToProps = state => {
  /* set isFetching if either of the required API requests are in flight,
   * *or* if there is no selectedCameraId set yet and thus the appropriate
   * data can't be loaded
   */
  const isFetching =
    state.devices.isFetchingSpeakerSettings ||
    state.isFetching.getAllCameras ||
    !state.devices.selectedCamera;

  const metadata = {
    isFetching,
    isSaving: state.devices.isCommittingSpeakerSettings,
    selectedCameraId: state.devices.selectedCamera,
  };

  let cameraData = {};

  // if we have all the necessary data in state, populate the
  // speakers var with data from the model
  findCamera(state, metadata.selectedCameraId, camera => {
    cameraData = {
      CameraId: camera.Id,
      ...camera.speakers,
      DeviceId: camera.DeviceId,
      RemoteId: camera.RemoteId,
      hasSpeaker: camera.Capabilities.speaker.SPEAKER_OUTPUT,
    };
  });

  return {
    isFetchingCameraSettings: state.devices.isFetchingCameraSettings,
    ...metadata,
    ...cameraData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        getCamera,
        getSpeakerSettings,
        setSpeakerSettings,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CameraSettingsAudio);
