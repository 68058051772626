import { Redirect } from 'react-router-dom';
import React from 'react';
import { PATH_HEALTH_MONITOR } from 'constants/urlPaths';

export const isHMSPath = () => {
  const urlArray = window.location.pathname.split(PATH_HEALTH_MONITOR);
  return urlArray && urlArray.length === 2 && urlArray[1].length > 0;
};

export const windowRedirectToHMSRoot = () => {
  window.history.pushState({}, '', PATH_HEALTH_MONITOR);
};

export const renderRedirectToHMSRoot = redirect =>
  redirect ? <Redirect to={PATH_HEALTH_MONITOR} /> : null;
