import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Translate } from 'react-localize-redux';

import * as validation from 'util/validation';

import { FieldInput } from 'components/FormElements';
import { FormGroup } from 'lib';
import * as LocationFormConsts from '../../constants';

import { formField } from '../../styles.css';

const NonUsaRegion = props => {
  const { disabled } = props;
  return (
    <FormGroup
      id={LocationFormConsts.idRegionDropdown}
      keyName="nonUsaRegion"
      label={<Translate id="ADDRESS.NON_USA_REGION" />}
    >
      <Field
        className={formField}
        component={FieldInput}
        disabled={disabled}
        isNewVersion
        name={LocationFormConsts.nameRegion}
        translate
        validate={validation.required}
      />
    </FormGroup>
  );
};

NonUsaRegion.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default NonUsaRegion;
