import React from 'react';
import { Translate } from 'react-localize-redux';
import { defaultToInput } from 'util/languagesUtils';
import {
  inputError,
  inputValidating,
  formReadOnlyText,
} from 'sharedStyles/modalForms.css';
import { isInputError } from 'sharedStyles/isState.css';
import renderIf from 'render-if';

const FieldInput = ({
  input,
  isCodeError,
  type,
  className,
  disabled,
  placeholder,
  translate,
  meta: { touched, error, asyncValidating },
  maxLength,
  isNewVersion,
}) => {
  const errorDiv = touched && error && !asyncValidating && (
    <div className={inputError}>
      {translate || typeof error === 'string' ? (
        <Translate
          id={error}
          options={{ onMissingTranslation: defaultToInput }}
        />
      ) : (
        error
      )}
    </div>
  );
  let field = null;
  if (disabled && isNewVersion) {
    let inputText = '';
    if (type === 'text') {
      inputText = input.value;
    } else if (type === 'checkbox') {
      inputText = input.value ? (
        <Translate id="COMMON.YES" />
      ) : (
        <Translate id="COMMON.NO" />
      );
    }
    field = <div className={formReadOnlyText}>{inputText}</div>;
  } else {
    field = (
      <>
        <div className={className}>
          <input
            {...input}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            className={touched && error ? isInputError : ''}
            maxLength={maxLength}
          />
          {renderIf(!isCodeError)(errorDiv)}
          {asyncValidating && (
            <div className={inputValidating}>
              <Translate id="INTEGRATIONS.CONFIGS.VALIDATING_INPUT" />
            </div>
          )}
        </div>
        <div>{renderIf(isCodeError)(errorDiv)}</div>
      </>
    );
  }
  return field;
};

export default FieldInput;
