import React from 'react';
import { SVG_XMLNS } from '../constants/app';

const IconSmallCheck = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <title>Icon-small-check</title>
      <g
        id="06-Org-Mgmt-Form-Register"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="01-Org-Mgmt-General"
          transform="translate(-675.000000, -421.000000)"
        >
          <g id="All-Content" transform="translate(262.000000, 182.000000)">
            <g id="Content" transform="translate(17.000000, 104.000000)">
              <g id="Group-2" transform="translate(371.000000, 10.000000)">
                <g
                  id="icon-small-check"
                  transform="translate(25.000000, 125.000000)"
                >
                  <circle id="Oval" fill="#6ECA3D" cx="6" cy="6" r="6" />
                  <path
                    d="M7.70710678,8.70710678 C7.88807119,8.52614237 8,8.27614237 8,8 L8,3 C8,2.44771525 7.55228475,2 7,2 C6.44771525,2 6,2.44771525 6,3 L6,3 L6,7 L5,7 C4.44771525,7 4,7.44771525 4,8 C4,8.55228475 4.44771525,9 5,9 L7,9 C7.27614237,9 7.52614237,8.88807119 7.70710678,8.70710678 Z"
                    id="Combined-Shape"
                    fill="#FFFFFF"
                    transform="translate(6.000000, 5.500000) rotate(30.000000) translate(-6.000000, -5.500000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSmallCheck.defaultProps = {
  className: null,
  width: '12px',
  height: '12px',
};

export default IconSmallCheck;
