import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconDelete } from 'icons';
import { ListView } from 'lib';
import { Translate } from 'react-localize-redux';
import { parseOrgStatusKey } from 'util/userRoles';
import { parseProviderSubtypesTranslateId } from 'util/tenantTypes';
import { CUSTOMER_TYPES } from 'constants/app';
import { customerTableHeight } from './constants';

class CustomersTable extends Component {
  handleInfiniteScroll = () => {
    const {
      getNextPage,
      nextPageURL,
      nextPagefetchType,
      tableFilterId,
    } = this.props;
    if (tableFilterId) {
      getNextPage(nextPageURL, tableFilterId);
    } else {
      getNextPage(nextPageURL, nextPagefetchType);
    }
  };

  handleRowClick = data => {
    const { onClickedRow } = this.props;
    onClickedRow(data);
  };

  handleDeleteClick = rowData => {
    const { handleDeleteClick } = this.props;
    avoLog('Clicked to delete', rowData);
    handleDeleteClick(rowData);
  };

  render() {
    const {
      canDeleteOrg,
      customers,
      isFetchingNextPage,
      rowsSelected,
      scrollTo,
      showCustomerColumn,
    } = this.props;
    const translationsId = {
      AdministratorPhone: 'GENERAL_TABLE.HEADER.PHONE_LABEL',
      BillingNumber: 'GENERAL_TABLE.HEADER.BILLING_ID_LABEL',
      Name: 'COMMON.NAME',
      Status: 'COMMON.STATUS',
      SubType: 'GENERAL_TABLE.HEADER.PROVIDER_TYPE_LABEL',
      administratorName: 'GENERAL_TABLE.HEADER.PRIMARY_ADMIN_LABEL',
    };
    const translatedStatus = status => (
      <Translate id={parseOrgStatusKey(status)} />
    );

    const translatedSubType = tenantType => (
      <Translate id={parseProviderSubtypesTranslateId(tenantType)} />
    );

    const isViewingProviders =
      customers.length > 0 && customers[0].Type === CUSTOMER_TYPES.Provider;

    let fieldOrder;
    if (showCustomerColumn) {
      if (isViewingProviders) {
        fieldOrder = [
          'Name',
          'BillingNumber',
          'SubType',
          'administratorName',
          'Status',
        ];
      } else {
        fieldOrder = ['Name', 'BillingNumber', 'administratorName', 'Status'];
      }
    } else {
      fieldOrder = [
        'Name',
        'administratorName',
        'AdministratorPhone',
        'Status',
      ];
    }

    const truncatedFields = ['Name', 'administratorName'];

    const customCells = {
      Status: customer => translatedStatus(customer.Status),
      SubType: customer => translatedSubType(customer.SubType),
      administratorName: customer => {
        return `${customer.AdministratorFirstName} ${customer.AdministratorLastName}`;
      },
    };

    const ROW_ACTIONS = [
      ({ rowData }) => {
        return rowData.Type === CUSTOMER_TYPES.Subscriber && canDeleteOrg ? (
          <div
            onClick={e => {
              e.stopPropagation();
              this.handleDeleteClick(rowData);
            }}
          >
            <IconDelete />
          </div>
        ) : (
          ''
        );
      },
    ];

    return (
      <ListView
        bodyHeight={customerTableHeight}
        cellWidths={{ Actions: 50 }}
        clickableRows
        customCells={customCells}
        data={customers}
        fieldOrder={fieldOrder}
        headerTranslationIds={translationsId}
        hideFilter
        infiniteScroll
        isFetchingMoreData={isFetchingNextPage}
        onInfiniteScroll={this.handleInfiniteScroll}
        resizable={false}
        rowActions={ROW_ACTIONS}
        rowClickCallback={this.handleRowClick}
        rowsSelected={rowsSelected}
        scrollToPos={scrollTo}
        sortType="local"
        tableBodyStyle={null}
        truncatedFields={truncatedFields}
      />
    );
  }
}

CustomersTable.defaultProps = {
  canDeleteOrg: false,
  customers: [],
  getNextPage: () => {},
  handleDeleteClick: () => {},
  isFetchingNextPage: false,
  nextPageURL: {},
  nextPagefetchType: undefined,
  onClickedRow: () => {},
  rowsSelected: [],
  scrollTo: undefined,
  tableFilterId: null,
};

CustomersTable.propTypes = {
  canDeleteOrg: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.any),
  getNextPage: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  nextPageURL: PropTypes.objectOf(PropTypes.any),
  nextPagefetchType: PropTypes.string,
  onClickedRow: PropTypes.func,
  rowsSelected: PropTypes.arrayOf(PropTypes.number),
  scrollTo: PropTypes.number,
  showCustomerColumn: PropTypes.bool.isRequired,
  tableFilterId: PropTypes.string,
};
export default CustomersTable;
