import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

import BulkActionContext from 'lib/BulkActionContext/BulkActionContext';

import OGExpandedRow from './OGExpandedRow';
import OGRowActions from './OGRowActions';
import OGRowOverflow from './OGRowOverflow';
import OGCell from '../OGCell';

import * as styles from '../OGTable.css';

const OGRow = ({
  alignClass,
  bulkActions,
  bulkActionsSingle,
  bulkActionsTable,
  cellWidths,
  clickableRowPrompt,
  clickableRows,
  customBodyRowClass,
  customCells,
  customRowClass,
  customRowTrigger,
  data,
  expanded,
  fieldOrder,
  headerLabels,
  height,
  hideBulkActionsWhen,
  highlightRow,
  idx,
  inlineDetailContent,
  inlineDetailLabel,
  inlineDetails,
  overflowFieldOrder,
  overflowFieldOrderField,
  overflowHeaderTranslations,
  rowActions,
  rowClickCallback,
  rowData,
  rowDisabled,
  setExpandedRow,
  totalColumns,
  truncatedFields,
  visibleFieldOrder,
  xray,
}) => {
  const { bulkActionIdsFor, setBulkActionIds } = useContext(BulkActionContext);
  const isRowDisabled = rowDisabled(rowData);
  const bulkActionIds = bulkActionIdsFor(bulkActionsTable) || [];

  function customRow(row) {
    return (
      row[Object.keys(customRowTrigger)] ===
      customRowTrigger[Object.keys(customRowTrigger)]
    );
  }

  function onSelectRow(row, checked) {
    let newList;
    const currentIds = bulkActionIdsFor(bulkActionsTable) || [];
    if (checked) {
      if (bulkActionsSingle) {
        newList = [row.Id];
      } else {
        newList = [...currentIds, row.Id];
      }
    } else {
      newList = currentIds.filter(actionId => actionId !== row.Id);
    }
    setBulkActionIds(bulkActionsTable, newList);
  }

  function inlineDetailsEnabled() {
    return inlineDetails || xray;
  }

  const onBodyClick = (row, rowIndex) => {
    if (inlineDetailsEnabled()) {
      setExpandedRow(rowIndex);
      return;
    }
    if (clickableRows && !rowDisabled(row)) {
      rowClickCallback(row, rowIndex);
    }
  };

  const cellContent = fieldName => {
    const callbackParams = { data, idx };
    if (
      !truncatedFields.includes(fieldName) &&
      clickableRows &&
      clickableRowPrompt &&
      !isRowDisabled
    ) {
      callbackParams.selected = bulkActionIdsFor(bulkActionsTable).includes(
        rowData.Id,
      );
    }
    return (
      (customCells[fieldName] &&
        customCells[fieldName](rowData, callbackParams)) ||
      rowData[fieldName] ||
      ''
    );
  };
  const rowClass = () => {
    if (highlightRow) return styles.selectedRow;
    if (bulkActionIds.find(i => i === rowData.Id)) return styles.selectedRow;
    if (!isRowDisabled) return styles.clickableRow;

    return '';
  };

  return (
    <tr
      className={`${customBodyRowClass || styles.ogBodyRow} ${
        clickableRows || inlineDetailsEnabled() || highlightRow
          ? rowClass()
          : ''
      } ${customRow(rowData) ? customRowClass : ''}`}
    >
      {inlineDetailsEnabled() && expanded ? (
        <OGExpandedRow
          cellWidths={cellWidths}
          content={inlineDetailContent(
            rowData,
            idx,
            inlineDetails,
            xray,
            headerLabels,
            fieldOrder,
            customCells,
          )}
          idx={idx}
          isRowDisabled={isRowDisabled}
          rowActions={rowActions}
          rowData={rowData}
          setExpandedRow={setExpandedRow}
          theme="detail"
          title={inlineDetailLabel(rowData, idx)}
          totalColumns={totalColumns}
        />
      ) : (
        <>
          {bulkActions === true ? (
            <td
              key={`rowselect-${idx}`}
              className={styles.ogBodyCell}
              style={{ width: '20px' }}
            >
              {renderIf(!hideBulkActionsWhen(rowData))(
                <div className={styles.ogBodyCellCheckbox}>
                  <input
                    checked={bulkActionIdsFor(bulkActionsTable).includes(
                      rowData.Id,
                    )}
                    onChange={e => {
                      e.stopPropagation();
                      const { target } = e;
                      onSelectRow(rowData, target.checked);
                    }}
                    type="checkbox"
                  />
                </div>,
              )}
            </td>
          ) : null}
          {visibleFieldOrder.map(fieldName => (
            <OGCell
              key={fieldName}
              alignClass={alignClass}
              cellContent={cellContent(fieldName)}
              clickable={clickableRows}
              disabled={isRowDisabled}
              fieldName={fieldName}
              height={height}
              onClick={ev => onBodyClick(rowData, idx, ev)}
              title={clickableRowPrompt}
              truncated={truncatedFields.includes(fieldName)}
              width={`${cellWidths[fieldName]}`}
            />
          ))}
        </>
      )}
      {renderIf(
        (overflowFieldOrder.length || overflowFieldOrderField) && !expanded,
      )(
        <OGRowOverflow
          cellWidths={cellWidths}
          disabled={isRowDisabled}
          isLastRow={!!(data && idx && idx + 1 === data.length)}
          overflowFieldOrder={overflowFieldOrder}
          overflowFieldOrderField={overflowFieldOrderField}
          overflowHeaderTranslations={overflowHeaderTranslations}
          rowData={rowData}
        />,
      )}
      {renderIf(rowActions.length && !expanded)(
        <td className={styles.ogBodyCell} width={cellWidths.Actions || ''}>
          <div
            className={`${styles.ogBodyCellContent} ${styles.alignRight} ${
              isRowDisabled ? styles.disabledCell : ''
            }`}
          >
            <OGRowActions
              cellWidths={cellWidths}
              disabled={isRowDisabled}
              idx={idx}
              rowActions={rowActions}
              rowData={rowData}
              selected={
                bulkActions &&
                bulkActionIdsFor(bulkActionsTable).includes(rowData.Id)
              }
            />
          </div>
        </td>,
      )}
    </tr>
  );
};

OGRow.defaultProps = {
  bulkActions: false,
  bulkActionsSingle: false,
  bulkActionsTable: null,
  cellWidths: {},
  clickableRowPrompt: null,
  clickableRows: false,
  customBodyRowClass: '',
  customCells: {},
  customRowClass: {},
  customRowTrigger: {},
  expanded: false,
  headerLabels: {},
  height: '',
  hideBulkActionsWhen: () => false,
  highlightRow: false,
  inlineDetails: false,
  overflowFieldOrder: [],
  overflowFieldOrderField: null,
  rowActions: [],
  rowDisabled: () => false,
  truncatedFields: [],
};

OGRow.propTypes = {
  alignClass: PropTypes.func.isRequired,
  bulkActions: PropTypes.bool,
  bulkActionsSingle: PropTypes.bool,
  bulkActionsTable: PropTypes.string,
  cellWidths: PropTypes.objectOf(PropTypes.any),
  clickableRowPrompt: PropTypes.string,
  clickableRows: PropTypes.bool,
  customBodyRowClass: PropTypes.string,
  customCells: PropTypes.objectOf(PropTypes.any),
  customRowClass: PropTypes.string,
  customRowTrigger: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  expanded: PropTypes.bool,
  fieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  headerLabels: PropTypes.objectOf(PropTypes.string),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideBulkActionsWhen: PropTypes.func,
  highlightRow: PropTypes.bool,
  idx: PropTypes.number.isRequired,
  inlineDetailContent: PropTypes.func.isRequired,
  inlineDetailLabel: PropTypes.func.isRequired,
  inlineDetails: PropTypes.bool,
  overflowFieldOrder: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  overflowFieldOrderField: PropTypes.string,
  overflowHeaderTranslations: PropTypes.objectOf(PropTypes.string).isRequired,
  rowActions: PropTypes.arrayOf(PropTypes.any),
  rowClickCallback: PropTypes.func.isRequired,
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  rowDisabled: PropTypes.func,
  setExpandedRow: PropTypes.func.isRequired,
  totalColumns: PropTypes.number.isRequired,
  truncatedFields: PropTypes.arrayOf(PropTypes.string),
  visibleFieldOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OGRow;
