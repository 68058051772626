import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconViewDropdown3x2 = props => {
  const { className, fill, height, translate, width } = props;
  return (
    <svg
      className={className}
      height={height}
      version="1.1"
      viewBox="0 0 17 15"
      width={width}
      xmlns={SVG_XMLNS}
    >
      <title>{translate('VIEWS.LAYOUT_TOOLTIP')}</title>
      <g
        fill="none"
        fillRule="evenodd"
        id="view-live"
        stroke="none"
        strokeWidth="1"
      >
        <g transform="translate(-1067.000000, -56.000000)">
          <g transform="translate(110.000000, 20.000000)">
            <g transform="translate(957.000000, 36.000000)">
              <path
                d="M12,8 L17,8 L17,12 L12,12 L12,8 Z M12,3 L17,3 L17,7 L12,7 L12,3 Z M6,8 L11,8 L11,12 L6,12 L6,8 Z M6,3 L11,3 L11,7 L6,7 L6,3 Z M0,8 L5,8 L5,12 L0,12 L0,8 Z M0,3 L5,3 L5,7 L0,7 L0,3 Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconViewDropdown3x2.defaultProps = {
  className: null,
  fill: '#000000',
  height: '15px',
  width: '17px',
};

IconViewDropdown3x2.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  translate: PropTypes.func.isRequired,
  width: PropTypes.string,
};

export default withLocalize(IconViewDropdown3x2);
