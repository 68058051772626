export const BOOKMARK_FIELDS = [
  {
    type: 'text',
    label: 'COMMON.NAME',
    inputId: 'BookmarkName',
    name: 'name',
  },
  {
    type: 'textarea',
    label: 'COMMON.DESCRIPTION',
    inputId: 'BookmarkDescription',
    name: 'description',
  },
  // {
  //   type: 'duration',
  //   label: 'BOOKMARKS.FORM.DURATION',
  //   inputId: 'BookmarkDuration',
  //   name: 'Duration',
  // },
];

export const MEDIA_PLAYER_PROPERTIES = {
  height: 310,
  position: 'relative',
  width: 500,
};
export const MAX_CHARACTERS = '256';
export const MIN_DURATION_SECONDS = 0;
export const MAX_DURATION_SECONDS = 63158400; // 731 DAYS

export const PROTECTED_RADIO_OPTIONS = [
  {
    translateId: 'COMMON.YES',
    value: true,
  },
  {
    translateId: 'COMMON.NO',
    value: false,
  },
];
