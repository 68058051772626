import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from 'constants/app';

const IconX = props => {
  return (
    <div
      title={props.translate('BUTTONS.CLOSE')}
      style={{
        height: props.height,
        width: props.width,
        lineHeight: props.lineHeight,
      }}
    >
      <svg
        className={props.className}
        width={props.width}
        height={props.height}
        viewBox="0 0 11 11"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <filter
            x="-4.5%"
            y="-13.6%"
            width="109.1%"
            height="118.2%"
            filterUnits="objectBoundingBox"
            id="IconX_filter-1"
          >
            <feOffset
              dx="0"
              dy="-1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
              result="shadowMatrixOuter1"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g
          stroke="none"
          strokeWidth="1"
          fill={props.strokeFill}
          fillRule="evenodd"
        >
          <path
            stroke={props.strokeFill}
            d="M10.5704617,2.01661401 C10.70065,1.88345792 10.70065,1.66520206 10.5704617,1.53264667 L9.16474238,0.101368832 C9.03455405,-0.0313867938 8.8207856,-0.0313867938 8.69000729,0.101368832 L5.35683205,3.49494702 L2.02169023,0.099566719 C1.89071526,-0.0331889063 1.67694681,-0.0331889063 1.54675848,0.099566719 L0.141039171,1.53144527 C0.0102608634,1.66339995 0.0102608634,1.8810551 0.141039171,2.01541261 L3.47578768,5.4101922 L0.142219125,8.80437109 C0.0126207727,8.93652601 0.0126207727,9.15478187 0.142219125,9.28773773 L1.54793843,10.7192158 C1.6789134,10.8511705 1.89366515,10.8511705 2.0232635,10.7192158 L5.35643874,7.32563762 L8.68764738,10.7174137 C8.81783571,10.8499691 9.03278412,10.8499691 9.16297245,10.7174137 L10.5692817,9.28553514 C10.6990767,9.15338022 10.6990767,8.9353246 10.5692817,8.8015678 L7.23807309,5.4095915 L10.5704617,2.01661401 Z"
          />
        </g>
      </svg>
    </div>
  );
};

IconX.defaultProps = {
  className: null,
  width: '12px',
  height: '13px',
  lineHeight: '18px',
  strokeFill: '#7c7c7c',
};

export default withLocalize(IconX);
