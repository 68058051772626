// Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import Spinner from 'react-md-spinner';
import renderIf from 'render-if';
import { debounce } from 'lodash';

// Validation
import * as validation from 'util/validation';
import { defaultToInput } from 'util/languagesUtils';

import * as UserActions from 'actions/user';

// Styles
import { IconSmallCheck, IconSmallWrong } from 'icons';
import { formField } from 'components/VerticalForm/styles.css';
import { isInputError } from 'sharedStyles/isState.css';

import * as PasswordRules from 'constants/PasswordRules';
import {
  errorBody,
  icon,
  inputError,
  pageMessageIcon,
  passwordInfoBody,
  passwordInfoBoxVertical,
  passwordInfoheader,
  passwordMsg,
  passwordSecondheader,
} from './styles.css';

const mainCheckboxSize = { height: '20px', width: '20px' };

// Class
class FieldPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pswdErrors: [],
      pswdFocused: false,
      userHasInteracted: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { input, testNotLast3Passwords } = this.props;
    if (testNotLast3Passwords !== prevProps.testNotLast3Passwords) {
      this.updatePasswordValidationTests(input.value.trim());
    }
  }

  get inputError() {
    const {
      meta: { error, touched },
      translate,
    } = this.props;
    if (!touched || !error) return '';
    return typeof error === 'string'
      ? translate(error, { onMissingTranslation: defaultToInput })
      : error;
  }

  get validationStatusIcon() {
    const {
      input,
      isConfirmation,
      meta: { dirty, valid },
      newUser,
      testNotLast3Passwords,
    } = this.props;
    return (
      <>
        {renderIf(
          (testNotLast3Passwords === PasswordRules.PASSED ||
            testNotLast3Passwords === PasswordRules.PENDING) &&
            dirty &&
            (valid || !newUser),
        )(
          <div style={{ paddingLeft: '5px', paddingTop: '4px' }}>
            {valid &&
              (newUser ||
                testNotLast3Passwords === PasswordRules.PASSED ||
                isConfirmation) && (
                <IconSmallCheck
                  height={mainCheckboxSize.height}
                  width={mainCheckboxSize.width}
                />
              )}
            {this.doPasswordChecksPass(input.value) &&
              !newUser &&
              !isConfirmation &&
              testNotLast3Passwords === PasswordRules.PENDING && (
                <Spinner
                  color1="#006e99"
                  color2="#3d6f99"
                  color3="#0f4c7f"
                  color4="#006e99"
                  size={mainCheckboxSize.height}
                />
              )}
          </div>,
        )}
      </>
    );
  }

  doPasswordChecksPass(password) {
    const errors = validation.validatePasswordInput(
      password.trim(),
      false,
      PasswordRules.PENDING,
    );
    return validation.allPasswordChecksPass(errors);
  }

  startValidatePasswordRequest = debounce(passwordText => {
    this.sendValidatePasswordRequest(passwordText);
  }, 500);

  sendValidatePasswordRequest = passwordText => {
    const { actions, code, input } = this.props;
    const formData = {
      NewPassword: passwordText,
      SecurityToken: code,
      validateNotLast3PasswordsOnly: true,
    };
    this.updatePasswordValidationTests(passwordText);
    actions.requestValidateNot3OldPasswordsStatus(input.name, formData);
  };

  updatePasswordValidationTests = passwordText => {
    const { isConfirmation, newUser, testNotLast3Passwords } = this.props;
    const errors = validation.validatePasswordInput(
      passwordText,
      !newUser,
      newUser || isConfirmation ? PasswordRules.PASSED : testNotLast3Passwords,
    );
    this.setState({
      pswdErrors: errors,
    });
    return errors;
  };

  handleFocusPassword = e => {
    const { pswdFocused } = this.state;
    const { validationWindowVisible } = this.props;
    if (!pswdFocused) {
      this.setState({
        pswdFocused: true,
        userHasInteracted: true,
      });
    }

    if (validationWindowVisible) {
      this.updatePasswordValidationTests(e.target.value.trim());
    }
  };

  handleBlurPassword = e => {
    const { pswdFocused } = this.state;
    const { input } = this.props;
    input.onBlur(e);
    if (pswdFocused) {
      this.setState({
        pswdFocused: false,
        userHasInteracted: true,
      });
    }
  };

  handleChangePassword = e => {
    const { input, newUser, validationWindowVisible } = this.props;
    this.setState({ pswdFocused: true });

    if (validationWindowVisible) {
      if (
        e.target.value.trim().length !== 0 &&
        !newUser &&
        this.doPasswordChecksPass(e.target.value)
      ) {
        this.startValidatePasswordRequest(e.target.value.trim());
      } else {
        this.updatePasswordValidationTests(e.target.value.trim());
      }
    }

    input.onChange(e);
  };

  getPasswordRuleStatusIcon = testStatus => {
    let statusIcon;
    if (testStatus === PasswordRules.PASSED) {
      statusIcon = <IconSmallCheck />;
    } else if (testStatus === PasswordRules.FAILED) {
      statusIcon = <IconSmallWrong />;
    } else {
      statusIcon = (
        <Spinner
          color1="#006e99"
          color2="#3d6f99"
          color3="#0f4c7f"
          color4="#006e99"
          size={12}
        />
      );
    }
    return statusIcon;
  };

  render() {
    const {
      hideErrorLabel,
      input,
      inputId,
      meta,
      preventAutocomplete,
      showValidCheckmark,
      translate,
      validationWindowVisible,
    } = this.props;
    const { pswdErrors, pswdFocused } = this.state;
    const passwordMustHaveErrors = [];
    const passwordMustNotHaveErrors = [];

    pswdErrors.forEach(errorElement => {
      if (errorElement.type === PasswordRules.PASSWORD_MUST_HAVE) {
        passwordMustHaveErrors.push(
          <div key={errorElement.errorMessage} className={pageMessageIcon}>
            <div className={icon}>
              {this.getPasswordRuleStatusIcon(errorElement.testPassed)}
            </div>
            <div className={errorBody}>
              {translate(errorElement.errorMessage)}
            </div>
          </div>,
        );
      } else if (errorElement.type === PasswordRules.PASSWORD_MUST_NOT_HAVE) {
        passwordMustNotHaveErrors.push(
          <div key={errorElement.errorMessage} className={pageMessageIcon}>
            <div className={icon}>
              {this.getPasswordRuleStatusIcon(errorElement.testPassed)}
            </div>
            <div className={errorBody}>
              {translate(errorElement.errorMessage)}
            </div>
          </div>,
        );
      }
    });

    return (
      <div className={formField}>
        <div style={{ display: 'flex' }}>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input}
            autoComplete={preventAutocomplete ? 'new-password' : ''}
            className={meta.touched && meta.error ? isInputError : ''}
            id={inputId}
            onBlur={this.handleBlurPassword}
            onChange={this.handleChangePassword}
            onFocus={this.handleFocusPassword}
            type="password"
          />
          {renderIf(showValidCheckmark)(this.validationStatusIcon)}
        </div>
        {renderIf(!hideErrorLabel)(
          <span className={inputError}>{this.inputError}</span>,
        )}
        {validationWindowVisible && pswdFocused && (
          <div className={passwordInfoBoxVertical}>
            <div className={passwordInfoBody}>
              <div>
                <div className={passwordInfoheader}>
                  {translate('PROFILE.NO_PASSWORD_WORD_SUBHEADER')}
                </div>
                <div className={passwordMsg}>{passwordMustHaveErrors}</div>
                <div>
                  <div className={passwordSecondheader}>
                    {translate('PROFILE.NO_PASSWORD_WORD_HEADER')}
                  </div>
                  <div className={passwordMsg}>{passwordMustNotHaveErrors}</div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // props passed in directly to take precedence
  // over props in the redux store
  const message = state.pageMessage;
  return {
    props: ownProps.props || message.props,
    testNotLast3Passwords: state.user.isNotLast3Passwords[ownProps.input.name],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...UserActions,
      },
      dispatch,
    ),
  };
}

FieldPassword.defaultProps = {
  code: '',
  hideErrorLabel: false,
  input: {},
  inputId: 'password',
  isConfirmation: false,
  label: '',
  name: '',
  newUser: undefined,
  preventAutocomplete: false,
  showValidCheckmark: true,
  testNotLast3Passwords: PasswordRules.PENDING,
  validationWindowVisible: true,
};

FieldPassword.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  code: PropTypes.string,
  hideErrorLabel: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any),
  inputId: PropTypes.string,
  isConfirmation: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  newUser: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.bool,
  ]),
  preventAutocomplete: PropTypes.bool,
  showValidCheckmark: PropTypes.bool,
  testNotLast3Passwords: PropTypes.string,
  translate: PropTypes.func.isRequired,
  validationWindowVisible: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(FieldPassword));
