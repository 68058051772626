const focusedCameraSelector = state => {
  const { currentView, panelId } = state.views;

  if (currentView && currentView.Elements) {
    return currentView.Elements.find(v => v && v.Position === panelId);
  }

  return null;
};

export default focusedCameraSelector;
