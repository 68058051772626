// Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { PropTypes } from 'prop-types';

// Actions
import * as DeviceActions from 'actions/devices';
import * as UserActions from 'actions/user';
import * as AlarmActions from 'actions/alarms';
import * as LocationActions from 'actions/locations';
import * as ModalActions from 'actions/modal';
import * as NotificationsActions from 'actions/notifications';
import * as ContactsActions from 'actions/contacts';
import * as ViewsActions from 'actions/views';
import * as OrganizationActions from 'actions/organizations';
import * as SignalActions from 'actions/signals';
import * as ClusterActions from 'actions/clusters';
import { showMessage, showTranslatedError } from 'actions/pageMessage';
import { bindActionCreators } from 'redux';
import 'signalr';

// Selectors
import clusterLocationMapSelector from 'selectors/clusterLocationSelector';

// Util
import jsonParseRobust from 'util/jsonParseRobust';

// Constants
import * as urlConstants from 'constants/urlPaths';
import { SIGNALR_HUBNAME } from 'constants/app';
import * as signalTypes from 'constants/SignalTypes';
import * as cookieNames from 'constants/cookieNames';
import * as modalTypes from 'constants/ModalTypes';
import * as messageTypes from 'constants/MessageTypes';
import * as cameraTypes from 'constants/cameraTypes';
import * as cookieUtils from 'util/cookies';
import * as PushServiceConsts from './constants';
import { messageStyleStrings } from '../PageMessage/constants';

/* global $, avoLog, avoLogError */

class PushService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connection: null,
      debounceCameraSignalMap: {},
    };
  }

  componentDidMount() {
    this.establishConnection();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      actions,
      connectionTries,
      hasConnectionSubscription,
      isUpdatingConnectionSubscription,
      orgId,
      subscribedTenant,
    } = this.props;
    const { connection } = prevState;
    if (
      connection && // connection object available
      subscribedTenant && // currently subscribed as a tenant
      !hasConnectionSubscription && // flagged to tear down connection
      !isUpdatingConnectionSubscription // no API requests in flight
    ) {
      // This should only occur when ORG changes (LHS menu)
      // For a user switch, see the setUserContext action creator
      actions.unsubscribeToNotifications(connection.id, subscribedTenant);
    } else if (
      connection && // connection object available
      !subscribedTenant && // not currently subscribed as any tenant
      !isUpdatingConnectionSubscription && // no API requests in flight
      orgId && // logged in as a tenant, profile&account data available
      connectionTries <= 5 // not stuck in a request loop due to network issues
    ) {
      actions.subscribeToNotifications(
        {
          connectionId: connection.id,
          orgId,
        },
        connection,
      );
    }
  }

  componentWillUnmount() {
    const { actions, orgId } = this.props;
    const { connection } = this.state;

    // disconnect from signalr
    if (connection) {
      actions.unsubscribeToNotifications(connection.id, orgId);
      actions.setConnectionSubscription({ id: null });
      connection.stop();
    }
  }

  // Experimental helpers, only used for RegisteredCameras so far, used to debounce on a per-camera-Id basis
  // May be generalized later to debounce other responses by Id

  createDebouncingFunction = () => debounce(action => action(), 2000);

  debounceCameraSignal = (cameraId, action) => {
    const { debounceCameraSignalMap: signalMap } = this.state;
    if (signalMap[cameraId]) {
      signalMap[cameraId](action);
    } else {
      this.setState(state => {
        const debounceSignal = this.createDebouncingFunction();
        debounceSignal(action);
        const debounceCameraSignalMap = Object.assign(
          state.debounceCameraSignalMap,
          { [cameraId]: debounceSignal },
        );
        return { debounceCameraSignalMap };
      });
    }
  };

  // Helper to prevent multiple database calls for identical, clustered signals
  debounceSignal = debounce(action => action(), 2000);

  establishConnection() {
    let connection;
    let config = {};
    if (process.env.API_ENV === 'mock') {
      return;
    }
    if (process.env.API_ENV === 'dev') {
      // cross origin request, cant use sockets

      const url = `${location.protocol}//${(window.localStorage &&
        localStorage.devHost) ||
        urlConstants.HOST_DEV.replace(/\/$/, '')}`;
      connection = $.hubConnection(url);
      connection.logging = false;
      config = {
        transport: ['serverSentEvents', 'longPolling'],
      };
    } else {
      // default to same origin
      connection = $.hubConnection();
    }
    // If Oasis is enabled, replace log no-ops with working functions
    if (window.localStorage && localStorage.skeletonKey === 'true') {
      window.avoLogItems = [];
      window.avoLog = function(logMessage, logObject, logType = 'log') {
        window.avoLogItems.push({ logMessage, logObject, logType });
      };

      window.avoLogError = function(label, object) {
        avoLog(label, object, 'error');
      };
    }
    connection.logging = false;
    const hubProxy = connection.createHubProxy(SIGNALR_HUBNAME);

    const onNotficationReceived = this.notificationReceived.bind(this);
    hubProxy.on('update', onNotficationReceived);

    // after connection established
    const onStartSuccess = () => {
      const { actions } = this.props;
      const msg = `SignalR connection established with connectionId = ${connection.id}`;
      avoLog(msg);
      this.setState({ connection });
      actions.setConnectionSubscription(connection);
    };

    connection
      .start(config, onStartSuccess)
      .fail(err =>
        avoLogError('connection to SignalR *not* established. ', err),
      );

    // attempt to reconnect after disconnected

    connection.disconnected(function() {
      setTimeout(function() {
        avoLog('Attempting to reconnect to SignalR');
        connection.start();
      }, 5000);
    });
  }

  // handler for receiving push msg from server hub
  notificationReceived(payload) {
    const {
      actions,
      allAlarms,
      cameras,
      clusterLocationMap,
      currentUserId,
      devices,
      hasConnectionSubscription,
      orgId,
      pageMessage,
      selectedAlarm,
      selectedCamera,
      selectedServer,
      updatingDeviceIds,
      videoExports,
    } = this.props;

    if (payload.signalError) {
      // signalError is null if there is no error

      if (
        window.localStorage &&
        localStorage.skeletonKey !== undefined &&
        localStorage.skeletonKey === 'true'
      ) {
        actions.logSignalRMessage(payload);
      }
      if (payload.signalType === signalTypes.EXPORT_VIDEO) {
        actions.showModal(modalTypes.SHOW_ERROR, {
          messageEmphasize: PushServiceConsts.VIDEO_EXPORT_ERROR_MESSAGE_START,
          messageRegular: PushServiceConsts.VIDEO_EXPORT_ERROR_MESSAGE_FINISH,
        });
      }
      if (payload.signalType === signalTypes.FIRMWARE) {
        actions.firmwareUpgradeProgress(payload.objectId, null, null);
        actions.showMessage(messageTypes.DEVICE_ERROR, null, null, {
          data: { error: jsonParseRobust(payload.progressObject).state },
          messageStyle: messageStyleStrings.error,
          translateBody:
            'DEVICE_DETAILS.GENERAL_TAB.DEVICE_FIRMWARE_UPGRADE_ERROR_MESSAGE',
        });
      }
      if (payload.signalType === signalTypes.DISCOVERED_CAMERAS_READY) {
        let translationId = 'ERROR_MESSAGES.NO_RESULTS';
        if (
          payload.signalError.message ===
          PushServiceConsts.AUTHENTICATION_FAILED_MESSAGE
        ) {
          translationId =
            'DEVICE_DETAILS.CAMERAS_TAB.UNDISCOVERED_CAMERAS_FORM.AUTHENTICATION_ERROR';
        }
        actions.showTranslatedError(
          messageTypes.DISCOVERED_CAMERAS_ERROR,
          translationId,
        );
      }
      return;
    }
    if (
      window.localStorage &&
      localStorage.skeletonKey !== 'undefined' &&
      localStorage.skeletonKey === 'true'
    ) {
      actions.logSignalRMessage(payload);
    }

    switch (payload.signalType) {
      case signalTypes.ALARM: {
        actions.getUpdatedAlarm(payload.objectId, orgId); // Update the signalled alarm in the alarm list
        break;
      }
      case signalTypes.LOCATIONS: {
        if (payload.signalVerb === PushServiceConsts.DELETE) {
          actions.removeLocation(payload.objectId);
        } else {
          actions.getUpdatedLocation(payload.objectId);
          actions.getUsers();
        }
        break;
      }
      case signalTypes.USER_LOGOUT: {
        // this user-id logged out of a browser
        // if their sessionCookie matches the input payload
        // we'll log them out of this browser session too
        const cookieVal = cookieUtils.getCookie(cookieNames.SESSION_COOKIE);
        if (payload.objectId === cookieVal) {
          // pass do-not-propagate to 'logout', we'll just log out of this one session
          actions.logout(false);
        }
        break;
      }
      case signalTypes.SCHEDULES: {
        // not many schedules, so just re-fetch
        actions.getSchedules();
        break;
      }
      case signalTypes.USERS: {
        if (payload.signalVerb === PushServiceConsts.DELETE) {
          actions.removeUser(payload.objectId);
        } else {
          actions.getUser(payload.objectId);
        }
        if (payload.objectId === currentUserId) {
          actions.getUserProfile();
          actions.getOrganizations();
          // This signal is also used to represent a change in site access
          actions.getLocations();
        }
        break;
      }
      case signalTypes.PROFILE: {
        actions.getUserProfile();
        actions.getOrganizations();
        break;
      }
      case signalTypes.CUSTOMERS: {
        actions.getCustomerOrganization(payload.objectId);
        actions.getUsers();
        break;
      }
      case signalTypes.CONTACTS: {
        const { objectId, signalVerb } = payload;
        const { CREATE, DELETE, UPDATE } = PushServiceConsts;

        if (signalVerb === CREATE || signalVerb === UPDATE) {
          actions.getContact(objectId);
        } else if (signalVerb === DELETE) {
          actions.getContacts();
        }

        break;
      }
      case signalTypes.DEVICES: {
        this.debounceSignal(() => {
          // If the signal verb (of signal type DEVICES) is CREATE, the payload.objectId should
          // be a JSON object which contains the serverId, and the subscriberTenantId. This allows
          // the audience tenantId to be either the subscriber or dealer. In the case, where it is
          // the dealer, it will not equal the subscriberTenantId, otherwise they will be identical.

          let serverId;
          let subscriberTenantId;

          if (typeof payload.objectId === 'object') {
            serverId = payload.objectId.serverId;
            subscriberTenantId = payload.objectId.subscriberTenantId;
          } else {
            serverId = payload.objectId;
            subscriberTenantId = this.props.orgId;
          }

          if (payload.signalVerb === PushServiceConsts.DELETE) {
            const deletedDevice = devices.find(d => d.Id === serverId);
            // Only get all devices if the deleted device is still in redux state.
            if (deletedDevice) {
              actions.removeDevice(serverId);
            }
            actions.getLocations();
            return; // If a device was deleted, don't waste time doing this other stuff.
          }
          if (serverId) {
            actions.getDevice(serverId, subscriberTenantId);
          }
          const updatedDevice = devices.find(d => d.Id === serverId);
          if (updatedDevice) {
            actions.getUpdatedLocation(updatedDevice.SiteId);
          } else {
            actions.getLocations();
          }
          // If the device was in the process of upgrading firmware, it is now done
          if (updatingDeviceIds.includes(serverId)) {
            actions.firmwareUpgradeProgress(serverId);
            actions.getSitesWithUpgrades();
          }

          // If the device was fetching a log, stop it
          actions.receiveDeviceLogs(serverId);
          if (payload.signalVerb === PushServiceConsts.UPDATE) {
            // If the device has active cameras, better update those as well.
            cameras
              .filter(c => c.DeviceId === serverId && c.Active)
              .forEach(camera => actions.getCamera(camera.Id));
          }
        });
        break;
      }

      case signalTypes.CLUSTERS: {
        if (payload.signalVerb === PushServiceConsts.DELETE) {
          const locationId = clusterLocationMap[payload.objectId];
          actions.removeCluster(locationId, payload.objectId);
          actions.getUpdatedLocation(locationId);
        }
        if (payload.signalVerb === PushServiceConsts.CREATE) {
          actions.getCluster(payload.objectId);
        }
        break;
      }

      case signalTypes.PROVIDER_INTEGRATION_CONFIGURATIONS: {
        actions.getIntegrationConfigs();
        break;
      }

      case signalTypes.SUBSCRIBER_INTEGRATION_CONFIGURATIONS: {
        actions.getIntegrationSubscriberSummary();
        actions.getIntegrationSubscriberSites(payload.objectId);
        break;
      }
      case signalTypes.CAMERAS: {
        if (
          payload.signalVerb === PushServiceConsts.UPDATE ||
          payload.signalVerb === PushServiceConsts.CREATE
        ) {
          if (selectedCamera && payload.objectId === selectedCamera.Id) {
            this.debounceSignal(() => {
              const { DeviceId, Id, RemoteId } = selectedCamera;
              actions.getAnalyticsStatus(DeviceId, RemoteId, Id);
              actions.getAllCameraSettings(DeviceId, RemoteId, Id);
              actions.getCamera(Id);
            });
          }
        }
        break;
      }
      case signalTypes.CAMERA_SNAPSHOT: {
        actions.flagStaleSnapshotURL(payload.objectId);
        break;
      }
      case signalTypes.REGISTERED_CAMERAS: {
        const cameraId =
          jsonParseRobust(payload.objectId).cameraId || payload.objectId;
        if (
          payload.signalVerb === PushServiceConsts.CREATE ||
          payload.signalVerb === PushServiceConsts.UPDATE
        ) {
          if (cameraId) {
            this.debounceCameraSignal(cameraId, () => {
              actions.getCamera(cameraId); // This call will also update the serversAndCameras table
            });
          }
        } else if (payload.signalVerb === PushServiceConsts.DELETE) {
          actions.removeFromConnectedCameras(cameraId);
        }
        break;
      }
      case signalTypes.DISCOVERED_CAMERAS_READY: {
        // If we're not adding or deleting a camera, signalVerb is null
        if (
          payload.signalVerb === PushServiceConsts.CREATE &&
          selectedServer === payload.objectId
        ) {
          const isTriggeredByManualSearch =
            pageMessage.messageType ===
            messageTypes.FETCHING_DISCOVERED_CAMERA_MANUALLY;
          actions.getDiscoveredCameraResults(
            payload.objectId,
            payload.correlationId,
            isTriggeredByManualSearch,
          );
        }
        break;
      }
      case signalTypes.VIEWS: {
        actions.getViews();
        break;
      }
      case signalTypes.CREDENTIALS_REVOKED: {
        actions.logout();
        break;
      }
      case signalTypes.EXPORT_VIDEO: {
        const videoExportData = videoExports[payload.objectId];
        actions.videoExportGetStream(
          videoExportData.serverId,
          videoExportData.cameraId,
          payload.objectId,
          videoExportData.orgId,
        );
        break;
      }
      case signalTypes.EXPORT_LOG: {
        const logId = payload.objectId;
        const deviceId = payload.objectId;
        actions.getDeviceLogs(deviceId, logId);
        break;
      }
      case signalTypes.FIRMWARE: {
        const deviceId = payload.objectId;
        const device = devices.find(d => d.Id === deviceId);
        // Ignore signals for deleted or disconnected devices
        if (device && device.ConnectionState === cameraTypes.CAMERA_CONNECTED) {
          const progressObject = jsonParseRobust(payload.progressObject);
          const status = progressObject.state;
          let progress = 0;
          let speed = 0;
          if (status === signalTypes.DOWNLOADING) {
            progress = Math.floor(
              progressObject.progress
                ? progressObject.progress.percentComplete * 100
                : 0,
            );
            speed = Math.floor(
              progressObject.progress ? progressObject.progress.Mbps : 0,
            );
          }
          actions.firmwareUpgradeProgress(deviceId, status, progress, speed);
        } else if (!device && deviceId) {
          // If for some reason we don't have the device, get the device.
          actions.getDevice(deviceId);
        }
        break;
      }
      case signalTypes.SWITCH_USER: {
        // check if the session id in the cookie matches the input payload
        const cookieVal = cookieUtils.getCookie(cookieNames.SESSION_COOKIE);
        if (payload.correlationId === cookieVal || cookieVal === null) {
          // Cookie session tag is not available over http
          // so only https instances with defined session ids in the cookie will
          // ignore this signal
          window.location.reload();
        }
        break;
      }
      case signalTypes.BANNER_NOTICE: {
        actions.getBannerNotices();
        break;
      }
      case signalTypes.REBOOT: {
        if (payload.signalVerb === PushServiceConsts.UPDATE) {
          const locationId = payload.objectId;
          actions.getLocationDevices(locationId);
          actions.getAllCameras();
        }
        break;
      }
      case signalTypes.SUBSCRIPTION_CHANGE_REQUEST: {
        if (
          payload.signalVerb === PushServiceConsts.CREATE ||
          payload.signalVerb === PushServiceConsts.DELETE
        ) {
          actions.getPendingSiteRequests();
          actions.getLocations();
          actions.getIntegrationSubscriberSummary();
          actions.getIntegrationSubscriberSites(payload.objectId);
        }
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { children, orgId } = this.props;
    return <div key={orgId}>{children}</div>;
  }
}

function mapStateToProps(state) {
  const orgId = state.user.profile.CustomerTenantId
    ? state.user.profile.CustomerTenantId
    : state.user.profile.TenantId;
  const currentUserId = state.user.profile.Id;
  const { selectedAlarm } = state.alarms;
  let selectedCamera = null;
  if (state.devices.selectedCamera) {
    selectedCamera = state.devices.cameras.find(
      camera => camera.Id === state.devices.selectedCamera,
    );
  }
  return {
    allAlarms: state.alarms.allAlarms,
    cameras: state.devices.cameras,
    clusterLocationMap: clusterLocationMapSelector(state),
    connectionTries: state.notifications.connectionTries,
    currentUserId,
    devices: state.devices.devices,
    hasConnectionSubscription: state.notifications.hasConnectionSubscription,
    isUpdatingConnectionSubscription:
      state.notifications.isUpdatingConnectionSubscription,
    orgId,
    pageMessage: state.pageMessage,
    profile: state.user.profile,
    selectedAlarm,
    selectedCamera,
    selectedServer: state.devices.selectedServer,
    subscribedTenant: state.notifications.subscribedTenant,
    updatingDeviceIds: Object.keys(state.devices.firmwareUpgrade),
    videoExports: state.devices.videoExports,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...UserActions,
        ...AlarmActions,
        ...LocationActions,
        ...OrganizationActions,
        ...NotificationsActions,
        ...ContactsActions,
        ...ViewsActions,
        ...ModalActions,
        ...SignalActions,
        ...ClusterActions,
        showMessage,
        showTranslatedError,
      },
      dispatch,
    ),
  };
}

PushService.defaultProps = {
  children: null,
  pageMessage: {
    messageType: null,
  },
  selectedCamera: null,
  selectedServer: null,
  subscribedTenant: null,
};

PushService.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  allAlarms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cameras: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  clusterLocationMap: PropTypes.shape({}).isRequired,
  connectionTries: PropTypes.number.isRequired,
  currentUserId: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasConnectionSubscription: PropTypes.bool.isRequired,
  isUpdatingConnectionSubscription: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
  pageMessage: PropTypes.shape({
    messageType: PropTypes.string,
  }),
  selectedAlarm: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCamera: PropTypes.string,
  selectedServer: PropTypes.string,
  subscribedTenant: PropTypes.string,
  updatingDeviceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  videoExports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PushService);
