import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import itemUpsert from '../util/itemUpsert';
import concatUpsert from '../util/concatUpsert';
import customCameraObject from '../util/customCameraObject';
import jsonParseRobust from '../util/jsonParseRobust';
import audioReducers from './deviceReducer/audio.js';
import ptzReducers from './deviceReducer/ptz.js';
import * as cameraTypes from '../constants/cameraTypes';

export default function deviceReducer(state, action = {}) {
  if (audioReducers && audioReducers[action.type]) {
    return audioReducers[action.type](state, action);
  }
  if (ptzReducers && ptzReducers[action.type]) {
    return ptzReducers[action.type](state, action);
  }

  switch (action.type) {
    case types.RECEIVE_SNAPSHOT_URL: {
      const snapshots = Object.assign({}, state.snapshots, {
        [action.cameraId]: { uri: action.uri, cameraId: action.cameraId },
      });
      return Object.assign({}, state, { snapshots });
    }

    case types.FLAG_STALE_SNAPSHOT_URL: {
      // TODO: Needs unit test
      if (state.snapshots[action.cameraId]) {
        const currentSnapshot = Object.assign(
          {},
          state.snapshots[action.cameraId],
          { stale: action.value },
        );
        const snapshots = Object.assign({}, state.snapshots, {
          [action.cameraId]: currentSnapshot,
        });
        return Object.assign({}, state, { snapshots });
      }
      return state;
    }

    case types.RECEIVE_SNAPSHOT_SOURCE: {
      const snapshotSource = Object.assign({}, state.snapshotSource, {
        [action.cameraId]: {
          ...action,
          isFetchingSnapshot: false,
        },
      });
      return Object.assign({}, state, { snapshotSource });
    }

    case types.CLEAR_SNAPSHOT_SOURCE: {
      const snapshotSource = Object.assign({}, state.snapshotSource, {
        [action.cameraId]: null,
      });
      return Object.assign({}, state, { snapshotSource });
    }

    case types.IS_FETCHING_MEDIA_PARAMS: {
      const { mediaParamsId } = action.data;
      const mediaParamItem = Object.assign(
        {},
        state.mediaParams[mediaParamsId] || {},
        { isFetching: action.value },
      );
      const mediaParams = Object.assign({}, state.mediaParams, {
        [mediaParamsId]: mediaParamItem,
      });
      return Object.assign({}, state, { mediaParams });
    }

    case types.RECEIVE_MEDIA_PARAMS: {
      const id = action.mediaParamsId;
      const item = {};
      item[id] = action.data;
      item[id].isFetching = false;
      const list = state.mediaParams;
      const mediaParams = Object.assign({}, list, item);
      const _state = Object.assign({}, state, {
        mediaParams,
      });
      return _state;
    }

    case types.FLAG_STREAM_USED:
    case types.RECEIVE_STREAM_DATA: {
      let mediaParams = {};
      const list = state.mediaParams;
      const keys = Object.keys(list);
      const streamData = action.data;
      const { streamName } = action;
      const key = keys.find(
        key =>
          list[key] &&
          list[key].params &&
          list[key].params.streamName === streamName,
      );
      if (key) {
        const params = Object.assign({}, list[key].params, streamData);
        const item = {};
        item[key] = Object.assign({}, list[key], { params });
        mediaParams = Object.assign({}, list, item);
      } else {
        avoLog('no match');
      }
      return Object.assign({}, state, { mediaParams });
    }

    case types.ADD_DEVICE: {
      return state;
    }

    case types.REMOVE_DEVICE: {
      const { deviceId } = action;
      const connDevicesNewList = state.devices;
      if (state.devices && state.devices.length > 0) {
        return Object.assign({}, state, {
          devices: connDevicesNewList.filter(device => device.Id !== deviceId),
        });
      }
      return state;
    }

    case types.REMOVE_CLUSTER: {
      const newCameras = [];
      state.cameras.forEach(camera => {
        if (camera.GroupId !== action.clusterId) {
          newCameras.push({ ...camera });
        }
      });
      return Object.assign({}, state, { cameras: newCameras });
    }

    case types.IS_RECEIVING_SITEIDSWITHUPGRADES: {
      return Object.assign({}, state, {
        sitesWithUpgrades: action.sitesWithUpgrades,
      });
    }

    case types.IS_FETCHING_LOCATION_DEVICES: {
      const isFetchingLocationDeviceData = {
        ...state.isFetchingLocationDeviceData,
        [action.data.locationId]: action.value,
      };
      return Object.assign({}, state, {
        isFetchingLocationDeviceData,
      });
    }

    case types.RECEIVE_LOCATION_DEVICES: {
      const updatedDevices = concatUpsert(
        state.devices,
        action.locationDevices,
      );
      return Object.assign({}, state, {
        devices: updatedDevices,
      });
    }

    case types.IS_FETCHING_DEVICE_SNAPSHOTS: {
      return Object.assign({}, state, {
        isFetchingDeviceSnapshots: Object.assign(
          {},
          state.isFetchingDeviceSnapshots,
          {
            [action.data.deviceId]: action.value,
          },
        ),
      });
    }

    case types.RECEIVE_DEVICE_POE: {
      const poe = Object.assign({}, state.poe, {
        [action.deviceId]: action.poe,
      });
      return Object.assign({}, state, { poe });
    }

    case types.CLEAR_LOCATION_DEVICES: {
      return Object.assign({}, state);
    }

    case types.RECEIVE_DEVICES: {
      const devices = concatUpsert(state.devices, action.devices);
      return Object.assign({}, state, {
        devices,
        isFetchingDeviceData: false,
      });
    }

    case types.RECEIVE_DEVICE: {
      if (action.device && action.device.Id) {
        const devices = itemUpsert(state.devices, action.device);
        return Object.assign({}, state, {
          devices,
        });
      }
      return state;
    }

    case types.RECEIVE_ALL_SERVERS_AND_CAMERAS: {
      return { ...state, serversAndCameras: action.serversAndCameras };
    }

    case types.UPGRADE_DEVICE_PROGRESS: {
      const firmwareUpgrade = Object.assign({}, state.firmwareUpgrade, {
        [action.id]: {
          status: action.status,
          progress: action.progress,
          speed: action.speed,
        },
      });
      return Object.assign({}, state, { firmwareUpgrade });
    }

    case types.RECEIVE_LATEST_FIRMWARE: {
      return Object.assign({}, state, {
        firmwareImage: action.firmwareImage,
        isFetchingFirmwareImage: false,
      });
    }

    case types.IS_FETCHING_LATEST_FIRMWARE: {
      return Object.assign({}, state, {
        isFetchingFirmwareImage: action.value,
      });
    }

    case types.IS_FETCHING_DEVICE_LOGS: {
      const oldLog = state.logs[action.data.id] || {};
      const newLog = { ...oldLog, isFetching: action.value };
      if (action.data && action.data.error) {
        newLog.error = action.data.error;
      }
      const logs = Object.assign({}, state.logs, { [action.data.id]: newLog });
      return Object.assign({}, state, { logs });
    }

    case types.RECEIVE_DEVICE_LOGS: {
      const oldLog = state.logs[action.id] || {};
      const newLog = { ...oldLog, uri: action.uri || null, isFetching: false };
      const logs = Object.assign({}, state.logs, { [action.id]: newLog });
      return Object.assign({}, state, { logs });
    }

    case types.RECEIVE_SUPPORTED_CAMERAS: {
      return Object.assign({}, state, {
        supportedCameras: action.cameras.sort(),
        isFetchingSupportedCameras: false,
      });
    }

    case types.RECEIVE_SERVER_CAPABILITIES: {
      return Object.assign({}, state, {
        serverCapabilities: action.serverCapabilities,
        isFetchingServerCapabilities: false,
      });
    }

    case types.RECEIVE_CAMERAS: {
      let newCameras = concatUpsert(state.cameras, action.cameras);
      let selectedNewCamera = state.selectedCamera;
      if (
        action.cameras.length === 1 &&
        state.selectedCamera &&
        action.cameras[0].Id === state.selectedCamera
      ) {
        const parsedStatus = jsonParseRobust(
          action.cameras[0].ConnectionStatus,
        );
        if (
          parsedStatus.errorFlags.includes(cameraTypes.CAMERA_DISABLED_BY_USER)
        ) {
          selectedNewCamera = null;
        }
      }
      // Also initialize or update snapshot uris
      const newSnapshots = {};
      action.cameras.forEach(camera => {
        if (camera.SnapshotUri) {
          newSnapshots[camera.Id] = { uri: camera.SnapshotUri };
        }
      });
      const snapshots = { ...state.snapshots, ...newSnapshots };

      newCameras = newCameras.map(camera => {
        const status = jsonParseRobust(camera.ConnectionStatus);
        if (
          status.state === 'FACTORY_DEFAULT' &&
          !status.errorFlags.includes('FACTORY_DEFAULT')
        ) {
          return { ...camera, ConnectionState: 'CONNECTED' };
        }
        return camera;
      });

      const tableCameras = customCameraObject(newCameras);
      const serversAndCameras = concatUpsert(
        state.serversAndCameras,
        tableCameras,
      );

      return Object.assign({}, state, {
        cameras: newCameras,
        serversAndCameras,
        selectedCamera: selectedNewCamera,
        snapshots,
      });
    }

    case types.IS_FETCHING_DEVICE_DATA: {
      return Object.assign({}, state, { isFetchingDeviceData: action.value });
    }

    case types.IS_FETCHING_DISCOVERED_CAMERAS: {
      return Object.assign({}, state, { isFetchingDiscoveredCameras: true });
    }

    case types.IS_FETCHING_SUPPORTED_CAMERAS: {
      return Object.assign({}, state, {
        isFetchingSupportedCameras: action.value,
      });
    }

    case types.IS_FETCHING_SERVER_CAPABILITIES: {
      return Object.assign({}, state, {
        isFetchingServerCapabilities: action.value,
      });
    }

    case types.DEVICE_FETCH_COMPLETED: {
      return Object.assign({}, state, { isFetchingDeviceData: false });
    }

    case types.CLAIM_DEVICE_SUCCESS: {
      return {
        ...state,
        claimedDeviceId: action.deviceId,
        isFetchingDeviceData: false,
      };
    }

    case types.RECEIVE_USERS: {
      const grpId = action.cameraGroupId;
      const usersNew = action.users.reduce(function(previous, current) {
        previous[current.Id] = current;
        return previous;
      }, {});
      const userIds = Object.keys(usersNew);
      const groups = Object.assign({}, state.cameraGroups);
      if (groups[grpId]) {
        groups[grpId].userIds = userIds;
      }
      const users = Object.assign({}, state.users, { ...usersNew });
      return Object.assign({}, state, {
        cameraGroups: groups,
        users,
      });
    }

    case types.RECEIVE_CLAIM_KEY: {
      const keyObj = {
        serverId: action.data.serverId,
        claimKey: action.data.claimingkey,
      };
      const keys = Object.assign({}, state.claimKeys, {
        [action.data.serverId]: keyObj,
      });
      return Object.assign({}, state, {
        claimKeys: keys,
        claimError: null,
        isFetchingDeviceData: false,
      });
    }

    case types.RECEIVE_CLAIM_ERROR: {
      return Object.assign({}, state, { claimError: action.error });
    }

    case types.RECEIVE_ACTIVATION_CODE: {
      return Object.assign({}, state, {
        deviceActivationCode: action.deviceActivationCode,
      });
    }

    case types.SET_SERVER_BREADCRUMB: {
      return { ...state, serverBreadcrumb: action.server };
    }
    case types.UNSET_SERVER_BREADCRUMB: {
      return { ...state, serverBreadcrumb: '' };
    }

    case types.RECEIVE_SELECTED_CAMERA: {
      const { cameraId } = action;
      let deviceId;

      if (state.devices) {
        const devices = state.devices.filter(
          device =>
            device.childCameraIds && device.childCameraIds.includes(cameraId),
        );

        if (devices && devices.length > 0) {
          deviceId = devices[0].Id;
        }
      }
      // When a new camera is selected,
      // flag that rules and settings may need to be refetched
      const {
        isFetchingRuleData,
        isFetchingAcquisitionData,
        isFetchingCompressionData,
        isFetchingDeviceEntities,
        isFetchingPrivacyZones,
        isFetchingCameraSettings,
      } = initialState().devices;
      return Object.assign({}, state, {
        selectedCamera: cameraId,
        selectedDevice: deviceId,
        isFetchingRuleData,
        isFetchingAcquisitionData,
        isFetchingCompressionData,
        isFetchingDeviceEntities,
        isFetchingPrivacyZones,
        isFetchingCameraSettings,
      });
    }

    case types.RECEIVE_SELECTED_SERVER: {
      return Object.assign({}, state, {
        selectedServer: action.serverId,
      });
    }

    case types.RECEIVE_DISCOVERED_CAMERAS: {
      return Object.assign({}, state, {
        discoveredCameras: action.cameras,
        isFetchingDiscoveredCameras: false,
      });
    }

    case types.UPDATE_DISCOVERED_CAMERAS_CREDENTIALS: {
      const {
        username,
        password,
        address,
        startIpAddress,
        endIpAddress,
        uri,
        actionType,
      } = action.credentials;

      const credentials = [...state.discoveredCamerasCredentials];

      let existingCredential = null;

      switch (actionType) {
        case types.MANUALLY_DISCOVER_CAMERA: {
          existingCredential = credentials.find(
            c =>
              c.actionType === types.MANUALLY_DISCOVER_CAMERA &&
              c.address === address,
          );
          break;
        }
        case types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE: {
          existingCredential = credentials.find(
            c =>
              c.actionType === types.MANUALLY_DISCOVER_CAMERAS_BY_RANGE &&
              c.startIpAddress === startIpAddress &&
              c.endIpAddress === endIpAddress,
          );
          break;
        }
        case types.MANUALLY_DISCOVER_RTSP_CAMERA: {
          existingCredential = credentials.find(
            c =>
              c.actionType === types.MANUALLY_DISCOVER_RTSP_CAMERA &&
              c.uri === uri,
          );
          break;
        }
        default:
          break;
      }

      if (existingCredential) {
        existingCredential.username = username;
        existingCredential.password = password;
      } else {
        credentials.push(action.credentials);
      }

      return Object.assign({}, state, {
        discoveredCamerasCredentials: credentials,
      });
    }

    case types.RECEIVE_CONNECTED_CAMERAS: {
      return Object.assign({}, state, { connectedCameras: action.cameras });
    }

    case types.CLEAR_CAMERAS: {
      return Object.assign({}, state, {
        connectedCameras: [],
        discoveredCameras: [],
      });
    }

    case types.REMOVE_FROM_CONNECTED_CAMERA: {
      const { cameraId } = action;
      if (state.cameras) {
        const connCamerasNewList = state.cameras.filter(
          camera => camera.Id !== cameraId,
        );
        if (connCamerasNewList.length !== state.cameras.length) {
          return Object.assign({}, state, {
            cameras: connCamerasNewList,
          });
        }
      }
      return state;
    }

    case types.SELECT_DISCOVERED_CAMERA: {
      return Object.assign({}, state, {
        selectedDiscoveredCamera: action.camera,
        selectedRowIndex: action.index,
      });
    }

    case types.IS_FETCHING_SNAPSHOTS: {
      return Object.assign({}, state, { isFetchingSnapshots: true });
    }

    case types.IS_FETCHING_SNAPSHOT_SOURCE: {
      let snapshotData = {};
      if (state.snapshotSource[action.data.cameraId]) {
        snapshotData = state.snapshotSource[action.data.cameraId];
      }
      const snapshotSource = Object.assign({}, state.snapshotSource, {
        [action.data.cameraId]: {
          ...snapshotData,
          isFetchingSnapshot: action.value,
        },
      });
      return Object.assign({}, state, { snapshotSource });
    }

    case types.SNAPSHOT_REQUEST_COMPLETED: {
      return Object.assign({}, state, { isFetchingSnapshots: false });
    }

    case types.IS_FETCHING_DEVICE_ENTITIES: {
      const isFetchingDeviceEntities = Object.assign(
        {},
        state.isFetchingDeviceEntities,
        { [action.data.deviceId]: action.value },
      );
      return Object.assign({}, state, { isFetchingDeviceEntities });
    }

    case types.RECEIVE_DEVICE_ENTITIES: {
      const entities = Object.assign({}, state.entities, {
        [action.deviceId]: action.entities.entities,
      });
      const isFetchingDeviceEntities = Object.assign(
        {},
        state.isFetchingDeviceEntities,
        { [action.deviceId]: false },
      );
      return Object.assign({}, state, { entities, isFetchingDeviceEntities });
    }

    case types.IS_FETCHING_ENTITY_SETTINGS: {
      const isFetchingEntitySettings = Object.assign(
        {},
        state.isFetchingEntitySettings,
        { [action.data.entityId]: action.value },
      );
      return Object.assign({}, state, { isFetchingEntitySettings });
    }

    case types.RECEIVE_ENTITY_SETTINGS: {
      const entitySettings = Object.assign({}, state.entitySettings, {
        [action.entityId]: action.settings,
      });
      const isFetchingEntitySettings = Object.assign(
        {},
        state.isFetchingEntitySettings,
        { [action.entityId]: false },
      );
      return Object.assign({}, state, {
        entitySettings,
        isFetchingEntitySettings,
      });
    }

    case types.RECEIVE_RULES: {
      const currentRules = state.rules;
      const currentSettings = state.settings;
      // Update cameraSettings
      const newCameraSettings = Object.assign(
        {},
        currentSettings[action.cameraId] || { analytics: null },
      );
      newCameraSettings.analytics = action.rules.analytics;
      const settings = Object.assign({}, currentSettings, {
        [action.cameraId]: newCameraSettings,
      });

      // Update rules with normalized rules objects
      const { events, rois, beams } = action.rules.analytics;
      const cameraRulesContent = {
        eventId: events.id,
        eventCount: 0,
        roiId: rois.id,
        roiCount: 0,
        beamId: beams.id,
        beamCount: 0,
        rules: {},
      };
      if (events.val) {
        events.val.forEach(event => {
          const ruleObject = { event, roi: null, loi: null };
          cameraRulesContent.eventCount += 1;
          // roi = Region Of Interest, loi = Line Of Interest
          // beams are distinguished from rois by whether roi or loi is null
          const roi =
            rois.val && rois.val.find(roi => roi.id === event.sensorId);
          if (roi) {
            cameraRulesContent.roiCount += 1;
            // received angle is -179 to 180 range, convert to 0 to 360 for UI
            if (roi.directionAngle) {
              const calculatedAngle =
                roi.directionAngle >= 0
                  ? 180 - roi.directionAngle
                  : roi.directionAngle * -1 + 180;
              roi.directionAngle = calculatedAngle;
            }
            ruleObject.roi = roi;
          }
          const loi =
            beams.val &&
            beams.val.lois &&
            beams.val.lois.find(loi => loi.id === event.sensorId);
          if (loi) {
            cameraRulesContent.beamCount += 1;
            ruleObject.loi = loi;
          }
          cameraRulesContent.rules[event.sensorId] = ruleObject;
        });
      }
      const newCameraRules = { [action.cameraId]: cameraRulesContent };

      const rules = Object.assign({}, currentRules, newCameraRules);
      return Object.assign({}, state, { rules, settings });
    }

    case types.RECEIVE_ANALYTICS_STATUS: {
      let settings = Object.assign({}, state.settings);
      const cameraId = action.id;
      const updatedSettings = Object.assign({}, settings[cameraId], {
        remoteAnalyticsEnabled: action.status,
      });
      settings[cameraId] = updatedSettings;
      return Object.assign({}, state, { settings });
    }

    case types.IS_FETCHING_RECORDING_TIMELINE: {
      const timelineItem = Object.assign(
        {},
        state.timelines[action.data.cameraId] || {},
        { isFetching: action.value },
      );
      const timelines = { ...state.timelines };
      timelines[action.data.cameraId] = timelineItem;
      return Object.assign({}, state, { timelines });
    }

    case types.RECEIVE_RECORDING_TIMELINE: {
      const oldTimelineItem = state.timelines[action.cameraId];
      const record = action.record.map(item => ({
        ...item,
        scope: action.scope,
      }));
      const motion = action.motion.map(item => ({
        ...item,
        scope: action.scope,
      }));
      // TODO: This does not preserve the chronological order of items
      if (oldTimelineItem.record && oldTimelineItem.record.length > 0) {
        oldTimelineItem.record.forEach(item => {
          if (
            !record.find(
              r =>
                r.start === item.start &&
                r.end === item.end &&
                r.scope === item.scope,
            )
          ) {
            record.push(item);
          }
        });
      }
      if (oldTimelineItem.motion && oldTimelineItem.motion.length > 0) {
        oldTimelineItem.motion.forEach(item => {
          if (
            !motion.find(
              r =>
                r.start === item.start &&
                r.end === item.end &&
                r.scope === item.scope,
            )
          ) {
            motion.push(item);
          }
        });
      }
      const timelineItem = { record, motion, isFetching: false };
      const timelines = Object.assign({}, state.timelines, {
        [action.cameraId]: timelineItem,
      });
      return Object.assign({}, state, { timelines });
    }

    case types.IS_FETCHING_CAMERA_SETTINGS: {
      return Object.assign({}, state, {
        isFetchingCameraSettings: {
          isFetching: action.value,
          error: action.data.error,
        },
      });
    }

    case types.IS_UPDATING_CAMERA_SETTINGS: {
      return Object.assign({}, state, {
        isSavingCameraSettings: true,
      });
    }

    case types.DONE_UPDATING_CAMERA_SETTINGS: {
      return Object.assign({}, state, {
        isSavingCameraSettings: false,
      });
    }

    case types.IS_FETCHING_ACQUISITION_DATA: {
      return Object.assign({}, state, { isFetchingAcquisitionData: true });
    }

    case types.IS_FETCHING_COMPRESSION_DATA: {
      return Object.assign({}, state, { isFetchingCompressionData: true });
    }

    case types.DONE_FETCHING_ACQUISITION_DATA: {
      return Object.assign({}, state, { isFetchingAcquisitionData: false });
    }

    case types.DONE_FETCHING_COMPRESSION_DATA: {
      return Object.assign({}, state, { isFetchingCompressionData: false });
    }

    case types.RECEIVE_IMAGE_SETTINGS: {
      const newCameraSettings = action.settings;
      const currentSettings = state.settings;
      const oldCameraSettings = currentSettings[action.cameraId] || {};
      const newSettings = {};
      newSettings[action.cameraId] = Object.assign(
        {},
        oldCameraSettings,
        newCameraSettings,
      );
      const settings = Object.assign({}, currentSettings, newSettings);
      return Object.assign({}, state, {
        settings,
        isFetchingImageSettings: false,
      });
    }
    // clear hw cache except for orgs
    case types.RESET_USER_CONTEXT: {
      // Preserve claim keys so internal claiming works
      // TODO: Maybe just don't call setOrganization when claiming container is loaded
      return Object.assign({}, initialState().devices, {
        claimKeys: state.claimKeys,
      });
    }
    // clear hardware cache
    case types.UNSET_USER: {
      return initialState().devices;
    }

    case types.IS_FETCHING_GATEWAY_GENERAL_SETTINGS: {
      return Object.assign({}, state, { isFetchingPrivacyZones: true });
    }
    case types.DONE_FETCHING_GATEWAY_GENERAL_SETTINGS: {
      return Object.assign({}, state, { isFetchingPrivacyZones: false });
    }

    case types.RECEIVE_CAMERA_VIDEO_EXPORT: {
      const id = action.videoExportId;
      const item = {};
      item[id] = action.data;
      const list = state.videoExports;
      const videoExports = Object.assign({}, list, item);
      return Object.assign({}, state, {
        videoExports,
      });
    }

    case types.RECEIVE_CAMERA_VIDEO_STREAM: {
      const currentVideoExports = state.videoExports;
      delete currentVideoExports[action.videoExportId];
      return Object.assign({}, state, {
        videoExports: currentVideoExports,
      });
    }

    case types.RECEIVE_PTZ_TOURS: {
      const tours = Object.assign({}, state.tours, {
        [action.deviceId]: action.tours,
      });
      const isFetchingPtzTours = Object.assign({}, state.isFetchingPtzTours, {
        [action.deviceId]: false,
      });
      return Object.assign({}, state, { tours, isFetchingPtzTours });
    }

    case types.IS_FETCHING_PTZ_TOURS: {
      const isFetchingPtzTours = Object.assign({}, state.isFetchingPtzTours, {
        [action.data.deviceId]: action.value,
      });
      return Object.assign({}, state, { isFetchingPtzTours });
    }

    case types.SET_ACTIVE_PTZ_FUNCTION: {
      return Object.assign({}, state, { activePtzFunction: action.name });
    }

    default:
      return state || initialState().devices;
  }
}
