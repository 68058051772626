import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Buttons,
  DownloadSnapshot,
  ExportVideo,
  PanTiltZoomContext,
  PlayerContext,
  PortalBoundaryContextProvider,
  Timeline,
  TimePicker,
} from '@avo-svtr/savitar';
import BookmarkButton from './BookmarkButton';
import Settings from './Settings';
import * as classes from './savitarPlayer.styles.css';

const {
  FullScreenToggle,
  PTZToggle,
  PlayPauseToggle,
  SkipBackwardButton,
  SkipForwardButton,
} = Buttons;

const PlaybackControls = ({
  cameraId,
  className,
  hideBookmarkButton,
  hideOverlayToggle,
  hostId,
}) => {
  const { isLive } = useContext(PlayerContext);
  const { isCameraPTZ, isPTZModeAvailable } = useContext(PanTiltZoomContext);

  const [tooltipBoundary, setTooltipBoundary] = useState(undefined);

  return (
    <PortalBoundaryContextProvider boundaryElement={tooltipBoundary}>
      <div className={classes.tooltipBoundaryWrapper}>
        <div ref={setTooltipBoundary} className={classes.tooltipBoundary} />
      </div>
      <div
        className={`${className} ${classes.playbackControls} ${
          classes.playbackControlsVerticalGroup
        }`}
      >
        <div className={classes.playbackControlsHorizontalGroup}>
          {/* TODO consider resetting the controls using
            context which can be widely used everywhere */}
          {isPTZModeAvailable ? null : <Timeline />}
        </div>
        <div className={classes.playbackControlsHorizontalGroup}>
          <div className={classes.playbackControlsGroup}>
            <SkipBackwardButton />
            <PlayPauseToggle />
            {isLive ? null : <SkipForwardButton />}
            <TimePicker />
          </div>
          <div className={classes.playbackControlsGroup}>
            {isCameraPTZ && isLive ? <PTZToggle /> : null}
            <DownloadSnapshot />
            <ExportVideo />
            <Settings
              cameraId={cameraId}
              hideOverlayToggle={hideOverlayToggle}
              hostId={hostId}
            />
            {hideBookmarkButton ? null : (
              <BookmarkButton cameraId={cameraId} hostId={hostId} />
            )}
            <FullScreenToggle />
          </div>
        </div>
      </div>
    </PortalBoundaryContextProvider>
  );
};

PlaybackControls.propTypes = {
  cameraId: PropTypes.string.isRequired,
  className: PropTypes.string,
  hideBookmarkButton: PropTypes.bool,
  hideOverlayToggle: PropTypes.bool,
  hostId: PropTypes.string,
};

PlaybackControls.defaultProps = {
  className: '',
  hideBookmarkButton: false,
  hideOverlayToggle: false,
  hostId: 'playerHost',
};

export default PlaybackControls;
