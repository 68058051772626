import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import * as DeviceActions from 'actions/devices';
import * as BookmarkActions from 'actions/bookmarks';
import { hideModal } from 'actions/modal';
import { refreshCameraList } from 'actions/aftClient';

// Selectors
import cameraSelectionSelector from 'selectors/cameraSelection';
import clusterLocationSelector from 'selectors/clusterLocationSelector';

// Utils
import { sortObjectsArrayByPropertyValue } from 'util/sortDevicesAndCameras';

// Components
import { Spinner } from 'components';
import BookmarkForm from './BookmarkForm';

// Styles
import { bookmarkFormSpinner, bookmarkFormWrapper } from './styles.css';

class BookmarkFormContainer extends Component {
  constructor(props) {
    super(props);
    this.bookmarkModalRef = React.createRef();
    this.state = {
      dispatchedSavitarActiveElementChange: false,
    };
  }

  componentDidMount() {
    const {
      actions,
      isFetchingBlueCameras,
      isFetchingDeviceData,
      siteId,
    } = this.props;
    if (isFetchingDeviceData === null) actions.getAllDevices();
    if (isFetchingBlueCameras === undefined)
      actions.getCameras({ field: 'LocationId', value: siteId });
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      cluster,
      isFetchingBlueCameras,
      selectionList,
      siteId,
    } = this.props;
    const { dispatchedSavitarActiveElementChange } = this.state;
    if (
      prevProps.isFetchingBlueCameras &&
      isFetchingBlueCameras === false &&
      (!selectionList || Object.keys(selectionList[0]).length === 0)
    ) {
      actions.refreshCameraList(siteId, cluster);
    }
    if (
      this.bookmarkModalRef.current &&
      !dispatchedSavitarActiveElementChange
    ) {
      this.setActiveElement();
    }
  }

  componentWillUnmount() {
    document.body.dispatchEvent(
      new CustomEvent('savitarActiveElementChange', {
        detail: { target: null },
      }),
    );
  }

  setActiveElement = () => {
    this.setState({ dispatchedSavitarActiveElementChange: true }, () => {
      document.exitFullscreen();
      document.body.dispatchEvent(
        new CustomEvent('savitarActiveElementChange', {
          detail: { target: this.bookmarkModalRef.current },
        }),
      );
    });
  };

  handleSubmitForm = (initialFormData, clusterId) => {
    const formData = { ...initialFormData };
    const { actions, handleCancel } = this.props;
    if (formData.id) {
      actions.updateBookmark(clusterId, formData);
    } else {
      delete formData.id;
      actions.createBookmark(clusterId, formData);
    }
    handleCancel();
  };

  handleDelete = (clusterId, bookmarkId) => {
    const { actions, handleCancel } = this.props;
    actions.deleteBookmarksForCluster(clusterId, [bookmarkId]);
    handleCancel();
  };

  handleCancel = () => {
    const { handleCancel } = this.props;
    handleCancel();
  };

  render() {
    const { bookmarkId, isFetchingBlueCameras, selectionList } = this.props;
    if (isFetchingBlueCameras === true || isFetchingBlueCameras === undefined) {
      return (
        <div className={`${bookmarkFormSpinner} ${bookmarkFormWrapper}`}>
          <Spinner size={80} />
        </div>
      );
    }
    if (selectionList && isFetchingBlueCameras === false) {
      return (
        <div ref={this.bookmarkModalRef}>
          <BookmarkForm
            key={bookmarkId}
            initialKeys={[
              'id',
              'name',
              'description',
              'startTime',
              'endTime',
              'cameraIds',
              'isProtected',
            ]}
            onCancel={this.handleCancel}
            onDelete={this.handleDelete}
            onSubmit={this.handleSubmitForm}
            {...this.props}
          />
        </div>
      );
    }
    return <div className={`${bookmarkFormWrapper}`} />;
  }
}

BookmarkFormContainer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  bookmarkId: PropTypes.string,
  cluster: PropTypes.objectOf(PropTypes.any),
  clusterId: PropTypes.string,
  handleCancel: PropTypes.func,
  isFetchingBlueCameras: PropTypes.bool,
  isFetchingDeviceData: PropTypes.bool,
  locationClusters: PropTypes.objectOf(PropTypes.string).isRequired,
  selectionList: PropTypes.arrayOf(PropTypes.object),
  siteId: PropTypes.string.isRequired,
};

BookmarkFormContainer.defaultProps = {
  bookmarkId: null,
  cluster: {},
  clusterId: null,
  handleCancel() {},
  isFetchingBlueCameras: undefined,
  isFetchingDeviceData: null,
  selectionList: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const allCameras = cameraSelectionSelector(state);
  const locationClusters = clusterLocationSelector(state);
  const { bookmarkId } = state.modal.modalProps;
  const siteId = state.modal.modalProps.siteId || ownProps.siteId;
  const isFetchingBlueCameras = state.isFetching.getCameras[siteId];
  const clusterId = state.modal.modalProps.clusterId || ownProps.clusterId;
  const cluster = state.clusters.clusters.find(c => c.Id === clusterId);
  let initialValues = {
    cameraIds: [],
    isProtected: false,
    ...ownProps.initialValues,
  };
  const cameraSelectObject =
    allCameras && allCameras.find(item => item.locationId === siteId);

  if (state.bookmarks.bookmarks[clusterId] && bookmarkId) {
    const selectedBookmark = state.bookmarks.bookmarks[clusterId].find(
      b => b.id === bookmarkId,
    );
    initialValues = selectedBookmark || initialValues;
  }
  let selectedCameraIds = initialValues.cameraIds;
  if (initialValues.cameraIds.length === 0 && ownProps.selectedCameraId) {
    selectedCameraIds = [ownProps.selectedCameraId];
  }

  const camerasArray = cameraSelectObject && [
    { ...cameraSelectObject, cameras: cameraSelectObject.cameras },
  ];
  if (camerasArray && camerasArray[0] && camerasArray[0].cameras) {
    sortObjectsArrayByPropertyValue(camerasArray[0].cameras, 'Name');
  }
  return {
    allCameras,
    bookmarkId,
    cluster,
    clusterId,
    initialValues,
    isFetchingBlueCameras,
    isFetchingDeviceData: state.devices.isFetchingDeviceData,
    locationClusters,
    profile: state.user.profile,
    selectedCameraIds,
    selectionList: camerasArray,
    siteId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      {
        ...DeviceActions,
        ...BookmarkActions,
        hideModal,
        refreshCameraList,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookmarkFormContainer);
