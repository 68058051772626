/* eslint-disable import/no-cycle */
// Libs
import React from 'react';

// Components
import { masthead } from 'sharedStyles/global.css';
import { PropTypes } from 'prop-types';
import Logo from './Logo';
// eslint-disable-next-line import/no-named-as-default
import LoginStatus from './LoginStatus';

function Masthead({
  canChangeOrganization,
  changeOrganization,
  currentOrganization,
  isAuthenticated,
  isFetchingUserProfile,
  languages,
  logout,
  organizations,
  role,
  showFrame,
  userName,
}) {
  return (
    <div className={masthead} style={{ display: showFrame ? 'flex' : 'none' }}>
      <Logo />
      <LoginStatus
        canChangeOrganization={canChangeOrganization}
        changeOrganization={changeOrganization}
        currentOrganization={currentOrganization}
        currentRole={role}
        isAuthenticated={isAuthenticated}
        isEnabled={canChangeOrganization}
        isFetchingProfile={isFetchingUserProfile}
        languages={languages}
        onLogout={logout}
        organizations={organizations}
        userName={userName}
      />
    </div>
  );
}

Masthead.propTypes = {
  canChangeOrganization: PropTypes.bool.isRequired,
  changeOrganization: PropTypes.func.isRequired,
  currentOrganization: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetchingUserProfile: PropTypes.bool.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  logout: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  role: PropTypes.string.isRequired,
  showFrame: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
};

Masthead.defaultProps = {};

export default Masthead;
