/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import renderIf from 'render-if';

// Components
import GroupLayout from 'lib/GroupLayout/GroupLayout';
import Icon from 'lib/Icon/Icon';
import MainContentWrapper from 'lib/ContentWrapper/MainContentWrapper';
import NoStyleButton from 'lib/Button/NoStyleButton';
import TopContentWrapper from 'lib/ContentWrapper/TopContentWrapper';
import { SelectCustomerContainer } from 'containers';

// Constants
import { secondaryNavContainer } from 'sharedStyles/styles.css';
import { IC_FILTER, IC_FILTER_OFF } from './constants';

// Util
import { persistShowFilters } from 'util/persistentStorageHelper';

import {
  AddControl,
  DeleteIcon,
  EditControl,
  SuspendUnsuspendIcons,
} from './NavControls';

// Styles
import {
  buttonGroup,
  contentMainFilters,
  contentTopHeader,
  crumb,
  filterGroup,
  listNavButtons,
  navHeader,
  navRightContent,
  navWrapper,
  secondaryNavContainerInner,
  toggleFiltersWrapper,
} from './ListNav.css';

const ListNav = props => {
  const {
    bulkActionsTable,
    canAdd,
    canChangeCustomer,
    canDelete,
    canEdit,
    canSuspendLocations,
    children,
    customButtons,
    forceFilterToggle,
    hideControls,
    id,
    navigationTabs,
    pageTitle,
    showFilters,
    setShowFilters,
  } = props;

  const renderToggleFiltersTitle = useMemo(() => {
    if (showFilters) {
      return 'ALARMS.FILTERS.HIDE_FILTERS';
    }
    return 'ALARMS.FILTERS.SHOW_FILTERS';
  }, [showFilters]);

  return (
    <div className={navWrapper}>
      <TopContentWrapper contentTopHeader={contentTopHeader}>
        {canChangeCustomer ? (
          <div className={crumb}>
            <SelectCustomerContainer />
          </div>
        ) : null}
        {renderIf(pageTitle)(
          <div className={navHeader} id={id}>
            {pageTitle}
            <div className={navRightContent}>
              <GroupLayout verticalSpacing="none">
                {renderIf(
                  React.Children.count(children) > 0 || forceFilterToggle,
                )(
                  <div className={buttonGroup}>
                    <NoStyleButton
                      className={toggleFiltersWrapper}
                      onClick={() => {
                        setShowFilters(!showFilters);
                        persistShowFilters(!showFilters);
                      }}
                    >
                      <Icon
                        id={showFilters ? IC_FILTER_OFF : IC_FILTER}
                        renderBare
                        title={renderToggleFiltersTitle}
                      />
                    </NoStyleButton>
                  </div>,
                )}
                {renderIf(!hideControls)(
                  !customButtons ? (
                    <div className={buttonGroup}>
                      {renderIf(canSuspendLocations)(
                        <>
                          <SuspendUnsuspendIcons {...props} />
                        </>,
                      )}
                      {renderIf(canDelete)(
                        <>
                          <DeleteIcon
                            bulkActionsTable={bulkActionsTable}
                            {...props}
                          />
                        </>,
                      )}
                      {renderIf(canEdit)(
                        <div className={listNavButtons}>
                          <EditControl {...props} />
                        </div>,
                      )}
                      {renderIf(canAdd)(
                        <div className={listNavButtons}>
                          <AddControl {...props} />
                        </div>,
                      )}
                    </div>
                  ) : (
                    <div className={buttonGroup}> {customButtons} </div>
                  ),
                )}
              </GroupLayout>
            </div>
          </div>,
        )}
        {renderIf(navigationTabs)(
          <div className={secondaryNavContainer}>
            <div className={secondaryNavContainerInner}>{navigationTabs}</div>
          </div>,
        )}
      </TopContentWrapper>
      {renderIf(React.Children.count(children) > 0 && showFilters)(
        <MainContentWrapper contentClass={contentMainFilters}>
          <div className={filterGroup}>{children}</div>
        </MainContentWrapper>,
      )}
    </div>
  );
};

ListNav.defaultProps = {
  bulkActionsTable: null,
  canAdd: false,
  canChangeCustomer: false,
  canDelete: false,
  canEdit: false,
  canSuspendLocations: false,
  children: null,
  customButtons: null,
  customTitle: null,
  deleteActive: false,
  forceFilterToggle: false,
  hasFilters: true,
  hideControls: false,
  id: 'idListNavHeader',
  navigationTabs: null,
  pageTitle: null,
  title: null,
};

ListNav.propTypes = {
  bulkActionsTable: PropTypes.string,
  canAdd: PropTypes.bool,
  canChangeCustomer: PropTypes.bool,
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  canSuspendLocations: PropTypes.bool,
  children: PropTypes.node,
  customButtons: PropTypes.element,
  customTitle: PropTypes.element,
  deleteActive: PropTypes.bool,
  forceFilterToggle: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hideControls: PropTypes.bool,
  id: PropTypes.string,
  navigationTabs: PropTypes.node,
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export default ListNav;
