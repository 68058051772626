import React from 'react';
import { SVG_XMLNS, ICON_COLOR_DEFAULT } from '../constants/app';

const IconSettings = props => {
  return (
    <svg
      className={props.className}
      width={props.width}
      height={props.height}
      viewBox="0 0 15 15"
      version="1.1"
      xmlns={SVG_XMLNS}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="03-alarms-review-full"
          transform="translate(-347.000000, -347.000000)"
          stroke={props.fill}
        >
          <g id="Video" transform="translate(0.000000, 90.000000)">
            <g id="Bar-/-Menu" transform="translate(0.000000, 246.000000)">
              <g id="Group-2" transform="translate(271.000000, 11.000000)">
                <g
                  id="img-icon-settings-off"
                  transform="translate(76.000000, 0.000000)"
                >
                  <path
                    d="M9.82821531,7.49915797 C9.82821531,8.69203501 8.83910973,9.66261591 7.62433989,9.66261591 C6.41069275,9.66261591 5.41990311,8.69203501 5.41990311,7.49915797 C5.41990311,6.30740363 6.41069275,5.33738409 7.62433989,5.33738409 C8.83967109,5.33794544 9.82821531,6.30740363 9.82821531,7.49915797 Z M12.6086012,5.95150526 L12.2611231,5.12519232 C12.2611231,5.12519232 13.0694727,3.29798536 12.9970581,3.22725477 L11.9288016,2.18032974 C11.8541415,2.10791509 10.0319868,2.94208699 10.0319868,2.94208699 L9.19107863,2.60134517 C9.19107863,2.60134517 8.44840742,0.75 8.34624101,0.75 L6.83844443,0.75 C6.73290989,0.75 6.04581272,2.605836 6.04581272,2.605836 L5.20714999,2.94770053 C5.20714999,2.94770053 3.3462618,2.15450746 3.2744085,2.2246767 L2.20727473,3.27384715 C2.13317602,3.34513909 2.98306582,5.13585804 2.98306582,5.13585804 L2.63614911,5.96217098 C2.63614911,5.96217098 0.75,6.69080835 0.75,6.78848393 L0.75,8.27158094 C0.75,8.37543141 2.64063994,9.05074016 2.64063994,9.05074016 L2.98755666,9.87480768 C2.98755666,9.87480768 2.17976839,11.7008919 2.25049898,11.7710612 L3.31931681,12.8202316 C3.39173146,12.8915236 5.21500894,12.057913 5.21500894,12.057913 L6.05647844,12.3992162 C6.05647844,12.3992162 6.79914965,14.25 6.90131606,14.25 L8.41023535,14.25 C8.51464718,14.25 9.20230571,12.394164 9.20230571,12.394164 L10.0443366,12.0517381 C10.0443366,12.0517381 11.9012953,12.8454925 11.9731486,12.7758847 L13.041405,11.7278369 C13.1160651,11.6548609 12.2644912,9.86526467 12.2644912,9.86526467 L12.6108466,9.03951308 C12.6108466,9.03951308 14.4975571,8.30919165 14.4975571,8.21039336 L14.4975571,6.72785771 C14.4986798,6.62512994 12.6086012,5.95150526 12.6086012,5.95150526 Z"
                    id="Stroke-36"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconSettings.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: ICON_COLOR_DEFAULT,
};

export default IconSettings;
