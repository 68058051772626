/* Reducer for visual overlays or other metadata on a stream
   Organized by id; for the current implementation, this can be assumed to be
   the cameraId, but future use cases may require a unique per-stream id.

   Data shape:
  {
    id1: {
      // Ids and counts are camera-wide data, not rule-specific, only need to be stored once
      eventId: string,
      eventCount: number,
      roiId: string,
      roiCount: number,
      beamId: string,
      beamCount: number,
      privacyZoneId: string,
      rules: { // The original rule data from the server; only touched with a RECEIVE_RULES action
        ruleId1: {
          event: {},
          roi: {},
          loi: null,
          id: string (optional), // The event sensorId or roi/loi id
          isSelected: bool (optional),
          isVisible: bool (optional),
        },
        ruleId2: {
          event: {},
          roi: null,
          loi: {},
        },
      },
      clearRuleEdits: bool, // Flag indicating ruleEdits should be cleared next time new rules data is received,
      ruleEdits: { // Updates made to the rules through the UI; changed via ADD_RULE, SET_RULE, and DELETE_RULE actions
        ruleId2: {
          ...edited copy of rules.rules.id2
        },
        ruleId2: null,
      },
      // New rule data looks like { ...rules, ...ruleEdits }.filter(null)
      showRuleCanvas: [ playerHostId1, playerHostId2 ],
      // Allow individual player instances to determine rule overlay visibility
      privacyZones: {
        pId1:  {
          isSelected: bool,
          privacyZoneDimensions: { left, right, top, bottom }
        },
        pId2:  {
          isSelected: bool,
          privacyZoneDimensions: { left, right, top, bottom }
        },
       },
      },
      clearPrivacyZoneEdits: bool,
      privacyZoneEdits: {
        pId1: { left, right, top, bottom },
        pId2: null,
      },
      // New pzone data looks like 
         Object.values({ ...privacyZones, ...privacyZoneEdits }).filter(null)
         [Privacy zones are delivered to canvas as an array - 
          this should preserve order while removing logically deleted zones]
    },
    id2: {
       rules: [],
       privacyZones: []
    }
    } */

import * as types from 'constants/ActionTypes';
import initialState from 'store/initialState';
import { newPrivacyZone, newRule } from 'constants/cameraSettings';
import {
  analyticsWepToRedux,
  generalWepToRedux,
} from 'util/streamOverlaysHelper';
import { canvasObjectTypes } from 'constants/app';

export default function streamOverlaysReducer(state, action = {}) {
  let { id } = action;
  const { cameraId } = action;
  if (!id && !cameraId) {
    return state || initialState().streamOverlays;
  }
  if (!id && cameraId) {
    id = cameraId;
  }
  // All actions for this reducer must specify which stream they target
  switch (action.type) {
    // camera-settings: ANALYTICS call gives us RULES
    case types.RECEIVE_RULES: {
      const { rules } = action;
      if (!rules) return state;
      const { analytics: data } = rules;
      if (!data) return state;
      const { beams, events, rois } = data;
      const currentCameraState = state[id] || {};
      const { clearRuleEdits } = currentCameraState;
      const newState = {
        ...currentCameraState,
        beamCount: beams.val && beams.val.lois ? beams.val.lois.length : 0,
        beamId: beams.id,
        clearRuleEdits: false,
        eventCount: events.val ? events.val.length : 0,
        eventId: events.id,
        roiCount: rois.val ? rois.val.length : 0,
        roiId: rois.id,
        rules: analyticsWepToRedux(data),
      };
      if (clearRuleEdits) {
        newState.ruleEdits = {};
      }
      return { ...state, [id]: newState };
    }
    case types.SET_RULE: {
      // New event in ruleData will replace old event,
      // but if it's easier, could merge events
      const { ruleData, ruleId } = action;
      const currentCameraState = state[id] || {};
      const { ruleEdits: oldRuleEdits } = currentCameraState;
      let ruleEdits = {};
      if (!oldRuleEdits) {
        ruleEdits[ruleId] = ruleData;
      } else if (!oldRuleEdits[ruleId]) {
        ruleEdits = { ...oldRuleEdits, [ruleId]: ruleData };
      } else {
        const oldRuleData = oldRuleEdits[ruleId];
        const rule = { ...oldRuleData, ...ruleData };
        // should only have one of loi or roi
        if (ruleData.roi) {
          rule.loi = null;
        } else if (ruleData.loi) {
          rule.roi = null;
        }
        ruleEdits = { ...oldRuleEdits, [ruleId]: rule };
      }
      return { ...state, [id]: { ...state[id], ruleEdits } };
    }
    case types.RESET_RULE: {
      const currentCameraState = state[id] || {};
      const { ruleId } = action;
      const { ruleEdits: oldRuleEdits } = currentCameraState;
      const ruleEdits = {};
      if (oldRuleEdits) {
        Object.keys(oldRuleEdits).forEach(key => {
          if (key.toString() !== ruleId.toString()) {
            ruleEdits[key] = oldRuleEdits[key];
          }
        });
      }
      return {
        ...state,
        [id]: { ...currentCameraState, ruleEdits },
      };
    }
    case types.RESET_ALL_RULES: {
      const currentCameraState = state[id] || {};
      return {
        ...state,
        [id]: { ...currentCameraState, ruleEdits: {} },
      };
    }
    case types.ADD_RULE: {
      const currentCameraState = state[id] || {};
      const { ruleId } = action;
      let { boundaryType } = action;
      if (!Object.values(canvasObjectTypes).includes(boundaryType)) {
        boundaryType = canvasObjectTypes.roi;
      }
      const ruleEdits = {
        ...currentCameraState.ruleEdits,
        [ruleId]: newRule(ruleId),
      };
      return {
        ...state,
        [id]: { ...currentCameraState, ruleEdits },
      };
    }
    case types.CLEAR_RULE_EDITS: {
      // Flag ruleEdits for deletion because we are fetching new rules from the server
      const currentCameraState = state[id] || {};
      return {
        ...state,
        [id]: { ...currentCameraState, clearRuleEdits: true },
      };
    }
    case types.DELETE_RULE: {
      // Value of null in ruleEdits indicates a rule is flagged for deletion
      const { ruleId } = action;
      const currentCameraState = state[id] || {};
      const { ruleEdits: oldRuleEdits } = currentCameraState;
      let ruleEdits = {};
      if (!oldRuleEdits) {
        ruleEdits[ruleId] = null;
      } else {
        ruleEdits = { ...oldRuleEdits, [ruleId]: null };
      }
      return { ...state, [id]: { ...state[id], ruleEdits } };
    }
    // camera-settings: GENERAL call gives us PRIVACY_ZONES
    case types.RECEIVE_IMAGE_SETTINGS: {
      const { settings } = action;
      if (!settings) return state;
      const { general: data } = settings;
      if (!data) return state;
      const currentCameraState = state[id] || {};
      const privacyZones = generalWepToRedux(data);
      let newState;

      if (data.privacyZones && data.privacyZones.id) {
        const privacyZoneId = data.privacyZones.id;
        newState = {
          ...currentCameraState,
          privacyZoneId,
          privacyZones,
        };
      } else {
        newState = { ...currentCameraState };
      }

      return {
        ...state,
        [id]: newState,
      };
    }
    case types.SET_RULE_CANVAS_VISIBILITY: {
      const { playerId, show } = action;
      if (!playerId) return state;
      const currentCameraState = state[id] || {};
      let showRuleCanvas = currentCameraState.showRuleCanvas
        ? [...currentCameraState.showRuleCanvas]
        : [];
      if (show === false) {
        showRuleCanvas = showRuleCanvas.filter(i => i !== playerId);
      } else if (!showRuleCanvas.includes(playerId)) {
        showRuleCanvas.push(playerId);
      }
      const newState = { ...currentCameraState, showRuleCanvas };
      return {
        ...state,
        [id]: newState,
      };
    }
    case types.SET_ZONE: {
      // TODO: Merge replaces current zone
      // should it merge instead?
      const { data, zoneId } = action;
      if (zoneId === null || zoneId === undefined) return state;
      const currentCameraState = state[id] || {};
      const privacyZoneEdits = {
        ...currentCameraState.privacyZoneEdits,
        [zoneId]: data,
      };
      const cameraState = {
        ...currentCameraState,
        privacyZoneEdits,
      };
      return { ...state, [id]: cameraState };
    }
    case types.RESET_ALL_ZONES: {
      const currentCameraState = state[id] || {};
      return {
        ...state,
        [id]: { ...currentCameraState, privacyZoneEdits: {} },
      };
    }
    case types.ADD_ZONE: {
      const currentCameraState = state[id] || {};
      const {
        privacyZoneEdits: oldPrivacyZoneEdits,
        privacyZones: oldPrivacyZones,
      } = currentCameraState;
      const mergedZones = {
        ...oldPrivacyZones,
        ...oldPrivacyZoneEdits,
      };
      const lastIndex = Object.keys(mergedZones).length
        ? Object.keys(mergedZones).pop()
        : -1; // Privacy zones start at id 0
      const privacyZoneEdits = {
        ...oldPrivacyZoneEdits,
        [parseInt(lastIndex, 10) + 1]: {
          ...newPrivacyZone(),
          isSelected: action.selected || false,
        },
      };
      return {
        ...state,
        [id]: { ...currentCameraState, privacyZoneEdits },
      };
    }
    default:
      return state || initialState().streamOverlays;
  }
}
