import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { SVG_XMLNS } from '../constants/app';

const IconGoToHomePreset = props => {
  return (
    <div className={props.className} title={props.translate(props.title)}>
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 20 20"
        version="1.1"
        xmlns={SVG_XMLNS}
      >
        <defs>
          <path id="aa" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          <path id="da" d="M0 0h24v24H0z" />
          <filter
            x="-29.2%"
            y="-20.8%"
            width="158.3%"
            height="158.3%"
            filterUnits="objectBoundingBox"
            id="ca"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="2"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0 0.533827594 0 0 0 0.285694067 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g transform="translate(-2 -3)" fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z" />
          <mask id="ba" fill="#fff">
            <use xlinkHref="#aa" />
          </mask>
          <g mask="url(#ba)">
            <use fill="#000" filter="url(#ca)" xlinkHref="#da" />
            <use fill="#FFF" xlinkHref="#da" />
          </g>
        </g>
      </svg>
    </div>
  );
};

IconGoToHomePreset.defaultProps = {
  className: null,
  width: '20px',
  height: '20px',
  fill: 'white',
  title: 'RULES.GO_TO_HOME_PRESET',
};

export default withLocalize(IconGoToHomePreset);
