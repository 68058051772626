// Libs
import React from 'react';

// Styles
import { headerItem, halfHour } from './styles.css';

const GenerateTime = () => {
  const timeJsx = [
    <div className={headerItem} key={0}>
      <span>{'12'}</span>
    </div>,
  ];
  timeJsx.push(
    <div className={halfHour} key={30}>
      <div>
        <span>{`30`}</span>
      </div>
      <div>
        <span>{`:`}</span>
      </div>
    </div>,
  );
  for (let i = 1; i <= 23; i++) {
    let time = i % 12;
    if (time === 0) {
      time = 12;
    }
    timeJsx.push(
      <div className={headerItem} key={i}>
        <span>{`${time}`}</span>
      </div>,
    );
    timeJsx.push(
      <div className={halfHour} key={i + 30}>
        <div>
          <span>{`30`}</span>
        </div>
        <div>
          <span>{`:`}</span>
        </div>
      </div>,
    );
  }

  return timeJsx;
};

export default GenerateTime;
